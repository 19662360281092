// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- constants export
/* istanbul ignore file -- constants export */

const ACTIVE_TRUST_LIST_CACHE_ID = 'ActiveTrustList';
const PENDING_TRUST_LIST_CACHE_ID = 'PendingTrustList';
const TRUST_CACHE_ID = 'TRUST_CACHE_ID';
const TRUST_LIST_CACHE_ID = 'TRUST_LIST_CACHE_ID';

const DIRECTORY_TRUSTEE_EVENT = {
  UPDATE: 'UpdateDirectoryTrustee',
};

const DIRECTORY_TRUSTEE_LIST_EVENT = {
  UPDATE: 'UpdateDirectoryTrusteeList',
  UPDATE_COUNT: 'UpdateDirectoryTrusteeCount',
};

const TRUST_STATUS = {
  ACTIVE: 'ACTIVE', // Trustee
  PENDING: 'PENDING', // Access Request
};

const TRUST_EVENT = {
  DELETE: 'DeleteTrust',
};

const TRUSTEE_EVENT = {
  UPDATE: 'UpdateTrustee',
};

const TRUSTEE_LIST_EVENT = {
  UPDATE: 'UpdateTrusteesList',
  UPDATE_COUNT: 'UpdateTrusteesCount',
};

const ACCESS_REQUESTS_EVENT = {
  UPDATE: 'UpdateAccessRequestsList',
  UPDATE_COUNT: 'UpdateAccessRequestsCount',
};

export {
  ACTIVE_TRUST_LIST_CACHE_ID,
  PENDING_TRUST_LIST_CACHE_ID,
  TRUST_CACHE_ID,
  TRUST_LIST_CACHE_ID,
  DIRECTORY_TRUSTEE_EVENT,
  DIRECTORY_TRUSTEE_LIST_EVENT,
  TRUST_STATUS,
  TRUST_EVENT,
  TRUSTEE_EVENT,
  TRUSTEE_LIST_EVENT,
  ACCESS_REQUESTS_EVENT,
};
