(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.video-modal
   * @description module that defines video-modal widget
   */
  angular.module('binky.widgets.common.video-modal', [
    'binky.core.url-utils',
    'binky.shell.panels',
    'binky.shell.panels.modal',
  ]);
})();
