import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getProvisionedDelegatedQtyAndLabels} from '../line-chart/usageLineChartUtils';

import {
  getAssignedColumnContent,
  getLicensesColumnContent,
  getYearlyOrMonthTableHeader,
  getYearlyOrMonthlyColumnContent,
} from './usageLineTableUtils';

/**
 * A component that will display chart component for etla usage report
 */
const UsageLineTable = ({'aria-label': ariaLabel, chartDataPoints, selectedPeriodType}) => {
  const intl = useIntl();
  const provisionedDelegatedQtyAndLabels = getDelegatedAndProvisionedQty();
  return (
    <TableView
      aria-label={ariaLabel}
      data-testid="usage-line-table"
      density="compact"
      height={provisionedDelegatedQtyAndLabels.length < 4 ? 'auto' : 'size-2000'}
      overflowMode="wrap"
    >
      <TableHeader data-testid="table-header">
        <Column>{getYearlyOrMonthTableHeader(intl, selectedPeriodType)}</Column>
        <Column align="end">
          {intl.formatMessage({
            id: 'binky.common.etlaUsageReport.chart.table.header.licenses',
          })}
        </Column>
        <Column align="end">
          {intl.formatMessage({
            id: 'binky.common.etlaUsageReport.chart.table.header.assigned',
          })}
        </Column>
      </TableHeader>
      <TableBody items={provisionedDelegatedQtyAndLabels}>
        {(item) => (
          <Row key={getYearlyOrMonthlyColumnContent(intl, item.labels, selectedPeriodType)}>
            <Cell>{getYearlyOrMonthlyColumnContent(intl, item.labels, selectedPeriodType)}</Cell>
            <Cell>{getLicensesColumnContent(intl, item.provisionedQty)}</Cell>
            <Cell>{getAssignedColumnContent(intl, item.delegatedQty, item.provisionedQty)}</Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
  function getDelegatedAndProvisionedQty() {
    const {delegatedQty, labels, provisionedQty} = getProvisionedDelegatedQtyAndLabels(
      chartDataPoints,
      selectedPeriodType
    );
    const provisionedDelegatedQtyAndLabelsArray = [];
    delegatedQty.filter((item, index) =>
      provisionedDelegatedQtyAndLabelsArray.push({
        delegatedQty: item,
        labels: labels[index],
        provisionedQty: provisionedQty[index],
      })
    );
    return provisionedDelegatedQtyAndLabelsArray;
  }
};

UsageLineTable.propTypes = {
  /**
   * The aria-label for Chart table Component.
   */
  'aria-label': PropTypes.string.isRequired,
  /**
   * The chart data points fetched from FoxManager dataPoints API for a selected period type.
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  chartDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * The selected period type.
   */
  selectedPeriodType: PropTypes.string.isRequired,
};

export default UsageLineTable;
