import get from 'lodash/get';

import {isOrganizationConsumable} from 'models/fulfillableItemList/fulfillableItemListUtils';
import feature from 'services/feature';
import {
  PRODUCT_BUYING_PROGRAM,
  PRODUCT_FAMILY,
  PRODUCT_LICENSE_ALLOWLIST,
  PRODUCT_LICENSE_DISALLOWLIST,
} from 'services/product/ProductConstants';
import {keysToCamelCase} from 'utils/objectUtils';

/**
 * The utility methods in this file are written to be applicable to
 * shared attributes between Offers and Products to keep things DRY.
 */

/**
 * @description Method to determine if the offer/product is a "consumable" (either group or an org consumable) and
 *      should look for usage information via the ConsumableSummarizationList.
 *
 * @param {String} buyingProgram the buying program
 * @param {String} family the family
 * @param {FulfillableItemList} fulfillableItemList the fulfillableItemList instance
 * @returns {Boolean} true if it's a consumable offer/product, else false
 */
function isConsumable({buyingProgram, family, fulfillableItemList}) {
  return (
    isGroupConsumable({buyingProgram, family, fulfillableItemList}) ||
    isOrganizationConsumable(fulfillableItemList)
  );
}
/**
 * @returns {Boolean} true if the offer corresponds to a feature restricted
 *                    licenses product.
 */
function isFeatureRestrictedLicense({fulfillableItemList}) {
  return (
    fulfillableItemList.hasPackagePreconditioning() &&
    !fulfillableItemList.hasLaboratoryLicenseManagement()
  );
}

/**
 * @description Method to determine if this Product/Offer is a group "consumable" (not organization consumable) and
 * should look for usage information via the ConsumableSummarizationList.
 *
 * @param {String} buyingProgram the buying program
 * @param {String} family the family
 * @param {FulfillableItemList} fulfillableItemList the fulfillableItemList instance
 * @returns {Boolean} true if it's a group consumable product/offer, else false
 */
function isGroupConsumable({buyingProgram, family, fulfillableItemList}) {
  // Consumables all have a Fulfillable Item with fulfillableItemType = QUOTA
  // However some products that fit this criteria are not considered Consumables (yet), so we need to
  // check a few more pieces of data to narrow it down.
  const quotaFulfillableItems = fulfillableItemList.getConsumableQuotaTypeItems({
    requireDelegationConfigurable: false,
  });

  const units = get(quotaFulfillableItems, '[0].chargingModel.unit');

  // These deeper properties checks will restrict returning 'true' to Stock Credit Packs (via ON_DEMAND type,
  // rather than images/month which has RECURRING), Sign Transactions (via units of 'transactions', Sign KBA and Phone Auth
  // rather than Sign user/seat which has unit of 'license'; both have type of RECURRING), and NUELL (via the
  // laboratory_license_mgmt FI) products.
  // As we decide to consider more things "consumables" the check here may change, and ideally will simplify.
  const signAddonUnits = feature.isEnabled('temp_sign_addon')
    ? ['transactions', 'SIGN_KBA', 'SIGN_PHONE_AUTH']
    : ['transactions'];

  // Sign Aadhaar
  if (feature.isEnabled('temp_sign_aadhaar')) {
    signAddonUnits.push('SIGN_AADHAAR');
  }

  return (
    quotaFulfillableItems.length > 0 &&
    (fulfillableItemList.hasLaboratoryLicenseManagement() || // NUELL is the only kind of ETLA license that supports the consumption API; exclude ETLA for other types
      (buyingProgram !== PRODUCT_BUYING_PROGRAM.ETLA &&
        family === PRODUCT_FAMILY.SIGN &&
        signAddonUnits.includes(units))) // Sign Transactions
  );
}

/**
 * @description Method to determine the offer corresponds to a shared device license (SDL).
 *
 * @returns {Boolean} true if it's a shared device license product, else false
 */
function isSharedDeviceLicense({fulfillableItemList}) {
  return fulfillableItemList.hasLaboratoryLicenseManagement();
}

/**
 * @description Rename and camelCase fields from AOS offer FIs
 * to be compatible with FulfillableItemList, whose FIs are usually pulled from the Product FIs
 *
 * @param {Object[]} fulfillableItems AOS offer FIs
 *
 * @returns {Object[]} FIs transformed to work with FulfillableItemList
 */
function transformFIsToProductFIs(fulfillableItems) {
  return fulfillableItems?.map((fulfillableItem) => ({
    fulfillableItemType: fulfillableItem.type,
    ...keysToCamelCase(fulfillableItem),
  }));
}

/**
 * @description Checks if the provided attributes of a product or offer is seat based
 *
 * @param {String} buyingProgram the buying program
 * @param {String} family the family
 * @param {FulfillableItemList} fulfillableItemList The fulfillableItemList instance
 * @param {String} productArrangementCode the product arrangement code
 * @returns {Boolean} true if the product or offer uses seat based delegation
 */
function usesSeatBasedDelegation({
  buyingProgram,
  family,
  fulfillableItemList,
  productArrangementCode,
}) {
  const hasQuotaItems = fulfillableItemList.hasConsumableQuotaTypeItems({
    includeDelegationTypePerson: false,
    requireDelegationConfigurable: false,
  });

  return (
    !isConsumable({buyingProgram, family, fulfillableItemList}) &&
    !isProductDisallowed(productArrangementCode) &&
    (!hasQuotaItems || isProductAllowed(productArrangementCode)) &&
    !fulfillableItemList.hasLaboratoryLicenseManagement() &&
    !fulfillableItemList.hasPackagePreconditioning()
  );

  function isProductAllowed(paCode) {
    return PRODUCT_LICENSE_ALLOWLIST.includes(paCode);
  }

  function isProductDisallowed(paCode) {
    return PRODUCT_LICENSE_DISALLOWLIST.includes(paCode);
  }
}

export {
  isConsumable,
  isFeatureRestrictedLicense,
  isGroupConsumable,
  isSharedDeviceLicense,
  transformFIsToProductFIs,
  usesSeatBasedDelegation,
};
