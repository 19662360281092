(function () {
  /**
   * @deprecated Please use src2/core/models/contact/Contact.js
   *
   * @ngdoc factory
   * @name binky.core.contact:Contact
   * @description Model for an individual Contact
   */
  angular.module('binky.core.contact').factory('Contact', getContactModel);

  /* @ngInject */
  function getContactModel(binkySrc2) {
    /**
     * @deprecated This class has been ported to src2.
     * Any subsequent changes should be made to the src2 implementation.
     */

    /** Return reference to Constructor function **/
    return binkySrc2.models.contact.Contact;
  }
})();
