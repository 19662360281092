/* eslint-disable max-lines */
(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.shell.navigation.assembler').provider('navAssembler', navAssembler);

  /* @ngInject */
  function navAssembler(
    $stateProvider,
    $transitionsProvider,
    _,
    CACHED_STATE_KEY_PREFIX,
    ORGANIZATION_LIST,
    stateManagerProvider
  ) {
    const initInjector = angular.injector(['ng']);
    const navigationLists = {};

    this.configure = configure;
    this.getNavigationLists = getNavigationLists;
    this.$get = $get;

    ////////////

    /**
     * @description Method to configure navigation lists in the UI. This method
     *   can either configure a single navigation list, or multiple lists in a
     *   single call.
     * @param {Object|Object[]} navData - configuration data for navigation list(s)
     * @param {String} navData.navID - unique ID of navigation list to configure
     * @param {Object|Object[]} navData.navValue - navigation item(s) in list
     * @param {Object} [navData.navValue.access] - optional access configuration
     *   options
     * @param {Function} [navData.navValue.access.callback] - method that can be
     *   used to determine if a user has access to this navigable item. Method
     *   must return a Promise that resolves to Boolean value indicating
     *   accessibility (true for accessible, false to block). Method will be
     *   called with a reference to the $q service and UIInjector (e.g. -
     *   callback($q, injector)), which allows for returning a Promise ($q) and
     *   accessing other models/services (injector.get('exampleServiceName'))
     * @param {Boolean} [navData.navValue.access.children] - indicates whether
     *   or not a route contains children with accessibility restrictions. If
     *   true, this indicates that transitions into this section of the state
     *   tree should first check what child states are accessible before routing
     *   the transition to the first available (by order) state. If false or
     *   missing, then no checks or rerouting of this nature will be performed
     * @param {Object} [navData.navValue.access.feature] - options to configure
     *   bumpering of this state's sub-tree, based on runtime feature flag
     *   values
     * @param {String} [navData.navValue.access.feature.name] - name of feature
     *   flag to use when determining bumpering status
     * @param {Boolean} [navData.navValue.access.feature.omit] - whether or not
     *   to register the bumpered state as 'not-found' (true) or 'unavailable'
     *   (false)
     * @param {Function} [navData.navValue.access.params] - method that can be
     *   used to determine if a user has access to this navigable item. Method
     *   must return a Promise that resolves to Boolean value indicating
     *   accessibility (true for accessible, false to block). Method will be
     *   called with a reference to the $q service and Transition (e.g. -
     *   callback($q, trans)), which allows for returning a Promise ($q) and
     *   accessing data attached to this state's, or a parent state, resolve
     *   attributes (trans.injector().getAsync('exampleResolvedObject'))
     * @param {ROLE[]} [navData.navValue.access.roles] - list of roles that user
     *   must possess in order to access this state sub-tree. If the signed in
     *   user does not possess at least one of these roles for the active
     *   organization, then access will be denied
     * @param {Boolean} [navData.navValue.cacheChildState] - whether or not the
     *   last active state in this parent's state sub-tree should be cached and
     *   used to redirect to on subsequent views. If this value is true, then
     *   when users navigate to this state sub-tree, they will be redirected to
     *   the last state/view that was active. Users are able to navigate between
     *   states within the sub-tree (without redirection). However, this will
     *   influence (store/cache) the last view and use this state as the next
     *   state to redirect to on access (from outside state sub-tree). If this
     *   value is false or missing, no caching or redirection of this nature is
     *   performed
     * @param {String} [navData.navValue.child] - ID of child navigation list
     * @param {Boolean} [navData.navValue.disabled] - true if item should be disabled
     * @param {Boolean} [navData.navValue.hidden] - true if item should be hidden
     * @param {String} [navData.navValue.icon] - name of icon to be used in item
     * @param {Boolean} [navData.navValue.invalid] - true if item is invalid
     * @param {String} [navData.navValue.list] - if item is a sub list, this is its key
     * @param {String} navData.navValue.name - name of item
     * @param {Number} navData.navValue.order - zero-based index where tab should live in list
     * @param {Object} navData.navValue.state - item's destination state (when clicked) (see: https://ui-router.github.io/ng1/docs/0.3.1/index.html#/api/ui.router.state.$state)
     * @param {String} navData.navValue.state.name - state name
     * @param {String} [navData.navValue.state.params] - state params
     * @param {String} [navData.navValue.state.options] - state options
     * @returns {Promise} resolves if configured successfully, else rejects with error
     */
    function configure(navData) {
      const $q = initInjector.get('$q');
      const navDataArray = angular.isArray(navData) ? navData : [navData];

      // go through lists and configure each one...
      _.forEach(navDataArray, (navDatum) => {
        const {navID} = navDatum;
        let {navValue} = navDatum;

        // ensure value(s) being added of type array
        if (!angular.isArray(navValue)) {
          navValue = [navValue];
        }

        // add value(s) to list
        if (navigationLists[navID]) {
          // if present, add new value
          Array.prototype.push.apply(navigationLists[navID], navValue);
        } else {
          // create if not present
          navigationLists[navID] = navValue;
        }

        _.forEach(navValue, (item) => {
          initializeTransitionHookStorage(item);

          if (_.has(item, 'access.children')) {
            configureChildrenVisibility(item);
          }

          if (
            _.has(item, 'access.roles') ||
            _.has(item, 'access.params') ||
            _.has(item, 'access.callback')
          ) {
            configureLogicBumper(item);
          }

          if (_.get(item, 'cacheChildState', false)) {
            configureChildStateCaching(item);
          }

          if (_.has(item, 'access.feature')) {
            configureFeatureBumper(item);
          }

          if (_.has(item, 'state.definition')) {
            stateManagerProvider.configure(item.state);
          }
        });
      });

      return $q.resolve();

      //////////////

      /**
       * @description Method to configure transition hooks on a route to
       *   control any child route visibility. The transition hook will
       *   intercept any entering transitions to the parent route or its
       *   descendants, checking/updating the visibility of any child routes,
       *   and finally resolving the original transition or redirecting to the
       *   first active/available child route (if accessing the parent route
       *   directly).
       * @param {Object} navItem - navigation definition Object
       */
      function configureChildrenVisibility(navItem) {
        navItem.state.transitionHooks.push(
          $transitionsProvider.onEnter(
            {to: `${navItem.state.name}.**`},
            configureVisibilityAndRoute
          )
        );
        navItem.state.transitionHooks.push(
          $transitionsProvider.onRetain(
            {from: `${navItem.state.name}.**`, to: `${navItem.state.name}`},
            configureVisibilityAndRoute
          )
        );

        function configureVisibilityAndRoute(trans) {
          const navList = trans.injector().get('navManager').find(navItem.child);
          const stateManager = trans.injector().get('stateManager');
          const feature = trans.injector().get('feature');

          const isStateEnabled = feature.isEnabled('bug_fix_32937')
            ? stateManager.isStateEnabled(navItem.state.name, navItem.state)
            : true;

          if (navItem.access.children && !navItem.hidden && isStateEnabled) {
            if (navList) {
              return navList.updateVisibility(trans).then(redirectToFirstActive);
            }
          }

          /**
           * @description Method to redirect to the first active/available
           *   route in a navigation list. This method will only redirect to
           *   the first available route if the original transition is going
           *   to the parent state (has children). If a child route is being
           *   accessed directly, this method will simply return true
           *   (allowing the current/original transition to complete).
           * @returns {Boolean|TargetState} true is returned unless parent
           *   route is being accessed directly and an available child route
           *   exists to redirect to (in which case, the TargetState will
           *   reflect this new route, causing the current transition to
           *   redirect)
           */
          function redirectToFirstActive() {
            if (trans.to().name === navItem.state.name) {
              const activeNavItem = navList.findFirstActive();

              if (activeNavItem && _.has(activeNavItem, 'state.name')) {
                return trans.router.stateService.target(
                  activeNavItem.state.name,
                  _.defaults({}, activeNavItem.state.params, trans.params('to'))
                );
              }
            }
            return true;
          }

          return true;
        }
      }

      /**
       * @description Method to configure a transition hook that will both store
       *   and redirect to the last state a user had been viewing. States will
       *   be cached as they are accessed through navigation. States will be
       *   redirected to when they are accessed from outside of this state
       *   sub-tree, but still within the application (does not redirect when
       *   deep linking into the application). This allows users to return to
       *   the last view they had accessed when switching away from this section
       *   of the application. But, it does not prevent them from moving between
       *   views in this section. It also does not prevent bookmarks into
       *   specific views from redirecting (however, these views will update the
       *   cache). If users want to bookmark the cached view, they simply need
       *   remove the last path segment and attempt to access the parent route
       *   directly (will redirect).
       * @param {Object} navItem - navigation definition Object
       */
      function configureChildStateCaching(navItem) {
        navItem.state.transitionHooks.push(
          $transitionsProvider.onEnter(
            {to: `${navItem.state.name}.**`},
            cacheLastActiveStateAndRedirect
          )
        );

        /**
         * @description Method to handle navigation within a sub-tree that has
         *   child state caching enabled. Transitions between states in this
         *   sub-tree are permitted and will only update the cache tracking the
         *   last viewed state. Transitions originating from outside of this
         *   sub-tree, but within the application, will redirect to the last
         *   viewed (cached) state.
         * @param {Transition} trans - Object representing the current
         *   transition taking place
         * @returns {Promise} returns a Promise that resolves to true if the
         *   transition should proceed, else if transition should redirect,
         *   returns a Promise that resolves to a TargetState reference
         *   representing the last viewed (cached) state
         */
        function cacheLastActiveStateAndRedirect(trans) {
          const storageUtils = trans.injector().get('storageUtils');
          const transitionFromStateName = _.chain(trans).invoke('from').get('name', '').value();
          const transitionToStateName = _.chain(trans).invoke('to').get('name', '').value();

          if (
            _.startsWith(transitionFromStateName, `${navItem.state.name}.`) ||
            _.isEmpty(transitionFromStateName)
          ) {
            // transition came from a sub-state or came from outside of application
            if (_.startsWith(transitionToStateName, `${navItem.state.name}.`)) {
              // transition is going to another sub-state, so cache new last
              // active state...
              storageUtils.setLocalStorageItem(
                `${CACHED_STATE_KEY_PREFIX}${navItem.state.name}`,
                transitionToStateName
              );
            }
            return $q.resolve(true);
          }

          const redirectToState = storageUtils.getLocalStorageItem(
            `${CACHED_STATE_KEY_PREFIX}${navItem.state.name}`
          );

          // don't redirect if not accessing an actual child state, or if
          // accessing the current state (what this hook is looking for; will cause
          // infinite loop)
          if (_.isNil(redirectToState) || _.isEqual(redirectToState, transitionToStateName)) {
            return $q.resolve(true);
          }

          return $q.resolve(trans.router.stateService.target(redirectToState, trans.params('to')));
        }
      }

      /**
       * @description Method to configure a feature bumper for the navigation
       *   item currently being configured. A feature bumper allows the use of
       *   feature flags to completely bumper entire hierarchies of the
       *   navigation tree from being accessible to users.
       *
       *   Bumpering is achieved by adding an onEnter transition hook to the
       *   route (and all children; wildcard route). When a transition is made
       *   to this route (or any children), the value of the configured
       *   feature flag is evaluated at runtime and, if it resolves to true,
       *   the transition is instead replaced with a not-found or unavailable
       *   transition (depending on value of omit attribute in feature
       *   definition within route).
       * @param {Object} navItem - navigation definition Object
       */
      function configureFeatureBumper(navItem) {
        let bumperState;

        if (navItem.access.feature.omit) {
          bumperState = 'not-found';
        } else {
          bumperState = `${navItem.state.name}-unavailable`;
          $stateProvider.state(bumperState, {
            component: 'binkyBumper',
            parent: navItem.state.parent,
            resolve: {
              details: _.constant('binky.shell.navigation.ready.bumper.feature'),
            },
            url: `${
              _.get(navItem, 'state.url') || _.get(navItem, 'state.definition.url') || ''
            }/unavailable`,
          });
        }

        navItem.state.transitionHooks.push(
          $transitionsProvider.onEnter({to: `${navItem.state.name}.**`}, (trans) => {
            const feature = trans.injector().get('feature');
            return feature.isEnabled(navItem.access.feature.name)
              ? trans.router.stateService.target(bumperState, trans.params('to'))
              : true;
          })
        );
      }

      /**
       * @description Method to configure a logic bumper for the current route
       *   being configured. A logic bumper consists of a transition hook,
       *   configured to intercept any transitions to this route or any
       *   sub-route in the navigation tree. This transition hook checks any
       *   logic checks that have been configured for this route, returning
       *   true if all checks pass, or a TargetState Object that will redirect
       *   the transition to our not-found state.
       *
       *   Logic checks can be callback handlers, params checks, or role
       *   checks. Checks can be combined for a route, but at least one must
       *   exist in the navigation route definition, in order to set up/check
       *   any of these preconditions.
       * @param {Object} navItem - navigation definition Object
       */
      function configureLogicBumper(navItem) {
        navItem.state.transitionHooks.push(
          $transitionsProvider.onEnter({to: `${navItem.state.name}.**`}, (trans) =>
            hasAccessToNavItem(navItem, trans.injector(), trans).then(
              (result) => result || trans.router.stateService.target('not-found')
            )
          )
        );

        navItem.access.authorized = hasAccessToNavItem;
      }

      /**
       * @description Method to check if navigation route is accessible
       *   according to its configured callback function.
       * @param {Object} navItem - navigation definition Object
       * @param {UIInjector} injector - Object allowing injectable services to
       *   be accessed at runtime
       * @returns {Promise} resolves to true if navigation route is
       *   accessible, else resolves to false
       */
      function hasAccessByCallback(navItem, injector) {
        if (_.has(navItem, 'access.callback')) {
          return navItem.access.callback($q, injector);
        }
        return $q.resolve(true);
      }

      /**
       * @description Method to check if navigation route is accessible
       *   according to its configured params function.
       * @param {Object} navItem - navigation definition Object
       * @param {Transition} trans - Object representing the current
       *   transition taking place
       * @returns {Promise} resolves to true if navigation route is
       *   accessible, else resolves to false
       */
      function hasAccessByParams(navItem, trans) {
        if (_.has(navItem, 'access.params') && trans) {
          return navItem.access.params($q, trans);
        }
        return $q.resolve(true);
      }

      /**
       * @description Method to check if navigation route is accessible
       *   according to any configured roles the user must also possess.
       * @param {Object} navItem - navigation definition Object
       * @param {UIInjector} injector - Object allowing injectable services to
       *   be accessed at runtime
       * @returns {Promise} resolves to true if navigation route is
       *   accessible, else resolves to false
       */
      function hasAccessByRole(navItem, injector) {
        if (_.has(navItem, 'access.roles')) {
          const user = injector.get('AuthenticatedUser').get();
          const roles = user.getRoles();
          const orgList = injector.get('OrganizationList').get();
          const feature = injector.get('feature');
          try {
            const activeOrg = orgList.getActive();
            // allow access to admins in the org or adobe agents
            if (feature.isEnabled('temp_adobe_agent_access')) {
              const hasAccess =
                roles.anyOfForAdobeAgentOrg(navItem.access.roles) ||
                roles.anyOfForOrg(navItem.access.roles, activeOrg.id);
              return $q.resolve(hasAccess);
            }

            return $q.resolve(roles.anyOfForOrg(navItem.access.roles, activeOrg.id));
          } catch (error) {
            switch (error.message) {
              case ORGANIZATION_LIST.ERROR_CODE.ACTIVE_ORG_NOT_SET:
                // twosie does not set an active org, so use authenticated
                // user roles to determine access in this context
                return $q.resolve(roles.anyOf(navItem.access.roles));
              default:
                // uknown error encountered fetching active org, so return
                // false, since we can not determine access by role
                return $q.resolve(false);
            }
          }
        }
        return $q.resolve(true);
      }

      /**
       * @description Method to check whether or not a navigation route is
       *   accessible. Accessibility is determined by checking any configured
       *   logic bumpering methods for this navigation route, against the
       *   current transition (current user, etc).
       *
       *   Logic checks can be callback handlers, params checks, or role
       *   checks. Checks can be combined for a route, but at least one must
       *   exist in the navigation route definition, in order to set up/check
       *   any of these preconditions.
       *
       *   If an Error is caught in any of the access checks being performed,
       *   then access to this NavItem/state will be blocked for users (access
       *   denied).
       * @param {Object} navItem - navigation definition Object
       * @param {UIInjector} injector - Object allowing injectable services to
       *   be accessed at runtime
       * @param {Transition} trans - Object representing the current
       *   transition taking place
       * @returns {Promise} resolves to true if navigation route is
       *   accessible, else resolves to false
       */
      function hasAccessToNavItem(navItem, injector, trans) {
        try {
          return $q
            .all([
              hasAccessByCallback(navItem, injector),
              hasAccessByParams(navItem, trans),
              hasAccessByRole(navItem, injector),
            ])
            .then((hasAccess) => _.every(hasAccess))
            .catch((error) => {
              logError(error);
              return false;
            });
        } catch (error) {
          logError(error);
          return $q.resolve(false);
        }

        ////////

        function logError(err) {
          // the statement being disabled on the next line is never really
          // encountered and is only being disabled because there must be
          // something wrong with how this eslint plugin was written (boo)
          // eslint-disable-next-line angular/no-http-callback
          injector
            .get('$log')
            .error('nav-assembler: hasAccessToNavItem: access check error: ', err);
        }
      }

      /**
       * @description Method to detect if we need to attach state information to
       *   this navigation definition Object. State information is needed in
       *   configuration to attach references to transition hooks that may be
       *   created during configuration. If hooks are attached to a navigable
       *   route at runtime that bifurcated (split) by feature flags, then we
       *   need to be able to correctly remove the hooks meant for the route
       *   that has been deleted. Otherwise, hooks may compete and force
       *   mutually exclusive conditions to be true prior to resolving routes
       *   (i.e. - infinite loop).
       * @param {Object} navItem - navigation definition Object
       */
      function initializeTransitionHookStorage(navItem) {
        if (_.has(navItem, 'access') || _.get(navItem, 'cacheChildState', false)) {
          if (!_.has(navItem, 'state')) {
            navItem.state = {};
          }
          navItem.state.transitionHooks = [];
        }
      }
    }
    /**
     * @description Fetch the configured navigation lists.
     * @returns {Array} the registered navigation lists
     */
    function getNavigationLists() {
      return navigationLists;
    }

    /* @ngInject */
    function $get(feature, NavItem, NavItemList, navManager, stateManager) {
      return {buildNavigation};

      ////////////

      /**
       * @description Method to build navigation lists.
       * @returns {Promise} a Promise that resolves an array of promises on completion
       */
      function buildNavigation() {
        // Check for the existence of the dev_route_tracing flag, turning on
        // the ui-router trace feature, if flag resolves to true at runtime
        if (feature.isEnabled('dev_route_tracing')) {
          stateManager.enableTracing();
        }

        // initialize navigation lists
        _.forEach(navigationLists, initNavigationList);

        return navManager.updateVisibility();
      }

      function initNavigationList(navItemJsonArrayObj, navListKey) {
        let navigationList;
        if (navManager.exists(navListKey)) {
          navigationList = navManager.find(navListKey);
        } else {
          navigationList = new NavItemList();
        }

        _.forEach(navItemJsonArrayObj, (navItemJsonObj) => {
          // if no state defined in navigation item or state is not blocked
          if (
            !_.has(navItemJsonObj, 'state.definition') ||
            stateManager.isStateEnabled(navItemJsonObj.state.name, navItemJsonObj.state)
          ) {
            // create navigation item and add to navigation list
            addNavigation(navItemJsonObj);
          }
        });

        navManager.add(navListKey, navigationList);

        ////////

        function addNavigation(navItemJsonObj) {
          if (!_.has(navItemJsonObj, 'access') || !navBlockedByFeatureFlag(navItemJsonObj.access)) {
            navigationList.add(new NavItem(navItemJsonObj));
          }
        }

        function navBlockedByFeatureFlag(navItemJsonAccessObj) {
          return _.get(navItemJsonAccessObj, 'feature.omit')
            ? feature.isEnabled(_.get(navItemJsonAccessObj, 'feature.name'))
            : false;
        }
      }
    }
  }
})();
/* eslint-enable max-lines */
