const DIRECTORY_ENCRYPTION_PROGRESS = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  UNKNOWN: 'UNKNOWN',
};

const DIRECTORY_ENCRYPTION_STATUS = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  FAILED: 'FAILED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  REVOKED: 'REVOKED',
  UNKNOWN: 'UNKNOWN',
};

const DIRECTORY_EVENT = {
  DOMAIN_SYNCED: 'DomainSynced',
  UPDATE: 'UpdateDirectory',
};

const DIRECTORY_OWNERSHIP_STATUS = {
  OWNED: 'OWNED',
  TRUSTED: 'TRUSTED',
};

// the set of possible directory statuses returned by JIL vary depending on whether ownership status is OWNED or TRUSTED
const DIRECTORY_STATUS = {
  ACTIVE: 'ACTIVE', // (owned or trusted) either the directory is active (type 2 directories are always ACTIVE) or the directory is trusted
  NEEDS_DOMAIN: 'NEEDS_DOMAIN', // (owned) active directory not linked with any domain(s)
  PENDING: 'PENDING', // (trusted) awaiting Trust request approval/rejection
  REJECTED: 'REJECTED', // (trusted) rejected Trust request
  REQUIRES_ACTIVATION: 'REQUIRES_ACTIVATION', // (owned, type 3 only) directory has SAML configuration, now is ready for admin to activate it
  REQUIRES_APPROVAL: 'REQUIRES_APPROVAL', // (owned, type 3 only) newly created directory, awaiting Adobe approval
  REQUIRES_CONFIGURATION: 'REQUIRES_CONFIGURATION', // (owned, type 3 only) directory has been approved, now needs SAML configuration
  REVOKED: 'REVOKED', // (trusted) revoked Trust request
};

const DIRECTORY_TYPE = {
  TYPE2: 'TYPE2',
  TYPE2E: 'TYPE2E',
  TYPE3: 'TYPE3',
};

const IDP_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
};

const IDP_TYPES = {
  AZURE: 'AAD',
  OKTA: 'OKTA',
  SAML: 'GS', // Generic SAML
  SOIDC: 'SOIDC', // Static OpenID Connect
};

const DIRECTORY_DOMAIN_ENFORCEMENT_STATUS = {
  ACTIVATED: 'ACTIVATED', // Disables the creation of T1's accounts
  DEACTIVATED: 'DEACTIVATED', // Domain enforcement is disabled
  ENFORCED: 'ENFORCED', // Existing T1's forced to change email or switch to T3
  NOTIFIED: 'NOTIFIED', // Admins are notified. This is the beginning of the OPT-OUT Phase.
};

export {
  DIRECTORY_DOMAIN_ENFORCEMENT_STATUS,
  DIRECTORY_ENCRYPTION_PROGRESS,
  DIRECTORY_ENCRYPTION_STATUS,
  DIRECTORY_EVENT,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  IDP_STATUS,
  IDP_TYPES,
};
