import axios from 'axios';

import authentication from 'services/authentication';
import fraudMitigation from 'services/fraudMitigation/fraudMitigation';

let clientId, url;

/**
 * @description Configure the orders api
 *
 * @param {Object} config - Configuration object with properties described below
 * @param {String} config.clientId - The identifier for the application
 * @param {String} config.url - The base url for the orders service
 */
const configure = (config) => {
  ({clientId, url} = config);
};

/**
 * @description Builds the common request configurations of the app
 *
 * @returns {Object} request configuration object to pass into axios
 */
const getHeaders = () => {
  const authenticationToken = authentication.getAccessToken();
  const sherlockToken = fraudMitigation.getForterToken();

  return {
    Authorization: authenticationToken.token, // Bearer prefix not supported https://wiki.corp.adobe.com/display/CacheBack/Orders+V4+Auth+Token+Requirements
    'x-adobe-fingerprint-token': sherlockToken,
    'X-Api-Key': clientId,
  };
};

/**
 * @description Create an order
 *
 * @param {Object} orderDetails - Order configurations body
 *
 * @returns {Promise} - an axios post promise that resolves to the resolution of the create order call
 */
const createOrder = (orderDetails) =>
  axios.post(`${url}/v4/orders`, orderDetails, {headers: getHeaders()});

/**
 * @description Retrieve an order
 *
 * @param {String} orderId - the order ID of the order information to be fetched
 *
 * @returns {Promise} - an axios get promise that resolves to the order information of the order with order ID
 */
const fetchOrder = (orderId) => axios.get(`${url}/v4/orders/${orderId}`, {headers: getHeaders()});

/**
 * @description Search for an order
 *
 * @param {Object} searchParams -the query parameters used to search for an order.
 *                  Parameter Description:
 *                    [orderNumber] - Unique customer facing transaction number for the order
 *                    [type] -	Type describing the order (eg. NEW_ORDER or QUOTE or RENEWAL_ORDER, etc)
 *                    [status] -	Status describing the lifecycle of the order (eg. IN_PROGRESS or FULFILLED, etc)
 *                    [ownerId]	- IMS user guid if the order is associated to a user
 *                    [identityEmail] -	Email address on the order if the order is associated to a user email
 *                    [companyName] -	Company name on the order; Not implemented yet
 *                    [agentId] -	Agent id for the support agent creating the order; Not implemented yet
 *                    [convertedQuoteId] -	Original quote referring to the conversion; Not implemented yet
 *                    [since] -	Date filter for the order; Not implemented yet
 *                    [page] -	Page number indicating the pagination results.
 *                    [pageSize] -	Number of orders in the response. Default value is 10
 *
 * @returns {Promise} - an axios get promise with the order search results
 */
const searchOrders = (searchParams = {}) =>
  axios.get(`${url}/v4/orders`, {headers: getHeaders(), params: searchParams});

/**
 * @description Modify an existing order
 *
 * @param {Object} orderDetails - The order configuration object
 * @param {String} orderId - The ID of the order to be modified
 *
 * @returns {Promise} - an axios put promise that resolves with the updated order
 */
const updateOrder = (orderDetails, orderId) =>
  axios.put(`${url}/v4/orders/${orderId}`, orderDetails, {headers: getHeaders()});

/**
 * @description Cancel Purchase Authorization.
 *
 * @param {Object} cancelPurchaseAuthorizationDetails - cancel purchase authorization details
 *
 * @returns {Promise} - an axios put promise that resolves to the resolution of the cancel purchase authorization call
 */
const cancelPA = (cancelPurchaseAuthorizationDetails) =>
  axios.post(`${url}/v4/orders`, cancelPurchaseAuthorizationDetails, {headers: getHeaders()});

/**
 * @description Create Purchase Authorization.
 *
 * @param {Object} createPurchaseAuthorizationDetails - create purchase authorization details
 *
 * @returns {Promise} - an axios put promise that resolves to the resolution of the create purchase authorization call
 */
const createPA = (createPurchaseAuthorizationDetails) =>
  axios.post(`${url}/v4/orders`, createPurchaseAuthorizationDetails, {headers: getHeaders()});

const orders = {cancelPA, configure, createOrder, createPA, fetchOrder, searchOrders, updateOrder};
export default orders;
