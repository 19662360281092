import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import toString from 'lodash/toString';
import {action, makeObservable, observable} from 'mobx';

import {LICENSE_ALLOCATION_RESOURCE_TYPE} from './LicenseAllocationConstants';

class LicenseAllocationResourceList {
  items;
  /**
   * @description Creates a new LicenseAllocationResourceList for use.
   *
   * @param {Object[]} items License Resources which make up this list
   */
  constructor(items = []) {
    makeObservable(this, {
      items: observable,
      setFulfillableItemGrantQuantity: action,
      setLicenseGrantQuantity: action,
    });
    this.items = cloneDeep(items);
    this.registerSavedState();
  }

  /**
   * @description get the change in grant quantity from last saved state of this model
   *
   * @returns {Number} the change in grant quantity or NaN
   */
  getChangeInGrantQuantity() {
    const savedStateGrantQuantityIndex = getLicenseGrantQuantityIndex(this.savedState.items);
    return (
      this.getLicenseGrantQuantity() -
      Number.parseInt(this.savedState.items[savedStateGrantQuantityIndex].grant_quantity, 10)
    );
  }

  /**
   * @description get the quantity granted to the license for a particular FI.
   *
   * @param {String} name the fulfillable item code to fetch.
   *
   * @returns {Number|String|undefined} the amount of granted quota, UNLIMITED,
   *     or undefined if no resource was found.
   */
  getFulfillableItemGrantQuantity(name) {
    const fiResource = getFulfillableItemResource(this.items, name);
    return fiResource ? getGrantQuantityAsANumberOrUnlimitedString(fiResource) : undefined;
  }

  /**
   * @description get the saved state quantity granted to the license for a
   *     particular FI.
   *
   * @param {String} name the fulfillable item code to fetch.
   *
   * @returns {Number|String|undefined} the amount of granted quota, UNLIMITED,
   *     or undefined if no resource was found.
   */
  getFulfillableItemSavedStateGrantQuantity(name) {
    const fiResource = getFulfillableItemResource(this.savedState.items, name);
    return fiResource ? getGrantQuantityAsANumberOrUnlimitedString(fiResource) : undefined;
  }

  /**
   * @description Get the quantity granted to the license.
   *
   * @returns {Number|String} - The amount of granted licenses, or UNLIMITED.
   * @throws {Error} - Throws an error when no license resource is found.
   */
  getLicenseGrantQuantity() {
    const licenseResource = getLicenseResource(this.items);
    return getGrantQuantityAsANumberOrUnlimitedString(licenseResource);
  }

  /**
   * @description get the local quantity for the license resource.
   *
   * @return {String} - The local quantity or UNLIMITED.
   * @throws {Error} - Throws an error when no license resource is found.
   */
  getLicenseLocalQuantity() {
    const licenseResource = getLicenseResource(this.items);
    return licenseResource?.local_quantity;
  }

  /**
   * @description method to get the least possible assignable value for an FIs grant quantity.
   * @param {string} name - the fulfillable item code to fetch.
   * @returns {Number} Returns 1 if there are grant quantities matching the
   *     provided name.
   * @throws {Error} Throws an error when no grant quantities match the provided
   *     name.
   */
  getMinFulfillableItemGrantQuantity(name) {
    const grantQuantityIndex = getFIGrantQuantityIndex(this.items, name);
    const savedStateGrantQuantityIndex = getFIGrantQuantityIndex(this.savedState.items, name);
    if (grantQuantityIndex >= 0 && savedStateGrantQuantityIndex >= 0) {
      return 1;
    }
    throw new Error(`No fulfillable item grant quantities found for name: ${name}`);
  }

  /**
   * @description get the least possible assignable value for grant quantity
   *
   * @returns {Number} Returns 1 if there are license grant quantities.
   * @throws {Error} Throws an error if there are no license grant quantities.
   */
  getMinGrantQuantity() {
    const grantQuantityIndex = getLicenseGrantQuantityIndex(this.items);
    const savedStateGrantQuantityIndex = getLicenseGrantQuantityIndex(this.savedState.items);
    if (grantQuantityIndex >= 0 && savedStateGrantQuantityIndex >= 0) {
      return 1;
    }
    throw new Error('No license grant quantities were found');
  }

  /**
   * @description method to determine if there a particular FI grant quantity has changed.
   * @param {String} name - the fulfillable item code to fetch.
   * @returns {Boolean} true if there has been a change, false otherwise.
   */
  hasItemChanged(name) {
    return !isEqual(
      this.getFulfillableItemGrantQuantity(name),
      this.getFulfillableItemSavedStateGrantQuantity(name)
    );
  }

  /**
   * @description method to determine if there are unsaved changes to this list
   * @returns {Boolean} true if there are unsaved changes
   */
  hasUnsavedChanges() {
    return !isEqual(this.savedState, {
      items: this.items,
    });
  }

  /**
   * @description method to determine if there are unsaved FI changes to this list
   * @returns {Boolean} true if there are unsaved FI changes
   */
  hasUnsavedFIChanges() {
    const currentFIItems = this.items.filter(
      (item) => item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.FULFILLABLE_ITEM
    );
    const savedStateFIItems = this.savedState.items.filter(
      (item) => item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.FULFILLABLE_ITEM
    );
    return !isEqual(currentFIItems, savedStateFIItems);
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *  which maybe later modified
   */
  registerSavedState() {
    this.savedState = {
      items: cloneDeep(this.items),
    };
  }

  /**
   * @description Restore the model from its saved state
   */
  restore() {
    this.items = cloneDeep(this.savedState.items);
  }

  /**
   * @description Setter for the grant quantity for a particular FIs grant quantity.
   * @param {String} name - the fulfillable item code to fetch.
   * @param {Number} value - Updated value that will replace the existing.
   */
  setFulfillableItemGrantQuantity(name, value) {
    const grantQuantityIndex = getFIGrantQuantityIndex(this.items, name);
    this.items[grantQuantityIndex].grant_quantity = toString(value);
  }

  /**
   * @description Setter for the grant quantity for this model's grant
   *     quantity entry.
   * @param {Number} value - Updated value that will replace the existing.
   */
  setLicenseGrantQuantity(value) {
    const grantQuantityIndex = getLicenseGrantQuantityIndex(this.items);
    this.items[grantQuantityIndex].grant_quantity = toString(value);
  }
}

/**
 * @description Helper to obtain the index of the license resource for the
 *     requested fulfillable item.
 *
 * @param {Object[]} items - Items to search through.
 * @param {String} name - Name that corresponds to the fulfillable item to find.
 * @returns {Number} - Index of the target license resource or -1
 */
function getFIGrantQuantityIndex(items, name) {
  return items.findIndex(
    (item) => item.name === name && item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.FULFILLABLE_ITEM
  );
}

/**
 * @description Helper to obtain a particular fulfillable item resource of the
 *     license resource list.
 *     Note: this can be adjusted to throw an error once temp_port_fn_modal is
 *     validated and the angular source is removed.
 * @param {Object[]} items - Items to search through.
 * @param {String} name - Name that corresponds to the fulfillable item to find.
 * @returns {Object|undefined} - Resource that matches the fulfillable item name
 *     provided, or undefined if no resource is found.
 */
function getFulfillableItemResource(items, name) {
  return items.find(
    (item) => item.name === name && item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.FULFILLABLE_ITEM
  );
}

/**
 * @description Helper to get the grant quantity as a number if it can be parsed
 *     as a number, or a string of 'UNLIMITED' otherwise.
 * @param {Object} resource
 * @returns {Number|String} Number if the grant quantity is parsed as a number,
 *     or a string if the value is 'UNLIMITED'.
 */
function getGrantQuantityAsANumberOrUnlimitedString(resource) {
  const grantQuantityAsNumber = Number.parseInt(resource.grant_quantity, 10);
  return Number.isNaN(grantQuantityAsNumber) ? resource.grant_quantity : grantQuantityAsNumber;
}

/**
 * @description Helper to obtain the license type resource index in the items array.
 *
 * @param {Object[]} items - Items to search through.
 * @returns {Number} - Index of the license resource or -1.
 */
function getLicenseGrantQuantityIndex(items) {
  return items.findIndex((item) => item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.LICENSE);
}

/**
 * @description Helper to obtain the license resource of the license resource
 *     list items.
 * @param {Object[]} items - Items to search through.
 * @returns {Object} - License resource with LICENSE type.
 * @throws {Error} - Throws an error when no license resource is found.
 */
function getLicenseResource(items) {
  const licenseResource = items.find(
    (item) => item.type === LICENSE_ALLOCATION_RESOURCE_TYPE.LICENSE
  );
  if (!licenseResource) {
    throw new Error('No license resource found');
  }
  return licenseResource;
}

export default LicenseAllocationResourceList;
