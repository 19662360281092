/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name binky.widgets.directories:binkyDirectoryType
 *
 * @description Defines the directory type.
 *
 * @param {String} [align]. The alignment of the directory type label and image. "Verical" alignment is optional, defaults to "horizontal".
 * @param {String} [case]. Sets directory type to be displayed in either all lowercase or uppercase. Defaults to "lowercase".
 * @param {String} type. The type of directory. Can be one of either "TYPE2" (EnterpriseId) or "TYPE3" (FederatedId).
 */

(function () {
  'use strict';
  angular.module('binky.widgets.directories.directory-type').component('binkyDirectoryType', {
    bindings: {
      align: '@?',
      case: '@?',
      type: '@',
    },
    templateUrl: 'widgets/directories/directory-type/directory-type.component.html',
  });
})();
