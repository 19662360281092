(function () {
  /**
   * @deprecated Please use src2/core/models/user/userRoleUtils.js
   *
   * @ngdoc factory
   * @name UserRoles
   * @description Model for the UserRoleUtils which enables patches, comparisons between roles, etc.
   */
  angular.module('binky.core.user.roles').factory('userRoleUtils', getUserRoleUtilsModel);

  function getUserRoleUtilsModel(_, feature, USER_ROLE) {
    class UserRoleUtils {
      /**
       * @description Compare two exploded roles.
       *
       * @param {UserRole} itm1 reference to the first UserRole
       * @param {UserRole} itm2 reference to the UserRole to compare to
       * @returns {Boolean} true if the two items are equal
       */
      static compareAdminRoles(itm1, itm2) {
        if (itm1.type !== itm2.type) {
          return false;
        } else if (
          itm1.type === USER_ROLE.LICENSE_ADMIN ||
          itm1.type === USER_ROLE.LICENSE_DEV_ADMIN
        ) {
          return itm1.targetId === itm2.targetId && itm1.targetParentId === itm2.targetParentId;
        } else if (
          itm1.type === USER_ROLE.CONTRACT_ADMIN ||
          itm1.type === USER_ROLE.PRODUCT_ADMIN ||
          (feature.isEnabled('temp_parkour_mm') && itm1.type === USER_ROLE.PRODUCT_SUPPORT_ADMIN) ||
          itm1.type === USER_ROLE.USER_GROUP_ADMIN
        ) {
          return itm1.targetId === itm2.targetId;
        }
        // if it's any other admin type, there's nothing else to compare
        return true;
      }

      /**
       * @description Explode the roles and their targets into individual roles for easier comparison
       *
       * @param {Array} roles the set of roles to explode
       * @returns {Array} the exploded array of roles
       */
      static explodeAdminRoles(roles) {
        // we explode the role list to make comparisons simpler
        let explodedRoles = [];
        _.forEach(roles, (role) => {
          explodedRoles = _.union(explodedRoles, explodeRole(role));
        });
        return explodedRoles;

        function explodeRole(role) {
          if (role.type === USER_ROLE.LICENSE_ADMIN || role.type === USER_ROLE.LICENSE_DEV_ADMIN) {
            return _.map(role.targets, (target) => ({
              targetId: target.id,
              targetParentId: target.parentId,
              type: role.type,
            }));
          } else if (
            role.type === USER_ROLE.CONTRACT_ADMIN ||
            role.type === USER_ROLE.PRODUCT_ADMIN ||
            (feature.isEnabled('temp_parkour_mm') && role.type === USER_ROLE.PRODUCT_SUPPORT_ADMIN)
          ) {
            return _.map(role.targets, (target) => ({
              targetId: target.id,
              type: role.type,
            }));
          } else if (role.type === USER_ROLE.USER_GROUP_ADMIN) {
            return _.map(role.targets, (target) => ({
              targetId: target.id,
              type: role.type,
            }));
          }
          return [role];
        }
      }

      /**
       * @description Helper method to construct the patches for Admin Role changes to be saved.
       *
       * @param {OrganizationUser} model reference to OrganizationUser model
       * @returns {Array} the set of patches to be performed
       */
      static getAdminRolePatches(model) {
        const explodedRoles = UserRoleUtils.explodeAdminRoles(model.roles);
        const explodedSavedRoles = UserRoleUtils.explodeAdminRoles(model.savedState.roles);
        const addedRoles = _.differenceWith(
          explodedRoles,
          explodedSavedRoles,
          UserRoleUtils.compareAdminRoles
        );
        const removedRoles = _.differenceWith(
          explodedSavedRoles,
          explodedRoles,
          UserRoleUtils.compareAdminRoles
        );
        const addOperations = _.map(addedRoles, (addedRole) => ({
          op: 'add',
          path: `/${model.id}/roles/${getRoleTarget(addedRole)}`,
        }));
        const removeOperations = _.map(removedRoles, (removedRole) => ({
          op: 'remove',
          path: `/${model.id}/roles/${getRoleTarget(removedRole)}`,
        }));
        return _.union(addOperations, removeOperations);

        function getRoleTarget(role) {
          let suffix = '';
          if (role.type === USER_ROLE.LICENSE_ADMIN || role.type === USER_ROLE.LICENSE_DEV_ADMIN) {
            suffix = `/${role.targetId}/product/${role.targetParentId}`;
          } else if (
            role.type === USER_ROLE.CONTRACT_ADMIN ||
            role.type === USER_ROLE.PRODUCT_ADMIN ||
            (feature.isEnabled('temp_parkour_mm') &&
              role.type === USER_ROLE.PRODUCT_SUPPORT_ADMIN) ||
            role.type === USER_ROLE.USER_GROUP_ADMIN
          ) {
            suffix = `/${role.targetId}`;
          }
          return `${role.type}${suffix}`;
        }
      }
    }
    return UserRoleUtils;
  }
})();
