(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableSummary.js
   *
   * @ngdoc factory
   * @name ConsumableSummary
   * @description Model for an individual ConsumableSummary
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Summary
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableSummary', getConsumableSummaryModel);

  /* @ngInject */
  function getConsumableSummaryModel(_, ConsumableList) {
    class ConsumableSummary {
      /**
       * @description Creates a new ConsumableSummary for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.id  "value" of dimension this is a summary of
       *            i.e. for summarization by "fulfillment_id," this would be a specific fulfillment id
       *            i.e. for summarization by "license_id," this would be a specific license id
       * @param {Array} options.consumables array of Consumable entities, each is a Consumable summary for a
       *            Fulfillable Item associated with the dimension value
       * @param {ConsumableSummarization} parentSummarization the ConsumableSummarization that contains this Summary
       */
      constructor(options, parentSummarization) {
        this.parentSummarization = parentSummarization;
        initModel(this, options);
      }

      /**
       * @description Find any Consumable objects that are for the specified fulfillable item code
       *
       * @param {String} fiCode the fulfillable item code to find summaries for
       *
       * @returns {Array} List of Consumables that are for the fulfillable item code
       */
      getConsumablesForFulfillableItemCode(fiCode) {
        return this.consumableList.getConsumablesForFulfillableItemCode(fiCode);
      }

      /**
       * @description Get the top-level query params used when fetching this summary
       *
       * @returns {Object} Object of params used in the query to get this summary (provided by parent)
       */
      getTopLevelQueryParams() {
        return this.parentSummarization.getTopLevelQueryParams();
      }

      /**
       * @description Tests to see if this summary is for a given ID
       *
       * @param {String} id The ID to test for
       *
       * @returns {Boolean} true if this summary is for the given ID
       */
      isSummaryForId(id) {
        return this.id === id;
      }

      /**
       * @class
       * @description Transforms the /consumable summary response Object into a new ConsumableSummary
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @param {ConsumableSummarization} parentSummarization parent options (see constructor)
       */
      static apiResponseTransformer(dataTransferObject, parentSummarization) {
        return new ConsumableSummary(dataTransferObject, parentSummarization);
      }
    }

    //////////////

    function initModel(model, options) {
      // First we assign the model fields, from our data object and the parent params provided
      _.assignIn(model, _.pick(options, ['id']));

      model.consumableList = new ConsumableList(options.consumables, model);
    }

    return ConsumableSummary;
  }
})();
