// Client for the IMS service
// https://wiki.corp.adobe.com/display/ims/Endpoints+Index

import axios from 'axios';

import authentication from 'services/authentication';
import Locale from 'services/locale/Locale';

let baseUrl, clientId;

function getOptions(headers = {}) {
  const options = {
    headers: {
      'X-Api-Key': clientId,
      ...headers,
    },
    timeout: 5000,
  };

  const token = authentication.getAccessToken();
  if (token && token.token) {
    options.headers.Authorization = `Bearer ${token.token}`;
  }
  return options;
}

/**
 * Accept the terms and conditions content for a specific service and org
 * See: https://wiki.corp.adobe.com/display/imsArchive/IMS+API+-+accept+tc
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.orgId - The GUID for the org accepting terms content
 * @param {string} queryParams.serviceName - The service to accept T&C content for
 * @return {Promise} a promise which will resolve with the response
 */
const acceptTermsAndConditions = (queryParams) => {
  const {orgId, serviceName} = queryParams;
  return axios.get(
    `${baseUrl}/accept_tc/v1?auth_src=AdobeOrg&client_id=${clientId}&org_id=${orgId}&service_name=${serviceName}`,
    getOptions()
  );
};
/**
 * Set environment and application level context for IMS
 * @param {Object} config - A configuration object.
 * @param {string} config.url - The root url for the IMS service api
 * @param {string} config.clientId - The identifier for the consuming application
 */
const configure = (config) => {
  baseUrl = config.url;
  clientId = config.clientId;
};
/**
 * Fetch the IMS avatar information. This returns a JSON objection with a 'location'
 * property to find the avatar, or 400 if none exists.
 * See: https://wiki.corp.adobe.com/display/ims/IMS+User+Avatars#IMSUserAvatars-GetPublicImage
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {Number} queryParams.height - The height of avatar to request
 * @param {string} queryParams.userId - The user ID to fetch an avatar for
 * @param {Number} queryParams.width - The width of avatar to request
 * @return {Promise} a promise which will resolve with the response
 */
const getAvatar = (queryParams) => {
  const {height, userId, width} = queryParams;
  let url = `${baseUrl}/avatar/v1?client_id=${clientId}&user_id=${userId}`;
  if (height) url = `${url}&height=${height}`;
  if (width) url = `${url}&width=${width}`;
  return axios.get(url, getOptions());
};
/**
 * Fetch the list of supported countries based on locale.
 * See: https://wiki.corp.adobe.com/display/imsArchive/IMS+API+-+countries
 *
 * @param {Object} locale - The locale to use for this call
 * @return {Promise} a promise which will resolve with the response
 */
const getCountries = (locale = Locale.get().activeLocaleCode) =>
  axios.get(`${baseUrl}/countries/v1?client_id=${clientId}&locale=${locale}`, getOptions());

/**
 * Fetch the list of supported regions for a specified country.
 * See: https://wiki.corp.adobe.com/display/imsArchive/IMS+API+-+regions
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.countryCode - The two character code for the country. For example 'US'
 * @param {string} queryParams.locale - country locale
 * @return {Promise} a promise which will resolve with the response
 */
const getRegions = (queryParams) => {
  const {countryCode, locale = Locale.get().activeLocaleCode} = queryParams;
  return axios.get(
    `${baseUrl}/regions/v1?client_id=${clientId}&country_code=${countryCode}&locale=${locale}`,
    getOptions()
  );
};
/**
 * Fetch the acceptance state of terms and conditions content for a specific service and org
 * See: https://wiki.corp.adobe.com/display/imsArchive/IMS+API+-+query+tc
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.orgId - The GUID for the org accepting terms content
 * @param {string} queryParams.serviceName - The service to accept T&C content for
 * @return {Promise} a promise which will resolve with the response
 */
const getTermsAndConditionsAcceptance = (queryParams) => {
  const {orgId, serviceName} = queryParams;
  return axios.get(
    `${baseUrl}/query_tc/v1?auth_src=AdobeOrg&client_id=${clientId}&org_id=${orgId}&service_name=${serviceName}`,
    getOptions()
  );
};
/**
 * Fetch the terms and conditions content for a specific service
 * See: https://wiki.corp.adobe.com/display/ims/IMS+API+-+fetch+tc
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.serviceName - The service to fetch T&C content for
 * @return {Promise} a promise which will resolve with the response
 */
const getTermsAndConditionsContent = (queryParams) => {
  const {serviceName} = queryParams;
  return axios.get(
    `${baseUrl}/fetch_tc/v1?client_id=${clientId}&locale=${
      Locale.get().activeLocaleCode
    }&service_name=${serviceName}`,
    getOptions()
  );
};
/**
 * Redirect through IMS to fetch a token for the target clientId
 * See: https://wiki.corp.adobe.com/display/ims/IMS+API+-+jumptoken
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.targetClientId - The client ID to end up in
 * @param {string} queryParams.targetRedirectUri - The URL to redirect to
 * @param {string} queryParams.targetScope - The scopes to ask for in the jump
 * @return {Promise} a promise which will resolve with the response
 */
const postJumpToken = (queryParams) => {
  const {targetClientId, targetRedirectUri, targetScope} = queryParams;
  const searchParams = new URLSearchParams();
  searchParams.append('client_id', clientId);
  searchParams.append('locale', Locale.get().activeLocaleCode);
  searchParams.append('target_client_id', targetClientId);
  searchParams.append('target_redirect_uri', targetRedirectUri);
  searchParams.append('target_scope', targetScope);
  return axios.post(`${baseUrl}/jumptoken/v1`, searchParams, getOptions());
};

const ims = {
  acceptTermsAndConditions,
  configure,
  getAvatar,
  getCountries,
  getRegions,
  getTermsAndConditionsAcceptance,
  getTermsAndConditionsContent,
  postJumpToken,
};
export default ims;
