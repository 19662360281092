import binky from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import DateRange from 'common/components/date-range/DateRange';

const insightsUtils = binky.services.insights.insightsUtils;

/**
 * InsightsDateRange - a widget for selecting date range when creating insights item detail.
 */
const InsightsDateRange = ({content, onChangeDateRange}) => {
  const intl = useIntl();
  const {defaults, ...dateRangeContent} = content;
  const maxDate = insightsUtils.getMaxDate(dateRangeContent);
  const minDate = insightsUtils.getMinDate(dateRangeContent);
  const defaultValue = [insightsUtils.getMinDate(defaults), insightsUtils.getMaxDate(defaults)];

  return (
    <DateRange
      defaultValue={defaultValue}
      disabled={!minDate}
      label={content.title || intl.formatMessage({id: content.titleKey})}
      max={maxDate}
      min={minDate}
      onChangeDateRange={onChangeDateRange}
    />
  );
};

InsightsDateRange.propTypes = {
  /**
   * Date range content of creating insights item detail which includes maxDate/maxDateFromNow,
   * minDate/minDateFromNow and title/titleKey. maxDate and minDate are date strings in YYYY-MM-DD.
   */
  content: PropTypes.shape({
    defaults: PropTypes.shape({
      maxDate: PropTypes.string,
      maxDateFromNow: PropTypes.number,
      minDate: PropTypes.string,
      minDateFromNow: PropTypes.number,
    }),
    maxDate: PropTypes.string,
    maxDateFromNow: PropTypes.number,
    minDate: PropTypes.string,
    minDateFromNow: PropTypes.number,
    title: PropTypes.string,
    titleKey: PropTypes.string, // keys are prefixed with 'binky.widgets.insights'.
  }).isRequired,
  /**
   * Handler that is called when the DateRange is selected. The param is an object with start and end keys
   * whose values are date strings in YYYY-MM-DD.
   */
  onChangeDateRange: PropTypes.func.isRequired,
};

export default InsightsDateRange;
