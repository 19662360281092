import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import differenceWith from 'lodash/differenceWith';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import set from 'lodash/set';

/**
 * @description Sorts the input array (by the given key name if provided)
 * with the provided collator.
 *
 * @param {Intl.Collator} collator the Intl.Collator object returned from
 *         useCollator from React Spectrum/React Spectrum Aria
 *
 * @param {Array} inputArr the array to be sorted
 * @param {String} [key] the field to sort by
 * @returns {Array} the sorted input array
 */
function collatorSort({collator, inputArr, key}) {
  if (key) {
    return inputArr.sort((a, b) => collator.compare(a[key], b[key]));
  }
  return inputArr.sort((a, b) => collator.compare(a, b));
}

/**
 * @description Compare two arrays.
 *
 * @param {Array} arr1 reference to the first array
 * @param {Array} arr2 reference to the array to compare to
 * @param {Function} comparator the function to use to compare the two arrays
 * @returns {Boolean} true if the two items are equal
 */
function compareArrays(arr1, arr2, comparator) {
  return (
    differenceWith(arr1, arr2, comparator).length === 0 &&
    differenceWith(arr2, arr1, comparator).length === 0
  );
}

/**
 * Converts an object's keys to camelCase, for normalization
 *
 * @param {Object} object - the object whose keys you want converted
 * @returns {Object} new object with keys in camelCase
 */
function convertObjectKeysToCamelCase(object) {
  return mapKeys(object, (value, key) => camelCase(key));
}

/**
 * Converts an object's keys to kebab-case, for normalization
 *
 * @param {Object} object - the object whose keys you want converted
 * @returns {Object} new object with keys in kebab-case
 */
function convertObjectKeysToKebabCase(object) {
  return mapKeys(object, (value, key) => kebabCase(key));
}

/**
 * @description Gets a list of an object's nested keys.
 *
 * {
 *   a: {
 *     b: 'value1'
 *   },
 *   c: 'value 2'
 * }
 *
 * => ['a.b', 'c']
 *
 * @param {Object} object the object to get the keys for
 * @param {String} [prefix] a prefix to prepend to each result
 * @param {Object} [options] additional options
 * @param {Boolean} [options.excludeArrayValue] a boolean value to determine whether an array should be flattened or not
 * @returns {Array<String>} an array of the object's nested keys
 */
function flattenKeys(object, prefix = '', options = {}) {
  return flatMap(object, (value, key) => {
    const prefixAndKey = `${prefix}${key}`;
    const isDate = Object.prototype.toString.call(value) === '[object Date]';
    if (isObject(value) && !isDate && !(options.excludeArrayValue && Array.isArray(value))) {
      return flattenKeys(value, `${prefixAndKey}.`, options);
    }
    return prefixAndKey;
  });
}

/**
 * Separates the specified string into one or more strings by splitting on commas and
 * whitespace. For example, given the string:
 *
 *   we all    live
 *     in,a,,,yellow
 *
 *       submarine
 *
 * This function would return:
 *
 *   ['we','all','live','in','a','yellow','submarine']
 *
 * @param {String} str - the string to split
 * @param {Object} options - options for the split - see below
 * @param {Boolean} options.removeDupes - if true, remove duplicate items from the list
 * @returns {Array} the array of split strings
 */
function splitList(str, options = {}) {
  let retVal = compact(str.split(/[\s,]+/g));
  if (options.removeDupes) {
    retVal = [...new Set(retVal)];
  }
  return retVal;
}

/**
 * Translate one JSON object into another based on `dataMap` param.
 * Example usage:
 *   const resultObj = {};
 *   const sourceObj = {
 *     assignedLicense: 3,
 *     availableLicense: 5,
 *     detail: {
 *       product_name: 'Amazing Product',
 *       product_short_name: 'Amazing'
 *     },
 *     offer_id: 'offer123',
 *     selected: 6
 *   };
 *   const dataMapper = {
 *     assignedLicense: 'license.assigned',
 *     availableLicense: 'license.available',
 *     detail: {
 *       product_name: 'product.name',
 *       product_short_name: 'product.abbr'
 *     },
 *     offer_id: 'product.offerId',
 *     selected: 'license.cart'
 *   };
 *   jsonTranslator(sourceObj, resultObj, dataMapper);
 *
 *   Afterward, resultObj is:
 *     {
 *        license: {
 *          assigned: 3,
 *          available: 5,
 *          cart: 6
 *        },
 *        product: {
 *          abbr: 'Amazing',
 *          name: 'Amazing Product',
 *          offerId: 'offer123'
 *        }
 *     }
 *
 * @param {Object} sourceObj Source object data
 * @param {Object} destObj Destination object data
 * @param {Object} dataMap The mapping from sourceObj into destObj (see example above)
 *
 */
function translateJson(sourceObj, destObj, dataMap) {
  const flattenDataMap = flattenKeys(dataMap);
  flattenDataMap.forEach((sourceObjKey) => {
    const destPropPath = get(dataMap, sourceObjKey);
    const value = get(sourceObj, sourceObjKey);
    if (value !== undefined) {
      set(destObj, destPropPath, value);
    }
  });
}

export {
  collatorSort,
  compareArrays,
  convertObjectKeysToCamelCase,
  convertObjectKeysToKebabCase,
  flattenKeys,
  splitList,
  translateJson,
};
