(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name SizeWatcher
   * @description Model for SizeWatcher Objects
   */
  angular.module('binky.widgets.common.watch-size').factory('SizeWatcher', getSizeWatcher);

  /* @ngInject */
  function getSizeWatcher($interval, _) {
    /**
     * @class
     * @description Simple model/service to track element dimensions.
     */
    class SizeWatcher {
      /**
       * @constructs SizeWatcher
       * @description Constructor for SizeWatcher model Objects.
       * @param {Object} options - SizeWatcher instance settings
       * @param {Number} [options.count] - optional; number of times to run (default is infinite)
       * @param {Element} options.element - element reference to watch size of
       * @param {Scope} options.msgId - ID to use when emitting messages
       * @param {Number} options.rate - refresh rate of watcher (in ms)
       * @param {Scope} options.scope - scope to send messages up (emit)
       */
      constructor(options) {
        _.defaults(this, _.pick(options, ['count', 'element', 'msgId', 'rate', 'scope']), {
          count: 0,
        });

        this.monitor = $interval(_.bind(this.update, this), this.rate, this.count, false);
      }

      /**
       * @description Method to cancel the internal watch/polling mechanism for
       *   this model. This must be called when the scope this model is attached
       *   to is destroyed.
       * @returns {undefined} no return value
       */
      cancel() {
        $interval.cancel(this.monitor);
      }

      /**
       * @description Method to update the internal dimensions attributes.
       * @returns {undefined} no return value
       */
      update() {
        const boundingClientRect = this.element.getBoundingClientRect();
        const currentDimensions = [
          _.get(boundingClientRect, 'height', 0),
          _.get(boundingClientRect, 'width', 0),
        ];
        if (!_.isEqual(this.dimensions, currentDimensions)) {
          this.dimensions = currentDimensions;
          this.scope.$emit(this.msgId, {
            height: this.dimensions[0],
            width: this.dimensions[1],
          });
        }
      }
    }

    return SizeWatcher;
  }
})();
