import jilDirectories from 'api/jil/jilDirectories';
import log from 'services/log';

class Directory {
  /**
   * @description Method to retrieve an existing Directory from
   *              back-end data store.
   *
   * @param {Object} options options for the Directory. See the construction options.
   * @returns {Promise<Directory>} A promise which resolves the the fetched Directory.
   */
  static get(options) {
    const model = new Directory(options);
    // Caching would be handled here
    return model.refresh();
  }

  /**
   * @class
   * @description Creates a new Directory model.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.id The id of the directory.
   * @param {String} options.orgId The id of the organization.
   */
  constructor({id, orgId}) {
    this.id = id; // the API uses id not directoryId
    this.orgId = orgId;
  }

  async refresh() {
    let response;

    try {
      response = await jilDirectories.getDirectoryDetails({
        directoryId: this.id,
        orgId: this.orgId,
      });
    } catch (error) {
      log.error(`Directory.refresh() failed. Error: ${error}`);
      return Promise.reject(error);
    }

    Object.assign(this, response.data);

    return this;
  }
}

export default Directory;
