(function () {
  /**
   * @deprecated Please use src2/core/models/user/linked-accounts/LinkedUserAccount.js
   *
   * @ngdoc factory
   * @name LinkedUserAccount
   * @description Model for a linked user account
   */
  angular.module('binky.core.user.linked-accounts').factory('LinkedUserAccount', getModel);

  /* @ngInject */
  function getModel(binkySrc2) {
    return binkySrc2.models.user.LinkedUserAccount;
  }
})();
