(function () {
  /**
   * @deprecated Please use src2/core/utils/domUtils.js
   */
  angular.module('binky.core.dom-utils').factory('domUtils', domUtils);

  /* @ngInject */
  function domUtils($document, $q) {
    const service = {
      htmlDecode,
      loadCss,
      loadEl,
      loadJs,
    };

    return service;

    ////////

    /**
     * @description HTML-decodes the specified string and returns the resulting string
     * @param {String} html - the string to HTML-decode
     * @returns {String} the decoded string
     */
    function htmlDecode(html) {
      const txt = $document[0].createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    /**
     * @description Loads the specified CSS file by appending a <link> element to the DOM with the
     *   specified href
     * @param {String} href - the URL of the CSS file to load
     * @returns {Promise} a promise which resolves with the underlying load DOM event when the file
     *   has loaded, or rejects with the error DOM event if an error occured
     */
    function loadCss(href) {
      const el = angular
        .element('<link>')
        .attr('href', href)
        .attr('rel', 'stylesheet')
        .attr('type', 'text/css');
      return this.loadEl(el);
    }

    /**
     * @description Loads the specified element by appending it to the DOM
     * @param {JQLite} el - the element to load
     * @returns {Promise} a promise which resolves with the underlying load DOM event when the
     *   element has loaded, or rejects with the error DOM event if an error occured
     */
    function loadEl(el) {
      return $q((resolve, reject) => {
        el.one('load', resolve);
        el.one('error', reject);
        $document.find('head').append(el);
      });
    }

    /**
     * @description Loads the specified JS file by appending a <script> element to the DOM with the
     *   specified src
     * @param {String} src - the URL of the JS file to load
     * @returns {Promise} a promise which resolves with the underlying load DOM event when the file
     *   has loaded, or rejects with the error DOM event if an error occured
     */
    function loadJs(src) {
      const el = angular.element('<script>').attr('src', src);
      return this.loadEl(el);
    }
  }
})();
