/* eslint-disable complexity -- moving up*/
import {CONTRACT_LEGACY_DX_ID} from '@admin-tribe/binky';

/**
 * @description get a human readable string representing the type of a contract
 * @param {Contract} contract the contract
 * @param [Object] options
 * @param [Boolean] options.abbreviate when true, returns the short form
 * @returns {String} human readable string representing the contract type
 */
function getContractDisplayName(intl, contract, {abbreviate = false} = {}) {
  let contractType;
  if (contract.id === CONTRACT_LEGACY_DX_ID) {
    contractType = 'dxVirtual';
  } else if (contract.isModelAllocation()) {
    contractType = 'allocation';
  } else if (contract.isModelClassroom()) {
    contractType = 'classroom';
  } else if (contract.isEnterpriseContract()) {
    contractType = contract.isBuyingProgramEnterpriseProduct() ? 'dx' : 'etla';
  } else if (contract.isIndirectContract()) {
    contractType = contract.isBuyingProgramVIPMP() ? 'vipMarketplace' : 'vip';
  } else if (contract.isDirectContract()) {
    contractType = 'teamDirect';
  } else if (contract.isTrial()) {
    contractType = 'trial';
  } else {
    contractType = 'unsupported';
  }
  return intl.formatMessage({
    id: `binky.contract.displayName.${abbreviate ? 'short.' : ''}${contractType}`,
  });
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getContractDisplayName};
/* eslint-enable complexity -- moving up*/
