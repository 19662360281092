(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc       module
   * @name        observabilityReadyProvider
   * @description App-ready module to initiate observability
   */

  angular
    .module('binky.core.observability.ready')
    .provider('observabilityReady', observabilityReadyProvider);

  /* @ngInject */
  function observabilityReadyProvider(_, configurationReadyProvider) {
    this.$get = $get;
    this.configure = configure;

    let observabilityConfig;

    ////////////

    function configure() {
      return configurationReadyProvider.whenConfigReady().then((configData) => {
        observabilityConfig = _.get(configData, 'services.observability');
      });
    }

    /* @ngInject */
    function $get($log, $q, binkySrc2, featureReady) {
      const runDeferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        return (
          // Need featureReady to check for bumper_observability
          featureReady
            .whenRunReady()
            .then(initialize)
            .catch((error) => {
              $log.warn(`Error configuring observability: ${error}`);
            })
            // Use 'finally' so the host app doesn't stop loading when there's something wrong with initialization
            .finally(runDeferred.resolve)
        );
      }

      function initialize() {
        binkySrc2.services.observability.observabilityMetrics.initialize(observabilityConfig);
        return $q.resolve();
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
