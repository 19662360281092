// eslint-disable-next-line filenames/match-exported -- context
import PropTypes from 'prop-types';
import React, {useContext, useMemo} from 'react';

/**
 * The UserPicker context for passing in various permissions and values
 */
const UserPickerContext = React.createContext({});
const useUserPickerContext = () => useContext(UserPickerContext);

/**
 * A provider that verifies the required props for UserPicker are passed in
 */
const UserPickerContextProvider = ({canAddUser, children}) => {
  const value = useMemo(() => ({canAddUser}), [canAddUser]);
  return <UserPickerContext.Provider value={value}>{children}</UserPickerContext.Provider>;
};

UserPickerContextProvider.propTypes = {
  // Whether the current admin can add users to the org.
  canAddUser: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export {useUserPickerContext};
export default UserPickerContextProvider;
