const USER_GROUP_EVENT = {
  CREATE: 'UserGroupCreate',
  UPDATE: 'UserGroupUpdate',
};
const USER_GROUP_THRESHOLD_GO_URL = {
  PRODUCT: 'ac_product_threshold',
  PRODUCT_CONFIGURATION: 'ac_product_configuration_threshold',
  USER_GROUP: 'ac_user_group_threshold',
};
const USER_GROUP_USER_COUNT_THRESHOLD = {
  ERROR: 200000,
  WARNING: 75000,
};

export {USER_GROUP_EVENT, USER_GROUP_THRESHOLD_GO_URL, USER_GROUP_USER_COUNT_THRESHOLD};
