const DEFAULT_LANGUAGE = 'en';
// @deprecated - will be removed when all usages are removed
const LANGUAGE_BCP47_MAP = {
  bg: 'bg',
  cs: 'cs',
  da: 'da',
  de: 'de',
  el: 'el',
  en: 'en',
  es: 'es',
  et: 'et',
  fi: 'fi',
  fr: 'fr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ja: 'ja',
  kk: 'kk',
  ko: 'ko',
  lt: 'lt',
  lv: 'lv',
  ms: 'ms',
  nb: 'nb',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sl: 'sl',
  sv: 'sv',
  th: 'th',
  tr: 'tr',
  uk: 'uk',
  vi: 'vi',
  'zh-cn': 'zh-Hans',
  'zh-tw': 'zh-Hant',
};
const LANGUAGE_COUNTRY_MAP = {
  bg: 'bg',
  cs: 'cz',
  da: 'dk',
  de: 'de',
  el: 'gr',
  en: 'en',
  es: 'es',
  et: 'ee',
  fi: 'fi',
  fr: 'fr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ja: 'jp',
  kk: 'kk',
  ko: 'kr',
  lt: 'lt',
  lv: 'lv',
  ms: 'my',
  nb: 'no',
  nl: 'nl',
  pl: 'pl',
  pt: 'br',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sl: 'si',
  sv: 'se',
  th: 'th',
  tr: 'tr',
  uk: 'ua',
  vi: 'vn',
  'zh-cn': 'cn',
  'zh-tw': 'tw',
};
// the storage key for the selected locale
const SELECTED_LOCALE_KEY = 'selectedLocale';
// BCP47 language codes as per https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1387827668
// Only those languages whose value differs from their BCP47 language tag will have a bcp47Code property.
const SUPPORTED_LANGUAGES = [
  {
    dateFnsLocale: 'cs',
    label: 'Čeština',
    locale: 'cs_CZ',
    value: 'cs',
  },
  {
    dateFnsLocale: 'da',
    label: 'Dansk',
    locale: 'da_DK',
    value: 'da',
  },
  {
    dateFnsLocale: 'de',
    label: 'Deutsch',
    locale: 'de_DE',
    value: 'de',
  },
  {
    dateFnsLocale: 'en-US',
    label: 'English',
    locale: 'en_US',
    value: 'en',
  },
  {
    dateFnsLocale: 'es',
    label: 'Español',
    locale: 'es_ES',
    value: 'es',
  },
  {
    dateFnsLocale: 'fr',
    label: 'Français',
    locale: 'fr_FR',
    value: 'fr',
  },
  {
    dateFnsLocale: 'kk',
    // used for storing string ids for localization testing
    hidden: true, // only selectable via query param
    label: 'String ID',
    locale: 'kk_KZ',
    value: 'kk',
  },
  {
    dateFnsLocale: 'it',
    label: 'Italiano',
    locale: 'it_IT',
    value: 'it',
  },
  {
    dateFnsLocale: 'nl',
    label: 'Nederlands',
    locale: 'nl_NL',
    value: 'nl',
  },
  {
    dateFnsLocale: 'nb',
    label: 'Norsk',
    locale: 'nb_NO',
    value: 'nb',
  },
  {
    dateFnsLocale: 'pl',
    label: 'Polski',
    locale: 'pl_PL',
    value: 'pl',
  },
  {
    dateFnsLocale: 'pt',
    label: 'Português',
    locale: 'pt_BR',
    value: 'pt',
  },
  {
    dateFnsLocale: 'fi',
    label: 'Suomi',
    locale: 'fi_FI',
    value: 'fi',
  },
  {
    dateFnsLocale: 'sv',
    label: 'Svenska',
    locale: 'sv_SE',
    value: 'sv',
  },
  {
    dateFnsLocale: 'tr',
    label: 'Türkçe',
    locale: 'tr_TR',
    value: 'tr',
  },
  {
    dateFnsLocale: 'ru',
    label: 'Pусский',
    locale: 'ru_RU',
    value: 'ru',
  },
  {
    bcp47Code: 'zh-Hans',
    dateFnsLocale: 'zh-CN',
    label: '简体中文',
    locale: 'zh_CN',
    value: 'zh-cn',
  },
  {
    bcp47Code: 'zh-Hant',
    dateFnsLocale: 'zh-TW',
    label: '繁體中文',
    locale: 'zh_TW',
    value: 'zh-tw',
  },
  {
    dateFnsLocale: 'ja',
    label: '日本語',
    locale: 'ja_JP',
    value: 'ja',
  },
  {
    dateFnsLocale: 'ko',
    label: '한국어',
    locale: 'ko_KR',
    value: 'ko',
  },
  {
    dateFnsLocale: 'bg',
    label: 'Български',
    locale: 'bg_BG',
    // markedForTesting is the new field which will be used to include the new locales on stage
    markedForTesting: 'true',
    value: 'bg',
  },
  {
    dateFnsLocale: 'el',
    label: 'Ελληνικά',
    locale: 'el_GR',
    markedForTesting: 'true',
    value: 'el',
  },
  {
    dateFnsLocale: 'et',
    label: 'Eesti',
    locale: 'et_EE',
    markedForTesting: 'true',
    value: 'et',
  },
  {
    dateFnsLocale: 'hu',
    label: 'Magyar',
    locale: 'hu_HU',
    markedForTesting: 'true',
    value: 'hu',
  },
  {
    dateFnsLocale: 'id',
    label: 'Bahasa Indonesia',
    locale: 'id_ID',
    markedForTesting: 'true',
    value: 'id',
  },
  {
    dateFnsLocale: 'lv',
    label: 'Latviešu',
    locale: 'lv_LV',
    markedForTesting: 'true',
    value: 'lv',
  },
  {
    dateFnsLocale: 'lt',
    label: 'Lietuvių',
    locale: 'lt_LT',
    markedForTesting: 'true',
    value: 'lt',
  },
  {
    dateFnsLocale: 'ms',
    label: 'Bahasa Melayu',
    locale: 'ms_MY',
    markedForTesting: 'true',
    value: 'ms',
  },
  {
    dateFnsLocale: 'ro',
    label: 'Română',
    locale: 'ro_RO',
    markedForTesting: 'true',
    value: 'ro',
  },
  {
    dateFnsLocale: 'sk',
    label: 'Slovenčina',
    locale: 'sk_SK',
    markedForTesting: 'true',
    value: 'sk',
  },
  {
    dateFnsLocale: 'sl',
    label: 'Slovenščina',
    locale: 'sl_SI',
    markedForTesting: 'true',
    value: 'sl',
  },
  {
    dateFnsLocale: 'th',
    label: 'ไทย',
    locale: 'th_TH',
    markedForTesting: 'true',
    value: 'th',
  },
  {
    dateFnsLocale: 'uk',
    label: 'Українська',
    locale: 'uk_UA',
    markedForTesting: 'true',
    value: 'uk',
  },
  {
    dateFnsLocale: 'vi',
    label: 'Tiếng Việt',
    locale: 'vi_VN',
    markedForTesting: 'true',
    value: 'vi',
  },
];
const SURNAME_FIRST_LOCALES = ['ja', 'ko', 'zh-cn', 'zh-tw'];
const CHINESE_LANGUAGE_CODES = ['zh-cn', 'zh-tw'];

export {
  CHINESE_LANGUAGE_CODES,
  DEFAULT_LANGUAGE,
  LANGUAGE_BCP47_MAP,
  LANGUAGE_COUNTRY_MAP,
  SELECTED_LOCALE_KEY,
  SUPPORTED_LANGUAGES,
  SURNAME_FIRST_LOCALES,
};
