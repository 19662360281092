(function () {
  /**
   * @deprecated Please use src2/core/utils/objectUtils.js
   *
   * @ngdoc factory
   * @name binky.core.object-utils
   * @description Utility service for objects.
   */
  angular.module('binky.core.object-utils').factory('objectUtils', utils);

  /* @ngInject */
  function utils(binkySrc2) {
    return binkySrc2.utils.objectUtils;
  }
})();
