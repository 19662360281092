import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

const configure = (config) => {
  ({clientId, includeRoles, url} = config);
};

/**
 * @description Fetches a list of consumables for an organization.
 *     Parameters defined at:
 *     https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-ConsumableSummaryAPI
 *
 * @param {string} orgId - for which to fetch consumable summaries
 *
 * @returns {Promise} - resolves to array of consumables
 */
const getSummaries = ({orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/consumables:summary`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {isArray: true, ...params},
  });

/**
 * @description Fetches a list of consumable usages for an organization.
 *     parameters defined at:
 *     https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-ConsumableUsageAPI
 *
 * @param {string} orgId - for which to fetch consumable usages
 *
 * @returns {Promise} - resolves to array of consumable usages
 */
const getUsages = ({orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/consumables:usages`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {isArray: true, ...params},
  });

const jilConsumables = {configure, getSummaries, getUsages};

export default jilConsumables;
