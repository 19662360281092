(function () {
  /**
   * @deprecated Please use src2/core/models/user/UserRoles.js
   *
   * @ngdoc factory
   * @name UserRoles
   * @description Model for the UserRoles an authenticated user has.
   */
  angular.module('binky.core.user.roles').factory('UserRoles', getUserRolesModel);

  function getUserRolesModel(binkySrc2) {
    return binkySrc2.models.user.UserRoles;
  }
})();
