(function () {
  'use strict';
  /**
   * @deprecated use src2/widgets/common/components/file-drop-zone/FileDropZone.jsx
   */
  angular.module('binky.widgets.common.file-uploader').constant('LABEL_TYPE', {
    BUTTON_PRIMARY: 'label-button-primary',
    BUTTON_SECONDARY: 'label-button-secondary',
    TEXT: 'label-text',
  });
})();
