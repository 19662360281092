import intlTelInput from 'intl-tel-input';
import moment from 'moment';
import format from 'number-format.js';

import binkyUISrc2 from '../../binkyUISrc2';

// Our Angular code expects to use some libraries as globals. Shimming with webpack.ProvidePlugin
// isn't sufficient because parts of the code refer to XXX as $window.XXX
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- boag@ to update
window.format = format;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- boag@ to update
window.intlTelInput = intlTelInput;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- boag@ to update
window.moment = moment;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- boag@ to update
window.binkyUISrc2 = binkyUISrc2;
