import isEqual from 'lodash/isEqual';

import log from 'services/log';

import {
  FULFILLMENT_EVENT_STATUS,
  FULFILLMENT_NEEDED_TYPE,
} from '../../../models/fulfillmentEvent/FulfillmentEventConstants';
import FulfillmentEventList from '../FulfillmentEventList';
import {FULFILLMENT_EVENT_LIST_CONTEXT} from '../FulfillmentEventListConstants';

import {FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS} from './fulfillmentEventRefreshManagerConstants';

let EXTENDED_INTERVAL_PROMISE,
  POLLING_PROMISE,
  currentOrgId,
  inProgressFulfillmentEventList,
  modeSetting,
  refreshCount;
let banners = {};
const registeredCallbacks = {};

/**
 * Service that handles polling for fulfillment events.
 */
const fulfillmentEventRefreshManager = {
  addTempFulfillmentEventAndStartExtendedPoll,
  deregisterCallback,
  getBanners,
  getOriginalFulfillmentIdsOfReturns,
  hasInProgressFulfillmentEventWithOfferId,
  isPollingForOrg,
  registerCallback,
  startPolling,
  stopPolling,
};

////////

/**
 * @description Method to add a temporary fulfillment event to this manager's fulfillment event
 *  list items so the rest of the application can be notified about the in progress fulfillment
 *  event that does not exist yet. This method will set an extended timeout and afterwards will
 *  resume polling. Successive calls to this method should reset the interval.
 *
 * @param {Object} options - Wrapper object for the params of this method.
 * @param {FulfillmentEvent} options.fulfillmentEvent - Fulfillment event to add, NOTE: The
 *  event is expected to have it's offers property set, and needs to have an IN_PROGRESS status.
 * @param {string} options.orgId - Organization id that will be used to determine if we were
 *  already polling.
 */
function addTempFulfillmentEventAndStartExtendedPoll(options) {
  const {fulfillmentEvent, orgId} = options;
  if (isPollingForOrg(orgId)) {
    stopPolling();
  }
  inProgressFulfillmentEventList.items.push(fulfillmentEvent);
  updateStateAndNotifyChanges(inProgressFulfillmentEventList);
  // On successive POSTs or PATCHes we want to reset the timer so that we
  // don't experience data loss.
  if (EXTENDED_INTERVAL_PROMISE !== null && EXTENDED_INTERVAL_PROMISE !== undefined) {
    clearInterval(EXTENDED_INTERVAL_PROMISE);
  }
  EXTENDED_INTERVAL_PROMISE = setInterval(() => {
    // We only want this interval to run once then we'll cancel it and resume standard polling.
    clearInterval(EXTENDED_INTERVAL_PROMISE);
    EXTENDED_INTERVAL_PROMISE = undefined;
    startPolling(orgId);
  }, FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.EXTENDED_INTERVAL);
}

/**
 * @description Method to remove a callback from this managers set of callbacks
 *     that will be invoked when fulfillment event list changes are encountered.
 * @param {Object} ref - Ref that will be used to look up the callback that
 *     should be removed.
 */
function deregisterCallback(ref) {
  delete registeredCallbacks[ref];
}

/**
 * @description Method to get the currently set banners based on the most
 *  recently resolved fulfillment event list.
 *
 * @returns {Object} Banners based on previous refresh and banner state.
 */
function getBanners() {
  return banners;
}

/**
 * @description Method to get the original fulfillment ids of return orders that are in progress.
 *  Note: It is assumed that callers of this method will only be calling this method in response
 *  to a UPDATE_BANNERS event indicating that the inProgressFulfillmentEventList will be defined
 *  and the manager is polling.
 * @returns {string[]} Array of fulfillment ids for the return orders.
 */
function getOriginalFulfillmentIdsOfReturns() {
  const originalFulfillmentIdsOfReturns = inProgressFulfillmentEventList.items
    .filter((fulfillmentEvent) => fulfillmentEvent.requestType === FULFILLMENT_NEEDED_TYPE.RETURN)
    .map((fulfillmentEvent) => fulfillmentEvent.originalFulfillmentId)
    .filter((fulfillmentEvent) => fulfillmentEvent !== null && fulfillmentEvent !== undefined);
  return [...new Set(originalFulfillmentIdsOfReturns)];
}

/**
 * @description Method to check if there are in progress fulfillment events
 *  for a given offerId.
 *
 * @param {string} offerId - Offer id to compare against.
 * @returns {boolean} True if there is a fulfillment event that matches,
 *  false otherwise.
 */
function hasInProgressFulfillmentEventWithOfferId(offerId) {
  const offersWithInProgressFulfillmentEvents =
    getBanners()?.offersThatHaveInProgressFulfillmentEvents || [];
  return offersWithInProgressFulfillmentEvents.includes(offerId);
}

/**
 * @description Method to check if we are already polling for a particular
 *  organization.
 *
 * @param {string} orgId - Org id we want to compare to this manager's stored
 *  value.
 * @returns {boolean} True if a polling promise was defined and the stored
 *  organization id matches the provided param value.
 */
function isPollingForOrg(orgId) {
  return POLLING_PROMISE !== null && POLLING_PROMISE !== undefined && orgId === currentOrgId;
}

/**
 * @description Helper to register a callback to invoke when an update happens.
 * @param {Object} ref - Ref that will be used to organize pieces of the UI and
 *     the callbacks that should be invoked.
 * @param {Function} callback - Callback to invoke when an update event needs to
 *     be communicated.
 */
// eslint-disable-next-line promise/prefer-await-to-callbacks -- Async await will not work for the proposed arrangement for this manager and the UI subscribers.
function registerCallback(ref, callback) {
  registeredCallbacks[ref] = callback;
}

/**
 * @description Method to begin polling for a particular organization.
 *
 * @param {string} orgId - Organization id that will be used to
 *  determine whether this manager should stop polling on one organization
 *  and start polling on another, or if nothing should be done.
 * @param {string} [mode] - Mode that the polling should use, defaults to mode where it
 *  will refresh MAX_REFRESH_ATTEMPTS times and then broadcast an event so the UI can prompt
 *  the user to resume polling.
 */
async function startPolling(orgId, mode = FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.DEFAULT) {
  if (POLLING_PROMISE !== undefined && POLLING_PROMISE !== null && currentOrgId !== orgId) {
    stopPolling();
  }

  modeSetting = mode;

  if (modeSetting === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.EXPONENTIAL_BACKOFF) {
    await executeExponentialBackoffPolling(orgId);
  } else {
    await executeDefaultPolling(orgId);
  }
}

/**
 * @description Method to stop polling on a particular organization.
 */
function stopPolling() {
  if (POLLING_PROMISE !== undefined && POLLING_PROMISE !== null) {
    clearInterval(POLLING_PROMISE);
    POLLING_PROMISE = undefined;
  }
  if (EXTENDED_INTERVAL_PROMISE !== undefined && EXTENDED_INTERVAL_PROMISE !== null) {
    clearInterval(EXTENDED_INTERVAL_PROMISE);
    EXTENDED_INTERVAL_PROMISE = undefined;
  }
  banners = {};
  modeSetting = undefined;
  refreshCount = 0;
}

/////////

function broadcastBanners() {
  const refKeys = Object.keys(registeredCallbacks);
  refKeys.forEach((ref) => {
    registeredCallbacks[ref](banners);
  });
}

async function executeDefaultPolling(orgId) {
  if (
    (POLLING_PROMISE === null || POLLING_PROMISE === undefined) &&
    (EXTENDED_INTERVAL_PROMISE === null || EXTENDED_INTERVAL_PROMISE === undefined)
  ) {
    // In the event we navigate to the same org we need to make sure that the
    // EXTENDED_INTERVAL_PROMISE is not set because if it is we want that interval to complete
    // before we start polling.
    currentOrgId = orgId;
    refreshCount = 1;
    try {
      inProgressFulfillmentEventList = await FulfillmentEventList.get({
        includeTranslatedEvent: false,
        listContext: FULFILLMENT_EVENT_LIST_CONTEXT.IN_PROGRESS,
        orgId: currentOrgId,
        status: FULFILLMENT_EVENT_STATUS.IN_PROGRESS,
        useCache: false,
      });
      updateStateAndNotifyChanges(inProgressFulfillmentEventList);
      POLLING_PROMISE = setInterval(
        refreshInProgressFulfillmentEvents,
        FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL
      );
    } catch (error) {
      log.error('An error occurred fetching the in progress fulfillment events', error);
    }
  }
}

async function executeExponentialBackoffPolling(orgId) {
  if (POLLING_PROMISE === null || POLLING_PROMISE === undefined) {
    currentOrgId = orgId;
    try {
      inProgressFulfillmentEventList = await FulfillmentEventList.get({
        includeTranslatedEvent: false,
        listContext: FULFILLMENT_EVENT_LIST_CONTEXT.IN_PROGRESS,
        orgId: currentOrgId,
        status: FULFILLMENT_EVENT_STATUS.IN_PROGRESS,
        useCache: false,
      });
      POLLING_PROMISE = getSelfTerminatingInterval(0);
    } catch (error) {
      log.error('An error occurred fetching the in progress fulfillment events', error);
    }
  }
}

function getIntervalDuration(count) {
  return Math.min(
    Math.max(
      2 ** count * FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL,
      FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL
    ),
    FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MAX_EXPONENTIAL_BACKOFF
  );
}

function getSelfTerminatingInterval(count) {
  return setInterval(() => {
    clearInterval(POLLING_PROMISE);
    POLLING_PROMISE = undefined;
    refreshInProgressFulfillmentEvents();
    POLLING_PROMISE = getSelfTerminatingInterval(count + 1);
  }, getIntervalDuration(count));
}

async function refreshInProgressFulfillmentEvents() {
  try {
    const refreshedList = await inProgressFulfillmentEventList.refresh();
    updateStateAndNotifyChanges(refreshedList);
  } catch (error) {
    log.error('An error occurred refreshing the in progress fulfillment events', error);
  }
}

function updateStateAndNotifyChanges(list) {
  const currentValues = {
    contractsThatHaveInProgressFulfillmentEvents: list.getContractsWithFulfillmentEvents(),
    offersThatHaveInProgressFulfillmentEvents: list.getOffersWithFulfillmentEvents(),
    showFulfillmentsNoLongerPendingBanner: !!(
      banners.showInProgressFulfillmentEventsPresentBanner && !list.hasInProgressFulfillmentEvents()
    ),
    showInProgressFulfillmentEventsPresentBanner: list.hasInProgressFulfillmentEvents(),
  };

  if (modeSetting === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.DEFAULT) {
    Object.assign(currentValues, {
      showAreYouStillThereBanner:
        refreshCount === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MAX_REFRESH_ATTEMPTS,
    });
    if (currentValues.showAreYouStillThereBanner) {
      fulfillmentEventRefreshManager.stopPolling();
    }
    refreshCount += 1;
  }

  if (!isEqual(currentValues, banners)) {
    banners = currentValues;
    broadcastBanners();
  }
}

export default fulfillmentEventRefreshManager;
