(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.insights.create-insights-item-detail-modal
   * @description module that defines create-insights-item-detail-modal components
   */
  angular.module('binky.widgets.insights.create-insights-item-detail-modal', [
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.shell.panels.modal',
    'binky.ui-migration',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'binky.widgets.insights.create-insights-item-detail-modal.date-range',
    'pascalprecht.translate',
  ]);
})();
