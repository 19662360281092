(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.shell.navigation.nav-manager').factory('navManager', getNavigationService);

  /* @ngInject */
  function getNavigationService($q, _, APP_SHELL_NAV_ID, feature) {
    const navigationLists = {};

    const service = {
      add,
      exists,
      find,
      updateVisibility,
    };

    return service;

    ////////////

    /**
     * @description Method to add a new navigation list (or overwrite existing
     *   list).
     * @param {String} listName - name of list to add
     * @param {NavItemList} navItemList - actual navigation list to add
     */
    function add(listName, navItemList) {
      if (feature.isEnabled('bug_fix_32275') && !listName) {
        return;
      }
      navigationLists[listName] = navItemList;
    }

    /**
     * @description Method to determine if a given list exists or not.
     * @param {String} listName - name of list to see if exists
     * @returns {Boolean} true if list exists, else false
     */
    function exists(listName) {
      return _.has(navigationLists, listName);
    }

    /**
     * @description Method to retrieve a specific navigation list instance. If
     *   list does not exist, this method return undefined.
     * @param {String} listName - name of list to return instance of
     * @returns {NavList|undefined} if exists, reference to list instance, else
     *   undefined
     */
    function find(listName) {
      return navigationLists[listName];
    }

    /**
     * @description Method to recursively update the visibility of a nav item
     *   list's items and their children
     * @param {String} listName - name of the NavItemList to update. If not supplied
     *   this method will update the root (top level) list
     * @returns {Promise} a Promise that resolves an array of promises on completion
     */
    function updateVisibility(listName) {
      const list = find(listName || APP_SHELL_NAV_ID);

      return list ? list.updateVisibility().then(updateChildListVisibility) : $q.resolve();

      ////////

      /**
       * @description Method to update the visibility of any child lists found
       *   while updating a parent list visibility.
       * @returns {Promise} resolves when all child lists have had their
       *   visibility updated, rejects if no list found while updating
       */
      function updateChildListVisibility() {
        const promises = _.map(list.items, (item) => {
          if (item.child && !item.hidden) {
            return updateVisibility(item.child);
          }
          return $q.resolve();
        });

        return $q.all(promises);
      }
    }
  }
})();
