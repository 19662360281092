import {USAGE_REPORT_DATA} from '@admin-tribe/binky';
import {isAfter} from 'date-fns';

/**
 * @description Helper to construct text content for table cells
 * @param {Object} apiOptions - fox manager's getAnnualDataPoints/getMonthlyDataPoints API call parameters.
 * @returns {boolean} whether product is deleted between the time period.
 */
function isProductDeletedInTimePeriod(apiOptions) {
  return (
    apiOptions.deleted &&
    apiOptions.deletedTS < apiOptions.endDate * 1000 &&
    apiOptions.deletedTS < apiOptions.startDate * 1000 &&
    apiOptions.endDate >= USAGE_REPORT_DATA.AVAILABLE_START_DATE
  );
}
/**
 * @description Helper to construct text content for table cells
 * @param {Object} intl - Intl object coming from the useIntl hook.
 * @returns {String} text for learn more go url.
 */
function getLearnMoreGoUrlText(intl) {
  return intl.formatMessage({
    id: 'binky.widgets.reports.learnMoreGoUrl.etlaComponentText',
  });
}
/**
 * @description Helper to construct text content for table cells
 * @param {Object} intl - Intl object coming from the useIntl hook.
 * @param {String} learnMoreGoUrlText - text for learn more go url.
 * @returns {String} text for etla usage report header.
 */
function getHeaderTitle(intl, learnMoreGoUrlText) {
  return intl.formatMessage(
    {id: 'binky.widgets.reports.header.title'},
    {
      learnMoreGoUrlText,
    }
  );
}
/**
 * @description Helper to construct text content for table cells
 * @param {Object} intl - Intl object coming from the useIntl hook.
 * @returns {String} text for timestamp string.
 */
function getTimeStamp(intl) {
  return intl.formatMessage(
    {id: 'binky.widgets.reports.timestamp'},
    {
      localTimestamp: intl.formatDate(new Date(), {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
      }),
    }
  );
}
/**
 * @description Helper to construct text content for table cells
 * @param {number} endDate - contract's endDate epoch
 * @returns {boolean} whether to show expired product banner for a contract or not.
 */
function shouldShowExpiredContractBanner(endDate) {
  const currentDate = new Date();
  return isAfter(currentDate, new Date(endDate * 1000));
}
export {
  isProductDeletedInTimePeriod,
  getLearnMoreGoUrlText,
  getHeaderTitle,
  getTimeStamp,
  shouldShowExpiredContractBanner,
};
