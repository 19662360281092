// Load the library
import './core/services/angularDependencies';

import src2 from './binkySrc2';

export default src2;
export * from './core/api';
export * from './core/models';
export * from './core/services';
export * from './core/utils';
