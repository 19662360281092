/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- @cawright to fix*/
import {action, computed, makeObservable, observable} from 'mobx';

import feature from 'services/feature';

import {
  CHINESE_LANGUAGE_CODES,
  DEFAULT_LANGUAGE,
  LANGUAGE_COUNTRY_MAP,
  SUPPORTED_LANGUAGES,
  SURNAME_FIRST_LOCALES,
} from './LocaleConstants';

const LOCALE_QUERY_PARAM = 'locale';
let singleton;

// To speed things up a little bit by using a Map for constant time lookups
const CACHED_LANGUAGES = new Map();
SUPPORTED_LANGUAGES.forEach((languageObj) => {
  CACHED_LANGUAGES.set(languageObj.value, languageObj);
});

// Note that if location.search changes, this implicitly changes location.href so the page will be reloaded.
const deleteQueryParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete(LOCALE_QUERY_PARAM);
  window.location.search = urlSearchParams.toString();
};

/**
 * @description Helper to obtain the language when a localeCode is provided.
 * @param {string} localeCode - locale code that will be used to obtain the
 *     language code.
 * @return {string} two character language code (or if China, zh-cn/zh-tw)
 *     corresponding to the localeCode provided.
 */
const getLanguageFromLocaleCode = (localeCode) => {
  if (localeCode) {
    if (localeCode.toLowerCase() === 'zh_tw') {
      return 'zh-tw';
    }
    if (localeCode.toLowerCase() === 'zh_cn') {
      return 'zh-cn';
    }
    return localeCode.split('_')[0];
  }
  return undefined;
};

/**
 *
 * @param {String} languageCode - two-character language code (or if China, zh-cn/zh-tw) to get BCP 47 code from
 * @returns {Object} language object that we need to use to get any props from
 */
const getLanguageObject = (languageCode = DEFAULT_LANGUAGE) => {
  const languageObject = CACHED_LANGUAGES.has(languageCode)
    ? CACHED_LANGUAGES.get(languageCode)
    : CACHED_LANGUAGES.get(DEFAULT_LANGUAGE);
  return languageObject;
};

/**
 * @description Method to retrieve the BCP 47 language tag from the corresponding 2-character language code.
 *
 * @param {String} languageCode - two-character language code (or if China, zh-cn/zh-tw) to get BCP 47 code from
 * @returns {String} the BCP 47 language tag
 */
const getBcp47CodeFromLanguage = (languageCode = DEFAULT_LANGUAGE) => {
  const languageObject = getLanguageObject(languageCode);
  // key bcp47Code only exists if it differs from value
  return languageObject.bcp47Code || languageObject.value;
};

/**
 * @description Method to retrieve a locale code from the corresponding 2-character language code.
 *
 * @param {String} languageCode - two-character language code (or if China, zh-cn/zh-tw) to get locale code from
 * @returns {String} locale code of language requested
 */
const getLocaleCodeFromLanguage = (languageCode = DEFAULT_LANGUAGE) => {
  const languageObject = getLanguageObject(languageCode);
  return languageObject.locale;
};

const getQueryParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(LOCALE_QUERY_PARAM);
};

/**
 * @description Method to return the supported languages.
 * @param {Object} [options] Options
 * @param {Boolean} [options.includeHidden] Default is true. If false, any locale objects
 *  with hidden=false will be filtered out of the returned Array.
 *  In particular, locale kk_KZ which was added for localization testing is removed.
 * @returns {Array} an array of objects defining the supported locales
 *  Properties are [hidden], label, locale, url, value, [requiresFlag]
 */
const getSupportedLanguages = ({includeHidden = true, useFeatureFlag = true} = {}) => {
  const languageList =
    useFeatureFlag && feature.isEnabled('temp_new_locales')
      ? SUPPORTED_LANGUAGES
      : SUPPORTED_LANGUAGES.filter((language) => !language.markedForTesting);

  return includeHidden ? languageList : languageList.filter((language) => !language.hidden);
};

/**
 * @description Method to return the supported languages keys.
 * @param {Object} [options] Options
 * @param {Boolean} [options.includeHidden] Default is true. If false, any locale objects
 *  with hidden=false will be filtered out of the returned Array.
 *  In particular, locale kk_KZ which was added for localization testing is removed.
 * @returns {Array} an array of the locale values
 */
const getSupportedLanguageKeys = (options) =>
  getSupportedLanguages(options).map((lang) => lang.value);

/**
 *
 * @param {String} languageCode - two-character language code (or if China, zh-cn/zh-tw) to get BCP 47 code from
 * @returns {String} dateFns string to be used in the format
 */
const getDateFnsLocaleString = (languageCode = DEFAULT_LANGUAGE) => {
  const languageObject = getLanguageObject(languageCode);
  return languageObject.dateFnsLocale;
};

class Locale {
  /**
   * @description Method for getting the Locale if there is one
   * @returns {Locale} the Locale fetched from cache, or null
   */
  static get() {
    return singleton;
  }

  language;

  constructor(language) {
    makeObservable(this, {
      activeCountry: computed,
      activeLanguage: computed,
      activeLanguageBCP47Code: computed,
      activeLocaleCode: computed,
      activeLocaleForSpectrum: computed,
      dateFnsLocaleString: computed,
      isChineseLanguageActive: computed,
      isSurnameFirstNameLocale: computed,
      language: observable,
      languageObject: computed,
      setLanguage: action,
    });

    this.language = language;
    singleton = this;
  }

  get activeCountry() {
    return LANGUAGE_COUNTRY_MAP[this.language];
  }

  get activeLanguage() {
    return this.language;
  }

  // Format used for for the lang attribute on the <html> element.
  // This is also used to converse with some Adobe APIs.
  get activeLanguageBCP47Code() {
    return getBcp47CodeFromLanguage(this.language);
  }

  get activeLocaleCode() {
    return getLocaleCodeFromLanguage(this.language);
  }

  // Format for the locale param provided to Coral Spectrum and React Spectrum.
  get activeLocaleForSpectrum() {
    return getLocaleCodeFromLanguage(this.language).replace('_', '-');
  }

  // We need this as the Date-fns locale is a property of the supported language
  get dateFnsLocaleString() {
    return getDateFnsLocaleString(this.language);
  }

  get isChineseLanguageActive() {
    return CHINESE_LANGUAGE_CODES.includes(this.language);
  }

  get isSurnameFirstNameLocale() {
    return SURNAME_FIRST_LOCALES.includes(this.language);
  }

  // Gets the whole language object
  get languageObject() {
    return getLanguageObject(this.language);
  }

  setLanguage(language) {
    this.language = language;
  }
}

const localeUtils = {
  deleteQueryParam,
  getBcp47CodeFromLanguage,
  getDateFnsLocaleString,
  getLanguageFromLocaleCode,
  getLanguageObject,
  getLocaleCodeFromLanguage,
  getQueryParam,
  getSupportedLanguageKeys,
  getSupportedLanguages,
};

export default Locale;
export {localeUtils};
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- @cawright to fix*/
