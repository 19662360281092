import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import kebabcase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import snakeCase from 'lodash/snakeCase';

/**
 * @description Helper function to turn an object's keys into camelcase.
 *
 * @param {Object|Array} input - Input object or array to be converted
 * @returns {Object|Array} object or array with it's keys or subelements' keys turned to camelcase
 */
function keysToCamelCase(input) {
  return recursivelyTraverseAndUpdateKeys(input, camelCase);
}

/**
 * @description Helper function to turn an object's keys to kebabcase.
 *
 * @param {Object|Array} input - Input object or array to be converted
 * @returns {Object|Array} object or array with it's keys or subelement's keys turned to kebabcase
 */
function keysToKebabCase(input) {
  return recursivelyTraverseAndUpdateKeys(input, kebabcase);
}

/**
 * @description Helper function to turn an object's keys into snakecase.
 *
 * @param {Object|Array} input - Input object or array to be converted
 * @returns {Object|Array} object or array with it's keys or subelements' keys turned to snakecase
 */
function keysToSnakeCase(input) {
  return recursivelyTraverseAndUpdateKeys(input, snakeCase);
}

/////////////

/**
 * @description Helper to actually perform the recursive traversal that will update keys as it
 *  navigates down an objects nodes using the provided lodash function name.
 *
 * @param {Object|Array} input - Input object or array to be converted
 * @param {Function} lodashFn - lodash function that will be used to operate on the items keys,
 *  (either camelCase or snakeCase)
 * @returns {Object|Array} object or array with its keys or subelements updated
 */
function recursivelyTraverseAndUpdateKeys(input, lodashFn) {
  const clonedInput = cloneDeep(input);

  if (Array.isArray(clonedInput)) {
    return clonedInput.map((entry) => recursivelyTraverseAndUpdateKeys(entry, lodashFn));
  }
  if (isPlainObject(clonedInput)) {
    const mappedKeys = mapKeys(clonedInput, (value, key) => lodashFn(key));
    return mapValues(mappedKeys, (value) => recursivelyTraverseAndUpdateKeys(value, lodashFn));
  }
  return clonedInput;
}

export {keysToCamelCase, keysToKebabCase, keysToSnakeCase};
