import {ActionButton, Grid, Item, Picker, Text, View} from '@adobe/react-spectrum';
import ChevronLeftIcon from '@spectrum-icons/workflow/ChevronLeft';
import ChevronRightIcon from '@spectrum-icons/workflow/ChevronRight';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import PaginationConstants from 'common/components/pagination/PaginationConstants';
import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

/**
 * @deprecated use Pandora adminstration TableFooter:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/table-footer
 *
 * A Pagination component that can be used with either currentPage/totalPages for
 * page pagination or hasNextToken/hasPreviousToken for token pagination.
 * The default is the page pagination.
 */
const Pagination = observer(
  ({
    currentPage,
    defaultPageSize = PaginationConstants.DEFAULT_PAGE_SIZE,
    hasNextToken,
    hasPreviousToken,
    isDisabled = false,
    isNextTokenPagination = false,
    onGoNext,
    onGoPrevious,
    onPageSizeChange,
    pageSizeOptions = PaginationConstants.PAGE_SIZES,
    pageSize = defaultPageSize,
    selectedItemCount = 0,
    showPageSizeSelector = true,
    totalPages,
  }) => {
    const hasNextPage = isNextTokenPagination ? hasNextToken : currentPage < totalPages;
    const hasPreviousPage = isNextTokenPagination ? hasPreviousToken : currentPage > 1;
    const intl = useIntl();

    return (
      <nav
        aria-label={intl.formatMessage({
          id: 'binky.common.pagination',
        })}
      >
        <Grid
          alignItems="center"
          areas={['selected-item-count nav-buttons size-selector']}
          columns={['1fr', '1fr', '1fr']}
          rows={['auto']}
        >
          {selectedItemCount > 0 && (
            <View
              data-testid="selected-item-count-view"
              gridArea="selected-item-count"
              justifySelf="start"
              UNSAFE_style={{fontWeight: 'bold'}}
            >
              <FormattedMessage
                id="binky.common.pagination.selectedItemCount"
                values={{
                  count: selectedItemCount,
                }}
              />
            </View>
          )}
          {/* add padding so grid row height accounts for the button focus rings */}
          <View gridArea="nav-buttons" justifySelf="center" paddingY="size-100">
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'binky.common.pagination.previousPage',
              })}
              data-testid="previous-btn"
              isDisabled={isDisabled || !hasPreviousPage}
              isQuiet
              onPress={onGoPrevious}
              variant="primary"
            >
              <ChevronLeftIcon size="S" />
              <Text>
                <FormattedMessage id="binky.common.pagination.button.previous" />
              </Text>
            </ActionButton>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'binky.common.pagination.nextPage',
              })}
              data-testid="next-btn"
              isDisabled={isDisabled || !hasNextPage}
              isQuiet
              onPress={onGoNext}
              UNSAFE_style={{
                paddingLeft: 'var(--spectrum-global-dimension-size-150)',
                paddingRight: 'var(--spectrum-global-dimension-size-75)',
              }}
              variant="primary"
            >
              {/* do not use Text because it forces the icon to be on the left */}
              <View elementType="span">
                <FormattedMessage id="binky.common.pagination.button.next" />
              </View>
              <ChevronRightIcon size="S" />
            </ActionButton>
          </View>
          {showPageSizeSelector && (
            <View data-testid="pagesize-view" gridArea="size-selector" justifySelf="end">
              <Picker
                data-testid="pagesize-select"
                isDisabled={isDisabled}
                isQuiet
                items={pageSizeOptions}
                label={intl.formatMessage({
                  id: 'binky.common.pagination.itemsPerPage',
                })}
                labelAlign="end"
                labelPosition="side"
                menuWidth="size-1000"
                onSelectionChange={onPageSizeChange}
                selectedKey={pageSize}
              >
                {(item) => <Item>{item.label}</Item>}
              </Picker>
            </View>
          )}
        </Grid>
      </nav>
    );
  }
);

Pagination.propTypes = {
  /**
   * The current page. Required if using page rather than token pagination.
   */
  currentPage: requiredIf(PropTypes.number, (props) => !props.isNextTokenPagination),
  /**
   * If using the pageSizeSelector, the default page size. The default is PaginationConstants.DEFAULT_PAGE_SIZE.
   */
  defaultPageSize: PropTypes.number,
  /**
   * If using token pagination, this indicates whether or not to show the next page button.
   */
  hasNextToken: requiredIf(PropTypes.bool, (props) => props.isNextTokenPagination),
  /**
   * If using token pagination, this indicates whether or not to show the next previous button.
   */
  hasPreviousToken: requiredIf(PropTypes.bool, (props) => props.isNextTokenPagination),
  /**
   * Set to true if the buttons and page size selector should be disabled. The default is false.
   */
  isDisabled: PropTypes.bool,
  /**
   * Set to true if using token pagination. This means that rather than providing the current page /
   * totalPages, hasNextToken and hasPreviousToken are provided. The default is false.
   */
  isNextTokenPagination: PropTypes.bool,
  /**
   * Callback function which is called when the next page button is pressed.
   */
  onGoNext: PropTypes.func.isRequired,
  /**
   * Callback function which is called when the previous page button is pressed.
   */
  onGoPrevious: PropTypes.func.isRequired,
  /**
   * If showPageSizeSelector is true, the callback function which is called when the page size is
   * changed. The param is the new page size in numeric format.
   */
  onPageSizeChange: requiredIf(PropTypes.func, (props) => props.showPageSizeSelector),
  /**
   * If showPageSizeSelector is true, the initial page size to show in the page size selector.
   * The default is PaginationConstants.DEFAULT_PAGE_SIZE.
   */
  pageSize: PropTypes.number,
  /**
   * If showPageSizeSelector is true, the page size items to show in the page size selector.
   * It is an array of objects whose id is the numeric page size and whose label is the string shown
   * in the page size selector. The default is PaginationConstants.PAGE_SIZES.
   */
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  /**
   * Whether or not the page size selector is shown. Defaults to true.
   */
  showPageSizeSelector: PropTypes.bool,
  /**
   * The total number of pages. This is required if using page rather than token pagination.
   */
  totalPages: requiredIf(PropTypes.number, (props) => !props.isNextTokenPagination),
};

export default Pagination;
