/**
 * @description This class represents basic chat info
 */
class ChatDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {Object} [options.data] dunamis analytics data from jarvis
   * @param {String} [options.time] time data relating to event
   * @param {String} [options.environment] env analytics were generated in
   * @param {String} [options.project] name for services used by jarvis
   */
  constructor({data, time, environment, project} = {}) {
    Object.assign(this, {data, environment, project, time});
  }
}

export default ChatDescriptor;
