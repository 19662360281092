import Migration from 'models/migration/Migration';
import {MIGRATION_TYPE} from 'models/migration/MigrationConstants';

class T2EMigration extends Migration {
  static canTransform(rawData) {
    return rawData.type === MIGRATION_TYPE.T2E;
  }
}

export default T2EMigration;
