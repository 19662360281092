const ORGANIZATION_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.organization.ORGANIZATION_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/services/organization/OrganizationConstants.js
   */
  angular
    .module('binky.core.organizations')
    .constant(
      'DELEGATION_GROUP_MIGRATION_STATUS',
      ORGANIZATION_CONSTANTS.DELEGATION_GROUP_MIGRATION_STATUS
    )
    .constant(
      'ORGANIZATION_DELETE_ERROR_CODE',
      ORGANIZATION_CONSTANTS.ORGANIZATION_DELETE_ERROR_CODE
    )
    .constant('ORGANIZATION_EVENT', ORGANIZATION_CONSTANTS.ORGANIZATION_EVENT)
    .constant('ORGANIZATION_LIST', ORGANIZATION_CONSTANTS.ORGANIZATION_LIST)
    .constant('ORGANIZATION_MARKET_SEGMENT', ORGANIZATION_CONSTANTS.ORGANIZATION_MARKET_SEGMENT)
    .constant(
      'ORGANIZATION_MARKET_SUBSEGMENT',
      ORGANIZATION_CONSTANTS.ORGANIZATION_MARKET_SUBSEGMENT
    )
    .constant('ORGANIZATION_MIGRATION_STATUS', ORGANIZATION_CONSTANTS.ORGANIZATION_MIGRATION_STATUS)
    .constant('ORGANIZATION_TYPE', ORGANIZATION_CONSTANTS.ORGANIZATION_TYPE);
})();
