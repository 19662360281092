(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.pagination-control-bar
   * @description module that defines pagination-control-bar widgets
   */
  angular.module('binky.widgets.common.pagination-control-bar', [
    'angularUtils.directives.dirPagination',
    'binky.core.lodash',
    'binky.widgets.common.feature',
    'binky.widgets.common.pagination-control-bar.pagination-select',
  ]);
})();
