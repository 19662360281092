(function () {
  'use strict';
  /**
   * @deprecated use src2/widgets/common/components/go-url/GoUrl.jsx
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.common.go-url').filter('goUrl', goUrlFilter);

  /* @ngInject */
  function goUrlFilter(ADOBE_URL, locale) {
    return getGoUrl;

    /**
     * @description get a go-url for a key
     * @param {String} key to use in go-url
     * @returns {String} go-url
     */
    function getGoUrl(key) {
      const lang = locale.getActiveLanguage();
      let goUrlLang;

      switch (lang) {
        case 'zh-cn':
          goUrlLang = 'zh_CN';
          break;
        case 'zh-tw':
          goUrlLang = 'zh_TW';
          break;
        default:
          goUrlLang = lang;
      }

      return `${ADOBE_URL}/go/${key}_${goUrlLang}`;
    }
  }
})();
