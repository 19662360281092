import {MEMBER_TYPE} from './MemberTypeConstants';

export default class MemberType {
  /**
   * @description Creates a new MemberType for use. The 'type' parameter is required.
   *
   * @param {String} type String of type (can be TYPE1, TYPE2, TYPE2E, TYPE3, or USER_GROUP)
   * @param {String} [id] String ID. This will be used to determine if this is a technical account.
   */
  constructor(type, id) {
    this.type = type;
    this.memberId = id;
  }

  /**
   * @description Method to determine if this MemberType represents a Technical Account or not.
   *
   * @returns {Boolean} true if this MemberType represents a Technical Account, else false
   */
  isTechnicalAccount() {
    return this.memberId ? this.memberId.toLowerCase().endsWith('@techacct.adobe.com') : false;
  }

  /**
   * @description Method to determine if this MemberType represents a Type1 account or not.
   *
   * @returns {Boolean} true if this MemberType represents a Type1 account, else false
   */
  isType1() {
    return this.type === MEMBER_TYPE.TYPE1;
  }

  /**
   * @description Method to determine if this MemberType represents a Type2 account or not.
   *
   * @returns {Boolean} true if this MemberType represents a Type2 account, else false
   */
  isType2() {
    return this.type === MEMBER_TYPE.TYPE2;
  }

  /**
   * @description Method to determine if this MemberType represents a Type2E account or not.
   *
   * @returns {Boolean} true if this MemberType represents a Type2E account, else false
   */
  isType2E() {
    return this.type === MEMBER_TYPE.TYPE2E;
  }

  /**
   * @description Method to determine if this MemberType represents a Type3 account or not.
   *
   * @returns {Boolean} true if this MemberType represents a Type3 account, else false
   */
  isType3() {
    return this.type === MEMBER_TYPE.TYPE3;
  }

  /**
   * @description Method to determine if this MemberType is of any user type
   *     (including technical accounts).
   *
   * @returns {Boolean} true if this MemberType represents any user type, else false
   */
  isUser() {
    return this.isType1() || this.isType2() || this.isType2E() || this.isType3();
  }

  /**
   * @description Method to determine if this MemberType represents a User Group or not.
   *
   * @returns {Boolean} true if this MemberType represents a User Group, else false
   */
  isUserGroup() {
    return this.type === MEMBER_TYPE.USER_GROUP;
  }
}
