/* eslint-disable @admin-tribe/admin-tribe/jsdoc-exported-functions -- @boag to fix */
import log from 'services/log';

const VALUE_KEY_APPEND = 'Type';

function getLocalStorageItem(key) {
  try {
    const value = localStorage.getItem(key);
    switch (localStorage.getItem(key + VALUE_KEY_APPEND)) {
      case 'number':
        return Number.parseInt(value, 10);
      case 'boolean':
        return value === 'true';
      case 'object':
        // this covers both objects and arrays, as the typeof array is object
        return JSON.parse(value);
      default:
        return value;
    }
  } catch (error) {
    log.warn('localStorage.getItem() is not supported');
    return null;
  }
}

function getSessionStorageItem(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    log.warn('sessionStorage.getItem() is not supported');
    return null;
  }
}

function removeLocalStorageItem(key) {
  try {
    localStorage.removeItem(key + VALUE_KEY_APPEND);
    localStorage.removeItem(key);
  } catch (error) {
    log.warn('localStorage.removeItem() is not supported');
  }
}

function removeSessionStorageItem(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    log.warn('sessionStorage.removeItem() is not supported');
  }
}

function setLocalStorageItem(key, value) {
  try {
    localStorage.setItem(key + VALUE_KEY_APPEND, typeof value); // store type
    switch (
      typeof value // store value
    ) {
      case 'object':
        // this covers both objects and arrays, as the typeof array is object
        localStorage.setItem(key, JSON.stringify(value));
        break;
      default:
        // string, number, boolean all stored as string
        localStorage.setItem(key, value);
    }
  } catch (error) {
    log.warn('localStorage.setItem() is not supported');
  }
}

function setSessionStorageItem(key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    log.warn('sessionStorage.setItem() is not supported');
  }
}

export {
  getLocalStorageItem,
  getSessionStorageItem,
  removeLocalStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
};
/* eslint-enable @admin-tribe/admin-tribe/jsdoc-exported-functions -- @boag to fix */
