(function () {
  /**
   * @ngdoc module
   * @name binky.core.fulfillment-events.refresh-manager
   * @description module that defines fulfillment event refresh manager service.
   */
  angular.module('binky.core.fulfillment-events.refresh-manager', [
    'binky.core.fulfillment-events',
    'binky.core.fulfillment-needed',
    'binky.core.lodash',
  ]);
})();
