import jilGlobalAdminPolicies from 'api/jil/jilGlobalAdminPolicies';
import log from 'services/log';
import ModelList from 'services/modelList/ModelList';

import {GLOBAL_ADMIN_POLICY_LIST_CACHE_ID} from './GlobalAdminConstants';
import GlobalAdminPolicy from './GlobalAdminPolicy';

/**
 * Global Admin policy list model
 */
class GlobalAdminPolicyList extends ModelList {
  /**
   * @description Method to get Global Admin policies.
   * This method always returns an instance of GlobalAdminPolicyList.
   * When there's a backend error, the instance will contain no policies,
   * thus default to 'allow' for all policies.
   * @param {Object} options Options, see constructor for details
   * @returns {GlobalAdminPolicyList} GlobalAdminPolicyList instance.
   */
  static async get(options) {
    let globalAdminPolicyList;

    try {
      globalAdminPolicyList = await super.get(options);
    } catch (error) {
      log.error('GlobalAdminPolicyList.get: error', error);

      // Empty list so all policies will default to 'allow'.
      globalAdminPolicyList = new GlobalAdminPolicyList({
        orgId: options.orgId,
      });
    }
    return globalAdminPolicyList;
  }

  /**
   * @description Constructor
   *
   * @param {Object} options Initialization options
   * @param {String} options.orgId Organization ID
   * @param {Boolean} [options.isCacheable] True for caching data, false otherwise
   */
  constructor(options) {
    super({
      isCacheable: options?.isCacheable !== false,
      itemClassRef: GlobalAdminPolicy,
      modelCacheId: GLOBAL_ADMIN_POLICY_LIST_CACHE_ID,
      resource: jilGlobalAdminPolicies.getPolicies,
      transformResponseData,
    });

    this.orgId = options.orgId;
  }

  /**
   * @description Get the unique cache key
   *
   * @returns {String} Unique cache key
   */
  getKey() {
    return ModelList.getKey({orgId: this.orgId});
  }

  /**
   * @description Get the policy value for a given policy name.
   * If the policy doesn't exist, default to return true.
   *
   * @param {String} policyName Policy name
   * @returns {Boolean} Policy value if it exists. Return true if the policy doesn't exist.
   */
  getPolicyValue(policyName) {
    const policy = this.items.find((item) => item.name === policyName);
    let value;
    if (policy) {
      value = policy.value ?? policy.defaultValue;
    }
    return !policy || value;
  }

  /**
   * @description Updates a policy with the specified value
   *
   * @param {String} orgId The id of the org for which policy update is required
   * @param {String} policy The name of the policy
   * @param {Boolean} policyValue The value determining whether to select/deselect the policy
   *
   * @returns {GlobalAdminPolicyList} - Updated GlobalAdminPolicyList
   */
  async updatePolicy({orgId, policy, policyValue}) {
    const operations = [
      {
        op: 'replace',
        path: `/${policy}`,
        value: policyValue,
      },
    ];
    try {
      await jilGlobalAdminPolicies.patchPolicy({orgId}, operations);
    } catch (error) {
      log.error(`GlobalAdminPolicyList: error updating policy`, error);

      throw error;
    }

    await this.refresh({orgId});
    return this;
  }
}

function transformResponseData(responseData, ItemClassRef) {
  return responseData.map((responseItem) => new ItemClassRef(responseItem));
}

export default GlobalAdminPolicyList;
