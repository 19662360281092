(function () {
  'use strict';
  /**
   * @deprecated Will not be required once all code moves to React
   */
  angular
    .module('binky.ui-migration')
    // eslint-disable-next-line angular/window-service
    .constant('binkyUISrc2', window.binkyUISrc2);
})();
