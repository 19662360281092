// Client for the Asset Policy service
// https://wiki.corp.adobe.com/display/collaboration/Policy+Service

import axios from 'axios';

import authentication from 'services/authentication';

let baseUrl, clientId;

/**
 * Build and return the options for a call to the Asset Policy Service
 * @param {Object} headers - The headers to use for axios call
 * @param {Object} newContentAuthenticityPolicy - The query parameter to retrieve expanded ca policy
 * @returns {Object} options for the axios call
 */
function getOptions(headers = {}, newContentAuthenticityPolicy = false) {
  const options = {
    headers: {
      'X-Api-Key': clientId,
      ...headers,
    },
    params: newContentAuthenticityPolicy ? {newcapolicy: true} : undefined,
    timeout: 5000,
  };

  const token = authentication.getAccessToken();
  if (token?.token) {
    options.headers.Authorization = `Bearer ${token.token}`;
  }
  return options;
}

/**
 * Set environment and application level context for the Asset Policy Service
 * @param {Object} config - A configuration object.
 * @param {String} config.url - The root url for the IMS service api
 * @param {String} config.clientId - The identifier for the consuming application
 */
const configure = (config) => {
  baseUrl = config.url;
  clientId = config.clientId;
};

/**
 * Fetch the list of Collaboration Policies for the organization
 *
 * @param {Object} options - The options to use for this call
 * @param {Object} options.newContentAuthenticityPolicy - The query parameter to retrieve expanded ca policy
 * @param {String} options.orgId - The organization to fetch policies for
 * @return {Promise} a promise which will resolve with the response
 */
const getPolicies = ({orgId, newContentAuthenticityPolicy}) =>
  axios.get(`${baseUrl}/organization/${orgId}`, getOptions({}, newContentAuthenticityPolicy));

/**
 * Write the list of policies back to the Collaboration Policy service
 *
 * @param {Object} options - The options to use for this call
 * @param {String} options.orgId - The organization to write policies for
 * @param {Object[]} options.policies - The array of policy objects to write back to the service
 */
const setPolicies = ({orgId, policies}) =>
  axios.post(`${baseUrl}/organization/${orgId}`, policies, getOptions());

const collaborationPolicyService = {
  configure,
  getPolicies,
  setPolicies,
};
export default collaborationPolicyService;
