import axios from 'axios';
import omitBy from 'lodash/omitBy';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

/**
 * Defines service to manage jil admin resources
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for JIL authentication policy api
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */

let clientId, includeRoles, url;

const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

const getAdmins = (params) => {
  const {filterExcludeDomain, filterIncludeDomain, orgId, ...queryParams} = params;
  const snakeCaseParams = omitBy(
    {
      filter_exclude_domain: filterExcludeDomain,
      filter_include_domain: filterIncludeDomain,
      ...queryParams,
    },
    (value) => value === undefined
  );
  const requestUrl = `${url}/v2/organizations/${orgId}/admins`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: snakeCaseParams,
  });
};

const patchAdmins = ({orgId}, patchOperations) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/admins`;
  return axios.patch(requestUrl, patchOperations, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

const exportAgents = async ({orgId}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/admins`;
  let headerObj = getHeaders({clientId, includeRoles});
  headerObj = {...headerObj, Accept: 'text/csv+adobe-agents,application/json'};
  const response = await axios.get(requestUrl, {
    headers: headerObj,
  });
  return csvBlobTransformer(response);
};

const jilAdmins = {
  configure,
  exportAgents,
  getAdmins,
  patchAdmins,
};

export default jilAdmins;
