(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.language-switcher
   * @description module that defines language-switcher widgets
   */
  angular.module('binky.widgets.common.language-switcher', [
    'binky.core.locale',
    'binky.core.storage-utils',
    'ngCoral',
  ]);
})();
