import {authentication} from '@admin-tribe/binky';
import {Flex, TextField} from '@adobe/react-spectrum';
import Copy from '@spectrum-icons/workflow/Copy';
import React from 'react';
import {useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';

/**
 * OmniToolUtilitiesPanel - encapsulates data for the utilities panel
 */
const OmniToolUtilitiesPanel = () => {
  const intl = useIntl();
  const bearerTokenLabel = intl.formatMessage({
    id: 'binky.common.omniTool.utilitiesPanel.copyBearerToken',
  });
  const {token} = authentication.getAccessToken(false);

  return (
    <Flex alignItems="end" gap="size-100">
      <TextField flexGrow={1} label={bearerTokenLabel} value={token} />
      <CopyToClipboardButton aria-label={bearerTokenLabel} value={token}>
        <Copy />
      </CopyToClipboardButton>
    </Flex>
  );
};

OmniToolUtilitiesPanel.propTypes = {};

export default OmniToolUtilitiesPanel;
