const DOMAIN_STATUS = {
  ACTIVE: 'ACTIVE',
  CLAIMED: 'CLAIMED',
  UNCLAIMED: 'UNCLAIMED',
  UNDEFINED: 'UNDEFINED',
  VALIDATED: 'VALIDATED',
  WITHDRAWN: 'WITHDRAWN',
};

export {DOMAIN_STATUS};
