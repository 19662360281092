const FULFILLMENT_EVENT_LIST_CONTEXT = {
  IN_PROGRESS: 'IN_PROGRESS',
  LICENSE: 'LICENSE',
  ORGANIZATION: 'ORGANIZATION',
};
const FULFILLMENT_EVENT_LIST_EVENT = {
  UPDATE: 'UpdateFulfillmentEventList',
  UPDATE_COUNT: 'UpdateFulfillmentEventCount',
};
const IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT = {
  UPDATE: 'UpdateInProgressFulfillmentEventList',
  UPDATE_COUNT: 'UpdateInProgressFulfillmentEventCount',
};
const LICENSE_FULFILLMENT_EVENT_LIST_EVENT = {
  UPDATE: 'UpdateLicenseFulfillmentEventList',
  UPDATE_COUNT: 'UpdateLicenseFulfillmentEventCount',
};

export {
  FULFILLMENT_EVENT_LIST_CONTEXT,
  FULFILLMENT_EVENT_LIST_EVENT,
  IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT,
  LICENSE_FULFILLMENT_EVENT_LIST_EVENT,
};
