import pick from 'lodash/pick';

import {CONSUMABLE_THRESHOLD_STATUS} from './ConsumableConstants';
import ConsumableUsageList from './ConsumableUsageList';

class Consumable {
  /**
   * @description Transforms the consumable summary response object into a new
   *     Consumable instance. See constructor for params.
   */
  static apiResponseTransformer(dataTransferObject, parentSummary) {
    return new Consumable(dataTransferObject, parentSummary);
  }

  /**
   * @description Creates a new Consumable instance.
   *
   * @param {Object} options - initialization object
   * @param {number} options.consumedQuantity - total used, summary across all
   *     usage instances intersecting criteria, i.e., combined usage for a
   *     particular license
   * @param {string} options.fulfillableItemCode - the Fulfillable Item Code for
   *     the consumable, e.g., "stock_image"
   * @param {number} [options.overConsumedQuantity] - N/A for stock; from Sign
   * @param {boolean} [options.overQuota] - N/A for stock; from Sign
   * @param {number} options.remainingQuantity - total remaining, summary of
   *     remaining across all usage instances intersecting criteria
   * @param {string} [options.thresholdStatus] - possible values defined by
   *     CONSUMABLE_THRESHOLD_STATUS
   * @param {number} [options.thresholdValue] - when a threshold_status suggests
   *     a value, such as a percentage, value computed by consumable system
   * @param {number} options.totalQuantity - total units, summary across all
   *     usage instances intersecting criteria.
   *     e.g., 5 qty of Stock 40 packs = 200 total
   * @param {Object} options.unit - the units of the consumable, including
   *     pluralization information
   * @param {number} options.usageTotal - total number of "usage" elements, which
   *     may exceed the "usages" array length returned when paging is required
   * @param {ConsumableUsage[]} options.usages - consumables usage elements (may
   *     be incomplete)
   * @param {ConsumableSummary} parentSummary - the parent ConsumableSummary
   *     this Consumable belongs to
   */
  constructor(options, parentSummary) {
    this.parentSummary = parentSummary;

    Object.assign(
      this,
      pick(options, [
        'consumedQuantity',
        'fulfillableItemCode',
        'overConsumedQuantity',
        'overQuota',
        'remainingQuantity',
        'thresholdStatus',
        'thresholdValue',
        'totalQuantity',
        'unit',
        'usageTotal',
      ])
    );

    this.consumableUsageList = new ConsumableUsageList(
      {
        items: options.usages,
        usageTotal: this.usageTotal,
      },
      this
    );
  }

  /**
   * @description Get the query params needed to get the usages for this Consumable
   *
   * @returns {Object} Object of params used in the query to get the usages
   */
  getQueryParams() {
    return {
      ...this.parentSummary?.getTopLevelQueryParams(),
      fulfillableItemCode: this.fulfillableItemCode,
    };
  }

  /**
   * @description Method to determine if this Consumable has threshold status
   *     of "DEPLETED".
   *
   * @returns {boolean} whether threshold is reached
   */
  isThresholdStatusDepleted() {
    return this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.DEPLETED;
  }

  /**
   * @description Method to determine if Consumable has threshold status of
   *     "PERCENTAGE_THRESHOLD".
   *
   * @returns {boolean} whether threshold is reached
   */
  isThresholdStatusPercentageThreshold() {
    return this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.PERCENTAGE_THRESHOLD;
  }

  /**
   * @description Method to determine if this Consumable has reached one of its
   *     thresholds.
   *
   * @returns {boolean} whether either threshold is reached
   */
  isThresholdTriggered() {
    return this.isThresholdStatusPercentageThreshold() || this.isThresholdStatusDepleted();
  }
}

export default Consumable;
