import invoke from 'lodash/invoke';
import omitBy from 'lodash/omitBy';

import jilOrganizationsMigrations from 'api/jil/jilOrganizationsMigrations';
import Migration from 'models/migration/Migration';
import {MIGRATION_STATUS} from 'models/migration/MigrationConstants';
import OptionsPerStatusMigration from 'models/migration/optionsPerStatus/OptionsPerStatusMigration';
import T2EMigration from 'models/migration/t2e/T2EMigration';
import log from 'services/log';
import ModelList from 'services/modelList/ModelList';

import {MIGRATION_LIST_CACHE_ID} from './migrationListConstants';

class MigrationList extends ModelList {
  /**
   * @description Creates a new MigrationList
   *
   * @param {Object} options as described below
   * @param {String} options.orgId associates MigrationList instance with an org.
   * @param {String[]} options.types the migration types to fetch in this list
   */
  constructor({isCacheable = true, orgId, types}) {
    const itemClassRef = [OptionsPerStatusMigration, T2EMigration, Migration];

    super({
      isCacheable,
      itemClassRef,
      modelCacheId: MIGRATION_LIST_CACHE_ID,
      resource: () =>
        jilOrganizationsMigrations.getMigrations({
          orgId,
          types: types.join(','),
        }),
      transformResponseData,
    });
    this.orgId = orgId;
    this.types = types;
  }

  /**
   * @description Returns the first migration in the list whose
   *  type matches the specified type.
   *
   * @param {MIGRATION_TYPE} type the migration type to search for
   * @returns {Migration} the first migration with the specified type,
   *  or undefined if no such migration is found
   */
  findByType(type) {
    return this.items.find((item) => item.type === type);
  }

  /**
   * @description Retrieves the first migration found whose org is locked.
   *
   * @returns {Migration} the first migration found that indicates the org is locked. If
   *  no migration has a locked org, returns undefined.
   */
  getMigrationWithLockedOrg() {
    return this.items.find((item) => invoke(item, 'isOrgLocked'));
  }

  /**
   * @description Returns whether any migrations in the list are currently migrating.
   *
   * @returns {boolean} true if there is at least one migration with the status "MIGRATING",
   *  false otherwise
   */
  isMigrating() {
    return this.items.some((item) => item.status === MIGRATION_STATUS.MIGRATING);
  }

  /**
   * @description Refreshes the contents of the list
   *
   * @returns {Promise} a promise which is resolved when the list is refreshed successfully
   */
  async refresh() {
    try {
      await super.refresh(
        omitBy({orgId: this.orgId, types: this.types.join(',')}, (value) => value === undefined)
      );
    } catch (error) {
      log.error('MigrationList.refresh() failed. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description Check if the default behavior to add admins is allowed, assuming
   *  a migration has lifted its read-only state.
   *
   * @returns {Boolean} true if the default behavior to add admins is allowed.
   */
  shouldAlignWithAddAdminLogic() {
    return (
      // This check ensures that the default behavior is preserved in the case
      // that a certain type of migration does not have to lift the read-only state to
      // reach workflows of adding admins.
      !this.shouldForceAllowAddAdminsOrUsers() || this.shouldForceAllowAddAdmins()
    );
  }

  /**
   * @description Check if the default behavior to add users is allowed, assuming
   *  a migration has lifted its read-only state.
   *
   * @returns {Boolean} true if the default behavior to add users is allowed.
   */
  shouldAlignWithAddUserLogic() {
    return (
      // This check ensures that the default behavior is preserved in the case
      // that a certain type of migration does not have to lift the read-only state to
      // reach workflows of adding users.
      !this.shouldForceAllowAddAdminsOrUsers() || this.shouldForceAllowAddUsers()
    );
  }

  /**
   * @description Checks to see if any migration allows for
   *  adding admins, regardless of the usual requirements.
   *
   * @returns {Boolean} true if overriding existing behavior to add admins is allowed,
   *  returns false otherwise.
   */
  shouldForceAllowAddAdmins() {
    return this.items.some((item) => invoke(item, 'shouldForceAllowAddAdmins'));
  }

  /**
   * @description Checks to see if any migration allows for
   *  adding admins or users, regardless of the usual requirements.
   *
   * @returns {Boolean} true if overriding existing behavior to add admins or users
   *  is allowed, returns false otherwise.
   *  Added one extra condition to check if any migration is in MIGRATING state to override the default value.
   */
  shouldForceAllowAddAdminsOrUsers() {
    return this.items.some((item) => {
      if (item.status === MIGRATION_STATUS.MIGRATING) {
        return this.shouldForceAllowAddAdmins() || this.shouldForceAllowAddUsers();
      }
      return false;
    });
  }

  /**
   * @description Checks to see if any migration allows for
   *  adding users, regardless of the usual requirements.
   *
   * @returns {Boolean} true if overriding existing behavior to add users is allowed,
   *  returns false otherwise.
   */
  shouldForceAllowAddUsers() {
    return this.items.some((item) => invoke(item, 'shouldForceAllowAddUsers'));
  }

  /**
   * @description Checks to see if any migration allows for domain
   *  claiming, regardless of the usual requirements.
   *
   * @returns {Boolean} true if overriding existing behavior to claim domains is allowed, returns false otherwise.
   */
  shouldForceAllowDomainClaiming() {
    return this.items.some((item) => invoke(item, 'shouldForceAllowDomainClaiming'));
  }

  /**
   * @description Checks to see if any migration blocks PA creation,
   *  regardless of the usual requirements.
   *
   * @returns {Boolean} true if overriding existing behavior to block PA creation, returns false otherwise.
   */
  shouldForceBlockPACreation() {
    return this.items.some((item) => invoke(item, 'shouldForceBlockPACreation'));
  }
}

function transformResponseData(responseData, classRefs) {
  return responseData.map((responseItem) => {
    const ClassRef = classRefs.find((ref) => ref.canTransform(responseItem));
    return new ClassRef(responseItem);
  });
}

export default MigrationList;
