/* eslint-disable react/no-unknown-property -- styleName not recognized */
import binky, {feature} from '@admin-tribe/binky';
import InfoIcon from '@react/react-spectrum/Icon/Info';
import SyncIcon from '@react/react-spectrum/Icon/Sync';
import UserIcon from '@react/react-spectrum/Icon/User';
import UserGroupIcon from '@react/react-spectrum/Icon/UserGroup';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Wait should be replaced with v3 ProgressCircle
import Wait from '@react/react-spectrum/Wait';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import TruncatedText from 'common/components/truncated-text/TruncatedText';

import styles from './UserPicker.pcss';
import {useUserPickerContext} from './UserPickerContext';

const MEMBER_TYPE = binky.models.member.MEMBER_TYPE_CONSTANTS.MEMBER_TYPE;

const EXTRA_ROW_TYPES = {
  ADD_USER: 'ADD_USER',
  LOADING: 'LOADING',
  NO_RESULTS: 'NO_RESULTS',
};

// Used to help with detecting row types in UserPicker tests.
const ROW_TEST_IDS = {
  ADD_USER: 'add-user-row',
  LOADING: 'loading-row',
  NO_RESULTS: 'no-results-row',
  RESULT: 'result-row',
  RESULT_DISABLED: 'result-disabled-row',
};

const BLANK_NAME = '–'; // en-dash

// Defines the UserPickerRow component, a sub-component of UserPicker.
// Represents a single dropdown-row in UserPicker.

const UserPickerRow = ({item, showTypes = true}) => {
  const {member, rowType, rowTypeReason, isRowDisabled} = item;
  const intl = useIntl();

  // Whether the current admin can add users to the org.
  const {canAddUser = true} = useUserPickerContext();

  const renderIdentityType = (user) => {
    let t2eKey;
    if (feature.isEnabled('temp_remove_business_id')) {
      t2eKey =
        user.authenticatingAccount?.type === undefined
          ? 'notAvailable'
          : user.authenticatingAccount?.type.toLowerCase();
    } else {
      t2eKey = 'type2e';
    }
    switch (user.type) {
      case MEMBER_TYPE.TYPE1:
        return intl.formatMessage({id: 'binky.common.userPicker.userTypes.type1'});
      case MEMBER_TYPE.TYPE2:
        return intl.formatMessage({id: 'binky.common.userPicker.userTypes.type2'});
      case MEMBER_TYPE.TYPE2E:
        return intl.formatMessage({
          id: `binky.common.userPicker.userTypes.${t2eKey}`,
        });
      case MEMBER_TYPE.TYPE3:
        return intl.formatMessage({id: 'binky.common.userPicker.userTypes.type3'});
      default:
        return user.type;
    }
  };

  // Precondition: "member" is an OrganizationUser object.
  /* eslint-disable @admin-tribe/admin-tribe/prefer-composition -- thean@ to update */
  // eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- thean@ to update
  const renderUserRow = () => {
    // spectrum: Update once UserPicker is ported to v3.
    // We can't actually disable clicks on a v2 Autocomplete row, so this is only greyed out in UI.
    // The UserPicker class will handle the ignoring of disabled rows for now.
    const isDisabled = member.inTarget || isRowDisabled;
    const rowTestId = isDisabled ? ROW_TEST_IDS.RESULT_DISABLED : ROW_TEST_IDS.RESULT;
    const rowStyleName = isDisabled ? 'dropdown-user-row disabled' : 'dropdown-user-row';
    return (
      <div data-testid={rowTestId} styleName={rowStyleName}>
        <div styleName="icon-circle-user">
          <UserIcon size="S" styleName="icon" />
        </div>
        <div styleName="dropdown-user-values">
          <div styleName="value-column name">
            <div styleName="attr-label">
              {intl.formatMessage({id: 'binky.common.userPicker.label.name'})}
            </div>
            <div styleName="attr-value">
              <TruncatedText
                data-testid="user-picker-row-display-name"
                tooltipClassName={styles['attr-tooltip']}
              >
                {(member.getDisplayName && member.getDisplayName()) || BLANK_NAME}
              </TruncatedText>
              {member.externallyManaged && <SyncIcon size="XS" styleName="sync-icon" />}
            </div>
          </div>
          <div styleName="value-column email">
            <div styleName="attr-label">
              {intl.formatMessage({id: 'binky.common.userPicker.label.email'})}
            </div>
            <div styleName="attr-value">
              <TruncatedText tooltipClassName={styles['attr-tooltip']}>
                {member.email}
              </TruncatedText>
            </div>
          </div>
          {showTypes && (
            <div data-testid="user-picker-row-type-column" styleName="value-column type">
              <div styleName="attr-label">
                {intl.formatMessage({id: 'binky.common.userPicker.label.identityType'})}
              </div>
              <div styleName="attr-value">
                <TruncatedText
                  data-testid="user-picker-row-type"
                  tooltipClassName={styles['attr-tooltip']}
                >
                  {renderIdentityType(member)}
                </TruncatedText>
              </div>
            </div>
          )}
          {showTypes && (
            <div data-testid="user-picker-row-username-column" styleName="value-column username">
              <div styleName="attr-label">
                {intl.formatMessage({id: 'binky.common.userPicker.label.username'})}
              </div>
              <div styleName="attr-value">
                <TruncatedText tooltipClassName={styles['attr-tooltip']}>
                  {member.userName}
                </TruncatedText>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  /* eslint-enable @admin-tribe/admin-tribe/prefer-composition -- thean@ to update */

  if (rowType === EXTRA_ROW_TYPES.ADD_USER) {
    return (
      <div data-testid={ROW_TEST_IDS.ADD_USER} styleName="dropdown-add-user-row">
        <div styleName="dropdown-add-user-text">
          {intl.formatMessage({id: 'binky.common.userPicker.addNewUser'})}
        </div>
      </div>
    );
  }
  if (rowType === EXTRA_ROW_TYPES.LOADING) {
    return (
      <div data-testid={ROW_TEST_IDS.LOADING} styleName="dropdown-loading-row">
        <Wait size="S" styleName="icon" />
        {intl.formatMessage({id: 'binky.common.userPicker.loading'})}
      </div>
    );
  }
  if (rowType === EXTRA_ROW_TYPES.NO_RESULTS) {
    return (
      <div data-testid={ROW_TEST_IDS.NO_RESULTS} styleName="dropdown-no-results-row">
        <InfoIcon size="S" styleName="icon" />
        {rowTypeReason ||
          intl.formatMessage({
            id: canAddUser
              ? 'binky.common.userPicker.noResults'
              : 'binky.common.userPicker.noResults.noAdd',
          })}
      </div>
    );
  }
  if (rowType === MEMBER_TYPE.USER_GROUP) {
    // We can't actually disable clicks on a V2 Autocomplete row, so this is only greyed out in UI for now
    // Pending shift to V3 -- @thean needs to fix

    const isDisabled = member.inTarget || isRowDisabled;

    const rowTestId = isDisabled ? ROW_TEST_IDS.RESULT_DISABLED : ROW_TEST_IDS.RESULT;
    const rowStyleName = isDisabled ? 'dropdown-group-row disabled' : 'dropdown-group-row';
    return (
      <div data-testid={rowTestId} styleName={rowStyleName}>
        <div styleName="icon-circle-group">
          <UserGroupIcon size="S" styleName="icon" />
        </div>
        <div styleName="dropdown-group-values">
          <div styleName="value-column usergroup">
            <div styleName="attr-label">
              {intl.formatMessage({id: 'binky.common.userPicker.label.userGroup'})}
            </div>
            <div styleName="attr-value">
              <TruncatedText tooltipClassName={styles['attr-tooltip']}>{member.name}</TruncatedText>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // else if rowType == USER
  return renderUserRow();
};

UserPickerRow.EXTRA_ROW_TYPES = EXTRA_ROW_TYPES;
UserPickerRow.ROW_TEST_IDS = ROW_TEST_IDS;

UserPickerRow.propTypes = {
  item: PropTypes.shape({
    isRowDisabled: PropTypes.bool,
    // This is generally what we assume exists in the items given by SearchMemberList
    member: PropTypes.shape({
      email: PropTypes.string,
      externallyManaged: PropTypes.bool,
      getDisplayName: PropTypes.func,
      inTarget: PropTypes.bool,
      name: PropTypes.string,
      type: PropTypes.string,
      userName: PropTypes.string,
    }),
    rowType: PropTypes.string.isRequired,
    /**
     * @description Render this prop if set, instead of the default the no result found msg.
     * This property is used only when rowType === EXTRA_ROW_TYPES.NO_RESULTS
     */
    rowTypeReason: PropTypes.string,
  }).isRequired,

  // Whether to show the user-type in the dropdown. For example, SMB orgs generally have the same user type for all users.
  showTypes: PropTypes.bool,
};

export default UserPickerRow;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
