(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/panels/modal-dialog/ModalDialog.jsx
   *
   * @ngdoc service
   * @name binky.shell.panels.modal:modalHelper
   * @description product icon helper factory
   */
  angular.module('binky.shell.panels.modal').factory('modalHelper', modalHelper);
  /* @ngInject */
  function modalHelper($document, _, PANEL_MANAGER, panelManager) {
    const service = {
      updateModalContextIfExists,
    };

    return service;

    //////////////

    /**
     * This function updates a modal context based on the current component's view.
     * @param {String} modalContext The new modal's context. The input modal context will be converted into Snake Case.
     * @param {String} componentSelector The component selector.
     * The selector is used to verify if there is an open modal that contains the current component
     * before updating the modal context.
     */
    function updateModalContextIfExists(modalContext, componentSelector) {
      if (modalContext && componentSelector) {
        const openModalIds = panelManager.getOpenPanelIdsByType(PANEL_MANAGER.TYPE.MODAL);

        // Find an open modal that contains the current component
        const mainModalId = _.find(
          openModalIds,
          (modalId) => !_.isNil($document[0].querySelector(`#${modalId} ${componentSelector}`))
        );

        // If there is an open modal that contains the current component,
        // we update the modal context based on the current component view or selection.
        if (mainModalId) {
          const context = _.snakeCase(modalContext);
          $document[0].querySelector(`#${mainModalId}`).setAttribute('context', context);
        }
      }
    }
  }
})();
