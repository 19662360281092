import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL authentication policy APIs
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for JIL authentication policy api
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Fetches authentication policies for an org
 *
 * @param {Object} params - Params wrapper object.
 * @param {string} params.orgId - The org id
 *
 * @returns {Object} Object containing fetched authentication policies.
 */
const getAuthenticationPolicies = ({orgId}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/authentication-policies`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * Updates authentication policies for an org.
 * @param {Object} params - Params wrapper object.
 * @param {string} params.orgId - org id whose authentication policy will be updated.
 * @param {string} params.name - authentication policy name to add or replace the current.
 * @param {string} params.value - authentication policy value to add or replace the current.
 *
 * @returns {Object} Object containing updated authentication policies
 */
const setAuthenticationPolicy = async ({orgId, name, value}) => {
  const response = await axios.post(
    `${url}/v2/organizations/${orgId}/authentication-policies`,
    {name, value},
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response.data;
};

/**
 * Updates authentication policies for an org.
 * @param {Object} params - Params wrapper object.
 * @param {string} params.orgId - org id whose authentication policy will be removed.
 * @param {string} params.name - name of the authentication policy to be removed.
 *
 * @returns {Object} Object containing updated authentication policies
 */
const removeAuthenticationPolicy = async ({orgId, name}) => {
  const response = await axios.delete(
    `${url}/v2/organizations/${orgId}/authentication-policies/${name}`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response.data;
};

const jilAuthenticationPolicies = {
  configure,
  getAuthenticationPolicies,
  removeAuthenticationPolicy,
  setAuthenticationPolicy,
};

export default jilAuthenticationPolicies;
