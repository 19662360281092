const MIGRATION_STATUS = {
  MIGRATED: 'MIGRATED',
  MIGRATING: 'MIGRATING',
  NOT_MIGRATING: 'NOT_MIGRATING',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
};
const MIGRATION_TYPE = {
  ESM_TYPE1: 'ESM_TYPE1',
  MA_LEGACY_TO_ADMIN_CONSOLE: 'MA_LEGACY_TO_ADMINCONSOLE',
  T2E: 'T2E',
  VIP2DIRECT: 'VIP2DIRECT',
  VIPMP: 'VIPMP',
};

export {MIGRATION_STATUS, MIGRATION_TYPE};
