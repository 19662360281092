(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   *
   * @ngdoc component
   * @name binky.widgets.insights.insights-item-list-table:binkyInsightsItemListTable
   * @description displays a list of insight item.
   *
   * @param {Object} insightsItemList - The insights item list to be displayed.
   * @param {Function} onNavigateToItem - a function invoked when the user attempts to navigate to an item.
   *   Invoked with {isNew, item, section}.
   * @param {String} orgId - the organization id
   */
  angular
    .module('binky.widgets.insights.insights-item-list-table')
    .component('binkyInsightsItemListTable', {
      bindings: {
        contractInfoList: '<?',
        contractList: '<',
        insightsItemList: '<',
        onNavigateToItem: '&?',
        orgId: '<?',
        productList: '<',
      },
      controller,
      templateUrl:
        'widgets/insights/insights-item-list-table/insights-item-list-table.component.html',
    });

  // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
  function controller(
    _,
    $translate,
    CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID,
    INSIGHTS_ITEM,
    InsightsItemAccess,
    panelManager,
    translationUtils
  ) {
    const vm = this;

    _.assign(vm, {
      getActionLabel,
      getDescription,
      getName,
      getPrimaryActionDescription,
      onClickAction,
      onClickItem,
      onSuccessCreateInsightsItemDetail,
      showActionLabel,
    });

    function getActionLabel(primaryAction) {
      return $translate.instant(`binky.widgets.insights.actionLabel.${_.toLower(primaryAction)}`);
    }

    function getDescription(insightsItem) {
      return (
        insightsItem.description ||
        translationUtils.sanitizeSafeInstant(insightsItem.descriptionKey)
      );
    }

    function getName(insightsItem) {
      return insightsItem.name || translationUtils.sanitizeSafeInstant(insightsItem.nameKey);
    }

    function getPrimaryActionDescription(insightsItem) {
      return (
        insightsItem.primaryActionDescription ||
        translationUtils.sanitizeSafeInstant(insightsItem.primaryActionDescriptionKey)
      );
    }

    function onClickAction(insightsItem) {
      if (insightsItem.primaryAction === INSIGHTS_ITEM.ACTION.CREATE) {
        panelManager.open(CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID, {insightsItem, orgId: vm.orgId});
      }
    }

    function onClickItem(insightsItem) {
      navigateToItem(insightsItem, false);
    }

    function onSuccessCreateInsightsItemDetail(insightsItem) {
      navigateToItem(insightsItem, true);
    }

    function showActionLabel(insightsItem) {
      const primaryAction = insightsItem.primaryAction;
      return (
        primaryAction &&
        insightsItem.actions[primaryAction] &&
        InsightsItemAccess.checkAccess(insightsItem.actions[primaryAction].access, {
          contractList: vm.contractList,
          orgId: vm.orgId,
          productList: vm.productList,
        })
      );
    }

    /////////////

    function navigateToItem(item, isNew) {
      _.invoke(vm, 'onNavigateToItem', {
        isNew,
        item,
        section: vm.insightsItemList.insightsSection,
      });
    }
  }
})();
