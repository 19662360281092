(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc provider
   * @name binky.core.api:kmiProvider
   * @description
   *    Service that calls Key Management Interface (kmi)
   *    to retrieve the asset encryption status of an organization.
   */
  angular.module('binky.core.api.kmi').provider('kmi', kmiProvider);

  function kmiProvider() {
    let url;
    this.$get = $get;
    this.configure = configure;

    function configure(config) {
      url = `${config.url}`;
    }

    /* @ngInject */
    function $get(_, $resource, apiUtils) {
      const service = {
        orgEncryption: orgEncryption(),
      };

      return service;

      ///////////////

      function orgEncryption() {
        return $resource(
          `${url}/organizations/:orgId/encryption`,
          {
            orgId: () => _.split(apiUtils.getActiveOrg(), '@').join('-'),
          },
          {
            enableEncryption: {
              method: 'POST',
            },
            getOrgEncryptionStatus: {
              method: 'GET',
            },
            revokeEncryption: {
              method: 'PUT',
            },
          }
        );
      }
    }
  }
})();
