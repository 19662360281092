import {ActionButton, Content, Dialog, DialogTrigger, Flex, View} from '@adobe/react-spectrum';
import CheckmarkIcon from '@spectrum-icons/workflow/Checkmark';
import ChevronDownIcon from '@spectrum-icons/workflow/ChevronDown';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useWorkspaceNavContext} from '../workspace/nav/WorkspaceNavContext';

import styles from './NavItemPopover.pcss';
import {HEADER_NAV, WORKSPACE_NAV} from './NavItemPopoverConstants';

/**
 * Action menu for picking an alternate nav item to route to.
 */
const NavItemPopover = ({
  colorScheme = 'dark',
  context = HEADER_NAV,
  groupName,
  navItems,
  selectedKey,
}) => {
  const intl = useIntl();

  const selectedKeyPath = context === HEADER_NAV ? 'state.name' : 'key';
  const selectedKeyExistInItems =
    navItems.filter((item) => get(item, selectedKeyPath) === selectedKey).length > 0;

  const navContextProperties = useWorkspaceNavContext();

  const getAnchorClassName = () =>
    colorScheme === 'dark' ? styles['nav-item-anchor-dark'] : styles['nav-item-anchor-light'];

  const buttonLabel =
    context === HEADER_NAV
      ? intl.formatMessage({id: 'binky.shell.nav.navItemPopover.label'})
      : groupName;

  const onClickHandler = (e, item) => {
    if (context === WORKSPACE_NAV) {
      navContextProperties.navigateUsingItem(e, item);
    }
  };

  return (
    <DialogTrigger type="popover">
      <ActionButton
        aria-label={intl.formatMessage({id: 'binky.shell.nav.navItemPopover.ariaLabel'})}
        isQuiet
        //  eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Styling the action button
        UNSAFE_className={classNames(
          !groupName && styles['nav-item-popover-button'],
          selectedKeyExistInItems && colorScheme === 'dark' ? styles['selected-dark'] : '',
          selectedKeyExistInItems && colorScheme === 'light' ? styles['selected-light'] : '',
          groupName && styles['group-nav-item']
        )}
      >
        {buttonLabel}
        <ChevronDownIcon aria-hidden />
      </ActionButton>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Styling the popover to remove negative space */}
      <Dialog UNSAFE_className={styles.popover}>
        <Content>
          {/* eslint-disable-next-line react/forbid-dom-props -- Removing list styling */}
          <ul style={{listStyle: 'none', paddingInlineStart: 0}}>
            {navItems.map((item) => {
              const itemKey = get(item, selectedKeyPath);
              const dataTestId = context === HEADER_NAV ? `nav-item-${itemKey}` : itemKey;
              return (
                // eslint-disable-next-line react/forbid-dom-props -- Applying simple margin
                <li key={itemKey} style={{marginBottom: '5px'}}>
                  <Flex alignItems="center" gap="size-100">
                    <a
                      aria-current={itemKey === selectedKey ? 'page' : false}
                      className={getAnchorClassName()}
                      data-testid={dataTestId}
                      href={item.getHrefForState?.() || item.href}
                      onClick={(e) => onClickHandler(e, item)}
                    >
                      {context === HEADER_NAV && intl.formatMessage({id: item.name})}
                      {context === WORKSPACE_NAV && item.name}
                    </a>
                    {itemKey === selectedKey && (
                      <View marginEnd="size-100" marginStart="auto">
                        <CheckmarkIcon color="informative" />
                      </View>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

NavItemPopover.propTypes = {
  /**
   * Color scheme that is currently applied. Defaults to 'dark'.
   */
  colorScheme: PropTypes.oneOf(['dark', 'light']),
  /**
   * Context that this component is being used in. Defaults to HEADER_NAV.
   */
  context: PropTypes.oneOf([HEADER_NAV, WORKSPACE_NAV]),
  /**
   * Group name to apply to the popover button label.
   */
  groupName: PropTypes.string,
  /**
   * Nav items to display in the menu
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  navItems: PropTypes.arrayOf(PropTypes.object),
  /**
   * Key of the selected nav item to show indicator if included in this components nav items.
   */
  selectedKey: PropTypes.string,
};
export default NavItemPopover;
