(function () {
  'use strict';
  /**
   * @deprecated use src2/shell/panels/drawer/header/Header.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.drawer-header.drawer-header-title:binkyDrawerHeaderTitle
   *
   * @description The header for a binky-drawer.
   *
   * @param {String} headerTitle The title for the drawer.
   * @param {String} [variant] Variant = "icon" to style header which contains an avatar/icon. Optional.
   */
  angular
    .module('binky.widgets.common.drawer-header.drawer-header-title')
    .component('binkyDrawerHeaderTitle', {
      bindings: {
        headerTitle: '@',
        variant: '@?',
      },
      templateUrl:
        'widgets/common/drawer-header/drawer-header-title/drawer-header-title.component.html',
    });
})();
