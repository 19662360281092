import {Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ChartErrorScreen from 'common/components/etla-usage-report/chart-and-select/chart-error-screen/ChartErrorScreen';

// Error screen for the initial data fetch of the EtlaUsageReport Component
const TopErrorScreen = ({errorStatus}) => {
  const intl = useIntl();
  const errorMessageArray = [];
  switch (errorStatus) {
    case 400:
      errorMessageArray.push({
        id: 'badRequest.line1',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.badRequest.line1',
        }),
      });
      errorMessageArray.push({
        id: 'badRequest.line2',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.badRequest.line2',
        }),
      });
      break;
    case 401:
    case 403:
      errorMessageArray.push({
        id: 'noAccessPermission',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.noAccessPermission',
        }),
      });
      break;
    case 404:
      errorMessageArray.push({
        id: 'notFound.line1',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.notFound.line1',
        }),
      });
      errorMessageArray.push({
        id: 'notFound.line2',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.notFound.line2',
        }),
      });
      break;
    case 410:
      errorMessageArray.push({
        id: 'contractExpired.line1',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.contractExpired.line1',
        }),
      });
      errorMessageArray.push({
        id: 'contractExpired.line2',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.contractExpired.line2',
        }),
      });
      break;
    default:
      errorMessageArray.push({
        id: 'otherError.line1',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.otherError.line1',
        }),
      });
      errorMessageArray.push({
        id: 'otherError.line2',
        message: intl.formatMessage({
          id: 'binky.widgets.insights.dashboard.errorMessage.otherError.line2',
        }),
      });
  }
  const errorViewHeading = intl.formatMessage({
    id: 'binky.widgets.insights.dashboard.errorView.title',
  });
  return (
    <View
      backgroundColor="gray-50"
      borderRadius="small"
      height="size-2000"
      padding="size-100"
      position="relative"
    >
      <Heading>{errorViewHeading}</Heading>
      <View>
        <ChartErrorScreen errorMessageArray={errorMessageArray} />
      </View>
    </View>
  );
};

TopErrorScreen.propTypes = {
  /**
   *  Error response status code which determines the error message
   */
  errorStatus: PropTypes.number.isRequired,
};

export default TopErrorScreen;
