import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';

import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';
import modelCache from 'services/cache/modelCache/modelCache';
import feature from 'services/feature';
import log from 'services/log';
import {getListCacheKey} from 'utils/cacheUtils';
import {generatePatches, get} from 'utils/patchUtils';

import {
  PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE,
  PROFILE_PERMISSIONS_SUB_CONTENT_TYPE,
} from './LicenseGroupConfigurationsConstants';
import {areLicenseGroupConfigurationsInvalid} from './licenseGroupConfigurationsUtils';

const LICENSE_GROUP_CONFIGURATIONS_2_CACHE_ID = 'LicenseGroupConfigurations2';
// to go from a select value to the parent, we need to go three levels up the object
const PATH_DIFFERENCE_TO_PARENT = 3;
// the ANGELS API uses non-standard PATCHes where an array identifier is the id value, rather than index
const resourceId = (resource) => ('id' in resource ? resource.id : '');

class LicenseGroupConfigurations {
  /**
   * @description Method to retrieve an existing LicenseGroupConfigurations from back-end data store.
   *
   * @param {Object} options
   * @param {String} options.orgId - Id of the org
   * @param {String} options.productId - Id of the product
   * @param {String} options.licenseGroupId - Id of the license group group
   * @param {Boolean} options.usesLicenseGroupConfiguration - True to use the license group configuration API, otherwise use the permissions API. Default: true
   *
   * @returns {Promise<LicenseGroupConfigurations>} The license group configurations
   */
  static get(options) {
    const model = new LicenseGroupConfigurations(options);

    const key = model.key();
    if (modelCache.has(LICENSE_GROUP_CONFIGURATIONS_2_CACHE_ID, key)) {
      const cachedPromise = modelCache.get(LICENSE_GROUP_CONFIGURATIONS_2_CACHE_ID, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    return model.refresh();
  }

  /**
   * @description Creates a new LicenseGroupConfigurations.
   *
   * @param {Object} options
   * @param {String} options.orgId - Id of the org
   * @param {String} options.productId - Id of the product
   * @param {String} options.licenseGroupId - Id of the license group
   * @param {Array} options.configurations - Array of configurations, default empty array
   * @param {Boolean} options.usesLicenseGroupConfiguration - True to use the license group configuration API, otherwise use the permissions API. Default: true
   */
  constructor({
    orgId,
    productId,
    licenseGroupId,
    configurations = [],
    usesLicenseGroupConfiguration = true,
  }) {
    this.orgId = orgId;
    this.productId = productId;
    this.licenseGroupId = licenseGroupId;
    this.configurations = configurations;
    this.usesLicenseGroupConfiguration = usesLicenseGroupConfiguration;
    this.registerSavedState();

    if (usesLicenseGroupConfiguration) {
      this.getApi = jilOrganizationsProductsLicenseGroups.getLicenseGroupConfigurations;
      this.patchApi = jilOrganizationsProductsLicenseGroups.patchLicenseGroupConfigurations;
      this.putApi = jilOrganizationsProductsLicenseGroups.putLicenseGroupConfigurations;
    } else {
      this.getApi = jilOrganizationsProductsLicenseGroups.getLicenseGroupPermissions;
      this.patchApi = jilOrganizationsProductsLicenseGroups.patchLicenseGroupPermissions;
      this.putApi = jilOrganizationsProductsLicenseGroups.putLicenseGroupPermissions;
    }
  }

  async #putInner(configurations) {
    let response;
    try {
      const options = {
        licenseGroupId: this.licenseGroupId,
        orgId: this.orgId,
        productId: this.productId,
      };
      response = await this.putApi(options, configurations);
    } catch (error) {
      log.error(
        `LicenseGroupConfigurations.#putInner(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }
    return Promise.resolve(response);
  }

  /**
   * @description Determines if there are any unsaved changes to this LicenseGroupConfigurations.
   *
   * @returns {Boolean} - true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return !isEqual(this.configurations, this.savedState);
  }

  /**
   * @description Determines whether the current state of configurations is invalid.
   *
   * @returns {Boolean} - true if the current configurations is invalid, false if valid
   */
  isInvalid() {
    return areLicenseGroupConfigurationsInvalid(this.configurations);
  }

  key() {
    return getListCacheKey(
      omitBy(
        {licenseGroupId: this.licenseGroupId, orgId: this.orgId, productId: this.productId},
        (e) => e === undefined
      )
    );
  }

  /**
   * @description Assign a sortIndex to each include configuration in the list in order to perserve the initial ordering of the configurations. This allows them to be removed and then re-added back into their original index
   */
  memoizeSortOrders() {
    this.configurations = this.configurations.map((configuration) => ({
      ...configuration,
      sections: configuration.sections.map((section) => ({
        ...section,
        content: section.content.map((content) => ({
          ...content,
          content: content.content.map((subcontent) => ({
            ...subcontent,
            values:
              // only assign sortIndexes to the 'include' type configurations
              subcontent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.INCLUDE
                ? subcontent.values.map((value, index) => ({
                    ...value,
                    sortIndex: index,
                  }))
                : subcontent.values,
          })),
        })),
      })),
    }));
  }

  /**
   * @description Save a new LicenseGroupConfigurations to the back-end.
   *
   * @returns {Promise<LicenseGroupConfigurations>} resolves to fetched LicenseGroupConfigurations instance, else rejects with error message
   */
  async put() {
    try {
      if (feature.isDisabled('temp_put_config_array')) {
        // Because of a bug in the JIL API, we need to send a separate request per configuration
        const requests = this.configurations.map((configuration) => this.#putInner(configuration));
        await Promise.all(requests);
      } else {
        await this.#putInner(this.configurations);
      }
    } catch (error) {
      log.error(
        `LicenseGroupConfigurations.put(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }
    return Promise.resolve(this);
  }

  /**
   * @description Refresh this LicenseGroupConfigurations from the back-end.
   *
   * @returns {Promise<LicenseGroupConfigurations>} resolves to fetched LicenseGroupConfigurations instance, else rejects with error message
   */
  async refresh() {
    let response;
    try {
      const options = {
        licenseGroupId: this.licenseGroupId,
        orgId: this.orgId,
        productId: this.productId,
      };
      response = await this.getApi(options);
    } catch (error) {
      log.error(
        `LicenseGroupConfigurations.refresh(): Failed to retrieve data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    // the /configurations endpoint returns an array, while /permissions returns a single object
    this.configurations = this.usesLicenseGroupConfiguration ? response.data : [response.data];

    this.memoizeSortOrders();
    this.registerSavedState();

    const key = this.key();
    modelCache.put(LICENSE_GROUP_CONFIGURATIONS_2_CACHE_ID, key, Promise.resolve(this));

    return Promise.resolve(this);
  }

  /**
   * @description Updates model with a nested form of itself recording state which may be later modified
   */
  registerSavedState() {
    this.savedState = cloneDeep(this.configurations);
  }

  /**
   * @description Determines whether these configurations require configuration before they can be saved.
   *
   * @returns {Boolean} true if configuration is required
   */
  requiresConfiguration() {
    return areLicenseGroupConfigurationsInvalid(this.savedState);
  }

  /**
   * @description Restores the LicenseGroupConfigurations from its saved state.
   */
  restore() {
    if (this.hasUnsavedChanges()) {
      this.configurations = cloneDeep(this.savedState);
    }
  }

  /**
   * @description Save configurations changes to the back-end.
   *
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message
   */
  async save() {
    if (!this.hasUnsavedChanges()) {
      return Promise.resolve(this);
    }

    // assemble the PATCH document
    const patchOperations = generatePatches(this.savedState, this.configurations, {
      getResourceId: resourceId,
    })
      // filter out the false selected operation as per the ANGELS API: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=NGL&title=ANGELS+API+For+AM+UI+-+PATCH+Lab+Permissions
      .filter((op) => {
        if (feature.isEnabled('bug_fix_abps_37943')) {
          return !(
            op.op === 'replace' &&
            op.path.endsWith('/selected') &&
            !op.value &&
            isTargetParentSelect(this.configurations, op.path)
          );
        }
        return !(op.op === 'replace' && op.path.endsWith('/selected') && !op.value);
      })
      // filter out any operation on the sortIndex
      .filter((op) => !op.path.endsWith('/sortIndex'));

    try {
      const options = {
        licenseGroupId: this.licenseGroupId,
        orgId: this.orgId,
        productId: this.productId,
      };
      await this.patchApi(options, patchOperations);
    } catch (error) {
      log.error(
        `LicenseGroupConfigurations.save(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    // need to refresh as ANGELS will assign an ID to new values, which we need to pull in
    return this.refresh();
  }
}

/**
 * @description Determines whether resource type is expandable or expandableSelect
 * @returns {Boolean} - true if the type is expandable or expandableSelect, false if not
 */
function isTargetParentSelect(resource, key) {
  // have to .slice(1) to get rid of the empty item at the start
  const keys = key.split('/').slice(1);
  if (keys.length <= PATH_DIFFERENCE_TO_PARENT) {
    return false;
  }
  // go three levels up so that we are inspecting the object containing the select values
  const pathToParent = keys.slice(0, keys.length - PATH_DIFFERENCE_TO_PARENT);
  const item = get(resource, pathToParent, resourceId);
  // return true if it is a expandable select or select
  return (
    item &&
    (item.type === PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE.EXPANDABLE_SELECT ||
      item.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.SELECT)
  );
}

export default LicenseGroupConfigurations;
