const COUNTRY_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.country.COUNTRY_CONSTANTS;
/**
 * @deprecated Please use src2/core/models/country/CountryConstants.js
 */
(function () {
  /**
   * @ngdoc constant
   * @name Country constants
   * @description defines country constants
   */
  angular
    .module('binky.core.common.country')
    .constant('COUNTRY_LIST_CACHE_ID', COUNTRY_CONSTANTS.COUNTRY_LIST_CACHE_ID)
    .constant('COUNTRY_TO_LOCALE_MAP', COUNTRY_CONSTANTS.COUNTRY_TO_LOCALE_MAP)
    .constant('DEFAULT_COUNTRY_LOCALE', COUNTRY_CONSTANTS.DEFAULT_COUNTRY_LOCALE)
    .constant('REGION_LIST_CACHE_ID', COUNTRY_CONSTANTS.REGION_LIST_CACHE_ID);
})();
