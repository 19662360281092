import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL product arrangements APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Products api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Fetches all product metadata
 *
 * @param {String} orgId - The org id
 * @param {String} productArrangementCode - The product arrangement code
 * @param {String} [productId] - The product id
 */
const getMetadata = async ({orgId, productArrangementCode, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/product-arrangements/${productArrangementCode}/metadata/${productId}`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response.data;
};

const jilProductArrangements = {configure, getMetadata};

export default jilProductArrangements;
