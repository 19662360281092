import {Text} from '@adobe/react-spectrum';
import {DirectoryDomainEnforcementStatus} from '@pandora/react-directory-domain-enforcement-status';
import PropTypes from 'prop-types';
import React from 'react';

const EN_DASH = '\u2013';

// Content of the domain enforcement cell inside of directory table.
const DomainEnforcementStatus = ({directory}) => {
  const status = directory?.domainEnforcement?.state;
  // Display "-" for all statuses other than NOTIFIED, ACTIVATED, ENFORCED, and DEACTIVATED.
  const shouldShowStatus =
    !!status && ['NOTIFIED', 'ACTIVATED', 'ENFORCED', 'DEACTIVATED'].includes(status);

  const enablementDate = directory?.domainEnforcement?.stateEndsAt
    ? new Date(directory?.domainEnforcement?.stateEndsAt)
    : new Date();

  return shouldShowStatus ? (
    <DirectoryDomainEnforcementStatus
      enablementDate={enablementDate}
      state={directory?.domainEnforcement?.state}
      UNSAFE_style={{fontStyle: 'normal'}}
    />
  ) : (
    <Text marginStart="size-150">{EN_DASH}</Text>
  );
};

DomainEnforcementStatus.propTypes = {
  /**
   * Directory info to display.
   */
  directory: PropTypes.shape({
    domainEnforcement: PropTypes.shape({
      /**
       * Status state.
       */
      state: PropTypes.oneOf(['NOTIFIED', 'ACTIVATED', 'ENFORCED', 'DEACTIVATED', 'NEW'])
        .isRequired,
      stateEndsAt: PropTypes.number,
    }),
  }),
};

export default DomainEnforcementStatus;
