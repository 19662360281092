//
// App Configuration - navigation menus
//
(function () {
  'use strict';
  /**
   * @deprecated
   */
  angular.module('binky.shell.navigation.ready').provider('navReady', navReady);

  /* @ngInject */
  function navReady(_, configurationReadyProvider) {
    const ngInjector = angular.injector(['ng']);
    const initQ = ngInjector.get('$q');
    const configDeferred = initQ.defer();

    let configuredRunReadyWaitNames = [];

    this.configure = configure;
    this.whenConfigReady = whenConfigReady;

    this.$get = $get;

    ////////

    /**
     * @description Method to coordinate and retrieve configuration data needed
     *   prior to navigation initialization/construction.
     * @returns {Promise} resolves when navigation configuration data has been
     *   retrieved/set, otherwise rejects if error encountered in configuration
     *   provider
     */
    function configure() {
      configurationReadyProvider
        .whenConfigReady()
        .then((configData) => {
          configuredRunReadyWaitNames = _.get(configData, 'ready.navigation.runReadyWaitOn', []);
          configDeferred.resolve();
        })
        .catch(configDeferred.reject);

      return configDeferred.promise;
    }

    /**
     * @description Method to expose configuration readiness of navigation.
     *   Outside services/providers can use this method to coordinate
     *   application set-up steps.
     * @returns {Promise} resolves when navigation configuration data has been
     *   retrieved/set, otherwise rejects if error encountered in configuration
     *   provider
     */
    function whenConfigReady() {
      return configDeferred.promise;
    }

    /* @ngInject */
    function $get($injector, $q, authenticationReady, featureReady, navAssembler) {
      const deferred = $q.defer();
      return {run, whenRunReady};

      ////////

      /**
       * @description Method to coordinate building of navigation system. Will
       *   wait for any dependent run ready providers to become ready prior to
       *   building navigation.
       * @returns {Promise} resolves when navigation system is built, else
       *   rejects if error encountered while building navigation
       */
      function run() {
        // iterate through map and initialize/assemble NavItemList models
        whenConfigReady()
          .then(waitOnDependentRunReadyProviders)
          .then(navAssembler.buildNavigation)
          .then(deferred.resolve)
          .catch(deferred.reject);

        return deferred.promise;

        ////////

        /**
         * @description Method to wait on navigation configuration to complete,
         *   then for any run ready providers to become run ready. This is used
         *   to coordinate building the navigation system, ensuring that any
         *   necessary set-up steps occur prior to build.
         * @returns {Promise} resolves when configuration completes and
         *   dependent providers become run ready, rejects if any errors during
         *   configuration or provider run readiness
         */
        function waitOnDependentRunReadyProviders() {
          return $q.all([
            ..._(configuredRunReadyWaitNames)
              .map((runReadyWaitName) => $injector.get(runReadyWaitName))
              .invokeMap('whenRunReady')
              .value(),
            authenticationReady.whenRunReady(),
            featureReady.whenRunReady(),
          ]);
        }
      }

      /**
       * @description Method to expose run readiness of navigation. Outside
       *   services/providers can use this method to coordinate application run
       *   steps.
       * @returns {Promise} resolves when navigation system is built, else
       *   rejects if error encountered while building navigation
       */
      function whenRunReady() {
        return deferred.promise;
      }
    }
  }
})();
