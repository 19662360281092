import {Text, View} from '@adobe/react-spectrum';
import CheckmarkIcon from '@spectrum-icons/workflow/Checkmark';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './BulletListItem.pcss';

/* An item in a list where a spectrum icon is used as a bullet */
const BulletListItem = ({children, Icon, useIconColor, variant}) => {
  let BulletIcon;

  if (typeof Icon === 'object') {
    // when passing an icon as a node
    BulletIcon = Icon;
  } else {
    // if passing in as a react hook such as spectrum icons
    const SpectrumIcon = Icon || CheckmarkIcon;
    BulletIcon = <SpectrumIcon color={variant} flexShrink="0" marginTop="size-25" size="S" />;
  }

  return (
    <View
      elementType="li"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
      UNSAFE_className={styles['bullet-list-item']}
    >
      {/* <BulletIcon color={variant} flexShrink="0" marginTop="size-25" size="S" /> */}
      {BulletIcon}
      <Text
        UNSAFE_style={
          variant && useIconColor && {color: `var(--spectrum-semantic-${variant}-color-icon)`}
        }
      >
        {children}
      </Text>
    </View>
  );
};

BulletListItem.propTypes = {
  /**
   * Content of each bulletted list
   */
  children: PropTypes.node.isRequired,
  /**
   * The icon to use as a bullet point. If passed in as a hook, it wil have default props appropriateThe default is a checkmark
   */
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * If true, the corresponding text will match the color of the icon when variant is passed in.
   */
  useIconColor: PropTypes.bool,
  /**
   * The semantic variant of the icon and corresponding information. uses sepectrum semantic colors https://spectrum.adobe.com/page/color/#Semantic-colors
   */

  variant: PropTypes.oneOf(['negative', 'notice', 'positive', 'informative']),
};

export default BulletListItem;
