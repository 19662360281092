/**
 * Utility functions for downloading files.
 */
import axios from 'axios';

import authentication from 'services/authentication';
import log from 'services/log';

import {csvBlobTransformer} from '../api/utils/apiUtils';

/**
 * @description Method to download a file within the browser.
 * @param {Object} fileData - reference to the file data to download
 * @param {String} fileName - name to assign to download
 */
function download(fileData, fileName) {
  let processedFile;
  if (fileName.split('.')[1] === 'csv') {
    // Add Unicode BOM so that the csv file can render non-English characters
    processedFile = new Blob(['\uFEFF', fileData], {type: 'text/csv;charset=utf-8'});
  } else {
    processedFile = fileData;
  }
  const url = URL.createObjectURL(processedFile);
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  anchor.target = '_self';

  anchor.click();

  // This is in a timeout because otherwise Firefox revokes the URL
  // before it manages to pop its own download dialog.
  setTimeout(() => {
    anchor.remove();
    URL.revokeObjectURL(url);
  }, 100);
}

/**
 * @description Method to download an export data file within the browser.
 * @param {Object} csvData - reference to file data to download
 * @param {String} fileName - name to assign to download
 */
function downloadExportData(csvData, fileName) {
  if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
    log.info('Safari user detected: rendering CSV data in current tab');
  }
  download(csvData, fileName);
}

/**
 * @description Method to fetch and download an export data file within the browser.
 * @param {Object} options - the parameter object including the following:
 * @param {String} options.url - the url/path to the download file
 * @param {Object} [options.headers] - additional headers to pass in with the GET call
 * @param {String} options.clientId - the identifier of the consuming application
 * @param {Object} [options.params] - parameters to pass into the GET call
 * @param {String} options.fileName - name to assign to the download file
 */
async function fetchAndDownloadExportData(options) {
  const {clientId, fileName, headers, params, url} = options;
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for fetching the file data.');
  }
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${tokenObject.token}`,
      'X-Api-Key': clientId,
      ...headers,
    },
    params,
  });
  return downloadExportData(csvBlobTransformer(response)?.data?.file, fileName);
}

export {download, downloadExportData, fetchAndDownloadExportData};
