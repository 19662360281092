(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.fulfillment-events.refresh-manager')
    .constant('FULFILLMENT_EVENT_REFRESH_MANAGER_EVENT', {
      UPDATE: 'UPDATE',
    })
    .constant('FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS', {
      EXTENDED_INTERVAL: 30000,
      INTERVAL: 10000,
      MAX_EXPONENTIAL_BACKOFF: 1200000,
      MAX_REFRESH_ATTEMPTS: 120,
      MODE: {
        DEFAULT: 'DEFAULT',
        EXPONENTIAL_BACKOFF: 'EXPONENTIAL_BACKOFF',
      },
    });
})();
