import {feature, loadJs, log} from '@admin-tribe/binky';
import {Link} from '@adobe/react-spectrum';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

const CookiePreferences = (linkProps) => {
  const intl = useIntl();

  const FOOTER_LINK_SELECTOR = '#openCookieModal .one-trust';
  const FOOTER_LINK_CLASS_NAMES = 'one-trust';

  // Fetch the privacy library
  useEffect(() => {
    downloadPrivacyLib();
  }, []);

  async function downloadPrivacyLib() {
    // If the privacy library is already loaded then we are in fakes mode - do not load the real library.
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- False positive, it is using optional chaining
    if (window?.adobePrivacy?.isFake) {
      return;
    }

    let privacyUrl;
    // Enable developer_test_one_trust to test recent changes made to oneTrust's PrivacyJs
    if (feature.isEnabled('developer_test_one_trust')) {
      // The script is looking for an anchor with class names specified by FOOTER_LINK_SELECTOR.
      // We must ensure FOOTER_LINK_SELECTOR stays in sync with FOOTER_LINK_CLASS_NAMES.
      Object.assign(window, {
        fedsConfig: {
          privacy: {
            footerLinkSelector: FOOTER_LINK_SELECTOR,
            otDomainId: '7a5eb705-95ed-4cc4-a11d-0cc5760e93db-test',
          },
        },
      });

      privacyUrl =
        'https://www.dev02.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js';
    } else {
      // The script is looking for an anchor with class names specified by FOOTER_LINK_SELECTOR.
      // We must ensure FOOTER_LINK_SELECTOR stays in sync with FOOTER_LINK_CLASS_NAMES.
      Object.assign(window, {
        fedsConfig: {
          privacy: {
            footerLinkSelector: FOOTER_LINK_SELECTOR,
            otDomainId: '7a5eb705-95ed-4cc4-a11d-0cc5760e93db',
          },
        },
      });

      privacyUrl =
        'https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js';
    }

    await loadJs(privacyUrl).catch((error) => {
      log.error(`Failed to load script ${privacyUrl}. Error: ${error}`);
    });
  }

  function onPress() {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- False positive, it is using optional chaining
    window?.adobePrivacy?.showConsentPopup?.();
  }

  // The privacy script we're loading is looking for the footer link using FOOTER_LINK_SELECTOR
  // so explicitly add these classes to the generated <a> element.
  return (
    <Link
      {...linkProps}
      id="openCookieModal"
      isQuiet
      onPress={onPress}
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to set class required by onetrust
      UNSAFE_className={FOOTER_LINK_CLASS_NAMES}
    >
      {intl.formatMessage({id: 'binky.common.cookiePreferences.label'})}
    </Link>
  );
};

export default CookiePreferences;
