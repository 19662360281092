import jilOrganizationsMigrations from 'api/jil/jilOrganizationsMigrations';
import log from 'services/log';

const MIGRATION_POLICY_NAMES = {
  STUDENT_ASSET: 'STUDENT_ASSET',
};
const MIGRATION_POLICY_VALUES = {
  OFF: 'OFF',
  ON: 'ON',
};

class MigrationPolicy {
  /**
   * @description Method to fetch a MigrationPolicy.
   * @param {Object} options as described below
   * @param {String} options.orgId - ID of organization for which the MigrationPolicy should be fetched.
   * @param {String} options.name - the name of the policy to fetch
   * @param {String} options.value - the value of the policy
   * @returns {Promise<MigrationPolicy>} This MigrationPolicy object or a Rejected promise when the refresh fails.
   */
  static async get(options) {
    const model = new MigrationPolicy(options);
    await model.refresh();
    return model;
  }

  /**
   * @description Method to create a new instance of a MigrationPolicy object.
   * @param {Object} options - options to config the new MigrationPolicy object.
   * @param {String} options.orgId - associates MigrationPolicy instance with an organization.
   * @param {String} options.name - the name of the policy to fetch
   * @param {Boolean} options.value - the value of the policy
   */
  constructor(options) {
    this.orgId = options.orgId;
    this.name = options.name;
    this.value = options.value;
  }

  /**
   * @description Method to get the current policy of an organization.
   * @returns {Promise<MigrationPolicy>} A MigrationPolicy object or a Reject promise when it cannot be fetched.
   */
  async refresh() {
    try {
      const response = await jilOrganizationsMigrations.getMigrationPolicy({
        name: this.name,
        orgId: this.orgId,
      });
      this.value = response.data.value;
    } catch (error) {
      log.error(`Could not fetch migration policy for ${this.orgId}:${this.name}. Error: ${error}`);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to save changes to the policy
   * @returns {Promise<MigrationPolicy>} This MigrationPolicy object or a Rejected promise when the update fails.
   */
  async save() {
    try {
      const response = await jilOrganizationsMigrations.putMigrationPolicy({
        name: this.name,
        orgId: this.orgId,
        policy: {
          name: this.name,
          value: this.value,
        },
      });
      this.value = response.data.value;
    } catch (error) {
      log.error(
        `Could not update migration policy for ${this.orgId}:${this.name}. Error: ${error}`
      );
      return Promise.reject(error);
    }
    return this;
  }
}

export {MIGRATION_POLICY_NAMES, MIGRATION_POLICY_VALUES};
export default MigrationPolicy;
