(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-accordion
   * @description A product-accordion meant to wrap one or more 'binky-product-accordion-item'
   */
  angular.module('binky.widgets.products.product-accordion', [
    'binky.core.lodash',
    'binky.widgets.products.product-accordion-item',
  ]);
})();
