(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum Picker
   *
   * @ngdoc directive
   * @name binky.widgets.common.select:binkySelect
   *
   * @description a wrapper around the native select widget
   *
   * @param {Boolean} [isDisabled] Default false. If true, the select is disabled.
   * @param {Boolean} [disableSorting] By default, false, and the items are ordered by the nameKey.
   * @param {Object} [filter] The filter object to apply to the repeat
   * @param {Array} items The set of items to repeat over inside this list.
   * Each item has {id: <selected value>, name: <text to display>, disabled: <the item is shown, but cannot be selected>}.
   * The 'id' and 'name' properties may change when 'valueKey' and 'nameKey' are specified.
   * @param {String} [label] The label for the select component. Required for all new usages.
   * @param {String} nameKey The field key to use instead of 'name' for each item
   * @param {Function} [onChange] A function to call when the selected item for this list changes,
   *     which will be passed a 'selection' parameter. Must be of the form 'changeFunc(selection)'.
   * @param {String} [placeholder] A display label if item's display name is not specified
   * @param {String} selectedOption The value to match against on initial load, and this is updated as selection changes
   * @param {String} valueKey The field key to use instead of 'id' for each item
   * @param {Boolean} [variant] If 'quiet' there is no background color or focus for the select.
   *
   * @example <binky-select items="orgs" name-key="name" selected-option="selectedOption" value-key="id" on-change="onChange(selection)"></binky-select>
   */
  angular.module('binky.widgets.common.select').directive('binkySelect', binkySelectDirective);

  function binkySelectDirective(_) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        disableSorting: '<?',
        filter: '<?',
        isDisabled: '<?',
        items: '<',
        label: '@?', // should be require for all new usages
        nameKey: '@',
        onChange: '&?',
        placeholder: '<?',
        selectedOption: '=',
        valueKey: '@',
        variant: '@?',
      },
      templateUrl: 'widgets/common/select/select.directive.html',
    };

    return directive;

    /////////////////

    function linkFn(scope) {
      scope.selectId = _.uniqueId('binky-select-');
      scope.option = {
        currentSelection: scope.selectedOption,
        disabled: scope.isDisabled || false,
      };

      scope.onUpdate = function (selection) {
        if (selection) {
          _.invoke(scope, 'onChange', {selection});
          setSelection(selection);
        }
      };

      scope.$watch('isDisabled', (newVal, oldVal) => {
        if (!_.isEqual(newVal, oldVal)) {
          scope.option.disabled = newVal;
        }
      });

      scope.$watch('selectedOption', (newVal, oldVal) => {
        if (!_.isEqual(newVal, oldVal)) {
          scope.onUpdate(newVal);
        }
      });

      setSelection(scope.selectedOption);

      function setSelection(selection) {
        const option = _.find(scope.items, [scope.valueKey, selection]);
        const backupOption = _.find(scope.items, [scope.valueKey, '']);
        const displayName = _.get(option, scope.nameKey);
        if (displayName) {
          scope.display = displayName;
          scope.option.currentSelection = selection;
        } else if (scope.placeholder) {
          scope.display = scope.placeholder;
          scope.option.currentSelection = selection;
        } else if (backupOption) {
          scope.display = backupOption[scope.nameKey];
          scope.option.currentSelection = '';
        }
      }
    }
  }
})();
