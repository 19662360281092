import {showError, showSuccess} from 'shell/toasts/toastManager';

/**
 * @description Copies passed value to clipboard and shows success or error toast appropriately.
 *
 * @param {Object} opts.intl react-intl
 * @param {String} opts.successMessage Optional message to show in success toast.
 * @param {String} opts.value The string to copy to the clipboard.
 */
const copyWithAlertToast = async ({intl, successMessage, value}) => {
  let valueCopied = false;
  if (typeof window !== 'undefined') {
    try {
      await window.navigator.clipboard.writeText(value);
      valueCopied = true;
    } catch (error) {}
  }

  if (valueCopied) {
    showSuccess(
      successMessage || intl.formatMessage({id: 'common.copyToClipboardButton.valueCopied'})
    );
  } else {
    showError(intl.formatMessage({id: 'common.copyToClipboardButton.errorCopying'}));
  }
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {copyWithAlertToast};
