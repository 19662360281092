import axios from 'axios';

import Locale from '../../services/locale/Locale';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Configure JIL Offers APIs
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for JIL offers api
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches offers.
 *
 * @deprecated no longer used infavor of jilOrganizationsOffers.getOffers(PORES)
 *
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the requested offers.
 */
const getOffers = ({...params}) => {
  const allParams = Object.assign(params, {locale: Locale.get().activeLocaleCode});
  return axios.get(`${url}/offers`, {
    headers: getHeaders({clientId, includeRoles}),
    params: allParams,
  });
};

/**
 * @description Method for fetching offers by comma separated offer ids.
 *
 * @deprecated use aosOffers.getOffersByOfferId instead
 *
 * @param {Object} params - Top level wrapper object containing params for the
 *     JIL call.
 *
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the requested offers.
 */
const getOffersByOfferId = ({...params}) => {
  const allParams = Object.assign(params, {locale: Locale.get().activeLocaleCode});
  const {offerIds, ...queryParams} = allParams;
  return axios.get(`${url}/offers/${offerIds}`, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Fetches offers by sku.
 *
 * @deprecated use aosOffers.getOffersBySku instead
 *
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the offers that match the requested sku.
 */
const getOffersBySku = ({...params}) => {
  const allParams = Object.assign(params, {locale: Locale.get().activeLocaleCode});
  return axios.get(`${url}/offers:search.sku`, {
    headers: getHeaders({clientId, includeRoles}),
    params: allParams,
  });
};

const jilOffers = {
  configure,
  getOffers,
  getOffersByOfferId,
  getOffersBySku,
};

export default jilOffers;
