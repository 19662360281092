(function () {
  /**
   * @deprecated Migrated to GlobalAdminPolicy.js
   *
   * @ngdoc factory
   * @name binky.core.globalAdmin:GlobalAdminPolicy
   * @description Model for an individual Policy
   */
  angular.module('binky.core.globalAdmin').factory('GlobalAdminPolicy', factory);

  /* @ngInject */
  function factory(_) {
    const ALLOWED_FIELDS = ['defaultValue', 'name', 'value'];

    class GlobalAdminPolicy {
      /**
       * @class
       * @description Creates a new Policy for use.
       *
       * @param {Object} options initialization options
       * @param {String} options.defaultValue the default policy value
       * @param {String} options.name policy name
       * @param {String} options.value the set value
       */
      constructor(options) {
        initModel(this, options);
      }

      static apiResponseTransformer(rawData) {
        return new GlobalAdminPolicy(rawData);
      }
    }

    return GlobalAdminPolicy;

    ////////

    /**
     * @description Initializes Policy data from options.
     *
     * @param {Object} model Policy model Object instance to initialize. See constructor.
     * @param {Object} options Data to initiate the model with. See constructor.
     *
     * @returns {Object} the model with selected values
     */
    function initModel(model, options) {
      const clonedOptions = _.cloneDeep(options);
      return _.assignIn(model, _.pick(clonedOptions, ALLOWED_FIELDS));
    }
  }
})();
