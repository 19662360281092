(function () {
  /**
   * @deprecated - Selection is built-in to the Spectrum components. For a table, see
   *   the TableStore story in binky-ui.
   *
   * @ngdoc factory
   * @name Selection
   * @description Service for tracking view selection state
   */
  angular.module('binky.core.common.selection').factory('Selection', getSelectionModel);

  /* @ngInject */
  function getSelectionModel($rootScope, _, SELECTION_EVENT, SELECTION_STATE) {
    class Selection {
      /** Constructor (with class name) **/
      /**
       * @description Creates a new Selection for use.
       *
       * @param {String} trackBy id
       */
      constructor(trackBy) {
        this.count = 0;
        this.items = [];
        this.trackBy = trackBy || 'id';
        this.map = {};
      }

      /* Public methods (assigned to prototype) */
      deselectAllItems() {
        this.map = {};
        this.count = 0;
        this.items = [];

        emitChangeEvent(this);
      }

      deselectItems(items) {
        let itemsDeselected = 0;

        const itemsList = angular.isArray(items) ? items : [items];

        _.forEach(itemsList, (item) => {
          if (this.isItemSelected(item)) {
            delete this.map[item[this.trackBy]];
            itemsDeselected += 1;
          }
        });

        if (itemsDeselected) {
          this.items = this.toArray();
          this.count = this.items.length;
          emitChangeEvent(this);
        }
      }

      getSelectionStateForItems(items) {
        let state;
        const vm = this;
        // Temporarily disable for https://github.com/Gillespie59/eslint-plugin-angular/issues/449
        const itemsSelected = _.filter(items, (item) => vm.isItemSelected(item));

        if (items.length > 0 && itemsSelected.length === items.length) {
          state = SELECTION_STATE.ALL;
        } else if (itemsSelected.length > 0) {
          state = SELECTION_STATE.SUBSET;
        } else {
          state = SELECTION_STATE.NONE;
        }

        return state;
      }

      isItemSelected(item) {
        return _.has(this.map, item[this.trackBy]);
      }

      selectItems(items) {
        const vm = this;
        let itemsSelected = 0;

        const itemsList = angular.isArray(items) ? items : [items];

        _.forEach(itemsList, (item) => {
          if (!vm.isItemSelected(item)) {
            vm.map[item[vm.trackBy]] = item;
            itemsSelected += 1;
          }
        });

        if (itemsSelected) {
          this.items = this.toArray();
          this.count = this.items.length;
          emitChangeEvent(this);
        }
      }

      toArray() {
        const vm = this;
        return _.map(vm.map);
      }

      toggleItemSelection(item) {
        if (this.isItemSelected(item)) {
          this.deselectItems(item);
        } else {
          this.selectItems(item);
        }
      }
    }

    /** Private functions **/
    /**
     * @description Triggering selection update event
     * @param {Object} model - the object that has been updated
     * @returns {void}
     */
    function emitChangeEvent(model) {
      $rootScope.$emit(SELECTION_EVENT.UPDATE, model);
    }

    /**
     * Return the constructor function
     */
    return Selection;
  }
})();
