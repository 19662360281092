(function () {
  'use strict';
  angular.module('binky.shell.workspace', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.shell.bumper',
    'binky.shell.navigation.nav-manager',
    'binky.shell.navigation.responsive-nav',
    'binky.shell.navigation.tab-nav',
    'binky.shell.panels.manager',
    'binky.shell.workspace.wait-on-error',
    'binky.widgets.common.wait',
  ]);
})();
