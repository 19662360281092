(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.directories.encryption-status
   * @description module that defines directory encryption status
   */
  angular.module('binky.widgets.directories.encryption-status', [
    'binky.core.directories',
    'binky.core.lodash',
  ]);
})();
