(function () {
  /**
   * @deprecated Please use src2/core/services/contact/ContactList.js
   *
   * @ngdoc factory
   * @name binky.core.contact:ContactList
   * @description Model for a contact list
   */
  angular.module('binky.core.contact').factory('ContactList', ContactListModel);

  /* @ngInject */
  function ContactListModel(binkySrc2, CONTACT_LIST_CACHE_ID, modelCache, promiseUtils) {
    // We register the cache size for this class
    modelCache.register(CONTACT_LIST_CACHE_ID, 1);

    /** Private Methods **/

    class ContactList2 extends binkySrc2.services.contact.ContactList {
      constructor(options = {}) {
        super(options);
      }

      refresh() {
        this.$resolved = false;

        this.$promise = promiseUtils.toAngularPromise(super.refresh()).finally(() => {
          this.$resolved = true;
        });
        return this.$promise;
      }

      save(newContactInfo) {
        this.$resolved = false;

        this.$promise = promiseUtils.toAngularPromise(super.save(newContactInfo)).finally(() => {
          this.$resolved = true;
        });
        return this.$promise;
      }

      static get(options = {}) {
        const model = new ContactList2(options);
        model.refresh();
        return model;
      }
    }

    return ContactList2;
  }
})();
