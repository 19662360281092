// Load the library
import './core/services/angularDependencies';

import './colors.pcss';
import './globals.pcss';

import src2 from './binkyUISrc2';

export default src2;
export * from './common';
export * from './core/services';
export * from './features';
export * from './shell';
export * from './test';
