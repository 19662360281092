(function () {
  /**
   * @deprecated Please use src2/core/models/user/AuthenticatedUser.js
   *
   * @ngdoc factory
   * @name AuthenticatedUser
   * @description Model for the AuthenticatedUser an authenticated user has.
   */
  angular
    .module('binky.core.user.authenticated-user')
    .factory('AuthenticatedUser', getAuthenticatedUserModel);

  function getAuthenticatedUserModel(binkySrc2) {
    return binkySrc2.models.user.AuthenticatedUser;
  }
})();
