/* eslint-disable max-lines -- this file requires more lines */
import {
  Contract as PandoraContract,
  TermsAcceptance,
  TermsAcceptanceTemplateName,
  getTermsToAccept,
} from '@pandora/data-model-contract';
import {addMonths} from 'date-fns';
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import assignIn from 'lodash/assignIn';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import max from 'lodash/max';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import thru from 'lodash/thru';
import {toJS} from 'mobx';

import jilContracts from 'api/jil/jilContracts';
import {
  getComplianceSymptomValue,
  hasComplianceSymptomEnabled,
} from 'models/complianceSymptom/complianceSymptomUtils';
import {CUSTOMER_SEGMENT, SALES_CHANNEL} from 'models/offers/OfferConstants';
import {IMS_USER_ID_REGEX} from 'models/user/UserConstants';
import modelCache from 'services/cache/modelCache/modelCache';
import {CONTRACT_LIST_CACHE_ID} from 'services/contract/ContractListConstants';
import {FORMAT, MINIMUM} from 'services/dateTime/DateTimeConstants';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import log from 'services/log';
import {IMS_ID_REGEX} from 'services/organization/OrganizationConstants';
import price from 'services/price';
import {INCLUSIVITY, compareDates, getDaysLeft, getHoursFromNow, isBetween} from 'utils/dateUtils';
import {looksLikeAnEmail} from 'utils/emailUtils';
import {flattenKeys} from 'utils/jsUtils';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from 'utils/storageUtils';

import ComplianceSymptom from '../complianceSymptom/ComplianceSymptom';

import {
  CONTRACT_AUTORENEWAL_MODE,
  CONTRACT_BILLING_FREQUENCY,
  CONTRACT_BUYING_PROGRAM,
  CONTRACT_COMPLIANCE_SYMPTOMS,
  CONTRACT_EVENT,
  CONTRACT_EXPIRATION_GRACE_UNIT,
  CONTRACT_GRACE_END_DATE_OFFSET,
  CONTRACT_LEGACY_DX_ID,
  CONTRACT_MODEL,
  CONTRACT_PATCH_API_KEY_LOOKUP_TABLE,
  CONTRACT_PAYMENT_CATEGORY,
  CONTRACT_STATUS,
  THREE_YEAR_COMMIT,
} from './ContractConstants';
// Remove with temp_terms_redirect
import ContractTerms from './ContractTerms';
import {
  CONTRACT_MIGRATION_STATUS,
  CONTRACT_MIGRATION_TYPE,
} from './migration/ContractMigrationConstants';

const {
  CAN_CANCEL_PROMISES,
  CAN_CREATE_PROMISE,
  CAN_MESSAGE_ABLE_TO_RENEW,
  CAN_MESSAGE_CANCELLATION,
  CAN_MESSAGE_EXPIRATION,
  // eslint-disable-next-line id-length -- id matches symptom name
  CAN_MESSAGE_UNBACKED_PROMISE_QUANTITY_EXCEEDED,
  CAN_MESSAGE_UNBACKED_PROMISES,
  CAN_MESSAGE_UPCOMING_CANCELLATION,
  CAN_MESSAGE_UPCOMING_EXPIRATION,
  CAN_MESSAGE_UPCOMING_RENEWAL_UNTIL,
  CAN_RENEW_CONTRACT,
  CAN_RENEW_CONTRACT_UNTIL,
  CAN_SWITCH,
} = CONTRACT_COMPLIANCE_SYMPTOMS;

const EDITABLE_FIELDS = [
  'adminGroupId',
  'accountInfo',
  'autoRenewalMode',
  'billingInfo',
  'buyingProgram',
  'changeOwnerInvitation',
  'complianceDetails',
  'complianceStatus',
  'customerSegment',
  'displayName',
  'displayNamePrefix',
  'dmaTenantId',
  'eccIds',
  'enrollee',
  'id',
  'isDrContract',
  'loyaltyInfo',
  'manualTeamToEnterprise',
  'model',
  'migrations',
  'name',
  'orgName',
  'ownerInfo',
  'preferredLanguage',
  'renewalInfo',
  'requestedPlanType',
  'resellerInfo',
  'salesChannel',
  'status',
  'termInfo',
  // Remove with temp_terms_redirect check
  'termsAndConditions',
  'vipNumber',
];

const isDate = (date) => Object.prototype.toString.call(date) === '[object Date]';

class Contract {
  /**
   * @description Method to transform API responses from a Contract fetch
   *   operation into an actual Contract instance. This is necessary since new
   *   Contract instances do not have an id value. So, we need a custom method
   *   here to inject the legacy DX value when fetching an existing Contract
   *   that also does not have an id (i.e. - legacy DX contract).
   * @param {Object} responseData - JSON of fetched contract from server
   * @returns {Contract} new Contract instance, representing external Contract
   *   state/data
   */
  static apiResponseTransformer(responseData) {
    // since this is an existing Contract, if no id is set, we set it to reflect
    // the legacy DX contract id value...
    const data = responseData;
    if (data.id === undefined) {
      data.id = CONTRACT_LEGACY_DX_ID;
    }
    return new Contract(data);
  }

  /**
   * @class
   * @description Creates a new Contract for use.
   *
   * @param {Object} [options] Initialization Object (params described below)
   * @param {String} [options.adminGroupId] A contract's admin group id
   * @param {Object} [options.accountInfo] A Contract's account info
   * @param {String} [options.accountInfo.accountManagerEmail] The account manager's email
   * @param {String} [options.accountInfo.accountManagerName] The account manager's email
   * @param {String} [options.accountInfo.agreementNumber] The contract's agreement number (aka ECM ID)
   * @param {String} [options.accountInfo.dealId] The contract's dealId
   * @param {String} [options.accountInfo.overDeployContactEmail] The overdeploy contact's email
   * @param {String} [options.autoRenewalMode] The auto renewal mode for the contract
   * @param {Object} [options.autoRenewal] The auto renewal details for the contract
   * @param {Boolean} [options.autoRenewal.isOptInSelfServiceAvailable] Indicates whether self service opt in is available for auto renewal
   * @param {Object} [options.billingInfo] A Contract's billing info
   * @param {String} [options.buyingProgram] A Contract's buying program, either ETLA or ENTERPRISE_PRODUCT
   * @param {Object} [options.changeOwnerInvitation] A Contract's change-contract-owner invitation
   * @param {Object} [options.complianceDetails] A Contract's compliance details. Fetched for VIPMP only.
   * @param {Object} [options.complianceDetails.expirationGraceUnit] A Contract's expiration grace unit. DAY or YEAR
   * @param {Object} [options.complianceDetails.expirationGraceValue] A Contract's expiration grace value
   * @param {String} [options.complianceStatus] DEPRECATED. Do not use anymore, use complianceSymptoms instead.
   * This field is only supported by CLAM for Team Direct so we can use PIE (payment instruction library).
   * We will need to keep complianceStatus until PIE deprecates complianceStatus.
   * Either PAST_DUE, GRACE_PAST_DUE or COMPLIANT
   * @param {Object[]} [options.complianceSymptoms] An array of compliance symptom objects consisting of name-value pair
   * @param {String} [options.displayName] The contract display name
   * @param {String} [options.displayNamePrefix] The contract's display name prefix
   * @param {Boolean} [options.isDrContract] true if Contract is DrContract, else false
   * @param {Object} [options.loyaltyInfo] A Contract's loyalty program info
   * @param {Object} [options.model] returned if using the Contract Service
   * @param {Object} [options.name] A contract's name
   * @param {String} [options.orgName] A Contract's orgName
   * @param {Object} [options.ownerInfo] A Contract's owner info
   * @param {Object} [options.ownerInfo.address] A Contract owner's address
   *   info
   * @param {String} [options.ownerInfo.address.city] A Contract owner's
   *   address city
   * @param {Object} [options.ownerInfo.address.country] A Contract owner's
   *   address country info
   * @param {String} [options.ownerInfo.address.country.countryCode] A
   *   Contract owner's address country code. This should be a two letter
   *   code
   * @param {String} [options.ownerInfo.address.country.countryName] A
   *   Contract owner's address country name
   * @param {String} [options.ownerInfo.address.country.countryLocale] A
   *   Contract owner's address country locale. This should be a five
   *   character code - two letters describing the language, a dash, then
   *   two letters describing the country/region (e.g. - en-US for US
   *   English)
   * @param {String} [options.ownerInfo.address.postalCode] A Contract
   *   owner's address postal code (zip code)
   * @param {Object} [options.ownerInfo.address.region] A Contract owner's
   *   address region info
   * @param {String} [options.ownerInfo.address.region.regionCode] A
   *   Contract owner's address region code
   * @param {String} [options.ownerInfo.address.region.regionName] A
   *   Contract owner's address region name
   * @param {String} [options.ownerInfo.address.street1] A Contract owner's
   *   address street (including house number, e.g. - "801 N 34th St")
   * @param {String} [options.ownerInfo.address.street2] A Contract owner's
   *   address street (additional line data, e.g. - "Apt 3" or "Suite 5")
   * @param {String} [options.preferredLanguage] A Contract's preferred language
   * @param {Object} [options.renewalInfo] A Contract's renewal window info
   * @param {Object} [options.resellerInfo] A Contract's reseller info
   * @param {Object} [options.salesChannel] A Contract's sales channel
   * @param {String} [options.status] A Contract's status. ACTIVE, INACTIVE or EXPIRED (introduced for trials).
   * @param {String} [options.termInfo] A Contract's term info
   * @param {String} [options.termInfo.effectiveDate] A Contract's effectiver date
   * @param {String} [options.termInfo.endDate] A Contract's end date
   * @param {String} [options.termInfo.startDate] A Contract's start date
   * @param {Object[]} [options.termsAcceptances] List of TermsAcceptance
   * @param {Object} [options.termsAndConditions] A Contract's T&Cs info -- remove with temp_terms_redirect
   * @param {String} [options.vipNumber] A Contract's VIP number
   */
  constructor(options = {}) {
    initModel(this, options);
    this.registerSavedState();
  }

  /**
   * @description Returns true if all required fields have values in the contract
   *
   * @returns {Boolean} true if all required fields are filled
   */
  areRequiredFieldsFilled() {
    // general contract requirements
    if (
      !this.isValidBuyingProgram() ||
      !this.isValidModel() ||
      !this.isValidSalesChannelOrCustomerSegment()
    ) {
      return false;
    }

    // type-specific contract requirements
    return this.isValidForBuyingProgram();
  }

  /**
   * @description Returns true if this contract can add licenses. At least
   *    one contract must be Direct or Indirect, not migrating, not have new terms to accept,
   *    have an owner country code, and be allowed to create a PA (not be in past due state, etc).
   *
   * @returns {Boolean} true if allowed to add team licenses.
   */
  canAddProducts() {
    return (
      !this.isMigrating() &&
      (this.isDirectContract() || this.isIndirectContract()) &&
      this.canCreatePA() &&
      !this.isOrderProcessing() &&
      !isEmpty(this.getOwnerCountryCode()) &&
      (feature.isEnabled('temp_delegate_without_provisioning_pending_tc') ||
        !this.hasTermsToAccept()) &&
      !this.canMessageUnbackedPromiseQuantityExceeded()
    );
  }

  /**
   * @description Returns true if can_cancel_promises compliance symptom is true.
   *
   * @returns {Boolean} true if complianceSymptoms' can_cancel_promises is true
   */
  canCancelPromises() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_CANCEL_PROMISES,
    });
  }

  /**
   * @description Returns true if can_create_promise compliance symptom is true. PA = Purchase Authorization.
   *
   * @returns {Boolean} true if complianceSymptoms' can_create_promise is true
   */
  canCreatePA() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_CREATE_PROMISE,
    });
  }

  /**
   * @description Returns true if can_message_cancellation compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_cancellation is true
   */
  canMessageCancellation() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_CANCELLATION,
    });
  }

  /**
   * @description Returns true if can_message_expiration compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_expiration is true
   */
  canMessageExpiration() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_EXPIRATION,
    });
  }

  /**
   * @description Returns true if can_message_unbacked_promise_quantity_exceeded compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_unbacked_promise_quantity_exceeded is true
   */
  canMessageUnbackedPromiseQuantityExceeded() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_UNBACKED_PROMISE_QUANTITY_EXCEEDED,
    });
  }

  /**
   * @description Returns true if can_message_unbacked_promises compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_unbacked_promises is true
   */
  canMessageUnbackedPromises() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_UNBACKED_PROMISES,
    });
  }

  /**
   * @description Returns true if can_message_upcoming_cancellation compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_upcoming_cancellation is true
   */
  canMessageUpcomingCancellation() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_UPCOMING_CANCELLATION,
    });
  }

  /**
   * @description Returns true if can_message_upcoming_expiration compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_message_upcoming_expiration is true
   */
  canMessageUpcomingExpiration() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_UPCOMING_EXPIRATION,
    });
  }

  /**
   * @description Returns true if can_switch compliance symptom is true
   *
   * @returns {Boolean} true if complianceSymptoms' can_switch is true
   */
  canSwitch() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_SWITCH,
    });
  }

  /**
   * @description Gets the info for a 3YC feature based on its status
   * @param {('ELIGIBLE' | 'ENROLLED')} status The status of the 3YC feature info to get
   * @returns {Object} An object containing 3YC info based on the status provided
   */
  get3YCInfo(status) {
    if (feature.isEnabled('temp_update_3yc_functions')) {
      return get3YCFeature(this, status);
    }

    return this.getSelectProgramFeatures().find((f) => f.name === THREE_YEAR_COMMIT);
  }

  /**
   * @description Returns the acceptance date of the terms and conditions. Applicable to VIP.
   *
   * @returns {String} the acceptance date.
   */
  getAcceptanceDate() {
    return this.termInfo?.acceptanceDate;
  }

  /**
   * @description Returns the address for this contract, if it exists.
   *
   * @returns {Object} address object, or undefined
   * @property {String} city
   * @property {Object} country
   * @property {String} country.countryCode
   * @property {String} country.countryCodeISO3
   * @property {String} country.countryName
   * @property {String} postalCode
   * @property {Object} region
   * @property {String} region.regionCode
   * @property {String} region.regionName
   * @property {String} street1
   */
  getAddress() {
    return get(this, 'ownerInfo.address');
  }

  /**
   * @description Returns the anniversary date for this contract.
   *
   * @returns {String} anniversary date in ISO-8061 timestamp format
   */
  getAnniversaryDate() {
    return this.termInfo?.endDate;
  }

  /**
   * @description Returns the contract's billing frequency, if it has one.
   *
   * @returns {String} billing frequency, e.g. 'MONTHLY', 'ANNUAL'
   */
  getBillingFrequency() {
    return get(this, 'billingInfo.billingFrequency');
  }

  /**
   * @description Fetches a compliance symptom value
   *
   * @returns {String} the value of the specified symptom, or null
   */
  getComplianceSymptom(name) {
    const foundSymptom = this.complianceSymptoms?.find((symptom) => symptom.name === name);
    return get(foundSymptom, 'value');
  }

  /**
   * @description Method to get the # of 24-hour periods between now and when a
   *   contract ends. Note this algorithm was designed by the NGL team. The # of
   *   days left is rounded up with does not seem correct but this ensures that
   *   if a trial is X days long, the trial will initially show X days.
   *
   * @returns {Integer|NaN} If there is an endDate, returns the ceil of number
   *   24-hour periods between now and when a contract ends. If the contract has
   *   expired the return will be a 0 or a negative number of days. If there is
   *   not an endDate or the endDate isn't a valid Date object it returns NaN.
   */
  getDaysLeft() {
    const MILLISECONDS_PER_DAY = 3600000 * 24;

    const endDate = this.getEndDate();
    if (!isDate(endDate)) {
      return Number.NaN;
    }

    const endDateInMs = new Date(endDate).getTime();
    const nowInMs = new Date().getTime();

    const diff = (endDateInMs - nowInMs) / MILLISECONDS_PER_DAY;

    // Even 1ms over 1 day is counted as two days!!
    return diff >= 0 ? Math.ceil(diff) : Math.floor(diff);
  }

  /**
   * @description Returns the display name for a contract.
   *
   * @returns {String | undefined} returns the display name for a contract,
   *   else undefined if no display name exists
   */
  getDisplayName() {
    return get(this, 'displayName');
  }

  /**
   * @description Returns the display name prefix for a contract.
   *
   * @returns {String | undefined} returns the display name prefix for a
   *   contract, else undefined if no display name prefix exists
   */
  getDisplayNamePrefix() {
    return get(this, 'displayNamePrefix');
  }

  /**
   * @description Returns the effective date for a contract.
   *
   * @returns {Date} effective date converted from ISO-8061 timestamp format
   *   when Contract is constructed
   */
  getEffectiveDate() {
    return get(this, 'termInfo.effectiveDate');
  }

  /**
   * @description Returns the end date for an ETLA contract.
   *
   * @returns {Date} end date converted from ISO-8061 timestamp format when Contract is constructed
   */
  getEndDate() {
    return get(this, 'termInfo.endDate');
  }

  /**
   * @description Returns the enrollee id for a contract.
   *
   * @returns {String|undefined} returns the enrollee id set for a contract,
   *   else undefined if no enrollee id exists
   */
  getEnrolleeId() {
    return get(this, 'enrollee.id');
  }

  /**
   * @description Returns the expiration grace value if expiration grace unit is DAY.
   *
   * @returns {Number} expiration grace days
   */
  getExpirationGraceDays() {
    return get(this, 'complianceDetails.expirationGraceUnit') === CONTRACT_EXPIRATION_GRACE_UNIT.DAY
      ? get(this, 'complianceDetails.expirationGraceValue')
      : undefined;
  }

  /**
   * @description Returns the future currency value if one exists in the contract.
   * @param {Contract} contract the contract containing currency information
   * @returns {String | undefined} returns the currency code for a future
   *   currency if it has one else undefined.
   */
  getFutureCurrency() {
    return this.billingInfo?.nextBillingAmount?.currency?.futureCurrency;
  }

  /**
   * @description Returns the name of the contract.
   *
   * @returns {String | undefined} returns the name of the contract, else
   *   undefined if no name exists
   */
  getName() {
    return get(this, 'name');
  }

  /**
   * @description Returns the next billing amount object for this contract, if it has one. Will return the latest
   *   billing if user has recently made a change to purchased items.
   * @returns {Object} an object with 4 attributes:
   *   includesTax: which indicates whether the price returned includes tax,
   *   formattedPrice: the price formatted according to the currency info,
   *   futureCurrency: a string denoting what currency the next bill will be in,
   *   currencyCode: next billing currency code e.g. 'CAD', 'MXN'. Returns undefined if non existant
   */
  getNextBillingAmountInfo() {
    const nextBilling = get(this, 'billingInfo.nextBillingAmount');

    if (nextBilling) {
      let total = {...nextBilling?.nextTotal};
      const updatedBilling = this.getSubmittedNextBilling();

      if (updatedBilling) {
        const expires = updatedBilling.expires;

        if (isPast(parseISO(expires))) {
          removeSessionStorageItem(this.getSubmittedNextBillingCacheId());
        } else {
          delete updatedBilling.expires;
          total = {...total, ...updatedBilling};
        }
      }

      const includesTax = typeof total.priceWithTax === 'number';
      return {
        currency: nextBilling?.currency,
        currencyCode: nextBilling?.currency?.iso3code,
        formattedPrice: price.formatPrice(
          includesTax ? total.priceWithTax : total.priceWithoutTax,
          nextBilling.currency
        ),
        futureCurrency: nextBilling?.currency?.futureCurrency,
        includesTax,
        total,
      };
    }
    return undefined;
  }

  /**
   * @description Returns the next billing date for this contract, if it has one.
   *
   * @returns {String} anniversary date in ISO-8061 timestamp format
   */
  getNextBillingDate() {
    const contractNextBillingDate = this.billingInfo?.nextBillingDate;
    const storedNextBilling = this.getSubmittedNextBilling();
    const updatedBillingDate = storedNextBilling?.nextBillingDate;

    if (updatedBillingDate) {
      const expires = storedNextBilling.expires;

      if (isPast(parseISO(expires))) {
        removeSessionStorageItem(this.getSubmittedNextBillingCacheId());
      }

      return updatedBillingDate;
    }

    return contractNextBillingDate;
  }

  /**
   * @description Returns the offset from the anniversary date for this
   *   contract.
   * @returns {Number} anniversary date offset, a positive number means pre-anniversary
   */
  getOffsetFromAnniversaryDate() {
    return getDaysLeft(this.getAnniversaryDate());
  }

  /**
   * @description Returns the offset from the next billing date for this
   *   contract.
   * @returns {String} next billing date offset in ISO-8061 timestamp format
   */
  getOffsetFromNextBillingDate() {
    return get(this, 'billingInfo.offsetFromNextBillingDate');
  }

  /**
   * @description Returns the number of calendar days since the renewal window
   * start date for this contract.
   * @returns {Number|Nan} If the contract has a renewal start date, returns the
   * number of days between today's date and the start date. If the Renewal Start Date
   * is empty or not a date, returns NaN.
   */
  getOffsetFromRenewalWindowStartDate() {
    const renewalWindowStartDate = getDaysLeft(this.getRenewalWindowStartDate());
    return renewalWindowStartDate === undefined ? Number.NaN : renewalWindowStartDate;
  }

  /**
   * @description Returns the number of days since the contract start date
   * @returns {Number|Nan} If the contract has a start date, returns the
   * number of days between today's date and the start date. If the Start Date
   * is empty or not a date, returns NaN.
   */
  getOffsetFromStartDate() {
    const startDate = getDaysLeft(this.getStartDate());
    return startDate === undefined ? Number.NaN : startDate;
  }

  /**
   * @description Returns the owner's country code for this contract.
   *
   * @returns {String} the owner's country code
   */
  getOwnerCountryCode() {
    return get(this, 'ownerInfo.countryCode');
  }

  /**
   * @description Returns the owner's email for this contract.
   *
   * @returns {String} the owner's email
   */
  getOwnerEmail() {
    return get(this, 'ownerInfo.email');
  }

  /**
   * @description Returns the owner's authenticating user ID for this contract.
   *
   * @returns {String} the owner's authenticating user ID
   */
  getOwnerUserId() {
    return get(this, 'ownerInfo.userId');
  }

  /**
   * @description Returns the previous anniversary date for this contract.
   * @returns {String} anniversary date in ISO-8061 timestamp format
   */
  getPreviousAnniversaryDate() {
    return get(this, 'renewalInfo.previousAnniversaryDate');
  }

  /**
   * @description Returns the renewal window end date for this contract.
   * @returns {String} renewal window end date in ISO-8061 timestamp format
   */
  getRenewalWindowEndDate() {
    return getComplianceSymptomValue({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_RENEW_CONTRACT_UNTIL,
    });
  }

  /**
   * @description Returns the renewal window start date for this contract.
   * @returns {String} renewal window end date in ISO-8061 timestamp format
   */
  getRenewalWindowStartDate() {
    return (
      getComplianceSymptomValue({
        complianceSymptoms: this.complianceSymptoms,
        name: CAN_MESSAGE_UPCOMING_RENEWAL_UNTIL,
      }) ?? ''
    );
  }

  /**
   * @description Returns the reseller name, if it has one.
   *
   * @returns {String} contract reseller name
   */
  getResellerName() {
    return get(this, 'resellerInfo.name');
  }

  /**
   * @description Returns the reseller organization id (IMS id), if it has one.
   *
   * @returns {String|undefined} contract reseller org id, else undefined if not set
   */
  getResellerOrgId() {
    return get(this, 'resellerInfo.id');
  }

  /**
   * @description Returns select program membership info (for both enrolled and unenrolled)
   *
   * @returns {Object} select membership info (or empty object)
   */
  getSelectMembershipInfo() {
    return this.getSelectProgramFeatures().find((f) => f.name === 'DISCOUNT');
  }

  /**
   * @description Returns select program features
   *
   * @returns {Object} select program features (or empty object)
   */
  getSelectProgramFeatures() {
    const program = this.loyaltyInfo
      ? this.loyaltyInfo.programs.find((p) => p.name === 'SELECT')
      : undefined;
    return program ? program.features : [];
  }

  /**
   * @description Returns the start date for an ETLA contract.
   *
   * @returns {Date|undefined} start date if one is set, else undefined if no
   *   start date has been set
   */
  getStartDate() {
    return get(this, 'termInfo.startDate');
  }

  /**
   * @description Retrieves the submitted next billing total after
   *    a purchase or cancellation
   *
   * @returns {Object} result - a summary of the new total price with
   *  and without tax if found in session storage, and empty object if not.
   *  result.priceWithoutTax - The updated price without tax
   *  result.priceWithTax - The updated price with tax
   */
  getSubmittedNextBilling() {
    const nextBilling = getSessionStorageItem(this.getSubmittedNextBillingCacheId());
    try {
      // returns null if nextBilling not in session storage
      return JSON.parse(nextBilling);
    } catch (error) {
      // occurs if nextBilling is not a JSON parseable string
      return undefined;
    }
  }

  /**
   * @description Retrieves the session key used for submitted next billing prices
   *
   * @returns {String} The session storage key used for the submitted next billing prices
   */
  getSubmittedNextBillingCacheId() {
    return `${this.id}_nextBilling`;
  }

  /**
   * @description Returns first migration object that is eligible for a
   * team to enterprise migration
   *
   * @returns {Object} migration
   */
  getTeamToEnterpriseEligibleMigration() {
    return this.migrations
      ? this.migrations.find(
          (migration) =>
            migration.status === CONTRACT_MIGRATION_STATUS.NOT_MIGRATING &&
            migration.type === CONTRACT_MIGRATION_TYPE.TEAM_VIP_TO_TEAM_EVIP
        )
      : undefined;
  }

  /**
   * @description Returns the T&C for this contract, if it has one.
   *
   * @returns {ContractTerms} contract terms object
   */
  getTermsAndConditions() {
    const serviceName = get(this, 'termsAndConditions.serviceName', undefined);
    if (serviceName && !this.contractTerms && !this.isDirectContract()) {
      this.contractTerms = ContractTerms.get({orgId: this.orgId, serviceName});
    }
    return this.contractTerms;
  }

  /**
   * @deprecated Will be removed with temp_relax_anniversary_date flag.
   * @description Returns the valid max anniversary date for this contract.
   *   The maximum valid date is 3 years past the current start date. This
   *   additional affordance (2 years) is built in to allow for resellers
   *   offering a 3 years commitment period/contract.
   * @returns {Date|undefined} Returns the valid max anniversary date
   *   without time for this contract. Returns undefined if the start
   *   date is invalid (can not create a valid date from invalid value)
   */
  getValidMaxAnniversaryDate() {
    if (this.isValidStartDate()) {
      return addYears(startOfDay(new Date(this.getStartDate())), 3);
    }
    return undefined;
  }

  /**
   * @description Returns the valid min anniversary date for this contract. The
   *   minimum valid date is 1 day past the current start date.
   *
   * @returns {Date|undefined} Returns the valid min anniversary date for this
   *   contract. Returns undefined if the start date is invalid (can not create
   *   a valid date from invalid value)
   */
  getValidMinAnniversaryDate() {
    if (feature.isEnabled('temp_relax_anniversary_date')) {
      if (this.isValidStartDate()) {
        return addDays(startOfDay(new Date(this.getStartDate())), 1);
      }
      return undefined;
    }
    if (this.isValidStartDate()) {
      return addMonths(startOfDay(new Date(this.getStartDate())), 12);
    }
    return undefined;
  }

  /**
   * @description Returns the valid min end date for this contract
   *
   * @returns {Date|NaN} Returns the valid min end date without time for this
   *   contract. Returns NaN (Number type) if the start date is unspecified.
   */
  getValidMinEndDate() {
    const minValidEndDateBasedOnStartDate = addDays(startOfDay(new Date(this.getStartDate())), 1);

    if (hasChangedDate(this, 'termInfo.endDate')) {
      const newDate = new Date();
      const minValidEndDateBasedOnToday = addDays(
        startOfDay(addMinutes(newDate, newDate.getTimezoneOffset())),
        feature.isEnabled('temp_relax_end_date') ? 1 : 30
      );
      return max([minValidEndDateBasedOnStartDate, minValidEndDateBasedOnToday]);
    }

    return minValidEndDateBasedOnStartDate;
  }

  /**
   * @description Returns true when the billing payment Instrument has an active status
   *
   * @returns {Boolean} whether the billing payment InstrumentInfo status is active
   */
  hasActivePaymentInstrument() {
    return get(this, 'billingInfo.paymentInstrumentInfo.status') === 'ACTIVE';
  }

  /**
   * @description Returns true when admin group exists
   *
   * @returns {Boolean} whether the admin group exists
   */
  hasAdminGroupId() {
    return this.adminGroupId !== null && this.adminGroupId !== undefined;
  }

  /**
   * @description Returns true for any migration object is eligible for a
   * manual migration
   *
   * @returns {Boolean} migration
   */
  hasEligibleManualMigration() {
    return this.manualTeamToEnterprise === true;
  }

  /**
   * @description Check whether this contract has offer switch migration.
   * Note: Limiting scope to indirect for offer switch M1
   * @param {Object} options - options to configure check
   * @param {Boolean} options.onlyEligibleMigrations - Set to true for checking
   * eligibility (non-migrating). Set to false for checking the existence
   * of offer migration with any status.
   *
   * @returns {Boolean} true if this contract has offer switch migration, false otherwise.
   */
  hasOfferSwitchMigration({onlyEligibleMigrations = true} = {}) {
    const statusList = onlyEligibleMigrations
      ? [CONTRACT_MIGRATION_STATUS.NOT_MIGRATING]
      : Object.values(CONTRACT_MIGRATION_STATUS);

    return (
      this.isIndirectContract() &&
      this.migrations &&
      this.migrations.some(
        (migration) =>
          statusList.includes(migration.status) &&
          migration.type === CONTRACT_MIGRATION_TYPE.OFFER_SWITCH
      )
    );
  }

  /**
   * @description Returns true any migration object is eligible for a
   * team to enterprise migration
   *
   * @returns {Boolean} migration
   */
  hasTeamToEnterpriseEligibleMigration() {
    return (
      this.isIndirectContract() &&
      this.migrations &&
      this.migrations.some(
        (migration) =>
          migration.status === CONTRACT_MIGRATION_STATUS.NOT_MIGRATING &&
          migration.type === CONTRACT_MIGRATION_TYPE.TEAM_VIP_TO_TEAM_EVIP
      )
    );
  }

  /**
   * @description Returns true if this contract requires acceptance of terms. This does
   *   not mean the user can accept. Use mustAcceptTerms for that.
   *
   * @returns {Boolean} true if acceptance of terms is required.
   */
  hasTermsToAccept() {
    if (feature.isEnabled('temp_terms_redirect')) {
      return hasVipTermsAcceptance(this);
    }

    return !!this.contractTerms && !this.contractTerms.getCurrent();
  }

  /**
   * @description Returns true if this contract requires reacceptance of terms.
   *
   * @returns {Boolean} Returns true if reacceptance of terms is required. Returns false otherwise.
   */
  hasTermsToReAccept() {
    const pandoraContractList = [this.toPandoraContract()];

    // Any VIP or VIP-MP terms acceptance are based on the same VIP terms, thus they're treated as "VIP".
    const vipTerms = getTermsToAccept(pandoraContractList, TermsAcceptanceTemplateName.VIP);
    if (vipTerms.length > 0 && !!vipTerms[0].acceptorId) {
      return true;
    }

    const vipmpTerms = getTermsToAccept(pandoraContractList, TermsAcceptanceTemplateName.VIPMP);
    if (vipmpTerms.length > 0 && !!vipmpTerms[0].acceptorId) {
      return true;
    }

    return false;
  }

  /**
   * @description Method to determine if there are any unsaved changes to this object.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    const current = filterEmptyFields(this);
    const saved = filterEmptyFields(this.savedState);
    return !isEqual(current, saved) || hasChangedContractDates(this);

    function filterEmptyFields(contract) {
      const filteredByFields = pick(contract, EDITABLE_FIELDS);
      delete filteredByFields.termInfo;
      return Object.values(
        omitBy(filteredByFields, (value) => !isBoolean(value) && isEmpty(value))
      );
    }
  }

  isAutoRenewalOff() {
    return this.autoRenewalMode === CONTRACT_AUTORENEWAL_MODE.OFF;
  }

  isAutoRenewalOnce() {
    return this.autoRenewalMode === CONTRACT_AUTORENEWAL_MODE.ONCE;
  }

  isAutoRenewalPerpetual() {
    return this.autoRenewalMode === CONTRACT_AUTORENEWAL_MODE.PERPETUAL;
  }

  isBuyingProgramEnterpriseProduct() {
    return this.buyingProgram === CONTRACT_BUYING_PROGRAM.ENTERPRISE_PRODUCT;
  }

  isBuyingProgramETLA() {
    return this.buyingProgram === CONTRACT_BUYING_PROGRAM.ETLA;
  }

  isBuyingProgramVIP() {
    return this.buyingProgram === CONTRACT_BUYING_PROGRAM.VIP;
  }

  isBuyingProgramVIPMP() {
    return this.buyingProgram === CONTRACT_BUYING_PROGRAM.VIPMP;
  }

  /**
   * @description Returns true, if it is canceled.
   *
   * @returns {Boolean} true if a contract has JEM contract type INACTIVE
   */
  isCancelled() {
    return this.isDirectContract() && this.status === CONTRACT_STATUS.INACTIVE;
  }

  /**
   * @deprecated. Use canMessageUpcomingExpiration() instead.
   * @description Returns true if the compliance status is grace past due.
   *
   * @returns {Boolean} true if this is a grace past due contract.
   */
  isComplianceStatusGracePastDue() {
    return this.canMessageUpcomingExpiration();
  }

  /**
   * @deprecated. Use canMessageUpcomingExpiration() instead.
   * @description Returns true if the compliance status is past due.
   *
   * @returns {Boolean} true if this is an past due contract.
   */
  isComplianceStatusPastDue() {
    return this.canMessageExpiration();
  }

  /**
   * @description Check for contract owner.
   * Using options.checkAuthUser is recommended because it's ID agnostic, e.g.
   * the contract owner who logs in with T2E ID is still return true.
   * Without options.checkAuthUser, this method returns false for the
   * contract owner who logs in with T2E ID.
   *
   * @param {Object} options - options to configure contract owner check
   * @param {String} [options.userId=undefined] - the user ID to check
   * @param {Boolean} [options.checkAuthUser=false] - check the logged-in user's
   * authenticating user which works across different ID types
   * @returns {Boolean} true for contract owner depending on the options
   */
  isContractOwner({checkAuthUser = false, userId} = {}) {
    if (checkAuthUser) {
      return this.ownerInfo.authUserIsOwner;
    }
    return !!userId && this.getOwnerUserId() === userId;
  }

  /**
   * @description Returns true if this is a direct contract.
   *
   * @returns {Boolean} true if this is an direct contract.
   */
  isDirectContract() {
    return this.customerSegment === CUSTOMER_SEGMENT.TEAM && this.isSalesChannelDirect();
  }

  /**
   * @description Returns true if the contract is eligible for any 3yc offer
   * @returns {Boolean} true if contract is eligible for 3yc offer
   */
  isEligibleFor3YC() {
    if (feature.isEnabled('temp_update_3yc_functions')) {
      return this.isIndirectContract() && get3YCFeature(this, 'ELIGIBLE') !== undefined;
    }

    const threeYearCommitInfo = this.get3YCInfo();
    if (this.isIndirectContract() && threeYearCommitInfo) {
      return threeYearCommitInfo.status === 'ELIGIBLE';
    }
    return false;
  }

  /**
   * @description Returns true if the contract is enrolled in 3yc offer
   * @returns {Boolean} true if contract is enrolled in 3yc offer
   */
  isEnrolledIn3YC() {
    if (feature.isEnabled('temp_update_3yc_functions')) {
      return this.isIndirectContract() && get3YCFeature(this, 'ENROLLED') !== undefined;
    }

    const threeYearCommitInfo = this.get3YCInfo();
    if (this.isIndirectContract() && threeYearCommitInfo) {
      return threeYearCommitInfo.status === 'ENROLLED';
    }
    return false;
  }

  /**
   * @description Returns true if this is an enterprise contract.
   *
   * @returns {Boolean} true if this is an enterprise contract.
   */
  isEnterpriseContract() {
    return this.customerSegment === CUSTOMER_SEGMENT.ENTERPRISE && this.isSalesChannelDirect();
  }

  /**
   * @description Returns true if a trial contract and it has ended/expired.
   *
   * @returns {Boolean} returns true if a trial contract and the trial has ended, else false.
   */
  isExpiredTrial() {
    return this.isModelTrial() && this.isStatusExpired();
  }

  /**
   * @description Returns true if this is an indirect contract but buying program is not VIPMP.
   *
   * @returns {Boolean} true if this is an indirect contract but buying program is not VIPMP.
   */
  isIndirectButNotVIPMPContract() {
    return this.isIndirectContract() && this.buyingProgram !== CONTRACT_BUYING_PROGRAM.VIPMP;
  }

  /**
   * @description Returns true if this is an indirect contract.
   *
   * @returns {Boolean} true if this is an indirect contract.
   */
  isIndirectContract() {
    return this.salesChannel === SALES_CHANNEL.INDIRECT;
  }

  /**
   * @description Returns true if this is an indirect contract with no owner info, indicating
   *   that it has no enrollee and needs to do an initial T&C acceptance to set enrollee.
   *   Used for VIP or VIPMP orgs that use outvites for all admins, rather than an invite workflow.
   *
   * @returns {Boolean} true if this is an indirect contract with no owner info
   */
  isIndirectContractWithNoEnrollee() {
    return this.isIndirectContract() && !this.ownerInfo;
  }

  /**
   * @description Returns true if a contract is in mid cycle.
   * @returns {Boolean} true if the contract is in mid cycle
   */
  isInMidCycle() {
    const offset = this.getOffsetFromAnniversaryDate();
    // is mid cycle if more than 14 and less then 30 days (a month) from from anniversary
    return offset < 365 - 14 && offset > 30;
  }

  isInPostAnniversaryRenewalWindow() {
    return this.isInRenewalWindow() && !this.isInPreAnniversaryRenewalWindow();
  }

  isInPreAnniversaryRenewalWindow() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_MESSAGE_ABLE_TO_RENEW,
    });
  }

  /**
   * @description Returns true if this contract is in renewal window.
   *
   * @returns {Boolean} true if this contract is in renewal window.
   */
  isInRenewalWindow() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: CAN_RENEW_CONTRACT,
    });
  }

  /**
   * @description Returns true if this is a Month-To-Month team.
   *
   * @returns {Boolean} true if this is a contract paid monthly.
   *   This implies it is a direct org.
   */
  isM2M() {
    return this.getBillingFrequency() === CONTRACT_BILLING_FREQUENCY.MONTHLY;
  }

  /**
   * @description Returns true if there are any active migrations for this contract.
   *
   * @returns {Boolean} true if there are migrations in progress
   */
  isMigrating() {
    return (
      this.migrations &&
      this.migrations.some((migration) => migration.status === CONTRACT_MIGRATION_STATUS.MIGRATING)
    );
  }

  /**
   * @description Returns true if there are any active trials to paid migrations for this contract.
   *
   * @returns {Boolean} true if there are migrations in progress
   */
  isMigratingFromBusinessTrialsToPaid() {
    return (
      this.migrations &&
      this.migrations.some(
        (migration) =>
          migration.status === CONTRACT_MIGRATION_STATUS.MIGRATING &&
          migration.type === CONTRACT_MIGRATION_TYPE.BIZ_TRIALS_TO_PAID
      )
    );
  }

  /**
   * @description Returns true if this is a Contract Service allocation contract.
   *
   * @returns {Boolean} true if this is an allocation contract
   */
  isModelAllocation() {
    return this.model === CONTRACT_MODEL.ALLOCATION;
  }

  /**
   * @description Returns true if this is a Contract Service classroom contract.
   *
   * @returns {Boolean} true if this is a classroom contract
   */
  isModelClassroom() {
    return this.model === CONTRACT_MODEL.CLASSROOM;
  }

  /**
   * @description Returns true if this is a Contract Service standard contract.
   *
   * @returns {Boolean} true if this is a standard contract
   */
  isModelStandard() {
    return this.model === CONTRACT_MODEL.STANDARD;
  }

  /**
   * @description Returns true if this is a Contract Service trial contract. JEM does not use this property.
   *
   * @returns {Boolean} true if this is a trial contract
   */
  isModelTrial() {
    return this.model === CONTRACT_MODEL.TRIAL;
  }

  /**
   * @description Method to determine if this Contract is a new one or not.
   *
   * @returns {Boolean} true if a new Contract, else false
   */
  isNew() {
    return this.id === null || this.id === undefined;
  }

  /**
   * @description Returns true if it's an NFR (not for resale) team
   *
   * @returns {Boolean} true if the contract's reseller is one of the NFR ones
   */
  isNFR() {
    const resellerName = get(this, 'resellerInfo.name');
    return [
      'Adobe Systems NFR Account',
      'Adobe Youth Voices',
      'Adobe Systems NFR Account (China)',
    ].includes(resellerName);
  }

  /**
   * @description Returns true if the org's address is in North America
   *
   * @returns {Boolean} true for country codes US, CA, MX
   */
  isNorthAmerica() {
    const countryCode = this.getOwnerCountryCode();
    return ['US', 'CA', 'MX'].includes(countryCode);
  }

  /**
   * @description Checks and returns whether self service for auto renewal opt in is
   * available for the contract
   *
   * @returns {boolean} true if self service auto renewal is available, false otherwise
   */
  isOptInSelfServiceAvailable() {
    return (
      feature.isEnabled('auto_renewal_opt_in_self_service') &&
      !!this.autoRenewal?.optInSelfServiceAvailable
    );
  }

  /**
   * @description Returns true if the order is still processing or pending initialization.
   * Currently, JEM/ECC take around an hour after the team is initially purchased
   * to process orders. Consequently, the team does not yet know whether
   * it is billed periodically or paid upfront.
   *
   * @returns {Boolean} true if the order is still processing
   */
  isOrderProcessing() {
    return (
      this.getBillingFrequency() === CONTRACT_BILLING_FREQUENCY.UNKNOWN ||
      this.canMessageUnbackedPromises()
    );
  }

  /**
   * @description Returns true if the contract has an end date in the past.
   *
   * @returns {Boolean} whether the contract has an end date in the past
   */
  isPastEndDate() {
    return new Date(this.getEndDate()).getTime() < new Date().getTime();
  }

  /**
   * @description Returns true if the contract payment was made through a
   *   credit card.
   * @returns {Boolean} true if the contract's paymentCategory is CREDIT_CARD
   */
  isPaymentCategoryCreditCard() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.CREDIT_CARD);
  }

  /**
   * @description Returns true if the contract payment was made through Digital River.
   * @returns {Boolean} true if the contract's paymentCategory is VENDOR_DIGITAL_RIVER_PAYMENT
   */
  isPaymentCategoryDigitalRiver() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.VENDOR_DIGITAL_RIVER_PAYMENT);
  }

  /**
   * @description Returns true if the contract payment was made through
   *   direct debit.
   * @returns {Boolean} true if the contract's paymentCategory is DIRECT_DEBIT
   */
  isPaymentCategoryDirectDebit() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.DIRECT_DEBIT);
  }

  /**
   * @description Returns true if the contract payment was made offline
   * @returns {Boolean} true if the contract's paymentCategory is OFFLINE
   */
  isPaymentCategoryOffline() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.OFFLINE);
  }

  /**
   * @description Returns true if the contract payment was made through
   *   Paypal.
   * @returns {Boolean} true if the contract's paymentCategory is PAYPAL
   */
  isPaymentCategoryPaypal() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.PAYPAL);
  }

  /**
   * @description Returns true if the contract's payment was made through a
   *   purchase order.
   * @returns {Boolean} true if the contract's paymentCategory is PURCHASE_ORDER
   */
  isPaymentCategoryPO() {
    return isPaymentCategoryType(this, CONTRACT_PAYMENT_CATEGORY.PURCHASE_ORDER);
  }

  /**
   * @deprecated. Use canMessageUnbackedPromiseQuantityExceeded() instead.
   * @description Returns true if the contract's pending payment order exceeds limit.
   *
   * @returns {Boolean} true if the contract's complianceStatus is PENDING_PAYMENT_ORDER_QUANTITY_EXCEEDED
   */
  isPendingPaymentOrderQuantityExceeded() {
    return this.canMessageUnbackedPromiseQuantityExceeded();
  }

  /**
   * @description Returns true if this is a Paid-Up-Front team.
   *
   * @returns {Boolean} true if this is a contract paid annually.
   *   This implies it is a direct org.
   */
  isPUF() {
    return this.getBillingFrequency() === CONTRACT_BILLING_FREQUENCY.ANNUAL;
  }

  /**
   * @description Returns true if this is a renewable direct contract.
   *   Digital River contracts are not renewable.
   *
   * @returns {Boolean} true if this is a renewable direct contract.
   */
  isRenewableDirectContract() {
    return (
      this.isDirectContract() &&
      !this.isDrContract &&
      !this.canMessageCancellation() &&
      !this.canMessageUpcomingCancellation() &&
      !this.canMessageExpiration()
    );
  }

  /**
   * @description Returns true of salesChannel is DIRECT
   * @returns {Boolean} true if this contract's salesChannel is DIRECT
   */
  isSalesChannelDirect() {
    return this.salesChannel === SALES_CHANNEL.DIRECT;
  }

  /**
   * @description Returns true if this contract is active.
   *
   * @returns {Boolean} true if this contract's status is ACTIVE
   */
  isStatusActive() {
    return this.status === CONTRACT_STATUS.ACTIVE;
  }

  /**
   * @description Returns true if this contract is expired.
   *
   * @returns {Boolean} true if this contract's status is EXPIRED
   */
  isStatusExpired() {
    return this.status === CONTRACT_STATUS.EXPIRED;
  }

  /**
   * @description Returns true if this is a trial contract.
   *  Legacy ETLA trial contracts (model='TRIAL' and an ETLA buying program) should be treated as ETLA
   *  contracts and are not considered true trial contracts.
   *
   * @returns {Boolean} true if this should be treated as a trial contract
   */
  isTrial() {
    return this.isModelTrial() && !this.isBuyingProgramETLA();
  }

  /**
   * @description Returns true if the account info data is valid
   *
   * @returns {Boolean} true if contract account info is valid, else false
   */
  isValidAccountInfo() {
    return (
      this.isValidAccountManagerEmail() && this.isValidAccountManagerName() && this.isValidDealId()
    );
  }

  /**
   * @description Returns true if this is a valid account manager email
   *
   * @returns {Boolean} true if this is a valid account manager email
   */
  isValidAccountManagerEmail() {
    return looksLikeAnEmail(get(this, 'accountInfo.accountManagerEmail'));
  }

  /**
   * @description Method to determine if the contract account manager name
   *   value is valid or not.
   * @returns {Boolean} true if account manager name is valid, else false
   */
  isValidAccountManagerName() {
    const name = get(this, 'accountInfo.accountManagerName');
    return !isEmpty(name);
  }

  /**
   * @description Method to determine if the contract agreement number value
   *   is valid or not.
   * @returns {Boolean} true if agreement number is valid, else false
   */
  isValidAgreementNumber() {
    const agreementNumber = get(this, 'accountInfo.agreementNumber');
    return !isEmpty(agreementNumber);
  }

  /**
   * @description Method to check validity of anniversary date set. A valid
   *   anniversary date is one that is equal to or later than the start date. If
   *   no start date is set, then we return undefined, as the calculation is
   *   indeterminate.
   *
   * @returns {Boolean|undefined} true if this is a valid anniversary date,
   *   else undefined if unable to make a determination
   */
  isValidAnniversaryDate() {
    const anniversaryDate = this.getAnniversaryDate();
    let minAnniversaryDate;
    if (feature.isEnabled('temp_relax_anniversary_date')) {
      if (anniversaryDate === undefined) {
        return undefined;
      }

      minAnniversaryDate = this.getValidMinAnniversaryDate();
      if (minAnniversaryDate === undefined) {
        return undefined;
      }

      return compareDates(anniversaryDate, minAnniversaryDate) >= 0;
    }

    if (anniversaryDate === undefined) {
      return false;
    }

    const maxAnniversaryDate = this.getValidMaxAnniversaryDate();
    minAnniversaryDate = this.getValidMinAnniversaryDate();
    if (maxAnniversaryDate === undefined || minAnniversaryDate === undefined) {
      return undefined;
    }

    return isBetween(anniversaryDate, minAnniversaryDate, maxAnniversaryDate, INCLUSIVITY.BOTH);
  }

  /**
   * @description Method to determine if the contract buying program value
   *   is valid or not.
   * @returns {Boolean} true if buying program is valid, else false
   */
  isValidBuyingProgram() {
    return (
      this.buyingProgram !== undefined &&
      CONTRACT_BUYING_PROGRAM[this.buyingProgram] === this.buyingProgram
    );
  }

  /**
   * @description Method to determine if the contract deal id value
   *   is valid or not.
   * @returns {Boolean} true if deal id is valid, else false
   */
  isValidDealId() {
    const dealId = get(this, 'accountInfo.dealId');
    return !isEmpty(dealId);
  }

  /**
   * @description Method to determine if the ECC IDs set on a Contract are
   *   valid or not.
   * @returns {Boolean} true if eccIds value is valid, else false
   */
  isValidEccIds() {
    return !isEmpty(this.eccIds);
  }

  /**
   * @description Returns true if this is a valid effective date
   *
   * @returns {Boolean} true if this is a valid effective date
   */
  isValidEffectiveDate() {
    const effectiveDate = this.getEffectiveDate();
    const startDate = this.getStartDate();
    return (
      this.model === CONTRACT_MODEL.STANDARD &&
      startDate !== undefined &&
      effectiveDate !== undefined &&
      compareDates(startDate, effectiveDate) <= 0
    );
  }

  /**
   * @description Returns true if this is a valid end date.
   *
   * @returns {Boolean} true if this is a valid end date
   */
  isValidEndDate() {
    const endDate = this.getEndDate();
    return endDate !== undefined && compareDates(endDate, this.getValidMinEndDate()) >= 0;
  }

  /**
   * @description Returns true if this is a valid enrollee id
   *
   * @returns {Boolean} true if this is a valid enrollee id, else false
   */
  isValidEnrolleeId() {
    const enrolleeId = this.getEnrolleeId();
    return IMS_USER_ID_REGEX.test(enrolleeId);
  }

  /**
   * @description Method to determine if the contract values are valid for
   *   the buying program set. VIP contracts support a different set of
   *   required fields that must be validated independently from ETLA or
   *   ENTERPRISE_PRODUCT contracts.
   * @returns {Boolean} true if fields are valid for buying program, else
   *   false
   */
  isValidForBuyingProgram() {
    switch (this.buyingProgram) {
      case CONTRACT_BUYING_PROGRAM.ENTERPRISE_PRODUCT:
        return isValidForBuyingProgramEnterpriseProduct(this);
      case CONTRACT_BUYING_PROGRAM.ETLA:
        return isValidForBuyingProgramETLA(this);
      case CONTRACT_BUYING_PROGRAM.VIP:
        return isValidForBuyingProgramVIP(this);
      default:
        // other buying programs are not supported
        return false;
    }

    function isValidForBuyingProgramEnterpriseProduct(contract) {
      return (
        contract.isEnterpriseContract() &&
        contract.isValidAccountInfo() &&
        contract.isValidEccIds() &&
        contract.isValidEndDate() &&
        contract.isValidForModel() // TRIAL/STANDARD differ for ETLA
      );
    }

    function isValidForBuyingProgramETLA(contract) {
      return (
        contract.isEnterpriseContract() &&
        contract.isValidAccountInfo() &&
        contract.isValidEccIds() &&
        contract.isValidEndDate() &&
        contract.isValidGraceEndDateProperties() &&
        contract.isValidForModel() // TRIAL/STANDARD differ for ETLA
      );
    }

    function isValidForBuyingProgramVIP(contract) {
      return (
        contract.isIndirectContract() &&
        contract.isModelStandard() &&
        // anniversary date can be empty or defined for a new VIP contract.
        // when empty, this signals to other services that its value should
        // be set when the first order is actually placed. this helps ensure
        // that the anniversary date is accurate, despite any gap between
        // the contract being created and the first products being available
        // (i.e. - contract being created vs purchased products on contract
        // being usable)
        (contract.anniversaryDate === undefined || contract.isValidAnniversaryDate()) &&
        contract.isValidOwnerInfoAddress() &&
        contract.isValidResellerInfo() &&
        contract.isValidStartDate()
      );
    }
  }

  /**
   * @description Method to determine if the contract values are valid for
   *   the model set. TRIAL contracts support a different set of required
   *   fields that must be validated independently from STANDARD contracts.
   * @returns {Boolean} true if fields are valid for model, else false
   */
  isValidForModel() {
    switch (this.model) {
      case CONTRACT_MODEL.STANDARD:
        return isValidForModelStandard(this);
      case CONTRACT_MODEL.TRIAL:
        return true;
      default:
        // other models are not supported
        return false;
    }

    function isValidForModelStandard(contract) {
      return (
        contract.isValidAgreementNumber() &&
        contract.isValidEffectiveDate() &&
        contract.isValidOverDeployContactEmail()
      );
    }
  }

  /**
   * @description Method to determine if the grace end date offset is assigned
   *     to a valid value.
   * @returns {Boolean} true if the grace end date offset is assigned to a
   *     valid value, else false.
   */
  isValidGraceEndDateProperties() {
    if (this.isNew()) {
      return true;
    }
    const offsetAsANumber = Number(this.complianceDetails?.graceEndOffset);
    const graceEndUnit = this.complianceDetails?.graceEndUnit;
    return (
      this.complianceDetails?.graceEndOffset !== undefined &&
      offsetAsANumber >= CONTRACT_GRACE_END_DATE_OFFSET.MIN &&
      offsetAsANumber <= CONTRACT_GRACE_END_DATE_OFFSET.MAX &&
      graceEndUnit === 'DAY'
    );
  }

  /**
   * @description Method to determine if a contract's model value is valid
   *   or not.
   * @returns {Boolean} true if model value is valid, else false
   */
  isValidModel() {
    return this.model !== undefined && CONTRACT_MODEL[this.model] === this.model;
  }

  /**
   * @description Returns true if this is a valid over deploy contact email
   *
   * @returns {Boolean} true if this is a valid  over deploy contact email
   */
  isValidOverDeployContactEmail() {
    return looksLikeAnEmail(get(this, 'accountInfo.overDeployContactEmail'));
  }

  /**
   * @description Returns true if the owner info in this contract is valid
   *
   * @returns {Boolean} true if the owner info is valid, else false
   */
  isValidOwnerInfoAddress() {
    // note: street2 is an optional value and not all countries have regions
    // that can be set, so no validation is performed on these fields, as it
    // pertains to ensuring correct address has been set for a contract owner
    return [
      this.isValidOwnerInfoAddressCity(),
      this.isValidOwnerInfoAddressCountry(),
      this.isValidOwnerInfoAddressPostalCode(),
      this.isValidOwnerInfoAddressStreet1(),
    ].every((isValueValid) => isValueValid);
  }

  /**
   * @description Method to determine if the owner info address city value
   *   for this Contract is valid or not.
   * @returns {Boolean} true if owner info address city value is valid, else
   *   false
   */
  isValidOwnerInfoAddressCity() {
    const ownerInfoAddressCity = get(this, 'ownerInfo.address.city');
    return !isEmpty(ownerInfoAddressCity);
  }

  /**
   * @description Method to determine if the owner info address country
   *   value for this Contract is valid or not.
   * @returns {Boolean} true if owner info address country value is valid,
   *   else false
   */
  isValidOwnerInfoAddressCountry() {
    // not testing individually, since values are set with dropdown select
    // via a single form field (so no notion of one being invalid while
    // another is valid - it's all or nothing to mark up the field)
    const ownerInfoAddressCountryCode = get(this, 'ownerInfo.address.country.countryCode');
    const ownerInfoAddressCountryLocale = get(this, 'ownerInfo.address.country.countryLocale');
    const ownerInfoAddressCountryName = get(this, 'ownerInfo.address.country.countryName');

    return [
      !isEmpty(ownerInfoAddressCountryCode) && ownerInfoAddressCountryCode.length === 2,
      !isEmpty(ownerInfoAddressCountryLocale) && ownerInfoAddressCountryLocale.length === 5,
      !isEmpty(ownerInfoAddressCountryName),
    ].every((isValueValid) => isValueValid);
  }

  /**
   * @description Method to determine if the owner info address postal code
   *   value for this Contract is valid or not.
   * @returns {Boolean} true if owner info address postal code value is
   *   valid, else false
   */
  isValidOwnerInfoAddressPostalCode() {
    const ownerInfoAddressPostalCode = get(this, 'ownerInfo.address.postalCode');
    return !isEmpty(ownerInfoAddressPostalCode);
  }

  /**
   * @description Method to determine if the owner info address street1
   *   value for this Contract is valid or not.
   * @returns {Boolean} true if owner info address street1 value is valid,
   *   else false
   */
  isValidOwnerInfoAddressStreet1() {
    const ownerInfoAddressStreet1 = get(this, 'ownerInfo.address.street1');
    return !isEmpty(ownerInfoAddressStreet1);
  }

  /**
   * @description Returns true if the reseller info in this contract is valid
   *
   * @returns {Boolean} true if the reseller info is valid, else false
   */
  isValidResellerInfo() {
    const resellerOrgId = this.getResellerOrgId();
    return IMS_ID_REGEX.test(resellerOrgId);
  }

  /**
   * @description Method to determine if a contract's customer segment or sales channel
   * is valid
   * @returns {Boolean} true if route to market value is valid, else false
   */
  isValidSalesChannelOrCustomerSegment() {
    return (
      (this.salesChannel !== undefined && SALES_CHANNEL[this.salesChannel] === this.salesChannel) ||
      (this.customerSegment !== undefined &&
        CUSTOMER_SEGMENT[this.customerSegment] === this.customerSegment)
    );
  }

  /**
   * @description Returns true if this is a valid start date.
   *
   * @returns {Boolean} true if this is a valid start date
   */
  isValidStartDate() {
    const startDate = this.getStartDate();
    return startDate !== undefined && compareDates(startDate, new Date(MINIMUM.DATETIMEZONE)) >= 0;
  }

  /**
   * @description Method to determine if it's an VIPMP (VIP Market Place) project
   * https://wiki.corp.adobe.com/display/IDS/CME+527+-+VIP+%28Market+Place%29+%28Old+Name+Automated+Valued+Plan+%28AVP%29%29+a.k.a+Partner+API+project
   * @returns {Boolean} true if it's an VIPMP contract, else false
   */
  isVIPMPContract() {
    return this.isIndirectContract() && this.buyingProgram === CONTRACT_BUYING_PROGRAM.VIPMP;
  }

  /**
   * @description Returns true if this contract requires acceptance of terms.
   * Contract data doesn't have the information whether the current user is authorized to accept terms.
   *
   * @returns {Boolean} true if acceptance of terms is required.
   */
  mustAcceptTerms() {
    if (feature.isEnabled('temp_terms_redirect')) {
      return hasVipTermsAcceptance(this);
    }

    return invoke(this.contractTerms, 'mustAcceptTerms') || false;
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   *
   * @param {Contract} contract - contract model to save state on
   */
  registerSavedState() {
    if (this.isNew()) {
      this.savedState = {};
    } else {
      const savedContract = pick(this, EDITABLE_FIELDS);
      this.savedState = cloneDeep(toJS(savedContract));
    }
  }

  /**
   * @description Restores the contract from its saved state
   */
  restore() {
    const editableFields = EDITABLE_FIELDS;
    if (this.hasUnsavedChanges()) {
      editableFields.forEach((field) => {
        delete this[field];
      });
      initModel(this, this.savedState);
    }
  }

  /**
   * @description Save changes to the contract. This handles both the creation
   * of a new contract and updating of an existing contract.
   * @returns {Promise<Contract>} This Contract instance
   */
  async save() {
    try {
      if (this.isNew()) {
        return await createContract.call(this);
      }
      return await updateContract.call(this);
    } catch (error) {
      log.error('Failed to create/update contract. Error: ', error);
      return Promise.reject(error);
    }

    /**
     * @description Helper to create a new contract (save to back-end).
     * @returns {Promise<Contract>} This Contract instance
     */
    async function createContract() {
      const response = await jilContracts.postContract({orgId: this.orgId}, this.toMinimumModel());

      modelCache.clear(CONTRACT_LIST_CACHE_ID);
      initModel(this, response.data);
      this.registerSavedState();

      return this;
    }

    /**
     * @description Helper to save changes to an existing contract.
     * @returns {Promise<Contract>} This Contract instance
     */
    async function updateContract() {
      if (!this.hasUnsavedChanges()) {
        return this;
      }
      const getPatchOperations = () => {
        const minModel = this.toMinimumModel();
        const minModelFlattenedKeys = thru(minModel, (contract) =>
          flattenKeys(contract, '', {excludeArrayValue: true})
        );
        const changedContractFieldKeys = minModelFlattenedKeys.filter((key) =>
          hasChangedField(this, key)
        );
        const patchOperations = changedContractFieldKeys.map((key) => ({
          op: 'replace',
          path: `/${this.id}/${get(CONTRACT_PATCH_API_KEY_LOOKUP_TABLE, key)}/${get(
            minModel,
            key
          )}`,
        }));

        if (
          patchOperations.find((operation) =>
            operation.path.includes(
              CONTRACT_PATCH_API_KEY_LOOKUP_TABLE.complianceDetails.graceEndOffset
            )
          ) &&
          patchOperations.find((operation) =>
            operation.path.includes(
              CONTRACT_PATCH_API_KEY_LOOKUP_TABLE.complianceDetails.graceEndUnit
            )
          ) === undefined
        ) {
          // If we're editing the offset we need to send the unit over as well.
          patchOperations.push({
            op: 'replace',
            path: `/${this.id}/${
              CONTRACT_PATCH_API_KEY_LOOKUP_TABLE.complianceDetails.graceEndUnit
            }/${get(minModel, 'complianceDetails.graceEndUnit')}`,
          });
        }

        return patchOperations;
      };
      const contractResponses = await jilContracts.patchContract(
        {orgId: this.orgId},
        getPatchOperations()
      );

      if (
        Array.isArray(contractResponses.data) &&
        contractResponses.data[0].responseCode &&
        contractResponses.data[0].responseCode >= 200 &&
        contractResponses.data[0].responseCode <= 300
      ) {
        this.registerSavedState();
        eventBus.emit(CONTRACT_EVENT.UPDATE, this);
      }

      return this;
    }
  }

  /**
   * @description Register the orgId this contract is against.
   * @param {String} orgId - the orgId to record
   */
  setOrgId(orgId) {
    this.orgId = orgId;
  }

  /**
   * @description Saves the contract next billing amount from products:change and cart submission responses to session storage
   * @param {Object} nextBilling - Object representing
   * @param {Number} nextBilling.priceWithTax - Tax inclusive next billing amount after submitted change
   * @param {Number} nextBilling.priceWithoutTax - Tax exlusive next billing amount after submitted change
   */
  setSubmittedNextBilling(nextBilling) {
    const expires = getHoursFromNow(1);
    setSessionStorageItem(
      this.getSubmittedNextBillingCacheId(),
      JSON.stringify({
        ...pick(nextBilling, ['nextBillingDate', 'priceWithoutTax', 'priceWithTax']),
        expires,
      })
    );
  }

  /**
   * @description Method to remove extraneous properties prior to API calls
   * @returns {Object} The minimum object representing this contract
   */
  toMinimumModel() {
    // convert to plain Object to remove any properties attached to the
    // prototype chain
    const contract = assignIn({}, pick(this, EDITABLE_FIELDS));

    getContractDateFields().forEach((field) => {
      const value = get(contract, field);
      const dateString = getDateString(value);
      set(contract, field, dateString);
    });
    return contract;

    function getDateString(value) {
      if (isDate(value)) {
        return format(value, FORMAT.DATEFNS.DATETIMEZONE);
      }

      return value;
    }
  }

  /**
   * @description Translate binky Contract to Pandora Contract instance.
   * This method does not clone the current Contract instance.
   * And, the resulting Pandora Contract object may have more properties than what Pandora Contract model specifies because binky Contract has more properties.
   *
   * @returns {PandoraContract} Pandora Contract instance
   */
  toPandoraContract() {
    return new PandoraContract(this);
  }

  /**
   * @description Update contract reseller through reseller change code.
   * The current Contract instance is updated with the new reseller info when
   * the API request is successful.
   * Otherwise, an error is thrown.
   * @param {Number} resellerChangeCode Reseller change code
   */
  async updateReseller({resellerChangeCode}) {
    let apiResponse;

    try {
      apiResponse = await jilContracts.putContractChangeReseller({
        contractId: this.id,
        orgId: this.orgId,
        resellerChangeCode,
      });
    } catch (error) {
      log.error(
        `Contract.updateReseller(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      throw error?.response?.data;
    }

    this.resellerInfo = apiResponse.data.resellerInfo;
  }
}

/** Private Methods **/
/**
 * @description A method to retrieve contract date fields.
 *
 * @returns {List} returns an array of contract date fields.
 */
function getContractDateFields() {
  return ['termInfo.effectiveDate', 'termInfo.endDate', 'termInfo.startDate'];
}

/**
 * @description A method to determine if any of the contract dates have changed.
 *
 * @param {Object} contract - Contract model Object instance to initialize. See constructor.
 *
 * @returns {Boolean} returns true if changes are detected.
 */
function hasChangedContractDates(contract) {
  return getContractDateFields().some((fieldName) => hasChangedDate(contract, fieldName));
}

/**
 * @description A method to determine if a contract date has changed.
 *
 * @param {Object} contract - Contract model Object instance to initialize. See constructor.
 * @param {String} fieldName - The field of a date to check for
 *
 * @returns {Boolean} returns true if changes are detected.
 */
function hasChangedDate(contract, fieldName) {
  const date = get(contract, fieldName);
  const savedDate = get(contract, `savedState.${fieldName}`);
  return (
    (date === undefined && savedDate === undefined) ||
    (date !== undefined && savedDate !== undefined && compareDates(date, savedDate) !== 0)
  );
}

/**
 * @description A method to determine if a contract field has changed.
 *
 * @param {Object} contract - Contract model Object instance to initialize. See constructor.
 * @param {String} fieldName - The field of a date to check for
 *
 * @returns {Boolean} returns true if changes are detected.
 */
function hasChangedField(contract, fieldName) {
  if (getContractDateFields().includes(fieldName)) {
    return hasChangedDate(contract, fieldName);
  }

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return sortBy(value);
    }
    return value;
  };

  const currentValue = formatValue(get(contract, fieldName));
  const savedValue = formatValue(get(contract, `savedState.${fieldName}`));
  return get(CONTRACT_PATCH_API_KEY_LOOKUP_TABLE, fieldName) && !isEqual(currentValue, savedValue);
}

/**
 * @description Initializes Contract data from options. If dates are expressed
 *   as String values, this method will convert them into actual JS Dates.
 *
 * @param {Object} contract - Contract model Object instance to initialize. See
 *   constructor.
 * @param {Object} options - Data to initiate the model with.
 *
 * @returns {Object} the model with selected values
 */
function initModel(contract, options) {
  const clonedOptions = cloneDeep(options);

  // convert date Strings into Date Objects for app to use
  getContractDateFields().forEach((field) => {
    const value = get(clonedOptions, field);
    // if no date String is set, do not create a new Date Object...
    if (value) {
      const dateStringDate = getDateStringDate(value);
      set(clonedOptions, field, dateStringDate);
    }
  });

  assignIn(clonedOptions, {
    complianceSymptoms:
      options?.complianceSymptoms?.map((symptom) => new ComplianceSymptom(symptom)) || [],
  });

  if (feature.isEnabled('temp_terms_redirect')) {
    assignIn(clonedOptions, {
      termsAcceptances:
        options?.termsAcceptances?.map((termAcceptance) => new TermsAcceptance(termAcceptance)) ||
        [],
    });
  }

  if (feature.isEnabled('auto_renewal_opt_in_self_service')) {
    assignIn(clonedOptions, {
      autoRenewal: options?.autoRenewal || {},
    });
  }

  // do not set legacy DX contract id value here, as new Contracts do not have
  // an id value (yet)
  return assignIn(
    contract,
    pick(clonedOptions, [
      ...EDITABLE_FIELDS,
      'autoRenewal',
      'complianceSymptoms',
      'termsAcceptances',
    ])
  );

  function getDateStringDate(dateString) {
    return isDate(dateString) ? dateString : new Date(dateString);
  }
}

/**
 * @description Determines whether this contract has any VIP terms to accept.
 * VIP and VIP-MP are using the same terms thus they're considered as "VIP".
 *
 * @param {Contract} contract Contract instance to check against
 *
 * @returns {Boolean} Returns true if this contract has any VIP terms to accept. Returns false otherwise.
 */
function hasVipTermsAcceptance(contract) {
  const pandoraContractList = [contract.toPandoraContract()];

  // Any VIP or VIP-MP terms acceptance are based on the same VIP terms, thus they're treated as "VIP".
  const vipTerms = getTermsToAccept(pandoraContractList, TermsAcceptanceTemplateName.VIP);
  if (vipTerms.length > 0) {
    return true;
  }

  const vipMpTerms = getTermsToAccept(pandoraContractList, TermsAcceptanceTemplateName.VIPMP);
  return vipMpTerms.length > 0;
}

/**
 * @description Method to determine if a contract's payment category is
 *   of a particular type.
 * @param {Contract} contract - instance of Contract model to check against
 * @param {CONTRACT_PAYMENT_CATEGORY} type - type of payment category to check
 * @returns {Boolean} true if the contract's paymentCategory is equal to
 *   they type passed in
 */
function isPaymentCategoryType(contract, type) {
  return get(contract, 'billingInfo.paymentCategory') === type;
}

/**
 * @description Gets a 3YC feature object matching the provided status
 * @param {Contract} contract - instance of Contract model to check against
 * @param {('ELIGIBLE' | 'ENROLLED')} status The status of the 3YC feature to get
 * @returns {Object} 3YC feature object
 */
function get3YCFeature(contract, status) {
  return contract
    .getSelectProgramFeatures()
    .find((feat) => feat.name === THREE_YEAR_COMMIT && feat.status === status);
}

export default Contract;
/* eslint-enable max-lines -- this file requires more lines */
