/* eslint-disable max-lines -- this file requires more lines */
/**
 * Wrapper for setting up feature flags settings.
 *
 * Features can be enabled or disabled via the URL query params allFlags or flags, via the configure
 * parameter or via the IMS Floodgate service. The omni tool panel, which can be accessed via the
 * '~' key on the One Console, will show all feature states and can also be used to turn params
 * on or off.
 *
 * If the allFlags query param is specified, it is used as the lone definition of which feature
 * flags to enable. This means that all flags will be overridden to be false, unless explicity
 * set to true in the allFlags parameter. Otherwise flags specified with the query param take
 * precedence, followed by Floodgate and the config file.
 *
 * The value of the flags and allFlags query params is a comma separated string of the form
 * &flags=flagName[=boolean][,flagName[=boolean]]*
 * For example '&flags=flag1,flag2=true,flag3=false'
 */
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';

import floodgate from 'api/floodgate';
import {reloadWithPersistentData} from 'utils/persistentQueryParamDataUtils';
import {setLocalStorageItem} from 'utils/storageUtils';

import log from './log';

let cachedFloodgateReleases;
let overrideFlags = {};
const featureStates = {};
let featureAnalytics = {};
let releaseAnalytics = {};
let allReleases = {};
const REGISTERED_HANDLERS = [
  (feature) => !!(featureStates[feature] && featureStates[feature].enabled),
];

const ALL_FLAGS = 'allFlags';
const CONFIG = 'config';
const FLOODGATE = 'floodgate';
const OMNITOOL = 'omnitool';
const URL = 'url';
// TODO: Remove this once React migration is completed and switching isn't required
const USE_REACT_KEY = 'useReact';
const ENABLED = 'enabled';
const DISABLED = 'disabled';

/**
 * @description Initialize the floodgate release feature and feature names.
 * @param {Object} backupFloodgateReleases - floodgate releases features.
 * @param {String} featureNames - A string of a list of featureNames.
 */
function initialize(backupFloodgateReleases = {}, featureNames = undefined) {
  const features = featureNames ? JSON.parse(featureNames) : [];
  features.forEach((feature) => {
    if (!Object.prototype.hasOwnProperty.call(featureStates, feature)) {
      featureStates[feature] = {};
    }
  });

  cachedFloodgateReleases =
    backupFloodgateReleases && backupFloodgateReleases.releases
      ? backupFloodgateReleases.releases
      : {};
}

/**
 * @description Configures the feature flags set in the configuration file.
 * @param {Object} configFeatures - feature flags information in the configuration file.
 */
function loadConfigFeatures(configFeatures = {}) {
  // Configure the feature flags set in the configuration file.
  Object.entries(configFeatures).forEach((configFeature) => {
    const [feature, enabled] = configFeature;
    addToFeatureStates({
      enabled,
      feature,
      source: CONFIG,
    });
  });
}

function setReactSwitchFlag(enabled) {
  try {
    setLocalStorageItem(USE_REACT_KEY, enabled ? ENABLED : DISABLED);
  } catch (error) {
    // ignore error
  }
}

/**
 * @description Configures the feature flags in the localStorage Overrides.
 */
function loadLocalStorage() {
  if (localStorage.overrideFlags !== undefined) {
    try {
      overrideFlags = JSON.parse(localStorage.overrideFlags);

      Object.entries(overrideFlags).forEach((overrideFlag) => {
        const [feature, info] = overrideFlag;
        addToFeatureStates({
          enabled: info.enabled,
          feature,
          source: info.source,
        });
      });
    } catch (error) {
      log.warn('There is an issue while parsing localStorage.');
    }
  }
}

/**
 * @description Configure the feature flags from URL parameters
 */
function loadFeaturesFromURL() {
  const allFlags = getParameterByName(ALL_FLAGS);
  if (allFlags) {
    localStorage.overrideFlags = {};
    overrideFlags = {};
    if (allFlags === 'true' || allFlags === 'false') {
      applyUrlFeatures(Object.keys(featureStates), allFlags === 'true');
    } else {
      applyUrlFeatures(Object.keys(featureStates), false);
      applyUrlFeatures(allFlags);
    }
  } else {
    // Any flags specified in the 'flags' query param override flags from the URL
    const urlFlags = getParameterByName('flags');
    if (urlFlags) {
      applyUrlFeatures(urlFlags);
    }
  }

  Object.entries(overrideFlags).forEach((overrideFlag) => {
    const [feature, info] = overrideFlag;
    addToFeatureStates({
      enabled: info.enabled,
      feature,
      source: info.source,
    });
  });

  // automatically save any new url flags
  localStorage.overrideFlags = JSON.stringify(overrideFlags);
}

/**
 * @description A top level function to setup features from all resources.
 * @param {Object} configFeatures - feature flags information in the configuration file.
 * @param {Object} backupFloodgateReleases - floodgate releases features.
 * @param {String} featureNames - A string of a list of featureNames.
 */
function setup(configFeatures, backupFloodgateReleases, featureNames) {
  initialize(backupFloodgateReleases, featureNames);
  loadConfigFeatures(configFeatures);
  loadLocalStorage();
  loadFeaturesFromURL();
}

/**
 * @description Returns the names of all the features found in the code
 * @returns {Array} Returns an array of strings with all the feature names found in the code
 */
function getFeatures() {
  return Object.keys(featureStates);
}

/**
 * @description Returns the source of where feature was most recently set
 * @param {String} feature - the feature we want to find the source of
 * @returns {String} Returns "config", "floodgate", "url", "omnitool", or undefined if unset
 */
function getSource(feature) {
  return featureStates[feature] && featureStates[feature].source;
}

/**
 * @description Returns true if the feature is enabled
 * @param {String} feature - the feature to test
 * @returns {boolean} Returns true if the feature is enabled
 */
function isEnabled(feature) {
  return REGISTERED_HANDLERS.every((handler) => handler(feature));
}

/**
 * @description Returns true if the feature is disabled
 * @param {String} feature - the feature to test
 * @returns {boolean} Returns true if the feature is disabled
 */
function isDisabled(feature) {
  return !isEnabled(feature);
}

/**
 * @description Returns true if the feature has been prepared for a change
 * @param {String} feature - the feature to test
 * @returns {boolean} Returns true if the feature has been prepared for a change
 */
function hasChange(feature) {
  const source = getSource(feature);
  const enabled = isEnabled(feature);
  const overrideFlagInfo = overrideFlags[feature];

  // If overrideFlags does not match featureStates for a particular feature
  // then a change has been prepared
  return overrideFlagInfo
    ? // We have the override info, but the source or enabled
      // value doesn't match featureStates
      overrideFlagInfo.enabled !== enabled || overrideFlagInfo.source !== source
    : // We don't have the override info, but the source from
      // featureStates indicates that the flag has been overrode
      source === OMNITOOL;
}

/**
 * @description Returns the most recent non-overriding source of where the feature was set
 * @param {String} feature - the feature we want to find the source of
 * @returns {String} Returns "config", "floodgate" or undefined if unset
 */
function getNonOverrideSource(feature) {
  const sources = {
    [CONFIG]: featureStates[feature] && featureStates[feature].config,
    [FLOODGATE]: featureStates[feature] && featureStates[feature].floodgate,
  };

  return Object.keys(sources).find((key) => sources[key] !== undefined);
}

/**
 * @description Returns the value from the most recent non-overrided source
 * @param {String} feature - the feature we want to look up
 * @returns {String} Returns the value from the most recent non-overrided source
 */
function getNonOverrideValue(feature) {
  const source = getNonOverrideSource(feature);
  return (featureStates[feature] && featureStates[feature][source]) || false;
}

/**
 * @description Returns the prepared value of the feature in overrides
 * @param {String} feature - the feature we want to look up
 * @returns {String} Returns the prepared value of the feature in overrides
 */
function getOverrideValue(feature) {
  return overrideFlags[feature] && overrideFlags[feature].enabled;
}

/**
 * @description Returns the value for the feature analytics variant id
 * @param {String} feature - the feature we want to look up
 * @returns {Number} Returns the variant id value associated with the feature.
 *                   This variant id can be:
 *                     * 0, the user fell into the control group
 *                     * non-zero and not equal to -1, user fell in a variant
 *                     * -1, the data is not valid for this feature
 *                     * undefined, if there is no analytics data associated with this feature
 */
function getVariantId(feature) {
  return featureAnalytics?.[feature]?.variant_id;
}

/**
 * @description Returns the value for the release or feature group analytics variant id
 * @param {String} release - the release we want to look up
 * @returns {Number} Returns the variant id value associated with the release.
 *                   This variant id can be:
 *                     * 0, the user fell into the control group
 *                     * non-zero and not equal to -1, user fell in a variant
 *                     * -1, the data is not valid for this feature
 *                     * undefined, if there is no analytics data associated with this feature
 */
function getReleaseVariantId(release) {
  return releaseAnalytics?.[release]?.variant_id;
}

/**
 * @description Returns all feature groups and releases that the current user is eligible for
 * @returns {Object} Returns all feature groups and releases objects that the current user is eligible for, the reason
 *                   I don't use releaseAnalytics is it only pick the first release_analytics_params for each feature
 *                   group which doesn't meet the request.
 */
function getAllReleases() {
  return allReleases;
}

/**
 * @description Returns true if the feature is overridden, or prepared to be overridden
 * @param {String} feature - the feature to test
 * @returns {boolean} Returns true if the feature is overridden, or prepared to be overridden
 */
function isOverridden(feature) {
  return !!overrideFlags[feature] || false;
}

/**
 * @description Registers a function called to verify feature enablement
 * @param {Function} handler - the handler to register
 */
function registerHandler(handler) {
  REGISTERED_HANDLERS.push(handler);
}

/**
 * @description Resets all features from overrides
 */
function resetOverrides() {
  overrideFlags = {};
}

/**
 * @description prepares the given feature to overrides
 * @param {Object} state - state of the feature being added
 * @param {String} state.feature - the feature to prepare to overrides
 * @param {boolean} state.enabled - whether the feature will be active or inactive
 * @param {String} state.source - where the feature was set
 */
function prepareOverride({feature, enabled, source = OMNITOOL}) {
  addToOverrideFlags({enabled, feature, source});
}

/**
 * @description Unprepares the given feature from overrides
 * @param {String} feature - the feature to unprepare from overrides
 */
function unprepareOverride(feature) {
  delete overrideFlags[feature];
}

/**
 * @description Refreshes the features
 * @returns {Object} Returns the refreshed floodgate released features.
 */
async function refresh({floodgateQueryParams = {}} = {}) {
  function storeFloodgateFeatures(releases) {
    featureAnalytics = {}; // We always want to store latest analytics data
    releaseAnalytics = {}; // We always want to store latest analytics data
    allReleases = releases;
    let useReactIsEnabled = false;
    if (releases.length > 0) {
      releases.forEach((release) => {
        const releaseName = release?.release_name;
        if (releaseName) {
          releaseAnalytics[releaseName] = release?.release_analytics_params?.[0];
        }
        release.features.forEach((feature) => {
          addToFeatureStates({enabled: true, feature, source: FLOODGATE});
          if (feature === USE_REACT_KEY) {
            useReactIsEnabled = true;
          }
        });
        release?.release_analytics_params?.forEach((featureAnalytic) => {
          const feature = featureAnalytic?.f_key;
          if (feature) {
            featureAnalytics[feature] = featureAnalytic;
          }
        });
      });
    }
    // TODO: Remove this once React migration is completed and switching isn't required
    setReactSwitchFlag(useReactIsEnabled);
  }

  if (!isEnabled(FLOODGATE)) {
    return {};
  }

  try {
    const featureResponse = await floodgate.getReleases({queryParams: floodgateQueryParams});
    if (featureResponse.releases) {
      // Merge releases into a single array.
      const mergedReleases = featureResponse.releases.reduce(
        (acc, curr) => [...acc, ...curr.features],
        []
      );

      // Disable features in 'featureStates' that were enabled
      // but 'floodgate.getReleases' didn't return them, which means they are disabled now.
      // Applies only to features coming from floodgate. Features in the omni tool or the URL would not be impacted.
      Object.entries(featureStates).forEach(([key, value]) => {
        const shouldDisable =
          !mergedReleases.includes(key) && value.floodgate === true && value.enabled === true;

        if (shouldDisable) {
          addToFeatureStates({enabled: false, feature: key, source: FLOODGATE});
        }
      });

      storeFloodgateFeatures(featureResponse.releases);
      return featureResponse.releases;
    }
    return apiCallFailure(featureResponse);
  } catch (error) {
    return apiCallFailure(error);
  }

  function apiCallFailure(result) {
    // Log the error, but fallback to the cached features from the last build
    log.error(`Error occurred while calling floodgate ${result}`);
    storeFloodgateFeatures(cachedFloodgateReleases);
    return cachedFloodgateReleases;
  }
}

/**
 * @description saves the overrides to local storage and reloads the page
 */
function saveAndReload() {
  localStorage.overrideFlags = JSON.stringify(overrideFlags);
  reloadWithPersistentData();
}

////////////

// Take the flags from the URL if they're present.
function getParameterByName(name) {
  return decodeURI(
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- @wenywang to fix
    window.location.search.replace(
      new RegExp(
        `^(?:.*[&\\?]${encodeURI(name).replace(/[*+.]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`,
        'i'
      ),
      '$1'
    )
  );
}

/**
 * @description merges the given information into featureStates
 * @param {Object} state - state of the feature being added
 * @param {String} state.feature - the feature who's state is updating
 * @param {Boolean} state.enabled - whether the feature was set to be active or inactive
 * @param {String} state.source - where the feature was set
 */
function addToFeatureStates({feature, enabled, source}) {
  // first update the feature states with the new source state for the feature
  merge(featureStates, {
    [feature]: {
      [source]: enabled,
    },
  });

  // find the first set source for the feature
  // OT
  const sources = {
    /* eslint-disable sort-keys -- hierarchy of the source */
    [OMNITOOL]: featureStates[feature][OMNITOOL],
    [URL]: featureStates[feature][URL],
    [FLOODGATE]: featureStates[feature][FLOODGATE],
    [CONFIG]: featureStates[feature][CONFIG],
    /* eslint-enable sort-keys -- hierarchy of the source */
  };
  const newSource = findKey(sources, (value) => value !== null && value !== undefined);
  // update the source, and the value to be from that source
  merge(featureStates, {
    [feature]: {
      enabled: featureStates[feature][newSource],
      source: newSource,
    },
  });
}

// Parse and apply the features obtained from the URL
function applyUrlFeatures(urlFeatures, enabled) {
  const flagsArray = typeof urlFeatures === 'string' ? urlFeatures.split(',') : urlFeatures;
  flagsArray.forEach((flag) => {
    const keyValues = decodeURIComponent(flag).split('=');
    const key = keyValues[0];
    let value = keyValues.length > 1 ? keyValues[1] === 'true' : true;
    value = enabled === null || enabled === undefined ? value : enabled;
    addToFeatureStates({enabled: value, feature: key, source: URL});
    addToOverrideFlags({enabled: value, feature: key, source: URL});
  });
}

/**
 * @description stores the given information into overrideFlags
 * @param {Object} state - state of the feature being added
 * @param {String} state.feature - the feature who's state is updating
 * @param {Boolean} state.enabled - whether the feature was set to be active or inactive
 * @param {String} state.source - where the feature was overridden (url or omnitool)
 */
function addToOverrideFlags({feature, enabled, source}) {
  Object.assign(overrideFlags, {[feature]: {enabled, source}});
}

////////////////

const feature = {
  getAllReleases,
  getFeatures,
  getNonOverrideSource,
  getNonOverrideValue,
  getOverrideValue,
  getReleaseVariantId,
  getSource,
  getVariantId,
  hasChange,
  initialize,
  isDisabled,
  isEnabled,
  isOverridden,
  loadConfigFeatures,
  loadFeaturesFromURL,
  loadLocalStorage,
  prepareOverride,
  refresh,
  registerHandler,
  resetOverrides,
  saveAndReload,
  setup,
  unprepareOverride,
};

export default feature;
/* eslint-enable max-lines  -- this file requires more lines */
