import {CONTRACT_MODEL} from '@admin-tribe/binky';
import {Item, Picker, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Contract Picker component used for selecting a particular contract
 * under the ETLA buying program in a specific organization
 */
const ContractPicker = ({contractInfoList, onSelectionChange}) => {
  const intl = useIntl();
  const pickerLabel = (
    <Text>
      {intl.formatMessage({
        id: 'binky.common.etlaUsageReport.contractPicker.label',
      })}
    </Text>
  );
  const pickerInfoList = contractInfoList.map((contractInfo) => ({
    id: contractInfo.contractId,
    text: intl.formatMessage(
      {
        id:
          contractInfo.type.toUpperCase() === CONTRACT_MODEL.ALLOCATION
            ? 'binky.common.etlaUsageReport.contractPicker.allocationModel.text'
            : 'binky.common.etlaUsageReport.contractPicker.otherModels.text',
      },
      {
        contractId: contractInfo.contractId,
      }
    ),
  }));
  return (
    <Picker
      defaultSelectedKey={pickerInfoList[0].id}
      label={pickerLabel}
      labelAlign="start"
      labelPosition="side"
      marginBottom="size-200"
      marginTop="size-200"
      maxWidth="100%"
      onSelectionChange={onSelectionChange}
      width="size-5000"
    >
      {pickerInfoList.map(({id, text}) => (
        <Item key={id}>{text}</Item>
      ))}
    </Picker>
  );
};

ContractPicker.propTypes = {
  /**
   * Array of objects each representing contract info
   * Each such object has the following fields:
   * - contractId(String): id of contract
   * - type(String): type of contract
   */
  contractInfoList: PropTypes.arrayOf(
    PropTypes.shape({
      contractId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Callback method invoked when the current contract selection is changed by the user
   * The following would be passed as argument:
   * - id(String): id of the contract that has been selected by the user.
   */
  onSelectionChange: PropTypes.func.isRequired,
};

export default ContractPicker;
