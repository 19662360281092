import PropTypes from 'prop-types';
import React from 'react';

import EtlaUsageReport from './EtlaUsageReport';
import TopErrorScreen from './TopErrorScreen';

/**
 * A component that will display etla usage report on insights dashboard.
 */
const InsightsDashboardEtlaUsageReport = ({displayErrorScreen, errorStatus, orgId, reportData}) => {
  if (displayErrorScreen) {
    return <TopErrorScreen errorStatus={errorStatus} />;
  }
  return <EtlaUsageReport orgId={orgId} reportData={reportData} />;
};
InsightsDashboardEtlaUsageReport.propTypes = {
  /**
   *  A flag to check whether to display error screen or not.
   */
  displayErrorScreen: PropTypes.bool,
  /**
   * An object defining errorStatus, in case of displaying error screen.
   */
  errorStatus: PropTypes.number,
  /**
   * The organisation id for which etla usage report dashboard data is to be displayed.
   */
  orgId: PropTypes.string.isRequired,
  /**
   * An object defining the reportData which wraps timePeriods, currentYear_dataPoints, productList, contractId and orgId.
   */
  reportData: PropTypes.shape({
    contractId: PropTypes.string,
    dataPoints: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
      dataPoints: PropTypes.arrayOf(PropTypes.object),
      endDate: PropTypes.number,
      startDate: PropTypes.number,
    }),
    dataPointsStatus: PropTypes.string,
    endDate: PropTypes.number,
    graceEndDate: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    productsTableData: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    timePeriods: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default InsightsDashboardEtlaUsageReport;
