(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-icon
   * @description module that defines the product icon widget
   */
  angular.module('binky.widgets.products.product-icon', [
    'binky.core.lodash',
    'pascalprecht.translate',
  ]);
})();
