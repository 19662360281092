(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.directories')
    .constant('DIRECTORY_LIST_CACHE_ID', 'DirectoryList')
    .constant('DIRECTORY_LIST_EVENT', {
      UPDATE: 'UpdateDirectoryList',
      UPDATE_COUNT: 'UpdateDirectoriesCount',
    });
})();
