(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.omni-tool.fakes-panel
   * @description module that defines the omni tool fakes-panel widget
   */
  angular.module('binky.widgets.omni-tool.fakes-panel', [
    'binky.core.lodash',
    'binky.widgets.omni-tool.panel-manager',
  ]);
})();
