import {Cell, Column, Flex, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getProductDisplayName} from '../../../../features';

import DeletedProductToolTip from './DeletedProductToolTip';
import NoProductFoundErrorScreen from './NoProductFoundErrorScreen';
import {getQuantityStr} from './SearchAndProductsUtils';

/**
 * A component that will display etla usage report products table.
 */
const EtlaUsageReportProductTable = ({onSelectProduct, productsTableData}) => {
  const intl = useIntl();
  const defaultSelectedKeys = new Set();
  if (productsTableData.length > 0) {
    defaultSelectedKeys.add(productsTableData[0].product.id);
  }
  return (
    <TableView
      aria-label={intl.formatMessage({id: 'binky.widgets.reports.productTable.label'})}
      defaultSelectedKeys={defaultSelectedKeys}
      disallowEmptySelection
      height="size-6000"
      onSelectionChange={onSelectProduct}
      overflowMode="wrap"
      renderEmptyState={() => <NoProductFoundErrorScreen />}
      selectionMode="single"
      selectionStyle="highlight"
    >
      <TableHeader data-testid="table-header">
        <Column minWidth="38%">
          {intl.formatMessage({id: 'binky.widgets.reports.listTable.header.name'})}
        </Column>
        <Column align="end">
          <Flex
            UNSAFE_style={{
              overflowWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {intl.formatMessage({id: 'binky.widgets.reports.listTable.header.licenses'})}
          </Flex>
        </Column>
        <Column align="end">
          <Flex
            UNSAFE_style={{
              overflowWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {intl.formatMessage({id: 'binky.widgets.reports.listTable.header.assigned'})}
          </Flex>
        </Column>
      </TableHeader>
      <TableBody items={productsTableData}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- table virtualizer requires Cell here */}
        {(item) => (
          <Row key={item.product.id}>
            <Cell>
              <Flex>
                <Flex marginTop="size-100">
                  <ImageIcon alt="" size="S" src={item.product.assets.icons.svg} />
                </Flex>
                <Flex
                  marginTop="size-100"
                  marginX="size-100"
                  UNSAFE_style={{
                    overflowWrap: 'anywhere',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {getProductDisplayName(intl, item.product)}
                </Flex>
                {item.bundleProduct?.deleted && <DeletedProductToolTip />}
              </Flex>
            </Cell>
            <Cell>{getQuantityStr(intl, 'provisioned', item)}</Cell>
            <Cell>{getQuantityStr(intl, 'delegated', item)}</Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

EtlaUsageReportProductTable.propTypes = {
  /**
   * A callback called when a product is selected from product table list with parameter as select product item.
   */
  onSelectProduct: PropTypes.func.isRequired,
  /**
   * An array of objects wrapping bundleProduct and Product.
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  productsTableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EtlaUsageReportProductTable;
