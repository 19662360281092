import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilUserGroups from 'api/jil/jilUserGroups';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';
import LicenseGroup from 'services/product/license-group/LicenseGroup';

import {USER_GROUP_LICENSE_GROUPS_EVENT} from './UserGroupLicenseGroupsConstants';

class UserGroupLicenseGroupsList extends JilModelList {
  /**
   * @description Method to retrieve an existing list of UserGroupLicenseGroupsList
   *
   * @param {Object} options object. See constructor for params
   * @returns {Promise<UserGroupLicenseGroupsList>} new UserGroupLicenseGroupsList Object reference
   */
  static get(options) {
    const model = new UserGroupLicenseGroupsList(options);
    return model.refresh();
  }

  /**
   * @class UserGroupLicenseGroupsList
   * @description Method to create a new instance of a UserGroupLicenseGroupsList.
   *
   * @param {Object}[options] - See JilModelList for additional options
   * @param {String} options.groupId - The user group id.
   * @param {String} options.orgId - The org id.
   * @param any other JilModelList option
   */
  constructor({groupId, orgId, ...options}) {
    super({
      itemClassRef: LicenseGroup,
      resource: jilUserGroups.getUserGroupLicenseGroups,
      sortExpression: JIL_CONSTANTS.SORT.NAME,
      sortOrder: JIL_CONSTANTS.ORDER.ASC,
      ...options,
    });
    this.orgId = orgId;
    this.groupId = groupId;
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<UserGroupLicenseGroupsList>} resolves to refreshed UserGroupLicenseGroupsList model, else rejects with error message
   */
  async refresh() {
    try {
      await super.refresh({
        orgId: this.orgId,
        userGroupId: this.groupId,
      });
    } catch (error) {
      log.error('UserGroupConfigurationList.refresh() failed. Error: ', error);
      return Promise.reject(error);
    }

    eventBus.emit(USER_GROUP_LICENSE_GROUPS_EVENT.UPDATE.LIST, this.groupId);

    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(USER_GROUP_LICENSE_GROUPS_EVENT.UPDATE.COUNT, this.pagination.itemCount);
    }

    return this;
  }

  /**
   * @description Method to save changes to user group license group list to the back-end.
   *
   * @returns {Promise<UserGroupLicenseGroupsList>} resolves if changes successfully saved, else rejects with error message
   */
  async save() {
    const getOperations = (op, items) =>
      items.map((item) => ({
        op,
        path: `/${item.id}/product/${item.product.id}`,
      }));

    if (!this.hasUnsavedChanges()) {
      return this;
    }

    const addOperations = getOperations('add', this.addedItems);
    const removeOperations = getOperations('remove', this.removedItems);

    const operations = [...addOperations, ...removeOperations];

    try {
      await jilUserGroups.patchUserGroupLicenseGroups(
        {orgId: this.orgId, userGroupId: this.groupId},
        operations
      );
    } catch (error) {
      log.error('Failed to add/remove configs from list. Error: ', error);
      return Promise.reject(error);
    }

    this.resetAddedItems();
    this.resetRemovedItems();

    this.filter.lastQuery = this.filter.query;
    this.filter.query = '';

    try {
      await this.refresh();
    } catch (error) {
      log.error('UserGroupLicenseGroupsList.save() refresh failed. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }
}
export default UserGroupLicenseGroupsList;
