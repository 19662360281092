import jilDirectories from 'api/jil/jilDirectories';
import DirectoryUser from 'models/directory/DirectoryUser';
import MemberList from 'services/member/MemberList';

import {DIRECTORY_USER_LIST_CACHE_ID} from './DirectoryUserListConstants';

/**
 * UserList for directory users.
 */
class DirectoryUserList extends MemberList {
  /**
   * @param {Object} options - Top level wrapper
   * @param {string} options.directoryId - Id of the directory the users belong
   *     to.
   */
  constructor({directoryId, ...options}) {
    super({
      // Because of the large number of directory users, we let the cache timeout.
      // When users are removed, via save(), the cache will be explicitly cleared.
      // Removal is the only operation supported within Admin Console.
      // While not ideal, admins know to refresh the page/list if they externally add users.
      cacheClearingEvents: [],
      itemClassRef: DirectoryUser,
      modelCacheId: DIRECTORY_USER_LIST_CACHE_ID,
      refreshResource: jilDirectories.getDirectoryUsers,
      saveResource: jilDirectories.patchDirectoryUsers,
      ...options,
    });
    this.directoryId = directoryId;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      directoryId: this.directoryId,
    };
  }

  /**
   * @description Method to save changes to the directory user list.
   *     Overriding userList.save because we need to provide the directoryId to
   *     the resource performing the API call so the generated yurel includes the
   *     directoryId. By default, the list is not refreshed.
   * @param {Object} [options] - options to alter the behavior of save. See MemberList.save() for more details
   * @returns {Promise} promise - resolved after the API call is executed
   */
  async save(options = {}) {
    await super.save(
      {
        directoryId: this.directoryId,
      },
      {refresh: false, ...options}
    );

    // This list only clears its cache on removal of directory users.
    // Listening to MEMBER_EVENT.UPDATE would cause the cache to be cleared whenever any of the user
    // lists are modified.
    this.clearCache();

    return this;
  }
}

export default DirectoryUserList;
