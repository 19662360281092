/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated Please use src2/core/services/modelList/ModelList.js
   *
   * @ngdoc factory
   * @name List
   * @description abstract class for representing collections of objects
   */
  angular.module('binky.core.common.list').factory('List', getList);

  /* @ngInject */
  function getList(
    $log,
    $q,
    _,
    JIL_CONSTANTS,
    LIST_DEFAULT_PAGE_SIZE,
    modelCache,
    modelCacheUtils,
    ModelError
  ) {
    class List {
      /**
       * @class
       * @description Constructor for List model Objects.
       * @param {Object} [options] - List instance settings
       * @param {String} [options.cacheType] - key to use to find the cache
       * @param {String} [options.filterQuery] - query to filter results against
       * @param {Array} [options.items] - actual things to include in this list
       * @param {Class} [options.itemClassRef] - class to instantiate list items as on refresh
       *   Optionally, this can be an array of classes. In this case, each class is expected
       *   to have a canTransform() method to determine which class to use when instantiating
       *   an individual response item.
       * @param {Number} [options.itemCount] - the total number of items available, if known; part of pagination
       * @param {String} [options.itemId] - name of the item property which uniquely identifies an
       *   item in the list. Typically this is the 'id' property, but for Domains it is 'domainName'
       *   (defaults to 'id')
       * @param {Number} [options.pageNumber] - page number of cached model to fetch
       * @param {Number} [options.pageSize] - number of items to display per page
       * @param {Resource} [options.resource] - $resource object to perform operations on
       * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
       * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
       * @param {ListState} [options.state] - list state
       */
      constructor(options = {}) {
        this.items = options.items || [];
        this.addedItems = [];
        this.removedItems = [];

        this.pagination = {
          currentPage: options.pageNumber || 1,
          itemCount: options.itemCount || 0,
          pageSize: options.pageSize || LIST_DEFAULT_PAGE_SIZE,
        };

        this.filter = {
          lastQuery: '',
          query: options.filterQuery || '',
          visible: false,
        };

        this.sort = {
          expression: options.sortExpression || '',
          order: options.sortOrder || '',
        };
        this.SEARCH_QUERY_PATTERN = angular.copy(JIL_CONSTANTS.FILTER.PATTERN);
        this.SEARCH_QUERY_MIN_LENGTH = 3;

        this.state = options.state;

        this.resource = options.resource;
        this.itemClassRef = options.itemClassRef;
        this.itemId = options.itemId || 'id';
        this.cacheType = options.cacheType;
      }

      /**
       * @description Method for adding items to a list
       * @param {Array} items - items to add to list
       */
      add(items) {
        const vm = this;
        const itemsToAdd = [];

        _.forEach(items, (item) => {
          // Note, if a list requires a non-ID based approach, it can override for now
          const itemPredicate = this.getItemPredicate(item);
          const itemPendingRemove = _.find(vm.removedItems, itemPredicate);

          if (itemPendingRemove) {
            // item was pending remove (unsaved)
            vm.removedItems = _.without(vm.removedItems, itemPendingRemove);
          } else if (
            !_.find(vm.items, itemPredicate) && // item not in list of items
            !_.find(vm.addedItems, itemPredicate) && // item not in list of items to add
            !_.find(itemsToAdd, itemPredicate)
          ) {
            // item not in list of items to add to list of items to add
            itemsToAdd.push(item);
          }
        });

        this.addedItems = _.union(this.addedItems, itemsToAdd);
      }

      /**
       * @description Returns the predicate for the specified item, which can be used for uniquely
       *   identifying the item in a list.
       *
       * @param {Object} item the list item whose predicate should be returned
       * @return {Object} the predicate, e.g. {id: '34fh23hf23@AdobeID'} or
       *   {domainName: 'example.com'}
       */
      getItemPredicate(item) {
        return {[this.itemId]: item[this.itemId]};
      }

      /**
       * @description Method to fetch the total count of items for this list.
       *
       * @return {Number} the number of items available to this list on the server
       */
      getTotalItemCount() {
        return _.get(this, 'pagination.itemCount');
      }

      /**
       * @description Method to determine if there are any items in this list or the search query is
       *              empty.
       *
       * @return {Boolean} true if the number of items available to this list on the server is 0 or
       *                   search query is empty
       */
      hasContent() {
        return !this.isEmpty() || this.filter.query;
      }

      /**
       * @description Method to determine if there are any unsaved changes to this
       *              list. Unsaved changes are either items pending addition or
       *              removal to/from this list since list creation or last save.
       * @returns {Boolean} true if there are unsaved changes, else false
       */
      hasUnsavedChanges() {
        return this.addedItems.length > 0 || this.removedItems.length > 0;
      }

      /**
       * @description Method to determine if there are any items in this list on the server.
       *
       * @return {Boolean} true if the number of items available to this list on the server is 0
       */
      isEmpty() {
        return _.get(this, 'pagination.itemCount', 0) === 0;
      }

      /**
       * @description Method to determine if an item is pending removal from this
       *              list.
       * @param {Object} item - Item to see if removal pending
       * @returns {Boolean} true if item is pending removal, else false
       */
      isPendingRemoval(item) {
        const vm = this;
        return !!_.find(vm.removedItems, this.getItemPredicate(item));
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @param {Object} params - params passed into a GET
       * @returns {String} key to uniquely identify this list
       */
      key(params = {}) {
        return this.state
          ? modelCacheUtils.getParameterizedKey(_.assign(this.state.toQueryParams(), params))
          : modelCacheUtils.getParameterizedKey(
              _.assign(
                {
                  page: this.pagination.currentPage - 1,
                  pageSize: this.pagination.pageSize,
                  searchQuery: this.filter.query,
                  sort: this.sort.expression,
                  sortOrder: this.sort.order,
                },
                params
              )
            );
      }

      /**
       * @description
       * Method to refresh the contents of the list
       *
       * @param {Object} params - params to pass into the GET
       * @param {Object} options - options to control the flow in this function (do not get passed into the GET call)
       * @param {Boolean} [options.delegatePromise] - if true, this.$promise must be resolved in the inherited class
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(params = {}, options = {}) {
        const deferred = $q.defer();
        if (!options.delegatePromise) {
          this.$promise = deferred.promise;
          this.$resolved = false;
        }
        // if the query is fewer than 3 characters (default), we don't make it
        if (this.filter.query !== '' && this.filter.query.length < this.SEARCH_QUERY_MIN_LENGTH) {
          this.filter.query = '';
        }
        // reset the current page if the search query has changed, otherwise
        // the offset will be incorrect
        if (this.filter.query !== this.filter.lastQuery) {
          this.pagination.currentPage = 1;
        }

        this.resource.query(
          _.assign(
            this.state
              ? this.state.toQueryParams()
              : {
                  page: this.pagination.currentPage - 1,
                  page_size: this.pagination.pageSize,
                  search_query: this.filter.query,
                  sort: this.sort.expression,
                  sort_order: this.sort.order,
                },
            params
          ),
          onQuerySuccess.bind(this),
          onQueryError.bind(this)
        );

        return deferred.promise;

        function onQueryError(error) {
          $log.error('List.refresh(): Failed to refresh data from back-end. Error: ', error);
          if (!options.delegatePromise) {
            this.$resolved = true;
          }
          deferred.reject(error);
        }

        function onQuerySuccess(response, headers) {
          // update state params based on response and headers
          if (this.state) {
            this.state.setResponseAndHeaders(response, headers);
          } else {
            // update model settings
            this.pagination.itemCount =
              parseInt(headers(JIL_CONSTANTS.HEADERS.TOTAL_COUNT), 10) || 0;
            this.filter.lastQuery = this.filter.query;
          }

          // update model items
          try {
            this.items = _.isArray(this.itemClassRef)
              ? transformMixedResponseData(response, this, this.itemClassRef)
              : transformResponseData(response, this, this.itemClassRef);
          } catch (error) {
            // we catch and reject errors, to ensure they don't fall through
            $log.error(error);
            if (!options.delegatePromise) {
              this.$resolved = true;
            }
            deferred.reject(error);
            // we return to avoid resolving after the rejection
            return;
          }
          if (!options.delegatePromise) {
            this.$resolved = true;
          }
          deferred.resolve(this);

          if (this.cacheType) {
            modelCache.put(this.cacheType, this, this.key(params));
          }
        }
      }

      /**
       * @description Method for removing items from a list
       * @param {Array} items - items to remove from list
       */
      remove(items) {
        const vm = this;
        const itemsToRemove = [];

        _.forEach(items, (item) => {
          const itemPredicate = this.getItemPredicate(item);
          const itemPendingAdd = _.find(vm.addedItems, itemPredicate);

          if (itemPendingAdd) {
            // item was pending add (unsaved)
            vm.addedItems = _.without(vm.addedItems, itemPendingAdd);
          } else {
            itemsToRemove.push(item);
          }
        });

        this.removedItems = _.union(this.removedItems, itemsToRemove);
      }

      /**
       * @description Method to reset the list to its collapsed state.
       *   This is being introduced for tables with a large number of items that wish to return to a search view after
       *   each operation rather than refreshing the entire list.
       *
       // eslint-disable-next-line valid-jsdoc
       * returns {Promise} a promise which is resolved with the model
       */
      reset() {
        ModelError.throwUnimplementedMethodException(this);
      }

      /**
       * @description Method to remove any items pending addition to this list.
       */
      resetAddedItems() {
        this.addedItems = [];
      }

      /**
       * @description Method to remove any items pending removal from this list.
       */
      resetRemovedItems() {
        this.removedItems = [];
      }

      /**
       * @description Method to search and refresh the list.
       * @param {String} value search query
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      search(value) {
        if (value !== this.filter.query) {
          this.filter.query = value;
          return this.refresh();
        }
        return $q.resolve();
      }

      /**
       * @description Method to set the current page of results to display.
       *
       * @param {Number} newPage page number to select
       * @return {undefined} no return value
       */
      setPage(newPage) {
        if (newPage > -1 && newPage !== this.pagination.currentPage - 1) {
          // valid page (starts at zero and not same page that is currently selected)
          this.pagination.currentPage = 1 + newPage;
        }
      }

      /**
       * @description Method to set the current page of results to display.
       *
       * @param {Number} newPage 1-based page number to select.
       *   Default is 1 which is the first page.
       */
      setPageNumber(newPage = 1) {
        if (newPage >= 1) {
          this.pagination.currentPage = newPage;
        }
      }

      /**
       * @description Method to set the page size of current results to display.
       *
       * @param {Number} newPageSize number of results to display per page
       * @return {undefined} no return value
       */
      setPageSize(newPageSize) {
        if (newPageSize > 0 && newPageSize !== this.pagination.pageSize) {
          // valid size for page
          this.pagination.pageSize = newPageSize;
        }
      }

      /**
       * @description Method to determine whether we need to update the total itemCount of a list.
       *
       * @return {Boolean} true if the total itemCount needs to be updated
       */
      shouldUpdateTotalItemCount() {
        return _.has(this, 'pagination.itemCount') && this.filter.query === '';
      }

      /**
       * @description Method to change the sort ordering for this list.
       * @param {String} property - attribute to sort people by
       * @param {Boolean} [desc] - true if should sort in descending order, else false
       */
      sortBy(property, desc) {
        this.sort = {
          expression: property,
          order: desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
        };
      }
    }

    List.SEARCH_QUERY_PATTERN = angular.copy(JIL_CONSTANTS.FILTER.PATTERN);

    return List;

    //////////////

    /**
     * @description Method to transform list of homogeneous objects into a
     *              list of instances of a single classRef.
     *
     * @param {Array} responseData list of objects
     * @param {List} listRef the list being operated on
     * @param {Class} classRef a class reference
     *
     * @return {Array} an array of classRef objects
     */
    function transformResponseData(responseData, listRef, classRef) {
      return _.map(responseData, (responseItem, idx) =>
        classRef.apiResponseTransformer(responseItem, listRef, idx)
      );
    }

    /**
     * @description Method to transform list of heterogenous objects into a
     *              list of instances of multiple classRefs.
     *
     * @param {Array} responseData list of objects
     * @param {List} listRef the list being operated on
     * @param {Array} classRefs list of class references to transform the
     *    data into.
     *
     * @return {Array} an array of classRef objects
     */
    function transformMixedResponseData(responseData, listRef, classRefs) {
      return _.map(responseData, (responseItem) => {
        const classRef = _.find(classRefs, (ref) => ref.canTransform(responseItem));
        return classRef ? classRef.apiResponseTransformer(responseItem, listRef) : responseItem;
      });
    }
  }
})();
/* eslint-enable max-lines */
