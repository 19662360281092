import {defineContents} from '@pandora/react-content-provider';
import {PriceContentModel} from '@pandora/react-price';

// A custom content model that can be set on the Pandora price component.
const WithoutParenthesisPriceContentModel = defineContents('WithoutParenthesisPriceContent', {
  priceContent: {
    id: 'priceContent',
    type: PriceContentModel,
  },
});

export default WithoutParenthesisPriceContentModel;
