(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.core.api.search').provider('search', searchProvider);

  /* @ngInject */
  function searchProvider(_) {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = _.get(config, 'url');
    }

    ////////////

    /* @ngInject */
    function $get($resource) {
      const service = {
        searchHelpTopics: getSearchHelpTopics(),
      };

      return service;

      ////////////

      function getSearchHelpTopics() {
        return $resource(
          url,
          {},
          {
            save: {
              headers: {
                'Content-Type': 'application/vnd.adobe.search-request+json',
              },
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
