import binky from '@admin-tribe/binky';
import {action, computed, makeObservable, observable} from 'mobx';

import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from 'common/components/add-user-form-table/utils/MemberAndSelectedItemsConstants';

import MemberAndSelectedItems from './MemberAndSelectedItems';

const {ERROR_STATUS} = MEMBER_AND_SELECTED_ITEMS_CONSTANTS;

const OrganizationUserList = binky.services.organization.OrganizationUserList;

const OPERATION = {
  ADD: 'add',
  REMOVE: 'remove',
};

/**
 * An object that reflects the state of the AddUserFormTable.
 */
class MemberAndSelectedItemsList {
  /**
   * Transform MemberAndSelectedItemsList into OrgUserList.
   * The transformed OrgUserList will exclude all invalid org users that do not have any unsaved changes.
   * @param {MemberAndSelectedItemsList} model An instance of MemberAndSelectedItemsList
   * @param {String} orgId The org Id
   * @param {Function} toOrgUser A callback function that transforms a valid memberAndSelectedItems to OrgUser
   * @returns {OrganizationUserList} An instance of OrganizationUserList
   */
  static toOrgUserUnsavedList(model, orgId, toOrgUser) {
    const orgUserList = new OrganizationUserList({orgId});
    orgUserList.add(
      model
        .getValidItems()
        .map((item) => {
          const orgUser = toOrgUser(item);
          return {item, orgUser};
        })
        .filter(({item, orgUser}) => orgUser.hasUnsavedChanges() || item.productRoles?.length > 0)
        .map(({orgUser}) => orgUser)
    );

    return orgUserList;
  }

  items;

  /**
   * Constructor
   * @param {Array<MemberAndSelectedItems>} items - Initial items to use, if provided
   * @param {Number} defaultMemberListSize - Initial number of new items to add
   * @param {Number} maxMemberListSize - Maximum number of new items to add
   */
  constructor({items, defaultMemberListSize, maxMemberListSize = 10}) {
    makeObservable(this, {
      generateNewItems: action,
      isLoading: computed,
      items: observable,
      removeItem: action,
      updateMaxItemCount: action,
    });

    this.items = items || [];
    this.maxMemberListSize = maxMemberListSize;
    this.generateNewItems(Math.min(defaultMemberListSize, this.maxMemberListSize));
  }

  /**
   * Changes the error-status for the item with the given ID.
   * @param {String} id - the unique row id
   * @param {OPERATION} op - the operation type
   * @param {String} status - the status value
   */
  changeErrorStatus({id, op, status}) {
    const item = this.findItemForId(id);

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
    // istanbul ignore else
    if (op === OPERATION.ADD) {
      item.addErrorStatus(status);
    } else if (op === OPERATION.REMOVE) {
      item.clearErrorStatus(status);
    }
  }

  /**
   * Changes the loading-status for the item with the given ID.
   * @param {String} id - the unique row id
   * @param {OPERATION} op - the operation type
   * @param {String} status - the status value
   */
  changeLoadingStatus({id, op, status}) {
    const item = this.findItemForId(id);
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
    // istanbul ignore else
    if (op === OPERATION.ADD) {
      item.addLoadingStatus(status);
    } else if (op === OPERATION.REMOVE) {
      item.clearLoadingStatus(status);
    }
  }

  /**
   * Changes the query-text for the item matching the given ID
   * @param {String} id - The unique row id
   * @param {String} queryText - The text to set in the item
   */
  changeQueryText({id, value: {value: queryText}}) {
    const item = this.findItemForId(id);
    item.setQueryText(queryText);
    this.validateDuplicateEntries();
  }

  /**
   * Finds the item in the list matching the given id.
   * @param {String} id - The unique row id
   * @returns {MemberAndSelectedItem} the item matching the given id
   */
  findItemForId(id) {
    return this.items.find((item) => item.id === id);
  }

  /**
   * A function to add new rows to the AddUserFormTable
   * @param {Number} count - A total number of new rows that will be added to the AddUserFormTable
   */
  generateNewItems(count) {
    for (let i = 0; i < count; i++) {
      this.items.push(new MemberAndSelectedItems());
    }
  }

  /**
   * A function that returns all usnaved and valid MemberAndSelectedItems
   * @returns {Array<MemberAndSelectedItems>} Returns all users that are valid and have unsaved changes
   */
  getValidItems() {
    return this.items.filter((item) => item && item.hasValidMember());
  }

  /**
   * Returns true if there is at least one MemberAndSelectedItems with a member that has zero selectedItems
   * @returns {Boolean}
   */
  hasMemberWithNoSelectedItems() {
    return this.items.some(
      (memberAndSelectedItems) =>
        memberAndSelectedItems.member && memberAndSelectedItems.selectedItems.length === 0
    );
  }

  /**
   * Invalid when the instance has errors or in a loading state.
   */
  isInvalid() {
    return this.items.some((item) => item.isInvalid());
  }

  /**
   * @returns true when the instance is in a loading state.
   */
  get isLoading() {
    return this.items.some((item) => item.isLoading());
  }

  /**
   * Removes the item in the list matching the given id.
   * @param {String} id - The unique row id
   */
  removeItem({id}) {
    this.items = this.items.filter((item) => item.id !== id);
    this.validateDuplicateEntries();
  }

  /**
   * @returns true if it is within limits to add a new item to this list
   */
  shouldGenerateNewItem() {
    const validItems = this.getValidItems();
    return validItems.length >= this.items.length - 1 && this.items.length < this.maxMemberListSize;
  }

  /**
   * Set the maximum number of rows in the add user form table
   * @param {Number} maxItemCount - the maximum number of rows that the add user form table has
   */
  updateMaxItemCount(maxItemCount) {
    this.maxMemberListSize = maxItemCount;
    this.items = this.items.slice(0, maxItemCount);
  }

  /**
   * Update the member for the given id
   * @param {String} id - The unique row id
   * @param {Member} value - The member selected from the UserPicker
   * @returns the MemberAndSelectedItems object for the given id.
   */
  updateMember({id, value}) {
    const item = this.findItemForId(id);
    item.updateMember(value);
    return item;
  }

  /**
   * Update the product role for a member
   * @param {String} id - The unique row id
   * @param {Array<ProductRole>} value - The product roles
   * @param {String} value.id - the role id
   * @param {Array<String>} value.licenseGroupId - The license group id
   * @param {Product} value.product - The product
   */
  updateProductRoles({id, value}) {
    const item = this.findItemForId(id);
    item.updateProductRoles(value);
  }

  /**
   * Update the selected assignment items for a member
   * @param {String} id - The unique row id
   * @param {Array} value - The selected items as defined in AssignmentSection
   */
  updateSelectedItems({id, value}) {
    const item = this.findItemForId(id);
    if (item) item.updateSelectedItems(value);
    return item;
  }

  /**
   * Scans the list and updates Error-Statuses based on duplicate entries.
   */
  validateDuplicateEntries() {
    const enteredQueryTexts = new Set();

    this.items.forEach((memberAndSelectedItems) => {
      const queryText = memberAndSelectedItems.queryText;
      if (queryText) {
        if (enteredQueryTexts.has(queryText)) {
          memberAndSelectedItems.addErrorStatus(ERROR_STATUS.DUPLICATE_INPUT);
        } else {
          memberAndSelectedItems.clearErrorStatus(ERROR_STATUS.DUPLICATE_INPUT);
          enteredQueryTexts.add(queryText);
        }
      }
    });
  }
}

export default MemberAndSelectedItemsList;
export {OPERATION};
