(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-assignment-item
   * @description A product assignment pulldown widget used for selecting products to be assigned to a user
   */
  angular.module('binky.widgets.products.product-assignment-pulldown', [
    'binky.core.lodash',
    'binky.shell.panels.pulldown',
    'binky.widgets.products.product-icon',
    'binky.widgets.products.product-name',
    'ngCoral',
  ]);
})();
