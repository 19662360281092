import axios from 'axios';

import authentication from 'services/authentication';

let clientId, url;

/**
 * Configure Kmi api
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for Kmi api
 */
const configure = (config) => {
  ({clientId, url} = config);
};

const formatOrgId = (orgId) => orgId.replace('@', '-');

const formatOrgIdForDirectoryEncryption = (orgId) => orgId.split('@').reverse().join('-');

/**
 * Get headers for Kmi API calls
 *
 * @returns {Object} The header map
 */
const getHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject?.token) {
    throw new Error('Unable to get token for KMI API call');
  }
  return {
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };
};

/**
 * @description Fetches current org encryption status
 *
 * @param {String} orgId - The associated org Id
 * @returns {Promise<Object>} Axios response
 */
const getOrgEncryptionStatus = ({orgId}) => {
  const formattedOrgId = formatOrgId(orgId);

  return axios.get(`${url}/organizations/${formattedOrgId}/encryption`, {
    headers: getHeaders(),
  });
};

/**
 * @description Fetches encrypted directories for an organization
 *
 * @param {String} orgId - The associated org Id
 * @returns {Promise<Object>} Axios response
 */
const getEncryptedDirectories = ({orgId}) =>
  axios.get(`${url}/organizations/${orgId}/encrypted-directories`, {
    headers: {Accept: 'application/json;strict=true', ...getHeaders()},
  });

/**
 * @description Fetches encrypted domains for an organization
 *
 * @param {String} orgId - The associated org Id
 * @param {String} domainId - The associated domain Id
 * @returns {Promise<Object>} Axios response
 */
const getEncryptedDomains = ({orgId, domainId}) =>
  axios.get(`${url}/enterprises/${orgId}/encrypted-domains/${domainId}`, {
    headers: {Accept: 'application/json;strict=true', ...getHeaders()},
  });

/**
 * @description Revokes encryption for an organization
 *
 * @param {String} orgId - The associated org Id
 * @returns {Promise<Object>} Axios response
 */
const revokeOrgEncryption = ({orgId}) => {
  const formattedOrgId = formatOrgId(orgId);
  return axios.put(
    `${url}/organizations/${formattedOrgId}/encryption`,
    {orgId},
    {
      headers: getHeaders(),
    }
  );
};

/**
 * @description Enables encryption for an organization
 *
 * @param {String} orgId - The associated org Id
 * @returns {Promise<Object>} Axios response
 */
const enableOrgEncryption = ({orgId}) => {
  const formattedOrgId = formatOrgId(orgId);
  return axios.post(
    `${url}/organizations/${formattedOrgId}/encryption`,
    {orgId},
    {
      headers: getHeaders(),
    }
  );
};

/**
 * @description Enables dedicated encryption key for a directory. remove with temp_org_asset_encryption_migrated
 * @param orgId - The associated org Id
 * @param directoryId - The directory for which to enable encryption (kms refers to this as "domainId")
 * @returns {Promise<Object>} Axios response
 */
const enableDirectoryEncryption = ({orgId, directoryId}) => {
  const formattedOrgId = formatOrgIdForDirectoryEncryption(orgId);
  return axios.post(
    `${url}/organizations/${formattedOrgId}/domains/${directoryId}/kms`,
    {id: directoryId},
    {
      headers: getHeaders(),
    }
  );
};

/**
 * @description Revokes dedicated encryption key for a directory. remove with temp_org_asset_encryption_migrated
 * @param orgId - The associated org Id
 * @param directoryId - The directory for which to disable encryption (kms refers to this as "domainId")
 * @returns {Promise<Object>} Axios response
 */
const revokeDirectoryEncryption = ({orgId, directoryId}) => {
  const formattedOrgId = formatOrgIdForDirectoryEncryption(orgId);
  return axios.put(
    `${url}/organizations/${formattedOrgId}/domains/${directoryId}/kms`,
    {id: directoryId},
    {
      headers: getHeaders(),
    }
  );
};

const kmi = {
  configure,
  enableDirectoryEncryption,
  enableOrgEncryption,
  getEncryptedDirectories,
  getEncryptedDomains,
  getOrgEncryptionStatus,
  revokeDirectoryEncryption,
  revokeOrgEncryption,
};

export default kmi;
