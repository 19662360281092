import {navBus} from '@admin-tribe/binky';
import {Item, TabList, TabPanels, Tabs, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

const cleanName = (name) => name.replace(/\W/gi, '-');

/**
 * A tabbed navigation component that displays tabs and tab panel content that is associated with the URL.
 * Other props are applied to the `<Tabs>` component.
 * If your tabs are behind access checks then make sure you await for all the async checks to be completed
 * before rendering the TabbedNav component. By default the first tab in the `tabs` array will be selected
 * only on initial render so if a new tab is injected into the array at the first position after first render
 * then the incorrect tab will be selected.
 */
const TabbedNav = ({onSelectionChange, tabs, windowContext: windowContextProp, ...other}) => {
  const windowContext = windowContextProp || window;
  const tabsWithKeys = useMemo(
    () =>
      tabs.map((tab, idx) => ({
        key: `tab-${cleanName(tab.name)}-${idx}`,
        ...tab,
      })),
    [tabs]
  );

  // Used so that the other hooks will only re-run when any of the keys change
  const memoizedKeys = useMemo(
    () => JSON.stringify(tabsWithKeys.map((tab) => tab.key)),
    [tabsWithKeys]
  );

  // redirect to the path of the first tab if there is no tab that matches the current URL
  useEffect(() => {
    if (
      !tabsWithKeys.find((tabWithKey) => windowContext.location.pathname === tabWithKey.pathname) &&
      tabsWithKeys[0]?.pathname
    ) {
      navBus.replaceState({url: tabsWithKeys[0].pathname});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only re-run when the keys change
  }, [memoizedKeys]);

  // Default the selected key to a tab with a pathname that matches the current URL, otherwise select the first tab
  const [selectedTabKey, setSelectedTabKey] = useState(
    tabsWithKeys.find((tabWithKey) => windowContext.location.pathname === tabWithKey.pathname)
      ?.key ?? tabsWithKeys[0]?.key
  );

  /** Listen for the browser's back/forward buttons, attempt to find an associated tab and select it */
  useEffect(
    () => {
      const listener = () => {
        const tab = tabsWithKeys.find((_tab) => windowContext.location.pathname === _tab.pathname);

        if (tab) {
          setSelectedTabKey(tab.key);
        }
      };

      windowContext.addEventListener('popstate', listener);

      return () => {
        windowContext.removeEventListener('popstate', listener);
      };
    },
    /**
     * This hook must only run on mount because otherwise the event handler
     * reference is not maintained and the event is not properly listened to.
     * This may cause a bug if the tabs or windowContext props are changed
     * after the initial render.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See above
    []
  );

  const onChange = useCallback(
    (itemKey) => {
      onSelectionChange?.(itemKey);
      setSelectedTabKey(itemKey);

      const tab = tabsWithKeys.find((_tab) => _tab.key === itemKey);
      if (tab) {
        navBus.pushState({url: tab.pathname});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only re-run when the keys change
    [memoizedKeys]
  );

  const visibleTabs = useMemo(() => tabsWithKeys.filter((tab) => !tab.hidden), [tabsWithKeys]);

  const disabledKeys = useMemo(
    () => tabsWithKeys.filter((tab) => tab.disabled).map((tab) => tab.key),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only re-run when the keys change
    [memoizedKeys]
  );

  return (
    <Tabs
      disabledKeys={disabledKeys}
      items={visibleTabs}
      onSelectionChange={onChange}
      selectedKey={selectedTabKey}
      {...other}
    >
      <TabList>
        {(item) => (
          <Item key={item.key} textValue={item.name}>
            {item.icon}
            <Text>{item.name}</Text>
          </Item>
        )}
      </TabList>
      <TabPanels>{(item) => <Item key={item.key}>{item.content}</Item>}</TabPanels>
    </Tabs>
  );
};

TabbedNav.propTypes = {
  /**
   * Callback function to notify on tab change.
   * This callback function is triggered with Tab key.
   */
  onSelectionChange: PropTypes.func,
  /** An array of tab data */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /** The content to display for the tab */
      content: PropTypes.node.isRequired,
      /** If true then the tab will be disabled and not selectable */
      disabled: PropTypes.bool,
      /** If true then the tab will not display */
      hidden: PropTypes.bool,
      /** An icon that will display left of the tab name */
      icon: PropTypes.node,
      /** The text that will appear on the tab */
      name: PropTypes.string.isRequired,
      /** The exact pathname for the tab, used for URL matching */
      pathname: PropTypes.string.isRequired,
    })
  ),
  /** A reference to a Window object for URL matching. Defaults to `window.top`. */
  // eslint-disable-next-line react/forbid-prop-types -- Unable to get window instance working
  windowContext: PropTypes.object,
};

export default TabbedNav;
