const InsightsConstants = {
  DROPDOWN_TYPE: {
    CONTRACTS: 'contracts',
  },
  STRING_TYPE: {
    DESCRIPTION: 'description',
    LABEL: 'label',
    LINK_LABEL: 'linkLabel',
    TITLE: 'title',
  },
};

export default InsightsConstants;
