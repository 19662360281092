import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

/**
 * The message that being displayed when DetailSection has no content.
 * Could be passed as 'messageKey' or a whole string.
 */
const DetailSectionMessage = ({messageKey, translatedString}) => {
  const intl = useIntl();
  return (
    <Text
      UNSAFE_style={{color: 'var(--spectrum-global-color-static-gray-700)', fontStyle: 'italic'}}
    >
      {translatedString ||
        intl.formatMessage({
          id: messageKey,
        })}
    </Text>
  );
};

DetailSectionMessage.propTypes = {
  /**
   * The key of the string saved in locales directory.
   */
  messageKey: requiredIf(PropTypes.node, (props) => !props.translatedString),
  /**
   * The translated string.
   */
  translatedString: requiredIf(PropTypes.node, (props) => !props.messageKey),
};

export default DetailSectionMessage;
