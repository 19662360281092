class ComplianceSymptom {
  /**
   *
   * @param {Object} symptom - Wrapper object for the name value pair.
   * @param {string} symptom.name - Name of the compliance symptom.
   * @param {string} symptom.value - Value for a given compliance symptom.
   */
  constructor(symptom) {
    this.name = symptom.name;
    this.value = symptom.value;
  }

  isDate() {
    return !Number.isNaN(Date.parse(this.value));
  }

  isDisabled() {
    return this.value === 'false';
  }

  isEnabled() {
    return this.value === 'true';
  }
}
export default ComplianceSymptom;
