/* eslint-disable max-lines -- this file requires more lines */
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import feature from 'services/feature';

import {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from './UserRolesConstants';

/**
 * @description Method to set the roles and filtering for the list.
 * @param {UserRoles} target - the UserRoles object to set state on
 * @param {Object[]} roles - list of roles search through
 * @param {String[]} filterToRoleNames - The roles to filter down to.
 */
function init(target, roles, filterToRoleNames) {
  // we store all of these to allow for easy updating

  target.roles = roles;
  target.unfilteredRoles = roles;
  target.filterToRoleNames = filterToRoleNames;
  target.actingAsAnAdobeAgentOrgs = [];

  filter(target);
}

/**
 * @description Method to filtering the UserRoles based on stored state.
 * @param {UserRoles} target - the UserRoles object to modify
 */
function filter(target) {
  if (target.filterToRoleNames) {
    const filterToRoleNames = new Set(target.filterToRoleNames);
    // we remove any role not explicitly accounted for in the list

    target.roles = target.unfilteredRoles.filter(
      (role) => role.named_role && filterToRoleNames.has(role.named_role.toUpperCase())
    );
  }
}

/**
 * @description Method to check for a certain role a user may have for a
 *   given organization. This method casts the name and named_role values to
 *   uppercase before comparing, to avoid issues between how different
 *   back-end servers store/return values (e.g. - org_admin vs ORG_ADMIN).
 * @param {Object[]} roles - list of roles search through
 * @param {String} orgId - ID of org to check against
 * @param {String} name - name of role to search for
 * @returns {Boolean} true if role exists for org, else false
 */
function findRoleForOrg(roles, orgId, name) {
  return roles.some(
    (role) => name.toUpperCase() === role.named_role.toUpperCase() && orgId === role.organization
  );
}

/**
 * @description Method to check for a certain role a user may have for a
 *   given role target. This method casts the name and named_role values to
 *   uppercase before comparing, to avoid issues between how different
 *   back-end servers store/return values (e.g. - org_admin vs ORG_ADMIN).
 * @param {Object[]} roles - list of roles search through
 * @param {String} name - name of role to search for
 * @param {String} target - the role target primary data to search for
 * @param {String} secondaryData - the role target secondary data to search for
 * @returns {Boolean} true if role exists for the target, else false
 */
function findRoleForTarget(roles, name, target, secondaryData) {
  return roles.some(
    (role) =>
      name.toUpperCase() === role.named_role.toUpperCase() &&
      role.target === determineTarget(target, secondaryData)
  );
}

/**
 * @description cache role locally. This exists because IMS can take a few minutes
 * to invalidate their cache, and so for determining if an admin or agent has taken
 * an action that alters their roles, it's necessary to cache locale updates. We
 * know the server will enforce the real rule, so there's no escalation danger.
 *
 * @param {UserRoles} userRoles - UserRoles to search through
 * @param {String} targetRole - The named_role being added to the list
 * @param {String} orgId - The orgId to add this role for
 * @param {String} [secondaryData] - optional secondaryData to add this role for
 * @returns {void}
 */
function insertRoleForTarget(userRoles, targetRole, orgId, secondaryData) {
  userRoles.unfilteredRoles.push({
    named_role: targetRole,
    organization: orgId,
    target: determineTarget(orgId, secondaryData),
  });
  // we re-perform filtering after modifying the roles
  filter(userRoles);
}

/**
 * @description clear a role from the local cache. This is necessary for the same
 * reasons as the insert
 *
 * @param {UserRoles} userRoles - UserRoles to search through
 * @param {String} targetRole - The named_role being added to the list
 * @param {String} orgId - The orgId to remove this role from
 * @returns {void}
 */
function removeRoleForTarget(userRoles, targetRole, orgId) {
  remove(userRoles.unfilteredRoles, {
    named_role: targetRole,
    organization: orgId,
  });
  // we re-perform filtering after modifying the roles
  filter(userRoles);
}

function determineTarget(target, secondaryData) {
  if (secondaryData) {
    return `${target}:${secondaryData}`;
  }
  return target;
}

/**
 * @description Method to retrieve all roles for a user in a target org
 *
 * @param {Array<UserRoles>} userRoles - UserRoles to search through
 * @param {String} orgId - The orgId of the target org
 * @returns {Array<UserRoles>} - List of roles assigned to the user in the target org
 */
function getRolesForTargetOrg(userRoles, orgId) {
  return userRoles.filter((roles) => roles.organization === orgId);
}

/**
 * @name UserRoles
 * @description Model for the UserRoles an authenticated user has.
 */
export default class UserRoles {
  static fromJIL(orgId, roles) {
    const imsRoles = roles.flatMap((role) =>
      role.targets.map((target) => ({
        named_role: role.type,
        organization: orgId,
        target: determineTarget(orgId, target.id),
      }))
    );

    return new UserRoles(imsRoles);
  }

  /**
   * @description initializes UserRoles object.
   * @param {Array} roles The unfiltered IMS roles to wrap in this list.
   */
  constructor(roles) {
    init(this, roles);
  }

  /**
   * @description Checks if the user has any role.
   * @returns {Boolean} true if the user has any role.
   */
  any() {
    return this.roles.length > 0;
  }

  /**
   * @description Checks if the user has any role for the provided org.
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has any role for the provided org.
   */
  anyForOrg(orgId) {
    return this.roles.some((role) => orgId === role.organization);
  }

  /**
   * @description Checks if the user has any role from the provided list.
   * @param {Array} roles - The roles to check for.
   * @returns {Boolean} true if the user has any of the specified roles.
   */
  anyOf(roles) {
    return this.roles.some((role) => roles.includes(role.named_role));
  }

  /**
   * @description Checks if the user has any role from the provided list on the Adobe Agent org.
   * @param {Array} roles - The roles to check for.
   * @returns {Boolean} true if the user has any of the specified roles.
   */
  anyOfForAdobeAgentOrg(roles) {
    return this.anyOfForOrg(roles, ADOBE_AGENT.ORG_ID);
  }

  /**
   * @description Checks if the user has any role from the provided list.
   * @param {Array} roles - The roles to check for.
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has any of the specified roles.
   */
  anyOfForOrg(roles, orgId) {
    return roles.some((role) =>
      role === CONTRACT_ADMIN
        ? feature.isEnabled('temp_contract_admin_role') && findRoleForOrg(this.roles, orgId, role)
        : findRoleForOrg(this.roles, orgId, role)
    );
  }

  /**
   * @description Reduces the stored roles to only those that are requested.
   * @param {Array} filterToRoleNames - The roles to filter down to.
   */
  filterTo(filterToRoleNames) {
    init(this, this.unfilteredRoles, filterToRoleNames);
  }

  /**
   * @description Check what the highest agent role the user has.
   * @returns {String} the highest agent role in the array, or null if no role.
   */
  getHighestAdobeAgentRole() {
    if (this.isAdobeAgentAdmin()) {
      return ADOBE_AGENT.ADMIN;
    }
    if (this.isAdobeAgentProvisioner()) {
      return ADOBE_AGENT.PROVISIONER;
    }
    if (this.isAdobeAgentResellerLicensing()) {
      return ADOBE_AGENT.RESELLER_LICENSING;
    }
    if (this.isAdobeAgentProfessionalServices()) {
      return ADOBE_AGENT.PROFESSIONAL_SERVICES;
    }
    if (this.isAdobeAgentCustomerCare()) {
      return ADOBE_AGENT.CUSTOMER_CARE;
    }
    if (this.isAdobeAgentRead()) {
      return ADOBE_AGENT.READ;
    }
    return null;
  }

  /**
   * @description Check what the highest role the user has in the provided org.
   * @param {String} orgId - The orgId to query for.
   * @returns {String} the highest role in the array for the org, or null if no role.
   */
  /* eslint-disable complexity -- checks required */
  getHighestRoleForOrg(orgId) {
    if (this.isCompartmentAdminForOrg(orgId)) {
      return COMPARTMENT_ADMIN;
    }
    if (this.isOrgAdminForOrg(orgId)) {
      return ORG_ADMIN;
    }
    if (this.isContractAdminForOrg(orgId)) {
      return CONTRACT_ADMIN;
    }
    if (this.isProductAdminForOrg(orgId)) {
      return PRODUCT_ADMIN;
    }
    if (this.isDeploymentAdminForOrg(orgId)) {
      return DEPLOYMENT_ADMIN;
    }
    if (this.isSupportAdminForOrg(orgId)) {
      return SUPPORT_ADMIN;
    }
    if (this.isProductSupportAdminForOrg(orgId)) {
      return PRODUCT_SUPPORT_ADMIN;
    }
    if (this.isCompartmentViewerForOrg(orgId)) {
      return COMPARTMENT_VIEWER;
    }
    if (this.isLicenseGroupAdminForOrg(orgId)) {
      return LICENSE_ADMIN;
    }
    if (this.isUserGroupAdminForOrg(orgId)) {
      return USER_GROUP_ADMIN;
    }
    return null;
  }
  /* eslint-enable complexity -- checks required */

  /**
   * @description Check if the user is acting as an Adobe agent for the specified org
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has opened this org as an Adobe agent
   */
  isActingAsAdobeAgentForOrg(orgId) {
    return this.actingAsAnAdobeAgentOrgs.includes(orgId);
  }

  /**
   * @description Checks if the user is an admin for a specific contract.
   * @param {string} orgId - The organization ID.
   * @param {string} contractId - The contract ID.
   * @returns {boolean} - Returns true if the user is an admin for the contract, otherwise false.
   */
  isAdminForContract(orgId, contractId) {
    return findRoleForTarget(this.roles, CONTRACT_ADMIN, orgId, contractId);
  }

  /**
   * @description Check if the user is an Adobe agent admin
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentAdmin() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.ADMIN);
  }

  /**
   * @description Check if the user is a customer care Adobe agent
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentCustomerCare() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.CUSTOMER_CARE);
  }

  /**
   * @description Check if the user is a professional services Adobe agent
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentProfessionalServices() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.PROFESSIONAL_SERVICES);
  }

  /**
   * @description Check if the user is an Adobe agent provisioner
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentProvisioner() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.PROVISIONER);
  }

  /**
   * @description Check if the user is an Adobe agent and has the permission to read
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentRead() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.READ);
  }

  /**
   * @description Check if the user is a reseller licensing Adobe agent
   * @returns {Boolean} true if the user has the role
   */
  isAdobeAgentResellerLicensing() {
    return findRoleForOrg(this.roles, ADOBE_AGENT.ORG_ID, ADOBE_AGENT.RESELLER_LICENSING);
  }

  /**
   * @description Check if the user is any type of Adobe agent
   * @returns {Boolean} true if the user has any Adobe Agent role
   */
  isAnyAdobeAgent() {
    return (
      this.isAdobeAgentAdmin() ||
      this.isAdobeAgentCustomerCare() ||
      this.isAdobeAgentProfessionalServices() ||
      this.isAdobeAgentProvisioner() ||
      this.isAdobeAgentRead() ||
      this.isAdobeAgentResellerLicensing()
    );
  }

  /**
   * @description Check if the user is a compartment admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the compartment admin role
   */
  isCompartmentAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, COMPARTMENT_ADMIN);
  }

  /**
   * @description Check if the user has the compartment admin or viewer role on any org
   * @returns {Boolean} true if the user has one of the roles
   */
  isCompartmentAdminOrViewerForAnyOrg() {
    return this.anyOf([COMPARTMENT_ADMIN, COMPARTMENT_VIEWER]);
  }

  /**
   * @description Check if the user is a compartment admin or viewer for an org
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isCompartmentAdminOrViewerForOrg(orgId) {
    return this.isCompartmentAdminForOrg(orgId) || this.isCompartmentViewerForOrg(orgId);
  }

  /**
   * @description Check if the user is a compartment viewer
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the compartment viewer role
   */
  isCompartmentViewerForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, COMPARTMENT_VIEWER);
  }

  /**
   * @description Check if the user is a contract admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isContractAdminForOrg(orgId) {
    return (
      feature.isEnabled('temp_contract_admin_role') &&
      findRoleForOrg(this.roles, orgId, CONTRACT_ADMIN)
    );
  }

  /**
   * @description Check if the user is a deployment admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isDeploymentAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, DEPLOYMENT_ADMIN);
  }

  /**
   * @description Check if the user is a license group admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isLicenseGroupAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, LICENSE_ADMIN);
  }

  /**
   * @description Check if the user is a license group admin for a specific group
   * @param {String} target - Either the complete role target, or the orgId
   *   if the groupId is also provided.
   * @param {String} [groupId] The groupId. Optional
   * @returns {Boolean} true if the user has the role for the target
   */
  isLicenseGroupAdminForTarget(target, groupId) {
    return findRoleForTarget(this.roles, LICENSE_ADMIN, target, groupId);
  }

  /**
   * @description Check if the user is a license group developer
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isLicenseGroupDeveloperForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, LICENSE_DEV_ADMIN);
  }

  /**
   * @description Check if the user is a license group developer for a specific group
   * @param {String} target - Either the complete role target, or the orgId
   *   if the groupId is also provided.
   * @param {String} [groupId] The groupId. Optional
   * @returns {Boolean} true if the user has the role for the target
   */
  isLicenseGroupDeveloperForTarget(target, groupId) {
    return findRoleForTarget(this.roles, LICENSE_DEV_ADMIN, target, groupId);
  }

  /**
   * @description checks to see if the user is assigned to only the target role within the active org
   *
   * @param {String} orgId - The active org id
   * @param {String} targetRole - The target role to search
   * @returns {Boolean} - true if user has only one role in active org that matches the target role
   */
  isOnlyRoleForOrg(orgId, targetRole) {
    const rolesForOrg = getRolesForTargetOrg(this.roles, orgId);
    if (rolesForOrg.length === 1 && rolesForOrg[0].named_role === targetRole) {
      return true;
    }
    return false;
  }

  /**
   * @description Check if the user is an organization admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isOrgAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, ORG_ADMIN);
  }

  /**
   * @description Check if the user is a product admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isProductAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, PRODUCT_ADMIN);
  }

  /**
   * @description Check if the user is a product admin for a specific product context
   * @param {String} target - Either the complete role target, or the orgId
   *   if the productContextId is also provided.
   * @param {String} [productContextId] The productContextId. Optional
   * @returns {Boolean} true if the user has the role for the target
   */
  isProductAdminForTarget(target, productContextId) {
    return findRoleForTarget(this.roles, PRODUCT_ADMIN, target, productContextId);
  }

  /**
   * @description Check if the user is a product support admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isProductSupportAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, PRODUCT_SUPPORT_ADMIN);
  }

  /**
   * @description Check if the user is a product support admin for a specific product context
   * @param {String} target - Either the complete role target, or the orgId
   *   if the productContextId is also provided.
   * @param {String} [productContextId] The productContextId. Optional
   * @returns {Boolean} true if the user has the role for the target
   */
  isProductSupportAdminForTarget(target, productContextId) {
    return findRoleForTarget(this.roles, PRODUCT_SUPPORT_ADMIN, target, productContextId);
  }

  /**
   * @description Check if the user is a storage admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isStorageAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, STORAGE_ADMIN);
  }

  /**
   * @description Check if the user is a support admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isSupportAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, SUPPORT_ADMIN);
  }

  /**
   * @description Check if the user is a user group admin
   * @param {String} orgId - The orgId to query for.
   * @returns {Boolean} true if the user has the role
   */
  isUserGroupAdminForOrg(orgId) {
    return findRoleForOrg(this.roles, orgId, USER_GROUP_ADMIN);
  }

  /**
   * @description Check if the user is a user group admin for a specific group
   * @param {String} target - Either the complete role target, or the orgId
   *   if the groupId is also provided.
   * @param {String} [groupId] The groupId. Optional
   * @returns {Boolean} true if the user has the role for the target
   */
  isUserGroupAdminForTarget(target, groupId) {
    return findRoleForTarget(this.roles, USER_GROUP_ADMIN, target, groupId);
  }

  /**
   * @description cache a compartment admin role locally. This is for when an agent
   * makes themselves a compartment admin.
   *
   * @param {String} orgId - The orgId to add this role for
   * @returns {void}
   */
  setCompartmentAdminForTarget(orgId) {
    insertRoleForTarget(this, COMPARTMENT_ADMIN, orgId);
  }

  /**
   * @description Push an org into the list to act as an Adobe Agent for.
   * @param {String} orgId - The orgId to store.
   */
  setIsActingAsAdobeAgentForOrg(orgId) {
    this.actingAsAnAdobeAgentOrgs.push(orgId);
  }

  /**
   * @description cache an org admin role locally. This is for when an agent
   * makes themselves an org admin.
   *
   * @param {String} orgId - The orgId to add this role for
   * @returns {void}
   */
  setOrgAdminForTarget(orgId) {
    insertRoleForTarget(this, ORG_ADMIN, orgId);
  }

  /**
   * @description cache a user group admin role locally. This is for product/plc
   * admins, who need to be given this role when they make a user group.
   *
   * @param {String} orgId - The orgId to add this role for
   * @param {String} groupId - the groupId to add this role for
   * @returns {void}
   */
  setUserGroupAdminForTarget(orgId, groupId) {
    insertRoleForTarget(this, USER_GROUP_ADMIN, orgId, groupId);
  }

  /**
   * @description fetch the unfiltered role list.
   *
   * @returns {UserRoles} a list of unfiltered roles
   */
  unfiltered() {
    return new UserRoles(cloneDeep(this.unfilteredRoles));
  }

  /**
   * @description remove a locally cached compartment admin role.
   *
   * @param {String} orgId - The orgId to remove this role from
   * @returns {void}
   */
  unsetCompartmentAdminForTarget(orgId) {
    removeRoleForTarget(this, COMPARTMENT_ADMIN, orgId);
  }

  /**
   * @description remove a locally cached org admin role.
   *
   * @param {String} orgId - The orgId to remove this role from
   * @returns {void}
   */
  unsetOrgAdminForTarget(orgId) {
    removeRoleForTarget(this, ORG_ADMIN, orgId);
  }
}
/* eslint-enable max-lines -- this file requires more lines */
