/* eslint-disable max-statements -- this file requires more statements*/
/* eslint-disable max-lines -- this file requires more lines */
import binky, {
  EMAIL_MAX_LENGTH,
  MEMBER_TYPE,
  OrganizationUser,
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  UserGroup,
} from '@admin-tribe/binky';
import Autocomplete from '@react/react-spectrum/Autocomplete';
import Provider from '@react/react-spectrum/Provider';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';
import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import ValidatedTextField from 'common/components/validated-text-field/ValidatedTextField';
import './UserPicker.pcss';
import {getDisallowedTypeMessage} from 'common/utils/global-admin/globalAdminUtils';

import {useUserPickerContext} from './UserPickerContext';
import UserPickerRow from './UserPickerRow';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-user-picker
 */

const AvailableTypeList = binky.services.availableType.AvailableTypeList;
const SearchMemberList = binky.services.search.SearchMemberList;

const PICKER_TYPE = {
  USERS_AND_GROUPS: 'USERS_AND_GROUPS',
  USERS_ONLY: 'USERS_ONLY',
};

const SEARCH_TYPE = {
  EXISTING_USER: 'EXISTING_USER', // in practice, "existing or new user"
  NEW_USER: 'NEW_USER',
};

const STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  NO_RESULTS: 'NO_RESULTS',
  SHOW_RESULTS: 'SHOW_RESULTS',
};

const VALIDATION_STATUS = {
  ALREADY_ADDED: 'ALREADY_ADDED',
  CLEAR: 'CLEAR',
  INVALID_EMAIL: 'INVALID_EMAIL',
};

const DEFAULT_SEARCH_RESULT_LIMIT = 5;
const DEBOUNCE_TIME = 1000; // milliseconds
const SEARCH_QUERY_MIN_LENGTH = 3; // characters

// Defines the UserPicker react component, which allows the user to type a partial name,
// and let the component show a list of possible matches.
const UserPicker = ({
  className,
  defaultValue = '',
  disabledTypes = [],
  disabledList = [],
  excludeTechAccounts = false,
  message,
  id,
  label,
  onInputChange = () => {},
  onSelect = () => {},
  onStatusChange,
  onValidationStatusChange,
  orgId,
  pickerType = PICKER_TYPE.USERS_AND_GROUPS,
  placeholder,
  searchResultLimit = DEFAULT_SEARCH_RESULT_LIMIT,
  searchType,
  showTypes = true,
  targetOptions = {},
}) => {
  const intl = useIntl();

  const {canAddUser = true} = useUserPickerContext();

  const [inputText, setInputText] = useState(defaultValue);
  const [queryText, setQueryText] = useState();
  const [completions, setCompletions] = useState([]);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [validationStatus, setValidationStatus] = useState(VALIDATION_STATUS.CLEAR);
  const [cachedAvailableTypesList, setCachedAvailableTypesList] = useState();
  const [statusReason, setStatusReason] = useState();
  const autocompleteRef = useRef(null);
  const idMemo = useMemo(() => id || `user-picker-${uniqueId()}-id`, [id]);

  const updateInputField = (text) => {
    setInputText(text);
    onInputChange(text);
  };

  // Defines what text should appear in the input after selecting the item.
  // Assumes member is not null.
  const memberQueryText = (member) => {
    switch (member.type) {
      case MEMBER_TYPE.TYPE1:
      case MEMBER_TYPE.TYPE2:
      case MEMBER_TYPE.TYPE2E:
      case MEMBER_TYPE.TYPE3:
        return member.email;
      case MEMBER_TYPE.USER_GROUP:
        return member.name;
      default:
        // Something odd was selected, just keep the input text the same
        return inputText;
    }
  };

  const onSelectMember = (item, availableTypesList = cachedAvailableTypesList) => {
    const {member, rowType} = item;

    // Normally, onSelect would automatically set the textfield to be the selected string,
    // or the selected "item.label" if an object (similar to how Spectrum's Select options are defined).
    // We don't define item.label currently, so we need to set the textfield ourselves.
    if (member) {
      if (member.inTarget) {
        // Ignore selection and keep menu open if member is "inTarget" (and should be disabled).
        // Workaround for V2-Autocomplete as we can't disable clicks at the Dropdown-row level.
        return;
      }
      updateInputField(memberQueryText(member));
      setValidationStatus(VALIDATION_STATUS.CLEAR);
    }

    setStatus(STATUS.IDLE);
    if (
      rowType !== UserPickerRow.EXTRA_ROW_TYPES.LOADING &&
      rowType !== UserPickerRow.EXTRA_ROW_TYPES.NO_RESULTS
    ) {
      onSelect(member, availableTypesList, setInputText);
    }
  };

  // Retrieve search results once inputText has stabilized and has been passed to queryText
  useEffect(() => {
    let mounted = true;

    const generateResultsFromSearchData = (query, availableTypeItems, searchListItems) => {
      // Only include availableTypes already in the org if searchListItems isn't included,
      // or we will show duplicates.

      const isForNewUser = searchListItems === null;

      const availableTypesWithDirectoryUsers = availableTypeItems.filter(
        (availableType) =>
          availableType.existingUser &&
          ((!availableType.userIsInOrg &&
            availableType.allowed !== false &&
            availableType.available) ||
            (availableType.userIsInOrg && isForNewUser))
      );

      const combinedListItems = [
        ...availableTypesWithDirectoryUsers
          // Despite AvailableType listing .existingUser as an OrganizationUser object,
          // the existingUsers in the response are just objects for some reason.
          // We'll convert these here for now, until we address the issue in Binky.
          .map(
            (availableType) =>
              new OrganizationUser({
                ...availableType.existingUser,
                // If they are from other orgs, we must clean them to be "new" for this org.
                id: availableType.userIsInOrg ? availableType.existingUser.id : undefined,
                inTarget: availableType.userIsInOrg,
                orgId,
              })
          ),
        ...(searchListItems === null ? [] : searchListItems),
      ];

      const isDisabledFromRows = (member) => {
        // If member has a type that is in the disabledTypes we disable that user row
        if (disabledTypes.includes(member.type)) return true;

        // Otherwise we compare the member with all the users in the disabledList
        return disabledList.some((item) => {
          if (member.type === MEMBER_TYPE.USER_GROUP && item.name === member.name) return true;
          if (member.type !== MEMBER_TYPE.USER_GROUP && item.email === member.email) return true;
          return false;
        });
      };

      const rowResults = combinedListItems
        .filter((member) => (excludeTechAccounts ? !member.getType().isTechnicalAccount() : true))
        .map((member) => ({
          isRowDisabled: isDisabledFromRows(member),
          member,
          rowType: member.type,
        }));

      const showNewUserRow = availableTypeItems.some(
        (availableType) =>
          !availableType.existingUser && availableType.allowed !== false && availableType.available
      );
      if (showNewUserRow) {
        const newUser = new OrganizationUser({email: query, orgId});
        rowResults.push({
          isRowDisabled: isDisabledFromRows(newUser),
          member: newUser,
          rowType: UserPickerRow.EXTRA_ROW_TYPES.ADD_USER,
        });
      }

      return rowResults;
    };

    const generateStatusReason = (availableTypeList) =>
      availableTypeList.hasOneOrMoreDisabledTypes
        ? getDisallowedTypeMessage({availableTypeList, intl})
        : undefined;

    const setNewUserCompletions = async (query) => {
      if (!canAddUser) {
        setCompletions([]);
        setStatus(STATUS.NO_RESULTS);
        return;
      }

      const availableTypeList = await AvailableTypeList.get({
        email: query,
        orgId,
      });
      if (mounted) {
        setCachedAvailableTypesList(availableTypeList);
        if (availableTypeList.hasInvalidEmail) {
          setValidationStatus(VALIDATION_STATUS.INVALID_EMAIL);
          setCompletions([]);
          setStatus(STATUS.IDLE);
          return;
        }

        const availableTypeItems = toJS(availableTypeList.items);
        const hasTypesInOrg = availableTypeItems.some((availableType) => availableType.userIsInOrg);
        const hasOtherValidTypes = availableTypeItems.some(
          (availableType) =>
            availableType.allowed !== false && availableType.available && !availableType.userIsInOrg
        );
        if (hasTypesInOrg && !hasOtherValidTypes) {
          setValidationStatus(VALIDATION_STATUS.ALREADY_ADDED);
          setCompletions([]);
          setStatus(STATUS.IDLE);
          return;
        }

        const results = generateResultsFromSearchData(query, availableTypeItems, null);

        // Force selection if only one valid option is available
        if (results.length === 1 && hasOtherValidTypes) {
          setCompletions([]);
          setStatus(STATUS.IDLE);
          onSelectMember(results[0], availableTypeList);
          return;
        }
        // else
        setCompletions(results);
        setStatus(results.length === 0 ? STATUS.NO_RESULTS : STATUS.SHOW_RESULTS);
        setStatusReason(generateStatusReason(availableTypeList));
      }
    };

    // eslint-disable-next-line complexity -- will go back down to 10 after feature flag removal
    const setExistingUserCompletions = async (query) => {
      // Calling APIs in this manner to run these awaits concurrently.
      const availableTypeListPromise = AvailableTypeList.get({
        email: query,
        orgId,
      });
      const searchListPromise = SearchMemberList.get({
        filterQuery: query,
        includeUserGroups: pickerType !== PICKER_TYPE.USERS_ONLY,
        orgId,
        pageSize: searchResultLimit,
        ...SearchMemberList.generateTargetOptions(targetOptions),
      });
      const availableTypeList = await availableTypeListPromise;
      const searchList = await searchListPromise;

      if (mounted) {
        setCachedAvailableTypesList(availableTypeList);
        const availableTypeItems = canAddUser ? toJS(availableTypeList.items) : [];
        const searchListItems = toJS(searchList.items);
        if (availableTypeList.hasInvalidEmail) {
          setValidationStatus(VALIDATION_STATUS.INVALID_EMAIL);
        } else if (searchListItems.some((member) => member.email === query && member.inTarget)) {
          setValidationStatus(VALIDATION_STATUS.ALREADY_ADDED);
        }

        const results = generateResultsFromSearchData(query, availableTypeItems, searchListItems);

        // A11Y-fix: If there are no existing users for the current query, remove the overlay dropdown
        // to let the screen reader announce the "Enter a valid email address" error message
        if (
          availableTypeList.hasInvalidEmail &&
          results.length === 0 &&
          binky.services.feature.isEnabled('bug_fix_a11y_input_error_announcement')
        ) {
          setValidationStatus(VALIDATION_STATUS.INVALID_EMAIL);
          setCompletions([]);
          setStatus(STATUS.IDLE);
          return;
        }

        // only force selection if there are no existing-user matches
        if (results.length === 1 && searchListItems.length === 0) {
          setCompletions([]);
          setStatus(STATUS.IDLE);
          onSelectMember(results[0], availableTypeList);
          return;
        }

        // else
        setCompletions(results);
        setStatus(results.length === 0 ? STATUS.NO_RESULTS : STATUS.SHOW_RESULTS);
        setStatusReason(generateStatusReason(availableTypeList));
      }
    };

    const requestSearchCompletions = async (query) => {
      try {
        if (searchType === SEARCH_TYPE.NEW_USER) {
          await setNewUserCompletions(query);
        } else {
          // else if SEARCH_TYPE.EXISTING_USER
          await setExistingUserCompletions(query);
        }
      } catch (error) {
        // PENDING: No design yet on how to show an error with lookup.
        // The angular component shows "no results" for errors, will default to that for now.
        // eslint-disable-next-line no-console -- No design yet, writing console log for now
        console.log(error);
        if (mounted) {
          setStatus(STATUS.NO_RESULTS);
        }
      }
    };

    if (queryText) {
      requestSearchCompletions(queryText);
    }

    return function cleanup() {
      mounted = false;
    };
    // "onSelectMember" needs to be omitted as it relies on an external prop,
    // and would be a different instance every time this component is re-rendered.
    // "targetOptions" needs to be omitted as it is an external prop object,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore onSelectMember, targetOptions
  }, [canAddUser, intl, orgId, pickerType, queryText, searchResultLimit, searchType]);

  // Re-render the dropdown for any change in the search results or status.
  useEffect(() => {
    autocompleteRef.current.getCompletions();
  }, [completions, status, statusReason]);

  // notify the parent component when the validation status changes
  useEffect(() => {
    onValidationStatusChange?.(validationStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- remove onValidationStatusChange from the dependency list.
  }, [validationStatus]);

  // notify the parent component when the component's status changes.
  useEffect(() => {
    onStatusChange?.(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- remove onStatusChange from the dependency list.
  }, [status]);

  const generateCompletions = () => {
    if (status === STATUS.LOADING) {
      return [{rowType: UserPickerRow.EXTRA_ROW_TYPES.LOADING}];
    }
    if (status === STATUS.NO_RESULTS) {
      return [{rowType: UserPickerRow.EXTRA_ROW_TYPES.NO_RESULTS, rowTypeReason: statusReason}];
    }
    return completions;
  };

  const delayedQuery = useMemo(
    () =>
      debounce((query) => {
        setQueryText(query.trim());
      }, DEBOUNCE_TIME),
    []
  );

  const onTextfieldChange = (text) => {
    if (text === undefined) {
      // "undefined" can happen when a dropdown-item without the "label" property defined is selected.
      // Currently, that's all dropdown-items, which are all Members.
    } else {
      updateInputField(text);
      setQueryText(undefined);
      if (text.length < SEARCH_QUERY_MIN_LENGTH) {
        setStatus(STATUS.IDLE);
        delayedQuery.cancel();
      } else {
        setStatus(STATUS.LOADING);
        setValidationStatus(VALIDATION_STATUS.CLEAR);
        delayedQuery(text);
      }
    }
  };

  const onBlur = () => {
    if (STATUS.SHOW_RESULTS === status) {
      const firstExactMatch = completions.find((item) => item?.member?.email === inputText);
      if (firstExactMatch) {
        setCompletions([]);
        onSelectMember(firstExactMatch, cachedAvailableTypesList);
      }
    }

    setStatus(STATUS.IDLE);
    delayedQuery.cancel();
  };

  let validationMessage, validationVariant;

  ({validationMessage, validationVariant} = React.useMemo(() => {
    const retVal = {};
    if (message) {
      retVal.validationMessage = message.value;
      retVal.validationVariant = message.variant;
    }

    return retVal;
  }, [message]));

  // No need to show validation errors until they stopped typing and have tabbed away
  if (status === STATUS.IDLE) {
    if (validationStatus === VALIDATION_STATUS.INVALID_EMAIL) {
      validationMessage = intl.formatMessage({
        id: 'binky.common.userPicker.error.addUser.invalidEmail',
      });
      validationVariant = 'error';
    } else if (validationStatus === VALIDATION_STATUS.ALREADY_ADDED) {
      validationMessage = intl.formatMessage({
        id: 'binky.common.userPicker.error.addUser.alreadyAdded',
      });
      validationVariant = 'error';
    }
  }

  return (
    <Provider data-testid="user-picker" styleName="autocomplete-provider">
      <Autocomplete
        ref={autocompleteRef}
        className={className}
        getCompletions={generateCompletions}
        id={idMemo}
        onBlur={onBlur}
        onChange={onTextfieldChange}
        onSelect={(item) => onSelectMember(item)}
        renderItem={(item) => <UserPickerRow item={item} showTypes={showTypes} />}
        // We need to manage the Autocomplete menu visibility ourselves, as it seems
        // there's an issue when Autocomplete handles objects, rather than string values.
        showMenu={status !== STATUS.IDLE}
        value={inputText}
      >
        <ValidatedTextField
          label={label}
          maxLength={EMAIL_MAX_LENGTH}
          // Autocomplete already handles onChange internally, we don't need this one.
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
          onChange={/* istanbul ignore next */ () => {}}
          placeholder={placeholder}
          validationMessage={validationMessage}
          variant={validationVariant}
          wrapperProps={{
            maxWidth: '100%',
            width: '100%',
          }}
        />
      </Autocomplete>
    </Provider>
  );
};

UserPicker.PICKER_TYPE = PICKER_TYPE;
UserPicker.SEARCH_TYPE = SEARCH_TYPE;
UserPicker.VALIDATION_STATUS = VALIDATION_STATUS;
UserPicker.STATUS = STATUS;

UserPicker.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  // This disabledList contains a list of users and/or user groups to be disabled from picking
  // The disabled list would still appear in search however the rows will be disabled
  disabledList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(OrganizationUser), PropTypes.shape(UserGroup)])
  ),
  // The disabledTypes is for when all rows of a certain user type (TYPE1-TYPE3, USER_GROUP)
  // needs to be disabled in the User Picker
  disabledTypes: PropTypes.arrayOf(PropTypes.string),
  /** Omits technical accounts from search results when true. Defaults to false. */
  excludeTechAccounts: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.shape({value: PropTypes.string, variant: PropTypes.string}),
  // Function to run once the input textfield is modified. Defaults to no-op.
  onInputChange: PropTypes.func,
  // Function to run once an item is selected. Defaults to no-op.
  // Passes the selected Member. Also passes the AvailableTypeList, which should be removed once caching works.
  onSelect: PropTypes.func,
  onStatusChange: PropTypes.func,
  onValidationStatusChange: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  // Defines what entities should be shown. Defaults to USERS_AND_GROUPS.
  pickerType: PropTypes.oneOf(Object.values(PICKER_TYPE)),
  placeholder: PropTypes.string,
  searchResultLimit: PropTypes.number,
  searchType: PropTypes.oneOf(Object.values(SEARCH_TYPE)).isRequired,
  // Whether to show the user-type columns in the search results.
  // For example, SMB orgs generally have the same user type for all users and don't need this.
  // Also shows the synced-users info popover for now, as it also is not needed for SMB orgs.
  showTypes: PropTypes.bool,
  // Search filtering target settings. Defines the product/group to search within.
  // See SearchMemberList.generateTargetOptions for details.
  targetOptions: PropTypes.shape({
    searchTargetType: PropTypes.oneOf(Object.values(SEARCH_TARGET_RESULT_TYPE)),
    targetClass: PropTypes.oneOf(Object.values(SEARCH_TARGET_CLASS)),
    targetId: PropTypes.string,
    targetParentId: PropTypes.string,
  }),
};

export default UserPicker;
/* eslint-enable max-lines -- this file requires more lines */
/* eslint-enable max-statements -- this file requires more statements*/
