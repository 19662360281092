(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name insightsAccess
   * @description insights access factory
   */
  angular.module('binky.core.insights.access').factory('insightsAccess', InsightsAccess);
  /* @ngInject */
  function InsightsAccess($q, _, INSIGHTS_SECTION, InsightsItemAccess) {
    const service = {
      canViewDashboard,
      canViewInsights,
      canViewLogs,
      canViewReports,
      getDefaultSection,
    };

    return service;

    //////////////

    /**
     * @description Method to determine if the org has access to view Dashboard
     *
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
     * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
     * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
     *
     * @returns {Promise} Resolves with true if the org has access to view Dashboard
     */
    function canViewDashboard(options) {
      return InsightsItemAccess.hasInsightsItemAccess(INSIGHTS_SECTION.DASHBOARD, options);
    }

    /**
     * @description Method to determine if the org has access to Insights related content
     *
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
     * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
     * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
     *
     * @returns {Promise} Resolves with true if the org has access to Insights related content
     */
    function canViewInsights(options) {
      return $q
        .all([
          this.canViewDashboard(options),
          this.canViewLogs(options),
          this.canViewReports(options),
        ])
        .then((results) => _.some(results))
        .catch(_.stubFalse);
    }

    /**
     * @description Method to determine if the org has access to view Logs
     *
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
     * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
     * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
     *
     * @returns {Promise} Resolves with true if the org has access to view Logs
     */
    function canViewLogs(options) {
      return InsightsItemAccess.hasInsightsItemAccess(INSIGHTS_SECTION.LOGS, options);
    }

    /**
     * @description Method to determine if the org has access to view reports
     *
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
     * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
     * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
     *
     * @returns {Promise} Resolves with true if the org has access to view reports
     */
    function canViewReports(options) {
      return InsightsItemAccess.hasInsightsItemAccess(INSIGHTS_SECTION.REPORTS, options);
    }

    /**
     * @description Method to get the default insights section
     *
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
     * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
     * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
     *
     * @returns {Promise} Resolves with default insights section
     */
    function getDefaultSection(options) {
      return $q
        .all([
          this.canViewDashboard(options),
          this.canViewReports(options),
          this.canViewLogs(options),
        ])
        .then((results) => {
          if (results[0]) {
            return INSIGHTS_SECTION.DASHBOARD;
          } else if (results[1]) {
            return INSIGHTS_SECTION.REPORTS;
          } else if (results[2]) {
            return INSIGHTS_SECTION.LOGS;
          }
          return null;
        });
    }
  }
})();
