import React from 'react';

/**
 * Create a state and a ref with the same value, as well as a method
 * to update both. This can be used in other hooks where you need to
 * read AND update the current value of some state, but in doing so
 * will create an infinite loop.
 * @template T
 * @param {T} initialValue
 * @returns {[T, React.MutableRefObject<T>, (newData: T) => void]}
 */
const useStateAndRef = (initialValue) => {
  const dataRef = React.useRef(initialValue);
  const [data, setData] = React.useState(initialValue);

  const updateData = React.useCallback((newData) => {
    dataRef.current = newData;
    setData(newData);
  }, []);

  return [data, dataRef, updateData];
};

export default useStateAndRef;
