(function () {
  /**
   * @deprecated Please use src2/core/services/organization/Organization.js
   *
   * @ngdoc factory
   * @name binky.core.organizations:Organization
   * @description model that represents an Organization
   */
  angular.module('binky.core.organizations').factory('Organization', getOrganization);

  /* @ngInject */
  function getOrganization($q, _, binkySrc2) {
    class Organization2 extends binkySrc2.services.organization.Organization {
      constructor(options) {
        super(options);
        this.$resolved = false;
      }

      create() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .create()
            .then(() => resolve(this))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      delete() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .delete()
            .then(() => resolve(this))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => resolve(this))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      update(options) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .update(options)
            .then(() => resolve(this))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Transforms the organization response Object into a new Organization
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @returns {Organization2} Reference to pre-existing Organization2
       */
      static apiResponseTransformer(dataTransferObject) {
        return new Organization2(dataTransferObject);
      }

      /**
       * @description Method to retrieve an existing Organization from back-end data
       *   store.
       *
       * @param {String} id ID of the Organization to retrieve
       * @param {Object} options Get params Object (params described below)
       * @returns {Organization} Reference to pre-existing Organization
       */
      static get(id, options) {
        const model = new Organization2(_.assign({id}, options));
        model.refresh();
        return model;
      }
    }

    return Organization2;
  }
})();
