import CacheItem from 'services/cache/CacheItem';
import CacheTimer from 'services/cache/CacheTimer';

/**
 * This class helps with creating a Cache store. This cache store can contain
 * multiple items (each being in it's own key) and can be used either globally
 * or can be instantiated in different places.
 *
 * The store is a `Map` so the keys can be more than just strings.
 *
 * Every value is stored in a `CacheItem` that handles the lifetime
 * of that value. This enables the cache to store items with multiple
 * lifetimes if needed.
 */
class Cache {
  store = new Map();

  /**
   * Deletes the provided key.
   *
   * @param {any} key The key to be removed from the Cache store.
   */
  clear(key) {
    this.store.delete(key);
  }

  /**
   * Clears all the stored cache items. This also clears all the keys so
   * `has` will report false for any provided key until, of course, a new one
   * is added using `put`.
   */
  clearAll() {
    this.store.clear();
  }

  /**
   * Gets the value of the provided key if it's not stale.
   *
   * @param {any} key The identifier of a cache item in store
   *
   * @return {null|any} The value stored for the provided key or null if stale.
   */
  get(key) {
    if (!this.has(key) || this.store.get(key).isStale) {
      return null;
    }

    return this.store.get(key).getValue();
  }

  /**
   * Gets the underlying cache item. This can be helpful when you need the stored
   * data even if it's stale or you need more control over the underlying
   * `CacheItem`.
   *
   * @param {any} key The identifier of a cache item in store
   *
   * @return {null|any} CacheItem stored for the provided key or null if not present.
   */
  getCacheItem(key) {
    if (!this.has(key)) {
      return null;
    }

    return this.store.get(key);
  }

  /**
   * Checks whether the provided key is present in the cache or not.
   *
   * @param {any} key The identifier of a cache item in store
   *
   * @return {Boolean} True if the key has a value assigned, false otherwise.
   */
  has(key) {
    return this.store.has(key);
  }

  /**
   * Returns an iterator for looping through cache keys. Used by Omni Tool.
   *
   * @return {MapIterator} Iterator for all keys in store
   */
  keys() {
    return this.store.keys();
  }

  /**
   * Adds or updates a cache item with the provided key. If the cache already exists
   * the lifetime cannot be modified. If no lifetime is provided when the cache item
   * is first created the `CacheTimer.CACHE_LIFETIME` value is used.
   *
   * @param {any}    key                The identifier of a cache item in store
   * @param {any}    value              Value to be stored in this cache item.
   * @param {Object} options            Options for the cache item.
   * @param {Array}  [options.lifetime] Item TTL in milliseconds
   * @param {Class}  [options.merge]    If item already exists, merge passed fields with existing ones
   */
  put(key, value, options = {lifetime: CacheTimer.CACHE_LIFETIME, merge: true}) {
    if (this.has(key)) {
      this.store.get(key).putValue(value, {merge: options.merge});
    } else {
      this.store.set(key, new CacheItem(value, options.lifetime));
    }
  }
}

export default Cache;
