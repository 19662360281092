import {ContactList} from '@admin-tribe/binky';
import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import EditSecurityContactsModal from './edit-security-contacts-modal/EditSecurityContactsModal';

/**
 * The button to open the modal to edit security contacts.
 */
const EditSecurityContactsButton = observer(({contactList, isDisabled = false, onSuccess}) => {
  const intl = useIntl();
  const getAction = () => (contactList.isAllContactsEmpty() ? 'add' : 'edit');
  const [action, setAction] = useState(getAction());
  const onSaveSuccess = (currContacts) => {
    setAction(getAction());
    onSuccess?.(currContacts);
  };

  return (
    <DialogTrigger>
      <Button data-testid="edit-button" isDisabled={isDisabled} variant="accent">
        {intl.formatMessage({
          id: `binky.common.editSecurityContactsModal.editSecurityContactsButton.${action}`,
        })}
      </Button>
      {(close) => (
        <EditSecurityContactsModal
          contactList={contactList}
          onClose={close}
          onSuccess={onSaveSuccess}
        />
      )}
    </DialogTrigger>
  );
});

EditSecurityContactsButton.propTypes = {
  /**
   * The ContactList whose contacts are being updated.
   */
  contactList: PropTypes.instanceOf(ContactList).isRequired,
  /**
   * Whether the button should be disabled or not. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * Callback to invoke when the modal's actions are successful.
   * Includes the current contact list as a parameter.
   */
  onSuccess: PropTypes.func,
};

export default EditSecurityContactsButton;
