/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to convert from a DOMAIN_STATUS status to a DOMAIN_STATUS_LABEL_KEY translate key.
 * @param {Object} domain A domain with a status.
 * @returns {String} The DOMAIN_STATUS_LABEL_KEY key that corresponds to the domain's status. This can be run
 *   thru binkyDomainStatusLabel to get the display string which corresponds to DOMAIN_STATUS_LABEL_KEY key.
 * @example To derive the status label for a given domain:
 *   $ctrl.domain | binkyDomainStatusToStatusLabelKey | binkyDomainStatusLabel
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.domains.status-filter')
    .filter('binkyDomainStatusToStatusLabelKey', filter);

  /* @ngInject */
  function filter(DOMAIN_STATUS_LABEL_KEY) {
    return getDomainStatusLabelKey;

    function getDomainStatusLabelKey(domain) {
      if (domain.isActive()) {
        return DOMAIN_STATUS_LABEL_KEY.ACTIVE;
      } else if (domain.needsApproval()) {
        return DOMAIN_STATUS_LABEL_KEY.NEEDS_APPROVAL;
      } else if (domain.needsDirectory()) {
        return DOMAIN_STATUS_LABEL_KEY.NEEDS_DIRECTORY;
      } else if (domain.needsValidation()) {
        return DOMAIN_STATUS_LABEL_KEY.NEEDS_VALIDATION;
      } else if (domain.isWithdrawn()) {
        return DOMAIN_STATUS_LABEL_KEY.WITHDRAWN;
      }
      return '';
    }
  }
})();
