import {Flex, ProgressCircle, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * This is an internal component used by the Drawer and ModalDialog to grey out
 * the contents of the container while it is waiting to load or process actions.
 */
const PanelWait = () => {
  const intl = useIntl();

  return (
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      left="size-0"
      position="absolute"
      width="100%"
    >
      <View
        backgroundColor="gray-100"
        borderRadius="small"
        height="100%"
        UNSAFE_style={{opacity: '0.75'}}
        width="100%"
      >
        <Flex alignItems="center" height="100%" justifyContent="center" width="100%">
          <ProgressCircle
            aria-label={intl.formatMessage({id: 'binky.shell.panels.modal.loading'})}
            data-testid="panel-wait"
            isIndeterminate
            size="M"
          />
        </Flex>
      </View>
    </Flex>
  );
};

PanelWait.propTypes = {};

export default PanelWait;
