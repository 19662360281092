(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilDirectories.js
   *
   * @ngdoc service/provider
   * @name jilDirectories
   * @description defines service to manage jil directory resources
   */
  angular.module('binky.core.api.jil').provider('jilDirectories', jilDirectoriesProvider);

  let url;

  /* @ngInject */
  function jilDirectoriesProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        directories: getDirectoriesResource(),
        directoryDomains: getDirectoryDomainsResource(),
        directoryTrusts: getDirectoryTrustsResource(),
        directoryUsers: getDirectoryUsersResource(),
        directoryUsersAvatar: getDirectoryUsersAvatarResource(),
        samlConfig: getSamlConfigResource(),
        searchDirectoryUsers: getSearchDirectoryUsersResource(),
        tenantAdmins: getTenantAdminsResource(),
      };

      return service;

      ///////////

      function getDirectoriesResource() {
        return $resource(
          `${url}/:orgId/directories/:directoryId`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              isArray: true,
              method: 'PATCH',
            },
            export: {
              headers: {
                Accept: 'text/csv+directory',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            update: {
              method: 'PUT',
            },
          }
        );
      }

      function getDirectoryDomainsResource() {
        return $resource(
          `${url}/:orgId/directories/:directoryId/domains`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            export: {
              headers: {
                Accept: 'text/csv+domain',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }

      function getDirectoryTrustsResource() {
        return $resource(`${url}/:orgId/directories/:directoryId/trusts`, {
          directoryId: '@directoryId',
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getDirectoryUsersResource() {
        return $resource(
          `${url}/:orgId/directories/:directoryId/users/:userId`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
            userId: '@userId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            export: {
              headers: {
                Accept: 'text/csv+directory-user-all,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }

      function getDirectoryUsersAvatarResource() {
        return $resource(
          `${url}/:orgId/directories/:directoryId/users/:userId/avatar`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
            userId: '@userId',
          },
          {
            upload: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
            },
          }
        );
      }

      function getSamlConfigResource() {
        return $resource(
          `${url}/:orgId/directories/:directoryId/tenants/:tenantId:action`,
          {
            action: '@action',
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
            tenantId: '@tenantId',
          },
          {
            getTenantMetadata: {
              url: `${url}/:orgId/directories/:directoryId/tenants/:tenantId/metadata`,
            },
            save: {
              headers: {
                Accept: 'application/json',
                'Content-Type': undefined,
              },
              method: 'POST',
            },
          }
        );
      }

      function getSearchDirectoryUsersResource() {
        return $resource(`${url}/:orgId/directories\\:search.users`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getTenantAdminsResource() {
        return $resource(`${url}/:orgId/directories/:directoryId/tenants/:tenantId/admins/:id`, {
          directoryId: '@directoryId',
          id: '@id',
          orgId: apiUtils.getActiveOrg,
          tenantId: '@tenantId',
        });
      }
    }
  }
})();
