(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.member.member-license-end-date:binkyMemberLicenseEndDate
   * @description Component to display the license end data for a member
   *
   * @param {Object} member A member
   */
  angular
    .module('binky.widgets.common.member.member-license-end-date')
    .component('binkyMemberLicenseEndDate', {
      bindings: {
        member: '<',
      },
      controller,
      templateUrl:
        'widgets/common/member/member-license-end-date/member-license-end-date.component.html',
    });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      vm.hasOverdueLicenses = _.result(vm, 'member.hasOverdueLicenses', false);
      vm.licenseEndDate = _.get(vm, 'member.licenseEndDate');
    }

    ///////////////
  }
})();
