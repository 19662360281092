import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL password policy APIs
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for JIL password policy api
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Fetches password policy for an org
 *
 * @param {Object} params - Params wrapper object.
 * @param {string} params.orgId - The org id
 *
 * @returns {Object} Object containing fetched password policy.
 */
const getPasswordPolicy = async ({orgId}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/password-policy`, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response.data;
};

/**
 * Updates a password policy for an org.
 * @param {Object} params - Params wrapper object.
 * @param {string} params.orgId - org id whose password policy will be updated.
 * @param {string} params.passwordPolicy - password policy to replace the current.
 *
 * @returns {Object} Object containing updated password policy
 */
const putPasswordPolicy = async ({orgId, passwordPolicy}) => {
  const response = await axios.put(
    `${url}/v2/organizations/${orgId}/password-policy`,
    {passwordPolicy},
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response.data;
};

const jilPasswordPolicy = {configure, getPasswordPolicy, putPasswordPolicy};

export default jilPasswordPolicy;
