(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.commerce
   * @description defines filters which produce commerce strings.
   */
  angular.module('binky.widgets.commerce', [
    'binky.core.commerce',
    'binky.core.feature',
    'binky.core.offers',
    'binky.core.price',
    'pascalprecht.translate',
  ]);
})();
