(function () {
  'use strict';
  /**
   * @deprecated use src2/shell/panels/drawer/header/Header.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.drawer-header.drawer-header-description:binkyDrawerHeaderIcon
   *
   * @description A header for a binky-drawer which includes an optional icon and the drawer title,
   *  an optional list of details, and an optional description paragraph lined up below the title.
   *
   *  The header-icon transclusion slot is sized for a coral-icon size="S" which is
   *    height: rem(40px);
   *    width: rem(40px);
   *  If another type of icon is used it should be sized accordingly.
   *
   * @param {Object} [avatarMember] The member to associate with the binky-avatar.
   *   Required if the default binky-avatar icon is to be used.
   * @param {Array} [detailItems] An array of 0 or more class/label/value objects that are listed below the
   *   headerTitle in the order given. Optional.
   * @param {String} [headerDescription] The description for the drawer.
   *   Will be displayed after any detail-items. The string may contain html tags.
   * @param {String} [headerTitle] The title for the drawer. Optional.
   *
   * @example without transclusion which uses binky-avatar for the icon
   *   <drawer-header-icon
   *      member=$ctrl.member"
   *      detail-items="$ctrl.detailItems"
   *      title="My Title">
   *   </drawer-header-icon>
   *
   * @example with transclusion with a specified icon and specified action buttons
   *   <drawer-header-icon detail-items="$ctrl.detailItems" title="My Title">
   *     <header-icon>
   *       <app-product-profile-icon></app-product-profile-icon>
   *     </header-icon>
   *     <header-action-buttons>
   *       <button></button>
   *       <button></button>
   *     </header-action-buttons>
   *   </drawer-header-icon>
   *
   *   where $ctrl.detailItems = [{class: 'description', label: 'Username', value: vm.productConfiguration.description}];
   */
  angular
    .module('binky.widgets.common.drawer-header.drawer-header-icon')
    .component('binkyDrawerHeaderIcon', {
      bindings: {
        detailItems: '<?',
        headerDescription: '@?',
        headerTitle: '@',
        member: '<?', // required if using the default binky-avatarMember
      },
      templateUrl:
        'widgets/common/drawer-header/drawer-header-icon/drawer-header-icon.component.html',
      transclude: {
        'header-action-buttons': '?headerActionButtons', // optional
        'header-icon': '?headerIcon', // optional, size="S" icon
      },
    });
})();
