import {dispatchUiEventAnalytics, log} from '@admin-tribe/binky';

/**
 *  Adapt analytics tracking data from the form expected by the AnalyticsInterface defined in @pandora/react-analytics-provider
 * (https://git.corp.adobe.com/PandoraUI/core/blob/420190fd92e4274cdf431d9f146e3a9ecc595499/packages/react-analytics-provider/src/types.ts#L17)
 *  to binky AnalyticsEvent through dispatchUiEventAnalytics.
 *
 */
const track = (_tag, data) => {
  if (!data) return;

  const primaryEvent =
    data.digitalData?.primaryEvent?.eventInfo ||
    data.digitalData?.primaryEvent ||
    data.digitalData?.eventInfo ||
    {};

  if (!primaryEvent.eventAction || !primaryEvent.eventName) {
    log.info('AnalyticsAdapter is unable to find and parse a primaryEvent on analytics data');
    log.info(data.digitalData);
    return;
  }

  const analyticsObject = {
    eventAction: primaryEvent.eventAction,
    eventName: primaryEvent.eventName,
  };

  const productArray = data.digitalData.product;

  if (productArray) {
    if (Array.isArray(productArray)) {
      analyticsObject.productList = {
        items: productArray.map((product) => ({
          productName: product.productInfo?.productName,
        })),
      };
    } else {
      log.info('data.digitalData.product is not an array');
      log.info(data.digitalData.product);
    }
  }

  dispatchUiEventAnalytics(analyticsObject);
};

const AnalyticsAdapter = {
  track,
};

export default AnalyticsAdapter;
