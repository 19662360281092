const CLOUD = {
  CREATIVE: 'CREATIVE',
  DOCUMENT: 'DOCUMENT',
  EXPERIENCE: 'EXPERIENCE',
  OTHERS: 'OTHERS',
};
const COMMITMENT = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};
const CUSTOMER_SEGMENT = {
  ENTERPRISE: 'ENTERPRISE',
  TEAM: 'TEAM',
};
const DELEGATION_TYPE = {
  MACHINE: 'MACHINE',
  PERSON: 'PERSON',
};
const PAYMENT_STRUCTURE = {
  PAID_IN_ADVANCE: 'PAID_IN_ADVANCE',
  PAID_IN_ARREARS: 'PAID_IN_ARREARS',
};
const PRICE_POINT = {
  FREE: 'FREE',
  REGULAR: 'REGULAR',
  TRIAL: 'TRIAL',
};
const PROCESSING_INSTRUCTION = {
  ADDON: 'addon',
  ADDON_LICENSE_ID_REQUIRED: 'addon.license_id_required',
  ENTERPRISE_PRODUCT_FULFILLMENT_ROUTING: 'enterprise_product_fulfillment_routing',
  PA_RELATIONSHIP: 'pa_relationship',
  PA_RELATIONSHIP_CARDINALITY: {
    INSTANCES: 'INSTANCES',
    SINGLETON: 'SINGLETON',
  },
  PA_RELATIONSHIP_SUBTYPE: {
    ADDON: 'ADDON',
    BASE: 'BASE',
  },
};
const QUALIFIER_TARGET = {
  CC_ORG_WITH_STORAGE: 'CC_ORG_WITH_STORAGE',
  // the *_EDU_ORG qualifiers map to MARKET_SUBSEGMENT on the organization
  FURTHER_EDU_ORG: 'FURTHER_EDU_ORG',
  K12_EDU_ORG: 'K12_EDU_ORG',
  PREVENT_PA_CREATION: 'PREVENT_PA_CREATION',
  STANDARD: 'STANDARD',
};
const SALES_CHANNEL = {
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT',
};
const TYPE = {
  BASE: 'BASE',
  PROMOTION: 'PROMOTION',
  TRIAL: 'TRIAL',
};
const VIRTUAL_LICENSE_SELECTION_ARL_PREFIX = '/this_fulfillment/';

export {
  CLOUD,
  COMMITMENT,
  CUSTOMER_SEGMENT,
  DELEGATION_TYPE,
  PAYMENT_STRUCTURE,
  PRICE_POINT,
  PROCESSING_INSTRUCTION,
  QUALIFIER_TARGET,
  SALES_CHANNEL,
  TYPE,
  VIRTUAL_LICENSE_SELECTION_ARL_PREFIX,
};
