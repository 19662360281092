import {Locale} from '@admin-tribe/binky';
import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
import {AnalyticsProvider} from '@pandora/react-analytics-provider';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {OverlayProvider} from '@react-aria/overlays';
import PropTypes from 'prop-types';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';

import AnalyticsAdapter from './AnalyticsAdapter';
import ApiProvider from './ApiProvider';
import PandoraProvider from './PandoraProvider';
import styles from './ReactContainerBridge.pcss';
import {getStrings} from './locales';

/**
 * Context telling component whether or not it is wrapped in an Angular wrapper.
 */
const AngularWrapperContext = createContext(false);

/**
 * The bridge between our Angular and our React code.
 */
const ReactContainerBridge = ({children, isNested = false}) => {
  const language = Locale.get().activeLanguage;
  const messages = getStrings(language);

  const locale = Locale.get().activeLocaleForSpectrum;

  // The SpectrumV2Provider internally configures locale for use by the v2 components.
  // The SpectrumV3Provider internally configures an I18nProvider with the locale for use by the v3 components.
  return (
    <AngularWrapperContext.Provider value>
      <ApiProvider>
        <SpectrumV2Provider
          className={(isNested && styles['is-nested']) || ''}
          locale={locale}
          scale="medium"
          theme="light"
          toastPlacement="bottom left"
        >
          <SpectrumV3Provider
            colorScheme="light"
            locale={locale}
            theme={defaultTheme}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- set background color
            UNSAFE_className={(isNested && styles['is-nested']) || ''}
          >
            <IntlProvider defaultLocale="en" locale={language} messages={messages}>
              <PandoraProvider>
                <AnalyticsProvider value={AnalyticsAdapter}>
                  <OverlayProvider>{children}</OverlayProvider>
                </AnalyticsProvider>
              </PandoraProvider>
            </IntlProvider>
          </SpectrumV3Provider>
        </SpectrumV2Provider>
      </ApiProvider>
    </AngularWrapperContext.Provider>
  );
};
ReactContainerBridge.propTypes = {
  /**
   * The child components to render inside the container
   */
  children: PropTypes.node.isRequired,
  /**
   * true if the React component is not a top level page container and must
   *     inherit background color from current context in DOM hierarchy, else
   *     false/undefined for theme based background color
   */
  isNested: PropTypes.bool,
};

/**
 * Destroys any React component that was previously rendered into the given element.
 *
 * @param {Element} element - the HTML element where the React component was rendered
 */
const destroyReactComponent = (element) => {
  // eslint-disable-next-line react/no-deprecated -- @jwu to fix
  ReactDOM.unmountComponentAtNode(element);
};

/**
 * Renders a React component with the specified type and props into the given element.
 *
 * @param {Function} Component - the React component to render
 * @param {Object.<string, Object>} props - props to pass through to the React component
 * @param {Element} element - the HTML element to render the React component into
 * @param {boolean} [isNested] - true if the React component is not a top
 *     level page container and must inherit background color from current
 *     context in DOM hierarchy, else false/undefined for theme based
 *     background color
 */
const renderReactComponent = (Component, props, element, isNested) => {
  const reactElement = (
    <ReactContainerBridge isNested={isNested}>
      <Component {...props} />
    </ReactContainerBridge>
  );
  // eslint-disable-next-line react/no-deprecated -- @jwu to fix
  ReactDOM.render(reactElement, element);
};

export default ReactContainerBridge;

export {AngularWrapperContext, destroyReactComponent, renderReactComponent};
