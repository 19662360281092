/* eslint-disable @admin-tribe/admin-tribe/include-in-index -- specific to binky */
import {AssignmentModalSectionContentModel} from '@pandora/react-assignment-section';
import {CopyToClipboardContentModel} from '@pandora/react-copy-to-clipboard';
import {DirectoryDomainEnforcementStatusContentModel} from '@pandora/react-directory-domain-enforcement-status';
import {DirectoryMoveContentModel} from '@pandora/react-directory-move-modal';
import {DrawerContentModel} from '@pandora/react-drawer';
import {GoUrlContentModel} from '@pandora/react-go-url';
import {IllustratedMessageContentModel} from '@pandora/react-illustrated-message';
import {ModalAlertDialogContentModel, ModalDialogModel} from '@pandora/react-modal-dialog';
import {PageNavContentModel, PageSizeSelectorContentModel} from '@pandora/react-pagination';
import {PriceContentModel} from '@pandora/react-price';
import {ProductIconListContentModel} from '@pandora/react-product-icon-list';
import {SearchContentModel} from '@pandora/react-search';
import {
  NoSearchResultsIllustratedMessageContentModel,
  ServerErrorIllustratedMessageContentModel,
  TableFiltersContentModel,
  TableFooterContentModel,
} from '@pandora/react-table-section';
import {UserPickerV3ContentModel} from '@pandora/react-user-picker';

import WithoutParenthesisPriceContentModel from 'common/components/price/WithoutParenthesisPriceContentModel';

const pandoraContentModel = {
  assignmentModalSectionContent: {
    id: 'assignmentModalSectionContent',
    type: AssignmentModalSectionContentModel,
  },
  copyToClipboardContent: {
    id: 'copyToClipboardContent',
    type: CopyToClipboardContentModel,
  },
  directoryDomainEnforcementStatusContent: {
    id: 'directoryDomainEnforcementStatusContent',
    type: DirectoryDomainEnforcementStatusContentModel,
  },
  directoryMoveModalContent: {
    id: 'directoryMoveModalContent',
    type: DirectoryMoveContentModel,
  },
  drawerContent: {
    id: 'drawerContent',
    type: DrawerContentModel,
  },
  goUrlContent: {
    id: 'goUrlContent',
    type: GoUrlContentModel,
  },
  illustratedMessageContent: {
    id: 'illustratedMessageContent',
    type: IllustratedMessageContentModel,
  },
  modalAlertDialog: {
    id: 'modalAlertDialog',
    type: ModalAlertDialogContentModel,
  },
  modalDialog: {
    id: 'modalDialog',
    type: ModalDialogModel,
  },
  noSearchResultsIllustratedMessageContent: {
    id: 'noSearchResultsIllustratedMessageContent',
    type: NoSearchResultsIllustratedMessageContentModel,
  },
  pageNavContent: {
    id: 'pageNavContent',
    type: PageNavContentModel,
  },
  pageSizeSelectorContent: {
    id: 'pageSizeSelectorContent',
    type: PageSizeSelectorContentModel,
  },
  priceContent: {
    id: 'priceContent',
    type: PriceContentModel,
  },
  productIconListContentModel: {
    id: 'productIconListContentModel',
    type: ProductIconListContentModel,
  },
  searchContent: {
    id: 'searchContent',
    type: SearchContentModel,
  },
  serverErrorIllustratedMessageContent: {
    id: 'serverErrorIllustratedMessageContent',
    type: ServerErrorIllustratedMessageContentModel,
  },
  tableFiltersContent: {
    id: 'tableFiltersContent',
    type: TableFiltersContentModel,
  },
  tableFooterContent: {
    id: 'tableFooterContent',
    type: TableFooterContentModel,
  },
  userPickerV3Content: {
    id: 'userPickerV3Content',
    type: UserPickerV3ContentModel,
  },
  // Ideally, the Pandora price component should take in
  // content as a prop to allow for customized strings
  // to be passed in. We currently have to wrap a ContentEntrySelector
  // around Price to set custom strings.
  withoutParenthesisPriceContent: {
    id: 'withoutParenthesisPriceContent',
    type: WithoutParenthesisPriceContentModel,
  },
};

export default pandoraContentModel;
/* eslint-enable @admin-tribe/admin-tribe/include-in-index -- specific to binky */
