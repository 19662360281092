(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.member.member-license-end-date
   * @description module that defines the member-license-end-date widget
   */
  angular.module('binky.widgets.common.member.member-license-end-date', [
    'binky.core.lodash',
    'binky.widgets.common.date-time',
  ]);
})();
