(function () {
  /**
   * @deprecated Please use src2/core/api/clypeUsers.js
   *
   * @ngdoc service/provider
   * @name clypeUsers
   * @description defines service to manage clype user resources
   */
  angular.module('binky.core.api.clype').provider('clypeUsers', provider);

  let url;

  /* @ngInject */
  function provider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/users`;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        linkedAccounts: getLinkedAccountsResource(),
      };

      return service;

      ///////////
      function getLinkedAccountsResource() {
        return $resource(`${url}/:userId/linked-accounts`, {
          userId: '@userId',
        });
      }
    }
  }
})();
