import {ActionButton, Flex, Tooltip, TooltipTrigger, View} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getProductDisplayName} from 'features/product/productDisplayUtils';

import ImageIcon from '../../image-icon/ImageIcon';

/**
 * @deprecated. Use import {ProductIconList} from '@pandora/react-product-icon-list' instead.
 * A widget handling creation of a container if there are multiple products
 *     in the group, along with a counter representing the number.
 */
const ProductIconGroup = ({productGroup}) => {
  const intl = useIntl();

  if (productGroup[0] === undefined) {
    return (
      <TooltipTrigger>
        <ActionButton aria-label="Bad product" isQuiet>
          <AlertIcon aria-label="Bad product" size="S" />
        </ActionButton>
        <Tooltip variant="negative">
          {intl.formatMessage({id: 'binky.common.productIconGroup.invalidProduct'})}
        </Tooltip>
      </TooltipTrigger>
    );
  }

  const productText = productGroup[0].iconTitle || getProductDisplayName(intl, productGroup[0]);

  if (productGroup.length > 1) {
    return (
      <TooltipTrigger>
        <ActionButton aria-label={productText} isQuiet marginX="size-25">
          <View borderColor="gray-400" borderRadius="regular" borderWidth="thin" padding="size-10">
            <Flex gap="size-50">
              <ImageIcon alt={productText} size="S" src={productGroup[0].assets.icons.svg} />
              <View data-testid="product-count" elementType="span">
                ({productGroup.length})
              </View>
            </Flex>
          </View>
        </ActionButton>
        <Tooltip>
          {productGroup.map((product) => (
            <View key={product.id}>
              {product.iconTitle || getProductDisplayName(intl, product)}
            </View>
          ))}
        </Tooltip>
      </TooltipTrigger>
    );
  }

  return (
    <TooltipTrigger>
      <ActionButton aria-label={productText} isQuiet>
        <ImageIcon alt={productText} size="S" src={productGroup[0].assets.icons.svg} />
      </ActionButton>
      <Tooltip>{productText}</Tooltip>
    </TooltipTrigger>
  );
};

ProductIconGroup.propTypes = {
  /**
   * Products belonging to the same group. Products which do not have to be
   * grouped are present as the only element in the array
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  productGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductIconGroup;
