import NoSearchResultsIllustration from '@spectrum-icons/illustrations/NoSearchResults';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import IllustratedMessage from 'common/components/illustrated-message/IllustratedMessage';

/**
 * @deprecated use Pandora adminstration NoSearchResultsIllustratedMessage:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/empty-table-message/no-search-results
 *
 * Component to render a message with no search results found illustration, a header
 * and content.
 */
const NoSearchResultsIllustratedMessage = ({content, heading, headingLevel}) => {
  const intl = useIntl();
  const contentMsg =
    content || intl.formatMessage({id: 'binky.common.illustratedMessage.noSearchResults.content'});
  const headingMsg =
    heading || intl.formatMessage({id: 'binky.common.illustratedMessage.noSearchResults.heading'});

  return (
    <IllustratedMessage
      content={contentMsg}
      heading={headingMsg}
      headingLevel={headingLevel}
      illustration={<NoSearchResultsIllustration />}
    />
  );
};

NoSearchResultsIllustratedMessage.propTypes = {
  /**
   * Optional content. The default is "No results found".
   */
  content: PropTypes.string,
  /**
   * The heading. The default is "Try another search".
   */
  heading: PropTypes.string,
  /**
   * The header level of the message. The default is 3.
   */
  headingLevel: PropTypes.number,
};

export default NoSearchResultsIllustratedMessage;
