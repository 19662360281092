// The supported ways to narrow a Search call to a specific target sub-group
// within an organization.
const SEARCH_TARGET_CLASS = {
  PRODUCT: 'Product',
  PRODUCT_CONFIGURATION: 'ProductConfiguration',
  USER_GROUP: 'UserGroup',
};

// The supported ways to filter results from the search.
const SEARCH_TARGET_RESULT_TYPE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export {SEARCH_TARGET_CLASS, SEARCH_TARGET_RESULT_TYPE};
