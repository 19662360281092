import inRange from 'lodash/inRange';

const EMAIL_REGEX = /.+@.+\..+/;
const EMAIL_MAX_LEN = 254;

/**
 * @description Returns true if the specified string looks like an
 * email address, because it is of the form x@y.z.
 * This method does NOT ensure the email address is valid,
 * just does enough to catch the vast majority of cases.
 *
 * @param {String} email - the email address to validate
 * @returns {Boolean} true if the string seems to be an email address; else false
 */
function looksLikeAnEmail(email) {
  return EMAIL_REGEX.test(email) && inRange(email.length, 1, EMAIL_MAX_LEN + 1);
}

export {looksLikeAnEmail};
