(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.panels.pulldown:binkyPulldown
   *
   * @description An animated pulldown panel
   *
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {String} pulldownId The ID to assign to the pulldown-content div.
   * @param {String} [transition] 'Slide' (for header bar usage), 'fade' or 'scale'. Default: scale
   * @param {Transclude} pulldownBody The core transclusion for the pulldown content. Required.
   * @param {Transclude} [pulldownHeader] A transclusion for the pulldown header. Optional.
   *
   * @example <binky-pulldown is-shown="{{ myFlag }}"><pulldown-body></pulldown-body></binky-pulldown>
   * @example <binky-pulldown is-shown="{{ myFlag }}" transition="slide"><pulldown-body></pulldown-body></binky-pulldown>
   *
   */
  angular.module('binky.shell.panels.pulldown').component('binkyPulldown', {
    bindings: {
      onClose: '<?',
      onOpen: '<?',
      pulldownId: '@',
      transition: '@',
    },
    controller,
    templateUrl: 'shell/panels/pulldown/pulldown.component.html',
    transclude: {
      'pulldown-body': 'pulldownBody',
      'pulldown-header': '?pulldownHeader',
    },
  });

  /* @ngInject */
  function controller(
    $document,
    $element,
    $scope,
    $timeout,
    $transclude,
    _,
    PANEL_MANAGER,
    panelManager
  ) {
    const vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
      _.assign(vm, {
        getTransitionClass,
      });

      vm.isShown = false;

      const events = {
        click(event) {
          if (vm.isShown) {
            const targetIsPulldownOrChild =
              $element === event.target || $element[0].contains(event.target);
            if (!targetIsPulldownOrChild) {
              closePulldown();
            }
          }
        },
        'keydown keypress keyup'(event) {
          if (event.key === 'Escape' || event.key === 'Esc') {
            closePulldown();
            event.preventDefault();
          }
        },
      };

      $timeout(() => {
        panelManager.register(PANEL_MANAGER.TYPE.PULLDOWN, vm.pulldownId, onOpen, onClose);
      });

      function closePulldown() {
        $scope.$apply(() => {
          if (!panelManager.close(vm.pulldownId)) {
            // If the panel was unregistered or already closed, we still want to trigger the callback logic
            onClose();
          }
        });
      }

      function onOpen() {
        _.invoke(vm, 'onOpen');
        vm.isShown = true;

        $timeout(() => {
          _.forOwn(events, (value, key) => {
            $document.on(key, value);
          });
        });
      }

      function onClose() {
        _.invoke(vm, 'onClose');
        vm.isShown = false;

        $timeout(() => {
          _.forOwn(events, (value, key) => {
            $document.off(key, value);
          });
        });
      }

      function getTransitionClass() {
        if (vm.transition === 'fade') {
          return 'transform-fade';
        } else if (vm.transition === 'slide') {
          return 'transform-slide';
        }
        return 'transform-scale';
      }
    }

    function $onDestroy() {
      panelManager.unregister(vm.pulldownId);
    }
  }
})();
