(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains:binkyDomainListTable
   * @param {Object} [display] An object defining which columns of the table are enabled. See below.
   * @param {Boolean} [display.checkbox] The checkbox column.
   * @param {Boolean} [display.directory] The directory column.
   * @param {Boolean} [display.directoryType] The directory type column.
   * @param {Boolean} [display.header] The table header which includes the column headers.
   * @param {Boolean} [display.name] The name column.
   * @param {Boolean} [display.status] The status column.
   * @param {Boolean} [display.actions] The actions column.
   * @param {Boolean} [display.actions.approve] Approve in the actions column.
   * @param {Boolean} [display.actions.reject] Reject in the actions column.
   * @param {Boolean} [display.actions.link] Link directory in the actions column.
   * @param {Boolean} [display.actions.validate] Validate in the actions column.
   * @param {Function} [onClickApproveDomain] callback for when the approve action has been clicked
   * @param {Function} [onClickLinkDomain] callback for when the link action has been clicked
   * @param {Function} [onClickRejectDomain] callback for when the reject action has been clicked
   * @param {Function} [onClickValidateDomain] callback for when the validate action has been clicked
   * @description display list of owned and trusted domains for an organization.
   */
  angular.module('binky.widgets.domains').component('binkyDomainListTable', {
    bindings: {
      display: '<?',
      domainList: '<',
      onClickApproveDomain: '&?',
      onClickLinkDomain: '&?',
      onClickRejectDomain: '&?',
      onClickValidateDomain: '&?',
    },
    controller,
    templateUrl: 'widgets/domains/domain-list-table/domain-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    $timeout,
    $translate,
    _,
    DOMAIN_LIST_EVENT,
    DOMAIN_LIST_PAGINATION_ID,
    feature,
    JIL_CONSTANTS,
    panelManager,
    Selection
  ) {
    const vm = this;
    const PLACEHOLDER_TEXT = '--';

    _.assign(vm, {
      $onDestroy,
      $onInit,
      getDirectoryName,
      getDomainCheckboxLabel,
      onPageChange,
      onPageSizeChange,
      reverseSort,
    });

    const deregisterDomainListEventHandler = $rootScope.$on(DOMAIN_LIST_EVENT.UPDATE, () => {
      vm.editableDomains = getEditableDomains();
    });

    ///////////////

    function $onInit() {
      _.assign(vm, {
        domainHeaderCheckboxLabel: $translate.instant(
          'binky.widgets.domains.domainListTable.selectAll'
        ),
        editableDomains: getEditableDomains(),
        feature,
        isEditable,
        paginationId: _.uniqueId(DOMAIN_LIST_PAGINATION_ID),
        selection: new Selection('domainName'),
        sortOrderChanged: false,
        sortOrderChangedId: _.uniqueId('domain-list-sort-order-changed-'),
        sortOrderingId: _.uniqueId('domain-list-sort-ordering-'),
        sortReverse: false,
        wait: {},
      });
    }

    function $onDestroy() {
      deregisterDomainListEventHandler();
    }

    function getDirectoryName(directoryName) {
      return directoryName || PLACEHOLDER_TEXT;
    }

    function getDomainCheckboxLabel(domain) {
      return $translate.instant('binky.widgets.domains.domainListTable.selectDomain', {
        domainName: domain.domainName,
      });
    }

    function isEditable(domain) {
      return (
        !domain.isTrusted() &&
        (feature.isEnabled('temp_azure_idp') || !domain.isExternallyManaged())
      );
    }

    function onPageChange(newPage) {
      vm.domainList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.domainList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.domainList.setPage(0);
      refreshData();
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.domainList.sortBy(JIL_CONSTANTS.SORT.NAME, vm.sortReverse);
      refreshData();
    }

    ///////////////

    function getEditableDomains() {
      return _.filter(vm.domainList.items, isEditable);
    }

    function refreshData() {
      vm.domainList.refresh();

      // After sorting, we need to notify users of assistive technology that this has happened.
      // We only want the area that announces this to be visible long enough to let the user know.
      // So we set the variable that changes this temporarily to true.
      // This mimics behaviour in the coral table component. See also:
      // https://opensource.adobe.com/coral-spectrum/documentation/file/coral-spectrum/coral-component-table/src/scripts/Table.js.html#lineNumber1623
      vm.sortOrderChanged = true;
      $timeout(() => {
        vm.sortOrderChanged = false;
      }, 2500);
    }
  }
})();
