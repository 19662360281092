(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-chiclet
   * @description module product-chiclet
   */
  angular.module('binky.widgets.products.product-chiclet', [
    'binky.core.lodash',
    'binky.widgets.products.product-icon',
    'pascalprecht.translate',
  ]);
})();
