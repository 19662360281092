(function () {
  'use strict';
  /**
   * @deprecated Replaced by Pandora Administration's ReactVideoModal
   * https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-video-modal
   *
   * @ngdoc component
   * @name binky.widgets.common.video-modal:binkyVideoModal
   * @description A video modal widget.
   * @param {String} [modalId] The id of the modal.
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {String} [videoUrl] The video url link, can either be direct link to .mp4 or embedded youtube link.
   *   If it's an embedded youtube link, make sure none of the query params are attached ie: https://www.youtube.com/embed/c2I7X92IVGk
   */

  angular.module('binky.widgets.common.video-modal').component('binkyVideoModal', {
    bindings: {
      modalId: '@',
      onClose: '&?',
      onInitialized: '&?',
      onOpen: '&?',
      videoUrl: '@',
    },
    controller,
    templateUrl: 'widgets/common/video-modal/video-modal.component.html',
  });

  /* @ngInject */
  function controller(_, $element, $sce, $timeout, urlUtils, VIDEO_ALLOWLIST_DOMAINS) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      isYoutubeLink,
    });

    function $onInit() {
      const allowListValues = _.values(VIDEO_ALLOWLIST_DOMAINS);
      const acceptedUrl = _.some(allowListValues, (domainValue) =>
        urlUtils.hasDomain(vm.videoUrl, domainValue)
      );

      if (!acceptedUrl) {
        throw new Error('Video link source not accepted');
      }
      if (isYoutubeLink()) {
        vm.videoUrl = _.replace(vm.videoUrl, 'watch?v=', 'embed/'); // change to embedded link
        vm.videoUrl += '?modestbranding=1&rel=0'; // Minimize youtube branding
      }
      vm.trustedVideoUrl = $sce.trustAsResourceUrl(vm.videoUrl);

      Coral.commons.ready(() => {
        // change Coral set attributes so that close button is accessible
        const closeButton = $element[0].querySelector('._coral-Dialog-closeButton');
        // cannot unit test due to Coral issues, must invoke for coverage
        _.invoke(closeButton, 'removeAttribute', 'coral-tabcapture');
        _.invoke(closeButton, 'setAttribute', 'tabindex', '0');
      });

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function isYoutubeLink() {
      return urlUtils.hasDomain(vm.videoUrl, VIDEO_ALLOWLIST_DOMAINS.YOUTUBE);
    }
  }
})();
