(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.insights.item:InsightsItemDetailList
   * @description Model for a insight item detail list
   */
  angular
    .module('binky.core.insights.item')
    .factory('InsightsItemDetailList', insightsItemDetailList);

  /* @ngInject */
  function insightsItemDetailList(
    $q,
    $rootScope,
    _,
    binkySrc2,
    INSIGHTS_ITEM_DETAIL_EVENT,
    INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID,
    INSIGHTS_ITEM_DETAIL_LIST_EVENT,
    InsightsItemDetail,
    List,
    modelCache,
    reportBroker
  ) {
    class InsightsItemDetailList extends List {
      /**
       * @description Method to create a new instance of a InsightsItemDetailList.
       *
       * @param {Object} options - options passed to the InsightsItemDetailList constructor
       * @param {String} options.orgId - the id of the organization we're checking access for
       * @param {Object} [options.queryParams] - query params to filter InsightsItemDetailList
       * @param {String} options.type - type of insights item to fetch. Ex: assetActivity
       * @param {String} options.view - view type of insights item detail. Ex: default, preview
       */
      constructor(options) {
        super(
          _.assign(
            {
              cacheType: INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID,
              itemClassRef: InsightsItemDetail,
              resource: _.isUndefined(options.view)
                ? reportBroker.insightsItemDetail
                : reportBroker.insightsItemDetailView,
            },
            options
          )
        );
        this.orgId = options.orgId;
        this.queryParams = options.queryParams || {};
        this.type = options.type;
        this.view = options.view;
      }

      /**
       * @description Method to export csv file
       *
       * @param {Object} exportAction - object contains required url template
       * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=~rmccubbi&title=Report+Broker+Export+Design+Proposal
       * @param {String} apiKey - api key to pass as header
       *
       * @returns {Promise} resolves when successfully export data, else rejects with error
       */
      export(exportAction, apiKey) {
        const insightsItemDetailList2 = binkySrc2.services.insights.InsightsItemDetailList.get({
          queryParams: this.queryParams,
        });
        return insightsItemDetailList2.export(exportAction, apiKey);
      }

      /**
       * @description Method to refresh Insights Item Detail list from back-end.
       *
       * @returns {Promise} resolves to InsightItemDetailList on success, else rejects with error
       */
      refresh() {
        this.$promise = super
          .refresh(_.omitBy(getQueryParams(this), _.isUndefined))
          .then(onSuccess.bind(this))
          .catch($q.reject);

        function onSuccess() {
          $rootScope.$emit(INSIGHTS_ITEM_DETAIL_LIST_EVENT.REFRESH);
          return this;
        }
        return this.$promise;
      }

      /**
       * @description Method to remove insights report.
       *
       * @param {Array} reports - reports to be removed
       *
       * @returns {Promise} resolves to InsightsItemDetailList on success, else rejects with error
       */
      remove(reports) {
        this.$resolved = false;
        const operations = _.map(reports, (report) => ({
          op: 'remove',
          path: `/${report.id}`,
        }));

        this.$promise = reportBroker.insightsItemDetail
          .update(
            {
              type: this.type,
            },
            operations
          )
          .$promise.then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          this.state.resetParams();
          return this.refresh();
        }
        return this.$promise;
      }

      /**
       * @description Method to update query params of the insightsItemDetail list
       *
       * @param {Object} params - params to pass into the url
       */
      updateQueryParams(params) {
        _.forEach(params, (value, key) => {
          this.queryParams[key] = value;
        });
      }

      /**
       * @description Method to fetch the InsightsItemDetailList. It will be refreshed on registering the active org
       *
       * @param {Object} options - configuration options for list retrieval
       * @param {String} options.orgId - the org id we're checking access for
       * @param {String} options.type - type of insights item to fetch. Ex: assetActivity
       * @param {String} options.view - view type of insights item detail. Ex: default, preview
       *
       * @returns {InsightsItemDetailList} singleton InsightsItemDetailList.
       */
      static get(options) {
        let model = new InsightsItemDetailList(options);
        const cachedModel = modelCache.get(
          INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID,
          model.key(
            _.omitBy({orgId: options.orgId, type: options.type, view: options.view}, _.isUndefined)
          )
        );
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID, 10);
    modelCache.removeAllOnEvent(INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID, [
      INSIGHTS_ITEM_DETAIL_EVENT.CREATE,
    ]);

    return InsightsItemDetailList;

    ////////

    function getQueryParams(list) {
      return _.assign({orgId: list.orgId, type: list.type, view: list.view}, list.queryParams);
    }
  }
})();
