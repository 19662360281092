import jilSearch from 'api/jil/jilSearch';
import Member from 'models/member/Member';
import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import JilModelList from 'services/modelList/JilModelList';
import {
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
} from 'services/search/SearchMemberListConstants';
import UserGroup from 'services/users/user-group/UserGroup';

/**
 * @description generates a API response data transformer for the given OrgID
 *
 * @param {Object} orgId - data from the API response
 * @returns {Function} - ModelList transformer that converts API data into OrgUsers and UserGroups
 */
const searchMemberDataTransformer = (orgId) => {
  const transformer = (responseData) =>
    responseData
      .map((responseItem) => {
        if (
          [MEMBER_TYPE.TYPE1, MEMBER_TYPE.TYPE2, MEMBER_TYPE.TYPE2E, MEMBER_TYPE.TYPE3].includes(
            responseItem.type
          )
        ) {
          return new OrganizationUser({orgId, ...responseItem});
        }
        if (MEMBER_TYPE.USER_GROUP === responseItem.type) {
          return new UserGroup({orgId, ...responseItem});
        }
        return null;
      })
      .filter((item) => item !== null);
  return transformer;
};

class SearchMemberList extends JilModelList {
  /**
   * @description Retrives an existing SearchMemberList from the backend.
   *
   * @param {Object} options - The same options defined in the constructor below
   * @returns {Promise} Promise that resolves to the instance when the refresh is successful
   */
  static get(options) {
    const model = new SearchMemberList(options);
    return model.refresh();
  }

  /**
   * @description Generates SearchMemberList options from more intuitive values.
   *     It is easy to send invalid/conflicting target values to the API and throw errors,
   *     so this method exists to make this process easier.
   *     This method is similar to SearchUserList's getTargetOptionsFromPageContext,
   *     but has some differences to allow for greater flexibility when using it.
   *
   * @param {Object} options - A given context to derive target values from
   * @param {SEARCH_TARGET_RESULT_TYPE} [options.searchTargetType] - Defines the type of search results.
   *     Determines if results should filter to only admins of the target, or all users.
   * @param {SEARCH_TARGET_CLASS} [options.targetClass] - Class type of the target to search within
   *     (e.g. Product, License Groups (PLCs), User Group).
   *     If left blank, the search will default to searching the whole Organization.
   * @param {String} [options.targetId] - Object ID of the target
   *     (e.g. productId, licenseGroupId, userGroupId).
   * @param {String} [options.targetParentId] - Object ID of the parent of the target.
   *     Generally only required for LicenseGroups, to define the parent Product.
   * @returns {Object} Appropriate options for the SearchMemberList constructor
   */
  static generateTargetOptions(options) {
    const targetOptions = {};

    switch (options.searchTargetType) {
      case SEARCH_TARGET_RESULT_TYPE.ADMIN:
      case SEARCH_TARGET_RESULT_TYPE.USER:
        targetOptions.targetType = options.searchTargetType;
        break;
      default:
        break;
    }

    switch (options.targetClass) {
      case SEARCH_TARGET_CLASS.PRODUCT:
        // JIL currently treats searching of Users in a Product as an invalid request.
        // We will only set the targetProductId when searching for Admins.
        if (targetOptions.targetType === SEARCH_TARGET_RESULT_TYPE.ADMIN) {
          targetOptions.targetProductId = options.targetId;
        }
        break;
      case SEARCH_TARGET_CLASS.PRODUCT_CONFIGURATION:
        targetOptions.targetLicenseGroupId = options.targetId;
        targetOptions.targetProductId = options.targetParentId;
        break;
      case SEARCH_TARGET_CLASS.USER_GROUP:
        targetOptions.targetUserGroupId = options.targetId;
        break;
      default:
        break;
    }
    return targetOptions;
  }

  /**
   * @class
   * @description Store for fetching search results for users and user groups
   *
   * @param {Object} options - The options object. See JilModelList for params not listed here.
   * @param {String} [options.filterQuery] - query string to match
   * @param {Boolean} [options.includeUserGroups] - include user groups in search results
   * @param {String} options.orgId - the org id
   * @param {String} [options.searchQuery] - deprecated query string. After filterQuery is used in Onesie, can be removed.
   * @param {String} [options.targetLicenseGroupId] - target license group ID
   * @param {String} [options.targetProductId] - target product ID
   * @param {String} [options.targetType] - type of target fields. One of the SEARCH_TARGET_RESULT_TYPE constants
   * @param {String} [options.targetUserGroupId] - target user group ID
   * @param {Function} [options.transformResponseData] - Custom transform function used when fetching
   *  from resource. Required for backwards compatibility while the feature flag
   *  temp_react_port_search_user_list is still in use. Can be removed once flag is removed.
   */
  constructor({
    filterQuery,
    includeUserGroups,
    orgId,
    searchQuery,
    targetLicenseGroupId,
    targetProductId,
    targetType,
    targetUserGroupId,
    transformResponseData,
    ...otherOptions
  }) {
    if (orgId === null || orgId === undefined) {
      throw new Error('Missing required parameter options.orgId');
    }
    super({
      filterQuery: filterQuery || searchQuery,
      itemClassRef: Member,
      resource: jilSearch.getSearchMembers,
      transformResponseData: transformResponseData || searchMemberDataTransformer(orgId),
      ...otherOptions,
    });
    Object.assign(this, {
      includeUserGroups,
      orgId,
      targetLicenseGroupId,
      targetProductId,
      targetType,
      targetUserGroupId,
    });
  }

  /**
   * @description Method to refresh the contents of the organization user list
   *
   * @returns {Promise} promise - resolved when the list is refreshed
   */
  async refresh() {
    await super.refresh({
      include_user_groups: this.includeUserGroups,
      orgId: this.orgId,
      target_license_group_id: this.targetLicenseGroupId,
      target_product_id: this.targetProductId,
      target_type: this.targetType,
      target_user_group_id: this.targetUserGroupId,
    });

    return this;
  }
}

export default SearchMemberList;
