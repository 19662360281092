import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Configure JIL Self Cancel API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Self Cancel API
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Sends a list of products with attached licenses that the admin wishes to cancel
 * from the current contract.
 *
 * @param {String} options.orgId - Id of the organization.
 * @param {Object} options.context - Required object that contains the following:
 * @param {String} options.context.contractId Required id of the contract.
 * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
 * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
 * @param {Object[]} context.cancellationItems Required array of objects that contain the following:
 * @param {String} cancellationItem.productId Product id of what to cancel.
 * @param {Number} cancellationItem.quantity Number of licenses associated with the productId to cancel.
 * @param {String[]} cancellationItem.users Optional array of users who will lose access to that product.
 * @returns {Promise} Resolves to the axios response object whose data contains an id for the cancellation order,
 * a validation token string associated with the cancellation session and the cancellation data with the preview
 * result of cancelling the provided seats.
 */
const postChangeRequest = ({context, orgId}) =>
  axios.post(
    `${url}/v2/organizations/${orgId}/products:change/`,
    {context, type: 'CANCEL'},
    {headers: getHeaders({clientId, includeRoles})}
  );

/**
 * @description Sends a modified list of products with attached licenses
 * that the admin wishes to cancel from the current contract.
 * Will also be used when committing the final changes to the license subscription.
 *
 * @param {String} options.changeId - Id of the existing session open to cancel the licenses
 * @param {String} options.orgId - Id of the organization.
 * @param {Object} [params] - Optional object that may contain an include RETENTION_INFORMATION property
 * to request retention data to be returned in the response.
 * @param {String} options.validationToken - Security token passed to ensure session consistency
 * @param {Object} options.context - Required object that contains the following:
 * @param {String} context.contractId Required id of the contract.
 * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
 * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
 * @param {Object[]} context.cancellationItems Required array of objects that contain the following:
 * @param {String} cancellationItem.productId Product id of what to cancel.
 * @param {Number} cancellationItem.quantity Number of licenses associated with the productId to cancel.
 * @param {String[]} cancellationItem.users Optional array of users who will lose access to that product.
 * @returns {Promise} Resolves to the axios response object whose data contains an id for the cancellation order,
 * a validation token string associated with the cancellation session and the cancellation data with the preview
 * or submit result of cancelling the provided seats.
 */
const putChangeRequest = ({changeId, context, orgId, params, validationToken}) =>
  axios.put(
    `${url}/v2/organizations/${orgId}/products:change/${changeId}`,
    {context, id: changeId, type: 'CANCEL', validationToken},
    {headers: getHeaders({clientId, includeRoles}), params}
  );

const jilOrganizationsProductsChange = {
  configure,
  postChangeRequest,
  putChangeRequest,
};

export default jilOrganizationsProductsChange;
