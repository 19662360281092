(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   */
  angular
    .module('binky.widgets.omni-tool.panel-manager')
    .constant('OMNI_TOOL_PANEL_REGISTER', 'omni-tool-panel-register')
    .constant('OMNI_TOOL_PANEL_SWITCH', 'omni-tool-panel-switch');
})();
