(function () {
  /**
   * @deprecated Migrated to GlobalAdminPolicyList.js
   *
   * @ngdoc factory
   * @name GlobalAdminPolicyList
   * @description Model for organization policies which are set by GAC (Global Admin Console)
   */
  angular.module('binky.core.globalAdmin').factory('GlobalAdminPolicyList', factory);

  /* @ngInject */
  function factory(binkySrc2, promiseUtils) {
    class GlobalAdminPolicyList extends binkySrc2.services.globalAdmin.GlobalAdminPolicyList {
      static get(options) {
        return promiseUtils.toAngularPromise(
          binkySrc2.services.globalAdmin.GlobalAdminPolicyList.get(options)
        );
      }
    }

    return GlobalAdminPolicyList;
  }
})();
