// eslint-disable-next-line you-dont-need-lodash-underscore/omit -- spread is more complex
import omit from 'lodash/omit';
import union from 'lodash/union';
import without from 'lodash/without';

import modelCache from 'services/cache/modelCache/modelCache';
import log from 'services/log';
import {getListCacheKey} from 'utils/cacheUtils';

import {SEARCH_QUERY_MIN_LENGTH} from './ModelListConstants';

function transformResponseData(responseData, ClassRef) {
  return responseData.map((responseItem) => new ClassRef(responseItem));
}

class ModelList {
  /**
   * @description Method for getting a collection of models.  If the list is cacheable, this
   *    method will check for a cached instance before querying the resource.
   * @param {Object} options - See constructor for details
   * @return {Promise<ModelList>} resolves to refreshed list instance
   */
  static get(options) {
    const list = new this(options);

    if (!list.isCacheable) {
      return list.refresh(options);
    }

    const key = list.getModelKey(options);
    if (modelCache.has(list.modelCacheId, key)) {
      const cachedPromise = modelCache.get(list.modelCacheId, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    // Item is not already cached.
    const promise = list.refresh(options);
    modelCache.put(list.modelCacheId, key, promise);
    return promise;
  }

  /**
   * @description Method to return a unique key for the given parameters
   * @param {Object} params - params passed into a GET
   * @returns {String} key to uniquely identify this list
   */
  static getKey(params) {
    return getListCacheKey(params);
  }

  /**
   * @class
   * @description Constructor for List model Objects.
   * @param {Object}[options] - List instance settings
   * @param {Array<String>}[options.cacheClearingEvents] - Events that clear this list's cache. (defaults to [])
   * @param {Boolean} [options.isCacheable] - Are entries cached to avoid unnecessary queries?
   * @param {Class|Array<Class>} [options.itemClassRef] - class or an array of classes to instantiate list items as on refresh
   * @param {String} [options.itemId] - name of the item property which uniquely identifies an
   *   item in the list. Typically this is the 'id' property, but for Domains it is 'domainName'
   *   (defaults to 'id')
   * @param {Array} [options.items] - actual things to include in this list
   * @param {String} [options.modelCacheId] - The id used for this list's cache
   * @param {Function} [options.resource] - A function that returns a collection to populate list items.
   * @param {Function} [options.transformResponseData] - custom transform function used when fetching
   *                    from resource. It is recommended to use the default transform method
   *                    (via itemClassRef constructor), but it can be used to support necessary unique
   *                    transforms. Called with the axios response data FileReader.
   * @throws {Error} when list is marked isCacheable but modelCacheId is not defined.
   */
  constructor(options = {}) {
    this.SEARCH_QUERY_MIN_LENGTH = SEARCH_QUERY_MIN_LENGTH;
    this.addedItems = [];
    this.cacheClearingEvents = options.cacheClearingEvents || [];
    this.isCacheable = options.isCacheable || false;
    this.itemClassRef = options.itemClassRef;
    this.items = options.items || [];
    this.itemId = options.itemId || 'id';
    this.modelCacheId = options.modelCacheId;
    this.removedItems = [];
    this.resource = options.resource;
    this.transformResponseData = options.transformResponseData
      ? options.transformResponseData
      : transformResponseData;

    if (this.isCacheable && !this.modelCacheId) {
      throw new Error(`modelCacheId is required for cacheable list classes`);
    }

    this.#registerCacheClearingEvents();
  }

  // Private method used during construction to setup event listeners
  #registerCacheClearingEvents() {
    if (this.cacheClearingEvents.length === 0) {
      return;
    }

    if (!this.isCacheable) {
      throw new Error(`cacheClearingEvents cannot be defined for non-cacheable lists`);
    }

    modelCache.clearOnEvent(this.modelCacheId, this.cacheClearingEvents);
  }

  /**
   * @description Method for adding items to a list
   * @param {Array} items - items to add to list
   */
  add(items) {
    const itemsToAdd = [];

    items.forEach((item) => {
      const itemPendingRemove = this.removedItems.find(
        (removed) => removed[this.itemId] === item[this.itemId]
      );

      if (itemPendingRemove) {
        // item was pending remove (unsaved)
        this.removedItems = without(this.removedItems, itemPendingRemove);
      } else if (
        item[this.itemId] === undefined || // item is new
        item[this.itemId] === null || // item is new
        (!this.items.some((i) => i[this.itemId] === item[this.itemId]) && // item not in list of items
          !this.addedItems.some((added) => added[this.itemId] === item[this.itemId]) && // item not in list of items to add
          !itemsToAdd.some((toAdd) => toAdd[this.itemId] === item[this.itemId]))
      ) {
        // item not in list of items to add to list of items to add
        itemsToAdd.push(item);
      }
    });
    this.addedItems = union(this.addedItems, itemsToAdd);
  }

  /**
   * @description Clear all cached entries
   * @throws - Will throw an exception if list is not cached
   */
  clearCache() {
    if (!this.isCacheable) {
      throw new Error('Cannot clear cache on uncached list');
    }
    modelCache.clear(this.modelCacheId);
  }

  getModelKey(params) {
    const getKeyFunc = this.getKey?.bind(this) || ModelList.getKey;
    return getKeyFunc(params);
  }

  /**
   * @description Method to determine if there are any unsaved changes to this
   *              list. Unsaved changes are either items pending addition or
   *              removal to/from this list since list creation or last save.
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return this.addedItems.length > 0 || this.removedItems.length > 0;
  }

  /**
   * @description Method to determine if an item is pending removal from this list.
   * @param {Object} item - Item to see if removal pending
   * @returns {Boolean} true if item is pending removal, else false
   */
  isPendingRemoval(item) {
    return this.removedItems.some((removed) => removed[this.itemId] === item[this.itemId]);
  }

  /**
   * @description Method to refresh the contents of the list.  For cacheacble lists, this will not
   *              check the cache first, but it will cache the results.
   * @param {Object} queryParams - params to pass into the GET
   * @param {Object} [options] - additional options for refresh
   * @param {Function} [options.onSuccessPostTransform] - handler for successful
   *     response after transforming data
   * @param {Function} [options.onSuccessPreTransform] - handler for successful
   *     response before transforming data
   * @return {Promise<ModelList>} resolves to repopulated mode, else rejects with error message
   */
  async refresh(queryParams = {}, options = {}) {
    let promise;

    const onQueryError = (error) => {
      log.error(
        `ModelList.refresh(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      // eslint-disable-next-line promise/no-promise-in-callback -- why does this rule trigger here but not in onQuerySuccess?
      promise = Promise.reject(error);
    };

    const onQuerySuccess = (response) => {
      if (options.onSuccessPreTransform) {
        options.onSuccessPreTransform.call(this, response);
      }
      // update model items
      try {
        this.items = this.transformResponseData(response.data, this.itemClassRef);
        if (options.onSuccessPostTransform) {
          options.onSuccessPostTransform.call(this);
        }
      } catch (error) {
        // raise data transformation error
        log.error(error);
        promise = Promise.reject(error);
        return;
      }
      promise = Promise.resolve(this);
      if (this.isCacheable) {
        modelCache.put(this.modelCacheId, this.getModelKey(queryParams), promise);
      }
    };

    try {
      const response = await this.resource(queryParams);
      onQuerySuccess(response);
    } catch (error) {
      onQueryError(error);
    }

    return promise;
  }

  /**
   * @description Method for removing items from a list
   * @param {Array} items - items to remove from list
   */
  remove(items) {
    const itemsToRemove = [];

    items.forEach((item) => {
      const itemPendingAdd = this.addedItems.find(
        (added) => added[this.itemId] === item[this.itemId]
      );

      if (itemPendingAdd) {
        // item was pending add (unsaved)
        this.addedItems = without(this.addedItems, itemPendingAdd);
      } else {
        itemsToRemove.push(item);
      }
    });

    this.removedItems = union(this.removedItems, itemsToRemove);
  }

  /**
   * @description Method to remove any items pending addition to this list.
   */
  resetAddedItems() {
    this.addedItems = [];
  }

  /**
   * @description Method to remove any items pending removal from this list.
   */
  resetRemovedItems() {
    this.removedItems = [];
  }

  /**
   * @description Method to convert a src2 list to a src1 list
   * @param {Class} src1ItemClassRef - the src1 class
   * @param {Class} src1ListRef - the src1 class list class
   */
  toSrc1List(src1ItemClassRef, src1ListRef) {
    // omit fields not used in src2
    const listWithOmittedFields = omit(this, [
      'itemClassRef',
      'resource',
      'isCacheable',
      'modelCacheId',
      'transformResponseData',
    ]);

    // eslint-disable-next-line new-cap -- class property
    const src1List = new src1ListRef(listWithOmittedFields);
    // convert list to src1 list
    Object.assign(src1List, listWithOmittedFields);

    if (Array.isArray(src1ItemClassRef)) {
      // The list has an array of mixed item class refs, convert to src1 item class refs
      Object.assign(
        src1List.items,
        this.items.map((item) => {
          const CurrentClassRef = src1ItemClassRef.find(
            (ref) => ref.canTransform && ref.canTransform(item)
          );
          return Object.assign(new CurrentClassRef({...item}), {...item});
        })
      );
    } else {
      // convert all items to src1 items
      Object.assign(
        src1List.items,
        this.items.map((item) =>
          // eslint-disable-next-line new-cap -- class property
          Object.assign(new src1ItemClassRef({...item}), {...item})
        )
      );
    }

    return src1List;
  }
}

export default ModelList;
