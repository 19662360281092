import {Item, Picker, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductAdminRoleFilterConstants from './ProductAdminRoleFilterConstants';

const {ALL, PRODUCT, PRODUCT_SUPPORT} = ProductAdminRoleFilterConstants;

/**
 * A widget for picking the admin role to display in product detail page.
 */
const ProductAdminRoleFilter = ({onSelectRole, ...pickerProps}) => {
  const intl = useIntl();

  return (
    <Picker
      data-testid="product-admin-role-filter"
      defaultSelectedKey={ALL}
      label={intl.formatMessage({id: 'binky.common.productAdminRoleFilter.label'})}
      onSelectionChange={onSelectRole}
      {...pickerProps}
    >
      {[ALL, PRODUCT, PRODUCT_SUPPORT].map((option) => (
        <Item key={option}>
          <Text>
            {intl.formatMessage({
              id: `binky.common.productAdminRoleFilter.options.${option}`,
            })}
          </Text>
        </Item>
      ))}
    </Picker>
  );
};

ProductAdminRoleFilter.propTypes = {
  /**
   * Handler that is called when an admin role is selected.
   * The selected role is passed to the handler as a parameter.
   */
  onSelectRole: PropTypes.func.isRequired,
};

export default ProductAdminRoleFilter;
