(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name UiEventDescriptor
   * @description This class describes an event that occurred involving a UI component
   */
  angular.module('binky.core.ui-event').factory('UiEventDescriptor', factory);

  function factory(_) {
    class UiEventDescriptor {
      /**
       * @description Constructor
       *
       * @param {Object} options initialization options
       * @param {String} options.componentName source component name for this event
       * @param {String} options.eventAction event action that triggers this event (e.g. click)
       * @param {String} options.eventName event name (e.g. chat)
       */
      constructor(options = {}) {
        initModel(this, options);
      }
    }

    return UiEventDescriptor;

    //////////

    function initModel(model, options) {
      _.assignIn(model, _.pick(options, ['eventAction', 'eventName', 'interaction', 'name']));
    }
  }
})();
