(function () {
  /**
   * @deprecated React services do not use these
   *
   * @ngdoc factory
   * @name apiAntiCacheInterceptor
   * @description Request cache buster for certain browsers
   */
  angular
    .module('binky.core.api.interceptor')
    .provider('apiAntiCacheInterceptor', apiAntiCacheInterceptor);

  function apiAntiCacheInterceptor(_) {
    let allowlist = [];

    this.configure = configure;
    this.$get = $get;

    ///////////////

    /**
     * @description Configure the apiAntiCacheInterceptor
     * @param {Object} [config] - configuration object
     * @param {Array} [config.allowlist] - allowlist of api that require transform checks
     */
    function configure(config = {}) {
      allowlist = _.union(allowlist, config.allowlist);
    }

    /* @ngInject */
    function $get() {
      return {request: requestHandler};
    }

    function requestHandler(config = {}) {
      if (config.method === 'GET' && isAllowedApi(config)) {
        const separator = _.includes(config.url, '?') ? '&' : '?';
        const timestamp = new Date().getTime();
        config.url = `${config.url + separator}antiCache=${timestamp}`;
      }
      return config;
    }

    /**
     * @description Helper to determine if request requires cache busting
     * @param {Object} config - params of the request
     * @param {String} [config.url] - URL the request will be made to
     * @returns {Boolean} true if request url contains prefix of any allowed api
     */
    function isAllowedApi(config) {
      return _.some(allowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix));
    }
  }
})();
