(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.directories.directory-domain-list-table:binkyDirectoryDomainListTable
   * @description diplay list of domains for a specific directory.
   */
  angular
    .module('binky.widgets.directories.directory-domain-list-table')
    .component('binkyDirectoryDomainListTable', {
      bindings: {
        directoryDomainList: '<',
      },
      controller,
      templateUrl:
        'widgets/directories/directory-domain-list-table/directory-domain-list-table.component.html',
    });

  /* @ngInject */
  function controller($timeout, $translate, _, DIRECTORY_DOMAIN_LIST_PAGINATION_ID, JIL_CONSTANTS) {
    const vm = this;

    _.assign(vm, {
      onPageChange,
      onPageSizeChange,
      paginationId: DIRECTORY_DOMAIN_LIST_PAGINATION_ID,
      reverseSort,
      sortReverse: false,
      wait: {},
    });

    ///////////////

    function onPageChange(newPage) {
      vm.directoryDomainList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.directoryDomainList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.directoryDomainList.setPage(0);
      refreshData();
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.directoryDomainList.sortBy(JIL_CONSTANTS.SORT.NAME, vm.sortReverse);
      refreshData();
    }

    ///////////////

    function refreshData() {
      vm.directoryDomainList.refresh();
    }
  }
})();
