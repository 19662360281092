import pick from 'lodash/pick';

/**
 * @description This class represents basic organization info
 */
class OrganizationInfoDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {Number} [options.assignedLicenseVariation] The change in the number of licenses assigned to users in this organization
   * @param {Number} [options.totalCount] The total number of users in this organization
   * @param {Number} [options.userCountVariation] The change in the number of users in this organization
   */
  constructor(options = {}) {
    Object.assign(
      this,
      pick(options, ['assignedLicenseVariation', 'totalCount', 'userCountVariation'])
    );
  }
}

export default OrganizationInfoDescriptor;
