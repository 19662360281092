import moment from 'moment';
import format from 'number-format.js';

import binkySrc2 from '../../binkySrc2';

// Our Angular code expects to use some libraries as globals. Shimming with webpack.ProvidePlugin
// isn't sufficient because parts of the code refer to XXX as $window.XXX
/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- @boag to update*/
window.binkySrc2 = binkySrc2;
window.format = format;
window.moment = moment;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- @boag to update*/
