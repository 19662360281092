(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc constant
   * @name Country constants
   * @description defines country constants
   */
  angular
    .module('binky.core.organizations.user')
    .constant('ORGANIZATION_USER_2_CACHE_ID', 'OrganizationUser2')
    .constant('ORGANIZATION_USER_ERROR_CODE', {
      CANNOT_CREATE_USER: 'CANNOT_CREATE_USER',
      INVALID_USER_ID: 'INVALID_USER_ID',
      LDS_ERROR: 'LDS_ERROR',
      USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    });
})();
