(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-role-switcher
   * @description module that defines the product role switcher widget
   */
  angular.module('binky.widgets.products.product-role-switcher', [
    'binky.widgets.common.select',
    'pascalprecht.translate',
  ]);
})();
