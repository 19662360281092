import JIL_CONSTANTS from '../../../api/jil/JilConstants';
import AlphaListState from '../alpha/AlphaListState';

class HybridListState extends AlphaListState {
  /**
   * @description Constructor for HybridListState model Objects.
   *  This supports list services that can not use next-token paging when searching.
   * @param {Object} [options] - options object
   */
  constructor(options) {
    super(options);

    this.itemCount = 0;
    this.pageCount = 0;
  }

  goToNextPage() {
    if (this.filterQuery.length < this.filterQueryMinLength) {
      super.goToNextPage();
    } else {
      this.setPage(this.pageNumber + 1);
    }
  }

  goToPreviousPage() {
    if (this.filterQuery.length < this.filterQueryMinLength) {
      super.goToPreviousPage();
    } else {
      this.setPage(this.pageNumber - 1);
    }
  }

  hasNextPage() {
    return this.filterQuery.length < this.filterQueryMinLength
      ? super.hasNextPage()
      : this.pageNumber < this.pageCount;
  }

  hasPreviousPage() {
    return this.filterQuery.length < this.filterQueryMinLength
      ? super.hasPreviousPage()
      : this.pageNumber > 1;
  }

  setPage(newPage) {
    if (newPage > 0 && newPage !== this.pageNumber) {
      // valid page (starts at zero and not same page that is currently selected)
      this.pageNumber = newPage;
    }
  }

  setResponseAndHeaders(response, headers) {
    if (this.filterQuery.length >= this.filterQueryMinLength) {
      this.itemCount = Number.parseInt(headers[JIL_CONSTANTS.HEADERS.TOTAL_COUNT], 10) || 0;
      this.pageCount = Number.parseInt(headers[JIL_CONSTANTS.HEADERS.PAGE_COUNT], 10) || 0;
    }
    super.setResponseAndHeaders(response, headers);
  }

  toQueryParams() {
    // ACP paging does not support searching so we should call index paging api when searching
    return this.filterQuery.length < this.filterQueryMinLength
      ? super.toQueryParams()
      : {
          page: this.pageNumber - 1,
          page_size: this.pageSize,
          search_query: this.filterQuery,
          sort: this.sortKey,
          sort_order: this.sortReverse ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
        };
  }
}

export default HybridListState;
