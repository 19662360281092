import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ButtonLink from '../button-link/ButtonLink';

import ProductIconGroup from './product-icon-group/ProductIconGroup';

/**
 * Helper to group products for display
 *
 * @param {Product[]} products products to group
 *
 * @returns {Object[]} array containing sub-arrays of products denoting groups.
 *      If the group contains only one product, that indicates an un-grouped
 *      product
 */
function generateProductGroups(products) {
  const svgMapping = {};
  const productGroups = [];

  products.forEach((product) => {
    const svg = product?.assets?.icons?.svg;
    if (svg) {
      if (!svgMapping[svg]) {
        productGroups.push((svgMapping[svg] = []));
      }
      svgMapping[svg].push(product);
    } else {
      productGroups.push([undefined]);
    }
  });

  return productGroups;
}

/**
 * The default number of products to be shown before "+ x more" is shown.
 */
const DEFAULT_INLINE_PRODUCT_COUNT = 5;

/**
 * @deprecated. Use import {ProductIconList} from '@pandora/react-product-icon-list' instead.
 * A widget to display multiple product icons
 *
 * This widget also allows a visual grouping of products with the same icons in
 *     a container with a single icon and a product counter representing the
 *     similar products
 */
const ProductIconList = ({
  inlineProductCount = DEFAULT_INLINE_PRODUCT_COUNT,
  products = [],
  onClickMoreProducts,
  wrap = true,
}) => {
  const intl = useIntl();
  const productGroups = generateProductGroups(products);

  const numProductsToShow = inlineProductCount > 0 ? inlineProductCount : products.length;

  const displayList = productGroups.slice(0, numProductsToShow);
  const extraProductsCount = productGroups
    .slice(numProductsToShow)
    .reduce((count, productGroup) => count + productGroup.length, 0);

  return (
    <Flex alignContent="center" alignItems="center" gap="size-10" wrap={wrap}>
      {displayList.map((productGroup, index) => (
        <ProductIconGroup
          key={productGroup[0]?.assets?.icons?.svg || `empty${index}`}
          data-testid="product-group"
          productGroup={productGroup}
        />
      ))}

      {extraProductsCount > 0 && (
        <View marginStart="size-100">
          <ButtonLink data-testid="more-products" onPress={(e) => onClickMoreProducts?.(e)}>
            {intl.formatMessage(
              {id: 'binky.common.productIconGroup.seeMore'},
              {amount: extraProductsCount}
            )}
          </ButtonLink>
        </View>
      )}
    </Flex>
  );
};

ProductIconList.propTypes = {
  /**
   * The number of products to display, before any remaining products are
   * replace with a "+ x more" link.
   * The default is 5.
   * If this number is 0 or >= the length of the products array, all products are shown.
   */
  inlineProductCount: PropTypes.number,
  /**
   * Callback to be invoked when clicking the "+ x more" button
   */
  onClickMoreProducts: PropTypes.func,
  /**
   * The list of products whose icons would be displayed and optionally grouped
   * This requires the following fields: `longName`, `id`. If
   * `assets.icons.svg` of the product is undefined an alert icon is displayed
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  products: PropTypes.arrayOf(PropTypes.object),
  /**
   * Determines whether the list wraps to fit in the available space.
   * This is particularly useful if this component is in a Table <Cell>.
   * The default is true.
   */
  wrap: PropTypes.bool,
};

export {DEFAULT_INLINE_PRODUCT_COUNT};
export default ProductIconList;
