import {Content, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import GoUrl from 'common/components/go-url/GoUrl';

const RecommendedTypeMessage = ({additionalMessage}) => {
  const intl = useIntl();

  return (
    <>
      <Heading>
        {intl.formatMessage({
          id: `binky.common.idTypePicker.tooltip.t2t3recommendation.title`,
        })}
      </Heading>
      <Content>
        <View elementType="p">
          {intl.formatMessage({
            id: `binky.common.idTypePicker.tooltip.t2t3recommendation.body`,
          })}
        </View>
        {additionalMessage && (
          <View data-testid="additional-message" elementType="p">
            {additionalMessage}
          </View>
        )}
        <View elementType="p" marginTop="size-0">
          <GoUrl name="ac_identity">
            {intl.formatMessage({id: 'binky.common.idTypePicker.tooltip.learnMore'})}
          </GoUrl>
        </View>
      </Content>
    </>
  );
};

RecommendedTypeMessage.propTypes = {
  /**
   * @description Additional message to be rendered after the T2/T3 recommendation
   */
  additionalMessage: PropTypes.string,
};

export default RecommendedTypeMessage;
