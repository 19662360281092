(function () {
  /**
   * @deprecated Please use src2/core/utils/downloadUtils.js
   */
  angular.module('binky.core.download-utils').factory('downloadUtils', downloadUtils);

  /* @ngInject */
  function downloadUtils(binkySrc2) {
    return binkySrc2.utils.downloadUtils;
  }
})();
