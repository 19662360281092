import {Flex} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import {TooltipButton} from '@pandora/react-tooltip-button';
import ViewDetailIcon from '@spectrum-icons/workflow/ViewDetail';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

/**
 * A quiet action button with a 'View detail' icon which when pressed, opens a Pandora drawer.
 * The button is wrapped in a tooltip which shows on focus or hover.
 *
 * This button is designed to be placed in a Spectrum TableView Cell in a Column
 * with props:
 *  {hideHeader: true, showDivider: true}
 *
 * The flex wrapper is needed to center the icon so the focus indicator around the button
 * is not clipped. Setting the column alignment to 'center' does not fix the problem.
 *
 * @example
 * <ViewDetailsDrawerTrigger ...>
 *  {(close) => <Drawer>...<Button onPress={close}>Close</Button>...</Drawer>
 * </ViewDetailsDrawerTrigger>
 */
const ViewDetailsDrawerTrigger = ({
  'aria-label': ariaLabel,
  children,
  offsetTop = 'var(--header-height)',
  tooltipName,
  tooltipText,
}) => {
  const intl = useIntl();

  return (
    <Flex justifyContent="center">
      <DrawerTrigger offsetTop={offsetTop}>
        <TooltipButton
          buttonAriaLabel={ariaLabel}
          hoverText={
            tooltipText ||
            intl.formatMessage({id: 'binky.common.viewDetailsDrawerTrigger.tooltip'}, {tooltipName})
          }
          isQuiet
          variant="action"
        >
          <ViewDetailIcon size="S" />
        </TooltipButton>
        {(close) => children(close)}
      </DrawerTrigger>
    </Flex>
  );
};

ViewDetailsDrawerTrigger.propTypes = {
  /**
   * An aria-label for the button. This should provide whatever context is needed for a screen reader
   * user to understand what this icon is associated with.
   * The default is the tooltip text.
   */
  'aria-label': PropTypes.string,
  /**
   * This uses the React 'renderProp' pattern to render the Pandora drawer.
   */
  children: PropTypes.func.isRequired,
  /**
   * Used to offset top so drawer starts below the header so the navigation
   * is still accessible without closing the drawer. The default value is var(--header-height): 3.5rem
   * which is the height of the header of the Admin Console
   */
  offsetTop: PropTypes.string,
  /**
   * The name parameter for the default tooltip which is 'View details for {tooltipName}`.
   * This is required if hoverText is not specified.
   */
  tooltipName: requiredIf(PropTypes.string, (props) => !props.tooltipText),
  /**
   * The text to show in a tooltip when the button is in focus or hovered on.
   * It is also the aria-label for the icon button.
   * The default is 'View details for {displayName}'.
   */
  tooltipText: requiredIf(PropTypes.string, (props) => !props.tooltipName),
};

export default ViewDetailsDrawerTrigger;
