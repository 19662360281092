/**
 * @description Method to determine if the FIs have an organization "consumable" and
 *      should look for usage information via the ConsumableSummarizationList.
 *
 * @returns {Boolean} true if it's FIs have organization consumables, else false
 */
function isOrganizationConsumable(fulfillableItemList) {
  return fulfillableItemList.hasOrgOnDemandConsumable();
}

// eslint-disable-next-line import/prefer-default-export -- ignore for util files
export {isOrganizationConsumable};
