(function () {
  'use strict';
  angular.module('binky.shell.navigation.responsive-nav', [
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.strip-html-elements',
    'binky.widgets.common.watch-size',
    'ngSanitize',
    'pascalprecht.translate',
  ]);
})();
