import {AuthenticatedUser} from '@admin-tribe/binky';
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Provider,
  Text,
  View,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import GoUrl from 'common/components/go-url/GoUrl';

import Avatar from '../avatar/Avatar';

/**
 * AuthenticatedUserPullDown: The user profile drop down widget
 */
const AuthenticatedUserPullDown = ({activeRole, authenticatedUser, offSet = 6, onSignOut}) => {
  const intl = useIntl();
  const userTypeContent = authenticatedUser.getType().isType1()
    ? intl.formatMessage({
        id: 'binky.user.authenticatedUserPulldown.viewPersonalAccount',
      })
    : intl.formatMessage({
        id: 'binky.user.authenticatedUserPulldown.viewCompanyOrSchoolAccount',
      });
  const displayName = authenticatedUser.getDisplayName();
  const displayEmail = authenticatedUser.getEmail();
  const actionButtonAriaLabel = intl.formatMessage(
    {
      defaultMessage: 'User profile dropdown button',
      id: 'binky.user.authenticatedUserPulldown.userProfileDropdown',
    },
    {displayName}
  );

  return (
    <DialogTrigger containerPadding={0} hideArrow offset={offSet} shouldFlip={false} type="popover">
      <Provider colorScheme="dark">
        <View backgroundColor="gray-50">
          <ActionButton
            aria-haspopup="menu"
            aria-label={actionButtonAriaLabel}
            data-testid="user-profile-button"
            id="user-profile-button"
            isQuiet
          >
            <Avatar member={authenticatedUser} size="XS" useDefault={false} />
          </ActionButton>
        </View>
      </Provider>
      <Dialog
        aria-labelledby="user-profile-button"
        marginBottom="calc(-1 * size-500)"
        marginStart="calc(-1 * size-250)"
        marginTop="calc(-1 * size-175)"
        UNSAFE_style={{
          lineHeight: 'var(--spectrum-global-dimension-size-325)',
          width: '340px',
        }}
      >
        <Content
          alignSelf="start"
          UNSAFE_style={{
            width: 'var(--spectrum-global-dimension-size-3600)',
          }}
        >
          <Flex marginBottom="size-250">
            <Avatar member={authenticatedUser} size="L" useDefault={false} />
            <View
              marginStart="size-150"
              UNSAFE_style={{
                overflowWrap: 'anywhere',
              }}
            >
              <Text
                data-testid="user-profile-name"
                UNSAFE_style={{
                  color: 'var(--spectrum-global-color-gray-900)',
                  fontSize: 'var(--spectrum-global-dimension-font-size-500)',
                  fontWeight: 'bold',
                }}
              >
                {displayName}
              </Text>
              <Flex direction="column">
                <Text
                  data-testid="user-profile-email"
                  UNSAFE_style={{
                    color: 'var(--spectrum-global-color-gray-700)',
                  }}
                >
                  {displayEmail}
                </Text>
                {activeRole && (
                  <Text
                    data-testid="active-role"
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-700)',
                    }}
                  >
                    {activeRole}
                  </Text>
                )}
                <GoUrl marginTop="size-100" name="aac_acctmgmt" variant="secondary">
                  <Text
                    data-testid="account-type"
                    UNSAFE_style={{
                      color: 'var(--spectrum-global-color-gray-800)',
                    }}
                  >
                    {userTypeContent}
                  </Text>
                </GoUrl>
              </Flex>
            </View>
          </Flex>
          <Divider size="S" />
          <ActionButton data-testid="sign-out" isQuiet marginY="size-100" onPress={onSignOut}>
            <Flex
              UNSAFE_style={{
                fontSize: 'var(--spectrum-global-dimension-font-size-200)',
                width: '275px',
              }}
            >
              {intl.formatMessage({
                id: 'binky.user.authenticatedUserPulldown.signOut',
              })}
            </Flex>
          </ActionButton>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

AuthenticatedUserPullDown.propTypes = {
  /**
   * The active role of the user
   * eg: 'Admin'
   */
  activeRole: PropTypes.string,
  /**
   * The Authenticated User Object
   */
  authenticatedUser: PropTypes.instanceOf(AuthenticatedUser).isRequired,
  /**
   * The vertical offset value specified between trigger icon and dialog container
   * Default is set to 6
   */
  offSet: PropTypes.number,
  /**
   * The callback function to sign out of user account
   */
  onSignOut: PropTypes.func.isRequired,
};

export default AuthenticatedUserPullDown;
