(function () {
  /**
   * @ngdoc module
   * @name binky.core.extended-user-profile
   * @description Module which defines user-profile-related models
   */
  angular.module('binky.core.extended-user-profile', [
    'binky.core.api.jil',
    'binky.core.authentication.ready',
    'binky.core.common.member',
    'binky.core.common.model.cache',
    'binky.core.lodash',
  ]);
})();
