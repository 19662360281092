(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.core.contact').constant('CONTACT_TYPE', {
    DATA_ACCESS_ADMINISTRATOR: 'dataAccessAdministrator',
    DATA_PROTECTION_OFFICER: 'dataProtectionOfficer',
    SECURITY_OFFICER: 'securityOfficer',
  });
})();
