(function () {
  /**
   * @ngdoc module
   * @name binky.core.fulfillment-events
   * @description module that defines the fulfillment event and fulfillment
   *  event list models.
   */
  angular.module('binky.core.fulfillment-events', [
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.feature',
    'binky.core.fulfillment-needed',
    'binky.core.lodash',
    'binky.core.object-utils',
    'binky.core.offers',
    'binky.core.product.fulfillable-item-list',
    'binky.core.promise-utils',
    'binky.core.src2-migration',
  ]);
})();
