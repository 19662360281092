(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.insights.item:InsightsItem
   * @description Model for a insights item
   */
  angular.module('binky.core.insights.item').factory('InsightsItem', getInsightsItemModel);

  /* @ngInject */
  function getInsightsItemModel($q, _, reportBroker) {
    class InsightsItem {
      /**
       * @description Creates a new InsightsItem for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Object} options.access the access object of the insights item
       * @param {Object} options.actions the actions object of the insights item
       * @param {String} options.description the description of the insights item
       * @param {String} options.descriptionKey the description key of the insights item
       * @param {Object} options.details the details of the insights item
       * @param {String} options.id the id of the insights item
       * @param {String} options.insightsSection the insights section of the insights item
       * @param {String} options.name the name of the insights item
       * @param {String} options.nameKey the name key of the insights item
       * @param {String} options.primaryAction the primary action of the insights item
       * @param {String} options.primaryActionDescription the primary action description of the insights item
       * @param {String} options.primaryActionDescriptionKey the primary action description key of the insights item
       * @param {Array} options.tabs the tabs of the insights item
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      /**
       * @description Method to determine if insights item tabs contains the passed in tab name
       *
       * @param {INSIGHTS_ITEM_DETAIL_TAB} tabName the name of the tab. view or files
       *
       * @returns {Boolean} true if the array of tabs contains the passed in tab name
       */
      hasTab(tabName) {
        return _.some(this.tabs, {id: tabName});
      }

      /**
       * @description Method to return the current unique key for this insights item.
       *
       * @returns {String} key to uniquely identify this object
       */
      key() {
        return this.id;
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        return {
          id: this.id,
        };
      }

      /**
       * @class
       * @description Transforms the /organizations InsightsItem response Object into a new
       *   InsightsItem instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new InsightsItem(dataTransferObject);
      }

      /**
       * @description Method to fetch the InsightsItem.
       *
       * @param {Object} options - configuration options for list retrieval
       * @param {String} options.type - type of the insights item. Ex: assetActivity
       * @param {String} options.orgId - the org id to fetch state for
       *
       * @returns {InsightsItem} singleton InsightsItem.
       */
      static get(options) {
        const {orgId, type} = options;
        return reportBroker.insightsItem
          .query(_.omitBy({orgId, types: [type]}, _.isUndefined))
          .$promise.then((response) => {
            if (response.length > 0) {
              return new InsightsItem(response[0]);
            }
            return undefined;
          })
          .catch($q.reject);
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes Insights Item data.
     *
     * @param {Object} model InsightsItem model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick([
            'access',
            'actions',
            'description',
            'descriptionKey',
            'details',
            'id',
            'insightsSection',
            'name',
            'nameKey',
            'primaryAction',
            'primaryActionDescription',
            'primaryActionDescriptionKey',
            'tabs',
          ])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      // setup model as already resolved which ensures it can be used
      // interchangably when fetched from the insights-item list or instantiated
      // independently
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    return InsightsItem;
  }
})();
