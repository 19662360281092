import jilApplicationIntegrations from 'api/jil/jilApplicationIntegrations';
import AlphaListState from 'models/listState/alpha/AlphaListState';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import AppIntegration from './AppIntegration';
import {APP_INTEGRATION_LIST_CACHE_ID} from './appIntegrationConstants';

class AppIntegrationList extends JilModelList {
  /**
   * @description Method to create a new instance of a AppIntegrationList.
   *
   * @param {Object} options - options passed to the AppIntegrationList constructor
   * @param {Boolean} options.hasPolicies - true if we are getting policies of app integrations
   */
  constructor(options) {
    super({
      isCacheable: true,
      itemClassRef: AppIntegration,
      itemId: 'clientIds',
      modelCacheId: APP_INTEGRATION_LIST_CACHE_ID,
      resource: jilApplicationIntegrations.getApplicationIntegrations,
      state: new AlphaListState({cacheNextTokens: true}),
      ...options,
    });
    Object.assign(this, {
      hasAcceptedUsers: options.hasAcceptedUsers,
      hasPolicies: options.hasPolicies,
      orgId: options.orgId,
    });
  }

  /**
   * @description Method to refresh AppIntegrationList from back-end.
   * @returns {Promise<AppIntegrationList>} resolves to AppIntegrationList on success, else rejects with error
   */
  async refresh() {
    try {
      await super.refresh(
        {
          has_accepted_users: this.hasAcceptedUsers,
          has_policies: this.hasPolicies,
          orgId: this.orgId,
        },
        {
          onSuccessPostTransform: () => {
            this.items.forEach((appIntegration) => {
              // Assign orgId to individual app integrations since JIL does not provide that
              Object.assign(appIntegration, {orgId: this.orgId});
            });
          },
        }
      );
    } catch (error) {
      log.error('AppIntegrationList.refresh() failed. Error:', error);
      return Promise.reject(error);
    }
    return this;
  }
}

export default AppIntegrationList;
