import {Member} from '@admin-tribe/binky';
import {Flex, Link, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'common/components/avatar/Avatar';
import NavigationAnchor from 'common/components/navigation-anchor/NavigationAnchor';
import SyncIconActionButton from 'common/components/sync-icon-action-button/SyncIconActionButton';
import TableIconNameCell from 'common/components/table-icon-name-cell/TableIconNameCell';

/**
 * The contents of the NAME Cell for the MemberListTable or any other table that uses
 * a Member derived class to display an optional Avatar, a displayName which
 * may be either a Link or Text followed by a Sync icon if the memeber
 * is externallyManaged.
 */
const MemberNameCell = ({
  canViewMemberDetails = () => false,
  displayName,
  getDisplayNameHref,
  intl,
  member,
  showAvatar = true,
}) => {
  const memberType = member.getType();
  const showLink = !!(canViewMemberDetails?.(member) && getDisplayNameHref);
  const showUserGroup = showLink && memberType.isUserGroup();
  const showUser = showLink && memberType.isUser() && !memberType.isTechnicalAccount();

  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- keep it here
  function renderUser() {
    // Must manually hide overflow & truncate if cell contents are wrapped in Flex.
    return (
      <Flex alignItems="center">
        <Link UNSAFE_style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
          <NavigationAnchor href={getDisplayNameHref(member)}>{displayName}</NavigationAnchor>
        </Link>
        {member.externallyManaged && <SyncIconActionButton />}
      </Flex>
    );
  }

  // If a user group show the link to the user group and another line with the count of users in the group.
  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- keep it here
  function renderUserGroup() {
    return (
      <Flex direction="column">
        {renderUser()}
        <Text>
          {intl.formatMessage(
            {
              id: 'binky.common.memberNameCell.userCount',
            },
            {count: member.userCount}
          )}
        </Text>
      </Flex>
    );
  }

  return (
    // Avatar figures out the correct one to show for the member type.
    <TableIconNameCell IconComponent={showAvatar && <Avatar member={member} />}>
      {(showUser && renderUser()) || (showUserGroup && renderUserGroup()) || (
        <Text>{displayName}</Text>
      )}
    </TableIconNameCell>
  );
};

MemberNameCell.propTypes = {
  /**
   * Function whoose boolean result determines whether or not the name is linked to
   * the appropriate detail page for the type of member.
   * If not provided the assumption is that details can not be viewed.
   * If this returns true then onPressName is required.
   */
  canViewMemberDetails: PropTypes.func,
  /**
   * The name to use for display.
   * For Users/Admins the 'getMemberDisplayName' util is typically used.
   * If possible, display the firstName/lastName in the appropriate order with a fallback to email.
   * For technical accounts the default name is preferred over email.
   */
  displayName: PropTypes.string.isRequired,
  /**
   * Callback function to generate the href string for the name <a> link.
   * There is one param which will be the member.
   * This is required if canViewMemberDetails returns true.
   */
  getDisplayNameHref: PropTypes.func,
  /**
   * The intl object.
   */
  intl: PropTypes.instanceOf(Object).isRequired,
  /**
   * The member class object.
   */
  member: PropTypes.instanceOf(Member).isRequired,
  /**
   * True to show the avatar before the name. The default is true.
   */
  showAvatar: PropTypes.bool,
};

export default MemberNameCell;
