import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

// Private Context for the ModalDialog and its sub-components.
// Used to pass internal values like the modal ID between sub-components.

const ModalDialogContext = React.createContext(null);
const useModalDialogContext = () => React.useContext(ModalDialogContext);

const ModalDialogContextProvider = ({children, id, modalRef, setModalDescribedBy, styleProps}) => {
  const value = useMemo(
    () => ({id, modalRef, setModalDescribedBy, styleProps}),
    [id, modalRef, setModalDescribedBy, styleProps]
  );
  return <ModalDialogContext.Provider value={value}>{children}</ModalDialogContext.Provider>;
};

ModalDialogContextProvider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- useRef object
  modalRef: PropTypes.object.isRequired,
  setModalDescribedBy: PropTypes.func,
  styleProps: PropTypes.shape({
    spaceX: PropTypes.string.isRequired,
  }),
};

export {ModalDialogContextProvider, useModalDialogContext};
