import {navBus} from '@admin-tribe/binky';
import {useCallback, useMemo} from 'react';
import {matchPath, useLocation} from 'react-router-dom';

const findTabKeyByPath = (tabs, location) => {
  const currentTab = tabs.find((tab) => matchPath(location.pathname, tab.pathname));
  if (currentTab) return currentTab.key;

  // no tab matched the path, so we try to
  // redirect to the default tab if we have one
  const defaultTab = tabs.find((tab) => tab.default);
  if (defaultTab) return defaultTab.key;

  // we couldn't find a tab, so we throw an error
  throw new Error('No tab found for current location');
};

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

/**
 * @typedef {Object} NavigationTab
 * @property {String} key - unique identifier for the tab
 * @property {String} pathname - react-router path for the tab
 * @property {Boolean} default - whether this tab should be selected by default or not
 */

/**
 * Hook that aids with tab-based navigation using react-router -- keep browser URL and selected tab in sync.
 *
 * @param {Array<NavigationTab>} tabs - array of tabs to navigate between. Each tab should have a unique key and pathname. The pathname should be a valid react-router path.
 * @returns {{onTabChange: function, selectedTabKey: String}} - props to pass to a controlled react-spectrum Tabs component.
 */
const useTabbedNavigation = (tabs) => {
  const location = useLocation();

  const selectedTabKey = useMemo(() => findTabKeyByPath(tabs, location), [tabs, location]);

  const onTabChange = useCallback(
    (key) => {
      // if key same as currently selected tab, don't navigate
      if (key === selectedTabKey) {
        return;
      }

      // changing url causes selectedTabKey to be recalculated
      navBus.pushState({url: findTabByKey(tabs, key).pathname});
    },
    [selectedTabKey, tabs]
  );

  return {onTabChange, selectedTabKey};
};

export default useTabbedNavigation;
