(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   *
   * @ngdoc component
   * @name binky.widgets.omni-tool.fakes-panel:binkyOmniToolFakesPanel
   *
   * @description the fakes panel for the omni tool drawer
   *
   * @example <binky-omni-tool-fakes-panel></binky-omni-tool-fakes-panel>
   *
   */
  angular.module('binky.widgets.omni-tool.fakes-panel').component('binkyOmniToolFakesPanel', {
    controller,
    templateUrl: 'widgets/omni-tool/fakes-panel/omni-tool-fakes-panel.component.html',
  });

  /* @ngInject */
  function controller($window, _, omniToolPanelManager, OMNI_TOOL_FAKES_PANEL) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      omniToolPanelManager.registerPanel(OMNI_TOOL_FAKES_PANEL.ID);
      vm.fakesData = $window.fakes;
    }
  }
})();
