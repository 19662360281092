const FULFILLMENT_EVENT_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.fulfillmentEvent.FULFILLMENT_EVENT_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/models/fulfillmentEvent/FulfillmentEventConstants.js
   */
  angular
    .module('binky.core.fulfillment-needed')
    .constant('FULFILLMENT_REQUEST_TYPE', FULFILLMENT_EVENT_CONSTANTS.FULFILLMENT_NEEDED_TYPE);
})();
