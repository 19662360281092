import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import jilPasswordPolicy from '../../api/jil/jilPasswordPolicy';
import log from '../log';

class PasswordPolicy {
  /**
   * @description Static method to fetch the password policy for a given org.
   * @param {string} orgId - Org id that this password policy belongs to.
   * @returns {PasswordPolicy} Resolved password policy.
   */
  static get(orgId) {
    const model = new PasswordPolicy({orgId});
    return model.refresh();
  }

  /**
   * @constructs PasswordPolicy
   * @description Constructor for a PasswordPolicy model object.
   * @param {Object} options - Top level wrapper object.
   * @param {string} options.orgId - Org id
   */
  constructor(options) {
    Object.assign(this, pick(options, ['orgId']));
  }

  /**
   * @description Method to determine if there are any unsaved changes to this object.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return !isEqual(this.savedState, pick(this, ['passwordPolicy']));
  }

  /**
   * @description Method to fetch the password policy of an org.
   * @returns {PasswordPolicy} Resolved password policy.
   */
  async refresh() {
    try {
      const response = await jilPasswordPolicy.getPasswordPolicy({orgId: this.orgId});
      this.passwordPolicy = response.passwordPolicy;
      this.registerSavedState();
    } catch (error) {
      log.error('PasswordPolicy.refresh(): Unable to retrieve model from back-end. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to store the current password policy to be used for checking hasUnsavedChanges
   */
  registerSavedState() {
    this.savedState = pick(this, ['passwordPolicy']);
  }

  /**
   * @description Method to update the password policy for an org.
   * @param {string} policy Updated password policy to assign for the org.
   * @returns {PasswordPolicy} Updated password policy.
   */
  async save(policy) {
    try {
      const response = await jilPasswordPolicy.putPasswordPolicy({
        orgId: this.orgId,
        passwordPolicy: policy,
      });
      this.passwordPolicy = response.passwordPolicy;
      this.registerSavedState();
    } catch (error) {
      log.error(
        'PasswordPolicy.save(): An error occurred updating the password policy in the back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
    return this;
  }
}

export default PasswordPolicy;
