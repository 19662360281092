import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Organizations Contacts APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organizations Contacts api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches the contact resource for a particular
 *     organization with given org id.
 * @param {String} orgId - The associated org Id
 */
const getContacts = ({orgId}) =>
  axios.get(`${url}/v2/organizations/${orgId}/contacts`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Updates contacts list
 *
 * @param {String} orgId - The org id
 * @param {Object} contact - The contacts list object
 */
const putContacts = ({orgId}, contact) =>
  axios.put(`${url}/v2/organizations/${orgId}/contacts`, contact, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilContacts = {configure, getContacts, putContacts};
export default jilContacts;
