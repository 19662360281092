(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/bumper/Bumper.jsx
   */
  angular.module('binky.shell.bumper').constant('BUMPER_TYPE', {
    CUSTOM: 'custom',
    DEFAULT: 'default',
  });
})();
