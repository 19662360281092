import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './BulletList.pcss';

/* A list of items preformatted using spectrum icons as bullet points */
const BulletList = ({children}) => (
  <View
    elementType="ul"
    margin="size-0"
    padding="size-0"
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
    UNSAFE_className={styles['bullet-list']}
  >
    {children}
  </View>
);

BulletList.propTypes = {
  children: PropTypes.node,
};

export default BulletList;
