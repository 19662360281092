import pick from 'lodash/pick';

import jilUsers from 'api/jil/jilUsers';

class UserMemberships {
  /**
   * Method for getting a user group membership object and loading the data
   *
   * @param {Object} options - options to pass to the user group memberships constructor
   * @param {String} options.orgId - id of the org
   * @param {String} options.id - id of the user
   * @returns {UserMemberships} org - org object
   */
  static get(options) {
    const model = new UserMemberships(options);
    model.refresh();
    return model;
  }

  /**
   * Constructor
   *
   * @param {Object} options - options to pass to the user group memberships constructor
   * @param {String} options.orgId - id of the org
   * @param {String} options.id - id of the user
   */
  constructor(options) {
    initModel(this, options);
  }

  /**
   * Method to fetch the user group memberships data
   *
   * @returns {Promise} promise - resolved with the instance
   */
  async refresh() {
    const response = await jilUsers.getGroupMemberships({
      orgId: this.orgId,
      userId: this.id,
    });
    initModel(this, response.data);
    return this;
  }
}

function initModel(model, options) {
  Object.assign(model, pick(options, ['id', 'licenseGroups', 'orgId', 'userGroups']));
  return model;
}

export default UserMemberships;
