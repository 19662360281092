(function () {
  /**
   * @ngdoc module
   * @name binky.core.page-context
   * @description defines the page context model
   */
  angular.module('binky.core.page-context', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
