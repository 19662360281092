(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.products.product-accordion-item:binkyProductAccordionItem
   *
   * @description A specialized accordion widget item for products.
   *   The content and header content can be transcluded.
   *
   * @binding {Object} icons. The icons object returned as part of the product or product service response.
   * @binding {String} iconTitle. The icon title.
   * @binding {String} productName. The name of the product being displayed in the header
   */
  angular
    .module('binky.widgets.products.product-accordion-item')
    .component('binkyProductAccordionItem', {
      bindings: {
        icons: '<',
        iconTitle: '@',
        productName: '@',
      },
      controller,
      templateUrl: 'widgets/products/product-accordion-item/product-accordion-item.component.html',
      transclude: {
        'accordion-expanded-content': '?accordionExpandedContent',
        'accordion-header-content': '?accordionHeaderContent',
        'accordion-header-tags': '?accordionHeaderTags', // optional inline tags displayed following product name
      },
    });

  /* @ngInject */
  function controller($element, $transclude, _) {
    const vm = this;

    const transcludeLabelAndContent = $transclude.isSlotFilled('accordion-expanded-content');

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      if (transcludeLabelAndContent) {
        Coral.commons.ready(() => {
          appendTranscludedElements();
        });
      }
    }

    ////////////

    // Manually transclude coral elements to get around issues transcluding into a Coral accordion similar to Coral modals
    function appendTranscludedElements() {
      const labelInsertionPoint = $element[0].querySelector('coral-accordion-item-label');
      labelInsertionPoint.appendChild($element[0].querySelector('.item-header'));

      const contentInsertionPoint = $element[0].querySelector('coral-accordion-item-content');
      contentInsertionPoint.appendChild($element[0].querySelector('.expanded-content'));
    }
  }
})();
