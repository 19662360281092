import pick from 'lodash/pick';

import ConsumableSummaryList from './ConsumableSummaryList';

class ConsumableSummarization {
  /**
   * @description Transforms the consumable summary response object into a new
   *     ConsumableSummary instance. See constructor for params.
   */
  static apiResponseTransformer(dataTransferObject, parentSummarizationList) {
    return new ConsumableSummarization(dataTransferObject, parentSummarizationList);
  }

  /**
   * @description Creates a new ConsumableSummarization instance.
   *
   * @param {Object} options - initialization object
   * @param {string} options.organizationId - the id of the organization to
   *     which this summarization pertains
   * @param {string} options.contractId - the id of the contract to which this
   *     summarization pertains
   * @param {string} [options.summarizedBy] - dimension being summarized, such
   *     as "license_id" or "order_id"; if missing, fulfillable_item_code is
   *     assumed
   * @param {ConsumableSummary[]} options.summaries - array of ConsumableSummary
   *     elements, one for each "value" in the dimension (e.g., if summarize_by
   *     is license_id, there would be one summary for each license)
   * @param {ConsumableSummarizationList} parentSummarizationList - the parent
   *     list for this summarization
   */
  constructor(options, parentSummarizationList) {
    Object.assign(this, pick(options, ['contractId', 'organizationId', 'summarizedBy']));

    this.parentSummarizationList = parentSummarizationList;

    // We pass additional options (many from the parent summarization list
    // query params) to the ConsumableSummaryList constructor, so it can provide
    // them down the chain as needed (mostly needed by ConsumableUsageList to
    // construct its own relevant query params)
    this.summaryList = new ConsumableSummaryList(options.summaries, this);
  }

  /**
   * @description Find any Consumable objects that are for the specified
   *     fulfillable item code
   *
   * @param {string} fiCode - the fulfillable item code to find summaries for
   *
   * @returns {Consumable[]} the Consumables that are for the given fulfillable
   *     item code
   */
  getConsumablesForFulfillableItemCode(fiCode) {
    return this.summaryList.getConsumablesForFulfillableItemCode(fiCode);
  }

  /**
   * @description Find any ConsumableSummary objects for the specified ID
   *
   * @param {string} id - the ID for which to find summaries
   *
   * @returns {ConsumableSummary[]} the Summaries that are for the ID
   */
  getSummariesForId(id) {
    return this.summaryList.getSummariesForId(id);
  }

  /**
   * @description Get the top level query params from the parent summarization
   *     list, and add specific params for this summarization (contract id, org id)
   *
   * @returns {Object} Object of the parameters used to query for this summarization
   */
  getTopLevelQueryParams() {
    return {
      ...pick(this, ['contractId', 'organizationId', 'summarizedBy']),
      ...pick(this.parentSummarizationList, [
        'fulfillment_id',
        'group_id',
        'include_depleted',
        'include_expired',
        'license_id',
      ]),
    };
  }
}

export default ConsumableSummarization;
