import {DATA_POINT_TYPES} from '@admin-tribe/binky';

/**
 * @description Helper to get default selected duration id in picker
 * @param {Number} currentTimeInEpochSecs - Current time in seconds since epoch
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object[]} timePeriodsResponse - Array of time period objects
 * @returns {String} the default selected id of the duration in the picker
 */
function getDefaultSelectedDurationId(currentTimeInEpochSecs, intl, timePeriodsResponse) {
  return transformPeriodsData(currentTimeInEpochSecs, intl, timePeriodsResponse)[0].id;
}

/**
 * @description Helper to get duration info from id passed to the picker component
 * @param {String} durationId - id passed for a duration to the picker component
 * @returns {Object} Object containing startDate, endDate and selectedPeriodType info
 */
function getDurationInfoFromId(durationId) {
  const splitArr = durationId.split('-');
  const startDate = Number(splitArr[0]);
  const endDate = Number(splitArr[1]);
  let selectedPeriodType;
  if (splitArr[2] === 'year') {
    selectedPeriodType = DATA_POINT_TYPES.ANNUAL;
  } else {
    selectedPeriodType = DATA_POINT_TYPES.MONTHLY;
  }

  return {endDate, selectedPeriodType, startDate};
}

/**
 * @description Helper to construct period info for the Picker component
 * @param {Number} currentTimeInEpochSecs - Current time in seconds since epoch
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object[]} timePeriodsResponse - Array of objects each representing annual contract duration info
 * Each such object has the following fields:
 * - startDate(Number): start date of contract year in seconds since epoch
 * - endDate(Number): end date of contract year in seconds since epoch
 * - dataPoints(Object[]): array of objects representing info of monthly contract durations
 *   Each such monthly duration object contains the following fields:
 *   - startDate(Number): start date of contract month in seconds since epoch
 *   - endDate(Number): end date of contract month in seconds since epoch
 *
 * @returns {Object[]} Array of durations with localized date info selectable by the user
 * This transformation can be easily understood with an example
 * Let's say the contract begins on June 4, 2019 and ends on June 4, 2023.
 * There are 4 contract years: 2019-20, 2020-21, 2021-22 and 2022-23.
 * Let's say that the current date is 18 October, 2021, then the timePeriodsResponse would not contain
 * info for the future annual duration 2022-23. It will only contain info for past and current.
 * The durations selectable in the above case for the user(english locale) would be in the following order:
 *
 * Year 3 - Year to date
 * — Month 5 (Oct 4, 2021 - Nov 3, 2021)
 * ....options for 4 other monthly durations of current year
 * — Month 1 (Jun 4, 2021 - Jul 3, 2021)
 * - Year 2 (Jun 4, 2020 - Jun 3, 2021)
 * - Year 1 (Jun 4, 2019 - Jun 3, 2020)
 *
 * As mentioned in the above example, monthly durations are only shown for the current contract year.
 */
function transformPeriodsData(currentTimeInEpochSecs, intl, timePeriodsResponse) {
  return timePeriodsResponse
    .flatMap((timePeriod, idx) => {
      let dropDownTextArr = [];
      const yearIndex = idx + 1;

      const yearStart = getDateStringFromEpoch(timePeriod.startDate, intl);
      const yearEnd = getDateStringFromEpoch(timePeriod.endDate, intl);
      if (
        currentTimeInEpochSecs >= timePeriod.startDate &&
        currentTimeInEpochSecs <= timePeriod.endDate
      ) {
        dropDownTextArr = timePeriod.dataPoints.reduce((arr, dataPoint, index) => {
          const monthIndex = index + 1;
          const monthStart = getDateStringFromEpoch(dataPoint.startDate, intl);
          const monthEnd = getDateStringFromEpoch(dataPoint.endDate, intl);
          arr.push({
            id: `${dataPoint.startDate}-${dataPoint.endDate}-month`,
            text: intl.formatMessage(
              {id: `binky.common.etlaUsageReport.currentYearPeriod.month.text`},
              {
                count: `${monthIndex}`,
                monthEnd: `${monthEnd}`,
                monthStart: `${monthStart}`,
              }
            ),
          });
          return arr;
        }, []);
        dropDownTextArr.push({
          id: `${timePeriod.startDate}-${timePeriod.endDate}-year`,
          text: intl.formatMessage(
            {id: `binky.common.etlaUsageReport.currentYearPeriod.year.text`},
            {
              yearCount: idx + 1,
            }
          ),
        });
      } else {
        dropDownTextArr.push({
          id: `${timePeriod.startDate}-${timePeriod.endDate}-year`,
          text: intl.formatMessage(
            {id: `binky.common.etlaUsageReport.nonCurrentYearPeriod.year.text`},
            {
              count: `${yearIndex}`,
              yearEnd: `${yearEnd}`,
              yearStart: `${yearStart}`,
            }
          ),
        });
      }
      return dropDownTextArr;
    })
    .reverse();
}

/**
 * @description Helper to get formatted date string
 * @param {Number} epoch - time since epoch in seconds
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @returns {String} string containing formatted date
 */
function getDateStringFromEpoch(epoch, intl) {
  const options = {day: 'numeric', month: 'short', timeZone: 'UTC', year: 'numeric'};
  return intl.formatDate(new Date(epoch * 1000), options);
}

export {
  getDateStringFromEpoch,
  getDefaultSelectedDurationId,
  getDurationInfoFromId,
  transformPeriodsData,
};
