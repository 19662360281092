(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.confirmation-modal
   * @description module that defines confirmation-modal widget
   */
  angular.module('binky.widgets.common.confirmation-modal', [
    'binky.shell.panels',
    'binky.shell.panels.modal',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'pascalprecht.translate',
  ]);
})();
