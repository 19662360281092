(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.domains.domain-list-table
   * @description module that defines the domain list table widget
   */
  angular.module('binky.widgets.domains.domain-list-table', [
    'binky.core.lodash',
    'binky.widgets.common.table-checkbox',
  ]);
})();
