import {Flex, View} from '@adobe/react-spectrum';
import CloseIcon from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PillTagList from 'common/components/pill/PillTagList';
import TooltipButton from 'common/components/tooltip-button/TooltipButton';
import TruncatedText from 'common/components/truncated-text/TruncatedText';

import styles from './Pill.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-mini-cards
 */

/**
 * Pill displays a label that can contain links and can be removed from view.
 */
const Pill = ({
  children,
  closeButtonAriaLabel,
  closeButtonTooltipText = '',
  header,
  IconComponent,
  iconVariant = 'default',
  isDisabled = false,
  onDismiss,
  pillWidth = '336px',
  secondaryButton,
  subtitle,
  tags,
}) => {
  const intl = useIntl();

  const buttonAriaLabel =
    closeButtonAriaLabel ||
    intl.formatMessage({id: 'binky.common.pill.button.ariaLabel'}, {item: header});

  const renderIconComponent = () => (
    <View
      backgroundColor={iconVariant && 'gray-300'}
      borderRadius={iconVariant === 'square' ? 'regular' : 'large'}
      data-testid="pill-icon"
      flexGrow={0}
      flexShrink={0}
      marginStart="size-200"
    >
      <Flex alignItems="center" height="size-400" justifyContent="center" width="size-400">
        {IconComponent}
      </Flex>
    </View>
  );

  return (
    <View
      backgroundColor={isDisabled ? 'gray-200' : 'gray-50'}
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="pill"
      width={pillWidth}
    >
      <Flex wrap="wrap">
        <View flexBasis="100%">
          <Flex alignItems="center">
            {IconComponent && renderIconComponent()}
            <View flexGrow={1} minHeight="size-700">
              <Flex alignItems="center" minHeight="size-700">
                <View
                  flexGrow={1}
                  marginBottom="size-150"
                  marginStart="size-200"
                  marginTop="size-150"
                  /* needed for text-wrap to function correctly */ width={0}
                >
                  <span data-testid="pill-header">
                    <TruncatedText textClassName={styles.header}>{header}</TruncatedText>
                  </span>
                  {subtitle && (
                    <span data-testid="pill-subtitle">
                      <TruncatedText textClassName={styles.subtitle}>{subtitle}</TruncatedText>
                    </span>
                  )}
                </View>
                <View
                  data-testid="pill-buttons"
                  marginBottom="size-125"
                  marginEnd="size-50"
                  marginStart="size-50"
                  marginTop="size-125"
                >
                  <Flex>
                    {secondaryButton}
                    {onDismiss && (
                      <span data-testid="pill-dismiss-button">
                        <TooltipButton
                          buttonAriaLabel={buttonAriaLabel}
                          hoverText={closeButtonTooltipText}
                          isDisabled={isDisabled}
                          isQuiet
                          onClick={onDismiss}
                          variant="action"
                        >
                          <CloseIcon size="XS" />
                        </TooltipButton>
                      </span>
                    )}
                  </Flex>
                </View>
              </Flex>
            </View>
          </Flex>
        </View>
        {(tags || children) && (
          <View
            flexBasis="100%"
            marginStart="size-800"
            marginTop="-16px" /* shift up to where a subtitle would be */
            paddingBottom="size-150"
            paddingEnd="size-100" /* width needs to be 0 for flex to calculate width correctly */
            width={0}
          >
            {tags && <PillTagList tags={tags} />}
            <Flex data-testid="pill-children" direction="column" flexBasis="100%" wrap="wrap">
              {children}
            </Flex>
          </View>
        )}
      </Flex>
    </View>
  );
};

Pill.propTypes = {
  /**
   * The content to display in the Pill.
   */
  children: PropTypes.node,
  /**
   * The arial label for the close button.
   */
  closeButtonAriaLabel: PropTypes.string,
  /**
   * The tooltip text to display for the Pill's close button.
   */
  closeButtonTooltipText: PropTypes.string,
  /**
   * Header of the Pill.
   */
  header: PropTypes.string.isRequired,
  /**
   * IconComponent to display in the Pill.
   */
  IconComponent: PropTypes.node,
  /**
   * Variant type of the icon. One of: 'circle', 'default', 'square'. Defaults to 'default'.
   */
  iconVariant: PropTypes.oneOf(['circle', 'default', 'square']),
  /**
   * Whether the Pill is disabled. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The handler to be called when close button is clicked.
   */
  onDismiss: PropTypes.func,
  /**
   * The pill width. Defaults to 336px.
   */
  pillWidth: PropTypes.string,
  /**
   * The secondary button to display on the Pill.
   */
  secondaryButton: PropTypes.node,
  /**
   * The subtitle information to be displayed in the Pill.
   */
  subtitle: PropTypes.string,
  /**
   * An optional list of tag descriptors. Tags are displayed under the title and subtitle.
   * Each descriptor contains a unique key for the tag and the visible label. Default is undefined.
   */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default Pill;
