(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.wizard
   * @description module that defines wizard widgets
   */
  angular.module('binky.widgets.common.wizard', [
    'binky.core.lodash',
    'binky.shell.panels.modal',
    'binky.widgets.common.events',
  ]);
})();
