(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.common.selection')
    .constant('SELECTION_EVENT', {
      UPDATE: 'UpdateSelection',
    })
    .constant('SELECTION_STATE', {
      ALL: 'ALL',
      NONE: 'NONE',
      SUBSET: 'SUBSET',
    });
})();
