(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum ComboBox
   *
   * @ngdoc directive
   * @name binky.widgets.common.autocomplete:binkyAutocomplete
   *
   * @description a wrapper around the Coral autocomplete widget
   *
   * @param {String} defaultValue The default value of the autocomplete setting to.
   * @param {String} displayKey The key to use for display. Default: 'display'
   * @param {Boolean} enabled Whether the autocomplete should be enabled or disabled. Defaults to true.
   * @param {String} icon The icon of the autocomplete. Defaults to none.
   * @param {Boolean} isSorted Whether if the sorting should be enabled or disabled. Defaults to true.
   * @param {Array} items The content to display in autocomplete, have to be in "display" and "value" pair
   * @param {String} match The match mode, either "startswith", "contains", or "ignore".
   * @param {Boolean} multiple Whether this autocomplete supports multiple selection. Defaults to false.
   * @param {Function} onChange A function to call when the input for this search changes
   * @param {String} placeholder The placeholder text to display.
   * @param {Boolean} required True if the autocomplete is required in a form
   * @param {Boolean} requireItemMatch If true, onChange only returns values which match items, otherwise returns all inputs. Defaults to true.
   * @param {Array} values The intial values for the autocomplete, a list of values
   *
   * @example <binky-autocomplete items="myItems" match="startswith" on-change="myCallback"></binky-autocomplete>
   *
   *
   */
  angular.module('binky.widgets.common.autocomplete').component('binkyAutocomplete', {
    bindings: {
      defaultValue: '<?',
      displayKey: '@?',
      enabled: '<?',
      icon: '@?',
      isSorted: '<?',
      items: '<',
      match: '@',
      multiple: '<?',
      onChange: '&?',
      placeholder: '@?',
      required: '<?',
      requireItemMatch: '<?',
      values: '<?',
    },
    controller,
  });

  /* @ngInject */
  function controller($element, $timeout, _, feature) {
    let autocompleteElement, coralAutocomplete;
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    /////////////////

    function $onChanges(changes) {
      if (autocompleteElement && coralAutocomplete) {
        const defaultValue = _.get(changes, 'defaultValue.currentValue');
        if (!_.isUndefined(defaultValue)) {
          setDefaultValue();
        }
        const items = _.get(changes, 'items.currentValue');
        if (!_.isUndefined(items)) {
          setItems();
        }
        const values = _.get(changes, 'values.currentValue');
        if (!_.isUndefined(values)) {
          setValues();
        }
        const enabled = _.get(changes, 'enabled.currentValue');
        if (!_.isUndefined(enabled)) {
          setEnabled();
        }
      }
    }

    function $onInit() {
      vm.displayKey = _.get(vm, 'displayKey', 'display');
      vm.requireItemMatch = _.get(vm, 'requireItemMatch', true);

      // dynamically insert the coral autocomplete element
      coralAutocomplete = new Coral.Autocomplete();
      coralAutocomplete.set(
        _.omitBy(
          {
            icon: vm.icon,
            // we don't pass the ignore match, as it isn't supported by Coral
            match: vm.match === 'ignore' ? undefined : vm.match,
            multiple: vm.multiple,
            placeholder: vm.placeholder,
            required: vm.required,
          },
          _.isUndefined
        )
      );
      setEnabled();
      $element.append(coralAutocomplete);
      $element.prop('$coralComponent', coralAutocomplete);

      // Note we need to refind the elements, we can't use the coralAutocomplete above
      autocompleteElement = angular.element(coralAutocomplete);
      setDefaultValue();
      setItems();
      setValues();

      autocompleteElement.on('change', () => {
        $timeout(() => {
          setTagTitleText();
          // for multiple inputs, we verify all items are valid or not
          const containsInput = vm.multiple
            ? _.every(coralAutocomplete.values, (val) => _.some(vm.items, ['value', val]))
            : _.some(vm.items, ['value', coralAutocomplete.value]);
          if (containsInput || !vm.requireItemMatch) {
            autocompleteElement.removeClass('invalid');
            _.invoke(
              vm,
              'onChange',
              vm.multiple ? {values: coralAutocomplete.values} : {value: coralAutocomplete.value}
            );
          } else {
            autocompleteElement.addClass('invalid');
            _.invoke(
              vm,
              'onChange',
              vm.multiple
                ? {
                    values: _.filter(coralAutocomplete.values, (val) =>
                      _.some(vm.items, ['value', val])
                    ),
                  }
                : {value: ''}
            );
          }
        });
      });
      if (vm.match === 'ignore') {
        autocompleteElement.on('coral-autocomplete:showsuggestions', (event) => {
          event.preventDefault();
          $timeout(() => {
            setSuggestableItems();
          });
        });
      }

      function setSuggestableItems() {
        const items =
          feature.isEnabled('temp_autocomplete_is_sorted') &&
          (vm.isSorted || vm.isSorted === undefined)
            ? _(vm.items)
                .sortBy(vm.displayKey)
                // This is a hack. The coral autocomplete component duplicates items when the display and values
                // are the same string. To create a unique display string we add a Zero Width Space which
                // shouldn't show up in the UI and won't get trimmed like other types of characters (e.g. spaces).
                .map((item) => ({content: `${item[vm.displayKey]}\u200B`, value: item.value}))
                .value()
            : _.map(vm.items, (item) => ({
                content: `${item[vm.displayKey]}\u200B`,
                value: item.value,
              }));
        coralAutocomplete.addSuggestions(items, true);
      }
    }

    function setEnabled() {
      if (_.isBoolean(vm.enabled)) {
        coralAutocomplete.disabled = !vm.enabled;
      }
    }

    function setDefaultValue() {
      if (vm.defaultValue) {
        const result = _.find(vm.items, ['value', vm.defaultValue]);
        if (result) {
          coralAutocomplete.set('value', result[vm.displayKey]);
        } else if (vm.requireItemMatch) {
          autocompleteElement.addClass('invalid');
        } else {
          coralAutocomplete.set('value', vm.defaultValue);
        }
      }
    }

    function setItems() {
      if (vm.match !== 'ignore' && vm.items && !_.isEqual(vm.storedItems, vm.items)) {
        vm.storedItems = vm.items;
        const items =
          feature.isEnabled('temp_autocomplete_is_sorted') &&
          (vm.isSorted || vm.isSorted === undefined)
            ? _.sortBy(vm.items, vm.displayKey)
            : vm.items;
        coralAutocomplete.items.clear();

        _.forEach(items, (item) => {
          coralAutocomplete.items.add({
            content: {
              // This is a hack. The coral autocomplete component duplicates items when the display and values
              // are the same string. To create a unique display string we add a Zero Width Space which
              // shouldn't show up in the UI and won't get trimmed like other types of characters (e.g. spaces).
              innerText: `${item[vm.displayKey]}\u200B`,
            },
            value: item.value,
          });
        });
      }

      setDefaultValue();
    }

    function setTagTitleText() {
      const tags = autocompleteElement.find('coral-tag');
      _.forEach(tags, (tag) => {
        // Find the display value from the scope's item, not the tag's innerText. Otherwise,
        // the title text will be capitalized https://bugs.webkit.org/show_bug.cgi?id=17830
        const item = _.find(vm.items, {value: tag.value});
        if (item) {
          tag.title = item[vm.displayKey];
        }
      });
    }

    function setValues() {
      // Since values is an array of id's mapping to the items we have to make sure the items are set first.
      // Also, a common usecase is to set both the items and values at the same time. So in order to avoid the
      // race condition, delay the setting of values by one step with a timeout.
      $timeout(() => {
        coralAutocomplete.values = _.isArray(vm.values) || !vm.values ? vm.values : [vm.values];
        setTagTitleText();
      });
    }
  }
})();
