/* eslint-disable promise/prefer-await-to-callbacks, @admin-tribe/admin-tribe/check-browser-globals -- necessary for interception*/
import axios from 'axios';

const DEFAULT_NETWORK_CALL_STORED_MAX = 100;

let onNetworkCallHandlers = [];
let storedNetworkCalls = [];
let axiosRequestInterceptor, axiosResponseInterceptor, originalFetch;
let networkCallStoreMax = DEFAULT_NETWORK_CALL_STORED_MAX;

/**
 * Activate performance monitoring, this will set up listeners for Axios and Fetch calls.
 */
function activate() {
  if (!axiosRequestInterceptor) {
    axiosRequestInterceptor = axios.interceptors.request.use((config) => {
      config.metadata = {start: new Date()};
      return config;
    });
  }
  if (!axiosResponseInterceptor) {
    axiosResponseInterceptor = axios.interceptors.response.use(
      (response) => {
        storeAxiosCall(response);
        return response;
      },
      (error) => {
        storeAxiosCall(error.response);
        return Promise.reject(error);
      }
    );
  }
  if (!originalFetch) {
    originalFetch = window.fetch;
    window.fetch = async (resource, options) => {
      const start = new Date();
      const response = await originalFetch(resource, options);
      storeCall({
        method: options.method,
        requestId: response.headers?.get('x-request-id'),
        start,
        statusCode: response.status,
        url: resource.url || resource,
      });
      return response;
    };
  }
}

/**
 * Clear the cache of network calls and registered handlers.
 */
function clear() {
  onNetworkCallHandlers = [];
  storedNetworkCalls = [];
}

/**
 * Deactivate performance monitoring, removing any listeners for Axios and Fetch calls.
 */
function deactivate() {
  if (axiosRequestInterceptor) {
    axios.interceptors.request.eject(axiosRequestInterceptor);
    axiosRequestInterceptor = null;
  }
  if (axiosResponseInterceptor) {
    axios.interceptors.response.eject(axiosResponseInterceptor);
    axiosResponseInterceptor = null;
  }
  if (originalFetch) {
    window.fetch = originalFetch;
    originalFetch = null;
  }
}

/**
 * Fetch the max network call count, after which we'll clear old calls.
 */
function getNetworkCallStoreMax() {
  return networkCallStoreMax;
}

/**
 * Get the list of network calls we've recorded.
 */
function getStoredNetworkCalls() {
  return storedNetworkCalls;
}

/**
 * Register a handler which will be invoked every time a network call is recorded.
 */
function registerHandler(handler) {
  onNetworkCallHandlers.push(handler);
}

/**
 * Set the max network call count, after which we'll clear old calls.
 */
function setNetworkCallStoreMax(max) {
  networkCallStoreMax = max;
}

//
// Private methods
//

function storeAxiosCall(result) {
  storeCall({
    method: result.config.method,
    requestId: result.headers ? result.headers['x-request-id'] : null,
    start: result.config.metadata.start,
    statusCode: result.status,
    url: result.config.url,
  });
}

function storeCall({url, method, statusCode, requestId, start}) {
  const end = new Date();
  const call = {
    duration: end - start, // in ms
    end,
    method,
    requestId,
    start,
    statusCode,
    url,
  };
  storedNetworkCalls.push(call);
  onNetworkCallHandlers.forEach((handler) => handler(call));
  storedNetworkCalls = storedNetworkCalls.slice(
    Math.max(storedNetworkCalls.length - networkCallStoreMax, 0)
  );
}

const apiPerformanceInterceptor = {
  activate,
  clear,
  deactivate,
  getNetworkCallStoreMax,
  getStoredNetworkCalls,
  registerHandler,
  setNetworkCallStoreMax,
};

export default apiPerformanceInterceptor;
/* eslint-enable promise/prefer-await-to-callbacks, @admin-tribe/admin-tribe/check-browser-globals -- necessary for interception */
