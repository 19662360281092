(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilOffers
   * @description defines service to manage jil offers resources
   */
  angular.module('binky.core.api.jil').provider('jilOffers', jilOffersProvider);

  let url;

  /* @ngInject */
  function jilOffersProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/offers`;
    }

    /* @ngInject */
    function $get($resource, locale) {
      const service = {
        offers: getOffersResource(),
        offersBySku: getOffersBySkuResource(),
      };

      return service;

      ///////////

      function getOffersResource() {
        return $resource(url, {locale: locale.getActiveLocaleCode()});
      }

      function getOffersBySkuResource() {
        return $resource(`${url}\\:search.sku`, {locale: locale.getActiveLocaleCode()});
      }
    }
  }
})();
