(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name poresOrganizationsOffers
   * @description defines service to manage pores offers resources
   */
  angular
    .module('binky.core.api.pores')
    .provider('poresOrganizationsOffers', poresOrganizationsOffersProvider);

  let url;

  /* @ngInject */
  function poresOrganizationsOffersProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        getOffers: getOffersResource(),
      };

      return service;

      ///////////

      function getOffersResource() {
        return $resource(`${url}/:orgId/offers/`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
