import {View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * OmniToolFakesPanel - encapsulates data for the fakes panel
 */
const OmniToolFakesPanel = () => {
  const intl = useIntl();

  let fakesActive = false;
  if (typeof window !== 'undefined') {
    fakesActive = !!window.fakes;
  }

  let fakesMessage;
  if (fakesActive) {
    fakesMessage = intl.formatMessage({id: 'binky.common.omniTool.fakesPanel.active'});
  } else {
    fakesMessage = intl.formatMessage({id: 'binky.common.omniTool.fakesPanel.inactive'});
  }

  return <View>{fakesMessage}</View>;
};

OmniToolFakesPanel.propTypes = {};

export default OmniToolFakesPanel;
