import {FULFILLABLE_ITEM_CODE, feature} from '@admin-tribe/binky';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-administration-utils
 * Must use Pandora's Product model rather than Binky's Product model.
 * See pandoraUtils in binky for conversion helpers.
 */

/**
 * @description Method to get product display name
 *
 * @param {Object} intl - react intl
 * @param {Product} product - the product to get display name
 * @param {ConsumableSummarizationList} options.consumableSummarizationList -
 *     consumable summarization list to fetch quota
 * @param {Boolean} options.isTrial - whether the product is trial
 * @param {Boolean} options.showConsumableQuota - whether to show consumable
 *     quota
 *
 * @returns {String} display name of the product.
 */
function getProductDisplayName(
  intl,
  product,
  {consumableSummarizationList, isTrial = false, showConsumableQuota = false} = {}
) {
  if (
    feature.isEnabled('temp_parkour_mm') &&
    showConsumableQuota &&
    product?.isProductSupportRoleAssignmentAllowed()
  ) {
    return getProductDisplayNameWithConsumableQuota(consumableSummarizationList, intl, product);
  }

  if (product?.isEnterprise?.()) {
    const ccStorageQuota = product.fulfillableItemList?.getCreativeCloudStorageChargingModelCap?.();
    const esmUserStorageQuota = product.fulfillableItemList?.getESMUserStorageChargingModelCap?.();
    const quota = esmUserStorageQuota === 0 ? esmUserStorageQuota : ccStorageQuota;

    const separatorWithSpaces = intl.formatMessage({
      id: 'binky.products.productName.separatorWithSpaces',
    });

    if (quota !== undefined) {
      // storageQuota exists, so return name with storage data appended to end
      const storageQuotaStr = intl.formatMessage(
        {id: 'binky.products.productName.storageQuota'},
        {quota}
      );
      return `${product.longName}${separatorWithSpaces}${storageQuotaStr}`;
    }
    if (feature.isEnabled('temp_parkour_mm')) {
      return getProductDisplayNameWithInstanceName(intl, product);
    }
    const fulfillableEntityResourceName = product.fulfillableEntityResourceName;
    // eslint-disable-next-line no-eq-null -- isNil equivalent
    if (fulfillableEntityResourceName != null) {
      // fulfillableEntityResourceName exists, so return name with fulfillableEntityResourceName appended to end
      return `${product.longName}${separatorWithSpaces}${fulfillableEntityResourceName}`;
    }
  } else if (isTrial) {
    return intl.formatMessage({id: 'binky.products.productName.trial'}, {name: product?.longName});
  }
  // no storageQuota or fulfillableEntityResourceName listed for this Product, just return name if there
  // is one
  return product?.longName;
}

function getProductDisplayNameWithConsumableQuota(consumableSummarizationList, intl, product) {
  const consumableData = consumableSummarizationList?.getConsumableForSummaryIdAndFICode(
    FULFILLABLE_ITEM_CODE.SUPPORT_ALLOWED_ADMINS,
    product.id
  );
  const name = getProductDisplayNameWithInstanceName(intl, product);

  return consumableData
    ? intl.formatMessage(
        {id: 'binky.products.productName.consumableQuota'},
        {...consumableData, name}
      )
    : name;
}

function getProductDisplayNameWithInstanceName(intl, product) {
  const separatorWithSpaces = intl.formatMessage({
    id: 'binky.products.productName.separatorWithSpaces',
  });
  const fulfillableEntityResourceName = product.fulfillableEntityResourceName;

  // eslint-disable-next-line no-eq-null -- isNil equivalent
  if (fulfillableEntityResourceName != null) {
    // fulfillableEntityResourceName exists, so return name with fulfillableEntityResourceName appended to end
    return `${product.longName}${separatorWithSpaces}${fulfillableEntityResourceName}`;
  }
  return product.longName;
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getProductDisplayName};
