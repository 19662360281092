import Locale, {localeUtils} from 'services/locale/Locale';
import {DEFAULT_LANGUAGE, SELECTED_LOCALE_KEY} from 'services/locale/LocaleConstants';
import log from 'services/log';
import {getLocalStorageItem, setLocalStorageItem} from 'utils/storageUtils';

const supportedLanguages = localeUtils.getSupportedLanguageKeys();

/**
 * Executes the locale service by determining the best locale to use
 */
function run() {
  let locale = '';
  const overrideLocale = localeUtils.getQueryParam();

  if (overrideLocale) {
    locale = overrideLocale;
    // when a URL locale is passed we store it for future use
    setLocalStorageItem(SELECTED_LOCALE_KEY, locale);
  } else if (getLocalStorageItem(SELECTED_LOCALE_KEY)) {
    locale = getLocalStorageItem(SELECTED_LOCALE_KEY);
  }

  // Ensure requestedLocale is one of the supported ones.
  if (!supportedLanguages.includes(locale)) {
    // if it doesn't match, and the string is longer than
    // 2 characters, we try shortening it for a match
    if (locale.length > 2) {
      locale = locale.slice(0, 2);
    }
    // if it still isn't a match we fall back to the default
    if (!supportedLanguages.includes(locale)) {
      locale = DEFAULT_LANGUAGE;
    }
  }

  const loc = new Locale(locale);
  try {
    document.documentElement.lang = loc.activeLanguageBCP47Code;
  } catch (error) {
    log.error('Failed to set html lang attribute. Error: ', error);
  }
}

const localeReady = {run};

export default localeReady;
