//
// Asynchronous Language File Loader Service for Angular Translate
//
// Note that this service can not inject $locale because $locale injects $translate which would
// result in a circular dependency.
(function () {
  /**
   * @deprecated Please use src2/core/services/locale/Locale.js
   */
  angular.module('binky.core.locale').factory('localeAsyncFilesLoader', localeAsyncFilesLoader);

  /* @ngInject */
  function localeAsyncFilesLoader($http, $log, $q, DEFAULT_LANGUAGE) {
    /**
     * @description Asynchronous loader for locale string files. Used with $translate.useLoader().
     * @param {Object} options - NavItem instance settings
     * @param {String} [options.key] - the $translate language key, for example, 'en'.
     * @param {Function} [options.getStringFileUrl] - function which expects the key and returns
     *  the url to load
     * @returns {Promise} if success, resolves with url string, else rejects with key
     */
    return function (options) {
      if (!options || !options.key || !angular.isFunction(options.getStringFileUrl)) {
        return $q.reject();
      }

      const deferred = $q.defer();

      // The url will be fingerprinted by the build process so it will be of the form
      // '/str/str-{language}.{id}.json'.
      // This ensures the current version of the file is used rather than a cached version.

      const url = options.getStringFileUrl(options.key);
      $http
        .get(url)
        .then((response) => {
          $log.debug(`loaded: ${url}`);
          if (options.key === DEFAULT_LANGUAGE) {
            options.setDefaultLanguageLoaded(true);
          }
          deferred.resolve(response.data);
        })
        .catch(() => {
          deferred.reject(options.key);
        });

      return deferred.promise;
    };
  }
})();
