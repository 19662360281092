(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   */
  angular
    .module('binky.widgets.omni-tool.drawer')
    .constant('OMNI_TOOL_DRAWER_ID', 'omni-tool-drawer')
    .constant('OMNI_TOOL_VALID_KEY', '~');
})();
