import {LicenseGroup} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import PropertiesIcon from '@spectrum-icons/workflow/Properties';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import {getCustomAvatarSize} from 'common/utils/icons/iconUtils';

/**
 * The ProductProfileIcon component.
 * This component is used to wrap a Spectrum Properties icon with a shaded gray circle
 * or use a product profile's selected desktop icon if it exists.
 * The sizes are the same as those that the Avatar uses.
 */
const ProductProfileIcon = (props) => {
  const {productProfile, size = 'S', ...styleProps} = props;

  let selectedIcon, selectedIconTitle;
  if (productProfile.product?.fulfillableItemList.hasSingleDesktopApplicationConfig()) {
    selectedIcon = productProfile.getSelectedDesktopIcons();
    selectedIconTitle = productProfile.getSelectedDesktopFulfilledItem()?.longName ?? '';
  }

  const getAvatarSize = useCallback(() => getCustomAvatarSize(size), [size]);

  return (
    <>
      {selectedIcon && (
        <View {...styleProps}>
          <img
            alt={selectedIconTitle}
            height={getAvatarSize()}
            src={selectedIcon.svg}
            width={getAvatarSize()}
          />
        </View>
      )}
      {!selectedIcon && (
        <View
          {...styleProps}
          backgroundColor="gray-300"
          height={getAvatarSize()}
          UNSAFE_style={{borderRadius: '50%', display: 'inline-block'}}
          width={getAvatarSize()}
        >
          <Flex
            alignContent="center"
            alignItems="center"
            height={getAvatarSize()}
            justifyContent="center"
            width={getAvatarSize()}
          >
            <PropertiesIcon alt="" size={size} />
          </Flex>
        </View>
      )}
    </>
  );
};

ProductProfileIcon.propTypes = {
  /**
   * The profile's icon to display.
   */
  productProfile: PropTypes.instanceOf(LicenseGroup),
  /**
   * The size of the icon. Default is 'S'.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']),
};

export default ProductProfileIcon;
