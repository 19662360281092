import pick from 'lodash/pick';

import jilConsumables from 'api/jil/jilConsumables';
import modelCache from 'services/cache/modelCache/modelCache';
import {CART_EVENT} from 'services/cart/CartConstants';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';
import {ORGANIZATION_EVENT} from 'services/organization/OrganizationConstants';
import OrganizationList from 'services/organization/OrganizationList';
import {PRODUCT_LIST_EVENT} from 'services/product/ProductListConstants';

import {
  CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
} from './ConsumableConstants';
import ConsumableSummarization from './ConsumableSummarization';

class ConsumableSummarizationList extends JilModelList {
  /**
   * @description Method to fetch the ConsumableSummarizationList.
   *
   * @param {Object} [options] - see constructor for options properties
   *
   * @returns {ConsumableSummarizationList} the fetched or cached instance
   */
  static get(options) {
    const model = new ConsumableSummarizationList(options);
    const cachedModel = modelCache.get(CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID, model.getKey());
    if (cachedModel) {
      return cachedModel;
    }
    return model.refresh();
  }

  /**
   * @description Creates a new ConsumableSummarizationList instance.
   *
   * @param {Object} [options] - initialization object
   * @param {string} [options.contract_id] - when specified, filter the usage
   *     to only include usage associated with the requested contract id
   * @param {string} [options.fulfillment_id] - when specified, filter the usage
   *     useage to only include usage associated with the requested fulfillment
   *     id or ids
   * @param {string} [options.group_id] - when specified, filter the usage to
   *     only include usage associated with the specific delegation group id or ids
   * @param {boolean} [options.include_depleted=true] - whether to include
   *     consumable usage whose remaining quantity is zero
   * @param {boolean} [options.include_expired=false] - for consumables that
   *     expire, whether to include expired consumables in summary and usage
   *     details
   * @param {boolean} [options.include_usage=true] - whether or not to include
   *     individual Usage details along with the summary information
   * @param {number} [options.include_usage_limit=5] - max number of Usage
   *     details to include; server may return fewer
   * @param {ConsumableSummarization[]} [options.items]
   * @param {string} [options.license_id] - when specified, filter the usage to
   *     only include usage associated with the specific license id or ids
   * @param {string} [options.order_id] - to include in query params
   * @param {string} [options.organization_id] - id of the organization to
   *     which this summarization pertains
   * @param {string} [options.sort_by] - field name, e.g., "expiration_date"
   * @param {string} [options.summarize_by=organization_id] - dimension being
   *     summarized; one of CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY, such as
   *     "license_id" or "order_id"
   */
  constructor(options = {}) {
    super({
      cacheClearingEvents: [
        CART_EVENT.SUBMIT,
        ORGANIZATION_EVENT.UPDATE,
        PRODUCT_LIST_EVENT.REFRESH,
      ],
      isCacheable: true,
      itemClassRef: ConsumableSummarization,
      modelCacheId: CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
      resource: jilConsumables.getSummaries,
      transformResponseData: (response) => transformItems(response, this),
    });

    Object.assign(this, getQueryParams(options, {fillDefaults: true}));

    this.items = transformItems(options.items, this);
  }

  /**
   * @description Find consumable object that is for the specified
   *     fulfillable item code and summary ID
   *
   * @param {string} fiCode - the fulfillable item code for which to find Consumables
   * @param {string} summaryId - the ConsumableSummary's id ("value" of
   *     dimension this is a summary of) for which to find consumables.
   *
   * @returns {Consumable} Consumable that is for the fulfillable
   *     item code and summary ID
   */
  getConsumableForSummaryIdAndFICode(fiCode, summaryId) {
    return this.getConsumablesForSummaryId(summaryId).find(
      (consumable) => consumable.fulfillableItemCode === fiCode
    );
  }

  /**
   * @description Find any Consumable objects that are for the specified
   *     fulfillable item code
   *
   * @param {string} fiCode - the fulfillable item code for which to find Consumables
   * @param {Object} [options] - additional options for finding the Consumables
   * @param {string} [options.contract_id] - only return Consumables from
   *     within ConsumableSummarization items that have this contract_id
   *
   * @returns {Consumables[]} List of Consumables that are for the fulfillable
   *     item code
   */
  getConsumablesForFulfillableItemCode(fiCode, options) {
    const consumableSummarizations = options?.contract_id
      ? this.items.filter((item) => item.contractId === options.contract_id)
      : this.items;
    return consumableSummarizations.reduce(
      (consumables, item) => [
        ...consumables,
        ...item.getConsumablesForFulfillableItemCode(fiCode, options),
      ],
      []
    );
  }

  /**
   * @description Find any Consumable objects that are for the specified summary ID
   *
   * @param {string} summaryId - the ConsumableSummary's id ("value" of
   *     dimension this is a summary of) for which to find consumables.
   * @param {Object} [options] - additional options for finding the Consumables
   * @param {string} [options.summarize_by] - check for the dimension being
   *     summarized before returning the Consumables
   *
   * @returns {Consumable[]} List of Consumables that are for the ID
   */
  getConsumablesForSummaryId(summaryId, options) {
    return this.getSummariesForId(summaryId, options).reduce(
      (consumables, summary) => [...consumables, ...summary.consumableList.items],
      []
    );
  }

  /**
   * @description Get the unique key for caching
   *
   * @returns {string} Key based on the query params
   */
  getKey() {
    return super.getKey(getQueryParams(this));
  }

  /**
   * @description Find any ConsumableSummary objects that are for the specified ID
   *
   * @param {string} id - the ID to find summaries for
   * @param {Object} [options] - additional options for finding the Summaries
   * @param {string} [options.summarize_by] - check for the dimension being
   *     summarized before returning the Summaries
   *
   * @returns {ConsumableSummary[]} List of Summaries that are for the ID
   */
  getSummariesForId(id, options = {}) {
    if (options.summarize_by && this.summarize_by !== options.summarize_by) {
      log.warn(
        `The field summarize_by = ${options.summarize_by} does not match with
        the current dimension being summarized ${this.summarize_by}. Thus, no
        summary for the ID will be found.`
      );
      return [];
    }
    return this.items.reduce(
      (summaries, item) => [...summaries, ...item.getSummariesForId(id)],
      []
    );
  }

  /**
   * @description Method to fetch an array of ConsumableSummarizations
   *
   * @returns {Promise} Resolves with refreshed model if successful, else
   *     rejects with error message
   */
  async refresh() {
    await super.refresh(getQueryParams(this, {transformOrgId: true}));
    return this;
  }
}

//////////////

/**
 * @description Method to get the query param properties from a
 *     ConsumableSummarizationList instance or data. Query params are used for
 *     creating the model instance, for fetching/re-fetching the items, and for
 *     generating the cache key.
 *
 * @param {ConsumableSummarizationList|Object} model - an instance of a
 *     ConsumableSummarizationList, or an object that will be used to create it
 * @param {Object} [options] - options for building the query params object
 * @param {boolean} [options.transformOrgId] - whether to replace the
 *     "organization_id" key with "orgId"
 * @param {boolean} [options.fillDefaults] - whether to fill certain undefined
 *     properties with their default value
 *
 * @returns {Object} query params from the model or from default values. See
 *     constructor for descriptions of properties
 */
function getQueryParams(model, options) {
  let params = pick(model, [
    'contract_id',
    'fulfillment_id',
    'group_id',
    'include_depleted',
    'include_expired',
    'include_usage',
    'include_usage_limit',
    'license_id',
    'order_id',
    'sort_by',
    'summarize_by',
  ]);

  let orgId = model.organization_id;

  if (options?.fillDefaults) {
    params = {
      include_depleted: true,
      include_expired: false,
      include_usage: true,
      include_usage_limit: 5,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.ORGANIZATION_ID,
      ...params,
    };

    // Only fetch the org id from OrganizationList if it wasn't provided. Not
    // all users of binky set an activeOrg on OrganizationList, so we want to
    // be able to specify it in the params and NOT call for it in that case.
    if (!orgId) {
      const organizationList = OrganizationList.get();
      orgId = organizationList?.activeOrg?.id;
    }
  }

  params[options?.transformOrgId ? 'orgId' : 'organization_id'] = orgId;

  return params;
}

/**
 * @description Method to transform the list of ConsumableSummarization items
 *
 * @param {ConsumableSummarization[]} items - the raw item data
 * @param {ConsumableSummarizationList} model - the parent
 *     ConsumableSummarizationList instance
 *
 * @returns {ConsumableSummarization[]} the transformed ConsumableSummarization
 *     items, or an empty array if the item data was missing
 */
function transformItems(items, model) {
  return items
    ? items.map((item) => ConsumableSummarization.apiResponseTransformer(item, model))
    : [];
}

export default ConsumableSummarizationList;
