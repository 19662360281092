import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilDirectories from 'api/jil/jilDirectories';
import log from 'services/log';

/**
 * Head API request to get the count of the directory users.
 * This is more efficient than getting the list.
 */
class DirectoryUserListCount {
  /**
   * @description Method to retrieve an existing Directory from
   *              back-end data store.
   *
   * @param {Object} options See the construction options.
   * @returns {Promise<DirectoryUserListCount>}
   *   When the promise resolves, the totalCount properly will be an integer count or
   *   undefined if the count in the API response header could not be parsed.
   */
  static get(options) {
    const model = new DirectoryUserListCount(options);
    // Caching would be handled here
    return model.refresh();
  }

  /**
   * @class
   * @description Creates a new DirectoryUserListCount model.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.directoryId The id of the directory.
   * @param {String} options.orgId The id of the organization.
   */
  constructor({directoryId, orgId}) {
    this.directoryId = directoryId;
    this.orgId = orgId;
  }

  getQueryParams() {
    return {directoryId: this.directoryId, orgId: this.orgId};
  }

  async refresh() {
    let response;

    try {
      response = await jilDirectories.headUsers(this.getQueryParams());
    } catch (error) {
      log.error(`DirectoryUserListCount.refresh() failed. Error: ${error}`);
      return Promise.reject(error);
    }

    // Ensure totalCount to either be a Number or undefined (avoid NaN)
    this.totalCount =
      Number.parseInt(response.headers[JIL_CONSTANTS.HEADERS.TOTAL_COUNT], 10) || undefined;

    return this;
  }
}

export default DirectoryUserListCount;
