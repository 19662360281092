(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.help-search:HelpSearch
   * @description model that represents an HelpSearch
   */
  angular.module('binky.core.help-search').factory('HelpSearch', getHelpSearch);

  /* @ngInject */
  function getHelpSearch(_) {
    class HelpSearch {
      /**
       * @constructs HelpSearch
       * @description Constructor for HelpSearch model.
       * @param {Object} options - HelpSearch instance settings
       * @param {String} options.id The id of the document
       * @param {String} options.source - The url link of the document
       * @param {String} options.title - The description of the document
       */
      constructor(options) {
        initModel(this, options);
      }

      static apiResponseTransformer(response) {
        return new HelpSearch({
          id: response.asset_id,
          source: _.get(response, '_links.source.href'),
          title: response.asset_name,
        });
      }
    }

    return HelpSearch;

    /* Private functions */
    function initModel(model, options) {
      _.assignIn(model, _.pick(options, ['id', 'source', 'title']));
    }
  }
})();
