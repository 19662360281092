(function () {
  /**
   * @deprecated. Please use src2/core/services/trust/trustConstants.js
   */
  angular
    .module('binky.core.trusts')
    .constant('ACCESS_REQUESTS_EVENT', {
      UPDATE: 'UpdateAccessRequestsList',
      UPDATE_COUNT: 'UpdateAccessRequestsCount',
    })
    .constant('ACTIVE_TRUST_LIST_CACHE_ID', 'ActiveTrustList')
    .constant('DIRECTORY_TRUSTEE_EVENT', {
      UPDATE: 'UpdateDirectoryTrustee',
    })
    .constant('DIRECTORY_TRUSTEE_LIST_EVENT', {
      UPDATE: 'UpdateDirectoryTrusteeList',
      UPDATE_COUNT: 'UpdateDirectoryTrusteeCount',
    })
    .constant('PENDING_TRUST_LIST_CACHE_ID', 'PendingTrustList')
    .constant('TRUST_CACHE_ID', 'Trust')
    .constant('TRUST_LIST_CACHE_ID', 'TrustList')
    .constant('TRUST_STATUS', {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
    })
    .constant('TRUST_EVENT', {
      DELETE: 'DeleteTrust',
    })
    .constant('TRUSTEE_EVENT', {
      UPDATE: 'UpdateTrustee',
    })
    .constant('TRUSTEE_LIST_EVENT', {
      UPDATE: 'UpdateTrusteesList',
      UPDATE_COUNT: 'UpdateTrusteesCount',
    });
})();
