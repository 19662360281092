(function () {
  /**
   * @deprecated Please use src2/core/services/availableType/AvailableTypeList.js
   *
   * @ngdoc factory
   * @name AvailableTypeList
   * @description available types list model
   */
  angular
    .module('binky.core.search.available-type-list')
    .factory('AvailableTypeList', getAvailableTypeListFactory);

  /* @ngInject */
  function getAvailableTypeListFactory($q, _, binkySrc2) {
    class AvailableTypeList extends binkySrc2.services.availableType.AvailableTypeList {
      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @return {Promise} resolves to refreshed AvailableTypeList model, else rejects with error message
       */
      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              const error = this.error || super.error;
              if (error) {
                reject(_.get(error, 'response'));
              }
              resolve(this);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to retrieve an existing AvailableTypeList from the back-end.
       *
       * @param {Object} options - options provided to the get call
       * @param {String} options.email - the email to get the available types of
       * @param {String} options.orgId - the orgId that will be included in the assembled url
       *
       * @returns {AvailableTypeList} new AvailableTypeList Object reference
       */
      static get(options) {
        const model = new AvailableTypeList(options);
        model.refresh();
        return model;
      }
    }

    return AvailableTypeList;
  }
})();
