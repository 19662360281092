(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.avatar
   * @description module that defines avatar widgets
   */
  angular.module('binky.widgets.common.avatar', [
    'binky.core.authentication',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.user.authenticated-user',
    'binky.core.user.avatar',
  ]);
})();
