(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name reportBroker
   * @description defines service to manage report broker resources
   */
  angular.module('binky.core.api.report-broker').provider('reportBroker', reportBrokerProvider);

  let url;

  /* @ngInject */
  function reportBrokerProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        insightsItem: getInsightsItem(),
        insightsItemAccessList: getInsightsItemAccessList(),
        insightsItemDetail: getInsightsItemDetail(),
        insightsItemDetailView: getInsightsItemDetailView(),
      };

      return service;

      ///////////

      function getInsightsItem() {
        return $resource(`${url}/:orgId/insights/:types`, {
          orgId: apiUtils.getActiveOrg,
          types: '@types',
        });
      }

      function getInsightsItemAccessList() {
        return $resource(`${url}/:orgId/insights`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getInsightsItemDetail() {
        return $resource(
          `${url}/:orgId/insights/:type/reports`,
          {
            orgId: apiUtils.getActiveOrg || '@orgId',
            type: '@type',
          },
          {
            create: {
              method: 'POST',
              url: `${url}/:orgId/insights/:type/reports%3Acreate`,
            },
            update: {
              method: 'PATCH',
            },
          }
        );
      }

      function getInsightsItemDetailView() {
        return $resource(`${url}/:orgId/insights/:type/views/:view`, {
          orgId: apiUtils.getActiveOrg,
          type: '@type',
          view: '@view',
        });
      }
    }
  }
})();
