import {ROUTE_CHANGE_TYPE} from '../observability.constants';

const visitedRoutes = {};

/**
 * @description Get the route change type, which is one of ROUTE_CHANGE_TYPE.
 * @param {ObservabilityRoute} observabilityRoute Route to check
 * @returns {String} One of ROUTE_CHANGE_TYPE on the following priority:
 * - 1st app visit: START_SESSION
 * - 1st route visit: INITIAL_ROUTE
 * - Otherwise: RETURN_ROUTE
 */
const getRouteChangeType = (observabilityRoute) => {
  if (Object.entries(visitedRoutes).length === 0) {
    return ROUTE_CHANGE_TYPE.START_SESSION;
  }

  const key = observabilityRoute.getKey();
  return visitedRoutes[key] ? ROUTE_CHANGE_TYPE.RETURN_ROUTE : ROUTE_CHANGE_TYPE.INITIAL_ROUTE;
};

/**
 * @description Mark a route as visited
 * @param {ObservabilityRoute} observabilityRoute Record that the route is visited
 */
const visited = (observabilityRoute) => {
  const key = observabilityRoute.getKey();
  visitedRoutes[key] = true;
};

const observabilityRoutes = {
  getRouteChangeType,
  visited,
};

export default observabilityRoutes;
