// == Workaround for React 16.9+ unsafe component API warnings, in Spectrum V2.
//
// Spectrum V2 will not be fixing these in order to stay compatible with React 16.8
// and lower, so we will be ignoring these warnings until we fully migrate to Spectrum V3,
// by which time we can remove this class.
// See https://git.corp.adobe.com/React/react-spectrum-v2/issues/356

// This is a list of known components with the issue, please feel free to update this list
// if you see the react-unsafe warning with other spectrum components.
const componentsWhitelist = [
  'Accordion',
  'AccordionItem',
  'Button',
  'ButtonGroup',
  'Modal',
  'Overlay',
  'OverlayTrigger',
  'Portal',
  'Provider',
  'Search',
  'SearchField',
  'Slider',
  'SwitchBase',
];

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- thean@ to update
const spectrumV2IgnoreUnsafe = () => {
  const ignorePattern = /react-unsafe[^]*Please update the following components:/;
  /* eslint-disable no-console -- We are overriding console.warn here, so obviously we need to call console. */
  const originalWarn = console.warn;
  console.warn = (msg, ...args) => {
    if (args[0]) {
      const components = args[0].split(', ');
      if (
        ignorePattern.test(msg) &&
        components.every((component) => componentsWhitelist.includes(component))
      ) {
        return;
      }
      originalWarn.call(console, msg, ...args);
    }
  };

  /* eslint-enable no-console -- End disabling. */
};

export default spectrumV2IgnoreUnsafe;
