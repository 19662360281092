(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.member:binkyMemberStatus
   * @description Member account status. Can be active, disabled or simply a dash otherwise. Maps to Renga account status column.
   *
   * @param {String} status the status of the member. One of the ACCOUNT_STATUSES constants
   * @example <binky-member-status
   *            status="ACTIVE">
   *          </binky-member-status>
   *
   */
  angular.module('binky.widgets.common.member.status').component('binkyMemberStatus', {
    bindings: {
      status: '@',
    },
    controller,
    templateUrl: 'widgets/common/member/status/member-status.component.html',
  });

  /* @ngInject */
  function controller($log, $translate, _, memberStatusHelper) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      vm.labelKey = memberStatusHelper.getLabelKey(vm.status);
      vm.variant = memberStatusHelper.getVariant(vm.status);
    }
  }
})();
