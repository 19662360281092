(function () {
  /**
   * @deprecated Please use src2/core/services/authentication.js
   *
   * @ngdoc provider and service
   * @name binky.core.authentication
   * @description Authentication provider and service. This can be used to determine
   *  who the user is, whereas the Authorization service is used to determine what they
   *  can do.
   */
  angular.module('binky.core.authentication').provider('authentication', authenticationProvider);

  /* @ngInject */
  function authenticationProvider() {
    let ims, storedProfile;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(imsData) {
      ims = imsData;
    }

    /* @ngInject */
    function $get($q, binkySrc2) {
      const authentication = binkySrc2.services.authentication;
      const service = {
        getAccessToken: () => authentication.getAccessToken().token,
        getTransitoryAuthorizationCode: authentication.getTransitoryAuthorizationCode,
        getUserProfile: () => storedProfile,
        initialize: () =>
          $q((resolve, reject) => {
            authentication
              .initialize(ims)
              .then((profile) => {
                storedProfile = profile;
                resolve(profile);
              })
              .catch(reject);
          }),
        isUserSignedIn: authentication.isSignedInUser,
        setLocale: authentication.setLocale,
        signIn: authentication.signIn,
        signOut: authentication.signOut,
        switchTo: authentication.switchTo,
      };

      return service;
    }
  }
})();
