(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.products:binkyProductChiclet
   * @param {String} [body] The translated body text of the product chiclet.
   * @param {Object} [icons] The icons object returned as part of the product or product service response.
   * @param {String} [iconTitle] The icon title
   * @param {String} [name] The product name.
   *
   * Transclusion [chiclet-tags] Optional inline tags displayed following product name.
   */
  angular.module('binky.widgets.products.product-chiclet').component('binkyProductChiclet', {
    bindings: {
      body: '@?',
      icons: '<?',
      iconTitle: '@?',
      name: '@?',
    },
    templateUrl: 'widgets/products/product-chiclet/product-chiclet.component.html',
    transclude: {
      'tag-area': '?tagArea',
    },
  });
})();
