(function () {
  /**
   * @deprecated Please use src2/core/models/listState/ListState.js
   *
   * @ngdoc factory
   * @name ListState
   * @description class for representing the state of Lists
   */
  angular.module('binky.core.common.list').factory('ListState', getListState);

  /* @ngInject */
  function getListState(_, LIST_DEFAULT_PAGE_SIZE) {
    let paginationId = 0;

    class ListState {
      /**
       * @class
       * @description Constructor for ListState model Objects.
       * @param {Object} [options] - options object
       * @param {String} [options.filterQuery] - query to filter results against. Defaults to ''.
       * @param {Array} [options.filterQueryKeys] - array of string keys to search over. Defaults to [].
       * @param {String} [options.filterQueryMinLength] - minimum query length. Defaults to 3.
       * @param {Number} [options.pageNumber] - the current page of the results to show. 1-based index. Defaults to 1.
       * @param {Number} [options.pageSize] - number of items to display per page. Defaults to LIST_DEFAULT_PAGE_SIZE.
       * @param {String} [options.sortKey] - sort key (e.g. name). Defaults to undefined.
       * @param {Boolean} [options.sortReverse] - whether or not the current sort is reversed. Defaults to false.
       */
      constructor(options = {}) {
        paginationId += 1;
        _.assign(
          this,
          {
            filterQuery: '',
            filterQueryKeys: [],
            filterQueryMinLength: 3,
            pageNumber: 1,
            pageSize: LIST_DEFAULT_PAGE_SIZE,
            paginationId: `paginationId${paginationId}`,
            sortKey: options.sortKey || '',
            sortReverse: false,
          },
          options
        );
      }

      caseInsensitiveFilter(item) {
        return (
          !this.filterQuery || // Return all results when there is no query
          _.some(this.filterQueryKeys, (key) => {
            const value = _.get(item, key);
            const toLowerValue = _.toLower(value);
            return _.includes(toLowerValue, _.toLower(this.filterQuery));
          })
        );
      }

      setFilterQuery(filterQuery) {
        this.filterQuery = filterQuery;
        this.setPageNumber(1);
      }

      setPageNumber(pageNumber) {
        this.pageNumber = pageNumber;
      }

      setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.setPageNumber(1);
      }

      setSortKeyAndReverseIfSameKey(sortKey) {
        this.sortReverse = sortKey === this.sortKey ? !this.sortReverse : false;
        this.sortKey = sortKey;
      }
    }

    return ListState;
  }
})();
