(function () {
  /**
   * @deprecated Please use src2/core/models/user/UserAvatar.js
   */
  angular.module('binky.core.user.avatar').factory('UserAvatar', getUserAvatarModel);

  /* @ngInject */
  function getUserAvatarModel(
    $q,
    $rootScope,
    _,
    binkySrc2,
    MEMBER_EVENT,
    modelCache,
    promiseUtils,
    USER_AVATAR_CACHE_ID
  ) {
    const sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL'];

    class UserAvatarSrc2 extends binkySrc2.models.user.UserAvatar {
      /**
       * @description Method to fetch the user avatar
       * @returns {Promise} promise - promise object, resolved when the user avatar is loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              resolve(this);
              this.$resolved = true;
            })
            .catch((error) => {
              reject(error);
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method for getting a user avatar object and loading its data
       * @param {String} userId - ID of user to fetch an avatar for
       * @param {String} size - the size of avatar to fetch, one of S, M, L or XL
       * @returns {UserAvatar} userAvatar object
       */
      static get(userId, size) {
        const model = new UserAvatarSrc2({size, userId});
        const promise = super.get(userId, size);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((avatar) => {
            _.assign(model, avatar);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MEMBER_EVENT.UPDATE, removeCachedAvatar);

    modelCache.register(USER_AVATAR_CACHE_ID, 500);

    return UserAvatarSrc2;

    function generateAvatarKey(memberId, size) {
      return `${memberId}-${size}`;
    }

    function removeCachedAvatar(event, userId) {
      event.stopPropagation();
      _.forEach(sizes, (size) => {
        const key = generateAvatarKey(userId, size);
        modelCache.remove(USER_AVATAR_CACHE_ID, key);

        binkySrc2.models.cache.modelCache.remove(USER_AVATAR_CACHE_ID, key);
      });
    }
  }
})();
