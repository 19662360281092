const PRODUCT_ADMIN_LIST_CACHE_ID = 'ProductAdminList';

const PRODUCT_ADMIN_LIST_EVENT = {
  UPDATE: {
    COUNT: 'UpdateProductAdminsCount',
    LIST: 'UpdateProductAdminsList',
  },
};

export {PRODUCT_ADMIN_LIST_CACHE_ID, PRODUCT_ADMIN_LIST_EVENT};
