(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('binky.core.invoice')
    .constant('INVOICE_LIST_CACHE_ID', 'InvoiceList')
    .constant('INVOICE_LIST_EVENT', {
      UPDATE: 'UpdateInvoiceList',
    })
    .constant('INVOICE_SORT', {
      INVOICE_AMOUNT: 'INVOICE_AMOUNT',
      INVOICE_DATE: 'INVOICE_DATE',
    })
    .constant('INVOICE_STATUS', {
      CLOSED: 'CLOSED',
      OPEN: 'OPEN',
      PAID: 'PAID',
      PAST_DUE: 'PAST_DUE',
      PAYMENT_DUE: 'PAYMENT_DUE',
      PROCESSING: 'PROCESSING',
    })
    .constant('INVOICE_UNPAID_STATUSES', ['PAST_DUE', 'PAYMENT_DUE']);
})();
