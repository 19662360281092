import eventBus, {EVENT_SOURCES} from 'services/events/eventBus';
import log from 'services/log';
import {getClonedResolvedValuePromise} from 'utils/promiseUtils';

import Cache from '../Cache';

let modelCaches = {};

function findCache(modelId) {
  if (!modelId) {
    log.error('ModelCache findCache() missing required argument modelId');
  }
  if (!modelCaches[modelId]) {
    modelCaches[modelId] = new Cache();
  }
  return modelCaches[modelId];
}

/**
 * Object for coordinating caching Models to avoid extraneous api calls.
 */
const modelCache = {
  /**
   * @description Clear all models from a given cache
   * @param {String} modelId Unique Identifier for the model class
   */
  clear(modelId) {
    findCache(modelId).clearAll();
  },

  /**
   * @description Remove all caches
   */
  clearAll() {
    modelCaches = {};
  },

  clearOnEvent(modelId, eventIds) {
    eventBus.registerEventHandler((eventId) => {
      if (eventIds.includes(eventId)) {
        modelCache.clear(modelId);
      }
    }, EVENT_SOURCES.ANY);
  },

  /**
   * @description Retrieves cached items for model.
   * @param {String} modelId Unique Identifier for the model class
   * @param {String} key Stringified parameters used in model query
   * @return {Promise<Array<Object>>|null} Resolves to array of model objects on cache hit, or null on cache miss
   */
  get(modelId, key) {
    if (!key) {
      log.error('modelCache.get() missing required argument key');
    }
    const promise = findCache(modelId).get(key);
    return promise ? getClonedResolvedValuePromise(promise) : promise;
  },

  /**
   * @description Returns an iterator to loop through keys in a model cache. Used by Omni Tool.
   * @param {String} modelId Unique Identifier for the model class
   * @return {MapIterator} Iterator for keys in cache or undefined if the cache isn't found.
   */
  getKeys(modelId) {
    if (!modelId) {
      log.error('modelCache.getKeys() missing required argument modelId');
    }
    return modelCaches[modelId]?.keys();
  },

  /**
   * @description Returns all Model IDs with caches. Used by Omni Tool.
   * @return {Array<String>} List of model IDs
   */
  getModelIds() {
    return Object.keys(modelCaches);
  },

  /**
   * @description determine whether cache for a model contains a particular key.
   * @param {String} modelId Unique Identifier for the model class
   * @param {String} key Stringified parameters used in model query
   * @return {Boolean} Model objects stored with given key
   */
  has(modelId, key) {
    if (!key) {
      log.error('modelCache.has() missing required argument key');
    }
    return findCache(modelId).has(key);
  },

  /**
   * @description Adds or updates a cache item for the model.  Individual model
   *              instances should be added as an array with one object.
   * @param {String} modelId Unique Identifier for the model class
   * @param {String} key Stringified parameters used in model query
   * @param {Promise<Array<Object>>} modelsPromise Promise that resolves to models
   */
  put(modelId, key, modelsPromise) {
    if (!key) {
      log.error('modelCache.put() missing required argument key');
    }
    findCache(modelId).put(key, getClonedResolvedValuePromise(modelsPromise), {merge: false});
  },

  /**
   * @description Removes a cached item.
   * @param {String} modelId Unique Identifier for the model class
   * @param {String} key Stringified parameters used in model query
   */
  remove(modelId, key) {
    if (!key) {
      log.error('modelCache.remove() missing required argument key');
    }
    findCache(modelId).clear(key);
  },
};

export default modelCache;
