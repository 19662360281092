import pick from 'lodash/pick';

import MemberType from 'models/member/type/MemberType';

function initModel(model, options) {
  Object.assign(
    model,
    pick(options, [
      'authenticating_user_id',
      'authenticating_user_type',
      'entitlement_user_id',
      'entitlement_user_type',
      'organization_id',
      'organization_name',
      'organization_path_name',
      'organization_type',
    ])
  );
}

class LinkedUserAccount {
  /**
   * @description Creates a new Offer for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.authenticating_user_id - the ID of the primary authenticating
   *     account for this cluster of accounts
   * @param {String} options.authenticating_user_type - the type of the authenticating account,
   *     this can be TYPE1, TYPE2, or TYPE3
   * @param {String} options.entitlement_user_id - the ID of this entitlement account
   * @param {String} options.entitlement_user_type - the type of this entitlement account, this
   *     can be TYPE1, TYPE2, TYPE3, or TYPE2E
   * @param {String} options.organization_id - the organization which owns this account, if applicable
   * @param {String} options.organization_name - the name of the owning org, if applicable
   * @param {String} options.organization_path_name - Global Admin org hierarchy
   */
  constructor(options) {
    initModel(this, options);
  }

  /**
   * @description Get the authenticating user account type.
   * @returns {MemberType} the authenticating user's account type, 'TYPE1', 'TYPE2', or 'TYPE3'.
   */
  getAuthenticatingUserType() {
    return new MemberType(this.authenticating_user_type.toUpperCase(), this.authenticating_user_id);
  }

  /**
   * @description Get the entitlement user account type.
   * @returns {MemberType} the entitlement user's account type, 'TYPE1', 'TYPE2', 'TYPE3', or 'TYPE2E'.
   */
  getEntitlementUserType() {
    return new MemberType(this.entitlement_user_type.toUpperCase(), this.entitlement_user_id);
  }
}

export default LinkedUserAccount;
