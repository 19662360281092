import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';
import {useIntl} from 'react-intl';

/**
 * Details button that opens a drawer or a modal displaying
 * more details about a particular page or section.
 */
const DetailsButton = ({
  detailsDrawer,
  detailsModal,
  offsetTop = 'var(--spectrum-global-dimension-size-700)',
}) => {
  const intl = useIntl();

  return detailsDrawer ? (
    <DrawerTrigger offsetTop={offsetTop}>
      <Button data-testid="details-button" variant="secondary">
        {intl.formatMessage({
          id: 'binky.common.detailsButton',
        })}
      </Button>
      {detailsDrawer}
    </DrawerTrigger>
  ) : (
    <DialogTrigger>
      <Button data-testid="details-button" variant="secondary">
        {intl.formatMessage({
          id: 'binky.common.detailsButton',
        })}
      </Button>
      {/* Set the onClose callback to trigger close so DialogTrigger can close the modal */}
      {(close) => cloneElement(detailsModal, {onClose: close})}
    </DialogTrigger>
  );
};

DetailsButton.displayName = 'DetailsButton';

DetailsButton.propTypes = {
  /**
   * The drawer that provides more details for the page.
   */
  detailsDrawer: PropTypes.node,
  /**
   * The modal that provides more details for the page.
   * The modal must have a onClose callback function that the
   * DialogTrigger can use to close the modal.
   */
  detailsModal: PropTypes.node,
  /**
   * Used to offset top so drawer starts below the header so the navigation
   * is still accessible without closing the drawer. The default value is var(--header-height): 3.5rem
   * which is the height of the header of the Admin Console
   */
  offsetTop: PropTypes.string,
};

export default DetailsButton;
