import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import isBefore from 'date-fns/isBefore';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';

import {
  LICENSE_QUANTITY_RENEWAL_STATUS,
  LICENSE_QUANTITY_STATUS,
  LICENSE_QUANTITY_TAGS,
  LICENSE_QUANTITY_UNLIMITED,
} from 'models/licenseQuantityList/LicenseQuantityListConstants';

const RENEWAL_WINDOW_IN_DAYS = 60;

/**
 * @deprecated. Use LicenseTupleList instead. Need to keep around for Reseller Console usage.
 * Can remove after RC is sunset.
 */
class LicenseQuantityList {
  /**
   * @description Creates a new LicenseQuantityList for use.
   *
   * @param {Array} items License Quantities which make up this list
   */
  constructor(items = []) {
    this.items = parse(items);
    this.registerSavedState();
  }

  /**
   * @description Method to determine the quantity which may be assigned.
   * @returns {Number}  quantity that are assignable
   */
  getAssignableQuantity() {
    return this.getPaidOrPendingOrGracePastDueOrCancellingQuantity();
  }

  /**
   * @description Fetches the closest end date from the items, filtered by
   *  the provided condition.
   *
   * @param {Object} condition the filter to apply
   * @returns {Date} the closest end date, from the filtered items
   */
  getClosestEndDateWhen(condition) {
    const endDates = this.items
      .filter(condition)
      .map((item) => item.endDate)
      .filter((item) => !!item);
    return minBy(endDates, (endDate) => new Date(endDate).getTime());
  }

  /**
   * @description Method to determine the count of expiring licenses for a non renewal product.
   *    Note that the expiring license count is returned by the API as CANCELLING.
   *    For PUF teams, if a license got cancelled or returned, its status becomes CANCELLED immediately and won't
   *    be returned by the API. For APM teams, if a license got cancelled or returned, the status will be CANCELLING
   *    until the next payment due date; after the payment due date, the status becomes CANCELLED and won't be returned
   *    by the API.
   * @returns {Number}  quantity of licenses that are expiring
   */
  getExpiringQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.CANCELLING &&
        !isRenewing(licenseQuantity)
    );
  }

  /**
   * @description Method to sum the quantities that are either GRACE_PAST_DUE or PAST_DUE.
   * @returns {Number}  quantity grace past due or past due
   */
  getGracePastDueOrPastDueQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAST_DUE
    );
  }

  /**
   * @description Method to sum the quantities that are either PENDING_PAYMENT or
   *   GRACE_PAST_DUE and not in renewal.
   * @returns {Number}  quantity that need payment
   */
  getNeedPaymentQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        (licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
          licenseQuantity.status === LICENSE_QUANTITY_STATUS.PENDING_PAYMENT) &&
        !isRenewing(licenseQuantity)
    );
  }

  /**
   * @description Method to sum the quantities that are either PAID or
   *   GRACE_PAST_DUE and in renewal, based on the provided anniversaryDate.
   * @param {Date} anniversaryDate the anniversaryDate to compare to
   * @returns {Number}  quantity that need payment
   */
  getNeedRenewalQuantity(anniversaryDate) {
    const endOfRenewalWindow = addDays(new Date(anniversaryDate), 30); // T+30
    return this.sumWhen(
      (licenseQuantity) =>
        isRenewing(licenseQuantity) &&
        (licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
          licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID) &&
        licenseQuantity.endDate !== null &&
        isBefore(new Date(licenseQuantity.endDate), endOfRenewalWindow)
    );
  }

  /**
   * @description Method to determine the cumulative count of PAID, PENDING_PAYMENT,
   *   GRACE_PAST_DUE, or CANCELLING quantities.
   * @returns {Number} sum of quantities that are paid, pending, grace past
   *   due, or cancelling
   */
  // eslint-disable-next-line id-length -- Ported from src
  getPaidOrPendingOrGracePastDueOrCancellingQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PENDING_PAYMENT ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.CANCELLING
    );
  }

  /**
   * @description Method to determine the cumulative count of PAID, PENDING_PAYMENT,
   *   or GRACE PAST_DUE quantities.
   * @returns {Number} sum of quantities that are paid or pending
   */
  getPaidOrPendingOrGracePastDueQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID ||
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PENDING_PAYMENT
    );
  }

  /**
   * @description Method to sum the total PAID or renewing license quantities
   *
   * @returns {Number} sum of quantities that are paid or renewing
   */
  getPaidOrRenewingQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        isRenewing(licenseQuantity) || licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID
    );
  }

  /**
   * @description Method to sum the quantities that are either PENDING_PAYMENT or GRACE_PAST_DUE
   *  or PAST_DUE that are not renewing.
   *
   * @returns {Number} quantity pending payment, grace past due or past due that are not
   *  renewing.
   */
  // eslint-disable-next-line id-length -- Ported from src
  getPendingPaymentOrGracePastDueOrPastDueQuantity() {
    return this.sumWhen(
      (licenseQuantity) =>
        !isRenewing(licenseQuantity) &&
        (licenseQuantity.status === LICENSE_QUANTITY_STATUS.PENDING_PAYMENT ||
          licenseQuantity.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE ||
          licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAST_DUE)
    );
  }

  /**
   * @description Method to return the renewal status
   * @param {String} anniversaryDate the anniversaryDate to compare to
   *
   * @returns {String} LICENSE_QUANTITY_RENEWAL_STATUS constant
   */
  getRenewalStatus(anniversaryDate, isInPostAnniversaryRenewalWindow) {
    const renewedTotal = this.getRenewedTotal(anniversaryDate, isInPostAnniversaryRenewalWindow);
    const needRenewed = this.getNeedRenewalQuantity(anniversaryDate);

    if (renewedTotal > 0) {
      return needRenewed === 0
        ? LICENSE_QUANTITY_RENEWAL_STATUS.COMPLETE
        : LICENSE_QUANTITY_RENEWAL_STATUS.PARTIAL;
    }

    return needRenewed === 0
      ? LICENSE_QUANTITY_RENEWAL_STATUS.NOT_RENEWAL
      : LICENSE_QUANTITY_RENEWAL_STATUS.NONE;
  }

  /**
   * @description Method to return the sum of quantities that have renewed
   * @param {String} anniversaryDate the anniversaryDate to compare to
   *
   * @returns {Number} the sum of quantities that have renewed
   */
  getRenewedTotal(anniversaryDate, isInPostAnniversaryRenewalWindow) {
    if (isInPostAnniversaryRenewalWindow) {
      return this.sumWhen(
        // When a license is renewed, the status post T then still be PAID.
        (licenseQuantity) => licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID
      );
    }

    // This logic only works pre T. Once the contract anniversary date is rolled over on T,
    // the contract AD and license end date align with the next term.
    return this.sumWhen(
      (licenseQuantity) =>
        licenseQuantity.status === LICENSE_QUANTITY_STATUS.PAID &&
        // The endDate is null when not in renewal window
        licenseQuantity.endDate !== null &&
        // When licenses are renewed/bought, their end date will be set to the next anni date
        // so thus greater than the renewal window of 60 days.
        // Note: this will also include regular ordered (non-renewed) licenses.
        differenceInDays(new Date(licenseQuantity.endDate), new Date(anniversaryDate)) >
          RENEWAL_WINDOW_IN_DAYS
    );
  }

  /**
   * @description Method to calculate the number of license quantities that are not renewing
   *              given a filter condition.
   * @param {Object} condition the filter to apply
   * @returns {Number} sum that match the provided condition and are not renewing
   */
  getTotalNonRenewingWhen(condition) {
    return this.sumWhen(
      (licenseQuantity) => condition(licenseQuantity) && !isRenewing(licenseQuantity)
    );
  }

  /**
   * @description Method to determine if there are quantities with status GRACE_PAST_DUE.
   *              Once the grace period ends, all users provisioned to such licenses
   *              will lose access to products.
   * @returns {boolean} true if any item has status GRACE_PAST_DUE
   */
  hasGracePastDueLicenses() {
    return this.items.some((item) => item.status === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE);
  }

  /**
   * @description Method to determine if there are quantities with status PAST_DUE.
   * @returns {boolean} true if any item has status PAST_DUE
   */
  hasPastDueLicenses() {
    return this.items.some((item) => item.status === LICENSE_QUANTITY_STATUS.PAST_DUE);
  }

  /**
   * @description Method to determine if the list only has UNLIMITED quantities.
   * @returns {boolean} true if all items have quantity UNLIMITED
   */
  hasUnlimitedLicenses() {
    return this.items.every((item) => item.isUnlimited);
  }

  /**
   * @description Method to determine if there are any unsaved changes to this list.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    // we re-parse as some editing elements may change the quantity back to a string
    return !isEqual(parse(this.items), this.savedState);
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   */
  registerSavedState() {
    this.savedState = cloneDeep(parse(this.items));
  }

  /**
   * @description Restores the list items from its saved state
   */
  restore() {
    this.items = cloneDeep(this.savedState);
  }

  /**
   * @description Sums the item quantities, filtered by the provided condition.
   *
   * @param {Object} condition the filter to apply
   * @returns {Number} the sum of the filtered quantities
   */
  sumWhen(condition) {
    if (this.items && this.items.length > 0) {
      return sumBy(this.items, (licenseQuantity) =>
        !Number.isNaN(licenseQuantity.quantity) && condition(licenseQuantity)
          ? licenseQuantity.quantity
          : 0
      );
    }
    return 0;
  }

  /**
   * @description Method to transform the list into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @param {Object} [options] - Configuration for this function call.
   * @param {Boolean} [options.filterToModified] - Whether to limit to quantities
   *    that have been modified. Defaults to false.
   * @returns {Array} minimum necessary representation of the list
   */
  toMinimumModel(options = {}) {
    const {filterToModified = false} = options;
    return !filterToModified || this.hasUnsavedChanges()
      ? this.items.map((item) => {
          if (item.isUnlimited) {
            return {quantity: LICENSE_QUANTITY_UNLIMITED};
          }
          return {quantity: item.quantity};
        })
      : [];
  }
}

/** Private Methods **/

function isRenewing(licenseQuantity) {
  return (
    licenseQuantity.tags && licenseQuantity.tags.split(',').includes(LICENSE_QUANTITY_TAGS.RENEWING)
  );
}

function parse(licenseQuantities) {
  // UNLIMITED is currently reported as a string, so we convert to a number
  const clonedQuantities = cloneDeep(licenseQuantities);
  return clonedQuantities.map((licenseQuantity) => {
    if (typeof licenseQuantity.quantity === 'string') {
      return {
        ...licenseQuantity,
        isUnlimited: licenseQuantity.quantity === LICENSE_QUANTITY_UNLIMITED,
        quantity: toNumber(licenseQuantity.quantity),
      };
    }
    return licenseQuantity;
  });
}

export default LicenseQuantityList;
