(function () {
  /**
   * @deprecated - Moved to src2/core/ready/appReady.js
   */
  angular.module('binky.core.ready').provider('appReady', appReady);

  /* @ngInject */
  function appReady(_) {
    const initInjector = angular.injector(['ng']);
    const $q = initInjector.get('$q');
    const serviceDeferred = $q.defer();
    const configureDeferred = $q.defer();
    const runDeferred = $q.defer();

    $q.all([configureDeferred.promise, runDeferred.promise])
      .then(serviceDeferred.resolve)
      .catch(serviceDeferred.reject);

    this.configure = configure;
    this.whenRunReady = whenRunReady;
    this.$get = $get;

    ////////////

    function configure(...providers) {
      $q.all(_.invokeMap(providers, 'configure'))
        .then(configureDeferred.resolve)
        .catch(configureDeferred.reject);

      return configureDeferred.promise;
    }

    function whenRunReady() {
      return runDeferred.promise;
    }

    function $get() {
      return {
        promise: serviceDeferred.promise,
        run(...services) {
          configureDeferred.promise
            .then(() => $q.all(_.invokeMap(services, 'run')))
            .then(runDeferred.resolve)
            .catch(runDeferred.reject);

          return runDeferred.promise;
        },
        whenRunReady,
      };
    }
  }
})();
