import {Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import Search from '../../search/Search';
import {useTableSectionContext} from '../section/TableSectionContext';

/**
 * @deprecated use Pandora adminstration TableFilters and TableActions:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/table-filters
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/table-actions
 *
 * Actions that interact with a table, which can include search and action buttons.
 */
const TableActions = observer(({children, searchLabel}) => {
  const {listStore} = useTableSectionContext();

  const isInitialTableEmpty = () =>
    !listStore.searchInputValue && listStore.tableStore.isEmptyTable;

  // Event handlers
  const onSearchSubmit = (value) => {
    listStore.search(value);
  };

  const onSearchValueChange = (value) => {
    listStore.searchInputValue = value;
  };

  // Align components along their bottom edges so Search input aligns with Buttons.
  return (
    listStore && (
      <Flex alignItems="flex-end" justifyContent="space-between" marginBottom="size-200">
        {searchLabel && (
          <Search
            data-testid="table-action-search-field"
            isDisabled={listStore.isLoading || isInitialTableEmpty()}
            label={searchLabel}
            onChange={onSearchValueChange}
            onSubmit={onSearchSubmit}
            value={listStore.searchInputValue}
            width="size-3600"
          />
        )}
        {children}
      </Flex>
    )
  );
});

TableActions.displayName = 'TableActions';
TableActions.propTypes = {
  /**
   * A Button, ButtonGroup, ButtonGroupWithMoreOptions with actions related to the table.
   */
  children: PropTypes.node,
  /**
   * The label of the search bar.
   */
  searchLabel: PropTypes.string,
};

export default TableActions;
