import jilAdmins from 'api/jil/jilAdmins';
import jilUsers from 'api/jil/jilUsers';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import {
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from 'models/user/roles/UserRolesConstants';
import {getAdminRolePatches, getUsersWithModifiedRoles} from 'models/user/roles/userRoleUtils';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import MemberList from 'services/member/MemberList';

import {
  ORGANIZATION_ADMIN_LIST_CACHE_ID,
  ORGANIZATION_ADMIN_LIST_EVENT,
} from './OrganizationAdminListConstants';

/**
 * @description Method to instantiate OrganizationAdminList for the current active organization.
 * @param {Object} [options] - Options for the constructor.
 * @param {String} [options.filterQuery] - query to filter results against
 * @param {Number} [options.pageNumber] - 1-based page number to fetch
 * @param {Number} [options.pageSize] - number of items to display per page
 * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
 * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
 * @param {String} [options.role] - filtered role for admin. One of
 * ORG_ADMIN, DEPLOYMENT_ADMIN, PRODUCT_ADMIN, LICENSE_GROUP_ADMIN, STORAGE_ADMIN,
 * USER_GROUP_ADMIN.
 * @param {Array} [options.roles] - filtered roles for admin.
 * @param {Boolean} [options.includeAllAdminRoles] - Shorthand to set options.roles to all admin roles. Includes
 * COMPARTMENT_ADMIN,COMPARTMENT_VIEWER,DEPLOYMENT_ADMIN,LICENSE_ADMIN,ORG_ADMIN,PRODUCT_ADMIN,
 * STORAGE_ADMIN,SUPPORT_ADMIN,USER_GROUP_ADMIN and PRODUCT_SUPPORT_ADMIN (if temp_parkour_mm is enabled).
 * Defaults to false.
 * @returns {OrganizationAdminList} orgList - new OrganizationAdminList.
 */
class OrganizationAdminList extends MemberList {
  /**
   * @description Constructor for OrganizationAdminList model Objects.
   * @param {Object} options - options passed to the List constructor.
   */
  constructor(options) {
    super({
      itemClassRef: OrganizationUser,
      modelCacheId: ORGANIZATION_ADMIN_LIST_CACHE_ID,
      refreshResource: jilAdmins.getAdmins,
      saveResource: jilUsers.patchUsers,
      ...options,
    });

    this.modifiedUsers = [];
    this.role = options.role;

    // set all admin roles if includeAllAdminRoles is passed
    this.roles = options.includeAllAdminRoles
      ? [
          CONTRACT_ADMIN,
          COMPARTMENT_ADMIN,
          COMPARTMENT_VIEWER,
          DEPLOYMENT_ADMIN,
          LICENSE_ADMIN,
          // we intentionally omit LICENSE_DEV here as developers are shown elsewhere
          ORG_ADMIN,
          PRODUCT_ADMIN,
          // remove filter below when temp_parkour_mm is removed
          feature.isEnabled('temp_parkour_mm') ? PRODUCT_SUPPORT_ADMIN : undefined,
          STORAGE_ADMIN,
          SUPPORT_ADMIN,
          USER_GROUP_ADMIN,
        ].filter((role) => role)
      : options.roles;
  }

  /**
   * @description Method for providing modified users, to be persisted on save.
   * @param {OrganizationUser[]} users - the users that have been modified.
   */
  addModifiedUsers(users) {
    this.modifiedUsers = [...new Set([...this.modifiedUsers, ...users])];
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      role: this.role,
      roles: this.roles?.join(','),
    };
  }

  /**
   * @description Method to fetch the pending operations on the admins/users.
   *
   * @return {Array} an array of operations to save
   */
  getSaveOperations() {
    let operations = [];

    // MemberList only knows about addedItems and removedItems so add the modified users to removedItems,
    // removing the roles from the removed items so when 'getAdminRolePatches' computes the patch list
    // it is the complete set of roles for the admin, not just the different between savedState.roles and
    // the current value of roles.
    const modifiedItems = getUsersWithModifiedRoles(this.modifiedUsers);
    modifiedItems.forEach((removedUser) => {
      operations = [...new Set([...operations, ...getAdminRolePatches(removedUser)])];
    });

    // Remove the roles from the removed items so when 'getAdminRolePatches' computes the patch list
    // it is the complete set of roles for the admin, not just the different between savedState.roles and
    // the current value of roles.
    this.removedItems.forEach((removedUser) => {
      const user = {...removedUser, roles: []};
      operations = [...new Set([...operations, ...getAdminRolePatches(user)])];
    });

    return operations;
  }

  /**
   * @description Method to determine if there are any unsaved changes to this
   *              list. Unsaved changes are either items pending addition or
   *              removal to/from this list since list creation or last save.
   * @returns {Boolean} true if there are unsaved changes, else false.
   */
  hasUnsavedChanges() {
    return getUsersWithModifiedRoles(this.modifiedUsers).length > 0 || super.hasUnsavedChanges();
  }

  /**
   * @description Method to execute after a successful refresh.
   */
  onRefreshSuccess() {
    eventBus.emit(ORGANIZATION_ADMIN_LIST_EVENT.UPDATE);
    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(ORGANIZATION_ADMIN_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
    }
  }

  /**
   * @description Method to save changes to admin list to back-end.
   * @param {Object} [options] - options for saving to pass thru to MemberList.save()
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to true.
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message.
   */
  async save(options) {
    if (!this.hasUnsavedChanges()) {
      return this;
    }

    // First we check if users are being added, and fail if so.
    if (this.addedItems.length > 0) {
      throw new Error('OrganizationAdminList.save cannot be invoked with added people');
    }

    // This will call-back getSaveOperations() to derive the operations.
    await super.save({}, options);

    this.modifiedUsers.forEach((member) => {
      eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
    });
    this.modifiedUsers = [];

    return this;
  }
}

export default OrganizationAdminList;
