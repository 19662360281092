(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyPerTermPrice
   * @description determines what term frequency to append to the formatted price. Monthly is appended
   *    by default while any other CONTRACT_BILLING_FREQUENCY must have option.alwaysShowTerm set to true
   *    ex. "$123.45 / mo"
   * @param {String} formattedPrice - the pre-formatted price string.
   * @param {CONTRACT_BILLING_FREQUENCY} billingFrequency the frequency at which to append to the
   *    formatted price.
   * @param {Object} [options] Optional formatting options.
   * @param {Boolean} [options.alwaysShowTerm] If set, will allow any available frequency term
   *    to be shown
   * @return {String} The string with the billing frequency appended to the price string.
   * @example <div> {{ $ctrl.formattedPrice | binkyPerTermPrice:$ctrl.billingFrequency:$ctrl.options }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.commerce').filter('binkyPerTermPrice', perTermPriceString);

  /* @ngInject */
  function perTermPriceString($translate, CONTRACT_BILLING_FREQUENCY) {
    return getPerTermPriceString;

    function getPerTermPriceString(formattedPrice, billingFrequency, options = {}) {
      const {alwaysShowTerm = false} = options;

      if (billingFrequency && formattedPrice) {
        return billingFrequency === CONTRACT_BILLING_FREQUENCY.MONTHLY || alwaysShowTerm
          ? $translate.instant(`binky.widgets.commerce.perTerm.term.${billingFrequency}`, {
              price: formattedPrice,
            })
          : formattedPrice;
      }

      return $translate.instant('binky.widgets.commerce.perTerm.pricingUnavailable');
    }
  }
})();
