(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.titled-section:binkyTitledSection
   * @description A section widget that has a title with white styled box beneath it. Has an optional
   * binky-error-section that will display when errorMsg not an empty string.
   * @param {String} [errorIcon] The icon to set when an error occurs. Defaults to undefined.
   * @param {String} [errorMsg] The error message that is shown on the binky-error-section.
   * @param {Function} [onReload] The function invoked when the reload text is clicked on the binky-error-section.
   * @param {String} titleKey The main section title key to be translated.
   */

  angular.module('binky.widgets.common.titled-section').component('binkyTitledSection', {
    bindings: {
      errorIcon: '<?',
      errorMsg: '@?',
      forceHideManageLink: '<?',
      onManage: '&?',
      onReload: '&?',
      titleKey: '@',
    },
    controller,
    templateUrl: 'widgets/common/titled-section/titled-section.component.html',
    transclude: {
      'box-content': 'boxContent',
      'right-header-content': '?rightHeaderContent',
      'title-content': '?titleContent',
    },
  });

  /* @ngInject */
  function controller(_, $transclude) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      linkId: _.uniqueId('link-'),
    });

    function $onInit() {
      vm.showSectionLink =
        !$transclude.isSlotFilled('right-header-content') && vm.onManage && !vm.forceHideManageLink;
    }

    function $onChanges() {
      vm.showError = !_.isEmpty(vm.errorMsg);
    }
  }
})();
