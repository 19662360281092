/**
 * @description Returns the first enterprise contract which has an id.
 * @param {ContractList} contractList - the contract list
 * @param {CONTRACT_BUYING_PROGRAM} buyingProgram - the buying program to match. Optional.
 *
 * @returns {Contract} enterprise contract, or undefined
 */
function getEnterpriseContract(contractList, buyingProgram = undefined) {
  return contractList.items.find(
    (item) =>
      item.isEnterpriseContract() &&
      Object.prototype.hasOwnProperty.call(item, 'id') &&
      (buyingProgram === undefined || item.buyingProgram === buyingProgram)
  );
}

/**
 * @description Returns first contract that is in renewal state and has enough
 * enterprise licenses to initiate a one-click migration
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Object} contract
 */
function getTeamToEnterpriseMigrationEligibleContract(contractList) {
  return contractList.items.find((item) => item.hasTeamToEnterpriseEligibleMigration());
}

/**
 * @description Returns true if there is an enterprise contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if this is an enterprise contract.
 */
function hasEnterpriseContract(contractList) {
  return contractList.items.some((item) => item.isEnterpriseContract());
}

export {getEnterpriseContract, getTeamToEnterpriseMigrationEligibleContract, hasEnterpriseContract};
