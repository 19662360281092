(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.shell.navigation.constants')
    .constant('APP_SHELL_NAV_ID', 'AppShellNavID')
    .constant('CACHED_STATE_KEY_PREFIX', 'cached-state-key-');
})();
