(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.datepicker
   * @description module that wraps the Coral datepicker
   */
  angular.module('binky.widgets.common.datepicker', [
    'angularMoment',
    'binky.core.feature',
    'binky.core.lodash',
  ]);
})();
