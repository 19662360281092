import {Content, Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import DropZone from '@react/react-spectrum/DropZone';
import {useId} from '@react-aria/utils';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import Upload from '@spectrum-icons/illustrations/Upload';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {FormattedMessage} from 'react-intl';

import ButtonLink from 'common/components/button-link/ButtonLink';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-file-drop-zone
 */

/**
 *  A component that users can use to drag and drop or select files from their local device.
 */
const FileDropZone = ({
  acceptedTypes = [],
  className,
  headingLevel = 3,
  multiple = false,
  onFileAdded,
  showIcon = true,
}) => {
  const fileInputRef = useRef();
  const descriptionId = useId();
  const fileInputLabelId = useId();
  const acceptAttributeValue = acceptedTypes.join(',');

  const handleShouldAccept = (event) => {
    const draggedItems = [...event.dataTransfer.items];
    const areItemsFiles = draggedItems.every((item) => item.kind === 'file');
    const areTypesAccepted =
      acceptedTypes.length > 0
        ? draggedItems.every((item) => acceptedTypes.includes(item.type))
        : true;

    return areItemsFiles && areTypesAccepted;
  };

  const handleOnDrop = (event) => {
    onFileAdded(event.dataTransfer.files);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const onFilesSelected = () => {
    onFileAdded(fileInputRef.current.files);

    // Set the input value to null so that the onChange on the input
    // can re-trigger if a user selects the same file.
    fileInputRef.current.value = null;
  };

  return (
    <DropZone
      aria-describedby={descriptionId}
      className={className}
      onDrop={handleOnDrop}
      shouldAccept={handleShouldAccept}
    >
      <IllustratedMessage>
        {showIcon && <Upload />}
        <Heading id={descriptionId} level={headingLevel}>
          <FormattedMessage
            id={multiple ? 'common.fileDropZone.headingMultiple' : 'common.fileDropZone.heading'}
          />
        </Heading>
        <Content>
          <View elementType="span" id={fileInputLabelId}>
            <FormattedMessage
              id={
                multiple
                  ? 'common.fileDropZone.descriptionMultiple'
                  : 'common.fileDropZone.description'
              }
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components -- @ringold to fix
                link: ([label]) => <ButtonLink onPress={openFileDialog}>{label}</ButtonLink>,
              }}
            />
            <VisuallyHidden>
              <input
                ref={fileInputRef}
                accept={acceptAttributeValue}
                aria-labelledby={fileInputLabelId}
                multiple={multiple}
                onChange={onFilesSelected}
                type="file"
              />
            </VisuallyHidden>
          </View>
        </Content>
      </IllustratedMessage>
    </DropZone>
  );
};

FileDropZone.propTypes = {
  /**
   * An array of mime types (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
   * accepted by the hidden file input as well. If the dropped file is not an accepted type the drop zone
   * will not indicate a possible drop.
   */
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * The className is passed to the drop zone so it can be styled differently depending the specific needs.
   */
  className: PropTypes.string,
  /**
   * The aria-level for the Heading. Default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Controls whether the file dialog allows the user to select more than one file. Default is false.
   *
   * Note that the drop zone has no knowledge of how many files have been selected/dropped so it is your responsibility
   * to hide the drop zone if the limit has been reached.
   */
  multiple: PropTypes.bool,
  /**
   * A callback called when a files was dropped or selected manually.
   */
  onFileAdded: PropTypes.func.isRequired,
  /**
   * Flag to show the file upload icon. Default is true.
   */
  showIcon: PropTypes.bool,
};

export default FileDropZone;
