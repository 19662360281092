//
// Locale (inc. angular translate and tmhDynamicLocaleProvider) configuration
//
(function () {
  /**
   * @deprecated - Moved to src2/core/ready/localeReady.js
   */
  angular.module('binky.core.locale.ready').provider('localeReady', localeReady);

  /* @ngInject */
  function localeReady(
    $translateMessageFormatInterpolationProvider,
    $translateProvider,
    binkySrc2,
    DEFAULT_LANGUAGE,
    LOCALE_LANGUAGE_MAP,
    localeProvider,
    tmhDynamicLocaleProvider
  ) {
    const initInjector = angular.injector(['ng']);
    const $q = initInjector.get('$q');
    const supportedLanguages = localeProvider.getSupportedLanguageKeys();

    let defaultLanguageLoaded;
    const translateLoaderOptions = {
      getStringFileUrl: localeProvider.getStringFileUrl,
      setDefaultLanguageLoaded,
    };

    const configDeferred = $q.defer();

    this.configure = configure;
    this.whenConfigReady = whenConfigReady;
    this.$get = $get;

    ////////////

    function configure() {
      // Set language strings file loader.
      $translateProvider.useLoader('localeAsyncFilesLoader', translateLoaderOptions);

      // This restricts the languages that $use will accept.
      // This will cause the fallbackLanguage to asynchronously start to load.
      $translateProvider
        .registerAvailableLanguageKeys(supportedLanguages, LOCALE_LANGUAGE_MAP)
        .fallbackLanguage([DEFAULT_LANGUAGE]);

      // http://angular-translate.github.io/docs/#/guide/19_security
      $translateProvider.useSanitizeValueStrategy('escapeParameters');

      // Add an interpolation option for messageFormat: https://github.com/messageformat/messageformat.js
      // This will allow us to use it for pluralization
      $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
      $translateMessageFormatInterpolationProvider.messageFormatConfigurer(
        messageFormatConfiguration
      );

      // Configure the dynamic locale provider. {{locale}} is one of the supportedLanguages keys.
      tmhDynamicLocaleProvider.localeLocationPattern('/angular-i18n/angular-locale_{{locale}}.js');

      // once we've completed, we resolve the promise
      configDeferred.resolve();

      return configDeferred.promise;
    }

    function whenConfigReady() {
      return configDeferred.promise;
    }

    // Configure the MessageFormat object used for that style of interpolation to our specifications
    function messageFormatConfiguration($mf) {
      // We need to disable plural key checks because other languages get the English string
      // briefly while waiting for translations to come back. This caused errors for the locales
      // that only use "other", since they'd also have "one" in the temporary (English) string.
      $mf.disablePluralKeyChecks();
    }

    ////////////

    /* @ngInject */
    function $get(
      $log,
      $rootScope,
      $translate,
      $window,
      _,
      amMoment,
      configurationReady,
      locale,
      SELECTED_LOCALE_KEY,
      storageUtils,
      tmhDynamicLocale
    ) {
      const runDeferred = $q.defer();
      const services = {
        run,
        setDefaultLanguageLoaded, // hook for testing
        whenRunReady,
      };

      return services;

      ////////////

      function run() {
        let requestKey;

        const configData = _.get(configurationReady.getConfig(), 'ready.locale', {});
        const env = _.get(configurationReady.getConfig(), 'name', {});

        localeProvider.updateSupportedLanguageList(env);
        const supportedLanguagesList = localeProvider.getSupportedLanguageKeys();

        // overriding the registry set in the configure method for supported languages is computed again based on the environment value.
        $translateProvider
          .registerAvailableLanguageKeys(supportedLanguagesList, LOCALE_LANGUAGE_MAP)
          .fallbackLanguage([DEFAULT_LANGUAGE]);

        // We allow the locale query parameter to override the browser locale.
        const overrideLocale = locale.getQueryParam();

        if (overrideLocale) {
          requestKey = overrideLocale;
          // when a URL locale is passed we store it for future use
          storageUtils.setLocalStorageItem(SELECTED_LOCALE_KEY, requestKey);
        } else if (storageUtils.getLocalStorageItem(SELECTED_LOCALE_KEY)) {
          requestKey = storageUtils.getLocalStorageItem(SELECTED_LOCALE_KEY);
        } else {
          // This will only choose one of the registered keys.
          // According to the docs this should be used at `your own risk` because browser support
          // varies.
          $translateProvider.determinePreferredLanguage();
          requestKey = $translate.preferredLanguage();
        }

        // Ensure requestedLocale is one of the supported ones.
        if (!_.includes(supportedLanguagesList, requestKey)) {
          // if it doesn't match, and the string is longer than
          // 2 characters, we try shortening it for a match
          if (requestKey.length > 2) {
            requestKey = requestKey.substr(0, 2);
          }
          // if it still isn't a match we fall back to the default
          if (!_.includes(supportedLanguagesList, requestKey)) {
            requestKey = DEFAULT_LANGUAGE;
          }
        }

        // Note the $translate and tmhDynamicLocale are both expecting one of the supported
        // language keys which is usually 2 lower-case characters except for Chinese which
        // includes the region.

        // Get the message strings and then get the locale formats that correspond to the language
        // key of the loaded strings.
        $translate.use(requestKey).then(onTranslateSuccess).catch(onTranslateError);

        function onTranslateSuccess(languageKey) {
          // eslint-disable-next-line no-unused-vars
          const src2Locale = new binkySrc2.services.Locale(languageKey);

          // Now get the locale formats that correspond to the loaded language/locale.
          tmhDynamicLocale
            .set(languageKey)
            .then(() => {
              onResolvedTranslateAndLocale(languageKey);
            })
            .catch(() => {
              onResolvedTranslateAndRejectedLocale(languageKey);
            });
        }

        function onTranslateError(languageKey) {
          if (languageKey !== DEFAULT_LANGUAGE && defaultLanguageLoaded) {
            $log.debug(`falling back to default language ${DEFAULT_LANGUAGE}`);
            storageUtils.removeLocalStorageItem(SELECTED_LOCALE_KEY);
            onTranslateSuccess(DEFAULT_LANGUAGE);
          } else {
            onRejectedTranslate(languageKey);
          }
        }

        // Got requested translation (or fallback language) and requested corresponding locale.
        function onResolvedTranslateAndLocale(languageKey) {
          amMoment.changeLocale(languageKey);
          // for the index.html html tag lang attribute
          $rootScope.activeBCP47Language = locale.getActiveLanguageBCP47Code();
          if (configData.configureCoralUI) {
            $window.Coral.i18n.locale = locale.getCoralLocale();
          }
          runDeferred.resolve(locale.getActiveLocaleCode());
        }

        // Got requested translation (or fallback language) and built-in English locale.
        function onResolvedTranslateAndRejectedLocale(languageKey) {
          amMoment.changeLocale(languageKey);
          // for the index.html html tag lang attribute
          $rootScope.activeBCP47Language = locale.getActiveLanguageBCP47Code();
          if (configData.configureCoralUI) {
            $window.Coral.i18n.locale = locale.getCoralLocale();
          }
          runDeferred.resolve(locale.getActiveLocaleCode());
        }

        // Error getting requested locale.
        function onRejectedTranslate(languageKey) {
          $log.debug(`can not load strings file for ${languageKey}`);
          storageUtils.removeLocalStorageItem(SELECTED_LOCALE_KEY);
          runDeferred.reject();
        }

        return runDeferred.promise;
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }

    ////////////

    function setDefaultLanguageLoaded(boolValue) {
      defaultLanguageLoaded = boolValue;
    }
  }
})();
