import {addDays, addMilliseconds, format, max, min} from 'date-fns';

import log from 'services/log';

import {toLocalISOString} from '../../utils/dateUtils';
import {FORMAT, MILLISECONDS_TO_END_DAY} from '../dateTime/DateTimeConstants';

import {FILTER_TYPE} from './InsightsConstants';

/**
 * @description Returns the default query params based on filters.
 *
 * @param {Object} filters - an object which contains a list of filter content. Ex: {content: [{key: 'eventType', type: 'DROPDOWN'}]}
 * @param {String} orgId - the org id
 *
 * @returns {Object} the default query params
 */
function getDefaultQueryParams(filters, orgId) {
  const queryParams = {orgId};
  filters?.content?.forEach((content) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- @jwu to fix
    /* istanbul ignore else -- no action in else block */
    if (content.type === FILTER_TYPE.DATE_RANGE) {
      queryParams['filter[endDate]'] = getMaxISODate(content);
      queryParams['filter[startDate]'] = getMinISODate(content);
    } else if (content.type === FILTER_TYPE.DROPDOWN) {
      queryParams[`filter[${content.key}]`] = content.defaults?.value;
    }
  });
  return queryParams;
}

/**
 * @description Returns the default selected item for each dropdown contents.
 *
 * @param {Array<Object>} dropdownContents - a list of dropdown content. Ex: [{key: 'eventType', type: 'DROPDOWN'}]
 *
 * @returns {Object} the default selected item
 */
function getDefaultSelectedItem(dropdownContents) {
  const selectedItem = {};
  dropdownContents?.forEach((content) => {
    selectedItem[content.key] = content.defaults?.value;
  });
  return selectedItem;
}

/**
 * @description Returns the max date with YYYY-MM-DD format.
 *
 * @param {Object} dateRangeContent - the date range content.
 * Ex: {defaults: {maxDate: '2021-03-02'}, maxDate: '2021-03-01', type: 'DATE_RANGE'}
 *
 * @returns {String} the max date with YYYY-MM-DD format
 */

// eslint-disable-next-line complexity, @admin-tribe/admin-tribe/jsdoc-exported-functions -- It has cyclomatic complexity of 11, needs refactoring
function getMaxDate(dateRangeContent) {
  const {maxDate, maxDateFromNow} = dateRangeContent?.defaults || dateRangeContent || {};
  if (!maxDate && maxDateFromNow === undefined) {
    // set to today if dateRangeContent is defined in metadata
    return dateRangeContent ? format(new Date(), FORMAT.DATEFNS.DATE) : undefined;
  }

  // We need a maxDateFromNow || 0 because we might get undefined values for maxDateFromNow
  // In which case addDays function, will not default the second argument to 0, as moment does
  // The addDays will try to add undefined to a new Date which will result in an Invalid Date
  const dateFromRange = addDays(new Date(), maxDateFromNow || 0);
  // This is defensive against having at this point in the code an undefined value for maxDate
  // If it's undefined we default to a new Date, otherwise we concatenate the string with the TZ
  // to get the start of the day
  const specifiedDate = maxDate ? new Date(`${maxDate}T00:00:00`) : new Date();

  return maxDate && maxDateFromNow !== undefined
    ? format(min([specifiedDate, dateFromRange]), FORMAT.DATEFNS.DATE)
    : maxDate || format(dateFromRange, FORMAT.DATEFNS.DATE);
}

/**
 * @description Returns the max date with ISO string format.
 *
 * @param {Object} dateRangeContent - the date range content. Ex: {maxDate: '2021-03-01', type: 'DATE_RANGE'}
 * @param {String} date - the date with YYYY-MM-DD format
 *
 * @returns {String} the max date with local ISO string format. Ex: 2021-03-26T23:59:59.999-07:00
 */
function getMaxISODate(dateRangeContent, date) {
  const maxDate = date || getMaxDate(dateRangeContent);
  // using endOfDate/setHours(23,59,59,999) will set to the end day with UTC but we want local time
  // set maxDate to the end of the day and then convert to local ISO string
  return toLocalISOString(addMilliseconds(new Date(maxDate), MILLISECONDS_TO_END_DAY));
}

/**
 * @description Returns the min date with YYYY-MM-DD format.
 *
 * @param {Object} dateRangeContent - the date range content. Ex: {minDate: '2021-03-01', type: 'DATE_RANGE'}
 *
 * @returns {String} the min date with YYYY-MM-DD format
 */
// eslint-disable-next-line complexity, @admin-tribe/admin-tribe/jsdoc-exported-functions -- Cyclomatic complexity of 11, needs refactoring
function getMinDate(dateRangeContent) {
  const {minDate, minDateFromNow} = dateRangeContent?.defaults || dateRangeContent || {};
  if (!minDate && minDateFromNow === undefined) {
    if (dateRangeContent) {
      // only log error when dateRangeContent is defined in metadata
      log.error('Please define minDate or minDateFromNow');
    }
    return undefined;
  }

  const dateFromRange = addDays(new Date(), minDateFromNow || 0);
  const specifiedDate = minDate ? new Date(`${minDate}T00:00:00`) : new Date();

  return minDate && minDateFromNow !== undefined
    ? format(max([specifiedDate, dateFromRange]), FORMAT.DATEFNS.DATE)
    : minDate || format(dateFromRange, FORMAT.DATEFNS.DATE);
}

/**
 * @description Returns the min date with ISO string format.
 *
 * @param {Object} dateRangeContent - the date range content. Ex: {minDate: '2021-03-01', type: 'DATE_RANGE'}
 * @param {String} date - the date with YYYY-MM-DD format
 *
 * @returns {String} the min date with local ISO string format. Ex: 2021-03-26T00:00:00.000-07:00
 */
function getMinISODate(dateRangeContent, date) {
  const minDate = date || getMinDate(dateRangeContent);
  return toLocalISOString(new Date(minDate));
}

export {
  getDefaultQueryParams,
  getDefaultSelectedItem,
  getMaxDate,
  getMaxISODate,
  getMinDate,
  getMinISODate,
};
