(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.directories.directory-type')
    .filter('binkyDirectoryTypeLabel', binkyDirectoryTypeLabel);

  /* @ngInject */
  function binkyDirectoryTypeLabel($translate, _) {
    return getDirectoryType;

    function getDirectoryType(type, options = {}) {
      const casing = options.format === 'uppercase' ? 'uppercase' : 'lowercase';
      return $translate.instant(
        `binky.widgets.directories.directoryType.${casing}.${_.toLower(type)}`
      );
    }
  }
})();
