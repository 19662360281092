import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import orderBy from 'lodash/orderBy';

import etlaUsageReport from 'api/etla-usage-report/etlaUsageReport';
import EtlaUsageReportContractAndProductBundleList from 'models/etlaUsageReport/EtlaUsageReportContractAndProductBundleList';
import log from 'services/log';
import {PRODUCT_BUYING_PROGRAM} from 'services/product/ProductConstants';
import ProductList from 'services/product/ProductList';

import {DATA_POINT_TYPES, USAGE_REPORT_DATA, USAGE_REPORT_ERROR} from './foxManagerConstants';

/**
 * @description method to modify the datapoints to exclude inactive period data
 *     and add them to separate list contractInactiveProvisionedQty contains
 *     values for inactive period datapoints with algorithm which sets datapoint
 *     to next or previous valid value.
 * @param {Object[]} chartDataPoints - Array of datapoint objects
 * @returns {Object[]} Array of chart data points with the inactive periods
 *     removed, an array of provisioned quantities for the inactive periods, and
 *     a flag indicating whether an inactive period was detected.
 */
function getActiveAndInactiveDataPoints(chartDataPoints) {
  const provisionedQuantitiesForInactivePeriods = [];
  let chartDataPointsCopy = cloneDeep(chartDataPoints);
  chartDataPointsCopy = chartDataPointsCopy.map((dataPoint) => {
    const dataPointCopy = cloneDeep(dataPoint);
    if (dataPointCopy.delegatedQty === -1) {
      dataPointCopy.delegatedQty = null;
      dataPointCopy.provisionedQty = null;
    }
    return dataPointCopy;
  });
  chartDataPointsCopy.forEach((dataPoint) => {
    if (dataPoint.provisionedQty === null) {
      provisionedQuantitiesForInactivePeriods.push(0);
    } else {
      provisionedQuantitiesForInactivePeriods.push(null);
    }
  });
  const inactivePeriodPresent = provisionedQuantitiesForInactivePeriods.some((item) =>
    isNumber(item)
  );
  return [chartDataPointsCopy, provisionedQuantitiesForInactivePeriods, inactivePeriodPresent];
}

/**
 * @description method to fetch the etlaUsageReport annual dataPoints
 * @param {Object} options - options object
 * @param {String} options.contractId - the contract id for the etlaUsageReport
 * @param {String} options.endDate - the end date for the annual duration of
 *     the etlaUsageReport query
 * @param {String} options.licenseId - the license id for the etlaUsageReport
 *     product
 * @param {String} options.orgId - the org id for the etlaUsageReport
 * @param {String} options.startDate - the start date for the annual duration
 *     of the etlaUsageReport query
 * @returns {Promise<Object[]>} DataPoints promise for the etlaUsageReport
 *     widget in binky-ui to display the chart dataPoints which based on the
 *     productId and dropdown period selected
 */
function getAnnualDataPoints(options) {
  return fetchDataPoints({...options, type: 'annual'});
}

/**
 * @async
 * @description method to fetch initial list of contract information for etlaUsageReport
 * @param {String} orgId - the org id for the etlaUsageReport
 * @returns {Object[]} Array of initial contract information for an orgId for etlaUsageReport
 * @throws {Object} Axios error response during the api invocations
 */
async function getInitialContractInfoList(orgId) {
  try {
    const contractAndProductBundleList = await EtlaUsageReportContractAndProductBundleList.get({
      orgId,
    });
    return contractAndProductBundleList?.items;
  } catch (error) {
    log.error(`Failed to fetch initial contract list info for ${orgId}`, error);
    throw error;
  }
}

/**
 * @async
 * @description method to fetch the etlaUsageReport initialData
 * @param {String} orgId - the org id for the etlaUsageReport
 * @returns {Object} InitialData object for the etlaUsageReport widget in
 *     binky-ui to display the initial data which wraps timePeriods,
 *     currentYear_dataPoints, productList, contractId and orgId. If
 *     currentYear_dataPoints were not found will display initial data
 *     which wraps timePeriods, productList, contractId and orgId.
 * @throws {Object} Axios error response during the api invocations
 */
async function getInitialData(orgId) {
  try {
    const productListItems = await Promise.all([
      ProductList.get({includeExpired: true, includeInactive: true, orgId}),
      EtlaUsageReportContractAndProductBundleList.get({orgId}),
    ]);
    let productListWithEtlaType = [];
    if (productListItems[0].items) {
      productListWithEtlaType = productListItems[0].items.filter(
        (item) => item.buyingProgram === PRODUCT_BUYING_PROGRAM.ETLA
      );
    }
    const bundleProductList = productListItems[1]?.items[0]?.products?.products;
    const productsTableData = setUpEtlaProductList(bundleProductList, productListWithEtlaType);
    const contractId = productListItems[1]?.items[0]?.contractId;
    const endDate = productListItems[1]?.items[0]?.endDate;
    const graceEndDate = productListItems[1]?.items[0]?.graceEndDate;
    const postGraceEndDate = productListItems[1]?.items[0]?.postGraceEndDate;
    const timePeriods = (await etlaUsageReport.getTimePeriods(contractId, orgId)).data;
    const initialData = {
      contractId,
      endDate,
      graceEndDate,
      orgId,
      postGraceEndDate,
      productsTableData,
      timePeriods,
    };
    const dataPointResultObject = await getInitialChartDataPoints(initialData);
    Object.assign(initialData, dataPointResultObject);
    return initialData;
  } catch (error) {
    log.error('Failed to fetch data from report: ', error);
    throw error;
  }
}

/**
 * @async
 * @description method to fetch the etlaUsageReport initialData for contractId
 * @param {String} contractId - the contract id for the etlaUsageReport
 * @param {String} orgId - the org id for the etlaUsageReport
 * @returns {Object} InitialData object for the etlaUsageReport widget in
 *     binky-ui to display the initial data for contract id which wraps timePeriods,
 *     currentYear_dataPoints, productList, contractId and orgId. If
 *     currentYear_dataPoints were not found will display initial data
 *     which wraps timePeriods, productList, contractId and orgId.
 * @throws {Object} Axios error response during the api invocations
 */
async function getInitialDataForContractId(contractId, orgId) {
  try {
    const productListItems = await Promise.all([
      ProductList.get({contractId, includeExpired: true, includeInactive: true, orgId}),
      EtlaUsageReportContractAndProductBundleList.get({orgId}),
    ]);
    const currentContractProductListItem = productListItems[1].items.find(
      (item) => item.contractId === contractId
    );
    let productListWithEtlaType = [];
    if (productListItems[0].items?.length > 0) {
      productListWithEtlaType = productListItems[0].items.filter(
        (item) => item.buyingProgram === PRODUCT_BUYING_PROGRAM.ETLA
      );
    }
    const bundleProductList = currentContractProductListItem?.products?.products;
    let productsTableData = [];
    if (bundleProductList !== undefined) {
      productsTableData = setUpEtlaProductList(bundleProductList, productListWithEtlaType);
    }
    const {endDate, graceEndDate, postGraceEndDate} = currentContractProductListItem || {};
    const timePeriods = (await etlaUsageReport.getTimePeriods(contractId, orgId)).data;
    const initialData = {
      contractId,
      endDate,
      graceEndDate,
      orgId,
      postGraceEndDate,
      productsTableData,
      timePeriods,
    };
    const dataPointResultObject = await getInitialChartDataPoints(initialData);
    Object.assign(initialData, dataPointResultObject);
    return initialData;
  } catch (error) {
    log.error('Failed to fetch data from report: ', error);
    throw error;
  }
}

/**
 * @description method to fetch the etlaUsageReport monthly dataPoints
 * @param {Object} options - options object
 * @param {String} options.contractId - the contract id for the etlaUsageReport
 * @param {String} options.endDate - the end date for the monthly duration of
 *     the etlaUsageReport query
 * @param {String} options.licenseId - the license id for the etlaUsageReport
 *     product
 * @param {String} options.orgId - the org id for the etlaUsageReport
 * @param {String} options.startDate - the start date for the monthly duration
 *     of the etlaUsageReport query
 * @returns {Promise} DataPoints promise for the etlaUsageReport widget in
 *     binky-ui to display the chart dataPoints which based on the productId
 *     and dropdown period selected .
 */
function getMonthlyDataPoints(options) {
  return fetchDataPoints({...options, type: 'monthly'});
}

////////////////

/**
 * @async
 * @description method to fetch the etlaUsageReport monthly/annual dataPoints
 * @param {Object} options - options object
 * @param {String} options.contractId - the contract id for the etlaUsageReport
 * @param {String} options.endDate - the end date for the monthly/annual
 *     duration of the etlaUsageReport query
 * @param {String} options.licenseId - the license id for the etlaUsageReport
 *     product
 * @param {String} options.orgId - the org id for the etlaUsageReport
 * @param {String} options.startDate - the start date for the monthly/annual
 *     duration of the etlaUsageReport query
 * @param {String} options.type - the period type 'annual' or 'monthly' for
 *     the etlaUsageReport
 * @returns {Object[]} Array of data point objects for the criteria specified
 *     in the options object
 * @throws {String} Message indicating what kind of error took place. One of
 *     the following:
 *     - USAGE_REPORT_DATA.NOT_FOUND_STATUS.BEFORE_START_DATE
 *     - USAGE_REPORT_DATA.NOT_FOUND_STATUS.AFTER_START_DATE
 *     - 'otherError'
 */
async function fetchDataPoints(options) {
  const {contractId, licenseId, orgId, ...params} = options;
  try {
    const dataPointsResponse = await etlaUsageReport.getDataPoints(
      contractId,
      licenseId,
      orgId,
      params
    );
    if (isInvalidDataPointsResponse(dataPointsResponse.data)) {
      throw USAGE_REPORT_ERROR.EMPTY_DATA_POINTS;
    }
    dataPointsResponse.data.dataPoints.sort((a, b) => a.eventTimestamp - b.eventTimestamp);
    return dataPointsResponse.data;
  } catch (error) {
    throw getNoDataPointErrorMessage(options.endDate, error);
  }
}

/**
 * @async
 * @description method to fetch the initial chart data points
 * @param {Object} productAndDurationData - object containing product and
 *     duration data
 * @param {String} productAndDurationData.contractId - the contract id for
 *     the etlaUsageReport
 * @param {String} productAndDurationData.orgId - the org id for the
 *     etlaUsageReport
 * @param {Object[]} productAndDurationData.productsTableData - array
 *     containing info about the products
 * @param {Object[]} productAndDurationData.timePeriods - array containing
 *     startDate and endDate of the monthly and annual time periods
 * @returns {Object} The object would contain dataPoints field (if success) or
 *     dataPointsStatus field (if failure)
 */
async function getInitialChartDataPoints(productAndDurationData) {
  if (productAndDurationData.productsTableData.length === 0) {
    return {};
  }

  const currentTimeInSecs = Math.floor(Date.now() / 1000);

  const currentYear =
    productAndDurationData.timePeriods.find(
      (timePeriod) =>
        timePeriod.startDate <= currentTimeInSecs && timePeriod.endDate >= currentTimeInSecs
    ) || productAndDurationData.timePeriods[productAndDurationData.timePeriods.length - 1];

  try {
    const dataPoints = (
      await etlaUsageReport.getDataPoints(
        productAndDurationData.contractId,
        productAndDurationData?.productsTableData[0]?.bundleProduct?.licenseId,
        productAndDurationData.orgId,
        {
          endDate: currentYear.endDate,
          startDate: currentYear.startDate,
          type: DATA_POINT_TYPES.ANNUAL,
        }
      )
    ).data;

    if (isInvalidDataPointsResponse(dataPoints)) {
      throw USAGE_REPORT_ERROR.EMPTY_DATA_POINTS;
    }

    dataPoints.dataPoints.sort((a, b) => a.eventTimestamp - b.eventTimestamp);
    return {dataPoints};
  } catch (error) {
    const dataPointsStatus = getNoDataPointErrorMessage(currentYear.endDate, error);
    log.error(
      `Failed to fetch chart datapoints for org id: ${productAndDurationData.orgId}, contract id: ${productAndDurationData.contractId}, status: ${dataPointsStatus}`
    );
    return {dataPointsStatus};
  }
}

/**
 * @description method to fetch the appropriate error message when there is a
 *     error while fetching data points
 * @param {Number} endDate - The end date of data points query in epoch seconds
 * @param {Object | String} error - Error while fetching data points
 * @returns {String} - The appropriate error message
 */
function getNoDataPointErrorMessage(endDate, error) {
  if (error === USAGE_REPORT_ERROR.EMPTY_DATA_POINTS || error.response.status === 404) {
    return endDate < USAGE_REPORT_DATA.AVAILABLE_START_DATE
      ? USAGE_REPORT_DATA.NOT_FOUND_STATUS.BEFORE_START_DATE
      : USAGE_REPORT_DATA.NOT_FOUND_STATUS.AFTER_START_DATE;
  }
  return 'otherError';
}

/**
 * @description Check validity of data points response(it could be empty)
 * @param {Object} dataPointsResponse - The response body of data points API
 *     response
 * @returns {Boolean} - Whether the data points response is valid or not
 */
function isInvalidDataPointsResponse(dataPointsResponse) {
  return !dataPointsResponse || isEmpty(dataPointsResponse.dataPoints);
}

/**
 * @description method to combine the product and bundle product lists
 * @param {Object[]} bundleProductList - Array of product bundle objects from
 *     EtlaUsageReportContractAndProductBundleList
 * @param {Object[]} productList - Array of product objects from ProductList
 * @returns {Object[]} - Merged list after sorting
 */
function setUpEtlaProductList(bundleProductList, productList) {
  const productsTableData = [];

  productList.forEach((product) => {
    const bundleProduct = bundleProductList.find((listElem) => listElem.licenseId === product.id);
    if (bundleProduct !== undefined && bundleProduct.deleted === true) {
      bundleProduct.qty = bundleProduct.qty ?? {};
      Object.assign(bundleProduct.qty, {delegatedQty: '', provisionedQty: ''});
    }
    productsTableData.push({
      bundleProduct,
      product,
      shouldRenderChartInfo: shouldRenderChartInfo(bundleProduct, product),
    });
    return productsTableData;
  });
  const sortedProductTableDataBasedOnDelegatedQty = orderBy(
    productsTableData,
    (obj) => obj?.bundleProduct?.qty?.delegatedQty ?? Number.NEGATIVE_INFINITY,
    ['desc']
  );
  return orderBy(
    sortedProductTableDataBasedOnDelegatedQty,
    (obj) => obj?.bundleProduct?.deleted ?? false,
    ['asc']
  );
}

/**
 * @description method to decide whether chart has to be rendered for a product
 * @param {Object} bundleProduct -  Product bundle from
 *     EtlaUsageReportContractAndProductBundleList
 * @param {Object} product - Product info from ProductList
 * @returns {Boolean} - Whether chart has to be rendered or not.
 */
function shouldRenderChartInfo(bundleProduct, product) {
  return (
    bundleProduct &&
    !product.hasUnlimitedLicenses() &&
    !product.isFeatureRestrictedLicense() &&
    !product.isDeviceLicense()
  );
}

export {
  getActiveAndInactiveDataPoints,
  getAnnualDataPoints,
  getInitialContractInfoList,
  getInitialData,
  getInitialDataForContractId,
  getMonthlyDataPoints,
};
