const PAGE_SIZES = [
  {id: 10, label: '10'},
  {id: 20, label: '20'},
  {id: 50, label: '50'},
  {id: 100, label: '100'},
];

const PAGE_SIZES_MAX_50 = [
  {id: 10, label: '10'},
  {id: 20, label: '20'},
  {id: 50, label: '50'},
];

const DEFAULT_PAGE_SIZE = PAGE_SIZES[1].id;

const PaginationConstants = {
  DEFAULT_PAGE_SIZE,
  PAGE_SIZES,
  PAGE_SIZES_MAX_50,
};

export default PaginationConstants;
