import {Content, IllustratedMessage} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

/**
 * A component that will display error screen if no product is found corresponding
 * to the search query in productTableData.
 */
const NoProductFoundErrorScreen = () => (
  <IllustratedMessage>
    <Content>
      <FormattedMessage id="binky.widgets.reports.productSearch.noResults" />
    </Content>
  </IllustratedMessage>
);

export default NoProductFoundErrorScreen;
