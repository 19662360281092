/**
 * @description Get the size of our custom icons used for avatars and product profiles.
 *
 * @param {String} The size in px.
 * @throws {Error} if size not recognized
 */
function getCustomAvatarSize(size) {
  switch (size) {
    case 'XXS':
      return '16px';
    case 'XS':
      return '24px';
    case 'S':
      return '40px';
    case 'M':
      return '56px';
    case 'L':
      return '72px';
    case 'XL':
      return '88px';
    case 'XXL':
      return '104px';
    default:
      throw new Error('invalid avatar size');
  }
}

// eslint-disable-next-line import/prefer-default-export -- utils
export {getCustomAvatarSize};
