/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {
  AvailableTypeList,
  Locale,
  MEMBER_TYPE,
  OrganizationUser,
  feature,
} from '@admin-tribe/binky';
import {TextField} from '@adobe/react-spectrum';
import {AvailableTypeList as PandoraAvailableTypeList} from '@pandora/data-model-available-type';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import CountryPicker from 'common/components/country-picker/CountryPicker';
import IdTypePicker from 'common/components/id-type-picker/IdTypePicker';
import {isT2EUser} from 'features/member/memberDisplayUtils';

import {useAddUserFormContext} from '../AddUserFormContext';
import ReadonlyFields from '../readonly-fields/AddUserFormReadonlyFields';

import './AddUserFormEditableFields.pcss';

const INPUT_WIDTH = '192px';
const INPUT_MARGIN_END = '16px';
const EDITABLE_FIELDS = ['countryCode', 'email', 'firstName', 'lastName', 'userName'];
const NAME_MAX_LENGTH = 255;

/**
 * Internal AddUserForm component rendering the editable fields for the selected user
 */
// eslint-disable-next-line complexity -- over max
const AddUserFormEditableFields = ({availableTypeList, id, onFormChange, orgUser}) => {
  const intl = useIntl();

  const {defaultCountryCode} = useAddUserFormContext();

  const [userName, setUserName] = React.useState(orgUser.userName || orgUser.email);
  const isSurnameFirstNameLocale = Locale.get().isSurnameFirstNameLocale;
  const [selectedUserType, setSelectedUserType] = React.useState(orgUser.type);

  const findExistingUserForUserType = React.useCallback(
    (type) => {
      if (!availableTypeList) {
        return false;
      }

      const availableType = availableTypeList.items.find(
        (item) =>
          item.available &&
          item.userType === type &&
          !!item.existingUser &&
          item.existingUser.email === orgUser.email
      );
      return availableType?.existingUser;
    },
    [availableTypeList, orgUser.email]
  );

  const isCountryCodeDefined = React.useCallback(
    (countryCode) => countryCode && countryCode !== 'UD',
    []
  );

  const hasExistingUserWithCountryDefinedForType = React.useCallback(
    (type) => {
      const existingUser = findExistingUserForUserType(type);
      return existingUser && isCountryCodeDefined(existingUser.countryCode);
    },
    [isCountryCodeDefined, findExistingUserForUserType]
  );

  const hasT2EUserWithAuthenticatingDomainTypes = React.useCallback(
    (requiredByIdTypes) => {
      const type2EAvailableType = availableTypeList?.items.find(
        (item) => item.userType === MEMBER_TYPE.TYPE2E
      );

      const t2eAuthenticatingDomainType =
        type2EAvailableType &&
        (type2EAvailableType.trustedDomainType ?? type2EAvailableType.authenticatingDomainType);
      return requiredByIdTypes.includes(t2eAuthenticatingDomainType);
    },
    [availableTypeList]
  );

  const displayExistingUserNotInDirectory = React.useMemo(() => {
    const existingUser = findExistingUserForUserType(selectedUserType);

    if (existingUser) {
      // reload existingUser's details on type change
      EDITABLE_FIELDS.forEach((field) => onFormChange(field, existingUser[field]));
    }

    return existingUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Do not recompute when the callback function onFormChange changes
  }, [findExistingUserForUserType, selectedUserType]);

  const isSSOUserNameRequired = React.useMemo(() => {
    if (
      !availableTypeList &&
      ((isT2EUser(orgUser) && orgUser.authenticatingAccount.type === MEMBER_TYPE.TYPE3) ||
        selectedUserType === MEMBER_TYPE.TYPE3)
    ) {
      return true;
    }

    return (
      // T3 requires a userName if the user is not an existing directory user
      (!findExistingUserForUserType(selectedUserType) && selectedUserType === MEMBER_TYPE.TYPE3) ||
      // T2E requires a userName only if the authenticating domain is linked to T3
      (selectedUserType === MEMBER_TYPE.TYPE2E &&
        hasT2EUserWithAuthenticatingDomainTypes([MEMBER_TYPE.TYPE3]))
    );
  }, [
    availableTypeList,
    findExistingUserForUserType,
    hasT2EUserWithAuthenticatingDomainTypes,
    orgUser,
    selectedUserType,
  ]);

  const areFirstNameAndLastNameRequired = React.useMemo(
    () => selectedUserType && !findExistingUserForUserType(selectedUserType),
    [findExistingUserForUserType, selectedUserType]
  );

  const isCountryRequired = React.useMemo(() => {
    if (!availableTypeList) {
      return (
        (isT2EUser(orgUser) && orgUser.authenticatingAccount.type !== MEMBER_TYPE.TYPE1) ||
        (selectedUserType !== MEMBER_TYPE.TYPE1 && selectedUserType !== MEMBER_TYPE.TYPE2E)
      );
    }

    return (
      selectedUserType &&
      // T1 does not require a country
      selectedUserType !== MEMBER_TYPE.TYPE1 &&
      // T2E requires a country only if the authenticating domain is linked to T2 or T3
      (selectedUserType !== MEMBER_TYPE.TYPE2E ||
        hasT2EUserWithAuthenticatingDomainTypes([MEMBER_TYPE.TYPE2, MEMBER_TYPE.TYPE3])) &&
      // T2 or T3 requires a country if the user is not an existing directory user
      (!findExistingUserForUserType(selectedUserType) ||
        // ... or, the existing/associated directory user has no country
        !hasExistingUserWithCountryDefinedForType(selectedUserType))
    );
  }, [
    availableTypeList,
    findExistingUserForUserType,
    hasExistingUserWithCountryDefinedForType,
    hasT2EUserWithAuthenticatingDomainTypes,
    orgUser,
    selectedUserType,
  ]);

  const showEmailField = React.useMemo(
    () => !availableTypeList && selectedUserType !== MEMBER_TYPE.TYPE1,
    [selectedUserType, availableTypeList]
  );

  React.useEffect(() => {
    if (isSSOUserNameRequired) {
      onFormChange('userName', userName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger callback when value changes
  }, [userName]);

  React.useEffect(() => {
    onFormChange('type', selectedUserType);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger callback when value changes
  }, [selectedUserType]);

  const containsT2E = () =>
    availableTypeList?.items?.some((item) => item.userType === MEMBER_TYPE.TYPE2E);

  const canViewIdTypePicker =
    availableTypeList?.availableOnlyTypes?.length > 1 &&
    !(feature.isEnabled('temp_de_exclusion_40038') && containsT2E());

  return (
    <>
      {canViewIdTypePicker && (
        <FieldLabel
          label={intl.formatMessage({
            id: 'binky.common.addUserFormEditableFields.fieldLabel.idType',
          })}
          labelFor={`selector-ID-type-${id}`}
          styleName="id-type-item"
        >
          <IdTypePicker
            availableTypeList={availableTypeList}
            defaultUserType={orgUser.type}
            id={`selector-ID-type-${id}`}
            onChange={setSelectedUserType}
            required
          />
        </FieldLabel>
      )}

      {displayExistingUserNotInDirectory && (
        <ReadonlyFields orgUser={orgUser} showIdType={!canViewIdTypePicker} />
      )}

      <div styleName="second-row">
        {areFirstNameAndLastNameRequired && (
          <div
            data-testid={`name-inputs${isSurnameFirstNameLocale ? '-reverse' : ''}`}
            styleName={`name-inputs${isSurnameFirstNameLocale ? '-reverse' : ''}`}
          >
            <TextField
              // Since the specs for "autocomplete=off" allow for browsers to ignore it when
              // they interpret it as a potential "login" field (for better web security),
              // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
              autoComplete="nada"
              data-testid="first-name-field"
              defaultValue={orgUser.firstName}
              id={`textfield-first-name-${id}`}
              label={intl.formatMessage({
                id: 'binky.common.addUserFormEditableFields.fieldLabel.firstName',
              })}
              marginEnd={INPUT_MARGIN_END}
              maxLength={NAME_MAX_LENGTH}
              necessityIndicator="label"
              onChange={(value) => onFormChange('firstName', value)}
              width={INPUT_WIDTH}
            />
            <TextField
              // Since the specs for "autocomplete=off" allow for browsers to ignore it when
              // they interpret it as a potential "login" field (for better web security),
              // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
              autoComplete="nada"
              data-testid="last-name-field"
              defaultValue={orgUser.lastName}
              id={`textfield-last-name-${id}`}
              label={intl.formatMessage({
                id: 'binky.common.addUserFormEditableFields.fieldLabel.lastName',
              })}
              marginEnd={INPUT_MARGIN_END}
              maxLength={NAME_MAX_LENGTH}
              necessityIndicator="label"
              onChange={(value) => onFormChange('lastName', value)}
              width={INPUT_WIDTH}
            />
          </div>
        )}
        {showEmailField && (
          <TextField
            // Since the specs for "autocomplete=off" allow for browsers to ignore it when
            // they interpret it as a potential "login" field (for better web security),
            // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
            autoComplete="nada"
            data-testid="email-field"
            defaultValue={orgUser.email}
            id={`textfield-email-${id}`}
            label={intl.formatMessage({
              id: 'binky.common.addUserFormEditableFields.fieldLabel.email',
            })}
            marginEnd={INPUT_MARGIN_END}
            onChange={(value) => onFormChange('email', value)}
            width={INPUT_WIDTH}
          />
        )}
        {isSSOUserNameRequired && (
          <FieldLabel
            label={intl.formatMessage({
              id: 'binky.common.addUserFormEditableFields.fieldLabel.ssoUsername',
            })}
            labelFor={`textfield-sso-username-${id}`}
          >
            <TextField
              // Since the specs for "autocomplete=off" allow for browsers to ignore it when
              // they interpret it as a potential "login" field (for better web security),
              // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
              autoComplete="nada"
              data-testid="sso-username-field"
              id={`textfield-sso-username-${id}`}
              marginEnd={INPUT_MARGIN_END}
              onChange={setUserName}
              value={userName}
              width={INPUT_WIDTH}
            />
          </FieldLabel>
        )}

        {isCountryRequired && (
          <CountryPicker
            data-testid="country-selector"
            defaultCountryCode={orgUser.countryCode || defaultCountryCode}
            id={`selector-country-${id}`}
            onChange={(value) => onFormChange('countryCode', value)}
            useLabel
          />
        )}
      </div>
    </>
  );
};

AddUserFormEditableFields.propTypes = {
  /**
   * The availableTypeList result for the AddUserForm's current member
   */
  availableTypeList: PropTypes.oneOfType([
    PropTypes.instanceOf(AvailableTypeList),
    PropTypes.instanceOf(PandoraAvailableTypeList),
  ]),
  /**
   * The unique row id of the parent form within the AddUserFormTable
   */
  id: PropTypes.string.isRequired,
  /**
   * Callback function to notify on form change.
   * This callback function is triggered with 2 params. The first param is the fieldId, and the second param is the new
   * value of that field.
   */
  onFormChange: PropTypes.func.isRequired,
  /**
   * Placeholder for OrganizationUser
   */
  orgUser: PropTypes.instanceOf(OrganizationUser).isRequired,
};

export default AddUserFormEditableFields;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
