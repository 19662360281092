(function () {
  /**
   * @deprecated Please use src2/core/model/migration/optionsPerStatus/OptionsPerStatusMigration.js
   *
   * @ngdoc factory
   * @name OptionsPerStatusMigration
   * @description This migration type allows for setting up behavior overrides to allow an empty organization to
   *  be set up with domains, directories, and users, prior to a product being added. This allows for
   *  very little downtime for users during the migration process.
   */
  angular.module('binky.core.migration').factory('OptionsPerStatusMigration', factory);

  /* @ngInject */
  function factory(binkySrc2) {
    return binkySrc2.models.migration.OptionsPerStatusMigration;
  }
})();
