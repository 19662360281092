import {getLocalStorageItem, setLocalStorageItem} from '@admin-tribe/binky';
import Alert from '@react/react-spectrum/Alert';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import styles from './PageBanner.pcss';
import PageBannerConstants from './PageBannerConstants';

/**
 * PageBanner is a widget with a button. The 'info' and 'help' modals will default to having a 'Close'
 * button which "closes" the banner by removing it from the page.
 */
const PageBanner = ({
  children,
  closeLabel,
  'data-testid': dataTestId = 'page-banner',
  header,
  onClose,
  removeWithKey,
  showDefaultButton = true,
  variant = PageBannerConstants.variant.INFO,
}) => {
  const intl = useIntl();
  const isInitiallyShown = !(removeWithKey && getLocalStorageItem(removeWithKey));
  const [showPageBanner, setShowPageBanner] = useState(isInitiallyShown);

  let buttonText = closeLabel;
  let onButtonClick = onClose;

  // Default to constructing a Close button for help and info variants.
  if (
    showDefaultButton &&
    (variant === PageBannerConstants.variant.HELP || variant === PageBannerConstants.variant.INFO)
  ) {
    if (!closeLabel) {
      buttonText = intl.formatMessage({id: 'binky.common.pageBanner.close'});
    }

    if (!onClose) {
      onButtonClick = () => {
        setShowPageBanner(false);
      };
    }
  }

  // Default close label when there is onClose passed in.
  if (onClose && !closeLabel) {
    buttonText = intl.formatMessage({id: 'binky.common.pageBanner.close'});
  }

  const handleClose = () => {
    onButtonClick?.();
    if (removeWithKey) {
      setLocalStorageItem(removeWithKey, true);
    }
  };

  return showPageBanner ? (
    <Alert
      className={styles['page-banner']}
      closeLabel={buttonText}
      data-testid={dataTestId}
      header={header}
      onClose={handleClose}
      variant={variant}
    >
      {children}
    </Alert>
  ) : null;
};

PageBanner.displayName = 'PageBanner';
PageBanner.propTypes = {
  /**
   * Translated body text of the page banner. If the body changes, the banner is re-created with the new body
   */
  children: PropTypes.node.isRequired,
  /**
   * Translated button text for close button, default is Close.
   */
  closeLabel: PropTypes.string,
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
  /**
   * Translated header text of the page banner.
   */
  header: PropTypes.string,
  /**
   * Callback function called when button is clicked. Defaults to closing the banner if showDefaultButton and INFO or HELP variant.
   */
  onClose: PropTypes.func,
  /**
   * If the key is present in local storage, then the banner will not be rendered. Otherwise, banner will be rendered.
   */
  removeWithKey: PropTypes.string,
  /**
   * Default true. If true and no buttonText specified, will add the default close button for variants 'info' and 'help'. If false, and no buttonText specified, there will be no button added.
   */
  showDefaultButton: PropTypes.bool,
  /**
   * Variant type of the page banner. One of: 'error', 'warning', 'success', 'help', 'info'.
   *   Defaults to 'info'.
   */
  variant: PropTypes.oneOf([
    PageBannerConstants.variant.ERROR,
    PageBannerConstants.variant.HELP,
    PageBannerConstants.variant.INFO,
    PageBannerConstants.variant.SUCCESS,
    PageBannerConstants.variant.WARNING,
  ]),
};

export default PageBanner;
