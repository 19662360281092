import React from 'react';

/**
 * Custom hook that controls all product role selections.
 *
 * @param {Function} onProductRolesChange - callback function to notify calls with selected product roles
 */
const useProductRoleSelection = ({onProductRolesChange = () => {}}) => {
  // Custom hook to track all products that have profiles with unique product roles
  const [uniqueProfileRoleProductIds, dispatchUniqueProfileRoleProductIds] = React.useReducer(
    (state, action) => {
      const newState = new Set(state);
      switch (action.type) {
        case 'SEPARATE_PROFILE_PILL':
          newState.add(action.productId);
          return newState;
        default:
          return state;
      }
    },
    new Set()
  );

  const [selectedUnsavedProductRoles, dispatchSelectedProductRoles] = React.useReducer(
    (state, action) => {
      const newState = state.filter(
        (productRole) =>
          productRole.product.id !== action.product.id ||
          productRole.licenseGroupId !== action.licenseGroupId
      );

      switch (action.type) {
        case 'UPDATE': {
          if (action.hasUnsavedChanges) {
            newState.push(action);
          }
          return newState;
        }
        case 'REMOVE':
          return newState;
        default:
          return state;
      }
    },
    []
  );

  React.useEffect(() => {
    onProductRolesChange(selectedUnsavedProductRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore onProductRoleChange callback function
  }, [selectedUnsavedProductRoles]);

  return {
    dispatchSelectedProductRoles,
    dispatchUniqueProfileRoleProductIds,
    selectedUnsavedProductRoles,
    uniqueProfileRoleProductIds,
  };
};

export default useProductRoleSelection;
