(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum Toast
   */
  angular
    .module('binky.widgets.common.toast')
    .constant('TOAST_TYPES', {
      /** A default, gray Toast. */
      DEFAULT: 'default',
      /** A red Toast */
      ERROR: 'error',
      /** A blue Toast */
      INFO: 'info',
      /** A green Toast */
      SUCCESS: 'success',
      /** A yellow Toast */
      WARNING: 'warning',
    })
    .constant('DEFAULT_TOAST_POSITION', 'left')
    // This is the time to live in milliseconds for all toasts.
    .constant('TOAST_TIME_TO_LIVE', 5000);
})();
