(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.products:BinkyProductAccordion
   * @description A product-accordion meant to wrap one or more 'product-accordion-item'.
   * The 'product-accordion-item's are to be transcluded in product-accordion-item-list
   *
   *   To transclude:
   *   <binky-product-accordion>
   *     <product-accordion-item-list>
   *        <binky-product-accordion-item product-name="Photoshop">
   *        </binky-product-accordion-item>
   *        <binky-product-accordion-item product-name="Illustrator">
   *        </binky-product-accordion-item>
   *     </product-accordion-item-list>
   *   </binky-product-accordion>
   */
  angular.module('binky.widgets.products.product-accordion').component('binkyProductAccordion', {
    controller,
    templateUrl: 'widgets/products/product-accordion/product-accordion.component.html',
    transclude: {
      'product-accordion-item-list': 'productAccordionItemList',
    },
  });

  function controller($element, $transclude, _) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      Coral.commons.ready(() => {
        const coralAccordion = $element[0].querySelector('.binky-coral-accordion');
        const coralItems = $element[0].querySelectorAll('binky-product-accordion-item');

        _.forEach(coralItems, (item) => {
          coralAccordion.appendChild(item);
        });

        // automatically open the first accordion item
        coralAccordion.querySelector('coral-accordion-item').selected = true;

        const allItemHeaderElems = coralAccordion.querySelectorAll(
          'coral-accordion-item ._coral-Accordion-itemHeader'
        );
        _.forEach(allItemHeaderElems, (headerElem) => {
          headerElem.addEventListener('click', handleHeaderClick);
        });
      });
    }

    // Coral does not recognize items are grouped together if <coral-accordion-item>'s are
    // not a direct child of <coral-accordion>.
    // This implements back the functionality that only 1 accordion item is open at a time
    function handleHeaderClick(event) {
      let item = event.currentTarget;
      // Alternative to .closest('coral-accordion-item') because IE does not support it
      while (item.tagName !== 'CORAL-ACCORDION-ITEM' && item.parentNode.nodeType === 1) {
        item = item.parentNode;
      }
      const coralAccordion = $element[0].querySelector('.binky-coral-accordion');
      const allAccItemElems = coralAccordion.querySelectorAll('coral-accordion-item');

      _.forEach(allAccItemElems, (itemElem) => {
        if (itemElem !== item && itemElem.classList.contains('is-open')) {
          itemElem.selected = false;
        }
      });
    }
  }
})();
