import PropTypes from 'prop-types';

/**
 * @description Method for checking if prop is required.
 *
 * @param {PropType} propType - a PropType validator.
 * @param {Function} predicate - a function which returns a boolean. The default is a function which returns false.
 * @returns {Boolean} - true if the property is required, else false.
 */
function requiredIf(propType, predicate = () => false) {
  return (props, propName, componentName) => {
    const test = predicate(props) ? propType.isRequired : propType;
    return PropTypes.checkPropTypes({[propName]: test}, props, propName, componentName);
  };
}

/**
 * @description Method for checking if at least one of aria-label,
 *     aria-labelledby, or label was provided AND the individual prop being
 *     checked is a of string type.
 *
 * @param {Object} props - List of all props supplied to a particular component.
 * @param {string} propName - Name of prop that will be checked for type.
 * @param {string} componentName - Name of the component.
 * @returns {Error|null} - Error if none of aria-label/aria-labelledby/label
 *     are set OR if the type of one of those is not a string, null otherwise.
 */
function requiredLabelPropsCheck(props, propName, componentName) {
  if (!props['aria-label'] && !props['aria-labelledby'] && !props.label) {
    return new Error(
      `None of aria-label, aria-labelledby, or label were provided in '${componentName}'. At least one is required.`
    );
  }
  const propValue = props[propName];
  if (propValue) {
    return typeof propValue === 'string' ? null : new Error(`${propName} must be of type string.`);
  }
  return null;
}

export {requiredIf, requiredLabelPropsCheck};
