const DELEGATION_GROUP_MIGRATION_STATUS = {
  MIGRATED: 'MIGRATED',
  NOT_MIGRATED: 'NOT_MIGRATED',
};
const IMS_ID_REGEX = /^[\da-z]+@adobeorg$/i;
const ORGANIZATION_DELETE_ERROR_CODE = {
  ORG_HAS_CONTRACTS: 'ORG_HAS_CONTRACTS',
  ORG_HAS_DIRECTORIES: 'ORG_HAS_DIRECTORIES',
  ORG_HAS_LICENSES: 'ORG_HAS_LICENSES',
  ORG_HAS_TRUSTS: 'ORG_HAS_TRUSTS',
  ORG_HAS_USERS: 'ORG_HAS_USERS',
};
const ORGANIZATION_EVENT = {
  CREATE: 'CreateOrganization',
  DELETE: 'DeleteOrganization',
  REFRESH: 'RefreshOrganization',
  UPDATE: 'UpdateOrganization',
};
const ORGANIZATION_GET_ERROR_CODE = {
  FORBIDDEN_BY_ROLE: 'FORBIDDEN_BY_ROLE',
  POLICY_OR_PERMISSION_FAILURE: 'POLICY_OR_PERMISSION_FAILURE',
};
const ORGANIZATION_LIST = {
  ERROR_CODE: {
    ACTIVE_ORG_NOT_SET: 'ORGANIZATION_LIST_ERROR_CODE_ACTIVE_ORG_NOT_SET',
  },
};
const ORGANIZATION_MARKET_SEGMENT = {
  COMMERCIAL: 'COMMERCIAL',
  EDUCATION: 'EDUCATION',
  GOVERNMENT: 'GOVERNMENT',
};
const ORGANIZATION_MARKET_SUBSEGMENT = {
  HIGHER_ED: 'HIGHER_ED',
  K_12: 'K_12',
  NON_PROFIT: 'NON_PROFIT',
};
const ORGANIZATION_MIGRATION_STATUS = {
  MIGRATED: 'MIGRATED',
  MIGRATING: 'MIGRATING',
  NOT_MIGRATED: 'NOT_MIGRATED',
};
const ORGANIZATION_SEARCH_TYPE = {
  ADMIN_EMAIL: 'adminEmail',
  ADMIN_ID: 'adminId',
  CLAIMED_DOMAIN: 'claimedDomain',
  CONTRACT_ID: 'contractId',
  CORE_SERVICES_TENANT_ID: 'coreServicesTenantId',
  ECC_END_USER_ID: 'eccEndUserId',
  EXACT_NAME: 'exactName',
  EXTERNAL_CONTRACT_ID: 'externalContractId',
  IMS_ORG_ID: 'imsOrgId',
  TOKENIZED_NAME: 'tokenizedName',
};
const ORGANIZATION_TYPE = {
  CLASSROOM: 'CLASSROOM',
  DEVELOPER: 'DEVELOPER',
  DIRECT: 'DIRECT',
  DISTRIBUTOR: 'DISTRIBUTOR',
  ENTERPRISE: 'ENTERPRISE',
  INDIRECT: 'INDIRECT',
  RESELLER: 'RESELLER',
};

export {
  DELEGATION_GROUP_MIGRATION_STATUS,
  IMS_ID_REGEX,
  ORGANIZATION_DELETE_ERROR_CODE,
  ORGANIZATION_EVENT,
  ORGANIZATION_GET_ERROR_CODE,
  ORGANIZATION_LIST,
  ORGANIZATION_MARKET_SEGMENT,
  ORGANIZATION_MARKET_SUBSEGMENT,
  ORGANIZATION_MIGRATION_STATUS,
  ORGANIZATION_SEARCH_TYPE,
  ORGANIZATION_TYPE,
};
