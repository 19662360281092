(function () {
  /**
   * @ngdoc module
   * @name binky.core.api.ready
   * @description module that defines the apiReady provider
   */
  angular.module('binky.core.api.ready', [
    'binky.core.api.interceptor',
    'binky.core.configuration.ready',
    'binky.core.lodash',
  ]);
})();
