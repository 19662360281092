import {useRef} from 'react';

/**
 * Basic hook that stores the ref to the given Store.
 *
 * @param {Function} initFn - Function to be called to initialize the store. initFn should return a
 *  Store or a subclass of a Store.
 */
const useStore = (initFn) => {
  const storeRef = useRef(null);
  if (!storeRef.current) {
    storeRef.current = initFn();
  }

  return storeRef.current;
};

export default useStore;
