import axios from 'axios';

import authentication from '../services/authentication';
import Locale from '../services/locale/Locale';

let clientId, includeRoles, url;

/**
 * @description Gets header parameters for API.
 *
 * @returns {Object} Object containing header parameters.
 */

const getHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for AOS API call');
  }
  const headers = {
    'Accept-Language': Locale.get().activeLocaleCode,
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };

  if (includeRoles) {
    headers['X-Include-Roles'] = includeRoles.join(',');
  }

  return headers;
};

/**
 * @description Configure AOS Offers APIs
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for AOS offers api
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Method for fetching offers by comma separated offer ids.
 *
 * @param {Object} params - Top level wrapper object containing params for the
 *     AOS call.
 *
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the requested offers.
 */
const getOffersByOfferId = ({...params}) => {
  const allParams = Object.assign(params, {locale: Locale.get().activeLocaleCode});
  const {offerIds, ...queryParams} = allParams;
  return axios.get(`${url}/offers/${offerIds}`, {
    headers: getHeaders(),
    params: queryParams,
  });
};

/**
 * @description Fetches offers by sku.
 *
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the offers that match the requested sku.
 */
const getOffersBySku = ({...params}) => {
  const allParams = Object.assign(params, {locale: Locale.get().activeLocaleCode});
  return axios.get(`${url}/offers:search.sku`, {
    headers: getHeaders(),
    params: allParams,
  });
};

const aosOffers = {
  configure,
  getOffersByOfferId,
  getOffersBySku,
};

export default aosOffers;
