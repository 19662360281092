(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc constant
   * @name ExtendedUserProfile constants
   * @description defines User Profile constants
   */
  angular
    .module('binky.core.extended-user-profile')
    .constant('EXTENDED_USER_PROFILE_CACHE_ID', 'ExtendedUserProfile');
})();
