import BaseMetrics from './BaseMetrics';

/**
 * New Relic implementation to track observability metrics
 */
class NewRelicMetrics extends BaseMetrics {
  /**
   * @description Constructor
   * @param {Object} options Unused
   */
  constructor(options) {
    super(options);

    // NR will persist the attribute for the whole session
    this.setCustomAttributes({env: this.env});
  }

  /**
   * @description Trigger PageAction with extra attributes, including the transition time
   * @param {Object} options
   * @param {Number} options.transitionTime Elapsed time from the start time to when all spans
   * are completed
   */
  onAllSpansCompleted({transitionTime}) {
    const newRelicAttributes = processCustomAttributes({
      ...this.currentRoute.observabilityRoute.routeParams,
      env: this.env,
      routeChangeType: this.currentRoute.routeChangeType,
      transitionTime,
    });

    // eslint-disable-next-line no-undef -- newrelic should be initialized by the host app
    globalThis?.newrelic?.addPageAction(
      `${this.currentRoute.observabilityRoute.routeName}`,
      newRelicAttributes
    );
  }

  /**
   * @description Set custom attributes that will be applicable for the current session.
   * Note: New Relic handles the attribute store
   * @param {Object} attributes Key-value pair, e.g. {env: 'prod', orgId: '123@AdobeOrg'}
   */
  // eslint-disable-next-line class-methods-use-this -- New Relic doesn't need to use 'this'
  setCustomAttributes(attributes) {
    const processedAttributes = processCustomAttributes(attributes);

    Object.keys(processedAttributes).forEach((key) => {
      // eslint-disable-next-line no-undef -- newrelic should be initialized by the host app
      globalThis?.newrelic?.setCustomAttribute(key, processedAttributes[key]);
    });
  }
}

/**
 * @description Prefix custom attributes with AAC_ to avoid
 * naming conflicts with New Relic's PageAction table
 * @param {Object} attributes key/value,
 * e.g. {orgId: '123@AdobeOrg', 'initialLoad': true, ...}
 * @returns {Object} A new object with AAC_ prefixed on the key,
 * e.g. {AAC_orgId: '123@AdobeOrg', 'AAC_initialLoad': true, ...}
 */
function processCustomAttributes(attributes) {
  const renamedAttributes = {};

  // Prefix all keys so they're easier to find in New Relic
  Object.keys(attributes).forEach((key) => {
    renamedAttributes[`AAC_${key}`] = attributes[key];
  });

  return renamedAttributes;
}

export default NewRelicMetrics;
