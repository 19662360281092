import {LinkedUserAccount, ORGANIZATION_TYPE, Organization} from '@admin-tribe/binky';
import {Item, ListBox, Text, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {
  filterLinkedOrganizations,
  filterOrganizations,
} from 'features/organization/getOrganizationUtils';

import Search from '../search/Search';
import OverlayWait from '../wait/OverlayWait';

import OrganizationSwitcherRow from './OrganizationSwitcherRow';

/**
 * OrganizationSwitcher: The org switcher widget
 */
const OrganizationSwitcher = ({
  activeOrgId,
  authenticatedUserId,
  filterToTypes,
  linkedUserAccounts,
  onChange,
  organizationItems,
  showBorder = true,
  width = 'size-5000',
}) => {
  let initialItemId;
  const intl = useIntl();
  const searchLabelId = useId();
  const [searchName, setSearchName] = useState('');
  const label = intl.formatMessage({id: 'binky.organizations.organizationSwitcher.search'});

  const organizations = organizationItems
    ? filterOrganizations({filterToTypes, organizationItems})
    : undefined;
  const processedLinkedAccounts = linkedUserAccounts
    ? filterLinkedOrganizations({filterToTypes, linkedUserAccounts})
    : undefined;

  if (processedLinkedAccounts) {
    if (activeOrgId) {
      const activeOrg = processedLinkedAccounts.find(
        (account) => account.id === activeOrgId && account.userId === authenticatedUserId
      );
      initialItemId = get(activeOrg, 'idx');
    }
  } else {
    initialItemId = activeOrgId;
  }

  const [selectedOrg, setSelectedOrg] = React.useState(new Set().add(initialItemId));

  const setActiveOrg = (orgId) => {
    const selectedItem = orgId.values().next().value;
    if (processedLinkedAccounts) {
      const idx = Number.parseInt(selectedItem, 10);
      const org = processedLinkedAccounts[idx];
      if (org) {
        onChange({orgId: org.id, userId: org.userId});
      }
    } else if (selectedItem) {
      onChange({orgId: selectedItem});
    }
    setSelectedOrg(orgId);
  };

  return (
    <OverlayWait showContent>
      <View
        backgroundColor="static-white"
        borderColor={showBorder ? 'dark' : 'transparent'}
        borderRadius={showBorder ? 'medium' : undefined}
        borderWidth={showBorder ? 'thin' : undefined}
        data-testid="org-switcher"
        padding="size-50"
        width={width}
      >
        <Search
          aria-label={label}
          focusOnShow
          label={label}
          minLength={0}
          onChange={setSearchName}
          onSubmit={setSearchName}
          width="100%"
        />
        {processedLinkedAccounts ? (
          <ListBox
            aria-label={label}
            id={searchLabelId}
            items={processedLinkedAccounts.filter((org) =>
              org.name.toLowerCase().includes(searchName.toLowerCase())
            )}
            maxHeight="size-2400"
            onSelectionChange={setActiveOrg}
            overflow="hidden auto"
            selectedKeys={selectedOrg}
            selectionMode="single"
            width="100%"
          >
            {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- @patriawa to refactor */}
            {(item) => (
              <Item key={item.idx} textValue={item.name}>
                <Text>
                  <OrganizationSwitcherRow processedLinkedAccount={item} />
                </Text>
                {item.pathName && (
                  <Text slot="description">
                    {intl.formatMessage(
                      {
                        id: 'binky.organizations.organizationSwitcher.globalAdmin.orgPath',
                      },
                      {pathName: item.pathName}
                    )}
                  </Text>
                )}
              </Item>
            )}
          </ListBox>
        ) : (
          <ListBox
            aria-label={label}
            items={organizations.filter((org) =>
              org.name.toLowerCase().includes(searchName.toLowerCase())
            )}
            maxHeight="size-2400"
            onSelectionChange={setActiveOrg}
            overflow="hidden auto"
            selectedKeys={selectedOrg}
            selectionMode="single"
            width="100%"
          >
            {(item) => (
              <Item key={item.id} textValue={item.name}>
                {item.name}
              </Item>
            )}
          </ListBox>
        )}
      </View>
    </OverlayWait>
  );
};

OrganizationSwitcher.propTypes = {
  /**
   * The ID of the active org.
   */
  activeOrgId: PropTypes.string,
  /**
   * The ID of current authenticated user.
   */
  authenticatedUserId: PropTypes.string,
  /**
   * The org types to filter to. If not provided we won't filter the organizations.
   * Example : ['ENTERPRISE', 'DIRECT']
   */
  filterToTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ORGANIZATION_TYPE))),
  /**
   * The linked user accounts to display for the switcher.
   */
  linkedUserAccounts: PropTypes.arrayOf(PropTypes.instanceOf(LinkedUserAccount)),
  /**
   * The callback function to register for the change event.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The organization items to display for the switcher.
   */
  organizationItems: PropTypes.arrayOf(PropTypes.instanceOf(Organization)),
  /**
   * To display the border outside of the switcher or not.
   */
  showBorder: PropTypes.bool,
  /**
   * The size of the OrganizationSwitcher.
   */
  width: PropTypes.string,
};

export default OrganizationSwitcher;
