(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilUsers.js
   *
   * @ngdoc service/provider
   * @name jilUsers
   * @description defines service to manage jil user resources
   */
  angular.module('binky.core.api.jil').provider('jilUsers', jilUsersProvider);

  let url;

  /* @ngInject */
  function jilUsersProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        avatar: getAvatarResource(),
        batchUsers: getUsersBatchResource(),
        delegationsUsers: getDelegationsUsersResource(),
        users: getUsersResource(),
      };

      return service;

      ///////////
      function getAvatarResource() {
        return $resource(
          `${url}/:orgId/users/:id/avatar`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            upload: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
            },
          }
        );
      }

      function getDelegationsUsersResource() {
        return $resource(`${url}/:orgId/users%3Asearch.delegations`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getUsersBatchResource() {
        return $resource(
          `${url}/:orgId/users%3Abatch`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchAdd: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }

      function getUsersResource() {
        return $resource(
          `${url}/:orgId/users/:id`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            bulkAdd: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'add-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkEdit: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'edit-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkOfferSwitchMigration: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'offer-switch-migration',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkRemove: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'remove-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkSwitchIdentity: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'switch-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            create: {
              method: 'POST',
            },
            export: {
              headers: {
                Accept: 'text/csv+user-all,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            exportCSVSwitch: {
              headers: {
                Accept: 'text/csv+user-identity-type,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            exportLicenseStatusReport: {
              headers: {
                Accept: 'text/csv+license-status-report,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
