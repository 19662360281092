/* eslint-disable react/no-unknown-property -- styleName not recognized */
import PropTypes from 'prop-types';
import React from 'react';
import './LinkButton.pcss';

/**
 * @deprecated - Use a React Spectrum Link with an onPress handler.
 *
 * LinkButton is a widget to display a hyperlink-style button control.
 */
const LinkButton = ({ariaLabel, ariaLabelledby, onClick, children}) => (
  <button
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledby}
    data-testid="link-button"
    onClick={onClick}
    styleName="link-button"
    type="button"
  >
    {children}
  </button>
);

LinkButton.propTypes = {
  ariaLabel: PropTypes.string,
  /**
   *  The id of the accessibility label for the LinkButton.
   */
  ariaLabelledby: PropTypes.string,
  /**
   * The content to display in the LinkButton.
   */
  children: PropTypes.node.isRequired,
  /**
   * Handler that is called when the LinkButton is clicked.
   */
  onClick: PropTypes.func.isRequired,
};

export default LinkButton;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
