import log from '../log';

const EVENT_SOURCES = {
  ANY: 'any',
  SRC: 'src',
  SRC2: 'src2',
};

const registeredHandlersBySource = {
  [EVENT_SOURCES.ANY]: [],
  [EVENT_SOURCES.SRC]: [],
  [EVENT_SOURCES.SRC2]: [],
};

const getHandlers = (source) => registeredHandlersBySource[source];

// The Event Bus replicates the functionality of root scope events in Angular.
// https://wiki.corp.adobe.com/display/BPS/Admin+Tribe+Event+Architecture+Migration
const eventBus = {
  /**
   * De-register an event handler function
   *
   * @param {Function} callbackFn - the function to de-register
   * @param {String} source - optional source codebase
   */
  deregisterEventHandler: (callbackFn, source = EVENT_SOURCES.ANY) => {
    registeredHandlersBySource[source] = getHandlers(source).filter(
      (registeredFn) => registeredFn !== callbackFn
    );
  },

  /**
   * Emit an event to all registered handlers
   *
   * @param {String} id - the identifier for this type of event
   * @param {Object} event - the event object to emit
   * @param {String} source - optional source codebase [EVENT_SOURCES.SRC | EVENT_SOURCES.SRC2]
   *                          (default is EVENT_SOURCES.SRC2)
   * @throws {Error} - Throws an error on invalid source.
   */
  emit: (id, event, source = EVENT_SOURCES.SRC2) => {
    const invokeHandler = (handler) => {
      if (source !== EVENT_SOURCES.SRC && source !== EVENT_SOURCES.SRC2) {
        throw new Error(`eventBus.emit called with invalid source '${source}'`);
      }
      try {
        handler(id, event);
      } catch (error) {
        log.error(`Failed to emit an event for ${id}`, error);
      }
    };

    getHandlers(EVENT_SOURCES.ANY).forEach(invokeHandler);
    getHandlers(source).forEach(invokeHandler);
  },

  /**
   * Check how many event handlers have been registered
   *
   * @param {String} source - optional source codebase
   * @returns {Number} the number of registered handlers
   */
  registeredEventHandlerCount: (source = EVENT_SOURCES.ANY) => getHandlers(source).length,

  /**
   * Register an event handler function to receive events
   *
   * @param {Function} callbackFn - the function to register for events
   * @param {String} source - optional source codebase
   */
  registerEventHandler: (callbackFn, source = EVENT_SOURCES.ANY) => {
    const handlers = getHandlers(source);
    if (!handlers.includes(callbackFn)) {
      handlers.push(callbackFn);
    }
  },
};

export {EVENT_SOURCES};
export default eventBus;
