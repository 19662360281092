import {JilModelList as PandoraJilModelList} from '@pandora/data-model-list';
import {
  LicenseGroup as PandoraLicenseGroup,
  Product as PandoraProduct,
} from '@pandora/data-model-product';
import {UserGroup as PandoraUserGroup} from '@pandora/react-data-model-member';
import cloneDeep from 'lodash/cloneDeep';
import {toJS} from 'mobx';

import BinkyProduct from 'services/product/Product';
import BinkyProductList from 'services/product/ProductList';
import BinkyLicenseGroup from 'services/product/license-group/LicenseGroup';
import BinkyUserGroup from 'services/users/user-group/UserGroup';

/**
 * Converts a binky LicenseGroup object to a Pandora LicenseGroup, or the reverse,
 * using a pre-converted Product object. Used to prevent infinite recursion.
 * @param {BinkyLicenseGroup | PandoraLicenseGroup} licenseGroup - The licenseGroup object to convert
 * @param {BinkyProduct | PandoraProduct} product - The converted product of the licenseGroup
 * @param {boolean} binkyToPandora - If true will convert the object from the binky to Pandora, otherwise from Pandora to binky
 * @returns A binky or Pandora LicenseGroup object, without the Product object
 */
const adaptProductLicenseGroup = (licenseGroup, product, binkyToPandora) => {
  const licenseGroupData = cloneDeep(licenseGroup);

  if (binkyToPandora) {
    return new PandoraLicenseGroup({...licenseGroupData, product});
  }
  // else if pandora-to-binky...
  return new BinkyLicenseGroup({...licenseGroupData, product});
};

/**
 * Converts a binky Product object to a Pandora Product, or the reverse
 * @param {BinkyProduct | PandoraProduct} product - The product object to convert (aliased in this file for clarity, but exists under the Product name in both repos)
 * @param {boolean} binkyToPandora - If true will convert the object from the binky to Pandora, otherwise from Pandora to binky
 * @param {boolean} cloneData - If true then the data will be cloned so that the original data does not get modified
 * @returns A binky or Pandora Product object
 * @throws {TypeError} - Will throw if the given Product object is already the intended type
 */
const adaptProduct = (product, binkyToPandora, cloneData) => {
  // toJS() to remove getter from mobx's observable()
  const unobservedData = binkyToPandora ? toJS(product) : product;
  const productData = cloneData ? cloneDeep(unobservedData) : unobservedData;

  if (binkyToPandora) {
    if (product instanceof PandoraProduct) {
      throw new TypeError('Already a Pandora Product!');
    }

    productData.fulfillableItems = productData.fulfillableItemList?.items;
    productData.tuples = productData.licenseTupleList?.items;

    delete productData.fulfillableItemList;

    const pandoraProduct = new PandoraProduct(productData);

    // Reconnect the license group pointers.
    pandoraProduct.licenseGroups = productData.licenseGroups?.map((licenseGroup) =>
      adaptProductLicenseGroup(licenseGroup, pandoraProduct, true)
    );
    pandoraProduct.licenseGroupSummaries = productData.licenseGroupSummaries?.map((licenseGroup) =>
      adaptProductLicenseGroup(licenseGroup, pandoraProduct, true)
    );

    return pandoraProduct;
  }

  if (product instanceof BinkyProduct) {
    throw new TypeError('Already a binky Product!');
  }

  productData.fulfillableItems = productData.fulfillableItems?.items;
  productData.licenseQuantities = productData.licenseQuantities?.items;
  productData.tuples = productData.licenseTupleList?.items;

  const binkyProduct = new BinkyProduct(productData);

  // Reconnect the license group pointers.
  binkyProduct.licenseGroups = productData.licenseGroups?.map((licenseGroup) =>
    adaptProductLicenseGroup(licenseGroup, binkyProduct, false)
  );
  binkyProduct.licenseGroupSummaries = productData.licenseGroupSummaries?.map((licenseGroup) =>
    adaptProductLicenseGroup(licenseGroup, binkyProduct, false)
  );

  return binkyProduct;
};

/**
 * Converts a binky ProductList to a Pandora JilModelList<Product>, or the reverse
 * @param {PandoraJilModelList<PandoraProduct> | BinkyProductList} productList - The list to convert
 * @param {boolean} binkyToPandora - If true will convert the object from the binky to Pandora, otherwise from Pandora to binky
 * @returns A binky or Pandora Product list instance
 * @throws {TypeError} - Will throw if the given Product object is already the intended type
 */
const adaptProductList = (productList, binkyToPandora) => {
  const productListData = cloneDeep(productList);

  if (binkyToPandora) {
    if (productList instanceof PandoraJilModelList) {
      throw new TypeError('Already a Pandora JilModelList<Product>!');
    }

    productListData.items = productListData.items?.map((item) => adaptProduct(item, true, false));
    productListData.itemCount = productListData.pagination?.itemCount;

    return new PandoraJilModelList(productListData);
  }

  if (productList instanceof BinkyProductList) {
    throw new TypeError('Already a binky ProductList!');
  }

  productListData.items = productListData.items?.map((item) => adaptProduct(item, false, false));
  return new BinkyProductList(productListData);
};

/**
 * Converts a binky LicenseGroup object to a Pandora LicenseGroup, or the reverse
 * @param {BinkyLicenseGroup | PandoraLicenseGroup} licenseGroup - The licenseGroup object to convert (aliased in this file for clarity, but exists under the LicenseGroup name in both repos)
 * @param {boolean} binkyToPandora - If true will convert the object from the binky to Pandora, otherwise from Pandora to binky
 * @returns A binky or Pandora LicenseGroup object
 * @throws {TypeError} - Will throw if the given LicenseGroup object is already the intended type
 */
const adaptLicenseGroup = (licenseGroup, binkyToPandora) => {
  const licenseGroupData = cloneDeep(licenseGroup);

  if (binkyToPandora) {
    if (licenseGroup instanceof PandoraLicenseGroup) {
      throw new TypeError('Already a Pandora LicenseGroup!');
    }

    if (licenseGroupData.product) {
      licenseGroupData.product = adaptProduct(licenseGroupData.product, true, true);
    }

    return new PandoraLicenseGroup(licenseGroupData);
  }
  // else if pandora-to-binky...

  if (licenseGroup instanceof BinkyLicenseGroup) {
    throw new TypeError('Already a binky LicenseGroup!');
  }

  if (licenseGroupData.product) {
    licenseGroupData.product = adaptProduct(licenseGroupData.product, false, true);
  }

  return new BinkyLicenseGroup(licenseGroupData);
};

/**
 * Converts a binky UserGroup object to a Pandora UserGroup, or the reverse
 * @param {BinkyUserGroup | PandoraUserGroup} userGroup - The userGroup object to convert (aliased in this file for clarity, but exists under the UserGroup name in both repos)
 * @param {boolean} binkyToPandora - If true will convert the object from the binky to Pandora, otherwise from Pandora to binky
 * @returns A binky or Pandora UserGroup object
 * @throws {TypeError} - Will throw if the given UserGroup object is already the intended type
 */
const adaptUserGroup = (userGroup, binkyToPandora) => {
  const userGroupData = cloneDeep(userGroup);

  if (binkyToPandora) {
    if (userGroup instanceof PandoraUserGroup) {
      throw new TypeError('Already a Pandora UserGroup!');
    }

    return new PandoraUserGroup(userGroupData);
  }
  // else if pandora-to-binky...

  if (userGroup instanceof BinkyUserGroup) {
    throw new TypeError('Already a binky UserGroup!');
  }

  return new BinkyUserGroup(userGroupData);
};

/**
 * Convert a Pandora Product to a binky Product
 * @param {PandoraProduct} product
 * @returns {BinkyProduct}
 * @throws {TypeError} - Will throw if cloning fails or if the given Product object is already the intended type
 */
const toBinkyProduct = (product) => adaptProduct(product, false, true);

/**
 * Convert a binky Product to a Pandora Product
 * @param {BinkyProduct} product
 * @returns {PandoraProduct}
 * @throws {TypeError} - Will throw if the given Product object is already the intended type
 */
const toPandoraProduct = (product) => adaptProduct(product, true, true);

/**
 * Convert a Pandora Product list to a binky ProductList
 * @param {PandoraJilModelList<PandoraProduct>} productList
 * @returns {BinkyProductList}
 * @throws {TypeError} - Will throw if the given Product object is already the intended type
 */
const toBinkyProductList = (productList) => adaptProductList(productList, false);

/**
 * Convert a binky ProductList to a Pandora Product list
 * @param {BinkyProductList} productList
 * @returns {PandoraJilModelList<PandoraProduct>}
 * @throws {TypeError} - Will throw if the given Product object is already the intended type
 */
const toPandoraProductList = (productList) => adaptProductList(productList, true);

/**
 * Convert a Pandora LicenseGroup to a binky LicenseGroup
 * @param {PandoraLicenseGroup} licenseGroup
 * @returns {BinkyLicenseGroup}
 * @throws {TypeError} - Will throw if cloning fails or if the given LicenseGroup object is already the intended type
 */
const toBinkyLicenseGroup = (licenseGroup) => adaptLicenseGroup(licenseGroup, false);

/**
 * Convert a binky LicenseGroup to a Pandora LicenseGroup
 * @param {BinkyLicenseGroup} licenseGroup
 * @returns {PandoraLicenseGroup}
 * @throws {TypeError} - Will throw if the given LicenseGroup object is already the intended type
 */
const toPandoraLicenseGroup = (licenseGroup) => adaptLicenseGroup(licenseGroup, true);

/**
 * Convert a Pandora UserGroup to a binky UserGroup
 * @param {PandoraUserGroup} userGroup
 * @returns {BinkyUserGroup}
 * @throws {TypeError} - Will throw if cloning fails or if the given UserGroup object is already the intended type
 */
const toBinkyUserGroup = (userGroup) => adaptUserGroup(userGroup, false);

/**
 * Convert a binky UserGroup to a Pandora UserGroup
 * @param {BinkyUserGroup} userGroup
 * @returns {PandoraUserGroup}
 * @throws {TypeError} - Will throw if the given UserGroup object is already the intended type
 */
const toPandoraUserGroup = (userGroup) => adaptUserGroup(userGroup, true);

export {
  toBinkyProduct,
  toPandoraProduct,
  toBinkyProductList,
  toPandoraProductList,
  toBinkyLicenseGroup,
  toPandoraLicenseGroup,
  toBinkyUserGroup,
  toPandoraUserGroup,
};
