import {feature} from '@admin-tribe/binky';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import uniqueId from 'lodash/uniqueId';

import {NAV_ITEM_ID_PREFIX} from './WorkspaceNavItemConstants';
import WorkspaceNavItem from './model/WorkspaceNavItem';

/**
 * @description Helper method to generate a usable test-analytics-id for the group item of
 * a set of nav items. Please note that changin this function may break the analytics sent
 * from onesie to gainsight and other systems.
 * @param String groupName - The groupName to use as an input.
 * @returns String the new (sanitized) group name or null (if flagged off).
 */
const generateGroupAnalysisId = (groupName) => {
  if (!feature.isEnabled('temp_generate_group_analysis_ids') || !groupName || !groupName.trim()) {
    return null;
  }
  const processedGroupName = groupName.trim().replace(/\s+/g, '-').toLowerCase();
  return `nav-group-${processedGroupName}`;
};

/**
 * @description Helper method to assign keys to nav items.
 * @param {Object[]} navItems - Objects that will appended to.
 * @returns {Object[]} navItems with keys assigned.
 */
function generateAndAssignKeys(navItems) {
  return navItems.map((navItem) => Object.assign(navItem, {key: uniqueId(NAV_ITEM_ID_PREFIX)}));
}

/**
 * @description Helper to group nav items together if a group property is
 *     declared and return the resulting items as WorkspaceNavItem objects.
 *
 * @param {Object[]} navItems - Array of input nav items
 * @param {string} navItems[0].group - Group name for a nav item.
 * @returns {WorkspaceNavItem[]} Array of responsive nav items that account
 *     for groups that may appear in the workspace nav.
 */
function generateWorkspaceNavItems(navItems) {
  const partitionedItems = partition(navItems, 'group');
  const groupedItems = partitionedItems[0];
  const ungroupedItems = partitionedItems[1];

  const ungroupedNavItems = ungroupedItems.map(
    (navItem) =>
      new WorkspaceNavItem({
        ...(navItem.analysisId ? {analysisId: navItem.analysisId} : {}),
        href: navItem.href,
        icon: navItem.icon,
        key: navItem.key,
        name: navItem.name,
        order: navItem.order,
        ...(feature.isEnabled('temp_use_provided_nav_item_keys') && navItem.testId
          ? {testId: navItem.testId}
          : {}),
      })
  );

  let listElements;
  if (groupedItems.length > 0) {
    const itemsByGroup = groupBy(groupedItems, 'group');
    const groupNames = Object.keys(itemsByGroup);
    const groupedNavItems = groupNames.map((groupName) => {
      const groupAnalysisId = generateGroupAnalysisId(groupName);
      const workspaceNavItem = new WorkspaceNavItem({
        ...(groupAnalysisId ? {analysisId: groupAnalysisId} : {}),
        items: itemsByGroup[groupName].map(
          (navItem) =>
            new WorkspaceNavItem({
              ...(navItem.analysisId ? {analysisId: navItem.analysisId} : {}),
              href: navItem.href,
              icon: navItem.icon,
              key: navItem.key,
              name: navItem.name,
              order: navItem.order,
              ...(feature.isEnabled('temp_use_provided_nav_item_keys') && navItem.testId
                ? {testId: navItem.testId}
                : {}),
            })
        ),
        name: groupName,
      });
      workspaceNavItem.items.sort((a, b) => a.order - b.order);
      return workspaceNavItem;
    });
    listElements = [...groupedNavItems, ...ungroupedNavItems].sort((a, b) => a.order - b.order);
  } else {
    listElements = ungroupedNavItems;
  }

  return listElements;
}

export {generateAndAssignKeys, generateGroupAnalysisId, generateWorkspaceNavItems};
