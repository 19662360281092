import {Content, ContextualHelp, Heading, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import InfoItemContainer from 'common/components/info-item/info-item-container/InfoItemContainer';
import InfoItemScorecardDrawerTrigger from 'common/components/info-item/info-item-scorecard-drawer-trigger/InfoItemScorecardDrawerTrigger';

/**
 * InfoItemScorecard is a widget which displays a label and a numeric value or a React element.
 *
 * If the value is a finite number, it will be run through formatNumber.
 * For example, the number 1000 will be displayed as 1,000 for the English locale.
 *
 * If the value is not a finite number, for example, null/undefined/NaN, an en-dash will be
 * displayed in place of the number.
 *
 * While waiting for a initial value from an asynchronously loaded list, consider rendering this with a nil value which
 * will display an en-dash until the value is set.
 */
const InfoItemScorecard = ({
  children,
  contextualHelpHeading,
  contextualHelpText,
  contextualHelpType = 'info',
  'data-testid': dataTestId = 'info-item-scorecard',
  label,
  showContextualHelp,
  value,
}) => {
  const intl = useIntl();
  const ENDASH_STR = '\u2013';

  let valueToDisplay = ENDASH_STR;

  if (typeof value === 'string' && value) {
    valueToDisplay = value;
  } else if (Number.isFinite(value)) {
    valueToDisplay = intl.formatNumber(value);
  }

  return (
    <InfoItemContainer data-testid={dataTestId} label={label}>
      <Text data-testid="info-item-value">{valueToDisplay}</Text>
      {showContextualHelp && (
        <ContextualHelp data-testid="count-contextual-help" variant={contextualHelpType}>
          <Heading>{contextualHelpHeading}</Heading>
          <Content>
            <Text>{contextualHelpText}</Text>
          </Content>
        </ContextualHelp>
      )}
      {children && <InfoItemScorecardDrawerTrigger>{children}</InfoItemScorecardDrawerTrigger>}
    </InfoItemContainer>
  );
};

InfoItemScorecard.propTypes = {
  /**
   * A drawer. The 'View details' drawer trigger will be displayed after the value.
   */
  children: PropTypes.element,
  contextualHelpHeading: PropTypes.string,
  contextualHelpText: PropTypes.string,
  contextualHelpType: PropTypes.string,
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string.isRequired,
  showContextualHelp: PropTypes.bool,
  /**
   * The numeric value or a React element.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InfoItemScorecard;
