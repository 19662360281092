import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {matchPath, useLocation} from 'react-router-dom';

import {WorkspaceContext} from '../Workspace';

import CollapsedWorkspaceNavItems from './CollapsedWorkspaceNavItems';
import HorizontalWorkspaceNavItems from './HorizontalWorkspaceNavItems';
import VerticalWorkspaceNavItems from './VerticalWorkspaceNavItems';
import {ORIENTATION} from './WorkspaceNavConstants';
import WorkspaceNavContext from './WorkspaceNavContext';
import {generateAndAssignKeys, generateWorkspaceNavItems} from './WorkspaceNavUtils';

/**
 * Component that will return the WorkspaceNavItems as a side bar nav, a
 * horizontal nav or a collapsed nav.
 */
const WorkspaceNav = observer(({navItems}) => {
  const intl = useIntl();
  const location = useLocation();

  const [workspaceNavItems, setWorkspaceNavItems] = useState([]);
  const [navItemsWithKeys, setNavItemsWithKeys] = useState([]);
  const {orientation, shouldShowAsCollapsed} = useContext(WorkspaceContext);

  // Need to construct workspace nav items from the navItems.
  useEffect(() => {
    const generatedNavItemsWithKeys = generateAndAssignKeys(navItems);
    setNavItemsWithKeys(generatedNavItemsWithKeys);
    setWorkspaceNavItems(generateWorkspaceNavItems(generatedNavItemsWithKeys));
  }, [navItems]);

  // Returns the navItemKey for best match in navItemsWithKeys or undefined if no match.
  const selectedNavItemKey = useMemo(() => {
    // First check if nav item href matches full url
    const matchedNavItem = navItemsWithKeys.find((item) => item.href === location.pathname);
    if (matchedNavItem) {
      return matchedNavItem.key;
    }

    // Second check if nav item has pattern property eg: :/orgId/groups/:groupdId and
    // if exists return item with pattern that matches the url
    const matchedPatternItem = navItemsWithKeys.find((item) => {
      if (!item.pattern) {
        return undefined;
      }

      let modifiedPattern;
      if (item.pattern.endsWith('/')) {
        modifiedPattern = `${item.pattern}*`;
      } else if (item.pattern.endsWith('/*')) {
        modifiedPattern = item.pattern;
      } else {
        modifiedPattern = `${item.pattern}/*`;
      }

      return matchPath(modifiedPattern, location.pathname);
    });
    if (matchedPatternItem) {
      return matchedPatternItem.key;
    }

    // Fallback in case first 2 checks fails to fetch any match
    // check if item link finds match with url
    let selectedKey;
    // eslint-disable-next-line no-restricted-syntax -- Needed for early exit on exact match
    for (const navItem of navItemsWithKeys) {
      if (
        matchPath(`${navItem.href}/*`, location.pathname) &&
        navItem.href.length >= (selectedKey?.length || 0)
      ) {
        // remember the most recent partial match
        selectedKey = navItem.key;
      }
    }

    return selectedKey;
  }, [navItemsWithKeys, location.pathname]);

  return (
    <WorkspaceNavContext
      data-testid="context"
      navAriaLabel={intl.formatMessage({id: 'binky.shell.workspace.nav.ariaLabel'})}
      navItems={workspaceNavItems}
      selectedNavItemKey={selectedNavItemKey}
    >
      {() => (
        <>
          {shouldShowAsCollapsed && <CollapsedWorkspaceNavItems />}
          {!shouldShowAsCollapsed && orientation === ORIENTATION.VERTICAL && (
            <VerticalWorkspaceNavItems />
          )}
          {!shouldShowAsCollapsed && orientation === ORIENTATION.HORIZONTAL && (
            <HorizontalWorkspaceNavItems />
          )}
        </>
      )}
    </WorkspaceNavContext>
  );
});

WorkspaceNav.displayName = 'WorkspaceNav';
WorkspaceNav.propTypes = {
  /**
   * Items that will be converted into WorkspaceNavItems and rendered in this
   * component's children components.
   */
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Group name for this item, will be bundled together with other items
       * with matching group name.
       */
      group: PropTypes.string,
      /** Href that this item will link to. */
      href: PropTypes.string.isRequired,
      /** String name of the icon that should be used */
      icon: PropTypes.oneOf(['SettingsIcon', 'ViewGridIcon']),
      /** Name label that will be shown in the UI */
      name: PropTypes.string.isRequired,
      /** Index where this item should be placed */
      order: PropTypes.number.isRequired,
    })
  ),
};

export default WorkspaceNav;
