/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc service
 * @name binky.widgets.common.member.status-helper
 * @description member status helper factory
 */
(function () {
  'use strict';

  angular
    .module('binky.widgets.common.member.status-helper')
    .factory('memberStatusHelper', getMemberStatusHelper);

  /* @ngInject */
  function getMemberStatusHelper(ACCOUNT_STATUSES) {
    const statuses = {
      [ACCOUNT_STATUSES.ACTIVE]: {
        labelKey: ACCOUNT_STATUSES.ACTIVE,
        variant: 'success',
      },
      [ACCOUNT_STATUSES.DISABLED]: {
        labelKey: ACCOUNT_STATUSES.DISABLED,
        variant: 'default',
      },
    };

    const placeholderStatus = {
      labelKey: 'unknown',
      variant: 'info',
    };

    function getLabelKey(status) {
      return statuses[status] ? statuses[status].labelKey : placeholderStatus.labelKey;
    }

    function getVariant(status) {
      return statuses[status] ? statuses[status].variant : placeholderStatus.variant;
    }

    const service = {
      getLabelKey,
      getVariant,
    };

    return service;
  }
})();
