(function () {
  'use strict';
  /**
   * @deprecated use src2/widgets/common/components/file-drop-zone/FileDropZone.jsx
   *
   * @ngdoc directive
   * @name binky.widgets.common.file-uploader:binkyFileUploader
   * @description file uploader directive.
   * @param {String} [acceptedFileTypes] comma separated list of accepted file
   *   types. Optional; the default is to accept any file type
   * @param {Object} file object reference for the file to be uploaded
   * @param {Object} [focusStatus] reference from using code so that this
   *   directive can directly pass focus status changes along. This directive
   *   will initialize the value to false (Boolean) and toggle the value
   *   depending on whether or not the label being used should display as if it
   *   were in focus (true). Optional; only necessary if using custom
   *   label/trigger for file input dialog
   * @param {String} [labelKey] key to translation that should be used for the
   *   label of this component. Optional; do not provide a value here if you are
   *   using your own, custom label/trigger for file input dialog
   * @param {LABEL_TYPE} [labelType] type of label to generate to open up file
   *   input dialog (e.g. - text or primary/secondary button; see constant
   *   declaration for list of supported types). Optional; do not provide a
   *   value here if you are using your own, custom label/trigger for file input
   *   dialog
   * @param {Function} onUpload callback to be invoked when a file has been
   *   uploaded
   * @param {Function} [resetInput] do not pass a Function into this binding.
   *   Instead, use this binding to set-up a reference to a Function that this
   *   directive will set up for you to use. This provides controlling/using
   *   code with a Function reference that can be used to manually reset the
   *   file input dialog state. This is necessary when an upload completes or is
   *   canceled, in order to reset the state of the selected file. Failing to
   *   reset this directive at these points may cause issues with multiple
   *   uploads or canceled uploads in a single session (i.e. - file input dialog
   *   won't open if a change to the file is not detected). Optional; if you do
   *   not pass along a reference to use, be sure that you have another
   *   mechanism in place to reset the directive state (e.g. - directive exists
   *   in a modal that closes/reopens between uses)
   * @param {String} uploaderId unique identifier for input element
   * @example
   * <label for="dummy-file-uploader-id" <-- Make sure this matches the uploader-id below
   *   translate>dummy.label.key</label>
   * <binky-file-uploader
   *   accepted-file-types="text/csv,application/csv"
   *   file="$ctrl.file"
   *   focus-status="$ctrl.focusStatus"
   *   on-upload="$ctrl.onUpload"
   *   reset-input="$ctrl.onReset"
   *   uploader-id="dummy-file-uploader-id">
   * </binky-file-uploader>
   */
  angular.module('binky.widgets.common.file-uploader').directive('binkyFileUploader', fileUploader);

  function fileUploader($timeout, _, LABEL_TYPE) {
    const directive = {
      link,
      restrict: 'E',
      scope: {
        acceptedFileTypes: '@?',
        file: '=',
        focusStatus: '=?',
        labelKey: '@?',
        labelType: '@?',
        onUpload: '=',
        resetInput: '=?',
        uploaderId: '@',
      },
      template,
    };

    return directive;

    /////////////////

    function link(scope, element) {
      if (scope.labelKey) {
        switch (scope.labelType) {
          case LABEL_TYPE.BUTTON_PRIMARY:
            _.assign(scope, {
              isButton: true,
              isPrimary: true,
              isSecondary: false,
              isText: false,
            });
            break;
          case LABEL_TYPE.BUTTON_SECONDARY:
            _.assign(scope, {
              isButton: true,
              isPrimary: false,
              isSecondary: true,
              isText: false,
            });
            break;
          case LABEL_TYPE.TEXT:
          default:
            _.assign(scope, {
              isButton: false,
              isPrimary: false,
              isSecondary: false,
              isText: true,
            });
        }
      }

      scope.focusStatus = false;

      scope.onBlur = function () {
        scope.focusStatus = false;
      };

      scope.onFocus = function () {
        scope.focusStatus = true;
      };

      const input = element.find('input');
      scope.resetInput = function () {
        input.val('');
      };

      input.on('change', (event) => {
        $timeout(() => {
          if (_.get(event, 'target.files[0]')) {
            scope.file = event.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', (loadEvent) => {
              scope.file.path = loadEvent.target.result;
              _.invoke(scope, 'onUpload', scope.file);
            });
            reader.readAsDataURL(scope.file);
          }
        });
      });
    }

    function template() {
      return (
        `<label ng-attr-for="{{ uploaderId }}" ng-class="{'_coral-Button': isButton, '_coral-Button--primary': isPrimary, '_coral-Button--secondary': isSecondary, focused: focusStatus, text: isText}" ng-if="labelKey" translate>{{labelKey}}</label>` +
        '<input class="file-uploader" ng-attr-accept="{{ acceptedFileTypes }}" ng-attr-id="{{ uploaderId }}" ng-blur="onBlur()" ng-focus="onFocus()" type="file"/>'
      );
    }
  }
})();
