(function () {
  /**
   * @deprecated Please use src2/core/utils/urlUtils.js
   *
   * @ngdoc factory
   * @name binky.core.url-utils
   * @description Utility service for urls.
   */
  angular.module('binky.core.url-utils').factory('urlUtils', utils);

  /* @ngInject */
  function utils(binkySrc2) {
    return binkySrc2.utils.urlUtils;
  }
})();
