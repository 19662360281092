(function () {
  'use strict';

  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.badge:binkyBadge
   *
   * @description A badge widget. Similar to a tag, a badge displays a small
   *   block element with information in it. Unlike a tag, the right borders are
   *   squared in a badge, allowing it to fit along the right edge of a parent
   *   container, as if it were a part of the element.
   *
   * @param {String} [icon] - alternative icon to be used rather than the one typically associated with the variant
   * @param {String} [iconAltText] - alternative text to be used with the icon.
   *   If no label is provided, then alternate text must be provided for the
   *   icon being used in the badge. Otherwise, if the label provided
   *   communicates the purpose of the badge successfully and the iconAltText
   *   would be redundant, then the iconAltText binding can and should be
   *   omitted
   * @param {String} [label] - text for badge, should be limited to two words.
   *   If omitted (icon-only badge), then the iconAltText binding MUST be
   *   provided
   * @param {String} [variant] - one of error, info, special, success, or warning.
   *   Variants are expressed in the following manner:
   *
   *     error - red background, white text, alert icon
   *     info - blue background, white text, info icon
   *     special - purple background, white text, no icon
   *     success - green background, white text, no icon
   *     warning - orange background, white text, alert icon
   *
   * @example <binky-badge label="Example Badge" variant="info"></binky-badge>
   * @example <binky-badge icon-alt-text="Additional information available" variant="info"></binky-badge>
   */
  angular.module('binky.widgets.common.badge').component('binkyBadge', {
    bindings: {
      icon: '@?',
      iconAltText: '@?',
      label: '@?',
      variant: '@?',
    },
    controller,
    templateUrl: 'widgets/common/badge/badge.component.html',
  });

  /* @ngInject */
  function controller(_, BadgeUtils) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onChanges() {
      _.assign(vm, {
        alt: _.isEmpty(vm.label) ? vm.iconAltText : '', // only use alt text if no label provided (else redundant)
        icon: vm.icon || BadgeUtils.getIconFromVariant(vm.variant),
      });
    }

    function $onInit() {
      if (_.isEmpty(vm.label) && _.isEmpty(vm.iconAltText)) {
        throw new Error(
          'Icon-only (no label binding provided) badge must provide alt text for icon'
        );
      }
    }
  }
})();
