(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyContractPerTermPriceWithTax
   * @description returns the price per term with tax info, e.g. 'US$123.45 / mo incl. tax'
   * @param {Contract} contract model instance.
   * @param {Function} contract.getBillingFrequency returns the contract's billing frequency, e.g. 'MONTHLY' or 'ANNUAL'.
   * @param {Function} contract.getOwnerCountryCode returns the two digit country code, e.g. 'US'
   * @param {Boolean} includesTax - true to represent inclusion of tax, else false.
   * @param {String} formattedPrice - the pre-formatted price string.
   * @return {String} the string for price per term with tax info.
   * @example <div> {{ $ctrl.contract | binkyContractTaxSuffixLabel:$ctrl.includesTax:$ctrl.formattedPrice }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.commerce')
    .filter('binkyContractPerTermPriceWithTax', perTermPriceStringWithTaxInfo);

  /* @ngInject */
  function perTermPriceStringWithTaxInfo($translate, _, commerceUtils) {
    return getPerTermPriceStringWithTaxInfo;

    ////////////////

    function getPerTermPriceStringWithTaxInfo(contract, includesTax, formattedPrice) {
      const taxTerm = commerceUtils.getTaxTerm(_.invoke(contract, 'getOwnerCountryCode'));
      const billingFrequency = _.invoke(contract, 'getBillingFrequency');
      return $translate.instant(
        includesTax
          ? `binky.widgets.commerce.perTerm.inclTax.${taxTerm}.${billingFrequency}`
          : `binky.widgets.commerce.perTerm.exclTax.${taxTerm}.${billingFrequency}`,
        {price: formattedPrice}
      );
    }
  }
})();
