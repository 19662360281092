import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilUserGroups from 'api/jil/jilUserGroups';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import UserGroup from './UserGroup';

class UserGroupList extends JilModelList {
  /**
   * @description Method to retrieve an existing list of UserGroups from the back-end.
   *
   * @param {Object} options object. See constructor for params
   * @returns {Promise<UserGroupList>} new UserGroupList Object reference
   */
  static get(options) {
    const model = new UserGroupList(options);
    return model.refresh();
  }

  /**
   * @description Method to construct a new UserGroupList.
   * @param {Object} options options params
   * @param {String} options.orgId organization Id
   * @param {Boolean} [options.administerable] if set to true, will filter the refresh results to only user groups
   *  that can be administered. Defaults to false.
   * @param {String} [options.sortOrder] sorting order (ASC or DESC)
   */
  constructor(options) {
    super({
      itemClassRef: UserGroup,
      resource: jilUserGroups.getUserGroups,
      sortExpression: JIL_CONSTANTS.SORT.NAME,
      sortOrder: options.sortOrder || JIL_CONSTANTS.ORDER.ASC,
      ...options,
    });

    Object.assign(this, {
      administerable: options.administerable,
      orgId: options.orgId,
    });
  }

  /**
   * @awaitingdeprecation - see removeUserGroups below
   * @description Method to delete one or more user groups. The user group list is refreshed.
   *
   * @param {Object} selectedUserGroups UserGroup references to delete
   * @return {Promise<UserGroupList>} resolves if UserGroups are successfully deleted, else rejects with error message
   */
  async deleteUserGroups(selectedUserGroups) {
    try {
      const deletePromises = selectedUserGroups.map((userGroup) => userGroup.delete());
      await Promise.all(deletePromises);

      this.filter.query = '';
      this.setPageNumber(1);

      return this.refresh();
    } catch (error) {
      log.error('UserGroupList failed to delete user groups. Error: ', error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<UserGroupList>} resolves to refreshed UserGroupList model, else rejects with error message
   */
  async refresh() {
    try {
      await super.refresh({
        administerable: !!this.administerable,
        include: 'shared_user_groups,shared_user_groups_organization',
        orgId: this.orgId,
      });
      this.items.forEach((userGroup) => {
        Object.assign(userGroup, {
          orgId: this.orgId,
        });
      });
    } catch (error) {
      log.error('UserGroupList failed to refresh. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to remove one or more user groups. The user group list is not refreshed.
   *
   * @param {Object} selectedUserGroups UserGroup references to delete
   * @return {Promise<UserGroupList>} resolves if UserGroups are successfully deleted, else rejects with error message
   */
  async removeUserGroups(selectedUserGroups) {
    try {
      const deletePromises = selectedUserGroups.map((userGroup) => userGroup.delete());
      await Promise.all(deletePromises);
      return Promise.resolve(this);
    } catch (error) {
      log.error('UserGroupList failed to remove user groups. Error: ', error);
      return Promise.reject(error);
    }
  }
}

export default UserGroupList;
