// eslint-disable-next-line angular/window-service -- importing binkySrc2
const DOMAIN_CONSTANTS = window.binkySrc2.models.domain.DOMAIN_CONSTANTS;
(function () {
  /**
   * @deprecated except for JIL_ADD_DOMAINS_ERROR. Please use src2/core/models/domain/domainConstants.js.
   */
  angular
    .module('binky.core.domains')
    .constant('DOMAIN_STATUS', DOMAIN_CONSTANTS.DOMAIN_STATUS)
    .constant('JIL_ADD_DOMAINS_ERROR', {
      // These error codes are used as translate keys; when adding errors to this list be sure to
      // add corresponding strings to str-en.json.
      // Note: these errors code were not ported to src2 since are tied to localization keys.
      DOMAIN_ALREADY_CLAIMED_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_ACTIVE',
      DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG: 'DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG',
      DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE',
      DOMAIN_NAME_INVALID: 'DOMAIN_NAME_INVALID',
      DOMAIN_RESERVED: 'DOMAIN_RESERVED',
    });
})();
