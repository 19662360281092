import PropTypes from 'prop-types';
import React, {createContext, useContext, useReducer} from 'react';

const WizardContext = createContext(null);
const {Provider} = WizardContext;
const useWizardContext = () => useContext(WizardContext);

const WIZARD_DISPATCH_ACTION = {
  DECREMENT: 'decrement',
  INCREMENT: 'increment',
};

/**
 * Outer element that allows devs to supply a list of steps and a callback
 * containing the rest of the content and a WizardView that will be updated
 * whenever the wizard is advanced forwards or backwards.
 */
const WizardTrigger = ({children, defaultCurrentStep = 0, steps}) => {
  const reducer = (currentStep, action) => {
    switch (action.type) {
      case WIZARD_DISPATCH_ACTION.INCREMENT:
        return currentStep >= steps.length - 1 ? currentStep : currentStep + 1;
      case WIZARD_DISPATCH_ACTION.DECREMENT:
        return currentStep === 0 ? currentStep : currentStep - 1;
      default:
        return currentStep;
    }
  };

  const [currentStep, dispatch] = useReducer(reducer, defaultCurrentStep);

  return (
    <Provider value={{currentStep, dispatch, steps}}>
      <WizardContext.Consumer>{children}</WizardContext.Consumer>
    </Provider>
  );
};

WizardTrigger.propTypes = {
  /**
   * Children to pass through.
   */
  children: PropTypes.func,
  /**
   * Sets the initial currentStep. It will be 0 if not specified.
   */
  defaultCurrentStep: PropTypes.number,
  /**
   * Array of steps that the wizard can step through.
   */
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export {WizardTrigger, WIZARD_DISPATCH_ACTION, useWizardContext};
