(function () {
  'use strict';
  const componentName = 'binkyOrganizationsSwitcher';
  /**
   * @deprecated Please use src2/common/components/organization-switcher/OrganizationSwitcher.jsx
   *
   * @ngdoc component
   * @name binky.widgets.organizations.organizations-switcher:binkyOrganizationsSwitcher
   *
   * @description the org switcher widget
   *
   * @param {String} [activeOrgId] The ID of the active org.
   * @param {Array[String]} filterToTypes The org types to filter to. If not provided we won't filter the orgs.
   * @param {Array[LinkedUserAccount]} linkedUserAccounts The linked user accounts to display for switching.
   * @param {Function} onChange The callback function to register for the change event.
   * @param {Boolean} [showActiveOrg] Whether to display the active org in the list. Defaults to true.
   *
   * @example <binky-organizations-switcher></binky-organizations-switcher>
   *
   */
  angular.module('binky.widgets.organizations.organizations-switcher').component(componentName, {
    bindings: {
      activeOrgId: '<?',
      filterToTypes: '<?',
      linkedUserAccounts: '<',
      onChange: '&',
      showActiveOrg: '<',
    },
    controller,
    templateUrl:
      'widgets/organizations/organizations-switcher/organizations-switcher.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $compile,
    $element,
    $q,
    $scope,
    $timeout,
    AuthenticatedUser,
    binkyMemberTypeLabelFilter,
    MemberType
  ) {
    const vm = this;
    const ORG_SWITCHER_SEARCH_ID_PREFIX = `${componentName}-`;

    _.assign(vm, {
      $onInit,
      onSearch,
      processedLinkedAccounts: fetchLinkedOrganizations(),
      searchLabelId: _.uniqueId(ORG_SWITCHER_SEARCH_ID_PREFIX),
      setActiveOrg,
      waitOnChange: $q.resolve(),
    });

    /////////////////
    function $onInit() {
      vm.search = {name: ''};
      // We cache the activeOrg id on init, to avoid refreshing when it changes
      if (vm.activeOrgId) {
        const activeOrg = _.find(vm.processedLinkedAccounts, {
          id: vm.activeOrgId,
          userId: AuthenticatedUser.get().getId(),
        });
        vm.initialItemId = _.get(activeOrg, 'idx');
      }
      updateBindings();
    }

    function onSearch(value) {
      vm.search.name = value;
    }

    function setActiveOrg(orgId) {
      const idx = orgId;
      const org = vm.processedLinkedAccounts[idx];
      vm.waitOnChange = vm.onChange({orgId: org.id, userId: org.userId});
    }

    function fetchLinkedOrganizations() {
      // we filter before clustering to avoid removing part of a cluster
      const organizations = _.map(
        filterOrgs(vm.linkedUserAccounts, 'organization_type'),
        (linkedUserAccount, idx) => ({
          id: linkedUserAccount.organization_id,
          idx: `${idx}`,
          name: linkedUserAccount.organization_name,
          userId: linkedUserAccount.entitlement_user_id,
          userType: linkedUserAccount.entitlement_user_type,
        })
      );
      // we cluster organizations by their ID, so we can mark them up by user type
      // if the user is in the same org twice
      _.forEach(organizations, (organization) => {
        organization.multipleAccountsForOrg = _(organizations)
          .without(organization)
          .some({id: organization.id});
      });
      return organizations;
    }

    function filterOrgs(organizations, typeKey) {
      if (vm.filterToTypes) {
        return _.filter(organizations, orgTypeAllowed);
      }
      return organizations;

      function orgTypeAllowed(org) {
        return _.includes(vm.filterToTypes, org[typeKey]);
      }
    }

    function updateBindings() {
      // we do this on a timeout to ensure we act after Coral and Angular have rendered
      $timeout(() => {
        _(vm.processedLinkedAccounts)
          .filter({multipleAccountsForOrg: true})
          .forEach((organization) => {
            const itemContent = $element[0].querySelector(
              `coral-selectlist-item[value="${organization.idx}"] coral-selectlist-item-content`
            );
            angular
              .element(itemContent)
              .append(
                $compile(
                  `<coral-tag>${binkyMemberTypeLabelFilter(
                    new MemberType(organization.userType, organization.userId)
                  )}</coral-tag>`
                )($scope)
              );
          });
      });
    }
  }
})();
