(function () {
  /**
   * @deprecated Please use src2/core/models/product/Product.js
   *
   * @ngdoc factory
   * @name Product
   * @description Model for an individual Product
   */
  angular.module('binky.core.product').factory('Product', getProductModel);

  /* @ngInject */
  function getProductModel(
    $injector,
    $log,
    $q,
    _,
    binkySrc2,
    modelCache,
    PRODUCT_CACHE_ID,
    PRODUCT_METADATA_CACHE_ID
  ) {
    class Product2 extends binkySrc2.services.product.Product {
      constructor(options) {
        // We always ignore FIs from this direction, as the object being passed in has been transformed
        // and lacks Fulfillable Items
        super(_.assign({}, options, {ignoreEmptyFIs: true}));
        this.$resolved = true;
        this.$promise = $q.resolve(this);
      }

      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              const errorMessage = this.errorMessage || super.errorMessage;
              if (errorMessage) {
                reject(errorMessage);
              }
              resolve(this);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Transforms the /organizations product response Object into a new Product
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @returns {Product2} Reference to pre-existing Product
       */
      static apiResponseTransformer(dataTransferObject) {
        return new Product2(dataTransferObject);
      }

      /**
       * @description Method to retrieve an existing Product from back-end data
       *   store.
       *
       * @param {Object} options Get params Object (params described below)
       * @param {String} options.id ID of the Product to retrieve
       *
       * @returns {Product2} Reference to pre-existing Product
       */
      static get(options) {
        const {id} = options;
        if (_.isNil(id)) {
          const errorMsg = "Product.get(): 'options.id' is a required argument/param";
          $log.error(errorMsg);
          throw new Error(errorMsg);
        }

        const orgManager = $injector.get('OrganizationManager');

        const model = new Product2({id, orgId: orgManager.getActiveOrgId()});
        model.refresh();

        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(PRODUCT_CACHE_ID, 10);
    modelCache.register(PRODUCT_METADATA_CACHE_ID, 1);

    return Product2;
  }
})();
