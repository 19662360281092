(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name AnalyticsPageDescriptor
   * @description  This class describes an analytics page change
   */
  angular.module('binky.core.analytics-page').factory('AnalyticsPageDescriptor', factory);

  function factory(binkySrc2) {
    return binkySrc2.services.analytics.AnalyticsPageDescriptor;
  }
})();
