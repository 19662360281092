/**
 * Captures the source and target organizations and groups for a shared user group.
 * This data shows is available if the group is shared and the JIL API call passes
 * the shared_user_groups_organization include parameter is set.
 */
class UserGroupShareInfo {
  constructor(options) {
    this.sourceOrgId = options.sourceOrgId ?? '';
    this.targetOrgId = options.targetOrgId ?? '';
    this.sourceGroupId = options.sourceGroupId ?? '';
    this.targetGroupId = options.targetGroupId ?? '';
    this.isRemovalInProgress = options.isRemovalInProgress ?? false;
    this.sourceOrg = options.sourceOrg ?? {};
    this.targetOrg = options.targetOrg ?? {};
  }

  getSourceOrgName() {
    return this.sourceOrg.name;
  }

  getTargetOrgName() {
    return this.targetOrg.name;
  }
}

export default UserGroupShareInfo;
