(function () {
  /**
   * @deprecated Please use src2/core/utils/jsUtils.js
   */
  angular.module('binky.core.js-utils').factory('jsUtils', utils);

  /* @ngInject */
  function utils(binkySrc2) {
    return binkySrc2.utils.jsUtils;
  }
})();
