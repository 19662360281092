(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-accordion-item
   * @description module that defines an individual product accordion item widget
   */
  angular.module('binky.widgets.products.product-accordion-item', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.products.product-chiclet',
  ]);
})();
