import jilOrganizationLocale from 'api/jil/jilOrganizationLocale';
import eventBus from 'services/events/eventBus';
import {localeUtils} from 'services/locale/Locale';
import log from 'services/log';

import {ORGANIZATION_LOCALE_EVENT} from './OrganizationLocaleConstants';

/**
 * Model wrapping the logic for fetching/updating an organization's locale.
 */
class OrganizationLocale {
  /**
   * @description Static method for fetching the preferred language of an org.
   *
   * @param {Object} options - top level wrapper object.
   * @param {string} options.orgId - ID of the org whose preferred language
   *     should be fetched.
   * @param {string} [options.locale] - preferred locale.
   *
   * @returns {Promise} promise - resolved with the instance.
   */
  static get(options) {
    const model = new OrganizationLocale(options);
    return model.refresh();
  }

  /**
   * @description Creates a new OrganizationLocale.
   *
   * @param {Object} options - options for the new organization locale.
   * @param {string} [options.orgId] - id of the organization.
   * @param {string} [options.locale] - preferred locale.
   *
   */
  constructor(options = {}) {
    this.orgId = options.orgId;
    this.locale = options.locale;
    registerSavedState(this);
  }

  /**
   * @description Method to obtain the language from the current locale code.
   *
   * @returns {string} Language that corresponds to the current locale code.
   */
  getLanguage() {
    return localeUtils.getLanguageFromLocaleCode(this.locale);
  }

  /**
   * @description Helper to determine if there has been an update made to the
   *     preferred language.
   *
   * @returns {boolean} - True if the savedState doesn't match the current
   *     locale, false otherwise.
   */
  hasUnsavedChanges() {
    return this.orgId !== this.orgLocaleCopy?.orgId || this.locale !== this.orgLocaleCopy?.locale;
  }

  /**
   * @description Method to fetch the preferred language of an organization.
   *
   * @returns {Promise} promise - resolved with the instance.
   */
  async refresh() {
    try {
      const response = await jilOrganizationLocale.getOrganizationLocale({orgId: this.orgId});
      this.locale = response.data.locale;
      registerSavedState(this);
      return this;
    } catch (error) {
      log.error(`An error occurred fetching the preferred locale for org ${this.orgId}`);
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to revert all changes if there are some present.
   */
  revertChanges() {
    if (this.hasUnsavedChanges()) {
      this.locale = this.orgLocaleCopy?.locale;
      this.orgId = this.orgLocaleCopy?.orgId;
    }
  }

  /**
   * @description Setter to assigning the preferred language locale code for
   *     this object.
   *
   * @param {string} lang - selected language to change to.
   */
  setLanguage(lang) {
    this.locale = localeUtils.getLocaleCodeFromLanguage(lang).toLowerCase();
  }

  /**
   * @description Method to update the preferred language for an organization.
   *
   * @returns {Promise} promise - resolved with the instance.
   */
  async update() {
    try {
      await jilOrganizationLocale.putOrganizationLocale({locale: this.locale, orgId: this.orgId});
      eventBus.emit(ORGANIZATION_LOCALE_EVENT.UPDATE, this.orgId);
      registerSavedState(this);
      return this;
    } catch (error) {
      log.error(
        `An error occurred updating the preferred locale to ${this.locale} for org ${this.orgId}`
      );
      return Promise.reject(error);
    }
  }
}

/**
 * @description Helper to assign the a copy object of the the provided models
 *     values.
 *
 * @param {Object} model - Object whose locale and orgId will be copied.
 * @returns {undefined} no return value.
 */
function registerSavedState(model) {
  if (model.hasUnsavedChanges()) {
    Object.assign(model, {
      orgLocaleCopy: {
        locale: model.locale,
        orgId: model.orgId,
      },
    });
  }
}

export default OrganizationLocale;
