import {Item, Menu, MenuTrigger} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import MoreIcon from '@react/react-spectrum/Icon/More';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

/**
 * If a tooltip is not needed, use Spectrum V3 ActionMenu: https://react-spectrum.adobe.com/react-spectrum/ActionMenu.html
 *
 * MoreOptionsMenu is a widget to display a 'more options' action button which opens a menu.
 */
const MoreOptionsMenu = ({
  buttonMarginEnd,
  buttonMarginStart,
  disabledKeys,
  hoverText,
  isButtonDisabled = false,
  menuItems,
  onButtonPress,
  onItemSelected,
  onOpenChange,
}) => {
  const intl = useIntl();

  return (
    <MenuTrigger onOpenChange={onOpenChange}>
      <TooltipButton
        buttonAriaLabel={intl.formatMessage({id: 'binky.common.moreOptionsButton.label'})}
        data-testid="more-options-menu-button"
        hoverText={hoverText || intl.formatMessage({id: 'binky.common.moreOptionsButton.label'})}
        isDisabled={isButtonDisabled}
        isQuiet
        marginEnd={buttonMarginEnd}
        marginStart={buttonMarginStart}
        onPress={onButtonPress}
        variant="action"
      >
        <MoreIcon size="S" />
      </TooltipButton>
      <Menu disabledKeys={disabledKeys} items={menuItems} onAction={onItemSelected}>
        {(item) => (
          <Item key={item.key || item.body} textValue={item.textValue}>
            {item.body}
          </Item>
        )}
      </Menu>
    </MenuTrigger>
  );
};

MoreOptionsMenu.propTypes = {
  /**
   * MarginEnd of the action button.
   */
  buttonMarginEnd: PropTypes.string,
  /**
   * MarginStart of the action button.
   */
  buttonMarginStart: PropTypes.string,
  /**
   * An array of the keys of the menu items that are disabled. The key of a menu item key is the property 'key' if it
   * exists, else the property 'body'. By default all menu items are enabled.
   * It is also possible to disable the 'More options' button so the menu can't be opened.
   */
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * Text that is displayed in the tooltip on hover
   */
  hoverText: PropTypes.string,
  /**
   * Whether the 'More options' action button is disabled. The default is false.
   * It is also possible to disable the individual items in the 'More options' menu.
   */
  isButtonDisabled: PropTypes.bool,
  /**
   * An array of items of the menu with the body defined.
   * For dynamic items that replace each other depending on the state,
   * assign them the same key to prevent the Menu from refreshing and
   * losing tabbing focus.
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The content of the menu item.
       * NOTE: If this is ported to Pandora, please switch prop name to children.
       * That way, we can spread the menuItem object into <Item> to better emulate
       * React Spectrum's Item API
       */
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      /**
       * The key to distinguish the item. If the item has an id property, then that will be used instead.
       * See https://react-spectrum.adobe.com/react-spectrum/Menu.html#content
       */
      key: PropTypes.string,
      /** The aria-label for the menu item. Required if the children is not a plain string */
      textValue: requiredIf(PropTypes.string, (props) => typeof props.body !== 'string'),
    })
  ).isRequired,
  /**
   * Handler that is called when the action button is selected.
   * It's important to note that MenuTrigger controls opening
   * and closing the menu, not this prop.
   */
  onButtonPress: PropTypes.func,
  /**
   * Handler that is called when a menu item is selected.
   */
  onItemSelected: PropTypes.func.isRequired,
  /**
   * Handler that is called when a menu is opened or closed
   * and passes its state as a param.
   */
  onOpenChange: PropTypes.func,
};

export default MoreOptionsMenu;
