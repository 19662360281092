import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Organizations Fulfillment Events APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organizations Fulfillment Events api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches the fulfillment events resource for a particular
 *     organization with given org id.
 * @param {String} orgId - The associated org Id
 * @returns {Promise<Object>} Axios response
 */
const getFulfillmentEvents = ({orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/fulfillment-events`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Fetches the fulfillment events resource for a particular
 *     organization and product with given ids.
 * @param {String} orgId - The associated org Id
 * @param {String} productId - The associated product Id
 * @returns {Promise<Object>} Axios response
 */
const getFulfillmentEventsForLicense = ({orgId, productId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/products/${productId}/fulfillment-events`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

const jilFulfillmentEvents = {
  configure,
  getFulfillmentEvents,
  getFulfillmentEventsForLicense,
};
export default jilFulfillmentEvents;
