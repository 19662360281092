(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.select-list:binkyMultiSelect
   *
   * @description a wrapper around the select widget (with multiple set)
   *
   * @param {Boolean} [allowAll] - Default false. Indicator of whether to insert
   *   the ALL item into the list.
   * @param {Boolean} [isDisabled] - Default false. If true, the select is
   *   disabled.
   * @param {Collection} items - Set of items to repeat over inside this list.
   *   The name and value fields map to the provided keys. If items are not
   *   Objects (e.g. - simple list of Strings), then no name or value keys
   *   should be passed along. Each item may also have an optional
   *   incompatibleValues array, which declares values which will be blocked by
   *   selecting this one. This collection will be watched for updates.
   * @param {String} [nameKey] - Field key to use as the name. Optional,
   *   should be left undefined if collection (items) contains simple data types
   * @param {Function} [onChange] - Function to call when the selected item for
   *   this list changes. Required if not in read only mode, but not needed if
   *   select is in read only (i.e. - vm.readOnly is true)
   * @param {String} placeholder - Placeholder text to put on the select
   * @param {Boolean} [readOnly] - True if select should be in read only state
   *   (default is editable; falsey)
   * @param {String[]} [requiredSelections] - Values that are pre-selected from
   *   the available values and can not be removed
   * @param {String[]} selectedValues - Values to match against on initial load.
   *   Note this is not updated as the selection changes
   * @param {String} [valueKey] - Field key to use as the value. Optional,
   *   should be left undefined if collection (items) contains simple data types
   *
   * @example <binky-multi-select items="orgs" name-key="name" on-change="myCallback" value-key="id"></binky-multi-select>
   *
   *
   */
  angular.module('binky.widgets.common.multi-select').component('binkyMultiSelect', {
    bindings: {
      allowAll: '<?',
      isDisabled: '<?',
      items: '<',
      nameKey: '@?',
      onChange: '&?',
      placeholder: '@?',
      readOnly: '<?',
      requiredSelections: '<?',
      selectedValues: '<',
      valueKey: '@?',
    },
    controller,
    templateUrl: 'widgets/common/multi-select/multi-select.component.html',
  });

  function controller($element, $scope, $timeout, $translate, _, MULTI_SELECT_ALL_VALUE) {
    const allItem = {};
    let currentValues = [];
    let destroyItemsWatcher, destroySelectedValuesWatcher;

    const vm = this;
    _.assign(vm, {
      $onDestroy,
      $onInit,
      allSelected,
      isItemDisabled,
      isItemSelected,
    });

    function $onDestroy() {
      destroyItemsWatcher();
      destroySelectedValuesWatcher();
    }

    function $onInit() {
      if (_.isNil(vm.nameKey) && _.isNil(vm.valueKey)) {
        // no name or value key provided, so collection must contain simple values
        vm.isSimpleDataCollection = true;
        vm.nameKey = 'name';
        vm.valueKey = 'value';
      }

      allItem[vm.nameKey] = $translate.instant('binky.widgets.common.multiSelect.all');
      allItem[vm.valueKey] = MULTI_SELECT_ALL_VALUE;

      const prefixedItems = [];
      if (vm.allowAll) {
        prefixedItems.push(allItem);
      }
      processItems();

      if (vm.readOnly) {
        // in read only mode, all selections are also read only, so process
        // regular selections first, to prevent altering later...
        processChange(vm.selectedValues);
      }

      // wait for coral to render select list and popover elements, that way we
      // can apply special class names to mark required/read-only select items
      Coral.commons.ready(() => {
        const coralSelect = $element.find('coral-select');

        // set read-only class on required select options and tags
        const selectedOverlayContent = coralSelect[0].overlay.querySelectorAll('[selected]');
        _.forEach(selectedOverlayContent, (item) => {
          item.classList.add('is-read-only');
        });
        const selectedTags = coralSelect[0].querySelectorAll('coral-tag');
        _.forEach(selectedTags, (item) => {
          item.classList.add('is-read-only');
        });

        processChange(vm.selectedValues);
        watchForSelectChange();

        // we just need to signal to Angular that it needs to update the view,
        // as sometimes the coral elements render and a digest cycle/loop
        // completes before the ready() handler here is called
        $timeout(_.noop);
      });

      destroyItemsWatcher = $scope.$watchCollection('$ctrl.items', checkEqualityAndProcessItems);
      destroySelectedValuesWatcher = $scope.$watchCollection(
        '$ctrl.selectedValues',
        checkEqualityAndProcessSelectedValues
      );

      function checkEqualityAndProcessItems(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          processItems();
        }
      }

      function checkEqualityAndProcessSelectedValues(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          processChange(newValue);
        }
      }

      function processItems() {
        if (vm.isSimpleDataCollection) {
          vm.normalizedItems = _.map(vm.items, (item) => {
            const normalizedItem = {};
            normalizedItem[vm.nameKey] = _.toString(item);
            normalizedItem[vm.valueKey] = item;
            return normalizedItem;
          });
        } else {
          vm.normalizedItems = vm.items;
        }

        // we sort every item except 'All', it goes to the front of the queue
        vm.processedItems = _.union(prefixedItems, _.sortBy(vm.normalizedItems, vm.nameKey));
      }
    }

    function allSelected() {
      return _.includes(vm.selectedItems, MULTI_SELECT_ALL_VALUE);
    }

    function isItemDisabled(item) {
      // it's only valid to disable item selection if we don't allow 'All'
      if (!vm.allowAll) {
        return _.some(
          vm.processedItems,
          (processedItem) =>
            _.includes(vm.selectedItems, processedItem[vm.valueKey]) &&
            _.includes(processedItem.incompatibleValues, item[vm.valueKey])
        );
      }
      return false;
    }

    function isItemSelected(item) {
      return _(vm.requiredSelections).union(vm.selectedItems).includes(item[vm.valueKey]);
    }

    ////////

    function processChange(values) {
      if (
        _.includes(currentValues, allItem[vm.valueKey]) &&
        !_.includes(values, allItem[vm.valueKey])
      ) {
        // if 'all' was removed from the latest values, we switch everything off
        currentValues = vm.requiredSelections || [];
        vm.selectedItems = vm.requiredSelections || [];
      } else if (
        !_.includes(currentValues, allItem[vm.valueKey]) &&
        _.includes(values, allItem[vm.valueKey])
      ) {
        // if 'all' was added with the latest values, we switch everything on
        currentValues = _.map(vm.processedItems, vm.valueKey);
        vm.selectedItems = currentValues;
      } else {
        // otherwise we process the items to see if all should go on
        currentValues = processSelectedItems(values);
        vm.selectedItems = currentValues;
      }
      return currentValues;
    }

    function processSelectedItems(selectedList) {
      const sortedCompleteList = _(vm.normalizedItems)
        .map(vm.valueKey)
        .reject(allItem[vm.valueKey])
        .value();
      sortedCompleteList.sort();

      const sortedSelectedList = _.reject(selectedList, (item) => item === allItem[vm.valueKey]);
      sortedSelectedList.sort();

      // if all selection is allowed and all items are selected, update
      // selections to add the all selection
      if (vm.allowAll && _.isEqual(sortedCompleteList, sortedSelectedList)) {
        sortedSelectedList.push(allItem[vm.valueKey]);
      }

      return sortedSelectedList;
    }

    function watchForSelectChange() {
      currentValues = vm.selectedItems;
      // As we're instantiating via HTML we have to wait for Coral to complete
      // its processing of the HTML before attaching an event listener. Not
      // doing this results in the element.find returning empty.
      Coral.commons.ready(() => {
        // set up listener to handle selection changes
        const coralSelect = $element.find('coral-select');
        coralSelect.on('change', onSelectChange);

        function onSelectChange(evt) {
          // only handle changes to selection if not in read only mode
          if (!vm.readOnly) {
            // 'value' here refers to the Coral field, not the field we have on our model
            let values = _.map(evt.target.selectedItems, 'value');
            // Oddities between Coral and Angular mean we can end up seeing this event during redraw
            if (!_.isEqual(currentValues, values)) {
              values = processChange(values);
              // Timeout is necessary to avoid a loop of scope.$apply
              $timeout(() => {
                _.invoke(vm, 'onChange', {values});
                $scope.$apply();
              });
            }
          }
        }
      });
    }
  }
})();
