import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/flow';
import pick from 'lodash/pick';
import {makeObservable, observable} from 'mobx';

import LicenseAllocationResourceList from './LicenseAllocationResourceList';

class LicenseAllocationInfo {
  licenseResourceList;
  /**
   * @description Creates a new LicenseAllocationInfo for use.
   *
   * @param {Object} [options] Initialization Object (params described below)
   * @param {String} [options.contract_id] The ID for the contract providing the product.
   * @param {Boolean} [options.has_granted_allocations] True if the product has child allocations.
   * @param {Boolean} [options.is_allocation_license] True if the product is an allocation from a parent.
   * @param {String} [options.license_id] The owning Product ID.
   * @param {Array} [options.license_resources] The list of allocation resources
   */
  constructor(options = {}) {
    makeObservable(this, {
      licenseResourceList: observable,
    });
    initModel(this, options);
    this.orgId = options.orgId;
  }
}

/** Private Methods **/

function initModel(model, options) {
  // we clone to avoid issues when updating the nested object items
  const clonedMinimumOptions = flow(
    (_options) =>
      pick(_options, [
        'contract_id',
        'has_granted_allocations',
        'is_allocation_license',
        'license_id',
      ]),
    cloneDeep
  )(options);
  clonedMinimumOptions.licenseResourceList = new LicenseAllocationResourceList(
    options.license_resources
  );

  // First we assign the model fields
  Object.assign(model, clonedMinimumOptions);
}

export default LicenseAllocationInfo;
