import {EVENT_ACTION, dispatchUiEventAnalytics} from '@admin-tribe/binky';
import {Content, ContextualHelp, Footer, Heading, Link, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import StatusLight from '../status-light/StatusLight';

/**
 * StatusLightWithContextualHelp component.
 * This component combines the StatusLight and ContextualHelp components,
 * displaying a StatusLight and providing additional information through ContextualHelp
 * when the user clicks on a link.
 *
 * @param {Object} props - React component props.
 * @param {Object} props.statusLightProps - Props to be passed to the StatusLight component.
 * @param {boolean} props.statusLightText - Text for Status Light.
 * @param {boolean} props.hasContextualHelp - Indicates whether the contextual help is open.
 * @param {function} props.onLinkPressed - Callback function triggered when the link is pressed.
 * @returns {JSX.Element} - Rendered component.
 */
const StatusLightWithContextualHelp = ({
  statusLightProps,
  statusLightText,
  hasContextualHelp,
  onLinkPressed,
}) => {
  const [helpIsOpen, setHelpIsOpen] = useState(false);
  const intl = useIntl();
  const header = intl.formatMessage({id: 'binky.common.userContextualHelp.header'});
  const hyperlinktext = intl.formatMessage({
    id: 'binky.common.userContextualHelp.hyperlinktext',
  });
  const text = intl.formatMessage({id: 'binky.common.userContextualHelp.text'});

  const handleFooterLink = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'userprovisioning:viewuserswithoutaccesslink:click',
    });
    setHelpIsOpen(false);
    onLinkPressed();
  };

  const handleDialogBox = (isOpen) => {
    setHelpIsOpen(isOpen);
    if (isOpen) {
      dispatchUiEventAnalytics({
        eventAction: EVENT_ACTION.CLICK,
        eventName: 'userprovisioning:info:click',
      });
    }
  };

  return (
    <StatusLight alignStart {...statusLightProps}>
      {statusLightText}
      {hasContextualHelp && (
        <ContextualHelp
          data-testid="status-light-contextual-help"
          isOpen={helpIsOpen}
          marginStart="size-100"
          onOpenChange={handleDialogBox}
          variant="info"
        >
          <Heading>{header}</Heading>
          <Content>
            <Text>{text}</Text>
          </Content>
          <Footer>
            <Link data-testid="status-light-contextual-help-link" onPress={handleFooterLink}>
              {hyperlinktext}
            </Link>
          </Footer>
        </ContextualHelp>
      )}
    </StatusLight>
  );
};

StatusLightWithContextualHelp.propTypes = {
  hasContextualHelp: PropTypes.bool.isRequired,
  onLinkPressed: PropTypes.func,
  statusLightProps: PropTypes.shape({
    variant: PropTypes.string.isRequired,
  }).isRequired,
  statusLightText: PropTypes.string,
};

export default StatusLightWithContextualHelp;
