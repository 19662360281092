import {MIGRATION_STATUS, MIGRATION_TYPE} from 'models/migration/MigrationConstants';

/**
 * @description Returns true if the migration list is "T2E clean". This means its org does not contain
 *   any T1 accounts (except the contract owner) and new T1s cannot be added to it; instead,
 *   only T2Es can be added. This should be the case for orgs that have completed T2E migration,
 *   and orgs that have been created since the T2E project launch. JIL represents both these
 *   cases by returning a T2E migration with status MIGRATED.
 * @param {MigrationList} migrationList - the migration list to check for a migrated T2E
 *
 * @return {Boolean} true if the migration list is T2E-clean, else returns false.
 */
function isMigrationListT2eClean(migrationList) {
  return migrationList.findByType(MIGRATION_TYPE.T2E)?.status === MIGRATION_STATUS.MIGRATED;
}

/**
 * @description Returns true if the migration list contains "ESM Type1" with migrated "status"
 * @param {MigrationList} migrationList - the migration list to check for a migrated ESM T1
 *
 * @return {Boolean} true if the migration list contains ESM T1 with migrated status, else returns false.
 */
function isMigrationListESMType1(migrationList) {
  return migrationList.findByType(MIGRATION_TYPE.ESM_TYPE1)?.status === MIGRATION_STATUS.MIGRATED;
}

export {isMigrationListESMType1, isMigrationListT2eClean};
