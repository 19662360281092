(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.navigation.responsive-nav.component:binkyResponsiveNav
   * @description Component that displays a responsive navigation menu. A responsive
   *   navigation menu will resize itself to match its container size, wrapping
   *   items that would overflow this width and collapsing them into a more menu
   *   dropdown. If the width is too small to display any menu items, then the
   *   menu will entirely collapse into a "hamburger" icon dropdown menu.
   *
   *   The responsive nav menu is only concerned with laying out the menu items
   *   that it contains (i.e. - the top-level menu, and any dropdowns that may be
   *   created as a result of page size changes, text size changes, or container
   *   size changes). It does not deal with positioning dropdown menus or
   *   nested dropdown menus. These are left to the binkyResponsiveNavDropdown
   *   component to manage.
   *
   *   While unable to explicitly set the orientation of a responsive navigation
   *   menu, the orientation will be set to horizontal if the variant is not
   *   set to 'light'. If the variant is set to 'light', then the orientation will
   *   automatically set itself, according to the amount of horizontal space
   *   available to the menu - if sufficient space exists, it will display vertically
   *   (like a left-hand sidebar menu) without wrapping any content, if there is
   *   insufficient horizontal space, then it will display horizontally and wrap
   *   items that are unable to fit (akin to standard responsive nav display)
   * @param {NavItem[]} items - List of NavItems that should be displayed in this
   *   responsive navigation menu
   * @param {Function} [onCollapse] - method to call when all items wrap in
   *   navigation menu. Must pass in a JSOn Object to method with a key called
   *   isNavCollapsed (Boolean), indicating whether or not the menu is collapsed
   *   (true means collapsed, else false if expanded)
   * @param {String} [variant] - Currently only 'light' is recognized; describes
   *   the styling of the navigation menu - light is a slightly smaller menu with
   *   slightly smaller fonts and a white background, often present in page menus,
   *   while the default variant is larger, has a dark background, and is commonly
   *   used for workspace menus
   */
  angular.module('binky.shell.navigation.responsive-nav').component('binkyResponsiveNav', {
    bindings: {
      items: '<',
      onCollapse: '&?',
      variant: '@?',
    },
    controller,
    templateUrl: 'shell/navigation/responsive-nav/responsive-nav.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    $scope,
    $timeout,
    _,
    NAV_DROPDOWN_OPENED,
    NAV_MENU_ID_PREFIX,
    navDropdownManager,
    PANEL_MANAGER,
    panelManager,
    ResponsiveNavItem
  ) {
    let destroyItemsWatcher = _.noop;
    let itemHiddenWatchers = [];
    const throttledUpdateDisplay = _.throttle(updateDisplay, 300, {trailing: true});

    const vm = this;
    _.assign(vm, {
      $onDestroy,
      $onInit,
      onSizeChange,
      onVerticalMenuSizeChange,
    });

    function $onInit() {
      startListening();

      vm.elementDimensions = {};
      vm.id = _.uniqueId(NAV_MENU_ID_PREFIX);
      vm.itemsWrapped = false;
      vm.railOpened = panelManager.isPanelTypeOpen(PANEL_MANAGER.TYPE.RAIL);

      processItems();

      destroyItemsWatcher = $scope.$watchCollection('$ctrl.items', checkEqualityAndProcessItems);
    }

    function $onDestroy() {
      stopListening();
      destroyItemsWatcher();
    }

    function onSizeChange(id, dimensions) {
      // don't pay attention if elements are hidden (display: none)...
      if (dimensions.height > 0 && dimensions.width > 0) {
        vm.elementDimensions[id] = dimensions;
        throttledUpdateDisplay();
      }
    }

    function onVerticalMenuSizeChange(id, dimensions) {
      if (_.get(vm.elementDimensions[id], 'height') > 0) {
        if (dimensions.height < 1) {
          // previously showing vertical menu, now closed (close any open dropdowns)
          closeOpenDropdowns();
        }
      }
      vm.elementDimensions[id] = dimensions;
    }

    ////////

    function checkEqualityAndProcessItems(newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        processItems();
      }
    }

    function closeOpenDropdowns() {
      // hide more menus on changes to menu contents (items added/removed)
      navDropdownManager.close(vm.moreMenuNavItem);

      // hide any other dropdowns that are showing
      const openDropdowns = _.filter(vm.listElements, 'isDropdownOpen');
      _.forEach(openDropdowns, (openDropdown) => navDropdownManager.close(openDropdown));
    }

    function dropdownOpenedHandler(event) {
      event.stopPropagation();
      $timeout(() => {
        vm.dropdownToggling = true;
      });

      $timeout(() => {
        vm.dropdownToggling = false;
      }, 4); // current browser default refresh ~4ms (allows for unit testing of toggling behavior)
    }

    function onRailClose() {
      vm.railOpened = false;
    }

    function onRailOpen() {
      vm.railOpened = true;
    }

    function processItems() {
      watchItems();
      const partitionedItems = _.partition(vm.items, 'group');
      const groupedItems = partitionedItems[0];
      const ungroupedItems = partitionedItems[1];
      const ungroupedNavItems = _.map(
        ungroupedItems,
        (navItem) => new ResponsiveNavItem({menuId: vm.id, navItem})
      );

      if (groupedItems.length > 0) {
        const itemsByGroup = _.groupBy(groupedItems, 'group');
        const groupNames = _.keys(itemsByGroup);
        const groupedNavItems = _.map(
          groupNames,
          (groupName) =>
            new ResponsiveNavItem({
              groupName,
              navItems: _.map(
                itemsByGroup[groupName],
                (navItem) => new ResponsiveNavItem({menuId: vm.id, navItem})
              ),
            })
        );
        vm.listElements = [...groupedNavItems, ...ungroupedNavItems];
      } else {
        vm.listElements = ungroupedNavItems;
      }

      vm.moreMenuNavItem = new ResponsiveNavItem({
        groupName: 'binky.shell.navigation.more',
        menuId: vm.id,
        navItems: [],
        order: vm.listElements.length + 1,
      });
    }

    function startListening() {
      vm.destroyDropdownListener = $scope.$on(NAV_DROPDOWN_OPENED, dropdownOpenedHandler);
      vm.destroyRailCloseListener = $rootScope.$on(PANEL_MANAGER.RAIL.CLOSE, onRailClose);
      vm.destroyRailOpenListener = $rootScope.$on(PANEL_MANAGER.RAIL.OPEN, onRailOpen);
    }

    function stopListening() {
      vm.destroyDropdownListener();
      vm.destroyRailCloseListener();
      vm.destroyRailOpenListener();
    }

    function updateDisplay() {
      const renderedListWidth = _.get(vm, 'elementDimensions.menu.width', 0);

      // This approach traverses list and builds up from scratch each time
      // if wrapping is even possible (must have at least two items)
      if (vm.listElements.length > 1) {
        wrapItemsOverFullListSize(renderedListWidth);
      }
    }

    function watchItems() {
      _.forEach(itemHiddenWatchers, (itemHiddenWatcher) => itemHiddenWatcher());
      itemHiddenWatchers = _.map(vm.items, (val, idx) =>
        $scope.$watch(`$ctrl.items[${idx}].hidden`, checkEqualityAndProcessItems)
      );
    }

    function wrapItem(listOfWrappedElements, responsiveNavItem) {
      responsiveNavItem.parent = vm.moreMenuNavItem;
      responsiveNavItem.wrapped = true;
      listOfWrappedElements.push(responsiveNavItem);
    }

    // apply 'wrapped' style to elements over list width
    function wrapItemsOverFullListSize(renderedListWidth) {
      _.invokeMap(vm.listElements, 'resetState');
      const wrappedNavItems = [];
      // need enough room for the more menu
      let listWidth = _.get(vm, `elementDimensions['moreMenu'].width`, 0);
      let itemsWrapped = false;
      _.forEach(vm.listElements, (element, index) => {
        if (itemsWrapped) {
          wrapItem(wrappedNavItems, element);
        } else {
          listWidth += _.get(vm, `elementDimensions[${element.id}].width`, 0);
          if (index === vm.listElements.length - 1) {
            // last item, so see if it fits with the moreMenu absent
            listWidth -= _.get(vm, `elementDimensions['moreMenu'].width`, 0);
          }
          if (renderedListWidth < listWidth) {
            // if more than 1 item, then also hide parent (will be replaced with hamburger)
            if (index > 0) {
              const previousElement = vm.listElements[_.indexOf(vm.listElements, element) - 1];
              wrapItem(wrappedNavItems, previousElement);
            }
            itemsWrapped = true;
            wrapItem(wrappedNavItems, element);
          }
        }
      });
      // wrap this item in a $timeout to kick off an angular $digest cycle and
      // recognize the changed values (above)
      $timeout(() => {
        vm.itemsWrapped = itemsWrapped;
        vm.allItemsWrapped = wrappedNavItems.length === vm.listElements.length;
        _.invoke(vm, 'onCollapse', {isNavCollapsed: vm.allItemsWrapped});
        if (vm.moreMenuNavItem.navItems.length !== wrappedNavItems.length) {
          closeOpenDropdowns();
        }
        vm.moreMenuNavItem.navItems = wrappedNavItems;
      });
    }
  }
})();
