const LICENSE_GROUP_ADMIN_LIST_CACHE_ID = 'LicenseGroupAdminList';

const LICENSE_GROUP_ADMIN_LIST_EVENT = {
  UPDATE: {
    COUNT: 'UpdateLicenseGroupAdminsCount',
    LIST: 'UpdateLicenseGroupAdminsList',
  },
};

export {LICENSE_GROUP_ADMIN_LIST_CACHE_ID, LICENSE_GROUP_ADMIN_LIST_EVENT};
