const LICENSE_QUANTITY_LIST_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.licenseQuantityList.LICENSE_QUANTITY_LIST_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/models/licenseQuantityList/LicenseQuantityConstants.js
   */
  angular
    .module('binky.core.product.license-quantity-list')
    .constant(
      'LICENSE_QUANTITY_RENEWAL_STATUS',
      LICENSE_QUANTITY_LIST_CONSTANTS.LICENSE_QUANTITY_RENEWAL_STATUS
    )
    .constant('LICENSE_QUANTITY_STATUS', LICENSE_QUANTITY_LIST_CONSTANTS.LICENSE_QUANTITY_STATUS)
    .constant('LICENSE_QUANTITY_TAGS', LICENSE_QUANTITY_LIST_CONSTANTS.LICENSE_QUANTITY_TAGS)
    .constant(
      'LICENSE_QUANTITY_UNLIMITED',
      LICENSE_QUANTITY_LIST_CONSTANTS.LICENSE_QUANTITY_UNLIMITED
    );
})();
