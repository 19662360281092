import binky from '@admin-tribe/binky';
import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import Avatar from '../avatar/Avatar';

const UserGroup = binky.services.users.UserGroup;

const targetOrgTooltip = (intl, orgName) => {
  if (orgName) {
    return intl.formatMessage(
      {id: 'binky.common.userGroupAvatar.shared.target.tooltip'},
      {orgName}
    );
  }
  return intl.formatMessage({id: 'binky.common.userGroupAvatar.shared.target.tooltip.noOrg'});
};

/**
 * A special avatar for user group. It needs to be wrapped in a focusable ActionButton so that an optional tooltip
 * can explain the source of the user group if it was shared.
 *
 * @param marginEnd a prop passed into the "Avatar" component by binky TableIconNameCell to add margin
 * @param member The UserGroup member to display
 * @param size The icon size
 * @param props any other props passed into the "Avatar" component, including `size`
 * @constructor
 */
const UserGroupAvatar = ({marginEnd, member, size = 'S', ...props}) => {
  const intl = useIntl();

  if (member.getType().isUserGroup() && member.isShared() && member instanceof UserGroup) {
    const userGroup = member;
    const tooltip = userGroup.isTarget
      ? targetOrgTooltip(intl, userGroup.sharedUserGroupSource?.sourceOrg?.name)
      : intl.formatMessage({id: 'binky.common.userGroupAvatar.shared.source.tooltip'});
    return (
      <TooltipTrigger data-testid="usergroup-tooltip-trigger" delay={0} placement="top start">
        <ActionButton
          aria-label={intl.formatMessage({id: 'binky.common.userGroupAvatar.shared.label'})}
          data-testid="shared-usergroup-button"
          isQuiet
          marginEnd={marginEnd}
          {...props}
        >
          <Avatar member={member} size={size} />
        </ActionButton>
        <Tooltip>{tooltip}</Tooltip>
      </TooltipTrigger>
    );
  }
  return (
    <ActionButton
      aria-label={intl.formatMessage({id: 'binky.common.userGroupAvatar.unshared.label'})}
      data-testid="unshared-usergroup-button"
      isQuiet
      marginEnd={marginEnd}
      {...props}
    >
      <Avatar member={member} size={size} />
    </ActionButton>
  );
};

UserGroupAvatar.displayName = 'UserGroupAvatar';
UserGroupAvatar.propTypes = {
  /**
   * Optional margin following the avatar and any sibling elements.
   */
  marginEnd: PropTypes.string,
  /**
   * The UserGroup member to display.
   */
  member: PropTypes.instanceOf(UserGroup).isRequired,
  /**
   * The size of the avatar. Default is 'S'.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']),
};

export default UserGroupAvatar;
