import {ActionButton, AlertDialog, DialogTrigger} from '@adobe/react-spectrum';
import Delete from '@spectrum-icons/workflow/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Delete comment widget that wraps a button and a confirmation modal.
 */
const DeleteComment = ({onDelete}) => {
  const intl = useIntl();

  return (
    <DialogTrigger>
      <ActionButton
        aria-label={intl.formatMessage({id: 'binky.common.comments.delete.title'})}
        data-testid="delete-comment-button"
        isQuiet
        onPress={() => {}}
      >
        <Delete
          aria-label={intl.formatMessage({id: 'binky.common.comments.delete.title'})}
          size="XS"
        />
      </ActionButton>
      <AlertDialog
        cancelLabel={intl.formatMessage({id: 'binky.common.comments.delete.cancel'})}
        data-testid="delete-comment-dialog"
        onPrimaryAction={onDelete}
        primaryActionLabel={intl.formatMessage({id: 'binky.common.comments.delete.cta'})}
        title={intl.formatMessage({id: 'binky.common.comments.delete.title'})}
        variant="destructive"
      >
        {intl.formatMessage({id: 'binky.common.comments.delete.content'})}
      </AlertDialog>
    </DialogTrigger>
  );
};

DeleteComment.propTypes = {
  /**
   * Callback to invoke when the comment is deleted.
   */
  onDelete: PropTypes.func,
};

export default DeleteComment;
