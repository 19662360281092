const TAX_TERM = {
  GST: 'GST',
  IVA: 'IVA',
  KDV: 'KDV',
  SST: 'SST',
  TAX: 'TAX',
  VAT: 'VAT',
};

export {TAX_TERM};
