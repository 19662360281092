import {feature} from '@admin-tribe/binky';
import {
  Button,
  ButtonGroup,
  Cell,
  Column,
  Flex,
  Row,
  SearchField,
  Switch,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import {TruncatedText} from '@pandora/react-truncated-text';
import debounce from 'lodash/debounce';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import MoreOptionsMenu from 'common/components/more-options-menu/MoreOptionsMenu';

const FILTER_CHANGE_DEBOUNCE_DELAY = 300;

/**
 * OmniToolFlagsPanel - encapsulates data for the flags panel
 */
const OmniToolFlagsPanel = () => {
  const intl = useIntl();

  const [filterText, setFilterText] = useState('');

  const getFeatureState = useCallback(
    ({filterTo = filterText} = {}) => {
      const allFeatures = feature.getFeatures();
      return allFeatures
        .filter((featureName) => !filterTo || featureName.includes(filterTo))
        .map((featureName) => ({
          key: featureName,
          name: getDisplayableName(featureName),
          options: getDisplayableOptions(featureName),
          source: getDisplayableSource(featureName),
        }));

      function getDisplayableName(featureKey) {
        return `${feature.hasChange(featureKey) ? '*' : ''}${featureKey}`;
      }

      function getDisplayableOptions(featureKey) {
        const action = feature.isOverridden(featureKey) ? 'reset' : 'override';
        return [
          {
            body: intl.formatMessage({
              id: `binky.common.omniTool.flagsPanel.table.moreOptions.${action}`,
            }),
            key: action,
          },
        ];
      }

      function getDisplayableSource(featureKey) {
        let source =
          (feature.isOverridden(featureKey)
            ? feature.getSource(featureKey)
            : feature.getNonOverrideSource(featureKey)) || 'unset';

        if (feature.hasChange(featureKey)) {
          const status = feature.isOverridden(featureKey)
            ? feature.isEnabled(featureKey)
            : feature.getNonOverrideValue(featureKey);
          source += ` (${status ? 'on' : 'off'})`;
        }

        return source;
      }
    },
    [filterText, intl]
  );

  const [featureState, setFeatureState] = useState(getFeatureState());

  const updateFeatureState = useCallback(
    (filterParams) => {
      setFeatureState(getFeatureState(filterParams));
    },
    [getFeatureState, setFeatureState]
  );

  const debounceChangeFilter = useMemo(
    () =>
      debounce((input) => {
        setFilterText(input);
        updateFeatureState({filterTo: input});
      }, FILTER_CHANGE_DEBOUNCE_DELAY),
    [updateFeatureState]
  );

  function invokeOption(featureItem, key) {
    const featureKey = featureItem.key;
    switch (key) {
      case 'override':
        feature.prepareOverride({enabled: getToggleState(featureItem), feature: featureKey});
        break;
      case 'reset':
        feature.unprepareOverride(featureKey);
        break;
      default:
        break;
    }
    updateFeatureState();
  }

  function getToggleState(featureItem) {
    const featureKey = featureItem.key;
    return feature.isOverridden(featureKey)
      ? feature.getOverrideValue(featureKey)
      : feature.getNonOverrideValue(featureKey);
  }

  function resetAll() {
    feature.resetOverrides();
    updateFeatureState();
  }

  function setToggleState(featureItem) {
    const featureKey = featureItem.key;
    feature.prepareOverride({enabled: !getToggleState(featureItem), feature: featureKey});
    updateFeatureState();
  }

  const filterLabel = intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.filter.label'});

  return (
    <>
      <Flex alignItems="end" direction="row" gap="size-200" marginTop="size-100">
        <SearchField
          aria-label={filterLabel}
          label={filterLabel}
          minLength={0}
          onChange={debounceChangeFilter}
          onSubmit={debounceChangeFilter}
          width="size-3600"
        />
        <ButtonGroup>
          <Button
            data-testid="save-and-reload"
            onPress={() => feature.saveAndReload()}
            variant="secondary"
          >
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.buttons.saveAndReload'})}
          </Button>

          <Button data-testid="reset-all" onPress={resetAll} variant="secondary">
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.buttons.resetAll'})}
          </Button>
        </ButtonGroup>
      </Flex>
      <TableView
        aria-label={intl.formatMessage({id: 'binky.common.omniTool.tabs.flags'})}
        density="compact"
        marginTop="size-100"
        // Setting a maxHeight to fix a React Spectrum table resizing bug that crashes the page
        // See: https://jira.corp.adobe.com/browse/ONESIE-38109
        maxHeight={600}
        overflowMode="wrap"
      >
        <TableHeader>
          <Column width="54%">
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.table.headers.name'})}
          </Column>
          <Column width="18%">
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.table.headers.source'})}
          </Column>
          <Column width="14%">
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.table.headers.active'})}
          </Column>
          <Column width="14%">
            {intl.formatMessage({id: 'binky.common.omniTool.flagsPanel.table.headers.more'})}
          </Column>
        </TableHeader>
        <TableBody>
          {featureState.map((featureItem) => (
            <Row key={featureItem.name}>
              <Cell>
                <TruncatedText data-testid="feature-name" textContent={featureItem.name} />
              </Cell>
              <Cell data-testid="feature-source">{featureItem.source}</Cell>
              <Cell>
                <Switch
                  aria-label={intl.formatMessage({
                    id: 'binky.common.omniTool.flagsPanel.table.headers.active',
                  })}
                  defaultSelected={getToggleState(featureItem)}
                  onChange={() => setToggleState(featureItem)}
                />
              </Cell>
              <Cell>
                <MoreOptionsMenu
                  menuItems={featureItem.options}
                  onItemSelected={(key) => invokeOption(featureItem, key)}
                />
              </Cell>
            </Row>
          ))}
        </TableBody>
      </TableView>
    </>
  );
};

export default OmniToolFlagsPanel;
