(function () {
  /**
   * @ngdoc module
   * @name binky.core.invoice
   * @description Module which contains invoice (billing history) related services
   */
  angular.module('binky.core.invoice', [
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.common.model',
    'binky.core.lodash',
  ]);
})();
