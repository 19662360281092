(function () {
  /**
   * @deprecated Please use src2/core/models/country/Country.js
   *
   * @ngdoc factory
   * @name Country
   * @description Model for an individual Country
   */
  angular.module('binky.core.common.country').factory('Country', getCountryModel);

  /* @ngInject */
  function getCountryModel(binkySrc2) {
    return binkySrc2.models.country.Country;
  }
})();
