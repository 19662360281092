const LEVEL = {
  BASIC: 'BASIC',
  LIGHT: 'LIGHT',
  MEDIUM: 'MEDIUM',
  MEDIUM_REUSE: 'MEDIUM_REUSE',
  MEDIUM_REUSE_REJECT_COMMON: 'MEDIUM_REUSE_REJECTCOMMON',
  MEDIUM_REUSE_RESET: 'MEDIUM_REUSE_RESET',
  PCI: 'PCI',
  UNDEFINED: 'UNDEFINED',
};

export {LEVEL};
