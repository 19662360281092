import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @deprecated Use Spectrum's Link with an onPress handler.
 *
 * Looks like a link but has an onPress handler on the Link rather than an href.
 * For string-typed children, the link component will expose the accessible role to assistive technology as a "link".
 * See https://react-spectrum.adobe.com/react-spectrum/Link.html for valid linkProps.
 */
const ButtonLink = (props) => {
  const {children, onPress, ...linkProps} = props;

  return (
    <Link {...linkProps} onPress={onPress}>
      {children}
    </Link>
  );
};

ButtonLink.propTypes = {
  /**
   * The content to display in the link.
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether the link should be displayed with a quiet style.
   */
  isQuiet: PropTypes.bool,
  /**
   * Handler that is called when the press is released over the link.
   */
  onPress: PropTypes.func.isRequired,
  /**
   * The visual style of the link.
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'overBackground']),
};

export default ButtonLink;
