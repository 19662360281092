(function () {
  /**
   * @deprecated Please use src2/core/models/user/linked-accounts/linkedUserAccountListConstants.js
   */
  angular
    .module('binky.core.user.linked-accounts')
    .constant('LINKED_USER_ACCOUNT_LIST_CACHE_ID', 'LinkedUserAccountList')
    .constant(
      'LINKED_USER_ACCOUNT_LIST_FILTER',
      // eslint-disable-next-line angular/window-service
      window.binkySrc2.services.users.LINKED_USER_ACCOUNT_LIST_CONSTANTS
        .LINKED_USER_ACCOUNT_LIST_FILTER
    );
})();
