(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.organizations:OrganizationEncryption
   * @description model that represents the encryption information for an organization.
   */
  angular
    .module('binky.core.organizations')
    .factory('OrganizationEncryption', getOrganizationEncryption);
  /* @ngInject */
  function getOrganizationEncryption(_, kmi) {
    class OrganizationEncryption {
      /**
       * @description Method to create a new instance of an OrganizationEncryption object.
       * @param {Object} options - options to config the new OrganizationEncryption object.
       * @param {Object} [options.encryptionProgressStatus] - encryption progress status of new OrganizationEncryption object.
       * @param {Object} [options.encryptionStatus] - encryption status of new OrganizationEncryption object.
       * @param {string} options.orgId - associates OrganizationEncryption instance with an organization.
       */
      constructor(options) {
        this.orgId = options.orgId;
        updateEncryption(this, options);
      }

      /**
       * @description Method to enable encryption for an organization.
       * @returns {Boolean} true if org is present in org list.
       */
      enableOrgEncryption() {
        this.$resolved = false;
        this.$promise = kmi.orgEncryption
          .enableEncryption(this.orgId)
          .$promise.then(onSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;

        function onSuccess(response) {
          updateEncryption(this, response);
          return this;
        }
      }

      /**
       * @description Method to get the current encryption information of an organization.
       * @returns {Promise} a promise.
       */
      getOrgEncryptionStatusInfo() {
        this.$resolved = false;
        this.$promise = kmi.orgEncryption
          .getOrgEncryptionStatus(this.orgId)
          .$promise.then(onSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;

        function onSuccess(response) {
          updateEncryption(this, response);
          return this;
        }
      }

      /**
       * @description Method to revoke encryption for an organization.
       * @returns {Promise} a promise.
       */
      revokeOrgEncryption() {
        this.$resolved = false;
        this.$promise = kmi.orgEncryption
          .revokeEncryption(this.orgId)
          .$promise.then(onSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;

        function onSuccess(response) {
          updateEncryption(this, response);
          return this;
        }
      }

      /**
       * @description Method to fetch the OrganizationEncryption.
       * @param {Object} options as described below
       * @param {string} options.orgId - ID of organization for which OrganizationEncryption should be fetched.
       * @returns {OrganizationEncryption} OrganizationEncryption object.
       */
      static get(options) {
        const organizationEncryption = new OrganizationEncryption(options);
        organizationEncryption.getOrgEncryptionStatusInfo();

        return organizationEncryption;
      }
    }

    return OrganizationEncryption;

    //////////////////

    /**
     * @description Method to update the encryptionOrganization object
     * @param {Object} organizationEncryption - the OrganizationEncryption object to be updated.
     * @param {Object} options as described below
     * @param {Object} [options.encryptionProgressStatus] - encryption progress status of OrganizationEncryption object.
     * @param {Object} [options.encryptionStatus] - encryption status of OrganizationEncryption object.
     * @param {string} options.orgId - ID of organization for which OrganizationEncryption should be fetched.
     * @returns {Object} updated OrganizationEncryption object.
     */
    function updateEncryption(organizationEncryption, options) {
      angular.extend(organizationEncryption, toMinimumObject(options));
      return organizationEncryption;
    }

    function toMinimumObject(object) {
      return _.pick(object, [
        'encryptionStatus',
        'encryptionProgressStatus',
        'orgEncryptionRegionsStatus',
        'orgId',
      ]);
    }
  }
})();
