(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.member.member-list-table
   * @description module that defines the member-list-table widget
   */
  angular.module('binky.widgets.common.member.member-list-table', [
    'binky.core.api.jil',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.ui-migration',
    'binky.widgets.common.avatar',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.member.member-license-end-date',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.member.member-type',
    'binky.widgets.common.member.status',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.products.product-icon',
    'binky.widgets.products.product-role-switcher',
    'binky.widgets.user.user-role',
    'ngCoral',
    'pascalprecht.translate',
  ]);
})();
