import {MEMBER_TYPE, MemberType, feature} from '@admin-tribe/binky';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
function getMemberDisplayName(intl, member, {fallbackToEmail = false} = {}) {
  const displayName = member.getDisplayName();
  const trimmedName = displayName && displayName.trim();

  if (trimmedName) {
    return trimmedName;
  }
  if (fallbackToEmail && member.email) {
    return member.email;
  }

  return intl.formatMessage({
    id: 'binky.member.memberName.none',
  });
}
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
function getMemberTypeLabel(intl, memberType, {format} = {}) {
  const casing = format === 'uppercase' ? 'uppercase' : 'lowercase';
  return intl.formatMessage({id: `binky.member.memberType.${casing}.${getTypeName(memberType)}`});
}

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- wenywang@ to update
function getMemberType(item) {
  if (feature.isEnabled('temp_remove_business_id') && item.type === MEMBER_TYPE.TYPE2E) {
    return new MemberType(
      item.authenticatingAccount?.type || MEMBER_TYPE.NOT_AVAILABLE,
      item.authenticatingAccount?.id || undefined
    );
  }
  return item.getType();
}

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- wenywang@ to update
function getTypeName(memberType) {
  return memberType.isTechnicalAccount() ? 'technical_account' : memberType.type.toLowerCase();
}

/**
 * Checks if member is type2E
 *
 * @param {Member} member Method to determine if member is type2E
 * @returns {Boolean} return true if member is type2E, false otherwise
 */
function isT2EUser(member) {
  return member.type === MEMBER_TYPE.TYPE2E;
}

export {getMemberDisplayName, getMemberType, getMemberTypeLabel, getTypeName, isT2EUser};
