import {modelCache} from '@admin-tribe/binky';
import {
  Button,
  Cell,
  Column,
  Flex,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import {TruncatedText} from '@pandora/react-truncated-text';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import MoreOptionsMenu from 'common/components/more-options-menu/MoreOptionsMenu';
import {copyWithAlertToast} from 'common/utils/clipboard';

const ACTIONS = {
  CLEAR: 'clear',
  COPY: 'copy',
};

const countKeys = (keyIterator) => (keyIterator.next().done ? 0 : 1 + countKeys(keyIterator));

// Combines all cached items and keys for a modelId into a single object. This function is a little
// convoluted, but writing it as a loop throws a bunch of eslint errors. React doesn't like iterators,
// so I'd avoid using them in components that are exposed to users.
const getCache = async (modelId, keyIterator) => {
  const next = keyIterator.next();
  if (next.done) {
    return {};
  }
  return {
    ...(await getCache(modelId, keyIterator)),
    ...{[next.value]: await modelCache.get(modelId, next.value)},
  };
};

const getMenuItems = (intl) => [
  {
    body: intl.formatMessage({
      id: `binky.common.omniTool.cachePanel.table.moreOptions.clear`,
    }),
    key: ACTIONS.CLEAR,
  },
  {
    body: intl.formatMessage({
      id: `binky.common.omniTool.cachePanel.table.moreOptions.copy`,
    }),
    key: ACTIONS.COPY,
  },
];

/**
 * OmniToolCachePanel - encapsulates data for the utilities panel
 */
const OmniToolCachePanel = () => {
  const intl = useIntl();
  const [modelIds, setModelIds] = useState(modelCache.getModelIds());

  const onItemSelected = async ({action, modelId}) => {
    // eslint-disable-next-line default-case -- default case unncessary
    switch (action) {
      case ACTIONS.CLEAR:
        modelCache.clear(modelId);
        setModelIds(modelCache.getModelIds());
        break;
      case ACTIONS.COPY:
        copyWithAlertToast({
          intl,
          value: JSON.stringify(await getCache(modelId, modelCache.getKeys(modelId))),
        });
    }
  };

  const clearAll = () => {
    modelCache.clearAll();
    setModelIds(modelCache.getModelIds());
  };

  return (
    <>
      <Flex direction="row" gap="size-100" marginTop="size-100">
        <Button data-testid="clear-all-caches" onPress={clearAll} variant="secondary">
          {intl.formatMessage({id: 'binky.common.omniTool.cachePanel.clearAllCaches'})}
        </Button>
      </Flex>
      <TableView
        aria-label={intl.formatMessage({id: 'binky.common.omniTool.tabs.cache'})}
        density="compact"
        overflowMode="wrap"
      >
        <TableHeader>
          <Column width="50%">
            {intl.formatMessage({id: 'binky.common.omniTool.cachePanel.table.headers.modelId'})}
          </Column>
          <Column width="30%">
            {intl.formatMessage({id: 'binky.common.omniTool.cachePanel.table.headers.keyCount'})}
          </Column>
          <Column width="20%">
            {intl.formatMessage({id: 'binky.common.omniTool.cachePanel.table.headers.more'})}
          </Column>
        </TableHeader>
        <TableBody>
          {modelIds.map((modelId) => (
            <Row key={modelId}>
              <Cell>
                <TruncatedText textContent={modelId} />
              </Cell>
              <Cell>{countKeys(modelCache.getKeys(modelId))}</Cell>
              <Cell>
                <MoreOptionsMenu
                  menuItems={getMenuItems(intl)}
                  onItemSelected={(action) => {
                    onItemSelected({action, modelId});
                  }}
                />
              </Cell>
            </Row>
          ))}
        </TableBody>
      </TableView>
    </>
  );
};

export default OmniToolCachePanel;
