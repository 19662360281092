/**
 * @description Method for checking if prop is required.
 *
 * @param {String} name - The name of the go-url.
 * @param {String} language - Two-character language code (or if China, zh-cn/zh-tw) that intl.locale provides
 * @returns {String} - A GoURL for the given name and language.
 */
function getGoUrl(name, language) {
  const nonStandardLanguages = {
    'zh-cn': 'zh_CN',
    'zh-tw': 'zh_TW',
  };
  const goUrlLanguage = nonStandardLanguages[language] || language;
  return `https://www.adobe.com/go/${name}_${goUrlLanguage}`;
}

// eslint-disable-next-line import/prefer-default-export -- utils
export {getGoUrl};
