import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilOrganizations from 'api/jil/jilOrganizations';
import JilModelList from 'services/modelList/JilModelList';
import {getLocalStorageItem, setLocalStorageItem} from 'utils/storageUtils';

import Organization from './Organization';
import {ORGANIZATION_LIST} from './OrganizationConstants';

class OrganizationList extends JilModelList {
  /**
   * @description Method to fetch the OrganizationList.
   * @returns {OrganizationList} singleton OrganizationList.
   */
  static get() {
    const model = new OrganizationList();
    return model.refresh();
  }

  /**
   * @description Method to fetch the last used active org ID from session storage
   * @returns {String} the default org ID, or undefined if currently unset.
   */
  static getActiveOrgIdFromSessionStorage() {
    return getLocalStorageItem('activeOrg');
  }

  /**
   * @description Method to create a new instance of an OrganizationList.
   */
  constructor() {
    super({
      itemClassRef: Organization,
      resource: jilOrganizations.getOrganizations,
      sortExpression: JIL_CONSTANTS.SORT.NAME,
      sortOrder: JIL_CONSTANTS.ORDER.ASC,
    });

    this.items = [];
    this.activeOrg = null;
  }

  /**
   * @description Method to find an org from list
   * @param {String} orgId id of org to lookup.
   * @returns {Organization} Organization instance if found, otherwise undefined
   */
  findByOrgId(orgId) {
    return this.items.find((org) => org.id === orgId);
  }

  /**
   * @description Method to get the currently active org.
   * @returns {Organization} An organization object or a Reject promise when org is not found.
   */
  getActive() {
    if (!this.activeOrg) throw new Error(ORGANIZATION_LIST.ERROR_CODE.ACTIVE_ORG_NOT_SET);
    return this.activeOrg;
  }

  /**
   * @description Method to check whether an org is present in list.
   * @param {String} orgId id of org to lookup.
   * @returns {Boolean} true if org is present in org list.
   */
  has(orgId) {
    return !!this.findByOrgId(orgId);
  }

  /**
   * @description Method to refresh the organization list
   * @returns {Promise} promise - promise object, resolved when the organization list is loaded.
   */
  async refresh() {
    await super.refresh();
    if (this.activeOrg) {
      await this.setActive(this.activeOrg.id);
    }
    return this;
  }

  /**
   * @description Method to set the active org.
   * @param {String} orgId - ID of the org to set active.
   * @returns {Promise} promise resolved when new active org loaded, reject if not found in list.
   */
  setActive(orgId) {
    this.activeOrg = this.items.find((org) => org.id === orgId);
    if (this.activeOrg) {
      setLocalStorageItem('activeOrg', orgId);
      return this.activeOrg.refresh();
    }
    // eslint-disable-next-line prefer-promise-reject-errors -- in twosie we check the error with String, not an object
    return Promise.reject('Organization not found');
  }
}

export default OrganizationList;
