/* eslint-disable react/no-unknown-property -- styleName not recognized */
import PropTypes from 'prop-types';
import React from 'react';

import './CardButton.pcss';

/**
 * A widget of card-like button.
 */
const CardButton = ({children, isSelected, onClick}) => (
  <div
    data-testid="card-button"
    onClick={onClick}
    onKeyDown={(event) => {
      // @cframpto -- look into updating this to use "useButton" from react-aria
      if (event.key === 'Enter' || event.key === ' ') {
        onClick();
      }
    }}
    role="button"
    styleName={`card-button ${isSelected ? 'selected' : ''}`}
    tabIndex="0"
  >
    {children}
  </div>
);

CardButton.propTypes = {
  /**
   * The content to display in the link.
   */
  children: PropTypes.node,
  /**
   * Whether the button is selected.
   */
  isSelected: PropTypes.bool,
  /**
   * Handler that is called when the button is clicked.
   */
  onClick: PropTypes.func,
};

export default CardButton;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
