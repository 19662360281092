(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.toggle
   * @description module that wraps the coral switch input and styles it as a
   *    Spectrum toggle
   */
  angular.module('binky.widgets.common.toggle', []);
})();
