import axios from 'axios';

import authentication from 'services/authentication';

let clientId, url;

/**
 * Configure ETLA Usage Report APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for ETLA Usage Report api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({clientId, url} = config);
};

/**
 * Get headers for ETLA Usage Report API calls
 *
 * @returns {Object} The header map
 */
const getHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for ETLA Usage Report API call');
  }

  return {
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };
};

/**
 * @description Returns the etla usage data points for a license owned by an organization under a particular contract.
 *
 * @param {String} contractId - The contract id
 * @param {String} licenseId - The license id
 * @param {String} orgId - The org id
 * @param {Object} params - Parameters for query
 * @param {String} params.type - the period type 'annual' or 'monthly'
 * @param {String} params.startDate - the start date for the 'annual' or 'monthly' period
 * @param {String} params.endDate - the end date for the 'annual' or 'monthly' period
 *
 * @returns {Promise<Object>} Axios response
 */
const getDataPoints = (contractId, licenseId, orgId, params) =>
  axios.get(`${url}/organizations/${orgId}/contracts/${contractId}/licenses/${licenseId}`, {
    headers: getHeaders(),
    params,
  });

/**
 * @description Returns the annual and monthly time periods for a particular ETLA contract of an organization.
 *
 * @param {String} contractId - The contract id
 * @param {String} orgId - The org id
 *
 * @returns {Promise<Object>} Axios response
 */
const getTimePeriods = (contractId, orgId) =>
  axios.get(`${url}/organizations/${orgId}/contracts/${contractId}/periods`, {
    headers: getHeaders(),
  });

/**
 * @description Returns an overview of the Products under the ETLA contract of a particular organization
 *
 * @param {Object} options Options
 * @param {String} options.orgId - The org id
 * @returns {Promise<Object>} Axios response
 */
const getProducts = ({orgId}) =>
  axios.get(`${url}/organizations/${orgId}`, {
    headers: getHeaders(),
  });

const etlaUsageReport = {
  configure,
  getDataPoints,
  getProducts,
  getTimePeriods,
};

export default etlaUsageReport;
