(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.user.authenticated-user-pulldown
   * @description module that defines the authenticated-user-pulldown widget
   */
  angular.module('binky.widgets.user.authenticated-user-pulldown', [
    'binky.core.authentication',
    'binky.core.lodash',
    'binky.shell.panels.pulldown',
    'binky.widgets.common.avatar',
    'binky.widgets.common.go-url',
    'binky.widgets.common.member.member-name',
    'binky.widgets.user.user-role',
  ]);
})();
