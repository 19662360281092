(function () {
  /**
   * @ngdoc       module
   * @name        binky.core.fraud-mitigation.ready
   * @description Define app-ready module for fraud-mitigation configuration
   */

  angular.module('binky.core.fraud-mitigation.ready', [
    'binky.core.configuration.ready',
    'binky.core.feature.ready',
    'binky.core.fraud-mitigation',
    'binky.core.lodash',
  ]);
})();
