import pick from 'lodash/pick';

import kmi from 'api/kmi/kmi';
import log from 'services/log';

class OrganizationEncryption {
  /**
   * @description Method to fetch the OrganizationEncryption.
   * @param {Object} options as described below
   * @param {string} options.orgId - ID of organization for which OrganizationEncryption should be fetched.
   * @returns {OrganizationEncryption} An OrganizationEncryption object.
   */
  static async get(options) {
    const organizationEncryption = new OrganizationEncryption(options);
    await organizationEncryption.getOrgEncryptionStatusInfo();

    return organizationEncryption;
  }

  /**
   * @description Method to create a new instance of an OrganizationEncryption object.
   * @param {Object} options - options to config the new OrganizationEncryption object.
   * @param {Object} [options.encryptionProgressStatus] - encryption progress status of new OrganizationEncryption object.
   * @param {Object} [options.encryptionStatus] - encryption status of new OrganizationEncryption object.
   * @param {string} options.orgId - associates OrganizationEncryption instance with an organization.
   */
  constructor(options) {
    this.orgId = options.orgId;
    updateEncryption(this, options);
  }

  /**
   * @description Method to enable encryption for an organization.
   * @returns {OrganizationEncryption} An OrganizationEncryption object or a Reject promise when org encryption cannot be enabled.
   */
  async enableOrgEncryption() {
    try {
      const response = await kmi.enableOrgEncryption({orgId: this.orgId});
      updateEncryption(this, response);
    } catch (error) {
      log.error('Could not enable org encryption. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to get the current encryption information of an organization.
   * @returns {OrganizationEncryption} An OrganizationEncryption object or a Reject promise when org encryption status cannot be fetched.
   */
  async getOrgEncryptionStatusInfo() {
    try {
      const response = await kmi.getOrgEncryptionStatus({orgId: this.orgId});
      updateEncryption(this, response);
    } catch (error) {
      log.error('Could not fetch org encryption status. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to revoke encryption for an organization.
   * @returns {OrganizationEncryption} An OrganizationEncryption object or a Reject promise when org encryption cannot be revoked.
   */
  async revokeOrgEncryption() {
    try {
      const response = await kmi.revokeOrgEncryption({orgId: this.orgId});
      updateEncryption(this, response);
    } catch (error) {
      log.error('Could not revoke org encryption. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

/**
 * @description Method to update the encryptionOrganization object
 * @param {Object} organizationEncryption - the OrganizationEncryption object to be updated.
 * @param {Object} options as described below
 * @param {Object} [options.encryptionProgressStatus] - encryption progress status of OrganizationEncryption object.
 * @param {Object} [options.encryptionStatus] - encryption status of OrganizationEncryption object.
 * @param {string} options.orgId - ID of organization for which OrganizationEncryption should be fetched.
 * @returns {Object} updated OrganizationEncryption object.
 */
function updateEncryption(organizationEncryption, response) {
  Object.assign(
    organizationEncryption,
    pick(response.data, [
      'encryptionStatus',
      'encryptionProgressStatus',
      'orgEncryptionRegionsStatus',
      'orgId',
    ])
  );
  return organizationEncryption;
}

export default OrganizationEncryption;
