import {Locale} from '@admin-tribe/binky';
import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * A simple div used by the toastManager to render the toasts in. When the
 * first element of the app is a spectrum provider we won't need this anymore.
 *
 * This ensures the toast container is rendered in the body to be between
 * the last elements in the DOM so the toasts render above everything else.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const ToastContainer = () => {
  const locale = Locale.get().activeLocaleForSpectrum;

  return ReactDOM.createPortal(
    <SpectrumV2Provider locale={locale} scale="medium" theme="light">
      <SpectrumV3Provider colorScheme="light" locale={locale} theme={defaultTheme}>
        <div className="react-toast-container" />
      </SpectrumV3Provider>
    </SpectrumV2Provider>,
    document.body
  );
};

export default ToastContainer;
