(function () {
  angular.module('binky.core.feature.ready', [
    'binky.core.api.ready',
    'binky.core.authentication.ready',
    'binky.core.configuration.ready',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
