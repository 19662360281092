(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilAdmins.js
   *
   * @ngdoc service/provider
   * @name jilAdmins
   * @description defines service to manage jil admin resources
   */
  angular.module('binky.core.api.jil').provider('jilAdmins', jilAdminsProvider);

  let url;

  /* @ngInject */
  function jilAdminsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        admins: getAdminsResource(),
      };

      return service;

      ///////////

      function getAdminsResource() {
        return $resource(
          `${url}/:orgId/admins`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            exportAgents: {
              headers: {
                Accept: 'text/csv+adobe-agents,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
