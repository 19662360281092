import {Flex, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

const ChartLegends = () => {
  const intl = useIntl();
  const licensesLegendTitle = intl.formatMessage({
    id: 'binky.common.etlaUsageReport.chart.legends.licenses',
  });
  const assignedLegendTitle = intl.formatMessage({
    id: 'binky.common.etlaUsageReport.chart.legends.assigned',
  });

  return (
    <Flex alignItems="center" flexGrow={1} justifyContent="flex-end">
      <View
        backgroundColor="indigo-600"
        height="size-125"
        marginEnd="size-100"
        marginStart="size-100"
        width="size-125"
      />
      {/* We hide this from Aria as the text is communicated via the table itself */}
      {/* We use span rather than RS Text because RS doesn't support aria-hidden */}
      <span aria-hidden="true">{licensesLegendTitle}</span>
      <View
        backgroundColor="static-white"
        borderColor="seafoam-600"
        borderRadius="medium"
        borderWidth="thick"
        height="size-125"
        marginEnd="size-100"
        marginStart="size-300"
        width="size-125"
      />
      {/* We hide this from Aria as the text is communicated via the table itself */}
      {/* We use span rather than RS Text because RS doesn't support aria-hidden */}
      <span aria-hidden="true">{assignedLegendTitle}</span>
    </Flex>
  );
};

export default ChartLegends;
