import pick from 'lodash/pick';

class SophiaDescriptor {
  /**
   * @class
   * @description Creates a new SophiaDescriptor object that describes sophia campaign items.
   *
   * @param {Object} options initialization options
   * @param {Array} [options.responses] Array of SophiaResponses
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['responses']));
  }
}

export default SophiaDescriptor;
