// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Icons should be replaced with v3 workflow icons
import SpectrumIcon from '@react/react-spectrum/Icon';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-image-icon
 */

/**
 * ImageIcon is a widget to display image in icon format.
 */
const ImageIcon = ({alt, className, size, src}) => (
  <SpectrumIcon className={className} size={size}>
    <img alt={alt} src={src} />
  </SpectrumIcon>
);

ImageIcon.propTypes = {
  /**
   * The alternative text to show for the image.
   */
  alt: PropTypes.string.isRequired,
  /**
   * The customized class name to display the widget.
   */
  className: PropTypes.string,
  /**
   * The size of the widget.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']).isRequired,
  /**
   * The image source url for the icon.
   */
  src: PropTypes.string.isRequired,
};

export default ImageIcon;
