import chunk from 'lodash/chunk';
import omitBy from 'lodash/omitBy';
import sumBy from 'lodash/sumBy';
import toInteger from 'lodash/toInteger';

import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import modelCache from 'services/cache/modelCache/modelCache';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';
import {
  LICENSE_GROUP_LIST_CACHE_ID,
  PRODUCT_CONFIGURATIONS_EVENT,
} from 'services/product/license-group/LicenseGroupConstants';
import {LICENSE_GROUP_ADMIN_LIST_EVENT} from 'services/product/license-group/admin-list/LicenseGroupAdminListConstants';
import {LICENSE_GROUP_USER_LIST_EVENT} from 'services/product/license-group/user-list/LicenseGroupUserListConstants';

import {PRODUCT_LIST_EVENT} from '../ProductListConstants';

import LicenseGroup from './LicenseGroup';

class LicenseGroupList extends JilModelList {
  /**
   * @description Method to construct a new LicenseGroupList.
   *
   * @param {Object} options - LicenseGroupList instance settings
   * @param {String} options.orgId - organization id
   * @param {Product} options.product - the model of the product whose list of license groups should be returned
   *
   */
  constructor(options) {
    super({
      cacheClearingEvents: [
        MEMBER_EVENT.CREATE,
        MEMBER_EVENT.DELETE,
        MEMBER_EVENT.UPDATE,
        LICENSE_GROUP_USER_LIST_EVENT.UPDATE.LIST,
        LICENSE_GROUP_ADMIN_LIST_EVENT.UPDATE.LIST,
        PRODUCT_CONFIGURATIONS_EVENT.CREATE,
        PRODUCT_CONFIGURATIONS_EVENT.UPDATE,
        PRODUCT_LIST_EVENT.REFRESH,
      ],
      isCacheable: true,
      itemClassRef: LicenseGroup,
      modelCacheId: LICENSE_GROUP_LIST_CACHE_ID,
      resource: jilOrganizationsProductsLicenseGroups.getLicenseGroups,
      ...options,
    });

    Object.assign(this, {
      orgId: options.orgId,
      product: options.product,
    });
  }

  /**
   * @description overridden key method to be used when we fetch models from modelCache.
   *
   * @returns {String} key formed from local params and JilModelList params.
   */
  getKey() {
    return super.getKey(getParams(this));
  }

  getProduct() {
    return this.product;
  }

  /**
   * @description Function to return the total quota allocated to the list of
   *    license groups by summing the totalQuantity.
   *
   * @returns {Number} sum of the distributed quotas
   */
  getTotalAllocatedQuota() {
    return sumBy(this.items, (licenseGroup) => toInteger(licenseGroup.totalQuantity));
  }

  /**
   * @description Method to load the license group list
   * @returns {Promise<LicenseGroupList>} promise resolved with refreshed license groups,
   *                    otherwise rejects with an error
   */
  async refresh() {
    try {
      await super.refresh(
        {
          orgId: this.orgId,
          productId: this.product.id,
        },
        {
          onSuccessPostTransform: () => {
            this.items.forEach((licenseGroup) => {
              Object.assign(licenseGroup, {
                orgId: this.orgId,
                product: this.product,
              });
            });
          },
        }
      );
    } catch (error) {
      log.error('LicenseGroupList.refresh() failed. Error:', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to save changes to LicenseGroupList to the back-end.
   * @param {Object} [options] - custom options for saving
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to true for legacy list.
   *
   * @returns {Promise<LicenseGroupList>} resolves to updated state if changes successfully saved, else rejects with error message
   */
  async save({refresh = true} = {}) {
    try {
      if (this.addedItems.length > 0 || this.removedItems.length > 0) {
        await saveAddedItems(this);
        this.resetAddedItems();

        await removeItems(this);
        this.resetRemovedItems();

        modelCache.clear(LICENSE_GROUP_LIST_CACHE_ID);

        if (refresh) {
          this.filter.lastQuery = this.filter.query;
          this.filter.query = '';
          return this.refresh();
        }

        return this;
      }
      return this;
    } catch (error) {
      log.error('LicenseGroupList.save() failed. Error:', error);

      // we clear the cache in case not all of the added/removed items were saved to the API correctly.
      modelCache.clear(LICENSE_GROUP_LIST_CACHE_ID);

      return Promise.reject(error);
    }
  }

  /**
   * @description Method to modify the license quota for product configs. Like src1,
   * it will split PATCH calls into 5 license group ops per request until API performance is improved.
   *
   * @param {Array<LicenseGroup>} licenseGroups - array of license groups to modify license quota
   * @returns {Promise} promise object resolved when update is complete, or rejected with an error
   */
  async updateQuota(licenseGroups) {
    const operations = licenseGroups.map((licenseGroup) => ({
      op: 'replace',
      path: `/${licenseGroup.id}/TOTALQUANTITY/${licenseGroup.totalQuantity}`,
    }));

    try {
      // Until API performance is improved, split up the PATCH operations into 5 license group per request.
      // This is emulating the previous behavior from src1, except we do not wait on each request before sending the next.
      const CHUNK_SIZE = 5;
      const operationChunks = chunk(operations, CHUNK_SIZE);

      const promises = operationChunks.map((operationChunk) =>
        jilOrganizationsProductsLicenseGroups.patchLicenseGroups(
          {
            orgId: this.orgId,
            productId: this.product.id,
          },
          operationChunk
        )
      );
      await Promise.all(promises);

      await this.refresh();

      return this;
    } catch (error) {
      log.error('LicenseGroupList failed to update quotas. Error: ', error);
      return Promise.reject(error);
    }
  }
}

/** Private methods */

async function saveAddedItems(licenseGroupList) {
  await Promise.all(licenseGroupList.addedItems.map((item) => item.save()));
}

async function removeItems(licenseGroupList) {
  const operations = convertRemovedItemsToPatchOperation(licenseGroupList.removedItems);

  const response = await jilOrganizationsProductsLicenseGroups.patchLicenseGroups(
    {
      orgId: licenseGroupList.orgId,
      productId: licenseGroupList.product.id,
    },
    operations
  );
  return response;

  function convertRemovedItemsToPatchOperation(items) {
    return items.map((licenseGroup) => ({
      op: 'remove',
      path: `/${licenseGroup.id}`,
    }));
  }
}

///////////

/**
 * @description Fetch params used for this call.
 *
 * @param {Object} model the model to pick params from.
 * @returns {Array} params to be passed to JIL or used as a key.
 */
function getParams(model) {
  return omitBy({orgId: model.orgId, productId: model.product.id}, (value) => value === undefined);
}

export default LicenseGroupList;
