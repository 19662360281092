/**
 * @description Returns the first found direct contract.
 *  Note, an org should only have one direct contract but it may also have a trial contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} direct contract, or undefined
 */
function getDirectContract(contractList) {
  return contractList.items.find((item) => item.isDirectContract());
}

/**
 * @description Returns true if this is a direct org and all contracts are cancelled.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if the direct contract has been cancelled.
 */
function hasCancelledDirectContract(contractList) {
  return contractList.items.every((item) => item.isDirectContract() && item.isCancelled());
}

/**
 * @description Returns true if there is an direct contract.
 *   Note, an org should only have one direct contract but it could also have a trial contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there is an direct contract.
 */
function hasDirectContract(contractList) {
  return contractList.items.some((item) => item.isDirectContract());
}

export {getDirectContract, hasCancelledDirectContract, hasDirectContract};
