(function () {
  /**
   * @ngdoc module
   * @name binky.core.product.consumable
   * @description Module which contains consumable related services
   */
  angular.module('binky.core.product.consumable', [
    'binky.core.api.jil',
    'binky.core.cart',
    'binky.core.common.list',
    'binky.core.common.model.cache',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.core.product',
    'binky.core.product.configurations',
  ]);
})();
