import {ActionButton, Flex, View, Well} from '@adobe/react-spectrum';
import CheckmarkIcon from '@spectrum-icons/workflow/Checkmark';
import ChevronUpDownIcon from '@spectrum-icons/workflow/ChevronUpDown';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import styles from './CollapsedWorkspaceNavItems.pcss';
import NavIconAndLabel from './NavIconAndLabel';
import {useWorkspaceNavContext} from './WorkspaceNavContext';

/**
 * Collapsed workspace nav component for when the window width becomes too
 * narrow or the zoom level is greater than or equal to 400%.
 */
const CollapsedWorkspaceNavItems = () => {
  const intl = useIntl();
  const {navAriaLabel, navItems, navigateUsingItem, selectedNavItemKey} = useWorkspaceNavContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const getNavItemAsListItem = (item) => (
    <li key={item.key}>
      <a
        aria-current={item.key === selectedNavItemKey ? 'page' : false}
        data-testid={item.key}
        href={item.href}
        onClick={(e) => navigateUsingItem(e, item)}
      >
        <Flex alignItems="center">
          <NavIconAndLabel workspaceNavItem={item} />
          {item.key === selectedNavItemKey && <CheckmarkIcon size="S" />}
        </Flex>
      </a>
    </li>
  );

  return (
    <>
      <ActionButton
        aria-controls="workspace-nav-well"
        aria-expanded={isExpanded}
        isQuiet
        onPress={() => setIsExpanded(!isExpanded)}
      >
        {intl.formatMessage({id: 'binky.shell.workspace.nav.collapsedButtonLabel'})}
        <ChevronUpDownIcon />
      </ActionButton>
      {isExpanded && (
        <Well id="workspace-nav-well">
          <nav aria-label={navAriaLabel}>
            <ul className={styles['well-workspace-nav']}>
              {navItems.map((item) => {
                if (item.isGroup()) {
                  return (
                    <li key={item.name}>
                      <View>{item.name}</View>
                      <ul>
                        {item.items.map((childNavItem) => getNavItemAsListItem(childNavItem))}
                      </ul>
                    </li>
                  );
                }
                return getNavItemAsListItem(item);
              })}
            </ul>
          </nav>
        </Well>
      )}
    </>
  );
};

export default CollapsedWorkspaceNavItems;
