(function () {
  /**
   * @deprecated Please use src2/core/models/contract/ContractTerms.js
   *
   * @ngdoc factory
   * @name binky.core.contract:ContractTerms
   * @description Model for an individual ContractTerms object
   */
  angular.module('binky.core.contract').factory('ContractTerms', getContractTermsModel);

  /* @ngInject */
  function getContractTermsModel($q, _, binkySrc2, promiseUtils) {
    class ContractTerms2 extends binkySrc2.models.contract.ContractTerms {
      constructor(options) {
        super(options);
        this.$resolved = true;
        this.$promise = $q.resolve(this);
      }

      /**
       * @description Accept the current terms and conditions.
       *
       * @param {Contract} [contract] the contract to accept terms for.
       *
       * @returns {Promise} resolved when acceptance completes.
       */
      acceptTerms(contract) {
        const promise = super.acceptTerms.call(this, contract);
        return promiseUtils.toAngularPromise(promise).then((response) => response);
      }

      /**
       * @description Fetch the current acceptance state of the terms and conditions.
       *
       * @returns {Promise} resolved when acceptance state is available
       */
      refresh() {
        const promise = super.refresh.call(this);
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((contractTerms) => {
            _.assign(this, contractTerms);
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Method to fetch the ContractTerms for the active org.
       *
       * @param {Object} options - configures the ContractTerms model instance.
       * @param {String} [options.orgId] - associates ContractTerms instance with an org.
       * @param {String} [options.serviceName] the contract terms service name to fetch
       * @returns {ContractTerms} the found ContractTerms state
       */
      static get(options) {
        const model = new ContractTerms2(options);
        model.refresh();
        return model;
      }
    }

    /** Return reference to Constructor function **/
    return ContractTerms2;
  }
})();
