import binky from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getProductDisplayName} from 'features/product/productDisplayUtils';

import AssignmentMenu from '../assignment-menu/AssignmentMenu';
import ASSIGNMENT_MENU_CONSTANTS from '../assignment-menu/AssignmentMenuConstants';
import {AssignmentMenuContext} from '../assignment-menu/AssignmentMenuContext';
import useAssignmentMenu from '../assignment-menu/useAssignmentMenu';
import CollapsibleList from '../collapsible-list/CollapsibleList';
import ImageIcon from '../image-icon/ImageIcon';

import Pill from './Pill';

const ProductProfilePill = ({
  closeButtonAriaLabel,
  closeButtonTooltipText,
  children,
  header,
  isDisabled = false,
  onChange,
  onDismiss,
  productProfiles,
  subtitle,
}) => {
  const intl = useIntl();
  const menuContext = useAssignmentMenu({
    selectableItems: {
      [ASSIGNMENT_MENU_CONSTANTS.TARGET_TYPE.PRODUCT_PROFILES]: [],
    },
  });

  const product = productProfiles?.[0]?.product;
  const shouldShowProfiles = product && !product.isTeam();

  return (
    <AssignmentMenuContext.Provider value={menuContext}>
      <Pill
        closeButtonAriaLabel={closeButtonAriaLabel}
        closeButtonTooltipText={closeButtonTooltipText}
        header={productProfiles ? getProductDisplayName(intl, product) : header}
        IconComponent={
          productProfiles ? (
            <ImageIcon
              alt={getProductDisplayName(intl, product)}
              size="L"
              src={product.getIcon()}
            />
          ) : null
        }
        iconVariant="square"
        isDisabled={isDisabled}
        onDismiss={onDismiss}
        secondaryButton={
          onChange && (
            <AssignmentMenu
              disabled={isDisabled}
              isQuiet
              onSelection={onChange}
              targets={[ASSIGNMENT_MENU_CONSTANTS.TARGET_TYPE.PRODUCT_PROFILES]}
            />
          )
        }
        subtitle={shouldShowProfiles ? null : subtitle}
        tags={product?.tagList}
      >
        {shouldShowProfiles && (
          <CollapsibleList
            header={intl.formatMessage({id: 'binky.common.productProfilePill.listHeader'})}
            itemName={intl.formatMessage({id: 'binky.common.productProfilePill.listItemName'})}
            items={productProfiles.map((productProfile) => productProfile.name)}
          />
        )}
        {children}
      </Pill>
    </AssignmentMenuContext.Provider>
  );
};

ProductProfilePill.propTypes = {
  children: PropTypes.node,
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTooltipText: PropTypes.string,
  header: PropTypes.string,
  isDisabled: PropTypes.bool,
  /* callback when items are added/removed from the AssignmentMenu */
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  productProfiles: PropTypes.arrayOf(
    PropTypes.instanceOf(binky.services.product.licenseGroup.LicenseGroup)
  ),
  subtitle: PropTypes.string,
};

export default ProductProfilePill;
