import binky from '@admin-tribe/binky';
import {Item, Picker} from '@adobe/react-spectrum';
import {AvailableTypeList as PandoraAvailableTypeList} from '@pandora/data-model-available-type';
import camelCase from 'lodash/camelCase';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import IdTypePickerTooltip from './tooltip/IdTypePickerTooltip';

import './IdTypePicker.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-id-type-picker
 */

const {shouldShowRecommendedSuffix} = binky.models.availableType.AvailableTypeUtils;

/**
 * IdTypePicker - a widget for selecting a user type
 */
const IdTypePicker = observer(({availableTypeList, defaultUserType, id, onChange}) => {
  const intl = useIntl();

  const [value, setValue] = React.useState(defaultUserType);

  React.useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger callback when value changes
  }, [value]);

  const getUniqueSelectOptions = () => {
    const sortedAvailableTypes = availableTypeList.availableOnlyTypesInPriorityOrder;
    return sortedAvailableTypes
      .filter(
        // remove duplicate types
        (availableType, idx) =>
          idx === 0 || sortedAvailableTypes[idx - 1].userType !== availableType.userType
      )
      .map((availableType) => {
        const userType = availableType.userType;

        const suffix = shouldShowRecommendedSuffix(availableTypeList, userType)
          ? '.recommended'
          : '';

        return {
          label: intl.formatMessage({
            id: `binky.common.idTypePicker.selectOption.${camelCase(
              availableType.userType
            )}${suffix}`,
          }),
          value: availableType.userType,
        };
      });
  };

  const disallowedTypes = availableTypeList.availableAndDisallowedTypes.map(
    (availableType) => availableType.userType
  );

  const selectOptions = getUniqueSelectOptions();
  const isDisabled = selectOptions.length === 1;

  return (
    <>
      <Picker
        aria-label={intl.formatMessage({id: 'binky.common.idTypePicker.fieldLabel.idType'})}
        data-testid="id-type-picker"
        disabledKeys={disallowedTypes}
        id={id}
        isDisabled={isDisabled}
        isRequired
        items={selectOptions}
        onSelectionChange={(selectedItem) => {
          setValue(selectedItem);
        }}
        selectedKey={value}
        width="275px"
      >
        {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- khnguye@ to update
          /* istanbul ignore next -- covered by Picker tests */ (item) => (
            <Item key={item.value}>{item.label}</Item>
          )
        }
      </Picker>
      {!isDisabled && <IdTypePickerTooltip availableTypeList={availableTypeList} />}
    </>
  );
});
IdTypePicker.propTypes = {
  /**
   * Translated text of the list to display in the picker.
   */
  availableTypeList: PropTypes.oneOfType([
    PropTypes.instanceOf(binky.services.availableType.AvailableTypeList),
    PropTypes.instanceOf(PandoraAvailableTypeList),
  ]).isRequired,
  /**
   * The default user type to be selected in the picker.
   */
  defaultUserType: PropTypes.string,
  /**
   * The id of the widget.
   */
  id: PropTypes.string.isRequired,
  /**
   * Handler that is called when the value is changed.
   */
  onChange: PropTypes.func.isRequired,
};
export default IdTypePicker;
