/**
 * @description Helper function to extract param values from url
 *
 * @param {String} fullUrl - Full url string
 * @param {String} paramName - Name of param to be exracted from full url string
 * @returns {String} Value of param given from the full url, if param not found, return empty string
 */
function getValueForQueryParam(fullUrl, paramName) {
  if (!fullUrl.includes(`${paramName}=`)) {
    return '';
  }
  const splitIndex = fullUrl.indexOf(`${paramName}=`) + paramName.length + 1;
  const endIndex = fullUrl.indexOf('&', splitIndex);
  return endIndex > -1
    ? fullUrl.slice(splitIndex, endIndex)
    : fullUrl.slice(Math.max(0, splitIndex));
}

/**
 * @description Helper function to determine if url has given domain
 *
 * @param {String} fullUrl - Full url string
 * @param {String} domain - domain that hostname should end with ie: adobe.com
 * @returns {Boolean} True if given url has given domain
 */
function hasDomain(fullUrl, domain) {
  const urlHostname = new URL(fullUrl).hostname;
  return (
    urlHostname === domain ||
    `${urlHostname.slice(Math.max(0, urlHostname.length - domain.length - 1))}` === `.${domain}`
  );
}

/**
 * @description Creates URLSearchParams for a given object
 *
 * @param {Object} object - the object to convert
 * @returns {URLSearchParams} The URLSearchParams, with a param for each key / value
 */
function getUrlSearchParams(object) {
  const result = new URLSearchParams();
  Object.keys(object).forEach((key) => {
    if (object[key] !== undefined) {
      result.append(key, object[key]);
    }
  });
  return result;
}

export {getValueForQueryParam, getUrlSearchParams, hasDomain};
