const ASSIGNMENT_MENU_CONSTANTS = {
  DEFAULT_MSG_HEIGHT: '160px',
  DEFAULT_MSG_WIDTH: '300px',
  DISPLAYED_RESULT_LIMIT: 20,
  IDS: {
    MENU_PREFIX: 'assignment-menu-menu-',
    MENUITEM_PREFIX: 'assignment-menu-menuitem-',
  },
  // Intentionally camel cased as they are used for localization keys
  MENU_TABS: {
    CONTRACTS_TAB: 'contractsTab',
    PRODUCTS_TAB: 'productsTab',
    USER_GROUPS_TAB: 'userGroupsTab',
  },
  TAB_GROUPS: {
    CONTRACTS: ['contracts'],
    PRODUCTS: ['productProfiles', 'products', 'productSupports'],
    USER_GROUPS: ['userGroups'],
  },
  TARGET_TYPE: {
    // Intentionally camel cased as they are used for localization keys
    CONTRACTS: 'contracts',
    PRODUCT_PROFILES: 'productProfiles',
    PRODUCT_SUPPORTS: 'productSupports',
    PRODUCTS: 'products',
    USER_GROUPS: 'userGroups',
  },
};

export default ASSIGNMENT_MENU_CONSTANTS;
