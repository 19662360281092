import axios from 'axios';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL domains API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL domains api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Gets CSV of domains for an orgId
 *
 * @param {String} orgId - The org id
 * @param {Object} queryParams - Params used to filter the list of domains
 * @param {number} queryParams.page - Page to get
 * @param {number} queryParams.page_size - Number of items to get
 * @param {String} queryParams.search_query - A query to search by
 * @param {String} queryParams.sort - Property on which to sort
 * @param {String} queryParams.sort_order - Ascending or descending
 * @param {String} queryParams.status - Status of domain
 *
 * @returns {Promise<Object>} - Object containing blob representation of csv
 */
const exportDomainsToCsv = async ({orgId, ...queryParams}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/domains`, {
    headers: {
      Accept: 'text/csv+domain',
      ...getHeaders({clientId, includeRoles}),
    },
    params: queryParams,
  });

  return csvBlobTransformer(response);
};

/**
 * @description Fetches paginated list of domains
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id
 * @param {Object} params - Pagination params: page, page_size, search_query, sort, sort_order
 * @param {Domain} domain - The domain to create
 * @returns {Promise<AxiosResponse>} - the Axios response
 */
const getDomains = async ({orgId, ...params}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/domains/`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

/**
 * @description Patches a list of domains
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id
 * @param {Array<Object>} patchOperations - The patch operations
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const patchDomains = async ({orgId}, patchOperations) => {
  const response = await axios.patch(`${url}/v2/organizations/${orgId}/domains/`, patchOperations, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Claims a list of domains for the org
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id
 * @param {Domain} domain - The domain to create
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const postDomains = async ({orgId}, domains) => {
  const response = await axios.post(`${url}/v2/organizations/${orgId}/domains/`, domains, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Verifies that a list of domains can be claimed
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id
 * @param {Domain} domain - The domain to create
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const postDomainsPreview = async ({orgId}, domains) => {
  const response = await axios.post(`${url}/v2/organizations/${orgId}/domains:preview/`, domains, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

const jilDomains = {
  configure,
  exportDomainsToCsv,
  getDomains,
  patchDomains,
  postDomains,
  postDomainsPreview,
};

export default jilDomains;
