import {DATA_POINT_TYPES, getActiveAndInactiveDataPoints} from '@admin-tribe/binky';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {getProductDisplayName} from '../../../../features';
import OverlayWait from '../../wait/OverlayWait';

import ChartLegends from './ChartLegends';
import ChartErrorScreen from './chart-error-screen/ChartErrorScreen';
import DurationPicker from './duration-picker/DurationPicker';
import {
  getDateStringFromEpoch,
  getDefaultSelectedDurationId,
  getDurationInfoFromId,
} from './duration-picker/durationPickerUtils';
import UsageLineChart from './line-chart/UsageLineChart';
import UsageLineTable from './line-table/UsageLineTable';
/**
 * A component that will display etla usage report chart and the associated duration picker
 */
const ChartAndSelect = ({
  chartDataPoints,
  disableDropDown = false,
  displayErrorMessageLines,
  isLoading = false,
  maxWidth,
  onSelectedChanged,
  selectedProduct,
  timePeriods,
}) => {
  const intl = useIntl();
  const currentTimeInEpochSecs = Date.now() / 1000;
  const [currentSelectedDuration, setCurrentSelectedDuration] = useState(
    getDurationInfoFromId(getDefaultSelectedDurationId(currentTimeInEpochSecs, intl, timePeriods))
  );

  const getAriaLabel = (duration, type) => {
    const xAxisLabel =
      currentSelectedDuration.selectedPeriodType === DATA_POINT_TYPES.ANNUAL
        ? intl.formatMessage({id: 'binky.common.etlaUsageReport.chart.labels.xAxisMonthLabel'})
        : intl.formatMessage({id: 'binky.common.etlaUsageReport.chart.labels.xAxisDayLabel'});
    const ariaLabelString =
      type === 'chart'
        ? 'binky.common.etlaUsageReport.chart.labels.ariaLabel'
        : 'binky.common.etlaUsageReport.chart.table.labels.ariaLabel';
    return intl.formatMessage(
      {id: ariaLabelString},
      {
        endDate: getDateStringFromEpoch(duration.endDate, intl),
        startDate: getDateStringFromEpoch(duration.startDate, intl),
        xAxisLabel,
        yAxisLabel: intl.formatMessage({
          id: 'binky.common.etlaUsageReport.chart.labels.yAxisLabel',
        }),
      }
    );
  };

  let chartView, tableView;
  if (displayErrorMessageLines?.length > 0 || chartDataPoints === undefined) {
    chartView = (
      <View marginY="10%">
        <ChartErrorScreen errorMessageArray={displayErrorMessageLines} />
      </View>
    );
    tableView = null;
  } else {
    const activeAndInactiveDataPoints = getActiveAndInactiveDataPoints(chartDataPoints);
    chartView = (
      <UsageLineChart
        aria-label={getAriaLabel(currentSelectedDuration, 'chart')}
        chartDataPoints={activeAndInactiveDataPoints[0]}
        contractInactiveProvisionedQty={activeAndInactiveDataPoints[1]}
        isInactiveDataPresent={activeAndInactiveDataPoints[2]}
        selectedPeriodType={currentSelectedDuration.selectedPeriodType}
        selectedProductCreationTS={selectedProduct?.bundleProduct?.createdTS}
      />
    );
    tableView = (
      <View position="relative" width="100%">
        <UsageLineTable
          aria-label={getAriaLabel(currentSelectedDuration, 'table')}
          chartDataPoints={activeAndInactiveDataPoints[0]}
          selectedPeriodType={currentSelectedDuration.selectedPeriodType}
        />
      </View>
    );
  }

  const onSelectedDurationChanged = (duration) => {
    onSelectedChanged(duration);
    setCurrentSelectedDuration(duration);
  };

  return (
    <View
      backgroundColor="color-gray-100"
      borderRadius="size-100"
      flex="0 0 65%"
      height="size-6000"
      maxHeight="size-6000"
      maxWidth={maxWidth}
      minWidth="0"
      padding="size-100"
      position="relative"
    >
      <OverlayWait isLoading={isLoading}>
        <Flex direction="column">
          <Heading
            data-testid="etla-usage-report-chart-and-select-heading"
            id="etla-usage-report-chart-and-select-heading"
            level={2}
            marginBottom="size-100"
            maxWidth="100%"
          >
            {getProductDisplayName(intl, selectedProduct.product)}
          </Heading>
          <Flex alignItems="center" direction="row" flexGrow={1} marginBottom="size-400">
            <DurationPicker
              aria-labelledby="etla-usage-report-chart-and-select-heading"
              currentTimeInEpochSecs={currentTimeInEpochSecs}
              id="etla-usage-report-duration-picker"
              isDisabled={disableDropDown}
              onSelectionChange={onSelectedDurationChanged}
              timePeriodsResponse={timePeriods}
            />
            <ChartLegends />
          </Flex>
          {chartView}
        </Flex>
        {tableView}
      </OverlayWait>
    </View>
  );
};

ChartAndSelect.propTypes = {
  /**
   * An array of objects representing chart data points to load graph for a selected product and a selected period type
   * Each such object has the following fields:
   *   - billingMonth(Number): billing month of the data point
   *   - delegatedQty(Number): license delegated Qty for a month
   *   - endDate(Number): end date of contract year in seconds since epoch
   *   - eventTimestamp(Number): event time stamp for when license is delegated
   *   - startDate(Number): start date of contract month in seconds since epoch
   */
  chartDataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      billingMonth: PropTypes.number,
      delegatedQty: PropTypes.number,
      endDate: PropTypes.number,
      eventTimestamp: PropTypes.number,
      startDate: PropTypes.number,
    })
  ),
  /**
   * Boolean value for indicating whether the duration picker would be disabled
   *     or not. The default value is false.
   */
  disableDropDown: PropTypes.bool,
  /**
   * If an error screen has to be shown, this prop is the localized string with different lines as different elements of
   * the array.
   */
  displayErrorMessageLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    })
  ),
  /**
   * Flag indicating whether this component should show the OverlayWait spinner. Defaults to false.
   */
  isLoading: PropTypes.bool,
  /**
   * The maximum width of the component as per the react-spectrum sizing standard.
   */
  maxWidth: PropTypes.string,
  /**
   * Callback method invoked when the current duration selection is changed by the user
   * An object with the following fields would be passed as argument:
   * - startDate(Number): start date of duration in seconds since epoch
   * - endDate(Number): end date of duration in seconds since epoch
   * - selectedPeriodType(String): one of DATA_POINT_TYPES
   */
  onSelectedChanged: PropTypes.func,
  /**
   * Object containing info regarding the currently selected product for which the
   *     chart has to be shown.
   */
  selectedProduct: PropTypes.shape({
    bundleProduct: PropTypes.shape({
      createdTS: PropTypes.number,
      deletedTS: PropTypes.number,
    }),
    product: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  /**
   * Array of objects each representing annual contract duration info
   * Each such object has the following fields:
   * - startDate(Number): start date of contract year in seconds since epoch
   * - endDate(Number): end date of contract year in seconds since epoch
   * - dataPoints(Object[]): array of objects representing info of monthly contract durations
   *   Each such monthly duration object contains the following fields:
   *   - startDate(Number): start date of contract month in seconds since epoch
   *   - endDate(Number): end date of contract month in seconds since epoch
   */
  timePeriods: PropTypes.arrayOf(
    PropTypes.shape({
      dataPoints: PropTypes.arrayOf(
        PropTypes.shape({
          endDate: PropTypes.number.isRequired,
          startDate: PropTypes.number.isRequired,
        })
      ).isRequired,
      endDate: PropTypes.number.isRequired,
      startDate: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ChartAndSelect;
