import {DialogContainer} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo, useRef} from 'react';

import PortalContainerInDocumentBody from '../portal-container/PortalContainerInDocumentBody';

const ModalContainerContext = React.createContext();

/**
 * @deprecated Please use administration Pandora ModalDialog
 */
// Spectrum does not allow to specify the destination of the portal-ed elements in V3.
// By default, the V3 dialog is created as the last element in the DOM.
// V2 overlays placed inside the V3 Dialog will be rendered behind the dialog.
// A modal container is dynamically created after the dialog is open so that the v2 overlays
// can be open in the correct position.
// This component will be removed when switching to v3 Overlay (V3Overlay is not available at this point)
// More details: https://wulff-pack.slack.com/archives/C7N5BE1EW/p1606773462015000?thread_ts=1606337397.487500&cid=C7N5BE1EW
const ModalContainer = ({children}) => {
  const onDismiss = useRef();
  const value = useMemo(() => ({onDismiss}), [onDismiss]);
  return (
    children && (
      <>
        <ModalContainerContext.Provider value={value}>
          <DialogContainer onDismiss={() => onDismiss.current?.()}>{children}</DialogContainer>
        </ModalContainerContext.Provider>
        <PortalContainerInDocumentBody />
      </>
    )
  );
};

ModalContainer.propTypes = {
  children: PropTypes.node,
};

export default ModalContainer;
export {ModalContainerContext};
