import binky from '@admin-tribe/binky';
import {Item, Picker} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {requiredLabelPropsCheck} from 'common/utils/prop-type/propTypeUtils';

const localeUtils = binky.services.localeUtils;

/**
 * Widget that presents selectable languages and will invoke a callback when
 * the language selection changes.
 */
const LanguagePicker = ({defaultSelectedKey, onSelectionChange, ...pickerProps}) => {
  const languageList = localeUtils.getSupportedLanguages({includeHidden: false});

  function onSelectionChangeHandler(key) {
    if (defaultSelectedKey !== key) {
      onSelectionChange(key);
    }
  }

  return (
    <Picker
      {...pickerProps}
      data-testid="language-picker"
      onSelectionChange={onSelectionChangeHandler}
      selectedKey={defaultSelectedKey}
    >
      {languageList.map((item) => (
        <Item key={item.value}>{item.label}</Item>
      ))}
    </Picker>
  );
};

LanguagePicker.propTypes = {
  /**
   * Aria-label prop to assign to the picker.
   */
  'aria-label': requiredLabelPropsCheck,
  /**
   * Aria-labelledby prop to assign to the picker.
   */
  'aria-labelledby': requiredLabelPropsCheck,
  /**
   * Language that should be selected.
   */
  defaultSelectedKey: PropTypes.string,
  /**
   * Label prop to assign to the picker.
   */
  label: requiredLabelPropsCheck,
  /**
   * Callback to invoke when the picker's selection is updated.
   */
  onSelectionChange: PropTypes.func.isRequired,
};

export default LanguagePicker;
