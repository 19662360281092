import jilUserGroups from 'api/jil/jilUserGroups';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import MemberList from 'services/member/MemberList';

import {USER_GROUP_USER_LIST_EVENT} from './UserGroupUserListConstants';

class UserGroupUserList extends MemberList {
  /**
   * @description Constructor for UserGroupUserList model Objects.
   *
   * @param {Object} options - See MemberList for additional options
   * @param {String} options.userGroupId - The user group id
   * @param any other MemberList or JilModelList option
   */
  constructor(options) {
    super({
      eventUpdateCount: USER_GROUP_USER_LIST_EVENT.UPDATE.COUNT,
      eventUpdateList: USER_GROUP_USER_LIST_EVENT.UPDATE.LIST,
      itemClassRef: OrganizationUser,
      modelCacheId: 'UserGroupUserList',
      refreshResource: jilUserGroups.getUserGroupUsersList,
      saveResource: jilUserGroups.patchUserGroupUsers,
      ...options,
    });
    this.userGroupId = options.userGroupId;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      userGroupId: this.userGroupId,
    };
  }

  /**
   * If this is needed, in the constructor, set saveResource to jilUserGroups.patchUserGroupUsers
   * and implement this.
   */
  save() {
    if (this.addedItems.length > 0) {
      throw new Error('UserGroupUserList.save is not implemented for addedItems');
    }

    return super.save(
      {
        userGroupId: this.userGroupId,
      },
      {
        refresh: false,
      }
    );
  }
}

export default UserGroupUserList;
