import pick from 'lodash/pick';

/**
 * @description This class describes a state change for analytics
 */
class AnalyticsPageDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {String} [options.name] Analytics state name
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['name']));
  }
}

export default AnalyticsPageDescriptor;
