(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.help-search:HelpSearchList
   * @description model that represents an HelpSearchList
   */
  angular.module('binky.core.help-search').factory('HelpSearchList', getHelpSearchList);

  /* @ngInject */
  function getHelpSearchList(
    $log,
    $q,
    _,
    HELP_SEARCH_ALLOWLIST_DOMAINS,
    HelpSearch,
    locale,
    search,
    SEARCH_SCOPE,
    urlUtils
  ) {
    const QUERY_PARAMS = [
      'enable_spelling_correction',
      'limit',
      'scope',
      'sort_order',
      'sort_orderby',
      'start_index',
    ];
    const DEFAULT_QUERY_PAMRAS = {
      facets_fields: ['applicable_products'],
      locale: locale.getActiveLocaleCode(),
      post_facet_filters: {
        applicable_products: ['Adobe Enterprise & Teams'],
      },
      scope: [SEARCH_SCOPE.HELPX],
      subscope: [],
    };

    class HelpSearchList {
      /**
       * @constructs HelpSearchList
       * @description Constructor for HelpSearchList model Objects.
       * @param {Object} [helpSearchList] - HelpSearchList instance settings
       * @param {Number} [helpSearchList.limit] - Maximum number of elements returned in the result.
       * @param {Number} [helpSearchList.start_index] - Offset (zero-based) into the whole set of results, typically used for pagination.
       * @param {Array} [helpSearchList.scope] - An array of SEARCH_SCOPE data sources to search. Default: SEARCH_SCOPE.HELPX
       * @param {String} [helpSearchList.sort_order] - Specifies the order in which the results should be sorted based on sort_orderby. Must be either asc (ascending) or desc (descending).
       * @param {String} [helpSearchList.sort_orderby] - Allowed values are: relevancy, creation_date, or modify_date
       * @param {Boolean} [helpSearchList.enable_spelling_correction] - If true, spelling correction is enabled (the default); otherwise spelling correction is disabled.
       */
      constructor(helpSearchList = {}) {
        setQueryParams(this, helpSearchList);
        this.clear();
      }

      clear() {
        this.items = [];
        this.$promise = $q.resolve();
        this.$resolved = true;
      }

      /**
       * Find all search topics for a query
       * @param {String} query - the input query
       * @returns {Promise} promise - promise object, resolved when search topics are loaded.
       */
      find(query) {
        updateQuery(this, query);

        this.$resolved = false;
        this.$promise = search.searchHelpTopics
          .save(this.queryParams)
          .$promise.then((response) => {
            if (hasErrors(response)) {
              return $q.reject();
            }

            this.items = transformResponseData(response);

            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }
    }

    return HelpSearchList;

    /* Private functions */
    function setQueryParams(model, options) {
      model.queryParams = _(options).pick(QUERY_PARAMS).defaults(DEFAULT_QUERY_PAMRAS).value();
    }

    function updateQuery(model, query) {
      _.set(model, 'queryParams.q', query);
    }

    function transformResponseData(response) {
      const resultSets = _.get(response, 'result_sets');

      if (_.isEmpty(resultSets)) {
        return [];
      }

      const uniqueSearchResults = getUniqueSearchResults(resultSets);
      return _(uniqueSearchResults)
        .map((responseItem) => HelpSearch.apiResponseTransformer(responseItem))
        .filter(filterAdobeDomains)
        .value();
    }

    /**
     * Get unique search results for all scopes.
     * @param {Object} responseData The response of the search api
     * @returns {Array} An array of unique search results
     */
    function getUniqueSearchResults(responseData) {
      return _(responseData)
        .flatMap((set) => _.get(set, 'items'))
        .compact()
        .uniqBy('asset_id')
        .value();
    }

    function filterAdobeDomains(responseItem) {
      if (urlUtils.hasDomain(responseItem.source, HELP_SEARCH_ALLOWLIST_DOMAINS.ADOBE)) {
        return true;
      }
      $log.error(`Removed non-Adobe URL from response: ${responseItem.source}.`);
      return false;
    }

    function hasErrors(response) {
      const responseStatuses = _.get(response, 'status');
      return (
        _.isUndefined(responseStatuses) || _.some(responseStatuses, (item) => item.status !== 200)
      );
    }
  }
})();
