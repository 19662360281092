(function () {
  /**
   * @deprecated Please use src2/core/models/licenseQuantityList/LicenseQuantityList.js
   *
   * @ngdoc factory
   * @name LicenseQuantityList
   * @description Model for the License Quantity List
   */
  angular
    .module('binky.core.product.license-quantity-list')
    .factory('LicenseQuantityList', getLicenseQuantityListModel);

  /* @ngInject */
  function getLicenseQuantityListModel(binkySrc2) {
    return binkySrc2.models.licenseQuantityList.LicenseQuantityList;
  }
})();
