import {Locale} from '@admin-tribe/binky';
import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

// A global portal that lives in the document body which can be used to add
// overlays like modals outside the normal React component tree.
// All portal-ed elements are direct children of the nearest ReactProvider.
// Since all react components are temporarily wrapped inside ReactContainerBridge,
// Spectrum fails to portal these overlays out to document.body.
// This temporary change allows Spectrum Dialog to be rendered directly
// inside the Document.Body and outside the ReactContainerBridge.
// This component will be removed when switching to v3 Dialog
// or when the angular-to-react migration is complete.
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
const PortalContainerInDocumentBody = () => {
  const locale = Locale.get().activeLocaleForSpectrum;

  return ReactDOM.createPortal(
    <SpectrumV2Provider locale={locale} scale="medium" theme="light">
      <SpectrumV3Provider colorScheme="light" locale={locale} theme={defaultTheme}>
        <div className={classNames('portal-container-in-document-body')} />
      </SpectrumV3Provider>
    </SpectrumV2Provider>,
    document.body
  );
};

export default PortalContainerInDocumentBody;
