import {Flex} from '@adobe/react-spectrum';
import ProviderV2 from '@react/react-spectrum/Provider';
import {SideNav, SideNavItem} from '@react/react-spectrum/SideNav';
import ChevronDownIcon from '@spectrum-icons/workflow/ChevronDown';
import ChevronRightIcon from '@spectrum-icons/workflow/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationAnchor from 'common/components/navigation-anchor/NavigationAnchor';

import NavIconAndLabel from './NavIconAndLabel';
import styles from './VerticalWorkspaceNavItems.pcss';
import {useWorkspaceNavContext} from './WorkspaceNavContext';

const GroupNameAndIcon = ({item, ...props}) => {
  const isExpanded = props['aria-expanded'] === true;
  const ChevronIcon = isExpanded ? ChevronDownIcon : ChevronRightIcon;

  return (
    <a {...props}>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        {item.name}
        <ChevronIcon size="S" />
      </Flex>
    </a>
  );
};

GroupNameAndIcon.propTypes = {
  /** Whether or note the group is expanded. Comes from Spectrum in this case */
  'aria-expanded': PropTypes.bool,
  /** The WorkspaceNavItem */
  item: PropTypes.shape({
    /** Name label that will be shown in the UI */
    name: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Workspace navigation for when the page is at normal zoom level and page width.
 */
const VerticalWorkspaceNavItems = () => {
  const {navAriaLabel, navItems, selectedNavItemKey} = useWorkspaceNavContext();

  const getCustomLink = (item, props) => (
    <NavigationAnchor
      {...props}
      aria-current={item.key === selectedNavItemKey ? 'page' : false}
      href={item.href}
    >
      <NavIconAndLabel workspaceNavItem={item} />
    </NavigationAnchor>
  );

  const groupIncludesSelectedNavItemKey = (groupNavItems) =>
    groupNavItems.filter((item) => item.key === selectedNavItemKey) > 0;

  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition, @admin-tribe/admin-tribe/one-component-file -- Disabling to make it easier to read over the JSX alternative
  const getVerticalNavItem = (workspaceNavItem) => {
    if (workspaceNavItem.isGroup()) {
      return (
        <SideNavItem
          key={workspaceNavItem.name}
          data-analysis-id={workspaceNavItem.analysisId}
          data-testid={workspaceNavItem.testId ? workspaceNavItem.testId : workspaceNavItem.name}
          renderLink={(props) => <GroupNameAndIcon item={workspaceNavItem} {...props} />}
        >
          {workspaceNavItem.items.map((childNavItem) => (
            <SideNavItem
              key={childNavItem.key}
              data-analysis-id={childNavItem.analysisId}
              data-testid={childNavItem.testId ? childNavItem.testId : childNavItem.key}
              defaultExpanded={groupIncludesSelectedNavItemKey(workspaceNavItem.items)}
              renderLink={(props) => getCustomLink(childNavItem, props)}
              value={childNavItem.key}
            />
          ))}
        </SideNavItem>
      );
    }
    return (
      <SideNavItem
        key={workspaceNavItem.key}
        data-analysis-id={workspaceNavItem.analysisId}
        data-testid={workspaceNavItem.testId ? workspaceNavItem.testId : workspaceNavItem.key}
        defaultExpanded={false}
        renderLink={(props) => getCustomLink(workspaceNavItem, props)}
        value={workspaceNavItem.key}
      />
    );
  };

  return (
    <ProviderV2 className={styles['vertical-side-nav']}>
      <SideNav aria-label={navAriaLabel} value={selectedNavItemKey} variant="multiLevel">
        {navItems.map((item) => getVerticalNavItem(item))}
      </SideNav>
    </ProviderV2>
  );
};

export default VerticalWorkspaceNavItems;
