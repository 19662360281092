import pick from 'lodash/pick';

class EtlaUsageReportProduct {
  /**
   * @constructs EtlaUsageReportProduct
   * @description Constructor for EtlaUsageReportProduct model objects.
   * @param {Object} product-Wrapper object for EtlaUsageReportProduct properties.
   */
  constructor(product) {
    if (product) {
      Object.assign(this, toMinimumObject(product));
    }
  }
}

/** Private Methods **/
function toMinimumObject(prodObj) {
  return pick(prodObj, ['createdTS', 'deleted', 'deletedTS', 'licenseId', 'qty']);
}

export default EtlaUsageReportProduct;
