(function () {
  'use strict';
  /**
   * @deprecated replaced by React Spectrum TableView - see TableStore story
   *
   * @ngdoc component
   * @name binky.widgets.common.table-checkbox:binkyTableCheckboxHeader
   * @description widget to manage a selectable table checkbox header.
   * @param {Object[]} itemList list of items of which to track multi-selection status.
   * @param {String} label the label to use for a screenreader. Optional.
   * @param {Selection} selection selection model instance which tracks selection status.
   */
  angular.module('binky.widgets.common.table-checkbox').component('binkyTableCheckboxHeader', {
    bindings: {
      itemList: '<',
      label: '<?',
      selection: '<',
    },
    controller,
    templateUrl: 'widgets/common/table-checkbox/table-checkbox-header.component.html',
  });

  /* @ngInject */
  function controller(_, SELECTION_STATE) {
    const vm = this;

    _.assign(vm, {
      isChecked,
      isDisabled,
      isIndeterminate,
      onClickCheck,
    });

    function isChecked() {
      return vm.selection.getSelectionStateForItems(vm.itemList) === SELECTION_STATE.ALL;
    }

    function isDisabled() {
      return _.isEmpty(vm.itemList);
    }

    function isIndeterminate() {
      return vm.selection.getSelectionStateForItems(vm.itemList) === SELECTION_STATE.SUBSET;
    }

    function onClickCheck() {
      if (vm.selection.getSelectionStateForItems(vm.itemList) === SELECTION_STATE.ALL) {
        vm.selection.deselectItems(vm.itemList);
      } else {
        vm.selection.selectItems(vm.itemList);
      }
    }
  }
})();
