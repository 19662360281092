import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import jilUserGroups from 'api/jil/jilUserGroups';
import Member from 'models/member/Member';
import MemberType from 'models/member/type/MemberType';
import eventBus from 'services/events/eventBus';
import log from 'services/log';

import {USER_GROUP_EVENT} from './UserGroupConstants';
import UserGroupShareInfo from './UserGroupShareInfo';

const DEFAULT_TYPE = 'USER_GROUP';

class UserGroup extends Member {
  static canTransform(data) {
    const memberType = new MemberType(data.type, data.id);
    return memberType.isUserGroup();
  }

  /**
   * @description Method to retrieve an existing UserGroup from back-end data
   *              store.
   *
   * @param {String} orgId - the org id
   * @param {number} userGroupId - ID of the user group to retrieve for this org
   * @returns {UserGroup} Reference to pre-existing UserGroup
   */
  static async get(orgId, userGroupId) {
    const model = new UserGroup({id: userGroupId, orgId});
    await model.refresh();
    return model;
  }

  /**
   * @description Creates a new UserGroup.
   *
   * @param {Object} options - options for the new user group
   * @param {String} [options.adminCount] - count of admins in the user group
   * @param {Boolean} [options.administerable] - whether user group is administerable or not, defaults to true
   * @param {String} [options.description] - description of the user group
   * @param {String} [options.id] - unique ID of this user group
   * @param {String} [options.name] - name of this user group
   * @param {String} options.orgId - the org id
   * @param {String} [options.type] - type of user group.
   * @param {number} [options.userCount] - count of users in user group
   * @param {Boolean} [options.externallyManaged] - True if the group was created from an external system
   * @param {Boolean | undefined} [options.isSource] - True if the group was the source of a share
   * @param {Boolean | undefined} [options.isTarget] - True if the group was the target of a share
   * @param {Object | undefined} [options.sharedUserGroupSource] - Sharing metadata for the source group
   */
  constructor(options = {}) {
    super(options);
    Object.assign(
      this,
      pick(options, [
        'adminCount',
        'administerable',
        'description',
        'id',
        'name',
        'orgId',
        'type',
        'userCount',
        'externallyManaged',
        'isSource',
        'isTarget',
      ]),
      {
        administerable: options.administerable === undefined ? true : !!options.administerable,
        sharedUserGroupSource: options.sharedUserGroupSource
          ? new UserGroupShareInfo(options.sharedUserGroupSource)
          : undefined,
        type: options.type || DEFAULT_TYPE,
      }
    );
    this.registerSavedState();
  }

  /**
   * @description Method to delete the current model.
   *
   * @return {Promise<UserGroup>} resolves on success, else rejects
   *                   with error message
   */
  async delete() {
    try {
      await jilUserGroups.deleteUserGroup({orgId: this.orgId, userGroupId: this.id});
    } catch (error) {
      log.error('UserGroup failed to delete. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  getDisplayName() {
    return this.name;
  }

  /**
   * @description determine whether editable fields have been modified.
   * @returns {Boolean} return true if local copy has been modified.
   */
  hasUnsavedChanges() {
    return !isEqual(this.toMinimumModel(), this.savedState);
  }

  /**
   * @description Method to determine if this UserGroup instance
   *              is isAdministerable by the currently signed-in user.
   *
   * @returns {boolean} true if UserGroup administerable, else false
   */
  isAdministerable() {
    return this.administerable;
  }

  /**
   * @description Method to determine if this userGroups is editable.
   *
   * @returns {boolean} true if the current user can administer the group
   *                    and the group is not coming from an external system
   *                    and the group is not a target of sharing, otherwise
   *                    false.
   */
  isEditable() {
    return !this.isTarget && this.isAdministerable() && !this.isExternallyManaged();
  }

  /**
   * @description Method to determine if this group has been created in an
   *              external system (e.g. Azure)
   *
   * @returns {Boolean|*} true if the group comes from an external system, false otherwise
   */
  isExternallyManaged() {
    return this.externallyManaged;
  }

  /**
   * Returns true if the groups is either a source or target of sharing.
   * @returns {boolean|Boolean}
   */
  isShared() {
    return !!this.isSource || !!this.isTarget;
  }

  /**
   * @description Method to refresh the current model state against what is
   *              stored in the back-end.
   *
   * @return {Promise<UserGroup>} resolves to refreshed UserGroup model, else rejects
   *                              with error message
   */
  async refresh() {
    try {
      const response = await jilUserGroups.getUserGroups({
        include: 'shared_user_groups,shared_user_groups_organization',
        orgId: this.orgId,
        userGroupId: this.id,
      });
      Object.assign(
        this,
        pick(response.data, [
          'adminCount',
          'administerable',
          'description',
          'id',
          'name',
          'type',
          'userCount',
          'externallyManaged',
          'isSource',
          'isTarget',
        ]),
        {
          administerable:
            response.data?.administerable === undefined ? true : !!response.data.administerable,
          sharedUserGroupSource: response.data?.sharedUserGroupSource
            ? new UserGroupShareInfo(response.data?.sharedUserGroupSource)
            : undefined,
          type: response.data.type || DEFAULT_TYPE,
        }
      );
    } catch (error) {
      log.error('UserGroup failed to load. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   */
  registerSavedState() {
    if (this.isNew()) {
      this.savedState = {};
    } else {
      this.savedState = this.toMinimumModel();
    }
  }

  /**
   * Method to save the current model state to the back-end.
   *
   * @return {Promise} resolves to saved UserGroup model, else rejects with
   *                   error message
   */
  async save() {
    let response;

    try {
      if (this.id) {
        response = await jilUserGroups.putUserGroup(
          {orgId: this.orgId, userGroupId: this.id},
          this.toMinimumModel()
        );

        Object.assign(this, response.data);
        eventBus.emit(USER_GROUP_EVENT.UPDATE, this);
      } else {
        response = await jilUserGroups.postUserGroup({orgId: this.orgId}, this.toMinimumModel());

        Object.assign(this, response.data);
        eventBus.emit(USER_GROUP_EVENT.CREATE, this);
      }
    } catch (error) {
      log.error('UserGroup failed to save. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description Method to construct a minimum data object from the model.
   *
   * @returns {Object} Reference to minimum data object
   */
  toMinimumModel() {
    return {
      description: this.description,
      id: this.id,
      name: this.name,
    };
  }
}

export default UserGroup;
