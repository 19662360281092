(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.shell.navigation.responsive-nav.factory:ResponsiveNavItem
   * @description Factory that creates a service Object that informs the application
   *   on how to create ResponsiveNavItem class Objects.
   */
  angular
    .module('binky.shell.navigation.responsive-nav')
    .factory('ResponsiveNavItem', getResponsiveNavItem);

  /* @ngInject */
  function getResponsiveNavItem(_, NAV_ITEM_ID_PREFIX, navDropdownManager) {
    /**
     * @class
     * @description This class wraps a NavItem or group of NavItems in a model
     *   that allows a common place to access and define data that is common to
     *   a responsive navigation item (as opposed to a more general-purpose NavItem).
     */
    class ResponsiveNavItem {
      /**
       * @constructs ResponsiveNavItem
       * @description Constructor for ResponsiveNavItem model Objects.
       * @param {Object} options - ResponsiveNavItem instance settings
       * @param {String} [options.groupIcon] - icon to use for group label (if represents nav group)
       * @param {String} [options.groupName] - name to use for group label (if represents nav group)
       * @param {String} options.menuId - unique ID for menu that item belongs to
       * @param {NavItem} [options.navItem] - reference to single NavItem (if single nav item)
       * @param {ResponsiveNavItem[]} [options.navItems] - reference to grouped NavItems (if represent nav group)
       * @param {Number} [options.order] - list order for this nav item
       * @param {ResponsiveNavItem} [options.parent] - reference to parent nav item (if exists)
       */
      constructor(options) {
        _.assign(
          this,
          _.pick(options, [
            'groupIcon',
            'groupName',
            'menuId',
            'navItem',
            'navItems',
            'order',
            'parent',
          ])
        );

        if (_.isNil(this.order)) {
          // no order provided, so compute order for this item based on contents
          this.order = this.isGroup() ? _(this.navItems).map('order').min() : this.navItem.order;
        }

        // assign unique id
        this.id = _.uniqueId(NAV_ITEM_ID_PREFIX);

        // initially hide any dropdown menus associated with this item
        this.isDropdownOpen = false;

        // initially assume menu item is not wrapped
        this.wrapped = false;

        if (this.isGroup()) {
          navDropdownManager.registerDropdown(this);
        }
      }

      /**
       * @description Method to determine if this responsive nav item represents
       *   a group of navigable items (e.g. - More menu dropdown) or not.
       * @return {Boolean} true if responsive nav item represents a group, else
       *   false
       */
      isGroup() {
        return _.has(this, 'groupIcon') || _.has(this, 'groupName');
      }

      /**
       * @description Method to determine if this responsive nav item should
       *   be hidden or not.
       * @return {Boolean} true if responsive nav item should be hidden, else
       *   false
       */
      isHidden() {
        if (this.isGroup()) {
          return _.every(this.navItems, (navItem) => navItem.isHidden());
        }
        return !!this.navItem.hidden;
      }

      /**
       * @description Method to reset the internal state of this responsive nav
       *   item, as it pertains to any parent elements are wrapping behavior. This
       *   method is used in the responsive-nav class to reset menu state when
       *   the menu is rendered on init, browser resize, or font size changes.
       * @return {undefined} no return value
       */
      resetState() {
        this.parent = undefined;
        this.wrapped = false;
      }
    }

    return ResponsiveNavItem;
  }
})();
