import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * The Drawer header.
 * This should be used, even if there are no children so that all drawers have the same
 * amount of whitespace below the visible header content.
 */
const DrawerHeader = ({children}) => (
  <View marginBottom="size-400" marginX="size-200">
    {children}
  </View>
);

DrawerHeader.propTypes = {
  children: PropTypes.node,
};

export default DrawerHeader;
