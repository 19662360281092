const FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED = 'UNLIMITED';
const FULFILLABLE_ITEM_CHARGING_MODEL_TYPE = {
  ON_DEMAND: 'ON_DEMAND',
  RECURRING: 'RECURRING',
};
const FULFILLABLE_ITEM_CHARGING_UNIT = {
  FRL_ISOLATED: 'frl_isolated',
  FRL_LAN: 'frl_lan',
  FRL_OFFLINE: 'frl_offline',
  FRL_ONLINE: 'frl_online',
  // case-sensitive
  LICENSE: 'LICENSE',
  TRANSACTIONS: 'transactions',
};
const FULFILLABLE_ITEM_CODE = {
  ACOM_ESIGN: 'acom_esign',
  ACP_BATCH_DATA_ACTIVATION: 'acp_batch_data_activation',
  ACP_DATA_SCIENCE_WORKSPACE_INTEL_QUOTA: 'acp_data_science_workspace_intel_quota',
  ACP_DATA_SCIENCE_WORKSPACE_NOPRD_PROFILE: 'acp_data_science_workspace_noprd_profile',
  ACP_DATA_SCIENCE_WORKSPACE_RICHNESS: 'acp_data_science_workspace_richness',
  ACP_DATA_SCIENCE_WORKSPACE_SANDBOXES: 'acp_data_science_workspace_sandboxes',
  ACP_DATA_SERVICES_FOUNDATION: 'acp_data_services_foundation',
  ACP_DSF_STREAMING_QUOTA: 'acp_dsf_streaming_quota',
  ACP_HFA: 'acp_hfa',
  ACP_NON_PROD_PROFILES: 'acp_non_prod_profiles',
  ACP_NON_PROD_PROFILES_RICHNESS: 'acp_non_prod_profiles_richness',
  ACP_NON_PROD_STREAMINGSEGMENTATION: 'acp_non_prod_streamingsegmentation',
  ACP_PROFILE_RICHNESS_PACKS: 'acp_profile_richness_packs',
  ACP_RT_CDP_NON_PROD_PROFILES: 'acp_rt_cdp_non_prod_profiles',
  ACP_RT_DATA_ACTIVATION: 'acp_rt_data_activation',
  ACP_SANDBOXES: 'acp_sandboxes',
  ACP_UPS_STRAMINGSEGMENTATION: 'acp_ups_streamingsegmentation',
  APPS_OFFLINE_PROFILES_COUNT: 'apps_offline_profiles_count',
  ASSET_SHARING_POLICY_CONFIG: 'asset_sharing_policy_config',
  BEHANCE: 'behance',
  CC_STORAGE: 'cc_storage',
  CCPUBLISH_STORAGE: 'ccpublish_storage',
  CONSUMPTION_CYCLE_DURATION: 'consumption_cycle_duration',
  CONSUMPTION_START_DATE: 'consumption_start_date',
  DC_STORAGE: 'dc_storage',
  DMA_ACP_CS: 'dma_acp_cs',
  DMA_ANALYTICS_CONTRIBUTION_ANALYSIS_CUST: 'dma_analytics_contribution_analysis_cust',
  DMA_ANALYTICS_DATA_RETENTION: 'dma_analytics_data_retention',
  DMA_ANALYTICS_EVARS_CUSTOM_EVARS: 'dma_analytics_evars_custom_evars',
  DMA_ANALYTICS_EVARS_CUSTOM_EVENTS: 'dma_analytics_evars_custom_events',
  DMA_ANALYTICS_EVARS_CUSTOM_PROPS: 'dma_analytics_evars_custom_props',
  DMA_ANALYTICS_REPORT_BUILDER_CUSTOM: 'dma_analytics_report_builder_custom',
  DOMAIN_CLAIMING: 'domain_claiming',
  DX_ADOBE_IO_RUNTIME: 'dx_adobe_io_runtime',
  DX_AEM_API_CALLS: 'dx_aem_api_calls',
  DX_AEM_ASSET_SHARE_USERS: 'dx_aem_asset_share_users',
  DX_AEM_BRAND_PORTAL_STORAGE: 'dx_aem_brand_portal_storage',
  DX_AEM_BRAND_PORTAL_USERS: 'dx_aem_brand_portal_users',
  DX_AEM_DYNAMIC_MEDIA: 'dx_aem_dynamic_media',
  DX_AEM_ENVIRONMENT: 'dx_aem_environment',
  DX_AEM_PAGE_VIEWS: 'dx_aem_page_views',
  DX_AEM_STORAGE: 'dx_aem_storage',
  DX_AEM_USERS_CONCURRENT: 'dx_aem_users_concurrent',
  DX_AEM_USERS_LIGHT: 'dx_aem_users_light',
  DX_AEM_USERS_POWER: 'dx_aem_users_power',
  DX_AEM_USERS_STANDARD: 'dx_aem_users_standard',
  DX_CAMPAIGN: 'dx_campaign',
  DX_CJA_API_CALLS: 'dx_cja_api_calls',
  DX_CJA_ATTRIBUTIONS: 'dx_cja_attributions',
  DX_CJA_AVERAGE_ROW_SIZE: 'dx_cja_average_row_size',
  DX_CJA_CALC_METRICS: 'dx_cja_calc_metrics',
  DX_CJA_CONCURRENT_REQUESTS: 'dx_cja_concurrent_requests',
  DX_CJA_DATA_AGING: 'dx_cja_data_aging',
  DX_CJA_DATA_RESTATEMENT: 'dx_cja_data_restatement',
  DX_CJA_DATA_TRANSFER: 'dx_cja_data_transfer',
  DX_CJA_PROFILE_SIZE: 'dx_cja_profile_size',
  DX_CJA_PROFILE_SIZE_QUANTITY: 'dx_cja_profile_size_quantity',
  DX_CJA_PROFILES: 'dx_cja_profiles',
  DX_CJA_PROFILES_RESTATEMENT: 'dx_cja_profiles_restatement',
  DX_CJA_STREAMING_MEDIA: 'dx_cja_streaming_media',
  DX_JOURNEYS_NON_PROD_PROFILES: 'dx_journeys_non_prod_profiles',
  ESM_SHARED_STORAGE: 'esm_shared_storage',
  ESM_USER_STORAGE: 'esm_user_storage',
  EXPORT_SESSION_CREATION_DISALLOWED: 'disallow_expert_sessions',
  FIREFLY_CREDITS: 'firefly_credits',
  LABORATORY_LICENSE_MANAGEMENT: 'laboratory_license_mgmt',
  MSFT_INTEGRATION_READINESS: 'msft_integration_readiness',
  OVERDELEGATION_ALLOWED: 'overdelegation_allowed',
  OZ_STORAGE: 'oz_storage',
  PACKAGE_PRECONDITIONING: 'package_preconditioning',
  PORTFOLIO: 'portfolio',
  PRIVATE_CC_STORAGE: 'private_cc_storage',
  SIGN_SERVICE_LEVEL: 'sign_service_level',
  SINGLE_DESKTOP_APPLICATION_CONFIGURATION: 'single_desktop_application_configuration',
  STOCK_CREDIT: 'stock_credit',
  STOCK_IMAGE: 'stock_image',
  STOCK_PREMIUM_CREDITS: 'stock_premium_credits',
  STOCK_STANDARD_CREDITS: 'stock_standard_credits',
  STOCK_UNIVERSAL_CREDITS: 'stock_universal_credits',
  STOCK_VIDEO: 'stock_video',
  SUPPORT_ALLOWED_ADMINS: 'support_allowed_admins',
  SUPPORT_CASE_CREATION_ALLOWED: 'support_case_creation_allowed',
  SUPPORT_EXPERT_SESSION: 'support_expert_sess',
  SUPPORT_PLAN_LEVEL: 'support_plan_level',
  SUPPORT_ROLE_ASSIGNMENT_ALLOWED: 'support_role_assignment_allowed',
  TOTAL_SIGN_TRANSACTIONS: 'total_sign_transactions',
  USER_GROUP_ASSIGNMENT: 'user_group_assignment',
  USER_PERMISSION_MANAGEMENT: 'user_permission_management',
  WORKSPACES: 'workspaces',
};
const FULFILLABLE_ITEM_DELEGATION_TYPE = {
  GROUP: 'GROUP',
  MACHINE: 'MACHINE',
  NA: 'NA',
  ORGANIZATION: 'ORGANIZATION',
  PERSON: 'PERSON',
};
const FULFILLABLE_ITEM_TYPE = {
  DESKTOP: 'DESKTOP',
  FEATURE: 'FEATURE',
  QUOTA: 'QUOTA',
  SERVICE: 'SERVICE',
  SUPPORT: 'SUPPORT',
};

export {
  FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
  FULFILLABLE_ITEM_CHARGING_MODEL_TYPE,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_DELEGATION_TYPE,
  FULFILLABLE_ITEM_TYPE,
};
