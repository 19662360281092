const LICENSE_GROUP_USER_LIST_CACHE_ID = 'LicenseGroupUserList';

const LICENSE_GROUP_USER_LIST_EVENT = {
  UPDATE: {
    COUNT: 'UpdateLicenseGroupUsersCount',
    LIST: 'UpdateLicenseGroupUsersList',
  },
};

const LICENSE_GROUP_USERS_API_QUERY_PARAMS = {
  PROVISIONING_STATUS: 'PROVISIONING_STATUS',
};

export {
  LICENSE_GROUP_USER_LIST_CACHE_ID,
  LICENSE_GROUP_USER_LIST_EVENT,
  LICENSE_GROUP_USERS_API_QUERY_PARAMS,
};
