import {log} from '@admin-tribe/binky';
import {useEffect, useState} from 'react';

/**
 * @deprecated use Pandora adminstration-core useAsyncModel:
 * https://git.corp.adobe.com/PandoraUI/administration-core/tree/master/packages/react-async-model
 *
 * @description A helper hook to load models and reduce boilerplate code. Pass in memoized
 * (useCallback) functions to prevent infinite rerenders.
 *
 * @example
 * // We need to use useCallback so that React doesn't create a new function on every render cycle.
 * const loadDomainList = useCallback(async () => DomainList.get({orgId}), [orgId]);
 * // Hook in the model state
 * const {
 *   model: domainList,
 *   isLoading: isLoadingDomainList,
 *   error: domainListError,
 * } = useAsyncModel({loadFn: loadDomainList});
 *
 * @param {Object} [initState] - The initial state of the model
 * @param {Function} loadFn - An async useCallback function that returns the model
 * @returns {Object} state - Object composed of state variables: model, error, isLoading.
 *          {Object} state.model - The fetched model.
 *          {Object} state.error - If loading model failed, then this contains the error that occurred.
 *          {Boolean} state.isLoading - Whether model is currently being loaded.
 */
const useAsyncModel = ({initState = undefined, loadFn}) => {
  const [model, setModel] = useState(initState);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the model and assign state.
  useEffect(() => {
    let isMounted = true;

    const load = async () => {
      setIsLoading(true);
      setError(undefined);
      try {
        const fetchedModel = await loadFn();
        if (isMounted) {
          setModel(fetchedModel);
        }
      } catch (apiError) {
        log.error('useAsyncModel: Failed to fetch model.', apiError);
        if (isMounted) {
          setError(apiError);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    load();

    return () => {
      isMounted = false;
    };
  }, [loadFn]);

  return {
    error,
    isLoading,
    model,
  };
};

export default useAsyncModel;
