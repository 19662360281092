(function () {
  /**
   * @deprecated should use Pandora Commerce components
   */
  angular.module('binky.core.commerce').constant('TAX_TERM', {
    GST: 'GST',
    IVA: 'IVA',
    KDV: 'KDV',
    SST: 'SST',
    TAX: 'TAX',
    VAT: 'VAT',
  });
})();
