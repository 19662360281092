import binky, {Contract} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import AssignmentMenu from 'common/components/assignment-menu/AssignmentMenu';
import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import {AssignmentMenuContext} from 'common/components/assignment-menu/AssignmentMenuContext';
import useAssignmentMenu from 'common/components/assignment-menu/useAssignmentMenu';

import styles from './AssignmentSection.pcss';
import {itemToTargetType} from './assignmentSectionUtils';
import SelectedPills from './selected-pills/SelectedPills';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-assignment-section
 */

const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const Product = binky.services.product.Product;
const UserGroup = binky.services.users.UserGroup;

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

// eslint-disable-next-line multiline-comment-style -- keep formatting for JSDoc
/**
 * Context for AssignmentSection
 * @param {Object} [itemsToDisable] disabled items for assignmentMenu
 * @param {Array<String>} itemsToDisable[TARGET_TYPE.PRODUCTS] disabled product ids
 * @param {Function(UserGroup)}} itemsToDisable[TARGET_TYPE.USER_GROUPS] function to determine whether user group is disabled
 * @param {Object} [itemsToPreselect] default selected items for assignmentMenu
 * @param {Array<String>} itemsToPreselect[TARGET_TYPE] array of ids for each target type
 * @param {Array<Contract>} [itemsToPreselect.CONTRACT_LIST] Array of Contract List objects
 * @param {Array<LicenseGroup>} [itemsToPreselect.PRODUCT_PROFILES] Array of LicenseGroup objects
 * @param {Array<UserGroup>} [itemsToPreselect.USER_GROUPS] Object of user groups to preselect
 * @param {String} [productIdForProfileSelection] the product id for profile selection
 * @param {Array<Object>} [products] array of products for assignmentMenu
 * @param {Array<Object>} [contractList] array of contracts for assignmentMenu
 *
 * @example
 * <AssignmentSectionContext.Provider
 *     itemsToDisable={{
 *        contractList:[{id: 'contractId1'}]
 *        products: ['productId3'],
 *        userGroups: (userGroup) => isDisabled(userGroup),
 *     }}
 *     itemsToPreselect={{
 *         contractList: [{id: 'contractId1'}],
 *         products: ['productId1', 'productId2'],
 *         productProfiles: [
 *             new LicenseGroup(),
 *         ],
 *         userGroups: [
 *             new UserGroup(),
 *         ]
 *     }}
 *     productIdForProfileSelection = 'product-id'
 *     products = []
 *     contractList = []
 * }>{children}</AssignmentSectionContext.Provider>
 *
 * @returns {Context}
 * */
const AssignmentSectionContext = React.createContext();

/**
 * @returns {Component} An AssignmentSection section component that will display the AssignmentMenu
 * and the SelectedPills. To be used with AssignmentMenuContext.
 *
 * When given only one TARGET_TYPE, it will display just those items.
 *    To display multiple menus, provide multiple target types.
 *    To display products without their license groups, do not provide the profile target type.
 *    To display products with their license groups, include both product and profile target types.
 *        Products must have field licenseGroupSummaries so profiles can be displayed.
 */
const AssignmentSection = ({
  isDisabled = false,
  member,
  onChange,
  onDeselect,
  onProductRolesChange,
  orgId,
  showButtonLabel,
  targets,
}) => {
  const {
    contractList,
    consumableSummarizationList,
    itemsToDisable,
    itemsToPreselect,
    productIdForProfileSelection,
    products,
    role,
    setItemsToPreselect,
  } = React.useContext(AssignmentSectionContext) || {};

  const isFirstRender = useRef(true);

  const menuContext = useAssignmentMenu({
    consumableSummarizationList,
    productIdForProfileSelection,
    role,
  });

  const {
    disableItems,
    disableProductProfiles,
    isSelected,
    selectedItems,
    selectableItems,
    selectItem,
    setSelectedItems,
    updateSelectableItemsOfClass,
    toggleItem,
  } = menuContext;

  const onSelection = (item, selectionStatus) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else
    if (!selectionStatus) {
      onDeselect?.(item);
      const targetType = itemToTargetType(item, targets);
      // once a default item is deselected, prevent it from being re-selected when the selectableItems change
      if (targetType && itemsToPreselect?.[targetType]) {
        setItemsToPreselect?.((prevItemsToPreselect) => ({
          ...prevItemsToPreselect,
          [targetType]: prevItemsToPreselect[targetType].filter((prevItem) => {
            const prevId = [TARGET_TYPE.PRODUCTS, TARGET_TYPE.PRODUCT_SUPPORTS].includes(targetType)
              ? prevItem
              : prevItem.id;
            return prevId !== item.id;
          }),
        }));
      }
    }
  };

  React.useEffect(() => {
    // Reset selected items when a new member is selected
    setSelectedItems([]);
  }, [member, setSelectedItems]);

  // clear selection when unmounted
  useEffect(
    () => () => {
      const targetTypes = targets.reduce((mappedTargets, target) => {
        mappedTargets[target] = [];
        return mappedTargets;
      }, {});

      setItemsToPreselect?.((prevItemsToPreselect) => ({
        ...prevItemsToPreselect,
        ...targetTypes,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on unmount
    []
  );

  // update assignmentMenu when context changes
  useEffect(() => {
    /**
     * Converts ProductList response into their classes
     * @param {Array<Object>} items ProductList.items response
     * @returns {Array<Product>} Input array converted to class instances
     */
    const processProducts = (items) =>
      items.map((product) => {
        if (product.licenseGroupSummaries) {
          product.licenseGroupSummaries = product.licenseGroupSummaries.map(
            (profile) => new LicenseGroup({...profile, product})
          );
        }
        return product;
      });
    if (!targets.includes(TARGET_TYPE.CONTRACTS) && products) {
      updateSelectableItemsOfClass(Product, processProducts(products));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update on products
  }, [products]);

  // update assignmentMenu when context changes
  useEffect(() => {
    if (targets.includes(TARGET_TYPE.CONTRACTS) && contractList?.length > 0) {
      updateSelectableItemsOfClass(Contract, contractList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update on contractList change
  }, [contractList]);

  // After assignmentMenu is updated with items, toggle each default item
  React.useEffect(() => {
    if (itemsToPreselect) {
      targets.forEach((target) => {
        if (itemsToPreselect[target]) {
          if (target === TARGET_TYPE.PRODUCT_PROFILES || target === TARGET_TYPE.USER_GROUPS) {
            itemsToPreselect[target].forEach((item) => {
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
              // istanbul ignore else
              if (!isSelected(item)) {
                selectItem(item);
              }
            });
          } else {
            itemsToPreselect[target].forEach((itemId) => {
              const foundItem = selectableItems.find((item) => item.id === itemId);
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- khnguye@ to update
              // istanbul ignore else
              if (foundItem) {
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- khnguye@ to update
                // istanbul ignore else
                if (!isSelected(foundItem)) {
                  selectItem(foundItem);
                }
              }
            });
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update on selectableItems change
  }, [selectableItems]);

  // After assignmentMenu is updated with items, disable the predefined items to disable
  React.useEffect(() => {
    if (itemsToDisable?.[TARGET_TYPE.PRODUCTS]) {
      disableItems(
        itemsToDisable[TARGET_TYPE.PRODUCTS].map((itemId) =>
          selectableItems.find((item) => item.id === itemId)
        )
      );
    }

    if (itemsToDisable?.[TARGET_TYPE.USER_GROUPS]) {
      const userGroupFilter = itemsToDisable[TARGET_TYPE.USER_GROUPS];
      const disabledItems = selectableItems.filter((item) => userGroupFilter(item));
      disableItems(disabledItems);
    }

    if (itemsToDisable?.[TARGET_TYPE.PRODUCT_PROFILES]) {
      disableProductProfiles(itemsToDisable[TARGET_TYPE.PRODUCT_PROFILES]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update on selectableItems change
  }, [itemsToDisable, selectableItems]);

  React.useEffect(() => {
    // prevent onChange running on mount
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else
    if (onChange) {
      onChange(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- update parents with selectedItems
  }, [selectedItems]);

  const isPillDisabled = (item) => {
    if (isDisabled) {
      return true;
    }

    // product profiles/user groups may not be loaded yet, but some pills may be present due to pre-selection
    // in this case, check the itemsToDisable directly
    if (itemsToDisable) {
      if (item instanceof UserGroup && itemsToDisable[TARGET_TYPE.USER_GROUPS]) {
        const userGroupFilter = itemsToDisable[TARGET_TYPE.USER_GROUPS];
        return userGroupFilter(item);
      }

      if (item instanceof Product && itemsToDisable[TARGET_TYPE.PRODUCTS]) {
        return !!itemsToDisable[TARGET_TYPE.PRODUCTS].find((productId) => item.id === productId);
      }
    }

    return false;
  };

  return (
    <div data-testid="assignment-section">
      <AssignmentMenuContext.Provider value={menuContext}>
        <AssignmentMenu
          disabled={isDisabled}
          onSelection={onSelection}
          orgId={orgId}
          showButtonLabel={showButtonLabel}
          targets={targets}
        />
      </AssignmentMenuContext.Provider>
      {selectedItems.length > 0 ? (
        <SelectedPills
          className={styles['selected-pills']}
          consumableSummarizationList={consumableSummarizationList}
          isDisabled={isPillDisabled}
          items={selectedItems}
          onDismiss={(item) => {
            onSelection(item);
            const isItemSelected = false; // Pill can only be dismissed
            toggleItem(item, isItemSelected);
          }}
          onProductRolesChange={onProductRolesChange}
          orgId={orgId}
          role={role}
        />
      ) : null}
    </div>
  );
};

AssignmentSection.propTypes = {
  isDisabled: PropTypes.bool,
  member: PropTypes.instanceOf(binky.models.member.Member),
  onChange: PropTypes.func,
  onDeselect: PropTypes.func,
  onProductRolesChange: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  showButtonLabel: PropTypes.bool,
  targets: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TARGET_TYPE))).isRequired,
};

export default AssignmentSection;
export {AssignmentSectionContext};
