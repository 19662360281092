import binky from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

const log = binky.services.log;
const ProductList = binky.services.product.ProductList;

const LICENSE_ACTIONS = {
  DESELECT: 'DESELECT',
  INITIALIZE: 'INITIALIZE',
  SELECT: 'SELECT',
};

// Context used to shared state in a form when handling multiple license assignments for products with
// limited licenses (i.e. !product.fulfillableItemList.hasOverdelegationAllowed()).
const LicenseLimitContext = React.createContext();

/**
 * @description helper hook to grab LicenseLimitContext if it exists
 *
 * @returns {Context} returns a React Context if it exists, otherwise an empty array
 */
const useLicenseLimitContext = () => {
  const context = React.useContext(LicenseLimitContext);
  if (!context) {
    // Return empty array so client code can use array destructuring
    return [];
  }
  return context;
};

/**
 * @description A helper hook to track license assignment limits in a form context
 *
 * @param {Object} params
 * @param {String} orgId - the organization id to fetch products for
 *
 * @returns {Array} an array with contents [state, dispatch]. state is an object mapping
 * product.id to their remaining license counts. dispatch is the dispatch function to make state changes
 */
const useLicenseLimit = ({orgId}) => {
  function reducer(state, action) {
    const {initState, product, type} = action;
    switch (type) {
      case LICENSE_ACTIONS.INITIALIZE: {
        return initState;
      }
      case LICENSE_ACTIONS.SELECT:
        return {
          ...state,
          [product.id]: state[product.id] - 1,
        };
      case LICENSE_ACTIONS.DESELECT:
        return {
          ...state,
          [product.id]: state[product.id] + 1,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = React.useReducer(reducer, {});

  React.useEffect(() => {
    let isMounted = true;
    async function fetchProductList() {
      try {
        const productList = await ProductList.get({
          orgId,
        });
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (isMounted) {
          const initlicenseLimits = {};
          productList.items.forEach((product) => {
            initlicenseLimits[product.id] = product.getAvailableLicenseCount();
          });
          dispatch({initState: initlicenseLimits, type: LICENSE_ACTIONS.INITIALIZE});
        }
      } catch (error) {
        log.error('Unable to fetch products. Error:', error);
      }
    }

    fetchProductList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run on mount only
  }, []);

  return [state, dispatch];
};

const LicenseLimitProvider = ({children, orgId}) => {
  const state = useLicenseLimit({orgId});
  return <LicenseLimitContext.Provider value={state}> {children} </LicenseLimitContext.Provider>;
};

LicenseLimitProvider.propTypes = {
  children: PropTypes.node.isRequired,
  orgId: PropTypes.string.isRequired,
};

export {
  LICENSE_ACTIONS,
  LicenseLimitContext,
  LicenseLimitProvider,
  useLicenseLimit,
  useLicenseLimitContext,
};
