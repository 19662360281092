// Table attributes for Spectrum v3
const DENSITY = {COMPACT: 'compact', SPACIOUS: 'spacious'};
const FIRST_PAGE = 1;
const OVERFLOW_MODE = {TRUNCATE: 'truncate', WRAP: 'wrap'};
const SELECTION_MODE = {MULTIPLE: 'multiple', NONE: 'none', SINGLE: 'single'};
const SORT_DIRECTION = {ASCENDING: 'ascending', DESCENDING: 'descending'};
const TABLE_SLOTS = {
  ACTIONS: 'TableActions',
  INDEX_PAGINATION: 'IndexPagination',
  TABLE: 'Table',
  TOKEN_PAGINATION: 'TokenPagination',
};

const TableConstants = {
  DENSITY,
  FIRST_PAGE,
  OVERFLOW_MODE,
  SELECTION_MODE,
  SORT_DIRECTION,
  TABLE_SLOTS,
};

export default TableConstants;
