(function () {
  /**
   * @ngdoc module
   * @name binky.core.common.list.state.sigma
   * @description module defining abstract Sigma List model
   */
  angular.module('binky.core.common.list.state.sigma', [
    'binky.core.api.jil',
    'binky.core.common.list.state.sigma',
    'binky.core.common.model.cache',
  ]);
})();
