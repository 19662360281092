(function () {
  /**
   * @deprecated Please use src2/core/services/organization/OrganizationUserList.js
   *
   * @ngdoc factory
   * @name OrganizationUserList2
   * @description Model for a list of organization users
   */
  // eslint-disable-next-line angular/factory-name
  angular
    .module('binky.core.organizations.user-list')
    .factory('OrganizationUserList2', getOrganizationUserListModel);

  /* @ngInject */
  function getOrganizationUserListModel(
    $rootScope,
    _,
    jilUsers,
    MEMBER_EVENT,
    MemberList,
    modelCache,
    ORGANIZATION_USER_LIST_2_CACHE_ID,
    ORGANIZATION_USER_LIST_2_EVENT,
    OrganizationUser2
  ) {
    class OrganizationUserList2 extends MemberList {
      /**
       * @description Constructor for OrganizationUserList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: ORGANIZATION_USER_LIST_2_CACHE_ID,
              memberClassRef: OrganizationUser2,
              resource: jilUsers.users,
            },
            options
          )
        );
        this.orgId = options.orgId;
        // search query pattern
        this.SEARCH_QUERY_PATTERN = new RegExp(/^[^*]*\*?$/); // no content allowed after an asterisk
      }

      key() {
        return super.key(getParams(this));
      }

      /**
       * @description
       * Method to refresh the contents of the organization user list
       *
       * @param {Object} options - options for refresh method of super class
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(options = {}) {
        return super.refresh(getParams(this), options).then((userList) => {
          $rootScope.$emit(ORGANIZATION_USER_LIST_2_EVENT.UPDATE);
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(
              ORGANIZATION_USER_LIST_2_EVENT.UPDATE_COUNT,
              this.pagination.itemCount
            );
          }
          return userList;
        });
      }

      /**
       * @description Method to save changes to user list to back-end.
       *
       * @param {Object} options - options to alter the behavior of save
       * @param {Object} options.removePathOverride - memberId and path pairs to override the
       *                 default remove behavior
       * @param {Object} options.removeProductAndRolePathOverrides - memberId and paths pairs to
       *                 override the default remove behavior
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(options = {}) {
        // First we check if users are being added, and fail if so
        // This list does not support add in that way
        if (_.size(this.addedItems) > 0) {
          throw new Error('OrganizationUserList2.save cannot be invoked with added people');
        }
        // We make a copy of the users being removed, to generate the deleted event
        const removedItems = _.clone(this.removedItems);
        const promise = super.save(_.omitBy({orgId: this.orgId}, _.isEmpty), options);
        // On save success we send out events specific to this list
        // eslint-disable-next-line promise/catch-or-return
        promise.then(() => {
          // Notify the app of each removed user
          _.forEach(removedItems, (removedUser) => {
            $rootScope.$emit(MEMBER_EVENT.DELETE, removedUser.id);
          });
        });
        return promise;
      }

      /**
       * @description Method to retrieve an existing list of organization users
       *              from the back-end.
       *
       * @param {Object} [options] options for JIL API call
       * @property {Number} [options.orgId] - The id of the organization
       * @property {Number} [options.pageSize] - number of users to display per page
       *
       * @returns {OrganizationUserList} new OrganizationUserList2 Object reference
       */
      static get(options) {
        let model = new OrganizationUserList2(options);
        const cachedModel = modelCache.get(ORGANIZATION_USER_LIST_2_CACHE_ID, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    MemberList.setupCache(ORGANIZATION_USER_LIST_2_CACHE_ID, 5);

    return OrganizationUserList2;

    /**
     * @description Fetch params used for this call.
     *
     * @param {Object} model the model to pick params from
     * @returns {Array} params to be passed to JIL or used as a key
     */
    function getParams(model) {
      return _.omitBy({orgId: model.orgId, productConfigurations: 'true'}, _.isEmpty);
    }
  }
})();
