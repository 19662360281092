/* eslint-disable react/no-unknown-property -- styleName not recognized */
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @cawright needs to fix
import Tooltip from '@react/react-spectrum/Tooltip';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import './TruncatedText.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-truncated-text
 */

/**
 * TruncatedText is a widget to control the overflown text and display the truncated text.
 */
const TruncatedText = ({children, 'data-testid': dataTestId, textClassName, tooltipClassName}) => {
  const textRef = useRef();
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
  // istanbul ignore next
  useEffect(() => {
    const handleResize = () => {
      if (textRef.current) {
        setIsTextTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    };
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- thean@ to update
    window.addEventListener('resize', handleResize);
    handleResize();
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- thean@ to update
    return () => window.removeEventListener('resize', handleResize);
  }, [children]);

  return (
    <OverlayTrigger disabled={!isTextTruncated} placement="top">
      <div
        ref={textRef}
        aria-label={children}
        className={textClassName}
        data-testid={dataTestId}
        styleName="label"
      >
        {children}
      </div>
      <Tooltip className={tooltipClassName}>{children}</Tooltip>
    </OverlayTrigger>
  );
};

TruncatedText.propTypes = {
  /**
   * The content to display in the TruncatedText.
   */
  children: PropTypes.string,
  'data-testid': PropTypes.string,
  /**
   * Customized class name to be applied on the TruncatedText.
   */
  textClassName: PropTypes.string,
  /**
   * Customized class name to be applied on the tooltip of the TruncatedText.
   */
  tooltipClassName: PropTypes.string,
};

export default TruncatedText;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
