(function () {
  /**
   * @ngdoc module
   * @name binky.core.product
   * @description Module which contains product related services
   */
  angular.module('binky.core.product', [
    'binky.core.api.jil',
    'binky.core.cart',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.common.member.type',
    'binky.core.common.model',
    'binky.core.common.model.cache',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.product.fulfillable-item-list',
    'binky.core.product.license-quantity-list',
    'binky.core.promise-utils',
  ]);
})();
