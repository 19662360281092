const USAGE_REPORT_CHART_DATA_SET = {
  YAXIS1: {
    backgroundColor: '#5151d3',
    borderColor: '#5151d3',
    borderWidth: 2,
    data: '',
    fill: false,
    label: '',
    lineTension: 0,
    pointRadius: 4,
    pointStyle: 'rect',
    spanGaps: false,
  },
  YAXIS2: {
    backgroundColor: '#26c0c7',
    borderColor: '#26c0c7',
    borderWidth: 2,
    data: '',
    fill: -1,
    label: '',
    lineTension: 0,
    pointBackgroundColor: '#fbfbfb',
    pointBorderColor: '#26c0c7',
    pointRadius: 4,
    spanGaps: false,
  },
  YAXIS3: {
    backgroundColor: '#f5f5f5',
    borderColor: '#f5f5f5',
    borderWidth: 2,
    data: '',
    fill: false,
    label: '',
    lineTension: 0,
    pointBackgroundColor: '#f5f5f5',
    pointBorderColor: '#f5f5f5',
    pointHoverBackgroundColor: '#e34850',
    pointRadius: 0.05,
    showLine: false,
    spanGaps: false,
  },
};
const USAGE_REPORT_CHART_DEFAULT_OPTIONS = {
  aspectRatio: 3.5,
  hover: {
    intersect: false,
    mode: 'index',
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: true,
  responsive: true,
  spanGaps: true,
  title: {
    display: false,
  },
};

export {USAGE_REPORT_CHART_DATA_SET, USAGE_REPORT_CHART_DEFAULT_OPTIONS};
