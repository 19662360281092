import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';

import modelCache from 'services/cache/modelCache/modelCache';
import feature from 'services/feature';
import MemberConfigurationRoles from 'services/product/license-group/member-configuration/MemberConfigurationRoles';
import LicenseGroupUserList from 'services/product/license-group/user-list/LicenseGroupUserList';
import {LICENSE_GROUP_USER_LIST_CACHE_ID} from 'services/product/license-group/user-list/LicenseGroupUserListConstants';

const getProductRoleId = (member, {licenseGroupId, productId}) =>
  member.productRoles.find(
    (role) => role.product.id === productId && role.licenseGroupId === licenseGroupId
  )?.id;

// Transform a list of members to Member Configuration Roles
//
// [
//    Member({
//       id: 'user-1-id'
//       productRoles: [{id: 'user-1-role-1', product: {id: 'product-1-id'}}, {id: 'user-1-role-2'}, product: {id: 'product-2-id'}]
//    }),
//    Member({
//       id: 'user-2-id'
//       productRoles: [{id: 'user-2-role', product: {id: 'product-1-id'}]
//    })
// ]
//
// => result for product id: 'product-1-id'
// => {
//       'user-1-id': ['user-1-role-1'],
//       'user-2-id': ['user-2-role'],
//    }
const toMemberConfigurationRoles = (members, {licenseGroupId, productId}) =>
  members.reduce(
    (result, member) => ({
      ...result,
      [member.id]: [getProductRoleId(member, {licenseGroupId, productId})],
    }),
    {}
  );

const registerProductRoles = (members, {licenseGroupId, productId}) => {
  members.forEach((member) => {
    Object.assign(member, {
      productRole: [getProductRoleId(member, {licenseGroupId, productId})],
    });
  });
};

/**
 * Save member configuration roles for the given members based on user input stored in the model.
 *
 * @param {Array<Member>} members - members
 * @param {Object} options - options
 * @param {Boolean} editProductRoles - true if the roles are being edited, false if they are being added.
 * @param {String} licenseGroupId - the product's license group id.
 * @param {String} orgId - the organization id.
 * @param {String} productId - the product id.
 */
const updateMemberConfigurationRoles = async (
  members,
  {editProductRoles, licenseGroupId, orgId, productId}
) => {
  const memberIds = editProductRoles ? members.map((member) => member.id) : [];
  const memberConfigurationRoles = new MemberConfigurationRoles({
    licenseGroupId,
    memberIds,
    orgId,
    productId,
  });
  // If memberIds are specified, on refresh, their current roles will be stashed into savedState.
  // On save, the patchOperation will contain the difference between savedState and the
  // memberRoles set on memberConfigurationRoles below.
  await memberConfigurationRoles.refresh();
  memberConfigurationRoles.memberRoles = toMemberConfigurationRoles(members, {
    licenseGroupId,
    productId,
  });
  await memberConfigurationRoles.save();

  // This cache-clear is to force the user-list to refresh immediately and
  // pull the newly-assigned product roles from the MemberConfigurations.
  // Mainly for the product-profile users page, which shows roles.
  // Setting the 'productRole' directly on the member is problematic because it's possible
  // saveMemberProductRoles was called with both DMA and MemberConfig Products,
  // and the DMA role code assumes 'productRole' is only for DMA.
  if (feature.isEnabled('bug_fix_36254')) {
    modelCache.clear(LICENSE_GROUP_USER_LIST_CACHE_ID);
  }
};

/**
 * Save member product roles for the given members based on user input stored in the model
 * @param {Array<Member>} members - members
 */
const updateMemberProductRoles = async (members, {licenseGroupId, orgId, productId}) => {
  const licenseGroupUserList = new LicenseGroupUserList({licenseGroupId, orgId, productId});
  registerProductRoles(members, {licenseGroupId, productId});
  await licenseGroupUserList.saveMemberProductRole(members);
};

const getUpdateProductRoleFunc = (product) =>
  product.hasConfigurationSettingForLicenseGroupMember()
    ? updateMemberConfigurationRoles
    : updateMemberProductRoles;

/**
 * Save members' product roles for the current org
 * @param {Array<Member>} members - a list of members
 * @param {Boolean} [editProductRoles] - true if the roles are being edited, false if they are being added.
 *   The default is false.
 * @param {String} orgId - the org id
 * @returns {Promise} Resolves when updates are successful
 */
const saveMemberProductRoles = (members, {editProductRoles = false, orgId}) => {
  const existingMembers = members.filter((member) => !member.isNew());
  // get flatten product roles for org users
  const membersProductRoles = flatMap(existingMembers, (member) => {
    const productRoles = flatMap(member.productRoles, (productRole) => ({
      ...productRole,
      member,
    }));

    return productRoles;
  });

  const productRolesGroupedByLicenseGroup = Object.values(
    groupBy(
      membersProductRoles,
      (productRole) => `${productRole.product.id}_${productRole.licenseGroupId}`
    )
  );

  return Promise.all(
    productRolesGroupedByLicenseGroup.map((productRolesForLicenseGroup) => {
      const product = productRolesForLicenseGroup[0].product;
      const licenseGroupId = productRolesForLicenseGroup[0].licenseGroupId;
      const membersWithProductRolesForLicenseGroupId = productRolesForLicenseGroup.map(
        (productRole) => productRole.member
      );

      return getUpdateProductRoleFunc(product)(membersWithProductRolesForLicenseGroupId, {
        editProductRoles,
        licenseGroupId,
        orgId,
        productId: product.id,
      });
    })
  );
};

// eslint-disable-next-line import/prefer-default-export -- helper file
export {saveMemberProductRoles};
