import {CUSTOMER_CONTROLLABLE} from './ProcessingInstructionsConstants';

class ProcessingInstructions {
  /**
   * @description Creates a new ProcessingInstructions for use.
   *
   * @param {Object} [options] Initialization Object
   */
  constructor(options = {}) {
    // Data depends on processingInstructionCodes param from GET /products
    // call which may vary for each given code, therefore we are not specifying
    // any properties here
    Object.assign(this, options);
  }

  /**
   * @description Method to determine the allow_overuse_control value.
   *
   * @returns {Boolean} True if the allow_overuse_control is 'customer_controllable', false otherwise.
   */
  isOveruseCustomerControllable() {
    return this.global_administration?.allow_overuse_control === CUSTOMER_CONTROLLABLE;
  }
}

export default ProcessingInstructions;
