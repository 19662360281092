/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {AnalyticsEvent} from '@admin-tribe/binky';
import {Link, VisuallyHidden} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getGoUrl} from 'common/utils/url/goUrlUtils';
import './GoUrl.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-go-url
 */

/**
 * Component for a go-url which opens another window. This is a thin layer on a Spectrum v3 Link.
 * See https://react-spectrum.adobe.com/react-spectrum/Link.html for valid linkProps.
 */
const GoUrl = (props) => {
  const intl = useIntl();
  const {children, name, noWrap = true, ...linkProps} = props;
  const hiddenTestid = `${name}-hidden`;

  const triggerAnalytics = () => {
    AnalyticsEvent.dispatch({
      attributes: {
        goUrlName: name,
      },
      componentMethod: 'goUrl',
      componentMethodType: 'submit',
      componentName: 'binkyGoUrl',
    });
  };

  return (
    <Link {...linkProps} onPress={triggerAnalytics}>
      <a
        data-testid={name}
        href={getGoUrl(name, intl.locale)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span styleName={noWrap && 'go-url'}>{children}</span>
        <VisuallyHidden data-testid={hiddenTestid} elementType="span">
          {intl.formatMessage({id: 'binky.common.goUrl.opensWindow'})}
        </VisuallyHidden>
      </a>
    </Link>
  );
};

GoUrl.propTypes = {
  /**
   * The content to display in the link.
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether the link should be displayed with a quiet style.
   */
  isQuiet: PropTypes.bool,
  /**
   * The name of the go-url.
   */
  name: PropTypes.string.isRequired,
  /**
   * Sets if the GoUrl should wrap on multiple lines, defaults to true.
   */
  noWrap: PropTypes.bool,
  /**
   * The visual style of the link.
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'overBackground']),
};

export default GoUrl;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
