import kmi from 'api/kmi/kmi';
import modelCache from 'services/cache/modelCache/modelCache';
import feature from 'services/feature';
import log from 'services/log';
import OrganizationEncryption from 'services/organizationEncryption/OrganizationEncryption';
import {ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID} from 'services/organizationEncryptionMigration/OrganizationEncryptionMigrationConstants';

// Remove file with temp_org_asset_encryption_migrated and replace all calls to isMigrated with true

class OrganizationEncryptionMigration {
  /**
   * @description Method to get a model instance of OrganizationEncryptionMigration
   * @param {Object} options as described below
   * @param {string} options.orgId - ID of organization to get the migration status for.
   * @returns {Promise<OrganizationEncryptionMigration>} Promise containing a potentially cached instance of OrganizationEncryptionMigration
   */
  static get(options) {
    const model = new OrganizationEncryptionMigration(options);

    return modelCache.get(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, model.key) ?? model.refresh();
  }

  /**
   * @description Method to create a new instance of a OrganizationEncryptionMigration.
   * @param {Object} options - configures the OrganizationEncryptionMigration model instance.
   * @param {String} [options.orgId] - associates OrganizationEncryptionMigration instance with an org.
   */
  constructor(options) {
    this.orgId = options.orgId;

    /**
     * @description Used to determine whether an org has been migrated to org level encryption.
     *              true if migrated to org level encryption, otherwise false
     */
    this.isMigrated = null;

    /**
     * @description An org that has not yet been migrated might still have encrypted directories.
     *              These are fetched from KMS and we cache them here to avoid making multiple requests for them.
     */
    this.encryptedDirectories = [];
  }

  get key() {
    return this.orgId;
  }

  async refresh() {
    if (feature.isEnabled('temp_org_asset_encryption_migrated')) {
      this.isMigrated = true;

      modelCache.put(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, this.key, Promise.resolve(this));
      return this;
    }

    try {
      const [orgEncryption, encryptedDirectories] = await Promise.all([
        OrganizationEncryption.get({orgId: this.orgId}),
        kmi.getEncryptedDirectories({orgId: this.orgId}),
      ]);

      // if it has an encryption status, it's migrated
      const hasOrgEncryption = ['REVOKED', 'ENABLED'].includes(orgEncryption.encryptionStatus);

      // if it doesn't have any encrypted directories, it's migrated
      const hasDirectoryEncryption = encryptedDirectories.data.length > 0;

      this.isMigrated = hasOrgEncryption || !hasDirectoryEncryption;
      this.encryptedDirectories = encryptedDirectories.data;
    } catch (error) {
      log.error('Error fetching org encryption migration status. Error: ', error);

      // src1 treats the org as not migrated if there is an error
      this.isMigrated = false;
    }

    modelCache.put(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, this.key, Promise.resolve(this));

    return this;
  }
}

export default OrganizationEncryptionMigration;
