const MEMBER_TECH_ACCOUNT_DOMAIN = 'techacct.adobe.com';
const MEMBER_TYPE = {
  NOT_AVAILABLE: 'NOT_AVAILABLE', // Not an actual member type, used for UI purposes
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',
  TYPE2E: 'TYPE2E',
  TYPE3: 'TYPE3',
  USER_GROUP: 'USER_GROUP',
};

export {MEMBER_TECH_ACCOUNT_DOMAIN, MEMBER_TYPE};
