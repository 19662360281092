(function () {
  /**
   * @ngdoc module
   * @name binky.core.insight.item
   * @description Module which defines insight-item related models
   */
  angular.module('binky.core.insights.item', [
    'binky.core.api.report-broker',
    'binky.core.api.utils',
    'binky.core.authentication',
    'binky.core.common.list',
    'binky.core.common.list.state.alpha',
    'binky.core.common.model.cache',
    'binky.core.download-utils',
    'binky.core.insights.access',
    'binky.core.lodash',
    'binky.core.user',
  ]);
})();
