(function () {
  // Remove file with temp_org_asset_encryption_migrated and replace all calls to isMigrated with true

  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.organizations:OrganizationEncryptionMigration
   * @description model that helps determine whether an org has been migrated to org level encryption
   */
  angular
    .module('binky.core.organizations')
    .factory('OrganizationEncryptionMigration', getOrganizationEncryptionMigration);
  /* @ngInject */
  function getOrganizationEncryptionMigration(
    $log,
    $q,
    _,
    feature,
    kms,
    modelCache,
    ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID,
    OrganizationEncryption
  ) {
    class OrganizationEncryptionMigration {
      /**
       * @description Method to create a new instance of a OrganizationEncryptionMigration.
       * @param {Object} options - configures the OrganizationEncryptionMigration model instance.
       * @param {String} [options.orgId] - associates OrganizationEncryptionMigration instance with an org.
       */
      constructor(options) {
        this.$promise = null;
        this.migrated = null;
        this.orgId = options.orgId;
      }

      /**
       * @description Method to determine whether an org has been migrated to org level encryption.
       *              Should only be called after the OrganizationEncryptionMigration.get is resolved
       * @returns {Boolean} true if migrated to org level encryption, otherwise false
       */
      isMigrated() {
        if (!_.isBoolean(this.migrated)) {
          $log.error('Checking org level migration status before promises resolved');
        }
        return this.migrated;
      }

      /**
       * @description Method to get the cache key for this model
       * @returns {String} the cache key
       */
      key() {
        return this.orgId;
      }

      /**
       * @description Method to fetch whether the org is migrated to org level encryption
       * @returns {Promise} promise object that resolves to true if migrated, otherwise false.
       */
      refresh() {
        // After all have been migrated, no need to make API calls
        if (feature.isEnabled('temp_org_asset_encryption_migrated')) {
          this.migrated = true;
          this.$promise = $q.resolve(this.migrated);
        }

        // If we're in the middle of migration, check to see whether this org is migrated or not
        else {
          const deferred = $q.defer();
          this.$promise = deferred.promise;
          $q.all({
            directoryEncryption: kms.encryptedDirectories.query({orgId: this.orgId}).$promise,
            orgEncryption: OrganizationEncryption.get({
              orgId: this.orgId,
            }).getOrgEncryptionStatusInfo(),
          })
            .then((data) => {
              // If it has an encryption status, it's migrated
              const hasOrgEncryption =
                _.get(data, 'orgEncryption.encryptionStatus') === 'REVOKED' ||
                _.get(data, 'orgEncryption.encryptionStatus') === 'ENABLED';

              // If it doesn't have any encrypted directories, it's migrated
              const hasDirectoryEncryption = _.get(data, 'directoryEncryption.length') > 0;
              this.migrated = hasOrgEncryption || !hasDirectoryEncryption;
              modelCache.put(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, this, this.key());
            })
            .catch(() => {
              // Some error occurred, treat this org as unmigrated
              this.migrated = false;
            })
            .finally(() => {
              deferred.resolve(this.migrated);
            });
        }

        return this.$promise;
      }

      /**
       * @description Method to get a model instance of OrganizationEncryptionMigration
       * @param {Object} options as described below
       * @param {string} options.orgId - ID of organization to get the migration status for.
       * @returns {OrganizationEncryptionMigration} a potentially cached instance of OrganizationEncryptionMigration
       */
      static get(options) {
        let model = new OrganizationEncryptionMigration(options);
        const cachedModel = modelCache.get(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    modelCache.register(ORGANIZATION_ENCRYPTION_MIGRATION_CACHE_ID, 10);

    return OrganizationEncryptionMigration;
  }
})();
