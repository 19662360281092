const CONTRACT_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.contract.CONTRACT_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/models/contract/ContractConstants.js
   */
  angular
    .module('binky.core.contract')
    .constant('CONTRACT_AUTORENEWAL_MODE', CONTRACT_CONSTANTS.CONTRACT_AUTORENEWAL_MODE)
    .constant('CONTRACT_BILLING_FREQUENCY', CONTRACT_CONSTANTS.CONTRACT_BILLING_FREQUENCY)
    .constant('CONTRACT_BUYING_PROGRAM', CONTRACT_CONSTANTS.CONTRACT_BUYING_PROGRAM)
    .constant('CONTRACT_COMPLIANCE_SYMPTOMS', CONTRACT_CONSTANTS.CONTRACT_COMPLIANCE_SYMPTOMS)
    .constant('CONTRACT_EVENT', CONTRACT_CONSTANTS.CONTRACT_EVENT)
    .constant('CONTRACT_EXPIRATION_GRACE_UNIT', CONTRACT_CONSTANTS.CONTRACT_EXPIRATION_GRACE_UNIT)
    .constant('CONTRACT_GRACE_END_DATE_OFFSET', CONTRACT_CONSTANTS.CONTRACT_GRACE_END_DATE_OFFSET)
    .constant('CONTRACT_LEGACY_DX_ID', CONTRACT_CONSTANTS.CONTRACT_LEGACY_DX_ID)
    .constant('CONTRACT_MODEL', CONTRACT_CONSTANTS.CONTRACT_MODEL)
    .constant(
      'CONTRACT_PATCH_API_KEY_LOOKUP_TABLE',
      CONTRACT_CONSTANTS.CONTRACT_PATCH_API_KEY_LOOKUP_TABLE
    )
    .constant('CONTRACT_PAYMENT_CATEGORY', CONTRACT_CONSTANTS.CONTRACT_PAYMENT_CATEGORY)
    .constant('CONTRACT_STATUS', CONTRACT_CONSTANTS.CONTRACT_STATUS);
})();
