(function () {
  'use strict';
  /**
   * @deprecated use the Pandora wizard
   */
  angular
    .module('binky.widgets.common.wizard')
    .constant('WIZARD_CHANGE', 'WIZARD_CHANGE')
    .constant('WIZARD_INIT', 'WIZARD_INIT')
    .constant('WIZARD_NEXT', 'WIZARD_NEXT')
    .constant('WIZARD_PREVIOUS', 'WIZARD_PREVIOUS');
})();
