(function () {
  /**
   * @deprecated Please use src2/core/models/availableType/AvailableType.js
   *
   * @ngdoc factory
   * @name AvailableType
   * @description Model for an individual AvailableType
   */
  angular
    .module('binky.core.search.available-type-list')
    .factory('AvailableType', getAvailableTypeModel);

  /* @ngInject */
  function getAvailableTypeModel(binkySrc2) {
    return binkySrc2.models.availableType.AvailableType;
  }
})();
