/* eslint-disable angular/window-service -- src2 stub */

(function () {
  /**
   * @deprecated Please use src2/core/services/locale/LocaleConstants.js
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('binky.core.locale')
    .constant('DEFAULT_LANGUAGE', window.binkySrc2.services.LOCALE_CONSTANTS.DEFAULT_LANGUAGE)
    // BCP47 language codes as per https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1387827668
    .constant('LANGUAGE_BCP47_MAP', window.binkySrc2.services.LOCALE_CONSTANTS.LANGUAGE_BCP47_MAP)
    .constant(
      'LANGUAGE_COUNTRY_MAP',
      window.binkySrc2.services.LOCALE_CONSTANTS.LANGUAGE_COUNTRY_MAP
    )
    // Source: http://download1.parallels.com/Plesk/Plesk8.2/Doc/plesk-8.2-win-l10n-guide/39382.htm
    .constant('LOCALE_LANGUAGE_MAP', {
      ar_AE: 'ar',
      ar_BH: 'ar',
      ar_DZ: 'ar',
      ar_EG: 'ar',
      ar_IQ: 'ar',
      ar_JO: 'ar',
      ar_KW: 'ar',
      ar_LB: 'ar',
      ar_LY: 'ar',
      ar_MA: 'ar',
      ar_MENA: 'ar',
      ar_OM: 'ar',
      ar_QA: 'ar',
      ar_SA: 'ar',
      ar_SY: 'ar',
      ar_TN: 'ar',
      ar_YE: 'ar',
      bg_BG: 'bg',
      cs_CZ: 'cs',
      da_DK: 'da',
      de_AT: 'de',
      de_CH: 'de',
      de_DE: 'de',
      de_LI: 'de',
      de_LU: 'de',
      el_GR: 'el',
      en_AU: 'en',
      en_BE: 'en',
      en_BZ: 'en',
      en_CA: 'en',
      en_CB: 'en',
      en_GB: 'en',
      en_IE: 'en',
      en_JM: 'en',
      en_LU: 'en',
      en_NZ: 'en',
      en_PH: 'en',
      en_TT: 'en',
      en_US: 'en',
      en_ZA: 'en',
      en_ZW: 'en',
      es_AR: 'es',
      es_BO: 'es',
      es_CL: 'es',
      es_CO: 'es',
      es_CR: 'es',
      es_DO: 'es',
      es_EC: 'es',
      es_ES: 'es',
      es_GT: 'es',
      es_HN: 'es',
      es_MX: 'es',
      es_NI: 'es',
      es_PA: 'es',
      es_PE: 'es',
      es_PR: 'es',
      es_PY: 'es',
      es_SV: 'es',
      es_UY: 'es',
      es_VE: 'es',
      et_EE: 'et',
      fi_FI: 'fi',
      fr_BE: 'fr',
      fr_CA: 'fr',
      fr_CH: 'fr',
      fr_FR: 'fr',
      fr_LU: 'fr',
      fr_MC: 'fr',
      he_IL: 'he',
      hr_HR: 'hr',
      hu_HU: 'hu',
      id_ID: 'id',
      it_CH: 'it',
      it_IT: 'it',
      ja_JP: 'ja',
      kk_KZ: 'kk',
      ko_KR: 'ko',
      lt_LT: 'lt',
      lv_LV: 'lv',
      ms_MY: 'ms',
      nb_NO: 'nb',
      nl_BE: 'nl',
      nl_NL: 'nl',
      pl_PL: 'pl',
      pt_BR: 'pt',
      pt_PT: 'pt',
      ro_RO: 'ro',
      ru_RU: 'ru',
      sk_SK: 'sk',
      sl_SI: 'sl',
      sr_SP: 'sr',
      sv_FI: 'sv',
      sv_SE: 'sv',
      th_TH: 'th',
      tr_TR: 'tr',
      uk_UA: 'uk',
      vi_VN: 'vi',
      zh_CN: 'cn',
      zh_TW: 'tw',
    })
    .constant('SUPPORTED_LANGUAGES', [
      {
        label: 'Čeština',
        locale: 'cs_CZ',
        url: '/str/str-cs.495e31ace1.json',
        value: 'cs',
      },
      {
        label: 'Dansk',
        locale: 'da_DK',
        url: '/str/str-da.495e31ace1.json',
        value: 'da',
      },
      {
        label: 'Deutsch',
        locale: 'de_DE',
        url: '/str/str-de.495e31ace1.json',
        value: 'de',
      },
      {
        label: 'English',
        locale: 'en_US',
        url: '/str/str-en.495e31ace1.json',
        value: 'en',
      },
      {
        label: 'Español',
        locale: 'es_ES',
        url: '/str/str-es.495e31ace1.json',
        value: 'es',
      },
      {
        label: 'Français',
        locale: 'fr_FR',
        url: '/str/str-fr.495e31ace1.json',
        value: 'fr',
      },
      {
        // used for storing string ids for localization testing
        hidden: true, // only selectable via query param
        label: 'String ID',
        locale: 'kk_KZ',
        url: '/str/str-kk.495e31ace1.json',
        value: 'kk',
      },
      {
        label: 'Italiano',
        locale: 'it_IT',
        url: '/str/str-it.495e31ace1.json',
        value: 'it',
      },
      {
        label: 'Nederlands',
        locale: 'nl_NL',
        url: '/str/str-nl.495e31ace1.json',
        value: 'nl',
      },
      {
        label: 'Norsk',
        locale: 'nb_NO',
        url: '/str/str-nb.495e31ace1.json',
        value: 'nb',
      },
      {
        label: 'Polski',
        locale: 'pl_PL',
        url: '/str/str-pl.495e31ace1.json',
        value: 'pl',
      },
      {
        label: 'Português',
        locale: 'pt_BR',
        url: '/str/str-pt.495e31ace1.json',
        value: 'pt',
      },
      {
        label: 'Suomi',
        locale: 'fi_FI',
        url: '/str/str-fi.495e31ace1.json',
        value: 'fi',
      },
      {
        label: 'Svenska',
        locale: 'sv_SE',
        url: '/str/str-sv.495e31ace1.json',
        value: 'sv',
      },
      {
        label: 'Türkçe',
        locale: 'tr_TR',
        url: '/str/str-tr.495e31ace1.json',
        value: 'tr',
      },
      {
        label: 'Pусский',
        locale: 'ru_RU',
        url: '/str/str-ru.495e31ace1.json',
        value: 'ru',
      },
      {
        label: '简体中文',
        locale: 'zh_CN',
        url: '/str/str-zh-cn.495e31ace1.json',
        value: 'zh-cn',
      },
      {
        label: '繁體中文',
        locale: 'zh_TW',
        url: '/str/str-zh-tw.495e31ace1.json',
        value: 'zh-tw',
      },
      {
        label: '日本語',
        locale: 'ja_JP',
        url: '/str/str-ja.495e31ace1.json',
        value: 'ja',
      },
      {
        label: '한국어',
        locale: 'ko_KR',
        url: '/str/str-ko.495e31ace1.json',
        value: 'ko',
      },
      {
        label: 'Български',
        locale: 'bg_BG',
        // markedForTesting is the new field which will be used to include the new locales on stage
        markedForTesting: 'true',
        url: '/str/str-bg.495e31ace1.json',
        value: 'bg',
      },
      {
        label: 'Ελληνικά',
        locale: 'el_GR',
        markedForTesting: 'true',
        url: '/str/str-el.495e31ace1.json',
        value: 'el',
      },
      {
        label: 'Eesti',
        locale: 'et_EE',
        markedForTesting: 'true',
        url: '/str/str-et.495e31ace1.json',
        value: 'et',
      },
      {
        label: 'Magyar',
        locale: 'hu_HU',
        markedForTesting: 'true',
        url: '/str/str-hu.495e31ace1.json',
        value: 'hu',
      },
      {
        label: 'Bahasa Indonesia',
        locale: 'id_ID',
        markedForTesting: 'true',
        url: '/str/str-id.495e31ace1.json',
        value: 'id',
      },
      {
        label: 'Latviešu',
        locale: 'lv_LV',
        markedForTesting: 'true',
        url: '/str/str-lv.495e31ace1.json',
        value: 'lv',
      },
      {
        label: 'Lietuvių',
        locale: 'lt_LT',
        markedForTesting: 'true',
        url: '/str/str-lt.495e31ace1.json',
        value: 'lt',
      },
      {
        label: 'Bahasa Melayu',
        locale: 'ms_MY',
        markedForTesting: 'true',
        url: '/str/str-ms.495e31ace1.json',
        value: 'ms',
      },
      {
        label: 'Română',
        locale: 'ro_RO',
        markedForTesting: 'true',
        url: '/str/str-ro.495e31ace1.json',
        value: 'ro',
      },
      {
        label: 'Slovenčina',
        locale: 'sk_SK',
        markedForTesting: 'true',
        url: '/str/str-sk.495e31ace1.json',
        value: 'sk',
      },
      {
        label: 'Slovenščina',
        locale: 'sl_SI',
        markedForTesting: 'true',
        url: '/str/str-sl.495e31ace1.json',
        value: 'sl',
      },
      {
        label: 'ไทย',
        locale: 'th_TH',
        markedForTesting: 'true',
        url: '/str/str-th.495e31ace1.json',
        value: 'th',
      },
      {
        label: 'Українська',
        locale: 'uk_UA',
        markedForTesting: 'true',
        url: '/str/str-uk.495e31ace1.json',
        value: 'uk',
      },
      {
        label: 'Tiếng Việt',
        locale: 'vi_VN',
        markedForTesting: 'true',
        url: '/str/str-vi.495e31ace1.json',
        value: 'vi',
      },
    ])
    .constant(
      'SURNAME_FIRST_LOCALES',
      window.binkySrc2.services.LOCALE_CONSTANTS.SURNAME_FIRST_LOCALES
    );
})();
/* eslint-enable angular/window-service -- src2 stub */
