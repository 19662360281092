import {LinkedUserAccount, ORGANIZATION_TYPE, Organization} from '@admin-tribe/binky';
import {
  Button,
  ButtonGroup,
  Content,
  Heading,
  IllustratedMessage,
  View,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import OrganizationSwitcher from 'common/components/organization-switcher/OrganizationSwitcher';

/**
 * The message of the bumper including buttons for next steps, if provided.
 */
const BumperMessage = ({
  activeOrgId,
  authenticatedUserId,
  details,
  ctaButtonLabel,
  linkedUserAccounts,
  moreDetails,
  onButtonClick,
  onOrgChange,
  onSecondaryButtonClick,
  organizationItems,
  secondaryLabel,
  title,
}) => {
  const intl = useIntl();
  // set default values
  const ctaButtonLabelText =
    ctaButtonLabel || intl.formatMessage({id: 'binky.shell.bumper.button.primary'});
  const detailsText = details || intl.formatMessage({id: 'binky.shell.bumper.details'});
  const titleText = title || intl.formatMessage({id: 'binky.shell.bumper.title'});
  const filterToTypes = [ORGANIZATION_TYPE.DIRECT, ORGANIZATION_TYPE.ENTERPRISE];

  return (
    <IllustratedMessage gridArea="content">
      <Heading data-testid="bumper-header" level={2}>
        {titleText}
      </Heading>
      <Content data-testid="bumper-details">{detailsText}</Content>
      {moreDetails && <Content data-testid="bumper-more-details">{moreDetails}</Content>}
      {(linkedUserAccounts || organizationItems) && (
        <View marginTop="size-200">
          <OrganizationSwitcher
            activeOrgId={activeOrgId}
            authenticatedUserId={authenticatedUserId}
            filterToTypes={filterToTypes}
            linkedUserAccounts={linkedUserAccounts}
            onChange={onOrgChange}
            organizationItems={organizationItems}
          />
        </View>
      )}
      {onButtonClick && (
        <ButtonGroup marginTop="size-400">
          {secondaryLabel && onSecondaryButtonClick && (
            <Button
              data-testid="bumper-secondary-button"
              onPress={onSecondaryButtonClick}
              variant="secondary"
            >
              {secondaryLabel}
            </Button>
          )}
          <Button data-testid="bumper-cta-button" onPress={onButtonClick} variant="accent">
            {ctaButtonLabelText}
          </Button>
        </ButtonGroup>
      )}
    </IllustratedMessage>
  );
};

BumperMessage.propTypes = {
  /**
   * The ID of the active org.
   */
  activeOrgId: PropTypes.string,
  /**
   * The ID of current authenticated user, used in OrganizationSwitcher.
   */
  authenticatedUserId: PropTypes.string,
  /**
   * Translated text of the button label for cta button. Defaults to `Get Help`.
   */
  ctaButtonLabel: PropTypes.string,
  /**
   * Translated text of the details in the Bumper.
   */
  details: PropTypes.string,
  /**
   * The linked user accounts to display for org switching.
   */
  linkedUserAccounts: PropTypes.arrayOf(PropTypes.instanceOf(LinkedUserAccount)),
  /**
   * Translated text for more details in Bumper.
   */
  moreDetails: PropTypes.string,
  /**
   * Callback function called when cta button is clicked.
   */
  onButtonClick: PropTypes.func,
  /**
   * The function to invoke on selecting a different org
   */
  onOrgChange: PropTypes.func,
  /**
   * Callback function called when the secondary button is clicked.
   */
  onSecondaryButtonClick: PropTypes.func,
  /**
   * The organization items to display for the switcher.
   */
  organizationItems: PropTypes.arrayOf(PropTypes.instanceOf(Organization)),
  /**
   * Translated text of the button label for the secondary button.
   * If the label is not defined, the secondary button would not be displayed.
   */
  secondaryLabel: PropTypes.string,
  /**
   * Translated text for title in Bumper. Defaults to `We are sorry.`.
   */
  title: PropTypes.string,
};

export default BumperMessage;
