(function () {
  /**
   * @deprecated Please use src2/core/models/offers/OfferList.js
   *
   * @ngdoc factory
   * @name binky.core.offers:OfferList
   * @description fetch the list of offers
   */
  angular.module('binky.core.offers').factory('OfferList', offerList);

  /* @ngInject */
  function offerList(
    $q,
    _,
    binkySrc2,
    feature,
    jilOffers,
    jilOrganizationsContractOffers,
    jilOrganizationsOffers,
    List,
    Offer,
    OFFER_LIST_FILTER,
    OFFER_LIST_SERVICE_PROVIDER
  ) {
    const DEFAULT_PAGE = 0;
    // We generally don't want to paginate the results; hopefully 100 is large enough to get all the offers.
    const LIST_DEFAULT_PAGE_SIZE = 100;

    class OfferList extends List {
      /**
       * @description instantiate the list of Offers for an Organization.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} [options.contractId] - associates OfferList instance with a contract.
       * @param {String} [options.filter] - the filter to apply to offers, from OFFER_LIST_FILTER. Defaults to CONTRACT.
       * @param {String} [options.orgId] - associates OfferList instance with an org.
       * @param {Number} [options.page] the page to fetch, starting from 0
       * @param {Number} [options.pageSize] the number of items to fetch in a page
       * @param {Object} [options.queryParams] queryParams to pass along the offers API call - for example "price_point"
       */
      constructor(options = {}) {
        if (_.has(options, 'queryParams.intent')) {
          options.filter = OFFER_LIST_FILTER.PORES;
        }
        super({
          // we don't cache this in the List because we always want to fetch the latest price data
          itemClassRef: Offer,
          resource: getJilResource(options.filter),
        });
        this.$resolved = false;
        this.items = [];
        this.page = options.page || DEFAULT_PAGE;
        this.pageSize = options.pageSize || LIST_DEFAULT_PAGE_SIZE;

        this.queryParams = options.queryParams;
        this.orgId = options.orgId;
        this.contractId = options.contractId;

        this.offerListFilter = options.filter;
      }

      /**
       * @description Determine whether the offers prices all include tax.
       *
       * @returns {boolean} true if prices include tax, false otherwise
       */
      doPricesIncludeTax() {
        return binkySrc2.services.offers.OfferListUtils.doPricesIncludeTax(this);
      }

      /**
       * @description Method to filter the qualifying offers to only those which can have a Purchase
       *   Authorization created for them.
       *   This is a destructive change to the object.
       */
      filterOnCanCreatePurchaseAuthorization() {
        this.items =
          binkySrc2.services.offers.OfferListUtils.filterOnCanCreatePurchaseAuthorizationUtil(this);
      }

      /**
       * @description Method to filter the qualifying free offers for the org marketSubsegments.
       *   Note: assumes this is a free offer list - 'queryParams: {price_point: 'FREE'}}'
       *   It is also possible that there is not an offer for every marketSubsegment, or in the future, there may be
       *   more than one offer per marketSubsegment.
       *   This is a destructive change to the object.
       * @param {Object} context - Context provided to filter
       * @param {Organization} [context.organization] The organization to compare with for offer qualification
       * @param {ProductList} [context.ownedProducts] The current product list to discard offers that have already
       *   been provisioned.
       */
      filterOnQualifyingFreeOffers(context = {}) {
        this.items = binkySrc2.services.offers.OfferListUtils.filterOnQualifyingFreeOffersUtil(
          context,
          this
        );
      }

      /**
       * @description Gets a list of offer ids mapped from the resolved offers
       *
       * @returns {string[]} list of offer ids
       */
      getOfferIds() {
        return binkySrc2.services.offers.OfferListUtils.getOfferIds(this);
      }

      /**
       * @description Get the offers for the provided billableItems. Populates the available license count
       * and assignable license count if available from the product object; populates the renewing license count from the billableItems.
       *
       * @param {BillableItem[]} billableItems list of billableItems to get offers for
       * @param {Product[]} products - Array of products
       * @returns {Offer[]} Array of offers for the provided billableItems
       */
      getOffersForBillableItems(billableItems, products) {
        return binkySrc2.services.offers.OfferListUtils.getOffersForBillableItems({
          billableItems,
          offerList: this,
          products,
        });
      }

      /**
       * @description Get the offers for the provided billableItems and products. Populates the available license count
       * and assignable license count if available from the product object; populates the renewing license count from the billableItems.
       *
       * @param {BillableItem[]} billableItems list of billableItems to get offers for
       * @param {Product[]} products - Array of products to get offers for
       * @returns {Offer[]} list of offers for the provided billableItems and products
       */
      getOffersForBillableItemsAndProducts(billableItems, products) {
        return binkySrc2.services.offers.OfferListUtils.getOffersForBillableItemsAndProducts({
          billableItems,
          offerList: this,
          products,
        });
      }

      /**
       * @description Get the offers for the provided products. Populates the available license count,
       * if available from the product object.
       *
       * @param {Product[]} products - Array of products to get offers for
       * @param {string} [segment] Customer segment to filter by
       * @returns {Offer[]} - Array of offers for the provided products
       */
      getOffersForProducts(products, segment) {
        return binkySrc2.services.offers.OfferListUtils.getOffersForProducts({
          offerList: this,
          products,
          segment,
        });
      }

      /**
       * @description Get the offers for the provided customer segment.
       *
       * @param {String} segment segment to filter offers by. e.g. TEAM, ENTERPRISE
       * @returns {Offer[]} list of offers for the provided segment
       */

      getOffersForSegment(segment) {
        return binkySrc2.services.offers.OfferListUtils.getOffersForSegment(this, segment);
      }

      /**
       * @description Filter the list offers to exclude the provided products (by product arrangement code).
       *   If no products are provided, return all offers.
       *
       * @param {Product[]} products - Array of products to exclude
       * @param {Boolean} requireCopy if true, filters any offers that lack copy since we won't be able to display the
       *                   offer's product name, etc.
       * @param {string} segment Customer segment to filter by (optional)
       * @returns {Offer[]} list of offers without the offers corresponding to to the provided products
       */
      getOffersWithoutProducts(products, requireCopy, segment) {
        return binkySrc2.services.offers.OfferListUtils.getOffersWithoutProducts({
          offerList: this,
          products,
          requireCopy,
          segment,
        });
      }

      /**
       * @description Filter the list offers to exclude the provided billableItems and products (by offer Id).
       *
       * @param {BillableItem[]} billableItems - Array of billableItems to exclude offers for
       * @param {Product[]} products - Array of products to exclude offers for
       * @returns {Offer[]} Array of offers exclude the provided billableItems and products
       */
      getOffersWithoutRenewalOrderOrProducts(billableItems, products) {
        return binkySrc2.services.offers.OfferListUtils.getOffersWithoutRenewalOrderOrProducts({
          billableItems,
          offerList: this,
          products,
        });
      }

      /**
       * @description Method to refresh the Offer List
       *
       * @return {Promise} resolved when Offer list fetch completes.
       */
      refresh() {
        if (this.contractId || this.offerListFilter === OFFER_LIST_FILTER.SKU) {
          const queryParams = getQueryParams(this);
          this.$promise = super.refresh(queryParams).then(onRefreshSuccess.bind(this));
        } else {
          this.$resolved = true;
          this.$promise = $q.reject('No contract found');
        }

        return this.$promise;

        ////////

        function getFilterSpecificQueryParams(model) {
          const defaultServiceProvidersString = getDefaultServiceProvidersString();
          switch (model.offerListFilter) {
            case OFFER_LIST_FILTER.NONE:
            case OFFER_LIST_FILTER.SKU:
              return {
                id: model.contractId,
                service_providers: defaultServiceProvidersString,
              };
            case OFFER_LIST_FILTER.PORES:
              return {
                contractId: model.contractId,
                orgId: model.orgId,
                service_providers: defaultServiceProvidersString,
              };
            case OFFER_LIST_FILTER.CONTRACT:
            default:
              return {contractId: model.contractId, orgId: model.orgId};
          }
        }

        function getQueryParams(model) {
          const defaultQueryParams = {
            page_size: model.pageSize,
          };

          const filterSpecificQueryParams = getFilterSpecificQueryParams(model);

          return _(defaultQueryParams)
            .assign(filterSpecificQueryParams, model.queryParams)
            .omitBy(_.isUndefined)
            .value();
        }

        function onRefreshSuccess() {
          this.$resolved = true;
          // we allow for offers to be explicitly filtered by flag, to account for
          // offers which are not supposed to be live in production but have to
          // be put there to enable PAT prior to go live.
          this.items = _.reject(this.items, (offer) =>
            feature.isEnabled(`filter_offer_${offer.offer_id}`)
          );
          const sortingFunction =
            this.offerListFilter === OFFER_LIST_FILTER.PORES
              ? binkySrc2.services.offers.OfferListUtils.getPoresOrder
              : binkySrc2.services.offers.OfferListUtils.getCopyName;
          this.items = binkySrc2.services.offers.OfferListUtils.sortOffersByFunctionOutput(
            this.items,
            sortingFunction
          );

          return this;
        }
      }

      /**
       * @description instantiates a new instance of an OfferList. We don't cache this in the
       *    List because we always want to fetch the latest price data.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Number} [options.page] the page to fetch, starting from 0
       * @param {Number} [options.pageSize] the number of items to fetch in a page
       * @param {Object} [options.queryParams] queryParams to pass to the offers API call
       * @returns {OfferList} OfferList model object.
       */
      static get(options = {}) {
        const model = new OfferList(options);
        model.refresh();
        return model;
      }

      /**
       * @description Get the list of marketSubsegments represented in the list of offers.
       *
       * @param {Array} offers array of offers
       * @returns {Array} array of 0 or more marketSubsegments
       */
      static getMarketSubsegmentsForOffers(offers) {
        return binkySrc2.services.offers.OfferListUtils.getMarketSubsegmentsForOffers(offers);
      }
    }

    return OfferList;

    //////////////

    function getDefaultServiceProvidersString() {
      return _(OFFER_LIST_SERVICE_PROVIDER).values().join(',');
    }

    /**
     * @description Get the resource according to the filter
     * There are three APIs to get the offers
     * 1. {JIL}/offers - returns the offers based on various offer characteristics
     * 2. {JIL}/organization/{orgId}/contracts/{contractId}/offers - returns the offers appropriate for the supplied contract and mode
     * 3. {JIL}/organization/{orgId}/offers - returns the ordered list of personalised offers for the given organization.
     *       This is the endpoint of PORES for organizations. PORES stands for personalized offers recommendation service.
     *       More information about PORES in general: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=JIL2&title=Personalised+Offers+and+PORES
     *       API spec for Admin Console integration with PORES: https://wiki.corp.adobe.com/x/Q_Jjag
     *
     * Note: the ultimate goal is to deprecate /organization/{orgId}/contracts/{contractId}/offers and move everything to
     * PORES endpoint /organization/{orgId}/offers, but since the JIL team doesn't have the capacity to do so,
     * we are only using PORES for the new purchase workflow.
     *
     * @param {OFFER_LIST_FILTER} filter list of items that we want to get item from
     * @returns {$resource} the resource for the API
     */
    function getJilResource(filter) {
      switch (filter) {
        case OFFER_LIST_FILTER.NONE:
          return jilOffers.offers;
        case OFFER_LIST_FILTER.PORES:
          return jilOrganizationsOffers.offers;
        case OFFER_LIST_FILTER.SKU:
          return jilOffers.offersBySku;
        case OFFER_LIST_FILTER.CONTRACT:
        default:
          return jilOrganizationsContractOffers.offers;
      }
    }
  }
})();
