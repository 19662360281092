import {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
} from '@admin-tribe/binky';
import {Checkbox, CheckboxGroup, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import AssignmentSection from 'common/components/assignment-section/AssignmentSection';

const USER_ROLES = {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
};

/**
 * Displays a list of admin roles which are avaiable to be selected
 */
const AdminRoleCheckbox = ({checkboxList, onSelectRole, onSelectTarget, orgId}) => {
  const intl = useIntl();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(checkboxList.checked);

  return (
    <CheckboxGroup
      defaultValue={checkboxList.checked}
      isEmphasized
      label={intl.formatMessage({id: 'binky.common.adminRole.checkbox.title'})}
      marginTop="size-200"
      onChange={setSelectedCheckboxes}
    >
      {checkboxList.items.map((item) => (
        <View key={item.value}>
          <Checkbox
            isDisabled={checkboxList.disabled?.includes(item.value)}
            onChange={(checked) => onSelectRole(checked, item.value)}
            value={item.value}
          >
            {item.name}
          </Checkbox>
          <View marginBottom="size-250" marginStart="size-300">
            {item.description}
          </View>
          {item.targets && selectedCheckboxes?.includes(item.value) && (
            <>
              <View marginBottom="size-100" UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'binky.common.adminRole.checkbox.assignmentSection.title',
                })}
              </View>
              <AssignmentSection
                onChange={(selectedItems) => onSelectTarget(selectedItems, item.value)}
                orgId={orgId}
                targets={item.targets}
              />
            </>
          )}
        </View>
      ))}
    </CheckboxGroup>
  );
};

AdminRoleCheckbox.propTypes = {
  /**
   * Information for displaying checkbox.
   */
  checkboxList: PropTypes.shape({
    checked: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
    disabled: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
        target: PropTypes.oneOf(Object.values(ASSIGNMENT_MENU_CONSTANTS.TARGET_TYPE)),
        value: PropTypes.oneOf(Object.values(USER_ROLES)).isRequired,
      })
    ),
  }).isRequired,
  /**
   * Handler that is called when an admin role in the checkbox is selected
   */
  onSelectRole: PropTypes.func,
  /**
   * Handler that is called when an assignment target is selected
   */
  onSelectTarget: PropTypes.func,
  /**
   * The org id to fetch available products for the role
   */
  orgId: PropTypes.string,
};

export default AdminRoleCheckbox;
