import PropTypes from 'prop-types';
import React from 'react';
import {FormattedDate} from 'react-intl';

// App-wide default date/time formats.
//
// To format a date/time imperatively you can
//  - intl.formatDate(value, format);
//  - new Intl.DateTimeFormat(Locale.get().activeLanguage, format).format(value);
// where value is a String, Number or Date
// where format is one of these DATE_FORMATS or any format accepted by Intl.DateTimeFormat
//
// ref: https://spectrum.corp.adobe.com/page/grammar-and-mechanics/#Dates

// See Intl.DateTimeFormat documentation for description of these formats.
const DATE_FORMATS = {
  // English: February 11, 2018
  // French: 11 février 2018
  // @deprecated - use default
  dateOnly: {
    dateStyle: 'long',
  },
  // English: February 11, 2018 at 5:38 PM
  // French: 11 février 2018 à 17:38
  // @deprecated - use defaultDateTime
  dateTime: {
    dateStyle: 'long',
    timeStyle: 'short',
  },
  // English: Feb 11, 2018, 5:38 PM
  // French: 11 févr. 2018, 17:38
  // @deprecated - use defaultDateTime
  dateTimeMedium: {
    dateStyle: 'medium',
    timeStyle: 'short',
  },
  // English: Aug 21, 2017
  // French: 11 févr. 2018
  default: {
    dateStyle: 'medium',
  },
  // English: Aug 21, 2017
  // French: 11 févr. 2018
  defaultDateTime: {
    dateStyle: 'medium',
    timeStyle: 'short',
  },
  // English: 5:38 PM
  // French: 17:38
  timeOnly: {
    timeStyle: 'short',
  },
};

/**
 * Component used to display dates and times in the app-wide date/time formats.
 */
const FormattedDateAndTime = ({format = DATE_FORMATS.default, value}) => (
  <FormattedDate {...format} value={value} />
);

FormattedDateAndTime.DATE_FORMATS = DATE_FORMATS;

FormattedDateAndTime.propTypes = {
  /**
   * The format for the date/time from DATE\_FORMATS. Default is DATE\_FORMATS.dateOnly.
   */
  format: PropTypes.oneOf(Object.values(DATE_FORMATS)),
  /**
   * The date/time to be formatted.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

export default FormattedDateAndTime;
export {DATE_FORMATS};
