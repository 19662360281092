const CONTACT_LIST_CACHE_ID = 'ContactList';
const CONTACT_LIST_EVENT = {
  REFRESH: 'ContactListRefresh',
};
const CONTACT_TYPE = {
  DATA_ACCESS_ADMINISTRATOR: 'dataAccessAdministrator',
  DATA_PROTECTION_OFFICER: 'dataProtectionOfficer',
  SECURITY_OFFICER: 'securityOfficer',
};

export {CONTACT_LIST_CACHE_ID, CONTACT_LIST_EVENT, CONTACT_TYPE};
