import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';

import {
  LICENSE_QUANTITY_RENEWAL_STATUS,
  LICENSE_QUANTITY_STATUS,
} from 'models/licenseQuantityList/LicenseQuantityListConstants';

import LicenseTuple from './LicenseTuple';

// Maps the deprecated license quantity status to the LicenseTuple method name.
// Need to map old statuses until SLS M3.0
const LICENSE_STATUS_TO_METHOD = {
  [LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE]: 'isGracePastDue',
  [LICENSE_QUANTITY_STATUS.PAST_DUE]: 'isPastDue',
  [LICENSE_QUANTITY_STATUS.PENDING_PAYMENT]: 'isPendingPayment',
};

/**
 * @description LicenseTupleList is a wrapper class representing a Product's tuples (LicenseTuple).
 */
class LicenseTupleList {
  items;
  savedState;

  /**
   *
   * @param {Object[]} tuples The array of license tuples
   */
  constructor(tuples = []) {
    initModel.call(this, tuples);
    this.registerSavedState();
  }

  /**
   * @description Method to determine if there are tuples with the can_hide symptom.
   * @returns {Boolean} true if any tuple can be hidden
   */
  canHide() {
    return this.items.some((licenseTuple) => licenseTuple.canHide());
  }

  /**
   * @description Method to return the quantity that can be assigned
   * @returns {Number} quantity that are assignable
   */
  getAssignableQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.isAssignable());
  }

  /**
   * @description Fetches the closest (minimum) end date from the tuples for a given
   * license quantity status.
   *
   * @param {LICENSE_QUANTITY_STATUS} status The license quantity status
   *
   * @returns {Date} the closest end date, from the filtered items
   */
  getClosestEndDateForStatus(status) {
    const licenseTupleMethodToInvoke = LICENSE_STATUS_TO_METHOD[status];
    const endDates = this.items
      .filter((tuple) => tuple[licenseTupleMethodToInvoke]())
      .map((tuple) => tuple.getEndDate())
      .filter((tuple) => !!tuple);
    return minBy(endDates, (endDate) => new Date(endDate).getTime());
  }

  /**
   * @description Fetches the closest (minimum) end date from the tuples
   * @returns {Date} the closest end date
   */
  getEarliestEndDate() {
    const endDates = this.items.map((tuple) => tuple.getEndDate()).filter((tuple) => !!tuple);
    return minBy(endDates, (endDate) => new Date(endDate).getTime());
  }

  /**
   * @description Method to return the quantity that are cancelling
   *
   * @returns {Number} quantity that are expiring
   */
  getExpiringQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.isCancelling());
  }

  /**
   * @description Method to return the quantity that are grace past due
   *
   * @returns {Number} quantity that are grace past due
   */
  getGracePastDueQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.isGracePastDue());
  }

  /**
   * @description Method to sum the quantities that are either pending payment or
   *   grace past due and not in renewal.
   * @returns {Number} quantity that need payment
   */
  getNeedPaymentQuantity() {
    return sumWhen.call(
      this,
      (licenseTuple) =>
        (licenseTuple.isGracePastDue() || licenseTuple.isPendingPayment()) &&
        !licenseTuple.canRenew()
    );
  }

  /**
   * @description Method to sum the quantities that can be renewed
   * @returns {Number}  quantity that can be renewed
   */
  getNeedRenewalQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.canRenew());
  }

  /**
   * @description Method to return the quantity that are past due
   *
   * @returns {Number} quantity that are past due
   */
  getPastDueQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.isPastDue());
  }

  /**
   * @description Method to return the quantity that can be accessed and
   * but still needs payment (backing).
   *
   * Formerly known as license quantities with PENDING_PAYMENT status.
   *
   * @returns {Number} quantity that are pending payment
   */
  getPendingPaymentQuantity() {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.isPendingPayment());
  }

  /**
   * @description Method to return the renewal status. Will be supported until we
   * move to the renewal summaries from CLAM.
   * @param {String} renewalWindowEndDate the anniversary date to compare to
   *
   * @returns {String} LICENSE_QUANTITY_RENEWAL_STATUS constant
   */
  getRenewalStatus(renewalWindowEndDate) {
    const renewedTotal = this.getRenewedTotal(renewalWindowEndDate);
    const needRenewed = this.getNeedRenewalQuantity();

    if (renewedTotal > 0) {
      return needRenewed === 0
        ? LICENSE_QUANTITY_RENEWAL_STATUS.COMPLETE
        : LICENSE_QUANTITY_RENEWAL_STATUS.PARTIAL;
    }

    return needRenewed === 0
      ? LICENSE_QUANTITY_RENEWAL_STATUS.NOT_RENEWAL
      : LICENSE_QUANTITY_RENEWAL_STATUS.NONE;
  }

  /**
   * @description Method to return the sum of quantities that have renewed
   * @param {String} renewalWindowEndDate renewal window end date in ISO-8061 timestamp format
   *
   * @returns {Number} the sum of quantities that have renewed
   */
  getRenewedTotal(renewalWindowEndDate) {
    return sumWhen.call(this, (licenseTuple) => licenseTuple.hasRenewed(renewalWindowEndDate));
  }

  /**
   * @description Method to return the non renewing quantity for a given license quantity status
   *
   * @param {LICENSE_QUANTITY_STATUS} status The license quantity status
   * @returns {Number} quantity that match the status
   */
  getTotalNonRenewingForStatus(status) {
    const licenseTupleMethodToInvoke = LICENSE_STATUS_TO_METHOD[status];
    return sumWhen.call(
      this,
      (licenseTuple) => licenseTuple[licenseTupleMethodToInvoke]() && !licenseTuple.canRenew()
    );
  }

  /**
   * @returns {Number} total quantity of tuples for the product
   */
  getTotalQuantity() {
    return sumWhen.call(this, () => true);
  }

  /**
   * @description Method to determine if there are grace past due tuples.
   * @returns {Boolean} true if any tuple is grace past due
   */
  hasGracePastDueLicenses() {
    return this.items.some((licenseTuple) => licenseTuple.isGracePastDue());
  }

  /**
   * @description Method to determine if the list only has UNLIMITED quantities.
   * @returns {boolean} true if all license tuples have quantity UNLIMITED
   */
  hasOnlyUnlimitedLicenses() {
    return this.items.every((licenseTuple) => licenseTuple.isUnlimited());
  }

  /**
   * @description Method to determine if there are past due tuples.
   * @returns {Boolean} true if any tuple is past due
   */
  hasPastDueLicenses() {
    return this.items.some((licenseTuple) => licenseTuple.isPastDue());
  }

  /**
   * @description Method to return if any of the license tuples indicate a trial product
   * @returns {boolean} Return true if there are any license tuples that indicate a trial product
   */
  hasTrialLicenses() {
    return this.items.some((licenseTuple) => licenseTuple.hasTrialExpiration());
  }

  /**
   * @description Method to determine if there are any unsaved changes to this list.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return !isEqual(this.items, this.savedState);
  }

  /**
   * @description Method to check all license tuples to see if any are delegatable.
   *
   * @returns {Boolean} true if any tuple is delegatable, false otherwise.
   */
  isDelegatable() {
    return this.items.some((licenseTuple) => licenseTuple.isDelegatable());
  }

  /**
   * @description Updates model with the saved items which may be later modified.
   */
  registerSavedState() {
    this.savedState = cloneDeep(this.items);
  }

  /**
   * @description Restores the list items from its saved state
   */
  restore() {
    this.items = cloneDeep(this.savedState);
  }

  /**
   * @description Method to transform the list into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @param {Object} [options] - Configuration for this function call.
   * @param {Boolean} [options.filterToModified] - Whether to limit to quantities
   *    that have been modified. Defaults to false.
   * @returns {Array} minimum necessary representation of the list
   */
  toMinimumModel({filterToModified = false} = {}) {
    return !filterToModified || this.hasUnsavedChanges()
      ? this.items.map((licenseTuple) => licenseTuple.toMinimumModel())
      : [];
  }
}

function initModel(tuples) {
  this.items = tuples.map((tuple) => new LicenseTuple(tuple));
}

/**
 * @description Sums the tuple quantities, filtered by the provided condition.
 *
 * @param {Function} condition the filter to apply
 * @returns {Number} the sum of the filtered quantities
 */
function sumWhen(condition) {
  return sumBy(this.items, (licenseTuple) =>
    isNumber(licenseTuple.quantity) && condition(licenseTuple) ? licenseTuple.quantity : 0
  );
}

export default LicenseTupleList;
