import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';

/**
 * A component to use in the name cell which aligns an optional icon/avatar with
 * the name.
 * The Pandora Table should have
 *   UNSAFE_style={{overflowWrap: 'anywhere'}}
 * set on it so the name will wrap even if there are no break points in the name.
 * See the Table component in the MemberListTable for an example.
 */
const TableIconNameCell = ({children, IconComponent}) => (
  <Flex alignItems="center" data-testid="table-icon-name-cell" wrap="false">
    {IconComponent &&
      cloneElement(IconComponent, {flex: 'none', marginEnd: 'size-150', size: 'XS'})}
    <Text UNSAFE_style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{children}</Text>
  </Flex>
);

TableIconNameCell.propTypes = {
  /**
   * The name to display.
   */
  children: PropTypes.node,
  /**
   * The optional icon component to display before the name.
   * It will be rendered with size="XS" and there will be "size-150"
   * of whitespace between the icon and the name.
   */
  IconComponent: PropTypes.node,
};

export default TableIconNameCell;
