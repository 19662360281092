import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/flow';
import get from 'lodash/get';
import has from 'lodash/has';
// eslint-disable-next-line you-dont-need-lodash-underscore/includes -- Inspecting both array and object
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import stubTrue from 'lodash/stubTrue';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import jilProductArrangements from 'api/jil/jilProductArrangements';
import jilProducts from 'api/jil/jilProducts';
import {
  FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
  FULFILLABLE_ITEM_CHARGING_UNIT,
} from 'models/fulfillableItemList/FulfillableItemConstants';
import FulfillableItemList from 'models/fulfillableItemList/FulfillableItemList';
import {isOrganizationConsumable} from 'models/fulfillableItemList/fulfillableItemListUtils';
import LicenseAllocationInfo from 'models/licenseAllocations/LicenseAllocationInfo';
import LicenseQuantityList from 'models/licenseQuantityList/LicenseQuantityList';
import {LICENSE_QUANTITY_UNLIMITED} from 'models/licenseQuantityList/LicenseQuantityListConstants';
import LicenseTupleList from 'models/licenseTuple/LicenseTupleList';
import {LICENSE_TUPLE_QUANTITY_UNLIMITED} from 'models/licenseTuple/licenseTupleConstants';
import {CLOUD, CUSTOMER_SEGMENT, PRICE_POINT, SALES_CHANNEL} from 'models/offers/OfferConstants';
import {isConsumable, isGroupConsumable, usesSeatBasedDelegation} from 'models/offers/offerUtils';
import ProcessingInstructions from 'models/processingInstructions/ProcessingInstructions';
import {
  isProductValid,
  isProductValidForOfferSwitch,
} from 'services/product/validator/productValidatorUtils';

import feature from '../feature';
import log from '../log';

import {
  LICENSE_STATUS,
  PRODUCT_BUYING_PROGRAM,
  PRODUCT_CHANNELS,
  PRODUCT_CONFIGURATION_SETTING_TYPE,
  PRODUCT_DELEGATION_TARGET,
  PRODUCT_DELEGATION_USER_TARGETS,
  PRODUCT_FAMILY,
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
} from './ProductConstants';

/* eslint-disable max-lines -- Legacy code */
/** Private Attributes **/
const EDITABLE_FIELDS = [
  'buyingProgram',
  'contractId',
  'customerSegment',
  'endDate',
  'id',
  'offerId',
  'requestorExternalInfo',
  'salesChannel',
];
const MIGRATION_COMPLETE = 'COMPLETE';
const MIGRATION_IN_PROGRESS = 'IN_PROGRESS';
const MIGRATION_READY = 'READY';

class Product {
  /**
   * @class
   * @description Transforms the /organizations product response Object into a new Product
   *   instance for use.
   *
   * @param {Object} dataTransferObject Initialization Object (params described below)
   */
  static apiResponseTransformer(dataTransferObject) {
    return new Product(dataTransferObject);
  }

  /**
   * @description Method to retrieve an existing Product from back-end data
   *   store.
   *
   * @param {Object} options Get params Object (params described below)
   * @param {String} options.id ID of the Product to retrieve
   * @param {String} options.orgId ID of the Product's Organization
   *
   * @returns {Promise} Promise that resolves to the instance when the refresh is successful
   */
  static get(options) {
    const {id, orgId} = options;
    if (id === null || id === undefined) {
      const errorMsg = "Product.get(): 'options.id' is a required argument/param";
      log.error(errorMsg);
      throw new Error(errorMsg);
    }
    if (orgId === null || orgId === undefined) {
      const errorMsg = "Product.get(): 'options.orgId' is a required argument/param";
      log.error(errorMsg);
      throw new Error(errorMsg);
    }

    // We ignore FIs for a new fetch, because they won't exist till refresh completes
    const model = new Product({...options, ignoreEmptyFIs: true});
    return model.refresh();
  }

  acceptedResourceLocators;
  endDate;
  errorMessage;
  fulfillableItemList;
  fulfillableItems;
  instanceId;
  isLoading;
  licenseAllocationInfo;
  licenseSelectionOptions;
  licenseTupleList;
  selectedOfferToSwitch;

  /**
   * @description Creates a new Product for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.id A Product's ID
   * @param {Boolean} options.administerable true if Product is administerable, else false
   * @param {String} options.applicableOfferType A product's offer type: BASE, TRIAL
   * @param {Number} options.assignedQuantity DEPRECATED: Use provisionedQuantity instead. A Product's currently assigned quantity.
   * @param {Object} options.assets The product icons. See the 'getIcon' method.
   * @param {Class} [options.classRef] - class to instantiate on refresh
   * @param {String} options.cloud A Product's cloud; CREATIVE, DOCUMENT, or EXPERIENCE
   * @param {String} options.code A Product's code
   * @param {Array<String>} options.contractDisplayNames The displayNames for the contracts providing the product. This is usually
   *        just one, but for DX licenses in particular there may be multiple contracts.
   * @param {String} options.contractId Deprecated. Look at contractIds instead. The ID for the contract providing the product.
   * @param {Array<String>} options.contractIds The IDs for the contract providing the product. This is usually
   *        just one, but for DX licenses in particular there may be multiple contracts.
   * @param {Number} options.delegatedQuantity A Product's delegated quantity. A delegated product has not been provisioned yet.
   * @param {Array<String>} options.delegationTargets A Product's delegation targets. Examples: ['TYPE2', 'TYPE3'] (delegated to users of those types only),
   *        ['ORG_ID'] (delegated to the organization as a whole), etc. ['NA'] indicates the product is not delegated (has no valid delegation targets).
   * @param {String} options.figId The ID for the originating fulfillable item group
   * @param {String} options.fulfillableEntityResourceLocator A Product's fulfillable entity resource locator
   * @param {String} options.fulfillableEntityResourceName A product's fulfillable entity resource name
   * @param {Array} options.groupParameters An array of object that determines what type of
   *        scorecards to be displayed
   * @param {Number} options.groupsQuantity A Product's total license group count
   * @param {Array} options.licenseGroups A Product's nested licenseGroups. These are only returned
   *        when the product is nested within a single user fetch (ie, /organizations/:id/users/:id)
   * @param {Array} options.licenseGroupSummaries A Product's nested licenseGroups, in a condensed data object.
   *        These can be returned by both getProducts and getProduct calls.
   * @param {Array} options.licenseQuantities A Product's license quantities
   * @param {String} options.licenseStatus A Product's license status: ACTIVE, EXPIRED, INACTIVE.
   * @param {Array} options.links A Product's links
   * @param {String} options.longDescription A Product's long description
   * @param {String} options.longName A Product's long name
   * @param {String} options.migrationStatus A Product's migration status; COMPLETE, IN_PROGRESS, or READY
   * @param {String} options.offerId The Id of the offer backing this product
   * @param {String} options.orgId The Id of the org backing this product
   * @param {Object} options.processingInstructions The product processing instructions
   * @param {String} options.productArrangementCode The product arrangement code backing this product
   * @param {Number} options.provisionedQuantity A Product's provisioned quantity
   * @param {Object} options.quantities Quantities related to the product
   * @param {Number} options.quantities.total The total available quantity for this product
   * @param {String} options.shortDescription A Product's short description
   * @param {String} options.shortName A Product's short name
   * @param {String} options.targetExpression A Product's target role expression
   * @param {Array<Object>} options.tuples A Product's license tuples. Tuples have contractId, quantity, complianceSymptoms
   * @param {Boolean} options.ignoreEmptyFIs A value used only during recreation to suppress errors on empty FIs
   */
  constructor(options) {
    makeObservable(this, {
      acceptedResourceLocators: observable,
      endDate: observable,
      errorMessage: observable,
      fulfillableItemList: observable,
      fulfillableItems: observable,
      instanceId: observable,
      isLoading: observable,
      isValidForFulfillmentNeededSubmission: computed,
      isValidForOfferSwitchSubmission: computed,
      licenseAllocationInfo: observable,
      licenseSelectionOptions: observable,
      selectedOfferToSwitch: observable,
      setAcceptedResourceLocators: action,
      setEndDate: action,
      setInstanceId: action,
    });
    initModel(this, options);
    registerSavedState(this);
    this.orgId = options.orgId;
  }

  /**
   * @description Method to determine the count of licenses which may be assigned.
   *
   * @returns {Number}  quantity of licenses for this product that are assignable
   */
  getAssignableLicenseCount() {
    if (feature.isEnabled('enable_rc_license_quantity')) {
      return this.licenseQuantityList.getAssignableQuantity();
    }
    return this.licenseTupleList.getAssignableQuantity();
  }

  /**
   * @description Method to return the number of available (unassigned) licenses for this product
   *
   * @returns {Number} number of available licenses of Product
   */
  getAvailableLicenseCount() {
    if (this.getAssignableLicenseCount() >= this.provisionedQuantity) {
      return this.getAssignableLicenseCount() - this.provisionedQuantity;
    }
    return 0;
  }

  /**
   * @description Method to return the fulfillableItem for the product by fulfillableItem code
   *
   * @param {String} code the fulfillableItem code
   *
   * @returns {FulfillableItem} the fulfillableItem
   */
  getFulfillableItemByCode(code) {
    return this.fulfillableItemList.items.find((item) => item.code === code);
  }

  /**
   * @description Method to return the fulfillableItem's name for the product by fulfillableItem code
   *
   * @param {String} code the fulfillableItem code
   *
   * @returns {String} the name of the fulfillableItem
   */
  getFulfillableItemNameByCode(code) {
    const fulfillableItem = this.getFulfillableItemByCode(code);
    return fulfillableItem && fulfillableItem.longName;
  }

  /**
   * @description Method to get the icon for this product
   *
   * @returns {String} The path to the svg icon. Returns undefined if there is not an icon.
   */
  getIcon() {
    return this.assets?.icons?.svg;
  }

  /**
   * @description Method to get instance metadata for the product. Only DMA products
   *    currently support this.
   *
   * @returns {Promise} resolving the product metdata
   */
  async getMetadata() {
    if (has(this, 'metadata')) {
      return this.metadata;
    }
    if (this.isMarketingCloudProduct()) {
      const response = await jilProductArrangements.getMetadata({
        orgId: this.orgId,
        productArrangementCode: this.productArrangementCode,
        productId: this.id,
      });
      this.metadata = get(response, 'metadata', []);
      return this.metadata;
    }
    this.metadata = [];
    return this.metadata;

    ///////////////
  }

  /**
   * @description Method to determine the count of seat based delegated licenses which may be assigned.
   *
   * @returns {Number}  quantity of licenses for this product that are assignable
   */
  getSeatBasedAssignableLicenseCount() {
    if (!this.usesSeatBasedDelegation()) {
      return 0;
    }
    return this.getAssignableLicenseCount();
  }

  /**
   * @description Method to determine the count of seat based provisioned licenses.
   *
   * @returns {Number}  quantity of licenses for this product that are provisioned
   */
  getSeatBasedTotalProvisionedQuantity() {
    if (!this.usesSeatBasedDelegation()) {
      return 0;
    }
    return this.provisionedQuantity;
  }

  getTotalActivationCount() {
    return get(this, 'licenseActivation.totalActivationCount', 0);
  }

  /**
   * @description This ensures compatibility between new pandora components that use @pandora/data-model-product
   * this function is missing from the binky implementation of Product.
   * Method to return the number of undelegated licenses for this product.
   * There is one race-condition edge case where the auto-delegated user has been delegated but not yet provisioned.
   * In this case, we should use delegated count instead of provisioned count to determine
   * how many licenses are available for assignment.
   *
   * @returns {Number} number of undelegated licenses of Product
   */
  getUndelegatedLicenseCount() {
    if (this.getAssignableLicenseCount() >= this.delegatedQuantity) {
      return this.getAssignableLicenseCount() - this.delegatedQuantity;
    }
    return 0;
  }

  /**
   * @param {String} type the required configuration setting type
   * @param {Boolean} enforceFlag whether to enforce a flag, if one is required
   * @returns {Boolean} return true if the product has the specified config setting and any
   *   required flag is enabled.
   */
  hasConfigurationSetting(type, enforceFlag = true) {
    return (
      this.configurationSettings &&
      this.configurationSettings.some(
        (setting) =>
          setting.type === type &&
          (!enforceFlag || isEmpty(setting.requiresFlag) || feature.isEnabled(setting.requiresFlag))
      )
    );
  }

  /**
   * @returns {Boolean} return true if the product has the LEGACY_PERMISSIONS config setting. This
   *   setting does not require flags.
   */
  hasConfigurationSettingForLegacyPermissions() {
    return this.hasConfigurationSetting(PRODUCT_CONFIGURATION_SETTING_TYPE.LEGACY_PERMISSIONS);
  }

  /**
   * @param {Boolean} enforceFlag whether to enforce a flag, if one is required
   * @returns {Boolean} return true if the product has the LICENSE_GROUP config setting and
   *   any required flag is enabled.
   */
  hasConfigurationSettingForLicenseGroup(enforceFlag) {
    return this.hasConfigurationSetting(
      PRODUCT_CONFIGURATION_SETTING_TYPE.LICENSE_GROUP,
      enforceFlag
    );
  }

  /**
   * @param {Boolean} enforceFlag whether to enforce a flag, if one is required
   * @returns {Boolean} return true if the product has the LICENSE_GROUP_MEMBER config setting and
   *   any required flag is enabled.
   */
  hasConfigurationSettingForLicenseGroupMember(enforceFlag) {
    return this.hasConfigurationSetting(
      PRODUCT_CONFIGURATION_SETTING_TYPE.LICENSE_GROUP_MEMBER,
      enforceFlag
    );
  }

  /**
   *
   * @description Method to determine if the product comes from the provided contract id.
   *   Products may come from multiple different contracts.
   *
   * @param {String} contractId - the contract id
   * @returns {Boolean} true if the product comes from the contract
   */
  hasContractId(contractId) {
    return this.contractIds?.includes(contractId);
  }

  /**
   * @description Method to determine if this Product is the license-based variant of Sign.
   *   This variant has a 'total_sign_transactions' QUOTA FI with delegation type PERSON and
   *   a charging model unit of LICENSE.
   *
   * @returns {Boolean} true if the license-based Sign, else false
   */
  hasLicenseBasedSign() {
    return (
      this.buyingProgram !== PRODUCT_BUYING_PROGRAM.ETLA &&
      this.family === PRODUCT_FAMILY.SIGN &&
      this.fulfillableItemList.hasSignTransactions(FULFILLABLE_ITEM_CHARGING_UNIT.LICENSE)
    );
  }

  /**
   * @description Method to determine whether the product has no available licenses.
   *    Enterprise Direct does not require pre-purchased licenses to add more.
   *
   * @returns {Boolean} true if this Product has no available licenses, else false
   */
  hasNoAvailableLicenses() {
    return (
      this.getAvailableLicenseCount() <= 0 && !this.fulfillableItemList.hasOverdelegationAllowed()
    );
  }

  /**
   * @description Function to determine whether the product been delegated more
   *    licenses than the number of licenses it can provision.
   *
   * @returns {Boolean} true if this Product has over delegated licenses, else false
   */
  hasOverDelegatedLicenses() {
    return this.hasNoAvailableLicenses() && this.delegatedQuantity > this.provisionedQuantity;
  }

  /**
   * @description Method to check if the product has the scorecard type.
   *
   * @param {PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE} type - scorecard type
   *
   * @returns {Boolean} return true if groupParameters has 'scorecards' with
   *    the value type and type is a PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE
   */
  hasScorecardType(type) {
    return (
      includes(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE, type) &&
      !!this.groupParameters &&
      this.groupParameters
        .filter((param) => param.name === 'scorecards')
        .some((param) => includes(param.value, type))
    );
  }

  /**
   * @description Method to check if the product has the target qualifier.
   *
   * @param {String} target - Target qualifier to search for.
   *
   * @returns {Boolean} true if the qualifier is found, false otherwise.
   */
  hasTargetQualifier(target) {
    return this.qualifiers && this.qualifiers.some((qualifier) => qualifier.target === target);
  }

  /**
   * @description Method to determine if the product has unlimited licenses.
   *
   * @returns {Boolean} true if the product has unlimited licenses, false otherwise.
   */
  hasUnlimitedLicenses() {
    if (feature.isEnabled('enable_rc_license_quantity')) {
      return this.licenseQuantityList.hasUnlimitedLicenses();
    }
    return this.licenseTupleList.hasOnlyUnlimitedLicenses();
  }

  /**
   * @description Method to determine if there are any unsaved changes to this object.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    const savedBaseState = pick(this.savedState, EDITABLE_FIELDS);
    if (feature.isEnabled('enable_rc_license_quantity')) {
      return (
        !isEqual(savedBaseState, pick(this, EDITABLE_FIELDS)) ||
        this.fulfillableItemList.hasUnsavedChanges() ||
        this.licenseQuantityList.hasUnsavedChanges() ||
        this.licenseAllocationInfo.licenseResourceList.hasUnsavedChanges()
      );
    }
    return (
      !isEqual(savedBaseState, pick(this, EDITABLE_FIELDS)) ||
      this.fulfillableItemList.hasUnsavedChanges() ||
      this.licenseTupleList.hasUnsavedChanges() ||
      this.licenseAllocationInfo.licenseResourceList.hasUnsavedChanges()
    );
  }

  /**
   * @description Method to determine if a Product has valid delegation targets.  Most products have a combination of
   *  delegation targets like "TYPE1", "TYPE2", "ORG_ID", etc.
   *
   * @returns {Boolean} true unless product is not delegated (['NA']).
   */
  hasValidDelegationTarget() {
    return !isEqual(this.delegationTargets, [PRODUCT_DELEGATION_TARGET.NA]);
  }

  /**
   * @description Method to determine if this Product includes the higher tiers of Adobe Sign.
   *
   * @returns {Boolean} true if this Product contains business or enterprise Sign, else false
   */
  includesBusinessOrEnterpriseSign() {
    return ['APAP', 'ECHG', 'ECHE', 'ECHP', 'ESAP'].includes(this.code);
  }

  /**
   * @description Method to determine if this Product can be administered. A product must have a valid delegation target and
   * have a supported product channel to be administrable.
   *
   * @returns {Boolean} true if this Product is administrable, else false.
   */
  isAdministerable() {
    return (
      this.administerable === true &&
      this.hasValidDelegationTarget() &&
      this.customerUI &&
      (this.customerUI.includes(PRODUCT_CHANNELS.PRODUCTS) ||
        this.customerUI.includes(PRODUCT_CHANNELS.LEGACY_AAC) ||
        this.customerUI.includes(PRODUCT_CHANNELS.LEGACY_ENTD) ||
        this.customerUI.includes(PRODUCT_CHANNELS.LEGACY_TEAM_DIRECT) ||
        this.customerUI.includes(PRODUCT_CHANNELS.LEGACY_ENTD_PRODUCTS))
    );
  }

  /**
   * @description Method to determine if this Product is Adobe Stock.
   *
   * Caveats:
   *  Enterprise offer CESK with family CC_PRO should not be considered a 'Stock' offer.
   *
   * @returns {Boolean} true if this Product is Adobe Stock, else false
   */
  isAdobeStock() {
    const PRODUCT_ADOBE_STOCK_CODE = ['CTSK', 'STKS', 'STKM', 'STKL', 'STEL'];
    return (
      (this.code === 'CESK' && this.family === PRODUCT_FAMILY.STOCK_ASSETS) || // exclude 'CESK' with family CC_PRO
      PRODUCT_ADOBE_STOCK_CODE.includes(this.code)
    );
  }

  /**
   * @description Method to determine if this Product's buying program is 'ETLA'
   *
   * @returns {Boolean} true if buying program is 'ETLA'
   */
  isBuyingProgramETLA() {
    return this.buyingProgram === PRODUCT_BUYING_PROGRAM.ETLA;
  }

  /**
   * @description Method to determine if this Product's buying program is 'VIP'
   *
   * @returns {Boolean} true if buying program is 'VIP'
   */
  isBuyingProgramVIP() {
    return this.buyingProgram === PRODUCT_BUYING_PROGRAM.VIP;
  }

  /**
   * @description Method to determine if this Product's buying program is 'VIPMP'
   *
   * @returns {Boolean} true if buying program is 'VIPMP'
   */
  isBuyingProgramVIPMP() {
    return this.buyingProgram === PRODUCT_BUYING_PROGRAM.VIPMP;
  }

  /**
   * @description Method to determine if this Product is a "consumable" (either group or an org consumable) and
   *      should look for usage information via the ConsumableSummarizationList.
   *
   * @returns {Boolean} true if it's a consumable product, else false
   */
  isConsumable() {
    return isConsumable({
      buyingProgram: this.buyingProgram,
      family: this.family,
      fulfillableItemList: this.fulfillableItemList,
    });
  }

  /**
   * @description Method to determine if this Product is a Creative Cloud
   *              product.
   *
   * @returns {Boolean} true if this Product is a Creative Cloud product, else
   *                    false
   */
  isCreativeCloudProduct() {
    return this.cloud === CLOUD.CREATIVE;
  }

  /**
   * @description Method to determine if this Product can be delegated to the specified target
   *
   * @param {PRODUCT_DELEGATION_TARGET} target - the target type to check. One of API_KEY, MACHINE_ID,
   *   ORG_ID, THIRD_PARTY, TYPE1, TYPE2, TYPE2E, or TYPE3.
   * @param {Boolean} exclusive - when true, this ensures no other types are supported. Default: false
   *
   * @returns {Boolean} true if the product can be delegated to the specified target
   */
  isDelegatableToType(target, exclusive = false) {
    return (
      !!this.delegationTargets &&
      this.delegationTargets.includes(target) &&
      (!exclusive || this.delegationTargets.length === 1)
    );
  }

  /**
   * @description Method to determine if this Product can be delegated to individual users.
   *
   * @returns {Boolean} true if the product can be delegated to individual users.
   */
  isDelegatableToUser() {
    if (feature.isEnabled('temp_onesie_35741')) {
      return (
        this.licenseTupleList.isDelegatable() &&
        !!this.delegationTargets &&
        PRODUCT_DELEGATION_USER_TARGETS.some((target) => this.delegationTargets.includes(target))
      );
    }
    return (
      !!this.delegationTargets &&
      PRODUCT_DELEGATION_USER_TARGETS.some((target) => this.delegationTargets.includes(target))
    );
  }

  /**
   * @description Method to determine if this Product is a device license product (SDL or legacy).
   *
   * @returns {Boolean} true if it's a device license product, else false
   */
  isDeviceLicense() {
    return this.isDelegatableToType(PRODUCT_DELEGATION_TARGET.MACHINE_ID);
  }

  /**
   * @description Method to determine if this Product is a Document Cloud
   *              product.
   *
   * @returns {Boolean} true if this Product is a Docuent Cloud product, else
   *                    false
   */
  isDocumentCloudProduct() {
    return this.cloud === CLOUD.DOCUMENT;
  }

  /**
   * @description Method to determine if this Product is an enterprise product.
   *
   * @returns {Boolean} true if an enterprise product, else false
   */
  isEnterprise() {
    return this.customerSegment === CUSTOMER_SEGMENT.ENTERPRISE;
  }

  /**
   * @description Method to determine if this Product is a Direct Enterprise product.
   *
   * @returns {Boolean} true if a direct enterprise product, else false
   */
  isEnterpriseDirect() {
    return this.isEnterprise() && this.salesChannel === SALES_CHANNEL.DIRECT;
  }

  /**
   * @description Method to determine if this Product is an Indirect Enterprise product (EVIP).
   *
   * @returns {Boolean} true if an indirect enterprise product, else false
   */
  isEnterpriseIndirect() {
    return this.isEnterprise() && this.salesChannel === SALES_CHANNEL.INDIRECT;
  }

  /**
   * @description Method to determine if this Product is a feature restricted license product.
   *
   * @returns {Boolean} true if it's feature restricted license product, else false
   */
  isFeatureRestrictedLicense() {
    return (
      this.fulfillableItemList.hasPackagePreconditioning() &&
      !this.fulfillableItemList.hasLaboratoryLicenseManagement()
    );
  }

  /**
   * @description Method to determine if this Product is a group "consumable" (not organization consumable) and
   *      should look for usage information via the ConsumableSummarizationList.
   *
   * @returns {Boolean} true if it's a group consumable product, else false
   */
  isGroupConsumable() {
    if (feature.isEnabled('enable_rc_license_quantity')) {
      return (
        !this.licenseQuantityList.hasUnlimitedLicenses() &&
        isGroupConsumable({
          buyingProgram: this.buyingProgram,
          family: this.family,
          fulfillableItemList: this.fulfillableItemList,
        })
      );
    }
    return (
      !this.hasUnlimitedLicenses() &&
      isGroupConsumable({
        buyingProgram: this.buyingProgram,
        family: this.family,
        fulfillableItemList: this.fulfillableItemList,
      })
    );
  }

  /**
   * @description Method to determine if this Product is a legacy device license product.
   *
   * @returns {Boolean} true if it's a legacy device license product, else false
   */
  isLegacyDeviceLicense() {
    return (
      this.isDeviceLicense() &&
      !this.fulfillableItemList.hasLaboratoryLicenseManagement() &&
      !this.fulfillableItemList.hasPackagePreconditioning()
    );
  }

  /**
   * @description Method to determine if product is legacy stock
   *
   * @returns true if product is legacy stock product
   */
  isLegacyStock() {
    return this.isEnterprise() && this.isAdobeStock();
  }

  /**
   * @description Method to determine if this product's license is active.
   *
   * @returns {Boolean} true if this Product's license status is ACTIVE
   */
  isLicenseStatusActive() {
    return this.licenseStatus === LICENSE_STATUS.ACTIVE;
  }

  /**
   * @description Method to determine if this product's license is expired.
   *
   * @returns {Boolean} true if this Product's license status is EXPIRED
   */
  isLicenseStatusExpired() {
    return this.licenseStatus === LICENSE_STATUS.EXPIRED;
  }

  /**
   * @description Method to determine if this Product is a Marketing Cloud
   *              product.
   *
   * @returns {Boolean} true if this Product is a Marketing Cloud product,
   *                    else false
   */
  isMarketingCloudProduct() {
    return this.cloud === CLOUD.EXPERIENCE;
  }

  /**
   * @description Method to determine if this Product's migration is complete.
   *
   * @returns {Boolean} true if this Product's migration is complete, else
   *                    false
   */
  isMigrationComplete() {
    const solutionGroupProducts = ['DMA_ADLENS', 'DMA_AUDIENCEMANAGER', 'DMA_BULLSEYE', 'DMA_DTM'];
    if (!solutionGroupProducts.includes(this.code.toUpperCase())) {
      // we force the switch from solution groups to permissions for the products
      // we have not detected using them over the last six months
      return true;
    }
    return this.migrationStatus === MIGRATION_COMPLETE;
  }

  /**
   * @description Method to determine if this Product is in migration.
   *
   * @returns {Boolean} true if this Product is in migration, else false
   */
  isMigrationInProgress() {
    return this.migrationStatus === MIGRATION_IN_PROGRESS;
  }

  /**
   * @description Method to determine if this Product is migration ready.
   *
   * @returns {Boolean} true if this Product is migration ready, else false
   */
  isMigrationReady() {
    return this.migrationStatus === MIGRATION_READY;
  }

  /**
   * @description Method to determine if this Product is a new one or not.
   *
   * @returns {Boolean} true if a new Product, else false
   */
  isNew() {
    return this.id === undefined || this.id === null;
  }

  /**
   * @description Method to determine if this Product is an organization "consumable" and
   *      should look for usage information via the ConsumableSummarizationList.
   *
   * @returns {Boolean} true if it's an organization consumable product, else false
   */
  isOrganizationConsumable() {
    return isOrganizationConsumable(this.fulfillableItemList);
  }

  /**
   * @description Method to determine if this Product is an organization delegatable unlimited product.
   * For example, Substance 3D - Unlimited Assets.
   *
   * @returns {Boolean} true if this Product is an organization delegatable unlimited product, else false
   */
  isOrgDelegatableUnlimited() {
    return (
      !!this.fulfillableItemList.getOrgDelegatableUnlimitedItem() &&
      this.isDelegatableToType(PRODUCT_DELEGATION_TARGET.ORG_ID)
    );
  }

  /**
   * @description Method to determine if this Product is in an "other" cloud.
   *              Notably, e-learning products are in cloud "OTHERS".
   *
   * @returns {Boolean} true if this Product is in an "other" cloud,
   *                    else false
   */
  isOtherCloudProduct() {
    return this.cloud === CLOUD.OTHERS;
  }

  /**
   * @description Method to determine whether the product has overuse set to 'customer_controllable'
   *
   * @returns {Boolean} true if this Product has overuse set to 'customer_controllable', else false
   */
  isOveruseCustomerControllable() {
    return this.processingInstructions.isOveruseCustomerControllable();
  }

  /**
   * @description Method to determine if this Product has a free price point.
   *
   * @returns {Boolean} true if a free, else false
   */
  isPricePointFree() {
    return this.pricePoint === PRICE_POINT.FREE;
  }

  /**
   * @description Method to determine if this Product is eligible for product-specific support.
   *
   * @returns {Boolean} true if product is eligible for product-specific support, else false
   */
  isProductSupportRoleAssignmentAllowed() {
    return this.fulfillableItemList.hasSupportAllowedAdmins();
  }

  /**
   * @description Method to determine if this Product has scorecards defined in its groupParameters.
   *
   * @returns {Boolean} return true if groupParameters has defined scorecards logic
   */
  isScorecardsDefined() {
    return (
      !!this.groupParameters && this.groupParameters.some((param) => param.name === 'scorecards')
    );
  }

  /**
   * @description Method to determine if this Product is a shared device license product (SDL).
   *
   * @returns {Boolean} true if it's a shared device license product, else false
   */
  isSharedDeviceLicense() {
    return this.fulfillableItemList.hasLaboratoryLicenseManagement();
  }

  /**
   * @description Method to determine if this Product is a storage only product.
   *
   * @returns {Boolean} true if a storage only product, else false
   */
  isStorageOnly() {
    return this.family === PRODUCT_FAMILY.CCM_STORAGE;
  }

  /**
   * @description Method to determine if this Product is a team product.
   *
   * @returns {Boolean} true if a team product, else false
   */
  isTeam() {
    return this.customerSegment === CUSTOMER_SEGMENT.TEAM;
  }

  /**
   * @description Method to determine if this Product is Team Direct.
   *
   * @returns {Boolean} true if a direct team product, else false
   */
  isTeamDirect() {
    return this.isTeam() && this.salesChannel === SALES_CHANNEL.DIRECT;
  }

  /**
   * @description Method to determine if this Product is Team Indirect.
   *
   * @returns {Boolean} true if a Team Indirect product, else false
   */
  isTeamIndirect() {
    return this.isTeam() && this.salesChannel === SALES_CHANNEL.INDIRECT;
  }

  /**
   * @description Method to determine if this Product has assignable licenses and none are assigned.
   *
   * @returns {Boolean} true if this Product has assignable licenses and none are assigned.
   */
  isUnassignedProduct() {
    let quantity = this.assignedQuantity;

    if (this.isFeatureRestrictedLicense()) {
      quantity = this.getTotalActivationCount();
    } else {
      quantity = this.provisionedQuantity;
    }

    if (feature.isEnabled('enable_rc_license_quantity')) {
      return (
        !this.fulfillableItemList.hasLaboratoryLicenseManagement() &&
        quantity === 0 &&
        (this.licenseQuantityList.hasUnlimitedLicenses() || this.getAssignableLicenseCount() > 0)
      );
    }

    return (
      !this.fulfillableItemList.hasLaboratoryLicenseManagement() &&
      quantity === 0 &&
      (this.hasUnlimitedLicenses() || this.getAssignableLicenseCount() > 0)
    );
  }

  /**
   * @description Computed getter for checking if the product's submittable
   *              properties are valid for fulfillment needed submission.
   * @returns {Boolean} True if the product is valid, false otherwise.
   */
  get isValidForFulfillmentNeededSubmission() {
    return isProductValid(this, this.licenseSelectionOptions);
  }

  /**
   * @description Computed getter for checking if the product's submittable
   *              properties are valid for offer switch submission.
   * @returns {Boolean} True if the product is valid, false otherwise.
   */
  get isValidForOfferSwitchSubmission() {
    return isProductValidForOfferSwitch(this.selectedOfferToSwitch);
  }

  /**
   * @description Method to refresh an existing Product with latest data from
   *              back-end data store.
   *
   * @returns {Promise} Resolves with refreshed model if successful, else
   *                    rejects with error message
   */
  async refresh() {
    this.isLoading = true;
    this.errorMessage = undefined;

    try {
      const response = await jilProducts.getProducts(
        omitBy({orgId: this.orgId, productId: this.id}, (field) => field === undefined)
      );
      initModel(this, response.data);
    } catch (error) {
      log.error('Product.refresh(): Unable to retrieve model from back-end. Error: ', error);
      runInAction(() => {
        this.errorMessage = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return this;
  }

  /**
   * @description Restores the product from its saved state
   */
  restore() {
    Object.assign(this, pick(this.savedState, EDITABLE_FIELDS));
    this.fulfillableItemList.restore();
    this.fulfillableItems.restore();
    if (feature.isEnabled('enable_rc_license_quantity')) {
      this.licenseQuantityList.restore();
    } else {
      this.licenseTupleList.restore();
    }
    this.licenseAllocationInfo.licenseResourceList.restore();
  }

  /**
   * @description Setter for updating the accepted resource locators for this
   *     product.
   * @param {String[]} values - Array of accepted resource locators to assign to
   *     this product.
   */
  setAcceptedResourceLocators(values) {
    this.acceptedResourceLocators = values;
  }

  /**
   * @description Setter for updating the end date for this product.
   * @param {String} value - the new end date to assign to the product.
   */
  setEndDate(value) {
    this.endDate = value;
  }

  /**
   * @description Setter to assign the external request id for a product.
   *
   * @param {String} externalRequestId - External request id to assign to the product.
   */
  setExternalRequestId(externalRequestId) {
    this.externalRequestId = externalRequestId;
  }

  /**
   * @description Setter for updating the instance id for this product.
   * @param {String} value - the new instance id to assign to the product.
   */
  setInstanceId(value) {
    this.instanceId = value;
  }

  /**
   * @description Convert the product model to an object representing a fulfillment needed item
   *    to be used when sending a fulfillment needed message.
   *
   * @param {Object} options object wrapping the params that should be provided in each
   *    fulfillment needed item.
   * @param {Object} options.acceptedTerms accepted terms that will be applied to the item.
   * @param {String} options.externalItemId serves as the publisher's line item identifier.
   *
   * @returns {Object} object equivalent of a fulfillment needed message item.
   */
  toFulfillmentNeededItem(options) {
    const {acceptedTerms, externalItemId} = options;

    let quantity;
    if (feature.isEnabled('enable_rc_license_quantity')) {
      quantity = this.licenseQuantityList.hasUnlimitedLicenses()
        ? LICENSE_QUANTITY_UNLIMITED
        : this.licenseQuantityList.sumWhen(stubTrue); // Either going to be a sum of all license quantities or UNLIMITED
    } else {
      // Either going to be a sum of all license quantities or UNLIMITED
      quantity = this.hasUnlimitedLicenses()
        ? LICENSE_TUPLE_QUANTITY_UNLIMITED
        : this.licenseTupleList.getTotalQuantity();
    }
    const minimumModelFIs = this.fulfillableItemList.toMinimumModel();

    const fulfillmentNeededItem = {
      acceptedTerms,
      externalItemId,
      fulfillableItems: minimumModelFIs
        .filter((item) => item.selected || item.selected === null || item.selected === undefined)
        .map((item) => {
          const {selected, ...omittedItem} = item;
          return omittedItem;
        }),
      offerId: this.offerId,
      omittedFulfillableItems: minimumModelFIs
        .filter((item) => item.selected === false)
        .map((item) => {
          const {selected, ...omittedItem} = item;
          return omittedItem;
        }),
      quantity,
    };
    return fulfillmentNeededItem;
  }

  /**
   * @description Method to transform model into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @returns {Object} minimum necessary representation of model
   */
  toMinimumModel() {
    return {
      id: this.id,
    };
  }

  /**
   * @description Method to transform model into the smallest representation
   *    which can be used for create/update product calls to the server.
   *
   * @returns {Object} minimum necessary representation of model
   */
  toMinimumModelForSave() {
    const isNotNew = !this.isNew();
    const model = pick(this, EDITABLE_FIELDS);
    // we only send configurable items when modifying an existing fulfillment
    model.fulfillableItems = this.fulfillableItemList.toMinimumModel({
      filterToFulfillmentConfigurable: isNotNew,
      filterToModified: isNotNew,
    });
    if (feature.isEnabled('enable_rc_license_quantity')) {
      model.licenseQuantities = this.licenseQuantityList.toMinimumModel({
        filterToModified: isNotNew,
      });
    } else {
      model.tuples = this.licenseTupleList.toMinimumModel({filterToModified: isNotNew});
    }
    return model;
  }

  /**
   * @description record the current editable fields of the product as a
   *  local state
   *
   * Note: This method is only intended for use by AAUI for updating a
   *  product's savedState after an update fulfillment needed message was sent.
   */
  updateSavedState() {
    registerSavedState(this);
  }

  /**
   * @description Method to determine if this Product uses seat based delegation.
   *
   * @returns {Boolean} true if this Product uses seat based delegation, else false
   */
  usesSeatBasedDelegation() {
    if (feature.isEnabled('enable_rc_license_quantity')) {
      return (
        usesSeatBasedDelegation({
          buyingProgram: this.buyingProgram,
          family: this.family,
          fulfillableItemList: this.fulfillableItemList,
          productArrangementCode: this.productArrangementCode,
        }) &&
        !this.licenseQuantityList.hasUnlimitedLicenses() &&
        !this.isLegacyDeviceLicense()
      );
    }
    return (
      usesSeatBasedDelegation({
        buyingProgram: this.buyingProgram,
        family: this.family,
        fulfillableItemList: this.fulfillableItemList,
        productArrangementCode: this.productArrangementCode,
      }) &&
      !this.hasUnlimitedLicenses() &&
      !this.isLegacyDeviceLicense()
    );
  }
}

/** Private Methods **/

// eslint-disable-next-line complexity -- over max complexity
function initModel(model, options) {
  const updatedModel = model;

  // We do this here because we've seen SLS return this empty, and need to log the license ID
  if (
    // We allow it to be suppressed when we're creating provisional objects
    !options.ignoreEmptyFIs &&
    (!options.fulfillableItems || options.fulfillableItems.length === 0)
  ) {
    log.error(`Product returned with no FIs: ${options.id}`);
  }

  // First we assign the model fields
  Object.assign(
    updatedModel,
    {
      fulfillableItemList: new FulfillableItemList(cloneDeep(options.fulfillableItems)),
      fulfillableItems: new FulfillableItemList(cloneDeep(options.fulfillableItems)),
      licenseAllocationInfo: new LicenseAllocationInfo(options.licenseAllocationInfo),
      licenseQuantityList: feature.isEnabled('enable_rc_license_quantity')
        ? new LicenseQuantityList(cloneDeep(options.licenseQuantities))
        : undefined,
      licenseTupleList: new LicenseTupleList(cloneDeep(options.tuples)),
      processingInstructions: new ProcessingInstructions(cloneDeep(options.processingInstructions)),
    },
    flow(
      (_options) =>
        pick(_options, [
          'adminCount',
          'administerable',
          'applicableOfferType',
          'assets',
          'assignedQuantity',
          'buyingProgram',
          'cloud',
          'cancellation',
          'childCreationAllowed',
          'code',
          'configurationSettings',
          'contractId',
          'contractIds',
          'contractDisplayNames',
          'createdDate',
          'customerSegment',
          'customerUI',
          'delegatedQuantity',
          'delegationTargets',
          'family',
          'figId',
          'fulfillableEntityResourceLocator',
          'fulfillableEntityResourceName',
          'groupParameters',
          'groupsQuantity',
          'icons',
          'id',
          'isOverDeploymentAllowed',
          'licenseActivation',
          'licenseConfigurable',
          'licenseGroups',
          'licenseGroupSummaries',
          'licenseStatus',
          'licenseType',
          'links',
          'longDescription',
          'longName',
          'migrationStatus',
          'offerId',
          'pricePoint',
          'pricing',
          'productArrangementCode',
          'provisionedQuantity',
          'provisioningStatus',
          'qualifiers',
          'requestorExternalInfo',
          'salesChannel',
          'shortDescription',
          'shortName',
          'targetExpression',
        ]),
      // we clone to avoid issues when updating the nested object items
      cloneDeep
    )(options)
  );

  // Device licenses report an assigned quantity, delegated quantity, and provisioned quantity
  // of 0, which is pulling from the wrong source
  if (updatedModel.isLegacyDeviceLicense()) {
    delete updatedModel.assignedQuantity;
    delete updatedModel.delegatedQuantity;
    delete updatedModel.provisionedQuantity;
  }
  let paidPendingGracePastDueLicenseCount;
  if (feature.isEnabled('enable_rc_license_quantity')) {
    paidPendingGracePastDueLicenseCount = updatedModel.licenseQuantityList.hasUnlimitedLicenses()
      ? 1
      : updatedModel.licenseQuantityList.getPaidOrPendingOrGracePastDueQuantity();
  } else {
    // we iterate across the quota items and cache a paid/pending/grace count
    // unlimited licenses essentially mean we use the cap directly (a 1 multiplier)
    paidPendingGracePastDueLicenseCount = updatedModel.hasUnlimitedLicenses()
      ? 1
      : updatedModel.licenseTupleList.getAssignableQuantity();
  }
  updatedModel.fulfillableItemList
    .getConsumableQuotaTypeItems({
      requireDelegationConfigurable: false,
    })
    .forEach((item) => {
      // if isUnlimitedCap, this will be NaN
      Object.assign(item, {
        calculatedPaidOrPendingOrGracePastDueCount:
          get(item, 'chargingModel.cap', 0) * paidPendingGracePastDueLicenseCount,
        isUnlimitedCap:
          get(item, 'chargingModel.cap') === FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
      });
    });

  if (
    feature.isDisabled('temp_self_cancel') &&
    feature.isDisabled('temp_self_cancel_trial_with_payment')
  ) {
    // Remove if present due to initial assignment
    delete updatedModel.cancellation;
  }
}

// We register the cache size for this class

/**
 * @description Updates model with a nested form of itself recording state
 *     which may be later modified.
 *
 * @param {Product} model - product model to save state on
 */
function registerSavedState(model) {
  if (model.isNew()) {
    Object.assign(model, {
      savedState: {},
    });
  } else {
    const editableFields = pick(model, EDITABLE_FIELDS);
    Object.assign(model, {
      savedState: cloneDeep(editableFields),
    });
    model.fulfillableItemList.registerSavedState();
    model.fulfillableItems.registerSavedState();
    if (feature.isEnabled('enable_rc_license_quantity')) {
      model.licenseQuantityList.registerSavedState();
    } else {
      model.licenseTupleList.registerSavedState();
    }
    model.licenseAllocationInfo.licenseResourceList.registerSavedState();
  }
}

export default Product;
/* eslint-enable max-lines -- Legacy code */
