import 'cross-fetch';
import {AuthenticatedUser, authentication} from '@admin-tribe/binky';
import {AuthProvider} from '@pandora/react-auth-provider';
import {EnvProvider} from '@pandora/react-env-provider';
import {FetchProvider} from '@pandora/react-fetch-provider';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A wrapper of providers to set up the app's API infrastructure.
 */
const ApiProvider = ({children}) => {
  const authData = {
    getAccessToken: () => authentication.getAccessToken?.()?.token,
    getUserProfile: () => AuthenticatedUser.get().profile,
  };

  const customFetch = (path, options) => {
    const mergedOptions = {
      ...options,
      headers: {
        'X-Api-Key': authentication.getClientId?.(),
        ...options.headers,
      },
    };

    // By default the built-in window fetch will be used. For browsers that do not support this,
    // cross-fetch fetch will be used as a polyfill. Polyfills are defined by not explicitly
    // importing the default like seen above (import 'cross-fetch';). We can not use Pandora fetch
    // due to it using cross-fetch as a ponyfill rather than a polyfill, which we are not able to
    // correctly mock for our fakes environment.
    return fetch(path, mergedOptions);
  };

  return (
    <EnvProvider value={authentication.getAppEnv?.()}>
      <AuthProvider value={authData}>
        <FetchProvider value={customFetch}>{children}</FetchProvider>
      </AuthProvider>
    </EnvProvider>
  );
};
ApiProvider.propTypes = {
  /**
   * The child components to render inside the wrapper
   */
  children: PropTypes.node.isRequired,
};

export default ApiProvider;
