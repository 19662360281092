import pick from 'lodash/pick';

/**
 * @description This class represents an error
 */
class ErrorDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {String} [options.clientErrorName] Client error name/detail
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['clientErrorName']));
  }
}

export default ErrorDescriptor;
