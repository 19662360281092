(function () {
  'use strict';
  angular.module('binky.widgets.common.drawer-header.drawer-header-icon', [
    'binky.core.lodash',
    'binky.widgets.common.avatar',
    'binky.widgets.common.drawer-header.drawer-header-title',
    'binky.widgets.common.feature',
    'ngSanitize',
  ]);
})();
