(function () {
  /**
   * @deprecated Please use src2/core/models/offers/Offer.js
   *
   * @ngdoc factory
   * @name Offer
   * @description Model for an individual Offer
   */
  angular.module('binky.core.offers').factory('Offer', getOfferModel);

  /* @ngInject */
  function getOfferModel(binkySrc2) {
    return binkySrc2.models.offers.Offer;
  }
})();
