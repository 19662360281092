(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.trusts:Trust
   * @description model to fetch/update a trust.
   */
  angular.module('binky.core.trusts').factory('Trust', getTrustModel);

  /* @ngInject */
  function getTrustModel(
    $log,
    $q,
    $rootScope,
    _,
    jilTrusts,
    modelCache,
    TRUST_CACHE_ID,
    TRUST_EVENT
  ) {
    class Trust {
      /**
       * @description instantiate a Trust object.
       * @param {Object} options - options to config the new Trust.
       * @param {String} options.directoryId - the directoryId that the trust is requesting to.
       * @param {String} options.directoryName - the directoryName that the trust is requesting to.
       * @param {String} options.directoryType - the directoryType that the trust is requesting to.
       * @param {Date} options.initialRequestDate - the first request date of the trust.
       * @param {Date} options.lastRequestDate - the last request date of the trust.
       * @param {Boolean} options.notifyAdmins - If set true, the system admin of the owning org will be notified when new users are added to the trustee org.
       * @param {String} options.status - the status of the trust.
       * @param {String} options.trusteeOrgId - the id of the trustee org who sent out the trust request.
       * @param {String} options.trusteeOrgName - the name of the trustee org who sent out the trust request.
       * @param {String} options.trusteeOrgSysAdminEmail - the email of the trustee org admin who sent out the trust request.
       * @param {String} options.trusteeOrgSysAdminId - the id of the admin of the trustee org who sent out the trust request.
       * @param {String} options.owningOrgId - the id of the owning org that received the trust request.
       * @param {String} options.owningOrgName - the name of the owning org that received the trust request.
       * @param {String} options.owningOrgSysAdminEmail - the email of the owning org that received the trust request.
       * @param {String} options.owningOrgSysAdminId - the id of the owning org that received the trust request.
       */
      constructor(options = {}) {
        applyResource(this, options);
        // Since directoryId may not be unique, construct a unique id.
        this.id = `${options.directoryId}-${options.trusteeOrgId}`;
        // Work around until https://git.corp.adobe.com/admin-tribe/onesie/issues/4683 is resolved.
        // Use the same default as JIL: /jil/organizations/services/DirectoriesService.java#L81
        this.directoryName = this.directoryName || `${this.directoryId} directory`;
      }

      key() {
        return this.id;
      }

      refresh() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;
        this.$resolved = false;

        jilTrusts.trusts.get(
          {directoryId: this.directoryId},
          onSuccess.bind(this),
          onError.bind(this)
        );

        return this.$promise;

        function onError(error) {
          this.$resolved = true;
          deferred.reject(error);
        }

        function onSuccess(response) {
          applyResource(this, response);
          this.$resolved = true;
          modelCache.put(TRUST_CACHE_ID, this, this.key());
          deferred.resolve(this);
        }
      }

      /**
       * @description sends email reminder to owning org
       * @returns {Promise} promise
       */
      remind() {
        return $q((resolve, reject) => {
          jilTrusts.trustsNotifications.remind(
            {directoryId: this.directoryId},
            onSuccess.bind(this),
            reject
          );

          function onSuccess() {
            resolve(this);
          }
        });
      }

      /**
       * @description calls the PATCH API with the `remove` operation on the directory
       * @returns {Promise} promise
       */
      remove() {
        const patchPayload = [
          {
            op: 'remove',
            path: `/${this.trusteeOrgId}/${this.directoryId}`,
          },
        ];

        return Trust.performBatchOperation({
          errorCallback: onError,
          patchPayload,
          successCallback: onSuccess.bind(this),
        });

        function onSuccess() {
          $rootScope.$emit(TRUST_EVENT.DELETE);
          modelCache.remove(TRUST_CACHE_ID, this.key());
        }

        function onError(err) {
          $log.error('Trust failed to remove. Error: ', err);
        }
      }

      /**
       * @description calls the PATCH API with the `replace` operation and
       * the `revoke` path on the directory
       * @returns {Promise} promise
       */
      revoke() {
        const patchPayload = [
          {
            op: 'replace',
            path: `/${this.trusteeOrgId}/${this.directoryId}/revoke`,
          },
        ];

        return Trust.performBatchOperation({
          errorCallback: onError,
          patchPayload,
          successCallback: onSuccess.bind(this),
        });

        function onSuccess() {
          $rootScope.$emit(TRUST_EVENT.DELETE);
          modelCache.remove(TRUST_CACHE_ID, this.key());
        }

        function onError(err) {
          $log.error('Trust failed to revoke. Error: ', err);
        }
      }

      /**
       * @description generate model subset containing only API relevant fields.
       * @returns {Object} API relevant subset of Trust object.
       */
      toMinimumModel() {
        return toMinimumObject(this);
      }

      static apiResponseTransformer(item) {
        return new Trust(item);
      }

      /**
       * @static
       * @param {Object} options - options passed to constructor
       * @param {String} options.directoryId - id of the trust
       * @param {String} options.trusteeOrgId - org id of the trustee
       * @returns {Object} the model
       */
      static get(options = {}) {
        const model = new Trust(options);
        const cachedModel = modelCache.get(TRUST_CACHE_ID, model.key());
        if (cachedModel) {
          return cachedModel;
        }

        model.refresh();

        return model;
      }

      /**
       * @static
       * @param {Object} options - options passed trusts.batchOperation
       * @param {Array} options.patchPayload - the Array of operations
       * @returns {Promise} promise
       */
      static performBatchOperation(options) {
        return $q((resolve, reject) => {
          jilTrusts.trusts.batchOperation({}, options.patchPayload, onSuccess, onError);

          ////////

          function onSuccess(response) {
            const responseCode = _.chain(response).head().get('responseCode').value();
            if (!_.inRange(responseCode, 200, 300)) {
              onError(response);
              return;
            }
            if (options.successCallback) {
              options.successCallback(response);
            }
            resolve();
          }

          function onError(err) {
            if (options.errorCallback) {
              options.errorCallback(err);
            }
            reject(err);
          }
        });
      }
    }

    // We register the cache size for this class
    modelCache.register(TRUST_CACHE_ID, 10);

    return Trust;

    //////////////

    function applyResource(trust, resource) {
      _.assignIn(trust, toMinimumObject(resource));
      return trust;
    }

    function toMinimumObject(object) {
      return _.pick(object, [
        'directoryId',
        'directoryName',
        'initialRequestDate',
        'lastRequestDate',
        'notifyAdmins',
        'owningOrgName',
        'owningOrgSysAdminEmail',
        'reasonForRejection',
        'status',
        'trusteeOrgId',
        'trusteeOrgName',
        'trusteeOrgSysAdminEmail',
      ]);
    }
  }
})();
