import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './DrawerContent.pcss';

const DrawerContent = ({children, ...props}) => (
  <View
    overflow="auto"
    paddingBottom="size-200"
    paddingX="size-200"
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Required to set the style content
    UNSAFE_className={styles.content}
    {...props}
  >
    {children}
  </View>
);

DrawerContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DrawerContent;
