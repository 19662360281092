(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.panels.rail:binkyRail
   *
   * @description An animated rail panel
   *
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {String} [railLabelId] The id of the element which provides the accessible label for the rail.
   * @param {String} railId The ID to assign to the rail-content div.
   * @param {String} [railTitle] A title to show on the default header, if rail-header is not used.
   * @param {Promise} [waitOnRail] An optional promise to wait for before displaying rail content.
   * @param {Transclude} railBody The core transclusion for the rail content. Required.
   * @param {Transclude} [railHeader] A transclusion for the rail header. Optional.
   * @param {Transclude} [railFooter] A transclusion for the rail footer. Optional.
   *
   * @example <binky-rail on-close="myClose" on-open="myOpen" rail-id="my-id" rail-label-id="my-id-header" rail-title="My Title" wait-on-rail"example.$promise"><rail-header></rail-header><rail-body></rail-body><rail-footer></rail-footer></binky-rail>
   *
   */
  angular.module('binky.shell.panels.rail').component('binkyRail', {
    bindings: {
      onClose: '<?',
      onOpen: '<?',
      railId: '@',
      railLabelId: '@?',
      railTitle: '@?',
      waitOnRail: '<?',
    },
    controller,
    templateUrl: 'shell/panels/rail/rail.component.html',
    transclude: {
      'rail-body': 'railBody',
      'rail-footer': '?railFooter',
      'rail-header': '?railHeader',
    },
  });

  function controller($q, $timeout, $transclude, _, PANEL_MANAGER, panelManager) {
    const vm = this;

    _.assign(vm, {$onDestroy, $onInit});

    function $onDestroy() {
      panelManager.unregister(vm.railId);
    }

    function $onInit() {
      vm.isShown = false;
      vm.isFooterPresent = $transclude.isSlotFilled('rail-footer');
      if (!vm.waitOnRail) {
        vm.waitOnRail = $q.resolve();
      }

      $timeout(() => {
        panelManager.register(PANEL_MANAGER.TYPE.RAIL, vm.railId, onOpen, onClose);
      });

      function onOpen() {
        _.invoke(vm, 'onOpen');
        vm.isShown = true;
      }
      function onClose() {
        _.invoke(vm, 'onClose');
        vm.isShown = false;
      }
    }
  }
})();
