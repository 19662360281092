(function () {
  /**
   * @deprecated Please use src2/core/services/fulfillmentEvent/FulfillmentEventList.js
   *
   * @ngdoc factory
   * @name FulfillmentEventList
   * @description Model for a fulfillment event list
   */
  angular
    .module('binky.core.fulfillment-events')
    .factory('FulfillmentEventList', getFulfillmentEventListModel);

  /* @ngInject */
  function getFulfillmentEventListModel(
    $q,
    $rootScope,
    _,
    binkySrc2,
    FULFILLMENT_EVENT_LIST_CACHE_ID,
    IN_PROGRESS_FULFILLMENT_EVENT_LIST_CACHE_ID,
    IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT,
    LICENSE_FULFILLMENT_EVENT_LIST_CACHE_ID,
    modelCache,
    promiseUtils
  ) {
    class FulfillmentEventList2 extends binkySrc2.services.fulfillmentEvent.FulfillmentEventList {
      /**
       * @description Method to refresh fulfillment event list from back-end.
       * @returns {Promise} resolves to FulfillmentEventList on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              resolve(this);
            })
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the FulfillmentEventList.
       * @param {Object} options - options to configure the fulfillment event list.
       * @returns {FulfillmentEventList} the fetched FulfillmentEventList.
       */
      static get(options) {
        const model = new FulfillmentEventList2(options);
        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((modelList) => {
            _.assign(model, modelList);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    //////////////

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT.UPDATE, () => {
      binkySrc2.models.cache.modelCache.clear(IN_PROGRESS_FULFILLMENT_EVENT_LIST_CACHE_ID);
      modelCache.removeAll(IN_PROGRESS_FULFILLMENT_EVENT_LIST_CACHE_ID);
    });

    modelCache.register(FULFILLMENT_EVENT_LIST_CACHE_ID, 1);
    modelCache.register(IN_PROGRESS_FULFILLMENT_EVENT_LIST_CACHE_ID, 1);
    modelCache.register(LICENSE_FULFILLMENT_EVENT_LIST_CACHE_ID, 1);

    return FulfillmentEventList2;
  }
})();
