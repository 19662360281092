(function () {
  /**
   * @ngdoc       module
   * @name        binky.core.observability.ready
   * @description App-ready module to initiate observability
   */

  angular.module('binky.core.observability.ready', [
    'binky.core.configuration.ready',
    'binky.core.feature.ready',
    'binky.core.lodash',
    'binky.core.promise-utils',
  ]);
})();
