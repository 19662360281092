(function () {
  'use strict';
  const componentName = 'binkyGoUrl';
  /**
   * @deprecated use src2/widgets/common/components/go-url/GoUrl.jsx
   */
  angular.module('binky.widgets.common.go-url').component('binkyGoUrl', {
    bindings: {
      name: '@',
    },
    controller,
    templateUrl: 'widgets/common/go-url/go-url.component.html',
    transclude: true,
  });

  function controller(_, AnalyticsEvent) {
    const vm = this;
    _.assign(vm, {onClick});

    function onClick(event) {
      event.stopPropagation();
      triggerAnalytics();
    }

    function triggerAnalytics() {
      AnalyticsEvent.dispatch({
        attributes: {
          goUrlName: vm.name,
        },
        componentMethod: 'goUrl',
        componentMethodType: 'submit',
        componentName,
      });
    }
  }
})();
