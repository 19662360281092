(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Notice
   * @description General model for use in notification widgets
   */
  angular.module('binky.widgets.common.notice').factory('Notice', getModel);

  /* @ngInject */
  function getModel(_) {
    class Notice {
      /**
       * @description Constructs a new Notice. Required properties are dependent
       *   on the component that the Notice is used with. This view model can be
       *   used in conjunction with any component that displays messages to to
       *   the user -- especially those which require some call to action. Any
       *   option that is provided will be undefined by default.
       * @param {Object} [options] - options for the Notice
       * @param {Object} [options.action] - To be used for CTA buttons (onClick)
       *   or links (href), etc related to Notice
       * @param {Object} [options.action.href] - An href link to represent the
       *   action. If present there should not be an action.onClick handler defined.
       * @param {String} [options.action.icon] - The name of an icon to include in
       *   the button.
       * @param {String} [options.action.label] - The label for the action
       * @param {Function} [options.action.onClick] - The handler for the action.
       *   If present, there should not be an action.href defined.
       * @param {String} [options.body] - The detailed message to the user
       * @param {String} [options.context] - The context that Gainsight uses
       * to track the HTML elements within the notice.
       * @param {String} [options.header] - The header of the message
       * @param {String} [options.icon] - The icon to associate with the message
       *   (e.g., 'alert').
       * @param {String} [options.iconAltText] - The textual description of the
       *   purpose of the icon being used. In some cases (e.g. - badge
       *   popovers), if there is no label provided (icon only badges), then
       *   this attribute becomes required, since there would otherwise be no
       *   textual description accompanying the icon presented to users. In
       *   general, if an icon is provided, alt text should accompany it
       * @param {String} [options.label] - A briefer version of the header
       *   message
       * @param {Number} [options.priority] - The lower the number, the higher
       *   the prioriity
       * @param {String} [options.variant] - The type of message (e.g., 'error',
       *   'warning', 'success', 'help', 'info', etc.)
       */
      constructor(options = {}) {
        _.assign(
          this,
          _.pick(options, [
            'body',
            'context',
            'header',
            'icon',
            'iconAltText',
            'label',
            'priority',
            'variant',
          ])
        );

        if (_.isObject(options.action)) {
          this.action = _.pick(options.action, ['href', 'icon', 'label', 'onClick']);

          if (_.get(this.action, 'href') && this.action.onClick) {
            throw new TypeError('Notice: should not have both href and onClick');
          }
        }
      }

      /**
       * @description Method to determine if body content exists for this
       *   Notice. If the body attribute is set to a non-empty value for this
       *   Notice, then this method will return true.
       * @returns {Boolean} true if this Notice contains body content, else false
       */
      hasBody() {
        return !_.isEmpty(this.body);
      }
    }

    return Notice;
  }
})();
