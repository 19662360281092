import {DATA_POINT_TYPES} from '@admin-tribe/binky';
import {endOfDay} from 'date-fns';
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds';

import {
  USAGE_REPORT_CHART_DATA_SET,
  USAGE_REPORT_CHART_DEFAULT_OPTIONS,
} from './UsageLineChartConstants';

/**
 * @description Helper to construct chart data for chart component
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object} chartLabels - labels for chart component
 * @param {Object} dataPointsQty - array of data points for provisioned quantity, delegated quantity and inactive contract period's provisioned qty
 * @returns {Object} bundle of dataset and label as chart-js data for chart component
 */
function getChartLabelsAndDataSets(intl, chartLabels, dataPointsQty) {
  const yAxis = Object.create({});
  Object.assign(yAxis, {
    yAxis1: USAGE_REPORT_CHART_DATA_SET.YAXIS1,
    yAxis2: USAGE_REPORT_CHART_DATA_SET.YAXIS2,
    yAxis3: USAGE_REPORT_CHART_DATA_SET.YAXIS3,
  });
  const {contractInactiveProvisionedQty, delegatedQty, provisionedQty} = dataPointsQty;
  yAxis.yAxis1.data = provisionedQty;
  yAxis.yAxis1.label = intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.licenses'});
  yAxis.yAxis2.data = delegatedQty;
  yAxis.yAxis2.label = intl.formatMessage({
    id: 'binky.widgets.reports.chart.tooltip.highestAssigned',
  });
  yAxis.yAxis3.data = contractInactiveProvisionedQty;
  yAxis.yAxis3.label = intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.expired'});
  return {
    datasets: [yAxis.yAxis1, yAxis.yAxis2, yAxis.yAxis3],
    labels: chartLabels,
  };
}

/**
 * @description Helper to construct chart option for chart component
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {string} selectedPeriodType - selected period type for chart, i.e. annual or monthly
 * @param {Object} chartOptionParam - A wrapped object of chart option parameters required
 * for constructing chart options, i.e. maxWithPadding, minWithPadding,beginAtZero,isInactiveDataPresent and selectedProductCreationTS.
 * @param {Object} chartDataAndLabel - A bundle of dataset and label as chart-js data for chart component
 * @returns {Object} chart option for constructing chart component
 */
function getChartOptions(intl, selectedPeriodType, chartOptionParam, chartDataAndLabel) {
  const {
    beginAtZero,
    isInactiveDataPresent,
    maxWithPadding,
    minWithPadding,
    selectedProductCreationTS,
  } = chartOptionParam;

  const newChartOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            labelString: getXAxisLabelString(intl, selectedPeriodType),
          },
          ticks: {
            autoskip: true,
            autoSkipPadding: 30,
            callback(value) {
              if (selectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
                return intl.formatMessage(
                  {id: 'binky.widgets.reports.chart.labels.xAxis.yearlyView'},
                  {count: value.count}
                );
              }
              return intl.formatMessage(
                {id: 'binky.widgets.reports.chart.labels.xAxis.monthlyView'},
                {
                  day: intl.formatDate(value * 1000, {day: 'numeric', timeZone: 'UTC'}),
                  month: intl.formatDate(value * 1000, {month: 'short', timeZone: 'UTC'}),
                }
              );
            },
            maxRotation: 0,
            minRotation: 0,
          },
          tooltips: {
            enabled: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            color: '#cacaca',
            display: true,
          },
          id: intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.licenses'}),
          position: 'left',
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            labelString: intl.formatMessage({id: 'binky.widgets.reports.chart.labels.yAxisLabel'}),
          },
          ticks: {
            beginAtZero,
            suggestedMax: maxWithPadding,
            suggestedMin: minWithPadding,
            userCallback: getStringFromInteger,
          },
          type: 'linear',
        },
        {
          display: false,
          id: intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.highestAssigned'}),
          ticks: {
            beginAtZero,
            display: false,
            suggestedMax: maxWithPadding,
            suggestedMin: minWithPadding,
            userCallback: getStringFromInteger,
          },
          type: 'linear',
        },
        {
          display: false,
          id: intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.expired'}),
          ticks: {
            beginAtZero,
            display: isInactiveDataPresent,
            suggestedMax: maxWithPadding,
            suggestedMin: minWithPadding,
            userCallback: getStringFromInteger,
          },
          type: 'linear',
        },
      ],
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, labelsData) {
          return getEtlaUsageReportTooltipText(
            intl,
            [tooltipItem, labelsData],
            selectedPeriodType,
            selectedProductCreationTS
          );
        },
        labelColor(item) {
          return {
            backgroundColor: chartDataAndLabel.datasets[item.datasetIndex].borderColor,
            borderColor: chartDataAndLabel.datasets[item.datasetIndex].borderColor,
          };
        },
        title(item, value) {
          if (value === undefined || value.labels === undefined || !value?.labels[item[0]?.index]) {
            return '';
          }
          if (selectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
            return intl.formatMessage(
              {id: 'binky.widgets.reports.chart.tooltip.month.text'},
              {
                count: item[0].xLabel.count,
                monthEnd: `${intl.formatDate(item[0].xLabel.monthEnd * 1000, {
                  day: 'numeric',
                  month: 'short',
                  timeZone: 'UTC',
                  year: 'numeric',
                })}`,
                monthStart: `${intl.formatDate(item[0].xLabel.monthStart * 1000, {
                  day: 'numeric',
                  month: 'short',
                  timeZone: 'UTC',
                  year: 'numeric',
                })}`,
              }
            );
          }
          return intl.formatDate(value.labels[`${item[0].index}`] * 1000, {
            day: 'numeric',
            month: 'short',
            timeZone: 'UTC',
            year: 'numeric',
          });
        },
      },
      displayColors: true,
      enabled: true,
      intersect: false,
      mode: 'index',
    },
  };
  return Object.assign(
    USAGE_REPORT_CHART_DEFAULT_OPTIONS,
    {scales: newChartOptions.scales},
    {tooltips: newChartOptions.tooltips}
  );
}

/**
 * @description Function for obtaining label text for tooltip in etlaUsageReport graph.
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object} tooltipItemAndLabelData - A wrapper object for the ToolTipItem object for which the label text has to be computed and labelsData, which is the data object passed in the tooltip label callback
 * @param {string} type - It is one of the USAGE_REPORT_DATA_POINT_TYPES
 * @param {number} productCreationTime - The creation time of the selected product in the graph in milliseconds
 * @returns {string} The tooltip label text
 */
function getEtlaUsageReportTooltipText(intl, tooltipItemAndLabelData, type, productCreationTime) {
  const series = [
    intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.licenses'}),
    intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.highestAssigned'}),
    intl.formatMessage({id: 'binky.widgets.reports.chart.tooltip.expired'}),
  ];
  const tooltipItem = tooltipItemAndLabelData[0];
  const labelsData = tooltipItemAndLabelData[1];
  const label = labelsData?.datasets[tooltipItem?.datasetIndex]?.label;
  const yLabel = tooltipItem.yLabel.toLocaleString();

  if (label && label !== series[2] && Number.isInteger(tooltipItem.yLabel)) {
    if (label === series[0]) {
      return `${series[0]}: ${yLabel}`;
    }
    if (type === DATA_POINT_TYPES.ANNUAL) {
      return `${series[1]}: ${yLabel} (${intl.formatDate(tooltipItem.xLabel.peakDate * 1000, {
        day: 'numeric',
        month: 'short',
        timeZone: 'UTC',
        year: 'numeric',
      })})`;
    }
    return `${series[1]}: ${yLabel}`;
  }
  if (shouldShowAddOnProductTooltip(productCreationTime, tooltipItem, type)) {
    return intl.formatMessage(
      {id: 'binky.widgets.reports.chart.tooltip.addOnProduct'},
      {
        date: intl.formatDate(productCreationTime, {
          day: 'numeric',
          month: 'short',
          timeZone: 'UTC',
          year: 'numeric',
        }),
      }
    );
  }
  return series[2];
}

/**
 * @description Method for getting max and min y-Axis value with padding for UsageLineChart.
 * @param {Object} provisionedAndDelegatedQty - A wrapped object of array of provisioned qty and array of delegated qty data points.
 * @param {Object} contractInactiveProvisionedQty - An array of contractInactiveProvisioned qty data points.
 * @param {boolean} isInactiveDataPresent - A boolean flag to indicate inactive period data is present or not.
 * @returns {Object} Bundle of ma and min y-Axis value with padding for UsageLineChart.
 */
function getMaxAndMinYAxisValueWithPadding(
  provisionedAndDelegatedQty,
  contractInactiveProvisionedQty,
  isInactiveDataPresent
) {
  const {delegatedQty, provisionedQty} = provisionedAndDelegatedQty;
  const max = Math.max(
    ...[
      Math.max(...provisionedQty),
      Math.max(...delegatedQty),
      Math.max(...contractInactiveProvisionedQty),
    ]
  );
  const min = isInactiveDataPresent
    ? Math.min(
        ...[
          Math.min(...provisionedQty),
          Math.min(...delegatedQty),
          Math.min(...contractInactiveProvisionedQty),
        ]
      )
    : Math.min(...[Math.min(...provisionedQty), Math.min(...delegatedQty)]);
  return {maxWithPadding: Math.ceil(1.1 * max), minWithPadding: Math.floor(0.9 * min)};
}

/**
 * @description Method for getting wrapper of provisionedQty, delegatedQty and labels for plotting graph with different chart data points.
 * @param {Object} chartDataPoints - The chart data points indicating the data points for plotting the graph
 * @param {string} selectedPeriodType - selected period type for chart, i.e. annual or monthly
 * @returns {Object} object wrapper for provisioned qty, delegated qty and labels.
 */
function getProvisionedDelegatedQtyAndLabels(chartDataPoints, selectedPeriodType) {
  const provisionedQty = [];
  const delegatedQty = [];
  const labels = [];
  chartDataPoints.forEach((dataPoint) => {
    if (selectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
      labels.push({
        count: `${dataPoint.billingMonth % 12 === 0 ? 12 : dataPoint.billingMonth % 12}`,
        monthEnd: dataPoint.endDate,
        monthStart: dataPoint.startDate,
        peakDate: dataPoint.eventTimestamp,
      });
    } else {
      labels.push(dataPoint.eventTimestamp);
    }
    provisionedQty.push(dataPoint.provisionedQty);
    delegatedQty.push(dataPoint.delegatedQty);
  });
  return {delegatedQty, labels, provisionedQty};
}

/**
 * @description Helper to construct chart data chart component
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {string} selectedPeriodType - selected period type for chart, i.e. annual or monthly
 * @returns {string} x-axis label string for chart component.
 */
function getXAxisLabelString(intl, selectedPeriodType) {
  if (selectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
    return intl.formatMessage({id: 'binky.widgets.reports.chart.labels.xAxisMonthLabel'});
  }
  return intl.formatMessage({id: 'binky.widgets.reports.chart.labels.xAxisDayLabel'});
}

///////////////

/**
 * @description Method for converting string from integer
 * @param {number} value - integer value
 * @returns {string} converted string value
 */
function getStringFromInteger(value) {
  if (Number.isInteger(value)) {
    return value.toLocaleString();
  }
  return undefined;
}

/**
 * @description Method for detecting whether special add-on product label text should be shown in tooltip
 * @param {number} productCreationTime - The creation time of the selected product in the graph in milliseconds
 * @param {Object} tooltipItem - The ToolTipItem object for which the label text has to be computed
 * @param {string} type - It is one of the USAGE_REPORT_DATA_POINT_TYPES
 * @returns {boolean} indicates whether or not the add-on product label is applicable
 */
function shouldShowAddOnProductTooltip(productCreationTime, tooltipItem, type) {
  if (
    type === DATA_POINT_TYPES.ANNUAL &&
    millisecondsToSeconds(productCreationTime) > tooltipItem.xLabel.monthEnd
  ) {
    return true;
  }
  if (type === DATA_POINT_TYPES.MONTHLY) {
    const endDayEpoch = endOfDay(new Date(tooltipItem.xLabel * 1000)).getTime();
    if (productCreationTime > endDayEpoch) {
      return true;
    }
  }
  return false;
}

export {
  getChartLabelsAndDataSets,
  getChartOptions,
  getEtlaUsageReportTooltipText,
  getMaxAndMinYAxisValueWithPadding,
  getProvisionedDelegatedQtyAndLabels,
  getXAxisLabelString,
};
