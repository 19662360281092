import {ActionButton, Button, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-tooltip-button
 */

/**
 * TooltipButton is a button with a tooltip.
 */
const TooltipButton = ({
  buttonAriaLabel,
  children,
  hoverText,
  isDisabled = false,
  isQuiet = false,
  onClick,
  tooltipDelay = 0,
  variant,
}) => (
  <TooltipTrigger delay={tooltipDelay} placement="top">
    {variant === 'action' ? (
      <ActionButton
        aria-label={buttonAriaLabel || hoverText}
        data-testid="tooltip-button-button"
        isDisabled={isDisabled}
        isQuiet={isQuiet}
        onPress={onClick}
      >
        {children}
      </ActionButton>
    ) : (
      <Button
        aria-label={buttonAriaLabel || hoverText}
        data-testid="tooltip-button-button"
        isDisabled={isDisabled}
        onPress={onClick}
        variant={variant}
      >
        {children}
      </Button>
    )}

    <Tooltip data-testid="tooltip-button-tooltip" isOpen>
      {hoverText}
    </Tooltip>
  </TooltipTrigger>
);

TooltipButton.propTypes = {
  /**
   * The arial label to display for the TooltipButton.
   */
  buttonAriaLabel: PropTypes.string,
  /**
   * The content to display in the TooltipButton.
   */
  children: PropTypes.node,
  /**
   * The text to be display when hovering on the TooltipButton.
   */
  hoverText: PropTypes.string.isRequired,
  /**
   * Whether the TooltipButton is disabled. Default is false.
   */
  isDisabled: PropTypes.bool,
  /**
   * Whether the TooltipButton should be displayed with a quiet style. Default is false.
   */
  isQuiet: PropTypes.bool,
  /**
   * Handler that is called when the TooltipButton is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Delay for the tooltip to show up.
   */
  tooltipDelay: PropTypes.number,
  /**
   * Variant type of the TooltipButton. One of 'action', 'cta', 'overBackground', 'primary', 'secondary', 'negative'
   */
  variant: PropTypes.oneOf(['action', 'cta', 'overBackground', 'primary', 'secondary', 'negative']),
};

export default TooltipButton;
