import {ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import MoreOptionsMenu from 'common/components/more-options-menu/MoreOptionsMenu';

/**
 * Component for a ButtonGroup with a `...` more options Button which controls a Menu.
 */
const ButtonGroupWithMoreOptions = ({
  children,
  disabledMenuKeys,
  isMoreOptionsButtonDisabled = false,
  menuItems,
  onItemSelected,
}) => (
  <ButtonGroup>
    {children}
    <MoreOptionsMenu
      buttonMarginStart="size-200"
      disabledKeys={disabledMenuKeys}
      isButtonDisabled={isMoreOptionsButtonDisabled}
      menuItems={menuItems}
      onItemSelected={onItemSelected}
    />
  </ButtonGroup>
);

ButtonGroupWithMoreOptions.propTypes = {
  /**
   * Buttons that will proceed the `...` 'More options' button with menu.
   */
  children: PropTypes.node.isRequired,
  /**
   * An array of the keys of the menu items that are disabled. The key of a menu item is the property 'key' if it
   * exists, else the property 'body'. By default all menu items are enabled.
   * It is also possible to disable the 'More options' button so the menu can't be opened.
   */
  disabledMenuKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether the 'More options' action button is disabled. The default is false.
   * It is also possible to disable the individual items in the more options menu.
   */
  isMoreOptionsButtonDisabled: PropTypes.bool,
  /**
   * An array of items of the menu with the body defined.
   * For dynamic items that replace each other depending on the state,
   * assign them the same key to prevent the Menu from refreshing and
   * losing tabbing focus.
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      key: PropTypes.string,
    })
  ).isRequired,
  /**
   * Handler that is called when a menu item is selected.
   */
  onItemSelected: PropTypes.func.isRequired,
};

export default ButtonGroupWithMoreOptions;
