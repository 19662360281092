/* eslint-disable react/no-unknown-property -- styleName not recognized */
/* eslint-disable max-lines -- custom component needs more lines for custom behavior */
import {LicenseGroupList, SEARCH_QUERY_MIN_LENGTH} from '@admin-tribe/binky';
import {SearchField, View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v3 equivalent does not support the UI we want
import {MenuHeading} from '@react/react-spectrum/Menu';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import MenuItemCheckbox from 'common/components/assignment-menu/menu-item-checkbox/MenuItemCheckbox';
import MenuEndOfResults from 'common/components/assignment-menu/shared/MenuEndOfResults';
import MenuError from 'common/components/assignment-menu/shared/MenuError';
import MenuLoading from 'common/components/assignment-menu/shared/MenuLoading';
import MenuNoItemsMessage from 'common/components/assignment-menu/shared/MenuNoItemsMessage';
import MenuNoResults from 'common/components/assignment-menu/shared/MenuNoResults';

import {useAssignmentMenuContext} from '../AssignmentMenuContext';

import './ProductProfileMenu.pcss';

const {DISPLAYED_RESULT_LIMIT, IDS, TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;
const {MENUITEM_PREFIX} = IDS;
const SEARCH_MAX_LENGTH = 256;
const DELAY_INIT_TIME = 1000; // milliseconds
const GET_LICENSE_GROUP_PAGE_SIZE = 100; // fetch 100 instead of default 20 because admins want to scroll through more
const TAB_KEY = 'Tab';

/**
 * @description A component that will display product profiles (license groups) of a product
 * in a menu view. This menu can be rendered within a v2 Menu or v2 Submenu component. When product
 * has licenseGroupSummaries and groupsQuantity field, the component will render the profiles without
 * needing to GET /license-groups. A search input will render when there is more profiles than
 * can be displayed.
 *
 * When the product does not have licenseGroupSummaries, the component will always GET /license-groups on mount.
 *
 * Menu search state is cached after each user's search.
 */
const ProductProfileMenu = ({delayInit = false, onSelection, product, orgId}) => {
  const intl = useIntl();
  const {
    isSelected,
    isProductProfileDisabled: isDisabled,
    menuCache,
    setMenuCache,
    toggleItem,
  } = useAssignmentMenuContext();

  const productProfileMenuCache = menuCache[TARGET_TYPE.PRODUCT_PROFILES]?.[product.id] || {};
  const {cachedProfiles, cachedProfileCount, cachedFilteredCount, cachedSearchValue} =
    productProfileMenuCache;

  const [searchValue, setSearchValue] = React.useState(cachedSearchValue || '');
  const [profiles, setProfiles] = React.useState(cachedProfiles || product.licenseGroupSummaries);
  const [totalProfileCount, setTotalProfileCount] = React.useState(
    cachedProfileCount || product.groupsQuantity || 0
  );
  const [areResultsTruncated, setAreResultsTruncated] = React.useState(
    totalProfileCount > DISPLAYED_RESULT_LIMIT
  );
  const [promptProfileCreation, setPromptProfileCreation] = React.useState(totalProfileCount === 0);
  const [filteredCount, setFilteredCount] = React.useState(
    cachedFilteredCount || product.groupsQuantity
  );
  const licenseGroupListRef = React.useRef();

  const isMounted = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const searchInputRef = React.useRef();
  const profileMenuRef = React.useRef();
  const invalidMsgId = useId();
  const [isInputValid, setIsInputValid] = React.useState(true);

  React.useEffect(() => {
    async function initProfiles() {
      try {
        const hasCachedState = searchValue === cachedSearchValue;

        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (!hasCachedState) {
          licenseGroupListRef.current = await LicenseGroupList.get({
            filterQuery: searchValue,
            orgId,
            pageSize: GET_LICENSE_GROUP_PAGE_SIZE,
            product,
          });

          if (isMounted.current) {
            setProfiles(licenseGroupListRef.current.items);
            setTotalProfileCount(licenseGroupListRef.current.getTotalItemCount());
            setFilteredCount(licenseGroupListRef.current.getTotalItemCount());
            setMenuCache((prevCache) => ({
              ...prevCache,
              [TARGET_TYPE.PRODUCT_PROFILES]: {
                ...prevCache[TARGET_TYPE.PRODUCT_PROFILES],
                [product.id]: {
                  cachedFilteredCount: licenseGroupListRef.current.getTotalItemCount(),
                  cachedProfileCount: licenseGroupListRef.current.getTotalItemCount(),
                  cachedProfiles: licenseGroupListRef.current.items,
                  cachedSearchValue: searchValue,
                },
              },
            }));
          }
        }
      } catch {
        if (isMounted.current) {
          setError(true);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }

    let timerToInitProfiles;
    if (profiles === undefined) {
      setLoading(true);
      if (delayInit) {
        timerToInitProfiles = setTimeout(initProfiles, DELAY_INIT_TIME);
      } else {
        initProfiles();
      }
    }

    return () => {
      isMounted.current = false;
      if (timerToInitProfiles) {
        clearTimeout(timerToInitProfiles);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on mount
  }, []);

  React.useEffect(() => {
    setPromptProfileCreation(totalProfileCount === 0);
    setAreResultsTruncated(totalProfileCount > DISPLAYED_RESULT_LIMIT);
  }, [totalProfileCount]);

  React.useEffect(() => {
    const searchInput = searchInputRef.current;

    // Event listener to always ensure that first menu item can be tabbed to when search input has focus
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    /* istanbul ignore next -- TODO: test in e2e/integration test */
    const allowTabToFirstMenuItem = () => {
      const firstMenuItem = profileMenuRef.current?.querySelector(
        `li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`
      );
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
      /* istanbul ignore next -- TODO: test in e2e/integration test */
      if (firstMenuItem) {
        firstMenuItem.tabIndex = '0';
      }
    };

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    /* istanbul ignore else */
    if (searchInputRef.current) {
      searchInputRef.current.getInputElement().addEventListener(
        'keypress',
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        /* istanbul ignore next -- could not cover properly in unit test */ (e) => {
          // Because this component is rendered within a v2 Menu or v2 Submenu, Menu/Submenu will use a FocusManager
          // to redirect focus from our SearchField to a V2 MenuItem that starts with the letter typed.
          // Currently Menu is able to forward the prop `typeToSelect=false` to prevent this behavior, but Submenu can not.
          // In order to fix this behavior when this component is rendered within a Submenu, we prevent this event from reaching
          // React Spectrum's v2 FocusManager onKeyPress function. https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/utils/FocusManager.js#L286
          // This is a workaround fix - the React Spectrum team has said they will not be addressing this V2 issue.

          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
          /* istanbul ignore next -- could not cover properly in unit test */
          e.stopImmediatePropagation();
        }
      );

      /* TODO: test in e2e/integration test */
      // Always ensure that first menu item can be tabbed to when search input has focus
      searchInput.getInputElement()?.addEventListener('focus', allowTabToFirstMenuItem);
    }

    return () => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
      /* istanbul ignore next -- TODO: test in e2e/integration test */

      if (searchInput) {
        searchInput.getInputElement()?.removeEventListener('focus', allowTabToFirstMenuItem);
      }
    };
  }, [areResultsTruncated]);

  React.useEffect(
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    /* istanbul ignore next -- TODO: test in e2e/integration test */ () => {
      if (!loading && areResultsTruncated && profiles?.length > 0) {
        const menuItems = profileMenuRef.current?.querySelectorAll(
          `li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`
        );
        menuItems.forEach(
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
          /* istanbul ignore next -- TODO: test in e2e/integration test */ (item) => {
            item.addEventListener('keydown', (e) => {
              // Override default tab behavior so that tab order loops back to the search input.
              // If user uses "shift" and "tab", then we also send focus back to search input based
              // on the assumption that the profile menu will only have menu items, or a search input and menu items.
              if (e.key === TAB_KEY) {
                if (searchInputRef.current?.getInputElement()) {
                  // Need both stopPropagation and preventDefault to override focus
                  e.stopPropagation();
                  e.preventDefault();
                  searchInputRef.current.getInputElement().focus();
                }
              }
            });
          }
        );
      }

      // Override default focus from React Spectrum
      // https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/utils/FocusManager.js#L28
      const REACT_SPECTRUM_AUTOFOCUS_DELAY = 20;
      setTimeout(() => {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        /* istanbul ignore next -- TODO: test in e2e/integration test */
        if (isMounted.current) {
          const firstMenuItem = profileMenuRef.current?.querySelector(
            `li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`
          );
          if (searchInputRef.current) {
            searchInputRef.current.getInputElement().focus();
          } else if (firstMenuItem) {
            firstMenuItem.focus();
          }
        }
      }, REACT_SPECTRUM_AUTOFOCUS_DELAY + 1);
    },
    [areResultsTruncated, loading, profiles]
  );

  const handleSubmit = React.useCallback(
    async (query) => {
      if (query !== licenseGroupListRef.current?.filter?.query) {
        setError(false);
        setLoading(true);

        try {
          licenseGroupListRef.current = await LicenseGroupList.get({
            filterQuery: query,
            orgId,
            pageSize: GET_LICENSE_GROUP_PAGE_SIZE,
            product,
          });
          if (isMounted.current) {
            setProfiles(licenseGroupListRef.current.items);
            setFilteredCount(licenseGroupListRef.current.getTotalItemCount());
          }
        } catch {
          if (isMounted.current) {
            setError(true);
          }
        } finally {
          if (isMounted.current) {
            setLoading(false);
            setMenuCache((prevCache) => ({
              ...prevCache,
              [TARGET_TYPE.PRODUCT_PROFILES]: {
                ...prevCache[TARGET_TYPE.PRODUCT_PROFILES],
                [product.id]: {
                  ...prevCache[TARGET_TYPE.PRODUCT_PROFILES]?.[product.id],
                  cachedFilteredCount: licenseGroupListRef.current?.getTotalItemCount(),
                  cachedProfiles: licenseGroupListRef.current?.items,
                  cachedSearchValue: query,
                },
              },
            }));
          }
        }
      }
    },
    [orgId, product, setMenuCache]
  );

  function onSelectionInternal(item, isItemSelected) {
    toggleItem(item);
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
    // istanbul ignore else
    if (onSelection) {
      onSelection(item, isItemSelected);
    }
  }

  const renderMenu = () => (
    <View>
      <MenuHeading>
        {intl.formatMessage(
          {id: 'binky.common.assignmentMenu.productProfileMenu.heading.totalCount'},
          {
            totalCount: totalProfileCount,
          }
        )}
      </MenuHeading>

      {profiles?.map((licenseGroup) => (
        <MenuItemCheckbox
          key={licenseGroup.id}
          isDisabled={isDisabled}
          isPreselected
          isSelected={isSelected}
          item={licenseGroup}
          label={licenseGroup.name}
          onSelection={onSelectionInternal}
        />
      ))}

      {areResultsTruncated && (
        <MenuEndOfResults
          itemCount={profiles?.length}
          showSearchPrompt={filteredCount > DISPLAYED_RESULT_LIMIT}
          totalItemCount={totalProfileCount}
        />
      )}
    </View>
  );

  const renderMainContent = () => {
    if (error) {
      return <MenuError />;
    }
    if (loading) {
      return <MenuLoading />;
    }
    if (promptProfileCreation) {
      return (
        <MenuNoItemsMessage
          content={intl.formatMessage({
            id: 'binky.common.assignmentMenu.productProfileMenu.messages.noProfiles.content',
          })}
          heading={intl.formatMessage({
            id: 'binky.common.assignmentMenu.productProfileMenu.messages.noProfiles.heading',
          })}
        />
      );
    }
    if (profiles?.length === 0) {
      return <MenuNoResults />;
    }
    return renderMenu();
  };

  return (
    <div ref={profileMenuRef} data-testid="profile-menu" styleName="profile-menu">
      {areResultsTruncated && (
        <>
          {/* TODO: clean up SearchField and validation message. SearchField and validation message
              should be replaced with ValidatedSearch when it is ported to binky-ui. */}
          <SearchField
            ref={searchInputRef}
            aria-describedby={invalidMsgId}
            aria-label={intl.formatMessage({
              id: 'binky.common.assignmentMenu.productProfileMenu.search.label',
            })}
            data-testid="search"
            marginStart="size-125"
            marginTop="size-50"
            maxLength={SEARCH_MAX_LENGTH}
            onChange={(val) => {
              setSearchValue(val);
              setIsInputValid(true);
            }}
            onClear={() => {
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
              // istanbul ignore else
              if (licenseGroupListRef.current?.filter?.query !== '') {
                // only refresh results if there has been a search
                setSearchValue('');
                handleSubmit('');
              }
            }}
            onSubmit={() => {
              // Handling simple input validation that will be handled in future input component
              if (
                (searchValue === '' && cachedSearchValue !== undefined) ||
                searchValue.trim().length >= SEARCH_QUERY_MIN_LENGTH
              ) {
                handleSubmit(searchValue);
              } else {
                setIsInputValid(false);
              }
            }}
            placeholder={intl.formatMessage({
              id: 'binky.common.assignmentMenu.productProfileMenu.search.label',
            })}
            type="search"
            validationState={!isInputValid && 'invalid'}
            value={searchValue}
            width="280px"
          />
          {!isInputValid && (
            <View marginStart="size-150">
              <span
                data-testid="profile-menu-invalid-message"
                /* eslint-disable-next-line react/forbid-dom-props -- id for aria-describedby */
                id={invalidMsgId}
                styleName="invalid-message"
              >
                <FormattedMessage id="binky.common.assignmentMenu.shared.search.invalidMessage" />
              </span>
            </View>
          )}
        </>
      )}
      {renderMainContent()}
    </div>
  );
};

ProductProfileMenu.propTypes = {
  delayInit: PropTypes.bool,
  // onSelection prop will be called with params: item, isItemSelected
  onSelection: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  product: PropTypes.shape({
    groupsQuantity: PropTypes.number,
    id: PropTypes.string.isRequired,
    licenseGroupSummaries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ProductProfileMenu;
/* eslint-enable max-lines -- re-enabling */
/* eslint-enable react/no-unknown-property -- styleName not recognized */
