/* eslint-disable react/no-unknown-property -- styleName not recognized */
import CycleButton from '@react/react-spectrum/CycleButton';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import ChevronDownIcon from '@react/react-spectrum/Icon/ChevronDown';
import ChevronRightIcon from '@react/react-spectrum/Icon/ChevronRight';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import TruncatedText from '../truncated-text/TruncatedText';

import styles from './CollapsibleList.pcss';

/**
 * Collapsible list is a widget to show and hide a given section of content when its heading is clicked.
 */
const CollapsibleList = ({header, itemName, items}) => {
  const intl = useIntl();
  const [expanded, setIsExpanded] = useState(false);

  return (
    <FieldLabel label={header} position="left" styleName="container">
      {items.length === 1 && <p styleName="item-label">{items[0]}</p>}
      {items.length > 1 && (
        <ul styleName="item-list">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events -- these are covered by the chevron button */}
          <li
            onClick={() => setIsExpanded((prevExpandedState) => !prevExpandedState)}
            styleName="item-list-item"
          >
            <CycleButton
              action={expanded ? 'collapse' : 'expand'}
              // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- cawright@ to update
              actions={[
                {
                  icon: <ChevronRightIcon />,
                  label: intl.formatMessage(
                    {id: 'binky.common.collapsibleList.list.expand'},
                    {itemName}
                  ),
                  name: 'expand',
                },
                {
                  icon: <ChevronDownIcon />,
                  label: intl.formatMessage(
                    {id: 'binky.common.collapsibleList.list.collapse'},
                    {itemName}
                  ),
                  name: 'collapse',
                },
              ]}
              aria-expanded={expanded.toString()}
              styleName="toggle-button"
            />
            <TruncatedText textClassName={styles['item-first-row']}>
              {expanded
                ? items[0]
                : intl.formatMessage(
                    {id: 'binky.common.collapsibleList.list.collapsedTitle'},
                    {count: items.length, name: itemName}
                  )}
            </TruncatedText>
          </li>
          {expanded &&
            items.slice(1).map((item) => (
              <li key={item} styleName="item-list-item">
                <TruncatedText key={item} textClassName={styles['item-nth-row']}>
                  {item}
                </TruncatedText>
              </li>
            ))}
        </ul>
      )}
    </FieldLabel>
  );
};

CollapsibleList.propTypes = {
  /**
   * The header of the list.
   */
  header: PropTypes.string,
  /**
   * The name of the collection to display.
   */
  itemName: PropTypes.string.isRequired,
  /**
   * The content to display in the list.
   */
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CollapsibleList;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
