import {ProgressCircle} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useProductRoleLicenseGroupContext} from './ProductRoleContext';
import ProductRolePicker from './ProductRolePicker';
import useProductRolesApis from './useProductRolesApis';

const ProductRolePickerSection = ({onProductRoleChange = () => {}}) => {
  const intl = useIntl();

  const {licenseGroupId, product} = useProductRoleLicenseGroupContext();

  const [{allLicenseGroupsShareSameRole, availableProductRoles, existingProductRoleId}, isLoading] =
    useProductRolesApis();

  return (
    <>
      {isLoading && (
        <ProgressCircle
          aria-label={intl.formatMessage({id: 'binky.common.wait.loadingLabel'})}
          isIndeterminate
          size="S"
        />
      )}
      {!isLoading && availableProductRoles?.length > 0 ? (
        <ProductRolePicker
          defaultRoleId={existingProductRoleId}
          onRoleChange={(roleId) => {
            onProductRoleChange({
              allLicenseGroupsShareSameRole,
              hasUnsavedChanges: existingProductRoleId !== roleId,
              id: roleId,
              licenseGroupId,
              product,
            });
          }}
          productRoles={availableProductRoles}
        />
      ) : null}
    </>
  );
};

ProductRolePickerSection.propTypes = {
  onProductRoleChange: PropTypes.func,
};

export default ProductRolePickerSection;
