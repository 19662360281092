import {MIGRATION_STATUS} from 'models/migration/MigrationConstants';

class Migration {
  static canTransform() {
    // This class supports transforming any type of migration, so return true. Subclasses of
    // this class may parse migrations with a specific type.
    return true;
  }

  /**
   * @description Creates a new Migration instance.
   *
   * @param {Object} rawData the raw migration JSON as returned by the backend
   * @param {MIGRATION_STATUS} rawData.status the current status of the migration
   * @param {MIGRATION_TYPE} rawData.type the migration type
   * @param {Object} [rawData.metadata] - optional additional fields
   * @param {String} [rawData.metadata.scheduledStartDate] - ISO8601 date, included for T2E migrations that have
   */
  constructor(rawData) {
    this.status = rawData.status;
    this.type = rawData.type;
    this.metadata = rawData.metadata;
  }

  /**
   * @description Checks if the org is locked which by default is if this
   *  migration is currently migrating. Child classes should override this
   *  function if the logic to lock the org differs.
   *
   * @returns {Boolean} true if the org is locked.
   */
  isOrgLocked() {
    return this.status === MIGRATION_STATUS.MIGRATING;
  }
}

export default Migration;
