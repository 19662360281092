/* eslint-disable @admin-tribe/admin-tribe/include-in-index -- index file not necessary for this file*/
/* eslint-disable filenames/match-exported -- necessary to set this filename to a sensible capitalization */

import AddUserForm from 'common/components/add-user-form/AddUserForm';
import AddUserFormContext from 'common/components/add-user-form/AddUserFormContext';
import AddUserFormEditableFields from 'common/components/add-user-form/editable-fields/AddUserFormEditableFields';
import AddUserFormTable from 'common/components/add-user-form-table/AddUserFormTable';
import MemberAndSelectedItemsList from 'common/components/add-user-form-table/MemberAndSelectedItemsList';
import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from 'common/components/add-user-form-table/utils/MemberAndSelectedItemsConstants';
import AdminRolePicker from 'common/components/admin-role-picker/AdminRolePicker';
import AssignmentMenu from 'common/components/assignment-menu/AssignmentMenu';
import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import {
  LICENSE_ACTIONS,
  LicenseLimitProvider,
  useLicenseLimitContext,
} from 'common/components/assignment-menu/assignmentMenuUtils';
import AssignmentSection, {
  AssignmentSectionContext,
} from 'common/components/assignment-section/AssignmentSection';
import {
  toPandoraProductTargets,
  toPandoraUserGroupTargets,
} from 'common/components/assignment-section/assignmentSectionUtils';
import AuthenticatedUserPullDown from 'common/components/authenticated-user-pulldown/AuthenticatedUserPullDown';
import Avatar from 'common/components/avatar/Avatar';
import ButtonGroupWithMoreOptions from 'common/components/button-group-with-more-options/ButtonGroupWithMoreOptions';
import ButtonLink from 'common/components/button-link/ButtonLink';
import CardButton from 'common/components/card-button/CardButton';
import Chiclet from 'common/components/chiclet/Chiclet';
import Comment from 'common/components/comment/Comment';
import ContractExpirationStatus from 'common/components/contract-expiration-status/ContractExpirationStatus';
import {
  getContractExpirationStatus,
  getContractExpirationStatusText,
} from 'common/components/contract-expiration-status/contractExpirationStatusUtils';
import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import CountryPicker from 'common/components/country-picker/CountryPicker';
import DateRange from 'common/components/date-range/DateRange';
import DirectoryMoveModalWrapper from 'common/components/directory-move-modal-wrapper/DirectoryMoveModalWrapper';
import DomainEnforcementStatus from 'common/components/domain-enforcement-status/DomainEnforcementStatus';
import InsightsDashboardEtlaUsageReport from 'common/components/etla-usage-report/InsightsDashboardEtlaUsageReport';
import InsightsDashboardWithPicker from 'common/components/etla-usage-report/InsightsDashboardWithPicker';
import SearchAndProductsRail from 'common/components/etla-usage-report/search-and-products-rail/SearchAndProductsRail';
import FileDropZone from 'common/components/file-drop-zone/FileDropZone';
import FormattedDateAndTime from 'common/components/formatted-date-and-time/FormattedDateAndTime';
import PolicyInfoTooltip from 'common/components/global-admin/PolicyInfoTooltip';
import GoUrl from 'common/components/go-url/GoUrl';
import HorizontalList from 'common/components/horizontal-list/HorizontalList';
import HorizontalListItem from 'common/components/horizontal-list/HorizontalListItem';
import HorizontalProductsList from 'common/components/horizontal-products-list/HorizontalProductsList';
import IconHeaderDescription from 'common/components/icon-header-description/IconHeaderDescription';
import IdTypePicker from 'common/components/id-type-picker/IdTypePicker';
import ImageIcon from 'common/components/image-icon/ImageIcon';
import InsightsDateRange from 'common/components/insights/insights-date-range/InsightsDateRange';
import InsightsDetailsLink from 'common/components/insights/insights-details-link/InsightsDetailsLink';
import InsightsDropdown from 'common/components/insights/insights-dropdown/InsightsDropdown';
import InsightsItemDetailFilters from 'common/components/insights/insights-item-detail-filters/InsightsItemDetailFilters';
import LanguagePicker from 'common/components/language-picker/LanguagePicker';
import LinkButton from 'common/components/link-button/LinkButton';
import MoreOptionsMenu from 'common/components/more-options-menu/MoreOptionsMenu';
import OrganizationSwitcher from 'common/components/organization-switcher/OrganizationSwitcher';
import PageBanner from 'common/components/page-banner/PageBanner';
import CustomPill from 'common/components/pill/CustomPill';
import Pill from 'common/components/pill/Pill';
import ProductProfilePill from 'common/components/pill/ProductProfilePill';
import {
  ProductRoleContext,
  ProductRoleLicenseGroupContext,
  ProductRoleMemberContext,
} from 'common/components/pill/ProductRoleContext';
import ProductRolePicker from 'common/components/pill/ProductRolePicker';
import ProductRolePickerSection from 'common/components/pill/ProductRolePickerSection';
import UserGroupPill from 'common/components/pill/UserGroupPill';
import ProductAdminRoleFilter from 'common/components/product-admin-role-filter/ProductAdminRoleFilter';
import ProductAdminRoleFilterConstants from 'common/components/product-admin-role-filter/ProductAdminRoleFilterConstants';
import ProductIconList from 'common/components/product-icon-list/ProductIconList';
import ProductPicker from 'common/components/product-picker/ProductPicker';
import ProductProfileIcon from 'common/components/product-profile-icon/ProductProfileIcon';
import Search from 'common/components/search/Search';
import ShowMoreText from 'common/components/show-more-text/ShowMoreText';
import SurveyReasons from 'common/components/survey-reasons/SurveyReasons';
import TabbedNav from 'common/components/tabbed-nav/TabbedNav';
import TableIconNameCell from 'common/components/table-icon-name-cell/TableIconNameCell';
import TitledSection from 'common/components/titled-section/TitledSection';
import ToggleSection from 'common/components/toggle-section/ToggleSection';
import TooltipButton from 'common/components/tooltip-button/TooltipButton';
import TruncatedText from 'common/components/truncated-text/TruncatedText';
import UserPicker from 'common/components/user-picker/UserPicker';
import UserPickerContext from 'common/components/user-picker/UserPickerContext';
import ValidatedTextField from 'common/components/validated-text-field/ValidatedTextField';
import {
  WIZARD_DISPATCH_ACTION,
  WizardTrigger,
  useWizardContext,
} from 'common/components/wizard/WizardContext';
import WizardView from 'common/components/wizard/WizardView';
import {copyWithAlertToast} from 'common/utils/clipboard';
import {getPolicyDisabledActions} from 'common/utils/global-admin/globalAdminUtils';
import {
  addPandoraContentModel,
  getRootPandoraContentModel,
  getRootPandoraContentValue,
} from 'common/utils/pandora-content/pandoraContentUtils';
import ReactContainerBridge, {
  AngularWrapperContext,
  destroyReactComponent,
  renderReactComponent,
} from 'core/services/ReactContainerBridge';
import {
  getDateFnsLocaleModule,
  getStrings,
  putStrings,
  translateString,
} from 'core/services/locales';
import {getContractDisplayName} from 'features/contract/contractDisplayUtils';
import {getMemberDisplayName, isT2EUser} from 'features/member/memberDisplayUtils';
import {getOrganizationUserErrorMessage} from 'features/organization/organizationUser/organizationUserDisplayUtils';
import {getProductDisplayName} from 'features/product/productDisplayUtils';
import {getPersonName} from 'features/user/personNameUtils';
import Bumper from 'shell/bumper/Bumper';
import Footer from 'shell/footer/Footer';
import HeaderNav from 'shell/header/HeaderNav';
import AlertDialog from 'shell/panels/alert-dialog/AlertDialog';
import {Drawer, DrawerContent, DrawerHeader, useDrawer} from 'shell/panels/drawer';
import {
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
  ModalTagHeader,
  ModalWizardStepList,
  useModalDialog,
} from 'shell/panels/modal-dialog';
import OmniTool from 'shell/panels/omni-tool/OmniTool';
import panelBus from 'shell/panels/panel-bus/panelBus';
import ToastContainer from 'shell/toasts/ToastContainer';
import {showError, showInfo, showSuccess, showWarning} from 'shell/toasts/toastManager';
import WorkspaceNav from 'shell/workspace/nav/WorkspaceNav';
import {Page, PageContent, PageHeader, PageNav} from 'shell/workspace/page';

/**
 * This module provides the API used by AngularJS code to interface with src2. The exported object
 * structure mirrors the src2 file system structure.
 */
const binkyUISrc2 = {
  common: {
    components: {
      addUser: {
        AddUserForm,
        AddUserFormContext,
        AddUserFormEditableFields,
        AddUserFormTable,
        MEMBER_AND_SELECTED_ITEMS_CONSTANTS,
        MemberAndSelectedItemsList,
      },
      AdminRolePicker,
      AlertDialog,
      ASSIGNMENT_MENU_CONSTANTS,
      AssignmentMenu,
      assignmentMenuUtils: {
        LICENSE_ACTIONS,
        LicenseLimitProvider,
        useLicenseLimitContext,
      },
      AssignmentSection,
      AssignmentSectionContext,
      assignmentSectionUtils: {
        toPandoraProductTargets,
        toPandoraUserGroupTargets,
      },
      AuthenticatedUserPullDown,
      Avatar,
      ButtonGroupWithMoreOptions,
      ButtonLink,
      CardButton,
      Chiclet,
      Comment,
      ContractExpirationStatus,
      CopyToClipboardButton,
      CountryPicker,
      CustomPill,
      DateRange,
      DirectoryMoveModalWrapper,
      DomainEnforcementStatus,
      FileDropZone,
      FormattedDateAndTime,
      GoUrl,
      HorizontalList,
      HorizontalListItem,
      HorizontalProductsList,
      IconHeaderDescription,
      IdTypePicker,
      ImageIcon,
      LanguagePicker,
      LinkButton,
      modal: {
        ModalContainer,
        ModalContent,
        ModalDescription,
        ModalDialog,
        ModalHeading,
        ModalTagHeader,
        ModalWizardStepList,
        useModalDialog,
      },
      MoreOptionsMenu,
      OrganizationSwitcher,
      PageBanner,
      Pill,
      PolicyInfoTooltip,
      ProductAdminRoleFilter,
      ProductAdminRoleFilterConstants,
      ProductIconList,
      ProductPicker,
      ProductProfileIcon,
      ProductProfilePill,
      ProductRoleContext,
      ProductRoleLicenseGroupContext,
      ProductRoleMemberContext,
      ProductRolePicker,
      ProductRolePickerSection,
      Search,
      ShowMoreText,
      SurveyReasons,
      TabbedNav,
      TableIconNameCell,
      TitledSection,
      ToggleSection,
      TooltipButton,
      TruncatedText,
      UserGroupPill,
      UserPicker,
      UserPickerContext,
      ValidatedTextField,
      wizard: {
        useWizardContext,
        WIZARD_DISPATCH_ACTION,
        WizardTrigger,
        WizardView,
      },
    },
    utils: {
      clipboard: {copyWithAlertToast},
      pandoraContent: {
        addPandoraContentModel,
        getRootPandoraContentModel,
        getRootPandoraContentValue,
      },
    },
  },
  components: {
    etlaUsageReport: {
      InsightsDashboardEtlaUsageReport,
      InsightsDashboardWithPicker,
      SearchAndProductsRail,
    },
    insights: {
      InsightsDateRange,
      InsightsDetailsLink,
      InsightsDropdown,
      InsightsItemDetailFilters,
    },
  },
  contract: {
    contractDisplayUtils: {
      getContractDisplayName,
    },
    contractExpirationStatusUtils: {
      getContractExpirationStatus,
      getContractExpirationStatusText,
    },
  },
  core: {
    services: {
      locales: {getDateFnsLocaleModule, getStrings, putStrings, translateString},
      reactContainerBridge: {
        AngularWrapperContext,
        destroyReactComponent,
        ReactContainerBridge,
        renderReactComponent,
      },
    },
  },
  globalAdmin: {
    globalAdminUtils: {
      getPolicyDisabledActions,
    },
  },
  member: {
    memberDisplayUtils: {
      getMemberDisplayName,
      isT2EUser,
    },
  },
  organization: {
    organizationUser: {
      organizationUserDisplayUtils: {
        getOrganizationUserErrorMessage,
      },
    },
  },
  personName: {
    personNameUtils: {
      getPersonName,
    },
  },
  product: {
    productDisplayUtils: {
      getProductDisplayName,
    },
  },
  shell: {
    bumper: {
      Bumper,
    },
    Footer,
    header: {
      HeaderNav,
    },
    panels: {
      drawer: {
        Drawer,
        DrawerContent,
        DrawerHeader,
        useDrawer,
      },
      OmniTool,
      panelBus,
    },
    ToastContainer,
    toastManager: {showError, showInfo, showSuccess, showWarning},
    workspace: {
      WorkspaceNav,
    },
    workspaces: {
      page: {
        Page,
        PageContent,
        PageHeader,
        PageNav,
      },
    },
  },
};

export default binkyUISrc2;
/* eslint-enable filenames/match-exported -- necessary to set this filename to a sensible capitalization */
/* eslint-enable @admin-tribe/admin-tribe/include-in-index -- index file not necessary for this file*/
