(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum Toast
   *
   * @ngdoc factory
   * @name binky.widgets.common.toast
   * @description
   * Toasts are short-lived notifications shown in the UI.
   * ToastManager allows you to add a Toast to be shown; the actual showing, timeout,
   * and other behavior is taken care of by the component.
   * This class creates the toast, then removes them from the DOM once they're hidden.
   *
   * To display a toast, call showToast with the desired message and toast type. Valid types are
   * defined in the TOAST_TYPES constant.
   */
  angular.module('binky.widgets.common.toast').factory('toastManager', toastManager);

  /* @ngInject */
  function toastManager(_, binkyUISrc2, TOAST_TYPES) {
    const src2ToastManager = _.get(binkyUISrc2, 'shell.toastManager');
    return {
      showErrorToast: src2ToastManager.showError,
      showSuccessToast: src2ToastManager.showSuccess,
      // showToast is deprecated, code should use type specific show functions instead
      showToast: (variant, msg) => {
        switch (variant) {
          case TOAST_TYPES.ERROR:
            return src2ToastManager.showError(msg);
          case TOAST_TYPES.SUCCESS:
            return src2ToastManager.showSuccess(msg);
          case TOAST_TYPES.WARNING:
            return src2ToastManager.showWarning(msg);
          default:
            return src2ToastManager.showInfo(msg);
        }
      },
    };
  }
})();
