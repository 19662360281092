import {Item, TagGroup} from '@react-spectrum/tag';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

/**
 * TagGroup component used for PageHeader.
 */
const PageHeaderTagGroup = ({'aria-labelledby': ariaLabelledby, productName, tags}) => {
  const intl = useIntl();
  const ariaLabel = useMemo(() => {
    if (ariaLabelledby) {
      return undefined;
    }
    if (productName) {
      return intl.formatMessage(
        {id: 'binky.common.tagGroup.product.ariaLabel.label'},
        {productName}
      );
    }
    return intl.formatMessage({id: 'binky.common.tagGroup.unknown.ariaLabel.label'});
  }, [ariaLabelledby, intl, productName]);

  return (
    tags?.length > 0 && (
      <TagGroup aria-label={ariaLabel} aria-labelledby={ariaLabelledby} items={tags}>
        {(item) => <Item key={item.key}>{item.label}</Item>}
      </TagGroup>
    )
  );
};

PageHeaderTagGroup.displayName = 'PageHeaderTagGroup';
PageHeaderTagGroup.propTypes = {
  /**
   * The id of the label that should be used for this tag group. If present,
   * will override any aria-label that would have been generated
   */
  'aria-labelledby': PropTypes.string,
  /**
   * The name of the product whose tags are being shown
   */
  productName: PropTypes.string,
  /**
   * The list of tags that are displayed
   */
  tags: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string, label: PropTypes.string})),
};

export default PageHeaderTagGroup;
