import JilModelList from 'services/modelList/JilModelList';

import aosOffers from '../../api/aosOffers';
import jilOffers from '../../api/jil/jilOffers';
import jilOrganizationsContractOffers from '../../api/jil/jilOrganizationsContractOffers';
import jilOrganizationsOffers from '../../api/jil/jilOrganizationsOffers';
import feature from '../../services/feature';
import {
  filterOnCanCreatePurchaseAuthorizationUtil,
  filterOnQualifyingFreeOffersUtil,
  getCopyName,
  getPoresOrder,
  sortOffersByFunctionOutput,
} from '../../services/offerList/offerListUtils';

import Offer from './Offer';
import {OFFER_LIST_FILTER, OFFER_LIST_SERVICE_PROVIDER} from './OfferListConstants';

const DEFAULT_PAGE = 0;
// We generally don't want to paginate the results; hopefully 100 is large
// enough to get all the offers.
const LIST_DEFAULT_PAGE_SIZE = 100;

class OfferList extends JilModelList {
  /**
   * @description instantiates a new instance of an OfferList. We don't cache
   *     this in the List because we always want to fetch the latest price data.
   *
   * @param {Object} options - Initialization Object (params described below)
   * @param {Number} [options.page] - The page to fetch, starting from 0
   * @param {Number} [options.pageSize] - The number of items to fetch in a page
   * @param {Object} [options.queryParams] - queryParams to pass to the offers
   *     API call.
   * @returns {OfferList} - OfferList model object.
   */
  static get(options = {}) {
    const model = new OfferList(options);
    return model.refresh();
  }

  /**
   * @description instantiate the list of Offers for an Organization.
   *
   * @param {Object} options - Initialization Object (params described below)
   * @param {string} [options.contractId] - Associates OfferList instance with a
   *     contract.
   * @param {string} [options.filter] - The filter to apply to offers, from
   *     OFFER_LIST_FILTER. Defaults to CONTRACT.
   * @param {string} [options.orgId] - Associates OfferList instance with an org.
   * @param {number} [options.page] - The page to fetch, starting from 0
   * @param {number} [options.pageSize] - The number of items to fetch in a page
   * @param {Object} [options.queryParams] - queryParams to pass along the
   *     offers API call - for example "price_point".
   */
  constructor(options = {}) {
    let offerListFilter = options.filter;
    if (options.queryParams?.intent) {
      offerListFilter = OFFER_LIST_FILTER.PORES;
    }
    super({
      // we don't cache this in the List because we always want to fetch the latest price data
      itemClassRef: Offer,
      resource: getJilResource(offerListFilter),
    });

    this.items = [];
    this.page = options.page || DEFAULT_PAGE;
    this.pageSize = options.pageSize || LIST_DEFAULT_PAGE_SIZE;

    this.queryParams = options.queryParams;
    this.offerIds = options.offerIds;
    this.orgId = options.orgId;
    this.contractId = options.contractId;
    this.offerListFilter = offerListFilter;
  }

  /**
   * @description Method to filter the qualifying offers to only those which can
   *     have a Purchase Authorization created for them.
   *     This is a destructive change to the object.
   */
  filterOnCanCreatePurchaseAuthorization() {
    this.items = filterOnCanCreatePurchaseAuthorizationUtil(this);
  }

  /**
   * @description Method to filter the qualifying free offers for the org
   *   marketSubsegments.
   *   Note: assumes this is a free offer list - 'queryParams: {price_point: 'FREE'}}'
   *   It is also possible that there is not an offer for every
   *   marketSubsegment, or in the future, there may be more than one offer per
   *   marketSubsegment.
   *   This is a destructive change to the object.
   * @param {Object} context - Context provided to filter
   * @param {Organization} [context.organization] - The organization to compare
   *     with for offer qualification.
   * @param {ProductList} [context.ownedProducts] - The current product list to
   *     discard offers that have already been provisioned.
   */
  filterOnQualifyingFreeOffers(context = {}) {
    this.items = filterOnQualifyingFreeOffersUtil(context, this);
  }

  /**
   * @description Method to refresh the Offer List.
   *
   * @return {Promise} resolved when Offer list fetch completes.
   */
  async refresh() {
    if (
      this.contractId ||
      this.offerListFilter === OFFER_LIST_FILTER.SKU ||
      this.offerListFilter === OFFER_LIST_FILTER.OFFER_ID
    ) {
      const queryParams = getQueryParams(this);
      await super.refresh(queryParams);
      onRefreshSuccess.bind(this)();
    } else {
      return Promise.reject(new Error('No contract found'));
    }

    return this;

    ////////

    function getFilterSpecificQueryParams(model) {
      const defaultServiceProvidersString = getDefaultServiceProvidersString();
      switch (model.offerListFilter) {
        case OFFER_LIST_FILTER.NONE:
        case OFFER_LIST_FILTER.SKU:
          return {
            id: model.contractId,
            service_providers: defaultServiceProvidersString,
          };
        case OFFER_LIST_FILTER.PORES:
          return {
            contractId: model.contractId,
            orgId: model.orgId,
            service_providers: defaultServiceProvidersString,
          };
        case OFFER_LIST_FILTER.OFFER_ID:
          return {
            offerIds: model.offerIds,
            service_providers: defaultServiceProvidersString,
          };
        case OFFER_LIST_FILTER.CONTRACT:
        default:
          return {contractId: model.contractId, orgId: model.orgId};
      }
    }

    function getQueryParams(model) {
      const defaultQueryParams = {
        page_size: model.pageSize,
      };

      const filterSpecificQueryParams = getFilterSpecificQueryParams(model);

      const allQueryParams = Object.assign(
        defaultQueryParams,
        filterSpecificQueryParams,
        model.queryParams
      );
      Object.keys(allQueryParams).forEach((key) => {
        if (allQueryParams[key] === undefined) delete allQueryParams[key];
      });
      return allQueryParams;
    }

    function onRefreshSuccess() {
      // we allow for offers to be explicitly filtered by flag, to account for
      // offers which are not supposed to be live in production but have to
      // be put there to enable PAT prior to go live.
      this.items = this.items.filter(
        (offer) => !feature.isEnabled(`filter_offer_${offer.offer_id}`)
      );
      const sortingFunction =
        this.offerListFilter === OFFER_LIST_FILTER.PORES ? getPoresOrder : getCopyName;
      this.items = sortOffersByFunctionOutput(this.items, sortingFunction);

      return this;
    }
  }
}

function getDefaultServiceProvidersString() {
  return Object.values(OFFER_LIST_SERVICE_PROVIDER).join(',');
}

/**
 * @description Get the resource according to the filter
 * There are three APIs to get the offers:
 * 1. {JIL}/offers - returns the offers based on various offer characteristics
 * 2. {JIL}/organization/{orgId}/contracts/{contractId}/offers - returns the
 *     offers appropriate for the supplied contract and mode
 * 3. {JIL}/organization/{orgId}/offers - returns the ordered list of
 *     personalised offers for the given organization.
 *     This is the endpoint of PORES for organizations. PORES stands for
 *     personalized offers recommendation service.
 *     More information about PORES in general: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=JIL2&title=Personalised+Offers+and+PORES
 *     API spec for Admin Console integration with PORES: https://wiki.corp.adobe.com/x/Q_Jjag
 *  4. {AOS}/offers - returns the offers based on various offer characteristics
 *  5. {AOS}/offers:search.sku - returns the offers based on SKU
 *
 * Note: the ultimate goal is to deprecate /organization/{orgId}/contracts/{contractId}/offers and move everything to
 * PORES endpoint /organization/{orgId}/offers, but since the JIL team doesn't have the capacity to do so,
 * we are only using PORES for the new purchase workflow.
 *
 * @param {OFFER_LIST_FILTER} filter - list of items that we want to get item
 *     from.
 * @returns {Resource} - The resource for the API.
 */
function getJilResource(filter) {
  switch (filter) {
    case OFFER_LIST_FILTER.NONE:
      return jilOffers.getOffers;
    case OFFER_LIST_FILTER.OFFER_ID:
      return feature.isEnabled('temp_aos_offers_endpoint')
        ? aosOffers.getOffersByOfferId
        : jilOffers.getOffersByOfferId;
    case OFFER_LIST_FILTER.PORES:
      return jilOrganizationsOffers.getOffers;
    case OFFER_LIST_FILTER.SKU:
      return feature.isEnabled('temp_aos_offers_endpoint')
        ? aosOffers.getOffersBySku
        : jilOffers.getOffersBySku;
    case OFFER_LIST_FILTER.CONTRACT:
    default:
      return jilOrganizationsContractOffers.getOffers;
  }
}

export default OfferList;
