(function () {
  /**
   * @deprecated Use src2/core/api/ccs.js for free offers cart POST
   *
   * @ngdoc service/provider
   * @name ccs
   * @description defines service to fetch ccs resources
   */
  angular.module('binky.core.api.ccs').provider('ccs', ccsProvider);

  /* @ngInject */
  function ccsProvider(_) {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${_.get(config, 'url')}`;
    }

    /* @ngInject */
    function $get($resource, authentication, apiUtils, feature, locale) {
      const service = {
        cart: getCartResource(),
        resellerCart: getResellerCartResource(),
      };

      return service;

      ///////////

      function getCartResource() {
        const headers = getSharedHeaders();

        return $resource(
          `${url}/organizations/:orgId/carts/:cartId`,
          {
            cartId: '@cartId',
            orgId: apiUtils.getActiveOrgId,
          },
          {
            get: {
              headers,
              method: 'GET',
            },
            put: {
              headers,
              method: 'PUT',
            },
            save: {
              headers,
              method: 'POST',
            },
          }
        );
      }

      function getResellerCartResource() {
        const headers = getSharedHeaders();

        return $resource(
          `${url}/resellers/:resellerId/organizations/:orgId/carts/:cartId`,
          {
            cartId: '@cartId',
            orgId: apiUtils.getActiveOrgId,
            resellerId: '@resellerId',
          },
          {
            get: {
              headers,
              method: 'GET',
            },
            put: {
              headers,
              method: 'PUT',
            },
            save: {
              headers,
              method: 'POST',
            },
          }
        );
      }

      function getSharedHeaders() {
        const headers = {
          'Content-Type': 'application/json',
          'x-user-auth': authentication.getAccessToken(),
        };

        // Pass the `Accept-Language` header through to CCS when it is called in modals.
        // resolves: french canadian local currency mismatch between jil and ccs responses within modals
        // An agreement has been made with CCS: Using this Accept-Language header and country value (attained at their end relating to the contract)
        // CCS will return the French Canadian formatString to AC.
        let lang = locale.getActiveLocaleCode();

        if (lang === 'zh_CN' || lang === 'zh_TW') {
          lang = locale.getActiveLanguageBCP47Code();
        }

        _.assign(headers, {'Accept-Language': lang});

        return headers;
      }
    }
  }
})();
