(function () {
  'use strict';
  /**
   * @deprecated by the UserRoles component in src2
   */
  angular.module('binky.widgets.user.user-role').component('binkyUserRole', {
    bindings: {
      isLast: '<',
      longName: '<?',
      role: '@',
    },
    controller,
    template: '<span>{{ $ctrl.roleName }}</span>',
  });

  /* @ngInject */
  function controller(
    $log,
    $translate,
    _,
    binkyUserRoleNameFilter,
    feature,
    PRODUCT_ROLE,
    translationUtils,
    USER_ROLE
  ) {
    const vm = this;

    const ROLES = [
      {
        role: USER_ROLE.ADOBE_AGENT.ADMIN,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.admin',
      },
      {
        role: USER_ROLE.ADOBE_AGENT.CUSTOMER_CARE,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.customerCare',
      },
      {
        role: USER_ROLE.ADOBE_AGENT.PROFESSIONAL_SERVICES,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.professionalServices',
      },
      {
        role: USER_ROLE.ADOBE_AGENT.PROVISIONER,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.provisioner',
      },
      {
        role: USER_ROLE.ADOBE_AGENT.READ,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.read',
      },
      {
        role: USER_ROLE.ADOBE_AGENT.RESELLER_LICENSING,
        translateKey: 'binky.widgets.user.userRole.adobeAgent.resellerLicensing',
      },
      {
        role: USER_ROLE.COMPARTMENT_ADMIN,
        translateKeySuffix: 'compartmentAdmin',
      },
      {
        role: USER_ROLE.COMPARTMENT_VIEWER,
        translateKeySuffix: 'compartmentViewer',
      },
      {
        role: USER_ROLE.CONTRACT_ADMIN,
        translateKeySuffix: 'contract',
      },
      {
        role: USER_ROLE.DEPLOYMENT_ADMIN,
        translateKeySuffix: 'deployment',
      },
      {
        role: USER_ROLE.LICENSE_ADMIN,
        translateKeySuffix: 'license',
      },
      {
        role: USER_ROLE.ORG_ADMIN,
        translateKeySuffix: 'org',
      },
      {
        role: USER_ROLE.PRODUCT_ADMIN,
        translateKeySuffix: 'product',
      },
      {
        role: USER_ROLE.PRODUCT_SUPPORT_ADMIN,
        translateKeySuffix: 'productSupport',
      },
      {
        role: USER_ROLE.STORAGE_ADMIN,
        translateKeySuffix: 'storage',
      },
      {
        role: USER_ROLE.SUPPORT_ADMIN,
        translateKeySuffix: 'support',
      },
      {
        role: USER_ROLE.USER_GROUP_ADMIN,
        translateKeySuffix: 'userGroup',
      },
      {
        role: PRODUCT_ROLE.APPROVER,
        translateKey: 'binky.widgets.user.userRole.product.approver',
      },
      {
        role: PRODUCT_ROLE.EDITOR,
        translateKey: 'binky.widgets.user.userRole.product.editor',
      },
      {
        role: PRODUCT_ROLE.OBSERVER,
        translateKey: 'binky.widgets.user.userRole.product.observer',
      },
      {
        role: PRODUCT_ROLE.PUBLISHER,
        translateKey: 'binky.widgets.user.userRole.product.publisher',
      },
    ];

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      const foundRole = _.find(ROLES, {role: vm.role});
      if (_.get(foundRole, 'translateKey')) {
        vm.roleName = getAdminRoleName(foundRole.translateKey);
      } else if (_.get(foundRole, 'translateKeySuffix')) {
        vm.roleName = getAdminRoleNameFromSuffix(foundRole.translateKeySuffix);
      } else {
        vm.roleName = '';
      }
    }

    function getAdminRoleName(key) {
      let name = translationUtils.sanitizeSafeInstant(key);
      if (!vm.isLast) {
        name += $translate.instant('binky.widgets.user.userRole.separator');
      }
      return name;
    }

    function getAdminRoleNameFromSuffix(key) {
      const version = vm.longName ? 'longName' : 'shortName';
      let name = $translate.instant(`binky.widgets.user.userRole.admin.${key}.${version}`);
      if (!vm.isLast) {
        name += $translate.instant('binky.widgets.user.userRole.separator');
      }
      return name;
    }
  }
})();
