import {TableView} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import EmptyTableStateMessage from './empty-table-state-message/EmptyTableStateMessage';
import {useTableSectionContext} from './section/TableSectionContext';

/**
 * @deprecated use Pandora adminstration Table:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/table
 *
 * Table with default settings. Relies on a Store to define the current
 * state of the table. The Store is fetched through useTableSectionContext
 * which should be set using TableSectionContextProvider that is wrapped
 * around the TableSection.
 */
const Table = observer(
  ({
    ariaLabel,
    children,
    noItemsFoundMessage,
    noSearchResultsContentMessage,
    noSearchResultsHeadingMessage,
  }) => {
    const {listStore} = useTableSectionContext();

    // Event handlers
    const onSelectionChange = (selectionSetOrAll) => {
      listStore.tableStore.setSelection(selectionSetOrAll);
    };

    const onSortChange = (descriptor) => {
      listStore.onSortBy(descriptor);
    };

    return (
      listStore?.tableStore && (
        <TableView
          aria-label={ariaLabel}
          density={listStore.tableStore.density}
          disabledKeys={listStore.tableStore.disabledKeys}
          height={listStore.tableStore.isEmptyTable ? 'size-5000' : undefined}
          onSelectionChange={onSelectionChange}
          onSortChange={onSortChange}
          overflowMode={listStore.tableStore.overflowMode}
          renderEmptyState={() => (
            <EmptyTableStateMessage
              noItemsFound={noItemsFoundMessage}
              noSearchResultsContent={noSearchResultsContentMessage}
              noSearchResultsHeading={noSearchResultsHeadingMessage}
            />
          )}
          selectedKeys={listStore.tableStore.selectedKeys}
          selectionMode={listStore.tableStore.selectionMode}
          sortDescriptor={listStore.tableStore.sortDescriptor}
        >
          {children}
        </TableView>
      )
    );
  }
);

Table.displayName = 'Table';
Table.propTypes = {
  /**
   * The aria-label for the table.
   */
  ariaLabel: PropTypes.string.isRequired,
  /**
   * The TableHeader and TableBody of the table.
   */
  children: PropTypes.node.isRequired,
  /**
   * The string displayed when no items are found when the table loads.
   * All the empty state strings are required to ensure consistency in
   * states across tables.
   */
  noItemsFoundMessage: PropTypes.string.isRequired,
  /**
   * The content displayed when there are no results from a search.
   * This is required if the table supports search.
   */
  noSearchResultsContentMessage: PropTypes.string,
  /**
   * The heading displayed when there are no results from a search.
   * This is required if the table supports search.
   */
  noSearchResultsHeadingMessage: PropTypes.string,
};

export default Table;
