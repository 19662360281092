import {toBinkyProduct} from '@admin-tribe/binky';
import React from 'react';

import MemberAndSelectedItemsList from './MemberAndSelectedItemsList';

const EVENT_TYPE = {
  CHANGE_ERROR_STATUS: 'CHANGE_ERROR_STATUS',
  CHANGE_ITEM: 'CHANGE_ITEM',
  CHANGE_LOADING_STATUS: 'CHANGE_LOADING_STATUS',
  CHANGE_MEMBER: 'CHANGE_MEMBER',
  CHANGE_PRODUCT_ROLES: 'CHANGE_PRODUCT_ROLES',
  CHANGE_QUERY_TEXT: 'CHANGE_QUERY_TEXT',
  ITEM_COUNT_CHANGED: 'ITEM_COUNT_CHANGED',
  REMOVE_ROW: 'REMOVE_ROW',
};

/**
 * @param {Number} defaultUserListSize The default user list size
 * @param {Number} maxUserListSize The max user list size
 * @returns {Object} object that contains
 * - {Function} changeItemForId a callback function to update state on item assignment change
 * - {Function} changeMemberForId a callback function to update state on member change
 * - {Function} removeMemberAndSelectedItemsForId a callback function to update state on item removal
 * - {UsersDeteailsAndSelectedItems} memberAndSelectedItemsList the current state of the table
 */
const useAddUserFormTable = ({
  defaultMemberListSize,
  maxMemberListSize,
  validateSelectedMemberForItem,
}) => {
  // eslint-disable-next-line complexity -- handle each case
  const [memberAndSelectedItemsList, dispatch] = React.useReducer((state, action) => {
    const newState = new MemberAndSelectedItemsList(state);

    switch (action.type) {
      case EVENT_TYPE.CHANGE_PRODUCT_ROLES: {
        newState.updateProductRoles(action);
        break;
      }
      case EVENT_TYPE.CHANGE_ITEM:
        newState.updateSelectedItems({id: action.id, value: action.value});
        break;
      case EVENT_TYPE.CHANGE_MEMBER: {
        const item = newState.updateMember({id: action.id, value: action.value});
        if (validateSelectedMemberForItem) {
          validateSelectedMemberForItem(item);
        }
        break;
      }
      case EVENT_TYPE.CHANGE_QUERY_TEXT: {
        newState.changeQueryText(action);
        break;
      }
      case EVENT_TYPE.CHANGE_ERROR_STATUS: {
        const {op, value} = action.value;
        newState.changeErrorStatus({id: action.id, op, status: value});
        break;
      }
      case EVENT_TYPE.CHANGE_LOADING_STATUS: {
        const {op, value} = action.value;
        newState.changeLoadingStatus({id: action.id, op, status: value});
        break;
      }
      case EVENT_TYPE.ITEM_COUNT_CHANGED: {
        newState.updateMaxItemCount(action.value);
        break;
      }
      case EVENT_TYPE.REMOVE_ROW: {
        newState.removeItem({id: action.id});
        break;
      }
      default:
        return state;
    }
    if (newState.shouldGenerateNewItem()) {
      newState.generateNewItems(1);
    }
    return newState;
  }, new MemberAndSelectedItemsList({defaultMemberListSize, maxMemberListSize}));

  const changeFormValueForId = (id) => (value) => {
    dispatch({id, type: value.type, value});
  };

  const changeItemForId = (id) => (selectedItems) => {
    dispatch({id, type: EVENT_TYPE.CHANGE_ITEM, value: selectedItems});
  };

  const changeProductRolesForId = (id) => (productRoles) => {
    dispatch({id, type: EVENT_TYPE.CHANGE_PRODUCT_ROLES, value: productRoles});
  };

  const changePandoraProductRolesForId = (id) => (pandoraProductRoles) => {
    const binkyRoles = pandoraProductRoles.map((pandoraRole) => ({
      // We mustn't forget to convert the Pandora models to the Binky equivalents here.
      // Product should be the only such model in the OnProductRoleChangeProps type.
      ...pandoraRole,
      product: toBinkyProduct(pandoraRole.product),
    }));
    dispatch({id, type: EVENT_TYPE.CHANGE_PRODUCT_ROLES, value: binkyRoles});
  };

  const removeMemberAndSelectedItemsForId = (id) => () => {
    dispatch({id, type: EVENT_TYPE.REMOVE_ROW});
  };

  /**
   * Rerender the AddUserFormTable with the correct maximum rows when the limit is updated
   */
  React.useEffect(() => {
    dispatch({type: EVENT_TYPE.ITEM_COUNT_CHANGED, value: maxMemberListSize});
  }, [maxMemberListSize]);

  return {
    changeFormValueForId,
    changeItemForId,
    changePandoraProductRolesForId,
    changeProductRolesForId,
    dispatch,
    memberAndSelectedItemsList,
    removeMemberAndSelectedItemsForId,
  };
};

export default useAddUserFormTable;
export {EVENT_TYPE};
