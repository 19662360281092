(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum ListBox
   *
   * @ngdoc directive
   * @name binky.widgets.common.select-list:binkySelectList
   *
   * @description a wrapper around the Coral select-list widget
   *
   * @param {Function} onChange A function to call when the selected item for this list changes
   * @param {object} filter The filter object to apply to the repeat
   * @param {collection} items The set of items to repeat over inside this list
   * @param {string} nameKey The field key to use as the name
   * @param {string} selectedValue The value to match against on initial load. Note this is not updated as the selection changes
   * @param {string} valueKey The field key to use as the value
   *
   * @example <binky-select-list name-key="name" value-key="id" items="orgs" on-change="myCallback"></binky-select-list>
   *
   *
   */
  angular
    .module('binky.widgets.common.select-list')
    .directive('binkySelectList', binkySelectListDirective);

  function binkySelectListDirective($timeout, _) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        filter: '<?',
        items: '<',
        nameKey: '@',
        onChange: '<?',
        selectedValue: '@',
        valueKey: '@',
      },
      templateUrl: 'widgets/common/select-list/select-list.directive.html',
    };

    return directive;

    /////////////////

    function linkFn(scope, element) {
      let currentValue = scope.selectedValue;
      // As we're instantiating via HTML we have to wait for Coral to complete
      // its processing of the HTML before attaching an event listener. Not
      // doing this results in the element.find returning empty.
      const selectList = element.find('coral-selectlist');
      selectList.on('coral-selectlist:change', onSelectListChange);
      selectList.on('coral-selectlist:beforechange', onSelectListBeforeChange);

      function onSelectListBeforeChange(evt) {
        // We prevent the selection to change if we're in single selection and the clicked item is already selected
        if (evt.detail.item.selected) {
          evt.preventDefault();
        }
      }

      function onSelectListChange(evt) {
        const newValue = _.get(evt, 'target.selectedItem.value');
        // Oddities between Coral and Angular mean we can end up seeing this event during redraw
        if (newValue && scope.onChange && currentValue !== newValue) {
          currentValue = newValue;
          // Timeout is necessary to avoid a loop of scope.$apply
          $timeout(() => {
            scope.onChange(newValue);
          });
        }
      }
    }
  }
})();
