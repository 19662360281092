import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import {MEMBER_TECH_ACCOUNT_DOMAIN} from 'models/member/type/MemberTypeConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import modelCache from 'services/cache/modelCache/modelCache';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import log from 'services/log';
import MemberList from 'services/member/MemberList';
import UserGroup from 'services/users/user-group/UserGroup';

import {
  LICENSE_GROUP_USERS_API_QUERY_PARAMS,
  LICENSE_GROUP_USER_LIST_CACHE_ID,
  LICENSE_GROUP_USER_LIST_EVENT,
} from './LicenseGroupUserListConstants';

class LicenseGroupUserList extends MemberList {
  constructor(options) {
    super({
      filterExcludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
      itemClassRef: [OrganizationUser, UserGroup],
      modelCacheId: LICENSE_GROUP_USER_LIST_CACHE_ID,
      refreshResource: jilOrganizationsProductsLicenseGroups.getLicenseGroupUserList,
      saveResource: jilOrganizationsProductsLicenseGroups.patchLicenseGroupUserList,
      transformResponseData,
      ...options,
    });
    this.licenseGroupId = options.licenseGroupId;
    this.productId = options.productId;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      include: feature.isEnabled('temp_user_provisioning_status')
        ? [LICENSE_GROUP_USERS_API_QUERY_PARAMS.PROVISIONING_STATUS]
        : [],
      licenseGroupId: this.licenseGroupId,
      productId: this.productId,
    };
  }

  /**
   * @description Method to execute after a successful refresh.
   */
  onRefreshSuccess() {
    eventBus.emit(LICENSE_GROUP_USER_LIST_EVENT.UPDATE.LIST, this.licenseGroupId);
    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(LICENSE_GROUP_USER_LIST_EVENT.UPDATE.COUNT, this.pagination.itemCount);
    }
  }

  /**
   * @description Method to save changes to license group list to back-end.
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message.
   */
  save() {
    return super.save(getSaveParams(this), {refresh: false});
  }

  /**
   * @description Method to update the role for a set of members in the list on the back-end.
   * @param {Array} members - members within this list to perform the operation on
   * @param {Number} role - the role to assign to those members for this list
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message
   */
  async saveMemberProductRole(members) {
    const operations = getSaveOperations(members);

    try {
      await jilOrganizationsProductsLicenseGroups.patchLicenseGroupUserList({
        licenseGroupId: this.licenseGroupId,
        operations,
        orgId: this.orgId,
        productId: this.productId,
      });
    } catch (error) {
      log.error('Failed to update role for selected users in configuration. Error: ', error);
      return Promise.reject(error);
    }

    // Since a member's productRole isn't (and can't easily be) reflected in the cache key,
    // if a productRole is modified make sure the next 'get' will not use a stale cache entry.
    modelCache.clear(this.modelCacheId);

    members.forEach((member) => {
      eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
    });
    return this;
  }
}

function getSaveOperations(members) {
  return members.map((member) => ({
    op: 'replace',
    path: `/${member.id}/${member.productRole}`,
  }));
}

function transformResponseData(responseData, classRefs) {
  return responseData.map((responseItem) => {
    const ClassRef = classRefs.find((ref) => ref.canTransform(responseItem));
    return new ClassRef(responseItem);
  });
}

///////////

/**
 * @description Get params used for API calls.
 *
 * @param {Object} model the model to pick params from.
 * @returns {Array} params to be passed to JIL or used as a key.
 */
function getSaveParams(model) {
  return {
    licenseGroupId: model.licenseGroupId,
    productId: model.productId,
  };
}

export default LicenseGroupUserList;
