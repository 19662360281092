(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyContractTaxRate
   * @description return tax rate string, given tax term for country and tax rate.Returns the abbreviated tax suffix label, appropriate for communicating the inclusion or
   * exclusion of tax in the specified country, e.g. 'incl. GST' or 'excl. VAT'.
   * @param {Contract} contract model instance.
   * @param {Function} contract.getOwnerCountryCode returns two digit country code, e.g. 'US.
   * @param {Boolean} includesTax true to represent inclusion of tax, else false.
   * @return {String} the tax suffix label.
   * @example <div> {{ $ctrl.contract | binkyContractTaxSuffixLabel:$ctrl.includesTax }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.commerce')
    .filter('binkyContractTaxSuffixLabel', taxSuffixLabelFilter);

  /* @ngInject */
  function taxSuffixLabelFilter($translate, _, commerceUtils) {
    return getTaxSuffixLabel;

    ////////////////

    function getTaxSuffixLabel(contract, includesTax, includeParenthesis = false) {
      const taxTerm = commerceUtils.getTaxTerm(_.invoke(contract, 'getOwnerCountryCode'));
      const parenthesis = includeParenthesis ? 'WithParenthesis' : '';
      return $translate.instant(
        includesTax
          ? `binky.widgets.commerce.inclTax${parenthesis}.${taxTerm}`
          : `binky.widgets.commerce.exclTax${parenthesis}.${taxTerm}`
      );
    }
  }
})();
