import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {Children} from 'react';

import InfoBar from 'common/components/info-bar/InfoBar';

/**
 * InfoBar component used for Page.
 * Displays InfoItem and InfoItemScorecard components, each seperated by a vertical Divider.
 */
const PageInfoBar = ({children}) => {
  const infoItems = Children.toArray(children);

  return (
    infoItems.length > 0 && (
      <View data-testid="page-info-bar">
        <InfoBar>{children}</InfoBar>
      </View>
    )
  );
};

PageInfoBar.displayName = 'PageInfoBar';
PageInfoBar.propTypes = {
  /**
   * InfoItem and InfoItemScorecard elements to display.
   */
  children: PropTypes.node.isRequired,
};

export default PageInfoBar;
