(function () {
  'use strict';
  /**
   * @deprecated use the React Spectrum ActionButton and Menu
   *
   * @ngdoc component
   * @name binky.widgets.common.icon-tooltip
   *
   * @description an icon with a tooltip attached
   *
   * @param {string} [icon] The icon to show (from Coral.Icon). Default: info
   * @param {string} [iconSize] The size of icon to render. One of: S, M, L, XL. Default: S
   * @param {Function} [onMouseEnter] A callback function for when a mouse-enter event happens
   * @param {string} [tooltipContentString] DEPRECATED. The string to display inside the tooltip.
   * @param {string} [tooltipPlacement] The placement of the tooltip (from Coral.Tooltip). Default: right
   * @param {string} [tooltipVariant] The variant of tooltip to use (from Coral.Tooltip). Default: info
   * @param {Transclude} [content] The tooltip content. This should be preferred over the legacy tooltipContentString.
   *
   * @example <binky-icon-tooltip icon-size="M" icon="alert"></binky-icon-tooltip>
   *
   *
   */

  angular.module('binky.widgets.common.icon-tooltip').component('binkyIconTooltip', {
    bindings: {
      icon: '@?',
      iconSize: '@?',
      onMouseEnter: '&?',
      tooltipContentString: '@?',
      tooltipPlacement: '@?',
      tooltipVariant: '@?',
    },
    controller,
    transclude: true,
  });

  /* @ngInject */
  function controller(
    $compile,
    $element,
    $scope,
    $timeout,
    $transclude,
    _,
    ICON_TOOLTIP_MOUSELEAVE_DELAY
  ) {
    const vm = this;

    let coralTooltip;

    _.assign(vm, {
      $onInit,
      $postLink,
    });

    function $onInit() {
      _.assign(vm, {
        icon: vm.icon || 'info',
        iconSize: vm.iconSize || 'S',
        tooltipPlacement: vm.tooltipPlacement || 'right',
        tooltipVariant: vm.tooltipVariant || 'info',
      });
    }

    function $postLink() {
      const coralIcon = createCoralIcon();
      createCoralTooltip(coralIcon);

      $element.append(coralIcon);
      $element.append(coralTooltip);

      // Keep the tooltip open if the pointer is on the target element. Toggle open/closed on keypress
      // This isn't default Coral.Tooltip behaviour
      vm.lastMouseEnter = Date.now();
      $element.on('mouseenter', onMouseEnter);
      $element.on('mouseleave', () => {
        const delay = hasAnchorContent() ? ICON_TOOLTIP_MOUSELEAVE_DELAY : 0;
        const lastMouseLeave = Date.now();
        $timeout(() => {
          // if a new mouseenter happens after the last mouseleave event,
          // keep the tooltip open
          if (lastMouseLeave >= vm.lastMouseEnter) {
            coralTooltip.open = false;
          }
        }, delay);
      });

      // pass through angular.element() first to enable unit tests to trigger handlers on
      // the tooltip object
      const coralTooltipElement = angular.element(coralTooltip);
      coralTooltipElement.on('mouseenter', onMouseEnter);
      coralTooltipElement.on('mouseleave', () => {
        coralTooltip.open = false;
      });

      // we register this on the scope to allow for unit testing
      // as Coral blocks other approaches
      vm.onKeyPress = () => {
        coralTooltip.open = !coralTooltip.open;
      };
      coralIcon.on('keypress', vm.onKeyPress);

      $transclude(($linkNode, scope) => {
        const contentElement = coralTooltipElement.find('coral-tooltip-content');
        contentElement.empty().append($compile($linkNode)(scope));
      });

      function hasAnchorContent() {
        return $element.find('binky-go-url').length > 0 || $element.find('a').length > 0;
      }
    }

    function onMouseEnter() {
      vm.lastMouseEnter = Date.now();
      coralTooltip.open = true;

      _.invoke(vm, 'onMouseEnter');
    }

    ////////////

    function createCoralIcon() {
      const coralElement = new Coral.Icon();
      coralElement.set({
        icon: vm.icon,
        size: vm.iconSize,
        tabIndex: 0,
      });
      coralElement.setAttribute(
        'class',
        `${coralElement.getAttribute('class')} binky-tooltip-icon`
      );
      coralElement.setAttribute('tabIndex', '0');
      angular.element(coralElement).on('click', (e) => e.stopPropagation());

      return coralElement;
    }

    function createCoralTooltip(target) {
      coralTooltip = new Coral.Tooltip();
      coralTooltip.set({
        interaction: 'off',
        placement: vm.tooltipPlacement,
        target,
        variant: vm.tooltipVariant,
      });
      angular.element(coralTooltip).on('click', (e) => e.stopPropagation());
      setTooltipContentFromString();
      $scope.$watch('$ctrl.tooltipContentString', setTooltipContentFromString);
      return coralTooltip;
    }

    function setTooltipContentFromString() {
      if (vm.tooltipContentString) {
        const contentElement = angular.element(coralTooltip).find('coral-tooltip-content');
        contentElement.empty().append(vm.tooltipContentString);
      }
    }
  }
})();
