import {
  Content,
  Heading,
  IllustratedMessage as SpectrumIllustratedMessage,
  View,
} from '@adobe/react-spectrum';
import NotFoundIllustration from '@spectrum-icons/illustrations/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * @deprecated use Pandora adminstration IllustratedMessage:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-illustrated-message
 *
 * Component to render a message with an illustration, a header and optional content in a standard way.
 * If an illustration is not provided, the 'Not Found' illustration will be used.
 *
 * These are especially useful to represent the various empty states in a Table.
 */
const IllustratedMessage = ({content, heading, headingLevel, illustration}) => {
  const intl = useIntl();

  const headingText =
    heading || intl.formatMessage({id: 'binky.common.illustratedMessage.noResultsFound.heading'});

  return (
    <View padding="size-500">
      <SpectrumIllustratedMessage>
        {illustration || <NotFoundIllustration />}
        <Heading level={headingLevel}>{headingText}</Heading>
        {content && <Content>{content}</Content>}
      </SpectrumIllustratedMessage>
    </View>
  );
};

IllustratedMessage.propTypes = {
  /**
   * Optional content.
   */
  content: PropTypes.string,
  /**
   * The heading. The default is "No results found".
   */
  heading: PropTypes.string,
  /**
   * The header level of the message. The Heading component default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Optional illustration. The default is the NotFound illustration.
   */
  illustration: PropTypes.node,
};

export default IllustratedMessage;
