(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/bumper/Bumper.jsx
   *
   * @ngdoc component
   * @name binky.shell.bumper:binkyBumper
   *
   * @description a bumper component which can be embedded in larger pages
   *
   * @param {String} [activeOrgId] The ID of the active org.
   * @param {String} [button] The key to display on the button
   * @param {String} [details] The key to use for the details text
   * @param {String} [icon] The icon to display
   * @param {Array[LinkedUserAccount]} linkedUserAccounts The linked user accounts to display for org switching.
   * @param {String} [moreDetails] The 2nd detail paragraph that may contain html such
   *   as a go-url. The paragraph has the translate-compile attribute on it
   * @param {Function} [onButtonClick] The function to invoke on clicking the button
   * @param {Function} [onOrgChange] The function to invoke on selecting a different org
   * @param {Function} [onSecondaryButtonClick] The function to invoke on clicking the secondary button
   * @param {String} [secondaryButton] The key to display on the secondary button
   * @param {String} [titleKey] The key to use for the title text
   * @param {Object} [translateValues] Object that wraps translate values for any of the string keys above.
   * @param {String} [type] The type of bumper. Should use the bumper constants
   *
   */
  angular.module('binky.shell.bumper').component('binkyBumper', {
    bindings: {
      activeOrgId: '<?',
      button: '@?',
      details: '@?',
      icon: '@?',
      linkedUserAccounts: '<',
      moreDetails: '@?',
      onButtonClick: '<?',
      onOrgChange: '&?',
      onSecondaryButtonClick: '<?',
      secondaryButton: '@?',
      showOrgSwitcher: '<?',
      titleKey: '@?',
      translateValues: '<?',
      type: '@?',
    },
    controller,
    templateUrl: 'shell/bumper/bumper.component.html',
  });

  function controller(_, $window, BUMPER_TYPE, feature, ORGANIZATION_TYPE) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      filterToOrgTypes: [ORGANIZATION_TYPE.DIRECT, ORGANIZATION_TYPE.ENTERPRISE],
    });

    /////////////

    function $onInit() {
      const type = vm.type || BUMPER_TYPE.DEFAULT;

      // We register an object for this, as '@' binding doesn't allow for change
      // inside the component, and the other binding types make for uglier code outside it
      vm.state = {
        button: vm.button,
        details: vm.details,
        icon: vm.icon,
        moreDetails: vm.moreDetails,
        secondaryButton: vm.secondaryButton,
        titleKey: vm.titleKey,
      };

      switch (type) {
        case BUMPER_TYPE.CUSTOM:
          // We do nothing here, as custom types must specify all the values they want
          break;
        case BUMPER_TYPE.DEFAULT:
        default:
          vm.state.button = vm.state.button || 'binky.shell.bumper.button.primary';
          vm.state.details = vm.state.details || 'binky.shell.bumper.details';
          vm.state.icon = vm.state.icon || 'cloud';
          vm.state.titleKey = vm.state.titleKey || 'binky.shell.bumper.title';
          vm.onButtonClick =
            vm.onButtonClick ||
            function () {
              $window.history.back();
            };
          break;
      }
    }
  }
})();
