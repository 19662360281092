(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.person-name
   * @description module that defines person name widgets
   */
  angular.module('binky.widgets.common.person-name', [
    'binky.core.locale',
    'binky.core.user.authenticated-user',
    'pascalprecht.translate',
  ]);
})();
