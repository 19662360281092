(function () {
  /**
   * @deprecated Please use src2/core/services/modelList/ModelListConstants.js
   *
   * @ngdoc constant
   * @name LIST_DEFAULT_PAGE_SIZE
   * @description defines default page size for paginated api responses
   */
  angular.module('binky.core.common.list').constant(
    'LIST_DEFAULT_PAGE_SIZE',
    // eslint-disable-next-line angular/window-service
    window.binkySrc2.services.modelList.MODEL_LIST_CONSTANTS.LIST_DEFAULT_PAGE_SIZE
  );
})();
