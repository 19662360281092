import {Store} from '@admin-tribe/binky';
import {action, makeObservable, observable} from 'mobx';

import TableStore from './TableStore';

// The store that integrates a next token paging service with a paginated table.
class TokenPagingTableStore extends Store {
  searchInputValue = ''; // The visible Search input string

  tableStore;
  tokenService;

  /**
   * @deprecated use Pandora TableSection that comes with built-in state management
   *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section
   *  The TableSection uses ListContainer for state management:
   *  https://git.corp.adobe.com/PandoraUI/ui-components/tree/master/packages/react-list-container
   *
   *  The TokenPagingTableStore constructor.
   *
   *  @param {TableConstants.DENSITY} density - the amount of white space around each row.
   *   The default is TableConstants.DENSITY.SPACIOUS.
   *  @param {Function} [navigationCallback] - Callback to get the various links needed for this workflow.
   *    This method relies on the Angular router's $state.href().
   *  @param {TableConstants.OVERFLOW_MODE} overflowMode - sets the overflow behavior for the cell contents.
   *   By default, the TableView's default will be used.
   *  @param {Integer} [pageSize] - the number of rows per page in the table.
   *   The default is PaginationConstants.DEFAULT_PAGE_SIZE which is 20.
   *  @param {Array<Integer>} [pageSizeOptions] - the array of page sizes to select from. The default is PaginationConstants.PAGE_SIZES
   *  @param {TableConstants.SELECTION_MODE} [selectionMode] - the selection mode for the table.
   *    The default is TableConstants.SELECTION_MODE.MULTIPLE. If TableConstants.SELECTION_MODE.SINGLE, there will
   *    be no `Select all checkbox`. If TableConstants.SELECTION_MODE.NONE there will be no selection checkboxes.
   *  @param {Object} [sortDescriptor] - the descriptor for the active sort column.
   *  @param {String} [sortDescriptor.name] - the name of the column to sort. This should be the same 'key' used for the sort <Column>. Defaults to 'name'.
   *  @param {String} [sortDescriptor.direction] - one of TableConstants.SORT.ASCENDING or TableConstants.SORT.DESCENDING.
   *  @param {Object} [tokenService] - the list service to fetch that uses token paging through its attribute 'state', which can be a type of ListState.
   */
  constructor({
    density,
    navigationCallback,
    overflowMode,
    pageSize,
    pageSizeOptions,
    selectionMode,
    sortDescriptor,
    tokenService,
  } = {}) {
    super();

    makeObservable(this, {
      getAdminDetailsHref: action.bound,
      getUserDetailsHref: action.bound,
      goToNextPage: action.bound,
      goToPreviousPage: action.bound,
      hasNextPage: action.bound,
      hasPreviousPage: action.bound,
      onPageSizeChange: action.bound,
      onSortBy: action,
      resetPageState: action,
      search: action,
      searchInputValue: observable,
    });

    // Set this to true right away so the table doesn't have a chance to think it is empty.
    this.isLoading = true;

    // Callback to get the various links needed for the workflow.
    // This method relies on the Angular router's $state.href() method.
    this.navigationCallback = navigationCallback;

    this.tokenService = tokenService;

    this.tableStore = new TableStore({
      density,
      overflowMode,
      pageSize,
      pageSizeOptions,
      selectionMode,
      sortDescriptor,
    });
  }

  /**
   * This method should be implemented by every store that extends this one.
   * It should implement the mechanism of loading data and return a promise.
   *
   * The load method is then used to retrieve data.
   */
  // eslint-disable-next-line class-methods-use-this -- this method should be implemented by other stores
  fetchData() {
    throw new Error('Unimplemented');
  }

  /**
   * This is the callback to get the href for this particular admin's detail page.
   * This method relies on the Angular router's $state.href() method.
   */
  getAdminDetailsHref(adminId) {
    return this.navigationCallback
      ? this.navigationCallback('administrator-details', {userId: adminId})
      : undefined;
  }

  /**
   * This is the callback to get the href for this particular users's detail page.
   * This method relies on the Angular router's $state.href() method.
   */
  getUserDetailsHref(userId) {
    return this.navigationCallback ? this.navigationCallback('user-details', {userId}) : undefined;
  }

  goToNextPage() {
    if (this.tokenService) {
      this.tokenService.state.goToNextPage();
      return this.load();
    }
    return Promise.resolve();
  }

  goToPreviousPage() {
    if (this.tokenService) {
      this.tokenService.state.goToPreviousPage();
      return this.load();
    }
    return Promise.resolve();
  }

  hasNextPage() {
    return !!this.tokenService?.state.hasNextPage();
  }

  hasPreviousPage() {
    return !!this.tokenService?.state.hasPreviousPage();
  }

  onPageSizeChange(pageSize) {
    if (this.tokenService) {
      this.tableStore.setPageSize(pageSize);
      this.tokenService.state.setPageSize(pageSize);
      return this.load();
    }
    return Promise.resolve();
  }

  onSortBy(descriptor) {
    if (this.tokenService) {
      this.tableStore.setSortDescriptor(descriptor);
      this.tokenService.state.setSortKeyAndReverseIfSameKey(this.tokenService.state.sortKey);
      return this.load();
    }
    return Promise.resolve();
  }

  resetPageState() {
    if (this.tokenService) {
      this.tableStore.clearSelection();
      this.tableStore.searchQuery = '';
      this.tokenService.state.resetParams();
      // The visible Search input element needs to stay in sync with the API searchQuery.
      this.searchInputValue = this.tableStore.searchQuery;
    }
  }

  search(value) {
    if (this.tokenService.state.shouldRefreshOnSearch(value)) {
      this.tableStore.searchQuery = value;
      return this.load();
    }
    return Promise.resolve();
  }
}

export default TokenPagingTableStore;
