/* eslint-disable react/no-unknown-property -- styleName not recognized */
import binky, {SEARCH_QUERY_MIN_LENGTH} from '@admin-tribe/binky';
import {SearchField, View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v3 equivalent does not support the UI we want
import {MenuHeading} from '@react/react-spectrum/Menu';
import {useId} from '@react-aria/utils';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import MenuItemCheckbox from 'common/components/assignment-menu/menu-item-checkbox/MenuItemCheckbox';
import MenuEndOfResults from 'common/components/assignment-menu/shared/MenuEndOfResults';
import MenuError from 'common/components/assignment-menu/shared/MenuError';
import MenuLoading from 'common/components/assignment-menu/shared/MenuLoading';
import MenuNoItemsMessage from 'common/components/assignment-menu/shared/MenuNoItemsMessage';
import MenuNoResults from 'common/components/assignment-menu/shared/MenuNoResults';

import {useAssignmentMenuContext} from '../AssignmentMenuContext';
import './UserGroupMenu.pcss';

const UserGroup = binky.services.users.UserGroup;
const UserGroupList = binky.services.users.UserGroupList;
const SEARCH_MAX_LENGTH = 256;
const TAB_KEY = 'Tab';
const {DISPLAYED_RESULT_LIMIT, IDS, TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;
const {MENU_PREFIX, MENUITEM_PREFIX} = IDS;

const UserGroupMenu = ({onSelection, orgId}) => {
  const intl = useIntl();
  const {
    isSelected,
    isDisabled,
    menuCache,
    selectableItems,
    setMenuCache,
    setSelectableItems,
    toggleItem,
    updateSelectableItemsOfClass,
  } = useAssignmentMenuContext();

  const userGroupMenuCache = menuCache[TARGET_TYPE.USER_GROUPS] || {};
  const {cachedFilteredCount, cachedSearchValue, cachedTotalCount} = userGroupMenuCache;

  const [searchValue, setSearchValue] = useState(cachedSearchValue || '');
  const [loading, setLoading] = useState(false);
  const [totalUserGroupCount, setTotalUserGroupCount] = useState(cachedTotalCount || 0);
  const [areResultsTruncated, setAreResultsTruncated] = useState(
    totalUserGroupCount > DISPLAYED_RESULT_LIMIT
  );
  const isMounted = useRef(true);
  const userGroupListRef = useRef();
  const [error, setError] = useState(false);
  const searchInputRef = useRef();
  const userGroupMenuRef = useRef();
  const invalidMsgId = useId();
  const [isInputValid, setIsInputValid] = React.useState(true);
  const [promptUserGroupCreation, setPromptUserGroupCreation] = React.useState(false);
  const [filteredCount, setFilteredCount] = React.useState(cachedFilteredCount);

  const selectableUserGroups = selectableItems.filter((item) => item instanceof UserGroup);

  const handleSubmit = React.useCallback(
    async (query) => {
      setError(false);
      setLoading(true);

      try {
        userGroupListRef.current = await userGroupListRef.current.search(query);

        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (isMounted.current) {
          updateSelectableItemsOfClass(UserGroup, userGroupListRef.current.items);
          setFilteredCount(userGroupListRef.current.getTotalItemCount());
          setMenuCache((prevCache) => ({
            ...prevCache,
            [TARGET_TYPE.USER_GROUPS]: {
              ...prevCache[TARGET_TYPE.USER_GROUPS],
              cachedFilteredCount: userGroupListRef.current.getTotalItemCount(),
              cachedSearchValue: query,
            },
          }));
        }
      } catch {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (isMounted.current) {
          setError(true);
          updateSelectableItemsOfClass(UserGroup, []);
          setMenuCache((prevCache) => ({
            ...prevCache,
            [TARGET_TYPE.USER_GROUPS]: {
              ...prevCache[TARGET_TYPE.USER_GROUPS],
              cachedSearchValue: query,
            },
          }));
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    },
    [setMenuCache, updateSelectableItemsOfClass]
  );

  React.useEffect(() => {
    async function initUserGroupList() {
      setLoading(true);

      try {
        const hasCachedState = searchValue === cachedSearchValue;
        if (hasCachedState) {
          userGroupListRef.current = new UserGroupList({
            administerable: true,
            filterQuery: searchValue,
            orgId,
          });
          setLoading(false);
        } else {
          userGroupListRef.current = await UserGroupList.get({
            administerable: true,
            filterQuery: searchValue,
            orgId,
          });
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
          // istanbul ignore else
          if (isMounted.current) {
            // Merge any pre-selected items with the API result
            const selectableItemsOfDiffClass = selectableItems.filter(
              (item) => !(item instanceof UserGroup)
            );

            const uniqueUserGroups = uniqBy(
              [...selectableUserGroups, ...userGroupListRef.current.items],
              'id'
            );

            setSelectableItems([...selectableItemsOfDiffClass, ...uniqueUserGroups]);

            setFilteredCount(userGroupListRef.current.getTotalItemCount());
            setTotalUserGroupCount(userGroupListRef.current.getTotalItemCount());
            setMenuCache((prevCache) => ({
              ...prevCache,
              [TARGET_TYPE.USER_GROUPS]: {
                cachedFilteredCount: userGroupListRef.current.getTotalItemCount(),
                cachedSearchValue: searchValue,
                cachedTotalCount: userGroupListRef.current.getTotalItemCount(),
              },
            }));
          }
        }
      } catch {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (isMounted.current) {
          setError(true);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }

    initUserGroupList();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only on first mount
  }, []);

  React.useEffect(() => {
    setPromptUserGroupCreation(totalUserGroupCount === 0);
    setAreResultsTruncated(totalUserGroupCount > DISPLAYED_RESULT_LIMIT);
  }, [totalUserGroupCount]);

  React.useEffect(() => {
    const userGroupMenu = userGroupMenuRef.current;
    const searchInput = searchInputRef.current;
    // Event listener to always ensure that first menu item can be tabbed to when search input has focus
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    // istanbul ignore next -- TODO: test in e2e/integration test
    const allowTabToFirstMenuItem = () => {
      const firstMenuItem = userGroupMenu
        .UNSAFE_getDOMNode()
        .querySelector(`li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`);
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
      // istanbul ignore next -- will test in future e2e/integration test
      if (firstMenuItem) {
        firstMenuItem.tabIndex = '0';
      }
    };

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    /* istanbul ignore next -- TODO: test in e2e/integration test */
    if (searchInput) {
      searchInput.getInputElement().addEventListener('focus', allowTabToFirstMenuItem);

      // Override default focus from React Spectrum
      // https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/utils/FocusManager.js#L28
      const REACT_SPECTRUM_AUTOFOCUS_DELAY = 20;
      setTimeout(() => {
        if (isMounted.current) {
          const userGroupTab = document.querySelector(
            `[id^="${MENU_PREFIX}"] [role="tab"][aria-selected="true"]`
          );
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
          // istanbul ignore else
          if (!userGroupTab) {
            // TODO: test this in e2e/integration test
            searchInput.getInputElement().focus();
          }
        }
      }, REACT_SPECTRUM_AUTOFOCUS_DELAY + 1);
    }

    return () => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
      /* istanbul ignore next -- TODO: test in e2e/integration test */
      if (searchInput) {
        userGroupMenu
          .UNSAFE_getDOMNode()
          .querySelector(`li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`)
          ?.removeEventListener('focus', allowTabToFirstMenuItem);
      }
    };
  }, [areResultsTruncated]);

  React.useEffect(() => {
    if (!loading && areResultsTruncated && selectableUserGroups.length > 0) {
      const menuItems = userGroupMenuRef.current
        .UNSAFE_getDOMNode()
        .querySelectorAll('li[role=menuitemcheckbox]');
      menuItems?.forEach(
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        /* istanbul ignore next -- TODO: test in e2e/integration test */ (item) => {
          item.addEventListener('keydown', (e) => {
            if (!e.shiftKey && e.key === TAB_KEY) {
              // Need both stopPropagation and preventDefault to override focus
              e.stopPropagation();
              e.preventDefault();
              const userGroupTab = document.querySelectorAll('[role="menu"] [role="tab"]')[1];
              if (userGroupTab) {
                userGroupTab.focus();
              } else if (searchInputRef.current?.getInputElement()) {
                searchInputRef.current.getInputElement().focus();
              }
            }
          });
        }
      );
    }
  }, [areResultsTruncated, loading, selectableUserGroups]);

  /* eslint-disable @admin-tribe/admin-tribe/prefer-composition -- thean@ to update */

  const renderMenu = () => (
    <>
      <MenuHeading>
        {intl.formatMessage(
          {id: 'binky.common.assignmentMenu.userGroupMenu.heading.totalCount'},
          {
            totalCount: totalUserGroupCount,
          }
        )}
      </MenuHeading>
      {selectableUserGroups.map((userGroup) => (
        <MenuItemCheckbox
          key={userGroup.id}
          isDisabled={isDisabled}
          isSelected={isSelected}
          item={userGroup}
          label={userGroup.name}
          onSelection={(item, selected) => {
            toggleItem(item);
            // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
            // istanbul ignore else
            if (onSelection) {
              onSelection(item, selected);
            }
          }}
        />
      ))}

      {areResultsTruncated && (
        <MenuEndOfResults
          itemCount={selectableUserGroups.length}
          showSearchPrompt={filteredCount > DISPLAYED_RESULT_LIMIT}
          totalItemCount={totalUserGroupCount}
        />
      )}
    </>
  );

  const renderMainContent = () => {
    if (error) {
      return <MenuError />;
    }
    if (loading) {
      return <MenuLoading />;
    }
    if (promptUserGroupCreation) {
      return (
        <MenuNoItemsMessage
          content={intl.formatMessage({
            id: 'binky.common.assignmentMenu.userGroupMenu.messages.noUserGroups.content',
          })}
          heading={intl.formatMessage({
            id: 'binky.common.assignmentMenu.userGroupMenu.messages.noUserGroups.heading',
          })}
        />
      );
    }
    if (selectableUserGroups.length > 0) {
      return renderMenu();
    }
    return <MenuNoResults />;
  };
  /* eslint-enable @admin-tribe/admin-tribe/prefer-composition -- thean@ to update */
  return (
    <View ref={userGroupMenuRef} data-testid="user-group-menu">
      {areResultsTruncated && (
        <>
          {/* TODO: clean up SearchField and validation message. SearchField and validation message
              should be replaced with ValidatedSearch when it is ported to binky-ui. */}
          <SearchField
            ref={searchInputRef}
            aria-describedby={invalidMsgId}
            aria-label={intl.formatMessage({
              id: 'binky.common.assignmentMenu.userGroupMenu.search.label',
            })}
            data-testid="search"
            marginStart="size-125"
            marginTop="size-50"
            maxLength={SEARCH_MAX_LENGTH}
            onChange={(val) => {
              setSearchValue(val);
              setIsInputValid(true);
            }}
            onClear={() => {
              setSearchValue('');
              handleSubmit('');
            }}
            onSubmit={() => {
              // Only search when the searchValue is new
              if (searchValue !== userGroupListRef.current.filter.query) {
                // Handling simple input validation that will be handled in future input component
                if (searchValue === '' || searchValue.trim().length >= SEARCH_QUERY_MIN_LENGTH) {
                  handleSubmit(searchValue);
                } else {
                  setIsInputValid(false);
                }
              }
            }}
            placeholder={intl.formatMessage({
              id: 'binky.common.assignmentMenu.userGroupMenu.search.label',
            })}
            type="search"
            validationState={!isInputValid && 'invalid'}
            value={searchValue}
            width="280px"
          />
          {!isInputValid && (
            <View marginStart="size-150">
              <span
                data-testid="user-group-menu-invalid-message"
                /* eslint-disable-next-line react/forbid-dom-props -- id for aria-describedby */
                id={invalidMsgId}
                styleName="invalid-message"
              >
                <FormattedMessage id="binky.common.assignmentMenu.shared.search.invalidMessage" />
              </span>
            </View>
          )}
        </>
      )}
      {renderMainContent()}
    </View>
  );
};

UserGroupMenu.propTypes = {
  // onSelection prop will be called with params: item, isItemSelected
  onSelection: PropTypes.func,
  orgId: PropTypes.string.isRequired,
};
export default UserGroupMenu;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
