(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/product-picker/ProductPicker.jsx
   *
   * @ngdoc component
   * @name binky.widgets.products:BinkyProductAssignmentPulldown
   * @description A product assignment pulldown component used to assign products to a user
   * @param {Array} disabledProducts An array of products that indicate which products should be disabled from selection
   * @param {Array} products An array of products containing product information needed for product assignment
   * @param {Object} products[i].assets The assets object which contains the icons necessary to display the product
   * @param {Number} products[i].assignedLicenses The number of licenses that have been assigned to the product
   * @param {Number} products[i].availableLicenses The number of available licenses of the product
   * @param {String} products[i].longName The long name of the product
   * @param {String} products.id The id of the product to distinguish the product in the Selection
   * @param {String} pulldownId The pulldown id used to identify the pulldown
   * @param {Selection} selection The Selection used to track the state of selected products
   */

  angular
    .module('binky.widgets.products.product-assignment-pulldown')
    .component('binkyProductAssignmentPulldown', {
      bindings: {
        disabledProducts: '<?',
        products: '<',
        pulldownId: '<',
        selection: '<',
      },
      controller,
      templateUrl:
        'widgets/products/product-assignment-pulldown/product-assignment-pulldown.component.html',
    });

  function controller($translate, _, binkyProductNameLabelFilter) {
    const vm = this;

    _.assign(vm, {
      getLicenseCount,
      getProductLabel,
      isDisabled,
      isItemSelected,
      onClickChange,
    });

    function onClickChange(event, product) {
      // Prevent double clicking bug with spacebar
      if (_.get(event, 'type') === 'keydown') {
        event.preventDefault();
      }

      if (!isDisabled(product)) {
        vm.selection.toggleItemSelection(product);
        if (vm.selection.isItemSelected(product)) {
          _.find(vm.products, {id: product.id}).assignedLicenses += 1;
        } else {
          _.find(vm.products, {id: product.id}).assignedLicenses -= 1;
        }
      }
    }

    function isItemSelected(product) {
      return vm.selection.isItemSelected(product);
    }

    function isDisabled(product) {
      return !isProductAssignable(product);
    }

    function getLicenseCount(product) {
      return $translate.instant(
        'binky.widgets.products.productAssignmentPulldown.licenseCountLabel',
        {
          assignedLicenses: product.assignedLicenses,
          availableLicenses: product.availableLicenses,
        }
      );
    }

    function getProductLabel(product) {
      const productName = binkyProductNameLabelFilter(product);
      return product.isTrial
        ? $translate.instant('binky.widgets.products.productAssignmentPulldown.trial', {
            productName,
          })
        : productName;
    }

    /* Private functions */

    function isProductAssignable(product) {
      const isProductDisabled = _.find(vm.disabledProducts, {id: product.id});
      return (
        !isProductDisabled &&
        !(
          product.assignedLicenses === product.availableLicenses &&
          !vm.selection.isItemSelected(product)
        )
      );
    }
  }
})();
