(function () {
  /**
   * @ngdoc module
   * @name binky.core.offers
   * @description Module which defines offer models
   */
  angular.module('binky.core.offers', [
    'binky.core.api.jil',
    'binky.core.api.pores',
    'binky.core.common.list',
    'binky.core.contract',
    'binky.core.feature',
    'binky.core.js-utils',
    'binky.core.lodash',
    'binky.core.offers.fulfillable-items',
    'binky.core.organizations.market-subsegments',
    'binky.core.product',
    'binky.core.src2-migration',
  ]);
})();
