import EtlaUsageReportProduct from 'models/etlaUsageReport/EtlaUsageReportProduct';

class EtlaUsageReportProductList {
  /**
   * @description Method to create a new instance of a EtlaUsageReportProductList.
   * @param {Object[]} productsArray - Product entities which make up this list
   */
  constructor(productsArray) {
    this.products = [];
    if (productsArray) this.products = processProducts(productsArray);
  }
}

/** Private Methods **/
/**
 * @description Method to create the EtlaUsageReportProduct objects list
 * @param {Object[]} productsArray - top level wrapper object.
 * @returns {EtlaUsageReportProduct[]} the fetched EtlaUsageReportProduct array.
 */
function processProducts(productsArray) {
  return productsArray.map((product) => new EtlaUsageReportProduct(product));
}

export default EtlaUsageReportProductList;
