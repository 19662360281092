import ims from 'api/ims';
import modelCache from 'services/cache/modelCache/modelCache';

const USER_AVATAR_CACHE_ID = 'UserAvatar';

class UserAvatar {
  /**
   * @description Method for getting a user avatar object and loading its data
   * @param {String} userId - ID of user to fetch an avatar for
   * @param {String} size - the size of avatar to fetch, one of S, M, L or XL
   * @returns {Promise<UserAvatar>} userAvatar object
   */
  static get(userId, size) {
    const model = new UserAvatar({size, userId});
    const key = model.key();
    if (modelCache.has(USER_AVATAR_CACHE_ID, key)) {
      const cachedPromise = modelCache.get(USER_AVATAR_CACHE_ID, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }
    const promise = model.refresh();
    return promise;
  }

  constructor(config) {
    this.userId = config.userId;
    this.size = config.size;
    this.src = config.src;
  }

  key() {
    return `${this.userId}-${this.size}`;
  }

  /**
   * @description Method to fetch the user avatar
   * @returns {Promise<Avatar>} promise - promise object, resolved when the user avatar is loaded.
   */

  // eslint-disable-next-line complexity -- 1 over max
  async refresh() {
    if (this.userId) {
      let imsAvatarResponse, imsPixelHeight, imsPixelWidth;
      if (this.size === 'XXS' || this.size === 'XS' || this.size === 'S') {
        imsPixelHeight = 40;
        imsPixelWidth = 40;
      } else if (this.size === 'M') {
        imsPixelHeight = 56;
        imsPixelWidth = 56;
      } else if (this.size === 'L') {
        imsPixelHeight = 72;
        imsPixelWidth = 72;
      } else if (this.size === 'XL') {
        imsPixelHeight = 88;
        imsPixelWidth = 88;
      } else {
        throw new Error(`Unsupported user avatar size requested: ${this.size}`);
      }

      try {
        imsAvatarResponse = await ims.getAvatar({
          height: imsPixelHeight,
          userId: this.userId,
          width: imsPixelWidth,
        });
      } catch (error) {
        return Promise.reject(error.response);
      }

      // imsAvatarResponse.data.public may be a string "true"
      if (imsAvatarResponse?.data?.location && `${imsAvatarResponse?.data?.public}` === 'true') {
        this.src = imsAvatarResponse.data.location;

        const key = this.key();
        modelCache.put(USER_AVATAR_CACHE_ID, key, this);

        return this;
      }
      return Promise.reject(new Error('No location, or non-public'));
    }

    return Promise.reject(new Error(`Requested a UserAvatar without a userId`));
  }
}

export default UserAvatar;
export {USER_AVATAR_CACHE_ID};
