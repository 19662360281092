(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.split-button:binkySplitButton
   *
   * @description A split-button widget.
   *
   * @param {SplitButtonAction[]} buttons - Array of SplitButtonAction model
   *   Objects representing each button in this component
   * @param {String} [size] - Optional; The size of the overall button; 'S' only, default is normal button size.
   * @param {String} [variant] - Optional; One of cta, default, or secondary.
   *   Default: default
   *
   * @example <binky-split-button buttons="splitButtonActionArray" variant="cta"></binky-split-button>
   */
  angular.module('binky.widgets.common.split-button').component('binkySplitButton', {
    bindings: {
      buttons: '<',
      size: '@?',
      variant: '@?',
    },
    controller,
  });

  /* @ngInject */
  function controller($compile, $element, $scope, _) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onButtonClick,
    });

    function $onInit() {
      vm.dropdownChevronId = _.uniqueId('split-button-');
      vm.variant = vm.variant || 'default';

      switch (vm.size) {
        case 'S':
          $element.addClass('binky-small-button');
          break;
        default:
        // none
      }

      buildSplitButton();
    }

    function appendAdditionalButtonList(elementToAppendTo) {
      const additionalButtonList = new Coral.ButtonList();
      const additionalButtonListEl = angular.element(additionalButtonList);
      appendAdditionalButtons(additionalButtonListEl);
      elementToAppendTo.append(additionalButtonList);
    }

    function appendAdditionalButtonPopover(elementToAppendTo) {
      const popover = new Coral.Popover();
      popover.set({
        placement: 'bottom',
      });
      popover.setAttribute('target', `#${vm.dropdownChevronId}`);
      const popoverEl = angular.element(popover);
      appendAdditionalButtonList(popoverEl);
      elementToAppendTo.append(popover);
    }

    function appendAdditionalButtons(elementToAppendTo) {
      _.forEach(vm.buttons, (additionalButtonModel, index) => {
        const additionalButton = new Coral.ButtonList.Item();
        additionalButton.set({
          content: {
            innerHTML: additionalButtonModel.label,
          },
        });

        if (_.get(additionalButtonModel, 'selected')) {
          additionalButton.setAttribute('selected', '');
          appendCheckmarkIcon(angular.element(additionalButton));
        } else {
          additionalButton.setAttribute(
            'ng-click',
            `$ctrl.onButtonClick($event, $ctrl.buttons[${index}])`
          );
        }
        elementToAppendTo.append(additionalButton);
      });
    }

    function appendCheckmarkIcon(elementToAppendTo) {
      const checkMarkIcon = new Coral.Icon();
      checkMarkIcon.set({
        icon: 'checkmark',
        size: 'XS',
      });
      elementToAppendTo.append(checkMarkIcon);
    }

    function appendPopoverButton(elementToAppendTo) {
      const popoverButton = new Coral.Button();
      popoverButton.set({
        icon: 'ChevronDown',
        id: vm.dropdownChevronId,
      });
      popoverButton.setAttribute('coral-splitbutton-trigger', '');
      elementToAppendTo.append(popoverButton);
    }

    function appendSelectedButton(elementToAppendTo) {
      const selectedButton = new Coral.Button();
      selectedButton.setAttribute('coral-splitbutton-action', '');
      selectedButton.setAttribute('ng-click', `$ctrl.onButtonClick($event, $ctrl.selectedButton)`);
      selectedButton.setAttribute('title', vm.selectedButton.label);
      const selectedButtonEl = angular.element(selectedButton);
      appendSelectedButtonLabel(selectedButtonEl);
      elementToAppendTo.append(selectedButton);
    }

    function appendSelectedButtonLabel(elementToAppendTo) {
      const selectedButtonLabel = new Coral.Button.Label();
      angular.element(selectedButtonLabel).append(vm.selectedButton.label);
      elementToAppendTo.append(selectedButtonLabel);
    }

    function appendSplitButton(elementToAppendTo) {
      const splitButton = new Coral.SplitButton();
      splitButton.set({
        variant: vm.variant,
      });
      const splitButtonEl = angular.element(splitButton);
      appendSelectedButton(splitButtonEl);
      appendPopoverButton(splitButtonEl);
      elementToAppendTo.append(splitButton);
    }

    function buildSplitButton() {
      $element.empty();

      // use selected button (if one exists), otherwise use first button
      // encountered and assign selected attribute to track selection state
      vm.selectedButton =
        _.find(vm.buttons, {selected: true}) ||
        _.chain(vm.buttons).head().assign({selected: true}).value();

      appendSplitButton($element);
      appendAdditionalButtonPopover($element);

      $compile($element.contents())($scope);
    }

    function onButtonClick($event, clickedButtonAction) {
      $event.stopPropagation();
      clickedButtonAction.onClick();

      _.forEach(vm.buttons, (button) => {
        if (_.isEqual(button, clickedButtonAction)) {
          button.selected = true;
        } else {
          button.selected = false;
        }
      });

      buildSplitButton();
    }
  }
})();
