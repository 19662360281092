import {log} from '@admin-tribe/binky';
import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';

import InfoItemContainer from 'common/components/info-item/info-item-container/InfoItemContainer';
import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

/**
 * InfoItem is a widget which displays a label and an icon, a value or both.
 *
 * If the value is a string it should be formatted and labelled appropriately for its type.
 *   For example '$6,870', '3.24%' or '132K'.
 *
 * If the value is a number consider using InfoItemScorecard instead.
 *
 * If an icon is provided without a value, the icon must have an 'aria-label' attribute set.
 *
 * The formatting for this component is loosely based on Spectrum's Big number.
 */
const InfoItem = ({'data-testid': dataTestId = 'info-item', icon, label, value}) => {
  const isNil = (val) => val === undefined || val === null;

  React.useEffect(() => {
    if (icon && isNil(value) && !icon?.props['aria-label']) {
      log.warn("You have must provide an 'aria-label' for the InfoItem icon.");
    }
  }, [icon, value]);

  return (
    <InfoItemContainer data-testid={dataTestId} label={label}>
      {icon && cloneElement(icon, {marginEnd: 'size-50', size: 'S'})}
      {!isNil(value) && <Text data-testid="info-item-value">{value}</Text>}
    </InfoItemContainer>
  );
};

InfoItem.propTypes = {
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
  /**
   * An Spectrum icon which is displayed preceding the value.
   * Size 'S' will be applied to the icon and, if size is specified will override it.
   * One or both of icon and value is required.
   * If only an icon is provided it must have an appropriate aria-label.
   */
  icon: requiredIf(PropTypes.node, (props) => !props.value),
  /**
   * The InfoItem label.
   */
  label: PropTypes.string.isRequired,
  /**
   * The InfoItem value. One or both of icon and value is required.
   * If a number consider using InfoItemScorecard instead.
   */
  value: requiredIf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    (props) => !props.icon
  ),
};

export default InfoItem;
