(function () {
  /**
   * @deprecated Please use src2/core/models/member/type/MemberType.js
   *
   * @ngdoc factory
   * @name MemberType
   * @description
   *  Model for a Member's type - for example: TYPE1, TYPE2, TYPE3, or USER_GROUP. The
   *  raw 'type' string field is available in case of needing to update/POST data back
   *  to the server with type info).
   */
  angular.module('binky.core.common.member.type').factory('MemberType', memberType);

  /* @ngInject */
  function memberType(binkySrc2) {
    return binkySrc2.models.member.MemberType;
  }
})();
