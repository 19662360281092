import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import {CUSTOMER_SEGMENT, SALES_CHANNEL} from 'models/offers/OfferConstants';
import User from 'models/user/User';

import {CONTRACT_BUYING_PROGRAM} from './ContractConstants';

/**
 * @description Gets the contract owner with the pending owner prioritized
 * @param {Contract} contract - Contract containing owner information
 *
 * @returns {Object} - Object wrapping an isPending flag and a person object
 *     that will be the contract owner, the pending owner with pending status,
 *     or undefined if no userType is specified.
 * @property {Boolean} isPending - whether the contract owner is pending
 *     ownership
 * @property {Object} person - User object that defines the contract owner or
 *     pending owner
 */
function getContractOwner(contract) {
  const pendingContractOwner = contract.changeOwnerInvitation;
  if (pendingContractOwner) {
    const {email, firstName, lastName} = pendingContractOwner;
    return {
      isPending: true,
      person: new User({
        email,
        firstName,
        lastName,
        type: MEMBER_TYPE.TYPE1, // All team admins are Type 1
      }),
    };
  }
  const personOptions = {type: contract.ownerInfo?.userType, ...contract.ownerInfo};

  return {
    isPending: false,
    person: personOptions.type ? new User(personOptions) : undefined,
  };
}

/**
 * @description Returns the corresponding salesChannel from the buyingProgram.
 * Returns undefined when the buyingProgram does not have a valid mapping.
 * @param {String} buyingProgram the buying program of the contract
 * @returns {String} the mapped customerSegment value of the given buying program
 */
function getCustomerSegmentFromBuyingProgram(buyingProgram) {
  switch (buyingProgram) {
    case CONTRACT_BUYING_PROGRAM.ENTERPRISE_PRODUCT:
    case CONTRACT_BUYING_PROGRAM.ETLA:
      return CUSTOMER_SEGMENT.ENTERPRISE;
    default:
      return undefined;
  }
}

/**
 * @description Returns the corresponding salesChannel from the buyingProgram.
 * Returns undefined when the buyingProgram does not have a valid mapping.
 * @param {String} buyingProgram the buying program of the contract
 * @returns {String} the mapped salesChannel value of the given buying program
 */
function getSalesChannelFromBuyingProgram(buyingProgram) {
  switch (buyingProgram) {
    case CONTRACT_BUYING_PROGRAM.ETLA:
    case CONTRACT_BUYING_PROGRAM.ENTERPRISE_PRODUCT:
      return SALES_CHANNEL.DIRECT;
    case CONTRACT_BUYING_PROGRAM.VIP:
      return SALES_CHANNEL.INDIRECT;
    default:
      return undefined;
  }
}

export {getContractOwner, getCustomerSegmentFromBuyingProgram, getSalesChannelFromBuyingProgram};
