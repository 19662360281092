(function () {
  'use strict';
  angular.module('binky.shell.navigation.ready', [
    'binky.core.authentication.ready',
    'binky.core.configuration.ready',
    'binky.core.feature.ready',
    'binky.core.lodash',
    'binky.shell.navigation.assembler',
  ]);
})();
