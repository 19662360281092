const CONTRACT_MIGRATION_STATUS = {
  MIGRATED: 'MIGRATED',
  MIGRATING: 'MIGRATING',
  NOT_MIGRATING: 'NOT_MIGRATING',
};
const CONTRACT_MIGRATION_TYPE = {
  BIZ_TRIALS_TO_PAID: 'BIZ_TRIALS_TO_PAID',
  OFFER_SWITCH: 'OFFER_SWITCH',
  TEAM_VIP_TO_TEAM_EVIP: 'TEAM_VIP_TO_TEAM_EVIP',
  UNKNOWN: 'UNKNOWN',
};

export {CONTRACT_MIGRATION_STATUS, CONTRACT_MIGRATION_TYPE};
