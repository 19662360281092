(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   *
   * @ngdoc component
   * @name binky.widgets.omni-tool.features-panel:binkyOmniToolFeaturesPanel
   *
   * @description the features panel for the omni tool drawer
   *
   * @example <binky-omni-tool-features-panel></binky-omni-tool-features-panel>
   *
   */
  angular.module('binky.widgets.omni-tool.features-panel').component('binkyOmniToolFeaturesPanel', {
    controller,
    templateUrl: 'widgets/omni-tool/features-panel/omni-tool-features-panel.component.html',
  });

  /* @ngInject */
  function controller($filter, _, feature, omniToolPanelManager, OMNI_TOOL_FEATURES_PANEL) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      $postLink,
      getFeatureName,
      getFeatures,
      getMoreFunctionName,
      getNumberOfPages,
      getSource,
      getToggleState,
      invokeMoreFunction,
      isOverridden: feature.isOverridden,
      nextPage,
      onSearch,
      override,
      previousPage,
      reset,
      resetAll,
      saveAndReload: feature.saveAndReload,
      toggleActive,
      updateVisibleFeatures,
    });

    function $onInit() {
      omniToolPanelManager.registerPanel(
        OMNI_TOOL_FEATURES_PANEL.ID,
        {call: vm.saveAndReload, title: 'Save and reload'},
        {call: vm.resetAll, title: 'Reset all'}
      );
      vm.allFeatures = feature.getFeatures();
      vm.currentPage = 0;
      vm.updateVisibleFeatures();
    }

    function $postLink() {
      omniToolPanelManager.switchTo(OMNI_TOOL_FEATURES_PANEL.ID);
    }

    function getFeatureName(featureName) {
      return `${feature.hasChange(featureName) ? '*' : ''}${featureName}`;
    }

    function getFeatures() {
      return $filter('filter')(vm.allFeatures, vm.search);
    }

    function getMoreFunctionName(featureName) {
      return feature.isOverridden(featureName) ? 'reset' : 'override';
    }

    function getNumberOfPages() {
      return Math.ceil(vm.getFeatures().length / OMNI_TOOL_FEATURES_PANEL.ROWS_PER_PAGE);
    }

    function getSource(featureName) {
      let source =
        (vm.isOverridden(featureName)
          ? feature.getSource(featureName)
          : feature.getNonOverrideSource(featureName)) || 'unset';

      if (feature.hasChange(featureName)) {
        const status = vm.isOverridden(featureName)
          ? feature.isEnabled(featureName)
          : feature.getNonOverrideValue(featureName);
        source += ` (${status ? 'on' : 'off'})`;
      }

      return source;
    }

    function getToggleState(featureName) {
      return vm.isOverridden(featureName)
        ? feature.getOverrideValue(featureName)
        : feature.getNonOverrideValue(featureName);
    }

    function invokeMoreFunction(featureName) {
      if (feature.isOverridden(featureName)) {
        reset(featureName);
      } else {
        override(featureName);
      }
    }

    function nextPage() {
      if (vm.currentPage < vm.getNumberOfPages() - 1) {
        vm.currentPage += 1;
        vm.updateVisibleFeatures();
      }
    }

    function onSearch(input) {
      vm.currentPage = 0;
      vm.search = input;
      vm.updateVisibleFeatures();
    }

    function override(featureName) {
      feature.prepareOverride({enabled: getToggleState(featureName), feature: featureName});
    }

    function previousPage() {
      if (vm.currentPage > 0) {
        vm.currentPage -= 1;
        vm.updateVisibleFeatures();
      }
    }

    function reset(featureName) {
      feature.unprepareOverride(featureName);
    }

    function resetAll() {
      feature.resetOverrides();
    }

    function toggleActive(featureName) {
      feature.prepareOverride({enabled: !getToggleState(featureName), feature: featureName});
    }

    function updateVisibleFeatures() {
      const searchFilteredFeatures = $filter('filter')(vm.allFeatures, vm.search);
      const currentPageItems = _.slice(
        searchFilteredFeatures,
        vm.currentPage * OMNI_TOOL_FEATURES_PANEL.ROWS_PER_PAGE,
        vm.currentPage * OMNI_TOOL_FEATURES_PANEL.ROWS_PER_PAGE +
          OMNI_TOOL_FEATURES_PANEL.ROWS_PER_PAGE
      );
      const sortedFeatures = _.orderBy(currentPageItems, [(flag) => _.toLower(flag)]);
      vm.currentFeatures = sortedFeatures;
    }
  }
})();
