const FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS = {
  EXTENDED_INTERVAL: 30000,
  INTERVAL: 10000,
  MAX_EXPONENTIAL_BACKOFF: 1200000,
  MAX_REFRESH_ATTEMPTS: 120,
  MODE: {
    DEFAULT: 'DEFAULT',
    EXPONENTIAL_BACKOFF: 'EXPONENTIAL_BACKOFF',
  },
};

export {FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS};
