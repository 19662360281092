import binky, {
  ConsumableSummarizationList,
  Contract,
  SUPPORT_ADMINS,
  feature,
} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useLicenseLimitContext} from 'common/components/assignment-menu/assignmentMenuUtils';
import ContractListPill from 'common/components/pill/ContractListPill';
import CustomPill from 'common/components/pill/CustomPill';
import ProductProfileRolePill from 'common/components/pill/ProductProfileRolePill';
import UserGroupPill from 'common/components/pill/UserGroupPill';
import {getProductDisplayName} from 'features/product/productDisplayUtils';

import useProductRoleSelection from './useProductRoleSelection';

const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const Product = binky.services.product.Product;
const UserGroup = binky.services.users.UserGroup;

// A list of Pill component to be used in conjunction with AssignmentMenu
const SelectedPills = ({
  className,
  consumableSummarizationList,
  isDisabled = () => false,
  items,
  onDismiss,
  onProductRolesChange,
  orgId,
  role,
}) => {
  const intl = useIntl();
  const [profileGroups, setProfileGroups] = useState([]);
  const [, dispatch] = useLicenseLimitContext();

  const {
    dispatchSelectedProductRoles,
    dispatchUniqueProfileRoleProductIds,
    uniqueProfileRoleProductIds,
  } = useProductRoleSelection({
    onProductRolesChange,
  });

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    const groups = [];
    items.forEach((productProfile) => {
      if (productProfile instanceof LicenseGroup) {
        const productId = productProfile.product.id;
        const existingEntry = groups.find((entry) => entry.productId === productId);
        const showSeparateProfilePill = uniqueProfileRoleProductIds.has(productId);

        if (existingEntry && !showSeparateProfilePill) {
          existingEntry.profiles.push(productProfile);
        } else {
          groups.push({
            id: productProfile.id,
            product: productProfile.product,
            productId,
            profiles: [productProfile],
          });
        }
      }
    });
    setProfileGroups(groups);
  }, [items, uniqueProfileRoleProductIds]);

  function productHasLimitedLicenses(product) {
    return !product.fulfillableItemList.hasOverdelegationAllowed();
  }

  const renderPill = (item) => {
    if (item instanceof Product) {
      const productName = getProductDisplayName(intl, item, {
        consumableSummarizationList,
        showConsumableQuota: feature.isDisabled('temp_hide_psa_quota') && role === SUPPORT_ADMINS,
      });

      return (
        <CustomPill
          key={item.id}
          closeButtonTooltipText={intl.formatMessage({
            id: 'binky.common.assignmentSection.selectedPills.pill.dismiss',
          })}
          header={productName}
          iconAlt={productName}
          iconSrc={item.getIcon()}
          isDisabled={isDisabled(item)}
          onDismiss={() => {
            onDismiss(item);
          }}
          onUnmount={() => {
            // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
            // istanbul ignore else
            if (productHasLimitedLicenses(item)) {
              dispatch?.({product: item, type: 'DESELECT'});
            }
          }}
          tags={item.tagList}
        />
      );
    }

    if (item instanceof UserGroup) {
      return (
        <UserGroupPill
          key={item.id}
          closeButtonTooltipText={intl.formatMessage({
            id: 'binky.common.assignmentSection.selectedPills.pill.dismiss',
          })}
          header={item.name}
          isDisabled={isDisabled(item)}
          onDismiss={() => onDismiss(item)}
        />
      );
    }
    if (item instanceof Contract) {
      return (
        <ContractListPill
          key={item.id}
          closeButtonAriaLabel="Dismiss contract pill"
          closeButtonTooltipText={intl.formatMessage({
            id: 'binky.common.assignmentSection.selectedPills.pill.dismiss',
          })}
          header={item.displayName}
          onDismiss={() => {
            onDismiss(item);
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className={className} data-testid="selected-pills">
      {items.map((item) => renderPill(item))}
      {profileGroups.map((productProfilePillGroup) => (
        <ProductProfileRolePill
          key={productProfilePillGroup.id}
          closeButtonTooltipText={intl.formatMessage({
            id: 'binky.common.assignmentSection.selectedPills.pill.dismiss',
          })}
          isDisabled={isDisabled(productProfilePillGroup.product)}
          onDismiss={() => {
            productProfilePillGroup.profiles.forEach(onDismiss);
          }}
          onPillChange={dispatchUniqueProfileRoleProductIds}
          onProductRoleChange={dispatchSelectedProductRoles}
          orgId={orgId}
          productProfiles={productProfilePillGroup.profiles}
        />
      ))}
    </div>
  );
};

SelectedPills.propTypes = {
  className: PropTypes.string,
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList),
  // callback to determine whether to disable individual pill
  // eg. isPillDisabled={(item) => !item.administrable}
  isDisabled: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  onDismiss: PropTypes.func.isRequired,
  // callback function that notifies caller with selected items' product role
  onProductRolesChange: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  role: PropTypes.string,
};

export default SelectedPills;
