import {TextField, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import InfoIcon from '@spectrum-icons/workflow/Info';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {requiredLabelPropsCheck} from 'common/utils/prop-type/propTypeUtils';

import styles from './ValidatedTextField.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-validated-text-field
 */

/**
 * Textfield component that has configurable error icons and can show a given message.
 * Made primarily to show validation errors/messages for forms.
 */
const ValidatedTextField = ({
  id,
  onChange,
  placeholder,
  validationMessage,
  value = '',
  variant,
  wrapperProps,
  ...textFieldProps
}) => {
  const processedId = useId(id);
  const validationMessageId = `${processedId}-validation-message`;
  // There are three possible values for validationState: valid, invalid, and undefined.
  const states = {
    confirmation: {
      icon: undefined,
      validationState: 'valid',
    },
    error: {
      icon: undefined,
      // Sets aria-invalid, so no need to set our own here.
      validationState: 'invalid',
    },
    info: {
      icon: <InfoIcon aria-describedby={validationMessageId} />,
      validationState: undefined,
    },
    warning: {
      icon: <AlertIcon aria-describedby={validationMessageId} />,
      validationState: undefined,
    },
  };

  return (
    <View data-testid="validated-text-field" {...wrapperProps}>
      <TextField
        {...textFieldProps}
        aria-describedby={validationMessage ? validationMessageId : undefined}
        // Since the specs for "autocomplete=off" allow for browsers to ignore it when
        // they interpret it as a potential "login" field (for better web security),
        // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
        autoComplete="nada"
        data-testid="validated-textfield-input"
        icon={variant && states[variant].icon}
        id={`${processedId}`}
        onChange={onChange}
        placeholder={placeholder}
        validationState={variant && states[variant].validationState}
        value={value}
        width="100%"
      />
      {validationMessage && (
        <View marginTop="size-40">
          {/* spectrum: use HelpText component when added to v3 -- https://jira.corp.adobe.com/browse/SDS-7633 */}
          <span
            className={classNames(
              variant ? styles[`${variant}-text`] : '',
              styles['validation-text']
            )}
            data-testid="text-field-validation-message"
            // eslint-disable-next-line react/forbid-dom-props -- aria needs id
            id={validationMessageId}
          >
            {validationMessage}
          </span>
        </View>
      )}
    </View>
  );
};

ValidatedTextField.propTypes = {
  /**
   * aria-label prop to assign to the text field.
   */
  'aria-label': requiredLabelPropsCheck,
  /**
   * aria-labelledby prop to assign to the text field.
   */
  'aria-labelledby': requiredLabelPropsCheck,
  /**
   * Id for the text field.
   */
  id: PropTypes.string,
  /**
   * label prop to assign to the text field.
   */
  label: requiredLabelPropsCheck,
  /**
   * Callback to invoke when the text field value changes.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Placeholder for the text field.
   */
  placeholder: PropTypes.string,
  /**
   * Validation message to display.
   */
  validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Value for the text field. Default is ''.
   */
  value: PropTypes.string,
  /**
   * Variant for the validation message.
   */
  variant: PropTypes.oneOf(['confirmation', 'error', 'info', 'warning']),
  /**
   * Props to assign to the wrapper of the text field.
   */
  wrapperProps: PropTypes.shape({
    /**
     * Flex prop to apply to the surrounding element.
     */
    flex: PropTypes.string,
    /**
     * Max width for the surrounding element.
     */
    maxWidth: PropTypes.string,
    /**
     * Width for the surrounding element.
     */
    width: PropTypes.string,
  }),
};

export default ValidatedTextField;
