import axios from 'axios';

import {getHeaders} from 'api/jil/jilApiUtils';
import fraudMitigation from 'services/fraudMitigation/fraudMitigation';
import Locale from 'services/locale/Locale';

const CART_STATUS_SUBMIT = 'SUBMIT';
const CART_HEADERS = {
  ACCEPT_LANGUAGE: 'Accept-Language',
  SHERLOCK_TOKEN: 'x-adobe-fingerprint-token',
};
let clientId, url;

/**
 * Sets the configurations for the cart api
 *
 * @param {Object} config - configuration object, the necessary properties below:
 *         {String} config.url - the base url of the jil endpoint i.e. https://bps-il-stage.adobe.io/jil-api
 *         {clientId} config.clientId - the client ID of the app
 */
const configure = (config) => {
  ({url, clientId} = config);
};

/**
 * Post method to Jil Carts. Used for add seats preview and submission.
 *
 * @param {Object} options - options listed below
 *        {String} options.org Id- the current org of which the cart call will utilize
 * @param {Object} cart - cart request body, typical body includes the following
 *         {String} cart.id - the cart request id
 *         {Object} cart.billing_summary - the billing summary for contents in the cart, includes contract details and a list
 *                  of offers for licenses to be purchased.
 *         {String} cart.last_modified_by_id - id of the last user to modify the cart
 *         {String} cart.owner_id - the contract owner id
 *         {String} cart.status - the type of request this is. If PENDING, it is a preview. If SUBMIT, it is a submission.
 * @returns {Promise} response - the cart response detailing the results of the current cart
 */
const postCart = ({orgId}, cart) => {
  const headers = getCartHeaders();
  const sherlockToken = fraudMitigation.getForterToken();
  if (cart.status === CART_STATUS_SUBMIT && sherlockToken) {
    headers[CART_HEADERS.SHERLOCK_TOKEN] = sherlockToken;
  }
  return axios.post(`${url}/v2/organizations/${orgId}/carts`, cart, {
    headers,
  });
};

/**
 * Builds the request headers for the cart service
 *
 * @returns {Object} headers - request headers for the cart service
 */
function getCartHeaders() {
  const headers = getHeaders({clientId});

  const locale = Locale.get();

  const lang = locale.activeLocaleCode;

  // for mandarin, Cart service needs to take the the BC47 code where zh_CN maps to zh_HANS (simplified chinese)
  // and zh_TW maps to zh_HANT (traditional chinese)
  if (lang === 'zh_CN' || lang === 'zh_TW') {
    headers[CART_HEADERS.ACCEPT_LANGUAGE] = locale.activeLanguageBCP47Code;
  }

  return headers;
}

const jilOrganizationsCart = {
  configure,
  postCart,
};

export default jilOrganizationsCart;
