/**
 * @description Method to transform CSV data to blob
 * @param {Object} response - the response containing the raw CSV data
 * @returns {Object} object containing blob representation of csv
 */
const csvBlobTransformer = (response) => {
  if (response.data) {
    const file = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
    response.data = {file};
  }

  return response;
};

// eslint-disable-next-line import/prefer-default-export -- utils
export {csvBlobTransformer};
