(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc       factory
   * @name        fraudMitigation
   * @description Fraud detection provider that is responsible for
   * initializing Sherlock and providing wrapper methods.
   * Specs: https://wiki.corp.adobe.com/x/fowje
   */

  angular.module('binky.core.fraud-mitigation').provider('fraudMitigation', fraudMitigation);

  /* @ngInject */
  function fraudMitigation(binkySrc2) {
    const fraudMitigationSrc2 = binkySrc2.services.fraudMitigation;

    this.configure = configure;
    this.$get = $get;

    function configure(config) {
      fraudMitigationSrc2.configure(config);
    }
    ////////////

    /* @ngInject */
    function $get($q) {
      const service = {
        getForterToken: fraudMitigationSrc2.getForterToken,
        initialize,
        reportPageChange: fraudMitigationSrc2.reportPageChange,
      };

      return service;

      ////////////

      function initialize() {
        return $q((resolve, reject) => {
          fraudMitigationSrc2.initialize().then(resolve).catch(reject);
        });
      }
    }
  }
})();
