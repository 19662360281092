import {Store} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Context for the TableSection and its sub-components.
 * Used to pass a store containing the list's and table's state between sub-components.
 */

const TableSectionContext = createContext(null);
const useTableSectionContext = () => useContext(TableSectionContext);

const TableSectionContextProvider = ({children, listStore}) => {
  const value = useMemo(() => ({listStore}), [listStore]);
  return <TableSectionContext.Provider value={value}>{children}</TableSectionContext.Provider>;
};

TableSectionContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the table section.
   */
  children: PropTypes.node,
  /**
   * The store containing a list's and table's state.
   * The store should extend IndexPagingTableStore if the list service
   * uses index paging (most common).
   * The store should extend TokenPagingTableStore if the list service
   * uses token paging.
   */
  listStore: PropTypes.instanceOf(Store).isRequired,
};

export {TableSectionContextProvider, useTableSectionContext};
