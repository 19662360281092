(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.invoice:InvoiceList
   * @description Model for an Invoice list
   */
  angular.module('binky.core.invoice').factory('InvoiceList', invoiceListModel);

  /* @ngInject */
  function invoiceListModel(
    $rootScope,
    _,
    Invoice,
    INVOICE_LIST_CACHE_ID,
    INVOICE_LIST_EVENT,
    INVOICE_SORT,
    JIL_CONSTANTS,
    jilInvoices,
    List,
    modelCache,
    ModelError
  ) {
    class InvoiceList extends List {
      /**
       * @description Method to create a new instance of a InvoiceList.
       * @param {Object} [options] - configures the InvoiceList model instance.
       * @param {String} [options.orgId] - associates InvoiceList instance with an org.
       * @param {Array<INVOICE_STATUS>} [options.invoiceStatuses] - an array of invoice statuses as
       *   defined in INVOICE_STATUS, used for filtering through JIL. Defaults to empty array.
       */
      constructor(options = {}) {
        super(
          _.defaults(options, {
            cacheType: INVOICE_LIST_CACHE_ID,
            itemClassRef: Invoice,
            resource: jilInvoices.invoices,
            sortExpression: INVOICE_SORT.INVOICE_DATE,
            sortOrder: JIL_CONSTANTS.ORDER.DESC,
          })
        );
        this.orgId = options.orgId;

        this.invoiceStatuses = options.invoiceStatuses || [];
      }

      /**
       * @description Creating items in this fashion is not supported for this list.
       */
      create() {
        ModelError.throwUnimplementedMethodException(this);
      }

      /**
       * @description Method to get the latest invoice from an InvoiceList
       *
       * @returns {Invoice} invoice - the latest invoice
       */
      getLatestInvoice() {
        return _.find(this.items, 'latest');
      }

      key() {
        return super.key(getQueryParams(this));
      }

      /**
       * @description
       * Method to refresh the contents of the list.
       * @param {Object} [params] - query parameters
       * @param {String} [options.start_date] - ISO date string, earliest invoice date to include
       * @param {String} [options.end_date] - ISO date string, latest invoice date to include
       *
       * @param {Object} [options] - options to configure the invoice list.
       *
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(params = {}) {
        _.assign(this, _.pick(params, ['end_date', 'start_date']));
        return super.refresh(getQueryParams(this)).then(() => {
          $rootScope.$emit(INVOICE_LIST_EVENT.UPDATE, this);
        });
      }

      /**
       * @description Saving items is not supported for this list.
       */
      save() {
        ModelError.throwUnimplementedMethodException(this);
      }

      /**
       * @description Method to fetch the InvoiceList.
       * @param {Object} [options] - options to configure the invoice list.
       * @param {Object} [options.orgId] - org id for which invoices should be fetched.
       * @param {Boolean} [options.useCache] Dictates whether the method should use the cached entry
       *   if there is one. Defaults to true.
       * @returns {InvoiceList} the fetched InvoiceList.
       */
      static get(options) {
        const {useCache = true} = options || {};
        let model = new InvoiceList(options);
        const cachedModel = modelCache.get(INVOICE_LIST_CACHE_ID, model.key());
        if (cachedModel && useCache) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    //////////////

    function getQueryParams(model) {
      return _.omitBy(
        {
          end_date: model.end_date,
          invoice_statuses: _.isEmpty(model.invoiceStatuses)
            ? undefined
            : model.invoiceStatuses.join(','),
          orgId: model.orgId,
          start_date: model.start_date,
        },
        _.isUndefined
      );
    }

    modelCache.register(INVOICE_LIST_CACHE_ID, 1);

    return InvoiceList;
  }
})();
