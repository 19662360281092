import {Content, IllustratedMessage, Text} from '@adobe/react-spectrum';
import AlertCircleIcon from '@spectrum-icons/workflow/AlertCircle';
import PropTypes from 'prop-types';
import React from 'react';

const ChartErrorScreen = ({errorMessageArray}) => (
  <IllustratedMessage margin="size-100">
    <AlertCircleIcon alt={errorMessageArray[0]} size="L" />
    <Content>
      {errorMessageArray.map((error, index) => (
        <Text key={error.id}>
          {error.message} {index + 1 < errorMessageArray.length ? <br /> : ''}
        </Text>
      ))}
    </Content>
  </IllustratedMessage>
);

ChartErrorScreen.propTypes = {
  /**
   *  Localized string array for the error message with error id and error message to be shown to the user in the chart
   *  error screen. Each element in the array corresponds to different lines and different error id.
   */
  errorMessageArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ChartErrorScreen;
