(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/authenticated-user-pulldown/AuthenticatedUserPullDown
   *
   * @ngdoc component
   * @name binky.widgets.user.authenticated-user-pulldown:binkyAuthenticatedUserPulldown
   *
   * @description the authenticated user profile pulldown widget
   *
   * @param {UserRole} activeRole The active role that should be displayed for the user.
   * @param {User} authenticatedUser The authenticated user to render the profile for.
   * @param {Function} onClose The callback function to register for the close event.
   * @param {Function} onOpen The callback function to register for the open event.
   *
   * @example <binky-authenticated-user-pulldown authenticated-user="myAuthenticatedUser"></binky-authenticated-user-pulldown>
   *
   */
  angular
    .module('binky.widgets.user.authenticated-user-pulldown')
    .component('binkyAuthenticatedUserPulldown', {
      bindings: {
        activeRole: '<?',
        authenticatedUser: '<',
        onClose: '<?',
        onOpen: '<?',
      },
      controller,
      templateUrl:
        'widgets/user/authenticated-user-pulldown/authenticated-user-pulldown.component.html',
    });

  /* @ngInject */
  function controller(_, authentication) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      signOut,
    });

    function $onInit() {
      vm.strViewAccount = vm.authenticatedUser.getType().isType1()
        ? 'binky.widgets.user.authenticatedUserPulldown.viewPersonalAccount'
        : 'binky.widgets.user.authenticatedUserPulldown.viewCompanyOrSchoolAccount';
    }

    function signOut() {
      authentication.signOut();
    }
  }
})();
