import assignIn from 'lodash/assignIn';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

const EDITABLE_FIELDS = ['email', 'isInvalid', 'name', 'phone'];

class Contact {
  constructor(options) {
    initModel(this, options);
  }

  /**
   * @returns {boolean} true if all the fields are filled
   */
  isComplete() {
    return !!(this.email && this.name && this.phone);
  }

  /**
   * @returns {boolean} true if all the fields are not filled
   */
  isEmpty() {
    return !!(!this.email && !this.name && !this.phone);
  }

  /**
   * @returns {boolean} true if at least one of the fields are filled
   */
  isPartiallyComplete() {
    return !!(this.email || this.name || this.phone) && !this.isComplete();
  }
}

/** Private Methods **/
/**
 * @description Initializes Contact data from options.
 *
 * @param {Object} model Contact model Object instance to initialize. See constructor.
 * @param {Object} options Data to initiate the model with.
 *
 * @returns {Object} the model with selected values
 */
function initModel(model, options) {
  const clonedOptions = cloneDeep(options);
  return assignIn(model, pick(clonedOptions, EDITABLE_FIELDS));
}

export default Contact;
