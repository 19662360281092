(function () {
  /**
   * @deprecated Please use src2/core/models/organizationAdminList/OrganizationAdminListConstants.js
   */
  angular
    .module('binky.core.organizations.admin-list')
    .constant('ORGANIZATION_ADMIN_LIST_EVENT', {
      UPDATE: 'UpdateOrganizationAdminList',
      UPDATE_COUNT: 'UpdateCountOrganizationAdminList',
    })
    .constant('ORGANIZATION_ADMIN_LIST_CACHE_ID', 'OrganizationAdminList');
})();
