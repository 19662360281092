import {FILTER_TYPE} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 ComboBox should be replaced with v3 ComboBox
import ComboBox from '@react/react-spectrum/ComboBox';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import messages from '../../../../locales/en.495e31ace1.json';
import Search from '../../search/Search';
import InsightsDateRange from '../insights-date-range/InsightsDateRange';

/**
 * InsightsItemDetailFilters - a widget that contains filters for insights item detail.
 */
const InsightsItemDetailFilters = ({
  filters,
  onChangeDateRange,
  onSearch,
  onSelectItem,
  width = '100%',
}) => {
  const intl = useIntl();
  const labelFor = useId();
  const height = '70px'; // set a fixed height to reserve space for error message
  const dateRangeContent = filters?.find((filter) => filter.type === FILTER_TYPE.DATE_RANGE);
  const searchContent = filters?.find((filter) => filter.type === FILTER_TYPE.SEARCH);
  const dropdownContents = filters?.filter((filter) => filter.type === FILTER_TYPE.DROPDOWN);

  const getLabel = (content) =>
    content.title ||
    intl.formatMessage({
      id:
        content.titleKey ||
        `binky.widgets.insights.action.filters.title.${content.type.toLowerCase()}`,
    });

  const getOptions = (content) =>
    content.options?.map((option) => {
      const key = content.translationKeyPrefix + option.toLowerCase();
      return {
        label: messages[key] ? intl.formatMessage({id: key}) : option,
        value: option,
      };
    });

  const getPlaceholder = (content) =>
    content.placeholder || content.placeholderKey
      ? content.placeholder ||
        intl.formatMessage({
          id: content.placeholderKey,
        })
      : undefined;

  const onChangeDropdown = ({filterKey, value}) => {
    if (!value) {
      onSelectItem({filterKey});
    }
  };

  return (
    <Flex direction="row" gap="size-100" marginBottom="-16px" width={width} wrap>
      {searchContent && (
        <View height={height}>
          <Search
            label={getLabel(searchContent)}
            minWidth="230px" // add this to avoid search field width increase when error icon is displayed
            onSubmit={onSearch}
            placeholder={getPlaceholder(searchContent)}
            width="100%" // add this to allow search field width align with search label
          />
        </View>
      )}
      {dateRangeContent && (
        <View height={height}>
          <InsightsDateRange content={dateRangeContent} onChangeDateRange={onChangeDateRange} />
        </View>
      )}
      {dropdownContents?.map((content) => (
        <View key={content.key} height={height}>
          <FieldLabel label={getLabel(content)} labelFor={labelFor + content.key} />
          {/* move to v3 ComboBox when it's available */}
          <ComboBox
            id={labelFor + content.key}
            onChange={(value) => onChangeDropdown({filterKey: content.key, value})}
            onSelect={({value}) => onSelectItem({filterKey: content.key, value})}
            options={getOptions(content)}
            placeholder={getPlaceholder(content)}
          />
        </View>
      ))}
    </Flex>
  );
};

InsightsItemDetailFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string, // filter key to distinguish same type of components
      maxDate: PropTypes.string,
      maxDateFromNow: PropTypes.number,
      minDate: PropTypes.string,
      minDateFromNow: PropTypes.number,
      options: PropTypes.arrayOf(PropTypes.string), // options for dropdown component
      placeholder: PropTypes.string,
      placeholderKey: PropTypes.string,
      title: PropTypes.string,
      titleKey: PropTypes.string, // keys are prefixed with 'binky.widgets.insights.action.filters.title.'.
      translationKeyPrefix: PropTypes.string,
      type: PropTypes.string.isRequired, // type of filter. DROPDOWN, DATE_RANGE or SEARCH
    })
  ),
  onChangeDateRange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  width: PropTypes.string, // width is used to determine when to wrap the filters
};

export default InsightsItemDetailFilters;
