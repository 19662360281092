import PropTypes from 'prop-types';
import React from 'react';

import ImageIcon from 'common/components/image-icon/ImageIcon';

import Pill from './Pill';

const CustomPill = ({
  closeButtonTooltipText,
  header,
  iconSrc,
  iconAlt,
  isDisabled = false,
  onDismiss,
  onUnmount,
  subtitle,
  tags,
}) => {
  React.useEffect(
    () => () => {
      onUnmount?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
    []
  );

  return (
    <Pill
      closeButtonTooltipText={closeButtonTooltipText}
      header={header}
      IconComponent={<ImageIcon alt={iconAlt} size="L" src={iconSrc} />}
      iconVariant="default"
      isDisabled={isDisabled}
      onDismiss={onDismiss}
      subtitle={subtitle}
      tags={tags}
    />
  );
};

CustomPill.propTypes = {
  /**
   * The tooltip text to display for the Pill's close button.
   */
  closeButtonTooltipText: PropTypes.string,
  /**
   * Header of the Pill.
   */
  header: PropTypes.string.isRequired,
  /**
   * The Pill icon altText.
   */
  iconAlt: PropTypes.string.isRequired,
  /**
   * The Pill icon src.
   */
  iconSrc: PropTypes.string.isRequired,
  /**
   * Whether the Pill is disabled. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The handler to be called when close button is clicked.
   */
  onDismiss: PropTypes.func,
  /**
   * The handler to be called when this component unmounts.
   */
  onUnmount: PropTypes.func,
  /**
   * The subtitle information to be displayed in the Pill.
   */
  subtitle: PropTypes.string,
  /**
   * An optional list of tag descriptors. Tags are displayed under the title and subtitle.
   * Each descriptor contains a unique key for the tag and the visible label. Default is undefined.
   */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default CustomPill;
