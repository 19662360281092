(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('binky.core.feature.ready').provider('featureReady', featureReady);

  /* @ngInject */
  function featureReady(_, configurationReadyProvider, featureProvider) {
    const ngInjector = angular.injector(['ng']);
    const initQ = ngInjector.get('$q');
    const configDeferred = initQ.defer();

    this.configure = configure;
    this.whenConfigReady = whenConfigReady;
    this.$get = $get;

    ////////////

    function configure() {
      configurationReadyProvider
        .whenConfigReady()
        .then((configData) => {
          featureProvider.configure(
            _.get(configData, 'featureFlags'),
            _.get(configData, 'floodgateReleases'),
            _.get(configData, 'featureNames')
          );

          // Get the root target from the config, or default to '$document'
          const angularRoot = _.get(configData, 'angularRoot', '$document');

          // Use an angular service or get the root by an HTML element
          const initDocument = _.startsWith(angularRoot, '$')
            ? ngInjector.get(angularRoot)
            : angular.element(ngInjector.get('$document')[0].querySelector(angularRoot));

          // Additional handlers are configured by configData
          const handlerConfig = _.get(configData, 'ready.feature', {});
          if (handlerConfig.handlerFactory) {
            const appInjector = initDocument.injector();
            appInjector.loadNewModules(_.compact([handlerConfig.handlerFactoryModule]));
            const handlerFactory = appInjector.get(handlerConfig.handlerFactory);
            handlerFactory.configure(configData);
            featureProvider.registerHandler(handlerFactory.handler);
          }
          configDeferred.resolve();
        })
        .catch(configDeferred.reject);

      return configDeferred.promise;
    }

    function whenConfigReady() {
      return configDeferred.promise;
    }

    ////////////

    /* @ngInject */
    function $get($q, apiReady, authenticationReady, feature) {
      const runDeferred = $q.defer();
      const services = {
        run,
        whenRunReady,
      };

      return services;

      ////////////

      function run() {
        $q.all([apiReady.whenRunReady(), authenticationReady.whenRunReady()])
          .then(() => feature.refresh())
          .then(runDeferred.resolve)
          .catch(runDeferred.reject);

        return runDeferred.promise;
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
