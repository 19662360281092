/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- @boag to update */
/**
 * Wrapper for Adobe IMS session authentication library
 * @see {@link https://git.corp.adobe.com/pages/IMS/imslib.js/index.html}
 */
import {AdobeIMS} from '@identity/imslib';

import log from 'services/log';
import initialState from 'services/observability/initialState';

const AUTHENTICATION_ERROR = {
  USER_SIGNED_OUT: 'USER_SIGNED_OUT',
};

let locale, storedConfig;

function getOrigin() {
  // IE/Edge are flaky about window.location.origin, so we do it the old fashioned way
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
}

const authentication = {
  /**
   * Fetch an access token for the currently logged in user.
   *
   * @param {boolean} allowReauth whether to redirect the user to SUSI when no token is available
   * @return {Object} the IMS token object if available (with fields: token, expire, and sid)
   */
  getAccessToken: (allowReauth = true) => {
    if (authentication.isSignedInUser()) {
      return window.adobeIMS.getAccessToken();
    }
    if (allowReauth) {
      authentication.signIn();
    }
    // We return an empty object, as IMS now returns a nested token.
    // Note, if line 16 executes, the call to signIn will trigger a page
    // reload, so this will never actually be hit in the allowReauth case.
    return {};
  },
  /**
   * Fetch the app's environment.
   *
   * @return {String} the current environment of the app.
   */
  getAppEnv: () => {
    if (authentication.isSignedInUser()) {
      if (storedConfig?.environment) {
        return storedConfig.environment.startsWith('prod') ? 'production' : 'stage';
      }
      return undefined;
    }
    throw new Error(AUTHENTICATION_ERROR.USER_SIGNED_OUT);
  },
  /**
   * Fetch the client ID.
   *
   * @return {String} the IMS client_id.
   */
  getClientId: () => {
    if (authentication.isSignedInUser()) {
      return storedConfig?.clientId;
    }
    throw new Error(AUTHENTICATION_ERROR.USER_SIGNED_OUT);
  },
  /**
   * Fetch the profile JSON for the currently logged in user.
   *
   * @return {Promise} a promise which will resolve with the active user's IMS profile
   */
  getProfile: async () => {
    if (authentication.isSignedInUser()) {
      const profile = await window.adobeIMS.getProfile();
      return profile;
    }
    throw new Error(AUTHENTICATION_ERROR.USER_SIGNED_OUT);
  },
  /**
   * Fetch the transitory authorization code (TAC) for the ims configuration key.
   *   The user should already be signed in. The TAC lasts for 30 minutes.
   *
   * @param {String} paramsKey the name of the ims property in the config file for the params to pass to
   *   adobeIMS.getTransitoryAuthorizationCode.
   *   The object in the config file should have the properties 'target_client_id' and 'target_scope'.
   * @return {Promise} resolved with the transitory authorization code if gotten else rejected with an error
   */
  getTransitoryAuthorizationCode: (paramsKey) =>
    new Promise((resolve, reject) => {
      if (authentication.isSignedInUser()) {
        window.adobeIMS.getTransitoryAuthorizationCode(
          storedConfig[paramsKey], // an error is returned if paramsKey is undefined
          (response) => resolve(response.code),
          reject
        );
      } else {
        reject(new Error(AUTHENTICATION_ERROR.USER_SIGNED_OUT));
      }
    }),
  /**
   * Initialize our use of IMSLib.js
   *
   * @param {Object} config the configuration data to initialize with
   * @return {Promise} a promise deferred when initialization completes
   */
  initialize: (config) => {
    storedConfig = config;
    return new Promise((resolve, reject) => {
      // setup context
      window.adobeid = {
        api_parameters: {
          authorize: {
            ctx_id: config.contextId,
          },
        },
        autoValidateToken: true,
        client_id: config.clientId,
        environment: config.environment,
        is_mandatory_sign_in: false,
        locale,
        onAccessTokenHasExpired,
        onError,
        onReady,
        scope: config.scopes,
        useLocalStorage: false,
        uses_redirect_mode: true,
      };

      // If window.adobeIMS is already declared, we trigger onReady
      // This is used to allow 'fake' injection of IMS state
      if (window.adobeIMS) {
        onReady();
      } else {
        window.adobeIMS = new AdobeIMS();
        window.adobeIMS.initialize();
      }

      function onAccessTokenHasExpired() {
        authentication.signIn();
      }

      function onError() {
        reject(new Error(AUTHENTICATION_ERROR.USER_SIGNED_OUT));
      }

      async function onReady() {
        // Observability start time is right after authentication is done.
        // This method is called when:
        // - The user has finished authenticating with SUSI
        // - The user reloads AC, which triggers behind-the-scene authentication
        //   within IMSLib, but code will reach this point too.
        initialState.setAuthenticationReadyElapsedTime(performance.now());

        try {
          const profile = await authentication.getProfile();
          resolve(profile);
        } catch (error) {
          onError();
        }
      }
    });
  },
  /**
   * Check if there is an actively signed in user
   *
   * @return {boolean} true if we have a user signed in
   */
  isSignedInUser: () => {
    if (window.adobeIMS !== undefined) {
      return window.adobeIMS.isSignedInUser();
    }
    return false;
  },
  /**
   * Sets the locale to be used by IMS, and SUSI if appropriate
   *
   * @param {String} newLocale the locale to switch to (e.g. en_US)
   */
  setLocale: (newLocale) => {
    if (newLocale) {
      locale = newLocale;
      if (window.adobeid) {
        window.adobeid.locale = newLocale;
      }
    }
  },
  /**
   * Redirect to SUSI to trigger sign in
   *
   * @param {Object} params an object of parameters to pass to IMSLib.js
   */
  signIn: (params) => {
    window.adobeIMS.signIn(params);
  },
  /**
   * Sign out, triggering a redirect to SUSI for signing back in
   *
   * @param {Object} params an object of parameters to pass to IMSLib.js
   */
  signOut: (params) => {
    window.adobeIMS.signOut({redirect_uri: getOrigin(), ...params});
  },
  /**
   * Redirect to SUSI to trigger sign in as a different user. This is intended for
   * a user switching to a specific T2E within their authentication cluster.
   *
   * @param {String} userId the ID for the user to attempt to switch to.
   * @param {String} [redirectPath] a path on this domain to redirect to. If not provided, defaults to the root.
   */
  switchTo: async (userId, redirectPath) => {
    let path = '';
    let errorResult;

    if (redirectPath) {
      path = redirectPath.charAt(0) === '/' ? redirectPath : `/${redirectPath}`;
    }
    const url = getOrigin() + path;

    try {
      await window.adobeIMS.switchProfile(userId);

      // Force a full reload to reset the app state because it's a new user ID.
      window.location.href = url;
    } catch (error) {
      log.error(`authentication.switchTo(): switchProfile to ${url} failed. Error:`, error);
      errorResult = error;

      // On failure, we force sign out, to try and reset any IMS issues
      authentication.signOut();
    }

    return errorResult ? Promise.reject(errorResult) : Promise.resolve();
  },
};

export {AUTHENTICATION_ERROR};
export default authentication;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- @boag to update */
