(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum ListBox
   *
   * @ngdoc component
   * @name binky.widgets.common.anchor-list:binkyAnchorList
   *
   * @description a wrapper around the Coral AnchorList widget
   *
   * @param {String} [icon] The icon for each anchorList item
   * @param {Array} items The content to display in anchorList
   * @param {String} linkKey The link key per text.
   * @param {String} textKey The key to use for displaying the text
   */

  angular.module('binky.widgets.common.anchor-list').component('binkyAnchorList', {
    bindings: {
      icon: '@?',
      items: '<',
      linkKey: '@',
      textKey: '@',
    },
    controller,
    templateUrl: 'widgets/common/anchor-list/anchor-list.component.html',
  });

  /* @ngInject */
  function controller($element, _, AnalyticsEvent) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      const anchorList = $element.find('coral-anchorlist')[0];
      Coral.commons.ready(anchorList, () => {
        initilizeAnchorListItems(anchorList);
      });
    }

    /* Private functions */
    function initilizeAnchorListItems(anchorList) {
      anchorList.items.clear();
      _.forEach(vm.items, (item) => {
        const newAnchorListItem = new Coral.AnchorList.Item().set({
          content: {
            innerHTML: item[vm.textKey],
          },
          href: item[vm.linkKey],
          icon: vm.icon,
          target: '_blank',
        });

        // This won't affect the original click handler to link them
        // to a new page, it will just run this code as well.
        newAnchorListItem.on('click', () => {
          AnalyticsEvent.dispatch({
            attributes: {
              clickedLinkHref: item[vm.linkKey],
              clickedLinkText: item[vm.textKey],
            },
            componentMethod: 'goToLink',
            componentMethodType: 'click',
            componentName: 'binkyAnchorListItem',
          });
        });

        anchorList.items.add(newAnchorListItem);
      });
    }
  }
})();
