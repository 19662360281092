import inRange from 'lodash/inRange';

import Domain from 'models/domain/Domain';

/**
 * @description represents the response body of a multi-status domains API, such as the add
 *   domain preview API. The response body should be an array, where each item represents a
 *   domain which the request related to:
 *
 *   [
 *     {
 *       id: 'foo.com',     // one of the domains the request related to
 *       responseCode: 201, // HTTP status code for the operation
 *       response: {status: 'ACTIVE', ...} // if 2xx, the respective domain, else an error object
 *     },
 *     {
 *       id: 'bar.com',
 *       responseCode: 400,
 *       response: {
 *         errorCode: 'DOMAIN_ALREADY_CLAIMED_ACTIVE',
 *         errorContext: {'directoryId': 'SomeDirectory'} // optional, further error info
 *       }
 *     }
 *     ...
 *   ]
 */
class DomainListMultiStatusResponse {
  /**
   * @param {Object} response - the json array containing objects with the domain's id, response, responseCode
   */
  constructor(response) {
    this.items = response.map((item) => {
      if (isSuccess(item)) {
        Object.assign(item, {
          response: new Domain(item.response),
        });
      }
      return item;
    });
  }

  getErrors() {
    return this.items.filter((item) => !isSuccess(item));
  }

  getSuccesses() {
    return this.items.filter(isSuccess);
  }

  getSuccessResponses() {
    return this.getSuccesses().map((item) => item.response);
  }
}

function isSuccess(item) {
  return inRange(item.responseCode, 200, 300);
}

export default DomainListMultiStatusResponse;
