/**
 * @deprecated Please use src2/product/productDisplayUtils.js
 *
 * @ngdoc component
 * @name binky.widgets.products.product-name:binkyProductNameLabelFilter
 *
 * @description A filter to construct/retrieve the display name for a given Product or Product-like object which should
 *    at a minimum have a longName.
 *    The display name should append either the storage quota available for
 *    this Product (if storage has been allocated), the DMA fulfillableEntityResourceName,
 *    or free trial if isTrial is passed in as true. Defaults isTrial to false.
 *    Otherwise, just the product name is returned/used.
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.products.product-name')
    .filter('binkyProductNameLabel', productNameLabel);

  /* @ngInject */
  function productNameLabel($translate, _) {
    return getProductNameLabel;
    function getProductNameLabel(product, {isTrial = false} = {}) {
      if (_.invoke(product, 'isEnterprise')) {
        const ccStorageQuota = _.result(
          product,
          'fulfillableItemList.getCreativeCloudStorageChargingModelCap'
        );
        const esmUserStorageQuota = _.result(
          product,
          'fulfillableItemList.getESMUserStorageChargingModelCap'
        );
        const quota = esmUserStorageQuota === 0 ? esmUserStorageQuota : ccStorageQuota;
        const separatorWithSpaces = $translate.instant(
          'binky.widgets.products.productName.separatorWithSpaces'
        );
        // Note: We compose the string from its parts to ensure that $translate params do not
        // contain double-byte characters. $translate's sanitizeParams will mess these up.
        if (!_.isNil(quota)) {
          // storageQuota exists, so return name with storage data appended to end
          const storageQuotaStr = $translate.instant(
            'binky.widgets.products.productName.storageQuota',
            {
              quota,
            }
          );
          return `${product.longName}${separatorWithSpaces}${storageQuotaStr}`;
        }
        const fulfillableEntityResourceName = product.fulfillableEntityResourceName;
        if (!_.isNil(fulfillableEntityResourceName)) {
          // fulfillableEntityResourceName exists, so return name with fulfillableEntityResourceName appended to end
          return `${product.longName}${separatorWithSpaces}${fulfillableEntityResourceName}`;
        }
      } else if (isTrial) {
        return $translate.instant('binky.widgets.products.productName.trial', {
          name: _.get(product, 'longName'),
        });
      }
      // no storageQuota or fulfillableEntityResourceName listed for this Product, just return name if there
      // is one
      return _.get(product, 'longName');
    }
  }
})();
