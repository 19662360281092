import {Tag, TagList} from '@react/react-spectrum/TagList';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const PillTagList = ({tags = []}) => {
  const intl = useIntl();

  return (
    tags.length > 0 && (
      <TagList aria-label={intl.formatMessage({id: 'binky.common.pillTagList.ariaLabel'})} readOnly>
        {tags.map((tag) => (
          <Tag key={tag.key}>{tag.label}</Tag>
        ))}
      </TagList>
    )
  );
};

PillTagList.propTypes = {
  /**
   * An optional list of tag descriptors. Each descriptor contains a unique key for the tag
   * and the visible label. Default is [].
   */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default PillTagList;
