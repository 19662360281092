(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableSummaryList.js
   *
   * @ngdoc factory
   * @name ConsumableSummaryList
   * @description Model for an individual ConsumableSummaryList
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Summary
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableSummaryList', getConsumableSummaryListModel);

  /* @ngInject */
  function getConsumableSummaryListModel(_, ConsumableSummary) {
    class ConsumableSummaryList {
      /**
       * @description Creates a new ConsumableSummaryList for use.
       *
       * @param {Array} items ConsumableSummary entities which make up this list
       * @param {ConsumableSummarization} parentSummarization the parent Summarization for this Summary List
       */
      constructor(items, parentSummarization) {
        this.items = processItems(items, parentSummarization);
      }

      /**
       * @description Find any Consumable objects that are for the specified fulfillable item code
       *
       * @param {String} fiCode the fulfillable item code to find summaries for
       *
       * @returns {Array} List of Consumables that are for the fulfillable item code
       */
      getConsumablesForFulfillableItemCode(fiCode) {
        return _.flatMap(this.items, (item) => item.getConsumablesForFulfillableItemCode(fiCode));
      }

      /**
       * @description Find any ConsumableSummary objects that are for the specified ID
       *
       * @param {String} id the ID to find summaries for
       *
       * @returns {Array} List of Summaries that are for the ID
       */
      getSummariesForId(id) {
        return _.filter(this.items, (item) => item.isSummaryForId(id));
      }
    }

    //////////////

    /**
     * @description Initializes ConsumableSummary items in this list
     *
     * @param {Array} items ConsumableSummary entities to be processed
     * @param {ConsumableSummarization} parentSummarization the parent Summarization for this Summary List
     *
     * @returns {Array} the processed items
     */
    function processItems(items, parentSummarization) {
      return _.map(items, (item) =>
        ConsumableSummary.apiResponseTransformer(item, parentSummarization)
      );
    }

    return ConsumableSummaryList;
  }
})();
