import axios from 'axios';
import URITemplate from 'urijs/src/URITemplate';

import log from 'services/log';

import {csvBlobTransformer} from '../../api/utils/apiUtils';
import {downloadExportData} from '../../utils/downloadUtils';
import authentication from '../authentication';

// extends JilModelList after report broker provider is migrated to src2
class InsightsItemDetailList {
  /**
   * Method for getting a collection of insights item details
   *
   * @param {Object} options - options to configure the insights item detail list. See constructor for details.
   * @returns {InsightsItemDetailList} the insights item detail list instance.
   */
  static get(options) {
    const model = new InsightsItemDetailList(options);
    // return model.refresh() after extending to JilModelList
    return model;
  }

  /**
   * @description Method to create a new instance of a InsightsItemDetailList.
   *
   * @param {Object} options - options passed to the InsightsItemDetailList constructor
   * @param {Object} options.queryParams - query params to filter InsightsItemDetailList
   * @param {String} options.queryParams.orgId - the org id
   * @param {String} options.queryParams.type - type of insights item detail to fetch. Ex: assetActivity
   * @param {String} options.queryParams.view - view type of insights item detail. Ex: default, preview
   */
  constructor(options) {
    this.queryParams = options.queryParams || {};
  }

  /**
   * @description Method to export csv file
   *
   * @param {Object} exportAction - object contains required url template
   * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=~rmccubbi&title=Report+Broker+Export+Design+Proposal
   * @param {String} apiKey - api key to pass as header
   */
  async export(exportAction, apiKey) {
    // eslint-disable-next-line no-underscore-dangle -- key name from back-end
    const self = exportAction._links?.self;

    if (self?.templated) {
      const template = new URITemplate(self.href);
      const urlParams = {};
      Object.keys(self.variableMapping).forEach((key) => {
        urlParams[self.variableMapping[key]] = this.queryParams[key];
      });

      try {
        const response = await axios.get(template.expand(urlParams), {
          headers: {
            Accept: self.media,
            Authorization: `Bearer ${authentication.getAccessToken()?.token}`,
            'X-API-Key': apiKey,
          },
        });
        downloadExportData(
          csvBlobTransformer(response)?.data?.file,
          response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1] ||
            exportAction.defaultFileName ||
            'export'
        );
      } catch (error) {
        log.error('Failed to export data. Error: ', error);
        throw new Error(error);
      }
    } else {
      throw new Error('Not implemented.');
    }
  }

  /**
   * @description Method to update query params of the insightsItemDetail list
   *
   * @param {Object} params - params to pass into the url
   */
  updateQueryParams(params) {
    Object.keys(params).forEach((key) => {
      this.queryParams[key] = params[key];
    });
  }
}

export default InsightsItemDetailList;
