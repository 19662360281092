(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.insights.item')
    .constant('INSIGHTS_ITEM_DETAIL_CONTENT_TYPE', {
      FOLDER: 'folder',
    })
    .constant('INSIGHTS_ITEM_DETAIL_EVENT', {
      CREATE: 'CreateInsightsItemDetail',
    })
    .constant('INSIGHTS_ITEM_DETAIL_DOWNLOAD_MECHANISM', {
      REDIRECT: 'redirect',
      SIMPLE: 'simple',
    })
    .constant('INSIGHTS_ITEM_DETAIL_LIST_CACHE_ID', 'InsightsItemDetailList')
    .constant('INSIGHTS_ITEM_DETAIL_LIST_EVENT', {
      REFRESH: 'RefreshInsightsItemDetailList',
    })
    .constant('INSIGHTS_ITEM_DETAIL_TAB', {
      FILES: 'files',
      VIEW: 'view',
    })
    .constant('INSIGHTS_ITEM_LIST_CACHE_ID', 'InsightsItemList')
    .constant('INSIGHTS_ITEM_LIST_EVENT', {
      REFRESH: 'RefreshInsightsItemList',
    });
})();
