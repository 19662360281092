/**
 * @description Returns the first found indirect contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} indirect contract, or undefined
 */
function getIndirectContract(contractList) {
  return contractList.items.find((item) => item.isIndirectContract());
}

/**
 * @description Returns the first found indirect contract that is in renewal window.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} indirect contract that is in renewal window, or undefined
 */
function getIndirectContractInRenewalWindow(contractList) {
  return contractList.items.find((item) => item.isIndirectContract() && item.isInRenewalWindow());
}

/**
 * @description Returns true if there is an indirect contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if this is an indirect contract.
 */
function hasIndirectContract(contractList) {
  return contractList.items.some((item) => item.isIndirectContract());
}

export {getIndirectContract, getIndirectContractInRenewalWindow, hasIndirectContract};
