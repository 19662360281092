/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name binky.widgets.products.product-icon:binkyProductIcon
 *
 * @description A product icon, selecting the most appropriate based on requested size
 * @param {Boolean} [decorative] - true if this icon is used for purely decorative purposes,
 *    which enables accessiblity/screen reader changes within the component.  Default is
 *    undefined (which equates to false)
 * @param {Object} [icons] Icons object returned as part of the product or product service response
 * @param {String} [iconTitle] The name to be used in the alt attribute for this icon.  Optional,
 *    if value is falsey as well as decorative being falsey, defaults to a product not found message
 * @param {String} [size] Should be one of S, or L.  Optional.
 */
(function () {
  'use strict';
  angular.module('binky.widgets.products.product-icon').component('binkyProductIcon', {
    bindings: {
      decorative: '<?',
      icons: '<?',
      iconTitle: '@?',
      size: '@?',
    },
    controller,
    templateUrl: 'widgets/products/product-icon/product-icon.component.html',
  });

  /* @ngInject */
  function controller(_, $translate) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onInit() {
      resetIcon();
      vm.processedIconTitle =
        vm.iconTitle || $translate.instant('binky.widgets.products.productIcon.invalidProduct');
    }

    function $onChanges() {
      resetIcon();
    }

    function resetIcon() {
      if (vm.icons && vm.icons.svg) {
        vm.src = vm.icons.svg;
      }
    }
  }
})();
