const AUTH_ERROR = 'AUTH_ERROR'; // Artificial error to group all authorization errors
const JEM_TIMEOUT_ERROR = 'JEM_TIMEOUT_ERROR';
const MARKET_SEGMENT = {
  COMMERCIAL: 'COM',
  EDUCATION: 'EDU',
  GOVERNMENT: 'GOV',
};
const ORDER_STATUS = {
  AUTHENTICATED: 'AUTHENTICATED',
  CANCELLED: 'CANCELLED',
  FULFILLED: 'FULFILLED',
  HELD_FOR_MANUAL_AUTH: 'HELD_FOR_MANUAL_AUTH',
  HELD_OFFLINE_PAYMENT: 'HELD_OFFLINE_PAYMENT',
  HELD_UNAUTHORIZED: 'HELD_UNAUTHORIZED',
  HELD_UNVERIFIED: 'HELD_UNVARIFIED',
  IN_PROGRESS: 'IN_PROGRESS',
  INITIALIZE_PAYMENT: 'INITIALIZE_PAYMENT',
  OPEN: 'OPEN',
  PAYMENT_INITIALIZED: 'PAYMENT_INITIALIZED',
  PENDING_AUTHENTICATION: 'PENDING_AUTHENTICATION',
  SUBMITTED: 'SUBMITTED',
};
const ORDER_TYPES = {
  AUTH_ORDER: 'AUTH_ORDER',
  CHANGE_PLAN: 'CHANGE_PLAN',
  NEW_ORDER: 'NEW_ORDER',
  QUOTE: 'QUOTE',
  RENEWAL_ORDER: 'RENEWAL_ORDER',
  RENEWAL_QUOTE: 'RENEWAL_QUOTE',
  TRIAL_CONVERSION: 'TRIAL_CONVERSION',
  VIP_MIGRATION_ORDER: 'VIP_MIGRATION_ORDER',
};
const PURCHASE_FLOW = {
  UNIFIED_CHECKOUT: 'unified_checkout',
};

export {AUTH_ERROR, JEM_TIMEOUT_ERROR, MARKET_SEGMENT, ORDER_STATUS, ORDER_TYPES, PURCHASE_FLOW};
