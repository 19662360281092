import React from 'react';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- ybarra@ to update
const useModalDialog = (initialMode = false) => {
  const [isOpen, setIsOpen] = React.useState(initialMode);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  return [isOpen, openModal, closeModal];
};

export default useModalDialog;
