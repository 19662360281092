import binky from '@admin-tribe/binky';

const AuthenticatedUser = binky.models.user.AuthenticatedUser;
const Locale = binky.services.Locale;

/**
 * @description Utility function for obtaining a display name from provided options.
 *
 * @param {Object} intl - Intl object coming from the useIntl hook.
 * @param {Object} [options] - Top level wrapper object.
 * @param {string} [options.email] - Email of the user to display.
 * @param {string} [options.fallbackName] - Fallback name to apply.
 * @param {string} [options.firstName] - First name of the user to display.
 * @param {string} [options.fullName] - Full name of the user to display.
 * @param {string} [options.lastName] - Last name of the user to display.
 * @param {boolean} [options.showAdobeSuffix] - Flag indicating whether to
 *     include an Adobe suffix to the output.
 * @param {string} [options.userId] - Id of the user to display.
 *
 * @returns {string} Output string based on the provided options.
 */
function getPersonName(intl, options = {}) {
  const locale = Locale.get();
  const name = getName(options);

  if (name) {
    if (options.userId && isLoggedInUser(options.userId)) {
      return intl.formatMessage(
        {
          id: 'binky.common.filters.personName.youSuffix',
        },
        {name}
      );
    }
    if (options.showAdobeSuffix) {
      return intl.formatMessage(
        {
          id: 'binky.common.filters.personName.adobeSuffix',
        },
        {name}
      );
    }
  }

  return name;

  function getName({email, fallbackName, firstName, fullName, lastName}) {
    if (firstName && lastName) {
      return locale.isSurnameFirstNameLocale
        ? `${lastName} ${firstName}`
        : `${firstName} ${lastName}`;
    }
    return lastName || firstName || fullName || email || fallbackName || '';
  }

  function isLoggedInUser(userId) {
    const loggedInUserId = AuthenticatedUser.get().getId();
    return loggedInUserId ? userId === loggedInUserId : false;
  }
}
// eslint-disable-next-line import/prefer-default-export -- utils file
export {getPersonName};
