import {feature} from '@admin-tribe/binky';
import {Flex, ProgressCircle, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import WaitFocusScope from './WaitFocusScope';

/**
 * A wait component that traps the focus and displays a ProgressCircle while a section of the page is loading.
 * While loading, an optional message can be displayed under the wait indicator
 * The content can optionally be shown while loading.
 *
 * This is based on @pandora/react-page-wait (v2.0.1) except this supports a wait on a section of the page.
 * Use @pandora/react-page-wait for a wait component which overlays the entire visible screen,
 * includng any side-nav. There are plans to extend @pandora/react-page-wait to support section of
 * the page so check the most recent version.
 *
 * ToDo: when showContent is false, everything in the DOM below the overlay should be
 * marked aria-hidden="true", just as it is when a dialog is
 */
const OverlayWait = ({children, isLoading, loadingMessage, showContent, ...waitProps}) => {
  const intl = useIntl();

  const a11yWait = feature.isEnabled('temp_a11y_overlay_wait');

  // @deprecated with temp_a11y_overlay_wait
  const getWaitComponent = () => (
    <View
      backgroundColor="gray-100"
      bottom="size-0"
      left="size-0"
      position="absolute"
      right="size-0"
      top="size-0"
      UNSAFE_style={{opacity: showContent ? '0.75' : '1'}}
    >
      <Flex
        alignItems="center"
        bottom="size-0"
        direction="column"
        justifyContent="center"
        left="size-0"
        minHeight="size-800" // height of largest ProgressCircle
        position="absolute"
        right="size-0"
        top="size-0"
        width="100%"
      >
        <ProgressCircle
          aria-label={intl.formatMessage({id: 'binky.common.wait.loadingLabel'})}
          isIndeterminate
          {...waitProps}
        />
        {loadingMessage && (
          <View
            data-testid="loading-message"
            marginTop="size-100"
            UNSAFE_style={{color: 'var(--color-grey-800)', fontSize: 'larger'}}
          >
            {loadingMessage}
          </View>
        )}
      </Flex>
    </View>
  );

  return (
    <View aria-busy={isLoading ? 'true' : 'false'} position="relative">
      {children}
      {isLoading && !a11yWait && getWaitComponent()}
      {isLoading && a11yWait && (
        <WaitFocusScope loadingMessage={loadingMessage} showContent={showContent} {...waitProps} />
      )}
    </View>
  );
};

OverlayWait.propTypes = {
  /**
   * The content to display behind the wait.
   */
  children: PropTypes.node,
  /**
   * The number of milliseconds to delay before showing the wait spinner.
   * The default is 600ms when 'temp_a11y_overlay_wait' is enabled, else 0.
   */
  delay: PropTypes.number,
  /**
   * Whether the wait should be shown above the content.
   */
  isLoading: PropTypes.bool,
  /**
   * An optional localized string to display below the loading spinner.
   */
  loadingMessage: PropTypes.string,
  /**
   * Whether to show the content when the wait spinner is rendered.
   * The default is false.
   */
  showContent: PropTypes.bool,
  /**
   * Styles to pass to Spectrum's ProgressCircle.
   * An aria-label must be provided for accessibility. The default is 'Loading...'.
   * The size is what the ProgressCircle's diameter should be. Spectrum's default is 'M'.
   */
  waitProps: PropTypes.shape({
    'aria-label': PropTypes.string,
    size: PropTypes.string,
  }),
};

export default OverlayWait;
