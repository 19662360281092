import {
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from 'models/user/roles/UserRolesConstants';
import feature from 'services/feature';

class UserRole {
  /**
   * @description Method to construct a UserRole for one of the top-level types
   *
   * @param {String} type of role to create (e.g. - DEPLOYMENT_ADMIN, ORG_ADMIN, etc)
   * @returns {UserRole} the requested UserRole
   */
  static get(type) {
    let role;
    switch (type) {
      case COMPARTMENT_ADMIN:
      case COMPARTMENT_VIEWER:
      case CONTRACT_ADMIN:
      case DEPLOYMENT_ADMIN:
      case ORG_ADMIN:
      case STORAGE_ADMIN:
      case SUPPORT_ADMIN:
        role = new UserRole({
          type,
        });
        break;
      default:
        throw new Error(`Unable to resolve type (${type}) to create UserRole`);
    }
    return role;
  }

  /**
   * @description Method to construct a UserRole object for developer admins from an existing set of product license groups.
   *
   * @param {Array} productConfigurations instances to create UserRole from
   * @param {Array} activeOrgProducts Array containing active org product data
   * @returns {UserRole} new UserRole
   */
  static getDeveloperFromLicenseGroups(productConfigurations, activeOrgProducts) {
    return new UserRole({
      targets: innitTargets(productConfigurations, activeOrgProducts),
      type: LICENSE_DEV_ADMIN,
    });
  }

  /**
   * @description Method to construct a UserRole from an existing set of product license groups.
   *
   * @param {Array} productConfigurations instances to create UserRole from
   * @param {Array} activeOrgProducts Array containing active org product data
   * @returns {UserRole} new UserRole
   */
  static getFromLicenseGroups(productConfigurations, activeOrgProducts) {
    return new UserRole({
      targets: innitTargets(productConfigurations, activeOrgProducts),
      type: LICENSE_ADMIN,
    });
  }

  /**
   * @description Method to construct a UserRole from an existing set of products
   *
   * @param {Array} productConfigurations instances to create UserRole from
   * @param {Array} activeOrgProducts Array containing active org product data
   * @param {String} type role type, PRODUCT_ADMIN or PRODUCT_SUPPORT_ADMIN
   * @returns {UserRole} new UserRole
   */
  static getFromProducts(productConfigurations, activeOrgProducts, type) {
    return new UserRole({
      targets: productConfigurations?.map((productConfiguration) => ({
        id: productConfiguration.id,
        parentProduct: activeOrgProducts?.find((product) => product.id === productConfiguration.id),
      })),
      type: feature.isEnabled('temp_parkour_mm') ? type : PRODUCT_ADMIN,
    });
  }

  /**
   * @description Method to construct a UserRole from an existing set of contracts
   *
   * @param {Array} contractConfigurations instances to create UserRole from
   * @param {Array} activeOrgContracts Array containing active org contract data
   * @param {String} type role type, CONTRACT_ADMIN
   * @returns {UserRole} new UserRole
   */
  static getFromContracts(contractConfigurations, activeOrgContracts) {
    return new UserRole({
      targets: contractConfigurations?.map((contractConfiguration) => ({
        id: contractConfiguration.id,
        parentContract: activeOrgContracts?.find(
          (contract) => contract.id === contractConfiguration.id
        ),
      })),
      type: CONTRACT_ADMIN,
    });
  }

  /**
   * @description Method to construct a UserRole from an existing set of user groups
   *
   * @param {Array} productConfigurations instances to create UserRole from
   * @returns {UserRole} new UserRole
   */
  static getFromUserGroups(productConfigurations) {
    return new UserRole({
      targets: productConfigurations?.map((productConfiguration) => ({
        id: productConfiguration.id,
        name: productConfiguration.name,
      })),
      type: USER_GROUP_ADMIN,
    });
  }

  /**
   * @description Method to check whether type can have targets or not
   *
   * @param {String} type role type
   * @returns {Bool} whether type can have targets or not
   */
  static areTargetsRequiredForType(type) {
    return (
      type === LICENSE_ADMIN ||
      type === LICENSE_DEV_ADMIN ||
      type === PRODUCT_ADMIN ||
      (feature.isEnabled('temp_parkour_mm') && type === PRODUCT_SUPPORT_ADMIN) ||
      type === USER_GROUP_ADMIN
    );
  }

  /**
   * @description Creates a new UserRole for use.
   *
   * @param {Object} options - options to initialize user role
   * @param {String} [options.name] - name of role, if applicable
   * @param {String} [options.type] - type of role (e.g. - LICENSE_ADMIN, ORG_ADMIN, DEPLOYMENT_ADMIN)
   * @param {Array} [options.targets] - an array of targets the user has this role for
   */
  constructor(options) {
    this.name = options.name;
    this.type = options.type;
    this.targets = options.targets;
  }

  /**
   * @description Method to determine if this UserRole represents a Deployment Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a Deployment Admin, else false
   */
  isDeploymentAdmin() {
    return this.type === DEPLOYMENT_ADMIN;
  }

  /**
   * @description Method to determine if this UserRole represents a License Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a License Admin, else false
   */
  isLicenseAdmin() {
    return this.type === LICENSE_ADMIN;
  }

  /**
   * @description Method to determine if this UserRole represents a Org Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a Org Admin, else false
   */
  isOrgAdmin() {
    return this.type === ORG_ADMIN;
  }

  /**
   * @description Method to determine if this UserRole represents a Product Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a Product Admin, else false
   */
  isProductAdmin() {
    return this.type === PRODUCT_ADMIN;
  }

  /**
   * @description Method to determine if this UserRole represents a Storage Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a Storage Admin, else false
   */
  isStorageAdmin() {
    return this.type === STORAGE_ADMIN;
  }

  /**
   * @description Method to determine if this UserRole represents a User Group Admin role or not.
   *
   * @returns {Boolean} true if this UserRole represents a User Group Admin, else false
   */
  isUserGroupAdmin() {
    return this.type === USER_GROUP_ADMIN;
  }
}

function innitTargets(productConfigurations, activeOrgProducts) {
  return productConfigurations?.map((productConfiguration) => ({
    id: productConfiguration.id,
    name: productConfiguration.name,
    parentId: productConfiguration.parentId,
    parentProduct: activeOrgProducts?.find(
      (product) => product.id === productConfiguration.parentId
    ),
  }));
}

export default UserRole;
