import {v4 as uuid4} from 'uuid';

import Offer from 'models/offers/Offer';
import {usesSeatBasedDelegation} from 'models/offers/offerUtils';

/**
 * @description SummaryItem represents a SummaryItem from the acquisition-summaries API
 * and its matching offer.
 */
class SummaryItem {
  /**
   * @param {String[]} options.aqoIds - the offer id
   * @param {String}   options.backedQuantity - the backed quantity
   * @param {Date}     options.endDate - the end date
   * @param {Object}   options.offer - the offer data matching the offer id
   * @param {Object}   options.offer.product_arrangement - the product_arrangement of the offer
   * @param {Object[]} options.offer.product_arrangement.fulfillable_items - the fulfillable_items
   * @param {String}   options.offerId - the offer id
   * @param {String}   options.unbackedQuantity - the unbacked quantity
   * @param {Object[]} options.complianceDistributions - the compliance distributions
   * @param {Number}   options.complianceDistributions[].quantity - the quantity
   * @param {Object[]} options.complianceDistributions[].complianceSymptoms - the compliance symptoms
   * @param {Object[]} options.acquiredOfferDistributions - the acquired offer distributions
   * @param {String}   options.acquiredOfferDistributions[].acquiredOfferId - the acquired offer id
   * @param {Object[]} options.acquiredOfferDistributions[].acquiredOfferTuples - the acquired offer tuples
   * @param {String}   options.acquiredOfferDistributions[].acquiredOfferTuples[].tupleId - the tuple id
   * @param {Number}   options.acquiredOfferDistributions[].acquiredOfferTuples[].quantity - the quantity
   * @param {Object[]} options.acquiredOfferDistributions[].acquiredOfferTuples[].complianceSymptoms - the compliance symptoms
   * @param {Boolean}  options.hasEnglishAndMultiLanguage - true if this summary item belongs to an acquisition summary where
   *                                                        there are both EN and MULT offers
   * @param {Boolean} options.quantityUnlimited - true if the offer has unlimited quantities
   */
  constructor(options) {
    Object.assign(this, options, {
      offer: new Offer(options.offer),
      // Generate a unique id for this item since no other field is unique enough
      uuid: uuid4(),
    });
  }

  /**
   * @returns The offer's svg icon
   */
  getIcon() {
    return this.offer.getIcon();
  }

  /**
   * @returns The offer's language
   */
  getLanguage() {
    return this.offer.getLanguage();
  }

  /**
   * @returns The offer's copy name
   */
  getName() {
    return this.offer.getName();
  }

  /**
   * @returns {Number} the total quantity for this summary item
   */
  getTotalQuantity() {
    // default to 0 when the quantities are undefined
    const backed = Number.parseInt(this.backedQuantity, 10) || 0;
    const unbacked = Number.parseInt(this.unbackedQuantity, 10) || 0;
    return backed + unbacked;
  }

  /**
   * @returns {Boolean} true if the offer uses seat based delegation.
   */
  usesSeatBasedDelegation() {
    return (
      usesSeatBasedDelegation({
        buyingProgram: this.offer.buying_program,
        family: this.offer.product_arrangement.family,
        fulfillableItemList: this.offer.fulfillableItemList,
        productArrangementCode: this.offer.product_arrangement_code,
      }) && !this.quantityUnlimited
    );
  }
}

export default SummaryItem;
