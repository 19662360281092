import authentication from 'services/authentication';
import Locale from 'services/locale/Locale';

/**
 * Get headers for Pores API calls
 *
 * @param {String} clientId - The client id
 * @returns {Object} The header map
 */
const getPoresHeaders = ({clientId}) => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for Pores API call');
  }
  return {
    'Accept-Language': Locale.get().activeLocaleCode,
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getPoresHeaders};
