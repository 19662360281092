(function () {
  'use strict';
  /**
   * @deprecated use the Pandora phone number input
   *
   * @ngdoc component
   * @name binky.widgets.common.phone-number-input:binkyPhoneNumberInput
   *
   * @description A widget for entering a phone number.
   *
   * @binding {Boolean} [displayOnly] - disable interaction and change style if set to true
   * @binding {String} [initialCountryCode] - the two-character IMS country code to select by
   *   default in the international dial code dropdown. If unspecified, defaults to 'US'. If a
   *   dial code is included in the initialPhoneNumber binding, that dial code takes precedence.
   * @binding {String} [initialPhoneNumber] - the phone number to display in the input by default.
   *   Ideally, the number should be an E.164 compliant number (e.g. +12066757000), but the
   *   underlying intl-tel-input will attempt to parse any number.
   *   See http://en.wikipedia.org/wiki/E.164.
   * @binding {Function} [onChange] - called when the inputted number changes, and on widget
   *   initialization if an initialPhoneNumber is specified. The function is invoked with two
   *   arguments:
   *     - phoneNumber: the inputted phone number, in E.164 format if it could be parsed -
   *         e.g. '+12066757000'
   *     - isValid: true if the number is a valid phone number, else false
   * @binding {Function} [onChange] - called when the component has finished rendering and has
   *   validated the initial phone number (if any). Passes the same arguments as onChange.
   * @binding {Boolean} [isInternational] - whether to assume the initialPhoneNumber is an
   *   international phone number. If true, this component ensures the initialPhoneNumber is
   *   prepended with '+' before passing it to the underlying intl-tel-input. This is intended to
   *   be useful with phone numbers from IMS user profiles, which historically have been set by
   *   SUSI and accounts.adobe.com to be of the form '12066757000' rather than '+12066757000' (the
   *   latter being the E.164 format expected by intl-tel-input). Explicitly set this binding to
   *   "false" to disable this behaviour, otherwise it is treated as "true".
   * @binding {Boolean} [showCountryCode] - show the country code in the country selector
   */
  angular.module('binky.widgets.common.phone-number-input').component('binkyPhoneNumberInput', {
    bindings: {
      displayOnly: '@?',
      initialCountryCode: '@?',
      initialPhoneNumber: '@?',
      isInternational: '@?',
      onChange: '&?',
      onInit: '&?',
      showCountryCode: '@?',
    },
    controller,
    templateUrl: 'widgets/common/phone-number-input/phone-number-input.component.html',
  });

  /* @ngInject */
  function controller(_, $element, $log, $translate, $scope, $window, CountryList) {
    const vm = this;

    // Events fired by <input> which indicate the phone number has changed
    const PHONE_NUMBER_CHANGE_EVENTS = 'countrychange input';
    // Events fired by <input> which cause us to 'auto-format' the phone number it contains
    const PHONE_NUMBER_FORMAT_EVENTS = 'blur';

    // Error codes which have matching strings in widgets.common.phoneNumberInput.validationError.*.
    // When updating this list, you must also update the strings.
    const errorCodesWithCustomMessages = [
      $window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE,
      $window.intlTelInputUtils.validationError.TOO_LONG,
      $window.intlTelInputUtils.validationError.TOO_SHORT,
    ];

    let iti;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      $postLink,
    });

    function $onDestroy() {
      if (iti) {
        iti.destroy();
      }
    }

    function $onInit() {
      _.assign(vm, {
        // Disable the input until the IMS country list is fetched and intl-tel-input is initialized
        isDisabled: true,
        isDisplayOnly: vm.displayOnly === 'true',
        separateDialCode: vm.showCountryCode === 'true',
      });
    }

    function $postLink() {
      vm.waitPromise = CountryList.get()
        .$promise.then(
          (imsCountryList) => initInput(imsCountryList.items),
          () => {
            $log.error('Failed to fetch IMS country list from phone-number-input');
            initInput();
          }
        )
        .finally(() => {
          vm.isDisabled = false;
        });
    }

    ///////////////

    function addInputEventListeners() {
      const inputEl = getInputEl();
      inputEl.on(PHONE_NUMBER_CHANGE_EVENTS, onRawInputEvent);
      inputEl.on(PHONE_NUMBER_FORMAT_EVENTS, formatPhoneNumber);
    }

    function formatPhoneNumber() {
      // Format the input field by calling setNumber(..) with the currently-set phone number.
      // Remove event listeners first, since some numbers (e.g. "+4") cause setNumber(..) to
      // trigger a spurious countrychange event.
      removeInputEventListeners();
      iti.setNumber(iti.getNumber());
      addInputEventListeners();
    }

    /**
     * Returns an object containing the countries that should be shown in the phone number country
     * dropdown. Each key of the object is a country ISO2 code (e.g. 'de' for Germany) and the
     * value is the corresponding IMS localized name for that country, e.g.
     *
     * {de: 'Germany', ..., us: 'United States of America'}
     *
     * Any countries supported by intl-tel-input but not returned by the IMS countries API are
     * omitted from the list. This matches the account.adobe.com phone number input behaviour. It
     * also ensures we use the localized, IMS-approved term for any country shown.
     *
     * @param {Array<Country>} imsCountries - the countries returned by IMS
     * @return {Object} the countries that should be shown in the phone number country dropdown
     */
    function getCountryOptions(imsCountries) {
      const itiCountries = $window.intlTelInputGlobals.getCountryData();
      return _(imsCountries)
        .intersectionWith(
          itiCountries,
          (imsCountry, itiCountry) => itiCountry.iso2 === _.toLower(imsCountry.countryCode)
        )
        .reduce((result, imsCountry) => {
          result[_.toLower(imsCountry.countryCode)] = imsCountry.countryName;
          return result;
        }, {});
    }

    function getErrorMessage() {
      const errorCode = iti.getValidationError();
      const customErrorKey = _.includes(errorCodesWithCustomMessages, errorCode)
        ? _.findKey($window.intlTelInputUtils.validationError, (error) => error === errorCode)
        : null;

      const errorKey = customErrorKey ? `custom.${customErrorKey}` : 'generic';
      return $translate.instant(
        `binky.widgets.common.phoneNumberInput.validationError.${errorKey}`
      );
    }

    function getInputEl() {
      return $element.find('input');
    }

    function initInput(imsCountries) {
      const options = {
        allowDropdown: !vm.isDisplayOnly,
        customPlaceholder: (examplePhoneNumber) =>
          $translate.instant('binky.widgets.common.phoneNumberInput.placeholder', {
            examplePhoneNumber,
          }),
        initialCountry: getInitialCountry(),
        placeholderNumberType: 'FIXED_LINE',
        preferredCountries: [],
        separateDialCode: vm.separateDialCode,
      };

      // If IMS countries were successfully fetched, use the country names returned by IMS in the
      // country/flag dropdown. Otherwise, use the intl-tel-input's built in country names.
      if (imsCountries) {
        const localizedCountries = getCountryOptions(imsCountries);
        _.assign(options, {
          localizedCountries,
          onlyCountries: _.keys(localizedCountries),
        });
      }

      // Ensure <input> has been transcluded by coral-wait before attempting to access it
      Coral.commons.ready(() => {
        // Intialize the intl-tel-input widget
        iti = $window.intlTelInput(getInputEl()[0], options);
        const initialPhoneNumber = getInitialPhoneNumber();
        if (!_.isEmpty(initialPhoneNumber)) {
          iti.setNumber(initialPhoneNumber);
        }
        addInputEventListeners();
        showAnyValidationErrors();
        invokeInitCallback();
      });
    }

    /**
     * @description Returns the country code to use as intlTelInput's initialCountry option. This
     *   value determines what country/flag is shown by default, unless initialPhoneNumber was
     *   specified and intlTelInput can infer the country from it.
     *
     *   This function typically returns the initialCountryCode passed to the component. However,
     *   the value defaults to "US" in the following cases:
     *
     *   1. initialCountryCode was not specified
     *   2. initialCountryCode is "UD". We ignore "UD" because it's a special Renga value meaning
     *      "undefined", i.e. unknown; it is not a valid country code and breaks intlTelInput
     *      (See https://jira.corp.adobe.com/browse/CAUIP-4926).
     *
     * @returns {String} the default country code to set on intlTelInput
     */
    function getInitialCountry() {
      if (!vm.initialCountryCode || vm.initialCountryCode === 'UD') {
        return 'US';
      }
      return vm.initialCountryCode;
    }

    function getInitialPhoneNumber() {
      const shouldPrependPlusSymbol =
        vm.isInternational !== 'false' &&
        !_.isEmpty(vm.initialPhoneNumber) &&
        !_.startsWith(vm.initialPhoneNumber, '+');
      return shouldPrependPlusSymbol ? `+${vm.initialPhoneNumber}` : vm.initialPhoneNumber;
    }

    function invokeCallback(callbackName) {
      _.invoke(vm, callbackName, {
        $event: {isValid: iti.isValidNumber(), phoneNumber: iti.getNumber()},
      });
    }

    function invokeChangeCallback() {
      invokeCallback('onChange');
    }

    function invokeInitCallback() {
      invokeCallback('onInit');
    }

    function onRawInputEvent() {
      showAnyValidationErrors();
      invokeChangeCallback();
      // Since the input event occurred outside of Angular, force a digest cycle to make any vm
      // changes take effect
      $scope.$apply();
    }

    function removeInputEventListeners() {
      const inputEl = getInputEl();
      inputEl.off(PHONE_NUMBER_CHANGE_EVENTS, onRawInputEvent);
      inputEl.off(PHONE_NUMBER_FORMAT_EVENTS, formatPhoneNumber);
    }

    function showAnyValidationErrors() {
      const isInputPopulated = !!_.trim(iti.getNumber());
      vm.errorMessage = isInputPopulated && !iti.isValidNumber() ? getErrorMessage() : null;
    }
  }
})();
