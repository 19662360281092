import clone from 'lodash/clone';
import compact from 'lodash/compact';
import invokeMap from 'lodash/invokeMap';
import partition from 'lodash/partition';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';

import jilUsers from 'api/jil/jilUsers';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import {MEMBER_TECH_ACCOUNT_DOMAIN} from 'models/member/type/MemberTypeConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import log from 'services/log';

import MemberList from '../member/MemberList';

import {
  ORGANIZATION_USER_LIST_CACHE_ID,
  ORGANIZATION_USER_LIST_EVENT,
} from './OrganizationUserListConstants';

class OrganizationUserList extends MemberList {
  /**
   * @param {Object} options - See MemberList for additional options
   */
  constructor(options) {
    super({
      filterExcludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
      itemClassRef: OrganizationUser,
      modelCacheId: ORGANIZATION_USER_LIST_CACHE_ID,
      refreshResource: jilUsers.getUsers,
      saveResource: jilUsers.patchUsers,
      ...options,
    });
    this.SEARCH_QUERY_PATTERN = /^[^*]*\*?$/; // no content allowed after an asterisk
  }

  /**
   * @description Method to execute after a successful refresh.
   */
  onRefreshSuccess() {
    eventBus.emit(ORGANIZATION_USER_LIST_EVENT.UPDATE);
    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(ORGANIZATION_USER_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
    }
  }

  /**
   * @description Method to save changes to user list to back-end.
   *
   * @param {Object} [options] - options to alter the behavior of save. See MemberList.save() for more details
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message
   */
  async save(options = {}) {
    if (this.addedItems.length > 0) {
      if (hasDuplicateUsers(this)) {
        this.addedItems = mergeDuplicateUsers(this);
      }

      const [newUsers, existingUsers] = partition(this.addedItems, (user) => user.isNew());
      const addUserPromise = addNewUsers(this, newUsers);
      const patchUserPromise = patchExistingUsers(this, existingUsers);
      await Promise.all([addUserPromise, patchUserPromise]);
      return this;
    }

    if (this.removedItems.length > 0) {
      const removedItems = clone(this.removedItems);
      await super.save({}, options);
      removedItems.forEach((removedUser) => {
        eventBus.emit(MEMBER_EVENT.DELETE, removedUser.id);
      });
    }
    return this;
  }
}

function addNewUsers(model, addedUsers) {
  if (addedUsers.length === 0) {
    return Promise.resolve(model);
  }
  const usersArray = invokeMap(addedUsers, 'toMinimumModel');
  return (
    jilUsers
      .batchAddUsers({orgId: model.orgId, usersArray})
      // eslint-disable-next-line promise/prefer-await-to-then -- parallelize API calls avoid await
      .then((response) => {
        const responseData = response.data;
        const [successfulItems, failedItems] = partition(
          responseData,
          (item) => item.responseCode >= 200 && item.responseCode < 300
        );

        if (successfulItems.length > 0) {
          responseData.forEach((responseItem) => {
            if (successfulItems.includes(responseItem)) {
              // The order of users in API response can not be assumed.
              // Since duplicate emails are not allowed, the email field can be used to match results.
              let user;
              if (feature.isEnabled('temp_fix_add_user_case_check')) {
                user = addedUsers.find(
                  (addedUser) =>
                    addedUser.email.localeCompare(responseItem.response.email, 'en', {
                      sensitivity: 'base',
                    }) === 0
                );
              } else {
                user = addedUsers.find(
                  (addedUser) => addedUser.email === responseItem.response.email
                );
              }
              user.id = responseItem.response.id;
              eventBus.emit(MEMBER_EVENT.CREATE, user.id);
              model.items.push(user);
              remove(model.addedItems, user);
            }
          });
        }
        if (failedItems.length > 0) {
          failedItems.forEach((item) => {
            log.error('Failed to update new organization user. Error: ', item.response.errorCode);
          });
          // We store the headers on every item, to ensure we can trace errors
          // eslint-disable-next-line promise/no-return-wrap -- Use reject to pass error up to caller
          return Promise.reject(failedItems.map((item) => ({...item, headers: response.headers})));
        }
        return model;
      })
      // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks -- parallelize API calls avoid await
      .catch((error) => {
        log.error('Failed to create organization users. Error: ', error);
        // eslint-disable-next-line promise/no-return-wrap -- Use reject to pass error up to caller
        return Promise.reject(error);
      })
  );
}

function hasDuplicateUsers(model) {
  return (
    uniqBy(model.addedItems, (addedItem) => {
      const addedItemData = [addedItem.id, addedItem.email, addedItem.type];
      return addedItemData.join();
    }).length !== model.addedItems.length
  );
}

function mergeDuplicateUsers(model) {
  const newAddedItems = [];
  model.addedItems.forEach((addedItem) => {
    // No need to check for duplicates by id, List will not let us add duplicates with the same id
    const existingEntry = newAddedItems.find(
      (item) => item.email === addedItem.email && item.type === addedItem.type
    );
    if (existingEntry) {
      existingEntry.products.push(...addedItem.products);
      existingEntry.roles.push(...addedItem.roles);
      existingEntry.userGroups.push(...addedItem.userGroups);
    } else {
      newAddedItems.push(addedItem);
    }
  });

  return newAddedItems;
}

async function patchExistingUsers(userList, existingUsers) {
  let operations = [];
  existingUsers.forEach((user) => {
    operations = [...operations, ...OrganizationUser.getPatchOperationsForUpdate(user)];
  });
  operations = compact(operations);
  if (operations && operations.length > 0) {
    try {
      await jilUsers.patchUsers({operations, orgId: userList.orgId});
    } catch (error) {
      log.error('Failed to update organization users. Error: ', error);
      throw error;
    }
    existingUsers.forEach((user) => {
      const message = MEMBER_EVENT.UPDATE;
      eventBus.emit(message, user.id);
      userList.items.push(user);
      remove(userList.addedItems, user);
    });
  }
  return userList;
}

export default OrganizationUserList;
