/* eslint-disable promise/prefer-await-to-callbacks, promise/prefer-await-to-then -- Need to use promises */
import {log} from '@admin-tribe/binky';
import {Flex, ProgressCircle} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import useStateAndRef from 'common/hooks/useStateAndRef';

import HeaderNavItem from './HeaderNavItem';
import NavItems from './NavItems';

const addNavItem = (filteredNavItems, newNavItem) =>
  // Make a new array so that React can detect the change
  [...filteredNavItems, newNavItem].sort((a, b) => a.order - b.order);

/** Site header nav */
const HeaderNav = ({maxWidth, navItems, selectedNavItemKey}) => {
  // The filtered nav items and count get a state and ref so that the observed value does not have to be passed
  // into the dependency array of hooks which will trigger infinite re-renders as the list is updated.
  const [filteredNavItems, filteredNavItemsRef, setFilteredNavItems] = useStateAndRef([]);
  // The loading count is used to track how many async isVisible() calls are in progress
  const [loadingKeyCount, loadingKeyCountRef, setLoadingKeyCount] = useStateAndRef(0);
  const intl = useIntl();

  /** Filters each nav item by it's isVisible() return value */
  useEffect(() => {
    navItems?.forEach((item) => {
      // If the item is already in the filtered list then don't add it again
      if (filteredNavItemsRef.current.find((i) => i.key === item.key)) {
        return;
      }

      const itemIsVisible = item.isVisible();
      // isVisible() can return a promise so that must be handled
      if (itemIsVisible.then) {
        setLoadingKeyCount(loadingKeyCountRef.current + 1);
        itemIsVisible
          .then((isVisible) => {
            if (isVisible) {
              setFilteredNavItems(addNavItem(filteredNavItemsRef.current, item));
            }
          })
          .catch((error) => {
            log.error('Error determining header nav item visibility', error);
          })
          .finally(() => {
            setLoadingKeyCount(loadingKeyCountRef.current - 1);
          });
      } else if (itemIsVisible === true) {
        setFilteredNavItems(addNavItem(filteredNavItemsRef.current, item));
      }
    });
  }, [filteredNavItemsRef, loadingKeyCountRef, navItems, setFilteredNavItems, setLoadingKeyCount]);

  return (
    filteredNavItems.length > 0 && (
      <Flex data-testid="navitems-wrapper">
        {loadingKeyCount > 0 && (
          <ProgressCircle
            alignSelf="center"
            aria-label={intl.formatMessage({id: 'binky.common.wait.loadingLabel'})}
            data-testid="navitems-loading"
            isIndeterminate
            size="S"
          />
        )}
        <NavItems
          maxWidth={maxWidth}
          navItems={filteredNavItems}
          selectedNavItemKey={selectedNavItemKey}
        />
      </Flex>
    )
  );
};

HeaderNav.propTypes = {
  /**
   * Max width of the middle section of the site header
   */
  maxWidth: PropTypes.number,
  /**
   * Array of nav items to generate links for.
   */
  navItems: PropTypes.arrayOf(PropTypes.instanceOf(HeaderNavItem)),
  /**
   * Selected nav item to highlight
   */
  selectedNavItemKey: PropTypes.string,
};

export default HeaderNav;
/* eslint-enable promise/prefer-await-to-callbacks, promise/prefer-await-to-then -- Need to use promises */
