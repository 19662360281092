import pick from 'lodash/pick';

import eventBus from 'services/events/eventBus';

import {ANALYTICS_EVENT} from './AnalyticsConstants';

class AnalyticsEvent {
  /**
   * Dispatch an analytics event to Launch
   *
   * @param {Object} analytics - the analytics to be sent to Launch
   */
  static dispatch(analytics) {
    eventBus.emit(ANALYTICS_EVENT, new AnalyticsEvent(analytics));
  }

  /**
   * @class
   * @description Creates a new AnalyticsEvent object for use.
   *
   * @param {Object} options - configures the AnalyticsEvent instance.
   * @param {Array} [options.attributes] key value pair attributes to be passed along with this event
   * @param {Object} [options.cart] the details of the cart
   * @param {Object} [options.cgen] campaign code generator
   * @param {String} [options.componentMethod] the method of the component generating this event
   * @param {String} [options.componentMethodType] the type of method generating this event
   * @param {String} [options.componentName] the name of the component generating this event
   * @param {Object} [options.descriptors] Object that contains data descriptors keyed by the name,
   * for example:
   *  {
   *    cart: {
   *      offerList: [offer descriptors],
   *      payment: {payment descriptor}
   *    }
   *    product: {product descriptor},
   *    sophia: {sophia descriptor},
   *    uiEvent: {uiEvent descriptor}
   *  }
   * @param {String} [options.pageContext] the page context for the place this event was generated
   */
  constructor(options = {}) {
    Object.assign(
      this,
      pick(options, [
        'attributes',
        'cart',
        'cgen',
        'componentMethod',
        'componentMethodType',
        'componentName',
        'descriptors',
        'pageContext',
      ])
    );
    if (!this.attributes) {
      this.attributes = {};
    }
  }
}

export default AnalyticsEvent;
