import {StatusLight as SpectrumStatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import './StatusLight.pcss';

/**
 * A wrapper on Spectrum StatusLight so that it can be used in places where an unwrapped StatusLight is
 * out of alignment.
 * This component removes the unbalanced paddingTop and paddingBottom so that it is possible to align the text
 * wrapped in the StatusLight with nearby text.
 * It will also optionally remove the 12px left margin before the img which makes it difficult to align a StatusLight
 * in a column under its header, for example if the StatusLight is in a Cell in a Table/TableView.
 */
const StatusLight = ({alignStart = false, children, ...spectrumProps}) => (
  <SpectrumStatusLight
    data-testid={(alignStart && 'status-light-align-start') || 'status-light'}
    /* reduce the height since the top/bottom padding has been removed */
    minHeight="auto"
    UNSAFE_style={{
      padding: 0,
    }}
    {...spectrumProps}
  >
    {children}
  </SpectrumStatusLight>
);

StatusLight.propTypes = {
  /**
   * If true, removes the margin to the left of the StatusLight icon so that if in a column the StatusLight will align
   * with its visible left edge lined up with the left edge of its header. Default is false.
   */
  alignStart: PropTypes.bool,
  /**
   * The content to display as the label.
   * If there is not a visible label then an 'aria-label' or 'aria-labelledby' prop is required.
   */ children: PropTypes.node,
  /**
   * An object of Spectrum StatusLight props which will be passed thru to the component.
   * 'variant' is required.
   * This could include 'isDisabled' or any of aria properties or any of the Spectrum styling properties that
   * are valid for this component.
   */
  // eslint-disable-next-line react/forbid-prop-types -- pass-thru properties to Spectrum's StatusLight
  spectrumProps: PropTypes.any,
};

export default StatusLight;
