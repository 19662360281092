// eslint-disable-next-line angular/window-service
const OFFER_CONSTANTS = window.binkySrc2.models.offers.OFFER_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/models/offers/OfferConstants.js
   */
  angular
    .module('binky.core.offers')
    .constant('CLOUD', OFFER_CONSTANTS.CLOUD)
    .constant('COMMITMENT', OFFER_CONSTANTS.COMMITMENT)
    .constant('CUSTOMER_SEGMENT', OFFER_CONSTANTS.CUSTOMER_SEGMENT)
    .constant('DELEGATION_TYPE', OFFER_CONSTANTS.DELEGATION_TYPE)
    .constant('PAYMENT_STRUCTURE', OFFER_CONSTANTS.PAYMENT_STRUCTURE)
    .constant('OFFER_TYPE', OFFER_CONSTANTS.TYPE)
    .constant('PRICE_POINT', OFFER_CONSTANTS.PRICE_POINT)
    .constant('PROCESSING_INSTRUCTION', OFFER_CONSTANTS.PROCESSING_INSTRUCTION)
    .constant('QUALIFIER_TARGET', OFFER_CONSTANTS.QUALIFIER_TARGET)
    .constant('SALES_CHANNEL', OFFER_CONSTANTS.SALES_CHANNEL);
})();
