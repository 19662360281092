(function () {
  /**
   * @ngdoc module
   * @name binky.core.globalAdmin
   * @description Module which defines Global Admin related models
   */
  angular.module('binky.core.globalAdmin', [
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.lodash',
    'binky.core.promise-utils',
  ]);
})();
