(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.search.within:binkySearchWithin
   *
   * @description A widget for searching within a specific category
   *
   * @param {Function} [onSearchChange] Called when the input for the search changes
   * @param {Function} [onSearchSubmit] Called when the search is submitted (user hits enter).
   * @param {Function} [onSelectChange] Called when the select changes.
   * @param {Number} [searchMaxLength] The maximum search string length allowed (set on input field to restrict input length)
   * @param {Number} [searchMinLength] The minimum search string length allowed for validation before submit
   * @param {Array} selectItems The set of items to repeat over inside the select list
   * @param {String} selectNameKey The field key to use as the name on the select object
   * @param {String} selectSelectedOption The value to choose on initial load of the select. This is updated as selection changes
   * @param {String} selectValueKey The field key to use as the value on the select object
   */
  angular.module('binky.widgets.common.search.within').component('binkySearchWithin', {
    bindings: {
      onSearchChange: '&?',
      onSearchSubmit: '&?',
      onSelectChange: '&?',
      searchMaxLength: '<?',
      searchMinLength: '<?',
      selectItems: '<',
      selectNameKey: '@',
      selectSelectedOption: '=',
      selectValueKey: '@',
    },
    controller,
    templateUrl: 'widgets/common/search/within/search-within.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      handleSearchChange,
      handleSearchSubmit,
    });

    function handleSearchChange(value) {
      vm.onSearchChange({value});
    }

    function handleSearchSubmit(value) {
      vm.onSearchSubmit({value});
    }
  }
})();
