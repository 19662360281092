import cloneDeep from 'lodash/cloneDeep';

import MemberType from '../../member/type/MemberType';
import UserRoles from '../roles/UserRoles';

let singleton;

/**
 * @name AuthenticatedUser
 * @description Model for the AuthenticatedUser currently logged into this UI surface.
 */
export default class AuthenticatedUser {
  /**
   * @description Method for getting the AuthenticatedUser if there is one
   * @returns {AuthenticatedUser} the AuthenticatedUser fetched from cache, or null
   */
  static get() {
    return singleton;
  }

  /**
   * @description initializes AuthenticatedUser object.
   * @param {Object} profile The IMS profile to wrap in this user.
   */
  constructor(profile = {}) {
    this.profile = profile;
    const roles = this.profile.roles || [];
    this.roles = new UserRoles(cloneDeep(roles));
    // upon construction, we store this as the active AuthenticatedUser
    singleton = this;
  }

  /**
   * @description Get the authId.
   * @returns {String} the user's authenticating account ID.
   */
  getAuthenticatingAccountId() {
    return this.profile.authId;
  }

  /**
   * @description Get the user two character country code.
   * @returns {String} the user's country code.
   */
  getCountryCode() {
    return this.profile.countryCode;
  }

  /**
   * @description Get the user display name. This will be correctly ordered
   *    for their country code.
   * @returns {String} the user's display name.
   */
  getDisplayName() {
    return this.profile.displayName;
  }

  /**
   * @description Get the user email address.
   * @returns {String} the user's email.
   */
  getEmail() {
    return this.profile.email;
  }

  /**
   * @description Get the local part of the user email address.
   * @returns {String} the local part of the user's email.
   */
  getEmailLocalPart() {
    return this.getEmail().split('@')[0];
  }

  /**
   * @description Get the user email verification state.
   * @returns {Boolean} true if the email is verified
   */
  getEmailVerified() {
    const emailVerified = this.profile.emailVerified || '';
    return emailVerified.toLowerCase() === 'true';
  }

  /**
   * @description Get the user's first name.
   * @returns {String} the user's first name.
   */
  getFirstName() {
    return this.profile.first_name;
  }

  /**
   * @description Get the userId.
   * @returns {String} the user's unique ID.
   */
  getId() {
    return this.profile.userId;
  }

  /**
   * @description Get the user's last name.
   * @returns {String} the user's last name.
   */
  getLastName() {
    return this.profile.last_name;
  }

  /**
   * @description Get all roles for the user.
   * @returns {UserRoles} a UserRoles object wrapping all roles the user has.
   */
  getRoles() {
    return this.roles;
  }

  /**
   * @description Get the user account type.
   * @returns {MemberType} the user's account type, 'type1', 'type2', 'type2e', or 'type3'.
   */
  getType() {
    const rawType = this.profile.account_type || '';
    return new MemberType(rawType.toUpperCase(), this.getId());
  }
}
