import Consumable from './Consumable';

class ConsumableList {
  /**
   * @description Creates a new ConsumableList instance.
   *
   * @param {Consumable[]} items - Consumable entities which make up this list
   * @param {ConsumableSummary} parentSummary - the parent summary for this list
   */
  constructor(items, parentSummary) {
    this.items = items.map((item) => Consumable.apiResponseTransformer(item, parentSummary));
  }

  /**
   * @description Find any Consumable objects that are for the specified
   *     fulfillable item code
   *
   * @param {string} fiCode - the fulfillable item code to find summaries for
   *
   * @returns {Consumable[]} List of Consumables that are for the fulfillable
   *     item code
   */
  getConsumablesForFulfillableItemCode(fiCode) {
    return this.items.filter((item) => item.fulfillableItemCode === fiCode);
  }
}

export default ConsumableList;
