import binky from '@admin-tribe/binky';
import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const UserGroup = binky.services.users.UserGroup;

const targetOrgTooltip = (intl, orgName) => {
  if (orgName) {
    return intl.formatMessage(
      {id: 'binky.common.userGroupShareInfo.shared.target.tooltip'},
      {orgName}
    );
  }
  return intl.formatMessage({id: 'binky.common.userGroupShareInfo.shared.target.tooltip.noOrg'});
};

/**
 * A contextual tooltip for a usergroup. The tooltip needs to be triggered by a quiet, focusable ActionButton for
 * accessibility. The tooltip explains the source or target of the user group if it was shared.
 *
 * @param member The UserGroup member to display
 * @param placement The tooltip location relative to the button. Default is 'top start'
 * @param size The icon size, default is 'S'
 * @param props any other props passed into the "ActionButton" component, including `size`
 * @constructor
 */
const UserGroupShareInfo = ({member, placement = 'top start', size = 'S', ...props}) => {
  const intl = useIntl();

  if (member.getType().isUserGroup() && member.isShared() && member instanceof UserGroup) {
    const userGroup = member;
    const tooltip = userGroup.isTarget
      ? targetOrgTooltip(intl, userGroup.sharedUserGroupSource?.sourceOrg?.name)
      : intl.formatMessage({id: 'binky.common.userGroupShareInfo.shared.source.tooltip'});
    return (
      <TooltipTrigger data-testid="usergroup-tooltip-trigger" delay={0} placement={placement}>
        <ActionButton
          aria-label={intl.formatMessage({id: 'binky.common.userGroupShareInfo.shared.label'})}
          data-testid="shared-usergroup-button"
          isQuiet
          {...props}
        >
          <InfoOutline size={size} />
        </ActionButton>
        <Tooltip>{tooltip}</Tooltip>
      </TooltipTrigger>
    );
  }
  return null;
};

UserGroupShareInfo.displayName = 'UserGroupShareInfo';
UserGroupShareInfo.propTypes = {
  /**
   * The UserGroup member to display.
   */
  member: PropTypes.instanceOf(UserGroup).isRequired,
  /**
   * Controls the placement of the tooltip relative to the button. Default is 'top start'.
   */
  placement: PropTypes.string,
  /**
   * The size of the info icon. Default is 'S'.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']),
};

export default UserGroupShareInfo;
