import chunk from 'lodash/chunk';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import omitBy from 'lodash/omitBy';

import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilDomains from 'api/jil/jilDomains';
import {DOMAIN_STATUS, Domain, DomainListMultiStatusResponse} from 'models/domain';
import {DIRECTORY_EVENT} from 'services/directory/directoryConstants';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import {DOMAIN_LIST_CACHE_ID, DOMAIN_LIST_EVENT} from './domainListConstants';

class DomainList extends JilModelList {
  /**
   * @description instantiate the list of Domains for an Organization.
   * @param {Object} options - options object
   * @param {String} [options.orgId] - associates DomainList instance with an org.
   * @param {Number} [options.pageSize] - page size
   * @param {DOMAIN_STATUS} [options.status] - status
   */
  constructor(options) {
    super({
      cacheClearingEvents: [DIRECTORY_EVENT.DOMAIN_SYNCED],
      isCacheable: true,
      itemClassRef: Domain,
      itemId: 'domainName',
      modelCacheId: DOMAIN_LIST_CACHE_ID,
      pageSize: options.pageSize,
      resource: jilDomains.getDomains,
      sortExpression: JIL_CONSTANTS.SORT.NAME,
      sortOrder: JIL_CONSTANTS.ORDER.ASC,
    });

    Object.assign(this, {
      orgId: options.orgId,
      status: options.status,
    });
  }

  /**
   * @description Returns true if this list contains a Domain with the specified domainName.
   *
   *   WARNING: This model calls a paginated API. This method will only return true if the
   *   specified domain was contained within one of the fetched pages.
   *
   * @param {String} domainName the domain name to look for
   * @returns {Boolean} true if the list contains the specified domain name; else false.
   */
  containsDomainName(domainName) {
    return this.items.some((domain) => domain.domainName === domainName);
  }

  /**
   * @description overidden key method to be used when we fetch models from modelCache
   *
   * @returns {String} key formed from params.status (if present) and the orgId
   */
  key() {
    return super.getKey(getParams(this));
  }

  /**
   * @description link a set of domains to a selected directory
   * @param {Object} options options to be provided for the batchOperation
   * @param {Array} options.domains array of the selected domains to link
   * @param {Directory} options.directoryId id of the directory to link to
   * @returns {Promise} resolves to the difference.
   */
  async linkDomainsToDirectory({directoryId, domains}) {
    const patchPayload = buildPatchPayloadLinkDomainsToDirectory(directoryId, domains);

    let response;
    try {
      response = await jilDomains.patchDomains(
        {
          orgId: this.orgId,
        },
        patchPayload
      );
    } catch (error) {
      log.error('DomainList failed to link domain(s) to directory. Error:', error);
      throw error;
    }

    const linkedDomains = response.data;
    const difference = determinePatchResponseDifference(patchPayload, linkedDomains, directoryId);

    await this.refresh();
    return {difference};
  }

  /**
   * @description Returns a preview of what would happen if save were to be called.
   *
   * @returns {Promise<DomainListMultiStatusResponse>} resolves with an instance of DomainListMultiStatusResponse representing
   *   what would happen for each saved item.
   */
  async previewSave() {
    if (this.addedItems.length > 0) {
      const request = buildAddPayload(this.addedItems);
      try {
        const response = await jilDomains.postDomainsPreview({orgId: this.orgId}, request);
        return new DomainListMultiStatusResponse(response.data);
      } catch (error) {
        log.error('DomainList failed to preview domains. Error:', error);
        throw error;
      }
    }
    return new DomainListMultiStatusResponse([]);
  }

  /**
   * @description Method to refresh the contents of the list. If options not specified, this will use model's status.
   *
   * @param {Object} [options] the refresh options. Default is to use the model's status which if undefined is
   *   all statuses.
   * @param {String} [options.status] to filter by status specify one of DOMAIN_STATUS. This will become
   *   the model's new status value. If not specified the modal's current status value will be used.
   * @returns {Promise} promise - resolved when the list is refreshed
   */
  async refresh(options = {}) {
    Object.assign(this, {
      status: options.status,
    });

    try {
      await super.refresh(getParams(this));
    } catch (error) {
      log.error('DomainList failed to refresh. Error:', error);
      throw error;
    }

    eventBus.emit(DOMAIN_LIST_EVENT.UPDATE, this);
    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(DOMAIN_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
    }

    return this;
  }

  /**
   * @description Saves changes to the back-end.
   *   As currently coded it will save added items if there are any OR save removed items if there are any.
   *   It does not do both. If we need both operations together, we can add that complexity later.
   *
   * @returns {Promise} resolves with the modified domains if changes successfully saved, else
   *   rejects with error message
   */
  async save() {
    if (this.hasUnsavedChanges()) {
      if (this.addedItems.length > 0) {
        const payload = buildAddPayload(this.addedItems);

        let response;
        try {
          response = await jilDomains.postDomains({orgId: this.orgId}, payload);
        } catch (error) {
          log.error('DomainList.save() failed to add domain(s). Error:', error);
          throw error;
        }

        const addedDomains = response.data;
        this.addedItems = differenceBy(this.addedItems, addedDomains, 'domainName');

        await this.refresh();
        return addedDomains;
      }
      // else: there are removedItems to be removed
      const payload = buildRemovePatchPayload(this.removedItems);

      let response;
      try {
        response = await jilDomains.patchDomains(
          {
            orgId: this.orgId,
          },
          payload
        );
      } catch (error) {
        log.error('DomainList.save() failed to remove domain(s). Error:', error);
        throw error;
      }

      const removedDomains = response.data;
      this.removedItems = differenceBy(this.removedItems, removedDomains, 'domainName');

      await this.refresh();
      return removedDomains;
    }

    return [];
  }

  /**
   * @description Method to determine whether we need to update the total itemCount of a list.
   *  Do not update count if refresh was due to either a search or a status filter.
   *
   * @return {Boolean} true if the total itemCount needs to be updated
   */
  shouldUpdateTotalItemCount() {
    return super.shouldUpdateTotalItemCount() && this.status === undefined;
  }

  /**
   * @description Method to determine if there are any domains that need a directory (VALIDATED status)
   * @returns {Boolean} true if there's a domain with that status, otherwise false
   */
  someDomainsNeedDirectory() {
    return this.items.some((domain) => domain.needsDirectory());
  }

  /**
   * @description Method to determine if there are any domains that need validation
   * @returns {Boolean} true if there's a domain with that status, otherwise false
   */
  someDomainsNeedValidation() {
    return this.items.some((domain) => domain.needsValidation());
  }

  /**
   * @description Method to validate domains. Assumes domainList is already filtered.
   * @param {Array<Domain>} domains - list of Domain which are domains to be validated.
   * @returns {Promise} promise object resolved when validation is complete, or rejected with an error.
   *   The updatedItems contains items that were validated which may be less than the domains count if there
   *   were validation errors.
   */
  async validateDomains(domains) {
    const operations = buildPatchPayloadValidateDomains(domains);

    // Until API performance is improved, only 20 domains at a time can be patched.
    // This emulates the previous behavior from src1 except
    // 1) we do not wait on each request before sending the next.
    // 2) we do not send notifications after each individual request succeeds
    const CHUNK_SIZE = 20;
    const operationChunks = chunk(operations, CHUNK_SIZE);
    const promises = operationChunks.map((operationChunk) =>
      jilDomains.patchDomains(
        {
          orgId: this.orgId,
        },
        operationChunk
      )
    );

    let axiosResponses;
    try {
      axiosResponses = await Promise.all(promises);
    } catch (error) {
      log.error('DomainList failed to validate domain(s). Error:', error);
      throw error;
    }

    let validatedDomains = [];
    axiosResponses.forEach((response) => {
      validatedDomains = [...validatedDomains, ...response.data];
    });

    await this.refresh();

    return validatedDomains;
  }
}

function determinePatchResponseDifference(patchPayload, responseContent, directoryId) {
  return differenceWith(patchPayload, responseContent, operationsEqual);

  // Added a separate comparator because the response and request
  // content do not look exactly the same.....but we can compare certain parts
  // of both to determine matches, namely: status, domainName, and directoryId
  function operationsEqual(patchPayloadItem, responseContentItem) {
    const parts = patchPayloadItem.path.split('/');
    const [, domainName] = parts;
    const status = 'ACTIVE';

    return (
      domainName === responseContentItem.domainName &&
      directoryId === responseContentItem.directoryId &&
      status === responseContentItem.status
    );
  }
}

function getParams(list) {
  return omitBy({orgId: list.orgId, status: list.status}, (prop) => prop === undefined);
}

/**
 *
 * @param {Array<Domain>} addedItems - the Domains to be added
 * @returns {Array<Object>} - Array of json object with domainName field
 */
function buildAddPayload(addedItems) {
  return addedItems.map((domain) => ({
    domainName: domain.domainName,
  }));
}

/**
 *
 * @param {Array<Domain>} domains - The domains to be patched
 *
 * @returns {Array<Object>} - Array of patch operation objects for JIL
 */
function buildRemovePatchPayload(domains) {
  return domains.map((domain) => ({
    op: 'remove',
    path: `/${domain.domainName}/status`,
    value: DOMAIN_STATUS.WITHDRAWN,
  }));
}

/**
 *
 * @param {string} directoryId - The directory id
 * @param {Array<Domain>} domains - The domains to be patched
 *
 * @returns {Array<Object>} - Array of patch operation objects for JIL
 */
function buildPatchPayloadLinkDomainsToDirectory(directoryId, domains) {
  return domains.map((domain) => ({
    op: 'replace',
    path: `/${domain.domainName}/status/ACTIVE/directoryId/${directoryId}`,
  }));
}

/**
 *
 * @param {Array<Domain>} domains - The domains to be patched
 * @returns {Array<Object}> - Array of patch operation objects for JIL
 */
function buildPatchPayloadValidateDomains(domains) {
  return domains.map((domain) => ({
    op: 'replace',
    path: `/${domain.domainName}/status`,
    value: DOMAIN_STATUS.VALIDATED,
  }));
}

export default DomainList;
