import {Locale} from '@admin-tribe/binky';
import {createIntl, createIntlCache} from 'react-intl';

const strings = {};

// We create a cache for the translateString usage to prevent
// a potential memory leak issue in react-intl
let cache;

/**
 * @description Clear the IntlCache
 */
function clearCache() {
  cache = null;
}

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
function getStrings(language) {
  return {...strings[language]};
}
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
function putStrings(language, newStrings) {
  strings[language] = {...strings[language], ...newStrings};
  return strings[language];
}
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
function resetStrings(language) {
  delete strings[language];
}

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
function translateString(descriptor, values) {
  const language = Locale.get().activeLanguage;
  // We create a cache for the translateString usage to prevent
  // a potential memory leak issue in react-intl
  if (!cache) {
    cache = createIntlCache();
  }
  const intl = createIntl(
    {
      locale: language,
      messages: getStrings(language),
    },
    cache
  );
  return intl.formatMessage(descriptor, values);
}

/**
 * @description Used to fetch localized strings for a given locale.
 *
 * @param {String} locale - the locale to fetch the translation for
 * @param {Object} descriptor - object with id field for translation namespace
 * @param {Object} values - object of params to pass into the translation
 * @returns
 */
function translateStringForLocale(locale, descriptor, values) {
  // We create a cache for the translateString usage to prevent
  // a potential memory leak issue in react-intl
  if (!cache) {
    cache = createIntlCache();
  }
  const intl = createIntl(
    {
      locale,
      messages: getStrings(locale),
    },
    cache
  );
  return intl.formatMessage(descriptor, values);
}

/**
 * Helper function to get a dynamic import for a date-fns locale module
 * Webpack dynamic imports are actually returning a promise regardless if we use
 * code splitting, and the module is loaded async or it is already part of the bundle
 * Please use it in useEffect as such
 *   (async () => {
        const newDateFnsLocale = await getDateFnsLocaleModule(Locale.get().dateFnsLocaleString);
        setDateFnsLocale(newDateFnsLocale);
      })();
 * @param {String} localeString - The date-fns locale string
 * @returns {Object} The date-fns locale module
 */
const getDateFnsLocaleModule = async (localeString) => {
  let locale;
  try {
    locale = await import(
      /* webpackChunkName: "dateFnsLocale" */ `date-fns/locale/${localeString}/index.js`
    );
    return locale;
  } catch (error) {
    throw new Error(`Could not load the ${localeString} date-fns module: ${error}`);
  }
};

export {
  clearCache,
  getDateFnsLocaleModule,
  getStrings,
  putStrings,
  resetStrings,
  translateString,
  translateStringForLocale,
};
