import ServerErrorIllustration from '@spectrum-icons/illustrations/Error';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import IllustratedMessage from 'common/components/illustrated-message/IllustratedMessage';

/**
 * @deprecated use Pandora adminstration ServerErrorIllustratedMessage:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/empty-table-message/server-error
 *
 * Component to render a message with internal server error illustration, a header
 * and content.
 */
const ServerErrorIllustratedMessage = ({content, heading, headingLevel}) => {
  const intl = useIntl();
  const contentMsg =
    content || intl.formatMessage({id: 'binky.common.illustratedMessage.serverError.content'});
  const headingMsg =
    heading || intl.formatMessage({id: 'binky.common.illustratedMessage.serverError.heading'});

  return (
    <IllustratedMessage
      content={contentMsg}
      heading={headingMsg}
      headingLevel={headingLevel}
      illustration={<ServerErrorIllustration />}
    />
  );
};

ServerErrorIllustratedMessage.propTypes = {
  /**
   * Optional content. The default is "Something went wrong...".
   */
  content: PropTypes.string,
  /**
   * The heading. The default is "We are sorry".
   */
  heading: PropTypes.string,
  /**
   * The header level of the message. The default is 3.
   */
  headingLevel: PropTypes.number,
};

export default ServerErrorIllustratedMessage;
