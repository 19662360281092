import {ContactList, hasValidContacts, isContactListEqual, log} from '@admin-tribe/binky';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {ModalContent, ModalDescription, ModalDialog, ModalHeading} from 'shell/panels/modal-dialog';

import GoUrl from '../../go-url/GoUrl';

import SecurityContactsForm from './security-contacts-form/SecurityContactsForm';

/**
 * The modal used to update the security contacts.
 */
const EditSecurityContactsModal = observer(({contactList, onSuccess, onClose}) => {
  const [modalError, setModalError] = useState(null);
  const [isSavingContactList, setIsSavingContactList] = useState(false);
  const [currentContacts, setCurrentContacts] = useState(new ContactList({...contactList}));
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const ctaToastGenerator = () =>
    intl.formatMessage({id: 'binky.common.editSecurityContactsModal.toast.success'});

  const isCtaDisabled = () =>
    !currentContacts ||
    currentContacts.isAllContactsEmpty() ||
    isContactListEqual(currentContacts, contactList) ||
    !hasValidContacts(currentContacts);

  const getHeading = () => {
    const action = contactList.isAllContactsEmpty() ? 'add' : 'edit';
    return intl.formatMessage({id: `binky.common.editSecurityContactsModal.header.${action}`});
  };

  const onCancel = () => {
    setModalError(null);
    onClose?.();
  };

  const onCta = async () => {
    let isSuccess = false;
    setIsSavingContactList(true);

    try {
      await contactList.save(currentContacts);
      onSuccess?.(contactList);
      isSuccess = true;
      onClose?.();
    } catch (error) {
      log.error('Error saving contacts: ', error);
      if (isCurrentRef.current) {
        setModalError(intl.formatMessage({id: 'binky.shell.toasts.defaults.error'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingContactList(false);
      }
    }
    // Return to trigger success toast
    return isSuccess;
  };

  return (
    <ModalDialog
      ctaLabel={intl.formatMessage({id: 'binky.shell.panels.modal.save'})}
      ctaToastGenerator={ctaToastGenerator}
      errorMessage={modalError}
      id="edit-contacts-modal"
      isCtaDisabled={isCtaDisabled()}
      isLoading={isSavingContactList}
      onCancel={onCancel}
      onCta={onCta}
    >
      <ModalHeading>{getHeading()}</ModalHeading>
      <ModalDescription data-testid="description">
        {intl.formatMessage(
          {id: 'binky.common.editSecurityContactsModal.description'},
          {
            goUrl: (str) => <GoUrl name="ac_security_contacts">{str}</GoUrl>,
          }
        )}
      </ModalDescription>
      <ModalContent>
        <SecurityContactsForm
          contactList={currentContacts}
          isDisabled={isSavingContactList}
          onChange={setCurrentContacts}
        />
      </ModalContent>
    </ModalDialog>
  );
});

EditSecurityContactsModal.displayName = 'EditSecurityContactsModal';
EditSecurityContactsModal.propTypes = {
  /**
   * The ContactList whose contacts are being updated.
   */
  contactList: PropTypes.instanceOf(ContactList).isRequired,
  /**
   * Callback to invoke when the cancel or cta button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * Callback to invoke when the modal's actions are successful.
   * Includes the current contact list as a parameter.
   */
  onSuccess: PropTypes.func,
};

export default EditSecurityContactsModal;
