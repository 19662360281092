// Store observability initialization state.
// The initial state data may be set before observabilityMetrics can be loaded, and
// avoid circular dependencies.
// For example, authentication needs to store the elapsed time. observabilityMetrics
// depends on feature flag. Feature flag depends on authentication. And,
// authentication needs to set observability initial state.

let authenticationReadyElapsedTime;

const getAuthenticationReadyElapsedTime = () => authenticationReadyElapsedTime;

const setAuthenticationReadyElapsedTime = (elapsedTime) => {
  authenticationReadyElapsedTime = elapsedTime;
};

const initialState = {
  getAuthenticationReadyElapsedTime,
  setAuthenticationReadyElapsedTime,
};

export default initialState;
