import React from 'react';

/**
 * Only fetches product roles and displays the product role picker
 * when both ProductRolePickerSection and ProductRoleContext are defined.
 *
 * @param {Function} onError - callback function for errors
 * @example <ProductRoleContext.Provider value={{onError}}> <ProductRolePickerSection/> </ProductRoleContext.Provider>
 */
const ProductRoleContext = React.createContext();

/**
 * The License Group context for fetching and displaying the product roles
 *
 * @param {String} licenseGroupId - the license group id
 * @param {String} orgId - org id
 * @param {Product} product - product
 * @example <ProductRoleLicenseGroupContext.Provider value={{licenseGroupId, orgId, product}}> <ProductRolePickerSection/> </ProductRoleLicenseGroupContext.Provider>
 */
const ProductRoleLicenseGroupContext = React.createContext({});

/**
 * The Member context for fetching and displaying the product roles
 *
 * @param {Member} member - the member context.
 * @example <ProductRoleMemberContext.Provider value={member}> <ProductRolePickerSection/> </ProductRoleMemberContext.Provider>
 */
const ProductRoleMemberContext = React.createContext();

const useProductRoleContext = () => React.useContext(ProductRoleContext);
const useProductRoleLicenseGroupContext = () => React.useContext(ProductRoleLicenseGroupContext);
const useProductRoleMemberContext = () => React.useContext(ProductRoleMemberContext);

export {
  ProductRoleContext,
  ProductRoleLicenseGroupContext,
  ProductRoleMemberContext,
  useProductRoleContext,
  useProductRoleLicenseGroupContext,
  useProductRoleMemberContext,
};
