import binky from '@admin-tribe/binky';
import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- Intentionally use v2 Button to pair with v2 OverlayTrigger and v2 Menu
import Button from '@react/react-spectrum/Button';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import AddIcon from '@react/react-spectrum/Icon/Add';
import EditIcon from '@react/react-spectrum/Icon/Edit';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v3 Menu does not support the Menu/Submenu for product/profile picker
import {Menu} from '@react/react-spectrum/Menu';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 TabView should be replaced with v3 Tabs
import {Tab, TabView} from '@react/react-spectrum/TabView';
import {FocusScope} from '@react-aria/focus';
import classNames from 'classnames';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {RawIntlProvider, useIntl} from 'react-intl';

import styles from './AssignmentMenu.pcss';
import ASSIGNMENT_MENU_CONSTANTS from './AssignmentMenuConstants';
import {AssignmentMenuContext, useAssignmentMenuContext} from './AssignmentMenuContext';
import {LicenseLimitContext, useLicenseLimitContext} from './assignmentMenuUtils';
import ContractListMenu from './contract-list-menu/ContractListMenu';
import ProductMenu from './product-menu/ProductMenu';
import ProductProfileMenu from './product-profile-menu/ProductProfileMenu';
import UserGroupMenu from './user-group-menu/UserGroupMenu';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-assignment-menu
 */

const Product = binky.services.product.Product;

const {IDS, MENU_TABS, TARGET_TYPE, TAB_GROUPS} = ASSIGNMENT_MENU_CONSTANTS;
const {MENU_PREFIX, MENUITEM_PREFIX} = IDS;
const {CONTRACTS_TAB, PRODUCTS_TAB, USER_GROUPS_TAB} = MENU_TABS;

const MENU_TYPE = {
  // A menu without tabs will have only one tab group and not render any Tabs
  NO_TABS: 'NO_TABS',
  // A menu with tabs will have more than one tab group and will show each tab group in a different Tab
  TABS: 'TABS',
};

// Custom Menu to avoid re-rendering when AssignmentMenu is refreshed.
// This fixes the issue that SubMenu is closed on select.
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- khnguye@ to update
const CustomMenu = ({
  buttonId,
  className,
  intl,
  menuId,
  licenseLimitContext,
  menuContext,
  menuStyle,
  menuTabs,
  menuType,
  targets,
  onSelection,
  orgId,
  ...props
}) => {
  const style = {maxHeight: get(props, 'style.maxHeight')};

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
  /* istanbul ignore next -- TODO: test in e2e/integration test */
  const onFocus = () => {
    // We need to wrap this in a setTimeout so it is executed after React Spectrum's focus management
    setTimeout(() => {
      const firstMenuItem = document.querySelector(
        `[id^="${MENU_PREFIX}"] li[id^="${MENUITEM_PREFIX}"]:not([aria-disabled="true"])`
      );
      if (firstMenuItem) {
        // Override the tabIndex behavior set by React Spectrum v2's FocusManager
        firstMenuItem.tabIndex = '0';
      }
    });
  };

  /* eslint-disable @admin-tribe/admin-tribe/prefer-composition -- khnguye@ to update */
  // eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- khnguye@ to update
  const renderAssignmentMenu = () => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- new tab addition
    const renderMenu = (tab) => {
      if (tab === PRODUCTS_TAB) {
        if (
          targets.includes(TARGET_TYPE.PRODUCTS) ||
          targets.includes(TARGET_TYPE.PRODUCT_SUPPORTS)
        ) {
          return (
            <ProductMenu
              enableProfileSelection={targets.includes(TARGET_TYPE.PRODUCT_PROFILES)}
              onSelection={onSelection}
              orgId={orgId}
            />
          );
        }
        const selectableProducts = menuContext.selectableItems.filter(
          (item) => item instanceof Product
        );
        const productForProfileSelection = selectableProducts.find(
          (product) => product.id === menuContext.productIdForProfileSelection
        );
        return (
          <ProductProfileMenu
            onSelection={onSelection}
            orgId={orgId}
            product={productForProfileSelection}
          />
        );
      }
      if (tab === CONTRACTS_TAB) {
        return <ContractListMenu onSelection={onSelection} orgId={orgId} />;
      }
      return <UserGroupMenu onSelection={onSelection} orgId={orgId} />;
    };

    if (menuType === MENU_TYPE.NO_TABS) {
      return renderMenu(menuTabs[0]);
    }

    return (
      <TabView autoFocus quiet>
        {menuTabs.map((tab) => (
          <Tab
            key={tab}
            data-testid={`assignment-menu-tab-${tab}`}
            label={intl.formatMessage({
              id: `binky.common.assignmentMenu.tabs.${tab}.label`,
            })}
            onFocus={onFocus}
          >
            {renderMenu(tab)}
          </Tab>
        ))}
      </TabView>
    );
  };
  /* eslint-enable @admin-tribe/admin-tribe/prefer-composition -- khnguye@ to update */

  return (
    // Nest V2-V3-V2 Providers to have the Popover rendered correctly.
    // CSS failed to load if V3Provider is placed before V2Provider
    <SpectrumV2Provider scale="medium" theme="light" {...props}>
      <SpectrumV3Provider colorScheme="light" theme={defaultTheme}>
        {/* This allows the SubMenu to be rendered under V3Provider so the V3Checkboxes can load styles from SpectrumV3.*/}
        <SpectrumV2Provider scale="medium" theme="light">
          <Menu
            aria-labelledby={buttonId}
            className={classNames(className, styles.menu, menuStyle)}
            data-testid="assignment-menu"
            id={menuId}
            style={style}
            // typeToSelect set to false to prevent Menu from preventing Keyboard input on input fields embedded into the menu
            typeToSelect={false}
          >
            {/* Temporarily fix the focus issue until the spectrum team releases the fix. More info: https://github.com/adobe/react-spectrum/issues/1353 */}
            <FocusScope autoFocus contain>
              {/*
                Portaled Menu exists elsewhere in the DOM tree, preventing Contexts from finding their ancestor Provider.
                Thus we forward Context providers to portaled Popover children.
              */}
              <RawIntlProvider value={intl}>
                <AssignmentMenuContext.Provider value={menuContext}>
                  <LicenseLimitContext.Provider value={licenseLimitContext}>
                    {renderAssignmentMenu()}
                  </LicenseLimitContext.Provider>
                </AssignmentMenuContext.Provider>
              </RawIntlProvider>
            </FocusScope>
          </Menu>
        </SpectrumV2Provider>
      </SpectrumV3Provider>
    </SpectrumV2Provider>
  );
};

CustomMenu.propTypes = {
  buttonId: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- Allow passing Intl to V2Popover
  intl: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- Forwarding LicenseLimitContext from AssignmentMenu
  licenseLimitContext: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types -- Loading menuContext from AssignmentMenu
  menuContext: PropTypes.any,
  menuId: PropTypes.string,
  menuStyle: PropTypes.string,
  menuTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(MENU_TABS))),
  menuType: PropTypes.oneOf(Object.values(MENU_TYPE)),
  // onSelection prop will be called with params: item, isItemSelected
  onSelection: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  showButtonLabel: PropTypes.bool,
  targets: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TARGET_TYPE))).isRequired,
};

// A menu used to select items for assignment.
//    To display multiple menus, provide multiple target types of different tab groups.
//    To display products with their profiles, include both product and profile target types.
//        Products must have field licenseGroupSummaries so profiles can be displayed.
//    To display just products, do not provide the profile target type.
const AssignmentMenu = ({
  className,
  onSelection,
  orgId,
  disabled = false,
  isQuiet = false,
  showButtonLabel,
  targets,
}) => {
  const intl = useIntl();
  const menuContext = useAssignmentMenuContext();
  const licenseLimitContext = useLicenseLimitContext();

  const buttonId = useRef(uniqueId('assignment-menu-button-')).current;
  const menuId = useRef(uniqueId(MENU_PREFIX)).current;
  const buttonLabelLocKey = useRef('products'); // default to products, will be set correctly later

  const [menuType, setMenuType] = useState();
  const [menuStyle, setMenuStyle] = useState();
  const [menuTabs, setMenuTabs] = useState([]);

  React.useEffect(() => {
    const tabGroups = targets.reduce((tabGroupSet, target) => {
      let tabGroup;
      if (TAB_GROUPS.PRODUCTS.includes(target)) {
        tabGroup = PRODUCTS_TAB;
      } else if (TAB_GROUPS.CONTRACTS.includes(target)) {
        tabGroup = CONTRACTS_TAB;
      } else {
        tabGroup = USER_GROUPS_TAB;
      }

      if (!tabGroupSet.includes(tabGroup)) {
        tabGroupSet.push(tabGroup);
      }
      return tabGroupSet;
    }, []);

    // Products tab should go before User Groups tab
    tabGroups.sort();

    setMenuTabs(tabGroups);

    if (tabGroups.length === 1) {
      setMenuType(MENU_TYPE.NO_TABS);
      setMenuStyle(styles['assignment-menu']);
      if (targets.includes(TARGET_TYPE.PRODUCT_PROFILES)) {
        buttonLabelLocKey.current = 'productProfiles';
      }
      if (
        targets.includes(TARGET_TYPE.PRODUCTS) ||
        targets.includes(TARGET_TYPE.PRODUCT_SUPPORTS)
      ) {
        // If menu has both profiles and product targets, use 'products' loc key
        buttonLabelLocKey.current = 'products';
      }
      if (targets.includes(TARGET_TYPE.USER_GROUPS)) {
        buttonLabelLocKey.current = 'userGroups';
      }
      if (targets.includes(TARGET_TYPE.CONTRACTS)) {
        buttonLabelLocKey.current = 'contracts';
      }
    } else {
      // There is more than one tab group, configure menu with tabs
      setMenuType(MENU_TYPE.TABS);
      setMenuStyle(styles['assignment-menu-tabs']);

      // we can assume that the menu will have both the product and user groups menu
      buttonLabelLocKey.current = 'productsOrUserGroups';
    }
  }, [targets]);

  return (
    <>
      {showButtonLabel && (
        // TODO: Use different label text for button instead of the aria-label
        <FieldLabel
          label={intl.formatMessage({
            id: `binky.common.assignmentMenu.button.label.${buttonLabelLocKey.current}`,
          })}
          labelFor={buttonId}
        />
      )}
      <OverlayTrigger
        container={Array.prototype.slice
          .call(document.querySelectorAll('.portal-container-in-document-body'))
          .pop()}
        placement="bottom left"
        trigger="click"
      >
        {/* TODO: Give button tooltip */}
        <Button
          aria-haspopup="dialog"
          aria-label={intl.formatMessage({
            id: `binky.common.assignmentMenu.button.label.${buttonLabelLocKey.current}`,
          })}
          data-testid="assignment-menu-button"
          disabled={disabled}
          icon={menuContext.selectedItems.length === 0 ? <AddIcon /> : <EditIcon />}
          id={buttonId}
          quiet={isQuiet}
          variant="action"
        />
        <CustomMenu
          buttonId={buttonId}
          className={className}
          data-testid="custom-menu"
          intl={intl}
          licenseLimitContext={licenseLimitContext}
          menuContext={menuContext}
          menuId={menuId}
          menuStyle={menuStyle}
          menuTabs={menuTabs}
          menuType={menuType}
          onSelection={onSelection}
          orgId={orgId}
          targets={targets}
        />
      </OverlayTrigger>
    </>
  );
};

AssignmentMenu.propTypes = {
  className: PropTypes.string,
  // onSelection prop will be called with params: item, isItemSelected
  disabled: PropTypes.bool,
  isQuiet: PropTypes.bool,
  onSelection: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  showButtonLabel: PropTypes.bool,
  targets: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TARGET_TYPE))).isRequired,
};

export default AssignmentMenu;
