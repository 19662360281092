import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Organization Summaries APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organization Summaries api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches organization summaries for the specified orgs, which
 *   include limited contract and product information
 * @param {String[]} orgIds - The org IDs to look up summary state for
 */
const getSummaries = async ({orgIds}) => {
  const response = await axios.get(`${url}/v2/organizations:summaries?orgIds=${orgIds.join(',')}`, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response.data;
};

const jilOrganizationsSummaries = {configure, getSummaries};
export default jilOrganizationsSummaries;
