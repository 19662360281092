(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Region
   * @description Model for an individual Region
   */
  angular.module('binky.core.common.country').factory('Region', getRegionModel);

  /* @ngInject */
  function getRegionModel(_) {
    /** Constructor (Prototype function declaration) **/
    /**
     * @class
     * @description Creates a new Region for use.
     *
     * @param {Object} options Initialization Object (params described below)
     * @param {String} options.regionCode A Region's code
     * @param {String} options.regionName A Region's name
     */
    function Region(options) {
      _.assignIn(this, _.pick(options, ['regionCode', 'regionName']));
    }

    /** Return reference to Constructor function **/
    return Region;
  }
})();
