// The abbreviated form of TAX based on country, converted to a string for display purposes
const TAX_TERM = {
  GST: 'GST',
  IVA: 'IVA',
  KDV: 'KDV',
  SST: 'SST',
  TAX: 'TAX',
  VAT: 'VAT',
};

// Map from ISO 3166-1 alpha-2 country codes to country-specific data or overrides. See
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements for the
// list of country codes and the countries they correspond to. These country codes are used by
// various Adobe services to identify countries.
//
// Before hardcoding data here, ensure it's not returned by the e-commerce Countries service or
// any other Adobe services. Consider making a feature request to that service so this data
// may be eliminated in the long term.
//
// References:
// Countries service: https://wiki.corp.adobe.com/display/ecommerceservices/Countries+Service
// Example feature request: https://jira.corp.adobe.com/browse/ONESIE-3900
// Tax term source: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=988481800
const COUNTRIES = {
  AT: {
    taxTerm: TAX_TERM.VAT,
  },
  AU: {
    taxTerm: TAX_TERM.GST,
  },
  BE: {
    taxTerm: TAX_TERM.VAT,
  },
  BG: {
    taxTerm: TAX_TERM.VAT,
  },
  BR: {
    allPricesIncludeTax: true,
    taxTerm: TAX_TERM.VAT,
  },
  CA: {
    taxTerm: TAX_TERM.TAX,
  },
  CH: {
    taxTerm: TAX_TERM.VAT,
  },
  CO: {
    taxTerm: TAX_TERM.IVA,
  },
  CY: {
    taxTerm: TAX_TERM.VAT,
  },
  CZ: {
    taxTerm: TAX_TERM.VAT,
  },
  DE: {
    taxTerm: TAX_TERM.VAT,
  },
  DK: {
    taxTerm: TAX_TERM.VAT,
  },
  EE: {
    taxTerm: TAX_TERM.VAT,
  },
  ES: {
    taxTerm: TAX_TERM.VAT,
  },
  FI: {
    taxTerm: TAX_TERM.VAT,
  },
  FR: {
    taxTerm: TAX_TERM.VAT,
  },
  GB: {
    taxTerm: TAX_TERM.VAT,
  },
  GR: {
    taxTerm: TAX_TERM.VAT,
  },
  HU: {
    taxTerm: TAX_TERM.VAT,
  },
  IE: {
    taxTerm: TAX_TERM.VAT,
  },
  IT: {
    taxTerm: TAX_TERM.VAT,
  },
  JP: {
    taxTerm: TAX_TERM.TAX,
  },
  LT: {
    taxTerm: TAX_TERM.VAT,
  },
  LU: {
    taxTerm: TAX_TERM.VAT,
  },
  LV: {
    taxTerm: TAX_TERM.VAT,
  },
  MT: {
    taxTerm: TAX_TERM.VAT,
  },
  MX: {
    taxTerm: TAX_TERM.TAX,
  },
  MY: {
    taxTerm: TAX_TERM.SST,
  },
  NL: {
    taxTerm: TAX_TERM.VAT,
  },
  NO: {
    taxTerm: TAX_TERM.VAT,
  },
  NZ: {
    taxTerm: TAX_TERM.GST,
  },
  PL: {
    taxTerm: TAX_TERM.VAT,
  },
  PT: {
    taxTerm: TAX_TERM.VAT,
  },
  RO: {
    taxTerm: TAX_TERM.VAT,
  },
  SE: {
    taxTerm: TAX_TERM.VAT,
  },
  SG: {
    taxTerm: TAX_TERM.GST,
  },
  SI: {
    taxTerm: TAX_TERM.VAT,
  },
  SK: {
    taxTerm: TAX_TERM.VAT,
  },
  TR: {
    taxTerm: TAX_TERM.KDV,
  },
  US: {
    taxTerm: TAX_TERM.TAX,
  },
  ZA: {
    taxTerm: TAX_TERM.VAT,
  },
};

/**
 * Returns the appropriate tax term for the specified country as a TAX_TERM enum value. For
 * example, returns TAX_TERM.TAX for 'US', TAX_TERM.VAT for 'DE' (Germany), or TAX_TERM.GST for
 * 'AU' (Australia). For countries where the tax term is not known, defaults to TAX_TERM.TAX.
 *
 * @param {String} countryCode - the Adobe IT "ISO2" standard two digit country code, e.g. 'US'
 * @return {TAX_TERM} the appropriate tax term for the specified country
 */
const getTaxTerm = (countryCode) => COUNTRIES[countryCode]?.taxTerm || TAX_TERM.TAX;

/**
 * Returns an indication of whether all prices for this country include tax, regardless of what
 * offers or cart return info says.
 *
 * @param {String} countryCode - the Adobe IT "ISO2" standard two digit country code, e.g. 'US'
 * @return {Boolean} true if all prices for this country include tax, false otherwise
 */
const getAllPricesIncludeTax = (countryCode) => !!COUNTRIES[countryCode]?.allPricesIncludeTax;

export {getAllPricesIncludeTax, getTaxTerm, TAX_TERM};
