(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   */
  angular.module('binky.widgets.omni-tool.fakes-panel').constant('OMNI_TOOL_FAKES_PANEL', {
    ID: 'fakes-panel',
    TEXT: 'fakes',
  });
})();
