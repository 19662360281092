/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- @boag to fix*/
/**
 * Wraps the JS console and publishes error messages to NewRelic
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Console} for the JavaScript Console
 * @see {@link https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api} for NewRelic's noticeError
 */
import cloneDeepWith from 'lodash/cloneDeepWith';
import hasIn from 'lodash/hasIn';
import isError from 'lodash/isError';

function filterPII(obj) {
  const excluded = ['Authorization', 'email', 'x-user-token'];
  if (Array.isArray(obj)) {
    return obj.map(cloneFn);
  }
  return cloneFn(obj);

  function cloneFn(value) {
    return cloneDeepWith(value, (item) => {
      const response = item;
      excluded.forEach((key) => {
        if (hasIn(item, key)) {
          response[key] = '[REDACTED]';
        }
      });
      return response;
    });
  }
}

function reportError(error) {
  // If reporting an error to New Relic is causing an error, just swallow it to
  // prevent infinite loop.
  try {
    window.newrelic.noticeError(error); // arg is Error object
  } finally {
    // empty
  }
}

function isString(str) {
  return typeof str.valueOf() === 'string';
}

function processNonErrorObjectArg(arg) {
  if (isString(arg)) {
    return `${arg}; `;
  }
  return `${JSON.stringify(filterPII(arg))}; `;
}

function reportMessageAsError(msg, options) {
  // According to NR docs, due to browser limitations for Safari and IE we must
  // throw the error to generate a stack trace.
  try {
    throw new Error(msg.trim(), options);
  } catch (error) {
    reportError(error);
  }
}

function noticeMessageAndError(msg, err) {
  reportMessageAsError(processNonErrorObjectArg(msg), {cause: err});
}

function noticeErrorArray(args) {
  // Each non-error arg is appended to a single error to New Relic.
  // Error objects are reported individually
  let strMessage = '';
  for (let i = 0, len = args.length; i < len; i++) {
    const arg = args[i];
    if (isError(arg)) {
      reportError(arg);
    } else {
      strMessage += processNonErrorObjectArg(arg);
    }
  }
  // According to NR docs, due to browser limitations for Safari and IE we must
  // throw the error to generate a stack trace.
  if (strMessage !== '') {
    try {
      throw new Error(strMessage.trim());
    } catch (error_) {
      strMessage = error_;
    }
    reportError(strMessage);
  }
}

/* eslint-disable no-console -- this file controls logging to the console */
const log = {
  /**
   * Return a new reference to `log`. This function is included to satisfy the Pandora logger interface:
   * https://git.corp.adobe.com/PandoraUI/util/tree/master/packages/logger
   *
   * @returns {Object} A new reference to the log object.
   */
  child: () => log,
  /**
   * Log a message to the console at debug level. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/Console/debug
   *
   * @param {...args} args the set of arguments to pass down to console.debug
   */
  debug: (...args) => {
    console.debug(...args);
  },
  /**
   * Log a message to the console at error level. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/Console/error
   * It will also publish this message, and any errors within to NewRelic
   * for them to be aggregated and debugged there.
   *
   * @param {...args} args the set of arguments to pass down to console.error
   */
  error: (...args) => {
    if (window.newrelic && window.newrelic.noticeError) {
      if (args.length === 2 && !isError(args[0]) && isError(args[1])) {
        // when the first object is a string (or JSON) we'll construct our own error object
        // and use the second object as a cause
        noticeMessageAndError(args[0], args[1]);
      } else {
        noticeErrorArray(args);
      }
    } else {
      log.warn('New Relic not available to record error.');
    }
    console.error(...args);
  },
  /**
   * Log a message to the console at info level. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/Console/info
   *
   * @param {...args} args the set of arguments to pass down to console.info
   */
  info: (...args) => {
    console.info(...args);
  },
  /**
   * Log a message to the console at log level. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/Console/log
   *
   * @param {...args} args the set of arguments to pass down to console.log
   */
  log: (...args) => {
    console.log(...args);
  },
  /**
   * Output a stack trace to the console. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/console/trace
   */
  trace: () => {
    console.trace();
  },
  /**
   * Log a message to the console at warn level. See:
   * https://developer.mozilla.org/en-US/docs/Web/API/Console/warn
   *
   * @param {...args} args the set of arguments to pass down to console.warn
   */
  warn: (...args) => {
    console.warn(...args);
  },
};
/* eslint-enable no-console -- this file controls logging to the console */

export default log;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- @boag to fix*/
