import {
  DATA_POINT_TYPES,
  USAGE_REPORT_DATA,
  getAnnualDataPoints,
  getMonthlyDataPoints,
  log,
} from '@admin-tribe/binky';
import {Content, Flex, Heading, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import GoUrl from '../go-url/GoUrl';

import ExpiredContractBanner from './ExpiredContractBanner';
import ChartAndSelect from './chart-and-select/ChartAndSelect';
import {
  getHeaderTitle,
  getLearnMoreGoUrlText,
  getTimeStamp,
  isProductDeletedInTimePeriod,
  shouldShowExpiredContractBanner,
} from './etlaUsageReportUtils';
import SearchAndProductsRail from './search-and-products-rail/SearchAndProductsRail';
/**
 * A component that will display the etla usage report.
 */
const EtlaUsageReport = ({orgId, reportData}) => {
  const intl = useIntl();
  const showExpiredContractBanner = shouldShowExpiredContractBanner(reportData.endDate);
  const learnMoreGoUrlText = getLearnMoreGoUrlText(intl);
  const headerTitle = getHeaderTitle(intl, learnMoreGoUrlText);
  const timestamp = getTimeStamp(intl);

  const [selectedProduct, setSelectedProduct] = useState(reportData.productsTableData[0]);
  const [disableDropDown, setDisableDropDown] = useState(getInitialDisableDropDown());
  const [errorMessageStringLines, setErrorMessageStringLines] = useState(
    getInitialDisplayErrorMessageLines()
  );
  const [periodsDropDownField, setPeriodsDropDownField] = useState({
    endDate: reportData.timePeriods[reportData.timePeriods.length - 1].endDate,
    selectedPeriodType: DATA_POINT_TYPES.ANNUAL,
    startDate: reportData.timePeriods[reportData.timePeriods.length - 1].startDate,
  });
  const [chartDataPoints, setChartDataPoints] = useState(reportData?.dataPoints?.dataPoints);
  const [isChartDataPointsLoading, setChartDataPointsLoading] = useState(false);

  function getInitialDisableDropDown() {
    return !reportData.productsTableData[0].shouldRenderChartInfo;
  }
  function getInitialDisplayErrorMessageLines() {
    const result = [];
    if (!reportData.productsTableData[0].shouldRenderChartInfo) {
      result.push({
        id: 'unavailableProduct.line1',
        message: intl.formatMessage({
          id: 'binky.widgets.reports.error.errorMessage.unavailableProduct.line1',
        }),
      });
      return result;
    }
    if (reportData.dataPointsStatus !== undefined) {
      result.push({
        id: `${reportData.dataPointsStatus}.line1`,
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.${reportData.dataPointsStatus}.line1`,
        }),
      });
      if (reportData.dataPointsStatus !== 'notFoundBeforeReportAvailableStartDate') {
        result.push({
          id: `${reportData.dataPointsStatus}.line2`,
          message: intl.formatMessage({
            id: `binky.widgets.reports.error.errorMessage.${reportData.dataPointsStatus}.line2`,
          }),
        });
      }
    }
    return result;
  }

  async function findReport(currentProduct, currentDuration) {
    let resultErrorMessageString = errorMessageStringLines;
    let resultDisableDropDown = false;
    let resultChartDataPoints = null;
    const errorMessageString = [];
    const {endDate, selectedPeriodType, startDate} = currentDuration;
    const apiOptions = {
      contractId: reportData?.contractId,
      deleted: currentProduct?.bundleProduct?.deleted,
      deletedTS: currentProduct?.bundleProduct?.deletedTS,
      endDate,
      licenseId: currentProduct?.product?.id,
      orgId,
      startDate,
      type: selectedPeriodType,
    };
    if (!currentProduct?.shouldRenderChartInfo) {
      resultDisableDropDown = true;
      errorMessageString.push({
        id: 'unavailableProduct.line1',
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.unavailableProduct.line1`,
        }),
      });
      resultErrorMessageString = errorMessageString;
    } else if (isProductDeletedInTimePeriod(apiOptions)) {
      errorMessageString.push({
        id: 'deletedProduct.line1',
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.deletedProduct.line1`,
        }),
      });
      errorMessageString.push({
        id: 'deletedProduct.line2',
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.deletedProduct.line2`,
        }),
      });
      resultErrorMessageString = errorMessageString;
    } else if (apiOptions.deleted && apiOptions.endDate < USAGE_REPORT_DATA.AVAILABLE_START_DATE) {
      errorMessageString.push({
        id: 'notFoundBeforeReportAvailableStartDate.line1',
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.notFoundBeforeReportAvailableStartDate.line1`,
        }),
      });
      resultErrorMessageString = errorMessageString;
    } else {
      try {
        resultChartDataPoints = (await fetchDataPoints(apiOptions, selectedPeriodType)).dataPoints;
      } catch (error) {
        resultErrorMessageString = getErrorMessageLines(error);
        log.error(
          `An error occurred fetching data points for licenseId: ${currentProduct?.product?.id} and periodType: ${selectedPeriodType}`
        );
      }
    }

    if (resultChartDataPoints) {
      setChartDataPoints(resultChartDataPoints);
      setErrorMessageStringLines([]);
    } else setErrorMessageStringLines(resultErrorMessageString);
    setDisableDropDown(resultDisableDropDown);
    setChartDataPointsLoading(false);
  }

  function onSelectProduct(productData) {
    const currentSelectedProductIndex = reportData.productsTableData.findIndex(
      (productTableItem) => productTableItem.product.id === productData.anchorKey
    );

    setSelectedProduct(reportData.productsTableData[currentSelectedProductIndex]);
    setChartDataPointsLoading(true);
    findReport(reportData.productsTableData[currentSelectedProductIndex], periodsDropDownField);
  }

  function getErrorMessageLines(errorType) {
    const result = [];
    result.push({
      id: `${errorType}.line1`,
      message: intl.formatMessage({
        id: `binky.widgets.reports.error.errorMessage.${errorType}.line1`,
      }),
    });
    if (errorType !== 'notFoundBeforeReportAvailableStartDate') {
      result.push({
        id: `${errorType}.line2`,
        message: intl.formatMessage({
          id: `binky.widgets.reports.error.errorMessage.${errorType}.line2`,
        }),
      });
    }

    return result;
  }

  function fetchDataPoints(options, selectedPeriodType) {
    if (selectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
      return getAnnualDataPoints(options);
    }
    return getMonthlyDataPoints(options);
  }

  function onSelectedChanged(startEndDateAndSelectPeriodType) {
    const {endDate, selectedPeriodType, startDate} = startEndDateAndSelectPeriodType;
    setPeriodsDropDownField({
      endDate,
      selectedPeriodType,
      startDate,
    });
    setChartDataPointsLoading(true);
    findReport(selectedProduct, startEndDateAndSelectPeriodType);
  }

  return (
    <View backgroundColor="gray-50" marginBottom="size-10" padding="size-200">
      <Heading level={2}>{headerTitle}</Heading>
      <Text>
        {intl.formatMessage({id: 'binky.widgets.reports.header.body'})}
        <Flex marginBottom="size-100">
          <GoUrl id="license-assign-go-url" name="aac_license_assign_learn">
            {intl.formatMessage({
              id: 'binky.widgets.reports.learnMoreGoUrl.etlaComponentText',
            })}
          </GoUrl>
        </Flex>
      </Text>
      <Content marginBottom="size-400">
        <Flex>
          {showExpiredContractBanner && (
            <ExpiredContractBanner postGraceEndDate={reportData.postGraceEndDate} />
          )}
        </Flex>
        <Flex marginBottom="size-100">
          <View marginEnd="size-200">
            <SearchAndProductsRail
              onSelectProduct={onSelectProduct}
              productsTableData={reportData.productsTableData}
            />
            <View
              position="absolute"
              UNSAFE_style={{
                fontSize: 'smaller',
              }}
            >
              {timestamp}
            </View>
          </View>
          <View
            backgroundColor="gray-100"
            id="chart-and-select-placeholder"
            marginTop="size-100"
            padding="size-100"
            width="100%"
          >
            <ChartAndSelect
              chartDataPoints={chartDataPoints}
              disableDropDown={disableDropDown}
              displayErrorMessageLines={errorMessageStringLines}
              isLoading={isChartDataPointsLoading}
              maxWidth="100%"
              onSelectedChanged={onSelectedChanged}
              selectedProduct={selectedProduct}
              timePeriods={reportData.timePeriods}
            />
          </View>
        </Flex>
      </Content>
    </View>
  );
};
EtlaUsageReport.propTypes = {
  /**
   * The organisation id for which etla usage report dashboard data is to be displayed.
   */
  orgId: PropTypes.string.isRequired,
  /**
   * An object defining the reportData which wraps timePeriods, currentYear_dataPoints, productList, contractId and orgId.
   */
  reportData: PropTypes.shape({
    contractId: PropTypes.string,
    dataPoints: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
      dataPoints: PropTypes.arrayOf(PropTypes.object),
      endDate: PropTypes.number,
      startDate: PropTypes.number,
    }),
    dataPointsStatus: PropTypes.string,
    endDate: PropTypes.number,
    graceEndDate: PropTypes.number,
    postGraceEndDate: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    productsTableData: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    timePeriods: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default EtlaUsageReport;
