const CONTRACT_AUTORENEWAL_MODE = {
  OFF: 'OFF',
  ONCE: 'ONCE',
  PERPETUAL: 'PERPETUAL',
};
const CONTRACT_BILLING_FREQUENCY = {
  // If the bill frequency is annual, the customer has paid upfront.
  ANNUAL: 'ANNUAL',
  // If the bill frequency is monthly, the customer has an annual commitment.
  MONTHLY: 'MONTHLY',
  // JIL returns UNKNOWN if the order is still being processed in the backend (ECC and JEM).
  UNKNOWN: 'UNKNOWN',
};
const CONTRACT_BUYING_PROGRAM = {
  ENTERPRISE_PRODUCT: 'ENTERPRISE_PRODUCT',
  ETLA: 'ETLA',
  FREE: 'FREE',
  VIP: 'VIP',
  VIPMP: 'VIPMP',
};
const CONTRACT_COMPLIANCE_SYMPTOMS = {
  CAN_ADMINISTER: 'can_administer',
  CAN_ALLOCATE: 'can_allocate',
  CAN_CANCEL_PROMISES: 'can_cancel_promises',
  CAN_CREATE_PROMISE: 'can_create_promise',
  CAN_DELEGATE: 'can_delegate',
  CAN_DELETE: 'can_delete',
  CAN_MESSAGE_ABLE_TO_RENEW: 'can_message_able_to_renew',
  CAN_MESSAGE_CANCELLATION: 'can_message_cancellation',
  CAN_MESSAGE_EXPIRATION: 'can_message_expiration',
  CAN_MESSAGE_EXPIRATION_UNTIL: 'can_message_expiration_until',
  CAN_MESSAGE_INCOMPLETE_RENEWAL: 'can_message_incomplete_renewal',
  // eslint-disable-next-line id-length -- id matches symptom name
  CAN_MESSAGE_UNBACKED_PROMISE_QUANTITY_EXCEEDED: 'can_message_unbacked_promise_quantity_exceeded',
  CAN_MESSAGE_UNBACKED_PROMISES: 'can_message_unbacked_promises',
  CAN_MESSAGE_UPCOMING_CANCELLATION: 'can_message_upcoming_cancellation',
  CAN_MESSAGE_UPCOMING_EXPIRATION: 'can_message_upcoming_expiration',
  CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL: 'can_message_upcoming_expiration_until',
  CAN_MESSAGE_UPCOMING_RENEWAL_UNTIL: 'can_message_upcoming_renewal_until',
  CAN_RENEW_CONTRACT: 'can_renew_contract',
  CAN_RENEW_CONTRACT_UNTIL: 'can_renew_contract_until',
  CAN_SWITCH: 'can_switch',
};

const CONTRACT_EVENT = {
  UPDATE: 'UpdateContract',
};
const CONTRACT_EXPIRATION_GRACE_UNIT = {
  DAY: 'DAY',
  YEAR: 'YEAR',
};
const CONTRACT_EXPIRATION_PHASE = {
  GRACE: 'GRACE',
  INACTIVE: 'INACTIVE',
  NORMAL: 'NORMAL',
  NOTIFICATION: 'NOTIFICATION',
  POST_GRACE: 'POST_GRACE',
  UNKNOWN: 'UNKNOWN',
};
const CONTRACT_EXPIRATION_PHASE_ORDER = {
  GRACE: 3,
  INACTIVE: 5,
  NORMAL: 1,
  NOTIFICATION: 2,
  POST_GRACE: 4,
  UNKNOWN: 0,
};
const CONTRACT_GRACE_END_DATE_OFFSET = {
  MAX: 999,
  MIN: 7,
};
const CONTRACT_LEGACY_DX_ID = 'legacy_dx';
const CONTRACT_DX_CORE_SERVICES_DEAL_ID = 'Dx_Core_Services';
const CONTRACT_MODEL = {
  // Contract Service
  ALLOCATION: 'ALLOCATION',
  CLASSROOM: 'CLASSROOM',
  RESELLER: 'RESELLER',
  STANDARD: 'STANDARD',
  TRIAL: 'TRIAL',
};
const CONTRACT_PATCH_API_KEY_LOOKUP_TABLE = {
  // Temporary mapping from UI keys to API keys
  // https://git.corp.adobe.com/admin-tribe/twosie/issues/167
  accountInfo: {
    accountManagerEmail: 'ACCOUNT_MGR_EMAIL',
    accountManagerName: 'ACCOUNT_MGR_NAME',
    agreementNumber: 'AGREEMENT_NUMBER',
    dealId: 'DEAL_ID',
    overDeployContactEmail: 'OVERDEPLOY_CONTACT_EMAIL',
  },
  complianceDetails: {
    graceEndOffset: 'GRACE_END_OFFSET',
    graceEndUnit: 'GRACE_END_UNIT',
  },
  eccIds: 'ECC_IDS',
  model: 'MODEL',
  termInfo: {
    effectiveDate: 'EFFECTIVE_DATE',
    endDate: 'END_DATE',
    startDate: 'START_DATE',
  },
};
const CONTRACT_PAYMENT_CATEGORY = {
  CREDIT_CARD: 'CREDIT_CARD',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  // For Japanese PUF teams purchased with one of the offline payment methods; bank transfer (BT)
  // or Convenience store (CVS).
  OFFLINE: 'OFFLINE',
  PAYPAL: 'PAYPAL',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  REDEMPTION_CODE: 'REDEMPTION_CODE',
  REDEMPTION_CODE_WITH_PAYMENT_INSTRUMENT: 'REDEMPTION_CODE_WITH_PAYMENT_INSTRUMENT',
  VENDOR_AMAZON_PAYMENT: 'VENDOR_AMAZON_PAYMENT',
  VENDOR_APPLE_PAYMENT: 'VENDOR_APPLE_PAYMENT',
  VENDOR_DIGITAL_RIVER_PAYMENT: 'VENDOR_DIGITAL_RIVER_PAYMENT',
  VENDOR_GOOGLE_PAYMENT: 'VENDOR_GOOGLE_PAYMENT',
  VENDOR_MANAGED_PAYMENT: 'VENDOR_MANAGED_PAYMENT',
};
const CONTRACT_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLING: 'CANCELLING',
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
  MIGRATED: 'MIGRATED',
};
const PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS = {
  GRACE: {
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
    ],
    REQUIRED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_ADMINISTER,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION_UNTIL,
    ],
  },
  INACTIVE: {
    DISABLED_SYMPTOMS: [CONTRACT_COMPLIANCE_SYMPTOMS.CAN_ADMINISTER],
    EXCLUDED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION_UNTIL,
    ],
    REQUIRED_SYMPTOMS: [CONTRACT_COMPLIANCE_SYMPTOMS.CAN_DELETE],
  },
  NORMAL: {
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_DELETE,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION_UNTIL,
    ],
    REQUIRED_SYMPTOMS: [],
  },
  NOTIFICATION: {
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [],
    REQUIRED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
    ],
  },
  POST_GRACE: {
    DISABLED_SYMPTOMS: [CONTRACT_COMPLIANCE_SYMPTOMS.CAN_ADMINISTER],
    EXCLUDED_SYMPTOMS: [],
    REQUIRED_SYMPTOMS: [
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION,
      CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION_UNTIL,
    ],
  },
};

const THREE_YEAR_COMMIT = 'THREE_YEAR_COMMIT';

export {
  CONTRACT_AUTORENEWAL_MODE,
  CONTRACT_BILLING_FREQUENCY,
  CONTRACT_BUYING_PROGRAM,
  CONTRACT_COMPLIANCE_SYMPTOMS,
  CONTRACT_DX_CORE_SERVICES_DEAL_ID,
  CONTRACT_EVENT,
  CONTRACT_EXPIRATION_GRACE_UNIT,
  CONTRACT_EXPIRATION_PHASE,
  CONTRACT_EXPIRATION_PHASE_ORDER,
  CONTRACT_GRACE_END_DATE_OFFSET,
  CONTRACT_LEGACY_DX_ID,
  CONTRACT_MODEL,
  CONTRACT_PATCH_API_KEY_LOOKUP_TABLE,
  CONTRACT_PAYMENT_CATEGORY,
  CONTRACT_STATUS,
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS,
  THREE_YEAR_COMMIT,
};
