import {Flex} from '@adobe/react-spectrum';
import SettingsIcon from '@spectrum-icons/workflow/Settings';
import ViewGridIcon from '@spectrum-icons/workflow/ViewGrid';
import PropTypes from 'prop-types';
import React from 'react';

import WorkspaceNavItem from './model/WorkspaceNavItem';

/**
 * Component that will take a provided icon string and return the appropriate
 * icon element.
 */
const NavIconAndLabel = ({workspaceNavItem}) => {
  const getIcon = () => {
    switch (workspaceNavItem.icon) {
      case 'SettingsIcon':
        return <SettingsIcon size="XS" />;
      case 'ViewGridIcon':
        return <ViewGridIcon size="XS" />;
      default:
        return null;
    }
  };

  return (
    <Flex alignItems="center" gap="size-100">
      {getIcon()}
      {workspaceNavItem.name}
    </Flex>
  );
};

NavIconAndLabel.propTypes = {
  /**
   * Workspace nav item whose name and icon will be rendered.
   */
  workspaceNavItem: PropTypes.instanceOf(WorkspaceNavItem),
};
export default NavIconAndLabel;
