import binky from '@admin-tribe/binky';
import camelCase from 'lodash/camelCase';
import React from 'react';
import {useIntl} from 'react-intl';

import {
  useProductRoleContext,
  useProductRoleLicenseGroupContext,
  useProductRoleMemberContext,
} from './ProductRoleContext';

const feature = binky.services.feature;

const hasAlreadyAssignedToLicenseGroup = (member, {licenseGroupId, productId}) => {
  const memberProduct = member.savedState?.products?.find((product) => product.id === productId);
  return memberProduct?.licenseGroups?.some((licenseGroup) => licenseGroup.id === licenseGroupId);
};

const fetchExistingProductRoleIdIfExists = async (member, {licenseGroupId, orgId, product}) => {
  const productId = product.id;
  if (hasAlreadyAssignedToLicenseGroup(member, {licenseGroupId, productId})) {
    const licenseGroupUserList =
      new binky.services.product.licenseGroup.userList.LicenseGroupUserList({
        filterQuery: member.email,
        licenseGroupId,
        orgId,
        productId,
      });

    await licenseGroupUserList.refresh();

    const foundMatchingMember = licenseGroupUserList.items.find((item) => item.id === member.id);
    return foundMatchingMember?.productRole?.id;
  }

  return undefined;
};

const getFetchedMemberRoles = async ({licenseGroupId, member, orgId, product}) => {
  const memberConfigurationRoles =
    new binky.services.product.licenseGroup.memberConfiguration.MemberConfigurationRoles({
      licenseGroupId,
      memberIds: member?.id ? [member.id] : [],
      orgId,
      productId: product.id,
    });
  await memberConfigurationRoles.refresh();

  const firstExistingProductRoleId = memberConfigurationRoles.memberRoles[member.id]?.[0];
  return {
    allLicenseGroupsShareSameRole: true,
    availableProductRoles: [...memberConfigurationRoles.availableRoles],
    existingProductRoleId: firstExistingProductRoleId,
  };
};

const getFetchedLicenseGroupProductRoles = async ({
  intl,
  licenseGroupId,
  member,
  orgId,
  product,
}) => {
  const existingProductRoleIdPromise = fetchExistingProductRoleIdIfExists(member, {
    licenseGroupId,
    orgId,
    product,
  });
  const licenseGroup = await binky.services.product.licenseGroup.LicenseGroup.get({
    id: licenseGroupId,
    orgId,
    product,
  });

  const existingProductRoleId = await existingProductRoleIdPromise;

  const fetchedProductRoles = licenseGroup.getProductRoles();
  fetchedProductRoles.forEach((productRole) => {
    Object.assign(productRole, {
      name: intl.formatMessage({
        id: `binky.common.productRolePicker.userRole.product.${camelCase(productRole.title)}`,
      }),
    });
  });
  return {
    availableProductRoles: fetchedProductRoles,
    existingProductRoleId,
  };
};

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
const useProductRolesApis = () => {
  const [memberProductRoles, setMemberProductRoles] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const intl = useIntl();
  const member = useProductRoleMemberContext();
  const {licenseGroupId, orgId, product} = useProductRoleLicenseGroupContext() || {};
  const productRoleContext = useProductRoleContext();

  React.useEffect(() => {
    let isCurrent = true;

    const fetchAvailableProductRoles = async () => {
      let fetchedProductRoles = {};

      setIsLoading(true);

      try {
        const fetchFunc = product.hasConfigurationSettingForLicenseGroupMember()
          ? getFetchedMemberRoles
          : getFetchedLicenseGroupProductRoles;
        fetchedProductRoles = await fetchFunc({
          intl,
          licenseGroupId,
          member,
          orgId,
          product,
        });
      } catch (error) {
        binky.services.log.error('Failed to get product roles. Error:', error);
        if (isCurrent) {
          productRoleContext?.onError(error);
        }
      } finally {
        if (isCurrent) {
          setIsLoading(false);
          setMemberProductRoles(fetchedProductRoles);
        }
      }
    };

    if (!!productRoleContext && !!licenseGroupId && !!orgId && !!product && !!member) {
      const canViewProductRole =
        (feature.isDisabled('bug_fix_13921') || product.isAdministerable()) &&
        (!product.hasConfigurationSettingForLicenseGroupMember() || member.getType().isUser());
      if (canViewProductRole) {
        fetchAvailableProductRoles();
      }
    }

    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore onError function
  }, [intl, licenseGroupId, member, orgId, product]);

  return [memberProductRoles, isLoading];
};

export default useProductRolesApis;
