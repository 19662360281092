const LICENSE_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
};
const LICENSE_TYPE = {
  MACHINE: 'MACHINE',
  STANDARD: 'STANDARD',
};
const PRODUCT_APPLICABLE_OFFER_TYPE = {
  BASE: 'BASE',
  TRIAL: 'TRIAL',
};
const PRODUCT_BUYING_PROGRAM = {
  ETLA: 'ETLA',
  RETAIL: 'RETAIL',
  VIP: 'VIP',
  VIPMP: 'VIPMP',
};
const PRODUCT_CACHE_ID = 'Product';
const PRODUCT_CHANNELS = {
  LEGACY_AAC: 'aac_available_offers',
  LEGACY_ENTD: 'caui.services.adobe.com/admin',
  LEGACY_ENTD_OVERVIEW: 'entd_overview',
  LEGACY_ENTD_PRODUCTS: 'entd_products',
  LEGACY_TEAM_DIRECT: 'creative.adobe.com',
  OVERVIEW: 'aac_overview',
  PRODUCTS: 'aac_products',
};
const PRODUCT_CODE = {
  APAS: 'APAS',
  APSF: 'APSF',
  ASIG: 'ASIG',
  CSAS: 'CSAS', // storage only product
};
const PRODUCT_COMPLIANCE_SYMPTOM = {
  CAN_ACCESS: 'can_access',
  CAN_ACCESS_UNTIL: 'can_access_until',
  CAN_ADMINISTER: 'can_administer',
  CAN_AUTHORIZE: 'can_authorize',
  CAN_DELEGATE: 'can_delegate',
  CAN_HIDE: 'can_hide',
  CAN_MESSAGE_BILLING_FAILURE: 'can_message_billing_failure',
  CAN_MESSAGE_EXPIRATION: 'can_message_expiration',
  CAN_MESSAGE_EXPIRATION_AT: 'can_message_expiration_at',
  CAN_MESSAGE_NEEDS_BACKING: 'can_message_needs_backing',
  CAN_MESSAGE_UPCOMING_CANCELLATION: 'can_message_upcoming_cancellation',
  CAN_MESSAGE_UPCOMING_EXPIRATION: 'can_message_upcoming_expiration',
  CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL: 'can_message_upcoming_expiration_until',
  CAN_MESSAGE_UPCOMING_NON_RENEWAL: 'can_message_upcoming_non_renewal',
  CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION: 'can_message_upcoming_trial_expiration',
  CAN_OVERDELEGATE: 'can_overdelegate',
};
const PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS = {
  GRACE: {
    // can be removed when bug_fix_e2e_23023 is removed
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
    ],
    REQUIRED_SYMPTOMS: [
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_ADMINISTER,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_EXPIRATION,
    ],
  },
  INACTIVE: {
    // can be removed when bug_fix_e2e_23023 is removed
    DISABLED_SYMPTOMS: [PRODUCT_COMPLIANCE_SYMPTOM.CAN_ADMINISTER],
    EXCLUDED_SYMPTOMS: [
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_AUTHORIZE,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_EXPIRATION,
    ],
    REQUIRED_SYMPTOMS: [],
  },
  NORMAL: {
    // can be removed when bug_fix_e2e_23023 is removed
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_EXPIRATION,
    ],
    REQUIRED_SYMPTOMS: [PRODUCT_COMPLIANCE_SYMPTOM.CAN_ADMINISTER],
  },
  NOTIFICATION: {
    // can be removed when bug_fix_e2e_23023 is removed
    DISABLED_SYMPTOMS: [],
    EXCLUDED_SYMPTOMS: [],
    REQUIRED_SYMPTOMS: [
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION,
      PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL,
    ],
  },
  POST_GRACE: {
    // can be removed when bug_fix_e2e_23023 is removed
    DISABLED_SYMPTOMS: [PRODUCT_COMPLIANCE_SYMPTOM.CAN_ADMINISTER],
    EXCLUDED_SYMPTOMS: [PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS],
    REQUIRED_SYMPTOMS: [PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_EXPIRATION],
  },
};
const PRODUCT_CONFIGURATION_SETTING_TYPE = {
  LEGACY_PERMISSIONS: 'LEGACY_PERMISSIONS',
  LICENSE_GROUP: 'LICENSE_GROUP',
  LICENSE_GROUP_MEMBER: 'LICENSE_GROUP_MEMBER',
};
const PRODUCT_DELEGATION_TARGET = {
  API_KEY: 'API_KEY',
  MACHINE_ID: 'MACHINE_ID',
  NA: 'NA',
  ORG_ID: 'ORG_ID',
  THIRD_PARTY: 'THIRD_PARTY',
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',
  TYPE2E: 'TYPE2E',
  TYPE3: 'TYPE3',
};
const PRODUCT_DELEGATION_USER_TARGETS = [
  PRODUCT_DELEGATION_TARGET.TYPE1,
  PRODUCT_DELEGATION_TARGET.TYPE2,
  PRODUCT_DELEGATION_TARGET.TYPE2E,
  PRODUCT_DELEGATION_TARGET.TYPE3,
];
const PRODUCT_FAMILY = {
  ACROBAT: 'ACROBAT',
  CCM_STORAGE: 'CCM_STORAGE',
  SIGN: 'SIGN',
  SPARK: 'SPARK',
  STOCK_ASSETS: 'STOCK_ASSETS',
  STOCK_CREDIT_PACK: 'STOCK_CREDIT_PACK',
  STOCK_SUBSCRIPTION_PLAN: 'STOCK_SUBSCRIPTION_PLAN',
};
const PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE = {
  CONSUMPTION: 'CONSUMPTION',
  INSTANCES: 'INSTANCES',
  LICENSES: 'LICENSES',
  MACHINES: 'MACHINES',
  USERS: 'USERS',
};
const PRODUCT_LICENSE_ALLOWLIST = [
  'cc_single_app_pro_group_b_single_user_esm_etla_enterprise', // Single User, Pro ESM
  'cc_single_app_pro_group_b_single_user_etla_enterprise', // Single User, Pro
  'cc_single_app_pro_single_user_esm_etla_enterprise', // Single User, Pro ESM
  'cc_single_app_pro_single_user_etla_enterprise', // Single User, Pro
  'ctsk_direct_indirect_team', // Creative Cloud All Apps + Adobe Stock
  'creative_cloud_all_apps_stock_bundle_edu_team_vip_team', // Creative Cloud All Apps + Adobe Stock - Education (VIP team)
  'creative_cloud_all_apps_stock_bundle_edu_t_direct_team', // Creative Cloud All Apps + Adobe Stock - Education (direct team)
  'PA-848', // Single App - Pro Edition with Adobe Firefly
  'PA-913', // Single App Pro Group B with Firefly
  'PA-915', // CCE Pro Single App with Firefly, Group B - GOV
  'PA-938', // GenStudio: Content Module
  'stks_direct_indirect_team', // Stock subscription
];
const PRODUCT_LICENSE_DISALLOWLIST = [
  // This list exists to ensure we don't show these products as seat based
  // All of these are Stock EVIP (uses profiles, but not consumable based)
  // Longer term, this must be addressed by https://jira.corp.adobe.com/browse/BIZARCH-365
  'PA-168',
  'PA-173',
  'PA-174',
  'PA-175',
  'PA-176',
  'PA-177',
];
const PRODUCT_METADATA_CACHE_ID = 'ProductMetadata';
const PRODUCT_ROLE = {
  APPROVER: 'TARGET_PLC_APPROVER',
  EDITOR: 'TARGET_PLC_EDITOR',
  OBSERVER: 'TARGET_PLC_OBSERVER',
  PUBLISHER: 'TARGET_PLC_PUBLISHER',
};

export {
  LICENSE_STATUS,
  LICENSE_TYPE,
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS,
  PRODUCT_APPLICABLE_OFFER_TYPE,
  PRODUCT_BUYING_PROGRAM,
  PRODUCT_CACHE_ID,
  PRODUCT_CHANNELS,
  PRODUCT_CODE,
  PRODUCT_COMPLIANCE_SYMPTOM,
  PRODUCT_CONFIGURATION_SETTING_TYPE,
  PRODUCT_DELEGATION_TARGET,
  PRODUCT_DELEGATION_USER_TARGETS,
  PRODUCT_FAMILY,
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
  PRODUCT_LICENSE_ALLOWLIST,
  PRODUCT_LICENSE_DISALLOWLIST,
  PRODUCT_METADATA_CACHE_ID,
  PRODUCT_ROLE,
};
