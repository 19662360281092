/* eslint-disable react/no-unknown-property -- styleName not recognized */
import PropTypes from 'prop-types';
import React from 'react';

import './HorizontalList.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-horizontal-list
 */

/**
 * HorizontalList is a widget to display items in horizontal view.
 */
const HorizontalList = ({children, className}) => (
  <ul className={className} styleName="unordered-list-style">
    {children}
  </ul>
);

HorizontalList.propTypes = {
  /**
   * The content to display in the list.
   */
  children: PropTypes.node.isRequired,
  /**
   * The customized class name to display the widget.
   */
  className: PropTypes.string,
};

export default HorizontalList;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
