(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.date-time
   * @description module that defines the date-time custom filter
   */
  angular.module('binky.widgets.common.date-time', [
    'binky.core.translation-utils',
    'pascalprecht.translate',
  ]);
})();
