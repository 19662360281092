(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum ButtonGroup or our ButtonGroupWithMoreOptions for a replacement
   *   with primary-popover-buttons.
   *
   * @ngdoc component
   * @name binky.widgets.common.button-bar:binkyButtonBar
   *
   * @description A button bar widget.
   *
   * @param {Boolean} [hidePrimaryPopoverButtons] Hides the primary popover buttons if true
   * @param {Boolean} [hideSecondaryPopoverButtons] Hides the secondary popover buttons if true
   * @param {transclude} [primaryButtons] The primary buttons that are always visible.
   * @param {transclude} [primaryPopoverButtons] The primary buttons that are hidden in the popover.
   * @param {String} [primaryPopoverTooltip] The string to be displayed in the tooltip. If undefined,
   *                    no tooltip for the primary popover will be shown
   * @param {transclude} [secondaryButtons] The secondary buttons that are always visible.
   * @param {transclude} [secondaryPopoverButtons] The secondary buttons that are hidden in the popover.
   * @param {String} [secondaryPopoverTooltip] The string to be displayed in the tooltip. If undefined,
   *                    no tooltip for the secondary popover will be shown
   *
   * @example  <binky-button-bar>
   *             <secondary-buttons>
   *               <binky-button variant="warning">Secondary 1</binky-button>
   *             </secondary-buttons>
   *             <secondary-popover-buttons>
   *               <button is="coral-buttonlist-item">Secondary popover 1</button>
   *             </secondary-popover-buttons>
   *             <primary-buttons>
   *               <binky-button variant="cta">Primary 1</binky-button>
   *               <binky-button>Primary 2</binky-button>
   *             </primary-buttons>
   *             <primary-popover-buttons>
   *               <button is="coral-buttonlist-item">Primary popover 1</button>
   *               <button is="coral-buttonlist-item">Primary popover 2</button>
   *             </primary-popover-buttons>
   *           </binky-button-bar>
   */
  angular.module('binky.widgets.common.button-bar').component('binkyButtonBar', {
    bindings: {
      hidePrimaryPopoverButtons: '<?',
      hideSecondaryPopoverButtons: '<?',
      primaryPopoverTooltip: '@?',
      secondaryPopoverTooltip: '@?',
    },
    controller,
    templateUrl: 'widgets/common/button-bar/button-bar.component.html',
    transclude: {
      'primary-buttons': '?primaryButtons',
      'primary-popover-buttons': '?primaryPopoverButtons',
      'secondary-buttons': '?secondaryButtons',
      'secondary-popover-buttons': '?secondaryPopoverButtons',
    },
  });

  /* @ngInject */
  function controller($element, $translate, _) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      $postLink,
    });

    function $onInit() {
      setDefaults();
    }

    function $postLink() {
      generateUniqueIds();
      setupPopovers();
    }

    function createPrimaryPopover() {
      const transcludedPopoverButtons = getTranscludedPopoverButtons('primary');
      if (transcludedPopoverButtons.length === 0) {
        return null;
      }
      // Create primary popover button
      const visibleButtons = $element[0].querySelector('.primary-buttons');
      vm.primaryPopoverButton = createPopoverButton(
        vm.primaryPopoverButtonId,
        vm.primaryButtonListId,
        'primary-show-popover-button'
      );
      visibleButtons.appendChild(vm.primaryPopoverButton);

      // Create popover buttonlist
      const buttonList = createButtonList(
        transcludedPopoverButtons,
        vm.primaryPopoverButtonId,
        vm.primaryButtonListId
      );

      // Create popover content
      const popoverContent = new Coral.Popover.Content();
      popoverContent.appendChild(buttonList);

      // Create popover
      const popover = createPopoverElement(popoverContent, vm.primaryPopoverButton);
      $element.append(popover);

      // create tooltip if there is a tooltip string related to the popover
      const tooltipBinding = vm.primaryPopoverTooltip;
      if (tooltipBinding) {
        createTooltip(tooltipBinding, vm.primaryPopoverButton, 'primary-button-tooltip');
      }

      return popover;
    }

    function createSecondaryPopover() {
      const transcludedPopoverButtons = getTranscludedPopoverButtons('secondary');
      if (transcludedPopoverButtons.length === 0) {
        return null;
      }
      // Create secondary popover button
      const visibleButtons = $element[0].querySelector('.secondary-buttons');
      vm.secondaryPopoverButton = createPopoverButton(
        vm.secondaryPopoverButtonId,
        vm.secondaryButtonListId,
        'secondary-show-popover-button'
      );
      visibleButtons.appendChild(vm.secondaryPopoverButton);

      // Create popover buttonlist
      const buttonList = createButtonList(
        transcludedPopoverButtons,
        vm.secondaryPopoverButtonId,
        vm.secondaryButtonListId
      );

      // Create popover content
      const popoverContent = new Coral.Popover.Content();
      popoverContent.appendChild(buttonList);

      // Create popover
      const popover = createPopoverElement(popoverContent, vm.secondaryPopoverButton);
      $element.append(popover);

      // create tooltip if there is a tooltip string related to the popover
      const tooltipBinding = vm.secondaryPopoverTooltip;
      if (tooltipBinding) {
        createTooltip(tooltipBinding, vm.secondaryPopoverButton, 'secondary-button-tooltip');
      }

      return popover;
    }

    function createPopoverButton(popoverButtonId, buttonListId, popoverButtonClass) {
      const popoverButton = new Coral.Button();
      popoverButton.id = popoverButtonId;
      popoverButton.setAttribute(
        'aria-label',
        $translate.instant('binky.widgets.common.buttonBar.moreOptionsLabel')
      );
      popoverButton.setAttribute('aria-haspopup', 'true');
      popoverButton.setAttribute('aria-expanded', 'false');
      popoverButton.setAttribute('aria-controls', buttonListId);
      popoverButton.classList.add(popoverButtonClass);
      popoverButton.set({icon: 'more', variant: 'quiet'});
      return popoverButton;
    }

    function createButtonList(transcludedPopoverButtons, popoverButtonId, buttonListId) {
      const buttonList = new Coral.ButtonList();
      buttonList.id = buttonListId;
      buttonList.setAttribute('role', 'menu');
      buttonList.setAttribute('aria-labelledby', popoverButtonId);
      buttonList.on('click', () => hidePopover(popoverButtonId));

      _.forEach(transcludedPopoverButtons.children(), (button) => {
        button.setAttribute('role', 'menuitem');
        buttonList.appendChild(button);
      });

      return buttonList;
    }

    function createPopoverElement(popoverContent, popoverButton) {
      const popover = new Coral.Popover();
      popover.content = popoverContent;
      popover.placement = 'bottom';
      popover.target = popoverButton;
      popover.on('coral-overlay:open', () => toggleMenu(popoverButton.id, true));
      popover.on('coral-overlay:close', () => toggleMenu(popoverButton.id, false));
      return popover;
    }

    function createTooltip(tooltipBinding, popoverButton, popoverTooltipClass) {
      const buttonTooltip = new Coral.Tooltip();
      buttonTooltip.set({
        content: {
          innerHTML: tooltipBinding,
        },
        id: _.uniqueId('popover-tooltip-'),
        interaction: 'on',
        placement: 'top',
        target: popoverButton,
        variant: 'inspect',
      });

      buttonTooltip.classList.add(popoverTooltipClass);
      popoverButton.setAttribute('aria-describedby', buttonTooltip.id);
      popoverButton.appendChild(buttonTooltip);
      return buttonTooltip;
    }

    function generateUniqueIds() {
      vm.primaryPopoverButtonId = _.uniqueId('primary-popover-button-');
      vm.secondaryPopoverButtonId = _.uniqueId('secondary-popover-button-');
      vm.primaryButtonListId = _.uniqueId('primary-button-list-');
      vm.secondaryButtonListId = _.uniqueId('secondary-button-list-');
    }

    function getTranscludedPopoverButtons(primaryOrSecondary) {
      const transcludedPopoverButtons = $element.find(`${primaryOrSecondary}-popover-buttons`);
      const transcludedPopoverButtonsHTML = transcludedPopoverButtons.html();
      return transcludedPopoverButtonsHTML && transcludedPopoverButtonsHTML.length > 0
        ? transcludedPopoverButtons
        : [];
    }

    function hidePopover(popoverButtonId) {
      if (vm.primaryPopover && popoverButtonId === vm.primaryPopoverButtonId) {
        vm.primaryPopover.hide();
      } else if (vm.secondaryPopover && popoverButtonId === vm.secondaryPopoverButtonId) {
        vm.secondaryPopover.hide();
      }
    }

    function setDefaults() {
      vm.primaryPopover = null;
      vm.secondaryPopover = null;
    }

    function setupPopovers() {
      if (!vm.hidePrimaryPopoverButtons) {
        vm.primaryPopover = createPrimaryPopover();
      }
      if (!vm.hideSecondaryPopoverButtons) {
        vm.secondaryPopover = createSecondaryPopover();
      }
    }

    function toggleMenu(popoverButtonId, show) {
      const popoverButton = $element[0].querySelector(`#${popoverButtonId}`);
      popoverButton.setAttribute('aria-expanded', show ? 'true' : 'false');
    }
  }
})();
