/* eslint-disable max-lines -- file requires more lines */
import get from 'lodash/get';
import pick from 'lodash/pick';

import FulfillableItemList from 'models/fulfillableItemList/FulfillableItemList';
import log from 'services/log';
import {PRODUCT_FAMILY} from 'services/product/ProductConstants';

import aosOffers from '../../api/aosOffers';
import {ORGANIZATION_MARKET_SUBSEGMENT} from '../../services/organization/OrganizationConstants';
import {convertObjectKeysToCamelCase} from '../../utils/jsUtils';
import {
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_TYPE,
} from '../fulfillableItemList/FulfillableItemConstants';

import {
  CUSTOMER_SEGMENT,
  DELEGATION_TYPE,
  PRICE_POINT,
  QUALIFIER_TARGET,
  SALES_CHANNEL,
  TYPE,
} from './OfferConstants';
import {transformFIsToProductFIs} from './offerUtils';

class Offer {
  /**
   * @description Transforms the /offers response Object into a new Offer
   *   instance for use.
   *
   * @param {Object} dataTransferObject Initialization Object (params described below)
   * @param {Object} listRef the list being operated on
   * @param {Object} idx the index of the offer returned by the API
   * @return {Offers} the created Offer object
   */
  static apiResponseTransformer(dataTransferObject, listRef, idx) {
    if (isPoresOffer(dataTransferObject)) {
      return new Offer(transformPoresOfferToOffer(dataTransferObject, idx));
    }
    return new Offer(dataTransferObject);
  }

  /**
   * @description Method for getting an offer object and loading its data
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.offerId The offerId of the offer to fetch
   *
   * @returns {Offer} Offer model object
   */
  static get(options = {}) {
    const model = new Offer({offer_id: options.offerId});
    return model.refresh();
  }

  /**
   * @description Map from the Organization's ORGANIZATION_MARKET_SUBSEGMENT used by /organizations to
   *   QUALIFIER_TARGET used by /offers.
   * @param {Array} marketSubSegments set on the org, or undefined, if it hasn't been set
   *
   * @return {Array} array of QUALIFIER_TARGETs or undefined
   */
  static marketSubsegmentsToOfferQualifierTargets(marketSubSegments) {
    if (marketSubSegments) {
      const returnSegments = marketSubSegments.map((subSegment) => {
        if (subSegment === ORGANIZATION_MARKET_SUBSEGMENT.K_12) {
          return QUALIFIER_TARGET.K12_EDU_ORG;
        }
        if (subSegment === ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED) {
          return QUALIFIER_TARGET.FURTHER_EDU_ORG;
        }
        return undefined;
      });
      return returnSegments.filter((item) => !!item);
    }
    return undefined;
  }

  /**
   * @description Map from the Offer's QUALIFIER_TARGET used by /offers to Organization's ORGANIZATION_MARKET_SUBSEGMENT
   *  used by /organizations.
   * @param {Array} qualifiers the offer's qualifiers, or undefined if there are none
   *
   * @return {Array} array of ORGANIZATION_MARKET_SUBSEGMENTs or undefined
   */
  static qualifierTargetsToMarketSubsegments(qualifiers) {
    if (qualifiers) {
      const returnQualifiers = qualifiers.map((qualifier) => {
        if (qualifier.target === QUALIFIER_TARGET.K12_EDU_ORG) {
          return ORGANIZATION_MARKET_SUBSEGMENT.K_12;
        }
        if (qualifier.target === QUALIFIER_TARGET.FURTHER_EDU_ORG) {
          return ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED;
        }
        return undefined;
      });
      return returnQualifiers.filter((item) => !!item);
    }
    return undefined;
  }

  /**
   * @description Creates a new Offer for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.buying_program the buying program; RETAIL, VIP, ETLA
   * @param {String} options.commitment the commitment; MONTH, YEAR
   * @param {String} options.compliance.terms_href the URL to compliance terms for this offer.
   *      Only returned for RETAIL.
   * @param {String} options.customer_segment the customer segment; TEAM, ENTERPRISE
   * @param {String[]} options.customer_ui the set of channels this offer should be shown in
   * @param {String[]} options.fig_ids Fig ids to be used for assigning add ons to base offers
   * @param {String} options.language the language of the offer; EN or MULT
   * @param {String[]} options.market_segments an array of market segments; COM, EDU, GOV
   * @param {Object[]} options.materials an array of material objects containing country, material_id,
   *      and renewal_material_id. Only returned for RETAIL and VIP offers.
   * @param {Object} options.merchandising merchandising information for this offer
   * @param {String} options.merchandising.assets.icons an object containing icon URLs
   * @param {String} options.merchandising.code the merchandising code
   * @param {Object} options.merchandising.copy the merchandising copy
   * @param {String} options.merchandising.copy.description the long description of this offer
   * @param {String} options.merchandising.copy.market_segment_name the market segment name
   * @param {String} options.merchandising.copy.market_segment_description the market segment description
   * @param {String} options.merchandising.copy.market_segment_short_description the short market segment description
   * @param {String} options.merchandising.copy.name the long name of this offer
   * @param {String} options.merchandising.copy.short_description the short description of this offer
   * @param {String} options.merchandising.copy.short_name the short name of this offer
   * @param {String} options.merchandising.family_code the merchandising family code
   * @param {Object} options.merchandising.fulfillable_items an array of objects containing code, assets.icons,
   *      copy.name, copy.enterprise_name, copy.description, and copy.short_description.
   * @param {Object} options.merchandising.links an object containing links relevant to this offer
   * @param {Array} options.merchandising.links.detailed_help_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.help_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.product_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.provisioned_user an array of objects with hrefs
   * @param {Object} options.metadata the metadata from PORES offer
   * @param {Object} options.merchandising merchandising information for this offer
   * @param {String} options.merchandising.assets.icons an object containing icon URLs
   * @param {String} options.merchandising.code the merchandising code
   * @param {Object} options.merchandising.copy the merchandising copy
   * @param {String} options.merchandising.copy.description the long description of this offer
   * @param {String} options.merchandising.copy.market_segment_description the market segment description
   * @param {String} options.merchandising.copy.market_segment_name the market segment name
   * @param {String} options.merchandising.copy.market_segment_short_description the short market segment description
   * @param {String} options.merchandising.copy.name the long name of this offer
   * @param {String} options.merchandising.copy.short_description the short description of this offer
   * @param {String} options.merchandising.copy.short_name the short name of this offer
   * @param {String} options.merchandising.family_code the merchandising family code
   * @param {Object} options.merchandising.fulfillable_items an array of objects containing code, assets.icons,
   *      copy.name, copy.enterprise_name, copy.description, and copy.short_description.
   * @param {Object} options.merchandising.links an object containing links relevant to this offer
   * @param {Array} options.merchandising.links.detailed_help_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.help_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.product_admin an array of objects with hrefs
   * @param {Array} options.merchandising.links.provisioned_user an array of objects with hrefs
   * @param {String} options.merchant the seller; ADOBE
   * @param {Object} options.metadata the metadata from PORES offer
   * @param {String} options.offer_id An Offer's ID
   * @param {String} options.offer_type the type of offer; BASE
   * @param {String} options.payment_structure the payment structure of the offer;
   *      PAID_IN_ADVANCE, PAID_IN_ARREARS
   * @param {String} options.platform duplicative of platforms
   * @param {Array} options.platforms the platforms supported by the primary product
   * @param {Number} options.poresOrder the order of the offer in the API response
   * @param {String} options.price_point the price point of the offer; REGULAR, FREE
   * @param {Object} options.pricing pricing information for this offer. Only present for RETAIL.
   * @param {Object} options.pricing.currency the currency of the offer.
   * @param {String} options.pricing.currency.code the code of the currency, ie USD
   * @param {String} options.pricing.currency.delimiter the separator to use, generally . or ,
   * @param {String} options.pricing.currency.format_string the string to use when displaying the price.
   * @param {String} options.pricing.currency.symbol the symbol of the currency, ie US $
   * @param {String} options.pricing.currency.use_precision whether to use double values (ie, 10.99 vs 10)
   * @param {Array} options.pricing.prices an array of price details for this offer
   * @param {Object} options.pricing.prices.price_details the price details for this offer
   * @param {String} options.pricing.prices.price_details.annual_cost.amount_without_tax the amount paid in a year, without tax
   * @param {String} options.pricing.prices.price_details.annual_cost.tax the amount paid in tax in a year
   * @param {String} options.pricing.prices.price_details.annual_cost.tax_rate the percentage tax rate being applied
   * @param {String} options.pricing.prices.price_details.display_rules.price the price of this offer
   * @param {String} options.pricing.prices.price_details.display_rules.tax included or excluded
   * @param {String} options.pricing.prices.price_details.promotions any promotions applied to this offer
   * @param {String} options.pricing.prices.price_details.unit.amount_without_tax the price of this offer without tax
   * @param {String} options.pricing.prices.price_details.unit.includes_discount whether a discount is being applied
   * @param {String} options.pricing.prices.price_details.unit.tax the amount of tax being charged
   * @param {String} options.pricing.prices.price_details.unit.tax_rate the percentage tax rate being applied
   * @param {String} options.pricing.prices.sku the SKU backing this offer
   * @param {Object} [options.processing_instructions] instructions that determine whether the offer requires
   *      further actions before a FN message can be made.
   * @param {String} options.product_code the SAP code for the product
   * @param {Object} options.product_arrangement the product arrangement of this offer
   * @param {String} options.product_arrangement_code the product arrangement code
   * @param {String} options.product_arrangement.code the product arrangement code
   * @param {String} options.product_arrangement.label the product arrangement label
   * @param {String} options.product_arrangement.family the product arrangement family
   * @param {String} options.product_arrangement.cloud the product arrangement cloud; CREATIVE,
   *      DOCUMENT, EXPERIENCE, OTHER, UNKNOWN.
   * @param {String} options.product_arrangement.overdeployment_allowed whether this product can be
   *      over-deployed. Generally, true for ETLA, and false for others.
   * @param {String} options.product_arrangement.fulfillable_items an array of fulfillable items, containing
   *      id, code, label, type, fulfillment_configurable, delegation_configurable, delegation_type,
   *      charging_model.cap, charging_model.model, charging_model.unit, overdeployment_allowed.
   * @param {String} options.product_arrangement_version_id product arrangement version id
   * @param {String} options.sales_channel the sales channel; DIRECT, INDIRECT
   * @param {String} options.term the term; MONTHLY
   */
  constructor(options) {
    initModel(this, options);
    // used by add products modal, to track the count of licenses the customer wants to purchase
    this.numberSelected = 0;
  }

  /**
   * @description Check if this offer can have a Purchase Authorization created for it.
   *
   * @returns {Boolean} true if Purchase Authorizations can be made for this offer
   */
  canCreatePurchaseAuthorization() {
    // all these checks are negative, so we negate the whole block
    // eventually, we can switch to just the qualifier check
    return !(
      this.hasQualifier(QUALIFIER_TARGET.PREVENT_PA_CREATION) ||
      this.isLegacyDeviceLicense() ||
      (this.isTeamDirect() && this.hasOrgOnDemandConsumable())
    );
  }

  /**
   * @description Returns the offer's cloud.
   *
   * @returns {String} CLOUD.CREATIVE, CLOUD.DOCUMENT, CLOUD.EXPERIENCE, CLOUD.OTHER or undefined.
   */
  getCloud() {
    return this.product_arrangement?.cloud;
  }

  /**
   * @returns {String} Returns the offer's svg icon.
   */
  getIcon() {
    return this.merchandising?.assets?.icons?.svg;
  }

  /**
   * @returns {String} Returns the offer's language.
   */
  getLanguage() {
    return this.language;
  }

  /**
   * @returns {String} Returns the offer's merchandising name.
   */
  getName() {
    return this.merchandising?.copy?.name;
  }

  /**
   * @description Returns the charging model for the first QUOTA fi in the offer.
   *
   * @returns {Object} The charging model, or undefined if not found.
   */
  getQuotaChargingModel() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items;
    const quotaFi = fulfillableItems?.find((item) => item.type === FULFILLABLE_ITEM_TYPE.QUOTA);
    return quotaFi?.charging_model;
  }

  /**
   * @description Method to determine if this offer contains the ESM user storage FI.
   *
   * @returns {Boolean} True if the FI is present, false otherwise.
   */
  hasESMUserStorage() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items;
    return fulfillableItems.some((item) => item.code === FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE);
  }

  /**
   * @description Method to determine if this list contains an organization type quota on-demand consumable FI.
   *  Note this is the same code FulfillableItemList.hasOrgOnDemandConsumable except this uses snake-case for the FI
   *  properties.
   *
   * @returns {Boolean} True if the FI is present, false otherwise.
   */
  hasOrgOnDemandConsumable() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items;
    return fulfillableItems.some((item) => isOrgOnDemandConsumable(item));

    function isOrgOnDemandConsumable(item) {
      return (
        item.delegation_type === 'ORGANIZATION' &&
        item.type === FULFILLABLE_ITEM_TYPE.QUOTA &&
        item.charging_model?.model === 'ON_DEMAND'
      );
    }
  }

  /**
   * @description Helper to check if the offer has the target processing
   *     instructions set to true
   *
   * @param {string[]} processingInstructions - Array of target processing
   *     instructions.
   * @returns {boolean} True if ALL target processing instructions are
   *     present and set to true, false otherwise.
   */
  hasProcessingInstructionsEnabled(processingInstructions) {
    return processingInstructions.every(
      (instruction) =>
        get(this, `product_arrangement.processing_instructions.${instruction}`) === true
    );
  }

  /**
   * @description Helper to check if the offer has the target qualifier.
   * @param {Array} qualifiers - Target qualifiers to search for. Can optionally
   *  take a single string which wiill be cast to an Array.
   *
   * @returns {boolean} True if ANY of the target qualifiers is found, false otherwise.
   */
  hasQualifier(qualifiers) {
    const qualifiersArray = Array.isArray(qualifiers) ? qualifiers : [qualifiers];
    const targetQualifier = this.qualifiers?.find((qualifier) =>
      qualifiersArray.includes(qualifier.target)
    );
    return !!targetQualifier;
  }

  /**
   * @description Method to determine if an offer is a device offer.
   *
   * @returns {boolean} True if there is an FI that has delegation type of MACHINE, false
   *  otherwise.
   */
  isDeviceOffer() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items;
    return !!fulfillableItems?.some((fItem) => fItem.delegation_type === DELEGATION_TYPE.MACHINE);
  }

  /**
   * @description Helper to check whether the customer segment is ENTERPRISE.
   *
   * @returns {boolean} True if the customer_segment is ENTEPRIRSE, false otherwise.
   */
  isEnterpriseOffer() {
    return this.customer_segment === CUSTOMER_SEGMENT.ENTERPRISE;
  }

  /**
   * @description Helper to check if the offer has one of the product families which is being used to group offers.
   *  An org should 'own' no more than one offer in the family.
   *
   * @returns {Boolean} True if the offer is in a set of grouped offers, false otherwise.
   */
  isGroupedFamily() {
    return (
      this.product_arrangement?.family === PRODUCT_FAMILY.STOCK_SUBSCRIPTION_PLAN ||
      this.product_arrangement?.family === PRODUCT_FAMILY.STOCK_CREDIT_PACK
    );
  }

  /**
   * @description Checks to see if the offer is a device license offer
   *
   * @return {boolean} true if any fulfillable items are of delegation type MACHINE
   *    and none are the LABORATORY_LICENSE_MANAGEMENT FI
   */
  isLegacyDeviceLicense() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items;
    return (
      fulfillableItems &&
      fulfillableItems.some((item) => item.delegation_type === DELEGATION_TYPE.MACHINE) &&
      !fulfillableItems.some(
        (item) => item.code === FULFILLABLE_ITEM_CODE.LABORATORY_LICENSE_MANAGEMENT
      ) &&
      !fulfillableItems.some((item) => item.code === FULFILLABLE_ITEM_CODE.PACKAGE_PRECONDITIONING)
    );
  }

  /**
   * @description Checks if this offer is for a monthly term.
   *
   * @return {boolean} true if term is 'MONTHLY', false otherwise
   */
  isMonthlyOffer() {
    return this.term === 'MONTHLY';
  }

  /**
   * @description Helper to check whether the offer_type is PROMOTION.
   *
   * @returns {boolean} True if the offer_type is PROMOTION, false otherwise.
   */
  isOfferTypePromotion() {
    return this.offer_type === TYPE.PROMOTION;
  }

  /**
   * @description Checks to see if the fulfillable items are of delegation type ORGANIZATION, meaning they're
   *    not assigned to individual users. Fulfillable items that are of delegation type NA are ignored.
   *
   * @return {boolean} true if all fulfillable items are of delegation type ORGANIZATION, false if any are not
   */
  isOrganizationDelegationType() {
    const fulfillableItems = this.product_arrangement?.fulfillable_items?.filter(
      (item) => item.delegation_type !== 'NA'
    );
    return (
      fulfillableItems.length > 0 &&
      fulfillableItems.every((item) => item.delegation_type === 'ORGANIZATION')
    );
  }

  /**
   * @description Helper to check whether the price point is FREE.
   *
   * @returns {boolean} True if the price_point is FREE, false otherwise.
   */
  isPricePointFree() {
    return this.price_point === PRICE_POINT.FREE;
  }

  /**
   * @description Checks to see if the offer is a Spark EDU offer which has not already been provisioned.
   * @param {Array} productListItems array of the productList items
   * @param {Array} marketSubsegments set on the org, or undefined, if it hasn't been set
   *
   * @return {boolean} true if valid Spark EDU offer
   */
  isQualifyingSparkEduOffer(productListItems, marketSubsegments) {
    const qualifierTargets = marketSubsegments
      ? Offer.marketSubsegmentsToOfferQualifierTargets(marketSubsegments)
      : [QUALIFIER_TARGET.K12_EDU_ORG, QUALIFIER_TARGET.FURTHER_EDU_ORG];

    // a qualifying offer is free, has a supported qualifier target and hasn't already been provisioned.
    return !!(
      this.isPricePointFree() &&
      this.qualifiers?.some((qualifier) => qualifierTargets.includes(qualifier.target)) &&
      !productListItems.some(
        (item) => item.productArrangementCode === this.product_arrangement_code
      )
    );
  }

  /**
   * @description Determines if this is a team direct offer. Typically pricing is shown for these.
   *
   * @return {boolean} true if team direct offer, else false.
   */
  isTeamDirect() {
    return (
      this.customer_segment === CUSTOMER_SEGMENT.TEAM && this.sales_channel === SALES_CHANNEL.DIRECT
    );
  }

  /**
   * @description Helper to check whether the customer segment is TEAM.
   *
   * @returns {boolean} True if the customer_segment is TEAM, false otherwise.
   */
  isTeamOffer() {
    return this.customer_segment === CUSTOMER_SEGMENT.TEAM;
  }

  /**
   * @description Checks to see if the prices for this offer include tax by default.
   *
   * @return {boolean} true if it includes tax, false otherwise
   */
  priceIncludesTax() {
    // This is only used by Digital River offers
    const priceDetails = this.pricing?.prices[0]?.price_details;
    return !!(
      priceDetails?.display_rules?.tax === 'included' ||
      ((priceDetails?.unit?.amount_without_tax || 0) === 0 && priceDetails?.unit?.amount_with_tax)
    );
  }

  /**
   * @description Method to fetch an offer
   * @returns {Promise<Offer>} resolves to fetched Offer when successful, else rejects with error message
   */
  async refresh() {
    if (!this.offer_id) {
      return Promise.reject(new Error('offerId is required to fetch an offer'));
    }

    let response;
    try {
      response = await aosOffers.getOffersByOfferId({offerIds: this.offer_id});
    } catch (error) {
      log.error(`Offer.refresh() failed. Error: ${error}`);
      return Promise.reject(error.response);
    }

    Object.assign(this, response.data[0]);
    return this;
  }
}

/** Private Methods **/

function initModel(model, options) {
  Object.assign(
    model,
    pick(options, [
      'buying_program',
      'commitment',
      'compliance',
      'customer_segment',
      'customer_ui',
      'fig_ids',
      'language',
      'market_segments',
      'materials',
      'merchandising',
      'merchant',
      'metadata',
      'offer_id',
      'offer_type',
      'payment_structure',
      'platform',
      'platforms',
      'poresOrder',
      'price_point',
      'pricing',
      'product_arrangement_code',
      'product_arrangement',
      'product_arrangement_version_id',
      'product_code',
      'promotion',
      'qualifiers',
      'sales_channel',
      'term',
    ])
  );

  const fulfillableItems = transformFIsToProductFIs(model.product_arrangement?.fulfillable_items);
  Object.assign(model, {
    fulfillableItemList: new FulfillableItemList(fulfillableItems),
  });

  // convert currency attributes to camelCase
  // This is used by Digital River offers and by price.format.
  if (model.pricing?.currency) {
    model.pricing.currency = convertObjectKeysToCamelCase(model.pricing.currency);
  }
}

/**
 * @description Helper function to check whether the response Object is a PORES offer
 * Sample response for PORES: https://wiki.corp.adobe.com/display/BPS/M1%3A+Admin+Console+integration+with+PORES#M1:AdminConsoleintegrationwithPORES-ResponseObject
 * @param {Object} dataTransferObject the response Object from the api
 * @param {Object} [dataTransferObject.metadata] the metadata for the offer
 * @param {Offer} dataTransferObject.offer the Offer
 * @return {Boolean} return true if the dataTransferObject is a PORES offer
 */
function isPoresOffer(dataTransferObject) {
  // Since dataTransferObject.metadata is optional, we want to check whether the response Object has
  // a nested Offer object to determine whether the response Object is a PORES offer
  return !!dataTransferObject.offer;
}

function transformPoresOfferToOffer(poresOffer, idx) {
  const {metadata = {}, offer} = poresOffer;
  // Get the Offer object from the nested offer property, and set the metadata and poresOrder
  // as new properties of the Offer object.
  return {...offer, metadata, poresOrder: idx};
}

export default Offer;
/* eslint-enable max-lines -- file requires more lines */
