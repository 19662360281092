import {Divider, Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * InfoBar is a component to display InfoItem components, each seperated by a vertical Divider.
 */
const InfoBar = ({'data-testid': dataTestId = 'info-bar', children}) => {
  // Removes null/false children and assigns a key to each element.
  const infoItems = React.Children.toArray(children);

  // There is a divider after every item, except the last one.
  return (
    infoItems.length > 0 && (
      <Flex data-testid={dataTestId} gap="size-125" wrap>
        {infoItems.map((infoItem, index) => (
          <React.Fragment key={infoItem.key}>
            {/* Wrap in Flex so the InfoItem and the Divider wrap as a unit. */}
            <Flex direction="row" gap="size-125">
              <Text>{infoItem}</Text>
              {index < infoItems.length - 1 && (
                <Divider data-testid="item-divider" orientation="vertical" size="S" />
              )}
            </Flex>
          </React.Fragment>
        ))}
      </Flex>
    )
  );
};

InfoBar.propTypes = {
  /**
   * InfoItem and InfoItemScorecard elements to display.
   */
  children: PropTypes.node.isRequired,
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
};

export default InfoBar;
