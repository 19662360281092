(function () {
  /**
   * @deprecated Please use src2/core/models/pageContext/PageContextConstants.js
   */
  angular
    .module('binky.core.page-context')
    // eslint-disable-next-line angular/window-service
    .constant('PAGE_TARGET', window.binkySrc2.models.pageContext.PAGE_CONTEXT_CONSTANTS.PAGE_TARGET)
    .constant(
      'PAGE_TARGET_TYPE',
      // eslint-disable-next-line angular/window-service
      window.binkySrc2.models.pageContext.PAGE_CONTEXT_CONSTANTS.PAGE_TARGET_TYPE
    );
})();
