(function () {
  'use strict';
  /**
   * @deprecated Will not be required once all code moves to React
   *
   * @ngdoc component
   * @name binky.ui-migration:binkyReactContainer
   * @description AngularJS component which renders a React component with the
   *     specified props. When the props object reference changes, the React
   *     component is re-rendered. Similar to
   *     https://github.com/coatue-oss/react2angular, but with a simplified
   *     interface.
   *
   * @param {Function} component - the React component to render
   * @param {Boolean} [isNested] - true if the React component is not a top
   *     level page container and must inherit background color from current
   *     context in DOM hierarchy, else false/undefined for theme based
   *     background color
   * @param {Object.<string, Object>} props - props to pass to the React
   *     component
   */
  // eslint-disable-next-line angular/file-name
  angular.module('binky.ui-migration').component('binkyReactContainer', {
    bindings: {
      component: '<',
      isNested: '<?',
      props: '<',
    },
    controller,
  });

  /* @ngInject */
  function controller($element, _, binkyUISrc2) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
    });

    function $onChanges() {
      binkyUISrc2.core.services.reactContainerBridge.renderReactComponent(
        vm.component,
        vm.props,
        $element[0],
        vm.isNested
      );
    }

    function $onDestroy() {
      binkyUISrc2.core.services.reactContainerBridge.destroyReactComponent($element[0]);
    }
  }
})();
