(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.phone-number-input
   * @description module that provides a widget for entering phone numbers
   */
  angular.module('binky.widgets.common.phone-number-input', [
    'binky.core.common.country',
    'binky.core.lodash',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.wait',
    'pascalprecht.translate',
  ]);
})();
