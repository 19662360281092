import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';

import {PAGE_COMPONENTS} from '../PageConstants';

/**
 * Header component used for Page.
 */
const PageHeader = ({children, id, level = 1, title}) => {
  const isChildImageIcon = (child) =>
    // The Pandora ImageIcon does not have the element name set so
    // search for component using expected props.
    !!child?.props?.src;

  const isChildAvatar = (child) =>
    child?.type?.displayName === PAGE_COMPONENTS.AVATAR ||
    child?.type?.displayName === PAGE_COMPONENTS.USER_GROUP_AVATAR;

  const findAvatar = () => {
    const childElements = Array.isArray(children) ? children : [children];
    return childElements?.find((el) => isChildAvatar(el));
  };

  const findHeaderImageIcon = () => {
    const childElements = Array.isArray(children) ? children : [children];
    const imageIcon = childElements.find((el) => isChildImageIcon(el));
    return imageIcon && cloneElement(imageIcon, {size: 'XL'});
  };

  const findHeaderTagGroup = () => {
    if (children?.length > 1) {
      return children.find((el) => el?.type?.displayName === PAGE_COMPONENTS.TAG_GROUP);
    }

    const childName = children?.type?.displayName;

    return childName === PAGE_COMPONENTS.TAG_GROUP && children;
  };

  const findInfoButton = () => {
    const childElements = Array.isArray(children) ? children : [children];
    return childElements?.find(
      (child) => child?.type?.displayName === PAGE_COMPONENTS.USER_GROUP_SHARE_INFO
    );
  };

  const generateSizeStyle = (size) =>
    `var(--spectrum-global-dimension-size-${size}, var(--spectrum-alias-size-${size}))`;

  const avatar = findAvatar();
  const imageIcon = findHeaderImageIcon();
  const tagGroup = findHeaderTagGroup();
  const infoButton = findInfoButton();
  const headingStyleOverride = {lineHeight: generateSizeStyle(450)};

  return (
    <Flex alignItems="start" columnGap="size-200">
      {(imageIcon || avatar) && <View flex="none">{imageIcon || avatar}</View>}
      <Heading
        alignSelf="center"
        data-testid="page-header"
        flexGrow="1"
        flexShrink="1"
        id={id}
        level={level}
        margin="size-0"
        UNSAFE_style={headingStyleOverride}
      >
        <Text
          data-testid="page-header-title"
          UNSAFE_style={{
            fontSize: generateSizeStyle(350),
            fontWeight: 300,
          }}
        >
          {title}
        </Text>
        {infoButton && (
          <View
            marginStart="size-50"
            top="size-50"
            UNSAFE_style={{display: 'inline-block', position: 'relative'}}
          >
            {infoButton}
          </View>
        )}
        {tagGroup && (
          <View
            marginStart="size-100" // 8px, but tag has 4px margin, so actual spacing 12px
            top="calc(-1 * size-40)"
            UNSAFE_style={{display: 'inline-block', position: 'relative'}}
          >
            {tagGroup}
          </View>
        )}
      </Heading>
    </Flex>
  );
};

PageHeader.displayName = 'PageHeader';
PageHeader.propTypes = {
  /**
   * The Pandora ImageIcon or Avatar that is placed before the title
   * and/or the Spectrum v3 TagGroup with at least one Item to
   * append to the end of the page title. If both ImageIcon and Avatar
   * are passed, ImageIcon is prioritized.
   */
  children: PropTypes.node,
  /**
   * The id for the text content of this heading. Should be used in conjunction
   * with aria-labelledby to reference without repeating
   */
  id: PropTypes.string,
  /**
   * The heading level of the PageHeader. Defaults to 1.
   */
  level: PropTypes.number,
  /**
   * The title to be displayed in the PageHeader.
   */
  title: PropTypes.string.isRequired,
};

export default PageHeader;
