(function () {
  'use strict';
  /**
   * @deprecated - replaced by Pagination component - see TableStore story.
   */
  angular
    .module('binky.widgets.common.pagination-control-bar')
    .component('binkyPaginationControlBar', {
      bindings: {
        itemsPerPage: '=',
        listState: '=?',
        onGoToNextPage: '<?',
        onGoToPreviousPage: '<?',
        onPageChange: '<?',
        onPageSizeChange: '<?',
        pageSizeOptions: '<?',
        paginationId: '@',
        selectedItem: '<?',
      },
      controller,
      templateUrl: 'widgets/common/pagination-control-bar/pagination-control-bar.component.html',
    });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      disableLeftButton,
      disableRightButton,
      goToNextPage,
      goToPreviousPage,
      pageChange,
      pageSizeChange,
      pageSizeOptions: vm.pageSizeOptions || [10, 20, 50, 100],
    });

    function $onInit() {
      if (!_.includes(vm.pageSizeOptions, parseInt(vm.itemsPerPage, 10))) {
        throw new Error(`Incorrect page size of ${vm.itemsPerPage}`);
      }
    }

    function disableLeftButton(currentPage) {
      return vm.listState ? !vm.listState.hasPreviousPage() : currentPage === 1;
    }

    function disableRightButton(currentPage, lastPage) {
      return vm.listState ? !vm.listState.hasNextPage() : currentPage === lastPage;
    }

    function goToPreviousPage(currentPage, setCurrentFn) {
      if (vm.listState) {
        _.invoke(vm, 'onGoToPreviousPage');
      } else {
        pageChange(currentPage, setCurrentFn);
      }
    }

    function goToNextPage(currentPage, setCurrentFn) {
      if (vm.listState) {
        _.invoke(vm, 'onGoToNextPage');
      } else {
        pageChange(currentPage, setCurrentFn);
      }
    }

    function pageChange(currentPage, setCurrentFn) {
      // Page numbers stored in select are strings, so convert to integer first
      const parsedCurrentPage = parseInt(currentPage, 10);

      _.invoke({setCurrent: setCurrentFn}, 'setCurrent', parsedCurrentPage);
      _.invoke(vm, 'onPageChange', parsedCurrentPage);
    }

    function pageSizeChange(newSize) {
      if (vm.onPageSizeChange) {
        // Page sizes stored in select are strings, so convert to integer first
        vm.itemsPerPage = _.toInteger(newSize);
        vm.onPageSizeChange(vm.itemsPerPage);
      }
    }
  }
})();
