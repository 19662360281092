(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc provider
   * @name apiReady
   * @description defines apiReady provider to configure api endpoints
   */
  angular.module('binky.core.api.ready').provider('apiReady', apiReadyProvider);

  /* @ngInject */
  function apiReadyProvider(
    $httpProvider,
    $injector,
    $windowProvider,
    _,
    apiAntiCacheInterceptorProvider,
    apiHeaderInterceptorProvider,
    apiResponseInterceptorProvider,
    binkySrc2,
    configurationReadyProvider
  ) {
    const ngInjector = angular.injector(['ng']);
    const $q = ngInjector.get('$q');
    const configDeferred = $q.defer();

    this.$get = $get;
    this.configure = configure;
    this.whenConfigReady = whenConfigReady;

    /////////

    function configure() {
      $httpProvider.interceptors.push('apiAntiCacheInterceptor');
      $httpProvider.interceptors.push('apiHeaderInterceptor');
      $httpProvider.interceptors.push('apiResponseInterceptor');

      configurationReadyProvider
        .whenConfigReady()
        .then((configData) => {
          // First configure the interceptors, each service will add to this
          apiAntiCacheInterceptorProvider.configure({
            allowlist: getAntiCacheAllowlist(configData),
          });
          apiHeaderInterceptorProvider.configure({
            acceptLanguageHeaderAllowlist: getAcceptLanguageHeaderAllowlist(configData),
            apiKey: configData.services.ims.clientId,
            apiKeyAllowlist: getApiKeyAllowlist(configData),
            authorizationHeaderAllowlist: getAuthorizationHeaderAllowlist(configData),
            includeRoles: configData.includeRoles,
            includeRolesHeaderAllowlist: getIncludeRolesHeaderAllowlist(configData),
            jilFeatureHeaderAllowList: getJilFeatureHeaderAllowlist(configData),
          });
          apiResponseInterceptorProvider.configure({
            allowlist: getResponseTransformAllowlist(configData),
          });

          // Additional handlers are configured by configData
          const apiConfigs = _.get(configData, 'ready.api', []);
          _.forEach(apiConfigs, (apiConfig) => {
            const src2ProviderParent = apiConfig.providerPath
              ? _.get($windowProvider.$get(), apiConfig.providerPath)
              : binkySrc2.api;
            const src2Provider = _.get(src2ProviderParent, apiConfig.provider);
            let config = _.get(configData, apiConfig.configPath);
            config = checkOverride(config, configData);

            if (src2Provider) {
              src2Provider.configure(
                _.defaults(config, {
                  clientId: _.get(configData, 'services.ims.clientId'),
                  includeRoles: config.includeRolesHeader ? configData.includeRoles : undefined,
                })
              );
            }
            const providerName = `${apiConfig.provider}Provider`;
            if ($injector.has(providerName)) {
              $injector
                .get(providerName)
                .configure(config, _.get(configData, 'services.ims.clientId'));
            }
          });

          configDeferred.resolve();
        })
        .catch(configDeferred.reject);

      return configDeferred.promise;

      function checkOverride(config, configData) {
        if (config.overrideParam) {
          const overrideParamValue = getQueryParam(config.overrideParam);
          if (overrideParamValue) {
            return _.get(configData, `services.${overrideParamValue}`);
          }
        }
        return config;
      }

      // If key is 'a' and the query param is '?a=c&b=d', 'c' will be returned
      function getQueryParam(key) {
        const params = _.split($windowProvider.$get().location.search.substring(1), '&');
        const result = {};
        _.forEach(params, (param) => {
          const keyValue = _.split(param, '=');
          result[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1]);
        });
        return result[key];
      }
    }

    function whenConfigReady() {
      return configDeferred.promise;
    }

    function $get() {
      const service = {
        whenRunReady: whenConfigReady,
      };
      return service;
    }

    ////////

    function getAcceptLanguageHeaderAllowlist(config) {
      return _(config.services)
        .filter((flags) => _.get(flags, 'acceptLanguageHeader', true))
        .map('url')
        .uniq()
        .compact()
        .value();
    }

    function getAntiCacheAllowlist(config) {
      return _(config.services).filter({antiCache: true}).map('url').uniq().value();
    }

    function getApiKeyAllowlist(config) {
      return _(config.services).filter({apiKeyHeader: true}).map('url').uniq().value();
    }

    function getAuthorizationHeaderAllowlist(config) {
      return _(config.services).filter({authorizationHeader: true}).map('url').uniq().value();
    }

    function getIncludeRolesHeaderAllowlist(config) {
      return _(config.services)
        .filter((flags) => _.get(flags, 'includeRolesHeader', true))
        .map('url')
        .uniq()
        .compact()
        .value();
    }

    function getJilFeatureHeaderAllowlist(config) {
      return _(config.services).filter({jilFeatureHeader: true}).map('url').uniq().value();
    }

    function getResponseTransformAllowlist(config) {
      return _(config.services).filter({responseTransform: true}).map('url').uniq().value();
    }
  }
})();
