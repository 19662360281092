import {LIST_DEFAULT_PAGE_SIZE} from '../../services/modelList/ModelListConstants';

let paginationId = 0;

//  abstract class for a ModelList state.  This is a base class for the AlphaListState and HybridListState
class ListState {
  /**
   * @description Constructor for ListState model Objects. ListState supports list services that use other forms of paging than index paging.
   * @param {Object} [options] - options object
   * @param {String} [options.filterQuery] - query to filter results against. Defaults to ''.
   * @param {Array} [options.filterQueryKeys] - array of string keys to search over. Defaults to [].
   * @param {String} [options.filterQueryMinLength] - minimum query length. Defaults to 3.
   * @param {Number} [options.pageNumber] - the current page of the results to show. 1-based index. Defaults to 1.
   * @param {Number} [options.pageSize] - number of items to display per page. Defaults to LIST_DEFAULT_PAGE_SIZE.
   * @param {String} [options.sortKey] - sort key (e.g. name). Defaults to undefined.
   * @param {Boolean} [options.sortReverse] - whether or not the current sort is reversed. Defaults to false.
   */
  constructor(options) {
    paginationId += 1;

    Object.assign(this, {
      filterQuery: '',
      filterQueryKeys: [],
      filterQueryMinLength: 3,
      pageNumber: 1,
      pageSize: LIST_DEFAULT_PAGE_SIZE,
      paginationId: `paginationId${paginationId}`,
      sortKey: '',
      sortReverse: false,
      ...options, // override the defaults
    });
  }

  caseInsensitiveFilter(item) {
    return (
      !this.filterQuery || // Return all results when there is no query
      this.filterQueryKeys.some((key) => {
        const toLowerValue = item[key]?.toLowerCase();
        return !!toLowerValue?.includes(this.filterQuery.toLowerCase());
      })
    );
  }

  setFilterQuery(filterQuery) {
    this.filterQuery = filterQuery;
    this.setPageNumber(1);
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize;
    this.setPageNumber(1);
  }

  setSortKeyAndReverseIfSameKey(sortKey) {
    this.sortReverse = sortKey === this.sortKey ? !this.sortReverse : false;
    this.sortKey = sortKey;
  }

  toQueryParams() {
    throw new Error(`Not implemented: ${this.constructor.name}.toQueryParams()`);
  }
}

export default ListState;
