import {log} from '@admin-tribe/binky';
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useIntl} from 'react-intl';

import OverlayWait from 'common/components/wait/OverlayWait';
import Bumper from 'shell/bumper/Bumper';

// A Subpage is used to wrap content which can be bumpered if an error occurs.
// While the subpage is loading, the content is not shown.
// The bumper has defaults strings, which may be customized and a hard-coded button action which
// goes back to the previous page.
const Subpage = ({
  bumperCtaButtonLabel,
  bumperStrings,
  children,
  'data-testid': dataTestId,
  loadingMessage,
  isBumpered = false,
  isLoading = false,
}) => {
  const intl = useIntl();

  const [showContent, setShowContent] = useState(false);

  const strings = bumperStrings || {
    details: intl.formatMessage({id: 'binky.shell.workspace.subpage.bumper.details'}),
    title: intl.formatMessage({id: 'binky.shell.workspace.subpage.bumper.title'}),
  };

  // If there is not an error and the subpage isn't loading, show the content.
  useEffect(() => {
    setShowContent(!isBumpered && !isLoading);
  }, [isBumpered, isLoading]);

  // The bumper button goes to the previous page.
  const onButtonClick = () => {
    if (typeof window !== 'undefined') {
      window.history.back();
    }
  };

  const getBumper = () => (
    <Bumper
      ctaButtonLabel={
        bumperCtaButtonLabel ||
        intl.formatMessage({id: 'binky.shell.workspace.subpage.bumper.ctaButton'})
      }
      details={strings.details}
      onButtonClick={onButtonClick}
      title={strings.title}
    />
  );

  return isBumpered ? (
    getBumper()
  ) : (
    // Log any uncaught errors thrown under Subpage subtree and show bumper
    <ErrorBoundary
      fallbackRender={getBumper}
      onError={(error) => {
        if (dataTestId) {
          log.error(`Unexpected Subpage error for ${dataTestId}: ${error.message}`, error);
        } else {
          log.error(`Unexpected Subpage error: ${error.message}`, error);
        }
      }}
    >
      <OverlayWait
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        showContent={showContent}
        size="M"
      >
        <View data-testid={dataTestId}>{children}</View>
      </OverlayWait>
    </ErrorBoundary>
  );
};

Subpage.propTypes = {
  /**
   * Translated text of the Bumper's CTA button label. Defaults to `Go back`.
   * The button click goes to the previous page.
   */
  bumperCtaButtonLabel: PropTypes.string,
  /**
   * To customize the strings displayed by the bumper.
   */
  bumperStrings: PropTypes.shape({
    details: PropTypes.string,
    title: PropTypes.string,
  }),
  /**
   * The page sections to be displayed within the Subpage.
   */
  children: PropTypes.node,
  /**
   * The specified data-testid string. Also used to ID the subpage if
   * an error is caught by ErrorBoundary.
   */
  'data-testid': PropTypes.string,
  /**
   * If true the page bumper is show rather than the content.
   * Typically this is used when there is an API error.
   * Defaults to false.
   */
  isBumpered: PropTypes.bool,
  /**
   * If the page is still loading. Defaults to false.
   * If isBumpered is set the page will be bumpered regardless of the value of isLoading.
   */
  isLoading: PropTypes.bool,
  /**
   * An optional localized string to display below the loading spinner.
   */
  loadingMessage: PropTypes.string,
};

export default Subpage;
