const INVOICE_LIST_CACHE_ID = 'InvoiceList';
const INVOICE_SORT = {INVOICE_DATE: 'INVOICE_DATE'};
const INVOICE_LIST_EVENT = {UPDATE: 'UpdateInvoiceList'};
const INVOICE_STATUS = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  PAID: 'PAID',
  PAST_DUE: 'PAST_DUE',
  PAYMENT_DUE: 'PAYMENT_DUE',
  PROCESSING: 'PROCESSING',
};
const INVOICE_UNPAID_STATUSES = [INVOICE_STATUS.PAST_DUE, INVOICE_STATUS.PAYMENT_DUE];

export {
  INVOICE_LIST_CACHE_ID,
  INVOICE_LIST_EVENT,
  INVOICE_SORT,
  INVOICE_STATUS,
  INVOICE_UNPAID_STATUSES,
};
