(function () {
  /**
   * @deprecated React services do not use these
   *
   * @ngdoc factory
   * @name apiResponseInterceptor
   * @description Handles response transformations, specifically for detecting CloudFront errors
   */
  angular
    .module('binky.core.api.interceptor')
    .provider('apiResponseInterceptor', apiResponseInterceptor);

  function apiResponseInterceptor(_) {
    let allowlist = [];

    this.configure = configure;
    this.$get = $get;

    ///////////////

    /**
     * @description Configure the apiHeaderInterceptor
     * @param {Object} [config] - allowlist of api that require tranform checks
     * @param {Array} [config.allowlist] - allowlist of api that require tranform checks
     */
    function configure(config = {}) {
      allowlist = _.union(allowlist, config.allowlist);
    }

    /* @ngInject */
    function $get() {
      return {response: responseHandler};

      //////////////

      function responseHandler(response = {}) {
        // if one of the allowed APIs returns the default HTML, we know it should be a 403
        if (
          isAllowedApi(response.config) &&
          response.status === 200 &&
          response.headers('content-type') === 'text/html' &&
          _.isString(response.data) &&
          _.startsWith(response.data, '<!-- DEFAULT INDEX HTML -->')
        ) {
          response.status = 403;
          response.statusText = 'Forbidden';
          response.data = '';
        }
        return response;
      }
    }

    /**
     * @description Helper to determine if api request requires auth
     * @param {Object} [config] - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     * @returns {Boolean} true if request url contains prefix of any allowed api
     */
    function isAllowedApi(config = {}) {
      return _.some(allowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix));
    }
  }
})();
