const COUNTRY_LIST_CACHE_ID = 'CountryList';
// This mapping originally came from
// https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-common/src/main/java/com/adobe/jil/utils/ContryCodeLocaleMapper.java
const COUNTRY_TO_LOCALE_MAP = {
  ABW: 'nl-NL',
  AGO: 'pt-BR',
  AND: 'es-ES',
  ANT: 'nl-NL',
  ARG: 'es-ES',
  ATF: 'fr-FR',
  AUT: 'de-DE',
  BEL: 'nl-NL',
  BEN: 'fr-FR',
  BES: 'nl-NL',
  BFA: 'fr-FR',
  BLM: 'fr-FR',
  BOL: 'es-ES',
  BRA: 'pt-BR',
  CAF: 'fr-FR',
  CHE: 'de-DE',
  CHL: 'es-ES',
  CHN: 'zh-CN',
  CIV: 'fr-FR',
  CMR: 'fr-FR',
  COD: 'fr-FR',
  COG: 'fr-FR',
  COL: 'es-ES',
  COM: 'en-US',
  CPV: 'pt-BR',
  CRI: 'es-ES',
  CUB: 'es-ES',
  CUW: 'nl-NL',
  CZE: 'cs-CZ',
  DEU: 'de-DE',
  DJI: 'fr-FR',
  DNK: 'da-DK',
  DOM: 'es-ES',
  ECU: 'es-ES',
  ESP: 'es-ES',
  FIN: 'fi-FI',
  FRA: 'fr-FR',
  GAB: 'fr-FR',
  GIN: 'fr-FR',
  GLP: 'fr-FR',
  GNB: 'pt-BR',
  GNQ: 'es-ES',
  GTM: 'es-ES',
  GUF: 'fr-FR',
  HND: 'es-ES',
  HTI: 'fr-FR',
  ITA: 'it-IT',
  JPN: 'ja-JP',
  KOR: 'ko-KR',
  LIE: 'de-DE',
  MAF: 'fr-FR',
  MCO: 'fr-FR',
  MEX: 'es-ES',
  MLI: 'fr-FR',
  MOZ: 'pt-BR',
  MTQ: 'fr-FR',
  NCL: 'fr-FR',
  NER: 'fr-FR',
  NIC: 'es-ES',
  NLD: 'nl-NL',
  NOR: 'nb-NO',
  PER: 'es-ES',
  POL: 'pl-PL',
  PRK: 'ko-KR',
  PRT: 'pt-BR',
  PRY: 'es-ES',
  PYF: 'fr-FR',
  RUS: 'ru-RU',
  SEN: 'fr-FR',
  SLV: 'es-ES',
  SMR: 'it-IT',
  SPM: 'fr-FR',
  STP: 'pt-BR',
  SUR: 'nl-NL',
  SWE: 'sv-SE',
  TCD: 'fr-FR',
  TGO: 'fr-FR',
  TUR: 'tr-TR',
  TWN: 'zh-TW',
  URY: 'es-ES',
  VAT: 'it-IT',
  VEN: 'es-ES',
};
const DEFAULT_COUNTRY_LOCALE = 'en-US';
const REGION_LIST_CACHE_ID = 'RegionList';

export {COUNTRY_LIST_CACHE_ID, COUNTRY_TO_LOCALE_MAP, DEFAULT_COUNTRY_LOCALE, REGION_LIST_CACHE_ID};
