(function () {
  'use strict';
  /**
   * @deprecated not required with React
   */
  angular
    .module('binky.shell.panels.manager')
    .constant('PANEL_MANAGER', {
      DRAWER: {
        CLOSE: 'DRAWER.CLOSE',
        OPEN: 'DRAWER.OPEN',
      },
      MODAL: {
        CLOSE: 'MODAL.CLOSE',
        OPEN: 'MODAL.OPEN',
      },
      PULLDOWN: {
        CLOSE: 'PULLDOWN.CLOSE',
        OPEN: 'PULLDOWN.OPEN',
      },
      RAIL: {
        CLOSE: 'RAIL.CLOSE',
        OPEN: 'RAIL.OPEN',
      },
      TYPE: {
        DRAWER: 'PANEL.DRAWER',
        MODAL: 'PANEL.MODAL',
        PULLDOWN: 'PANEL.PULLDOWN',
        RAIL: 'PANEL.RAIL',
      },
    })
    .constant('PANEL_OPEN_FOCUS_DELAY', 150);
})();
