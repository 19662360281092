import jilOrganizationsProductsChange from 'api/jil/jilOrganizationsProductsChange';
import log from 'services/log';

const ACTIONS = {
  CANCEL: 'cancel',
  RETAIN: 'retain',
};

const RETENTION_INFORMATION = 'RETENTION_INFORMATION';

/**
 * Handles API calls and stores session information regarding licenses to cancel.
 */
class ProductsChange {
  /**
   * @description Factory method that creates a new session of product licenses to be canceled.
   *
   * @param {Object} options Required object that contains the following:
   * @param {String} options.contractId Required id of the contract.
   * @param {String} options.orgId Required id of the organization.
   * @param {String[]} [options.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [options.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @param {Object[]} options.cancellationItems Required array of objects that contain the following:
   * @param {String} cancellationItem.productId Product id of what to cancel.
   * @param {number} cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} [cancellationItem.users] Optional array of users who will lose access to that product.
   * @returns {Promise} A promise that resolves to an instance of the ProductChange class with id, validation token,
   * and cancellation context.
   */
  static get({cancellationItems, contractId, reasonCodes, reasonText, orgId} = {}) {
    const productChange = new ProductsChange({contractId, orgId});
    return productChange.create({cancellationItems, reasonCodes, reasonText});
  }

  /**
   * @description Instantiates cancellation session information.
   * @param {String} contractId Id of the contract.
   * @param {String} orgId Id of the organization.
   */
  constructor({contractId, orgId}) {
    this.contractId = contractId;
    this.orgId = orgId;
    this.submitted = false;
  }

  /**
   * @description Calls to commit changes made license/consumables owned by the org.
   *
   * @param {Object} context - Object that contains the following:
   * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @param {Object[]} [context.cancellationItems] Optional array of objects that contain the following:
   * @param {String} context.cancellationItem.productId Product id of what to cancel.
   * @param {number} context.cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} [context.cancellationItem.users] Optional array of users who will lose access to that product.
   * @param {String} [action] - The action query parameter used for order commitment. Can be either 'Cancel' or 'retain:<retentionId>'
   * @returns {Promise} Resolves updated ProductsChange class object upon successful api call. Rejects to the api call error.
   */
  async commit(context, action) {
    let response;

    try {
      response = await jilOrganizationsProductsChange.putChangeRequest({
        changeId: this.changeId,
        context: {...context, contractId: this.contractId},
        orgId: this.orgId,
        params: {action},
        validationToken: this.validationToken,
      });
    } catch (error) {
      log.error(
        `ProductChange.commit(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      throw error;
    }

    this.response = transformCancellationResponse(response.data);
    this.submitted = true;

    return this;
  }

  /**
   * @description Calls to commit cancellations to license/consumables owned by the org.
   *
   * @param {Object} context - Object that contains the following:
   * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @param {Object[]} [context.cancellationItems] Optional array of objects that contain the following:
   * @param {String} context.cancellationItem.productId Product id of what to cancel.
   * @param {number} context.cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} [context.cancellationItem.users] Optional array of users who will lose access to that product.
   * @returns {Promise} Resolves updated ProductsChange class object upon successful api call. Rejects to the api call error.
   */
  commitCancellation(context) {
    if (!this.changeId) {
      throw new Error('ProductChange: no order id for cancellation commitment');
    }

    return this.commit(context, ACTIONS.CANCEL);
  }

  /**
   * @description Calls to commit changes made license/consumables owned by the org.
   *
   * @param {Object} context - Object that contains the following:
   * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @param {Object[]} [context.cancellationItems] Optional array of objects that contain the following:
   * @param {String} context.cancellationItem.productId Product id of what to cancel.
   * @param {number} context.cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} [context.cancellationItem.users] Optional array of users who will lose access to that product.
   * @param {String} [retentionId] - The id of the retention offer. If present, will include the retention id in the action query
   *            param, otherwise the query param will be 'cancel'
   * @returns {Promise} Resolves updated ProductsChange class object upon successful api call. Rejects to the api call error.
   */
  commitRetention(context, retentionId) {
    if (!retentionId) {
      throw new Error('ProductChange: no id for retention');
    }
    if (!this.changeId) {
      throw new Error('ProductChange: no order id for retention commitment');
    }

    return this.commit(context, `${ACTIONS.RETAIN}:${retentionId}`);
  }

  /**
   * @description Creates the change request entity and gets the initial quote for the cancellation context.
   *
   * @param {Object} context - Object that contains the following:
   * @param {Object[]} context.cancellationItems Array of objects that contain the following:
   * @param {String} cancellationItem.productId Product id of what to cancel.
   * @param {number} cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} cancellationItem.users Optional array of users who will lose access to that product.
   * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @returns {Promise} resolves to the updated productChange instance if successfully creates the change request,
   * else rejects with error message
   */
  async create(context) {
    if (this.changeId) {
      throw new Error('ProductChange: Cannot call create more than once');
    }

    let response;
    try {
      response = await jilOrganizationsProductsChange.postChangeRequest({
        context: {
          ...context,
          contractId: this.contractId,
        },
        orgId: this.orgId,
      });
    } catch (error) {
      log.error(
        `ProductChange.create(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    const {data} = response;
    this.changeId = data.id;
    this.validationToken = data.validationToken;
    this.response = transformCancellationResponse(data);

    return this;
  }

  /**
   * @description Updates the change request entity and gets an updated quote for the cancellation context.
   *
   * @param {Object} context - Object that contains the following:
   * @param {String[]} [context.reasonCodes] Optional list of reason codes of why the admin is cancelling licenses.
   * @param {String} [context.reasonText] Optional text reason of why the admin is cancelling licenses.
   * @param {Object[]} [context.cancellationItems] Optional array of objects that contain the following:
   * @param {String} cancellationItem.productId Product id of what to cancel.
   * @param {number} cancellationItem.quantity Number of licenses associated with that product to cancel.
   * @param {String[]} cancellationItem.users Optional array of users who will lose access to that product.
   * @param {Object} [options] - Optional configuration for the request, as follows:
   * @param {Boolean} options.includeRetention Whether to request retention information. Defaults to
   * false, which won't include it.
   * @returns {Promise} resolves to the updated productChange instance if successfully creates the change request,
   * else rejects with error message
   */
  async update(context, {includeRetention} = {}) {
    let response;

    const params = includeRetention ? {include: RETENTION_INFORMATION} : undefined;
    try {
      response = await jilOrganizationsProductsChange.putChangeRequest({
        changeId: this.changeId,
        context: {...context, contractId: this.contractId},
        orgId: this.orgId,
        params,
        validationToken: this.validationToken,
      });
    } catch (error) {
      log.error(
        `ProductChange.update(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    const {data} = response;
    this.validationToken = data.validationToken;
    this.response = transformCancellationResponse(data);

    return this;
  }
}

/**
 * @description Helper function to transform Products:Change response to consolidate and summarize
 * the quantities in contractData.contractItems and cancellingItems array of items, to only have
 * one per offerId.
 * @param {Object} response - Products:Change response
 * @returns {Object} The transformed Products:Change response with consolidated items per product
 */
function transformCancellationResponse(response) {
  const cancellation = response?.cancellation;
  const contractData = cancellation?.contractData;
  const contractItems = contractData?.contractItems;
  const cancellingItems = cancellation?.cancellingItems;
  const retentions = response?.retention;

  const transformedResponse = {
    ...response,
    cancellation: {
      ...cancellation,
      cancellingItems: cancellingItems && transformCancellingItems(cancellingItems),
      contractData: {
        ...contractData,
        contractItems: contractItems && transformContractItems(contractItems),
      },
    },
  };

  if (retentions?.length > 0) {
    transformedResponse.retention = retentions?.map((retention) => {
      const contractDetails = retention?.contractDetails;
      const retentioncontractData = contractDetails?.contractData;
      const retentionContractItems = retentioncontractData?.contractItems;
      return {
        ...retention,
        contractDetails: {
          ...contractDetails,
          contractData: {
            ...retentioncontractData,
            contractItems: retentionContractItems && transformContractItems(retentionContractItems),
          },
        },
      };
    });
  }

  return transformedResponse;
}

/**
 * @description Helper function to transform Products:Change ContractItems to consolidate and
 * summarize the quantities in the array, to only have one item per offerId.
 * @param {Object} originalContractItems - Products:Change ContractItems
 * @returns {Object} The transformed Products:Change ContractItems with consolidated items
 */
function transformContractItems(originalContractItems) {
  const contractItemsPerOfferId = originalContractItems?.reduce((memo, item) => {
    const offerId = item?.offerId;
    if (!offerId) {
      return memo;
    }
    const consolidatedItem = memo[offerId];
    if (consolidatedItem) {
      return {
        ...memo,
        [offerId]: {
          ...consolidatedItem,
          newQuantity: Number.isInteger(consolidatedItem.newQuantity)
            ? consolidatedItem.newQuantity + item.newQuantity
            : undefined,
          totalQuantity: consolidatedItem.totalQuantity + item.totalQuantity,
        },
      };
    }
    return {
      ...memo,
      [offerId]: {
        ...item,
        existingPrices: {
          ...item?.existingPrices,
          totalTax: undefined,
          totalWithoutTax: undefined,
          totalWithTax: undefined,
        },
        newPrices: {
          ...item?.newPrices,
          totalTax: undefined,
          totalWithoutTax: undefined,
          totalWithTax: undefined,
        },
      },
    };
  }, {});

  return contractItemsPerOfferId && Object.values(contractItemsPerOfferId);
}

/**
 * @description Helper function to transform Products:Change CancellingItems to consolidate and
 * summarize the quantities in the array, to only have one item per offerId.
 * @param {Object} originalCancellingItems - Products:Change CancellingItems
 * @returns {Object} The transformed Products:Change CancellingItems with consolidated items
 */
function transformCancellingItems(originalCancellingItems) {
  const cancellingItemsPerOfferId = originalCancellingItems?.reduce((memo, item) => {
    const offerId = item?.offerId;
    if (!offerId) {
      return memo;
    }
    const consolidatedItem = memo[offerId];
    if (consolidatedItem) {
      return {
        ...memo,
        [offerId]: {
          ...consolidatedItem,
          quantity: consolidatedItem.quantity + item.quantity,
        },
      };
    }
    return {
      ...memo,
      [offerId]: {
        ...item,
        cancellingPrices: {
          ...item?.cancellingPrices,
          totalTax: undefined,
          totalWithoutTax: undefined,
          totalWithTax: undefined,
        },
      },
    };
  }, {});

  return cancellingItemsPerOfferId && Object.values(cancellingItemsPerOfferId);
}

export default ProductsChange;
