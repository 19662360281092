(function () {
  /**
   * @deprecated Please use src2/core/model/migration/migration.js
   *
   * @ngdoc factory
   * @name Migration
   * @description Model for an organization migration; can be used directly or subclassed for
   *   specific types of migration (e.g. T2EMigration) that have additional fields.
   */
  angular.module('binky.core.migration').factory('Migration', factory);

  /* @ngInject */
  function factory(binkySrc2) {
    return binkySrc2.models.migration.Migration;
  }
})();
