const PageBannerConstants = {
  variant: {
    ERROR: 'error',
    HELP: 'help',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
  },
};

export default PageBannerConstants;
