(function () {
  /**
   * @awaitingfordeprecation
   *
   * @ngdoc factory
   * @name binky.core.offers:PoresOfferList
   * @description fetch the list of offers
   */
  angular.module('binky.core.offers').factory('PoresOfferList', poresOfferList);

  /* @ngInject */
  function poresOfferList(
    _,
    binkySrc2,
    feature,
    List,
    Offer,
    OFFER_LIST_SERVICE_PROVIDER,
    poresOrganizationsOffers
  ) {
    class PoresOfferList extends List {
      /**
       * @description instantiate the list of Offers for an Organization.
       *
       * @param {Object} options - Initialization Object (params described below)
       * @param {string} [options.contractId] - Associates OfferList instance with a
       *     contract.
       * @param {string} [options.orgId] - Associates OfferList instance with an org.
       * @param {Object} [options.queryParams] - queryParams to pass along the
       *     offers API call - for example "price_point".
       */
      constructor(options = {}) {
        super({
          // we don't cache this in the List because we always want to fetch the latest data
          itemClassRef: Offer,
          resource: getResource(),
        });
        this.$resolved = false;
        this.items = [];
        this.queryParams = options.queryParams;
        this.offerIds = options.offerIds;
        this.orgId = options.orgId;
        this.contractId = options.contractId;
      }

      /**
       * @description Method to filter the qualifying offers to only those which can have a Purchase
       *   Authorization created for them.
       *   This is a destructive change to the object.
       *  Note :- being used by sortOffersByFunctionOutput when service_providers are passed
       */
      filterOnCanCreatePurchaseAuthorization() {
        this.items =
          binkySrc2.services.offers.OfferListUtils.filterOnCanCreatePurchaseAuthorizationUtil(this);
      }

      /**
       * @description Method to filter the qualifying free offers for the org
       *   marketSubsegments.
       *   Note: assumes this is a free offer list - 'queryParams: {price_point: 'FREE'}}'
       *   It is also possible that there is not an offer for every
       *   marketSubsegment, or in the future, there may be more than one offer per
       *   marketSubsegment.
       *   This is a destructive change to the object.
       * @param {Object} context - Context provided to filter
       * @param {Organization} [context.organization] - The organization to compare
       *     with for offer qualification.
       * @param {ProductList} [context.ownedProducts] - The current product list to
       *     discard offers that have already been provisioned.
       *
       * Note :- being used by sortOffersByFunctionOutput when service_providers are passed
       */
      filterOnQualifyingFreeOffers(context = {}) {
        this.items = binkySrc2.services.offers.OfferListUtils.filterOnQualifyingFreeOffersUtil(
          context,
          this
        );
      }

      /**
       * @description Get the offers for the provided customer segment.
       *
       * @param {String} segment segment to filter offers by. e.g. TEAM, ENTERPRISE
       * @returns {Offer[]} list of offers for the provided segment
       *
       * Note :- being used by sortOffersByFunctionOutput when service_providers are passed
       */

      getOffersForSegment(segment) {
        return binkySrc2.services.offers.OfferListUtils.getOffersForSegment(this, segment);
      }

      /**
       * @description Method to refresh the Offer List.
       *
       * @return {Promise} resolved when Offer list fetch completes.
       */
      refresh() {
        const queryParams = getQueryParams(this);
        this.$promise = super.refresh(queryParams).then(onRefreshSuccess.bind(this));
        return this.$promise;

        function getFilterSpecificQueryParams(model) {
          const defaultServiceProvidersString = getDefaultServiceProvidersString();
          let queryProvidersFromFilter;
          if (model.queryParams && model.queryParams.service_providers) {
            queryProvidersFromFilter = _.isArray(model.queryParams.service_providers)
              ? model.queryParams.service_providers.join(',')
              : model.queryParams.service_providers;
            model.queryParams.service_providers = queryProvidersFromFilter;
          }
          const poresServiceProviders = queryProvidersFromFilter || defaultServiceProvidersString;
          return {
            contractId: model.contractId,
            orgId: model.orgId,
            service_providers: poresServiceProviders,
          };
        }

        function getQueryParams(model) {
          const defaultQueryParams = {};

          const filterSpecificQueryParams = getFilterSpecificQueryParams(model);

          const allQueryParams = _.assign(
            defaultQueryParams,
            filterSpecificQueryParams,
            model.queryParams
          );
          _(allQueryParams)
            .keys()
            .forEach((key) => {
              if (_.isUndefined(allQueryParams[key])) {
                delete allQueryParams[key];
              }
            });
          return allQueryParams;
        }

        function onRefreshSuccess() {
          this.$resolved = true;

          this.items = _.reject(this.items, (offer) =>
            feature.isEnabled(`filter_offer_${offer.offer_id}`)
          );
          this.items = binkySrc2.services.offers.OfferListUtils.sortOffersByFunctionOutput(
            this.items,
            binkySrc2.services.offers.OfferListUtils.getPoresOrder
          );

          return this;
        }
      }

      /**
       * @description instantiates a new instance of a PoresOfferList. We don't cache
       *     this in the List because we always want to fetch the latest data.
       *
       * @param {Object} options - Initialization Object (params described below)
       * @param {Object} [options.queryParams] - queryParams to pass to the offers
       *     API call.
       * @returns {PoresOfferList} - PoresOfferList model object.
       */
      static get(options = {}) {
        const model = new PoresOfferList(options);
        model.refresh();
        return model;
      }
    }

    return PoresOfferList;

    function getResource() {
      return poresOrganizationsOffers.getOffers;
    }

    function getDefaultServiceProvidersString() {
      return _.values(OFFER_LIST_SERVICE_PROVIDER).join(',');
    }
  }
})();
