const ADOBE_AGENT = {
  ADMIN: 'ADOBE_AGENT_ADMIN',
  CUSTOMER_CARE: 'ADOBE_AGENT_CUSTOMER_CARE',
  ORG_ID: '015858B65073A8210A490D25@AdobeOrg',
  PROFESSIONAL_SERVICES: 'ADOBE_AGENT_PROFESSIONAL_SERVICES',
  PROVISIONER: 'ADOBE_AGENT_PROVISIONER',
  READ: 'ADOBE_AGENT_READ',
  RESELLER_LICENSING: 'ADOBE_AGENT_RESELLER_LICENSING',
};
const COMPARTMENT_ADMIN = 'COMPARTMENT_ADMIN';
const COMPARTMENT_VIEWER = 'COMPARTMENT_VIEWER';
const CONTRACT_ADMIN = 'CONTRACT_ADMIN';
const DEPLOYMENT_ADMIN = 'DEPLOYMENT_ADMIN';
const LICENSE_ADMIN = 'LICENSE_ADMIN';
const LICENSE_DEV_ADMIN = 'LICENSE_DEV_ADMIN';
const ORG_ADMIN = 'ORG_ADMIN';
const PRODUCT_ADMIN = 'PRODUCT_ADMIN';
const PRODUCT_SUPPORT_ADMIN = 'PRODUCT_SUPPORT_ADMIN';
const STORAGE_ADMIN = 'STORAGE_ADMIN';
const SUPPORT_ADMIN = 'SUPPORT_ADMIN';
// Used in AdminRolePicker when both support and product support admin are available
const SUPPORT_ADMINS = 'SUPPORT_ADMINS';
const USER_GROUP_ADMIN = 'USER_GROUP_ADMIN';

export {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
};
