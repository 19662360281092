(function () {
  /**
   * @deprecated Please use FulfillmentEventRefreshManager
   *
   * @ngdoc service
   * @name fulfillmentEventRefreshManager
   * @description fulfillment event refresh manager factory
   */
  angular
    .module('binky.core.fulfillment-events.refresh-manager')
    .factory('fulfillmentEventRefreshManager', fulfillmentEventRefreshManager);
  /* @ngInject */
  function fulfillmentEventRefreshManager(
    $interval,
    $log,
    $rootScope,
    _,
    FULFILLMENT_EVENT_LIST_CONTEXT,
    FULFILLMENT_EVENT_REFRESH_MANAGER_EVENT,
    FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS,
    FULFILLMENT_EVENT_STATUS,
    FULFILLMENT_REQUEST_TYPE,
    FulfillmentEventList
  ) {
    let EXTENDED_INTERVAL_PROMISE,
      POLLING_PROMISE,
      currentOrgId,
      inProgressFulfillmentEventList,
      modeSetting,
      refreshCount;
    let banners = {};

    const service = {
      addTempFulfillmentEventAndStartExtendedPoll,
      getBanners,
      getOriginalFulfillmentIdsOfReturns,
      hasInProgressFulfillmentEventWithOfferId,
      isPollingForOrg,
      startPolling,
      stopPolling,
    };

    return service;

    //////////////

    /**
     * @description Method to add a temporary fulfillment event to this manager's fulfillment event
     *  list items so the rest of the application can be notified about the in progress fulfillment
     *  event that does not exist yet. This method will set an extended timeout and afterwards will
     *  resume polling. Successive calls to this method should reset the interval.
     *
     * @param {Object} options - Wrapper object for the params of this method.
     * @param {FulfillmentEvent} options.fulfillmentEvent - Fulfillment event to add, NOTE: The
     *  event is expected to have it's offers property set, and needs to have an IN_PROGRESS status.
     * @param {string} options.orgId - Organization id that will be used to determine if we were
     *  already polling.
     */
    function addTempFulfillmentEventAndStartExtendedPoll(options) {
      const {fulfillmentEvent, orgId} = options;
      if (isPollingForOrg(orgId)) {
        stopPolling();
      }
      inProgressFulfillmentEventList.items.push(fulfillmentEvent);
      updateStateAndNotifyChanges(inProgressFulfillmentEventList);
      // On successive POSTs or PATCHes we want to reset the timer so that we
      // don't experience data loss.
      if (!_.isNil(EXTENDED_INTERVAL_PROMISE)) {
        $interval.cancel(EXTENDED_INTERVAL_PROMISE);
      }
      EXTENDED_INTERVAL_PROMISE = $interval(() => {
        // We only want this interval to run once then we'll cancel it and resume standard polling.
        // This was written with $interval because $timeout does not play nicely with protractor.
        $interval.cancel(EXTENDED_INTERVAL_PROMISE);
        EXTENDED_INTERVAL_PROMISE = undefined;
        startPolling(orgId);
      }, FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.EXTENDED_INTERVAL);
    }

    /**
     * @description Method to get the currently set banners based on the most
     *  recently resolved fulfillment event list.
     *
     * @returns {Object} Banners based on previous refresh and banner state.
     */
    function getBanners() {
      return banners;
    }

    /**
     * @description Method to get the original fulfillment ids of return orders that are in progress.
     *  Note: It is assumed that callers of this method will only be calling this method in response
     *  to a UPDATE_BANNERS event indicating that the inProgressFulfillmentEventList will be defined
     *  and the manager is polling.
     * @returns {string[]} Array of fulfillment ids for the return orders.
     */
    function getOriginalFulfillmentIdsOfReturns() {
      return _(inProgressFulfillmentEventList.items)
        .filter(['requestType', FULFILLMENT_REQUEST_TYPE.RETURN])
        .map('originalFulfillmentId')
        .compact()
        .uniq()
        .value();
    }

    /**
     * @description Method to check if there are in progress fulfillment events
     *  for a given offerId.
     *
     * @param {string} offerId - Offer id to compare against.
     * @returns {boolean} True if there is a fulfillment event that matches,
     *  false otherwise.
     */
    function hasInProgressFulfillmentEventWithOfferId(offerId) {
      const offersWithInProgressFulfillmentEvents = _.get(
        getBanners(),
        'offersThatHaveInProgressFulfillmentEvents'
      );
      return _.includes(offersWithInProgressFulfillmentEvents, offerId);
    }

    /**
     * @description Method to check if we are already polling for a particular
     *  organization.
     *
     * @param {string} orgId - Org id we want to compare to this manager's stored
     *  value.
     * @returns {boolean} True if a polling promise was defined and the stored
     *  organization id matches the provided param value.
     */
    function isPollingForOrg(orgId) {
      return !_.isNil(POLLING_PROMISE) && _.isEqual(orgId, currentOrgId);
    }

    /**
     * @description Method to begin polling for a particular organization.
     *
     * @param {string} orgId - Organization id that will be used to
     *  determine whether this manager should stop polling on one organization
     *  and start polling on another, or if nothing should be done.
     * @param {string} [mode] - Mode that the polling should use, defaults to mode where it
     *  will refresh MAX_REFRESH_ATTEMPTS times and then broadcast an event so the UI can prompt
     *  the user to resume polling.
     */
    function startPolling(orgId, mode = FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.DEFAULT) {
      if (!_.isNil(POLLING_PROMISE) && currentOrgId !== orgId) {
        stopPolling();
      }

      modeSetting = mode;

      if (modeSetting === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.EXPONENTIAL_BACKOFF) {
        executeExponentialBackoffPolling(orgId);
      } else {
        executeDefaultPolling(orgId);
      }
    }

    /**
     * @description Method to stop polling on a particular organization.
     */
    function stopPolling() {
      if (!_.isNil(POLLING_PROMISE)) {
        $interval.cancel(POLLING_PROMISE);
        POLLING_PROMISE = undefined;
      }
      if (!_.isNil(EXTENDED_INTERVAL_PROMISE)) {
        $interval.cancel(EXTENDED_INTERVAL_PROMISE);
        EXTENDED_INTERVAL_PROMISE = undefined;
      }
      modeSetting = undefined;
      refreshCount = 0;
    }

    //////////////

    function broadcastBanners() {
      $rootScope.$broadcast(FULFILLMENT_EVENT_REFRESH_MANAGER_EVENT.UPDATE, banners);
    }

    function executeDefaultPolling(orgId) {
      if (_.isNil(POLLING_PROMISE) && _.isNil(EXTENDED_INTERVAL_PROMISE)) {
        // In the event we navigate to the same org we need to make sure that the
        // EXTENDED_INTERVAL_PROMISE is not set because if it is we want that interval to complete
        // before we start polling.
        currentOrgId = orgId;
        refreshCount = 1;
        FulfillmentEventList.get({
          includeTranslatedEvent: false,
          listContext: FULFILLMENT_EVENT_LIST_CONTEXT.IN_PROGRESS,
          orgId: currentOrgId,
          status: FULFILLMENT_EVENT_STATUS.IN_PROGRESS,
          useCache: false,
        })
          .$promise.then((list) => {
            inProgressFulfillmentEventList = list;
            updateStateAndNotifyChanges(inProgressFulfillmentEventList);
            POLLING_PROMISE = $interval(
              refreshInProgressFulfillmentEvents,
              FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL
            );
          })
          .catch((error) => {
            $log.error('An error occurred fetching the in progress fulfillment events', error);
          });
      }
    }

    function executeExponentialBackoffPolling(orgId) {
      if (_.isNil(POLLING_PROMISE)) {
        currentOrgId = orgId;
        FulfillmentEventList.get({
          includeTranslatedEvent: false,
          listContext: FULFILLMENT_EVENT_LIST_CONTEXT.IN_PROGRESS,
          orgId: currentOrgId,
          status: FULFILLMENT_EVENT_STATUS.IN_PROGRESS,
          useCache: false,
        })
          .$promise.then((list) => {
            inProgressFulfillmentEventList = list;
            POLLING_PROMISE = getSelfTerminatingInterval(0);
          })
          .catch((error) => {
            $log.error('An error occurred fetching the in progress fulfillment events', error);
          });
      }
    }

    function getIntervalDuration(count) {
      return _.clamp(
        Math.pow(2, count) * FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL,
        FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.INTERVAL,
        FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MAX_EXPONENTIAL_BACKOFF
      );
    }

    function getSelfTerminatingInterval(count) {
      return $interval(() => {
        $interval.cancel(POLLING_PROMISE);
        POLLING_PROMISE = undefined;
        refreshInProgressFulfillmentEvents();
        POLLING_PROMISE = getSelfTerminatingInterval(count + 1);
      }, getIntervalDuration(count));
    }

    function refreshInProgressFulfillmentEvents() {
      inProgressFulfillmentEventList
        .refresh()
        .then(updateStateAndNotifyChanges)
        .catch((error) => {
          $log.error('An error occurred refreshing the in progress fulfillment events', error);
        });
    }

    function updateStateAndNotifyChanges(list) {
      const currentValues = {
        contractsThatHaveInProgressFulfillmentEvents: list.getContractsWithFulfillmentEvents(),
        offersThatHaveInProgressFulfillmentEvents: list.getOffersWithFulfillmentEvents(),
        showFulfillmentsNoLongerPendingBanner: !!(
          banners.showInProgressFulfillmentEventsPresentBanner &&
          !list.hasInProgressFulfillmentEvents()
        ),
        showInProgressFulfillmentEventsPresentBanner: list.hasInProgressFulfillmentEvents(),
      };

      if (modeSetting === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.DEFAULT) {
        _.assign(currentValues, {
          showAreYouStillThereBanner:
            refreshCount === FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MAX_REFRESH_ATTEMPTS,
        });
        if (currentValues.showAreYouStillThereBanner) {
          stopPolling();
        }
        refreshCount += 1;
      }

      if (!_.isEqual(currentValues, banners)) {
        banners = currentValues;
        broadcastBanners();
      }
    }
  }
})();
