const PAGE_TARGET = {
  ACCESS_MANAGEMENT: 'AccessManagement',
  DIRECTORY: 'Directory',
  ORGANIZATION: 'Organization',
  PRODUCT: 'Product',
  PRODUCT_CONFIGURATION: 'ProductConfiguration',
  STORAGE: 'Storage',
  USER_GROUP: 'UserGroup',
};
const PAGE_TARGET_TYPE = {
  ADMIN: 'ADMIN',
  DEVELOPER: 'DEVELOPER',
  DEVICES: 'DEVICES',
  DEVICES_PAYMENT_NEEDED: 'DEVICES_PAYMENT_NEEDED',
  INTEGRATION: 'INTEGRATION',
  PRODUCT_CONFIGURATION: 'PRODUCT_CONFIGURATION',
  USER: 'USER',
  USER_FOLDER: 'USER_FOLDER',
  USER_GROUP: 'USER_GROUP',
};

export {PAGE_TARGET, PAGE_TARGET_TYPE};
