import pick from 'lodash/pick';

class Country {
  /**
   * @class
   * @description Creates a new Country for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.countryCode A Country's code
   * @param {String} options.countryCodeISO3 A Country's code in ISO3 format
   * @param {String} options.countryName A Country's name
   */
  constructor(options) {
    Object.assign(this, pick(options, ['countryCode', 'countryCodeISO3', 'countryName']));
  }
}

export default Country;
