(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.directories')
    .constant('DIRECTORY_SAML_CONFIG_CACHE_ID', 'DirectorySamlConfig')
    .constant('DIRECTORY_SAML_CONFIG_EVENT', {
      UPDATE: 'UpdateDirectorySamlConfig',
    });
})();
