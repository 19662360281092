(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum components
   *
   * @ngdoc component
   * @name binky.widgets.common.popover:binkyPopover
   * @description Popover element to display content in a smaller area than a
   *   traditional modal.
   *
   * @binding {string} placement - Where the popover should be placed relative
   *   to the opener.
   * @binding {string} popoverId - Id of the popover.
   * @binding {Boolean} [showCloseIcon] - true to show close button in
   *   popover, else false or undefined (default is to hide)
   * @binding {Boolean} [showOnTargetHover] - true if popover should show when
   *   user hovers over target element or popover element (default is not to
   *   show popover on hover)
   * @param {Transclude} content The popover content
   * @param {Transclude} target The target element
   */
  angular.module('binky.widgets.common.popover').component('binkyPopover', {
    bindings: {
      placement: '@',
      popoverId: '@',
      showCloseIcon: '<?',
      showOnTargetHover: '<?',
    },
    controller,
    templateUrl: 'widgets/common/popover/popover.component.html',
    transclude: {
      content: 'content',
      target: 'target',
    },
  });

  /* @ngInject */
  function controller($element, $timeout, _) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      onKeydown,
      onKeyup,
      onPopoverHover,
      onTargetHover,
      targetId: _.uniqueId('popover-target-'),
    });

    function $onInit() {
      // Wait until the Coral popover element is constructed so we can find it to attach the optional escape key handler.
      Coral.commons.ready(() => {
        setTarget();
        appendTranscludedElements();
      });
    }

    /**
     * @description Method to handle keyboard interaction with badge popover
     * @param {Event} event - jqLite event that generated the keydown
     */
    function onKeydown(event) {
      // allow tab key events to propogate, else focus will be trapped
      if (event.keyCode !== 9) {
        event.preventDefault();
        event.stopPropagation();
      }

      // only call onClick handler if enter or spacebar is clicked
      if (event.keyCode === 13 || event.keyCode === 32) {
        const popover = getPopover();
        if (_.get(popover, 'open', false)) {
          popover.hide();
        } else {
          popover.show();
        }
      }
    }

    /**
     * @description Method to listen for and intercept keyup events before Coral
     *   can recognize and respond to any events of this type fired (e.g. - when
     *   using keyboard to toggle popover visibility)
     * @param {Event} event - jqLite event that generated the keyup
     */
    function onKeyup(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    /**
     * @description Method to handle mouse hover events over the popover element.
     *   Will update current popover hovering state before determining if any
     *   changes to the popover visibility need to occur.
     * @param {Boolean} isHovering - true if mouse is hovering over popover
     *   element, else false
     */
    function onPopoverHover(isHovering) {
      if (vm.showOnTargetHover) {
        vm.isPopoverHovering = isHovering;
        onHoverStateChanges();
      }
    }

    /**
     * @description Method to handle mouse hover events over the target element.
     *   Will update current target hovering state before determining if any
     *   changes to the popover visibility need to occur.
     * @param {Boolean} isHovering - true if mouse is hovering over target
     *   element, else false
     */
    function onTargetHover(isHovering) {
      if (vm.showOnTargetHover) {
        vm.isPopoverHovering = isHovering;
        onHoverStateChanges();
      }
    }

    ////////////////

    // Manually transclude coral elements to get around issues transcluding into a Coral popover
    // which is similar to what was reported with Coral modals.
    // https://git.corp.adobe.com/admin-tribe/onesie/issues/6241
    function appendTranscludedElements() {
      // first ensure that no nested buttons can duplicate tab focus away from
      // component button
      const transcludedButtonEls = $element[0].querySelectorAll('.target-wrapper button');
      _.forEach(transcludedButtonEls, (buttonEl) => {
        buttonEl.setAttribute('tabindex', '-1');
      });
      // append popover content
      const content = new Coral.Popover.Content();
      content.appendChild($element[0].querySelector('.popover-content'));
      getPopover().set({content});
    }

    /**
     * @description Method to retrieve a reference to the nested coral-popover
     *   element of this badge-popover.
     * @returns {Element} reference to coral-popover element
     */
    function getPopover() {
      // store the popover in an instance variable, otherwise once it's opened
      // it won't be a child of this element
      vm.popover = vm.popover || _.head($element.find('coral-popover'));
      return vm.popover;
    }

    /**
     * @description Since the popover needs to remain open when a user hovers
     *   over the badge element AND the popover element, this method handles
     *   that logic in a centralized place, while also allowing time (currently
     *   1 second) for the user's mouse to move from the badge over to the
     *   popover (before popover closes and hover is impossible).
     */
    function onHoverStateChanges() {
      const popover = getPopover();

      if (vm.isBadgeHovering || vm.isPopoverHovering) {
        popover.show();
      } else {
        // in order to allow users to move the cursor from the badge to the
        // actual popover, we need to insert a short timeout to allow for the
        // space between these elements to be traversed
        $timeout(() => {
          if (!vm.isBadgeHovering && !vm.isPopoverHovering) {
            popover.hide();
          }
        }, 1000);
      }
    }

    function setTarget() {
      getPopover().set({target: `#${vm.targetId}`});
    }
  }
})();
