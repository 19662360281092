const registeredHandlers = {};

// The panel bus is a temporary solution to open legacy panels and confirmation modals in src2

const panelBus = {
  /**
   * Trigger panelManager.close
   *
   * @param {String} panelId - the panel id
   * @param {Object} params - params to pass to panelManager.close
   */
  close: (panelId, params) => registeredHandlers.close(panelId, params),
  /**
   * Trigger panelManager.open
   *
   * @param {String} panelId - the panel id
   * @param {Object} params - params to pass to panelManager.open
   */
  open: (panelId, params) => registeredHandlers.open(panelId, params),
  /**
   * Register a callback function to process panelManager.close
   *
   * @param {Function} closeCallback - the function to register for triggering panelManager.close
   */
  registerPanelClose: (closeCallback) => {
    registeredHandlers.close = closeCallback;
  },
  /**
   * Register a callback function to process panelManager.open
   *
   * @param {Function} openCallback - the function to register for triggering panelManager.open
   */
  registerPanelOpen: (openCallback) => {
    registeredHandlers.open = openCallback;
  },
};

export default panelBus;
