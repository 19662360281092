/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a date-time filter to construct a date-time from a date part and a time part. Defaults to this app's
 *   date/time display format which is a longDate and a shortTime. For ex., September 25, 2017 2:14 PM.
 *   Can optionally specify only the date part or only the date part is returned.
 *
 * @param {String|Date} dateTime This is a valid date arg to angular's date filter.
 * @param {Object} [options] Optional arguments to override any piece of the defaults.
 * @param {Object} [options.dateFormatKey] - Key for loc string with date and time args.
 *   Default loc string is "{{date}} {{time}}". Applies only if both date and time are formatted.
 * @param {Object} [options.dateFormat] - Default is 'longDate'. Applies only if date is formatted.
 * @param {Boolean} [options.dateOnly] - Return only the formatted date. If dateFormat is specified it will be used,
 *   otherwise the format will default to 'longDate'.
 * @param {Object} [options.timeFormat] - Default is 'shortTime'. Applies only if time is formatted.
 * @param {Boolean} [options.timeOnly] - Return only the formatted time. If timeFormat is specified it will be used,
 *   otherwise the format will default to 'shortTime'.
 *
 * @returns {String} formatted date-time string
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.common.date-time').filter('binkyDateTime', dateTimeFilter);

  /* @ngInject */
  function dateTimeFilter($filter, translationUtils) {
    return getDateTime;

    function getDateTime(dateTime, options = {}) {
      if (options.dateOnly) {
        return getDatePart();
      }

      if (options.timeOnly) {
        return getTimePart();
      }

      return translationUtils.sanitizeSafeInstant(
        options.dateFormatKey || 'binky.widgets.common.dateTime.defaultDateTimeFormat',
        {
          date: getDatePart(),
          time: getTimePart(),
        }
      );

      /////////////

      function getDatePart() {
        return $filter('date')(dateTime, options.dateFormat || 'longDate');
      }

      function getTimePart() {
        return $filter('date')(dateTime, options.timeFormat || 'shortTime');
      }
    }
  }
})();
