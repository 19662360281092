(function () {
  'use strict';
  /**
   * @deprecated Please use src2/widgets/common/components/comment/Comment.jsx
   */
  angular.module('binky.widgets.common.comments').constant('COMMENT_BANNER_VARIANT', {
    WARNING: {
      ICON: 'alert',
      TYPE: 'warning',
    },
  });
})();
