import {Locale} from '@admin-tribe/binky';
import {Item, Picker} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useCountryList from 'common/hooks/useCountryList';

/**
 * CountryPicker - a widget for picking the language to display the content.
 */
const CountryPicker = observer(
  ({defaultCountryCode, flex, id, onChange, useLabel = false, label, selectedCountryCode}) => {
    const intl = useIntl();
    const [value, setValue] = useState(defaultCountryCode);
    const countryList = useCountryList();

    let countryOptions = null;

    // Trigger on change when the value changes
    useEffect(() => {
      onChange(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger callback when value changes
    }, [value]);

    const language = Locale.get().activeLanguageBCP47Code;
    countryOptions = countryList.items
      .map((country) => ({
        label: country.countryName,
        value: country.countryCode,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, language));

    let pickerLabel;
    if (useLabel) {
      pickerLabel =
        label || intl.formatMessage({id: 'binky.common.CountryPicker.fieldLabel.country'});
    }

    return (
      <Picker
        aria-label={
          useLabel
            ? undefined
            : intl.formatMessage({id: 'binky.common.CountryPicker.fieldLabel.country'})
        }
        data-testid="country-picker"
        defaultSelectedKey={defaultCountryCode}
        flex={flex}
        id={id}
        label={pickerLabel}
        onSelectionChange={setValue}
        placeholder={intl.formatMessage({id: 'binky.common.CountryPicker.placeholder.country'})}
        selectedKey={selectedCountryCode}
      >
        {countryOptions.map((item) => (
          <Item key={item.value}>{item.label}</Item>
        ))}
      </Picker>
    );
  }
);

CountryPicker.propTypes = {
  /**
   * The default country code to be displayed in the list, and it's set only once at first render,
   * This prop is used when the picker is used in an uncontrolled manner.
   */
  defaultCountryCode: PropTypes.string,
  /**
   * Optional flex prop to adjust styling when nested inside a <Flex/> element.
   */
  flex: PropTypes.string,
  /**
   * The id of the widget.
   */
  id: PropTypes.string,
  /**
   * A custom label for the widget. The value should already be formatted/translated.
   */
  label: PropTypes.string,
  /**
   * Handler that is called when the value is changed.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Sets the selected country value.
   * This prop is used when the picker is used in a controlled manner.
   */
  selectedCountryCode: PropTypes.string,
  /**
   * Flag indicating whether a label should be shown. Defaults to false.
   */
  useLabel: PropTypes.bool,
};

export default CountryPicker;
