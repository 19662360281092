(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilOrganizationsOffers
   * @description defines service to manage jil organizations offers resources
   */
  angular
    .module('binky.core.api.jil')
    .provider('jilOrganizationsOffers', jilOrganizationsOffersProvider);

  let url;

  /* @ngInject */
  function jilOrganizationsOffersProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        offers: getOffersResource(),
      };

      return service;

      ///////////

      function getOffersResource() {
        return $resource(`${url}/:orgId/offers/`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
