(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc directive
   * @name binkyWatchSize
   * @description Simple directive that monitors the element size and calls the
   *   associated (bound) callback method when changes occur.
   * @see {@link ./responsive-nav.component.html} for example of usage
   */
  angular
    .module('binky.widgets.common.watch-size')
    .directive('binkyWatchSize', binkyWatchSizeDirective);

  /* @ngInject */
  function binkyWatchSizeDirective(SizeWatcher) {
    const directive = {
      link: linkFn,
      restrict: 'A',
      scope: {
        watcherCallback: '&onSizeChange',
      },
    };

    return directive;

    /////////////////

    function linkFn($scope, $element, attrs) {
      const watcher = new SizeWatcher({
        element: $element[0],
        msgId: attrs.binkyWatchSize,
        rate: 200,
        scope: $scope,
      });

      const callbackListener = $scope.$on(attrs.binkyWatchSize, (event, dimensions) => {
        $scope.watcherCallback({dimensions, id: attrs.binkyWatchSize});
        event.stopPropagation();
      });

      $scope.$on('$destroy', () => {
        watcher.cancel();
        callbackListener();
      });
    }
  }
})();
