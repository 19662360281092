(function () {
  /**
   * @deprecated Please use src2/core/services/log.js
   */
  angular.module('binky.core.log').config(configLogDecorator);

  function configLogDecorator($provide) {
    $provide.decorator('$log', logDecorator);
  }

  /* @ngInject */
  function logDecorator($delegate, _, newrelic) {
    const originalLogErrorFn = $delegate.error;
    $delegate.error = function error(...args) {
      // Intercept $log.error to report to New Relic before logging the error locally.
      if (_.get(newrelic, 'noticeError')) {
        // Each non-error arg to $log.error is appended to a single error to New Relic.
        // Error objects are reported individually
        let message = '';
        for (let i = 0, len = args.length; i < len; i++) {
          const arg = args[i];
          if (_.isError(arg)) {
            reportError(arg);
          } else if (_.isString(arg)) {
            message += `${arg}; `;
          } else {
            message += `${JSON.stringify(filterPII(arg))}; `;
          }
        }
        // According to NR docs, due to browser limitations for Safari and IE we must
        // throw the error to generate a stack trace.
        if (!_.isEmpty(message)) {
          try {
            throw new Error(_.trim(message));
          } catch (error_) {
            message = error_;
          }
          reportError(message);
        }
      } else {
        $delegate.warn('New Relic not available to record error.');
      }

      // Call $log.error.
      originalLogErrorFn(args);
    };

    return $delegate;

    function filterPII(obj) {
      const excluded = ['Authorization', 'email', 'x-user-token'];
      return _.cloneDeepWith(obj, cloneFn);

      function cloneFn(value) {
        if (_.isObject(value)) {
          _.forEach(excluded, (key) => {
            if (_.hasIn(value, key)) {
              value[key] = '[REDACTED]';
            }
          });
        }
      }
    }

    function reportError(error) {
      // If reporting an error to New Relic is causing an error, just swallow it to
      // prevent infinite loop.
      try {
        newrelic.noticeError(error); // arg is Error object
      } finally {
        // empty
      }
    }
  }
})();
