(function () {
  'use strict';
  /**
   * @deprecated
   *
   * @ngdoc provider
   * @name bumperReadyProvider
   * @description
   */
  angular.module('binky.shell.bumper.ready').provider('bumperReady', bumperReadyProvider);
  function bumperReadyProvider() {
    this.$get = $get;

    function $get($q, BUMPER_READY_ERROR, feature, featureReady) {
      const deferred = $q.defer();

      const service = {
        run,
        whenRunReady,
      };

      return service;

      ///////////////

      function run() {
        featureReady
          .whenRunReady()
          .then(checkBumpers)
          .then(deferred.resolve)
          .catch(deferred.reject);
        return deferred.promise;
      }

      function whenRunReady() {
        return deferred.promise;
      }

      ///////////////

      function checkBumpers() {
        return $q((resolve, reject) => {
          if (feature.isEnabled('bumper_all_unplanned')) {
            reject(BUMPER_READY_ERROR.UNPLANNED_OUTAGE);
          } else {
            resolve();
          }
        });
      }
    }
  }
})();
