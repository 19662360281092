import {CLOUD} from 'models/offers/OfferConstants';

import ProductList from './ProductList';
import {hasOnlyTeamProducts, hasProductsInCloud} from './productListUtils';

const GET_LICENSE_GROUP_PAGE_SIZE = 100; // fetch 100 instead of default 20 so admins can view more

/**
 * @description Fetches licenseGroupSummaries safely without fear of timing out.
 *
 * As a general guideline, we do not retrieve licenseGroupSummaries when the org has DX products
 * due to certain orgs that have extreme amounts of profiles.
 *
 * @param {String} orgId - the orgId
 * @returns {Promise<ProductList>} A promise that resolves to the productList
 */
async function getProductListWithLicenseGroupSummariesIfSafe(orgId) {
  const productList = await ProductList.get({orgId});
  const isSafe =
    hasOnlyTeamProducts(productList) || !hasProductsInCloud(productList, CLOUD.EXPERIENCE);
  return isSafe
    ? ProductList.get({
        includeGroupsQuantity: true,
        licenseGroupLimit: GET_LICENSE_GROUP_PAGE_SIZE,
        orgId,
      })
    : productList;
}

export {
  // eslint-disable-next-line import/prefer-default-export -- users should expect more utility methods to be added in the future
  getProductListWithLicenseGroupSummariesIfSafe,
};
