/**
 * @description Returns the trial contract with the given id, or if not specified, the first trial
 *   contract.
 *   The contract must be from the Contract Service so that it uses the model property.
 * @param {ContractList} contractList - the contract list
 * @param {String} [contractId] - the contract id
 *
 * @returns {Contract} returns the contract or undefined if not found.
 */
function getTrialContract(contractList, contractId = undefined) {
  return contractList.items.find(
    (item) => (contractId === undefined || item.id === contractId) && item.isTrial()
  );
}

/**
 * @description Returns true if there are any trial contracts in this list.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there are any trial contracts in this list.
 */
function hasAnyTrialContracts(contractList) {
  return contractList.items.length > 0 && contractList.items.some((item) => item.isTrial());
}

/**
 * @description Returns true if there are only trial contracts in this list.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there are only trial contracts in this list.
 */
function hasOnlyTrialContracts(contractList) {
  return contractList.items.length > 0 && contractList.items.every((item) => item.isTrial());
}

/**
 * @description Returns true if there is a trial contract with the given id, or if id is not specified if there is
 *   a trial contract.
 *   The trial contract must be from the Contract Service so that it uses the model property.
 * @param {ContractList} contractList - the contract list
 * @param {String} [contractId] - the contract id
 *
 * @returns {Boolean} returns true there is a contract with given contract id and it is a trial contract, else
 *   returns false.
 */
function hasTrialContractForId(contractList, contractId) {
  return !!getTrialContract(contractList, contractId);
}

/**
 * @description Returns true if there are any active trials to paid migrations for these contracts
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there are migrations in progress
 */
function isMigratingFromBusinessTrialsToPaid(contractList) {
  return contractList.items.some((item) => item.isMigratingFromBusinessTrialsToPaid());
}

export {
  getTrialContract,
  hasAnyTrialContracts,
  hasOnlyTrialContracts,
  hasTrialContractForId,
  isMigratingFromBusinessTrialsToPaid,
};
