import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import IllustratedMessage from '../../illustrated-message/IllustratedMessage';
import NoSearchResultsIllustratedMessage from '../../illustrated-message/NoSearchResultsIllustratedMessage';
import ServerErrorIllustratedMessage from '../../illustrated-message/ServerErrorIllustratedMessage';
import {useTableSectionContext} from '../section/TableSectionContext';

/**
 * @deprecated use Pandora adminstration EmptyTableIllustratedMessage:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/empty-table-message
 *
 * Messages to display depending on what empty state
 * the table is in: server error, no search results,
 * or initial list is empty
 */
const EmptyTableStateMessage = observer(
  ({noItemsFound, noSearchResultsContent, noSearchResultsHeading}) => {
    const {listStore} = useTableSectionContext();

    if (listStore.hasLoadingError) {
      return <ServerErrorIllustratedMessage />;
    }

    // No search results so use Search illustration.
    if (listStore.tableStore.searchQuery) {
      return (
        <NoSearchResultsIllustratedMessage
          content={noSearchResultsContent}
          heading={noSearchResultsHeading}
        />
      );
    }

    // No items exist so use 'Not found' illustration.
    return <IllustratedMessage heading={noItemsFound} />;
  }
);

EmptyTableStateMessage.propTypes = {
  /**
   * The string displayed when no items are found when the table loads.
   * All the empty state strings are required to ensure consistency in
   * UI states across tables.
   */
  noItemsFound: PropTypes.string.isRequired,
  /**
   * The content displayed when there are no results from a search.
   * This is required if the table supports search.
   */
  noSearchResultsContent: PropTypes.string,
  /**
   * The heading displayed when there are no results from a search.
   * This is required if the table supports search.
   */
  noSearchResultsHeading: PropTypes.string,
};

export default EmptyTableStateMessage;
