(function () {
  angular.module('binky.core.domains', [
    'binky.core.api.jil',
    'binky.core.authentication',
    'binky.core.common.list',
    'binky.core.directories',
    'binky.core.directory-sync',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.promise-utils',
  ]);
})();
