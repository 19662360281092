/**
 * @ngdoc component
 * @name binky.widgets.common.strip-html-elements:binkyStripHtmlElements
 *
 * @description A filter to strip html elements from the given string.
 */
(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.common.strip-html-elements')
    .filter('binkyStripHtmlElements', binkyStripHtmlElements);

  /* @ngInject */
  function binkyStripHtmlElements(_) {
    return getStripHtmlElements;

    function getStripHtmlElements(str) {
      return _.replace(str, /<[^>]+>/gm, '');
    }
  }
})();
