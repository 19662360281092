import {observer} from 'mobx-react-lite';
import React from 'react';

import Pagination from '../../../pagination/Pagination';
import {useTableSectionContext} from '../../section/TableSectionContext';

/**
 * @deprecated use Pandora TableFooter:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section/src/table-footer
 *  Token-paginated behavior is now handled at the table section level with useTokenPaginatedTableSectionUtils.
 *
 * Next-token pagination
 */
const TokenPagination = observer(() => {
  // The listStore returned should be of the type TokenPagingTableStore
  const {listStore} = useTableSectionContext();

  // Event handlers
  const onGoNext = () => {
    listStore.goToNextPage();
  };

  const onGoPrevious = () => {
    listStore.goToPreviousPage();
  };

  const onPageSizeChange = (newPageSize) => {
    listStore.onPageSizeChange(newPageSize);
  };

  return (
    listStore?.tokenService &&
    !listStore.tableStore.isEmptyTable && (
      <Pagination
        hasNextToken={listStore.hasNextPage()}
        hasPreviousToken={listStore.hasPreviousPage()}
        isNextTokenPagination
        onGoNext={onGoNext}
        onGoPrevious={onGoPrevious}
        onPageSizeChange={onPageSizeChange}
        pageSize={listStore.tableStore.pageSize}
        pageSizeOptions={listStore.tableStore.pageSizeOptions}
        selectedItemCount={listStore.tableStore.selectedItemCount}
      />
    )
  );
});

TokenPagination.displayName = 'TokenPagination';

export default TokenPagination;
