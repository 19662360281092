(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.domains:binkyDomainStatus
   * @description A domain status widget.
   *
   * @param {string} status the status of the domain. One of: 'ACTIVE', 'UNCLAIMED', 'UNDEFINED', 'VALIDATED'.
   * @example <binky-domain-status
   *            status="ACTIVE"
   *          </binky-domain-status>
   *
   */
  angular.module('binky.widgets.domains.status').component('binkyDomainStatus', {
    bindings: {
      domain: '<',
    },
    controller,
    templateUrl: 'widgets/domains/status/domain-status.component.html',
  });

  function controller($log, $translate, _) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        isTrustedDomain: vm.domain.isTrusted(),
        variant: getVariant(vm.domain),
      });
    }

    //////////////

    function getVariant(domain) {
      if (domain.isActive()) {
        return 'success';
      } else if (domain.needsApproval() || domain.needsDirectory() || domain.needsValidation()) {
        return 'warning';
      }
      $log.error(`Invalid domain status for domain: ${domain.domainName}`);
      return 'warning';
    }
  }
})();
