(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.widgets.common.split-button:SplitButtonAction
   * @description A model for buttons in a split-button component.
   */
  angular
    .module('binky.widgets.common.split-button')
    .factory('SplitButtonAction', getSplitButtonAction);

  /* @ngInject */
  function getSplitButtonAction(_) {
    /**
     * @class
     * @description Representation of all properties needed for a split button
     *   button
     */
    class SplitButtonAction {
      /**
       * @constructs SplitButtonAction
       * @description Constructor for SplitButtonAction model Objects.
       * @param {Object} options - SplitButtonAction instance settings
       * @param {String} options.label - Translated text of the split button
       *   label
       * @param {Function} options.onClick - Callback action to take on button
       *   click
       * @param {Boolean} [options.selected] - Whether or not this button is
       *   selected. Optional; defaults to false (not selected)
       */
      constructor(options = {}) {
        _.defaults(this, _.pick(options, ['label', 'onClick', 'selected']), {selected: false});
      }
    }

    return SplitButtonAction;
  }
})();
