// eslint-disable-next-line angular/window-service -- importing binkySrc2
const DOMAIN_LIST_CONSTANTS = window.binkySrc2.services.domain.DOMAIN_LIST_CONSTANTS;
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.domains')
    .constant('DOMAIN_LIST_CACHE_ID', DOMAIN_LIST_CONSTANTS.DOMAIN_LIST_CACHE_ID)
    .constant('DOMAIN_LIST_EVENT', DOMAIN_LIST_CONSTANTS.DOMAIN_LIST_EVENT);
})();
