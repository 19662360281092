(function () {
  'use strict';
  /**
   * @deprecated not required in React code
   *
   * @ngdoc service
   * @name panelManager
   * @description Manage show/hide behavior of panel components
   */
  angular.module('binky.shell.panels.manager').factory('panelManager', panelManager);

  /* @ngInject */
  function panelManager($document, $rootScope, _, PANEL_MANAGER) {
    const CLOSE = 'CLOSE';
    const OPEN = 'OPEN';

    const service = {
      close,
      getOpenPanelIdsByType,
      getPanelTypeWidth,
      isOpen,
      isPanelTypeOpen,
      isRegistered,
      open,
      register,
      unregister,
    };

    const triggers = {};

    return service;

    //////////////

    /**
     * @description Close a previously registered panel
     *
     * @param {String} id - identifier for the panel to close
     * @param {Object} [params] - optional params pertaining to panel to close.
     *    (NOTE: only supported for panels that are Modals)
     * @returns {Boolean} true if closed, false if not registered or not open
     */
    function close(id, params) {
      if (!isRegistered(id)) {
        return false;
      }
      if (!getTrigger(id).isOpen) {
        return false;
      }
      // we message that the panel has been closed...
      emitPanelToggleMessage(getTrigger(id).type, CLOSE, id, params);

      return true;
    }

    /**
     * @description Obtain the width of a type of panel. Currently, only works
     *   with rail type panels
     * @param {PANEL_MANAGER.TYPE} type - type of panel to get width of
     * @throws {Error} when unsupported panel type is used
     * @returns {Number} number of pixels in panel width
     */
    function getPanelTypeWidth(type) {
      switch (type) {
        case PANEL_MANAGER.TYPE.RAIL: {
          const railId = _.head(getOpenPanelIdsByType(type));
          if (_.isString(railId)) {
            const railElement = $document[0].getElementById(railId);
            return _.chain(railElement).invoke('getBoundingClientRect').get('width').value();
          }
          return 0; // panel type not open, so width is currently zero
        }
        default:
          throw new Error(`Attempting to get width of unsupported panel type: ${type}`);
      }
    }

    /**
     * @description Determine if a panel is open or not
     * @param {String} id - unique ID of panel
     * @throws {Error} when panel is not registered
     * @returns {Boolean} true if panel is open, else false
     */
    function isOpen(id) {
      if (!isRegistered(id)) {
        throw new Error(`Panel: ${id} is unregistered`);
      }
      return getTrigger(id).isOpen;
    }

    /**
     * @description Method to determine if a certain type of panel (rail,
     *   drawer, etc) is open.
     * @param {PANEL_MANAGER.TYPE} panelType - type of panel to see if open
     * @returns {Boolean} true if panel type is open, else false
     */
    function isPanelTypeOpen(panelType) {
      return getOpenPanelIdsByType(panelType).length > 0;
    }

    /**
     * @description Determine if a panel is registered or not
     * @param {String} id - unique ID of panel
     * @returns {Boolean} true if panel is registered, else false
     */
    function isRegistered(id) {
      return Object.prototype.hasOwnProperty.call(triggers, id);
    }

    /**
     * @description Open a previously registered panel
     *
     * @param {String} id - identifier for the panel to open
     * @param {Object} [params] - optional params pertaining to panel to open.
     *    (NOTE: only supported for panels that are Modals)
     * @returns {Boolean} true if opened, false if already open
     */
    function open(id, params) {
      if (!isRegistered(id)) {
        // we throw an error here, because the lack of the open panel will be an issue
        throw new Error(`Attempting to open a panel: ${id} that is unregistered`);
      }
      if (getTrigger(id).isOpen) {
        // we don't throw here because the end result to the user is the same
        return false;
      }
      // we close all non-rail panels currently open when a new one is triggered,
      // exceptions to this rule include:
      //   1. we don't close any panels if the opening panel is a modal or rail
      //   2. we don't close any modals if the opening panel is a pulldown
      //   3. we never close rails when other panels are opened
      if (
        getTrigger(id).type !== PANEL_MANAGER.TYPE.MODAL &&
        getTrigger(id).type !== PANEL_MANAGER.TYPE.RAIL
      ) {
        if (getTrigger(id).type === PANEL_MANAGER.TYPE.PULLDOWN) {
          _.forOwn(triggers, (value, key) => {
            if (
              isOpen(key) &&
              getTrigger(key).type !== PANEL_MANAGER.TYPE.MODAL &&
              getTrigger(key).type !== PANEL_MANAGER.TYPE.RAIL
            ) {
              close(key);
            }
          });
        } else {
          _.forOwn(triggers, (value, key) => {
            if (isOpen(key) && getTrigger(key).type !== PANEL_MANAGER.TYPE.RAIL) {
              close(key);
            }
          });
        }
      }

      // we message that the panel has been opened...
      emitPanelToggleMessage(getTrigger(id).type, OPEN, id, params);

      return true;
    }

    /**
     * @description Register a new panel with the manager
     * @param {PANEL_MANAGER.TYPE} type - kind  of panel to register
     * @param {String} id - unique ID of panel
     * @param {Function} onOpen - callback to fire when panel is opened
     * @param {Function} onClose - callback to fire when panel is closed
     * @throws {Error} when panel has already been registered or type is unknown
     */
    function register(type, id, onOpen, onClose) {
      if (isRegistered(id)) {
        throw new Error(`Attempting to register a panel: ${id} that is already registered`);
      }
      let eventMsg;
      switch (type) {
        case PANEL_MANAGER.TYPE.DRAWER:
          eventMsg = PANEL_MANAGER.DRAWER;
          break;
        case PANEL_MANAGER.TYPE.MODAL:
          eventMsg = PANEL_MANAGER.MODAL;
          break;
        case PANEL_MANAGER.TYPE.PULLDOWN:
          eventMsg = PANEL_MANAGER.PULLDOWN;
          break;
        case PANEL_MANAGER.TYPE.RAIL:
          eventMsg = PANEL_MANAGER.RAIL;
          break;
        default:
          throw new Error(`Attempting to register a panel: ${id} of unknown type ${type}`);
      }
      const openHandler = $rootScope.$on(eventMsg.OPEN, (event, targetPanel, params) => {
        if (targetPanel === id) {
          toggleIsOpen(id);
          if (onOpen) {
            onOpen(params);
          }
        }
      });

      const closeHandler = $rootScope.$on(eventMsg.CLOSE, (event, data, params) => {
        if (data === id) {
          toggleIsOpen(id);
          if (onClose) {
            onClose(params);
          }
        }
      });

      // register the trigger
      triggers[id] = {
        closeHandler,
        isOpen: false,
        openHandler,
        type,
      };
    }

    /**
     * @description Remove a previously-registered panel from the manager
     * @param {String} id - unique ID of panel
     * @throws {Error} when panel has not been registered
     */
    function unregister(id) {
      if (isRegistered(id)) {
        _.invoke(getTrigger(id), 'closeHandler');
        _.invoke(getTrigger(id), 'openHandler');
        delete triggers[id];
      } else {
        throw new Error(`Attempting to unregister a panel: ${id} that is not registered`);
      }
    }

    //////////

    /**
     * @description Emits a message on the root scope of the application,
     *   indicating that a particular panel has opened or closed
     * @param {PANEL_MANAGER.TYPE} panelType - type of panel messaging about
     * @param {String} action - 'OPEN' or 'CLOSE'
     * @param {String} id - unique ID of panel
     * @param {Object} [params] - optional params pertaining to panel to open/close
     *    (NOTE: only supported for panels that are Modals)
     */
    function emitPanelToggleMessage(panelType, action, id, params) {
      // an error is thrown if registering a panel with a type not listed below,
      // so it is impossible for the type to be anything but one of these
      // options, so there is no default or error case listed (unreachable code)
      /* eslint-disable default-case */
      switch (panelType) {
        case PANEL_MANAGER.TYPE.DRAWER:
          $rootScope.$emit(PANEL_MANAGER.DRAWER[action], id);
          break;
        case PANEL_MANAGER.TYPE.MODAL:
          if (_.isEqual(action, OPEN)) {
            $rootScope.$emit(PANEL_MANAGER.MODAL[action], id, params);
          } else {
            $rootScope.$emit(PANEL_MANAGER.MODAL[action], id, params);
          }
          break;
        case PANEL_MANAGER.TYPE.PULLDOWN:
          $rootScope.$emit(PANEL_MANAGER.PULLDOWN[action], id);
          break;
        case PANEL_MANAGER.TYPE.RAIL:
          $rootScope.$emit(PANEL_MANAGER.RAIL[action], id);
          break;
      }
      /* eslint-enable default-case */
    }

    /**
     * @description Obtain a list of open panel IDs that match the type of panel
     *   passed into the method
     * @param {PANEL_MANAGER.TYPE} type - type of open panels to return
     * @returns {String[]} list of open panel IDs that match type passed in
     */
    function getOpenPanelIdsByType(type) {
      return _(triggers)
        .pickBy((trigger) => _.get(trigger, 'isOpen', false))
        .pickBy((trigger) => _.chain(trigger).get('type').isEqual(type).value())
        .keys()
        .value();
    }

    /**
     * @description Returns an Object that contains references to callback
     *   methods (triggers) registered, type, and state (isOpen) of a given
     *   panel
     * @param {String} id - unique ID of panel
     * @returns {Object} containing closeHandler (Function), isOpen (Boolean),
     *   openHandler (Function), and type (PANEL_MANAGER.TYPE)
     */
    function getTrigger(id) {
      return _.get(triggers, id);
    }

    /**
     * @description Changes a panel state from opened to closed or from closed
     *   to opened, depending on the current state of the panel
     * @param {String} id - unique ID of panel
     */
    function toggleIsOpen(id) {
      getTrigger(id).isOpen = !getTrigger(id).isOpen;
    }
  }
})();
