(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.shell.navigation.tab-nav').component('binkyTabNav', {
    bindings: {
      autoHide: '@?', // if "true", automatically hide the tab nav when there is only one tab
      idPrefix: '@', // required: to construct unique tab ids of the form 'idPrefix-index'
      orientation: '@',
      tabs: '<',
    },
    controller,
    templateUrl: 'shell/navigation/tab-nav/tab-nav.component.html',
  });

  /* @ngInject */
  function controller(_, $document, $element, $log, $scope, feature) {
    const ORIENTATION_VERTICAL = 'vertical';
    const HOME_KEY = 'Home';
    const END_KEY = 'End';
    const ARROW_DOWN_KEY = 'ArrowDown';
    const ARROW_RIGHT_KEY = 'ArrowRight';
    const PAGE_DOWN_KEY = 'PageDown';
    const ARROW_LEFT_KEY = 'ArrowLeft';
    const ARROW_UP_KEY = 'ArrowUp';
    const PAGE_UP_KEY = 'PageUp';
    let groupNameIndexHash = {};
    let arrayWatcher = _.noop;
    let tabHiddenWatchers = [];

    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      feature,
      hasGroups,
      isSelected,
      onKeydown,
      showGroupedTabs,
      showGroupName,
      showTabs,
    });

    /**
     * @description This method handles changes to values bound to the component.
     * @param {Object} changes - a hash whose keys are the names of the bound
     *   properties that have changed, and the values are an object of the form
     *   { currentValue, previousValue, isFirstChange() }
     * @return {undefined} no return value
     */
    function $onChanges(changes) {
      const tabs = _.get(changes, 'tabs.currentValue');
      handleChanges(tabs);
      // we recreate the array watcher on any change to the array
      arrayWatcher();
      arrayWatcher = $scope.$watchCollection('$ctrl.tabs', (newValue, oldValue) => {
        if (!_.isEqual(newValue, oldValue)) {
          handleChanges(newValue);
        }
      });
    }

    function $onDestroy() {
      arrayWatcher();
      _.forEach(tabHiddenWatchers, (tabHiddenWatcher) => tabHiddenWatcher());
      $element[0].removeEventListener('keydown', onKeydown, true);
    }

    function $onInit() {
      $element[0].addEventListener('keydown', onKeydown, true);
    }

    /**
     * @description Method to determine if the tabs represented by this component
     *   are grouped or not. Grouped tabs contain a group attribute that denotes
     *   the name of the group they belong to.
     * @returns {Boolean} true if tabs contain grouped content, else false
     */
    function hasGroups() {
      return !!_.find(vm.tabs, 'group');
    }

    /**
     * @description This method determines if the given tab should be shown as
     *   selected or not in the UI. Selected tabs are tabs that directly link to
     *   the current page/state, or contain a sub list that links to the current
     *   page/state (e.g. - abstract states).
     * @param {NavItem} tab - reference to NavItem to see if selected
     * @return {Boolean|undefined} returns true if the tab should show as
     *   selected in the UI, else undefined so the ngAttr directive will
     *   correctly not add the attribute to the element at all
     */
    function isSelected(tab) {
      // IMPORTANT: must return undefined instead of false for ngAttr to
      //   properly add/remove the relative attribute name (i.e. - selected)
      if (!tab.hidden && tab.containsRouteToCurrentLocation()) {
        return true;
      }
      return undefined;
    }

    /**
     * @description Method to handle any keydown events sent to the nested coral
     *   tablist component. Since the component comingles focus and selected
     *   behavior, we need to intercept all keyboard navigation and separate out
     *   navigation from selection ourselves. This method will respond to
     *   keydown events on the capture phase of the event lifecycle, allowing us
     *   to intercept key events dealing with navigation and simply provide
     *   focus behavior. This not only allows for a more representative UI
     *   experience, but also for screen readers to relay the correct
     *   information regarding selection status and content being displayed
     *   (accessibility fix).
     * @param {Event} event - keydown event model representing the key that was
     *   executed
     */
    function onKeydown(event) {
      switch (event.key) {
        case HOME_KEY:
          interceptEvent();
          focusFirstTab();
          break;
        case END_KEY:
          interceptEvent();
          focusLastTab();
          break;
        case ARROW_DOWN_KEY:
        case ARROW_RIGHT_KEY:
        case PAGE_DOWN_KEY:
          interceptEvent();
          focusNextTab();
          break;
        case ARROW_UP_KEY:
        case ARROW_LEFT_KEY:
        case PAGE_UP_KEY:
          interceptEvent();
          focusPreviousTab();
          break;
        default:
        // we only need to intercept/handle events that could change focus
      }

      function focusFirstTab() {
        const tablist = getTablist();
        const firstItem = _.invoke(tablist, 'items._getFirstSelectable');
        _.invoke(firstItem, 'focus');
      }

      function focusLastTab() {
        const tablist = getTablist();
        const lastItem = _.invoke(tablist, 'items._getLastSelectable');
        _.invoke(lastItem, 'focus');
      }

      function focusNextTab() {
        const tablist = getTablist();
        const nextItem = _.invoke(tablist, 'items._getNextSelectable', $document[0].activeElement);
        _.invoke(nextItem, 'focus');
      }

      function focusPreviousTab() {
        const tablist = getTablist();
        const previousItem = _.invoke(
          tablist,
          'items._getPreviousSelectable',
          $document[0].activeElement
        );
        _.invoke(previousItem, 'focus');
      }

      function interceptEvent() {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }

    /**
     * @description This method contains the logic controlling whether or not
     *   this component should display grouped tab content. Grouped tabs are only
     *   shown when the tabs contain group attributes and the orientation is set
     *   to vertical. Otherwise, ungrouped tabs are rendered in the component.
     * @returns {Boolean} true if should show grouped tabs, else false
     */
    function showGroupedTabs() {
      return vm.hasGroups() && vm.orientation === ORIENTATION_VERTICAL;
    }

    /**
     * @description This method contains the logic controlling whether or not
     *   this component should display a group name in the tab list. Group names
     *   are shown when their value is not 'undefined' and their value is different
     *   from a previously shown group name.
     * @param {String} groupName - name of group to check
     * @param {Number} index - index of item showing group name (only show for first item)
     * @returns {Boolean} true if should show group name, else false
     */
    function showGroupName(groupName, index) {
      if (
        _.isNil(groupName) ||
        (_.has(groupNameIndexHash, groupName) && groupNameIndexHash[groupName] < index)
      ) {
        // group name is null/undefined, or has already been added to a previous item
        return false;
      }
      groupNameIndexHash[groupName] = index;
      return true;
    }

    /**
     * @description Returns true if this component should show its tabs. Tabs are shown, unless
     *   the component's autoHide option is set to true and there are fewer than 2 visible tabs.
     *
     * @returns {Boolean} true if this component should show its tabs
     */
    function showTabs() {
      return vm.autoHide === 'true' ? getVisibleTabs().length > 1 : true;
    }

    ////////////

    /**
     * @description Method to retrieve a reference to the nested coral-tablist
     *   element of this tab-nav.
     * @returns {Element} reference to coral-tablist element
     */
    function getTablist() {
      vm.tablist = vm.tablist || _.head($element.find('coral-tablist'));
      return vm.tablist;
    }

    function getVisibleTabs() {
      return _.reject(vm.tabs, {hidden: true});
    }

    function handleChanges(tabs) {
      watchTabs(tabs);
      _.forEach(tabs, updateTab);
      if (vm.hasGroups()) {
        // reset hash of shown group name indexes
        groupNameIndexHash = {};
        updateGroups();
      }

      /**
       * @description Method to update grouped tabs.
       */
      function updateGroups() {
        const tabsByGroup = _.groupBy(vm.tabs, 'group');
        const groupKeys = _.keys(tabsByGroup);
        const ungroupedItems = _.remove(groupKeys, (name) => name === 'undefined');
        let groupNames = _.sortBy(groupKeys);
        if (!_.isEmpty(ungroupedItems)) {
          // prepend ungrouped items to beginning of list (if any exist)
          groupNames = [...ungroupedItems, ...groupNames];
        }
        vm.groupedTabs = [];
        _.forEach(groupNames, (groupName) => {
          vm.groupedTabs = [...vm.groupedTabs, ...tabsByGroup[groupName]];
        });
      }

      /**
       * @description This method takes a tab reference and key for where tab
       *   is located in list and ensures that attributes that can hold falsey
       *   values (i.e. - 0, false, null, undefined) are all set to undefined.
       *   This is necessary for the `ngAttr` property in the view to work
       *   correctly (only adding attributes that are defined).
       * @param {NavItem} tab - reference to NavItem to update
       * @param {Number} key - index of NavItem updating in tabs Array
       * @return {undefined} no return value
       */
      function updateTab(tab, key) {
        _.forEach(['disabled', 'hidden', 'icon', 'invalid'], (property) => {
          tab[property] = tab[property] || undefined;
        });
        const tabWithResolvedName = _.cloneDeep(tab);
        tabWithResolvedName
          .getDisplayName()
          .then((name) => {
            tabWithResolvedName.name = name;
          })
          .catch(() => {
            $log.warn('Error resolving tab getDisplayName');
          });

        vm.tabs[key] = tabWithResolvedName;
      }
    }

    function watchTabs(tabs) {
      _.forEach(tabHiddenWatchers, (tabHiddenWatcher) => tabHiddenWatcher());
      tabHiddenWatchers = _.map(tabs, (val, idx) =>
        $scope.$watch(`$ctrl.tabs[${idx}].hidden`, (newValue, oldValue) => {
          if (newValue !== oldValue) {
            handleChanges(vm.tabs);
          }
        })
      );
    }
  }
})();
