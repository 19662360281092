(function () {
  /**
   * @deprecated Migrated to jilGlobalAdminPolicies.js
   *
   * @ngdoc service/provider
   * @name jilGlobalAdminPolicies
   * @description defines service to manage JIL global admin policy resources
   */
  angular.module('binky.core.api.jil').provider('jilGlobalAdminPolicies', jilGlobalAdminPolicies);

  let url;

  /* @ngInject */
  function jilGlobalAdminPolicies() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        policies: getPoliciesResource(),
      };

      return service;

      ///////////

      function getPoliciesResource() {
        return $resource(
          `${url}/:orgId/policies`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              isArray: true,
            },
          }
        );
      }
    }
  }
})();
