(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   *
   * @ngdoc component
   * @name binky.widgets.insights.insights-item-detail-list-table.status:binkyReportStatus
   * @description A report status widget.
   *
   * @param {Function} onDownloadReport - Callback when download link has been clicked
   * @param {String} status the status of the report. One of: 'REPORT_STATUS.INPROGRESS',
   *                        'REPORT_STATUS.ERROR' and 'REPORT_STATUS.SUCCESS'
   * @example <binky-report-status
   *            status="Success"
   *          </binky-report-status>
   *
   */
  angular
    .module('binky.widgets.insights.insights-item-detail-list-table.status')
    .component('binkyReportStatus', {
      bindings: {
        allowDownload: '<?',
        onDownloadReport: '&?',
        status: '@',
      },
      controller,
      templateUrl:
        'widgets/insights/insights-item-detail-list-table/status/report-status.component.html',
    });

  /* @ngInject */
  function controller($translate, _, REPORT_STATUS) {
    const vm = this;
    _.assign(vm, {
      download,
      getStatusLabel,
      getVariant,
      showDownloadLink,
    });

    function download() {
      _.invoke(vm, 'onDownloadReport');
    }

    function getStatusLabel() {
      return $translate.instant(`binky.widgets.insights.status.${_.toLower(vm.status)}`);
    }

    function getVariant() {
      if (vm.status === REPORT_STATUS.ERROR) {
        return 'error';
      } else if (vm.status === REPORT_STATUS.INPROGRESS) {
        return 'warning';
      }
      return 'success';
    }

    function showDownloadLink() {
      return vm.allowDownload && vm.status === REPORT_STATUS.SUCCESS;
    }
  }
})();
