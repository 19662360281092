(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name NavItemList
   * @description Model for NavItemList Objects
   */
  angular.module('binky.shell.navigation.nav-item').factory('NavItemList', getNavItemList);

  /* @ngInject */
  function getNavItemList($q, _) {
    /**
     * @class
     * @description Model for list of navigable items (e.g. - tabs/links).
     */
    class NavItemList {
      /**
       * @constructs NavItemList
       * @description Constructor for NavItemList model Objects.
       * @param {NavItem[]} [items] - list of NavItems to display for navigation
       */
      constructor(items = []) {
        this.items = _.clone(items);
        sortList(this.items);
      }

      /**
       * @description Method to add new items to navigation list
       * @param {NavItem|NavItem[]} items - items to add to navigation
       */
      add(items) {
        const itemsArray = _.isArray(items) ? items : [items];
        this.items.push(...itemsArray);
        sortList(this.items);
        callChangeHandlers(this);
      }

      /**
       * @description Method to determine if the given state route exists as a
       *   link (i.e. - this.state.name) in any of the navigable items held in
       *   this list.
       * @param {String} stateName - name of state route to look for
       * @param {Object} stateParams - params of state route to look for
       * @returns {Boolean} true if route exists, else false
       */
      containsRouteTo(stateName, stateParams) {
        return !!_.find(this.items, (item) => item.containsRouteTo(stateName, stateParams));
      }

      /**
       * @description Method to retrieve an item from navigation list by name
       * @param {String} name - name of item to retrieve
       * @returns {NavItem|undefined} if found, named item from list, else
       *   undefined
       */
      find(name) {
        return _.find(this.items, {name});
      }

      /**
       * @description Method to retrieve an item from navigation list by state
       * @param {String} state - state of item to retrieve
       * @returns {NavItem|undefined} if found, item with given state from list, else
       *   undefined
       */
      findByState(state) {
        return _.find(this.items, (item) => item.state && item.state.name === state);
      }

      /**
       * @description Method to find the first active item in this list with a
       *   state or child defined. Active items are items that are not disabled, hidden,
       *   or invalid.
       * @returns {NavItem|undefined} if found, first active item, else
       *   undefined
       */
      findFirstActive() {
        return _.find(this.items, (item) => item.isActive());
      }

      /**
       * @description Method to register callbacks when navigation list changes
       *   occur. In some abstract components (workspace, etc), the only binding
       *   is a static key for the list that they should load. Since this key
       *   does not change, the only way to react to changes is by using the $doCheck
       *   hook of the component lifecycle. Since this check is usually redundant and
       *   can kick of a much larger computational effort (e.g. - loading/building UI
       *   data structures), we provide this method to register discrete functionality
       *   that these components can encapsulate and add "as needed" to the list model.
       *   Using this approach, we can avoid periodic and expensive computations
       *   from occurring in the UI until they are actually needed.
       * @param {Function} callback - method to handle model changes
       */
      registerChangeCallback(callback) {
        if (!this.changeCallbacks) {
          this.changeCallbacks = [];
        }
        this.changeCallbacks.push(callback);
      }

      /**
       * @description Method to remove items from navigation list
       * @param {NavItem|NavItem[]} items - items to remove from navigation
       */
      remove(items) {
        if (_.isArray(items)) {
          _.forEach(items, (item) => {
            this.removeByName(item.name, true);
          });
        } else {
          this.removeByName(items.name, true);
        }
        callChangeHandlers(this);
      }

      /**
       * @description Method to remove all items from navigation list
       */
      removeAll() {
        // we use _.remove to mutate and not replace the array
        _.remove(this.items, _.constant(true));
        callChangeHandlers(this);
      }

      /**
       * @description Method to remove an item from the navigation list by the
       *   name of the item
       * @param {String} toRemoveName - name of item to remove from navigation
       *   list
       * @param {Boolean} silent - true to prevent change handlers from being
       *   called, else false to run change handlers on removal
       */
      removeByName(toRemoveName, silent) {
        _.remove(this.items, ['name', toRemoveName]);
        if (!silent) {
          callChangeHandlers(this);
        }
      }

      /**
       * @description Method to signal when loading begins/ends for nav list.
       * @param {Boolean} loadingStatus - true if loading, else false
       */
      setLoadingStatus(loadingStatus) {
        this.loading = loadingStatus;
      }

      /**
       * @description Method to update the visibility of the navigable items in
       *   this list. Will call the updateVisibility method of each item in
       *   list, returning when all subcalls have completed.
       * @param {Transition} trans - the current Transition taking place when
       *   this list's visibility is updated. For more info, see:
       *
       *   https://ui-router.github.io/ng1/docs/latest/classes/transition.transition-1.html
       * @returns {Promise} resolves when all navigable item visibility has been
       *   updated
       */
      updateVisibility(trans) {
        return $q.all(_.invokeMap(this.items, 'updateVisibility', trans));
      }
    }

    return NavItemList;

    //////////

    /**
     * @description Method to call any registered handlers when a change to a
     *   model occurs.
     * @param {NavItemList} model - instance of model to call handlers on
     */
    function callChangeHandlers(model) {
      _.forEach(model.changeCallbacks, (callback) => callback());
    }

    /**
     * @description Method to sort the navigation list
     * @param {NavItem[]} list - navigation list items to sort
     */
    function sortList(list) {
      // we can't use the lodash result as its critical we keep the same array instance
      const newList = _(list).sortBy(['order', 'name']).compact().value();
      _.remove(list, _.constant(true));
      // we can't use map here, as it would create a new array instance
      // that orphans bindings that have occurred to the original array
      // eslint-disable-next-line lodash/prefer-map
      _.forEach(newList, (item) => {
        list.push(item);
      });
    }
  }
})();
