(function () {
  /**
   * @deprecated ES6 code uses mobx
   */
  angular.module('binky.core.common.model.cache').factory('modelCache', modelCacheService);

  /* @ngInject */
  function modelCacheService($cacheFactory, $rootScope, $timeout, _, binkySrc2) {
    const DEFAULT_KEY = 'key';
    const caches = {};
    const cacheOptions = {};
    const removeWatchers = {};
    const removeAllWatchers = {};
    const service = {
      destroy,
      get,
      info,
      put,
      register,
      remove,
      removeAll,
      removeAllOnEvent,
      removeOnEvent,
    };

    return service;

    /**
     * Removes references to this cache from $cacheFactory.
     *
     * @param {String} cacheId (optional) The id of the cache to destroy
     *                         (should correspond with MODEL constant). If no
     *                         cacheId value is passed, then all caches are
     *                         destroyed
     */
    function destroy(cacheId) {
      if (_.isNil(cacheId)) {
        // destroy all caches
        _.forEach(caches, (cache, id) => {
          cache.destroy();
          // eslint-disable-next-line no-param-reassign
          cache = null; // mark for potential garbage collection
          caches[id] = null;
        });
      }
      if (caches[cacheId]) {
        caches[cacheId].destroy();
        caches[cacheId] = null; // mark for potential garbage collection
      }
    }

    /**
     * Returns cached value for key or undefined for cache miss.
     *
     * @param {String} cacheId The id of the cached model to fetch (should
     *                         correspond with MODEL constant)
     * @param {String} key     (optional) The unique key to use for this model
     *                         If no key is provided, a default value is used
     *
     * @returns {Object} The cached model, or undefined, if not found
     */
    function get(cacheId, key) {
      if (_.isNil(cacheId)) {
        throw new TypeError('Missing required parameter to modelCache.get(cacheId, key)');
      }
      if (_.isNil(caches[cacheId])) {
        return undefined;
      }
      let model;
      if (key) {
        model = caches[cacheId].get(key);
      } else {
        model = caches[cacheId].get(DEFAULT_KEY);
      }
      // return a copy so controllers do not edit directly (preserve cached instance in case we need to revert)
      return model ? angular.copy(model) : model;
    }

    /**
     * Returns id, size, and options of cache.
     *
     * @param {String} cacheId The id of the cache to retrieve info about
     *                         (should correspond with MODEL constant)
     *
     * @returns {Object} Information about cache storage
     */
    function info(cacheId) {
      let cache;
      if (_.isNil(cacheId)) {
        throw new TypeError('Missing required parameter to modelCache.info(cacheId)');
      }
      if (caches[cacheId]) {
        cache = caches[cacheId].info();
      }
      return cache;
    }

    /**
     * Puts a new key-value pair into the cache and returns it.
     *
     * @param {String} cacheId The id of the cache to store model into (should
     *                         correspond with MODEL constant)
     * @param {Object} model   The actual model to cache
     * @param {String} key     (optional) The unique key to use for this model
     *                         If no key is provided, a default value is used
     */
    function put(cacheId, model, key) {
      if (_.isNil(cacheId) || _.isNil(model)) {
        throw new TypeError('Missing required parameter to modelCache.put(cacheId, model, key)');
      }
      if (_.isNil(caches[cacheId])) {
        throw new TypeError(`Cache has not been registered: ${cacheId}`);
      }
      $timeout(() => {
        // wrap in timeout so current digest cycle completes before caching
        if (!key) {
          return caches[cacheId].put(DEFAULT_KEY, angular.copy(model));
        }
        return caches[cacheId].put(key, angular.copy(model));
      });
    }

    /**
     * Register a new cache, and the size to associate with it.
     *
     * @param {String} cacheId The id of the cache to store model into (should
     *                         correspond with MODEL constant)
     * @param {Number} capacity The number of objects to keep in the cache at any time
     * @param {Object} [options] Optional configuration for this cache
     * @param {Boolean} [options.removable] Whether to allow removal of elements in this
     *    cache, including through removeAll. Defaults to true.
     */
    function register(cacheId, capacity, options = {}) {
      if (_.isNil(cacheId) || _.isNil(capacity)) {
        throw new TypeError('Missing required parameter to modelCache.register(cacheId, capacity)');
      }
      if (caches[cacheId]) {
        throw new TypeError(`Registered cache already exists: ${cacheId}`);
      }
      caches[cacheId] = $cacheFactory(cacheId, {
        capacity,
      });
      const {removable = true} = options;
      cacheOptions[cacheId] = {
        removable,
      };
    }

    /**
     * Removes a key-value pair from the cache.
     *
     * @param {String} cacheId The id of the cache to remove model from (should
     *                         correspond with MODEL constant)
     * @param {String} key     (optional) The unique key to use for this model
     *                         If no key is provided, a default value is used
     */
    function remove(cacheId, key) {
      if (_.isNil(cacheId)) {
        throw new TypeError('Missing required parameter to modelCache.remove(cacheId, key)');
      }
      if (cacheOptions[cacheId] && !cacheOptions[cacheId].removable) {
        throw new TypeError(`Cannot remove from cache: ${cacheId}`);
      }
      if (caches[cacheId]) {
        if (key) {
          caches[cacheId].remove(key);
        } else {
          caches[cacheId].remove(DEFAULT_KEY);
        }
      }
    }

    /**
     * Removes all cached values.
     *
     * @param {String} cacheId (optional) The id of the cache to remove models
     *                         from (should correspond with MODEL constant). If
     *                         no cacheId value is passed, then all caches are
     *                         removed
     */
    function removeAll(cacheId) {
      if (_.isNil(cacheId)) {
        // remove all removable caches
        _.forEach(caches, (cache, iteratedCacheId) => {
          if (cacheOptions[iteratedCacheId].removable) {
            cache.removeAll();
          }
        });
      }
      if (cacheOptions[cacheId] && !cacheOptions[cacheId].removable) {
        throw new TypeError(`Cannot remove from cache: ${cacheId}`);
      }
      if (caches[cacheId]) {
        caches[cacheId].removeAll();
      }
    }

    /**
     * Removes all cached values for the specified cacheId when an event occurs.
     *
     * @param {String} cacheId The id of the cache to remove
     * @param {String[]} eventNames An array of event names to listen for
     */
    function removeAllOnEvent(cacheId, eventNames) {
      if (cacheOptions[cacheId] && !cacheOptions[cacheId].removable) {
        throw new TypeError(`Cannot remove from cache: ${cacheId}`);
      }
      _.forEach(eventNames, (name) => {
        if (removeAllWatchers[name]) {
          removeAllWatchers[name].push(cacheId);
        } else {
          removeAllWatchers[name] = [cacheId];
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(name, removeAllCachedModels);
        }
      });
    }

    /**
     * Removes a key-value pair for the specified cacheId when an event occurs.
     *
     * @param {String} cacheId The id of the cache to act on
     * @param {String[]} eventNames An array of event names to listen for
     */
    function removeOnEvent(cacheId, eventNames) {
      if (cacheOptions[cacheId] && !cacheOptions[cacheId].removable) {
        throw new TypeError(`Cannot remove from cache: ${cacheId}`);
      }
      _.forEach(eventNames, (name) => {
        if (removeWatchers[name]) {
          removeWatchers[name].push(cacheId);
        } else {
          removeWatchers[name] = [cacheId];
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(name, removeCachedModel);
        }
      });
    }

    ///////////

    function removeAllCachedModels(event) {
      _.forEach(removeAllWatchers[event.name], (cacheType) => {
        removeAll(cacheType);

        binkySrc2.models.cache.modelCache.clear(cacheType);
      });
    }

    function removeCachedModel(event, key) {
      _.forEach(removeWatchers[event.name], (cacheType) => {
        remove(cacheType, key);
      });
    }
  }
})();
