import {Divider, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Navigation component used for Page, shows a divider
 * when no children are provided.
 */
const PageNav = ({children}) => <Flex direction="column">{children || <Divider size="M" />}</Flex>;

PageNav.displayName = 'PageNav';
PageNav.propTypes = {
  /**
   * The content to be displayed in the PageNav.
   */
  children: PropTypes.node,
};

export default PageNav;
