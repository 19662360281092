import PropTypes from 'prop-types';
import React from 'react';
import {Line} from 'react-chartjs-2';
import {useIntl} from 'react-intl';

import {
  getChartLabelsAndDataSets,
  getChartOptions,
  getMaxAndMinYAxisValueWithPadding,
  getProvisionedDelegatedQtyAndLabels,
} from './usageLineChartUtils';

/**
 * A component that will display chart component for etla usage report
 */
const UsageLineChart = ({
  'aria-label': ariaLabel,
  isInactiveDataPresent,
  chartDataPoints,
  contractInactiveProvisionedQty,
  selectedPeriodType,
  selectedProductCreationTS,
}) => {
  const intl = useIntl();
  const {delegatedQty, labels, provisionedQty} = getProvisionedDelegatedQtyAndLabels(
    chartDataPoints,
    selectedPeriodType
  );

  const {maxWithPadding, minWithPadding} = getMaxAndMinYAxisValueWithPadding(
    {delegatedQty, provisionedQty},
    contractInactiveProvisionedQty,
    isInactiveDataPresent
  );

  const beginAtZero = minWithPadding === 0;

  const chartDataAndLabel = getChartLabelsAndDataSets(intl, labels, {
    contractInactiveProvisionedQty,
    delegatedQty,
    provisionedQty,
  });
  const chartOptions = getChartOptions(
    intl,
    selectedPeriodType,
    {
      beginAtZero,
      isInactiveDataPresent,
      maxWithPadding,
      minWithPadding,
      selectedProductCreationTS,
    },
    chartDataAndLabel
  );
  return (
    <div
      aria-label={ariaLabel}
      className="chart-container"
      data-testid="usage-line-chart"
      role="img"
    >
      <Line data={chartDataAndLabel} height={null} options={chartOptions} redraw width={null} />
    </div>
  );
};

UsageLineChart.propTypes = {
  /**
   * The aria-label for chart Component.
   */
  'aria-label': PropTypes.string.isRequired,
  /**
   * The chart data points fetched from FoxManager dataPoints API for a selected period type.
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  chartDataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * The chart data points fetched from FoxManager dataPoints API for a selected period type.
   */
  contractInactiveProvisionedQty: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * The provisioned qty array for contract inactive period that is fetched from FoxManager dataPoints API for a selected period type.
   */
  isInactiveDataPresent: PropTypes.bool.isRequired,
  /**
   * The selected period type.
   */
  selectedPeriodType: PropTypes.string.isRequired,
  /**
   * The selected product creation time stamp.
   */
  selectedProductCreationTS: PropTypes.number.isRequired,
};

export default UsageLineChart;
