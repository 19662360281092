const FULFILLMENT_TEST_TYPE = {
  EXCEPTION: 'EXCEPTION',
  NO_RESPONSE: 'NO_RESPONSE',
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
};
const PRODUCT_LIST_CACHE_ID = 'ProductList';
const PRODUCT_LIST_EVENT = {
  REFRESH: 'ProductListRefresh',
};
// Processing Instruction code param
const PRODUCT_LIST_PI_CODE = {
  GLOBAL_ADMINISTRATION: 'global_administration',
};

export {FULFILLMENT_TEST_TYPE, PRODUCT_LIST_CACHE_ID, PRODUCT_LIST_EVENT, PRODUCT_LIST_PI_CODE};
