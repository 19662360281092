import isEqual from 'lodash/isEqual';

import jilContacts from 'api/jil/jilContacts';
import Contact from 'models/contact/Contact';
import modelCache from 'services/cache/modelCache/modelCache';
import {
  CONTACT_LIST_CACHE_ID,
  CONTACT_LIST_EVENT,
  CONTACT_TYPE,
} from 'services/contact/ContactListConstants';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import {getListCacheKey} from 'utils/cacheUtils';
import {trimContact} from 'utils/contactUtils';

class ContactList {
  /**
   * @description Method to fetch the ContactList. It will be refreshed on
   *     registering the active org
   * @param {Object} options - options to configure the contact list, may be undefined. (see updateModel for details)
   * @param {Object} orgId - ID of org of which contacts should be fetched.
   * @returns {Promise<ContactList>} promise that resolves to the ContactList.
   */
  static get(options) {
    const model = new ContactList(options);
    const key = model.getKey();
    if (modelCache.has(CONTACT_LIST_CACHE_ID, key)) {
      const cachedPromise = modelCache.get(CONTACT_LIST_CACHE_ID, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    // Item is not already cached.
    const promise = model.refresh(options);
    modelCache.put(CONTACT_LIST_CACHE_ID, key, promise);
    return promise;
  }

  constructor(options = {}) {
    this.orgId = options.orgId;
    Object.assign(this, {
      dataAccessAdministrator: new Contact(options.dataAccessAdministrator),
      dataProtectionOfficer: new Contact(options.dataProtectionOfficer),
      securityOfficer: new Contact(options.securityOfficer),
    });
  }

  /**
   * @description Method to return the current unique key for this list.
   *
   * @returns {String} key to uniquely identify this list
   */
  getKey() {
    return getListCacheKey({orgId: this.orgId});
  }

  /**
   * @return {boolean} true if all contacts are complete
   */
  isAllContactsComplete() {
    return (
      this.dataAccessAdministrator.isComplete() &&
      this.dataProtectionOfficer.isComplete() &&
      this.securityOfficer.isComplete()
    );
  }

  /**
   * @return {boolean} true if all contacts are empty
   */
  isAllContactsEmpty() {
    return (
      this.dataAccessAdministrator.isEmpty() &&
      this.dataProtectionOfficer.isEmpty() &&
      this.securityOfficer.isEmpty()
    );
  }

  /**
   * @return {boolean} true if any contacts are partially complete
   */
  isContactsPartiallyComplete() {
    return !this.isAllContactsComplete() && !this.isAllContactsEmpty();
  }

  async refresh() {
    try {
      const response = await jilContacts.getContacts({orgId: this.orgId});
      updateModel(this, response.data);
      modelCache.put(CONTACT_LIST_CACHE_ID, this.getKey(), this);
      eventBus.emit(CONTACT_LIST_EVENT.REFRESH, this);
    } catch (error) {
      log.error('Contact list failed to load. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  async save(newContactInfo) {
    if (!hasUnsavedChanges(this, newContactInfo)) {
      return this;
    }

    const reducer = (result, type) =>
      // Trim any whitespace around new contact info
      ({...result, [type]: trimContact(newContactInfo[type])});
    const reqObj = Object.values(CONTACT_TYPE).reduce(reducer, {});

    try {
      const response = await jilContacts.putContacts(
        {
          orgId: this.orgId,
        },
        reqObj
      );
      updateModel(this, response.data);
      modelCache.put(CONTACT_LIST_CACHE_ID, this.getKey(), this);
      eventBus.emit(CONTACT_LIST_EVENT.REFRESH, this);
    } catch (error) {
      log.error('Failed to create/update contacts. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

/** Private Methods **/

/**
 * @description Compare if there is difference between the two contact lists
 * @param {ContactList} list1 - the original ContactList
 * @param {ContactList} list2 - the new ContactList
 * @return {boolean} true if there are difference
 */
function hasUnsavedChanges(list1, list2) {
  return Object.values(CONTACT_TYPE).some((type) => !isEqual(list1[type], list2[type]));
}

/**
 * @description Initializes contact list.
 *
 * @param {Object} model ContactList model Object instance to initialize
 * @param {Object} options initialization object
 * @param {Object} options.dataAccessAdministrator dataAccessAdministrator's contact info
 * @param {Object} options.dataProtectionOfficer dataProtectionOfficer's contact info
 * @param {Object} options.securityOfficer securityOfficer's contact info
 */
function updateModel(model, options) {
  Object.assign(model, {
    dataAccessAdministrator: new Contact(options.dataAccessAdministrator),
    dataProtectionOfficer: new Contact(options.dataProtectionOfficer),
    securityOfficer: new Contact(options.securityOfficer),
  });
}

export default ContactList;
