(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.shell.navigation.responsive-nav.factory:navDropdownManager
   * @description Factory that creates a service Object that is used to manage
   *   which dropdowns should be opened or closed at any given time. Since this
   *   service needs to control the open/closed behavior of dropdown menus, there
   *   are methods which allow dropdown menus to register callback functions when
   *   a dropdown transitions from opened to closed. This is necessary to allow
   *   dropdowns to close their own "on open" listeners that react to browser
   *   width changes, along with clicking outside of the dropdown menu (to close it).
   *
   *   Only responsive navigation menus that are oriented horizontally need to use
   *   the navDropdownManager to handle dropdown behavior, since vertically-oriented
   *   menus allow multiple dropdowns to be opened at once (removing the need to
   *   create listeners for on open behavior and the need to manage open dropdown
   *   state across menus and between menu items).
   */
  angular
    .module('binky.shell.navigation.responsive-nav')
    .factory('navDropdownManager', getDropdownManagerService);

  /* @ngInject */
  function getDropdownManagerService(_) {
    const dropdownMenus = {};
    const onCloseHandlers = {};

    const service = {
      areListenersRegistered,
      close,
      deregisterOnCloseHandler,
      open,
      registerDropdown,
      registerOnCloseHandler,
    };

    return service;

    ////////////

    /**
     * @description Method to determine if any listeners have been registered against
     *   a given dropdown menu.
     * @param {String} responsiveNavItemId - unique identifier of dropdown menu to check against
     * @returns {Boolean} true if listeners registered, else false
     */
    function areListenersRegistered(responsiveNavItemId) {
      return _.has(onCloseHandlers, responsiveNavItemId);
    }

    /**
     * @description Method to hide a dropdown menu and any applicable child menus.
     * @param {Number} responsiveNavItem - ResponsiveNavItem dropdown to hide
     * @returns {undefined} no return value
     */
    function close(responsiveNavItem) {
      if (responsiveNavItem.isGroup()) {
        _.forEach(responsiveNavItem.navItems, close);
      }
      closeDropdownAndCallHandlers(responsiveNavItem);
    }

    /**
     * @description Method to close all dropdowns and call any listeners associated
     *   with them.
     * @returns {undefined} no return value
     */
    function closeAllDropdowns() {
      _.forEach(dropdownMenus, (dropdownMenu) => {
        const openDropdowns = _.filter(dropdownMenu, 'isDropdownOpen');
        _.forEach(openDropdowns, (openDropdown) => {
          closeDropdownAndCallHandlers(openDropdown);
        });
      });
    }

    /**
     * @description Method to close a dropdown and call any listeners associated
     *   with it.
     * @param {ResponsiveNavItem} responsiveNavItem - nav item to close dropdown of
     * @returns {undefined} no return value
     */
    function closeDropdownAndCallHandlers(responsiveNavItem) {
      responsiveNavItem.isDropdownOpen = false;

      if (areListenersRegistered(responsiveNavItem.id)) {
        onCloseHandlers[responsiveNavItem.id]();
        deregisterOnCloseHandler(responsiveNavItem.id);
      }
    }

    /**
     * @description Method to remove a registered listener.
     * @param {String} responsiveNavItemId - unique identifier of dropdown to remove listener from
     * @returns {undefined} no return value
     */
    function deregisterOnCloseHandler(responsiveNavItemId) {
      _.unset(onCloseHandlers, responsiveNavItemId);
    }

    /**
     * @description Method to show a dropdown menu and any applicable parent menus.
     * @param {Number} responsiveNavItem - ResponsiveNavItem dropdown to show
     * @returns {undefined} no return value
     */
    function open(responsiveNavItem) {
      // close all dropdowns
      closeAllDropdowns();

      // now open any dropdowns that should be showing...
      openDropdown(responsiveNavItem);

      function openDropdown(thisResponsiveNavItem) {
        // if item has parent, make sure parent is open, too
        if (_.get(thisResponsiveNavItem, 'parent', false)) {
          openDropdown(thisResponsiveNavItem.parent);
        }
        thisResponsiveNavItem.isDropdownOpen = true;
      }
    }

    /**
     * @description Method to add a new ResponsiveNavItem that possesses a
     *   dropdown menu to the manager for tracking/management.
     * @param {ResponsiveNavItem} responsiveNavItem - nav item with dropdown to track
     * @returns {undefined} no return value
     */
    function registerDropdown(responsiveNavItem) {
      if (!_.has(dropdownMenus, responsiveNavItem.menuId)) {
        dropdownMenus[responsiveNavItem.menuId] = [];
      }
      dropdownMenus[responsiveNavItem.menuId].push(responsiveNavItem);
    }

    /**
     * @description Method to register a listener for on close events (will get
     *   called when dropdown is closed).
     * @param {String} id - unique identifier of dropdown to register listener against
     * @param {Function} callback - function to call when dropdown is closed
     * @returns {undefined} no return value
     */
    function registerOnCloseHandler(id, callback) {
      onCloseHandlers[id] = callback;
    }
  }
})();
