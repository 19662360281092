import {Heading} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {useModalDialogContext} from '../ModalDialogContext';

import styles from './ModalHeading.pcss';

/**
 * Component for ModalDialog's Header to share styles and ID logic
 * @param {Number} options.level - The header level to use (h2, h3, etc.). Defaults to 2.
 */
const ModalHeading = ({children, level = 2}) => {
  const {styleProps} = useModalDialogContext();

  return (
    <Heading
      data-testid="modal-header"
      // The Heading's id is automatically set by spectrum to correctly tie the dialog's aria-labelledby with the header.
      level={level}
      marginStart={styleProps.spaceX}
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set the heading style
      UNSAFE_className={styles.heading}
    >
      {children}
    </Heading>
  );
};

ModalHeading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
};

export default ModalHeading;
