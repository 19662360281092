// This can be removed with the Angular asset-sharing.service.js
const INVITATION_DOMAIN_SHARING = {
  ALL: 'all',
  CLAIMED_AND_TRUSTED: 'claimedAndTrusted',
  CLAIMED_AND_TRUSTED_WITH_DOMAINS: 'claimedAndTrustedWithDomains',
  NONE: 'none',
};

// This can be removed with the Angular asset-sharing.service.js
const POLICY = {
  INVITATION_DOMAIN_ALLOWLIST: 'invitationDomainAllowlist',
  INVITATION_DOMAIN_WHITELIST: 'invitationDomainWhitelist', // remove with temp_use_allowlist
  PUBLIC_SHARE: 'publicShare',
  REQUEST_ACCESS: 'requestAccess',
};

const GLOBAL_ADMIN_POLICY = {
  ALLOW_ADOBE_ID: 'allowAdobeID',
  ALLOW_ENTERPRISE_ID: 'allowEnterpriseID',
  ALLOW_FEDERATED_ID: 'allowFederatedID',
  CHANGE_IDENTITY_CONFIG: 'changeIdentityConfig',
  CLAIM_DOMAINS: 'claimDomains',
  CREATE_CHILDREN: 'createChildren',
  DELETE_ORGS: 'deleteOrgs',
  DIRECTORY_MOVE: 'directoryMove',
  INHERIT_ADMINS: 'inheritAdmins',
  INHERIT_SHARING_POLICY: 'inheritSharingPolicy',
  INHERIT_USERS: 'inheritUsers',
  INJECT_GROUP: 'injectGroup',
  MANAGE_ADMINS: 'manageAdmins',
  MANAGE_PRODUCTS: 'manageProducts',
  MANAGE_USER_GROUPS: 'manageUserGroups',
  NON_CHILD_ALLOCATION: 'nonChildAllocation',
  RENAME_ORGS: 'renameOrgs',
  SET_SHARING_POLICY: 'setSharingPolicy',
};

export {INVITATION_DOMAIN_SHARING, POLICY, GLOBAL_ADMIN_POLICY};
