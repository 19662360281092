import {View} from '@adobe/react-spectrum';
import {TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';

import DETAIL_SECTION_VARIANT_CONSTANTS from 'common/components/detail-section/DetailSectionVariantConstants';
import DetailSectionMessage from 'common/components/detail-section/detail-section-message/DetailSectionMessage';
import OverlayWait from 'common/components/wait/OverlayWait';

/**
 * Detail section is a widget with a header which contains a title and optional content which is
 * right-aligned with the title. An optional OverlayWait can be placed over the content area
 * while the content is loading. An optional error message can be shown if the expected content
 * is not available.
 *
 * This widget should be used to used to construct sections for drawers and detail pages.
 *
 * There are two variants of the Detail section: DRAWER and PAGE.
 * The DRAWER variant uses the default background color and has no borders or padding.
 * The PAGE variant has a gray-50 background, borders and padding.
 */
const DetailSection = ({
  children,
  errorText,
  headingLevel = 3,
  headingId,
  isLoading = false,
  variant = DETAIL_SECTION_VARIANT_CONSTANTS.DRAWER,
  rightHeaderContent,
  showError = false,
  title,
  tooltipDescription,
  ...titledSectionFlexProps
}) => {
  const id = useId(headingId);
  const ROW_GAP = 'size-200';
  const isDrawerVariant = variant === DETAIL_SECTION_VARIANT_CONSTANTS.DRAWER;

  let viewProps;

  // Sections in drawers do not have background color, borders or padding.
  if (!isDrawerVariant) {
    viewProps = {
      backgroundColor: 'gray-50',
      borderColor: 'gray-200',
      borderRadius: 'medium',
      borderWidth: 'thin',
      padding: 'size-200',
    };
  }

  return (
    <View data-testid="detail-section" marginBottom={ROW_GAP} {...viewProps}>
      <TitledSection
        {...titledSectionFlexProps}
        headingLevel={headingLevel}
        id={id}
        rightHeaderContent={rightHeaderContent}
        ruleVariant="M"
        title={title}
        tooltipDescription={tooltipDescription}
      >
        <OverlayWait isLoading={isLoading} size="S">
          <View
            data-testid="detail-section-message"
            marginBottom={isDrawerVariant ? 0 : ROW_GAP}
            marginTop={ROW_GAP}
          >
            {!showError && children}
            {showError && (
              <DetailSectionMessage
                messageKey="binky.common.detailsSection.error"
                translatedString={errorText}
              />
            )}
          </View>
        </OverlayWait>
      </TitledSection>
    </View>
  );
};

DetailSection.propTypes = {
  /**
   * The detail section contents.
   */
  children: PropTypes.node,
  /**
   * If showError is true, the children will be hidden and the errorText will be shown.
   * The default is 'This information isn’t available. Please try again shortly.'.
   */
  errorText: PropTypes.string,
  /**
   * The heading id.
   */
  headingId: PropTypes.string,
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * If true, a wait spinner will be shown in the content area.
   * children and errorText are not shown. The default is false.
   */
  isLoading: PropTypes.bool,
  /**
   * Content to be displayed on the right side of the header.
   */
  rightHeaderContent: PropTypes.node,
  /**
   * If true, the errorText will be shown if it is provided, otherwise a default
   * error message will be shown.
   */
  showError: PropTypes.bool,
  /**
   * The header text.
   */
  title: PropTypes.string.isRequired,
  /**
   * The tooltip description text.
   */
  tooltipDescription: PropTypes.string,
  /**
   * The variant of the section. Either 'DETAIL_SECTION_VARIANT_CONSTANTS.DRAWER' or 'DETAIL_SECTION_VARIANT_CONSTANTS.PAGE'.
   * The default is 'DETAIL_SECTION_VARIANT_CONSTANTS.DRAWER'.
   * The DRAWER variant uses the default background color and has no borders or padding. The PAGE variant has a gray-50 background, borders and padding.
   */
  variant: PropTypes.oneOf(Object.values(DETAIL_SECTION_VARIANT_CONSTANTS)),
};

export default DetailSection;
