(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.insights.insights-item-list-table
   * @description module that defines insights-item-list-table components
   */
  angular.module('binky.widgets.insights.insights-item-list-table', [
    'binky.core.insights.access',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.shell.panels.manager',
    'binky.widgets.common.toast',
    'binky.widgets.insights.create-insights-item-detail-modal',
    'pascalprecht.translate',
  ]);
})();
