(function () {
  /**
   * @deprecated
   */
  angular.module('binky.core.common.country').factory('RegionList', getRegionListModel);

  /* @ngInject */
  function getRegionListModel(
    $q,
    _,
    binkySrc2,
    modelCache,
    modelCacheUtils,
    Region,
    REGION_LIST_CACHE_ID
  ) {
    class RegionList {
      /**
       * @class
       * @description Constructor for RegionList model Objects.
       * @param {Object} options - options passed to the constructor
       * @param {String} [options.countryCode] - the countryCode the list is for
       */
      constructor(options) {
        this.countryCode = options.countryCode;
        this.items = [];
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return modelCacheUtils.getParameterizedKey({
          countryCode: this.countryCode,
        });
      }

      /**
       * @description Method to fetch the region list
       * @returns {Promise} promise - promise object, resolved when the region list is loaded.
       */
      refresh() {
        const deferred = $q.defer();
        const vm = this;

        vm.$resolved = false;
        vm.$promise = deferred.promise;

        binkySrc2.api.ims
          .getRegions({countryCode: this.countryCode})
          .then((response) => onSuccess(response.data))
          .catch((error) => onError(error.response));

        return deferred.promise;

        function onError(error) {
          vm.$resolved = true;
          deferred.reject(error);
        }

        function onSuccess(response) {
          vm.items = _.map(response.regions, (region) => new Region(region));
          vm.$resolved = true;
          deferred.resolve(vm);
          modelCache.put(REGION_LIST_CACHE_ID, vm, vm.key());
        }
      }

      /**
       * @description Method for getting all the regions for a country
       * @param {Object} [options] - Configuration options for list retrieval
       * @param {Boolean} [options.countryCode] the IMS country code to fetch regions for
       * @param {Boolean} [options.useCache=true] true to fetch from cache, else false
       * @returns {RegionList} new RegionList instance, fetched from back-end
       */
      static get(options) {
        const {countryCode, useCache = true} = options;

        const model = new RegionList({countryCode});

        if (useCache) {
          const cachedModel = modelCache.get(REGION_LIST_CACHE_ID, model.key());
          if (cachedModel) {
            return cachedModel;
          }
        }

        model.refresh();

        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(REGION_LIST_CACHE_ID, 1);

    return RegionList;
  }
})();
