import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';

import jilConsumables from 'api/jil/jilConsumables';
import JilModelList from 'services/modelList/JilModelList';

import ConsumableUsage from './ConsumableUsage';

class ConsumableUsageList extends JilModelList {
  /**
   * @description Creates a new ConsumableUsageList instance.
   *
   * @param {Object} options - initialization object
   * @param {String} [options.filterQuery] - query to filter results against
   * @param {ConsumableUsage[]} options.items - entities which make up this list
   * @param {Number} [options.pageNumber] - zero-based page number to fetch (defaults to 0)
   * @param {Number} [options.pageSize] - number of items to display per page (defaults to 20)
   * @param {Consumable} [options.parentConsumable] - the Consumable to which this
   *   list belongs (if parentConsumable arg also passed, it will take
   *   precedence). Must be present if not passing parentConsumable arg (below)
   * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
   * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC; defaults to ASC)
   * @param {number} options.usageTotal - total usages available to be queried (defaults to 0)
   * @param {Consumable} [parentConsumable] - the Consumable to which this list
   *   belongs, if not passed as an option must be passed as own argument
   */
  constructor(options, parentConsumable) {
    super({
      filterQuery: options.filterQuery,
      itemClassRef: ConsumableUsage,
      itemCount: options.usageTotal || 0,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize,
      resource: jilConsumables.getUsages,
      sortExpression: options.sortExpression,
      sortOrder: options.sortOrder,
      transformResponseData: transformItems,
    });

    this.items = transformItems(options.items);
    this.parentConsumable = parentConsumable || options.parentConsumable;

    Object.assign(this, getQueryParams(this));
  }

  /**
   * @description Method to fetch the updated array of ConsumableUsages
   */
  refresh() {
    return super.refresh(getQueryParams(this));
  }
}

//////////////

/**
 * @description Method to get values from the parent Consumable object to be
 *     assigned to the model
 *
 * @param {ConsumableUsageList} model - the model with a parent Consumable
 *
 * @returns {Object} values to be assigned with keys converted to snake case
 */
function getQueryParams(model) {
  const parentParams = mapKeys(model?.parentConsumable?.getQueryParams(), (value, key) =>
    snakeCase(key)
  );

  const result = pick(parentParams, [
    'contract_id',
    'fulfillable_item_code',
    'fulfillment_id',
    'group_id',
    'include_depleted',
    'include_expired',
    'license_id',
    'organization_id',
  ]);
  result.orgId = parentParams.organization_id;

  return result;
}

/**
 * @description Method to transform the list of ConsumableUsage items
 *
 * @param {ConsumableUsage[]} items - the raw item data
 *
 * @returns {ConsumableUsage[]} the transformed ConsumableUsage items, or an
 *     empty array if the item data was missing
 */
function transformItems(items) {
  return items?.map((item) => ConsumableUsage.apiResponseTransformer(item)) || [];
}

export default ConsumableUsageList;
