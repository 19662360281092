import {getSettledPromisesAndMergedResult} from '../services/analytics/analyticsUtils';
import OrganizationUserListCount from '../services/users/OrganizationUserListCount';

/**
 * @description Add/Assign User Analytics
 * @param {String} [options.orgId] Organization ID for analytics info
 */
async function constructAsyncAddAssignUserAnalytics(options) {
  const {orgId} = options;

  const result = await getSettledPromisesAndMergedResult([
    getAsyncOrganizationInfoAnalytics(orgId),
  ]);
  return result;
}

async function getAsyncOrganizationInfoAnalytics(orgId) {
  const result = await OrganizationUserListCount.get({
    orgId,
  });
  return {organizationInfo: {totalCount: result.totalCount}};
}

export {constructAsyncAddAssignUserAnalytics};
