(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum Accordion
   *
   * @ngdoc component
   * @name binky.widgets.common.accordion:binkyAccordion
   *
   * @description A specialized accordion widget with only one expandable accordion item.
   *   The label and items can either be specified via bindings or transcluded. For each, transclusion takes precedence
   *   over bindings.
   *   If a labelKey is specified, it is formatted with messageformat and expects a count to be present somewhere in
   *   the message string.
   *
   *   To transclude:
   *    <binky-accordion>
   *      <accordion-item-label>
   *          your label here
   *      </accordion-item-label>
   *      <accordion-item-content>
   *          your content here
   *      </accordion-item-content>
   *    </binky-accordion>
   *
   * @binding [Boolean] allowHtml allows appending the content to the DOM
   * @binding [String] class. Optional class to add to each of the items displayed in the content
   *   section of the accordion.
   * @binding [Array] items. List of items for the accordion content section. See itemSelector.
   * @binding [Function|String] itemSelector. Function getter that returns a string or path to item property in items.
   * @binding [String] label.  Optional label. If not specified, then labelKey or the
   *  accordion-item-label transclusion slot must be specified.
   * @binding [int] labelCount. Used with labelKey. Optional messageformat arg. If omitted, items.length will be used.
   * @binding [String] labelKey.  Optional key for messageformat type message. If not specified,
   *   then label or the accordion-item-label transclusion slot must be specified.
   * @binding [Boolean] selected. Whether the accordion should be selected (i.e. opened).
   */
  angular.module('binky.widgets.common.accordion').component('binkyAccordion', {
    bindings: {
      allowHtml: '<?',
      itemClass: '@?',
      items: '<',
      itemSelector: '@',
      label: '@?',
      labelCount: '<?', // if omitted, uses items.length
      labelKey: '@?',
      selected: '<?',
    },
    controller,
    templateUrl: 'widgets/common/accordion/accordion.component.html',
    transclude: {
      'accordion-item-content': '?accordionItemContent',
      'accordion-item-label': '?accordionItemLabel',
    },
  });

  /* @ngInject */
  function controller($compile, $element, $scope, $transclude, _, translationUtils) {
    const vm = this;

    const transcludeLabelAndContent =
      $transclude.isSlotFilled('accordion-item-label') &&
      $transclude.isSlotFilled('accordion-item-content');

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onChanges() {
      if (transcludeLabelAndContent) {
        const existingElements = $element[0].querySelectorAll('coral-accordion-item');
        if (existingElements.length > 0) {
          existingElements[0].selected = vm.selected ? 'selected' : undefined;
          return;
        }
      }

      const coralAccordion = new Coral.Accordion();
      const accordionElement = angular.element(coralAccordion);
      accordionElement.addClass('binky-accordion');

      coralAccordion.items.add({
        content: {
          innerHTML: transcludeLabelAndContent ? '' : getSafeContentHtml(),
        },
        label: {
          innerHTML: transcludeLabelAndContent ? '' : getSafeLabelHtml(),
        },
        selected: vm.selected ? 'selected' : undefined,
      });

      if (transcludeLabelAndContent) {
        $element.append(coralAccordion);
      } else {
        $element.empty().append(coralAccordion);
      }
    }

    function $onInit() {
      if (transcludeLabelAndContent) {
        Coral.commons.ready(() => {
          appendTranscludedElements();
        });
      }
    }

    ////////////

    // Manually transclude coral elements to get around issues transcluding into a Coral modal
    // https://git.corp.adobe.com/admin-tribe/onesie/issues/6241
    function appendTranscludedElements() {
      const labelNodes = $element[0].querySelectorAll('coral-accordion-item-label');
      const labelInsertionPoint = labelNodes[labelNodes.length - 1];
      labelInsertionPoint.appendChild($element[0].querySelector('.item-label'));

      const contentNodes = $element[0].querySelectorAll('coral-accordion-item-content');
      const contentInsertionPoint = contentNodes[contentNodes.length - 1];
      contentInsertionPoint.appendChild($element[0].querySelector('.item-content'));
    }

    function getSafeContentHtml() {
      let contentHtml = '';
      _.forEach(vm.items, (item) => {
        const value = _.isFunction(item[vm.itemSelector])
          ? _.invoke(item, vm.itemSelector)
          : _.get(item, vm.itemSelector);
        if (value) {
          const div = angular.element('<div class="item-content"></div>');
          if (vm.allowHtml) {
            $compile(div.append(value))($scope);
          } else {
            div.text(value);
          }
          if (vm.itemClass) {
            div.addClass(vm.itemClass);
          }
          contentHtml += div[0].outerHTML;
        }
      });

      return contentHtml;
    }

    function getSafeLabelHtml() {
      const itemCount = _.isUndefined(vm.labelCount) ? _.get(vm.items, 'length', 0) : vm.labelCount;
      const label = vm.label
        ? vm.label
        : translationUtils.sanitizeSafeInstant(vm.labelKey, {count: itemCount}, 'messageformat');

      const span = angular.element('<span class="item-label"></span>');
      span.text(label);
      return span[0].outerHTML;
    }
  }
})();
