(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.button-bar
   * @description module that defines button-bar widgets
   */
  angular.module('binky.widgets.common.button-bar', [
    'binky.core.lodash',
    'pascalprecht.translate',
  ]);
})();
