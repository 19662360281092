(function () {
  /**
   * @ngdoc module
   * @name binky.core.user.linked-accounts
   * @description Module which defines linked accounts models
   */
  angular.module('binky.core.user.linked-accounts', [
    'binky.core.api.clype',
    'binky.core.common.list',
    'binky.core.common.member.type',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
