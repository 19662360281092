(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.toast
   * @description module that defines toast widgets
   */
  angular.module('binky.widgets.common.toast', [
    'binky.core.dom-utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.ui-migration',
    'pascalprecht.translate',
  ]);
})();
