(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   */
  angular
    .module('binky.widgets.insights.create-insights-item-detail-modal')
    .constant('CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID', 'create-insights-item-detail-modal')
    .constant('INSIGHTS_ITEM', {
      ACTION: {
        CREATE: 'CREATE',
      },
      CONTENT_TYPE: {
        DATE_RANGE: 'DATE_RANGE',
        DROPDOWN: 'DROPDOWN',
        SEARCH: 'SEARCH',
      },
      DROPDOWN_TYPE: {
        CONTRACTS: 'contracts',
      },
    });
})();
