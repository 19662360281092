(function () {
  angular.module('binky.core.directories', [
    'binky.core.api.jil',
    'binky.core.api.kms',
    'binky.core.common.model.cache',
    'binky.core.directory-sync',
    'binky.core.domains',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.organizations.encryption',
  ]);
})();
