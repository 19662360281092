import {Flex, View} from '@adobe/react-spectrum';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {cloneElement, createContext, useEffect, useMemo, useRef, useState} from 'react';
import {Outlet} from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import {
  COLLAPSED_DISPLAY_BOUNDARY,
  HORIZONTAL_DISPLAY_BOUNDARY,
  ORIENTATION,
} from './nav/WorkspaceNavConstants';
import Page from './page/Page';

const DEBOUNCE_TIME = 500; // in milliseconds

const WorkspaceContext = createContext(null);

/**
 * Workspace component to wrap the page content and the workspace nav.
 */
const Workspace = ({children, useOutlet = false}) => {
  const [orientation, setOrientation] = useState(ORIENTATION.HORIZONTAL);
  const [shouldShowAsCollapsed, setShouldShowAsCollapsed] = useState(false);

  const findElementInChildren = ({element, displayName}) => {
    const childrenArr = React.Children.toArray(children);

    let targetElement;

    if (element) {
      targetElement = childrenArr.find((child) => child.type === element);
    }

    if (displayName) {
      targetElement = childrenArr.find((child) => child.type?.displayName?.includes(displayName));
    }

    if (targetElement) {
      return targetElement;
    }

    throw new Error(
      childrenArr.length === 0
        ? 'No children were provided to the Workspace component'
        : 'Failed to find target element'
    );
  };

  const getStyles = () => (shouldShowAsCollapsed ? {} : {marginLeft: '-16px'});

  const workspaceNav = findElementInChildren({displayName: 'WorkspaceNav'});

  const pageProps = {isInWorkspace: true, orientation};
  const pageContent = !useOutlet && cloneElement(findElementInChildren({element: Page}), pageProps);

  const sizeWorkspace = ({width}) => {
    let isUnder400PercentZoom = true;
    if (typeof window !== 'undefined') {
      isUnder400PercentZoom = Math.floor(window.devicePixelRatio) < 4;
    }
    if (width <= COLLAPSED_DISPLAY_BOUNDARY || !isUnder400PercentZoom) {
      setOrientation(undefined);
      setShouldShowAsCollapsed(true);
    } else if (width <= HORIZONTAL_DISPLAY_BOUNDARY) {
      setOrientation(ORIENTATION.HORIZONTAL);
      setShouldShowAsCollapsed(false);
    } else {
      setOrientation(ORIENTATION.VERTICAL);
      setShouldShowAsCollapsed(false);
    }
  };

  const sizeWorkspaceOnAnimationFrame = ({width}) => {
    if (typeof window === 'undefined') {
      sizeWorkspace({width});
    } else {
      // We embed this inside the animation frame to avoid two NewRelic observed errors:
      // ResizeObserver loop completed with undelivered notifications
      // ResizeObserver loop limit exceeded
      //
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- checked above
      window.requestAnimationFrame(() => sizeWorkspace({width}));
    }
  };

  // Set initial size
  useEffect(() => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- window is required for test renders that cover hooks
    // istanbul ignore else
    if (typeof window !== 'undefined') {
      sizeWorkspace({width: window.innerWidth});
    }
  }, []);

  const workspaceRef = useRef();
  useResizeObserver({
    onResize: debounce(sizeWorkspaceOnAnimationFrame, DEBOUNCE_TIME, {
      leading: true,
      trailing: true,
    }),
    ref: workspaceRef,
  });

  const contextValue = useMemo(
    () => ({orientation, shouldShowAsCollapsed}),
    [orientation, shouldShowAsCollapsed]
  );

  return (
    <div ref={workspaceRef}>
      <WorkspaceContext.Provider value={contextValue}>
        <Flex
          direction={
            orientation === ORIENTATION.HORIZONTAL || shouldShowAsCollapsed ? 'column' : 'row'
          }
        >
          <View marginEnd="size-300" UNSAFE_style={getStyles()}>
            {workspaceNav}
          </View>
          {useOutlet ? <Outlet context={pageProps} /> : pageContent}
        </Flex>
      </WorkspaceContext.Provider>
    </div>
  );
};

Workspace.propTypes = {
  /**
   * Children elements to slot into this component. Currently only expecting a
   * WorkspaceNav or a Page element. If useOutlet is true, it will only expect a WorkspaceNav.
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether or not to "slot" in the Page component into React Router's Outlet. Defaults to false.
   * If false, Workspace will find the Page in its children via displayName and "slot" it in manually.
   */
  useOutlet: PropTypes.bool,
};

export {WorkspaceContext};
export default Workspace;
