(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.insights.access')
    .constant('AVAILABLE_INSIGHTS_TAB', {
      NAMES: ['files', 'view'],
    })
    .constant('INSIGHTS_ITEM_ACCESS_LIST_CACHE_ID', 'InsightsItemAccessList')
    .constant('INSIGHTS_SECTION', {
      DASHBOARD: 'dashboard',
      LOGS: 'logs',
      REPORTS: 'reports',
    });
})();
