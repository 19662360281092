(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.invoice:Invoice
   * @description Model for an individual Invoice
   */
  angular.module('binky.core.invoice').factory('Invoice', getInvoiceModel);

  /* @ngInject */
  function getInvoiceModel(_, INVOICE_UNPAID_STATUSES) {
    class Invoice {
      /**
       * @description Creates a new Invoice for use.
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.id an Invoice's ID
       * @param {Object} options.currency Invoice currency delimiter, formatString, symbol, usePrecision, iso3Code
       * @param {Object} options.dates Key invoice dates: invoiceDate, billingStartDate, billingEndDate
       * @param {String} options.invoiceStatus Invoice status: can be PAID, PAYMENT_DUE, PAST_DUE, or PROCESSING
       * @param {Number} options.noOfDays This can be applied to invoice status PAYMENT_DUE or PAST_DUE and is the number
       * of days before an invoice must be paid or number of days past the due date is has not been paid respectively
       * @param {String} options.poNumber Purchase Order number associated with an invoice
       * @param {Object} options.total Invoice totals: amountWithoutTax, amountWithTax, tax, taxRate
       * @param {Object} options.link Link to Invoice pdf: rel, href
       * @param {Boolean} options.latest true if this is the latest invoice
       *
       */
      constructor(options) {
        applyResource(this, options);
      }

      /**
       * @description Returns whether or not the invoiceStatus is unpaid: PAYMENT_DUE, PAST_DUE
       * @returns {Boolean} true if invoiceStatus is one of the INVOICE_UNPAID_STATUSES
       */
      isUnpaid() {
        return _.includes(INVOICE_UNPAID_STATUSES, this.invoiceStatus);
      }

      /**
       * @description generate model subset containing only API relevant fields.
       * @returns {Object} API relevant subset of Invoice object.
       */
      toMinimumModel() {
        return toMinimumObject(this);
      }

      /** Static Methods **/
      /**
       * @class
       * @description Transforms a data response Object into a new Invoice
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @param {String} dataTransferObject.id an Invoice's ID
       * @param {Object} dataTransferObject.currency, Currency used. currency: units, delimiter, formatString, symbol, usePrecision, iso3Code
       * @param {Object} dataTransferObject.dates, Key Invoice dates. dates: invoiceDate, billingStartDate, billingEndDate
       * @param {String} dataTransferObject.invoiceStatus Invoice status: can be PAID, PAYMENT_DUE, PAST_DUE, or PROCESSING
       * @param {Number} dataTransferObject.noOfDays This can be applied to invoice status PAYMENT_DUE or PAST_DUE and is the number
       * of days before an invoice must be paid or number of days past the due date is has not been paid respectively
       * @param {String} poNumber Purchase Order number associated with an invoice
       * @param {Object} dataTransferObject.total, Invoice totals. total: amountWithoutTax, amountWithTax, tax, taxRate
       * @param {Object} dataTransferObject.link, Link to PDF. link: rel, href
       * @param {Boolean} dataTransferObject.latest true if this is the latest invoice
       */
      static apiResponseTransformer(dataTransferObject) {
        return new Invoice(dataTransferObject);
      }
    }
    return Invoice;

    //////////////

    function applyResource(invoice, resource) {
      angular.extend(invoice, toMinimumObject(resource));
      return invoice;
    }

    function toMinimumObject(object) {
      return _.pick(object, [
        'currency',
        'dates',
        'id',
        'invoiceStatus',
        'latest',
        'link',
        'noOfDays',
        'poNumber',
        'total',
      ]);
    }
  }
})();
