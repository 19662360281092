/* eslint-disable @admin-tribe/admin-tribe/jsdoc-exported-functions -- bluu@ to update*/
import {useState} from 'react';

import useSelection from '../selection/useSelection';

import ASSIGNMENT_MENU_CONSTANTS from './AssignmentMenuConstants';

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

//
// @description A helper hook to build AssignmentMenuContext context object which will hold
// relevant state and fn for the menu. Hook will initialize state for the submenus. Initial product list
// state must be passed in when using product or product profile menu. User groups will be lazy loaded and
// don't require an init
//
// @example - initializing a single menu of products
// const menuContext = useAssignmentMenu({
//     selectableItems: {
//       [TARGET_TYPE.PRODUCTS]: initProductList,
//       [TARGET_TYPE.CONTRACTS]: initContractList
//     },
// });
//
// @example - initializing a single menu for profiles of a specific product
// const menuContext = useAssignmentMenu({
//     selectableItems: {
//       [TARGET_TYPE.PRODUCTS]: initProductList
//       productIdForProfileSelection: productId //
//     },
// });
//
// @example - initializing a single menu for user group selection
// const menuContext = useAssignmentMenu();
//
// @param {Object} [options] options init object
// @param {Object} [options.selectableItems] An object with keys of TARGET_TYPE value
// @param {Array<Product>} [options.selectableItems.products] The list of products to be initialized as selectableItems
// @param {String} [options.productIdForProfileSelection] The product id whose license groups will be made
//          selectable. Required if we want to display the single ProductProfileMenu. Will find product within selectableItems.products.
// @returns {Object} with the following fields: (same as obj returned from useSelection and more)
//                    deselectAllItems,
//                    deselectItem,
//                    disableItems,
//                    isSelected,
//                    menuCache - menuCache object used for caching, keyed by target type
//                    productProfileTargetId - product id used for ProductProfileMenu
//                    selectableItems,
//                    selectAllItems,
//                    selectedItems,
//                    selectionStatus,
//                    selectItem,
//                    setMenuCache
//                    setSelectableItems,
//                    setSelectedItems,
//                    toggleItem,
//                    updateSelectableItemsOfClass - fn to replace selectable items of given class
//
const useAssignmentMenu = (options = {}) => {
  const [disabledItems, setDisabledItems] = useState([]);
  const [disabledProductProfiles, setDisabledProductProfiles] = useState([]);
  const {selectableItems = {}} = options;
  const selectionOptions = {
    // Default to empty array if not given any products. some items (e.g. user groups) are lazy loaded
    selectableItems: selectableItems[TARGET_TYPE.PRODUCTS] || [],
  };
  const menuContext = useSelection(selectionOptions);

  const disableItems = (newDisabledItems) => {
    setDisabledItems(newDisabledItems);
  };

  const disableProductProfiles = (newDisabledItems) => {
    setDisabledProductProfiles(newDisabledItems);
  };

  const isDisabled = (item) => disabledItems.some((i) => i.id === item.id);
  const isProductProfileDisabled = (item) => disabledProductProfiles.some((i) => i.id === item.id);

  // Function to replace the selectableItems of the same class with the new items
  const updateSelectableItemsOfClass = (classRef, newItems) => {
    const selectableItemsOfDiffClass = menuContext.selectableItems.filter(
      (item) => !(item instanceof classRef)
    );
    menuContext.setSelectableItems([...selectableItemsOfDiffClass, ...newItems]);
  };

  // Store cached state for the menu so when menu is closed, latest state is restored. Keyed by TARGET_TYPE
  const [menuCache, setMenuCache] = useState({});

  return {
    ...menuContext,
    consumableSummarizationList: options.consumableSummarizationList,
    disableItems,
    disableProductProfiles,
    isDisabled,
    isProductProfileDisabled,
    menuCache,
    productIdForProfileSelection: options.productIdForProfileSelection,
    role: options.role,
    setMenuCache,
    updateSelectableItemsOfClass,
  };
};

export default useAssignmentMenu;
/* eslint-enable @admin-tribe/admin-tribe/jsdoc-exported-functions -- bluu@ to update*/
