import {Flex, Text, View} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import Help from '@spectrum-icons/workflow/Help';
import Info from '@spectrum-icons/workflow/Info';
import flow from 'lodash/flow';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedRelativeTime, useIntl} from 'react-intl';

import {getPersonName} from 'features/user/personNameUtils';

import Avatar from '../avatar/Avatar';

import styles from './Comment.pcss';
import DeleteComment from './delete-comment/DeleteComment';

/**
 * Widget for displaying comments that are generated from various BPS workflows.
 * e.g. Support tickets in AAC, and Organization/Ticket details in AAUI.
 */
const Comment = ({
  bannerContent,
  bannerVariant = 'warning',
  commentContent,
  creationDate,
  member,
  onDelete,
  title,
}) => {
  const intl = useIntl();
  const BANNER_ICONS = {
    error: (
      <Alert
        aria-label={intl.formatMessage({id: 'binky.common.icon.alert.ariaLabel'})}
        color="negative"
        size="XS"
      />
    ),
    help: (
      <Help
        aria-label={intl.formatMessage({id: 'binky.common.icon.help.ariaLabel'})}
        color="informative"
        size="XS"
      />
    ),
    info: (
      <Info
        aria-label={intl.formatMessage({id: 'binky.common.icon.info.ariaLabel'})}
        color="informative"
        size="XS"
      />
    ),
    warning: (
      <Alert
        aria-label={intl.formatMessage({id: 'binky.common.icon.alert.ariaLabel'})}
        color="notice"
        size="XS"
      />
    ),
  };
  const BANNER_COLORS = {
    error: 'negative',
    help: 'informative',
    info: 'informative',
    warning: 'notice',
  };

  function getCommentTitle() {
    if (title) {
      return title;
    }
    if (member) {
      const options = flow(
        (_options) => pick(_options, ['firstName', 'lastName']),
        (_options) => Object.assign(_options, {userId: get(member, 'id')}),
        (_options) => omitBy(_options, (option) => option === undefined)
      )(member);
      // If we don't have the title key, but we have the user, we use the getPersonName utility function to display them
      return getPersonName(intl, options);
    }
    return '';
  }
  return (
    <>
      <Flex alignItems="baseline" marginBottom="size-100">
        <Avatar
          alt={`${getPersonName(intl, member)} avatar`}
          data-testid="comment-avatar"
          marginBottom="size-0"
          marginStart="size-50"
          member={member}
          size="XXS"
        />
        <View
          data-testid="comment-title"
          elementType="p"
          marginBottom="size-0"
          marginX="size-100"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the comment title
          UNSAFE_className={styles['comment-title']}
        >
          {getCommentTitle()}
        </View>
        <View
          data-testid="comment-creation-date"
          elementType="p"
          marginBottom="size-0"
          marginEnd="size-50"
          marginStart="auto"
          minWidth="size-1000"
          order="2"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the creation date
          UNSAFE_className={styles['comment-creation-date']}
        >
          <FormattedRelativeTime
            numeric="auto"
            updateIntervalInSeconds={10}
            value={Math.floor((new Date(creationDate).getTime() - Date.now()) / 1000)}
          />
        </View>
      </Flex>
      <View backgroundColor="gray-75" borderRadius="large" elementType="div" padding="size-0">
        {bannerContent && (
          <View
            borderColor={BANNER_COLORS[bannerVariant]}
            borderTopEndRadius="large"
            borderTopStartRadius="large"
            borderWidth="thin"
            data-testid="banner-container"
            elementType="div"
            paddingX="size-200"
            paddingY="size-200"
          >
            <Flex padding="size-100">
              <View>{BANNER_ICONS[bannerVariant]}</View>
              <Text data-testid="banner-content" marginStart="size-100">
                {bannerContent}
              </Text>
            </Flex>
          </View>
        )}
        <View
          borderBottomEndRadius={bannerContent && 'large'}
          borderBottomStartRadius={bannerContent && 'large'}
          borderColor="gray-300"
          borderRadius={bannerContent ? undefined : 'large'}
          borderWidth="thin"
          data-testid="comment-container"
          paddingX="size-200"
          paddingY="size-100"
        >
          <Flex
            alignItems="center"
            data-testid="comment-content"
            // Preserving all whitespace and newline characters here to preserve human-written spacing in the way it was entered.
            UNSAFE_style={{whiteSpace: 'pre-wrap'}}
          >
            {commentContent}
            {!!onDelete && (
              <View alignSelf="flex-start" justifySelf="flex-end" marginStart="auto" order="2">
                <DeleteComment data-testid="delete-comment" onDelete={onDelete} />
              </View>
            )}
          </Flex>
        </View>
      </View>
    </>
  );
};

Comment.propTypes = {
  /**
   * Content that will be displayed in the banner.
   */
  bannerContent: PropTypes.string,
  /**
   * Variant of the banner that will be shown (defaults to warning).
   */
  bannerVariant: PropTypes.oneOf(['error', 'help', 'info', 'warning']),
  /**
   * The publisher's message content.
   */
  commentContent: PropTypes.node.isRequired,
  /**
   * The creation date of the comment, in ISO8601 format.
   */
  creationDate: PropTypes.string.isRequired,
  /**
   * The member whose properties will be used to populate the comment title.
   * If provided, and no override is set, this will be used for the title, and
   * it will be used to create the avatar. This requires the following fields:
   * `firstName`, `id`, `lastName`
   */
  member: PropTypes.shape({
    /**
     * First name of the user.
     */
    firstName: PropTypes.string,
    /**
     * Id of the user.
     */
    id: PropTypes.string,
    /**
     * Last name of the user.
     */
    lastName: PropTypes.string,
  }),
  /**
   * Callback to invoke when the comment is deleted.
   */
  onDelete: PropTypes.func,
  /**
   * The text to use as the title. If not provided, the user `displayName` will
   * be used.
   */
  title: PropTypes.string,
};

export default Comment;
