(function () {
  /**
   * @ngdoc module
   * @name binky.core.object-utils
   * @description Module which defines object utility helpers
   */
  angular.module('binky.core.object-utils', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
