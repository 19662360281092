(function () {
  /**
   * @ngdoc module
   * @name binky.core.search.available-type-list
   * @description module that defines the AvailableTypeUserList model
   */
  angular.module('binky.core.search.available-type-list', [
    'binky.core.api.jil',
    'binky.core.email-utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
