import get from 'lodash/get';
import has from 'lodash/has';

/**
 * An object to store the configuration fetched for different services.
 */
let config = {};
const configStore = {
  /**
   * Get the value with given key for the configuration
   * @param {String} key - Key for fetching certain value
   * @return {Object} - the corresponding value
   */
  get: (key) => get(config, key),

  /**
   * Verify if the certain key is in the config object
   * @param {String} key - Key for fetching certain value
   * @return {Boolean} - True if the object contains the key, false for otherwise
   */
  has: (key) => has(config, key),

  /**
   * Pass the configuration content for the object
   * @param {Object} configData - A configuration object
   */
  initialize: (configData) => {
    config = configData;
  },

  /**
   * Return the configuration object
   * @return {Object} - A configuration object
   */
  loadConfig() {
    return config || {};
  },

  /**
   * Verify if the configuration is loaded
   * @return {Boolean} - True if the object is loaded, false for otherwise
   */
  whenConfigLoaded() {
    return config !== undefined;
  },
};

export default configStore;
