(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.directories.directory-list-table:binkyDirectoryListTable
   * @description display list of owned and trusted directories for an organization.
   */
  angular
    .module('binky.widgets.directories.directory-list-table')
    .component('binkyDirectoryListTable', {
      bindings: {
        canViewEncryptionStatus: '<',
        directoryLinks: '<',
        directoryList: '<',
        disableDirectoryActions: '<?',
        display: '<?',
        onApproveClick: '<?',
        onConfigureSamlClick: '<?',
        onRowClick: '<?',
        removedSyncDirectoryIdsList: '<',
        viewDirectorySref: '@',
      },
      controller,
      templateUrl: 'widgets/directories/directory-list-table/directory-list-table.component.html',
    });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    $state,
    $timeout,
    $translate,
    _,
    binkyUISrc2,
    DIRECTORY_ENCRYPTION_STATUS,
    DIRECTORY_LIST_PAGINATION_ID,
    feature,
    JIL_CONSTANTS,
    Selection,
    toastManager
  ) {
    const encryptionStatusNotApplicable = {
      encryptionStatus: DIRECTORY_ENCRYPTION_STATUS.NOT_APPLICABLE,
    };
    const vm = this;

    _.assign(vm, {
      $onInit,
      clearDirectoryBeingMoved,
      feature,
      getColumnCount,
      getDirectoryCheckboxLabel,
      getDirectoryTrLabel,
      getEncryptionInfo,
      isEditable,
      onClickApprove,
      onClickConfigureSaml,
      onClickRow,
      onEditLinkClick,
      onOpenMoveDirectoryModal,
      onPageChange,
      onPageSizeChange,
      requiresIdpMigration,
      requiresLinkConfig,
      reverseSort,
      showAutoRemovedSyncStatus,
      showDirectoryMoveSuccessToastAndRefresh,
      showDomainEnforcement,
      showEditLink,
      showLink,
    });

    function $onInit() {
      _.assign(vm, {
        allActionCallbacks: $q.resolve(),
        directoryHeaderCheckboxLabel: $translate.instant(
          'binky.widgets.directories.directoryListTable.selectAll'
        ),
        DirectoryMoveModalWrapper: binkyUISrc2.common.components.DirectoryMoveModalWrapper,
        directoryTrRole: 'row',
        directoryTrTabindex: '0',
        DomainEnforcementStatus: binkyUISrc2.common.components.DomainEnforcementStatus,
        paginationId: DIRECTORY_LIST_PAGINATION_ID,
        selection: new Selection('id'),
        sortOrderChanged: false,
        sortOrderChangedId: _.uniqueId('directory-list-sort-order-changed-'),
        sortOrderingId: _.uniqueId('directory-list-sort-ordering-'),
        sortReverse: false,
        wait: {},
      });
      setSelectableItems();
    }

    ///////////////

    /**
     * closes the directory move modal.
     *
     * Note: this function is meant to be called from non-angular context
     * currently, since it calls $apply which should only be used for external
     * changes to be digested by angular
     */
    function clearDirectoryBeingMoved() {
      $scope.$apply((vm.directoryBeingMoved = undefined));
    }

    // Gets the number of table columns rendered.  This equals the number of truthy
    // properties in the display object: ignoring 'actions' and 'checkbox'.
    // The 'name' column is also always shown.
    // @returns {Integer} the number of visible columns
    function getColumnCount() {
      return (
        _(vm.display)
          .keys()
          .filter((key) => !_.includes(['actions', 'checkbox', 'name'], key) && vm.display[key])
          .value().length + 1
      );
    }

    function getDirectoryCheckboxLabel(directory) {
      return $translate.instant('binky.widgets.directories.directoryListTable.selectDirectory', {
        directoryName: directory.name,
      });
    }

    function getDirectoryTrLabel(directory) {
      return $translate.instant('binky.widgets.directories.directoryListTable.rowLabel', {
        directoryName: directory.name,
      });
    }

    function getEncryptionInfo(directory) {
      return vm.canViewEncryptionStatus
        ? directory.encryptionStatusInfo
        : encryptionStatusNotApplicable; // return a const to avoid additional digest cycle
    }

    function isEditable(directory) {
      return directory.type !== 'TYPE2E';
    }

    /**
     * Callback called when the approve button on a row is clicked
     *
     * @param {Directory} directory - directory object of clicked row
     */
    function onClickApprove(directory) {
      _.invoke(vm, 'onApproveClick', directory);
    }

    /**
     * Callback called when the configure button on a row is clicked
     *
     * @param {Directory} directory - directory object of clicked row
     */
    function onClickConfigureSaml(directory) {
      if (!vm.disableDirectoryActions) {
        _.invoke(vm, 'onConfigureSamlClick', directory);
      }
    }

    /**
     * Callback called when a table row is clicked
     *
     * @param {any} $event - angular event
     * @param {Directory} directory - directory object of clicked row
     */
    function onClickRow($event, directory) {
      // Throwaway clicks in certain areas of the row (i.e. the checkbox)
      if (wasClickOnReservedElement($event)) {
        return;
      }
      _.invoke(vm, 'onRowClick', directory);
    }

    function onEditLinkClick(directory) {
      if (!vm.disableDirectoryActions) {
        $state.go(vm.viewDirectorySref, {
          directoryId: directory.id,
          orgId: directory.orgId,
        });
      }
    }

    function onOpenMoveDirectoryModal(directory) {
      vm.directoryBeingMoved = directory;
    }

    function onPageChange(newPage) {
      vm.directoryList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.directoryList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.directoryList.setPage(0);
      refreshData();
    }

    /**
     * Checks if directory has OKTA configuration as default IdP
     *
     * @param {Directory} directory - directory object of clicked row
     * @returns {boolean} True if it has OKTA as default IdP
     */
    function requiresIdpMigration(directory) {
      return directory.isPrimaryIdpOkta;
    }

    function requiresLinkConfig(directory) {
      return !(feature.isEnabled('temp_idp_migration') && directory.isPrimaryIdpOkta);
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.directoryList.sortBy(JIL_CONSTANTS.SORT.NAME, vm.sortReverse);
      refreshData();
    }

    function setSelectableItems() {
      vm.selectableItems = _.filter(vm.directoryList.items, isEditable);
    }

    function showAutoRemovedSyncStatus(directory) {
      return _.includes(vm.removedSyncDirectoryIdsList, directory.id);
    }

    function showDirectoryMoveSuccessToastAndRefresh() {
      toastManager.showSuccessToast();
      refreshData();
      clearDirectoryBeingMoved();
    }

    function showEditLink(directory) {
      return feature.isEnabled('temp_idp_migration') && requiresIdpMigration(directory);
    }

    function showLink(directory) {
      return (
        vm.display.actions.configureSaml &&
        directory.isType3() &&
        directory.requiresConfiguration() &&
        vm.requiresLinkConfig(directory)
      );
    }

    function showDomainEnforcement() {
      return feature.isEnabled('temp_domain_enforcement');
    }

    ///////////////

    function refreshData() {
      vm.directoryList.refresh();
      setSelectableItems();

      // After sorting, we need to notify users of assistive technology that this has happened.
      // We only want the area that announces this to be visible long enough to let the user know.
      // So we set the variable that changes this temporarily to true.
      // This mimics behaviour in the coral table component. See also:
      // https://opensource.adobe.com/coral-spectrum/documentation/file/coral-spectrum/coral-component-table/src/scripts/Table.js.html#lineNumber1623
      vm.sortOrderChanged = true;
      $timeout(() => {
        vm.sortOrderChanged = false;
      }, 2500);
    }

    function wasClickOnReservedElement($event) {
      if ($event.target) {
        const targetEl = angular.element($event.target);
        return (
          targetEl.hasClass('name') ||
          targetEl.hasClass('approve-link') ||
          targetEl.hasClass('saml-link') ||
          targetEl.hasClass('move-directory') ||
          targetEl[0].tagName === 'INPUT' ||
          targetEl[0].tagName === 'LABEL'
        );
      }
      return false;
    }
  }
})();
