/**
 * Utility functions for Dom.
 */

/**
 * @description HTML-decodes the specified string and returns the resulting string
 * @param {String} html - the string to HTML-decode
 * @returns {String} the decoded string
 */
function htmlDecode(html) {
  const text = document.createElement('textarea');
  text.innerHTML = html;
  return text.value;
}

/**
 * @description Loads the specified CSS file by appending a <link> element to the DOM with the
 *   specified href
 * @param {String} href - the URL of the CSS file to load
 * @param {function} _loadEl - the reference to loadEl()
 * @returns {Promise} a promise which resolves with the underlying load DOM event when the file
 *   has loaded, or rejects with the error DOM event if an error occured
 */
function loadCss(href, _loadEl = loadEl) {
  const linkElement = document.createElement('link');
  linkElement.href = href;
  linkElement.rel = 'stylesheet';
  linkElement.type = 'text/css';
  return _loadEl(linkElement);
}

/**
 * @description Loads the specified element by appending it to the DOM
 * @param {HTMLElement} el - the element to load
 * @returns {Promise} a promise which resolves with the underlying load DOM event when the
 *   element has loaded, or rejects with the error DOM event if an error occurred
 */
function loadEl(el) {
  return new Promise((resolve, reject) => {
    el.addEventListener('load', resolve);
    el.addEventListener('error', reject);
    document.head.append(el);
  });
}

/**
 * @description Loads the specified JS file by appending a <script> element to the DOM with the
 *   specified src
 * @param {String} src - the URL of the JS file to load
 * @param {function} _loadEl - the reference to loadEl()
 * @returns {Promise} a promise which resolves with the underlying load DOM event when the file
 *   has loaded, or rejects with the error DOM event if an error occured
 */
function loadJs(src, _loadEl = loadEl) {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;
  return _loadEl(scriptElement);
}

export {htmlDecode, loadCss, loadEl, loadJs};
