import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';

/**
 *
 * @param {AvailableTypeList} availableTypeList
 */
function hasRecommendedSuffix(availableTypeList) {
  return (
    (availableTypeList.hasType(MEMBER_TYPE.TYPE1) ||
      availableTypeList.hasType(MEMBER_TYPE.TYPE2E)) &&
    (availableTypeList.hasType(MEMBER_TYPE.TYPE2) || availableTypeList.hasType(MEMBER_TYPE.TYPE3))
  );
}
/**
 *
 * @param {AvailableTypeList} availableTypeList
 * @param {MEMBER_TYPE} userType
 */
function shouldShowRecommendedSuffix(availableTypeList, userType) {
  return (
    hasRecommendedSuffix(availableTypeList) &&
    [MEMBER_TYPE.TYPE2, MEMBER_TYPE.TYPE3].includes(userType)
  );
}

export {hasRecommendedSuffix, shouldShowRecommendedSuffix};
