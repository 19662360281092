(function () {
  /**
   * @deprecated Please use src2/core/services/country/CountryList.js
   */
  angular.module('binky.core.common.country').factory('CountryList', getCountryListModel);

  /* @ngInject */
  function getCountryListModel($q, binkySrc2, promiseUtils) {
    class CountryList extends binkySrc2.services.country.CountryList {
      /**
       * @description Method to fetch the country list
       * @returns {Promise} promise - promise object, resolved when the country list is loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.refresh())
          .then(() => {
            const errorMessage = this.errorMessage || super.errorMessage;
            if (errorMessage) {
              return $q.reject(errorMessage);
            }

            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      static get() {
        const model = new CountryList();
        model.refresh();
        return model;
      }
    }

    return CountryList;
  }
})();
