(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum ProgressBar
   *
   * @ngdoc component
   * @name binky.widgets.common.progress-bar:binkyProgressBar
   * @description A progress bar widget that wraps around the coral-progress element. This
   * progress bar will allow for dynamic labels on both the top left and top right side of the bar. The
   * width of the progress bar will take up the width of its parent container.
   * @param {String} [leftLabel] The left label that is above the progress bar.
   * @param {String} [rightLabel] The right label that is above the progress bar.
   * @param {String} [size] The size of the progress bar. Can be set to ['S', 'M']. Defaults to 'M'.
   * @param {Number} value The value of the progress bar. This is the percentage of how much the bar will fill.
   */

  angular.module('binky.widgets.common.progress-bar').component('binkyProgressBar', {
    bindings: {
      leftLabel: '@?',
      rightLabel: '@?',
      size: '@?',
      value: '<',
    },
    controller,
    templateUrl: 'widgets/common/progress-bar/progress-bar.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      size: _.defaultTo(vm.size, 'M'),
    });
  }
})();
