import axios from 'axios';
import omitBy from 'lodash/omitBy';

import authentication from 'services/authentication';
import feature from 'services/feature';

let clientId, includeRoles, url;

/**
 * Set environment and application level context for Clype Users
 * @param {Object} config - A configuration object.
 * @param {string} config.url - The root url for the Clype Users service api
 * @param {string} config.clientId - The identifier for the consuming application
 */
const configure = (config) => {
  url = `${config.url}/users`;
  clientId = config.clientId;
  includeRoles = config.includeRoles;
};

/**
 * Fetch and return resource for these accounts
 * @see {@link https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1308897197}
 * @param {String} userId - Identifier for user
 * @param {Object} params - Additional params
 * @param {Boolean} params.includePaths - Set to true to get organization_path_name in the response.
 * @return {Promise} a promise which will resolve with the response data
 */
const getLinkedAccountsResource = async (userId, params) => {
  const options = {
    headers: {
      'X-Api-Key': clientId,
    },
  };

  const token = authentication.getAccessToken();
  if (token && token.token) {
    options.headers.Authorization = `Bearer ${token.token}`;
  }

  const administrableRoles = includeRoles?.filter((role) => !role.startsWith('ADOBE_AGENT_'));

  options.params = omitBy(
    {
      includePaths: params?.includePaths,
      roleFilter: feature.isEnabled('temp_contract_admin_role')
        ? administrableRoles?.join(',')
        : undefined,
    },
    (value) => value === undefined
  );

  const response = await axios.get(`${url}/${userId}/linked-accounts`, options);
  return response;
};

const clypeUsers = {configure, getLinkedAccountsResource};
export default clypeUsers;
