(function () {
  /**
   * @deprecated Please use src2/core/services/configStore/configStore.js
   */
  angular
    .module('binky.core.contact')
    .constant('CONTACT_LIST_CACHE_ID', 'ContactList')
    .constant('CONTACT_LIST_EVENT', {
      REFRESH: 'ContactListRefresh',
    });
})();
