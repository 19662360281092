import {mergeFeatureFlagValues} from '@pandora/feature-flag-vendors';

const VENDOR_CONSTANTS = {
  LOCAL_STORAGE_KEY: 'overrideFlags',
};

const {LOCAL_STORAGE_KEY} = VENDOR_CONSTANTS;

const defaultURLConfig = {
  queryParameter: 'flags',
};

/**
 * Determines if a URL feature flag is enabled
 * @param {Array<string>} segments - it's an array that typically comes in two shapes,
 * [featureName, isEnabled] or [featureName] depending on what was parsed from the URL
 * @returns {Boolean} true if the flag is enabled, otherwise returns false
 */
const isFlagEnabled = (segments) => {
  const flagDirectlySetToTrue = segments.length === 2 && segments[1] === 'true';
  const flagIndirectlySetToTrue = segments.length === 1;
  return flagDirectlySetToTrue || flagIndirectlySetToTrue;
};

/**
 * Determines if a URL feature flag is disabled
 * @param {Array<string>} segments - it's an array that typically comes in two shapes,
 * [featureName, isEnabled] or [featureName] depending on what was parsed from the URL
 * @returns {Boolean} true if the flag is disabled, otherwise returns false
 */
const isFlagDisabled = (segments) => segments.length === 2 && segments[1] === 'false';

// Generic function that makes a flag object
const makeFlag = (name, value = true) => ({name, source: 'url', value});

// Parses an URL string, based on a query parameter
const parseURL = (URL, parameter) => {
  const urlFlags = [];
  const data = decodeURI(
    URL.replace(
      new RegExp(
        `^(?:.*[&\\?]${encodeURI(parameter).replace(/[*+.]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`,
        'i'
      ),
      '$1'
    )
  );

  // If we do not find flags just return empty array
  if (data === '') return urlFlags;

  const flags = decodeURIComponent(data).split(',');
  const separator = '=';

  flags.forEach((flag) => {
    const segments = decodeURIComponent(flag).split(separator);
    const flagName = segments[0];
    if (isFlagEnabled(segments)) {
      urlFlags.push(makeFlag(flagName));
    } else if (isFlagDisabled(segments)) {
      urlFlags.push(makeFlag(flagName, false));
    }
  });

  return urlFlags;
};

/**
 * Saves features to localStorage
 * @param {Object} localStorage - It's passed from upstream but resolved to window.localStorage
 * @param {Array<Object>} features - List of features to be persisted to localStorage
 */
const saveToLocalStorage = (localStorage, features) => {
  const featureOverrides = localStorage.getItem(LOCAL_STORAGE_KEY);
  const parsedFeatureOverrides = featureOverrides ? JSON.parse(featureOverrides) : {};
  features.forEach(({name, value, source}) => {
    parsedFeatureOverrides[name] = {
      enabled: value,
      source,
    };
  });
  // We just need here to have Omnitool write to this format as well, thats all.
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedFeatureOverrides));
};

/**
 * Creates URL Vendor - reads features from the URL and merges them with the previous features
 * from other vendors. Saves to local storage the feature overrides.
 * @param {Object} config - the configuration object for the URL vendor
 * @param {String} config.queryParameter - the URL query parameter that we need to read from
 * @returns {function} that takes in an array of features and process them
 * and returns the modified array of feature objects e.g. {name, value, source}
 */
const createUrlVendor =
  (config = defaultURLConfig) =>
  (features) => {
    if (typeof window !== 'undefined') {
      const urlFeatures = parseURL(window.location.href, config.queryParameter);
      saveToLocalStorage(localStorage, urlFeatures);
      return mergeFeatureFlagValues(features, urlFeatures);
    }
    return features;
  };

/**
 * Creates Local Storage Vendor (LSV) - reads features from Local Storage and
 * merges them with the previous features from other vendors.
 * @returns {function} that takes in an array of features and process them
 * and returns the modified array of feature objects e.g. {name, value, source}
 */
const createLocalStorageVendor = () => (features) => {
  if (typeof window !== 'undefined') {
    const localFeaturesDataString = window?.localStorage?.getItem(LOCAL_STORAGE_KEY);
    const localFeatures = localFeaturesDataString
      ? Object.entries(JSON.parse(localFeaturesDataString))
      : [];

    return mergeFeatureFlagValues(
      features,
      localFeatures.map(([key, val]) => ({
        name: key,
        source: val.source,
        value: val.enabled,
      }))
    );
  }
  return features;
};

/**
 * Utility that maps en data into a map of orgId:Array<string>
 * @param {Object} data - raw configuration file data object
 * @param {Object} data.featureFlagRequirements - object where keys are features, and values are array of orgIds
 * @returns {Map<string, Array<string>>} - features mapped by orgId
 */
const groupFeaturesUnderOrgs = (data) => {
  const organizationsMap = new Map();

  Object.entries(data.featureFlagRequirements).forEach(([flag, matches]) => {
    matches.orgIdMatches.forEach((orgId) => {
      if (organizationsMap.has(orgId)) {
        organizationsMap.get(orgId).push(flag);
      } else {
        organizationsMap.set(orgId, [flag]);
      }
    });
  });

  return organizationsMap;
};

/**
 * Parses the data from a configuration file and maps features based on an orgId
 * @param {Object} data - the raw environment configuration file data
 * @param {String} orgId - the active organization id
 * @returns {Array<Object>} - an array of feature flag objects
 */
const parseResponse = (data, orgId) => {
  const orgMap = groupFeaturesUnderOrgs(data);
  const org = orgMap.get(orgId);
  return org ? org.map((featureName) => ({name: featureName, source: 'config', value: true})) : [];
};

/**
 * Creates a Config Flag Vendor (CFV) - reads features from the environment configuration
 * and merges them with the previous features from other vendors.
 * @param {Object} configuration  - configuration object for the CFV
 * @param {Object} configuration.data  - the raw data configuration of a specific environment
 * @param {String} configuration.orgId - the active organization id
 * @returns {function} that takes in an array of features and process them
 * and returns the modified array of feature objects e.g. {name, value, source}
 */
const createConfigVendor =
  ({data, orgId}) =>
  (features = []) => {
    // If we do not have a orgId, just return the initial features
    if (!orgId) return features;
    return mergeFeatureFlagValues(features, parseResponse(data, orgId));
  };

/**
 * Creates AllFlags Vendor - reads features from the allFlags url parameter
 * and only activate those features, and sets all false or all true
 * Use cases:
 * 1. allFlags=false - turns all previous features to be false - use case Omnitool
 * 2. allFlags=true - turns all previous features to be true - use case Omnitool
 * 2. allFlags=foo,bar - turns all previous features to false, and only sets foo and bar to true - use case e2e
 * This vendor should be the last in the vendor array
 * @returns {function} that takes in an array of features and process them
 * and returns the modified array of feature objects e.g. {name, value, source}
 */
const createAllFlagsVendor = () => (features) => {
  let resultedFeatures = features;
  let url = '';
  if (typeof window !== 'undefined') url = window.location.href;
  const allFlagsFeatures = parseURL(url, 'allFlags');

  /**
   * Detects an allFlags=[boolean] situation. Looks at all eligible allFlags parameters
   * e.g. allFlags=foo,bar,[boolean] or allFlags=[boolean],foo,bar or allFlags=[boolean]
   * @param {Array<object>} flags - feature flag object extracted from allFlags URL param
   * @param {String} booleanString - 'true' or 'false'
   * @returns {Boolean} - true if an false parameter has been found, false otherwise
   */
  const isAllBoolean = (flags, booleanString) => {
    const foundBooleanFlag = flags.find((flag) => flag.name === booleanString);
    return !!foundBooleanFlag;
  };

  // If we do not have allFlags Features we return previous features
  if (allFlagsFeatures.length === 0) return features;

  /**
   * Utility that turns all features on or off
   * @param {Array<object>} feats - feature flag objects
   * @param {Boolean} value - true or false, will be applied across
   * @returns {Array<object>} - all features flipped to true or false
   */
  const assignValue = (feats, value) => feats.map((feat) => Object.assign(feat, {value}));

  // Handle allFlags=false
  if (isAllBoolean(allFlagsFeatures, 'false')) {
    resultedFeatures = assignValue(features, false);
  } else if (isAllBoolean(allFlagsFeatures, 'true')) {
    // Handle allFlags=true
    resultedFeatures = assignValue(features, true);
  } else {
    // Handle non-booleans e.g. allFlags=foo,bar
    resultedFeatures = assignValue(features, false);
  }

  // We return the features flipped to whatever boolean was in the allFlags concatenated
  // with all features found in the allFlags params, minus those either 'true' or 'false'
  return [
    ...resultedFeatures,
    ...allFlagsFeatures.filter((flag) => flag.name !== 'true' && flag.name !== 'false'),
  ];
};

export {
  createAllFlagsVendor,
  createConfigVendor,
  createLocalStorageVendor,
  createUrlVendor,
  VENDOR_CONSTANTS,
};
