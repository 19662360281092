(function () {
  /**
   * @deprecated Please use src2/core/utils/dateUtils.js
   */
  angular.module('binky.core.date-utils').factory('dateUtils', utils);

  /* @ngInject */
  function utils(binkySrc2) {
    return binkySrc2.utils.dateUtils;
  }
})();
