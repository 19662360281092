import {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_ROLE,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from '@admin-tribe/binky';

/**
 * Method to translate a role into a user displayable string.
 *
 * @param {Object} intl - the React intl object for internationalization
 * @param {options} options - options
 * @param {String} options.type - the user role which should be one of the constants imported above.
 * @param {Boolean} options.isShortName - whether or not to return the short, or long version, of the displayable string.
 *   There is only one version of the string for ADOBE_AGENT and PRODUCT_ROLE constants.
 * @returns {String} the displayable equivalent of the given role
 * @throws if type isn't recognized
 */
// eslint-disable-next-line complexity, @admin-tribe/admin-tribe/jsdoc-exported-functions -- a switch makes sense
function getUserRoleName(intl, {isShortName = true, type}) {
  const subKey = isShortName ? 'shortName' : 'longName';

  switch (type) {
    case ADOBE_AGENT.ADMIN:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.admin'});
    case ADOBE_AGENT.CUSTOMER_CARE:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.customerCare'});
    case ADOBE_AGENT.PROFESSIONAL_SERVICES:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.professionalServices'});
    case ADOBE_AGENT.PROVISIONER:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.provisioner'});
    case ADOBE_AGENT.READ:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.read'});
    case ADOBE_AGENT.RESELLER_LICENSING:
      return intl.formatMessage({id: 'binky.user.userRole.adobeAgent.resellerLicensing'});
    case COMPARTMENT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.compartmentAdmin.${subKey}`});
    case COMPARTMENT_VIEWER:
      return intl.formatMessage({id: `binky.user.userRole.admin.compartmentViewer.${subKey}`});
    case CONTRACT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.contract.${subKey}`});
    case DEPLOYMENT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.deployment.${subKey}`});
    case LICENSE_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.license.${subKey}`});
    case LICENSE_DEV_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.licenseDev.${subKey}`});
    case ORG_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.org.${subKey}`});
    case PRODUCT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.product.${subKey}`});
    case PRODUCT_SUPPORT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.productSupport.${subKey}`});
    case STORAGE_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.storage.${subKey}`});
    case SUPPORT_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.support.${subKey}`});
    case USER_GROUP_ADMIN:
      return intl.formatMessage({id: `binky.user.userRole.admin.userGroup.${subKey}`});
    case PRODUCT_ROLE.APPROVER:
      return intl.formatMessage({id: 'binky.user.userRole.product.approver'});
    case PRODUCT_ROLE.EDITOR:
      return intl.formatMessage({id: 'binky.user.userRole.product.editor'});
    case PRODUCT_ROLE.OBSERVER:
      return intl.formatMessage({id: 'binky.user.userRole.product.observer'});
    case PRODUCT_ROLE.PUBLISHER:
      return intl.formatMessage({id: 'binky.user.userRole.product.publisher'});
    default:
      throw new Error(`getUserRoleName: unable to resolve type (${type})`);
  }
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getUserRoleName};
