import {useOverlayTriggerState} from '@react-stately/overlays';
import React, {useState} from 'react';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- ybarra@ to update
const useDrawer = () => {
  const state = useOverlayTriggerState({});
  const [overlayVisible, setOverlayVisible] = useState(false);

  const isOpen = state.isOpen;
  const closeDrawer = React.useCallback(() => {
    setOverlayVisible(false);
    state.close();
  }, [state]);
  const openDrawer = React.useCallback(() => {
    state.open();
    setOverlayVisible(true);
  }, [state]);
  // Note that in practice setOverlayVisible should not be used, it is listed
  // here to test styles that are updated as part of a CSSTransition
  return [closeDrawer, isOpen, openDrawer, overlayVisible, setOverlayVisible];
};

export default useDrawer;
