/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name binky.widgets.common.detail-section:binkyDetailSection
 *
 * @description Used to construct sections for drawers and detail pages.
 *
 * @binding {String|undefined} title. The msg string to be translated and used for the section title.
 * @binding {Number|undefined} ariaLevel. The accessible heading level, defaults to the same as
 *   headingLevel if not supplied. Must be between 2 and 6 inclusive or it will be matched to
 *   the headingLevel value. ONLY use this parameter if the heading level cannot be correctly
 *   conveyed using the semantic heading controlled by the headingLevel parameter. For example
 *   the heading level may be 4 (resulting in an h4 element) in order to apply correct CSS styling,
 *   but the ariaLevel may be 3 so that there are no gaps in headings for assistive technology users.
 * @binding {Number|undefined} headingLevel. The heading level, defaults to 4. Must be between 2
 *   and 6 inclusive or the default 4 will be applied. Controls the h* tag used in component output,
 *   for example a value of 3 will result in h3 being used for the heading.
 * @binding {promise|undefined} waitPromise. If specified, a dot-dot-dot wait will be shown until
 *   the promise resolves.
 */
(function () {
  'use strict';
  angular.module('binky.widgets.common.detail-section').component('binkyDetailSection', {
    bindings: {
      ariaLevel: '<?',
      headingLevel: '<?',
      sectionTitle: '@?',
      waitPromise: '<?',
    },
    controller,
    templateUrl: 'widgets/common/detail-section/detail-section.component.html',
    transclude: {
      'detail-section-body': 'detailSectionBody',
      'detail-section-header': '?detailSectionHeader',
    },
  });

  /* @ngInject */
  function controller($q, _) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      showError: false,
    });

    function $onInit() {
      if (_.isUndefined(vm.waitPromise)) {
        vm.waitPromise = $q.resolve();
      }
      configureHeadingLevels();
    }

    function $onChanges() {
      if (vm.waitPromise) {
        vm.waitPromise.catch(() => {
          vm.showError = true;
        });
      }
    }

    function configureHeadingLevels() {
      vm.headingLevel = getValidHeadingLevel(vm.headingLevel) || 3;
      vm.ariaLevel = getValidHeadingLevel(vm.ariaLevel) || vm.headingLevel;
    }

    function getValidHeadingLevel(level) {
      if (level && level >= 2 && level <= 6) {
        return level;
      }

      return undefined;
    }
  }
})();
