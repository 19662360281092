(function () {
  /**
   * @deprecated Please use src2/core/services/product/ProductList.js
   *
   * @ngdoc factory
   * @name ProductList
   * @description Model for a product list
   */
  angular.module('binky.core.product').factory('ProductList', getProductListModel);

  /* @ngInject */
  function getProductListModel(
    $q,
    _,
    binkySrc2,
    CART_EVENT,
    feature,
    MEMBER_EVENT,
    modelCache,
    Product,
    PRODUCT_LIST_CACHE_ID,
    promiseUtils
  ) {
    class ProductList2 extends binkySrc2.services.product.ProductList {
      constructor(options) {
        super(options);
        this.itemClassRef = Product;
      }

      everyProductAndSomeFulfillableItemCondition(productCondition, fiCondition) {
        return binkySrc2.services.product.productListUtils.everyProductAndSomeFulfillableItemCondition(
          this,
          productCondition,
          fiCondition
        );
      }

      findFulfillableItem(code) {
        return binkySrc2.services.product.productListUtils.findFulfillableItem(this, code);
      }

      getAssignableLicenseCount() {
        return binkySrc2.services.product.productListUtils.getAssignableLicenseCount(this);
      }

      getClosestLicenseEndDateForStatus(status, contractId) {
        return binkySrc2.services.product.productListUtils.getClosestLicenseEndDateForStatus(
          this,
          status,
          contractId
        );
      }

      getClosestLicenseQuantityEndDateWhen(condition, contractId) {
        return binkySrc2.services.product.productListUtils.getClosestLicenseQuantityEndDateWhen(
          this,
          condition,
          contractId
        );
      }

      /**
       * @description Return the list of products that match offerIds
       *
       * @param {Array<String>} offerIds Array of offer ID's
       *
       * @returns {Array<Product>} Array of products
       */
      getProductsByOfferIds(offerIds) {
        return binkySrc2.services.product.productListUtils.getProductsByOfferIds(this, offerIds);
      }

      getSeatBasedAssignableLicenseCount() {
        return binkySrc2.services.product.productListUtils.getSeatBasedAssignableLicenseCount(this);
      }

      getSeatBasedTotalProvisionedQuantity() {
        return binkySrc2.services.product.productListUtils.getSeatBasedTotalProvisionedQuantity(
          this
        );
      }

      getStorageOnlyProducts() {
        return binkySrc2.services.product.productListUtils.getStorageOnlyProducts(this);
      }

      /**
       * @description Returns the fulfillable items for which support tickets can be created.
       *
       *  Support tickets can be created for a product's fulfillable items only if it has the
       * support_case_creation_allowed FI.
       *
       *  For such a product, tickets can be created for its "SUPPORT" type FIs. If the product has
       * no SUPPORT FIs, tickets can be created for its DESKTOP, PRODUCT or QUOTA FIs.
       *
       * The set of products whose FIs are returned by this method can be further filtered via the
       * optional parameter.
       *
       * @param {Object} productFilter - options passed to _.filter for limiting which products'
       *   FIs are returned, e.g. {cloud: 'CREATIVE'} to return only FIs of Creative Cloud products.
       * @param {Array<String>} productSupportRoleProductIds - product ids that require product support admin role
       * @param {Boolean} forExpertSession - boolean parameter to return FI based on whether it is for
       * Cases or ExpertSession. Default value is false.
       * @returns {Array<Object>} the fulfillable items for which tickets can be created
       */
      getSupportTicketFulfillableItems({
        productFilter,
        productSupportRoleProductIds,
        forExpertSession = false,
      } = {}) {
        return _(this.items)
          .filter(
            (product) =>
              feature.isDisabled('temp_parkour_mm') ||
              !product.isProductSupportRoleAssignmentAllowed() ||
              (product.isProductSupportRoleAssignmentAllowed() &&
                _.includes(productSupportRoleProductIds, product.id))
          )
          .filter((product) =>
            forExpertSession
              ? product.fulfillableItemList.hasExpertSessionCreationAllowed()
              : product.fulfillableItemList.hasSupportCaseCreationAllowed()
          )
          .filter(productFilter)
          .map('fulfillableItemList.items')
          .flatMap((items) => {
            const fiTypesToReturn = _.some(items, ['fulfillableItemType', 'SUPPORT'])
              ? ['SUPPORT']
              : ['DESKTOP', 'SERVICE', 'QUOTA'];
            // Disable rule because it is spuriously failing
            return _.filter(items, (item) => _.includes(fiTypesToReturn, item.fulfillableItemType));
          })
          .uniqBy('code')
          .value();
      }

      getTotalProvisionedQuantity() {
        return binkySrc2.services.product.productListUtils.getTotalProvisionedQuantity(this);
      }

      hasAllFulfillableItems(fulfillableItems) {
        return binkySrc2.services.product.productListUtils.hasAllFulfillableItems(
          this,
          fulfillableItems
        );
      }

      hasAssignableLicenses() {
        return binkySrc2.services.product.productListUtils.hasAssignableLicenses(this);
      }

      hasConsumableProducts() {
        return binkySrc2.services.product.productListUtils.hasConsumableProducts(this);
      }

      hasDeviceLicense() {
        return binkySrc2.services.product.productListUtils.hasLegacyDeviceLicenses(this);
      }

      hasGroupConsumableProducts() {
        return binkySrc2.services.product.productListUtils.hasGroupConsumableProducts(this);
      }

      hasIndirectProduct() {
        return binkySrc2.services.product.productListUtils.hasIndirectProducts(this);
      }

      hasOffers(offers) {
        return binkySrc2.services.product.productListUtils.hasOffers(this, offers);
      }

      hasOnlyTeamProducts() {
        return binkySrc2.services.product.productListUtils.hasOnlyTeamProducts(this);
      }

      hasOrganizationConsumableProducts() {
        return binkySrc2.services.product.productListUtils.hasOrganizationConsumableProducts(this);
      }

      hasPackageSupport() {
        return binkySrc2.services.product.productListUtils.hasPackageSupport(this);
      }

      hasProductsInCloud(cloud) {
        return binkySrc2.services.product.productListUtils.hasProductsInCloud(this, cloud);
      }

      hasProductSupportRoleAssignmentAllowed() {
        return binkySrc2.services.product.productListUtils.hasProductSupportRoleAssignmentAllowed(
          this
        );
      }

      hasSomeFulfillableItems(fulfillableItems) {
        return binkySrc2.services.product.productListUtils.hasSomeFulfillableItems(
          this,
          fulfillableItems
        );
      }

      hasStockProducts() {
        return binkySrc2.services.product.productListUtils.hasStockProducts(this);
      }

      hasSupportCaseCreationAllowed() {
        return binkySrc2.services.product.productListUtils.hasSupportCaseCreationAllowed(this);
      }

      hasSupportRoleAssignmentAllowed() {
        return binkySrc2.services.product.productListUtils.hasSupportRoleAssignmentAllowed(this);
      }

      hasTeamProduct() {
        return binkySrc2.services.product.productListUtils.hasTeamProducts(this);
      }

      /**
       * @description Method to determine if any products in the list are
       *              delegatable
       *
       * @returns {Boolean} true if a delegatable product exists, else false
       */
      hasUserDelegatableProduct() {
        return _.some(this.items, (product) => !product.isLegacyDeviceLicense());
      }

      /**
       * @description Method to refresh Product list from back-end.
       * @returns {Promise} resolves to ProductList on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              this.hasETLAProducts = _.some(this.items, (item) => item.isEnterpriseDirect());
              this.hasVIPProducts = _.some(this.items, (item) => item.isTeamIndirect());
              this.hasEVIPProducts = _.some(this.items, (item) => item.isEnterpriseIndirect());
              this.pagination.itemCount = this.items.length;
              this.$resolved = true;
              resolve(this);
            })
            .catch((error) => {
              this.$resolved = true;
              reject(error.response);
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to save changes to the product list to back-end.
       *
       * @param {Object} [options] Initialization Object (params described below)
       * @param {String} [options.fulfillmentTestType] the fulfillment test type for the save call
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(options = {}) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .save(options)
            .then((result) => resolve(result))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      someByFulfillableItemCondition(condition) {
        return binkySrc2.services.product.productListUtils.someByFulfillableItemCondition(
          this,
          condition
        );
      }

      /**
       * @description Method to fetch the ProductList.
       * @param {Object} options - options to configure the product list.
       * @param {Object} [options.contractId] - ID of contract of which products should be fetched.
       * @param {Boolean} [options.includeLicenseAllocationInfo] - whether to include the allocation
       *        information from PALS.
       * @param {Number} [options.licenseGroupLimit] - maximum limit for
       *        how many license groups to retreive for each product.
       * @param {Boolean} [options.notifyListeners] - whether the retreival of data should also notify
       *        listeners. This should be set to false when making one-time non-standard calls
       *        that shouldn't affect other components. Defaults to true.
       * @param {Object} [options.orgId] - ID of org of which products should be fetched.
       * @returns {ProductList} the fetched ProductList.
       */
      static get(options) {
        const model = new ProductList2(options);
        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((productList) => {
            _.assign(model, productList);
            // Ensures that any chained .then() usages will have the resolved
            // list as a callback argument.
            _.assign(model, {$promise: $q.resolve(model)});
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    modelCache.register(PRODUCT_LIST_CACHE_ID, 1);
    modelCache.removeAllOnEvent(PRODUCT_LIST_CACHE_ID, [
      CART_EVENT.SUBMIT,
      MEMBER_EVENT.CREATE,
      MEMBER_EVENT.UPDATE,
    ]);

    return ProductList2;
  }
})();
