import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import Pill from './Pill';

/**
 * To be ported to Pandora-UI/administration in future
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-mini-cards
 */

const ContractListPill = ({closeButtonAriaLabel, header, onDismiss, subtitle}) => {
  const intl = useIntl();
  return (
    <Pill
      closeButtonAriaLabel={closeButtonAriaLabel}
      closeButtonTooltipText={intl.formatMessage({
        id: 'binky.common.assignmentSection.selectedPills.pill.dismiss',
      })}
      header={header}
      iconVariant="circle"
      onDismiss={onDismiss}
      subtitle={subtitle}
    />
  );
};

ContractListPill.propTypes = {
  closeButtonAriaLabel: PropTypes.string,
  header: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
  subtitle: PropTypes.string,
};

export default ContractListPill;
