(function () {
  /**
   * @deprecated Please use src2/core/models/member/type/MemberTypeConstants.js
   */
  angular
    .module('binky.core.common.member.type')
    .constant(
      'MEMBER_TYPE',
      // eslint-disable-next-line angular/window-service
      window.binkySrc2.models.member.MEMBER_TYPE_CONSTANTS.MEMBER_TYPE
    )
    .constant(
      'MEMBER_TECH_ACCOUNT_DOMAIN',
      // eslint-disable-next-line angular/window-service
      window.binkySrc2.models.member.MEMBER_TYPE_CONSTANTS.MEMBER_TECH_ACCOUNT_DOMAIN
    );
})();
