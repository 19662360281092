(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyContractPerTermPrice
   * @description returns the string representing an offer's price per term per unit, if applicable.
   *    e.g. '$123.45 / mo per license' or '$346.90 / yr'
   * @param {Contract} contract model instance.
   * @param {Function} contract.getBillingFrequency returns the contract's billing frequency, e.g. 'MONTHLY' or 'ANNUAL'.
   * @param {Boolean} perLicense - true to represent the unit is per license, rather than organization-wide.
   * @param {String} formattedPrice - the pre-formatted price string.
   * @example <div> {{ $ctrl.contract | binkyContractTaxSuffixLabel:$ctrl.perLicense:$ctrl.formattedPrice }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.commerce').filter('binkyContractPerTermPrice', perTermPriceString);

  /* @ngInject */
  function perTermPriceString($translate, _) {
    return getPerTermPriceString;

    ////////////////

    function getPerTermPriceString(contract, perLicense, formattedPrice) {
      const billingFrequency = _.invoke(contract, 'getBillingFrequency');
      return $translate.instant(
        perLicense
          ? `binky.widgets.commerce.perTerm.perLicense.${billingFrequency}`
          : `binky.widgets.commerce.perTerm.term.${billingFrequency}`,
        {price: formattedPrice}
      );
    }
  }
})();
