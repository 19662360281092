import {Divider, View} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ModalContent,
  ModalDialog,
  ModalHeading,
  ModalTagHeader,
  STYLE_PROPS,
} from 'shell/panels/modal-dialog';

const ALERT_DIALOG_VARIANTS = {
  default: {
    ctaVariant: 'cta',
  },
  destructive: {
    ctaVariant: 'negative',
  },
  destructiveWarning: {
    ctaVariant: 'negative',
    iconColor: 'notice',
  },
  error: {
    ctaVariant: 'primary',
    iconColor: 'negative',
  },
  warning: {
    ctaVariant: 'primary',
    iconColor: 'notice',
  },
};

const AlertDialog = ({children, title, variant, ...dialogProps}) => {
  const variantConfig = ALERT_DIALOG_VARIANTS[variant] || ALERT_DIALOG_VARIANTS.default;
  const hasIcon = !!variantConfig.iconColor;

  return (
    <ModalDialog ctaVariant={variantConfig.ctaVariant} mode="alert" {...dialogProps}>
      <ModalHeading>{title}</ModalHeading>
      {hasIcon && (
        <ModalTagHeader
          IconComponent={<Alert aria-label="Notification Alert" color={variantConfig.iconColor} />}
        />
      )}
      <View
        data-testid="description"
        elementType="section"
        gridArea="divider"
        marginBottom="size-125"
        marginX={STYLE_PROPS.alert.spaceX}
      >
        <Divider marginTop="size-250" size="M" />
      </View>
      <ModalContent>{children}</ModalContent>
    </ModalDialog>
  );
};

AlertDialog.propTypes = {
  /**
   * Children nodes that should be rendered within the alert dialog.
   */
  children: PropTypes.node,
  /**
   * Label for the alert dialog heading.
   */
  title: PropTypes.string,
  /**
   * Variants of the alert dialog, configures alert icon or cta button variants.
   */
  variant: PropTypes.oneOf(['default', 'destructive', 'destructiveWarning', 'error', 'warning']),
};

export default AlertDialog;
export {ALERT_DIALOG_VARIANTS};
