(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.search
   * @description module that defines search widgets
   */
  angular.module('binky.widgets.common.search', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.common.events',
    'pascalprecht.translate',
  ]);
})();
