(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.organizations.search-list:OrganizationSearchList
   * @description model that represents a list of searched organizations.
   */
  angular
    .module('binky.core.organizations.search-list')
    .factory('OrganizationSearchList', getOrganizationSearchList);
  /* @ngInject */
  function getOrganizationSearchList(
    _,
    feature,
    JIL_CONSTANTS,
    jilOrganizations,
    List,
    Organization,
    ORGANIZATION_SEARCH_TYPE
  ) {
    class OrganizationSearchList extends List {
      /**
       * @description Method to create a new instance of an OrganizationList.
       * @param {Object} options - the options to use for this searched
       * @param {boolean} [options.includeAncestors] - (optional) true if the ancestors should be fetched
       * @param {String} [options.orgType] - (optional) the organization type filter
       * @param {String} options.query - the query term to use for the search
       * @param {String} options.type - the type of search being performed
       */
      constructor(options) {
        super({
          itemClassRef: Organization,
          pageSize: feature.isEnabled('temp_paged_orgs') ? undefined : 1000,
          resource: getResource(options.type),
          sortExpression: JIL_CONSTANTS.SORT.NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
        this.includeAncestors = options.includeAncestors;
        this.orgType = options.orgType;
        this.query = options.query;
        this.type = options.type;
        this.$resolved = false;
      }

      /**
       * @description Method to search organizations
       * @returns {Promise} promise - promise object resolved with the search results,
       *                    otherwise rejects with an error
       */
      refresh() {
        return super.refresh(
          _.assign(
            getTypeSpecificRefreshParams(this.type, this.query),
            this.includeAncestors ? {include_ancestors: this.includeAncestors} : {},
            this.orgType ? {org_type: this.orgType} : {}
          )
        );
      }

      /**
       * @description Method to fetch the OrganizationSearchList.
       * @param {Object} options - the options to use for this searched
       * @param {boolean} [options.includeAncestors] - true if the ancestors should be fetched
       * @param {Object} [options.orgType] - object containing filter name value pairs
       * @param {String} [options.query] - the query term to use for the search
       * @param {String} [options.type] - the type of search being performed
       * @returns {OrganizationList} singleton OrganizationSearchList.
       */
      static get(options) {
        const orgList = new OrganizationSearchList(options);
        orgList.refresh();
        return orgList;
      }
    }

    return OrganizationSearchList;

    // private functions

    function getTypeSpecificRefreshParams(type, query) {
      switch (type) {
        case ORGANIZATION_SEARCH_TYPE.ADMIN_EMAIL:
          return {admin_email: query};
        case ORGANIZATION_SEARCH_TYPE.ADMIN_ID:
          return {admin_id: query};
        case ORGANIZATION_SEARCH_TYPE.CLAIMED_DOMAIN:
          return {claimed_domain: query};
        case ORGANIZATION_SEARCH_TYPE.ECC_END_USER_ID:
          return {ecc_end_user_id: query};
        case ORGANIZATION_SEARCH_TYPE.CONTRACT_ID:
          return {id: query};
        case ORGANIZATION_SEARCH_TYPE.EXACT_NAME:
          return {name: query};
        case ORGANIZATION_SEARCH_TYPE.EXTERNAL_CONTRACT_ID:
          return {id: query};
        case ORGANIZATION_SEARCH_TYPE.TOKENIZED_NAME:
          return {tokenized_name: query};
        default:
          throw new Error(`Unknown search type: ${type}`);
      }
    }

    function getResource(type) {
      // ORGANIZATION_SEARCH_TYPE.CORE_SERVICES_TENANT_ID is not included
      // here because it uses a separate API.
      switch (type) {
        case ORGANIZATION_SEARCH_TYPE.ADMIN_EMAIL:
          return jilOrganizations.organizationsByAdminEmail;
        case ORGANIZATION_SEARCH_TYPE.ADMIN_ID:
          return jilOrganizations.organizationsByAdminId;
        case ORGANIZATION_SEARCH_TYPE.CLAIMED_DOMAIN:
          return jilOrganizations.organizationsByClaimedDomain;
        case ORGANIZATION_SEARCH_TYPE.CONTRACT_ID:
          return jilOrganizations.organizationsByContractId;
        case ORGANIZATION_SEARCH_TYPE.ECC_END_USER_ID:
          return jilOrganizations.organizationsByEccId;
        case ORGANIZATION_SEARCH_TYPE.EXACT_NAME:
          return jilOrganizations.organizationsByExactName;
        case ORGANIZATION_SEARCH_TYPE.EXTERNAL_CONTRACT_ID:
          return jilOrganizations.organizationsByExternalContractId;
        case ORGANIZATION_SEARCH_TYPE.TOKENIZED_NAME:
          return jilOrganizations.organizationsByTokenizedName;
        default:
          throw new Error(`Unknown search type: ${type}`);
      }
    }
  }
})();
