const JIL_CONSTANTS = {
  FILTER: {
    PATTERN: /^[^*]*\*?$/,
  },
  HEADERS: {
    ACCEPT: {
      DEFAULT: 'application/json',
      V2: 'application/vnd.com.adobe.jil.storage.paging.v2+json',
    },
    CONTENT_TYPE: 'content-type',
    CURRENT_PAGE: 'x-current-page',
    HAS_NEXT_PAGE: 'x-has-next-page',
    NEXT_PAGE: 'x-next-page',
    PAGE_COUNT: 'x-page-count',
    PAGE_SIZE: 'x-page-size',
    TOTAL_COUNT: 'x-total-count',
  },
  ORDER: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
  SORT: {
    CREATED_DATE: 'CREATED_DATE',
    EMAIL: 'EMAIL',
    FNAME_LNAME: 'FNAME_LNAME',
    LNAME_FNAME: 'LNAME_FNAME',
    NAME: 'NAME',
    STATUS: 'STATUS',
    TITLE: 'TITLE',
    TRUSTEE_ORG_NAME: 'TRUSTEE_ORG_NAME',
    USERNAME: 'USERNAME',
  },
};

export default JIL_CONSTANTS;
