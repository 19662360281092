import jilDirectories from 'api/jil/jilDirectories';
import User from 'models/user/User';
import log from 'services/log';

class DirectoryUser extends User {
  /**
   * @description Method to retrieve an existing DirectoryUser from
   * back-end data store.
   *
   * @param {Object} options options for the DirectoryUser as detailed below
   * @param {String} options.id ID of the user to retrieve for this org
   * @param {String} options.directoryId ID of the directory the user belongs to
   *
   * @returns {DirectoryUser} Reference to pre-existing user
   */
  static get(options = {}) {
    const model = new DirectoryUser({
      directoryId: options.directoryId,
      orgId: options.orgId,
      userId: options.userId,
    });

    return model.refresh();
  }

  /**
   * @class
   * @description Creates a new DirectoryUser.
   *
   * @param {Object} [options] options for the DirectoryUser as detailed below
   * @param {String} [options.directoryId] the id of the directory the user belongs to
   * @param {String} [options.id] the user id
   * @param {String} [options.email] the email address
   * @param {String} [options.firstName] the first name
   * @param {String} [options.lastName] the last name
   * @param {String} [options.countryCode] the country code
   * @param {String} [options.organizations] the orgs a user belongs to
   * @param {String} [options.type] the user type
   * @param {Boolean} [options.editable] True if User is editable
   * @param {String} [options.userName] the user name
   * @param {String} [options.externallyManaged] True if the user has been created from an external system
   * @param {String} [options.accountStatus] Status of this account
   */
  constructor(options = {}) {
    super(options);
    this.directoryId = options.directoryId;
    this.orgId = options.orgId;
    this.organizations = options.organizations;
    this.userId = options.userId;
  }

  /**
   * @description Method to refresh this user's state from the back-end.
   *
   * @returns {Promise} resolves to fetched DirectoryUser instance, else
   * rejects with error message
   */
  async refresh() {
    let response;
    try {
      response = await jilDirectories.getDirectoryUser({
        directoryId: this.directoryId,
        orgId: this.orgId,
        userId: this.userId,
      });
    } catch (error) {
      log.error(`DirectoryUser.refresh() failed. Error: ${error}`);
      return Promise.reject(error);
    }
    Object.assign(this, response.data);

    return this;
  }
}

export default DirectoryUser;
