import {action, computed, makeObservable, observable} from 'mobx';

import PaginationConstants from 'common/components/pagination/PaginationConstants';

import TableConstants from './TableConstants';

/**
 * @deprecated use Pandora TableSection that has table state management built-in:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section
 *  The built-in state management uses the ListContainer package:
 *  https://git.corp.adobe.com/PandoraUI/ui-components/tree/master/packages/react-list-container
 *
 * Store for Spectrum v3 Table.
 * Using the defaults, the table will be setup for multiple-selection with defaults that line up with the
 * JIL model list defaults. This assumes the first page is 1.
 *
 * See the UserGroupsPage in Admin Console for a complete example of how this can be used.
 */
class TableStore {
  currentPage = TableConstants.FIRST_PAGE;
  density;
  disabledKeys = [];
  overflowMode;
  pageSize;
  rows = [];
  selectedItems = [];
  selectionMode;
  sortDescriptor;
  totalItems = 0;

  /**
   *  The TableStore constructor.
   *
   *  @param {TableConstants.DENSITY} density - the amount of white space around each row.
   *   The default is TableConstants.DENSITY.SPACIOUS.
   *  @param {Function} getKey - a function which returns a String which is unique identifier for the item.
   *   By default it will return `id` if it exists, else `key`.
   *  @param {TableConstants.OVERFLOW_MODE} overflowMode - sets the overflow behavior for the cell contents.
   *   By default, the TableView's default will be used.
   *  @param {Integer} pageSize - the number of rows per page in the table.
   *   The default is PaginationConstants.DEFAULT_PAGE_SIZE which is 20.
   *  @param {Array<Object>} pageSizeOptions - the array of objects specifying page sizes id and labels to select from. The default is PaginationConstants.PAGE_SIZES
   *  @param {String} searchQuery - the active search string or '' if there isn't one.
   *   The default is ''.
   *  @param {TableConstants.SELECTION_MODE} selectionMode - the selection mode for the table.
   *    The default is TableConstants.SELECTION_MODE.MULTIPLE. If TableConstants.SELECTION_MODE.SINGLE, there will
   *    be no `Select all checkbox`. If TableConstants.SELECTION_MODE.NONE there will be no selection checkboxes.
   *  @param {Object} sortDescriptor - the descriptor for the active sort column, if there is one.
   *  @param sortDescriptor.name - the name of the column to sort. This should be the same 'key' used for the sort <Column>.
   *  @param sortDescriptor.direction - one of TableConstants.SORT.ASCENDING or TableConstants.SORT.DESCENDING.
   */
  constructor({
    density = TableConstants.DENSITY.SPACIOUS,
    getKey = (item) => item.id || item.key,
    overflowMode,
    pageSize = PaginationConstants.DEFAULT_PAGE_SIZE,
    pageSizeOptions = PaginationConstants.PAGE_SIZES,
    searchQuery = '',
    selectionMode = TableConstants.SELECTION_MODE.MULTIPLE,
    sortDescriptor,
  } = {}) {
    makeObservable(this, {
      clearSelection: action,
      currentPage: observable,
      density: observable,
      disabledKeys: observable,
      goNext: action,
      goPrevious: action,
      hasItems: computed,
      isEmptyTable: computed,
      isSearching: computed,
      isSortDescending: computed,
      overflowMode: observable,
      pageSize: observable,
      rows: observable,
      selectedItemCount: computed,
      selectedItems: observable,
      selectedKeys: computed,
      selectionMode: observable,
      setCurrentPage: action,
      setPageSize: action,
      setSelection: action,
      setSortDescriptor: action,
      setTotalItems: action,
      sortDescriptor: observable,
      totalItems: observable,
      totalPages: computed,
    });

    this.density = density;
    this.getKey = getKey;
    this.overflowMode = overflowMode;
    this.pageSize = pageSize;
    this.pageSizeOptions = pageSizeOptions;
    this.searchQuery = searchQuery;
    this.selectionMode = selectionMode;
    this.sortDescriptor = sortDescriptor;
  }

  /**
   *  Clear the selection.
   */
  clearSelection() {
    this.selectedItems = [];
  }

  /**
   *  Advance the current page by 1.
   */
  goNext() {
    this.setCurrentPage(this.currentPage + 1);
  }

  /**
   *  Decrement the current page by 1.
   */
  goPrevious() {
    this.setCurrentPage(this.currentPage - 1);
  }

  /**
   *  Getter which is true if the there are items/rows to show in the table when there is no active search.
   */
  get hasItems() {
    return this.totalItems > 0;
  }

  /**
   *  Getter which is true if there are no rows currently visible.
   *  This could be due to either an empty list or an active search which has no matches.
   */
  get isEmptyTable() {
    return this.rows.length === 0;
  }

  /**
   *  Getter which is true if there is an active search.
   */
  get isSearching() {
    return this.searchQuery !== '';
  }

  /**
   *  Getter which is true if there is a sort and it is descending.
   */
  get isSortDescending() {
    return this.sortDescriptor?.direction === TableConstants.SORT_DIRECTION.DESCENDING;
  }

  /**
   * Method to be called by the load action to populate the rows with the API data.
   *
   * @param {Array} items - the list items
   * @param {Function} [isSelectableFn] - function which takes the item parameter and
   *   returns true if the item is selectable, otherwise returns false. The
   *   default is every item is selectable.
   */
  mapDataToRows(items, {isSelectableFn = () => true} = {}) {
    this.rows = items.map((item) => ({
      data: item, // data is added to selectedItems if this row is selected
      id: this.getKey(item),
      selectable: isSelectableFn(item),
    }));

    this.disabledKeys = this.rows.filter((row) => !row.selectable).map((row) => this.getKey(row));
    this.selectableKeys = this.rows.filter((row) => row.selectable).map((row) => this.getKey(row));
  }

  /**
   *  Getter returns the number of selected items across all pages.
   */
  get selectedItemCount() {
    return this.selectedItems.length;
  }

  /**
   * The array of selected rows/items, indicated by their keys. The items may be across multiple
   * pages in the list.
   */
  get selectedKeys() {
    return this.selectedItems.map((item) => this.getKey(item));
  }

  /**
   * Sets the page number for the page that is visible.
   */
  setCurrentPage(currentPage) {
    if (currentPage >= TableConstants.FIRST_PAGE) {
      this.currentPage = currentPage;
    }
  }

  /**
   * Sets the page size which is the maximum number of items per page.
   */
  setPageSize(pageSize) {
    if (pageSize > 0) {
      this.pageSize = pageSize;
    }
  }

  /**
   * For Spectrum, the 'select all' checkbox means select all items in the list, even if they
   * haven't been loaded yet.
   * We need to overlay our notion of 'select all' which for us means to select just the items on
   * the current page, not all items in the list.
   *
   * @param selectionSetOrAll {Set | 'all'} - the set of keys of the selected rows, or 'all' if the
   *   select all checkbox is checked on the current table page.
   */
  setSelection(selectionSetOrAll) {
    let newSelection;
    const currentSelection = new Set(this.selectedKeys);

    if (selectionSetOrAll === 'all') {
      // Combine the current selection and all the selectable items on the page.
      // Because it is a set we can be assured that an item will only be selected once.
      newSelection = new Set([...currentSelection, ...this.selectableKeys]);
    } else if (selectionSetOrAll.size === 0) {
      // Remove just the items on the current page from the selection.
      newSelection = setDifference(currentSelection, new Set(this.selectableKeys));
    } else {
      newSelection = selectionSetOrAll;
    }

    // Handle the simple case first.
    if (newSelection.size === 0) {
      this.clearSelection();
      return;
    }

    // We're either adding or removing from the selection, but not both.
    // It will be just one item except if the select all checkbox is selected or deselected.
    const addToSelection = setDifference(newSelection, currentSelection);
    if (addToSelection.size > 0) {
      addToSelection.forEach((key) => {
        const newItem = this.rows.find((item) => this.getKey(item) === key);
        this.selectedItems.push(newItem.data);
      });
    } else {
      const removeFromSelection = setDifference(currentSelection, newSelection);
      removeFromSelection.forEach((key) => {
        this.selectedItems = this.selectedItems.filter((item) => this.getKey(item) !== key);
      });
    }
  }

  /**
   * Sets the sort descriptor for the column in the table to be sorted.
   *  @param descriptor - the sort descriptor
   *  @param descriptor.name - the name of the column to sort. This should be the same 'key' used for the <Column>.
   *  @param descriptor.direction - one of TableConstants.SORT.ASCENDING or TableConstants.SORT.DESCENDING.
   */
  setSortDescriptor(descriptor) {
    this.sortDescriptor = descriptor;
  }

  /**
   * Sets the total number of items in the table. These items can span multiple pages.
   */
  setTotalItems(totalItems) {
    this.totalItems = totalItems;
  }

  /**
   * Getter which returns the total number of pages in the table.
   * This is useful for the paginator.
   */
  get totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }
}

/**
 * Returns a Set that contains those elements of SetA that are not in SetB.
 */
function setDifference(setA, setB) {
  return new Set([...setA].filter((x) => !setB.has(x)));
}

export default TableStore;
