import omitBy from 'lodash/omitBy';

import jilProducts from 'api/jil/jilProducts';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import {CART_EVENT} from 'services/cart/CartConstants';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';
import Product from 'services/product/Product';
import LicenseGroup from 'services/product/license-group/LicenseGroup';

import {PRODUCT_LIST_CACHE_ID, PRODUCT_LIST_EVENT} from './ProductListConstants';
import {hasBuyingProgramEtlaAndVipProducts} from './productListUtils';

class ProductList extends JilModelList {
  /**
   * @description Method to create a new instance of a ProductList.
   * @param {Object} options - configures the ProductList model instance.
   * @param {Boolean} [options.acceptLanguage] - if defined, fetches product list in the provided language, otherwise English.
   * @param {String} [options.contractId] - associates ProductList instance with a contract.
   * @param {Boolean} [options.includeCancellationData] - whether to include cancellation information for the products or not. Defaults to false.
   * @param {Boolean} [options.includeExpired] - whether to include the expired licenses. Defaults to true.
   * @param {Boolean} [options.includeHideable] - whether to include the hideable licenses. Defaults to false.
   * @param {Boolean} [options.includeInactive] - whether to include the inactive product or not. Default to false.
   * @param {Boolean} [options.includeGroupsQuantity] - whether to include include total license group count. Defaults to false.
   * @param {Boolean} [options.includePricingData] - whether to include pricing information for the products or not. Defaults to false.
   * @param {Boolean} [options.includeLicenseAllocationInfo] - whether to include the allocation info. Defaults to false.
   * @param {Number} [options.licenseGroupLimit] - maximum limit for
   *        how many license groups to retreive for each product.
   * @param {Boolean} [options.notifyListeners] - whether the retreival of data should also notify
   *        listeners. This should be set to false when making one-time non-standard calls
   *        that shouldn't affect other components. Defaults to true.
   * @param {String} [options.orgId] - associates ProductList instance with an org.
   * @param {Array} [options.processingInstructionCodes] – processing instructions codes to be included
   */
  constructor(options) {
    super({
      cacheClearingEvents: [CART_EVENT.SUBMIT, MEMBER_EVENT.CREATE, MEMBER_EVENT.UPDATE],
      isCacheable: true,
      itemClassRef: Product,
      modelCacheId: PRODUCT_LIST_CACHE_ID,
      resource: jilProducts.getProducts,
    });
    Object.assign(
      this,
      {
        includeExpired: true,
        includeHideable: !!options?.includeHideable,
        includeInactive: !!options?.includeInactive,
        notifyListeners: true,
      },
      options
    );
  }

  /**
   * @description Adding items in this fashion is not supported for this list. Use 'create' instead.
   */
  // eslint-disable-next-line class-methods-use-this -- overriding super class
  add() {
    throw new Error('ProductList.add() is not supported');
  }

  /**
   * @description Method to return the current unique key for this list.
   *
   * @returns {String} key to uniquely identify this list
   */
  getKey() {
    return super.getKey({
      acceptLanguage: this.acceptLanguage,
      contractId: this.contractId,
      includeAcquiredOfferIds: this.includeAcquiredOfferIds,
      includeCancellationData:
        feature.isEnabled('temp_self_cancel') ||
        feature.isEnabled('temp_self_cancel_trial_with_payment')
          ? this.includeCancellationData
          : undefined,
      includeConfiguredProductArrangementId: this.includeConfiguredProductArrangementId,
      includeExpired: this.includeExpired,
      includeGroupsQuantity: this.includeGroupsQuantity,
      includeInactive: this.includeInactive,
      includeLegacyLSFields: this.includeLegacyLSFields,
      includeLicenseAllocationInfo: this.includeLicenseAllocationInfo,
      includePricingData: this.includePricingData,
      licenseGroupLimit: this.licenseGroupLimit,
      orgId: this.orgId,
      processingInstructionCodes: this.processingInstructionCodes,
    });
  }

  /**
   * @description Method to determine if any products in the list are delegatable
   *
   * @returns {Boolean} true if a delegatable product exists, else false
   */

  // Method name/check will be updated in the following ticket: https://jira.corp.adobe.com/browse/ONESIE-30777
  hasUserDelegatableProduct() {
    return this.items.some((product) => !product.isLegacyDeviceLicense());
  }

  /**
   * @description Method to refresh Product list from back-end.
   * @returns {Promise} resolves to ProductList on success, else rejects with error
   */
  async refresh() {
    try {
      await super.refresh(
        omitBy(
          {
            acceptLanguage: this.acceptLanguage,
            contractId: this.contractId,
            includeAcquiredOfferIds: this.includeAcquiredOfferIds,
            includeCancellationData:
              feature.isEnabled('temp_self_cancel') ||
              feature.isEnabled('temp_self_cancel_trial_with_payment')
                ? this.includeCancellationData
                : undefined,
            includeConfiguredProductArrangementId: this.includeConfiguredProductArrangementId,
            includeExpired: this.includeExpired,
            includeGroupsQuantity: this.includeGroupsQuantity,
            includeInactive: this.includeInactive,
            includeLegacyLSFields: this.includeLegacyLSFields,
            includeLicenseAllocationInfo: this.includeLicenseAllocationInfo,
            includePricingData: this.includePricingData,

            licenseGroupLimit: this.licenseGroupLimit,
            orgId: this.orgId,
            processingInstructionCodes: this.processingInstructionCodes,
          },
          (value) => value === undefined
        ),
        {
          onSuccessPostTransform: () => setPropertiesFromItems(this),
        }
      );
    } catch (error) {
      log.error(
        `ProductList.refresh(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    if (this.notifyListeners) {
      eventBus.emit(PRODUCT_LIST_EVENT.REFRESH, this);
    }

    return this;
  }

  /**
   * @description Method to remove products from the product list
   * @param {Object} [options] - Options to pass to the patchd escribed below.
   * @param {FULFILLMENT_TEST_TYPE} [options.fulfillmentTestType] - The fulfillment test type for the save call
   * @returns {Promise} resolves to ProductList on success, else rejects with error
   */
  async save(options = {}) {
    const {fulfillmentTestType} = options;
    if (this.removedItems.length === 0) {
      return this;
    }
    const operations = this.removedItems.map((product) => ({
      op: 'remove',
      path: `/${product.id}`,
    }));

    try {
      await jilProducts.patchProducts({
        fulfillmentTestType,
        operations,
        orgId: this.orgId,
      });
    } catch (error) {
      log.error('ProductList.save() failed. Error: ', error);
      return Promise.reject(error);
    }

    this.resetRemovedItems();
    this.filter.lastQuery = this.filter.query;
    this.filter.query = '';
    await this.refresh();
    return this;
  }
}

/**
 * @description Method to set additional properties on the productList and its
 *     products after the items have been fetched.
 * @param {ProductList} model - the instance to be updated
 */
function setPropertiesFromItems(model) {
  const updatedModel = model;

  updatedModel.items = model.items
    // We filter out any elements with the 'can_hide' tuple, unless the caller has asked to include them
    .filter(
      (item) =>
        feature.isDisabled('temp_sls_m_3') ||
        model.includeHideable ||
        !item.licenseTupleList.canHide()
    )
    .map((item) =>
      Object.assign(item, {
        licenseGroupSummaries: feature.isEnabled('temp_create_lg_instance')
          ? item.licenseGroupSummaries?.map(
              (profile) => new LicenseGroup({...profile, orgId: model.orgId, product: item})
            )
          : item.licenseGroupSummaries,
        orgId: model.orgId,
      })
    );

  // For multi-contract orgs, we need additional UI to help differentiate products.
  updatedModel.hasBuyingProgramEtlaAndVipProducts = hasBuyingProgramEtlaAndVipProducts(model);

  updatedModel.hasETLAProducts = model.items.some((item) => item.isEnterpriseDirect());
  updatedModel.hasVIPProducts = model.items.some((item) => item.isTeamIndirect());
  updatedModel.hasEVIPProducts = model.items.some((item) => item.isEnterpriseIndirect());
  updatedModel.pagination.itemCount = model.items.length;
}

export default ProductList;
