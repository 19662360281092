(function () {
  /**
   * @deprecated Please use src2/core/services/analytics/AnalyticsConstants.js
   */
  angular
    .module('binky.core.common.analytics')
    // For functions on newrelic, see:
    // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-apis/report-data-events-browser-agent-api#spa-api
    // eslint-disable-next-line angular/window-service
    .constant('newrelic', window.newrelic)
    .constant('ANALYTICS_EVENT', 'AnalyticsEvent');
})();
