import EtlaUsageReportProductList from './EtlaUsageReportProductList';

class EtlaUsageReportContractAndProductBundle {
  /**
   * @description Method to construct a EtlaUsageReportContractAndProductBundle from the etlaUsageReport API
   *  response.
   *
   * @param {Object} bundle - a single bundled EtlaUsageReportProductList
   * @returns {EtlaUsageReportContractAndProductBundle} reference to an EtlaUsageReportContractAndProductBundle object.
   */
  static apiResponseTransformer(bundle) {
    return new EtlaUsageReportContractAndProductBundle(bundle);
  }

  /**
   * @description Constructs EtlaUsageReportContractAndProductBundle
   * @param {Object} bundledItem - configures the EtlaUsageReportContractAndProductBundle model
   *  instance.
   */
  constructor(bundledItem) {
    if (bundledItem) {
      Object.assign(this, applyResource(bundledItem));
    }
  }
}

/**
 * @description Method to create the EtlaUsageReportProductList object and other contract related fields in the bundle object
 * @param {Object} resource - single entry from API
 * @param {Object} resource.contract - resource's contract
 * @param {Object[]} resource.products - resource's products
 */
function applyResource(resource) {
  const {contract, products} = resource;
  return {
    contractId: contract.contractId,
    endDate: contract.endDate,
    graceEndDate: contract.graceEndDate,
    postGraceEndDate: contract.postGraceEndDate,
    products: new EtlaUsageReportProductList(products),
    type: contract.type,
  };
}

export default EtlaUsageReportContractAndProductBundle;
