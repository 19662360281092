(function () {
  /**
   * @deprecated - please use src2/core/models/directory/DirectorySamlConfig.js
   *
   * @ngdoc factory
   * @name binky.core.directories:DirectorySamlConfig
   * @description model to fetch the SamlConfig for an owned directory
   */
  angular
    .module('binky.core.directories')
    .factory('DirectorySamlConfig', getDirectorySamlConfigModel);

  /* @ngInject */
  function getDirectorySamlConfigModel(
    $q,
    $rootScope,
    _,
    binkySrc2,
    DIRECTORY_EVENT,
    DIRECTORY_SAML_CONFIG_CACHE_ID,
    modelCache,
    promiseUtils
  ) {
    class DirectorySamlConfig extends binkySrc2.models.directory.DirectorySamlConfig {
      constructor(options = {}) {
        super(options);
      }

      getTenantMetadata() {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.getTenantMetadata())
          .then((model) => model)
          .catch((error) => $q.reject(error.response))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      refresh() {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.refresh())
          .then(() => this)
          .catch((error) => $q.reject(error.response))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      save(options) {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.save(options))
          .then((model) => model)
          .catch((error) => $q.reject(error.response))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      static get(options) {
        const model = new DirectorySamlConfig(options);

        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((config) => {
            _.assign(model, config);
            return model;
          })
          .catch((error) => $q.reject(error.response))
          .finally(() => {
            model.$resolved = true;
          });

        return model;
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(DIRECTORY_EVENT.REFRESH, (event, directory) => {
      const tenantId = _.get(directory, 'tenantInfo.imsTenantInfo.id');
      modelCache.remove(DIRECTORY_SAML_CONFIG_CACHE_ID, getKey(directory.directoryId, tenantId));
    });

    // We register the cache size for this class
    modelCache.register(DIRECTORY_SAML_CONFIG_CACHE_ID, 1);

    return DirectorySamlConfig;

    function getKey(directoryId, tenantId) {
      return `${directoryId}/${tenantId}`;
    }
  }
})();
