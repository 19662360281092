import {navBus} from '@admin-tribe/binky';
import {ActionMenu, Flex, Item, Provider, defaultTheme} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import getTextWidth from 'common/utils/getTextWidth';

import HeaderNavItem from './HeaderNavItem';
import NavItemAnchor from './HeaderNavItemAnchor';

// Multiplier that will be multiplied by the calculated width of each nav item.
// We do this to trigger a programmatic collapse prior to the buttons being
// forced to wrap to the next line.
const MULTIPLIER = 2;

/**
 * Component that will return the navItems as Links or if some cannot
 * fit the container they'll be bucketed into a Picker.
 */
const NavItems = ({maxWidth, navItems, selectedNavItemKey}) => {
  const intl = useIntl();
  const itemsToRender = [];
  const itemsToHide = [];
  let totalWidthOfItemsToRender = 0;
  const targetEl = document.querySelector('[data-testid="navitems-wrapper"]');

  let maxWidthReached = false;
  if (targetEl) {
    navItems.forEach((navItem) => {
      const navItemWidth = getTextWidth(targetEl, navItem.getLocalizedLabel(intl));

      const proposedWidth = totalWidthOfItemsToRender + navItemWidth * MULTIPLIER;
      if (!maxWidthReached && proposedWidth < maxWidth) {
        totalWidthOfItemsToRender = proposedWidth;
        itemsToRender.push(
          <NavItemAnchor
            key={navItem.key}
            intl={intl}
            item={navItem}
            selectedNavItemKey={selectedNavItemKey}
          />
        );
      } else {
        maxWidthReached = true;
        itemsToHide.push(navItem);
      }
    });
  }

  const onMenuActionPress = (itemKey) => {
    const item = itemsToHide.find((i) => itemKey === i.key);
    navBus.navigate(item.href);
  };

  return (
    <Provider colorScheme="dark" theme={defaultTheme} UNSAFE_style={{backgroundColor: 'black'}}>
      <Flex alignItems="center">
        {itemsToRender}
        {itemsToHide.length > 0 && (
          <ActionMenu
            aria-label={intl.formatMessage({
              id: 'binky.shell.header.nav.more.ariaLabel',
            })}
            isQuiet
            items={itemsToHide}
            marginEnd="size-200"
            marginY="size-50"
            onAction={onMenuActionPress}
          >
            {(item) => (
              <Item key={item.key} data-testid={`menu-nav-item-${item.key}`}>
                {item.getLocalizedLabel(intl)}
              </Item>
            )}
          </ActionMenu>
        )}
      </Flex>
    </Provider>
  );
};

NavItems.propTypes = {
  /**
   * Max width that this component should occupy.
   */
  maxWidth: PropTypes.number.isRequired,
  /**
   * Array of nav items that should have links or picker items rendered.
   */
  navItems: PropTypes.arrayOf(PropTypes.instanceOf(HeaderNavItem)).isRequired,
  /**
   * Currently selected nav item key
   */
  selectedNavItemKey: PropTypes.string,
};

export default NavItems;
