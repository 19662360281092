import axios from 'axios';

import authentication from 'services/authentication';

let clientId, url;

/**
 * Configure stock APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for Stock api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({url, clientId} = config);
};

/**
 * Get headers for Stock API calls
 *
 * @returns {Object} The header map
 */
const getHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for Stock API call');
  }
  const headers = {
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };

  return headers;
};

/**
 * Fetches the product quantities for the given license group
 *
 * @param {String} orgId - The org id
 * @param {String} [id] - The license group id
 */
const getProductQuantities = async ({orgId, id}) => {
  const response = await axios.get(
    `${url}/organization-usage/${orgId}/product-quantities/${id || ''}`,
    {
      headers: getHeaders(),
    }
  );
  return response;
};

const stock = {configure, getProductQuantities};

export default stock;
