import {DirectoryMoveModal} from '@pandora/react-directory-move-modal';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @description This represents a wrapper for the directory move modal in
 * pandora library.
 */
const DirectoryMoveModalWrapper = (props) => (
  <ModalContainer>
    <DirectoryMoveModal {...props} />
  </ModalContainer>
);

DirectoryMoveModalWrapper.propTypes = {
  /**
   * Callback used to dismiss/close the dialog
   */
  close: PropTypes.func.isRequired,
  /**
   * The id of the directory that will be moved.
   */
  directoryId: PropTypes.string.isRequired,
  /**
   * Callback to invoke when the modal is cancelled.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's move operation is successful.
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * The id of the organization that originally owned the directory being moved.
   */
  sourceOrgId: PropTypes.string.isRequired,
};

export default DirectoryMoveModalWrapper;
