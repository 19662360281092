(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableSummarizationList.js
   *
   * @ngdoc factory
   * @name ConsumableSummarizationList
   * @description List of ConsumableSummarization entities
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-ConsumableSummaryAPI
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableSummarizationList', getConsumableSummarizationListModel);

  /* @ngInject */
  function getConsumableSummarizationListModel(
    $rootScope,
    _,
    binkySrc2,
    PRODUCT_CONFIGURATIONS_EVENT,
    promiseUtils
  ) {
    class ConsumableSummarizationList2 extends binkySrc2.models.product.consumable
      .ConsumableSummarizationList {
      /**
       * @description Creates a new ConsumableSummarizationList instance.
       * @param {Object} [options] - see ConsumableSummarizationList.js
       */
      constructor(options) {
        super(options);

        this.deregisterOnProfileAddedHandler = $rootScope.$on(
          PRODUCT_CONFIGURATIONS_EVENT.CREATE,
          this.refresh.bind(this)
        );
      }

      /**
       * @description Method to free up any resources that need to be explicitly
       *     removed to prevent memory leaks (listeners, handlers, etc.)
       */
      destroy() {
        _.invoke(this, 'deregisterOnProfileAddedHandler');
      }

      /**
       * @description Method to fetch an array of ConsumableSummarizations
       *
       * @returns {Promise} Resolves with refreshed model if successful, else
       *     rejects with error message
       */
      refresh() {
        this.$resolved = false;
        this.$promise = promiseUtils.toAngularPromise(super.refresh()).finally(() => {
          this.$resolved = true;
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the ConsumableSummarization. See
       *     ConsumableSummarizationList.js for params.
       * @param {Object} [options] - see ConsumableSummarizationList.js
       * @returns {ConsumableSummarizationList} the fetched ConsumableSummarizationList.
       */
      static get(options) {
        const model = new ConsumableSummarizationList2(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(super.get(options))
          .then((response) => {
            _.assign(model, response);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    return ConsumableSummarizationList2;
  }
})();
