import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const HorizontalListItem = ({
  children,
  className,
  marginBottom = 'size-150',
  marginTop = 'size-150',
  maxWidth,
}) => (
  <li className={className}>
    <View
      borderEndColor="gray-400"
      borderEndWidth="thick"
      marginBottom={marginBottom}
      marginTop={marginTop}
      maxWidth={maxWidth}
      paddingEnd="size-200"
      paddingStart="size-200"
    >
      {children}
    </View>
  </li>
);

HorizontalListItem.propTypes = {
  /**
   * The contents of what is displayed in the list.
   */
  children: PropTypes.node.isRequired,
  /**
   * Customized class name to be applied on the HorizontalListItem.
   */
  className: PropTypes.string,
  /**
   * Bottom margin, use React Spectrum sizing: e.g. "size-0"
   * See https://react-spectrum.adobe.com/react-spectrum/styling.html#dimension-values
   * Default value is 'size-150'
   */
  marginBottom: PropTypes.string,
  /**
   * Top margin, use React Spectrum sizing: e.g. "size-0"
   * See https://react-spectrum.adobe.com/react-spectrum/styling.html#dimension-values
   * Default value is 'size-150'
   */
  marginTop: PropTypes.string,
  /**
   * Maximum width of this list item in pixels.
   */
  maxWidth: PropTypes.number,
};

export default HorizontalListItem;
