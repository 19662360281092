(function () {
  /**
   * @ngdoc module
   * @name binky.core.organizations.encryption
   * @description module that defines organization encryption
   */
  angular.module('binky.core.organizations.encryption', [
    'binky.core.api.kmi',
    'binky.core.api.kms',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.organizations',
  ]);
})();
