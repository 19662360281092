(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableUsage.js
   *
   * @ngdoc factory
   * @name ConsumableUsage
   * @description Model for an individual Consumable
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Usage
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableUsage', getConsumableUsageModel);

  /* @ngInject */
  function getConsumableUsageModel(_, CONSUMABLE_THRESHOLD_STATUS) {
    class ConsumableUsage {
      /**
       * @description Creates a new ConsumableUsage for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Number} options.consumedQuantity quantity consumed (for this usage)
       * @param {String} [options.externalId] (optional) authorization system identifier for this usage instance
       * @param {String} [options.fulfillmentId] (optional) id of fulfillment that this usage is a byproduct of
       * @param {String} [options.fulfillmentExternalItemId] (optional) external_item_id the fulfillable item
            appeared in within the internal fulfillment needed request
       * @param {String} [options.offerId] (optional) the offer whose fulfillment resulted in this usage
       * @param {Date} [options.expirationDate] (optional) ISO including time zone
       * @param {Number} [options.overConsumedQuantity] (optional) n/a for stock; from Sign
       * @param {Boolean} [options.overQuota] (optional) n/a for stock; from Sign
       * @param {Number} options.remainingQuantity quantity remaining (for this usage)
       * @param {Date} [options.startDate] (optional) ISO including time zone
       * @param {String} [options.status] (optional) status condition of the usage, such as EXPIRED
       * @param {String} [options.thresholdStatus] [CONSUMABLE_THRESHOLD_STATUS]  (optional) used for badging in admin console; NONE means no threshold has been reached
       * @param {Number} [options.thresholdValue] (optional) when a threshold_status suggests a value, such as a percentage, value computed by consumable system
       * @param {Number} options.totalQuantity total quantity of consumable (for this usage)
       * @param {String} options.unit unit the quantities are measurements in, i.e. transactions, gigabytes, etc
       */
      constructor(options) {
        initModel(this, options);
      }

      /**
       * @description Method to determine if this ConsumableUsage has had a threshold triggered or not
       *
       * @returns {Boolean} true if threshold reached/triggered, false otherwise
       */
      isThresholdTriggered() {
        return (
          this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.DEPLETED ||
          this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.PERCENTAGE_THRESHOLD
        );
      }

      /**
       * @class
       * @description Transforms the /consumable summary or usage response Object into a new ConsumableUsage
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new ConsumableUsage(dataTransferObject);
      }
    }

    //////////////

    function initModel(model, options) {
      // Assign the model fields
      _.assignIn(
        model,
        _.pick(options, [
          'consumedQuantity',
          'externalId',
          'fulfillmentExternalItemId',
          'fulfillmentId',
          'offerId',
          'overConsumedQuantity',
          'overQuota',
          'remainingQuantity',
          'status',
          'thresholdStatus',
          'thresholdValue',
          'totalQuantity',
          'unit',
        ])
      );
      // TO DO: we may have to do additional work to convert these to JS Date
      if (options.expirationDate) {
        model.expirationDate = new Date(options.expirationDate);
      }
      if (options.startDate) {
        model.startDate = new Date(options.startDate);
      }
    }

    return ConsumableUsage;
  }
})();
