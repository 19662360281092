import binky from '@admin-tribe/binky';
import uniqueId from 'lodash/uniqueId';
import {action, makeObservable, observable} from 'mobx';

import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from 'common/components/add-user-form-table/utils/MemberAndSelectedItemsConstants';

import {getErrorMessage, getInfoMessage} from './utils/addUserFormTableDisplayUtils';

const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const Product = binky.services.product.Product;
const UserGroupLicenseGroupsList = binky.services.users.UserGroupLicenseGroupsList;
const licenseGroupUtils = binky.services.product.licenseGroup.licenseGroupUtils;

/**
 * An object that reflects the state of a single row in the AddUserFormTable.
 */
class MemberAndSelectedItems {
  errorStatuses = new Set();
  infoStatuses = new Set();
  loadingStatuses = new Set();

  constructor() {
    makeObservable(this, {
      addErrorStatus: action,
      addInfoStatus: action,
      addLoadingStatus: action,
      clearErrorStatus: action,
      clearInfoStatus: action,
      clearLoadingStatus: action,
      errorStatuses: observable,
      infoStatuses: observable,
      loadingStatuses: observable,
    });

    this.id = uniqueId();
    // The member selected from the UserPicker component
    this.member = null;
    // Selected items from the AssignmentSection component
    this.selectedItems = [];
    // Product roles based on license group
    this.productRoles = [];

    // UserPicker's query text
    this.queryText = undefined;
  }

  addErrorStatus(errorStatus) {
    this.errorStatuses.add(errorStatus);
  }

  addInfoStatus(infoStatus) {
    this.infoStatuses.add(infoStatus);
  }

  addLoadingStatus(loadingStatus) {
    this.loadingStatuses.add(loadingStatus);
  }

  clearErrorStatus(errorStatus) {
    this.errorStatuses.delete(errorStatus);
  }

  clearInfoStatus(infoStatus) {
    this.infoStatuses.delete(infoStatus);
  }

  clearLoadingStatus(loadingStatus) {
    this.loadingStatuses.delete(loadingStatus);
  }

  /**
   * Assuming that the current "member" is a user-group,
   * queries the API for UserGroup information on itself.
   */
  async fetchUserGroupDetails() {
    const userGroupLicenseGroupsList = await UserGroupLicenseGroupsList.get({
      groupId: this.member.id,
      orgId: this.member.orgId,
    });

    this.member.products = licenseGroupUtils.toProductArray(userGroupLicenseGroupsList.items);
    this.member.registerSavedState();
  }

  /**
   * @returns Returns the error status, if one exists, to use in getFirstValidationMessage().
   */
  getFirstErrorStatus() {
    // We do not need to send a validation message to UserPicker that UserPicker has told us about.
    return [...this.errorStatuses].find(
      (item) => item !== MEMBER_AND_SELECTED_ITEMS_CONSTANTS.ERROR_STATUS.USER_PICKER_ERROR
    );
  }

  /**
   * Returns the validation message, if one exists, to display in the parent AddUserForm.
   *
   * @param {UserPicker.PICKER_TYPE} pickerType - The pickerType used by the form
   * @param {Object} intl - React-Intl object coming from the useIntl hook
   * @returns a ValidatedTextField.validationMessage object, or undefined
   */
  getFirstValidationMessage(pickerType, intl) {
    const firstErrorValidationStatus = this.getFirstErrorStatus();
    if (firstErrorValidationStatus) {
      return {
        value: getErrorMessage(firstErrorValidationStatus, {
          intl,
          pickerType,
        }),
        variant: 'error',
      };
    }
    if (this.hasInfoMessages()) {
      return {
        value: getInfoMessage(this.infoStatuses.values().next().value, {
          intl,
        }),
        variant: 'info',
      };
    }
    return undefined;
  }

  /**
   * Returns the list of selections of the given type, made in the AssignmentMenu for this Member.
   * @param {Class} itemType - The object class to search for in the AssignmentMenu
   *   e.g. UserGroups, Products, Admin-Roles.
   * @returns an array of selections of the given Type made for the current Member
   */
  getSelectedItems(itemType) {
    return this.selectedItems.filter((item) => item instanceof itemType);
  }

  /**
   * Returns the list of Product selections made in the AssignmentMenu for this Member.
   * @returns an array of Product selections
   */
  getSelectedProducts() {
    const products = this.getSelectedItems(Product).flatMap((product) =>
      licenseGroupUtils.toProductArray(product.licenseGroups || product.licenseGroupSummaries)
    );
    return products;
  }

  /**
   * Returns the list of Product and LicenseGroup selections made in the AssignmentMenu for this Member.
   * @returns an array of Product and LicenseGroup selections
   */
  getSelectedProductsAndLicenseGroups() {
    const getProductLicenseGroupsByProductId = () => {
      const licenseGroups = this.getSelectedItems(LicenseGroup);
      return licenseGroupUtils.toProductArray(licenseGroups);
    };

    const products = this.getSelectedProducts();
    products.push(...getProductLicenseGroupsByProductId());

    return products;
  }

  /**
   * @returns true if there are any error-messages to be displayed.
   */
  hasErrors() {
    return this.errorStatuses.size > 0;
  }

  /**
   * @returns true if there are any info-messages to be displayed.
   */
  hasInfoMessages() {
    return this.infoStatuses.size > 0;
  }

  /**
   * @returns true if this object has a Member and it is valid.
   */
  hasValidMember() {
    return !!this.member && !this.hasErrors();
  }

  /**
   * @returns true when there is no information present in the object
   */
  isEmpty() {
    return this.selectedItems.length === 0 && !this.member && !this.hasErrors();
  }

  /**
   * Invalid when the instance has errors or it's in a loading state.
   */
  isInvalid() {
    return this.hasErrors() || this.isLoading();
  }

  /**
   * @returns true if the object is currently loading.
   */
  isLoading() {
    return this.loadingStatuses.size > 0;
  }

  /**
   * Given a status value, returns whether the object is loading due to that status.
   * @param {String} status - The status to look for
   * @returns true if the given status is one of the current loading statuses, false otherwise
   */
  isStatusLoading(status) {
    return this.loadingStatuses.has(status);
  }

  /**
   * Sets the query text for the form.
   * @param {String} queryText - the query text to place in the UserPicker-search field.
   */
  setQueryText(queryText) {
    this.queryText = queryText;
  }

  /**
   * When an existing user is selected from the UserPicker, it will fetch the user details
   * or the user group details based on the member's type.
   * @param {Boolean} fetchMemberDetails - only fetch member details when fetchMemberDetails is set to true
   * @param {Member} member - a member selected from the UserPicker
   */
  async updateMember({fetchMemberDetails, member}) {
    this.member = member;

    if (fetchMemberDetails && this.member && !this.member.isNew()) {
      this.addLoadingStatus(
        MEMBER_AND_SELECTED_ITEMS_CONSTANTS.LOADING_STATUS.FETCHING_USER_OR_USER_GROUP_DETAILS
      );
      this.clearErrorStatus(MEMBER_AND_SELECTED_ITEMS_CONSTANTS.ERROR_STATUS.SERVER_ERROR);

      try {
        if (this.member.getType().isUserGroup()) {
          await this.fetchUserGroupDetails();
        } else {
          await member.refresh();
        }
      } catch {
        this.addErrorStatus(MEMBER_AND_SELECTED_ITEMS_CONSTANTS.ERROR_STATUS.SERVER_ERROR);
      } finally {
        this.clearLoadingStatus(
          MEMBER_AND_SELECTED_ITEMS_CONSTANTS.LOADING_STATUS.FETCHING_USER_OR_USER_GROUP_DETAILS
        );
      }
    }
  }

  /**
   * Update the product roles for this member
   * @param {Array<ProductRole>} value - The product roles
   */
  updateProductRoles(value) {
    this.productRoles = value;
  }

  /**
   * Update the selected assignment items for this member
   * @param {Array} value - The selected items as defined in AssignmentSection
   */
  updateSelectedItems(value) {
    this.selectedItems = value;
  }
}
export default MemberAndSelectedItems;
