import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import Product from 'services/product/Product';

/**
 * @description Generates array of products from an array of license groups
 * @param {Array<LicenseGroup>} licenseGroupArray - array of license groups
 * @returns {Array<Product>} array of products
 */
function toProductArray(licenseGroupArray) {
  const groupedLicenseGroups = groupBy(licenseGroupArray, 'product.id');
  const productArray = [];

  Object.entries(groupedLicenseGroups).forEach(([productId, groupedlicenseGroupArr]) => {
    if (productId !== 'undefined') {
      const product = new Product({
        ignoreEmptyFIs: true,
        ...get(groupedlicenseGroupArr, '[0].product'),
      });

      product.licenseGroups = groupedlicenseGroupArr.map((licenseGroup) => ({id: licenseGroup.id}));
      productArray.push(product);
    }
  });

  return productArray;
}

// eslint-disable-next-line import/prefer-default-export -- Disable lint
export {toProductArray};
