(function () {
  /**
   * @deprecated code should be using KMI instead of KMS
   *
   * @ngdoc provider
   * @name binky.core.api:kmsProvider
   * @description
   *    Service that calls CA-IL Key Management Service (KMS)
   *    to retrieve the list of encrypted domains for an org.
   */
  angular.module('binky.core.api.kms').provider('kms', kmsProvider);

  function kmsProvider(_) {
    let url;
    this.$get = $get;
    this.configure = configure;

    function configure(config) {
      url = `${config.url}`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        domains: getDomainsResource(),
        encryptedDirectories: getEncryptedDirectoriesResource(),
        encryptedDomains: getEncryptedDomainsResource(),
      };

      return service;

      ///////////////

      function getDomainsResource() {
        return $resource(
          `${url}/organizations/:orgId/domains/:id/kms`,
          {
            id: '@id',
            orgId: formatActiveOrgIdForDomainsApi,
          },
          {
            disableEncryption: {
              headers: {
                Accept: 'application/json;strict=true',
              },
              method: 'PUT',
            },
            enableEncryption: {
              headers: {
                Accept: 'application/json;strict=true',
              },
              method: 'POST',
            },
          }
        );
      }

      function getEncryptedDirectoriesResource() {
        return $resource(
          `${url}/organizations/:orgId/encrypted-directories`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              headers: {
                Accept: 'application/json;strict=true',
              },
              isArray: true,
              method: 'GET',
            },
          }
        );
      }

      function getEncryptedDomainsResource() {
        return $resource(
          `${url}/enterprises/:orgId/encrypted-domains/:id`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            get: {
              headers: {
                Accept: 'application/json;strict=true',
              },
              method: 'GET',
            },
            query: {
              headers: {
                Accept: 'application/json;strict=true',
              },
              isArray: true,
              method: 'GET',
            },
          }
        );
      }

      function formatActiveOrgIdForDomainsApi() {
        const parts = _.split(apiUtils.getActiveOrg(), '@');
        return `${parts[1]}-${parts[0]}`;
      }
    }
  }
})();
