(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.shell.workspace').constant('WORKSPACE', {
    NAV_BUTTON_CLICKED: 'WorkspaceHeaderNavButtonClicked',
    NAV_DISABLED: 'WorkspaceNavDisabled',
    NAV_ENABLED: 'WorkspaceNavEnabled',
  });
})();
