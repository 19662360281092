import pick from 'lodash/pick';

/**
 * @description This class repreesent a primary product used in user interactions
 */
class PrimaryProductDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {Number} [options.numberSelected] User selected quantity
   * @param {String} [options.offer_id] Offer ID
   * @param {String} [options.productBucket] The source bucket where the product was selected from
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['offer_id', 'numberSelected', 'productBucket']));
  }
}

export default PrimaryProductDescriptor;
