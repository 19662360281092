import jilUserGroups from 'api/jil/jilUserGroups';
import {ORGANIZATION_ADMIN_LIST_CACHE_ID} from 'models/organizationAdminList/OrganizationAdminListConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import modelCache from 'services/cache/modelCache/modelCache';
import MemberList from 'services/member/MemberList';

import {
  USER_GROUP_ADMIN_LIST_CACHE_ID,
  USER_GROUP_ADMIN_LIST_EVENT,
} from './UserGroupAdminListConstants';

class UserGroupAdminList extends MemberList {
  /**
   * @description Constructor for UserGroupAdminList model Objects.
   *
   * @param {Object} options - See MemberList for additional options
   * @param {String} options.userGroupId - The user group id
   */
  constructor(options) {
    super({
      eventUpdateCount: USER_GROUP_ADMIN_LIST_EVENT.UPDATE.COUNT,
      eventUpdateList: USER_GROUP_ADMIN_LIST_EVENT.UPDATE.LIST,
      itemClassRef: OrganizationUser,
      modelCacheId: USER_GROUP_ADMIN_LIST_CACHE_ID,
      refreshResource: jilUserGroups.getUserGroupAdmins,
      saveResource: jilUserGroups.patchUserGroupAdmins, // removes only
      ...options,
    });

    this.userGroupId = options.userGroupId;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      userGroupId: this.userGroupId,
    };
  }

  /**
   *  @description Method to remove user group admins.
   *
   *  NOTE: User group admins are currently added using OrganizationUserList.save()
   *        in combination with MemberAndSelectedItemsList.
   *        See Onesie AddAdminsToUserGroupModal.
   * @param {Object} [params] - params to pass into the save resource. See MemberList.
   * @param {Object} [options] - custom options for saving
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to false.
   * @returns {Promise<UserGroupAdminList>} promise - resolved when the list operations are done
   */
  async save(params = {}, options = {}) {
    if (this.addedItems.length > 0) {
      throw new Error('UserGroupAdminList.save is not implemented for addedItems');
    }

    const response = await super.save(
      {
        ...getSaveParams(this),
        ...params,
      },
      {
        refresh: false,
        ...options,
      }
    );

    // If the User group admin list is modified, then the Administrators list is modified too.
    modelCache.clear(ORGANIZATION_ADMIN_LIST_CACHE_ID);

    return response;
  }
}

/**
 * @description Fetch params used for this call.
 *
 * @param {Object} model the model to pick params from.
 * @returns {Array} params to be passed to JIL or used as a key.
 */
function getSaveParams(model) {
  return {
    userGroupId: model.userGroupId,
  };
}

export default UserGroupAdminList;
