(function () {
  /**
   *
   * @ngdoc factory
   * @name SigmaListState
   * @description class for representing the sigma state of Lists
   */
  angular.module('binky.core.common.list.state.sigma').factory('SigmaListState', getSigmaListState);

  /* @ngInject */
  function getSigmaListState(_, JIL_CONSTANTS, LIST_DEFAULT_PAGE_SIZE) {
    class SigmaListState {
      /**
       * @class
       * @description Constructor for SigmaListState model Objects. Sigma list states are
       * list states based on JIL's normal page-based APIs, but do not have the x-total-count header
       * and instead uses the x-has-next-page http header to signal a next page.
       *
       * EDIT: apparently LDS supports x-total-count now. If this is being ported to React, double check that
       * JIL's API license-quality API works normally with x-total-count. If it does, this whole list state can be
       * deprecated in favor of our regular list class.
       *
       * Sigma list state will track the current page index, the page size, and existence of
       * a previous/next page.
       *
       * Currently, the only JIL API this list state applies to is {orgId}/products/{productId}/users:search.license-quality.
       *
       * @param {Object} [options] - options object
       */
      constructor(options = {}) {
        _.assign(
          this,
          {
            currentPage: 0,
            itemCount: 0,
            pageSize: options.pageSize || LIST_DEFAULT_PAGE_SIZE,
          },
          options
        );
      }

      goToNextPage() {
        if (this.hasNextPage()) {
          this.currentPage += 1;
        }
      }

      goToPreviousPage() {
        if (this.currentPage > 0) {
          this.currentPage -= 1;
        }
      }

      hasNextPage() {
        return this.hasNextPageHeader;
      }

      hasPreviousPage() {
        return this.currentPage > 0;
      }

      setResponseAndHeaders(response, headers) {
        // Use JSON.parse to convert string 'true'/'false' to boolean true/false
        this.hasNextPageHeader = JSON.parse(headers(JIL_CONSTANTS.HEADERS.HAS_NEXT_PAGE)) || false;
        this.currentPage = parseInt(headers(JIL_CONSTANTS.HEADERS.CURRENT_PAGE), 10) || 0;
        this.itemCount = parseInt(headers(JIL_CONSTANTS.HEADERS.TOTAL_COUNT), 10) || 0;
      }

      toQueryParams() {
        return {
          page: this.currentPage,
          page_size: this.pageSize,
        };
      }
    }

    return SigmaListState;
  }
})();
