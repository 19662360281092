(function () {
  /**
   * @deprecated Please use src2/core/services/fulfillableItems/FulfillableItemList.js
   *
   * @ngdoc factory
   * @name FulfillableItemList
   * @description Model for the Fulfillable Item List
   */
  angular
    .module('binky.core.product.fulfillable-item-list')
    .factory('FulfillableItemList', getFulfillableItemListModel);

  /* @ngInject */
  function getFulfillableItemListModel(binkySrc2) {
    return binkySrc2.models.fulfillableItems.FulfillableItemList;
  }
})();
