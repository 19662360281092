import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Invoices APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Invoices Search api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches the invoice resource for a particular
 *     organization with given org id.
 * @param {Date} [endDate] - Date filter of the end of a time period
 * when a user wants to see invoices
 * @param {String} orgId - The associated org Id
 * @param {Date} [startDate] - Date filter of the beginning of a time
 * period when a user wants to see invoices
 */
const getInvoices = (params) => {
  const {orgId, ...queryParams} = params;
  return axios.get(`${url}/v2/organizations/${orgId}/invoices`, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Updates PO number of the invoice
 * @param {String} orgId - The associated org Id
 * @param {String} invoiceId - The associated invoice Id
 * @param {String} contractId - The associated contract Id
 * @param {String} newPoNumber - The new PO number
 * @param {String} oldPoNumber - The old PO number
 * @param {String} reason - The reason for updating
 * PO number('MISSING_PO_NUMBER', 'INCORRECT_PO_NUMBER', 'INVALID_PO_NUMBER')
 */
const putUpdatePONumber = ({invoiceId, orgId, ...payload}) =>
  axios.put(`${url}/v2/organizations/${orgId}/invoices/${invoiceId}:update-po-number`, payload, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilInvoices = {configure, getInvoices, putUpdatePONumber};

export default jilInvoices;
