import {CLOUD, Product, SUPPORT_ADMINS, feature} from '@admin-tribe/binky';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v3 equivalent does not support the UI we want
import {MenuDivider, MenuHeading, SubMenu} from '@react/react-spectrum/Menu';
import {FocusScope} from '@react-aria/focus';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {RawIntlProvider, useIntl} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import ImageIcon from 'common/components/image-icon/ImageIcon';
import {getProductDisplayName} from 'features/product/productDisplayUtils';

import {AssignmentMenuContext, useAssignmentMenuContext} from '../AssignmentMenuContext';
import {LICENSE_ACTIONS, useLicenseLimitContext} from '../assignmentMenuUtils';
import MenuItemCheckbox from '../menu-item-checkbox/MenuItemCheckbox';
import ProductProfileMenu from '../product-profile-menu/ProductProfileMenu';
import MenuNoItemsMessage from '../shared/MenuNoItemsMessage';

import styles from './ProductMenu.pcss';

const {MENUITEM_PREFIX} = ASSIGNMENT_MENU_CONSTANTS.IDS;

const CLOUD_ORDERING = [CLOUD.CREATIVE, CLOUD.DOCUMENT, CLOUD.EXPERIENCE, CLOUD.OTHERS];

/**
 * @returns {Component} A component that will display products and their product profiles as
 * menuitems and menuitemcheckboxes grouped by its cloud. License groups will appear in a SubMenu
 * when enableProfileSelection is set to true. By default, the SubMenu will only display if there
 * is more than one license group.
 *
 */
const ProductMenu = ({enableProfileSelection, onSelection, orgId}) => {
  const intl = useIntl();
  const subMenuRefs = useRef({});

  const menuContext = useAssignmentMenuContext();
  const {consumableSummarizationList, isDisabled, isSelected, role, selectableItems, toggleItem} =
    menuContext;

  const [licenseLimitState, dispatch] = useLicenseLimitContext();

  const selectableProducts = selectableItems.filter((item) => item instanceof Product);
  const sortedProducts = sortBy(selectableProducts, 'longName');
  const productsGroupedByCloud = groupBy(sortedProducts, 'cloud');

  function isItemDisabled(productOrLicenseGroup) {
    const product = getProductFromItem(productOrLicenseGroup);
    return (
      isDisabled(product) ||
      (!product.fulfillableItemList.hasOverdelegationAllowed() &&
        licenseLimitState?.[product.id] === 0 &&
        !isSelected(productOrLicenseGroup))
    );
  }

  function getHeadingText(id, selectedCount) {
    return intl.formatMessage({id}, {selectedCount});
  }

  function onSelectionInternal(productOrLicenseGroup, isItemSelected) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    // istanbul ignore else
    if (showLicenseLimit(productOrLicenseGroup)) {
      const product = getProductFromItem(productOrLicenseGroup);
      if (isItemSelected) {
        dispatch?.({product, type: LICENSE_ACTIONS.SELECT});
        // Note: the DESELECT action of the license counter is handled in the pills when unmounted
        // to capture cases where the pills are dismissed not via the dismiss button but through other actions
        // such as retyping the email or clicking the "Remove" button.
      }
    }

    toggleItem(productOrLicenseGroup);

    onSelection?.(productOrLicenseGroup, isItemSelected);
  }

  function showProfileSubMenu(product) {
    return (
      enableProfileSelection &&
      (product.licenseGroupSummaries === undefined ||
        product.licenseGroupSummaries.length > 1 ||
        product.licenseGroupSummaries.length === 0)
    );
  }

  /**
   * @description Returns license group reference if product menu is in profile selection mode else product
   * @param {Product} product the product instance
   * @returns {Product|LicenseGroup} depending on the menu mode
   */
  function getTargetItem(product) {
    return enableProfileSelection && product.licenseGroupSummaries?.length > 0
      ? product.licenseGroupSummaries?.[0]
      : product;
  }

  function showLicenseLimit(productOrLicenseGroup) {
    const product = getProductFromItem(productOrLicenseGroup);
    return !product.fulfillableItemList.hasOverdelegationAllowed();
  }

  function getProductFromItem(productOrLicenseGroup) {
    if (productOrLicenseGroup instanceof Product) {
      return productOrLicenseGroup;
    }
    // else productOrLicenseGroup is a LicenseGroup
    return productOrLicenseGroup.product;
  }

  function getRemainingLicenseCount(productOrLicenseGroup, product) {
    if (showLicenseLimit(productOrLicenseGroup) && licenseLimitState?.[product.id] !== undefined) {
      return `(${intl.formatNumber(licenseLimitState?.[product.id])})`;
    }
    return undefined;
  }

  // Renders the cloud heading and its associated products

  const renderMenuSection = (cloud, index) => (
    <React.Fragment key={cloud}>
      {index !== 0 && <MenuDivider />}
      <MenuHeading>
        {getHeadingText(
          `binky.common.assignmentMenu.productMenu.heading.${cloud.toLowerCase()}`,
          selectableProducts.filter((item) => item.cloud === cloud).length
        )}
      </MenuHeading>

      {productsGroupedByCloud[cloud].map((product) => {
        const productName = getProductDisplayName(intl, product, {
          consumableSummarizationList,
          showConsumableQuota: feature.isDisabled('temp_hide_psa_quota') && role === SUPPORT_ADMINS,
        });

        // Item is either the product or licenseGroup for the MenuItemCheckbox
        const item = getTargetItem(product);

        return showProfileSubMenu(product) && !isDisabled(product) ? (
          <SubMenu
            key={product.id}
            ref={
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
              /* istanbul ignore next */ (subMenuRef) => {
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
                // istanbul ignore next
                subMenuRefs.current[product.id] = subMenuRef;
              }
            }
            _onNestedSubmenuOpen={() => {
              // when a sub-menu closes, it tries to re-focus the last item focused.
              // there is a chance that the last item focused was the tab menu
              // in this case, we just focus the parent menu entry
              // eslint-disable-next-line unicorn/prefer-query-selector -- id better here
              const node = document.getElementById(subMenuRefs.current?.[product.id]?.menuId);
              node?.focus?.();
            }}
            data-testid="assignment-menu-menuitem"
            icon={<ImageIcon alt={productName} size="S" src={product.getIcon()} />}
            id={uniqueId(MENUITEM_PREFIX)}
            label={productName}
          >
            <FocusScope autoFocus contain>
              {/*
                    Portaled SubMenu exists elsewhere in the DOM tree, preventing Contexts from finding their ancestor Provider.
                    Thus we forward Context providers to portaled Popover children.
                  */}
              <RawIntlProvider value={intl}>
                <AssignmentMenuContext.Provider value={menuContext}>
                  <ProductProfileMenu
                    // Always delay the call so users cannot intentionally spam JIL /license-groups
                    delayInit
                    onSelection={onSelection}
                    orgId={orgId}
                    product={product}
                  />
                </AssignmentMenuContext.Provider>
              </RawIntlProvider>
            </FocusScope>
          </SubMenu>
        ) : (
          <MenuItemCheckbox
            key={product.id}
            icon={
              <ImageIcon
                alt={productName}
                className={isItemDisabled(item) ? styles.disabled : undefined}
                size="S"
                src={product.getIcon()}
              />
            }
            isDisabled={isItemDisabled}
            isSelected={isSelected}
            item={item}
            label={productName}
            onSelection={onSelectionInternal}
            secondaryLabel={getRemainingLicenseCount(item, product)}
          />
        );
      })}
    </React.Fragment>
  );

  return (
    <div data-testid="product-menu">
      {selectableProducts.length === 0 ? (
        <MenuNoItemsMessage
          heading={intl.formatMessage({
            id: 'binky.common.assignmentMenu.productMenu.messages.noProducts.heading',
          })}
        />
      ) : (
        CLOUD_ORDERING.filter(
          (cloudOrdering) => productsGroupedByCloud[cloudOrdering] !== undefined
        ).map((cloud, index) => renderMenuSection(cloud, index))
      )}
    </div>
  );
};

ProductMenu.propTypes = {
  /**
   * When enableProfileSelection is true, ProductMenu will display the profile submenu
   */
  enableProfileSelection: PropTypes.bool.isRequired,
  // onSelection prop will be called with params: item, isItemSelected
  onSelection: PropTypes.func,
  orgId: PropTypes.string.isRequired,
};

export default ProductMenu;
