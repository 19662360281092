/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name binky.widgets.directories:binkyDeleteDirectoryModal
 *
 * @description The modal is to confirm the Delete Directory operation.
 *
 * @param {Array} directoryItems. List of directories to be deleted.
 * @param {String} [modalId]. Modal ID to use; defaults to DELETE_DIRECTORY_MODAL_ID constant value.
 * @param {Function} onConfirm. The callback to confirm the delete directory operation.
 */

(function () {
  'use strict';
  angular
    .module('binky.widgets.directories.delete-directory')
    .component('binkyDeleteDirectoryModal', {
      bindings: {
        directoryItems: '<',
        isT2eOrg: '<',
        modalId: '@?',
        onConfirm: '&',
      },
      controller,
      templateUrl: 'widgets/directories/delete-directory/delete-directory-modal.component.html',
    });

  /* @ngInject */
  function controller(_, DIRECTORY_OWNERSHIP_STATUS, DELETE_DIRECTORY_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      DELETE_DIRECTORY_MODAL_ID,
    });

    function $onChanges() {
      setPromptMessage();
    }

    ////////////////

    function setPromptMessage() {
      // as long as there is at least one shared directory in the current selection,
      // we would need to prompt user about deleting a shared directory
      const isAnyDirectoryShared = _.some(vm.directoryItems, [
        'ownershipStatus',
        DIRECTORY_OWNERSHIP_STATUS.TRUSTED,
      ]);

      if (isAnyDirectoryShared) {
        vm.prompteMessageId = vm.isT2eOrg
          ? 'binky.widgets.directories.deleteDirectoryModal.sharedDirectoryInT2EOrg.message'
          : 'binky.widgets.directories.deleteDirectoryModal.sharedDirectoryInNonT2EOrg.message';
      } else {
        vm.prompteMessageId =
          'binky.widgets.directories.deleteDirectoryModal.ownedDirectory.message';
      }
    }
  }
})();
