// eslint-disable-next-line @admin-tribe/admin-tribe/include-in-index -- Should not be used elsewhere
import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationAnchor from 'common/components/navigation-anchor/NavigationAnchor';

import HeaderNavItem from './HeaderNavItem';
import styles from './NavItems.pcss';

/** Renders a single nav item as an anchor */
const HeaderNavItemAnchor = ({item, selectedNavItemKey, intl}) => {
  const {key, href} = item;
  // Match the current state to name on a partial prefix match, for instance `account.overview` should match `account`
  const isCurrent = key === selectedNavItemKey;

  return (
    <NavigationAnchor
      key={key}
      aria-current={isCurrent ? 'page' : false}
      className={styles['nav-item-link']}
      data-testid={`nav-item-${key}`}
      href={href}
    >
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Applying selected styling */}
      <Text UNSAFE_className={isCurrent ? styles.selected : undefined}>
        {item.getLocalizedLabel(intl)}
      </Text>
    </NavigationAnchor>
  );
};

HeaderNavItemAnchor.propTypes = {
  intl: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(HeaderNavItem).isRequired,
  selectedNavItemKey: PropTypes.string.isRequired,
};

export default HeaderNavItemAnchor;
