(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.organizations.user-list')
    .constant('ORGANIZATION_USER_LIST_2_EVENT', {
      UPDATE: 'UpdateOrganizationUserList2',
      UPDATE_COUNT: 'UpdateCountOrganizationUserList2',
    })
    .constant('ORGANIZATION_USER_LIST_2_CACHE_ID', 'OrganizationUserList2');
})();
