import {dispatchUiEventAnalytics} from '@admin-tribe/binky';
import {Button, ButtonGroup} from '@adobe/react-spectrum';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {showSuccess} from 'shell/toasts/toastManager';

import {useModalDialogContext} from '../ModalDialogContext';

// This is the default button group for ModalDialog if clients
// do not specify their own ButtonGroup.
const ModalButtonGroup = ({
  cancelLabel,
  closeModal,
  ctaLabel,
  ctaToastGenerator,
  ctaVariant = 'cta',
  hideCancelButton,
  isCtaDisabled,
  modalId,
  onCancel,
  onCta,
  onSecondary,
  secondaryLabel,
  styleProps,
}) => {
  const intl = useIntl();
  const {styleProps: dialogContextStyleProps} = useModalDialogContext();

  const triggerClickAnalytics = (method) => {
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: camelCase(`${modalId}-${method}`),
    });

    // Also send manual Aptrinsic-Gainsight click events for now,
    // to cover the most common Spectrum V3 button usages.
    // Full details in ONESIE-13799
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- thean@ to update
    window.aptrinsic?.('track', 'Modal Button Click', {
      'button-id': method,
      'modal-id': modalId,
    });
  };

  const triggerSuccessAnalytics = () => {
    dispatchUiEventAnalytics({
      eventAction: 'api',
      eventName: camelCase(`${modalId}-success`),
    });
  };

  const onCancelCallback = () => {
    triggerClickAnalytics('cancel');
    if (onCancel === undefined) {
      closeModal();
    } else {
      onCancel();
    }
  };

  const onCtaCallback = async () => {
    let isSuccess = true;

    let analyticsEventId = 'cta';
    if (ctaLabel === intl.formatMessage({id: 'binky.shell.panels.modal.save'})) {
      analyticsEventId = 'save';
    } else if (ctaLabel === intl.formatMessage({id: 'binky.shell.panels.modal.next'})) {
      analyticsEventId = 'next';
    }

    triggerClickAnalytics(analyticsEventId);
    if (onCta === undefined) {
      closeModal();
    } else {
      isSuccess = await onCta();
    }

    if (isSuccess) {
      triggerSuccessAnalytics();
      if (ctaToastGenerator) {
        showSuccess(ctaToastGenerator());
      }
    }
  };

  const onSecondaryCallback = () => {
    let analyticsEventId = 'secondary';
    if (secondaryLabel === intl.formatMessage({id: 'binky.shell.panels.modal.back'})) {
      analyticsEventId = 'back';
    }

    triggerClickAnalytics(analyticsEventId);
    onSecondary();
  };

  return (
    <ButtonGroup marginTop={styleProps.footerMarginY} marginX={dialogContextStyleProps.spaceX}>
      {!hideCancelButton && (
        <Button data-testid="cancel-button" onPress={onCancelCallback} variant="secondary">
          {cancelLabel || <FormattedMessage id="binky.shell.panels.modal.cancel" />}
        </Button>
      )}
      {typeof onSecondary === 'function' && secondaryLabel && (
        <Button data-testid="secondary-button" onPress={onSecondaryCallback} variant="secondary">
          {secondaryLabel}
        </Button>
      )}
      <Button
        data-testid="cta-button"
        isDisabled={isCtaDisabled}
        onPress={onCtaCallback}
        variant={ctaVariant}
      >
        {ctaLabel || <FormattedMessage id="binky.shell.panels.modal.cta" />}
      </Button>
    </ButtonGroup>
  );
};

ModalButtonGroup.propTypes = {
  /**
   * Label for the cancel button.
   */
  cancelLabel: PropTypes.string,
  /**
   * Method to close the modal.
   */
  closeModal: PropTypes.func,
  /**
   * Label for the cta button.
   */
  ctaLabel: PropTypes.string,
  /**
   * Function that must return a string for displaying in the toast.
   */
  ctaToastGenerator: PropTypes.func,
  /**
   * Variant for the cta button.
   */
  ctaVariant: PropTypes.oneOf(['cta', 'primary', 'negative']),
  /**
   * Flag indicating whether the cancel button is explicitly hidden,
   */
  hideCancelButton: PropTypes.bool,
  /**
   * Flag indicating whether the cta is disabled.
   */
  isCtaDisabled: PropTypes.bool,
  /**
   * Modal id this button group belongs to.
   */
  modalId: PropTypes.string.isRequired,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  /**
   * Callback to invoke when the modal's cta button is pressed.
   */
  onCta: PropTypes.func,
  /**
   * Callback to invoke when the modal's secondary button is pressed.
   */
  onSecondary: PropTypes.func,
  /**
   * Label for the secondary button.
   */
  secondaryLabel: PropTypes.string,
  /**
   * Style props to apply to the widget.
   */
  styleProps: PropTypes.shape({
    footerMarginY: PropTypes.string,
  }).isRequired,
};

export default ModalButtonGroup;
