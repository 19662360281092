const FULFILLMENT_EVENT_LIST_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.fulfillmentEvent.FULFILLMENT_EVENT_LIST_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/services/fulfillmentEvent/FulfillmentEventConstants.js
   */
  angular
    .module('binky.core.fulfillment-events')
    .constant('FULFILLMENT_EVENT_LIST_CACHE_ID', 'FulfillmentEventList')
    .constant(
      'FULFILLMENT_EVENT_LIST_CONTEXT',
      FULFILLMENT_EVENT_LIST_CONSTANTS.FULFILLMENT_EVENT_LIST_CONTEXT
    )
    .constant(
      'FULFILLMENT_EVENT_LIST_EVENT',
      FULFILLMENT_EVENT_LIST_CONSTANTS.FULFILLMENT_EVENT_LIST_EVENT
    )
    .constant('FULFILLMENT_EVENT_LIST_PAGINATION_ID', 'fulfillmentEventListPagination')
    .constant('IN_PROGRESS_FULFILLMENT_EVENT_LIST_CACHE_ID', 'InProgressFulfillmentEventList')
    .constant(
      'IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT',
      FULFILLMENT_EVENT_LIST_CONSTANTS.IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT
    )
    .constant('LICENSE_FULFILLMENT_EVENT_LIST_CACHE_ID', 'LicenseFulfillmentEventList')
    .constant(
      'LICENSE_FULFILLMENT_EVENT_LIST_EVENT',
      FULFILLMENT_EVENT_LIST_CONSTANTS.LICENSE_FULFILLMENT_EVENT_LIST_EVENT
    );
})();
