(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.navigation.responsive-nav.component:binkyResponsiveNavDropdown
   * @description Component that displays a dropdown menu item consisting of
   *   a label (or icon), and a list of other menu items (links or dropdowns)
   *   that are expanded and positioned beneath the menu item label (or icon).
   * @param {String} [icon] - Name of CoralUI icon to use for label (can omit if
   *   using label binding for dropdown label)
   * @param {ResponsiveNavItem} item - Reference to item that represents this
   *   dropdown menu
   * @param {String} [label] - String to use for label (if not using icon for label)
   * @param {Boolean} [nested] - true if this dropdown is nested within another
   *   dropdown menu, else false (optional; defaults to false)
   * @param {String} orientation - Either 'horizontal' or 'vertical'; describes
   *   the layout of the navigation menu this dropdown exists within
   * @param {String} [variant] - Currently only 'light' is recognized; describes
   *   the styling of the navigation menu this dropdown exists within - light is
   *   a slightly smaller menu with slightly smaller fonts and a white background,
   *   often present in page menus, while the default variant is larger, has a
   *   dark background, and is commonly used for workspace menus
   */
  angular.module('binky.shell.navigation.responsive-nav').component('binkyResponsiveNavDropdown', {
    bindings: {
      icon: '@?',
      item: '<',
      label: '@?',
      nested: '<?',
      orientation: '@',
      variant: '@?',
    },
    controller,
    templateUrl: 'shell/navigation/responsive-nav/responsive-nav-dropdown.component.html',
  });

  /* @ngInject */
  function controller(
    $document,
    $element,
    $scope,
    $timeout,
    $translate,
    $window,
    _,
    NAV_DROPDOWN_OPENED,
    NAV_DROPDOWN_ID_PREFIX,
    NAV_EVENT,
    navDropdownManager,
    PANEL_MANAGER,
    panelManager
  ) {
    const APP_WINDOW = angular.element($window);
    const EVENTS = 'keydown keypress keyup click';
    const RESIZE = 'resize';

    let dropdownResetListener;

    const vm = this;
    _.assign(vm, {
      $onDestroy,
      $onInit,
      dropdownCloseHandler,
      dropdownWrapperId: _.uniqueId(NAV_DROPDOWN_ID_PREFIX),
      focusDropdownOnMenuClose: false,
      isChevronVisible,
      isDropdownMenuItemSelected,
      toggleDropdown,
    });

    function $onDestroy() {
      if (navDropdownManager.areListenersRegistered(vm.item.id)) {
        navDropdownManager.deregisterOnCloseHandler(vm.item.id);
      }
      dropdownResetListener();
    }

    function $onInit() {
      computeIsDropdownOpen();
      dropdownResetListener = $scope.$on(NAV_EVENT.RESET, computeIsDropdownOpen);

      if (!_.isEmpty(vm.label)) {
        $translate(vm.label)
          .then((result) => {
            vm.translatedLabel = result;
          })
          .catch(() => {
            // This is hit when the name is not matched to a key in the
            // locale files.
            // Technically the error param returned here is the same as name,
            // but for safety (and ease of code reading) we use the original.
            vm.translatedLabel = vm.label;
          });
      }
    }

    function computeIsDropdownOpen() {
      // offsetParent is null when element display is set to none, so we can use
      // this to apply the "expand dropdown on page load" logic to only apply to
      // elements that are visible, vertically-oriented, and contain the
      // selected path
      if (
        _.isElement($element[0].offsetParent) &&
        vm.orientation === 'vertical' &&
        isDropdownMenuItemSelected()
      ) {
        vm.item.isDropdownOpen = true;
      } else {
        vm.item.isDropdownOpen = false;
      }
    }

    /**
     * @description Method to recursively check if any navItems in this dropdown
     * (or nested dropdown menus) contains the currently selected route (i.e. -
     * should display as 'selected' in the UI).
     * @return {Boolean} true if any menu item in dropdown is selected, else false
     */
    function isDropdownMenuItemSelected() {
      return _.some(vm.item.navItems, (responsiveNavItem) => isDropdownSelected(responsiveNavItem));

      function isDropdownSelected(responsiveNavItem) {
        if (responsiveNavItem.isGroup()) {
          return _.some(responsiveNavItem.navItems, (nestedResponsiveNavItem) =>
            isDropdownSelected(nestedResponsiveNavItem)
          );
        }
        return responsiveNavItem.navItem.containsRouteToCurrentLocation();
      }
    }

    /**
     * @description Method to toggle the dropdown content show/hide behavior.
     * @param {Event} event - click event that triggered show/hide toggle
     */
    function toggleDropdown(event) {
      event.stopPropagation();
      if (vm.orientation === 'vertical') {
        $timeout(() => {
          vm.imageToggling = true;
        });
        $timeout(() => {
          vm.item.isDropdownOpen = !vm.item.isDropdownOpen;
          vm.imageToggling = false;
        }, 4); // current browser default refresh ~4ms (allows for unit testing of toggling behavior)
      } else if (vm.item.isDropdownOpen) {
        // hide open dropdown
        navDropdownManager.close(vm.item);
      } else {
        // For top level dropdowns, ensure that focus will return to the dropdown trigger
        // when the menu closes.
        vm.focusDropdownOnMenuClose = isTopLevelDropdown();

        // show hidden dropdown
        setDropdownElementFromEvent(event);
        navDropdownManager.open(vm.item);
        navDropdownManager.registerOnCloseHandler(vm.item.id, dropdownCloseHandler);
        registerDropdownOpenListeners();
        positionDropdown();
        $scope.$emit(NAV_DROPDOWN_OPENED);
      }
    }

    ////////

    /**
     * @description Method to handle browser resize events. If a dropdown is
     *   currently opened when the browser size changes, its position will be
     *   recalculated. Otherwise, any existing dropdown listeners are destroyed.
     */
    function browserResizeHandler() {
      if (vm.item.isDropdownOpen) {
        positionDropdown();
      } else {
        deregisterDropdownOpenListeners();
      }
    }

    /**
     * @description Method to deregister any open dropdown listeners.
     */
    function deregisterDropdownOpenListeners() {
      APP_WINDOW.off(RESIZE, browserResizeHandler);
      $document.off(EVENTS, outsideClickHandler);
    }

    /**
     * @description Method that acts on the dropdown menu close event.
     * Deregisters any open dropdown listeners and re-focuses the relevant dropdown
     * button as the active element **if** this does not interfere with other
     * user actions.
     */
    function dropdownCloseHandler() {
      deregisterDropdownOpenListeners();

      // In the top level navigation only, for accessibility purposes, ensure that
      // we re-focus on the dropdown when the related popup menu closes. Do not do this
      // if a responsive nav button is **already** the active element or if
      // focusDropdownOnMenuClose has been set previously by outsideClickHandler()
      // otherwise we may interfere with the user clicking on another responsive menu
      // button or focusable item in the page.
      if (isTopLevelDropdown() && !isActiveElementAnyMenuButton() && vm.focusDropdownOnMenuClose) {
        focusDropdownTrigger();
      }
    }

    /**
     * @description Method to reset focus to the menu dropdown trigger after
     *  the related menu popup closes.
     */
    function focusDropdownTrigger() {
      const navTrigger = $element[0].querySelector('.label-wrapper');
      if (navTrigger) {
        navTrigger.focus();
      }
    }

    /**
     * @description Method to determine the absolute offset of an element from
     *   the left side of the browser window.
     * @param {Element} targetElement - element to determine global offset of
     * @returns {Number} the global offset value in pixels
     */
    function getGlobalOffset(targetElement) {
      let globalOffSetLeft = 0;
      if (_.isNil(targetElement)) {
        // when horizontal menu hides, target can become NULL
        return globalOffSetLeft;
      }

      return computeGlobalOffsetLeft(targetElement.offsetParent);

      function computeGlobalOffsetLeft(parentElement) {
        if (_.isNil(parentElement)) {
          return targetElement.offsetLeft + globalOffSetLeft;
        }

        globalOffSetLeft += parentElement.offsetLeft;

        return computeGlobalOffsetLeft(parentElement.offsetParent);
      }
    }

    function isChevronVisible() {
      return !_.isEmpty(vm.label);
    }

    /**
     * @description Determine if dropdown belongs to a horizontally-oriented
     *   menu or not
     * @returns {Boolean} true if dropdown belongs to horizontally-oriented
     *   menu, else false
     */
    function isHorizontallyOriented() {
      return _.chain(vm).get('orientation').isEqual('horizontal').value();
    }

    /**
     * @description Determine if dropdown belongs to a dark variant menu or not
     * @returns {Boolean} true if dropdown belongs to dark variant menu, else
     *   false
     */
    function isDarkVariant() {
      return _.chain(vm).get('variant').isEmpty().value();
    }

    /**
     * @description Determine if any responsive dropdown menu button is
     * currently the active element in the page.
     * @returns {Boolean} true if any responsive dropdown menu button is active,
     * otherwise false
     */
    function isActiveElementAnyMenuButton() {
      if (
        $document[0].activeElement &&
        $document[0].activeElement.classList.contains('label-wrapper')
      ) {
        return true;
      }

      return false;
    }

    /**
     * @description Determine if a dropdown menu is connected to the top-level
     *   navigation menu or not. Top-level dropdowns are able to flow over any
     *   Rail content, whereas secondary navigation dropdowns will need to
     *   position themselves according to the Rail to prevent their content from
     *   being hidden.
     * @returns {Boolean} true if dropdown belongs to top-level menu, else false
     */
    function isTopLevelDropdown() {
      // top-level navigation menu is dark variant (no variant; default) and has
      // an orientation set to horizontal
      return isDarkVariant() && isHorizontallyOriented();
    }

    /**
     * @description Handler for clicks outside of an open dropdown menu.
     * @param {Event} event - event that occured outside of the dropdown menu
     */
    function outsideClickHandler(event) {
      if (event.which === 1 || event.which === 27) {
        // mouse or esc key clicked
        if (isTopLevelDropdown()) {
          // In the top level menu, we re-focus on the dropdown when the related
          // menu closes. We only want to do this on click or Escape key events
          // if they happen whilst focused in the menu, otherwise we might
          // interfere with user-specified focus (e.g. if the user has clicked
          // away from the popup menu into a form field within the current page).
          vm.focusDropdownOnMenuClose = $element[0].contains(event.target);
        }

        $timeout(() => {
          navDropdownManager.close(vm.item);
        });
      }
    }

    /**
     * @description Method to position a dropdown menu. Since menus can render
     *   close to either browser edge, we use this method to keep content from
     *   flowing off of either edge, becoming invisible. If the Rail panel is
     *   open, its width is taken into account when determining dropdown
     *   positioning (to prevent dropdowns from flowing under open rail).
     */
    function positionDropdown() {
      const dropdownWidth = vm.dropdownElement.offsetWidth;
      const menuOffSet = getGlobalOffset(vm.dropdownElement.offsetParent);
      const menuItemOffSet = vm.dropdownElement.parentElement.offsetLeft;
      const windowWidth = getAvailableWindowWidth();

      const globalOffSet = menuOffSet + menuItemOffSet;

      if (dropdownWidth + globalOffSet < windowWidth) {
        vm.menuOffset = `${menuItemOffSet}px`;
      } else {
        const skinnyPosition = menuItemOffSet - (dropdownWidth + globalOffSet - windowWidth);
        vm.menuOffset = skinnyPosition > 0 ? `${skinnyPosition}px` : '0px';
      }

      function getAvailableWindowWidth() {
        // if top-level menu or if the rail is closed, all of the browser window
        // is available to use
        if (isTopLevelDropdown() || !panelManager.isPanelTypeOpen(PANEL_MANAGER.TYPE.RAIL)) {
          return $window.innerWidth;
        }
        // secondary window and rail is open, so subtract rail width from
        // available space when computing dropdown position
        return $window.innerWidth - panelManager.getPanelTypeWidth(PANEL_MANAGER.TYPE.RAIL);
      }
    }

    /**
     * @description Method to register listeners when a dropdown is opened.
     *   These listeners are used to detect and handle user interaction outside
     *   of the menu that may result in the menu closing.
     */
    function registerDropdownOpenListeners() {
      APP_WINDOW.on(RESIZE, browserResizeHandler);
      $document.on(EVENTS, outsideClickHandler);
    }

    /**
     * @description Method to set the dropdown element according to an event.
     *   This is used to determine the lcoation of the element that should
     *   display an associated dropdown menu. This element is then the basis for
     *   positioning the dropdown menu, when displayed.
     * @param {Event} event - event representing dropdown label click/toggle
     */
    function setDropdownElementFromEvent(event) {
      let target = event.target;
      // traverse up tree until binky-responsive-nav-dropdown element found...
      while (!_.isNil(target) && !target.matches('binky-responsive-nav-dropdown')) {
        target = target.parentElement;
      }
      // now select the dropdown-wrapper and return...
      const childDropdownElements = target.getElementsByClassName('dropdown-wrapper');
      vm.dropdownElement = childDropdownElements[0];
    }
  }
})();
