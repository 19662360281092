(function () {
  /**
   * @deprecated Please use src2/core/services/contract/ContractList.js
   *
   * @ngdoc factory
   * @name binky.core.contract:ContractList
   * @description Model for a contract list
   */
  angular.module('binky.core.contract').factory('ContractList', ContractListModel);

  /* @ngInject */
  function ContractListModel(
    $q,
    _,
    binkySrc2,
    Contract,
    CONTRACT_EVENT,
    CONTRACT_LIST_CACHE_ID,
    modelCache,
    promiseUtils
  ) {
    class ContractList2 extends binkySrc2.services.contract.ContractList {
      constructor(options) {
        super(options);
        this.itemClassRef = Contract;
      }

      canAddProducts() {
        return binkySrc2.services.contract.ContractListUtils.canAddProducts(this);
      }

      getContractsForProduct(product) {
        return binkySrc2.services.contract.ContractListUtils.getContractsForProduct(this, product);
      }

      getDirectContract() {
        return binkySrc2.services.contract.ContractListUtils.getDirectContract(this);
      }

      getDirectOrIndirectContract() {
        return binkySrc2.services.contract.ContractListUtils.getDirectOrIndirectContract(this);
      }

      getDirectOrIndirectContractId() {
        return binkySrc2.services.contract.ContractListUtils.getDirectOrIndirectContractId(this);
      }

      getEarliestAnniversaryDate() {
        return binkySrc2.services.contract.ContractListUtils.getEarliestAnniversaryDate(this);
      }

      getEarliestStartDate() {
        return binkySrc2.services.contract.ContractListUtils.getEarliestStartDate(this);
      }

      getEnterpriseContract(buyingProgram) {
        return binkySrc2.services.contract.ContractListUtils.getEnterpriseContract(
          this,
          buyingProgram
        );
      }

      getFirstContractForProduct(product) {
        return binkySrc2.services.contract.ContractListUtils.getFirstContractForProduct(
          this,
          product
        );
      }

      getIndirectContract() {
        return binkySrc2.services.contract.ContractListUtils.getIndirectContract(this);
      }

      getIndirectContractInRenewalWindow() {
        return binkySrc2.services.contract.ContractListUtils.getIndirectContractInRenewalWindow(
          this
        );
      }

      getLapsedVIPSubscriptionContract() {
        return binkySrc2.services.contract.ContractListUtils.getLapsedVIPSubscriptionContract(this);
      }

      getLapsedVIPSubscriptionPrevAnniversaryDate() {
        return binkySrc2.services.contract.ContractListUtils.getLapsedVIPSubscriptionPrevAnniversaryDate(
          this
        );
      }

      getLapsedVIPSubscriptionResellerName() {
        return binkySrc2.services.contract.ContractListUtils.getLapsedVIPSubscriptionResellerName(
          this
        );
      }

      /**
       * @description Returns first contract that has offer switch migration.
       *
       * @returns {Contract} contract with offer switch migration
       */
      getOfferSwitchMigrationEligibleContract() {
        return binkySrc2.services.contract.ContractListUtils.getOfferSwitchMigrationEligibleContract(
          this
        );
      }

      getTeamToEnterpriseMigrationEligibleContract() {
        return binkySrc2.services.contract.ContractListUtils.getTeamToEnterpriseMigrationEligibleContract(
          this
        );
      }

      getTrialContract(contractId) {
        return binkySrc2.services.contract.ContractListUtils.getTrialContract(this, contractId);
      }

      /**
       * @description Returns true if there is an allocation contract.
       *
       * @returns {Boolean} true if ContractList contains an allocation contract.
       */
      hasAllocationContract() {
        return binkySrc2.services.contract.ContractListUtils.hasAllocationContract(this);
      }

      /**
       * @description Check whether there's at least 1 contract that has
       * offer switch migration, regardless of the status.
       *
       * @returns {Boolean} true if at least 1 contract has offer switch migration;
       * false when all contracts have no offer switch migration
       */
      hasAnyOfferSwitchMigrationContracts() {
        return binkySrc2.services.contract.ContractListUtils.hasAnyOfferSwitchMigrationContracts(
          this
        );
      }

      hasAnyTrialContracts() {
        return binkySrc2.services.contract.ContractListUtils.hasAnyTrialContracts(this);
      }

      hasAnyTrialOrAllocationContracts() {
        return binkySrc2.services.contract.ContractListUtils.hasAnyTrialOrAllocationContracts(this);
      }

      hasCancelledDirectContract() {
        return binkySrc2.services.contract.ContractListUtils.hasCancelledDirectContract(this);
      }

      hasContractInRenewalWindow() {
        return binkySrc2.services.contract.ContractListUtils.hasContractInRenewalWindow(this);
      }

      hasDirectContract() {
        return binkySrc2.services.contract.ContractListUtils.hasDirectContract(this);
      }

      hasEnterpriseContract() {
        return binkySrc2.services.contract.ContractListUtils.hasEnterpriseContract(this);
      }

      hasIndirectContract() {
        return binkySrc2.services.contract.ContractListUtils.hasIndirectContract(this);
      }

      hasLapsedVIPSubscription() {
        return binkySrc2.services.contract.ContractListUtils.hasLapsedVIPSubscription(this);
      }

      hasOnlyTrialContracts() {
        return binkySrc2.services.contract.ContractListUtils.hasOnlyTrialContracts(this);
      }

      hasOnlyTrialOrAllocationContracts() {
        return binkySrc2.services.contract.ContractListUtils.hasOnlyTrialOrAllocationContracts(
          this
        );
      }

      hasTeamContract() {
        return binkySrc2.services.contract.ContractListUtils.hasTeamContract(this);
      }

      hasTermsToAccept() {
        return binkySrc2.services.contract.ContractListUtils.hasTermsToAccept(this);
      }

      hasTrialContractForId(contractId) {
        return binkySrc2.services.contract.ContractListUtils.hasTrialContractForId(
          this,
          contractId
        );
      }

      hasVIPMPContract() {
        return binkySrc2.services.contract.ContractListUtils.hasVIPMPContract(this);
      }

      isContractOwner({userId} = {}) {
        return binkySrc2.services.contract.ContractListUtils.isContractOwner(this, {userId});
      }

      isMigrating() {
        return binkySrc2.services.contract.ContractListUtils.isMigrating(this);
      }

      isMigratingFromBusinessTrialsToPaid() {
        return binkySrc2.services.contract.ContractListUtils.isMigratingFromBusinessTrialsToPaid(
          this
        );
      }

      isOrderProcessing() {
        return binkySrc2.services.contract.ContractListUtils.isOrderProcessing(this);
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return super.getKey({orgId: this.orgId});
      }

      mustAcceptTerms() {
        return binkySrc2.services.contract.ContractListUtils.mustAcceptTerms(this);
      }

      /**
       * @description Method to refresh Contract list from back-end.
       * @returns {Promise} resolves to ContractList on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              resolve(this);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the ContractList. It will be refreshed on
       *     registering the active org
       * @param {Object} options - options to configure the contract list.
       * @param {Object} [options.orgId] - ID of org of which contracts should be fetched.
       * @returns {ContractList} the fetched ContractList.
       */
      static get(options) {
        const model = new ContractList2(options);

        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((contractList) => {
            _.assign(model, contractList, {$promise: $q.resolve(model)});
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(CONTRACT_LIST_CACHE_ID, 1);
    modelCache.removeAllOnEvent(CONTRACT_LIST_CACHE_ID, [CONTRACT_EVENT.UPDATE]);

    return ContractList2;
  }
})();
