(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilOrganizationsMigrations.js
   *
   * @ngdoc service/provider
   * @name jilOrganizationsMigrations
   * @description defines service to manage jil organizations migrations resources
   */
  angular
    .module('binky.core.api.jil')
    .provider('jilOrganizationsMigrations', jilOrganizationsMigrationsProvider);

  let url;

  /* @ngInject */
  function jilOrganizationsMigrationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        migrations: getMigrationsResource(),
      };

      return service;

      ///////////

      function getMigrationsResource() {
        return $resource(
          `${url}/:orgId/migrations`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              headers: {Accept: 'application/vnd.com.adobe.jil.organizations.migrations.v2+json'},
              isArray: true,
            },
          }
        );
      }
    }
  }
})();
