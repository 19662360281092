(function () {
  'use strict';
  /**
   * @deprecated Please use src2/insights/insights-date-range/InsightsDateRange.jsx
   *
   * @ngdoc component
   * @name binky.widgets.insights.create-insights-item-detail-modal.date-range:binkyInsightsDateRange
   * @description display date range
   *
   * @param {Object} content The content of Insights item action.
   * @param {Function} onUpdateDateRange Callback function which must have one param that contains the updated start or end date.
   */
  angular
    .module('binky.widgets.insights.create-insights-item-detail-modal.date-range')
    .component('binkyInsightsDateRange', {
      bindings: {
        content: '<',
        onUpdateDateRange: '<?',
      },
      controller,
      templateUrl:
        'widgets/insights/create-insights-item-detail-modal/date-range/insights-date-range.component.html',
    });

  /* @ngInject */
  function controller($log, _, moment, translationUtils) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getMaxStartDate,
      getMinEndDate,
      onChangeEndDate,
      onChangeStartDate,
    });

    ///////////////

    function $onInit() {
      _.assign(vm, {
        dateRangeTitle:
          vm.content.title || translationUtils.sanitizeSafeInstant(vm.content.titleKey),
        maxEndDate: getMaxEndDate(
          _.get(vm.content, 'maxDate'),
          _.get(vm.content, 'maxDateFromNow')
        ),
        minStartDate: getMinStartDate(
          _.get(vm.content, 'minDate'),
          _.get(vm.content, 'minDateFromNow')
        ),
      });
    }

    function getMaxStartDate() {
      return vm.endDate ? moment.min(vm.endDate, vm.maxEndDate) : vm.maxEndDate;
    }

    function getMinEndDate() {
      if (vm.startDate) {
        return vm.minStartDate ? moment.max(vm.startDate, vm.minStartDate) : vm.startDate;
      }
      return vm.minStartDate;
    }

    function onChangeEndDate(date) {
      vm.endDate = date;
      vm.onUpdateDateRange({endDate: vm.endDate});
    }

    function onChangeStartDate(date) {
      vm.startDate = date;
      vm.onUpdateDateRange({startDate: vm.startDate});
    }

    ///////////////

    function getMaxEndDate(specifiedDate, numOfDays) {
      // Set the date to be the end of day to allow for
      // a range even when there is only one day available
      const dateFromRange = moment().add(numOfDays, 'days').endOf('day');
      const specifiedDateMoment = moment(specifiedDate);

      if (!specifiedDate && _.isUndefined(numOfDays)) {
        // set to today
        return moment();
      } else if (specifiedDate && !_.isUndefined(numOfDays)) {
        return moment.min(specifiedDateMoment, dateFromRange);
      }
      return specifiedDate ? specifiedDateMoment : dateFromRange;
    }

    function getMinStartDate(specifiedDate, numOfDays) {
      // Set the date to be the beginning of day to allow for
      // a range even when there is only one day available
      const dateFromRange = moment().add(numOfDays, 'days').startOf('day');
      const specifiedDateMoment = moment(specifiedDate);

      if (!specifiedDate && _.isUndefined(numOfDays)) {
        $log.error('Please define minDate or minDateFromNow');
        return undefined;
      } else if (specifiedDate && !_.isUndefined(numOfDays)) {
        return moment.max(specifiedDateMoment, dateFromRange);
      }
      return specifiedDate ? specifiedDateMoment : dateFromRange;
    }
  }
})();
