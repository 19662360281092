/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to display the directory status label string.
 * @param {String} statusLabelKey Should be one of the values in DIRECTORY_STATUS_LABEL.
 *   If statusLabelKey is DIRECTORY_STATUS_LABEL.ALL_STATUSES must also set options.allowAllStatuses to true since this
 *   status is not valid in all places.
 * @param {Object} [options] Configurable options.
 * @param {Boolean} [options.allowAllStatuses] To enable DIRECTORY_STATUS_LABEL.ALL_STATUSES to be formatted.
 *   Used for the directory table status filter item list.
 * @returns {String} formatted directory status label or an empty string if the status label key is invalid.
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.directories.status')
    .filter('binkyDirectoryStatusLabel', binkyDirectoryStatusLabel);

  /* @ngInject */
  function binkyDirectoryStatusLabel($translate, _, DIRECTORY_STATUS_LABEL) {
    return getDirectoryStatus;

    function getDirectoryStatus(statusLabelKey, options = {}) {
      const {allowAllStatuses = false} = options;

      if (
        _.includes(DIRECTORY_STATUS_LABEL, statusLabelKey) &&
        (allowAllStatuses || statusLabelKey !== DIRECTORY_STATUS_LABEL.ALL_STATUSES)
      ) {
        return $translate.instant(
          `binky.widgets.directories.directoryStatusLabel.${statusLabelKey}`
        );
      }

      return '';
    }
  }
})();
