/**
 * Gets the text width with the applied styles from a target element.
 * @param {HTMLElement} parentElement
 * @param {string} text
 * @returns {number}
 */
const getTextWidth = (parentElement, text) => {
  if (typeof window === 'undefined') {
    return 0;
  }

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- Guarded above
  const targetStyle = window.getComputedStyle(parentElement);
  const fakeEl = document.createElement('span');
  fakeEl.style = targetStyle;
  fakeEl.style.position = 'absolute';
  fakeEl.style.visibility = 'hidden';
  fakeEl.style.top = '-200px';
  fakeEl.innerText = text;

  // Element needs to be within the document to calculate the width
  document.body.appendChild(fakeEl);
  const width = fakeEl.getBoundingClientRect().width;
  document.body.removeChild(fakeEl);

  return width;
};

export default getTextWidth;
