import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Global Admin Policies APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organizations Search api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Get Global Admin policies
 * @param {Object} options Options
 * @param {String} options.orgId Organization ID
 * @returns {Object} JIL response that contains policy list
 */
const getPolicies = ({orgId}) =>
  axios.get(`${url}/v2/organizations/${orgId}/policies`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {isArray: true},
  });

/**
 * @description Patches a policy in the Global Admin policy list
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id
 * @param {Array<Object>} patchOperations - The patch operations
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const patchPolicy = async ({orgId}, patchOperations) => {
  const response = await axios.patch(
    `${url}/v2/organizations/${orgId}/policies/`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

const jilGlobalAdminPolicies = {configure, getPolicies, patchPolicy};

export default jilGlobalAdminPolicies;
