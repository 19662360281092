(function () {
  /**
   * @deprecated should use the Pandora cart:
   *   https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-cart
   *
   * @ngdoc factory
   * @name CartDescriptor
   * @description This class describes the user's purchase cart
   */
  angular.module('binky.core.cart').factory('CartDescriptor', factory);

  function factory(_) {
    class CartDescriptor {
      /**
       * @description Constructor
       *
       * @param {Object} options initialization options
       * @param {String} options.cart.id: cart ID
       * @param {String} options.cancellationReasonComment Free text cancellation reason for CANCEL_REASONS.OTHER
       * @param {Array} options.cancellationReasons Array of cancellation reason IDs from CANCEL_REASONS
       * @param {Object} options.contract object to represent a contract
       * @param {String} options.contract.id contract ID
       * @param {Boolean} options.contract.isNFR NFR (Not For Resale) flag
       * @param {Array} options.items list of cart items
       * @param {Number} options.items[n].assignableLicenseCount number of purchased licenses
       * @param {String} options.items[n].customer_segment customer segment
       * @param {Number} options.items[n].numberSelected number of selected quantity in the cart
       * @param {String} options.items[n].offer_id offer ID
       * @param {String} options.items[n].productBucket source of the product in cart:
       *   - 'cartItems' -- renewed products during renewal flow
       *   - 'ownedOffers' -- products already owned during add flow when the user adds a product that is already licensed
       *   - 'unownedWithoutRecOffers' -- non-owned products, not one of the recommended products
       *   - 'unownedWithRecOffers' -- non-owned products, one of the recommended products
       * @param {Number} options.items[n].provisionedQuantity number of assigned licenses
       * @param {String} options.purchaseAuthId Purchase authrorization ID for adding products
       * @param {Object} options.total object to represent a total
       * @param {Object} options.total.currency purchase currency information
       * @param {String} options.total.currency.code currency code
       * @param {String} options.total.currency.delimiter decimal delimiter
       * @param {String} options.total.currency.formatString format string
       * @param {String} options.total.currency.symbol symbol for the currency
       * @param {Boolean} options.total.currency.usePrecision use precision
       * @param {Number} options.total.subtotal amount of purchases without tax
       * @param {Number} options.total.tax amount of tax
       * @param {Number} options.total.total final amount to pay, may or may not include tax
       */
      constructor(options = {}) {
        initModel(this, options);
      }
    }

    return CartDescriptor;

    //////////

    function initModel(model, options) {
      _.assignIn(
        model,
        _.pick(options, [
          'id',
          'cancellationReasonComment',
          'cancellationReasons',
          'contract',
          'items',
          'purchaseAuthId',
          'total',
        ])
      );
    }
  }
})();
