import without from 'lodash/without';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- wenywang@ to update
function filterLinkedOrganizations({filterToTypes, linkedUserAccounts}) {
  // we filter before clustering to avoid removing part of a cluster
  const filteredOrgs = filterOrgs(filterToTypes, linkedUserAccounts, 'organization_type');
  const processedOrganizations = filteredOrgs.map((linkedUserAccount, idx) => ({
    id: linkedUserAccount.organization_id,
    idx: `${idx}`,
    name: linkedUserAccount.organization_name,
    pathName: linkedUserAccount.organization_path_name,
    userId: linkedUserAccount.entitlement_user_id,
    userType: linkedUserAccount.entitlement_user_type,
  }));

  // we cluster organizations by their ID, so we can mark them up by user type
  // if the user is in the same org twice
  processedOrganizations.forEach((organization) => {
    organization.multipleAccountsForOrg = without(processedOrganizations, organization).some(
      (org) => org.id === organization.id
    );
  });

  return processedOrganizations;
}
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- wenywang@ to update
function filterOrganizations({filterToTypes, organizationItems}) {
  return filterOrgs(filterToTypes, organizationItems, 'type');
}

function filterOrgs(filterToTypes, orgs, typeKey) {
  return filterToTypes ? orgs.filter((org) => filterToTypes.includes(org[typeKey])) : orgs;
}

export {filterLinkedOrganizations, filterOrganizations};
