(function () {
  /**
   * @deprecated. Please use src2/core/services/domain/DomainList.
   *
   * @ngdoc factory
   * @name binky.core.domains:DomainList
   * @description fetch the list of domains for an org.
   */
  angular.module('binky.core.domains').factory('DomainList', domainList);

  /* @ngInject */
  function domainList(
    _,
    binkySrc2,
    DIRECTORY_SYNC_EVENT,
    Domain,
    DOMAIN_LIST_CACHE_ID,
    JIL_CONSTANTS,
    modelCache,
    promiseUtils
  ) {
    class DomainListSrc2 extends binkySrc2.services.domain.DomainList {
      constructor(options = {}) {
        super(options);

        this.$resolved = false;

        // Set itemClassRef to be src1 compliant
        this.itemClassRef = Domain;
      }

      /**
       * @description link a set of domains to a selected directory
       * @param {Object} options options to be provided for the batchOperation
       * @param {Array} options.domains array of the selected domains to link
       * @param {Directory} options.directoryId id of the directory to link to
       * @returns {Promise} resolved when the link batchOperation and domainList refresh completes.
       */
      linkDomainsToDirectory(options) {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.linkDomainsToDirectory(options))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      /**
       * @description Returns a preview of what would happen if save were to be called.
       *
       * @returns {Promise} resolves with an instance of DomainListMultiStatusResponse representing
       *   what would happen for each saved item.
       */
      previewSave() {
        return promiseUtils.toAngularPromise(super.previewSave());
      }

      /**
       * @description Method to refresh the contents of the list. If options not specified, this will use model's status.
       *
       * @param {Object} [options] the refresh options. Default is to use the model's status which if undefined is
       *   all statuses.
       * @param {String} [options.status] to filter by status specify one of DOMAIN_STATUS. This will become
       *   the model's new status value. If not specified the modal's current status value will be used.
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(options) {
        this.$resolved = false;
        this.$promise = promiseUtils.toAngularPromise(super.refresh(options)).finally(() => {
          this.$resolved = true;
        });
        return this.$promise;
      }

      /**
       * @description Saves changes to the back-end.
       *   As currently coded it will save added items if there are any OR save removed items if there are any.
       *   It does not do both. If we need both operations together, we can add that complexity later.
       *
       * @returns {Promise} resolves with the modified domains if changes successfully saved, else
       *   rejects with error message
       */
      save() {
        this.$resolved = false;
        this.$promise = promiseUtils.toAngularPromise(super.save()).finally(() => {
          this.$resolved = true;
        });
        return this.$promise;
      }

      /**
       * @description Method to modify sort parameters of the domain list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description Method to validate domains. Assumes domainList is already filtered.
       * @param {Array} domains - list of Domain which are domains to be validated.
       * @returns {Promise} promise object resolved when validation is complete, or rejected with an error.
       *   The updatedItems contains items that were validated which may be less than the domains count if there
       *   were validation errors.
       */
      validateDomains(domains) {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.validateDomains(domains))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      /**
       * @description instantiates a new instance of DomainList.
       * @param {Object} options - options.pageSize to override LIST_DEFAULT_PAGE_SIZE
       * @param {Integer} options.pageSize - optional - to override LIST_DEFAULT_PAGE_SIZE
       * @param {Object} options.params - optional - query params to pass into the GET
       * @returns {DomainList} DomainList model object.
       */
      static get(options = {}) {
        const model = new DomainListSrc2(options);
        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((list) => {
            _.assign(model, list);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    //////////////

    // We register the cache size for this class
    modelCache.register(DOMAIN_LIST_CACHE_ID, 1);
    modelCache.removeAllOnEvent(DOMAIN_LIST_CACHE_ID, [DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED]);

    return DomainListSrc2;
  }
})();
