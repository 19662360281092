(function () {
  /**
   * @deprecated Please use src2/core/models/member/Member.js
   *
   * @ngdoc factory
   * @name Member
   * @description Standard model for representing a member
   */
  angular.module('binky.core.common.member').factory('Member', member);

  /* @ngInject */
  function member(binkySrc2, MEMBER_EVENT, modelCache) {
    class MemberSrc2 extends binkySrc2.models.member.Member {
      /**
       * @description Configure the model's cache.
       *
       * @param {String} cacheId The id of the cache to store model into (should
       *                         correspond with MODEL constant)
       * @param {Number} capacity The number of objects to keep in the cache at any time
       */
      static setupCache(cacheId, capacity) {
        modelCache.register(cacheId, capacity);
        modelCache.removeOnEvent(cacheId, [MEMBER_EVENT.DELETE, MEMBER_EVENT.UPDATE]);
      }
    }

    return MemberSrc2;
  }
})();
