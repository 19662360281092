/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.directories:Directory
   * @description model to fetch/update a directory.
   */
  angular.module('binky.core.directories').factory('Directory', getDirectoryModel);

  /* @ngInject */
  function getDirectoryModel(
    $q,
    $rootScope,
    _,
    DIRECTORY_ENCRYPTION_PROGRESS,
    DIRECTORY_ENCRYPTION_STATUS,
    DIRECTORY_EVENT,
    DIRECTORY_OWNERSHIP_STATUS,
    DIRECTORY_STATUS,
    DIRECTORY_TYPE,
    DirectorySamlConfig,
    IDP_STATUS,
    IDP_TYPES,
    jilDirectories,
    kms,
    ORGANIZATION_TYPE,
    OrganizationEncryptionMigration
  ) {
    class Directory {
      /**
       * @description instantiate a Directory object.
       * @param {Object} options - options to config the new Directory.
       */
      constructor(options) {
        applyResource(this, options);
        applyDefaultEncryptionStatusInfo(this);
      }

      /**
       * @description Adds the active user as a tenant admin.
       * @returns {Promise} resolved when the adding completes.
       */
      addUserTenantAdmin() {
        return toggleUserTenantAdmin(jilDirectories.tenantAdmins.save, this);
      }

      /**
       * @description check whether this directory supports domain migration,
       * currently trusted, encrypted and externally managed directories are not allowed.
       * @returns {Boolean} true if this directory supports domain migration.
       * @memberof Directory
       */
      allowDomainMigration() {
        // Remove orgMigratedToOrgLevelEncryption with temp_org_asset_encryption_migrated
        // Assumes OrganizationEncryptionMigration is cached from routes call
        const orgMigratedToOrgLevelEncryption = OrganizationEncryptionMigration.get({
          orgId: this.orgId,
        }).isMigrated();
        const canMigrateEncryptedDomains =
          orgMigratedToOrgLevelEncryption ||
          (!this.isEncryptionEnabled() && !this.isEncryptionRevoked());
        const externallyManaged = this.isExternallyManaged();

        return (
          this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
          (this.status === DIRECTORY_STATUS.ACTIVE ||
            this.status === DIRECTORY_STATUS.NEEDS_DOMAIN) &&
          _.isEmpty(this.trusts) &&
          canMigrateEncryptedDomains &&
          (this.isType2() || !externallyManaged)
        );
      }

      /**
       * @description disables encryption.
       * @returns {Promise} resolved when the disabling completes.
       */
      disableEncryption() {
        return this.toggleEncryption({resource: kms.domains.disableEncryption});
      }

      /**
       * @description enables encryption.
       * @returns {Promise} resolved when the enabling completes.
       */
      enableEncryption() {
        return this.toggleEncryption({resource: kms.domains.enableEncryption});
      }

      hasEncryptionFailed() {
        return (
          _.get(this, 'encryptionStatusInfo.encryptionProgressStatus') ===
          DIRECTORY_ENCRYPTION_PROGRESS.FAILED
        );
      }

      isConfigured() {
        if (!this.isStatusActive()) {
          return false;
        }
        if (this.isType3()) {
          const defaultIdpId = _.get(this, 'tenantInfo.imsTenantInfo.defaultIdp');
          const idpList = _.get(this, 'tenantInfo.imsTenantInfo.idps', []);
          const defaultIdp = _.find(idpList, {id: defaultIdpId}, {});
          return _.get(defaultIdp, 'status') === IDP_STATUS.ACTIVE;
        }
        return true;
      }

      isEncryptionCompleted() {
        return (
          this.isEncryptionEnabled() &&
          _.get(this, 'encryptionStatusInfo.encryptionProgressStatus') ===
            DIRECTORY_ENCRYPTION_PROGRESS.COMPLETED
        );
      }

      // Has never been enabled.
      isEncryptionDisabled() {
        return (
          _.get(this, 'encryptionStatusInfo.encryptionStatus') ===
          DIRECTORY_ENCRYPTION_STATUS.DISABLED
        );
      }

      isEncryptionEnabled() {
        return (
          _.get(this, 'encryptionStatusInfo.encryptionStatus') ===
          DIRECTORY_ENCRYPTION_STATUS.ENABLED
        );
      }

      isEncryptionInProgress() {
        return (
          this.isEncryptionEnabled() &&
          _.get(this, 'encryptionStatusInfo.encryptionProgressStatus') ===
            DIRECTORY_ENCRYPTION_PROGRESS.IN_PROGRESS
        );
      }

      isEncryptionNotApplicable() {
        return (
          _.get(this, 'encryptionStatusInfo.encryptionStatus') ===
          DIRECTORY_ENCRYPTION_STATUS.NOT_APPLICABLE
        );
      }

      // Has never enabled and then disabled.
      isEncryptionRevoked() {
        return (
          _.get(this, 'encryptionStatusInfo.encryptionStatus') ===
          DIRECTORY_ENCRYPTION_STATUS.REVOKED
        );
      }

      isExternallyManaged() {
        return this.externallyManaged;
      }

      /**
       * Is the ownership status OWNED
       *
       * @returns {Boolean} true if the ownership status is OWNED
       * @memberof Directory
       */
      isOwned() {
        return this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED;
      }

      /**
       * Is the given policy equal to 'true'
       *
       * @param {String} policy - The name of the policy to check
       * @returns {Boolean} true if the policy value is 'true'
       * @memberof Directory
       */
      isPolicyEnabled(policy) {
        return !_.isNil(this.policies) && this.policies[policy] === 'true';
      }

      /**
       * @description check whether the directory is active (note that type 2 directories are always ACTIVE)
       * @returns {Boolean} true if directory is active
       * @memberof Directory
       */
      isStatusActive() {
        return this.status === DIRECTORY_STATUS.ACTIVE;
      }

      /**
       * @description check whether the directory needs a domain
       * @returns {Boolean} true if the active directory needs a domain
       * @memberof Directory
       */
      isStatusNeedsDomain() {
        return this.status === DIRECTORY_STATUS.NEEDS_DOMAIN;
      }

      /**
       * @description check whether the directory is pending
       * @returns {Boolean} true if directory is pending access request to the directory owner
       * @memberof Directory
       */
      isStatusPending() {
        return this.status === DIRECTORY_STATUS.PENDING;
      }

      /**
       * @description check whether the directory has been rejected
       * @returns {Boolean} true if directory has been rejected by the owner
       * @memberof Directory
       */
      isStatusRejected() {
        return this.status === DIRECTORY_STATUS.REJECTED;
      }

      /**
       * @description check whether the directory has been revoked
       * @returns {Boolean} true if directory has been revoked
       * @memberof Directory
       */
      isStatusRevoked() {
        return this.status === DIRECTORY_STATUS.REVOKED;
      }

      /**
       * Is the ownership status TRUSTED
       *
       * @returns {Boolean} true if the ownership status is TRUSTED
       * @memberof Directory
       */
      isTrusted() {
        return this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED;
      }

      isType2() {
        return this.type === DIRECTORY_TYPE.TYPE2;
      }

      isType2E() {
        return this.type === DIRECTORY_TYPE.TYPE2E;
      }

      isType3() {
        return this.type === DIRECTORY_TYPE.TYPE3;
      }

      /**
       * @description Is the active user a tenant admin for this directory
       *
       * @returns {Boolean} true if the current user is a tenant admin
       * @memberof Directory
       */
      isUserTenantAdmin() {
        return _.get(this, 'tenantInfo.imsTenantAdminStatus.status', false);
      }

      /**
       * @description reload the directory and the encryption information for the directory.
       * @param {Object} options optional options.
       * @returns {Promise} resolved when directory and encryption information is loaded.
       */
      refresh() {
        this.$resolved = false;

        const reqParams = _.omitBy(
          {
            directoryId: this.id,
            orgId: this.orgId,
          },
          _.isUndefined
        );
        this.$promise = $q((resolve, reject) => {
          jilDirectories.directories.get(
            reqParams,
            onDirectorySuccess.bind(this),
            onError.bind(this)
          );

          function onDirectorySuccess(resource) {
            applyResource(this, resource);

            $rootScope.$broadcast(DIRECTORY_EVENT.REFRESH, this);

            if (this.orgType === ORGANIZATION_TYPE.ENTERPRISE) {
              this.refreshEncryptedStatusInfo()
                .then(onSuccess.bind(this))
                .catch(onError.bind(this));
            } else {
              onSuccess.call(this);
            }
          }

          function onSuccess() {
            this.$resolved = true;
            resolve(this);
          }

          function onError(error) {
            this.$resolved = true;
            reject(error);
          }
        });
        return this.$promise;
      }

      /**
       * @description reload the encrypted status info.
       * @returns {Promise} resolved when the encrypted status info is loaded.
       */
      refreshEncryptedStatusInfo() {
        return $q((resolve, reject) => {
          kms.encryptedDomains.get(
            _.omitBy(
              {
                id: this.externalId,
                orgId: this.orgId,
              },
              _.isUndefined
            ),
            onSuccess.bind(this),
            onError.bind(this)
          );

          function onSuccess(resource) {
            this.encryptionStatusInfo = resource;
            resolve(this.encryptionStatusInfo);
          }

          function onError(error) {
            _.set(
              this,
              'encryptionStatusInfo.encryptionProgressStatus',
              DIRECTORY_ENCRYPTION_PROGRESS.FAILED
            );
            reject(error);
          }
        });
      }

      /**
       * @description Removes the user as a tenant admin.
       * @param {String} userId - The User to perform the action on.
       * @returns {Promise} resolved when the removal completes.
       */
      removeUserTenantAdmin(userId) {
        return toggleUserTenantAdmin(jilDirectories.tenantAdmins.delete, this, userId);
      }

      requiresActivation() {
        return this.status === DIRECTORY_STATUS.REQUIRES_ACTIVATION;
      }

      requiresApproval() {
        return this.status === DIRECTORY_STATUS.REQUIRES_APPROVAL;
      }

      requiresConfiguration() {
        return this.status === DIRECTORY_STATUS.REQUIRES_CONFIGURATION;
      }

      samlConfig() {
        const params = {
          directoryId: this.id,
          orgId: this.orgId,
          tenantId: _.get(this, 'tenantInfo.imsTenantInfo.id'),
        };
        if (this.requiresActivation() || this.isStatusActive() || this.isStatusNeedsDomain()) {
          return DirectorySamlConfig.get(params).$promise;
        }
        return $q.resolve(new DirectorySamlConfig(params));
      }

      /**
       * @description Register the orgId this directory is against.
       * @param {String} orgId - the orgId to record
       */
      setOrgId(orgId) {
        this.orgId = orgId;
      }

      /**
       * @description enables or disables encryption.
       * @param {Object} options - options to specify resource to use.
       * @param {options.resource} resource The resource to use to either encrypt or de-encrypt the domain.
       * @returns {Promise} resolved when the enabling completes.
       */
      toggleEncryption(options = {}) {
        if (!options.resource) {
          return $q.reject('no resource specified');
        }

        return options
          .resource({id: this.externalId})
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this));

        function onSuccess(resource) {
          this.encryptionStatusInfo = resource;
          $rootScope.$emit(DIRECTORY_EVENT.UPDATE);
          return this;
        }

        function onError(error) {
          _.set(
            this,
            'encryptionStatusInfo.encryptionProgressStatus',
            DIRECTORY_ENCRYPTION_PROGRESS.FAILED
          );
          return $q.reject(error);
        }
      }

      /**
       * @description enables or disables given policy.
       * @param {String} policy - name of policy to toggle.
       * @returns {Promise} resolved when the toggling completes.
       */
      togglePolicy(policy) {
        if (_.isNil(policy)) {
          return $q.reject('no policy specified');
        }
        const originalPolicies = _.cloneDeep(this.policies);
        if (this.isPolicyEnabled(policy)) {
          delete this.policies[policy];
        } else {
          if (!this.policies) {
            this.policies = {};
          }
          this.policies[policy] = 'true';
        }

        return this.update({policies: this.policies})
          .then(() => $q.resolve())
          .catch(() => {
            this.policies = originalPolicies;
            return $q.reject();
          });
      }

      /**
       * @description generate model subset containing only API relevant fields.
       * @returns {Object} API relevant subset of Directory object.
       */
      toMinimumModel() {
        return toMinimumObject(this);
      }

      /**
       * @description update the specified directory properties.
       * @param {Object} options as described below
       * @param {String} options.name the directory name to be updated
       * @param {Boolean} options.notifyAdminsDomainMigrationComplete whether to email admins when
       *   a domain finishes migrating to this directory
       * @param {String} options.status directory status to be updated
       * @param {Object} options.policies policy list to be updated
       * @returns {Promise} resolved when directory is updated.
       */
      update(options) {
        const deferred = $q.defer();
        this.$promise = deferred.promise;
        this.$resolved = false;

        const reqParams = _.assign({directoryId: this.id}, options);
        jilDirectories.directories.update(
          reqParams,
          (response) => {
            this.name = response.name;
            this.notifyAdminsDomainMigrationComplete = response.notifyAdminsDomainMigrationComplete;
            this.status = response.status;
            this.$resolved = true;
            this.policies = response.policies;
            $rootScope.$emit(DIRECTORY_EVENT.UPDATE);
            deferred.resolve(this);
          },
          () => {
            this.$resolved = true;
            deferred.reject();
          }
        );

        return this.$promise;
      }

      static get(options) {
        const model = new Directory(options);
        model.refresh();
        return model;
      }

      static apiResponseTransformer(item, sourceList) {
        const options = _.defaults({}, item, _.pick(sourceList, 'orgType'));
        return new Directory(options);
      }
    }
    return Directory;

    //////////////

    function applyResource(directory, resource) {
      angular.extend(directory, toMinimumObject(resource));
      applyPrimaryIdpHelpers(directory);
      return directory;
    }

    // Reads IMS tenantInfo to set `isPrimaryIdp` helpers.
    // Note that currently, directories are 1-to-1 with IDPs.
    // This will change soon, so directories will have multiple IDPs with a single primary IDP.
    // This will make migration off of Okta simpler and allow testing newly setup IDPs before making them primary.
    function applyPrimaryIdpHelpers(directory) {
      const defaultIdpId = _.get(directory, 'tenantInfo.imsTenantInfo.defaultIdp');
      const idpList = _.get(directory, 'tenantInfo.imsTenantInfo.idps') || [];
      const defaultIdp = _.find(idpList, {id: defaultIdpId}) || {};
      directory.isPrimaryIdpOkta = defaultIdp.type === IDP_TYPES.OKTA;
      directory.isPrimaryIdpAzure = defaultIdp.type === IDP_TYPES.AZURE;
      directory.isPrimaryIdpSaml = defaultIdp.type === IDP_TYPES.SAML;
    }

    function applyDefaultEncryptionStatusInfo(directory) {
      _.assign(directory, {
        encryptionStatusInfo: {
          encryptedUsers: 0,
          encryptionProgressStatus: DIRECTORY_ENCRYPTION_PROGRESS.UNKNOWN,
          encryptionStatus:
            directory.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
            (directory.isStatusActive() || directory.isStatusNeedsDomain())
              ? DIRECTORY_ENCRYPTION_STATUS.DISABLED
              : DIRECTORY_ENCRYPTION_STATUS.NOT_APPLICABLE,
          reason: '',
          totalUsers: 0,
        },
      });
    }

    /**
     * @description creates or removes a user as a tenant admin.
     * @param {Resource} resource The resource to use to either create or remove the admin.
     * @param {Directory} directory - The Directory to perform the action on.
     * @param {String} [userId] - The User to perform the action on.
     * @returns {Promise} resolved when the action completes.
     */
    function toggleUserTenantAdmin(resource, directory, userId) {
      return resource(
        _.omitBy(
          {
            directoryId: directory.id,
            id: userId,
            orgId: directory.orgId,
            tenantId: _.get(directory, 'tenantInfo.imsTenantInfo.id'),
          },
          _.isUndefined
        ),
        // we pass an empty body to ensure the params aren't mapped to the POST body
        {}
      ).$promise;
    }

    function toMinimumObject(object) {
      return _.pick(object, [
        'id',
        'domainCount',
        'domainEnforcement',
        'encryptionStatusInfo',
        'externalId',
        'externallyManaged',
        'name',
        'notifyAdminsDomainMigrationComplete',
        'orgId',
        'orgType',
        'ownershipStatus',
        'policies',
        'status',
        'tenantInfo',
        'trusts',
        'type',
        'userCount',
      ]);
    }
  }
})();
/* eslint-enable max-lines */
