import isAfter from 'date-fns/isAfter';
import pick from 'lodash/pick';
import toNumber from 'lodash/toNumber';

import ComplianceSymptom from 'models/complianceSymptom/ComplianceSymptom';
import {
  getComplianceSymptomValue,
  hasComplianceSymptom,
  hasComplianceSymptomEnabled,
  hasComplianceSymptomsEnabled,
} from 'models/complianceSymptom/complianceSymptomUtils';
import feature from 'services/feature';
import {PRODUCT_COMPLIANCE_SYMPTOM} from 'services/product/ProductConstants';

import {LICENSE_TUPLE_QUANTITY_UNLIMITED} from './licenseTupleConstants';

/**
 * @description License tuples are the license quantity denominations of a Product.
 */
class LicenseTuple {
  complianceSymptoms;
  complianceTupleId;
  contractId;
  quantity;

  /**
   * @param {Object} options The init options
   * @param {String[]} options.acquiredOfferIds - Array of acquired offer ids that apply for this tuple.
   * @param {ComplianceSymptoms[]} options.complianceSymptoms The compliance symptom array
   * @param {String} options.complianceTupleId The compliance tuple id
   * @param {String} options.contractId The contract id associated with the tuple
   * @param {String|Number} options.quantity The quantity of licenses, or the string UNLIMITED
   */
  constructor(options = {}) {
    initModel(this, options);
  }

  /**
   * This is primarily used when a license is provisioning and not yet in a usable state.
   * See https://wiki.corp.adobe.com/display/SCS/can_hide+License+Compliance+Symptom+Computation
   *
   * @returns {Boolean} true if the related license can be hidden.
   */
  canHide() {
    return hasComplianceSymptom({
      complianceSymptoms: this.complianceSymptoms,
      name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_HIDE,
    });
  }

  /**
   * @returns {Boolean} true if the tuple can be renewed
   */
  canRenew() {
    return hasComplianceSymptom({
      complianceSymptoms: this.complianceSymptoms,
      name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_NON_RENEWAL,
    });
  }

  /**
   * @description Get the end date for when the license can be used.
   * Uses the value of can_message_expiration_at if it exists, otherwise uses
   * can_access_until.
   * @returns {String|undefined} the date value returned from CLAM / SCS, otherwise undefined
   */
  getEndDate() {
    return (
      getComplianceSymptomValue({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_EXPIRATION_AT,
      }) ||
      getComplianceSymptomValue({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS_UNTIL,
      })
    );
  }

  /**
   * @param {String} renewalWindowEndDate renewal window end date in ISO-8061 timestamp format
   * @returns {Boolean} true if the tuple has been renewed.
   */
  hasRenewed(renewalWindowEndDate) {
    // If the tuple has not been renewed, then can_message_expiration_at will exist and
    //    can be used to compare against the renewal window end date.
    // If it has been renewed, then can_message_expiration_at will be omitted. In that case
    //    we can use the can_access_until date instead.
    const endDate = this.getEndDate();
    return isAfter(new Date(endDate), new Date(renewalWindowEndDate));
  }

  /**
   * @description Method to determine whether or not a tuple are for trial licenses
   * @returns {Boolean} Returns true if the tuple are for trial licenses
   */
  hasTrialExpiration() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION,
    });
  }

  /**
   * @description Method to return if the tuple has the can_delegate or can_access compliance symptom
   * @returns {Boolean} true if tuple is assignable
   */
  isAssignable() {
    return (
      hasComplianceSymptomEnabled({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_DELEGATE,
      }) ||
      hasComplianceSymptomEnabled({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
      })
    );
  }

  /**
   * @description Method to return if the tuple has both can_message_upcoming_cancellation and can_access compliance symptom.
   * These tuples are cancelling.
   * @returns {Boolean} true if tuple is cancelling
   */
  isCancelling() {
    return hasComplianceSymptomsEnabled({
      complianceSymptoms: this.complianceSymptoms,
      names: [
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_UPCOMING_CANCELLATION,
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
      ],
    });
  }

  /**
   * @description Method to return if the tuple has the can_delegate symptom enabled.
   *
   * @returns {Boolean} True if the can_delegate symptom is enabled, false otherwise.
   */
  isDelegatable() {
    return hasComplianceSymptomEnabled({
      complianceSymptoms: this.complianceSymptoms,
      name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_DELEGATE,
    });
  }

  /**
   * @description Method to return if the tuple has both can_message_billing_failure and can_access compliance symptom.
   * These tuples are grace past due.
   * @returns {Boolean} true if tuple is grace past due
   */
  isGracePastDue() {
    return hasComplianceSymptomsEnabled({
      complianceSymptoms: this.complianceSymptoms,
      names: [
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_BILLING_FAILURE,
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
      ],
    });
  }

  /**
   * @description Method to return if the tuple has the can_message_billing_failure compliance symptom
   * and does not have can_access. These tuples are past due.
   * @returns {Boolean} true if tuple is past due
   */
  isPastDue() {
    return (
      hasComplianceSymptomEnabled({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_BILLING_FAILURE,
      }) &&
      !hasComplianceSymptom({
        complianceSymptoms: this.complianceSymptoms,
        name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
      })
    );
  }

  /**
   * @description Returns true if tuple has both can_access and can_message_needs_backing
   * if the bug fix feature flag for ABPS-43557 exists check tuple doesn't have can_message_billing_failure.
   * Formerly known as the PENDING_PAYMENT quantity status.
   * @returns {Boolean} Returns true if the tuple has both can_access and can_message_needs_backing
   * if the bug fix feature flag for ABPS-43557 exists check tuple doesn't have can_message_billing_failure.
   */
  isPendingPayment() {
    if (feature.isEnabled('bug_fix_abps_43557')) {
      return (
        hasComplianceSymptomsEnabled({
          complianceSymptoms: this.complianceSymptoms,
          names: [
            PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
            PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_NEEDS_BACKING,
          ],
        }) &&
        !hasComplianceSymptomEnabled({
          complianceSymptoms: this.complianceSymptoms,
          name: PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_BILLING_FAILURE,
        })
      );
    }
    return hasComplianceSymptomsEnabled({
      complianceSymptoms: this.complianceSymptoms,
      names: [
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS,
        PRODUCT_COMPLIANCE_SYMPTOM.CAN_MESSAGE_NEEDS_BACKING,
      ],
    });
  }

  /**
   * @description Returns true if the quantity is 'UNLIMITED'
   *
   * @returns {Boolean} true if tuple quantity is 'UNLIMITED''
   */
  isUnlimited() {
    return this.quantity === LICENSE_TUPLE_QUANTITY_UNLIMITED;
  }

  /**
   * @returns {Object} the bare json object representation of the model
   */
  toMinimumModel() {
    return {
      quantity: this.quantity,
    };
  }
}

function initModel(model, options) {
  Object.assign(model, pick(options, ['acquiredOfferIds', 'complianceTupleId', 'contractId']), {
    complianceSymptoms:
      options.complianceSymptoms?.map(
        (complianceSymptom) => new ComplianceSymptom(complianceSymptom)
      ) || [],
    // quantity comes in as a string, so convert to a number if not 'UNLIMITED'
    quantity:
      options.quantity === LICENSE_TUPLE_QUANTITY_UNLIMITED
        ? options.quantity
        : toNumber(options.quantity),
  });
}

export default LicenseTuple;
