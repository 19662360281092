import jilProductSolutionGroups from 'api/jil/jilProductSolutionGroups';
import log from 'services/log';

export default class SolutionGroupList {
  /**
   * @description instantiates a new instance of SolutionGroupList.
   *
   * @param {String} productId - the id for the product
   * @param {String} orgId - the id for the org
   * @returns {SolutionGroupList} SolutionGroupList model object.
   */
  static async get(productId, orgId) {
    const model = new SolutionGroupList(productId, orgId);
    await model.refresh();
    return model;
  }

  /**
   * @description Creates a new SolutionGroupList.
   *
   * @param {String} productId - the id for the product
   * @param {String} orgId - the id for the org
   */
  constructor(productId, orgId) {
    this.productId = productId;
    this.orgId = orgId;
  }

  /**
   * @description Reloads the solution group list from the product solution groups resource
   * @return {Promise} a promise
   */
  async refresh() {
    try {
      const response = await jilProductSolutionGroups.getProductSolutionGroups({
        orgId: this.orgId,
        productId: this.productId,
      });

      this.items = response.data;
    } catch (error) {
      log.error('SolutionGroupList.refresh() failed to load. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }
}
