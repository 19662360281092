/**
 * @description Helper to construct text content for table cells
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {String} type - one of 'delegated' or 'provisioned'
 * @returns {String} text content for the type of cell being rendered
 */
function getQuantityStr(intl, type, item) {
  const ENDASH_STR = '\u2013';
  if (
    !item?.bundleProduct ||
    (item?.bundleProduct?.qty && item.bundleProduct.qty[`${type}Qty`] === -1)
  ) {
    return ENDASH_STR;
  }
  if (item?.bundleProduct?.qty && !item?.bundleProduct?.deleted) {
    return intl.formatNumber(item.bundleProduct.qty[`${type}Qty`]);
  }
  return '';
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getQuantityStr};
