(function () {
  'use strict';
  angular.module('binky.shell.navigation.assembler', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.shell.navigation.constants',
    'binky.shell.navigation.nav-item',
    'binky.shell.navigation.state-manager',
    'ui.router',
  ]);
})();
