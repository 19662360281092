(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   *
   * @ngdoc component
   * @name binky.widgets.insights.insights-item-detail-list-table:binkyInsightsItemDetailListTable
   * @description displays a list of insights item detail.
   *
   * @param {String} apiKey - the api key for downloading insights item detail
   * @param {Object} insightsItemDetailsList - The insights item detail list to be displayed.
   * @param {Object} insightsItemTab - The insights item tab to get allowed actions and columns.
   * @param {INSIGHTS_SECTION} insightsSection - The insights section which determines the no item detail message.
   * @param {String} orgId - the organization id
   * @param {Selection} selection - The selection list object tracking which insights item details are selected.
   *
   */
  angular
    .module('binky.widgets.insights.insights-item-detail-list-table')
    .component('binkyInsightsItemDetailListTable', {
      bindings: {
        apiKey: '<',
        contractList: '<',
        insightsItemDetailList: '<',
        insightsItemTab: '<',
        insightsSection: '<',
        orgId: '<?',
        productList: '<',
        selection: '<?',
      },
      controller,
      templateUrl:
        'widgets/insights/insights-item-detail-list-table/insights-item-detail-list-table.component.html',
    });

  // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
  function controller(
    _,
    $scope,
    $translate,
    FileSize,
    INSIGHTS_ITEM_DETAIL_COLUMN,
    INSIGHTS_ITEM_DETAIL_LIST_PAGINATION_ID,
    INSIGHTS_ITEM_DETAIL_LIST_REFRESH,
    InsightsItemAccess,
    SELECTION_STATE,
    TOAST_TYPES,
    toastManager,
    translationUtils
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      download,
      getColumnLabelClass,
      getNoItemMessage,
      getObject,
      getSize,
      getStringOrNumber,
      getTableBodyClasses,
      getTableHeaderClasses,
      getTableHeaderLabel,
      isChecked,
      onClickCheck,
      onClickCheckAll,
      onGoToNextPage,
      onGoToPreviousPage,
      onPageSizeChange,
      showCheckbox,
      showColumn,
      showFolderIcon,
      showNoItemsRow,
      sortColumn,
    });

    function $onInit() {
      _.assign(vm, {
        isDownloading: false,
        paginationId: _.uniqueId(INSIGHTS_ITEM_DETAIL_LIST_PAGINATION_ID),
        sort: _.get(vm.insightsItemTab.actions.VIEW, 'defaults.sort'),
        sortReverse: _.get(vm.insightsItemTab.actions.VIEW, 'defaults.sortReverse'),
      });
      $scope.$on(INSIGHTS_ITEM_DETAIL_LIST_REFRESH, onRefresh);
    }

    function download(insightsItemDetail) {
      if (!vm.isDownloading) {
        vm.isDownloading = true;
        toastManager.showToast(
          TOAST_TYPES.INFO,
          $translate.instant(
            'binky.widgets.insights.insightsItemDetailListTable.download.downloadBeingPrepared'
          )
        );
        insightsItemDetail
          .download({apiKey: vm.apiKey, downloadAction: vm.insightsItemTab.actions.DOWNLOAD})
          .catch(() => {
            toastManager.showErrorToast(
              $translate.instant(
                'binky.widgets.insights.insightsItemDetailListTable.download.unableToDownload'
              )
            );
          })
          .finally(() => {
            vm.isDownloading = false;
          });
      }
    }

    function getColumnLabelClass(sort) {
      return _.isUndefined(sort) ? 'column-label' : 'sortable-column-label';
    }

    function getNoItemMessage() {
      return (
        vm.insightsItemTab.noItemMessage ||
        (translationUtils.hasKey(vm.insightsItemTab.noItemMessageKey)
          ? translationUtils.sanitizeSafeInstant(vm.insightsItemTab.noItemMessageKey)
          : $translate.instant(
              `binky.widgets.insights.item.detail.no${_.upperFirst(vm.insightsSection)}`
            ))
      );
    }

    function getObject(object, insightsItemDetail) {
      if (object && _.isObject(object)) {
        const eventTypeTermsConditions = 'TERMS_AND_CONDITIONS';
        const targetKeysForTermsConditions = ['termDescription', 'contractId'];
        const priorityOrder =
          insightsItemDetail.eventType === eventTypeTermsConditions
            ? targetKeysForTermsConditions
            : [];

        const keyList = [
          ...priorityOrder,
          // eslint-disable-next-line lodash/chaining
          ..._.filter(_.keys(object), (key) => !_.includes(priorityOrder, key)),
        ];

        return _(keyList)
          .filter((key) => !!object[key])
          .map((key) => object[key])
          .join(' | ');
      }
      return object;
    }

    function getSize(size) {
      return size ? FileSize.bytesToString(size) : undefined;
    }

    function getStringOrNumber(column, insightsItemDetail) {
      const key = column.translationKeyPrefix + _.toLower(_.get(insightsItemDetail, column.key));
      return translationUtils.hasKey(key)
        ? translationUtils.sanitizeSafeInstant(key)
        : _.get(insightsItemDetail, column.key);
    }

    function getTableBodyClasses(column) {
      return `column coral-Table-cell ${_.kebabCase(column.key)}`;
    }

    function getTableHeaderClasses(column) {
      return [
        {
          ascending: !(column.sort === vm.sort && vm.sortReverse),
          descending: column.sort === vm.sort && vm.sortReverse,
          sortable: !_.isUndefined(column.sort),
          sorted: column.sort === vm.sort,
        },
        `column coral-Table-headerCell ${_.kebabCase(column.key)}`,
      ];
    }

    function getTableHeaderLabel(column) {
      return column.title || translationUtils.sanitizeSafeInstant(column.titleKey);
    }

    function isChecked(insightsItemDetail) {
      return vm.selection.isItemSelected(insightsItemDetail);
    }

    function onClickCheck(insightsItemDetail) {
      vm.selection.toggleItemSelection(insightsItemDetail);
      updateHeaderCheckbox();
    }

    function onClickCheckAll() {
      if (vm.isAllChecked) {
        vm.selection.deselectItems(vm.insightsItemDetailList.items);
      } else {
        vm.selection.selectItems(vm.insightsItemDetailList.items);
      }
      updateHeaderCheckbox();
    }

    function onGoToNextPage() {
      vm.insightsItemDetailList.state.goToNextPage();
      refreshData();
    }

    function onGoToPreviousPage() {
      vm.insightsItemDetailList.state.goToPreviousPage();
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.insightsItemDetailList.state.setPageSize(pageSize);
      refreshData();
    }

    function showCheckbox(column) {
      return (
        column.key === INSIGHTS_ITEM_DETAIL_COLUMN.KEY.NAME &&
        vm.insightsItemTab.actions.DELETE &&
        InsightsItemAccess.checkAccess(vm.insightsItemTab.actions.DELETE.access, {
          contractList: vm.contractList,
          orgId: vm.orgId,
          productList: vm.productList,
        })
      );
    }

    function showColumn(column, types) {
      return _.includes(types, column.type);
    }

    function showFolderIcon(column, insightsItemDetail) {
      return column.key === INSIGHTS_ITEM_DETAIL_COLUMN.KEY.NAME && insightsItemDetail.isFolder();
    }

    function showNoItemsRow() {
      return vm.insightsItemDetailList.items.length === 0;
    }

    function sortColumn(sort) {
      if (_.isUndefined(sort) || vm.insightsItemDetailList.items.length === 0) {
        // no sort occurs if sort is undefined or there is no insights item detail
        return;
      } else if (vm.sort === sort) {
        // reverse sort order. Ex: ASC -> DESC
        vm.sortReverse = !vm.sortReverse;
      } else {
        // change sort name and set sort order to default(ASC).
        vm.sort = sort;
        vm.sortReverse = false;
      }
      vm.insightsItemDetailList.state.setSortKeyAndReverseIfSameKey(vm.sort);
      refreshData();
    }

    ///////////////

    function onRefresh() {
      updateHeaderCheckbox();
    }

    function refreshData() {
      vm.insightsItemDetailList.refresh();
    }

    function updateHeaderCheckbox() {
      if (_.get(vm.insightsItemDetailList, 'items.length') > 0) {
        const selectionState = vm.selection.getSelectionStateForItems(
          vm.insightsItemDetailList.items
        );
        vm.isAllChecked = selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
