import UserGroupIcon from '@react/react-spectrum/Icon/UserGroup';
import PropTypes from 'prop-types';
import React from 'react';

import Pill from './Pill';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-mini-cards
 */

const UserGroupPill = ({
  closeButtonAriaLabel,
  closeButtonTooltipText,
  header,
  isDisabled = false,
  onDismiss,
  subtitle,
}) => (
  <Pill
    closeButtonAriaLabel={closeButtonAriaLabel}
    closeButtonTooltipText={closeButtonTooltipText}
    header={header}
    IconComponent={<UserGroupIcon size="S" />}
    iconVariant="circle"
    isDisabled={isDisabled}
    onDismiss={onDismiss}
    subtitle={subtitle}
  />
);

UserGroupPill.propTypes = {
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTooltipText: PropTypes.string,
  header: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onDismiss: PropTypes.func,
  subtitle: PropTypes.string,
};

export default UserGroupPill;
