(function () {
  /**
   * @deprecated Please use src2/core/models/pageContext/PageContext.js
   *
   * @ngdoc factory
   * @name PageContext
   * @description Model factory for PageContext Objects
   */
  angular.module('binky.core.page-context').factory('PageContext', getPageContext);

  /* @ngInject */
  function getPageContext(binkySrc2) {
    return binkySrc2.models.pageContext.PageContext;
  }
})();
