import PropTypes from 'prop-types';

import {useWizardContext} from './WizardContext';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-wizard
 */

/**
 * Container for the children pages/steps that will be displayed within a wizard
 */
const WizardView = ({children}) => {
  const {currentStep} = useWizardContext();
  const nonNullChildren = children.filter((item) => item); // allows for children to be conditional
  return nonNullChildren[currentStep];
};

WizardView.propTypes = {
  /**
   * Each page/step component stacked in step order
   */
  children: PropTypes.node.isRequired,
};

export default WizardView;
