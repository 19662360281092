(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilOrganizations.js
   *
   * @ngdoc service/provider
   * @name jilOrganizations
   * @description defines service to manage jil organizations resources
   */
  angular.module('binky.core.api.jil').provider('jilOrganizations', jilOrganizationsProvider);

  let url;

  /* @ngInject */
  function jilOrganizationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($httpParamSerializerJQLike, $resource) {
      const service = {
        organizations: getOrganizationsResource(),
        organizationsByAdminEmail: searchByAdminEmailResource(),
        organizationsByAdminId: searchByAdminIdResource(),
        organizationsByClaimedDomain: searchByClaimedDomainResource(),
        organizationsByContractId: searchByContractIdResource(),
        organizationsByEccId: searchByEccIdResource(),
        organizationsByExactName: searchByExactNameResource(),
        organizationsByExternalContractId: searchByExternalContractIdResource(),
        organizationsByTokenizedName: searchByTokenizedNameResource(),
      };

      return service;

      ///////////

      function getOrganizationsResource() {
        return $resource(
          `${url}/:id`,
          {
            id: '@id',
          },
          {
            update: {
              method: 'PUT',
            },
          }
        );
      }

      function searchByAdminEmailResource() {
        return $resource(
          `${url}\\:search.admin_email`,
          {},
          {
            query: {
              headers: {'Content-Type': 'application/x-www-form-urlencoded'},
              isArray: true,
              method: 'POST',
              transformRequest(data) {
                return $httpParamSerializerJQLike(data);
              },
            },
          }
        );
      }

      function searchByAdminIdResource() {
        return $resource(`${url}\\:search.admin_id`);
      }

      function searchByClaimedDomainResource() {
        return $resource(`${url}\\:search.claimed_domain`);
      }

      function searchByContractIdResource() {
        return $resource(`${url}\\:search.contract`);
      }

      function searchByEccIdResource() {
        return $resource(`${url}\\:search.ecc_end_user_id`);
      }

      function searchByExactNameResource() {
        return $resource(`${url}\\:search.name`);
      }

      function searchByExternalContractIdResource() {
        return $resource(`${url}\\:search.external_contract`);
      }

      function searchByTokenizedNameResource() {
        return $resource(`${url}\\:search.tokenized_name`);
      }
    }
  }
})();
