/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to convert from a DOMAIN_STATUS_LABEL_KEY key to a translated string for display.
 * @param {String} statusLabelKey A key from DOMAIN_STATUS_LABEL_KEY.
 * @returns {String} The translated display string that corresponds to DOMAIN_STATUS_LABEL_KEY key.
 * @example
 *    To  derive the status label for a given status label key:
 *      binkyDomainStatusLabel(DOMAIN_STATUS_LABEL_KEY.ACTIVE);
 *    To derive the status label for a given domain:
 *      $ctrl.domain | binkyDomainStatusToStatusLabelKey | binkyDomainStatusLabel
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.domains.status-filter')
    .filter('binkyDomainStatusLabel', binkyDomainStatusLabel);

  /* @ngInject */
  function binkyDomainStatusLabel($translate, _, DOMAIN_STATUS_LABEL_KEY) {
    return getDomainStatusLabel;

    function getDomainStatusLabel(statusLabelKey) {
      if (_.includes(DOMAIN_STATUS_LABEL_KEY, statusLabelKey)) {
        return $translate.instant(`binky.widgets.domains.domainStatusLabel.${statusLabelKey}`);
      }

      return '';
    }
  }
})();
