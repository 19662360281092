import {Breadcrumbs, Item, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {requiredIf} from 'common/utils/prop-type/propTypeUtils';

import styles from './PageBreadcrumbs.pcss';

/**
 * Breadcrumbs component used for Page to show the path to the page.
 */
const PageBreadcrumbs = ({children, isDisabled = true, items, onAction}) => (
  // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- see comment on breadcrumbs style in pcss file
  <View UNSAFE_className={styles.breadcrumbs}>
    <Breadcrumbs isDisabled={isDisabled} onAction={onAction} showRoot>
      {children || items.map((item) => <Item key={item.key || item.id}>{item.label}</Item>)}
    </Breadcrumbs>
  </View>
);

PageBreadcrumbs.displayName = 'PageBreadcrumbs';
PageBreadcrumbs.propTypes = {
  /**
   * One or more V3 React Spectrum Breadcrumbs Items.
   * For example, '<Item key="home">Home</Item>'.
   * Either specify 'children' or 'items'. 'children' takes precedence.
   */
  children: requiredIf(PropTypes.node, (props) => !props.items),
  /**
   * If disabled and 'item' is specified, breadcrumbs are shown but no navigation is available.
   * The default is true. If set to false, 'onAction' should be specified.
   */
  isDisabled: PropTypes.bool,
  /**
   * An array of items to include as breadcrumbs. Each item should have an 'id' or a 'key'
   * and a 'label.
   * A Spectrum Breadcrumbs component will be built from these items.
   * Either specify 'children' or 'items'. 'children' takes precedence.
   */
  items: requiredIf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string, // specify either id or key, key takes precedence
        key: PropTypes.string,
        label: PropTypes.string.isRequired,
      })
    ),
    (props) => !props.children
  ),
  /**
   * If 'items' is specified, the callback which handles the press events on the breadcrumb items.
   * There is one parameter which is the id of the item pressed.
   * This prop should be specified if 'items' is set and 'isDisabled' is false.
   */
  onAction: requiredIf(PropTypes.func, (props) => props.items && props.isDisabled === false),
};

export default PageBreadcrumbs;
