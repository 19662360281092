(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum NumberField
   *
   * @ngdoc component
   * @name binky.widgets.common.number-input:binkyNumberInput
   *
   * @description A number input used to enter in numeric values.
   *
   * @param {Boolean} [allowScroll] When false, scrolling is blocked as a means of changing the value. Default: true
   * @param {String} [index] An index value, passed back on change events
   * @param {Boolean} [isDisabled] When true, the number input is disabled. Default: false
   * @param {String} [labelledBy] The element ID to point to as the label of this number input.
   * @param {Number} [maxValue] The maximum numeric value which can be entered. Default: 150000
   * @param {Number} [minValue] The minimum numeric value which can be entered. Default: 0
   * @param {Function} [onChange] A callback invoked when the value changes.
   * @param {String} [placeholder] Placeholder text to display when no number has been entered.
   * @param {String} [required] When true, a value is required so when the input is cleared
   *   the component is bordered in red to indicate a required field. Default is false.
   * @param {Number} [value] The number to bind this to. It will be updated as the value changes.
   *
   * @example <binky-number-input
   *            allow-scroll="false"
   *            is-disabled="false"
   *            on-change="onChange()"
   *            placeholder="My Placeholder"
   *            required="false">
   *         </binky-number-input>
   *
   */
  angular.module('binky.widgets.common.number-input').component('binkyNumberInput', {
    bindings: {
      allowScroll: '<?',
      index: '<?',
      isDisabled: '<?',
      labelledBy: '@',
      maxValue: '<?',
      minValue: '<?',
      onChange: '&?',
      placeholder: '@?',
      required: '<?',
      value: '=',
    },
    controller,
    templateUrl: 'widgets/common/number-input/number-input.component.html',
  });

  /* @ngInject */
  function controller($element, $scope, $timeout, _) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      onChanges,
    });

    Coral.commons.ready($element.find('coral-numberinput')[0], (numberInputEl) => {
      $element.find('coral-numberinput').find('input').on('input', onInput);
      $element.find('coral-numberinput').on('change', () => {
        _.invoke(vm, 'onChange');
      });

      if (!_.isUndefined(vm.allowScroll) && !vm.allowScroll) {
        // we prevent scrolling by blocking the two events Coral watches for
        $element.find('input').on('mousewheel', preventDefualtAndStopPropagation);
        $element.find('input').on('DOMMouseScroll', preventDefualtAndStopPropagation);
      }

      if (vm.required) {
        numberInputEl.required = true;
      }
    });

    ////////

    function onChanges() {
      if (!_.isUndefined(vm.value)) {
        $scope.$emit('INPUT_VALUE_UPDATED', {index: vm.index, newValue: vm.value});
      }
    }

    function $onDestroy() {
      _.invoke(vm, 'watchListener');
    }

    function $onInit() {
      if (!_.isUndefined(vm.index) && !_.isUndefined(vm.value)) {
        vm.watchListener = $scope.$watch(() => vm.value, vm.onChanges);
      }
    }

    function onInput(event) {
      // Timeout in order to trigger an Angular digest cycle.
      $timeout(() => {
        $scope.$emit('INPUT_VALUE_PENDING', event);
      });
    }

    function preventDefualtAndStopPropagation(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
})();
