(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum Alert
   */
  angular.module('binky.widgets.common.page-banner').constant('PAGE_BANNER_VARIANT', {
    ERROR: 'error',
    HELP: 'help',
    INFO: 'info',
    WARNING: 'warning',
  });
})();
