/* eslint-disable max-lines -- imports */
/* eslint-disable @admin-tribe/admin-tribe/include-in-index -- does not need index file */
import adobeStatusEvents from 'api/adobeStatusEvents';
import aosOffers from 'api/aosOffers';
import ccs from 'api/ccs';
import clypeUsers from 'api/clypeUsers';
import collaborationPolicyService from 'api/collaborationPolicyService';
import etlaUsageReport from 'api/etla-usage-report/etlaUsageReport';
import floodgate from 'api/floodgate';
import ims from 'api/ims';
import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilAdmins from 'api/jil/jilAdmins';
import {getHeaders} from 'api/jil/jilApiUtils';
import jilApplicationIntegrations from 'api/jil/jilApplicationIntegrations';
import jilAuthenticationPolicies from 'api/jil/jilAuthenticationPolicies';
import jilConsumables from 'api/jil/jilConsumables';
import jilContacts from 'api/jil/jilContacts';
import jilContracts from 'api/jil/jilContracts';
import jilDirectories from 'api/jil/jilDirectories';
import jilDomains from 'api/jil/jilDomains';
import jilFulfillmentEvents from 'api/jil/jilFulfillmentEvents';
import jilGlobalAdminPolicies from 'api/jil/jilGlobalAdminPolicies';
import jilInvoices from 'api/jil/jilInvoices';
import jilOffers from 'api/jil/jilOffers';
import jilOrganizationLocale from 'api/jil/jilOrganizationLocale';
import jilOrganizations from 'api/jil/jilOrganizations';
import jilOrganizationsCart from 'api/jil/jilOrganizationsCart';
import jilOrganizationsContractOffers from 'api/jil/jilOrganizationsContractOffers';
import jilOrganizationsMigrations from 'api/jil/jilOrganizationsMigrations';
import jilOrganizationsOffers from 'api/jil/jilOrganizationsOffers';
import jilOrganizationsProductsChange from 'api/jil/jilOrganizationsProductsChange';
import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';
import jilOrganizationsSummaries from 'api/jil/jilOrganizationsSummaries';
import jilPasswordPolicy from 'api/jil/jilPasswordPolicy';
import jilProductArrangements from 'api/jil/jilProductArrangements';
import jilProductSolutionGroups from 'api/jil/jilProductSolutionGroups';
import jilProducts from 'api/jil/jilProducts';
import jilSearch from 'api/jil/jilSearch';
import jilUserGroups from 'api/jil/jilUserGroups';
import jilUsers from 'api/jil/jilUsers';
import kmi from 'api/kmi/kmi';
import orders from 'api/orders/orders';
import apiPerformanceInterceptor from 'api/performance-interceptor/apiPerformanceInterceptor';
import {GLOBAL_ADMIN_POLICY, INVITATION_DOMAIN_SHARING, POLICY} from 'api/policy/PolicyConstants';
import {getPoresHeaders} from 'api/pores/poresApiUtils';
import poresOrganizationsOffers from 'api/pores/poresOrganizationsOffers';
import statusEvents from 'api/statusEvents';
import stock from 'api/stock';
import {csvBlobTransformer} from 'api/utils/apiUtils';
import AvailableType from 'models/availableType/AvailableType';
import {
  hasRecommendedSuffix,
  shouldShowRecommendedSuffix,
} from 'models/availableType/availableTypeUtils';
import ComplianceSymptom from 'models/complianceSymptom/ComplianceSymptom';
import {
  getExpirationPhaseForAllLicenseTuples,
  getExpirationPhaseFromContractComplianceSymptoms,
  getExpirationPhaseFromLicenseComplianceSymptoms,
} from 'models/complianceSymptom/complianceSymptomUtils';
import Contact from 'models/contact/Contact';
import Contract from 'models/contract/Contract';
import {
  CONTRACT_AUTORENEWAL_MODE,
  CONTRACT_BILLING_FREQUENCY,
  CONTRACT_BUYING_PROGRAM,
  CONTRACT_COMPLIANCE_SYMPTOMS,
  CONTRACT_DX_CORE_SERVICES_DEAL_ID,
  CONTRACT_EVENT,
  CONTRACT_EXPIRATION_GRACE_UNIT,
  CONTRACT_EXPIRATION_PHASE,
  CONTRACT_GRACE_END_DATE_OFFSET,
  CONTRACT_LEGACY_DX_ID,
  CONTRACT_MODEL,
  CONTRACT_PATCH_API_KEY_LOOKUP_TABLE,
  CONTRACT_PAYMENT_CATEGORY,
  CONTRACT_STATUS,
} from 'models/contract/ContractConstants';
import ContractTerms from 'models/contract/ContractTerms';
import {
  getCustomerSegmentFromBuyingProgram,
  getSalesChannelFromBuyingProgram,
} from 'models/contract/contractUtils';
import {
  CONTRACT_MIGRATION_STATUS,
  CONTRACT_MIGRATION_TYPE,
} from 'models/contract/migration/ContractMigrationConstants';
import Country from 'models/country/Country';
import {
  COUNTRY_LIST_CACHE_ID,
  COUNTRY_TO_LOCALE_MAP,
  DEFAULT_COUNTRY_LOCALE,
  REGION_LIST_CACHE_ID,
} from 'models/country/CountryConstants';
import DirectorySamlConfig from 'models/directory/DirectorySamlConfig';
import DirectoryUserList from 'models/directory/DirectoryUserList';
import Domain from 'models/domain/Domain';
import DomainListMultiStatusResponse from 'models/domain/DomainListMultiStatusResponse';
import {DOMAIN_STATUS} from 'models/domain/domainConstants';
import {
  FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
  FULFILLABLE_ITEM_CHARGING_MODEL_TYPE,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_DELEGATION_TYPE,
  FULFILLABLE_ITEM_TYPE,
} from 'models/fulfillableItemList/FulfillableItemConstants';
import FulfillableItemList from 'models/fulfillableItemList/FulfillableItemList';
import FulfillmentEvent from 'models/fulfillmentEvent/FulfillmentEvent';
import {
  FULFILLMENT_EVENT_STATUS,
  FULFILLMENT_NEEDED_TYPE,
} from 'models/fulfillmentEvent/FulfillmentEventConstants';
import Invoice from 'models/invoice/Invoice';
import {LICENSE_ALLOCATION_RESOURCE_TYPE} from 'models/licenseAllocations/LicenseAllocationConstants';
import LicenseAllocationInfo from 'models/licenseAllocations/LicenseAllocationInfo';
import LicenseAllocationResourceList from 'models/licenseAllocations/LicenseAllocationResourceList';
import LicenseQuantityList from 'models/licenseQuantityList/LicenseQuantityList';
import {
  LICENSE_QUANTITY_RENEWAL_STATUS,
  LICENSE_QUANTITY_STATUS,
  LICENSE_QUANTITY_TAGS,
  LICENSE_QUANTITY_UNLIMITED,
} from 'models/licenseQuantityList/LicenseQuantityListConstants';
import ListState from 'models/listState/ListState';
import AlphaListState from 'models/listState/alpha/AlphaListState';
import HybridListState from 'models/listState/hybrid/HybridListState';
import Member from 'models/member/Member';
import {MEMBER_EVENT, MODEL_CACHE_ID} from 'models/member/MemberConstants';
import MemberType from 'models/member/type/MemberType';
import {MEMBER_TECH_ACCOUNT_DOMAIN, MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import Migration from 'models/migration/Migration';
import {MIGRATION_STATUS, MIGRATION_TYPE} from 'models/migration/MigrationConstants';
import OptionsPerStatusMigration from 'models/migration/optionsPerStatus/OptionsPerStatusMigration';
import T2EMigration from 'models/migration/t2e/T2EMigration';
import Offer from 'models/offers/Offer';
import {
  CLOUD,
  COMMITMENT,
  CUSTOMER_SEGMENT,
  DELEGATION_TYPE,
  PAYMENT_STRUCTURE,
  PRICE_POINT,
  PROCESSING_INSTRUCTION,
  QUALIFIER_TARGET,
  SALES_CHANNEL,
  TYPE,
  VIRTUAL_LICENSE_SELECTION_ARL_PREFIX,
} from 'models/offers/OfferConstants';
import OfferList from 'models/offers/OfferList';
import {
  OFFER_LIST_FILTER,
  OFFER_LIST_INTENT,
  OFFER_LIST_MODE,
  OFFER_LIST_SERVICE_PROVIDER,
} from 'models/offers/OfferListConstants';
import PoresOfferList from 'models/offers/PoresOfferList';
import {
  AUTH_ERROR,
  JEM_TIMEOUT_ERROR,
  MARKET_SEGMENT,
  ORDER_STATUS,
  ORDER_TYPES,
  PURCHASE_FLOW,
} from 'models/orders/OrderConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import {
  compareProductLicenseGroups,
  compareUserGroups,
  explodeProductLicenseGroups,
  getProductLicensePatches,
  getUserGroupPatches,
} from 'models/organizationUser/organizationUserUtils';
import PageContext from 'models/pageContext/PageContext';
import {PAGE_TARGET, PAGE_TARGET_TYPE} from 'models/pageContext/PageContextConstants';
import Consumable from 'models/product/consumable/Consumable';
import {
  CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  CONSUMABLE_THRESHOLD_STATUS,
} from 'models/product/consumable/ConsumableConstants';
import ConsumableList from 'models/product/consumable/ConsumableList';
import ConsumableSummarization from 'models/product/consumable/ConsumableSummarization';
import ConsumableSummarizationList from 'models/product/consumable/ConsumableSummarizationList';
import ConsumableSummary from 'models/product/consumable/ConsumableSummary';
import ConsumableSummaryList from 'models/product/consumable/ConsumableSummaryList';
import ConsumableUsage from 'models/product/consumable/ConsumableUsage';
import ConsumableUsageList from 'models/product/consumable/ConsumableUsageList';
import {
  MAX_FI_GRANT_QUANTITY,
  MAX_LICENSE_GRANT_QUANTITY,
} from 'models/product/grant-quantity/GrantQuantityConstants';
import StockQuantity from 'models/stockQuantity/StockQuantity';
import User from 'models/user/User';
import {EMAIL_MAX_LENGTH, IMS_USER_ID_REGEX} from 'models/user/UserConstants';
import AuthenticatedUser from 'models/user/authenticated-user/AuthenticatedUser';
import UserAvatar from 'models/user/avatar/UserAvatar';
import LinkedUserAccount from 'models/user/linked-accounts/LinkedUserAccount';
import UserRoles from 'models/user/roles/UserRoles';
import {
  ADOBE_AGENT,
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
} from 'models/user/roles/UserRolesConstants';
import {
  compareAdminRoles,
  explodeAdminRoles,
  getAdminRolePatches,
} from 'models/user/roles/userRoleUtils';
import {ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY} from 'services/acquisition-summaries/AcquisitionSummaryConstants';
import AcquisitionSummaryList from 'services/acquisition-summaries/AcquisitionSummaryList';
import {
  ERROR,
  INVALIDATE,
  POLL_FREQUENCY,
  RECENT_HISTORY_THRESHOLD,
  REFRESH,
} from 'services/adobeStatus/AdobeStatusConstants';
import AdobeStatusData from 'services/adobeStatus/adobeStatusData/AdobeStatusData';
import {ANALYTICS_EVENT, EVENT_ACTION, EVENT_NAME} from 'services/analytics/AnalyticsConstants';
import AnalyticsEvent from 'services/analytics/AnalyticsEvent';
import AnalyticsPageDescriptor from 'services/analytics/AnalyticsPageDescriptor';
import CancellationOrderDescriptor from 'services/analytics/CancellationOrderDescriptor';
import ErrorDescriptor from 'services/analytics/ErrorDescriptor';
import GainsightEventDescriptor from 'services/analytics/GainsightEventDescriptor';
import OrganizationInfoDescriptor from 'services/analytics/OrganizationInfoDescriptor';
import {
  dispatchPageAnalytics,
  dispatchUiEventAnalytics,
  formatAnalyticsDate,
  getFloodgateVariantData,
} from 'services/analytics/analyticsUtils';
import authentication, {AUTHENTICATION_ERROR} from 'services/authentication';
import AvailableTypeList from 'services/availableType/AvailableTypeList';
import modelCache from 'services/cache/modelCache/modelCache';
import CartDescriptor from 'services/cart/CartDescriptor';
import CollaborationPolicies, {
  RESTRICTION_STATUS,
} from 'services/collaborationPolicies/CollaborationPolicies';
import configStore from 'services/configStore/configStore';
import ContactList from 'services/contact/ContactList';
import {
  CONTACT_LIST_CACHE_ID,
  CONTACT_LIST_EVENT,
  CONTACT_TYPE,
} from 'services/contact/ContactListConstants';
import ContractList from 'services/contract/ContractList';
import {CONTRACT_LIST_CACHE_ID, CONTRACT_LIST_EVENT} from 'services/contract/ContractListConstants';
import {
  getDirectContract,
  hasCancelledDirectContract,
  hasDirectContract,
} from 'services/contract/contractListUtils/contractDirectUtils';
import {
  getEnterpriseContract,
  getTeamToEnterpriseMigrationEligibleContract,
  hasEnterpriseContract,
} from 'services/contract/contractListUtils/contractEnterpriseUtils';
import {
  getIndirectContract,
  getIndirectContractInRenewalWindow,
  hasIndirectContract,
} from 'services/contract/contractListUtils/contractIndirectUtils';
import {
  canAddProducts,
  getContractsForProduct,
  getDirectOrIndirectContract,
  getDirectOrIndirectContractId,
  getEarliestAnniversaryDate,
  getEarliestStartDate,
  getFirstContractForProduct,
  getOfferSwitchMigrationEligibleContract,
  hasAllocationContract,
  hasAnyOfferSwitchMigrationContracts,
  hasAnyTrialOrAllocationContracts,
  hasContractInRenewalWindow,
  hasOnlyTrialOrAllocationContracts,
  hasTeamContract,
  hasTermsToAccept,
  hasTermsToReAccept,
  isContractOwner,
  isMigrating,
  isOrderProcessing,
  mustAcceptTerms,
} from 'services/contract/contractListUtils/contractListUtils';
import {
  getTrialContract,
  hasAnyTrialContracts,
  hasOnlyTrialContracts,
  hasTrialContractForId,
  isMigratingFromBusinessTrialsToPaid,
} from 'services/contract/contractListUtils/contractTrialUtils';
import {
  getLapsedVIPSubscriptionContract,
  getLapsedVIPSubscriptionPrevAnniversaryDate,
  getLapsedVIPSubscriptionResellerName,
  getNewVipContract,
  hasLapsedVIPSubscription,
  hasVIPMPContract,
} from 'services/contract/contractListUtils/contractVIPSubscriptionUtils';
import CountryList from 'services/country/CountryList';
import {FORMAT, MILLISECONDS_TO_END_DAY, MINIMUM} from 'services/dateTime/DateTimeConstants';
import {
  DIRECTORY_DOMAIN_ENFORCEMENT_STATUS,
  DIRECTORY_ENCRYPTION_PROGRESS,
  DIRECTORY_ENCRYPTION_STATUS,
  DIRECTORY_EVENT,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  IDP_STATUS,
  IDP_TYPES,
} from 'services/directory/directoryConstants';
import DomainList from 'services/domain/DomainList';
import {DOMAIN_LIST_CACHE_ID, DOMAIN_LIST_EVENT} from 'services/domain/domainListConstants';
import {
  getActiveAndInactiveDataPoints,
  getAnnualDataPoints,
  getInitialContractInfoList,
  getInitialData,
  getInitialDataForContractId,
  getMonthlyDataPoints,
} from 'services/etlaUsageReport/foxManagerUtils';
import {
  disableEventBridge,
  enableEventBridge,
  eventBridgeDecorator,
} from 'services/events/eventBridge';
import eventBus, {EVENT_SOURCES} from 'services/events/eventBus';
import feature from 'services/feature';
import FeaturesCache from 'services/featuresCache/FeaturesCache';
import fraudMitigation from 'services/fraudMitigation/fraudMitigation';
import FulfillmentEventList from 'services/fulfillmentEvent/FulfillmentEventList';
import {
  FULFILLMENT_EVENT_LIST_CONTEXT,
  FULFILLMENT_EVENT_LIST_EVENT,
  IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT,
  LICENSE_FULFILLMENT_EVENT_LIST_EVENT,
} from 'services/fulfillmentEvent/FulfillmentEventListConstants';
import fulfillmentEventRefreshManager from 'services/fulfillmentEvent/fulfillmentEventRefreshManager/fulfillmentEventRefreshManager';
import {FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS} from 'services/fulfillmentEvent/fulfillmentEventRefreshManager/fulfillmentEventRefreshManagerConstants';
import GlobalAdminPolicy from 'services/globalAdmin/GlobalAdminPolicy';
import GlobalAdminPolicyList from 'services/globalAdmin/GlobalAdminPolicyList';
import {FILTER_TYPE} from 'services/insights/InsightsConstants';
import InsightsItemDetailList from 'services/insights/InsightsItemDetailList';
import {
  getDefaultQueryParams,
  getDefaultSelectedItem,
  getMaxDate,
  getMaxISODate,
  getMinDate,
  getMinISODate,
} from 'services/insights/insightsUtils';
import InvoiceList from 'services/invoices/InvoiceList';
import Locale, {localeUtils} from 'services/locale/Locale';
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_BCP47_MAP,
  LANGUAGE_COUNTRY_MAP,
  SELECTED_LOCALE_KEY,
  SUPPORTED_LANGUAGES,
  SURNAME_FIRST_LOCALES,
} from 'services/locale/LocaleConstants';
import log from 'services/log';
import MemberList from 'services/member/MemberList';
import {saveMemberProductRoles} from 'services/member/memberProductRolesHelper';
import MigrationList from 'services/migration/MigrationList';
import {MIGRATION_LIST_CACHE_ID} from 'services/migration/migrationListConstants';
import {
  isMigrationListESMType1,
  isMigrationListT2eClean,
} from 'services/migration/migrationListUtils';
import JilModelList from 'services/modelList/JilModelList';
import ModelList from 'services/modelList/ModelList';
import {
  LIST_DEFAULT_PAGE_SIZE,
  SEARCH_QUERY_MIN_LENGTH,
} from 'services/modelList/ModelListConstants';
import PaginatedModelList from 'services/modelList/PaginatedModelList';
import navBus from 'services/navBus';
import {OBSERVABILITY_BUMPER_FEATURE_FLAG} from 'services/observability/observability.constants';
import observabilityMetrics from 'services/observability/observabilityMetrics';
import ObservabilityRoute from 'services/observability/route/ObservabilityRoute';
import observabilityRoutes from 'services/observability/route/observabilityRoutes';
import {
  doPricesIncludeTax,
  filterOnCanCreatePurchaseAuthorizationUtil,
  filterOnQualifyingFreeOffersUtil,
  getCopyName,
  getMarketSubsegmentsForOffers,
  getOfferIds,
  getOffersForBillableItems,
  getOffersForBillableItemsAndProducts,
  getOffersForProducts,
  getOffersForSegment,
  getOffersWithoutProducts,
  getOffersWithoutRenewalOrderOrProducts,
  getPoresOrder,
  sortOffersByFunctionOutput,
} from 'services/offerList/offerListUtils';
import Organization from 'services/organization/Organization';
import {
  DELEGATION_GROUP_MIGRATION_STATUS,
  IMS_ID_REGEX,
  ORGANIZATION_DELETE_ERROR_CODE,
  ORGANIZATION_EVENT,
  ORGANIZATION_GET_ERROR_CODE,
  ORGANIZATION_LIST,
  ORGANIZATION_MARKET_SEGMENT,
  ORGANIZATION_MARKET_SUBSEGMENT,
  ORGANIZATION_MIGRATION_STATUS,
  ORGANIZATION_SEARCH_TYPE,
  ORGANIZATION_TYPE,
} from 'services/organization/OrganizationConstants';
import OrganizationList from 'services/organization/OrganizationList';
import OrganizationLocale from 'services/organization/OrganizationLocale';
import OrganizationUserList from 'services/organization/OrganizationUserList';
import OrganizationEncryption from 'services/organizationEncryption/OrganizationEncryption';
import PasswordPolicy from 'services/passwordPolicy/PasswordPolicy';
import {LEVEL} from 'services/passwordPolicy/PasswordPolicyConstants';
import price from 'services/price';
import PrimaryProductDescriptor from 'services/product/PrimaryProductDescriptor';
import Product from 'services/product/Product';
import {
  LICENSE_STATUS,
  LICENSE_TYPE,
  PRODUCT_APPLICABLE_OFFER_TYPE,
  PRODUCT_BUYING_PROGRAM,
  PRODUCT_CACHE_ID,
  PRODUCT_CHANNELS,
  PRODUCT_CODE,
  PRODUCT_COMPLIANCE_SYMPTOM,
  PRODUCT_CONFIGURATION_SETTING_TYPE,
  PRODUCT_DELEGATION_TARGET,
  PRODUCT_FAMILY,
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
  PRODUCT_LICENSE_ALLOWLIST,
  PRODUCT_METADATA_CACHE_ID,
  PRODUCT_ROLE,
} from 'services/product/ProductConstants';
import ProductDescriptor from 'services/product/ProductDescriptor';
import ProductList from 'services/product/ProductList';
import {
  FULFILLMENT_TEST_TYPE,
  PRODUCT_LIST_CACHE_ID,
  PRODUCT_LIST_EVENT,
  PRODUCT_LIST_PI_CODE,
} from 'services/product/ProductListConstants';
import ProductListDescriptor from 'services/product/ProductListDescriptor';
import ProductAdminList from 'services/product/admin-list/ProductAdminList';
import {PRODUCT_ADMIN_LIST_EVENT} from 'services/product/admin-list/ProductAdminListConstants';
import LicenseGroup from 'services/product/license-group/LicenseGroup';
import {
  LICENSE_GROUP_LIST_CACHE_ID,
  LICENSE_GROUP_QUOTA_UNLIMITED,
  PRODUCT_CONFIGURATIONS_EVENT,
  PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE,
  PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT,
} from 'services/product/license-group/LicenseGroupConstants';
import LicenseGroupList from 'services/product/license-group/LicenseGroupList';
import LicenseGroupAdminList from 'services/product/license-group/admin-list/LicenseGroupAdminList';
import {LICENSE_GROUP_ADMIN_LIST_EVENT} from 'services/product/license-group/admin-list/LicenseGroupAdminListConstants';
import {LICENSE_GROUP_INTEGRATIONS_LIST_EVENT} from 'services/product/license-group/integrations-list/LicenseGroupIntegrationsListConstants';
import {toProductArray} from 'services/product/license-group/licenseGroupUtils';
import MemberConfigurationRoles from 'services/product/license-group/member-configuration/MemberConfigurationRoles';
import LicenseGroupUserList from 'services/product/license-group/user-list/LicenseGroupUserList';
import {LICENSE_GROUP_USER_LIST_EVENT} from 'services/product/license-group/user-list/LicenseGroupUserListConstants';
import ProductNoAccessUserList from 'services/product/no-access-user-list/ProductNoAccessUserList';
import {
  constructProductListDescriptor,
  formatProductPricingForAnalytics,
  getAnalyticsPromoOfferInteraction,
} from 'services/product/productListAnalyticsUtils';
import {getProductListWithLicenseGroupSummariesIfSafe} from 'services/product/productListGetUtils';
import {
  everyProductAndSomeFulfillableItemCondition,
  findFulfillableItem,
  getAssignableLicenseCount,
  getClosestLicenseEndDateForStatus,
  getClosestLicenseQuantityEndDateWhen,
  getDeveloperProducts,
  getProductsAssignableToUsers,
  getProductsByOfferIds,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  getStorageOnlyProducts,
  getTotalProvisionedQuantity,
  getUnassignedProducts,
  hasAdministrable,
  hasAllFulfillableItems,
  hasAssignableLicenses,
  hasConsumableProducts,
  hasEnterpriseProducts,
  hasGroupConsumableProducts,
  hasIndirectProducts,
  hasLegacyDeviceLicenses,
  hasOffers,
  hasOnlyTeamProducts,
  hasOrganizationConsumableProducts,
  hasPackageSupport,
  hasProductSupportRoleAssignmentAllowed,
  hasProductsInCloud,
  hasSDLProducts,
  hasSomeFulfillableItems,
  hasSomeSelectedFulfillableItems,
  hasStockProducts,
  hasSupportCaseCreationAllowed,
  hasSupportRoleAssignmentAllowed,
  hasTeamProducts,
  hasWorkspaces,
  someByFulfillableItemCondition,
} from 'services/product/productListUtils';
import SolutionGroupList from 'services/product/solution-group-list/SolutionGroupList';
import {
  PRODUCT_USER_LIST_CACHE_ID,
  PRODUCT_USER_LIST_EVENT,
} from 'services/product/user-list/ProductUserListConstants';
import {
  isProductValid,
  isRequestorExternalInfoItemValid,
} from 'services/product/validator/productValidatorUtils';
import SearchMemberList from 'services/search/SearchMemberList';
import {
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
} from 'services/search/SearchMemberListConstants';
import {
  ACTIONBLOCK_ID,
  BANNER_RESPONSE_KEYS,
  CAMPAIGN_ID,
  CARD_ID,
  CLICKABLE_CARD_LABELS,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  HVA_KEYS,
  INTERACTION,
  SOPHIA_EVENT_NAME,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from 'services/sophia/SophiaConstants';
import SophiaDescriptor from 'services/sophia/SophiaDescriptor';
import StockQuantitiesList from 'services/stock/StockQuantitiesList';
import {
  ACCESS_REQUESTS_EVENT,
  ACTIVE_TRUST_LIST_CACHE_ID,
  DIRECTORY_TRUSTEE_EVENT,
  DIRECTORY_TRUSTEE_LIST_EVENT,
  PENDING_TRUST_LIST_CACHE_ID,
  TRUSTEE_EVENT,
  TRUSTEE_LIST_EVENT,
  TRUST_CACHE_ID,
  TRUST_EVENT,
  TRUST_LIST_CACHE_ID,
  TRUST_STATUS,
} from 'services/trust/trustConstants';
import UiEventDescriptor from 'services/uiEvent/UiEventDescriptor';
import OrganizationUserListCount from 'services/users/OrganizationUserListCount';
import LinkedUserAccountList from 'services/users/linkedAccounts/LinkedUserAccountList';
import {LINKED_USER_ACCOUNT_LIST_FILTER} from 'services/users/linkedAccounts/LinkedUserAccountListConstants';
import UserMemberships from 'services/users/membership/UserMemberships';
import UserGroup from 'services/users/user-group/UserGroup';
import {
  USER_GROUP_EVENT,
  USER_GROUP_THRESHOLD_GO_URL,
  USER_GROUP_USER_COUNT_THRESHOLD,
} from 'services/users/user-group/UserGroupConstants';
import UserGroupList from 'services/users/user-group/UserGroupList';
import UserGroupShareInfo from 'services/users/user-group/UserGroupShareInfo';
import {USER_GROUP_LICENSE_GROUPS_EVENT} from 'services/users/user-group/license-group-list/UserGroupLicenseGroupsConstants';
import UserGroupLicenseGroupsList from 'services/users/user-group/license-group-list/UserGroupLicenseGroupsList';
import {constructAsyncAddAssignUserAnalytics} from 'utils/analyticsContextUtils';
import {getListCacheKey} from 'utils/cacheUtils';
import {
  INCLUSIVITY,
  compareDates,
  getDate,
  getDaysLeft,
  getHoursFromNow,
  isBetween,
} from 'utils/dateUtils';
import {htmlDecode, loadCss, loadEl, loadJs} from 'utils/domUtils';
import {download, downloadExportData, fetchAndDownloadExportData} from 'utils/downloadUtils';
import {looksLikeAnEmail} from 'utils/emailUtils';
import {
  compareArrays,
  convertObjectKeysToCamelCase,
  convertObjectKeysToKebabCase,
  flattenKeys,
  splitList,
  translateJson,
} from 'utils/jsUtils';
import {keysToCamelCase, keysToSnakeCase} from 'utils/objectUtils';
import {
  toBinkyProduct,
  toBinkyProductList,
  toPandoraProduct,
  toPandoraProductList,
} from 'utils/pandoraUtils';
import {
  clearStoredKeys,
  getPersistentData,
  reloadWithPersistentData,
  restoreDataToQueryParams,
  storePersistentData,
} from 'utils/persistentQueryParamDataUtils';
import {enableReactLogFilter} from 'utils/reactLogFilter';
import {
  getLocalStorageItem,
  getSessionStorageItem,
  removeLocalStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
} from 'utils/storageUtils';
import {getUrlSearchParams, getValueForQueryParam, hasDomain} from 'utils/urlUtils';
import {findAnyUserAccount, getUserAccount} from 'utils/userAccountUtils';

import {configure, localeReady, run} from './core/ready';

/**
 * This module provides the API used by AngularJS code to interface with src2.
 */
const binkySrc2 = {
  api: {
    adobeStatusEvents,
    aosOffers,
    apiPerformanceInterceptor,
    ccs,
    clypeUsers,
    collaborationPolicyService,
    etlaUsageReport,
    floodgate,
    ims,
    jil: {
      JIL_CONSTANTS,
      jilAdmins,
      jilApiUtils: {
        getHeaders,
      },
      jilApplicationIntegrations,
      jilAuthenticationPolicies,
      jilConsumables,
      jilContacts,
      jilContracts,
      jilDirectories,
      jilDomains,
      jilFulfillmentEvents,
      jilGlobalAdminPolicies,
      jilInvoices,
      jilOffers, // @deprecated - moved to aosOffers
      jilOrganizationLocale,
      jilOrganizations,
      jilOrganizationsCart,
      jilOrganizationsContractOffers,
      jilOrganizationsMigrations,
      jilOrganizationsOffers,
      jilOrganizationsProductsChange,
      jilOrganizationsProductsLicenseGroups,
      jilOrganizationsSummaries,
      jilPasswordPolicy,
      jilProductArrangements,
      jilProducts,
      jilProductSolutionGroups,
      jilSearch,
      jilUserGroups,
      jilUsers,
    },
    kmi,
    orders,
    policy: {
      POLICY_CONSTANTS: {
        GLOBAL_ADMIN_POLICY,
        INVITATION_DOMAIN_SHARING,
        POLICY,
      },
    },
    pores: {
      poresApiUtils: {
        getPoresHeaders,
      },
      poresOrganizationsOffers,
    },
    statusEvents,
    stock,
    utils: {csvBlobTransformer},
  },
  models: {
    availableType: {
      AvailableType,
      AvailableTypeUtils: {
        hasRecommendedSuffix,
        shouldShowRecommendedSuffix,
      },
    },
    cache: {
      modelCache,
    },
    complianceSymptom: {
      ComplianceSymptom,
    },
    complianceSymptomUtils: {
      contract: {
        getExpirationPhaseFromComplianceSymptoms: getExpirationPhaseFromContractComplianceSymptoms,
      },
      license: {
        getExpirationPhaseForAllLicenseTuples,
        getExpirationPhaseFromComplianceSymptoms: getExpirationPhaseFromLicenseComplianceSymptoms,
      },
    },
    contact: {
      Contact,
    },
    contract: {
      Contract,
      CONTRACT_CONSTANTS: {
        CONTRACT_AUTORENEWAL_MODE,
        CONTRACT_BILLING_FREQUENCY,
        CONTRACT_BUYING_PROGRAM,
        CONTRACT_COMPLIANCE_SYMPTOMS,
        CONTRACT_DX_CORE_SERVICES_DEAL_ID,
        CONTRACT_EVENT,
        CONTRACT_EXPIRATION_GRACE_UNIT,
        CONTRACT_EXPIRATION_PHASE,
        CONTRACT_GRACE_END_DATE_OFFSET,
        CONTRACT_LEGACY_DX_ID,
        CONTRACT_MODEL,
        CONTRACT_PATCH_API_KEY_LOOKUP_TABLE,
        CONTRACT_PAYMENT_CATEGORY,
        CONTRACT_STATUS,
      },
      CONTRACT_MIGRATION_CONSTANTS: {CONTRACT_MIGRATION_STATUS, CONTRACT_MIGRATION_TYPE},
      ContractTerms,
      contractUtils: {
        getCustomerSegmentFromBuyingProgram,
        getSalesChannelFromBuyingProgram,
      },
    },
    country: {
      Country,
      COUNTRY_CONSTANTS: {
        COUNTRY_LIST_CACHE_ID,
        COUNTRY_TO_LOCALE_MAP,
        DEFAULT_COUNTRY_LOCALE,
        REGION_LIST_CACHE_ID,
      },
    },
    directory: {
      DirectorySamlConfig,
      DirectoryUserList,
    },
    domain: {
      Domain,
      DOMAIN_CONSTANTS: {
        DOMAIN_STATUS,
      },
      DomainListMultiStatusResponse,
    },
    fulfillableItems: {
      FULFILLABLE_ITEM_CONSTANTS: {
        FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
        FULFILLABLE_ITEM_CHARGING_MODEL_TYPE,
        FULFILLABLE_ITEM_CHARGING_UNIT,
        FULFILLABLE_ITEM_CODE,
        FULFILLABLE_ITEM_DELEGATION_TYPE,
        FULFILLABLE_ITEM_TYPE,
      },
      FulfillableItemList,
    },
    fulfillmentEvent: {
      FULFILLMENT_EVENT_CONSTANTS: {
        FULFILLMENT_EVENT_STATUS,
        FULFILLMENT_NEEDED_TYPE,
      },
      FulfillmentEvent,
    },
    invoice: {
      Invoice,
    },
    licenseAllocations: {
      LICENSE_ALLOCATION_CONSTANTS: {
        LICENSE_ALLOCATION_RESOURCE_TYPE,
      },
      LicenseAllocationInfo,
      LicenseAllocationResourceList,
    },
    licenseQuantityList: {
      LICENSE_QUANTITY_LIST_CONSTANTS: {
        LICENSE_QUANTITY_RENEWAL_STATUS,
        LICENSE_QUANTITY_STATUS,
        LICENSE_QUANTITY_TAGS,
        LICENSE_QUANTITY_UNLIMITED,
      },
      LicenseQuantityList,
    },
    listState: {
      AlphaListState,
      HybridListState,
      ListState,
    },
    member: {
      Member,
      MEMBER_CONSTANTS: {
        MEMBER_EVENT,
        MODEL_CACHE_ID,
      },
      MEMBER_TYPE_CONSTANTS: {
        MEMBER_TECH_ACCOUNT_DOMAIN,
        MEMBER_TYPE,
      },
      MemberType,
    },
    migration: {
      Migration,
      MIGRATION_CONSTANTS: {
        MIGRATION_STATUS,
        MIGRATION_TYPE,
      },
      OptionsPerStatusMigration,
      T2EMigration,
    },
    offers: {
      Offer,
      OFFER_CONSTANTS: {
        CLOUD,
        COMMITMENT,
        CUSTOMER_SEGMENT,
        DELEGATION_TYPE,
        PAYMENT_STRUCTURE,
        PRICE_POINT,
        PROCESSING_INSTRUCTION,
        QUALIFIER_TARGET,
        SALES_CHANNEL,
        TYPE,
        VIRTUAL_LICENSE_SELECTION_ARL_PREFIX,
      },
      OFFER_LIST_CONSTANTS: {
        OFFER_LIST_FILTER,
        OFFER_LIST_INTENT,
        OFFER_LIST_MODE,
        OFFER_LIST_SERVICE_PROVIDER,
      },
      OfferList,
      PoresOfferList,
    },
    orders: {
      ORDER_CONSTANTS: {
        AUTH_ERROR,
        JEM_TIMEOUT_ERROR,
        MARKET_SEGMENT,
        ORDER_STATUS,
        ORDER_TYPES,
        PURCHASE_FLOW,
      },
    },
    organization: {
      ORGANIZATION_CONSTANTS: {
        DELEGATION_GROUP_MIGRATION_STATUS,
        IMS_ID_REGEX,
        ORGANIZATION_DELETE_ERROR_CODE,
        ORGANIZATION_EVENT,
        ORGANIZATION_GET_ERROR_CODE,
        ORGANIZATION_LIST,
        ORGANIZATION_MARKET_SEGMENT,
        ORGANIZATION_MARKET_SUBSEGMENT,
        ORGANIZATION_MIGRATION_STATUS,
        ORGANIZATION_TYPE,
      }, // @deprecated - moved to services.organization.ORGANIZATION_CONSTANTS
      OrganizationUser,
      OrganizationUserUtils: {
        compareProductLicenseGroups,
        compareUserGroups,
        explodeProductLicenseGroups,
        getProductLicensePatches,
        getUserGroupPatches,
      },
    },
    pageContext: {
      PAGE_CONTEXT_CONSTANTS: {PAGE_TARGET, PAGE_TARGET_TYPE},
      PageContext,
    },
    product: {
      consumable: {
        Consumable,
        CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
        CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
        CONSUMABLE_THRESHOLD_STATUS,
        ConsumableList,
        ConsumableSummarization,
        ConsumableSummarizationList,
        ConsumableSummary,
        ConsumableSummaryList,
        ConsumableUsage,
        ConsumableUsageList,
      },
      GRANT_QUANTITY_CONSTANTS: {
        MAX_FI_GRANT_QUANTITY,
        MAX_LICENSE_GRANT_QUANTITY,
      },
      PRODUCT_CONSTANTS: {
        LICENSE_STATUS,
        LICENSE_TYPE,
        PRODUCT_APPLICABLE_OFFER_TYPE,
        PRODUCT_BUYING_PROGRAM,
        PRODUCT_CACHE_ID,
        PRODUCT_CHANNELS,
        PRODUCT_CODE,
        PRODUCT_COMPLIANCE_SYMPTOM,
        PRODUCT_CONFIGURATION_SETTING_TYPE,
        PRODUCT_DELEGATION_TARGET,
        PRODUCT_FAMILY,
        PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
        PRODUCT_LICENSE_ALLOWLIST,
        PRODUCT_METADATA_CACHE_ID,
        PRODUCT_ROLE,
      }, // @deprecated - moved to services.product.PRODUCT_CONSTANTS
      PRODUCT_LIST_CONSTANTS: {
        FULFILLMENT_TEST_TYPE,
        PRODUCT_LIST_CACHE_ID,
        PRODUCT_LIST_EVENT,
        PRODUCT_LIST_PI_CODE,
      }, // @deprecated - moved to services.product.PRODUCT_LIST_CONSTANTS
    },
    stock: {
      StockQuantity,
    },
    user: {
      AuthenticatedUser,
      LinkedUserAccount,
      User,
      USER_CONSTANTS: {
        EMAIL_MAX_LENGTH,
        IMS_USER_ID_REGEX,
      },
      USER_ROLES_CONSTANTS: {
        ADOBE_AGENT,
        COMPARTMENT_ADMIN,
        COMPARTMENT_VIEWER,
        CONTRACT_ADMIN,
        DEPLOYMENT_ADMIN,
        LICENSE_ADMIN,
        LICENSE_DEV_ADMIN,
        ORG_ADMIN,
        PRODUCT_ADMIN,
        PRODUCT_SUPPORT_ADMIN,
        STORAGE_ADMIN,
        SUPPORT_ADMIN,
        SUPPORT_ADMINS,
        USER_GROUP_ADMIN,
      },
      UserAvatar,
      UserRoles,
      UserRoleUtils: {
        compareAdminRoles,
        explodeAdminRoles,
        getAdminRolePatches,
      },
    },
  },
  ready: {
    configure,
    localeReady,
    run,
  },
  services: {
    acquisitionSummary: {
      ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY,
      AcquisitionSummaryList,
    },
    adobeStatus: {
      ADOBE_STATUS_CONSTANTS: {
        POLL_FREQUENCY,
        RECENT_HISTORY_THRESHOLD,
      },
      ADOBE_STATUS_MESSAGES_CONSTANTS: {
        ERROR,
        INVALIDATE,
        REFRESH,
      },
      AdobeStatusData,
    },
    analytics: {
      ANALYTICS_CONSTANTS: {
        ANALYTICS_EVENT,
        EVENT_ACTION,
        EVENT_NAME,
      },
      AnalyticsEvent,
      AnalyticsPageDescriptor,
      analyticsUtils: {
        dispatchPageAnalytics,
        dispatchUiEventAnalytics,
        formatAnalyticsDate,
        getFloodgateVariantData,
      },
      CancellationOrderDescriptor,
      ErrorDescriptor,
      GainsightEventDescriptor,
      OrganizationInfoDescriptor,
    },
    authentication,
    AUTHENTICATION_ERROR,
    availableType: {
      AvailableTypeList,
    },
    cart: {
      CartDescriptor,
    },
    collaborationPolicies: {
      CollaborationPolicies,
      RESTRICTION_STATUS,
    },
    configStore,
    contact: {
      ContactList,
      ContactListConstants: {
        CONTACT_LIST_CACHE_ID,
        CONTACT_LIST_EVENT,
        CONTACT_TYPE,
      },
    },
    contract: {
      ContractList,
      ContractListConstants: {
        CONTRACT_LIST_CACHE_ID,
        CONTRACT_LIST_EVENT,
      },
      ContractListUtils: {
        canAddProducts,
        getContractsForProduct,
        getDirectContract,
        getDirectOrIndirectContract,
        getDirectOrIndirectContractId,
        getEarliestAnniversaryDate,
        getEarliestStartDate,
        getEnterpriseContract,
        getFirstContractForProduct,
        getIndirectContract,
        getIndirectContractInRenewalWindow,
        getLapsedVIPSubscriptionContract,
        getLapsedVIPSubscriptionPrevAnniversaryDate,
        getLapsedVIPSubscriptionResellerName,
        getNewVipContract,
        getOfferSwitchMigrationEligibleContract,
        getTeamToEnterpriseMigrationEligibleContract,
        getTrialContract,
        hasAllocationContract,
        hasAnyOfferSwitchMigrationContracts,
        hasAnyTrialContracts,
        hasAnyTrialOrAllocationContracts,
        hasCancelledDirectContract,
        hasContractInRenewalWindow,
        hasDirectContract,
        hasEnterpriseContract,
        hasIndirectContract,
        hasLapsedVIPSubscription,
        hasOnlyTrialContracts,
        hasOnlyTrialOrAllocationContracts,
        hasTeamContract,
        hasTermsToAccept,
        hasTermsToReAccept,
        hasTrialContractForId,
        hasVIPMPContract,
        isContractOwner,
        isMigrating,
        isMigratingFromBusinessTrialsToPaid,
        isOrderProcessing,
        mustAcceptTerms,
      },
      ContractTerms,
    },
    country: {
      CountryList,
    },
    dateTime: {
      DATE_TIME_CONSTANTS: {
        FORMAT,
        MILLISECONDS_TO_END_DAY,
        MINIMUM,
      },
    },
    directory: {
      DIRECTORY_CONSTANTS: {
        DIRECTORY_DOMAIN_ENFORCEMENT_STATUS,
        DIRECTORY_ENCRYPTION_PROGRESS,
        DIRECTORY_ENCRYPTION_STATUS,
        DIRECTORY_EVENT,
        DIRECTORY_OWNERSHIP_STATUS,
        DIRECTORY_STATUS,
        DIRECTORY_TYPE,
        IDP_STATUS,
        IDP_TYPES,
      },
    },
    domain: {
      DOMAIN_LIST_CONSTANTS: {
        DOMAIN_LIST_CACHE_ID,
        DOMAIN_LIST_EVENT,
      },
      DomainList,
    },
    etlaUsageReport: {
      foxManagerUtils: {
        getActiveAndInactiveDataPoints,
        getAnnualDataPoints,
        getInitialContractInfoList,
        getInitialData,
        getInitialDataForContractId,
        getMonthlyDataPoints,
      },
    },
    events: {
      disableEventBridge,
      enableEventBridge,
      EVENT_SOURCES,
      eventBridgeDecorator,
      eventBus,
    },
    feature,
    FeaturesCache,
    fraudMitigation,
    fulfillmentEvent: {
      FULFILLMENT_EVENT_LIST_CONSTANTS: {
        FULFILLMENT_EVENT_LIST_CONTEXT,
        FULFILLMENT_EVENT_LIST_EVENT,
        IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT,
        LICENSE_FULFILLMENT_EVENT_LIST_EVENT,
      },
      FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS,
      FulfillmentEventList,
      fulfillmentEventRefreshManager,
    },
    globalAdmin: {
      GlobalAdminPolicy,
      GlobalAdminPolicyList,
    },
    insights: {
      INSIGHTS_CONSTANTS: {
        FILTER_TYPE,
      },
      InsightsItemDetailList,
      insightsUtils: {
        getDefaultQueryParams,
        getDefaultSelectedItem,
        getMaxDate,
        getMaxISODate,
        getMinDate,
        getMinISODate,
      },
    },
    invoices: {
      InvoiceList,
    },
    Locale,
    LOCALE_CONSTANTS: {
      DEFAULT_LANGUAGE,
      LANGUAGE_BCP47_MAP,
      LANGUAGE_COUNTRY_MAP,
      SELECTED_LOCALE_KEY,
      SUPPORTED_LANGUAGES,
      SURNAME_FIRST_LOCALES,
    },
    localeUtils,
    log,
    member: {
      helpers: {
        saveMemberProductRoles,
      },
      MemberList,
    },
    migration: {
      MigrationList,
      migrationListConstants: {
        MIGRATION_LIST_CACHE_ID,
      },
      migrationListUtils: {
        isMigrationListESMType1,
        isMigrationListT2eClean,
      },
    },
    modelList: {
      JilModelList,
      MODEL_LIST_CONSTANTS: {LIST_DEFAULT_PAGE_SIZE, SEARCH_QUERY_MIN_LENGTH},
      ModelList,
      PaginatedModelList,
    },
    navBus,
    observability: {
      OBSERVABILITY_BUMPER_FEATURE_FLAG,
      observabilityMetrics,
      route: {
        ObservabilityRoute,
        observabilityRoutes,
      },
    },
    offers: {
      OfferListUtils: {
        doPricesIncludeTax,
        filterOnCanCreatePurchaseAuthorizationUtil,
        filterOnQualifyingFreeOffersUtil,
        getCopyName,
        getMarketSubsegmentsForOffers,
        getOfferIds,
        getOffersForBillableItems,
        getOffersForBillableItemsAndProducts,
        getOffersForProducts,
        getOffersForSegment,
        getOffersWithoutProducts,
        getOffersWithoutRenewalOrderOrProducts,
        getPoresOrder,
        sortOffersByFunctionOutput,
      },
    },
    organization: {
      Organization,
      ORGANIZATION_CONSTANTS: {
        DELEGATION_GROUP_MIGRATION_STATUS,
        IMS_ID_REGEX,
        ORGANIZATION_DELETE_ERROR_CODE,
        ORGANIZATION_EVENT,
        ORGANIZATION_GET_ERROR_CODE,
        ORGANIZATION_LIST,
        ORGANIZATION_MARKET_SEGMENT,
        ORGANIZATION_MARKET_SUBSEGMENT,
        ORGANIZATION_MIGRATION_STATUS,
        ORGANIZATION_SEARCH_TYPE,
        ORGANIZATION_TYPE,
      },
      OrganizationList,
      OrganizationLocale,
      OrganizationUser, // @deprecated - moved to models.organization.OrganizationUser
      OrganizationUserList,

      // @deprecated - moved to models.organization.OrganizationUserUtils
      OrganizationUserUtils: {
        compareProductLicenseGroups,
        compareUserGroups,
        explodeProductLicenseGroups,
        getProductLicensePatches,
        getUserGroupPatches,
      },
    },
    organizationEncryption: {
      OrganizationEncryption,
    },
    passwordPolicy: {
      PASSWORD_POLICY_CONSTANTS: {
        LEVEL,
      },
      PasswordPolicy,
    },
    price,
    product: {
      adminList: {
        PRODUCT_ADMIN_LIST_CONSTANTS: {
          PRODUCT_ADMIN_LIST_EVENT,
        },
        ProductAdminList,
      },
      licenseGroup: {
        adminList: {
          LICENSE_GROUP_ADMIN_LIST_CONSTANTS: {
            LICENSE_GROUP_ADMIN_LIST_EVENT,
          },
          LicenseGroupAdminList,
        },
        integrationsList: {
          LICENSE_GROUP_INTEGRATIONS_LIST_CONSTANTS: {LICENSE_GROUP_INTEGRATIONS_LIST_EVENT},
        },
        LICENSE_GROUP_CONSTANTS: {
          LICENSE_GROUP_LIST_CACHE_ID,
          LICENSE_GROUP_QUOTA_UNLIMITED,
          // eslint-disable-next-line id-length -- porting to src2. Should revisit and move string to the two usage files
          PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE,
          PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT,
          PRODUCT_CONFIGURATIONS_EVENT,
        },
        LicenseGroup,
        LicenseGroupList,
        licenseGroupUtils: {
          toProductArray,
        },
        memberConfiguration: {
          MemberConfigurationRoles,
        },
        userList: {
          LICENSE_GROUP_USER_LIST_CONSTANTS: {
            LICENSE_GROUP_USER_LIST_EVENT,
          },
          LicenseGroupUserList,
        },
      },
      PrimaryProductDescriptor,
      Product,
      PRODUCT_CONSTANTS: {
        LICENSE_STATUS,
        LICENSE_TYPE,
        PRODUCT_APPLICABLE_OFFER_TYPE,
        PRODUCT_BUYING_PROGRAM,
        PRODUCT_CACHE_ID,
        PRODUCT_CHANNELS,
        PRODUCT_CODE,
        PRODUCT_CONFIGURATION_SETTING_TYPE,
        PRODUCT_DELEGATION_TARGET,
        PRODUCT_FAMILY,
        PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
        PRODUCT_LICENSE_ALLOWLIST,
        PRODUCT_METADATA_CACHE_ID,
        PRODUCT_ROLE,
      },
      PRODUCT_LIST_CONSTANTS: {
        FULFILLMENT_TEST_TYPE,
        PRODUCT_LIST_CACHE_ID,
        PRODUCT_LIST_EVENT,
        PRODUCT_LIST_PI_CODE,
      },
      ProductDescriptor,
      ProductList,
      productListAnalyticsUtils: {
        constructProductListDescriptor,
        formatProductPricingForAnalytics,
        getAnalyticsPromoOfferInteraction,
      },
      ProductListDescriptor,
      productListGetUtils: {
        getProductListWithLicenseGroupSummariesIfSafe,
      },
      productListUtils: {
        everyProductAndSomeFulfillableItemCondition,
        findFulfillableItem,
        getAssignableLicenseCount,
        getClosestLicenseEndDateForStatus,
        getClosestLicenseQuantityEndDateWhen,
        getDeveloperProducts,
        getProductsAssignableToUsers,
        getProductsByOfferIds,
        getSeatBasedAssignableLicenseCount,
        getSeatBasedTotalProvisionedQuantity,
        getStorageOnlyProducts,
        getTotalProvisionedQuantity,
        getUnassignedProducts,
        hasAdministrable,
        hasAllFulfillableItems,
        hasAssignableLicenses,
        hasConsumableProducts,
        hasEnterpriseProducts,
        hasGroupConsumableProducts,
        hasIndirectProducts,
        hasLegacyDeviceLicenses,
        hasOffers,
        hasOnlyTeamProducts,
        hasOrganizationConsumableProducts,
        hasPackageSupport,
        hasProductsInCloud,
        hasProductSupportRoleAssignmentAllowed,
        hasSDLProducts,
        hasSomeFulfillableItems,
        hasSomeSelectedFulfillableItems,
        hasStockProducts,
        hasSupportCaseCreationAllowed,
        hasSupportRoleAssignmentAllowed,
        hasTeamProducts,
        hasWorkspaces,
        someByFulfillableItemCondition,
      },
      ProductNoAccessUserList,
      solutionGroupList: {
        SolutionGroupList,
      },
      userList: {
        PRODUCT_USER_LIST_CONSTANTS: {PRODUCT_USER_LIST_CACHE_ID, PRODUCT_USER_LIST_EVENT},
      },
      validator: {
        productValidatorUtils: {
          isProductValid,
          isRequestorExternalInfoItemValid,
        },
      },
    },
    search: {
      SEARCH_MEMBER_LIST_CONSTANTS: {
        SEARCH_TARGET_CLASS,
        SEARCH_TARGET_RESULT_TYPE,
      },
      SearchMemberList,
    },
    sophia: {
      SOPHIA_CONSTANTS: {
        ACTIONBLOCK_ID,
        BANNER_RESPONSE_KEYS,
        CAMPAIGN_ID,
        CARD_ID,
        CLICKABLE_CARD_LABELS,
        CONTAINER_ID,
        CONTROL_GROUP_ID,
        HVA_KEYS,
        INTERACTION,
        SOPHIA_EVENT_NAME,
        SURFACE_ID,
        TREATMENT_ID,
        VARIATION_ID,
      },
      SophiaDescriptor,
    },
    stock: {
      StockQuantitiesList,
    },
    trust: {
      TRUST_CONSTANTS: {
        ACCESS_REQUESTS_EVENT,
        ACTIVE_TRUST_LIST_CACHE_ID,
        DIRECTORY_TRUSTEE_EVENT,
        DIRECTORY_TRUSTEE_LIST_EVENT,
        PENDING_TRUST_LIST_CACHE_ID,
        TRUST_CACHE_ID,
        TRUST_EVENT,
        TRUST_LIST_CACHE_ID,
        TRUST_STATUS,
        TRUSTEE_EVENT,
        TRUSTEE_LIST_EVENT,
      },
    },
    uiEvent: {UiEventDescriptor},
    users: {
      LINKED_USER_ACCOUNT_LIST_CONSTANTS: {
        LINKED_USER_ACCOUNT_LIST_FILTER,
      },
      LinkedUserAccountList,
      OrganizationUserListCount,
      USER_GROUP_CONSTANTS: {
        USER_GROUP_EVENT,
        USER_GROUP_THRESHOLD_GO_URL,
        USER_GROUP_USER_COUNT_THRESHOLD,
      },
      USER_GROUP_LICENSE_GROUPS_CONSTANTS: {
        USER_GROUP_LICENSE_GROUPS_EVENT,
      },
      UserGroup,
      UserGroupLicenseGroupsList,
      UserGroupList,
      UserGroupShareInfo,
      UserMemberships,
    },
  },
  utils: {
    analyticsContextUtils: {constructAsyncAddAssignUserAnalytics},
    authenticationUtils: {findAnyUserAccount, getUserAccount},
    cacheUtils: {getListCacheKey},
    dateUtils: {compareDates, getDate, getDaysLeft, getHoursFromNow, INCLUSIVITY, isBetween},
    domUtils: {htmlDecode, loadCss, loadEl, loadJs},
    downloadUtils: {download, downloadExportData, fetchAndDownloadExportData},
    emailUtils: {looksLikeAnEmail},
    enableReactLogFilter,
    jsUtils: {
      compareArrays,
      convertObjectKeysToCamelCase,
      convertObjectKeysToKebabCase,
      flattenKeys,
      splitList,
      translateJson,
    },
    objectUtils: {keysToCamelCase, keysToSnakeCase},
    pandoraUtils: {
      toBinkyProduct,
      toBinkyProductList,
      toPandoraProduct,
      toPandoraProductList,
    },
    persistentQueryParamDataUtils: {
      clearStoredKeys,
      getPersistentData,
      reloadWithPersistentData,
      restoreDataToQueryParams,
      storePersistentData,
    },
    storageUtils: {
      getLocalStorageItem,
      getSessionStorageItem,
      removeLocalStorageItem,
      removeSessionStorageItem,
      setLocalStorageItem,
      setSessionStorageItem,
    },
    urlUtils: {getUrlSearchParams, getValueForQueryParam, hasDomain},
  },
};
export default binkySrc2;
/* eslint-enable @admin-tribe/admin-tribe/include-in-index -- does not need index file */
/* eslint-enable max-lines -- imports */
