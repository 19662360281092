/**
 * @deprecated as of temp_sls_m_3, please use core/services/acquisition-summaries/AcquisitionSummaryConstants.js
 */
const LICENSE_QUANTITY_RENEWAL_STATUS = {
  COMPLETE: 'COMPLETE',
  NONE: 'NONE',
  NOT_RENEWAL: 'NOT_RENEWAL',
  PARTIAL: 'PARTIAL',
};
const LICENSE_QUANTITY_STATUS = {
  CANCELLING: 'CANCELLING',
  GRACE_PAST_DUE: 'GRACE_PAST_DUE',
  PAID: 'PAID',
  PAST_DUE: 'PAST_DUE',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
};
const LICENSE_QUANTITY_TAGS = {
  RENEWING: 'RENEWING',
};
const LICENSE_QUANTITY_UNLIMITED = 'UNLIMITED';

export {
  LICENSE_QUANTITY_RENEWAL_STATUS,
  LICENSE_QUANTITY_STATUS,
  LICENSE_QUANTITY_TAGS,
  LICENSE_QUANTITY_UNLIMITED,
};
