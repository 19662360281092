import {action, makeObservable, observable} from 'mobx';

import log from 'services/log';

const GENERIC_ERROR_CODE = Symbol('GENERIC_ERROR_CODE');

/**
 * A Store super class that helps with managing loading data.
 * It covers errors and loading state for a common approach to handling them.
 */
class Store {
  errorCode = null;
  hasLoadingError = false;
  isLoading = false;

  constructor() {
    makeObservable(this, {
      errorCode: observable,
      fetchDone: action.bound,
      fetchError: action.bound,
      fetchSuccess: action.bound,
      hasLoadingError: observable,
      isLoading: observable,
      load: action,
    });
  }

  /**
   * This method should be implemented by every store that extends this one.
   * It should implement the mechanism of loading data and return a promise.
   *
   * The load method is then used to retrieve data.
   */
  // eslint-disable-next-line class-methods-use-this -- this method should be implemented by other stores
  fetchData() {
    throw new Error('Unimplemented');
  }

  /**
   * Action called when data is done loading
   */
  fetchDone() {
    this.isLoading = false;
  }

  /**
   * Action called when data has an error loading
   *
   * @return {Promise<never>} Rejects the promise further for anyone to catch it.
   */
  fetchError() {
    this.hasLoadingError = true;
    this.errorCode = GENERIC_ERROR_CODE;
  }

  /**
   * Action called when data fetch is successful. This method resets the error
   * if we had one from a previous call.
   */
  fetchSuccess() {
    // clear any error we might have gotten previously
    this.errorCode = null;
    this.hasLoadingError = false;
  }

  /**
   * This method is used to get the data from the server.
   *
   * @return {Promise<any>} Data fetching promise
   */
  async load() {
    this.isLoading = true;

    let response;

    try {
      response = await this.fetchData();
      this.fetchSuccess();
    } catch (error) {
      log.debug(`Store load error: ${error}`);
      response = this.fetchError(error);
    } finally {
      this.fetchDone();
    }

    return response;
  }
}

export default Store;
export {GENERIC_ERROR_CODE};
