(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc       module
   * @name        fraudMitigationReadyProvider
   * @description App-ready module to initialize fraudMitigation.provider with configuration files.
   * Specs: https://wiki.corp.adobe.com/x/fowje
   */

  angular
    .module('binky.core.fraud-mitigation.ready')
    .provider('fraudMitigationReady', fraudMitigationReadyProvider);

  /* @ngInject */
  function fraudMitigationReadyProvider(_, configurationReadyProvider, fraudMitigationProvider) {
    this.$get = $get;
    this.configure = configure;

    ////////////

    function configure() {
      return configurationReadyProvider
        .whenConfigReady()
        .then(onConfigurationReadyProviderFulfilled);

      ////////////

      function onConfigurationReadyProviderFulfilled(configData) {
        fraudMitigationProvider.configure(_.get(configData, 'services.fraudMitigation'));
      }
    }

    /* @ngInject */
    function $get($q, fraudMitigation, featureReady) {
      const runDeferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        // Use 'finally' so the app doesn't stop loading when Sherlock is not available.
        return featureReady
          .whenRunReady()
          .then(fraudMitigation.initialize)
          .catch(_.noop) // Need to ignore the reject from initialize() so the app continues to run
          .finally(() => {
            runDeferred.resolve();
          });
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
