(function () {
  /**
   * @deprecated Please use src2/core/utils/storageUtils.js
   */
  angular.module('binky.core.storage-utils').factory('storageUtils', utils);

  /* @ngInject */
  function utils(binkySrc2) {
    return binkySrc2.utils.storageUtils;
  }
})();
