(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc directive
   * @name binky.widgets.common.indeterminate:binkyIndeterminate
   * @description Allow dynamically setting of coral-checkbox (or other elements with
   * the indeterminate attribute) indeterminate state.
   *
   * @example <coral-checkbox binky-indeterminate='isSubsetChecked'></coral-checkbox>
   */
  angular
    .module('binky.widgets.common.indeterminate')
    .directive('binkyIndeterminate', binkyIndeterminateDirective);

  function binkyIndeterminateDirective() {
    return {
      link(scope, elm, attrs) {
        // If indeterminate has a value we set this to allow CoralUI to pick it up
        scope.$watch(attrs.binkyIndeterminate, (val) => elm.prop('indeterminate', !!val));
      },
      restrict: 'A',
    };
  }
})();
