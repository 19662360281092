(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   */
  angular
    .module('binky.widgets.insights.insights-item-detail-list-table.status')
    .constant('REPORT_STATUS', {
      DOWNLOAD: 'Download',
      ERROR: 'Error',
      INPROGRESS: 'Inprogress',
      SUCCESS: 'Success',
    });
})();
