import mapValues from 'lodash/mapValues';

import {CONTACT_TYPE} from '../services/contact/ContactListConstants';

import {looksLikeAnEmail} from './emailUtils';

/**
 * @description Checks if the given contact list contains all valid contacts.
 *
 * @param {ContactList} ContactList - the contact list being checked.
 * @returns {Boolean} true if the given contact list has all valid contacts.
 */
function hasValidContacts(contactList) {
  const contactTypes = Object.values(CONTACT_TYPE);
  return contactTypes.every((contactType) => isValidContact(contactList[contactType]));
}

/**
 * @description Checks if the given contact has the same email, name, and phone number as the otherContact.
 *
 * @param {Contact} contact - the contact being compared.
 * @param {Contact} otherContact - the other contact being compared to.
 * @returns {Boolean} true if the given contact has the same values as the otherContact.
 */
function isContactEqual(contact, otherContact) {
  return (
    contact.email === otherContact.email &&
    contact.name === otherContact.name &&
    contact.phone === otherContact.phone
  );
}

/**
 * @description Checks if the given contact list has the same contacts as the otherContactList.
 *
 * @param {ContactList} contactList - the contact list being compared.
 * @param {ContactList} otherContactList - the other contact list being compared to.
 * @returns {Boolean} true if the given contact list has the same contacts as the otherContactList.
 */
function isContactListEqual(contactList, otherContactList) {
  const contactTypes = Object.values(CONTACT_TYPE);
  return contactTypes.every((contactType) =>
    isContactEqual(contactList[contactType], otherContactList[contactType])
  );
}

/**
 * @description Checks if the given contact is a valid contact meaning all of its values are valid.
 *
 * @param {Contact} contact - the contact being checked.
 * @returns {Boolean} true if the given contact is valid.
 */
function isValidContact(contact) {
  return (
    !contact.isInvalid && isValidContactEmail(contact.email) && isValidContactName(contact.name)
  );
}

/**
 * @description Checks if the given email is a valid contact email.
 *
 * @param {String} email - the email string being checked.
 * @returns {Boolean} true if the given email is valid and can be used for a contact.
 */
function isValidContactEmail(email) {
  return !email || looksLikeAnEmail(email);
}

/**
 * @description Checks if the given name is a valid contact name.
 *
 * @param {String} name - the name string being checked.
 * @returns {Boolean} true if the given name is valid and can be used for a contact.
 */
function isValidContactName(name) {
  const regex = /^[^/]+$/;
  return !name || regex.test(name);
}

/**
 * @description Trims off any whitespace for string values in the contact.
 *
 * @param {Object} contact - object containing contact info as strings.
 * @returns {Object} copy of the contact trimmed of any whitespace for its string values.
 */
function trimContact(contact) {
  return mapValues(contact, (value) => {
    if (typeof value !== 'string') {
      // Do not include isInvalid in trimmed contact or any other custom attributes
      return undefined;
    }
    const trimmedValue = value?.trim();
    // Contact API doesn't support empty string as input
    return trimmedValue === '' ? undefined : trimmedValue;
  });
}

export {
  hasValidContacts,
  isContactEqual,
  isContactListEqual,
  isValidContact,
  isValidContactEmail,
  isValidContactName,
  trimContact,
};
