import get from 'lodash/get';

import Migration from 'models/migration/Migration';
import {MIGRATION_TYPE} from 'models/migration/MigrationConstants';

// This migration type allows for setting up behavior overrides to allow an empty organization to
// be set up with domains, directories, and users, prior to a product being added. This allows for
// very little downtime for users during the migration process.
class OptionsPerStatusMigration extends Migration {
  static canTransform(rawData) {
    // Check for all types that use metadata.optionsPerStatus
    // Currently only available type is MA_LEGACY_TO_ADMIN_CONSOLE
    return rawData.type === MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE;
  }

  /**
   * @description Creates a new OptionsPerStatusMigration instance.
   *   This migration type has metadata describing the behavior changes desired
   *   during the various states of the migration. This allows for special migration
   *   setup states, as well as specifying when the organization is locked, etc.
   *
   * @param {Object} rawData the raw migration JSON as returned by the backend
   * @param {MIGRATION_STATUS} rawData.status the current status of the migration
   * @param {MIGRATION_TYPE} rawData.type the migration type
   * @param {Array} rawData.products the products the migration applies to
   * @param {Object} [rawData.metadata] - optional additional fields
   * @param {String} [rawData.metadata.scheduledStartDate] - ISO8601 date
   * @param {String} [rawData.metadata.completedDate] - ISO8601 date
   * @param {map} [rawData.metadata.optionsPerStatus] - a set of options and overrides for each
   *   status of this flag
   * @param {Object} [rawData.metadata.optionsPerStatus.statusValue] - the set of flags for the
   *   given status; statusValue key name should be one of the valid MIGRATION_STATUS values
   * @param  {Object} [rawData.metadata.optionsPerStatus.statusValue.actionAccessOverrides] - defines
   *  what actions should be overridden
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.actionAccessOverrides.allowAddAdmins] -
   *   if true, force-allow adding users to the org, regardless of the usual requirements. Undefined or
   *   false does not change existing behavior
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.actionAccessOverrides.allowAddUsers] -
   *   if true, force-allow adding users to the org, regardless of the usual requirements. Undefined or
   *   false does not change existing behavior
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.actionAccessOverrides.allowDomainClaiming] -
   *   if true, force-allow domain claiming, regardless of the usual requirements. Undefined or
   *   false does not change existing behavior
   * @param  {Object} [rawData.metadata.optionsPerStatus.statusValue.contractComplianceOverrides] -
   *   defines what contract compliance data should be overridden
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.contractComplianceOverrides.canCreatePa] -
   *   if false, will block PA creation. If undefined or true, we will use regular compliance data
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.contractComplianceOverrides.canDelegateUsers] -
   *   if false, will block user delegation. If undefined or true, we will use regular compliance data
   * @param  {Object} [rawData.metadata.optionsPerStatus.statusValue.gainsightData] - string of data to send to
   *   Gainsight for surfacing tutorials
   * @param  {Boolean} [rawData.metadata.optionsPerStatus.statusValue.isOrgLocked] - true if
   *   organization is locked
   */
  constructor(rawData) {
    super(rawData);
    this.products = rawData.products;
  }

  /**
   * @description Checks the metadata related to the current status to see if
   *  the migration flag has data to pass to Gainsight.
   *
   * @returns {String} data for Gainsight to use for showing tutorials, etc.
   */
  getGainsightData() {
    return get(this.getMetadataOptionsForCurrentStatus(), 'gainsightData', '');
  }

  /**
   * @description Extracts the metadata containing options for the current
   *  status of this migration.
   *
   * @returns {Object} the options for the current status.
   */
  getMetadataOptionsForCurrentStatus() {
    return get(this, `metadata.optionsPerStatus.${this.status}`, {});
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if the org is locked.
   *
   * @returns {Boolean} true if the org is locked for the current status.
   */
  isOrgLocked() {
    return get(this.getMetadataOptionsForCurrentStatus(), 'isOrgLocked', false);
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if adding admins is allowed, regardless of the usual requirements. Absence of
   *  the override value for the status means existing behavior should not be overridden.
   *
   * @returns {Boolean} true if adding admins is allowed for the current status.
   */
  shouldForceAllowAddAdmins() {
    return get(
      this.getMetadataOptionsForCurrentStatus(),
      'actionAccessOverrides.allowAddAdmins',
      false
    );
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if adding users is allowed, regardless of usual requirements. Absence of
   *  the override value for the status means existing behavior should not be overridden.
   *
   * @returns {Boolean} true if adding users is allowed for the current status.
   */
  shouldForceAllowAddUsers() {
    return get(
      this.getMetadataOptionsForCurrentStatus(),
      'actionAccessOverrides.allowAddUsers',
      false
    );
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if domain claiming is allowed, regardless of usual requirements. Absence of
   *  the override value for the status means existing behavior should not be overridden.
   *
   * @returns {Boolean} true if domain claiming is allowed for the current status.
   */
  shouldForceAllowDomainClaiming() {
    return get(
      this.getMetadataOptionsForCurrentStatus(),
      'actionAccessOverrides.allowDomainClaiming',
      false
    );
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if PA creation should be blocked. Absence of the override value for
   *  the status means existing behavior should not be overridden.
   *
   * @returns {Boolean} true if PA creation should be blocked for the current status.
   */
  shouldForceBlockPACreation() {
    // only block PA creation if the value was explicitly set to false
    return !get(
      this.getMetadataOptionsForCurrentStatus(),
      'contractComplianceOverrides.canCreatePa',
      true
    );
  }

  /**
   * @description Checks the metadata related to the current status to see
   *  if user delegation should be blocked. Absence of the override value
   *  for the status means existing behavior should not be overridden.
   *
   * @returns {Boolean} true if user delegation should be blocked for the current status.
   */
  shouldForceBlockUserDelegation() {
    // only block delegating users if the value was explicitly set to false
    return !get(
      this.getMetadataOptionsForCurrentStatus(),
      'contractComplianceOverrides.canDelegateUsers',
      true
    );
  }
}

export default OptionsPerStatusMigration;
