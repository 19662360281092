import {ACCOUNT_STATUS} from '@admin-tribe/binky';
import {StatusLight} from '@pandora/react-status-light';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ACCOUNT_STATUS_LIGHT = {
  [ACCOUNT_STATUS.ACTIVE]: {
    label: 'binky.common.accountStatus.ACTIVE',
    variant: 'positive',
  },
  [ACCOUNT_STATUS.DISABLED]: {
    label: 'binky.common.accountStatus.DISABLED',
    variant: 'neutral',
  },
  [ACCOUNT_STATUS.LOCKED]: {
    label: 'binky.common.accountStatus.LOCKED',
    variant: 'negative',
  },
  [ACCOUNT_STATUS.REMOVED]: {
    label: 'binky.common.accountStatus.REMOVED',
    variant: 'negative',
  },
};

/**
 * A Spectrum StatusLight for the 'User' account status property.
 */
const AccountStatus = ({status}) => {
  const {variant, label} = ACCOUNT_STATUS_LIGHT[status] ?? {};
  const intl = useIntl();

  return (
    variant &&
    label && (
      <StatusLight alignStart minHeight="inherit" variant={variant}>
        {intl.formatMessage({id: label})}
      </StatusLight>
    )
  );
};

AccountStatus.propTypes = {
  /**
   * The User account status. One of ACCOUNT_STATUS.
   */
  status: PropTypes.oneOf(Object.values(ACCOUNT_STATUS)).isRequired,
};

export default AccountStatus;
export {ACCOUNT_STATUS_LIGHT};
