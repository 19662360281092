(function () {
  /**
   * @deprecated Please use src2/core/api/utils/apiUtils.js
   */
  angular.module('binky.core.api.utils').factory('apiUtils', apiUtils);

  function apiUtils(_, JIL_CONSTANTS, OrganizationList) {
    return {
      csvBlobTransformer,
      getAcceptHeader,
      getActiveOrg,
      getActiveOrgId,
    };

    /////////

    /**
     * @description Method to transform CSV data to blob
     * @param {Object} data - raw CSV data
     * @returns {Object} object containing blob representation of csv
     */
    function csvBlobTransformer(data) {
      let csvFile;

      if (data) {
        csvFile = new Blob([data], {type: 'text/csv;charset=utf-8;'});
      }

      return {file: csvFile};
    }

    /**
     * @description Method to get accepted header
     * @returns {String} accepted header e.g. 'application/json'
     */
    function getAcceptHeader() {
      return JIL_CONSTANTS.HEADERS.ACCEPT.V2;
    }

    /**
     * @description Method to get active org
     * @returns {String} org id e.g. '123@AdobeOrg'
     */
    function getActiveOrg() {
      const organizationList = OrganizationList.get();
      return _.get(organizationList, 'activeOrg.id');
    }

    /**
     * @description Method to get active org id only
     * @returns {String} org id only e.g. '123'
     */
    function getActiveOrgId() {
      const activeOrgId = getActiveOrg();
      return activeOrgId ? _.split(activeOrgId, '@')[0] : undefined;
    }
  }
})();
