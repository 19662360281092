import {Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import OverlayWait from '../../wait/OverlayWait';
import TableConstants from '../TableConstants';

import {useTableSectionContext} from './TableSectionContext';

const {TABLE_SLOTS} = TableConstants;

/**
 * @deprecated use Pandora adminstration TableSection:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section
 *
 * Table section component to layout the table-related components.
 */
const TableSection = observer(({children}) => {
  // If pagination is being used, the listStore returned should be of the type IndexPagingTableStore
  // or TokenPagingTableStore, otherwise it can be of the type Store.
  const {listStore} = useTableSectionContext();

  const isTableSlot = (component, slot) => component?.type?.displayName === slot;

  const findTableSlot = (slot) => {
    if (children.length > 1) {
      return children?.find((el) => isTableSlot(el, slot));
    }
    return isTableSlot(children, slot) && children;
  };

  const tableActions = findTableSlot(TABLE_SLOTS.ACTIONS);
  const table = findTableSlot(TABLE_SLOTS.TABLE);
  const indexPagination = findTableSlot(TABLE_SLOTS.INDEX_PAGINATION);
  const tokenPagination = findTableSlot(TABLE_SLOTS.TOKEN_PAGINATION);

  return (
    listStore && (
      <OverlayWait isLoading={listStore.isLoading} showContent={!listStore.tableStore.isEmptyTable}>
        <Flex
          direction="column"
          rowGap="size-300" // 24px
        >
          {tableActions}
          {table}
        </Flex>
        {indexPagination}
        {tokenPagination}
      </OverlayWait>
    )
  );
});

TableSection.propTypes = {
  /**
   * The table components that will be arranged within the table section.
   * Expects one or an array of the following: TableActions, Table,
   * IndexPagination, TokenPagination.
   */
  children: PropTypes.node,
};

export default TableSection;
