(function () {
  /**
   * @deprecated React services do not use these
   *
   * @ngdoc factory
   * @name apiHeaderInterceptor
   * @description Handles injection of common headers into API requests
   */
  angular
    .module('binky.core.api.interceptor')
    .provider('apiHeaderInterceptor', apiHeaderInterceptor);

  function apiHeaderInterceptor(_) {
    let apiKey = '';
    let apiKeyAllowlist = [];
    let authorizationHeaderAllowlist = [];
    let acceptLanguageHeaderAllowlist = [];
    let includeRoles;
    let includeRolesHeaderAllowlist = [];
    let jilFeatureHeaderAllowList = [];

    this.configure = configure;
    this.$get = $get;

    ///////////////

    /**
     * @description Configure the apiHeaderInterceptor
     * @param {Object} [config] - config
     * @property {String} [config.apiKeyAllowlist] of api that require an API key
     * @property {String} [config.authorizationHeaderAllowlist] of api that require auth token
     * @property {String} [config.acceptLanguageHeaderAllowlist] of api that require Accept-Language header
     */
    function configure(config = {}) {
      apiKeyAllowlist = _.union(apiKeyAllowlist, config.apiKeyAllowlist);
      authorizationHeaderAllowlist = _.union(
        authorizationHeaderAllowlist,
        config.authorizationHeaderAllowlist
      );
      acceptLanguageHeaderAllowlist = _.union(
        acceptLanguageHeaderAllowlist,
        config.acceptLanguageHeaderAllowlist
      );
      apiKey = config.apiKey || apiKey;
      includeRoles = config.includeRoles;
      includeRolesHeaderAllowlist = _.union(
        includeRolesHeaderAllowlist,
        config.includeRolesHeaderAllowlist
      );
      jilFeatureHeaderAllowList = _.union(
        jilFeatureHeaderAllowList,
        config.jilFeatureHeaderAllowList
      );
    }

    /* @ngInject */
    function $get($injector, authentication) {
      return {request};

      //////////////

      function request(config = {}) {
        if (!config.headers) {
          config.headers = [];
        }
        addAcceptLanguageHeader(config, $injector.get('locale'));
        addApiKeyHeader(config);
        addAuthorizationHeader(config, authentication);
        addIncludeRolesHeader(config);
        // When JIL removes the use_clam flag, we can remove addUseClam
        addUseClam(config);
        addPA4280(config);

        return config;
      }
    }

    /**
     * @description Helper to determine if api request requires Accept-Language header
     * @param {Object} config - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     * @param {Object} locale - locale service to provide the active locale code
     */
    function addAcceptLanguageHeader(config, locale) {
      if (_.has(config, 'params.acceptLanguage')) {
        config.headers['Accept-Language'] = config.params.acceptLanguage;
      }

      if (
        !_.has(config, 'headers.Accept-Language') &&
        _.some(acceptLanguageHeaderAllowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix))
      ) {
        config.headers['Accept-Language'] = locale.getActiveLocaleCode();
      }
    }

    /**
     * @description Helper to add the api key header if the requested requires it
     * @param {Object} config - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     */
    function addApiKeyHeader(config) {
      if (_.some(apiKeyAllowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix))) {
        config.headers['X-Api-Key'] = apiKey;
      }
    }

    /**
     * @description Helper to add the auth header if the api request requires it
     * @param {Object} config - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     * @param {Object} authentication - authentication service to provide tokens
     */
    function addAuthorizationHeader(config, authentication) {
      if (
        !_.has(config, 'headers.Authorization') &&
        _.some(authorizationHeaderAllowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix))
      ) {
        const token = authentication.getAccessToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    /**
     * @description Helper to determine if api request requires X-Include-Roles header
     * @param {Object} config - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     */
    function addIncludeRolesHeader(config) {
      if (
        includeRoles &&
        !_.has(config, 'headers.X-Include-Roles') &&
        _.some(includeRolesHeaderAllowlist, (apiPrefix) => _.startsWith(config.url, apiPrefix))
      ) {
        config.headers['X-Include-Roles'] = _.join(includeRoles, ',');
      }
    }

    /**
     * @description Helper to add x-jil-feature header for the PA-4280 project.
     * @param {Object} config - params for the api request
     * @param {String} [config.url] - URL the request will be made to
     */
    function addPA4280(config) {
      if (_.some(jilFeatureHeaderAllowList, (apiPrefix) => _.startsWith(config.url, apiPrefix))) {
        // when we remove use_clam, config.headers['x-jil-feature'] may become null
        config.headers['x-jil-feature'] = _.join([config.headers['x-jil-feature'], 'pa_4280'], ',');
      }
    }

    /**
     * @description Helper to add x-jil-feature header
     * @param {Object} config - params of the api request
     * @param {String} [config.url] - URL the request will be made to
     */
    function addUseClam(config) {
      if (
        !_.has(config, 'headers.x-jil-feature') &&
        _.some(jilFeatureHeaderAllowList, (apiPrefix) => _.startsWith(config.url, apiPrefix))
      ) {
        config.headers['x-jil-feature'] = 'use_clam';
      }
    }
  }
})();
