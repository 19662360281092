(function () {
  'use strict';
  /**
   * @deprecated use the React Spectrum IllustratedMessage
   *
   * @ngdoc component
   * @name binky.widgets.common.error-section:binkyErrorSection
   * @description A error section widget with an actionable callback function and error text.
   * @param {String} errorMsg The error message that is shown.
   * @param {String} [iconName] The name of the icon in spectrum. Defaults to 'AlertCircle'
   * @param {String} [iconSize] The size of the alert icon. Defaults to 'L'
   * @param {Function} onReload The function invoked when the reload text is clicked.
   */

  angular.module('binky.widgets.common.error-section').component('binkyErrorSection', {
    bindings: {
      errorMsg: '@',
      iconName: '<?',
      iconSize: '@?',
      onReload: '&',
    },
    controller,
    templateUrl: 'widgets/common/error-section/error-section.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        iconName: vm.iconName || 'AlertCircle',
        iconSize: vm.iconSize || 'L',
        onClick,
      });
    }

    function onClick(event) {
      // prevent user from scrolling if they use spacebar
      event.preventDefault();

      _.invoke(vm, 'onReload');
    }
  }
})();
