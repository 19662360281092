import {PRODUCT_SUPPORT_ADMIN, SUPPORT_ADMIN, SUPPORT_ADMINS} from '@admin-tribe/binky';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

/**
 * @description Method to get checkbox items based on user role
 *
 * @param {Object} defaultCheckbox - the default value for checkbox which contains checked and disabled
 * @param {Object} intl - react intl
 * @param {USER_ROLES} role - the user role for checkbox
 *
 * @returns {Object} an object of checkboxes info including description, name targets value, and default value.
 */
const getCheckboxList = (defaultCheckbox, intl, role) => {
  if (role === SUPPORT_ADMINS) {
    return {
      ...defaultCheckbox,
      items: [
        {
          description: intl.formatMessage({
            id: 'binky.common.adminRole.roles.supportAdmin.checkbox.description2',
          }),
          name: intl.formatMessage({
            id: 'binky.common.adminRole.roles.supportAdmin.checkbox.name2',
          }),
          value: SUPPORT_ADMIN,
        },
        {
          description: intl.formatMessage({
            id: 'binky.common.adminRole.roles.productSupportAdmin.checkbox.description2',
          }),
          name: intl.formatMessage({
            id: 'binky.common.adminRole.roles.productSupportAdmin.checkbox.name',
          }),
          targets: [TARGET_TYPE.PRODUCT_SUPPORTS],
          value: PRODUCT_SUPPORT_ADMIN,
        },
      ],
    };
  }
  return undefined;
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getCheckboxList};
