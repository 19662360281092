/**
 * @awaitingdeprecation - remove once all EVENT_SOURCES.SRC events are removed
 *
 * This module relays events from an angular scope to eventBus
 */

import eventBus, {EVENT_SOURCES} from './eventBus';

let bridgeEnabled = false;
const emitToEventBus = (args) => {
  if (!bridgeEnabled) {
    return;
  }
  const [eventId, eventData, source] = args;
  if (source === undefined || source === EVENT_SOURCES.SRC) {
    eventBus.emit(eventId, eventData, EVENT_SOURCES.SRC);
  }
};

/**
 * Relay events from an angular scope to eventBus.  This is intended to
 * be run during angular's config phase.  The bridge begins life in
 * a disabled state and should be enabled in the run phase.
 *
 * @param {Function} $delegate - delegate for an angular scope
 * @returns {Function} the modified delegate.
 */
const eventBridgeDecorator = ($delegate) => {
  const Scope = $delegate.constructor;
  const origBroadcast = Scope.prototype.$broadcast;
  const origEmit = Scope.prototype.$emit;

  function broadcastDecorator(...args) {
    emitToEventBus(args);
    return origBroadcast.apply(this, args);
  }
  Scope.prototype.$broadcast = broadcastDecorator;

  function emitDecorator(...args) {
    emitToEventBus(args);
    return origEmit.apply(this, args);
  }
  Scope.prototype.$emit = emitDecorator;
  return $delegate;
};

/**
 * Enable the bridge from angular to eventBus.
 */
const enableEventBridge = () => {
  bridgeEnabled = true;
};

/**
 * Disable the bridge from angular to eventBus.
 */
const disableEventBridge = () => {
  bridgeEnabled = false;
};

export {disableEventBridge, enableEventBridge, eventBridgeDecorator};
