import ProductDescriptor from './ProductDescriptor';

/**
 * @description This class repreesent a list of products
 */
class ProductListDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {List<Object>} [options.items] List of products
   */
  constructor(options = {}) {
    this.items = options.items?.map((product) => new ProductDescriptor(product)) || [];
  }
}

export default ProductListDescriptor;
