// eslint-disable-next-line filenames/match-exported -- context
import PropTypes from 'prop-types';
import React, {useContext, useMemo} from 'react';

/**
 * The AddUserForm context for passing in various permissions and values
 */
const AddUserFormContext = React.createContext({});
const useAddUserFormContext = () => useContext(AddUserFormContext);

/**
 * A provider that verifies the required props for AddUserForm are passed in
 */
const AddUserFormContextProvider = ({defaultCountryCode, children}) => {
  const value = useMemo(() => ({defaultCountryCode}), [defaultCountryCode]);
  return <AddUserFormContext.Provider value={value}>{children}</AddUserFormContext.Provider>;
};

AddUserFormContextProvider.propTypes = {
  /**
   * Children that will use the context.
   */
  children: PropTypes.node.isRequired,
  /**
   * The country code to initialize the CountryPicker, if the selected user does not have one.
   * Typically the current organization's country code.
   */
  defaultCountryCode: PropTypes.string.isRequired,
};

export {useAddUserFormContext};
export default AddUserFormContextProvider;
