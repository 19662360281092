(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name gainsightHelper
   * @description Helper functions for loading the scripts required for gainsight
   */
  angular.module('binky.core.gainsight').factory('gainsightHelper', gainsightHelper);

  /* @ngInject */
  function gainsightHelper(authenticationReady, featureReady) {
    const service = {
      loadGainsightScript,
      whenFeatureAndAuthRunReady,
    };

    return service;

    /**
     * @description Script given by gainsight for set up
     * https://support.gainsight.com/PX/Install_PX/Install_Gainsight_PX_on_your_web_app#STEP_2:_Get_the_Tag_Installed_on_to_your_Web_App_and_Web_Site_(Developer_Assistance_Needed)
     * @param {string} gainsightApiKey - API key for the loaded environment.
     * @param {string} gainsightUrl - Url to access the gainsight service.
     */
    function loadGainsightScript(gainsightApiKey, gainsightUrl) {
      (function (n, t, a, e) {
        const i = 'aptrinsic';
        n[i] =
          n[i] ||
          /* istanbul ignore next -- Snippet is provided by vendor */
          function () {
            (n[i].q = n[i].q || []).push(arguments); // eslint-disable-line prefer-rest-params
          };
        n[i].p = e;
        const r = t.createElement('script');
        r.async = !0;
        r.src = `${a}?a=${e}`;
        const c = t.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(r, c);
      })(window, document, gainsightUrl, gainsightApiKey);
    }

    /**
     * @description Helper to wait for the feature and auth ready providers.
     *
     * @returns {Promise} Resolved when the featureReady and authReady promises
     *     are resolved.
     */
    function whenFeatureAndAuthRunReady() {
      const authReadyPromise = authenticationReady.whenRunReady();
      const featureReadyPromise = featureReady.whenRunReady();

      return featureReadyPromise.then(() => authReadyPromise);
    }
  }
})();
