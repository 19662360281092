(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.insights.create-insights-item-detail-modal
   * @description module that defines insights date range components
   */
  angular.module('binky.widgets.insights.create-insights-item-detail-modal.date-range', [
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.widgets.common.datepicker',
    'pascalprecht.translate',
  ]);
})();
