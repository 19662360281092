(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.invitation')
    .constant('INVITATION_EVENT', {
      ACCEPT: 'AcceptInvitation',
      REVOKE: 'RevokeInvitation',
    })
    .constant('INVITATION_STATUS', {
      ACTIVE: 'ACTIVE',
      CLAIMED: 'CLAIMED',
      EXPIRED: 'EXPIRED',
      REMOVED: 'REMOVED',
      REVOKED: 'REVOKED',
    });
})();
