import feature from 'services/feature';
import log from 'services/log';

import initialState from './initialState';
import NewRelicMetrics from './metrics/NewRelicMetrics';
import {OBSERVABILITY_BUMPER_FEATURE_FLAG} from './observability.constants';
import observabilityRoutes from './route/observabilityRoutes';

let metricsProviders;

/**
 * @description Initialize observability metrics
 * @param {Object} configData Config 'observability' properties from configuration.495e31ace1.json
 */
const initialize = (configData) => {
  if (
    feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG) ||
    feature.isDisabled('temp_observability')
  ) {
    return;
  }

  try {
    // Consider post authentication as the starting point for the initial load
    const initialStartTime = initialState.getAuthenticationReadyElapsedTime();

    metricsProviders = [new NewRelicMetrics({configData, initialStartTime})];
  } catch (error) {
    log.warn(`observabilityMetrics: initialize error: ${error}`);
  }
};

/**
 * @description Store custom attributes for the current session
 * @param {Object} attributes Key-value pair, e.g. {env: 'prod', orgId: '123@AdobeOrg'}
 */
const setCustomAttributes = (attributes) => {
  if (feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG)) {
    return;
  }

  try {
    metricsProviders.forEach((provider) => provider.setCustomAttributes(attributes));
  } catch (error) {
    log.warn(`observabilityMetrics: setCustomAttributes error: ${error}`);
  }
};

/**
 * @description Handler for after route changed
 * @param {ObservabilityRoute} observabilityRoute Completed route information
 */
const onAfterRouteChange = ({observabilityRoute}) => {
  const routeChangeType = observabilityRoutes.getRouteChangeType(observabilityRoute);

  try {
    metricsProviders.forEach((provider) =>
      provider.onAfterRouteChange({observabilityRoute, routeChangeType})
    );
  } catch (error) {
    log.warn(`observabilityMetrics: onAfterRouteChange error: ${error}`);
  }

  // Mark as visited
  observabilityRoutes.visited(observabilityRoute);
};

/**
 * @description Handler for before route changed
 * Note: this won't be triggered during the initial load with Angular routing, thus rely on onAfterRouteChange to get route data.
 */
const onBeforeRouteChange = () => {
  try {
    metricsProviders.forEach((provider) => provider.onBeforeRouteChange());
  } catch (error) {
    log.warn(`observabilityMetrics: onBeforeRouteChange error: ${error}`);
  }
};

/**
 * @description Register the list of spans, which are used to track the work within a route.
 * The route is not done loading until all spans are completed.
 * @param {Array<String>} spanList List of span names
 */
const registerSpans = (spanList = []) => {
  if (feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG)) {
    return;
  }

  try {
    metricsProviders.forEach((provider) => provider.registerSpans(spanList));
  } catch (error) {
    log.warn(`observabilityMetrics: registerSpans error: ${error}`);
  }
};

/**
 * @description Mark a span as started
 * @param {String} span Span to start
 */
const startSpan = (span) => {
  if (feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG)) {
    return;
  }

  try {
    metricsProviders.forEach((provider) => provider.startSpan(span));
  } catch (error) {
    log.warn(`observabilityMetrics: startSpan error: ${error}`);
  }
};

/**
 * @description Mark a span as completed
 * @param {String} span Span to complete
 */
const completeSpan = (span) => {
  if (feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG)) {
    return;
  }

  try {
    metricsProviders.forEach((provider) => provider.completeSpan(span));
  } catch (error) {
    log.warn(`observabilityMetrics: completeSpan error: ${error}`);
  }
};

const observabilityMetrics = {
  completeSpan,
  initialize,
  onAfterRouteChange,
  onBeforeRouteChange,
  registerSpans,
  setCustomAttributes,
  startSpan,
};

export default observabilityMetrics;
