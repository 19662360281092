(function () {
  /**
   * @ngdoc module
   * @name binky.core.organizations.admin-list
   * @description module that defines the OrganizationAdminList model
   */
  angular.module('binky.core.organizations.admin-list', [
    'binky.core.api.jil',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.organizations.user',
    'binky.core.user.roles',
  ]);
})();
