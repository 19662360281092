import axios from 'axios';

import feature from 'services/feature';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Organizations Contracts APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organizations Contracts api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches a contract's acquisition summaries
 * @param {String} String - The contract id.
 * @param {String} orgId - The org id.
 * @param {Object} params - The query params.
 * @param {String[]} params.include - Array of strings. E.g. ['offers']
 * @returns {<PromiseAxiosResponse>} - the Axios response
 */
const getContractAcquisitionSummaries = ({contractId, orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/contracts/${contractId}/acquisition-summaries`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      ...params,
      include: params?.include?.join(','),
    },
  });

/**
 * @description Fetches the contract resource for a particular
 *     organization with given org id.
 * @param {Boolean} [includeAutoRenewal] - whether or not to return autoRenewal Object
 *   Defaults to false.
 * @param {Boolean} [includeInactiveContracts] - whether or not to return Contracts with
 *   status INACTIVE. Defaults to true.
 * @param {Boolean} [includeTermsAcceptances] - whether or not to return TermsAcceptance
 *   information. Defaults to false.
 * @param {String} orgId - The associated org Id
 */
const getContracts = async ({
  includeAutoRenewal = false,
  includeInactiveContracts = true,
  includeTermsAcceptances = false,
  orgId,
}) => {
  const params = feature.isEnabled('temp_terms_redirect')
    ? {includeInactiveContracts, includeTermsAcceptances}
    : {includeInactiveContracts};

  if (feature.isEnabled('auto_renewal_opt_in_self_service')) {
    params.includeAutoRenewal = includeAutoRenewal;
  }

  const response = await axios.get(`${url}/v2/organizations/${orgId}/contracts`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

  return response;
};

/**
 * @description Updates the contract for a particular
 *     organization with given contract id.
 * @param {String} orgId - The org id
 * @param {Array<String>} patchOperations - The patch operations to be updated with
 */
const patchContract = async ({orgId}, patchOperations) => {
  const response = await axios.patch(
    `${url}/v2/organizations/${orgId}/contracts`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

  return response;
};

/**
 * @description Creates a contract
 *
 * @param {String} orgId - The org id
 * @param {Contract} contractModel - The contract to create
 */
const postContract = async ({orgId}, contractModel) => {
  const response = await axios.post(`${url}/v2/organizations/${orgId}/contracts`, contractModel, {
    headers: getHeaders({clientId, includeRoles}),
  });

  return response;
};

/**
 * @description Updates the accept terms of a contract for a particular
 *     organization with given contract id.
 * @param {String} contractId - The associated contract Id
 * @param {Object} acceptTerms - The accept terms to be updated with
 * @param {String} orgId - The org id
 */
const putContractAcceptTerms = async ({contractId, orgId}, acceptTerms) => {
  const response = await axios.put(
    `${url}/v2/organizations/${orgId}/contracts/${contractId}:acceptTerms`,
    acceptTerms,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

  return response;
};

/**
 * @description Change contract reseller through user-provided code
 * @param {String} contractId - The associated contract Id
 * @param {String} orgId - The org id
 * @param {Number} resellerChangeCode - Required code to change reseller
 * @returns {Object} An object that contains an updated reseller info:
 * {
 *   data: {
 *     resellerInfo: {<same as each contract.resellerInfo from getContracts()>}
 *   }
 * }
 */
const putContractChangeReseller = async ({contractId, orgId, resellerChangeCode}) => {
  const response = await axios.put(
    `${url}/v2/organizations/${orgId}/contracts/${contractId}:change-reseller`,
    {
      code: String(resellerChangeCode),
    },
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

  return response;
};

const jilContracts = {
  configure,
  getContractAcquisitionSummaries,
  getContracts,
  patchContract,
  postContract,
  putContractAcceptTerms,
  putContractChangeReseller,
};
export default jilContracts;
