import pick from 'lodash/pick';

/**
 * @description This class repreesent a cart
 */
class CartDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} options Initialization options
   * @param {String } options.id: Cart ID
   * @param {String} options.cancellationReasonComment Free text cancellation reason for CANCEL_REASONS.OTHER
   * @param {Array} options.cancellationReasons Array of cancellation reason IDs from CANCEL_REASONS
   * @param {Object} options.contract Object to represent a contract
   * @param {String} options.contract.id Contract ID
   * @param {Boolean} options.contract.isNFR NFR (Not For Resale) flag
   * @param {String} options.contract.type Contract type
   * @param {Array} options.items List of cart items
   * @param {Number} options.items[n].assignableLicenseCount Number of purchased licenses
   * @param {String} options.items[n].customer_segment Customer segment
   * @param {Number} options.items[n].numberSelected Number of selected quantity in the cart
   * @param {String} options.items[n].offer_id Offer ID
   * @param {String} options.items[n].productBucket Source of the product in cart:
   *   - 'cartItems' -- renewed products during renewal flow
   *   - 'ownedOffers' -- products already owned during add flow when the user adds a product that is already licensed
   *   - 'unownedWithoutRecOffers' -- non-owned products, not one of the recommended products
   *   - 'unownedWithRecOffers' -- non-owned products, one of the recommended products
   * @param {Number} options.items[n].provisionedQuantity Number of assigned licenses
   * @param {String} options.purchaseAuthId Purchase authrorization ID for adding products
   * @param {Object} options.total Object to represent a total
   * @param {Object} options.total.currency Purchase currency information
   * @param {String} options.total.currency.code Currency code
   * @param {String} options.total.currency.delimiter Decimal delimiter
   * @param {String} options.total.currency.formatString Format string
   * @param {String} options.total.currency.symbol Symbol for the currency
   * @param {Boolean} options.total.currency.usePrecision Use precision
   * @param {Number} options.total.subtotal Amount of purchases without tax
   * @param {Number} options.total.tax Amount of tax
   * @param {Number} options.total.total Final amount to pay, may or may not include tax
   */
  constructor(options = {}) {
    Object.assign(
      this,
      pick(options, [
        'id',
        'cancellationReasonComment',
        'cancellationReasons',
        'contract',
        'items',
        'purchaseAuthId',
        'total',
      ])
    );
  }
}

export default CartDescriptor;
