(function () {
  /**
   * @ngdoc module
   * @name binky.core.product.license-quantity-list
   * @description Module which contains license-quantity-list related services
   */
  angular.module('binky.core.product.license-quantity-list', [
    'angularMoment',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
