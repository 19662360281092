(function () {
  'use strict';
  /**
   * @deprecated use the React Spectrum AlertDialog
   *
   * @ngdoc component
   * @name binky.widgets.common.confirmation-modal:binkyConfirmationModal
   *
   * @description A confirmation modal widget.
   *
   * @param {String} [cancelButtonLabel] The label for the confirmation button. Defaults to "Cancel".
   * @param {String} [cancelButtonVariant] The variant of the cancel button
   * @param {String} [confirmButtonLabel] The label for the confirmation button. Defaults to "Confirm".
   * @param {String} [confirmButtonVariant] The variant of the confirm button.
   * @param {String} [content] The content of the modal.
   * @param {String} [focusOnShow] Css selector for focusable object to focus when dialog is shown. If not specified,
   *    or selector doesn't match any elements, the first focusable element in the dialog will receive focus.
   * @param {Boolean} [isDisabled] True if confirm button is disabled, else is false.
   * @param {String} [modalContext] The context of the modal.
   * @param {String} [modalId] The id of the modal.
   * @param {Boolean} [modalTitle] The title of the modal.
   * @param {String} [modalTitleVariant] The icon to be displayed with the modal. Defaults to no icon. Icon can be set to warning, success, info, help, and error.
   * @param {Function} [onCancel] The callback function to register for the cancel event.
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onConfirm] The callback triggered when the confirm button is clicked.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {Boolean} [showCancelButton] The boolean for showing the cancel button. Defaults to true
   */
  angular.module('binky.widgets.common.confirmation-modal').component('binkyConfirmationModal', {
    bindings: {
      cancelButtonLabel: '@?',
      cancelButtonVariant: '@?',
      confirmButtonLabel: '@?',
      confirmButtonVariant: '@?',
      content: '@',
      focusOnShow: '@?',
      isDisabled: '&?',
      modalContext: '@?',
      modalId: '@',
      modalTitle: '@?',
      modalTitleVariant: '@?',
      onCancel: '&?',
      onClose: '&?',
      onConfirm: '&',
      onOpen: '<?',
      showCancelButton: '<?',
    },
    controller,
    templateUrl: 'widgets/common/confirmation-modal/confirmation-modal.component.html',
    transclude: {
      content: '?content',
    },
  });

  /* @ngInject */
  function controller($translate, _) {
    const vm = this;
    vm.cancelButtonLabel =
      vm.cancelButtonLabel ||
      $translate.instant('binky.widgets.common.confirmationModal.cancelButton');
    vm.confirmButtonLabel =
      vm.confirmButtonLabel ||
      $translate.instant('binky.widgets.common.confirmationModal.confirmButton');
    vm.modalTitle =
      vm.modalTitle || $translate.instant('binky.widgets.common.confirmationModal.title');
    vm.modalTitleVariant = vm.modalTitleVariant || 'default';
    vm.showCancelButton = _.defaultTo(vm.showCancelButton, true);
  }
})();
