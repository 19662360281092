(function () {
  'use strict';
  /**
   * @deprecated - replaced by Pagination component - see TableStore story.
   */
  angular
    .module('binky.widgets.common.pagination-control-bar.pagination-select')
    .component('binkyPaginationSelect', {
      bindings: {
        items: '=',
        labelledBy: '@',
        onChange: '<?',
        selectedLabel: '@?',
        selectedValue: '<',
      },
      controller,
      templateUrl:
        'widgets/common/pagination-control-bar/pagination-select/pagination-select.component.html',
    });

  /* @ngInject */
  function controller($element, $timeout, _) {
    const vm = this;
    const selectEl = $element.find('select')[0];
    const selectButtonEl = $element.find('span')[1];
    let lastWidth;

    vm.$doCheck = $doCheck;

    vm.onSelectionChange = function (selectedValue) {
      if (vm.onChange) {
        if (angular.isArray(vm.onChange)) {
          _.forEach(vm.onChange, (func) => {
            func(selectedValue);
          });
        } else {
          vm.onChange(selectedValue);
        }
      }
    };

    function $doCheck() {
      updateWidth();
    }

    function updateWidth() {
      if (lastWidth !== selectButtonEl.offsetWidth) {
        lastWidth = selectButtonEl.offsetWidth;
        $timeout(() => {
          selectEl.style.width = `${selectButtonEl.offsetWidth}px`;
        });
      }
    }
  }
})();
