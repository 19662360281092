import {Contact, Locale, isValidContactEmail, isValidContactName} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import {ErrorStatus, PhoneNumberInput} from '@pandora/react-phone-number-input';
import {ValidatedTextField, ValidatedTextFieldVariant} from '@pandora/react-validated-text-field';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

const NAME = 'name';
const EMAIL = 'email';
const PHONE = 'phone';

/**
 * The input fields to update a security contact's information.
 */
const SecurityContactInput = ({contact, isDisabled = false, onChange}) => {
  const textFields = [NAME, EMAIL];
  const intl = useIntl();

  const getContactValue = (valueType) => contact[valueType] || '';

  const getValidationState = () => {
    if (contact.isInvalid === true) {
      return 'invalid';
    }
    if (contact.isInvalid === false) {
      return 'valid';
    }
    return 'undefined';
  };

  const [validationState, setValidationState] = useState(getValidationState());
  const [hasChanged, setHasChanged] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(getContactValue(PHONE));

  const isValidValue = (type) =>
    type === NAME ? isValidContactName(contact[type]) : isValidContactEmail(contact[type]);

  const getLabel = (valueType) =>
    intl.formatMessage({
      id: `binky.common.editSecurityContactsModal.form.field.label.${valueType}`,
    });

  const getTextFieldVariant = (textFieldType) =>
    isValidValue(textFieldType) ? undefined : ValidatedTextFieldVariant.ERROR;

  const getEmailValidationMessage = () =>
    !isValidValue(EMAIL) &&
    intl.formatMessage({
      id: 'binky.common.editSecurityContactsModal.form.field.error.invalidEmail',
    });

  const onChangeValue = (updatedValue, valueType) => {
    onChange?.(new Contact({...contact, [valueType]: updatedValue}));
  };

  // onError is triggered whenever there is a change, whether there is an error or not
  // https://git.corp.adobe.com/PandoraUI/ui-components/blob/master/packages/react-phone-number-input/src/hooks/useErrorController.ts#L31
  const onError = (error) => {
    setValidationState(getValidationState());

    if (hasChanged && currentPhoneNumber !== getContactValue(PHONE)) {
      const isInvalid = error === ErrorStatus.InvalidPhoneNumber;
      onChange?.(
        new Contact({
          ...contact,
          isInvalid,
          phone: currentPhoneNumber,
        })
      );
      setHasChanged(false);
    }
  };

  return (
    <Flex columnGap="size-150" data-testid="security-contact-input">
      {textFields.map((textFieldType) => (
        <ValidatedTextField
          key={textFieldType}
          data-testid={`${textFieldType}-field`}
          isDisabled={isDisabled}
          label={getLabel(textFieldType)}
          maxLength="255"
          onChange={(newValue) => onChangeValue(newValue, textFieldType)}
          validationMessage={textFieldType === EMAIL && getEmailValidationMessage()}
          value={getContactValue(textFieldType)}
          variant={getTextFieldVariant(textFieldType)}
          wrapperProps={{width: '33%'}}
        />
      ))}
      <View width="33%">
        <PhoneNumberInput
          autoValidate
          data-testid={`${PHONE}-field`}
          defaultCountry={Locale.get().activeCountry}
          defaultValue={getContactValue(PHONE)}
          hideCountrySelector
          isDisabled={isDisabled}
          label={getLabel(PHONE)}
          onChange={(newPhoneNumber) => {
            // Holding off on updating the contact until we receive the
            // current onError status (which can be InvalidPhoneNumber,
            // Valid, or undefined) on the error callback
            if (newPhoneNumber !== getContactValue(PHONE)) {
              setCurrentPhoneNumber(newPhoneNumber);
              setHasChanged(true);
            }
          }}
          onError={onError}
          validationState={validationState}
        />
      </View>
    </Flex>
  );
};

SecurityContactInput.propTypes = {
  /**
   * The contact whose information is being edited.
   */
  contact: PropTypes.instanceOf(Contact).isRequired,
  /**
   * Whether the input is disabled or not. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The callback function triggered when the input is updated.
   * Includes the current contact as a parameter.
   */
  onChange: PropTypes.func,
};

export default SecurityContactInput;
