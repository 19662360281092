import {SEARCH_QUERY_MIN_LENGTH} from '@admin-tribe/binky';
import {SearchField, View} from '@adobe/react-spectrum';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {requiredLabelPropsCheck} from 'common/utils/prop-type/propTypeUtils';

import styles from './Search.pcss';

/**
 * @deprecated use Pandora adminstration Search:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-search
 *
 * Search is a widget wrapper for React SearchField component.
 */
const Search = ({
  allowsWildcard = false,
  maxLength = 256,
  minLength = SEARCH_QUERY_MIN_LENGTH,
  onSubmit,
  searchPattern = '^.*$',
  searchPatternErrorMessage,
  ...searchFieldProps
}) => {
  const intl = useIntl();
  const [validationState, setValidationState] = useState(undefined);
  const [errorMessageContent, setErrorMessageContent] = useState('');

  const handleChange = (input) => {
    setValidationState(undefined);
    setErrorMessageContent('');
    searchFieldProps.onChange?.(input);
  };

  const lengthValidation = (value) => {
    let valid = value.length >= minLength;
    let usesWildcard = false;
    if (valid && allowsWildcard && value.charAt(value.length - 1) === '*') {
      const lengthCheckValue = value.slice(0, value.length - 1);
      valid = lengthCheckValue.length >= minLength;
      usesWildcard = true;
    }

    if (!valid) {
      setErrorMessageContent(
        usesWildcard
          ? intl.formatMessage({id: 'binky.common.search.wildcardLengthError'}, {minLength})
          : intl.formatMessage({id: 'binky.common.search.lengthError'}, {minLength})
      );
    }

    return valid;
  };

  const validateSearchPattern = (value) => {
    const pattern = new RegExp(searchPattern);
    const valid = pattern.test(value);
    if (!valid) {
      setErrorMessageContent(
        searchPatternErrorMessage || intl.formatMessage({id: 'binky.common.search.patternError'})
      );
    }

    return valid;
  };

  const handleSubmit = (input = '') => {
    const trimmedSearchValue = input.trim();
    handleChange(trimmedSearchValue);
    const valid =
      trimmedSearchValue === '' ||
      (lengthValidation(trimmedSearchValue) && validateSearchPattern(trimmedSearchValue));
    setValidationState(valid ? undefined : 'invalid');
    if (valid) {
      setErrorMessageContent('');
      onSubmit(trimmedSearchValue);
    }
  };

  return (
    // Wrapping with a View to keep alignment consistent in the case
    // the Search component is wrapped in a Flex.
    <View data-testid="search">
      <SearchField
        aria-label={searchFieldProps['aria-label']}
        aria-labelledby={searchFieldProps['aria-labelledby']}
        data-testid="search-field"
        label={searchFieldProps.label}
        maxHeight="size-700"
        maxLength={maxLength}
        minLength={minLength}
        onChange={handleChange}
        onClear={() => onSubmit('')}
        onSubmit={handleSubmit}
        validationState={validationState}
        {...searchFieldProps}
      />
      {errorMessageContent && (
        <View marginTop="size-40">
          {/* spectrum: use HelpText component when added to v3 -- https://jira.corp.adobe.com/browse/SDS-7633 */}
          <span
            className={classNames(styles['error-text'])}
            data-testid="text-field-validation-message"
          >
            {errorMessageContent}
          </span>
        </View>
      )}
    </View>
  );
};

Search.propTypes = {
  /**
   * True to not count wildcard (*) at the end of the search string toward minLength (defaults to false)
   */
  allowsWildcard: PropTypes.bool,
  /**
   * Aria-label prop to assign to Search.
   */
  'aria-label': requiredLabelPropsCheck,
  /**
   * aria-labelledby prop to assign to the text field.
   */
  'aria-labelledby': requiredLabelPropsCheck,
  /**
   * Whether the enabled search field should take focus on display. Defaults to false.
   */
  focusOnShow: PropTypes.bool,
  /**
   * Label to be displayed for Search.
   */
  label: requiredLabelPropsCheck,
  /**
   * The maximum search string length allowed (set on input field to restrict input length) Defaults to 256.
   */
  maxLength: PropTypes.number,
  /**
   * The minimum search string length allowed for validation before submit
   */
  minLength: PropTypes.number,
  /**
   * A function to call when the search is submitted (user hits enter). This should be
   *   used when the search/filter shouldn't be applied until the user is finished entering (server search call)
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * The pattern allowed for validation before submit
   */
  searchPattern: PropTypes.string,
  /**
   * The error message to show if the pattern is violated
   */
  searchPatternErrorMessage: PropTypes.string,
};

export default Search;
