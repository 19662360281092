(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilFulfillmentEvents.js
   *
   * @ngdoc service/provider
   * @name jilFulfillmentEvents
   * @description defines service to manage the jil fulfillment events resource
   */
  angular
    .module('binky.core.api.jil')
    .provider('jilFulfillmentEvents', jilFulfillmentEventsProvider);

  let url;

  /* @ngInject */
  function jilFulfillmentEventsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        fulfillmentEvents: getFulfillmentEventsResource(),
        licenseFulfillmentEvents: getLicenseFulfillmentEventsResource(),
      };

      return service;

      ///////////

      function getFulfillmentEventsResource() {
        return $resource(`${url}/:orgId/fulfillment-events`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getLicenseFulfillmentEventsResource() {
        return $resource(`${url}/:orgId/products/:productId/fulfillment-events`, {
          orgId: apiUtils.getActiveOrg,
          productId: '@productId',
        });
      }
    }
  }
})();
