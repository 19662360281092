import {useOutletContext} from 'react-router-dom';

/**
 * @description Gets props for Page components used within subtree of a Workspace.
 *
 * @returns {Object} object with fields defined below
 * @property {Boolean} isInWorkspace boolean that flags if Page is children of Workspace
 * @property {ORIENTATION} orientation enum denoting whether workspace navigation is horizontal or vertical
 */
export default function useWorkspacePageProps() {
  return useOutletContext();
}
