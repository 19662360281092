import {LicenseGroup, feature} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import {useLicenseLimitContext} from '../assignment-menu/assignmentMenuUtils';

import ProductProfilePill from './ProductProfilePill';
import {ProductRoleLicenseGroupContext} from './ProductRoleContext';
import ProductRolePickerSection from './ProductRolePickerSection';

// For a product with no roles or a product that has profiles that share the same role, it will display a single product pill with a collapsible product profile list items.
// For a product with separate profile roles, it will display a product profile pill for every profile.
const ProductProfileRolePill = ({
  closeButtonAriaLabel,
  closeButtonTooltipText,
  isDisabled,
  onDismiss,
  onChange,
  onPillChange,
  onProductRoleChange,
  orgId,
  productProfiles,
}) => {
  const licenseGroupIds = productProfiles.map((productProfile) => productProfile.id);
  const product = productProfiles?.[0]?.product;
  const [role, setRole] = React.useState();
  const [, dispatch] = useLicenseLimitContext();

  React.useEffect(() => {
    if (role) {
      onProductRoleChange({
        ...role,
        type: 'UPDATE',
      });

      // for product with dma product roles, we need to display multiple profile pills.
      if (!role.allLicenseGroupsShareSameRole && productProfiles.length > 1) {
        onPillChange({
          productId: product.id,
          type: 'SEPARATE_PROFILE_PILL',
        });
      }
    }

    return () => {
      if (feature.isDisabled('bug_fix_32194')) {
        dispatch?.({product, type: 'DESELECT'});
      }

      if (role) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- see below
        // istanbul ignore else -- once flag is removed there's no need to cover else case
        if (feature.isEnabled('bug_fix_32194')) {
          dispatch?.({product, type: 'DESELECT'});
        }

        onProductRoleChange({
          ...role,
          type: 'REMOVE',
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only triggers callback function when the "role" value changes
  }, [product.id, productProfiles.length, role]);

  return (
    <ProductProfilePill
      closeButtonAriaLabel={closeButtonAriaLabel}
      closeButtonTooltipText={closeButtonTooltipText}
      isDisabled={isDisabled}
      onChange={onChange}
      onDismiss={onDismiss}
      productProfiles={productProfiles}
    >
      <ProductRoleLicenseGroupContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values -- @ringold to fix
        value={{
          licenseGroupId: licenseGroupIds?.[0],
          orgId,
          product,
        }}
      >
        <ProductRolePickerSection onProductRoleChange={setRole} orgId={orgId} />
      </ProductRoleLicenseGroupContext.Provider>
    </ProductProfilePill>
  );
};

ProductProfileRolePill.propTypes = {
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTooltipText: PropTypes.string,
  isDisabled: PropTypes.bool,
  /* callback when items are added/removed from the AssignmentMenu */
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  // callback function to transform a product pill with profiles to product profile pills
  // for products with unique profile roles.
  onPillChange: PropTypes.func,
  // callback function for roles change
  onProductRoleChange: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  productProfiles: PropTypes.arrayOf(PropTypes.instanceOf(LicenseGroup)),
};
export default ProductProfileRolePill;
