import AuthenticatedUser from '../models/user/authenticated-user/AuthenticatedUser';

/**
 * @description Find any available user account, return the 1st
 * of available entry from the input params.
 * Prefer T1/T2/T3 auth data over T2E one.
 * ---
 * Example use: a user may have no T1 and has token for T2E org1,
 * then wants to access T2E org2 from Global Admin.
 * @params {Object} options Options for this method
 * @param {LinkedUserAccountList} options.linkedUserAccountList Resolved LinkedUserAccountList instance
 * @param {OrganizationList} options.organizationList Resolved OrganizationList instance
 *
 * @returns {Object} undefined if there are no available user
 * account data. Otherwise, return:
 * {
 *   currentUser: true if the current logged-in user === the user in the org, false otherwise
 *   orgId: the org ID to login to
 *   userId: the user ID to login as
 * }
 */
function findAnyUserAccount({linkedUserAccountList, organizationList}) {
  if (organizationList.items.length > 0) {
    return generateUserAccountObject({
      orgId: organizationList.items[0].id,
      userId: AuthenticatedUser.get().getId(),
    });
  }

  return linkedUserAccountList.items.length > 0
    ? generateUserAccountObject({
        orgId: linkedUserAccountList.items[0].organization_id,
        userId: linkedUserAccountList.items[0].entitlement_user_id,
      })
    : undefined;
}

/**
 * @description Find matching user account for a given orgId.
 * @params {Object} options Options for this method
 * @param {String} options.orgId Org ID to get user account for
 * @param {LinkedUserAccountList} options.linkedUserAccountList Resolved LinkedUserAccountList instance
 * @param {OrganizationList} options.organizationList Resolved OrganizationList instance
 *
 * @returns {Object} undefined if there are no user account
 * for the org. Otherwise, return:
 * {
 *   currentUser: true if the current logged-in user === the user in the org, false otherwise
 *   orgId: the org ID to login to
 *   userId: the user ID to login as
 * }
 */
function getUserAccount({linkedUserAccountList, organizationList, orgId}) {
  const org = organizationList.findByOrgId(orgId);

  if (org) {
    return generateUserAccountObject({orgId, userId: AuthenticatedUser.get().getId()});
  }

  const linkedUserAccount = linkedUserAccountList.items.find(
    (account) => account.organization_id === orgId
  );

  if (linkedUserAccount) {
    return generateUserAccountObject({
      orgId: linkedUserAccount.organization_id,
      userId: linkedUserAccount.entitlement_user_id,
    });
  }

  return undefined;
}

/**
 * @description Generate user account object, see return value.
 * @params {Object} options Options for this method
 * @param {String} options.orgId Org ID to be stored in resulting user account object
 * @param {String} options.userId User ID to be stored in resulting user account object
 *
 * @returns Object with the following properties:
 * {
 *   currentUser: true if userId option === current logged-in user ID
 *   orgId: from the input option
 *   userId: from the input option
 * }
 */
function generateUserAccountObject({orgId, userId}) {
  return {
    currentUser: userId === AuthenticatedUser.get().getId(),
    orgId,
    userId,
  };
}

export {findAnyUserAccount, getUserAccount};
