(function () {
  /**
   * @ngdoc module
   * @name binky.core.api.ccs
   * @description module that defines ccs api resource services
   */
  angular.module('binky.core.api.ccs', [
    'binky.core.api.utils',
    'binky.core.authentication',
    'binky.core.feature',
    'binky.core.lodash',
    'ngResource',
  ]);
})();
