import {AvailableTypeList, hasRecommendedSuffix} from '@admin-tribe/binky';
import {ActionButton, Dialog, DialogTrigger} from '@adobe/react-spectrum';
import {AvailableTypeList as PandoraAvailableTypeList} from '@pandora/data-model-available-type';
import InfoIcon from '@spectrum-icons/workflow/Info';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getDisallowedTypeMessage} from 'common/utils/global-admin/globalAdminUtils';

import RecommendedTypeMessage from './content/RecommendedTypeMessage';

/**
 * A widget for displaying tooltips for IdTypePicker
 */
const IdTypePickerTooltip = observer(({availableTypeList}) => {
  const intl = useIntl();

  const renderTooltip = () => {
    if (hasRecommendedSuffix(availableTypeList)) {
      const additionalMessage = availableTypeList.hasOneOrMoreDisabledTypes
        ? getDisallowedTypeMessage({availableTypeList, intl})
        : undefined;
      return <RecommendedTypeMessage additionalMessage={additionalMessage} />;
    }

    return null;
  };

  const tooltip = renderTooltip();

  return (
    tooltip && (
      <DialogTrigger isDismissable placement="right" type="popover">
        <ActionButton aria-label="ID type info" data-testid="id-type-picker-tooltip-button" isQuiet>
          <InfoIcon color="informative" data-testid="tooltip-info-icon" />
        </ActionButton>
        <Dialog data-testid="id-type-picker-tooltip-content">{tooltip}</Dialog>
      </DialogTrigger>
    )
  );
});

IdTypePickerTooltip.propTypes = {
  availableTypeList: PropTypes.oneOfType([
    PropTypes.instanceOf(AvailableTypeList),
    PropTypes.instanceOf(PandoraAvailableTypeList),
  ]).isRequired,
};

export default IdTypePickerTooltip;
