import cloneDeep from 'lodash/cloneDeep';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';

import jilContracts from 'api/jil/jilContracts';
import {CART_EVENT} from 'services/cart/CartConstants';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import feature from '../feature';

import AcquisitionSummary from './AcquisitionSummary';

const ACQUISITION_SUMMARY_LIST_CACHE_ID = 'AcquisitionSummaryList';

class AcquisitionSummaryList extends JilModelList {
  /**
   * @param {String}   options.contractId - The contract id
   * @param {String[]} options.include - Array of GET include params.
   * @param {String}   options.orgId - The org id
   * @param {String}   options.summarizedBy - Type of summarization needed
   */
  constructor({contractId, include = ['offers'], orgId, summarizedBy}) {
    super({
      cacheClearingEvents: feature.isEnabled('temp_refresh_acquisition_summary')
        ? [CART_EVENT.SUBMIT]
        : [],
      isCacheable: true,
      itemClassRef: AcquisitionSummary,
      modelCacheId: ACQUISITION_SUMMARY_LIST_CACHE_ID,
      resource: jilContracts.getContractAcquisitionSummaries,
    });

    Object.assign(this, {
      contractId,
      // By default, set the query parameters for GET to include the offers
      include,
      orgId,
      summarizedBy,
    });
  }

  /**
   * @returns {SummaryItem[]} - returns all summary items in the acquisition summary list
   */
  getSummaryItems() {
    return this.summaryItems;
  }

  async refresh() {
    try {
      await super.refresh(this.toQueryParams());
      // Lift all nested summaryItems into one flat list and then sort.
      this.summaryItems = this.items.flatMap((acqSummary) => acqSummary.summaryItems);
      this.summaryItems = sortBy(this.summaryItems, [(item) => item.getName()]);
      this.registerSavedState();
    } catch (error) {
      log.error('AcquisitionSummaryList.refresh() failed. Error:', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Updates model with the saved items which may be later modified.
   */
  registerSavedState() {
    this.savedState = cloneDeep(this.summaryItems);
  }

  /**
   * @description Method to restore the previously saved values, to be used for
   * discarding changes during an edit operation.
   */
  restore() {
    this.summaryItems = cloneDeep(this.savedState);
  }

  /**
   * @description Method to obtain the query params for this list.
   */
  toQueryParams() {
    const options = {
      contractId: this.contractId,
      include: this.include,
      orgId: this.orgId,
      summarized_by: this.summarizedBy,
    };
    return omitBy(options, (value) => value === undefined);
  }

  updateSummaryItem(uuid, backedQuantity) {
    const targetIdx = this.summaryItems.findIndex((item) => item.uuid === uuid);
    if (targetIdx !== -1) {
      this.summaryItems[targetIdx].backedQuantity = backedQuantity;
    }
  }
}

export default AcquisitionSummaryList;
