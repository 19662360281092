const FULFILLABLE_ITEM_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.fulfillableItems.FULFILLABLE_ITEM_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/models/fulfillableItems/FulfillableItemConstants.js
   */
  angular
    .module('binky.core.product.fulfillable-item-list')
    .constant(
      'FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED',
      FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED
    );
})();
