import {Flex, Text} from '@adobe/react-spectrum';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './IconHeaderDescription.pcss';

/**
 * Displays an icon adjacent to a vertically stacked title and subtitle.
 */
const IconHeaderDescription = ({
  gap = 'size-0',
  iconGap = 'size-150',
  IconComponent,
  subtitle,
  title,
  variant = 'default',
}) => {
  const classVariant = styles[`title-${variant}`];
  return (
    <Flex alignItems="center" data-testid="container" direction="row" gap={iconGap}>
      <Flex flexShrink="0">{IconComponent}</Flex>
      <Flex
        data-testid="text-container"
        direction="column"
        gap={gap}
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to allow for truncation
        UNSAFE_className={styles['text-container']}
      >
        <Text
          data-testid="title"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the title
          UNSAFE_className={classnames(styles.title, {
            [classVariant]: variant !== 'default',
          })}
        >
          {title}
        </Text>
        {subtitle && (
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the title
          <Text data-testid="subtitle" UNSAFE_className={styles.subtitle}>
            {subtitle}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

IconHeaderDescription.propTypes = {
  /**
   * The spacing between the title and the subtitle, uses React Spectrum sizing: e.g. size-0
   * See https://react-spectrum.adobe.com/react-spectrum/styling.html#dimension-values
   */
  gap: PropTypes.string,
  /**
   * IconComponent to display, it should have an alt, src, and size props.
   */
  IconComponent: PropTypes.node.isRequired,
  /**
   * The spacing between the icon and the title/subtitle, uses React Spectrum sizing: e.g. size-150
   * See https://react-spectrum.adobe.com/react-spectrum/styling.html#dimension-values
   */
  iconGap: PropTypes.string,
  /**
   * The subtitle information to be displayed.
   * It can be a Node.
   */
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * The header/title information to be displayed next to the icon.
   * It can be a Node.
   */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /**
   * Variant type of the IconHeaderDescription. One of: 'default', 'large'.
   */
  variant: PropTypes.oneOf(['default', 'large']),
};

export default IconHeaderDescription;
