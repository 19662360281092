import {useState} from 'react';

/**
 * @description Hook for managing file selection state. Maintains a unique set
 * of the files selected.
 *
 * @param {Object} params - Object params.
 * @param {Object} [params.initState] - The init state for the file selection. Defaults to empty array.
 * @returns {Object} state - Object composed of state/functions below
 *          {Function} addFiles - Adds files to selection
 *          {Function} removeFiles - Remove files from selection
 *          {Array<File>} selectedFiles - The current selection of files.
 */
const useFileSelection = ({initState = []} = {}) => {
  const [selectedFiles, setSelectedFiles] = useState(initState);

  /**
   * @description Adds given files to the selectedFiles state
   *
   * @param {Array<File>} files - The array of Files to be added to the selection
   */
  const addFiles = (files) => {
    const newSelectedFiles = [...selectedFiles];

    files.forEach((fileToAdd) => {
      const foundRepeat = selectedFiles.some((selectedFile) =>
        isFileEqual(selectedFile, fileToAdd)
      );
      if (!foundRepeat) {
        newSelectedFiles.push(fileToAdd);
      }
    });

    setSelectedFiles(newSelectedFiles);
  };

  /**
   * @description Removes the files from the selected files state.
   *
   * @param {File} files - The array of Files to be removed from the selection.
   */
  const removeFiles = (files) => {
    const filteredFiles = [];

    selectedFiles.forEach((selectedFile) => {
      const foundFileToRemove = files.find((f) => isFileEqual(f, selectedFile));
      if (!foundFileToRemove) {
        filteredFiles.push(selectedFile);
      }
    });

    setSelectedFiles(filteredFiles);
  };

  return {addFiles, removeFiles, selectedFiles};
};

function isFileEqual(f1, f2) {
  return f1.name === f2.name && f1.lastModified === f2.lastModified;
}

export default useFileSelection;
