(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.insights.insights-item-detail-list-table.status
   * @description module that defines insights reports status components
   */
  angular.module('binky.widgets.insights.insights-item-detail-list-table.status', [
    'binky.core.lodash',
    'binky.widgets.common.status',
    'binky.widgets.insights.create-insights-item-detail-modal',
    'pascalprecht.translate',
  ]);
})();
