import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import jilDirectories from 'api/jil/jilDirectories';
import modelCache from 'services/cache/modelCache/modelCache';
import eventBus from 'services/events/eventBus';
import log from 'services/log';

const DIRECTORY_SAML_CONFIG_CACHE_ID = 'DirectorySamlConfig';
const DIRECTORY_SAML_CONFIG_EVENT = {
  UPDATE: 'UpdateDirectorySamlConfig',
};

class DirectorySamlConfig {
  /**
   * @description creates a new DirectorySamlConfig object from fetched data
   * @param {Object} options - options to configure new DirectorySamlConfig
   * @param {String} [options.directoryId] - ID of the directory to fetch config for
   * @param {String} [options.tenantId] - ID of the tenant to fetch config for
   * @returns {DirectorySamlConfig} new DirectorySamlConfig from fetched resource
   */
  static get(options) {
    const model = new DirectorySamlConfig(options);

    const cachedModel = modelCache.get(DIRECTORY_SAML_CONFIG_CACHE_ID, model.key());
    if (cachedModel) {
      return cachedModel;
    }
    return model.refresh();
  }

  /**
  @description instantiate a DirectorySamlConfig object.
  @param {Object} options - options to config the new DirectorySamlConfig.
  @param {String} [options.directoryId] - id of the directory.
  @param {String} [options.orgId] - id of the org to associate this with.
  @param {String} [options.tenantId] - tenantId of the directory.
   */
  constructor(options) {
    Object.assign(this, pick(options, ['directoryId', 'orgId', 'tenantId']));
  }

  getCertificateFileData() {
    if (!this.idpCertificateFileData) {
      return undefined;
    }
    return new Blob([this.idpCertificateFileData], {
      type: 'application/x-pem-file,application/pkix-cert,application/x-x509-ca-cert',
    });
  }

  /**
   * @description get tenant metadata.
   * @returns {Promise} resolves when Tenant Metadata is loaded.
   */
  getTenantMetadata() {
    return jilDirectories.getTenantMetadata(
      omitBy(
        {
          directoryId: this.directoryId,
          orgId: this.orgId,
          tenantId: this.tenantId,
        },
        (value) => value === undefined
      )
    );
  }

  isNew() {
    return !this.idpCertificateFileData;
  }

  /**
   * @description Method to return the current unique key for this SAML config.
   *
   * @returns {String} key to uniquely identify this object
   */
  key() {
    return getKey(this.directoryId, this.tenantId);
  }

  /**
   * @description reload the SAML config.
   * @returns {Promise} resolved when SAML config is loaded.
   */
  async refresh() {
    let response;
    const reqParams = omitBy(
      {
        directoryId: this.directoryId,
        orgId: this.orgId,
        tenantId: this.tenantId,
      },
      (value) => value === undefined
    );

    try {
      response = await jilDirectories.getSamlConfig(reqParams);
      applyResource(this, response);
      modelCache.put(DIRECTORY_SAML_CONFIG_CACHE_ID, this.key(), this);
    } catch (error) {
      log.error(`Failed to refresh DirectorySamlConfig: ${error}`);
      throw error;
    }
    return this;
  }

  /**
   * @description create a SAML configuration.
   * @param {Object} options - configuration object.
   * @param {File} options.idpCertificateFileData - cert file.
   * @param {String} options.idpLoginUrl - login url.
   * @param {String} options.idpIssuer - IdP Issuer.
   * @param {String} options.idpBinding - either "HTTP-POST" or "HTTP-REDIRECT".
   * @param {String} options.userLoginSetting - either "email" or "username".
   * @returns {Promise} resolved with the newly configured model when create config completes.
   */
  async save(options) {
    const reqObj = transformRequest(options);
    const reqParams = omitBy(
      {
        action: this.isNew() ? '' : ':update',
        directoryId: this.directoryId,
        orgId: this.orgId,
        tenantId: this.tenantId,
      },
      (value) => value === undefined
    );
    let response;
    try {
      response = await jilDirectories.postSamlConfig(reqParams, reqObj);
      applyResource(this, response);
      modelCache.put(DIRECTORY_SAML_CONFIG_CACHE_ID, this.key(), this);
      eventBus.emit(DIRECTORY_SAML_CONFIG_EVENT.UPDATE, this);
    } catch (error) {
      log.error(`Failed to save DirectorySamlConfig: ${error}`);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description returns plain JSON object containing only API relevant fields.
   * @returns {Object} saml config object.
   */
  toMinimumModel() {
    return toMinimumObject(this);
  }
}

function applyResource(directorySamlConfig, resource) {
  Object.assign(directorySamlConfig, toMinimumObject(resource));
  return directorySamlConfig;
}
function toMinimumObject(object) {
  return pick(object, [
    'idpBinding',
    'idpCertificateFileData',
    'idpIssuer',
    'idpLoginUrl',
    'userLoginSetting',
  ]);
}

function getKey(directoryId, tenantId) {
  return `${directoryId}/${tenantId}`;
}

function transformRequest(data) {
  const fd = new FormData();
  fd.append(
    'tenantConfig',
    JSON.stringify(
      pick(data, ['idpLoginUrl', 'idpIssuer', 'idpBinding', 'userLoginSetting', 'tenantId'])
    )
  );
  fd.append('samlCertFile', data?.idpCertificateFileData);
  return fd;
}
export default DirectorySamlConfig;
