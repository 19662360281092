import stock from 'api/stock';
import StockQuantity from 'models/stockQuantity/StockQuantity';
import log from 'services/log';

import ModelList from '../modelList/ModelList';

export default class StockQuantitiesList extends ModelList {
  static async get(orgId, id) {
    const model = new StockQuantitiesList({id, orgId});
    await model.refresh();
    return model;
  }

  constructor(options) {
    super(options);
    this.orgId = options.orgId;
    this.id = options.id;
  }

  async refresh() {
    try {
      const response = await stock.getProductQuantities({
        id: this.id,
        orgId: this.orgId,
      });

      this.items = response.data.details.map(initQuantity);
    } catch (error) {
      log.error('License group failed to load. Error: ', error);
      return Promise.reject(error);
    }

    function initQuantity(quantity) {
      return new StockQuantity(quantity);
    }

    return this;
  }
}
