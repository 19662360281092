import invoke from 'lodash/invoke';

import feature from 'services/feature';
import log from 'services/log';
import {loadJs} from 'utils/domUtils';

// configuration holds data from <env>/configuration.495e31ace1.json --> services.fraudMitigation
let configuration, forterToken, sherlock;

/**
 * @description Configure this provider
 * @param {Object} config Configuration object
 * @param {String} config.sherlockLibUrl The source URL for Sherlock library
 * @param {Boolean} [config.prodEnv] Set to 'true' for production. 'false' or empty means non-production
 */
const configure = (config) => {
  configuration = config || {};
};

/**
 * @description Return Forter token from Sherlock.
 * @returns {String} Forter token
 */
const getForterToken = () => {
  if (!forterToken) {
    log.error('Forter token requires Sherlock to be initialized.');
  }
  return forterToken;
};

/**
 * @description Initialize this provider from fraud-mitigation-ready.provider
 * @returns {Promise} The resolves if sherlock successfully initializes. The promise can be rejected due to unexpected error.
 */
const initialize = () => {
  // Don't log an error when fraud mitigation is disabled. Let loadSherlockScript() decide what to log.
  return feature.isEnabled('bumper_fraud_check')
    ? Promise.reject(new Error('Fraud mitigation is disabled.'))
    : loadSherlockScript();

  //////////

  async function loadSherlockScript() {
    const scriptUrl = configuration.sherlockLibUrl;
    if (!scriptUrl) {
      const errMsg = 'Sherlock configuration is missing.';
      log.error(errMsg);
      throw new Error(errMsg);
    }

    try {
      await loadJs(scriptUrl);
      return initializeSherlockScript();
    } catch (error) {
      const errMsg = `Unable to load Sherlock from ${scriptUrl}.`;
      log.error(errMsg, error);
      throw new Error(errMsg);
    }

    //////////

    // Leaving out error handler because the caller has `.catch` block.
    function initializeSherlockScript() {
      // Mostly following specs without passing:
      // - pageName -- because binky doesn't know the pageName
      // - sessionId -- because it's simpler for AC, and Sherlock can maintain its own session
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- @glo00238 to fix
      const SherlockFraudVendors = window.SherlockSdk.SherlockFraudVendors;
      const config = {
        errorCallback: (event) => {
          log.error('Unable to initialize Sherlock', event);
        },
        prodEnv: configuration.prodEnv === true,
        tokenCallback: (token) => {
          forterToken = token;
        },
        vendors: [SherlockFraudVendors.FORTER, SherlockFraudVendors.ADOBE_FRAUD_AI],
      };

      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- @glo00238 to fix
      sherlock = window.SherlockSdk.init(config);

      return Promise.resolve();
    }
  }
};

/**
 * @description Inform fraudMitigation about page change
 * @param {Object} options Page change options
 * @param {String} options.pageName Unique page name without URL and the format doesn't matter as long
 * as the pageName is consistent for the same page, e.g. 'overview.overview2'
 */
const reportPageChange = ({pageName}) => {
  invoke(sherlock, 'reportPageChange', {pageName});
};

const fraudMitigation = {configure, getForterToken, initialize, reportPageChange};
export default fraudMitigation;
