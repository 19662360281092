import {FeaturesCache, Locale, log} from '@admin-tribe/binky';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import {FeatureFlagProvider} from '@pandora/react-feature-provider';
import {LoggerProvider} from '@pandora/react-logger-provider';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getRootPandoraContentModel,
  getRootPandoraContentValue,
} from '../../common/utils/pandora-content/pandoraContentUtils';

import {getStrings} from './locales';

/**
 * A wrapper of Pandora providers.
 */
const PandoraProvider = ({children}) => {
  const language = Locale.get().activeLanguage;
  const messages = getStrings(language);

  const locale = Locale.get().activeLocaleForSpectrum;
  const features = FeaturesCache.get()?.features || [];

  return (
    <ContentEntryProvider
      locale={locale}
      model={getRootPandoraContentModel()}
      value={getRootPandoraContentValue(messages)}
    >
      <LoggerProvider value={log}>
        <FeatureFlagProvider value={features}>{children}</FeatureFlagProvider>
      </LoggerProvider>
    </ContentEntryProvider>
  );
};
PandoraProvider.propTypes = {
  /**
   * The child components to render inside the wrapper
   */
  children: PropTypes.node.isRequired,
};

export default PandoraProvider;
