(function () {
  /**
   * @deprecated Please use src2/core/model/migration/t2e/t2eMigration.js
   *
   * @ngdoc factory
   * @name T2EMigration
   * @description Model for an organization's Type 2 Enhanced migration
   */
  // Disable rule complaining about number in filename and rule suggesting filename should be
  // t2-emigration.service.js
  // eslint-disable-next-line angular/factory-name, angular/file-name
  angular.module('binky.core.migration.t2e').factory('T2EMigration', factory);

  /* @ngInject */
  function factory(binkySrc2) {
    return binkySrc2.models.migration.T2EMigration;
  }
})();
