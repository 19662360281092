/**
 * Client for Floodgate feature flag service
 * @see {@link https://wiki.corp.adobe.com/display/Floodgate/7.9+Obtaining+release+flags+and+feature+decoding}
 */
import axios from 'axios';

import authentication from 'services/authentication';

let clientId, url;

/**
 * Set environment and application level context for Floodgate
 * @param {Object} config - A configuration object.
 * @param {string} config.url - The root url for the Floodgate service api
 * @param {string} config.clientId - The identifier for the consuming application
 */
const configure = (config) => {
  url = `${config.url}/feature`;
  clientId = config.clientId;
};
/**
 * Fetch the Floodgate release and flag state
 * @see {@link https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1308897197}
 *
 * @param {Object} queryParams - The set of key value pairs, as an object, to append to the URL
 *   used to fetch flags. This can be used to 'cohort' based on specific criteria. See the 'context parameter'
 *   description at https://p13n.corp.adobe.com/platform/help/get-fg-api-v3-feature/
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const getReleases = async ({queryParams = {}} = {}) => {
  const options = {
    headers: {
      'X-Api-Key': clientId,
    },
    timeout: 5000,
  };

  const token = authentication.getAccessToken();
  if (token && token.token) {
    options.headers.Authorization = `Bearer ${token.token}`;
  }

  let getUrl = `${url}?clientId=${clientId}`;
  Object.entries(queryParams).forEach(([key, val]) => {
    getUrl += `&${key}=${encodeURIComponent(val)}`;
  });

  const response = await axios.get(getUrl, options);
  return response.data;
};

const floodgate = {configure, getReleases};
export default floodgate;
