(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.navigation.responsive-nav.component:binkyResponsiveNavItem
   * @description Component that displays a responsive menu item. A menu item can
   *   either be an individual link or a dropdown menu, depending on the type of
   *   ResponsiveNavItem passed into this component (if ResponsiveNavItem is a group,
   *   it will create a dropdown menu, otherwise, it will display the link).
   * @param {ResponsiveNavItem} item - Reference to item that represents this
   *   menu item
   * @param {Boolean} [nested] - true if this menu item is nested within a
   *   dropdown menu, else false (optional; defaults to false)
   * @param {String} orientation - Either 'horizontal' or 'vertical'; describes
   *   the layout of the navigation menu this item exists within
   * @param {String} [variant] - Currently only 'light' is recognized; describes
   *   the styling of the navigation menu this item exists within - light is
   *   a slightly smaller menu with slightly smaller fonts and a white background,
   *   often present in page menus, while the default variant is larger, has a
   *   dark background, and is commonly used for workspace menus
   */
  angular.module('binky.shell.navigation.responsive-nav').component('binkyResponsiveNavItem', {
    bindings: {
      item: '<',
      nested: '<?',
      orientation: '@',
      variant: '@?',
    },
    controller,
    templateUrl: 'shell/navigation/responsive-nav/responsive-nav-item.component.html',
  });

  /* @ngInject */
  function controller($translate, _) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      isCheckDisplayed,
    });

    /**
     * @description Initialize component
     */
    function $onInit() {
      vm.icon = _.get(vm, 'item.groupIcon');
      vm.label = _.get(vm, 'item.groupName');
      // we translate the name here to ensure that the nested usage inside
      // ng-attr-title doesn't get computed as JS (ie, if it contains {{ }})
      const name = _.get(vm, 'item.navItem.name');
      if (!_.isEmpty(name)) {
        $translate(name)
          .then((result) => {
            vm.translatedName = result;
          })
          // This is hit when the name is not matched to a key in the
          // locale files.
          .catch(() => {
            // If the name is sanitized, we trust it to display HTML
            if (_.get(vm, 'item.navItem.nameSanitized')) {
              vm.translatedName = name;
            }
            // Otherwise, we do nothing here because we want to know in
            // the HTML if the name is coming via translation or not
          });
      }
    }

    /**
     * @description Determine if check next to item should be displayed. Checks
     *   are only displayed next to a navigation item if the item in question
     *   represents the current location/view, the item is located in a dropdown
     *   menu, and that dropdown menu is located within a horizontally-oriented
     *   navigation menu.
     * @returns {Boolean} true if check should be displayed, else false
     */
    function isCheckDisplayed() {
      return (
        vm.orientation === 'horizontal' &&
        !!vm.nested &&
        vm.item.navItem.containsRouteToCurrentLocation()
      );
    }
  }
})();
