// Loads modules implicitly required by the legacy Angular code in /src. The
// import order is based on the dependency order generated by bower, and takes
// into account cross-library dependencies
import 'angular-material/angular-material.css';
import '@adobe/coral-spectrum/dist/css/coral.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import '../../../src/binky-ui.scss';

import './angularGlobalDependencies';
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'chart.js';
import 'angular-chart.js';
import 'angular-messages';
import 'angular-material';
import 'angular-moment';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-translate';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';
import 'angular-ui-router';
import 'angular-utils-pagination';
import 'angular-dynamic-locale';
// eslint-disable-next-line lodash/import-scope -- required to set window._ as expected by the Angular app
import 'lodash';
import 'moment-timezone';
import '@admin-tribe/binky';
import 'coralui-support-angular/build/ngCoral';
import 'intl-tel-input/build/js/utils';

import '../../../.tmp/angularSrcIndex';

import {prepareLibraryTemplateCache} from './angularTemplateCacheLoader';

prepareLibraryTemplateCache();
