(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum Switch
   */
  angular.module('binky.widgets.common.toggle').component('binkyToggle', {
    bindings: {
      isChecked: '<?',
      isDisabled: '<?',
      labelledby: '@',
      onChange: '&?',
    },
    templateUrl: 'widgets/common/toggle/toggle.component.html',
  });
})();
