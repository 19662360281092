(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum components
   *
   * @ngdoc component
   * @name binky.widgets.common.popover-button
   *
   * @description popover button to hold a button option that has been triggered from an ellipsis menu
   */
  angular.module('binky.widgets.common.popover-button').component('binkyPopoverButton', {
    bindings: {
      icon: '@?',
      iconSize: '@?',
      label: '@?',
      tooltip: '@?',
      variant: '@?',
    },
    controller,
    templateUrl: 'widgets/common/popover-button/popover-button.component.html',
    transclude: true,
  });

  /* @ngInject */
  function controller($element, $transclude, _) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      $postLink,
    });

    function $onInit() {
      _.defaults(vm, {
        icon: 'moreVertical',
        iconSize: 'S',
        variant: 'minimal',
      });
    }

    function $postLink() {
      const showPopoverButton = new Coral.Button();
      showPopoverButton.set(
        _.assign(
          {
            class: 'popover-button',
          },
          _.pick(vm, ['icon', 'iconSize', 'variant'])
        )
      );

      if (vm.label) {
        angular.element(showPopoverButton).append(vm.label);
      }

      angular.element(showPopoverButton).on('click', (e) => e.stopPropagation());

      showPopoverButton.classList.add('popover-button');
      $element[0].appendChild(showPopoverButton);
      const popover = $element.find('coral-popover');
      popover[0].set({target: showPopoverButton});

      $transclude(($linkNode) => {
        _.forEach($linkNode, (node) => {
          if (isCoralPopoverContent(node)) {
            $element.find('coral-popover-content').append(node);
          }
        });
      });

      if (vm.tooltip) {
        addButtonTooltip(showPopoverButton);
      }
    }

    function addButtonTooltip(button) {
      const popoverTooltip = new Coral.Tooltip().set({
        content: {
          innerHTML: vm.tooltip,
        },
        interaction: 'off',
        placement: 'top',
        target: button,
      });

      button.on('mouseenter', onMouseEnter);
      button.on('mouseleave', onMouseLeave);
      button.on('click', onClick);

      $element[0].appendChild(popoverTooltip);

      function onClick() {
        popoverTooltip.open = false;
      }

      function onMouseEnter() {
        popoverTooltip.open = true;
      }

      function onMouseLeave() {
        popoverTooltip.open = false;
      }
    }

    function isCoralPopoverContent(node) {
      return _.chain(node).get('localName').isEqual('coral-popover-content').value();
    }
  }
})();
