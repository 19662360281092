(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/Consumable.js
   *
   * @ngdoc factory
   * @name Consumable
   * @description Model for an individual Consumable
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Consumable
   */
  angular.module('binky.core.product.consumable').factory('Consumable', getConsumableModel);

  /* @ngInject */
  function getConsumableModel(_, CONSUMABLE_THRESHOLD_STATUS, ConsumableUsageList) {
    class Consumable {
      /**
       * @description Creates a new Consumable for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Number} options.consumedQuantity total used, summary across all usage instances intersecting criteria, i.e. combined usage for a particular license
       * @param {String} options.fulfillableItemCode The Fulfillable Item Code for the consumable, i.e. "stock_image"
       * @param {Number} [options.overConsumedQuantity] (optional) n/a for stock; from Sign
       * @param {Boolean} [options.overQuota] (optional) n/a for stock; from Sign
       * @param {Number} options.remainingQuantity total remaining, summary of remaining across all usage instances intersecting criteria
       * @param {String} [options.thresholdStatus] (optional) possible values defined by CONSUMABLE_THRESHOLD_STATUS
       * @param {Number} [options.thresholdValue] (optional) when a threshold_status suggests a value, such as a percentage, value computed by consumable system
       * @param {Number} options.totalQuantity total units, summary across all usage instances intersecting criteria, i.e. 5 qty of Stock 40 packs = 200 total
       * @param {Object} options.unit the units of the consumable, including pluralization information
       * @param {Number} options.usageTotal total number of "usage" elements, which may exceed the "usages" array length returned when paging is required
       * @param {Array} options.usages A Consumables usage elements (may be incomplete)
       * @param {ConsumableSummary} parentSummary the parent ConsumableSummary this Consumable belongs to
       */
      constructor(options, parentSummary) {
        this.parentSummary = parentSummary;
        initModel(this, options);
      }

      /**
       * @description Get the query params needed to get the usages for this Consumable
       *
       * @returns {Object} Object of params used in the query to get the usages
       */
      getQueryParams() {
        const queryParams = _.invoke(this, 'parentSummary.getTopLevelQueryParams') || {};
        return _.assignIn(queryParams, {fulfillableItemCode: this.fulfillableItemCode});
      }

      /**
       * @description Method to determine if this Consumable has threshold status of 'DEPLETED'.
       * @returns {Boolean} true if threshold reached, false otherwise
       */
      isThresholdStatusDepleted() {
        return this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.DEPLETED;
      }

      /**
       * @description Method to determine if Consumable has threshold status of 'PERCENTAGE_THRESHOLD'.
       * @returns {Boolean} true if threshold reached, false otherwise
       */
      isThresholdStatusPercentageThreshold() {
        return this.thresholdStatus === CONSUMABLE_THRESHOLD_STATUS.PERCENTAGE_THRESHOLD;
      }

      /**
       * @description Method to determine if this Consumable has reached one of its thresholds.
       * @returns {Boolean} true if a threshold reached, false otherwise
       */
      isThresholdTriggered() {
        return this.isThresholdStatusPercentageThreshold() || this.isThresholdStatusDepleted();
      }

      /**
       * @class
       * @description Transforms the /consumable summary response Object into a new Consumable
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @param {ConsumableSummary} parentSummary the parent ConsumableSummary this Consumable belongs to
       */
      static apiResponseTransformer(dataTransferObject, parentSummary) {
        return new Consumable(dataTransferObject, parentSummary);
      }
    }

    //////////////

    function initModel(model, options) {
      // First we assign the model fields
      _.assignIn(
        model,
        _.pick(options, [
          'consumedQuantity',
          'fulfillableItemCode',
          'overConsumedQuantity',
          'overQuota',
          'remainingQuantity',
          'thresholdStatus',
          'thresholdValue',
          'totalQuantity',
          'unit',
          'usageTotal',
        ])
      );

      model.consumableUsageList = new ConsumableUsageList(
        {
          items: options.usages,
          usageTotal: model.usageTotal,
        },
        model
      );
    }

    return Consumable;
  }
})();
