import {
  CONTRACT_EXPIRATION_PHASE,
  CONTRACT_STATUS,
  getExpirationPhaseFromContractComplianceSymptoms,
} from '@admin-tribe/binky';

const STATUS_TO_STATUS_LABEL_AND_LIGHT_VARIANT = {
  [CONTRACT_STATUS.ACTIVE]: {
    labelId: 'binky.common.contractExpirationStatus.active',
    variant: 'positive',
  },
  [CONTRACT_STATUS.CANCELLING]: {
    labelId: 'binky.common.contractExpirationStatus.cancelling',
    variant: 'notice',
  },
  [CONTRACT_STATUS.EXPIRED]: {
    labelId: 'binky.common.contractExpirationStatus.expired',
    variant: 'negative',
  },
  [CONTRACT_STATUS.INACTIVE]: {
    labelId: 'binky.common.contractExpirationStatus.inactive',
    variant: 'neutral',
  },
  [CONTRACT_STATUS.MIGRATED]: {
    labelId: 'binky.common.contractExpirationStatus.migrated',
    variant: 'notice',
  },
  [undefined]: {
    labelId: 'binky.common.contractExpirationStatus.unknown',
    variant: 'negative',
  },
};

const PHASE_TO_STATUS_LABEL_ID_AND_LIGHT_VARIANT = {
  [CONTRACT_EXPIRATION_PHASE.NORMAL]: {
    labelId: 'binky.common.contractExpirationStatus.active',
    variant: 'positive',
  },
  [CONTRACT_EXPIRATION_PHASE.NOTIFICATION]: {
    labelId: 'binky.common.contractExpirationStatus.expiring',
    variant: 'notice',
  },
  [CONTRACT_EXPIRATION_PHASE.GRACE]: {
    labelId: 'binky.common.contractExpirationStatus.expired',
    variant: 'negative',
  },
  [CONTRACT_EXPIRATION_PHASE.POST_GRACE]: {
    labelId: 'binky.common.contractExpirationStatus.expired',
    variant: 'negative',
  },
  [CONTRACT_EXPIRATION_PHASE.INACTIVE]: {
    labelId: 'binky.common.contractExpirationStatus.inactive',
    variant: 'neutral',
  },
  [CONTRACT_EXPIRATION_PHASE.UNKNOWN]: {
    labelId: 'binky.common.contractExpirationStatus.unknown',
    variant: 'negative',
  },
};

/**
 * @description Get contract expiration status
 * @param {String} contractStatus One of CONTRACT_STATUS values
 * @param {String} expirationPhase One of CONTRACT_EXPIRATION_PHASE values
 * @param {Boolean} isContractEtlaOrAllocation Whether the contract is ETLA or Allocation.
 * @returns {Object} Expiration status mapping for display:
 *   {
 *     labelId: string translation ID,
 *     variant: one of StatusLight variant,
 *   }
 */
const getContractExpirationStatus = ({
  contractStatus,
  expirationPhase,
  isContractEtlaOrAllocation,
}) =>
  isContractEtlaOrAllocation
    ? PHASE_TO_STATUS_LABEL_ID_AND_LIGHT_VARIANT[expirationPhase]
    : STATUS_TO_STATUS_LABEL_AND_LIGHT_VARIANT[contractStatus];

/**
 * @description Get the localized label for contract expiration
 * @param {String} contractStatus One of CONTRACT_STATUS values
 * @param {String} expirationPhase One of CONTRACT_EXPIRATION_PHASE values
 * @param {Object} intl react-intl
 * @param {Boolean} isContractEtlaOrAllocation Whether the contract is ETLA or Allocation.
 * @returns {String} Localized label for contract status
 */
const getContractExpirationStatusText = ({
  contractStatus,
  expirationPhase,
  intl,
  isContractEtlaOrAllocation,
}) => {
  const {labelId} = getContractExpirationStatus({
    contractStatus,
    expirationPhase,
    isContractEtlaOrAllocation,
  });
  return intl.formatMessage({id: labelId});
};

/**
 * @description Get the localized label for contract expiration
 * @param {Contract} contract Contract instance
 * @param {Object} intl react-intl
 * @returns {String} Localized label for contract status
 */
const getContractExpirationStatusTextFromContract = ({contract, intl}) =>
  getContractExpirationStatusText({
    contractStatus: contract.status,
    expirationPhase: getExpirationPhaseFromContractComplianceSymptoms(contract),
    intl,
    isContractEtlaOrAllocation: contract.isBuyingProgramETLA() || contract.isModelAllocation(),
  });

export {
  getContractExpirationStatus,
  getContractExpirationStatusText,
  getContractExpirationStatusTextFromContract,
};
