import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {DATE_FORMATS} from '../formatted-date-and-time/FormattedDateAndTime';
import PageBanner from '../page-banner/PageBanner';

/**
 * A component that will display contract expired banner for etla usage report.
 */
const ExpiredContractBanner = ({postGraceEndDate}) => {
  const intl = useIntl();
  const graceEndDateFormatted = intl.formatDate(
    new Date(postGraceEndDate * 1000),
    DATE_FORMATS.dateOnly
  );
  return (
    <PageBanner
      header={intl.formatMessage({id: 'binky.widgets.reports.warning.expiredContract.label'})}
      variant="warning"
    >
      {intl.formatMessage(
        {id: 'binky.widgets.reports.warning.expiredContract.message'},
        {graceEndDate: graceEndDateFormatted}
      )}
    </PageBanner>
  );
};
ExpiredContractBanner.propTypes = {
  /**
   * A date in seconds since epoch till dashboard data will be available for expired contract
   */
  postGraceEndDate: PropTypes.number.isRequired,
};

export default ExpiredContractBanner;
