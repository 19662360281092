(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.products.product-assignment
   * @description A product assignment widget used to select products for assignment
   */
  angular.module('binky.widgets.products.product-assignment', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.common.popover',
    'binky.widgets.products.product-assignment-pulldown',
    'binky.widgets.products.product-icon',
    'ngCoral',
  ]);
})();
