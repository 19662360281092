(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.directories:DirectoryList
   * @description fetch the list of directories for an org.
   */
  angular.module('binky.core.directories').factory('DirectoryList', directoryList);

  /* @ngInject */
  function directoryList(
    $log,
    $q,
    $rootScope,
    _,
    Directory,
    DIRECTORY_EVENT,
    DIRECTORY_LIST_CACHE_ID,
    DIRECTORY_LIST_EVENT,
    DIRECTORY_SAML_CONFIG_EVENT,
    DIRECTORY_STATUS,
    DIRECTORY_SYNC_EVENT,
    DOMAIN_LIST_EVENT,
    feature,
    JIL_CONSTANTS,
    jilDirectories,
    jilTrusts,
    kms,
    List,
    modelCache,
    ORGANIZATION_TYPE
  ) {
    class DirectoryList extends List {
      /**
       * @description instantiate the list of Directories for an Organization.
       * @param {Object} [options] - options to configure the DirectoryList
       * @param {String} [options.orgId] - associates DomainList instance with an org.
       * @param {Object} [options.ownershipStatus] the ownership status to filter this list to.
       * @param {Object} [options.status] the status to filter this list to.
       */
      constructor(options = {}) {
        super({
          // we don't cache this in the List because it needs to be merged with KMS data
          itemClassRef: Directory,
          resource: jilDirectories.directories,
          sortExpression: JIL_CONSTANTS.SORT.NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
        this.$resolved = false;
        this.hasEncryptionInfo = false;
        this.orgId = options.orgId;
        this.orgType = options.orgType;
        this.ownershipStatus = options.ownershipStatus;
        this.status = options.status;
      }

      /**
       * @description overidden key method to be used when we fetch models from modelCache
       *
       * @returns {String} key formed from params.status (if present) and the orgId
       */
      key() {
        return super.key(getParams(this));
      }

      /**
       * @description reload the Directory list and the encryption data
       * @param {Object} [options] the refresh options. Default is to retrieve all statuses.
       * @param {String} [options.status] to filter by status specify one of DIRECTORY_STATUS. This will become
       *   the model's new status value. Set to undefined to return all status values. If not specified the modal's
       *   current status value will be used.
       * @param {String} [options.ownershipStatus] to filter by ownershipStatus specify one of
       *   DIRECTORY_OWNERSHIP_STATUS. This will become the modal's new ownershipStatus value. Set to undefined to
       *   return all ownershipStatus values.  If not specified the modal's current status value will be used.
       * @returns {Promise} resolved when Directory list fetch completes.
       */
      refresh(options = {}) {
        _.assign(this, _.pick(options, ['ownershipStatus', 'status']));

        this.$resolved = false;
        const promises = {
          directories: this.refreshDirectories(),
          encryptionInfo: requiresEncryptionInfo(this.status, this.orgType)
            ? kms.encryptedDirectories.query(
                _.omitBy(
                  {
                    orgId: this.orgId,
                  },
                  _.isUndefined
                )
              ).$promise
            : null,
        };

        this.$promise = $q
          .all(promises)
          .then(resolveEncryptionStatus.bind(this))
          .then(onSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          _.forEach(this.items, (directory) => {
            directory.setOrgId(this.orgId);
          });

          // The directory list has changed.
          // Clear the cache to ensure if there are any variants of the list with different keys they are also removed.
          modelCache.removeAll(DIRECTORY_LIST_CACHE_ID);

          // Normally we could use the parent List.refresh cache behaviour, but
          // as this combines the List.refresh call with KMS data we need to handle
          // caching here instead.
          modelCache.put(DIRECTORY_LIST_CACHE_ID, this, this.key());

          $rootScope.$emit(DIRECTORY_LIST_EVENT.UPDATE);
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(DIRECTORY_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
          }
          return $q.resolve(this);
        }

        return this.$promise;

        ///////////

        function requiresEncryptionInfo(directoryStatus, orgType) {
          if (feature.isEnabled('temp_org_asset_encryption_migrated')) {
            return false;
          }
          if (orgType !== ORGANIZATION_TYPE.ENTERPRISE) {
            return false;
          }

          return (
            _.isNil(directoryStatus) ||
            directoryStatus === DIRECTORY_STATUS.ACTIVE ||
            directoryStatus === DIRECTORY_STATUS.NEEDS_DOMAIN
          );
        }
      }

      /**
       * @description reload the Directory list without reloading the encryption data
       * @returns {Promise} resolved when Directory list fetch completes.
       */
      refreshDirectories() {
        return super.refresh(getParams(this));
      }

      /**
       * @description Sends requests for access to the specified directories to the respective
       *   directory owners. Subsequently refreshes this directory list, since pending access
       *   requests manifest as items in the directory list.
       *
       * @param {Array} directories the directories to request access to. Each directory in the
       *   array must have an id property.
       * @returns {Promise} resolved when the operation completes.
       */
      requestAccess(directories) {
        const trustRequests = _.map(directories, (directory) => ({directoryId: directory.id}));

        return jilTrusts.trusts
          .sendTrustRequests(trustRequests)
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this));

        function onSuccess(successfulTrustRequests) {
          return this.refresh().then(() => successfulTrustRequests);
        }

        function onError(error) {
          $log.error('Failed to request access to directories. Error: ', error);
          return $q.reject(error);
        }
      }

      /**
       * @description Saves changes to the back-end.
       *   As currently coded it will save added items if there are any OR save removed items if there are any.
       *   It does not do both. If we need both operations together, we can add that complexity later.
       *
       * @returns {Promise} resolves with the modified directories if changes successfully saved, else
       *   rejects with error message
       */
      save() {
        if (this.hasUnsavedChanges()) {
          if (this.removedItems.length > 0) {
            this.$resolved = false;

            const request = generateRemovePayload.call(this);
            this.$promise = this.resource
              .batchOperation({}, request)
              .$promise.then(onRemoveSuccess.bind(this))
              .catch(onError.bind(this))
              .finally(() => {
                this.$resolved = true;
              });
            return this.$promise;
          }
          return $q.reject('unsupported operation');
        }
        // Nothing to save
        return $q.resolve([]);

        function onRemoveSuccess(deletedDirectories) {
          // The removedItems array represents directories which should be removed on save, so remove any
          // directories which were successfully removed.
          this.removedItems = _.differenceBy(this.removedItems, deletedDirectories, 'id');

          this.filter.lastQuery = this.filter.query;
          this.filter.query = '';

          return this.refresh().then(() => deletedDirectories);
        }

        function onError(error) {
          $log.error('Failed to remove items in directory list. Error: ', error);
          return $q.reject(error);
        }

        function generateRemovePayload() {
          return _.map(this.removedItems, (item) => ({
            op: 'remove',
            path: `/${item.id}`,
          }));
        }
      }

      /**
       * @description Method to determine whether we need to update the total itemCount of a list.
       *  Do not update count if refresh was due to either a search or a status filter.
       *
       * @return {Boolean} true if the total itemCount needs to be updated
       */
      shouldUpdateTotalItemCount() {
        return (
          super.shouldUpdateTotalItemCount() && _(this).thru(getParams).omit(['orgId']).isEmpty()
        );
      }

      /**
       * @description Method to modify sort parameters of the directory list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description create a new directory
       * @param {Directory} directory - directory to create
       * @param {String} directory.name - directory name
       * @param {DIRECTORY_TYPE} directory.type - Enterprise/Federated ID
       * @returns {Promise} resolved with created directory
       */
      static create(directory) {
        return jilDirectories.directories.save(directory).$promise.then(resolveCreate);

        ///////////

        function resolveCreate(resource) {
          return Directory.apiResponseTransformer(resource);
        }
      }

      /**
       * @description instantiates a new instance of DirectoryList.
       * @param {Object} options - options to configure the DirectoryList
       * @param {String} [options.orgId] - associates DirectoryList instance with an org.
       * @param {Object} [options.ownershipStatus] the ownership status to filter this list to.
       * @param {Object} [options.pageSize] set to undefined to query for all directories.
       * @param {Object} [options.status] the status to filter this list to.
       * @returns {DirectoryList} DirectoryList model object.
       */
      static get(options = {}) {
        let model = new DirectoryList(options);
        if (_.has(options, 'pageSize') && _.isUndefined(options.pageSize)) {
          model.pagination.pageSize = undefined;
        }

        const cachedModel = modelCache.get(DIRECTORY_LIST_CACHE_ID, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }

      /**
       * @description Method to determine which of the selectedDomains may be moved. This will query for the entire
       *   directory list, not just the first page's worth. Since it uses refresh, the encryption data is merged
       *   into the directoryList before the directionList is cached.
       *
       * @param {String} Object.orgId organization id
       * @param {String} Object.orgType organization type
       * @param {Array} Object.selectedDomains array of domains which have been selected to be moved
       * @returns {Object} containing array of availableDirectories, array of domainsToMigrate and array of
       *   domainsNotMigrated. Directories associated with the selectedDomains are not available.
       */
      static queryDirectories({orgId, orgType, selectedDomains}) {
        const model = DirectoryList.get({orgId, orgType, pageSize: undefined});
        return model.$promise.then((response) => {
          // Remove directories associated with 'selectedDomains' from the list of available directories and
          // any that don't allow migration.
          const availableDirectories = _.reject(
            response.items,
            (directory) =>
              !directory.allowDomainMigration() ||
              _.some(selectedDomains, ['directoryId', directory.id])
          );

          let domainsNotMigrated;
          let domainsToMigrate = [];
          if (_.isEmpty(availableDirectories)) {
            domainsNotMigrated = selectedDomains;
          } else {
            // Partition directories into those that can be migrated and those that can't.
            const domainLists = _.partition(selectedDomains, (domain) => {
              const directory = _.find(response.items, ['id', domain.directoryId]);
              return _.invoke(directory, 'allowDomainMigration');
            });

            domainsToMigrate = domainLists[0];
            domainsNotMigrated = domainLists[1];
          }

          return {availableDirectories, domainsNotMigrated, domainsToMigrate};
        });
      }
    }

    // We register the cache size for this class.
    modelCache.register(DIRECTORY_LIST_CACHE_ID, 2);
    modelCache.removeAllOnEvent(DIRECTORY_LIST_CACHE_ID, [
      DIRECTORY_EVENT.UPDATE,
      DIRECTORY_SAML_CONFIG_EVENT.UPDATE,
      DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED,
      DOMAIN_LIST_EVENT.UPDATE,
    ]);

    return DirectoryList;

    ///////////

    function getParams(list) {
      return _.omitBy(
        {orgId: list.orgId, ownershipStatus: list.ownershipStatus, status: list.status},
        _.isUndefined
      );
    }

    /**
     * @description If encryptionInfo present, unify data objects from JIL and KMS into single model
     *  object. encryptionInfo only applies if retrieving all directories or only active directories.
     * @param {Object} data object composed from $q.all promises
     * @param {Object} data.directories directory list response object.
     * @param {Object} [data.encryptionInfo] if status is nil or DIRECTORY_STATUS.ACTIVE, the encryption data
     *  response object.
     */
    function resolveEncryptionStatus(data) {
      this.hasEncryptionInfo = _.get(data, 'encryptionInfo.length') > 0;

      if (!this.hasEncryptionInfo) {
        return;
      }

      this.items = _.map(data.directories.items, (directory) => {
        const directoryExternalId = _.get(directory, 'externalId');
        const encryptionStatusInfo = _.find(data.encryptionInfo, (encryptionInfoResource) => {
          const encryptionInfoDirectoryId = _.get(encryptionInfoResource, 'domainId');
          return (
            directoryExternalId &&
            encryptionInfoDirectoryId &&
            // There are directories in which the case of the ids don't match, so do a case insensitive comparison
            // https://git.corp.adobe.com/admin-tribe/onesie/issues/811
            _.toLower(directoryExternalId) === _.toLower(encryptionInfoDirectoryId)
          );
        });
        // There will only be encryptionStatusInfo if this directory is encrypted, which implies ACTIVE.
        // Otherwise use the defaults already set on the model.
        if (encryptionStatusInfo) {
          directory.encryptionStatusInfo = encryptionStatusInfo;
        }
        return directory;
      });
    }
  }
})();
