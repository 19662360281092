import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Configure JIL Locale APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL organizations api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches the preferred locale for an organization.
 *
 * @param {Object} options - top level wrapper object.
 * @param {string} options.orgId - ID of the org whose preferred locale will be
 *     fetched.
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the preferred locale for an organization.
 */
const getOrganizationLocale = ({orgId}) =>
  axios.get(`${url}/v2/organizations/${orgId}/locale`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Updates the preferred locale for an organization.
 *
 * @param {Object} options - top level wrapper object.
 * @param {string} options.orgId - ID of the org that will be updated.
 * @param {string} options.locale - locale code to be set.
 * @returns {Promise} Resolves to the axios response object.
 */
const putOrganizationLocale = ({orgId, locale}) =>
  axios.put(
    `${url}/v2/organizations/${orgId}/locale`,
    {locale},
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilOrganizationLocale = {
  configure,
  getOrganizationLocale,
  putOrganizationLocale,
};

export default jilOrganizationLocale;
