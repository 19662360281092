import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL product solution group APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Products api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Fetches all solution groups for the given product
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 */
const getProductSolutionGroups = async ({orgId, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/solution-groups`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

const jilProductSolutionGroups = {configure, getProductSolutionGroups};

export default jilProductSolutionGroups;
