import {Item, Picker} from '@adobe/react-spectrum';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ProductRolePicker = ({defaultRoleId, onRoleChange, productRoles}) => {
  const intl = useIntl();

  const sortedProductRolesByName = sortBy(productRoles, ['name']);

  const defaultSelectedKey = React.useMemo(() => {
    const preferredProductRole = productRoles.find((role) => role.preferred || role.default);
    const retVal = defaultRoleId || preferredProductRole?.id;

    onRoleChange(retVal);
    return retVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- trigger onRoleChange callback function for default value one time per intiialization
  }, [defaultRoleId, productRoles]);

  return (
    <Picker
      data-testid="product-role-picker"
      defaultSelectedKey={defaultSelectedKey}
      label={intl.formatMessage({id: 'binky.common.productRolePicker.roleLabel'})}
      onSelectionChange={onRoleChange}
      placeholder={intl.formatMessage({id: 'binky.common.productRolePicker.pickerPlaceholder'})}
    >
      {sortedProductRolesByName.map((productRole) => (
        <Item key={productRole.id}>{productRole.name}</Item>
      ))}
    </Picker>
  );
};

ProductRolePicker.propTypes = {
  defaultRoleId: PropTypes.string,
  onRoleChange: PropTypes.func,
  productRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default ProductRolePicker;
