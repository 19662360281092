//
// App Configuration - auth
//
(function () {
  /**
   * @awaitingdeprecation
   */
  angular
    .module('binky.core.authentication.ready')
    .provider('authenticationReady', authenticationReady);

  /* @ngInject */
  function authenticationReady(_, authenticationProvider, configurationReadyProvider) {
    const initInjector = angular.injector(['ng']);
    const $q = initInjector.get('$q');

    let includeRoles, requiredRoles;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure() {
      return configurationReadyProvider
        .whenConfigReady()
        .then(onConfigurationReadyProviderFulfilled);

      ////////////

      function onConfigurationReadyProviderFulfilled(configData) {
        authenticationProvider.configure(_.get(configData, 'services.ims'));

        includeRoles = _.get(configData, 'includeRoles');
        requiredRoles = _.get(configData, 'ready.authentication.requiredRoles');
      }
    }

    /* @ngInject */
    function $get(
      AuthenticatedUser,
      authentication,
      binkySrc2,
      localeReady,
      AUTHENTICATION_READY_ERROR
    ) {
      const runDeferred = $q.defer();

      const AUTHENTICATION_ERROR = _.get(binkySrc2, 'services.AUTHENTICATION_ERROR');

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        return localeReady
          .whenRunReady()
          .then((activeLocale) => {
            authentication.setLocale(activeLocale);
            return authentication.initialize();
          })
          .then(onImsProfileSuccess)
          .catch((error) => {
            if (error.message === AUTHENTICATION_ERROR.USER_SIGNED_OUT) {
              runDeferred.reject(AUTHENTICATION_READY_ERROR.USER_SIGNED_OUT);
            } else {
              runDeferred.reject(error);
            }
          });
      }

      function onImsProfileSuccess(profile) {
        const authenticatedUser = new AuthenticatedUser(profile);
        const userRoles = authenticatedUser.getRoles();
        // if a list of supported roles were provided, we filter down to only those
        if (!_.isEmpty(includeRoles)) {
          userRoles.filterTo(includeRoles);
        }
        // if a list of required roles were provided, we reject if one is not present
        if (!_.isEmpty(requiredRoles) && !userRoles.anyOf(requiredRoles)) {
          return runDeferred.reject(AUTHENTICATION_READY_ERROR.REQUIRED_ROLES_NOT_FOUND);
        }
        return runDeferred.resolve(authenticatedUser);
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
