(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.shell.workspace.wait-on-error')
    .factory('waitOnError', getWaitOnErrorService);

  /* @ngInject */
  function getWaitOnErrorService($log, WAIT_ON_ERROR) {
    return {
      getBumperStrings,
    };

    ////////////

    /**
     * @description Fetches bumper strings for a provided error.
     * @param {Object} err - the error object to process
     * @returns {Object} an object containing a details and title key for the bumper
     */
    function getBumperStrings(err) {
      let bumperStrings;
      switch (err) {
        case WAIT_ON_ERROR.NOT_FOUND:
          bumperStrings = {
            details: 'binky.shell.workspace.bumpers.notFound.details',
            title: 'binky.shell.workspace.bumpers.notFound.title',
          };
          break;
        default:
          $log.error(err);
          bumperStrings = {
            details: 'binky.shell.workspace.bumpers.generic.details',
            title: 'binky.shell.workspace.bumpers.generic.title',
          };
          break;
      }
      return bumperStrings;
    }
  }
})();
