import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Description component used for Page.
 */
const PageDescription = ({children}) => <Text data-testid="page-description">{children}</Text>;

PageDescription.displayName = 'PageDescription';
PageDescription.propTypes = {
  /**
   * The description string
   */
  children: PropTypes.node.isRequired,
};

export default PageDescription;
