import {dispatchUiEventAnalytics, log} from '@admin-tribe/binky';
import {ButtonGroup, Dialog, View} from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import {Toast} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import PanelWait from '../panel-wait/PanelWait';

import styles from './ModalDialog.pcss';
import {ModalDialogContextProvider} from './ModalDialogContext';
import ModalButtonGroup from './button-group/ModalButtonGroup';
import {ModalContainerContext} from './modal-container/ModalContainer';

const STYLE_PROPS = {
  alert: {
    spaceX: 'size-400',
    width: '480px',
  },
  modal: {
    spaceX: 'size-400',
    width: '1100px',
  },
};

function getReactSpectrumWidgetIfIncludedInChildren(children, widget) {
  const childrenArr = React.Children.toArray(children);
  return childrenArr && childrenArr.find((child) => child.type === widget);
}

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-modal-dialog
 */

// This is a wrapper class around Spectrum's own Dialog class, for defining
// common Dialog features and logic shared across Binky codebases

const ModalDialog = ({
  analyticsContextFunc,
  cancelLabel,
  children,
  closeModal,
  ctaLabel,
  ctaVariant,
  ctaToastGenerator,
  errorMessage,
  errorToastProps = {},
  hideCancelButton,
  id,
  isCtaDisabled,
  isLoading,
  mode = 'modal',
  onCancel,
  onCloseError,
  onCta,
  onSecondary,
  secondaryLabel,
  heightVariant = 'default',
  ...dialogProps
}) => {
  const styleProps = STYLE_PROPS[mode];

  const modalRef = React.useRef();

  const [showError, setShowError] = React.useState(false);
  const [modalDescribedBy, setModalDescribedBy] = React.useState(dialogProps['aria-describedby']);

  const {onDismiss} = useContext(ModalContainerContext) || {};

  React.useEffect(() => {
    if (onSecondary && !secondaryLabel) {
      log.warn(
        'You have provided a secondary button callback but no label, the secondary button will not be included unless both are provided.'
      );
    } else if (!onSecondary && secondaryLabel) {
      log.warn(
        'You have provided a secondary button label but no callback, the secondary button will not be included unless both are provided.'
      );
    }
  }, [onSecondary, secondaryLabel]);

  React.useEffect(() => {
    setShowError(!!errorMessage);
  }, [errorMessage]);

  // Run this only on initial rendering.
  React.useEffect(
    () => {
      if (onDismiss) {
        // pass the onCancel function up to the ModalContainer to call on Esc key event
        onDismiss.current = onCancel;
      }

      // This function will wait for the analytics context function to resolve then
      // pass the resulting object to the dispatch call. If the analytics context
      // function is synchronous it will immediately resolve.
      async function dispatchAnalyticsAsync() {
        let dispatchAnalyticsOptions = {};
        try {
          dispatchAnalyticsOptions = (await analyticsContextFunc?.()) ?? {};
        } catch (error) {
          log.error(
            `The analytics context function provided has unexpectedly rejected and has been ignored: ${error}`
          );
        }
        dispatchUiEventAnalytics({
          ...dispatchAnalyticsOptions,
          eventAction: 'display',
          eventName: id,
        });
      }

      dispatchAnalyticsAsync();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Empty dependency to ensure this is only run once per open.
    []
  );

  const buttonGroupEl = getReactSpectrumWidgetIfIncludedInChildren(children, ButtonGroup);
  const buttonGroup = buttonGroupEl || (
    <ModalButtonGroup
      cancelLabel={cancelLabel}
      closeModal={closeModal}
      ctaLabel={ctaLabel}
      ctaToastGenerator={ctaToastGenerator}
      ctaVariant={ctaVariant}
      hideCancelButton={hideCancelButton}
      isCtaDisabled={isLoading || !!errorMessage || isCtaDisabled}
      modalId={id}
      onCancel={onCancel}
      onCta={onCta}
      onSecondary={onSecondary}
      secondaryLabel={secondaryLabel}
      styleProps={styleProps}
    />
  );

  const onCloseErrorToast = () => {
    setShowError(false);
    onCloseError?.();
  };

  const renderToast = () => (
    <View
      bottom="size-1000"
      data-testid="modal-error"
      maxWidth="size-4600"
      position="absolute"
      right="size-200"
    >
      {/* spectrum: use v3 toast */}
      <Provider>
        <Toast
          closable
          data-testid="error"
          onClose={onCloseErrorToast}
          styleName="error-toast"
          variant="error"
          {...errorToastProps}
        >
          {errorMessage}
        </Toast>
      </Provider>
    </View>
  );

  return (
    <ModalDialogContextProvider
      id={id}
      modalRef={modalRef}
      setModalDescribedBy={setModalDescribedBy}
      styleProps={styleProps}
    >
      <Dialog
        {...dialogProps}
        // By default, Spectrum's Dialog sets its aria-labelledby to match the Spectrum Heading's id.
        // Since ModalHeading uses Heading, we don't need to set it ourselves
        ref={modalRef}
        aria-describedby={modalDescribedBy}
        id={id}
        marginBottom={styleProps.footerMarginY}
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- fix for https://github.com/adobe/react-spectrum/issues/1404
        UNSAFE_className={
          heightVariant === 'default' ? undefined : styles[`modal-${heightVariant}`]
        }
        width={styleProps.width}
      >
        {children}
        {isLoading && <PanelWait />}
        {/* The modal-button-group must rendered before (and thus be below) the error-toast, so that
            click-events can hit the toast's buttons instead of the button-group's top-margin. */}
        {buttonGroup}
        {showError && renderToast()}
      </Dialog>
    </ModalDialogContextProvider>
  );
};

ModalDialog.propTypes = {
  /**
   * A function that returns an object to pass to the analytics display event.
   * An asynchronous function will wait to be resolved.
   */
  analyticsContextFunc: PropTypes.func,
  /**
   * Label for the cancel button.
   */
  cancelLabel: PropTypes.string,
  /**
   * Children nodes that should be rendered within the modal.
   */
  children: PropTypes.node,
  /**
   * Method to close the modal.
   */
  closeModal: PropTypes.func,
  /**
   * Label for the cta button.
   */
  ctaLabel: PropTypes.string,
  /**
   * Function that must return a string for displaying in the toast.
   */
  ctaToastGenerator: PropTypes.func,
  /**
   * Variant of the cta button of the modal. (Defaults to 'cta').
   */
  ctaVariant: PropTypes.oneOf(['cta', 'negative', 'primary']),
  /**
   * Error message to display when an error occurs.
   */
  errorMessage: PropTypes.string,
  /**
   * Props to pass to Spectrum(V2)'s Toast when the Modal Dialog displays errors.
   * See https://react-spectrum.corp.adobe.com/components/Toast for a full list of allowed props.
   * The listed props below are suggestions for what might be suitable for ModalDialog.
   */
  errorToastProps: PropTypes.shape({
    actionLabel: PropTypes.string,
    onAction: PropTypes.func,
  }),
  /**
   * Contols the behavior of the modal height
   * "default" will default to the spectrum Dialog behavior (always shrink to fit content)
   * "dynamic" variant means the modal will maintain a fixed min height, but grow with the content
   * "static" variant means the modal will always be a fixed height, no matter the content size
   */
  heightVariant: PropTypes.oneOf(['default', 'dynamic', 'static']),
  /**
   * As this ID will be used in analytics as is, we recommend this be easily parsed
   * and be suffixed with "modal", like "add-product-profile-modal" for example.
   */
  /**
   * Flag indicating whether the cancel button is explicitly hidden,
   */
  hideCancelButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  /**
   * Flag indicating whether the cta button should be disabled.
   */
  isCtaDisabled: PropTypes.bool,
  /**
   * Flag indicating whether the wait should be displayed.
   */
  isLoading: PropTypes.bool,
  /**
   * Mode for the modal. (Defaults to 'modal').
   */
  mode: PropTypes.oneOf(['alert', 'modal']),
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  /**
   * Callback to invoke when the modal's error message toast is closed.
   */
  onCloseError: PropTypes.func,
  /**
   * Callback to invoke when the modal's cta is pressed.
   */
  onCta: PropTypes.func,
  /**
   * Callback to invoke when the modal's secondary button is pressed.
   */
  onSecondary: PropTypes.func,
  /**
   * Label for the secondary button.
   */
  secondaryLabel: PropTypes.string,
};

export default ModalDialog;
export {STYLE_PROPS};
