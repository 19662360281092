import get from 'lodash/get';
import has from 'lodash/has';

import JIL_CONSTANTS from 'api/jil/JilConstants';
import {getListCacheKey} from 'utils/cacheUtils';

import ModelList from './ModelList';
import {LIST_DEFAULT_PAGE_SIZE} from './ModelListConstants';

class PaginatedModelList extends ModelList {
  /**
   * @class
   * @description Constructor for JilList. JilList supports JIL's list state such as pagination, filter, sorting, etc.
   * @param {Object} [options] - List instance settings
   * @param {String} [options.filterQuery] - query to filter results against
   * @param {Number} [options.itemCount] - the total number of items available, if known; part of pagination
   * @param {Number} [options.pageNumber] - 1-based page number to fetch
   * @param {Number} [options.pageSize] - number of items to display per page
   * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
   * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
   * @param {ListState} [options.state] - list state
   */
  constructor(options = {}) {
    super(options);

    // Set the lastQuery to filterQuery so the pageNumber will not be reset in refresh if there is an initial query.
    this.filter = {
      lastQuery: options.filterQuery || '',
      query: options.filterQuery || '',
      visible: false,
    };

    this.pagination = {
      currentPage: options.pageNumber || 1,
      itemCount: options.itemCount || 0,
      pageSize: options.pageSize || LIST_DEFAULT_PAGE_SIZE,
    };

    // This is the totalPages default value, that will be updated later onSuccessPreTransform
    this.pagination.totalPages = Math.ceil(this.pagination.itemCount / this.pagination.pageSize);

    this.sort = {
      expression: options.sortExpression || '',
      order: options.sortOrder || '',
    };
    this.SEARCH_QUERY_PATTERN = JIL_CONSTANTS.FILTER.PATTERN;

    this.state = options.state;
  }

  /**
   * @description Method to return the current unique key for this list.
   *
   * @param {Object} params - params passed into a GET
   * @returns {String} key to uniquely identify this list
   */
  getKey(params = {}) {
    const allParams = this.state
      ? {...this.state.toQueryParams(), ...params}
      : {
          page: this.pagination.currentPage - 1,
          pageSize: this.pagination.pageSize,
          searchQuery: this.filter.query,
          sort: this.sort.expression,
          sortOrder: this.sort.order,
          ...params,
        };
    const {state, ...keyParams} = allParams;

    return getListCacheKey(keyParams);
  }

  /**
   * @description Method to fetch the total count of items for this list.
   *
   * @return {Number} the number of items available to this list on the server
   */
  getTotalItemCount() {
    return get(this, 'pagination.itemCount');
  }

  /**
   * @description Method to determine if there are any items in the list.  If this is a filtered
   *              list and there are no items we do not know whether any items would exist in an
   *              unfiltered list.
   *
   * @return {Boolean|undefined} true, false or undefined if filter may be hiding items
   */
  hasContent() {
    if (!this.isEmpty()) {
      return true;
    }
    return this.filter.query.length > 0 ? undefined : false;
  }

  /**
   * @description Method to determine if there are any items in this list on the server.
   *
   * @return {Boolean} true if the number of items available to this list on the server is 0
   */
  isEmpty() {
    return get(this, 'pagination.itemCount', 0) === 0;
  }

  /**
   * @description Method to refresh the contents of the list
   *
   * @param {Object} queryParams - params to pass into the GET
   * @param {Object} [options] - additional options for refresh; see ModelList
   * @return {Promise} resolves with List when successful, else rejects with error message
   */
  refresh(queryParams = {}, options = {}) {
    // if the query is fewer than 3 characters (default), we don't make it
    if (this.filter.query !== '' && this.filter.query.length < this.SEARCH_QUERY_MIN_LENGTH) {
      this.filter.query = '';
    }

    // reset the current page if the search query has changed, otherwise
    // the offset will be incorrect
    if (this.filter.query !== this.filter.lastQuery) {
      this.setPageNumber();
    }

    // Save state or model settings before transforming successful refresh results
    // Sets object state or pagination and filter settings
    function onSuccessPreTransform(response) {
      // update state params based on response and headers
      if (this.state) {
        this.state.setResponseAndHeaders(response.data, response.headers);
      } else {
        // update model settings
        this.pagination.itemCount =
          Number.parseInt(response.headers[JIL_CONSTANTS.HEADERS.TOTAL_COUNT], 10) || 0;
        this.filter.lastQuery = this.filter.query;
        this.pagination.totalPages = Math.ceil(
          this.pagination.itemCount / this.pagination.pageSize
        );
      }
    }

    return super.refresh(
      {
        ...(this.state
          ? this.state.toQueryParams()
          : {
              page: this.pagination.currentPage - 1, // JIL uses 0-based page numbers
              page_size: this.pagination.pageSize,
              search_query: this.filter.query,
              sort: this.sort.expression,
              sort_order: this.sort.order,
            }),
        ...queryParams,
      },
      {
        ...options,
        onSuccessPreTransform,
      }
    );
  }

  /**
   * @description Method to reset the list to its collapsed state.
   *   This is being introduced for tables with a large number of items that wish to return to a
   *   search view after each operation rather than refreshing the entire list.
   *
   // eslint-disable-next-line valid-jsdoc
    * returns {Promise} a promise which is resolved with the model
    */
  reset() {
    throw new Error(`${this.constructor.name}.reset() method unimplemented`);
  }

  /**
   * @description Method to search and refresh the list.
   * @param {String} value search query
   * @returns {Promise} promise - resolved when the list is refreshed
   */
  search(value) {
    if (value !== this.filter.query) {
      this.filter.query = value;
      return this.refresh();
    }
    return Promise.resolve(this);
  }

  /**
   * @deprecated use setPageNumber which is the 1-based page number
   *
   * @description Method to set the current page of results to display.
   *
   * @param {Number} zeroBasedNewPage 0-based number to select
   * @return {undefined} no return value
   */
  setPage(zeroBasedNewPage) {
    if (zeroBasedNewPage > -1 && zeroBasedNewPage !== this.pagination.currentPage - 1) {
      // valid page (starts at zero and not same page that is currently selected)
      this.pagination.currentPage = 1 + zeroBasedNewPage;
    }
  }

  /**
   * @description Method to set the current page of results to display.
   *
   * @param [{Number}] newPage 1-based page number to select.
   *   Default is 1 which is the first page.
   * @return {undefined} no return value
   */
  setPageNumber(newPage = 1) {
    if (newPage >= 1) {
      this.pagination.currentPage = newPage;
    }
  }

  /**
   * @description Method to set the page size of current results to display.
   *
   * @param {Number} newPageSize number of results to display per page
   * @return {undefined} no return value
   */
  setPageSize(newPageSize) {
    if (newPageSize > 0) {
      // valid size for page
      this.pagination.pageSize = newPageSize;
    }
  }

  /**
   * @description Method to determine whether we need to update the total itemCount of a list.
   *
   * @return {Boolean} true if the total itemCount needs to be updated
   */
  shouldUpdateTotalItemCount() {
    return has(this, 'pagination.itemCount') && this.filter.query === '';
  }

  /**
   * @description Method to change the sort ordering for this list.
   * @param {String} property - attribute to sort people by
   * @param {Boolean} [desc] - true if should sort in descending order, else false
   */
  sortBy(property, desc) {
    this.sort = {
      expression: property,
      order: desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
    };
  }
}

export default PaginatedModelList;
