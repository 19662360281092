const MEMBER_AND_SELECTED_ITEMS_CONSTANTS = {
  ERROR_STATUS: {
    DUPLICATE_INPUT: 'duplicateInput',
    SERVER_ERROR: 'serverError',
    USER_PICKER_ERROR: 'userPickerError',
    USER_PRODUCT_ERROR: 'userProductError',
  },
  INFO_STATUS: {
    USER_PRODUCT_INFO: 'userProduct',
  },
  LOADING_STATUS: {
    FETCHING_USER_OR_USER_GROUP_DETAILS: 'fetchingUserOrUserGroupDetails',
    USER_PICKER_LOADING: 'userPickerLoading',
  },
};

export default MEMBER_AND_SELECTED_ITEMS_CONSTANTS;
