import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AccountStatus from 'common/components/account-status/AccountStatus';
import {DATE_FORMATS} from 'common/components/formatted-date-and-time/FormattedDateAndTime';
import MemberIdTypeCell from 'common/components/member-list-table/member-id-type-cell/MemberIdTypeCell';
import MemberNameCell from 'common/components/member-name-cell/MemberNameCell';
import TableSectionTable from 'common/components/table-section-table/TableSectionTable';
import UserRoles from 'common/components/user-roles/UserRoles';
import ViewDetailsDrawerTrigger from 'common/components/view-details-drawer-trigger/ViewDetailsDrawerTrigger';
import {
  getMemberDisplayName,
  getMemberType,
  getMemberTypeLabel,
} from 'features/member/memberDisplayUtils';

/**
 * The MemberListTable is a Pandora Table for displaying various types of users / admins /developers whose
 * base class is 'Member'.
 *
 * This component assumes it is wrapped in a Pandora TableSection which handles disabled items and selection,
 * sorting. If there are no members, rather than rendering this table, the TableSection will render the
 * EmptyTableIllustratedMessage.
 *
 * The members to be displayed are passed to this component via the TableSection's items.
 *
 * One of 'aria-label' or 'aria-labelledby' is required by the React Spectrum TableView.
 * React Spectrum logs a warning to the console if one of these props is missing.
 */
const MemberListTable = ({
  allowsSorting = true,
  canViewMemberDetails,
  display = {},
  drawerComponent,
  getDisplayNameHref,
  getProvisioningStatusHandler,
  isSystemAdmin,
  productIconList,
  rolePicker,
  // any remaining props passed to the TableSectionTable -> Pandora Table -> RS TableView
  // one of 'aria-label' or 'aria-labelledby' is required
  ...tableProps
}) => {
  const intl = useIntl();

  // This order of these objects determines the order of the columns.
  // Each object must have a key which has a string defined for it in this format:
  // `binky.common.memberListTable.column.${key}` string.
  // Any optional 'props' are passed to the <Column>.
  const columnDescriptor = [
    {
      key: 'name',
      props: {allowsSorting},
    },
    {
      key: 'viewDetails',
      props: {hideHeader: true, showDivider: true},
    },
    {key: 'createdDate'},
    {key: 'lastModifiedDate'},
    {
      key: 'email',
      props: {allowsSorting},
    },
    {key: 'userName'},
    {key: 'accountStatus'},
    {key: 'idType'},
    {key: 'productIcons'},
    {key: 'adminRole'}, // the display string has been updated to be plural but this property remains singular
    {
      key: 'productRole',
      props: {minWidth: 245}, // prevent picker controls from clipping off table (a11y; no way to see complete value)
    },
    {key: 'productProfile'},
    {key: 'provisioningStatus'},
  ];

  // Every key must have a function which is used to render its value.
  // When the render function is called, its prop object will contain the column 'key' and member.
  const renderers = {
    accountStatus: getAccountStatus,
    adminRole: getAdminRoles,
    createdDate: getFormattedDate,
    email: getAsTextIfUser,
    idType: getIdType,
    lastModifiedDate: getFormattedDate,
    name: getName,
    productIcons: getProductIcons,
    productProfile: getProductProfile,
    productRole: getProductRole,
    provisioningStatus: getProvisioningStatus,
    userName: getAsTextIfUser,
    viewDetails: getViewDetailsDrawerTrigger,
  };

  // Determine which columns to show based on the display object.
  // 'name' is always shown.
  const displayColumnDescriptor = columnDescriptor.filter(
    (column) => column.key === 'name' || display[column.key] === true
  );

  const getDisplayName = (member, options) => getMemberDisplayName(intl, member, options);

  function getAccountStatus({item, key}) {
    return <AccountStatus status={item[key]} />;
  }

  function getAdminRoles({item}) {
    return <UserRoles roles={item.roles} />;
  }

  function getAsTextIfUser({item, key}) {
    if (item.getType().isUser() && item[key] !== undefined) {
      return <Text>{item[key]}</Text>;
    }
    return undefined;
  }

  function getFormattedDate({item, key}) {
    if (item.getType().isTechnicalAccount()) {
      return intl.formatDate(item[key], DATE_FORMATS.defaultDateTime);
    }
    return undefined;
  }

  function getIdType({item}) {
    if (item.getType().isUser()) {
      return (
        <MemberIdTypeCell
          isDomainEnforcementException={item.domainEnforcementException}
          isSystemAdmin={isSystemAdmin}
          isType2E={item.getType().isType2E()}
          memberTypeLabel={getMemberTypeLabel(intl, getMemberType(item))}
        />
      );
    }
    return undefined;
  }

  function getName({item}) {
    return (
      <MemberNameCell
        canViewMemberDetails={canViewMemberDetails}
        displayName={getDisplayName(item)}
        getDisplayNameHref={getDisplayNameHref}
        intl={intl}
        member={item}
        showAvatar={display.avatar}
      />
    );
  }

  function getProductIcons({item}) {
    return cloneElement(productIconList, {member: item});
  }

  // Show the name of the first profile, optionally with '+ {count} more' appended.
  function getProductProfile({item}) {
    return (
      <>
        {item.licenseGroups?.length === 1 && <Text>{item.licenseGroups[0].name}</Text>}
        {item.licenseGroups?.length > 1 && (
          <FormattedMessage
            id="binky.common.memberListTable.productProfile.more"
            values={{
              count: item.licenseGroups.length - 1,
              profileName: item.licenseGroups[0].name,
            }}
          />
        )}
      </>
    );
  }

  function getProductRole({item}) {
    return cloneElement(rolePicker, {member: item});
  }

  function getProvisioningStatus({item}) {
    return getProvisioningStatusHandler(item.provisioningStatus);
  }

  function getViewDetailsDrawerTrigger({item}) {
    return (
      <ViewDetailsDrawerTrigger tooltipName={getDisplayName(item, {fallbackToEmail: true})}>
        {/* must use React.cloneElement here so it can be mocked in unit tests */}
        {(close) => React.cloneElement(drawerComponent, {close, member: item})}
      </ViewDetailsDrawerTrigger>
    );
  }

  return (
    <TableSectionTable
      columnDescriptor={displayColumnDescriptor}
      columnNamespace="binky.common.memberListTable.column"
      renderers={renderers}
      {...tableProps}
    />
  );
};

MemberListTable.propTypes = {
  /**
   * True, if this column can be sorted. The default is true.
   * The default sort order is on the NAME column with sort ascending.
   */
  allowsSorting: PropTypes.bool,
  /**
   * The 'aria-label' for the table. One of 'aria-label' or 'aria-labelledby' is required.
   * React Spectrum TableView will enforce this with warning log to console.
   */
  'aria-label': PropTypes.string,
  /**
   * The 'aria-label' for the table. One of 'aria-label' or 'aria-labelledby' is required.
   * React Spectrum TableView will enforce this with warning log to console.
   */
  'aria-labelledby': PropTypes.string,
  /**
   * Function whoose boolean result determines whether or not the name is linked to
   * the appropriate detail page for the type of member.
   * If not provided the assumption is that details can not be viewed.
   * If this returns true then onPressName is required.
   */
  canViewMemberDetails: PropTypes.func,
  /**
   * The object which determines which columns in the table are shown.
   * The name column is always shown but all other columns are optional.
   * To show a column, set the property which coresponds with the column to true.
   *
   * 'avatar' is a special case and is an opt-out property.
   *  If true, an avatar will be shown to the left of the name in the NAME cell.
   *  It defaults to true.
   */
  display: PropTypes.shape({
    accountStatus: PropTypes.bool,
    adminRole: PropTypes.bool,
    avatar: PropTypes.bool,
    createdDate: PropTypes.bool,
    idType: PropTypes.bool,
    lastModifiedDate: PropTypes.bool,
    productIcons: PropTypes.bool,
    productProfile: PropTypes.bool,
    productRole: PropTypes.bool,
    provisioningStatus: PropTypes.bool,
    userName: PropTypes.bool,
    viewDetails: PropTypes.bool,
  }),
  /**
   * The Pandora drawer component which is rendered in the 'VIEW DETAILS' column when the viewDetails
   * ActionButton is clicked. The component will be rendered with 'close' function prop which can be
   * called to close the drawer and a 'member' prop which is a 'Member' class or a class derived from
   * 'Member.
   * This is required if display.viewDetails is true.
   */
  drawerComponent: PropTypes.node,
  /**
   * Callback function to generate the href string for the name <a> link.
   * There is one param which will be the member.
   * This is required if canViewMemberDetails returns true.
   */
  getDisplayNameHref: PropTypes.func,
  /**
   * Function that invokes and returns the appropriate provisioning status component based
   * on specific conditions.
   */
  getProvisioningStatusHandler: PropTypes.func,
  /**
   * True if the current user is a system admin. This is used to determine which
   * ID type to show for a user.
   */
  isSystemAdmin: PropTypes.bool,
  /**
   * The ProductIconList component which is rendered in the 'PRODUCTS' column
   * when it is shown.  The component will be rendered with a 'member' prop.
   * This is required if display.productIcons is true.
   */
  productIconList: PropTypes.node, // required with products: true
  /**
   * A component which contains a role Picker and an optional Tooltip which is rendered in the
   * 'PRODUCT ROLE' column when it is shown.  The component will be rendered with a 'member' prop.
   * This is required if display.productRole is true.
   */
  rolePicker: PropTypes.node, // required with productRole defined
};

MemberListTable.displayName = 'MemberListTable';

export default MemberListTable;
