(function () {
  /**
   * @deprecated Please use src2/core/models/contract/contract.js
   *
   * @ngdoc factory
   * @name binky.core.contract:Contract
   * @description Model for an individual Contract
   */
  angular.module('binky.core.contract').factory('Contract', getContractModel);

  /* @ngInject */
  function getContractModel($q, _, binkySrc2, ContractTerms, promiseUtils) {
    class Contract2 extends binkySrc2.models.contract.Contract {
      constructor(options) {
        super(options);
        appendSrc1Attributes(this);
      }

      /**
       * @description Returns the T&C for this contract, if it has one.
       *
       * @returns {ContractTerms} contract terms object
       */
      getTermsAndConditions() {
        const serviceName = _.get(this, 'termsAndConditions.serviceName', undefined);
        if (serviceName && !this.contractTerms && !this.isDirectContract()) {
          this.contractTerms = ContractTerms.get({orgId: this.orgId, serviceName});
        }
        return this.contractTerms;
      }

      /**
       * @description Save changes to the contract.
       * @returns {Promise<Contract|Error>} resolves with saved contract,
       *   rejects with any error(s) from parent Class
       */
      save() {
        const promise = super.save();
        this.$resolved = false;
        return promiseUtils
          .toAngularPromise(promise)
          .then((response) => response)
          .finally(() => {
            this.$resolved = true;
          });
      }

      /**
       * @description Method to transform API responses from a Contract fetch
       *   operation into an actual Contract instance. This is necessary since new
       *   Contract instances do not have an id value. So, we need a custom method
       *   here to inject the legacy DX value when fetching an existing Contract
       *   that also does not have an id (i.e. - legacy DX contract).
       * @param {Object} responseData - JSON of fetched contract from server
       * @returns {Contract} new Contract instance, representing external Contract
       *   state/data
       */
      static apiResponseTransformer(responseData) {
        const transformedContract = super.apiResponseTransformer(responseData);
        appendSrc1Attributes(transformedContract);
        return transformedContract;
      }
    }

    /** Return reference to Constructor function **/
    return Contract2;

    /**
     * @description Method to append required src1 attributes to models that may
     *   have been created in a src2 context (e.g. - constructor,
     *   apiResponseTransformer, etc).
     * @param {Contract} contract - contract instance to append attributes to
     */
    function appendSrc1Attributes(contract) {
      contract.$resolved = true;
      contract.$promise = $q.resolve(contract);
    }
  }
})();
