(function () {
  angular.module('binky.core.organizations.user', [
    'binky.core.api.jil',
    'binky.core.common.model.cache',
    'binky.core.js-utils',
    'binky.core.lodash',
    'binky.core.promise-utils',
    'binky.core.user',
    'binky.core.user.roles',
  ]);
})();
