(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.comments
   * @description module that defines comment widgets
   */
  angular.module('binky.widgets.common.comments', [
    'angularMoment',
    'binky.core.lodash',
    'binky.widgets.common.avatar',
    'binky.widgets.common.comments.delete',
    'binky.widgets.common.date-time',
    'pascalprecht.translate',
  ]);
})();
