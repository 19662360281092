(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum StatusLight
   */
  angular.module('binky.widgets.common.status').constant('STATUS_VARIANT', {
    DEFAULT: 'default',
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
  });
})();
