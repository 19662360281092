import {Content, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {useModalDialogContext} from '../ModalDialogContext';

const ModalContent = ({children}) => {
  const {modalRef, styleProps} = useModalDialogContext();

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    // Auto focus, or re-focus modal on mount
    // For modals with multiple pages, the modal buttons never become
    // unmounted on page switch, so they retain their focus. In this case,
    // we reset the focus back to the modal
    // using the UNSAFE_getDOMNode escape hatch here -- https://github.com/adobe/react-spectrum/blob/main/rfcs/2019-v3-dom-props.md#refs
    modalRef.current?.UNSAFE_getDOMNode()?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, []);

  return (
    <Content data-testid="modal-content">
      <View paddingX={styleProps.spaceX}>{children}</View>
    </Content>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalContent;
