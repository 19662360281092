import React from 'react';

import WizardStepList from 'common/components/wizard/wizard-step-list/WizardStepList';

import styles from './ModalWizardStepList.pcss';

/**
 * Widget used for styling the wizard step list for modal usage.
 */
const ModalWizardStepList = () => <WizardStepList className={styles['wizard-step']} />;

export default ModalWizardStepList;
