import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilUsers from 'api/jil/jilUsers';
import {MEMBER_TECH_ACCOUNT_DOMAIN} from 'models/member/type/MemberTypeConstants';
import feature from 'services/feature';

class OrganizationUserListCount {
  /**
   * @description Method to retrieve an existing OrganizationUserListCount from back-end data
   *              store.
   *
   * @param {Object} options object. See constructor for params
   * @returns {OrganizationUserListCount} new OrganizationUserListCount Object reference
   */
  static async get(options) {
    const model = new OrganizationUserListCount(options);
    await model.refresh();
    return model;
  }

  /**
   * @param {Object} options - the options to pass to the constructor, described below.
   * @param {String} options.orgId - the org id
   */
  constructor(options) {
    Object.assign(this, options);
  }

  /**
   * Method to fetch the organization user list count
   *
   * @returns {Promise} promise - resolved with the instance
   */
  async refresh() {
    const response = await jilUsers.headUsers({
      filterExcludeDomain: feature.isEnabled('bug_fix_11606')
        ? MEMBER_TECH_ACCOUNT_DOMAIN
        : undefined,
      orgId: this.orgId,
    });
    // Ensure totalCount to either be a Number or undefined (avoid NaN)
    this.totalCount =
      Number.parseInt(response.headers[JIL_CONSTANTS.HEADERS.TOTAL_COUNT], 10) || undefined;
    return this;
  }
}

export default OrganizationUserListCount;
