/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {Contact} from '@admin-tribe/binky';
import {Flex, Text} from '@adobe/react-spectrum';
import {PhoneNumberInput} from '@pandora/react-phone-number-input';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import './ContactCard.pcss';

/**
 * The card that displays read-only contact information.
 */
const ContactCard = observer(({contact, descriptionId, labelId}) => {
  const intl = useIntl();
  const rows = [
    {key: 'name', value: contact?.name},
    {key: 'phone', value: contact?.phone},
    {key: 'email', value: contact?.email},
  ];

  const getEmptyMessage = () =>
    intl.formatMessage({
      id: 'binky.common.contactCard.empty',
    });

  const getLabel = (key) =>
    intl.formatMessage({
      id: `binky.common.contactCard.${key}.label`,
    });

  const isExistingPhoneNumber = (key) => key === 'phone' && contact?.phone;

  return !contact || contact.isEmpty() ? (
    <div aria-describedby={descriptionId} aria-labelledby={labelId} styleName="empty-contact-card">
      <Flex alignItems="center" height="size-800" justifyContent="center">
        <Text data-testid="empty-card" UNSAFE_style={{fontStyle: 'italic'}}>
          {getEmptyMessage()}
        </Text>
      </Flex>
    </div>
  ) : (
    <table aria-describedby={descriptionId} aria-labelledby={labelId} styleName="contact-card">
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            <th scope="row">
              <Text>{getLabel(row.key)}</Text>
            </th>
            <td>
              {isExistingPhoneNumber(row.key) ? (
                <PhoneNumberInput
                  aria-label={intl.formatMessage({
                    id: 'binky.common.contactCard.phone.label',
                  })}
                  data-testid={`${row.key}-value`}
                  defaultValue={row.value}
                  inputAriaLabel={intl.formatMessage({
                    id: 'binky.common.contactCard.phone.inputAriaLabel',
                  })}
                  isQuiet
                  isReadOnly
                  selectAriaLabel={intl.formatMessage({
                    id: 'binky.common.contactCard.phone.selectAriaLabel',
                  })}
                />
              ) : (
                <Text data-testid={`${row.key}-value`}>{row.value || <>&ndash;</>}</Text>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

ContactCard.propTypes = {
  /**
   * The contact containing an individual's information.
   */
  contact: PropTypes.instanceOf(Contact),
  /**
   * The id of the contact description.
   */
  descriptionId: PropTypes.string,
  /**
   * The id of the contact label.
   */
  labelId: PropTypes.string.isRequired,
};

export default ContactCard;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
