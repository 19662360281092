import JIL_CONSTANTS from 'api/jil/JilConstants';
import {MEMBER_EVENT, MODEL_CACHE_ID} from 'models/member/MemberConstants';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import {getListCacheKey} from 'utils/cacheUtils';

import JilModelList from '../modelList/JilModelList';

class MemberList extends JilModelList {
  /**
   * @class
   * @param {Object}[options] - See JilModelList for additional options
   * @param {String} [options.cacheClearingEvents] - The events which cause the cache to be cleared.
   *   In most cases, the defaults should be used.
   * @param {String} [options.eventUpdateCount] - event to emit if the list count is updated. The 2nd
   *   argument is the count. If the list is updated with an active search this event is not emitted.
   * @param {String} [options.eventUpdateList] - event to emit if the list is updated
   * @param {String} [options.filterExcludeDomain] - domain to exclude from results
   * @param {String} [options.filterIncludeDomain] - domain to include in results
   * @param {String} [options.orgId] - Org ID
   * @param {Function} [options.refreshResource] - A function that returns the list items.
   * @param {Function} [options.saveResource] - A function that saves the list items.
   */

  constructor(options) {
    if (!options.orgId) {
      throw new Error('MemberList.constructor() requires orgId');
    }

    super({
      cacheClearingEvents: options.cacheClearingEvents || [
        MEMBER_EVENT.CREATE,
        MEMBER_EVENT.DELETE,
        MEMBER_EVENT.UPDATE,
      ],
      filterQuery: options.filterQuery,
      isCacheable: true,
      itemClassRef: options.itemClassRef,
      items: options.items,
      modelCacheId: options.modelCacheId || MODEL_CACHE_ID,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize,
      resource: options.refreshResource,
      sortExpression: options.sortExpression || JIL_CONSTANTS.SORT.FNAME_LNAME,
      sortOrder: options.sortOrder || JIL_CONSTANTS.ORDER.ASC,
      transformResponseData: options.transformResponseData,
    });

    this.orgId = options.orgId;
    this.saveResource = options.saveResource;

    this.eventUpdateCount = options.eventUpdateCount;
    this.eventUpdateList = options.eventUpdateList;
    this.filter.excludeDomain = options.filterExcludeDomain;
    this.filter.includeDomain = options.filterIncludeDomain;
    this.filter.include = options.include;
  }

  /**
   * @description Method to return the current unique key for this list.
   *              Used for caching the fetched response.
   *
   * @returns {String} key to uniquely identify this list
   */
  getKey() {
    return getListCacheKey(this.getQueryParams());
  }

  /**
   * @description Get params for api call to fetch list.
   *              Subclasses should add additional params as needed.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      currentPage: this.pagination.currentPage,
      filterExcludeDomain: this.filter.excludeDomain,
      filterIncludeDomain: this.filter.includeDomain,
      filterQuery: this.filter.query,
      include: this.filter.include,
      orgId: this.orgId,
      page_size: this.pagination.pageSize,
      sort: this.sort.expression,
      sort_order: this.sort.order,
    };
  }

  /**
   * @description Method to return the save operations. If desired, this can be overriden.
   */
  getSaveOperations(options) {
    return getSaveOperations(this, options);
  }

  /**
   * @description Method to execute after a successful refresh.  Overriden where necessary by subclasses.
   */

  onRefreshSuccess() {
    if (this.eventUpdateList) {
      eventBus.emit(this.eventUpdateList);
    }
    if (this.eventUpdateCount && this.shouldUpdateTotalItemCount()) {
      eventBus.emit(this.eventUpdateCount, this.pagination.itemCount);
    }
  }

  /**
   * @description Method to refresh the contents of the member list
   * @throws {Error} - If refresh fails
   * @throws {Error} - If onRefreshSuccess fails
   * @returns {Promise<MemberList>} promise - resolved when the list is refreshed
   */
  async refresh() {
    try {
      await super.refresh(this.getQueryParams());
    } catch (error) {
      log.error(
        `MemberList.refresh(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      throw error;
    }

    try {
      await this.onRefreshSuccess();
    } catch (error) {
      log.error(`${this.constructor.name}.onRefreshSuccess() failed. Error: `, error);
      throw error;
    }
    return this;
  }

  /**
   * @description Method to reset the contents of the member list
   * @returns {Promise<MemberList>} promise - resolved with the instance
   */
  reset() {
    this.filter.lastQuery = '';
    this.filter.query = '';

    this.items = [];

    this.pagination.currentPage = 1;
    this.pagination.itemCount = 0;

    this.sort.expression = '';
    this.sort.order = '';

    return Promise.resolve(this);
  }

  /**
   * @description Method to save the contents of the member list
   * @param {Object} [params] - params to pass into the save resource
   * @param {Object} [options] - custom options for saving
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to true.
   * @param {Object} [options.removePathOverride] - A map from user id -> patch_operation, used to
   *   override the default patch remove operations.
   *   This assumes a 1-to-1 mapping between the user id and the patch operation. If a user id can map to more
   *   than one operation consider overriding the getSaveOperations mathod.
   * @returns {Promise<MemberList>} promise - resolved when the list is refreshed
   */
  async save(params = {}, options = {}) {
    if (!this.hasUnsavedChanges()) {
      return this;
    }
    const refresh = options.refresh === undefined ? true : options.refresh;
    const operations = this.getSaveOperations(options);
    try {
      await this.saveResource({
        operations,
        orgId: this.orgId,
        ...params,
      });
    } catch (error) {
      log.error('MemberList.save() failed. Error: ', error);
      return Promise.reject(error);
    }

    this.addedItems.forEach((member) => {
      eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
    });
    this.resetAddedItems();

    this.removedItems.forEach((member) => {
      eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
    });
    this.resetRemovedItems();

    this.filter.lastQuery = this.filter.query;
    this.filter.query = '';
    if (refresh) {
      try {
        await this.refresh();
      } catch (error) {
        log.error('MemberList.save() refresh failed. Error: ', error);
        return Promise.reject(error);
      }
    }
    return this;
  }
}

function getSaveOperations(model, options) {
  const addOperations = model.addedItems.map((member) => ({
    op: 'add',
    path: `/${member.id}`,
  }));
  const removeOperations = getRemoveOperations(model.removedItems, options.removePathOverride);
  return [...addOperations, ...removeOperations];
}

function getRemoveOperations(removedItems, pathOverride = {}) {
  return removedItems.map((member) => ({
    op: 'remove',
    path: pathOverride?.[member.id] || `/${member.id}`,
  }));
}

export default MemberList;
