import authentication from 'services/authentication';
import Locale from 'services/locale/Locale';

/**
 * Get headers for JIL API calls
 *
 * @param {String} clientId - The client id
 * @param {Array<String>} [includeRoles] - An array of admin roles to include in the request
 * @returns {Object} The header map
 */
const getHeaders = ({clientId, includeRoles}) => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for JIL API call');
  }
  const headers = {
    'Accept-Language': Locale.get().activeLocaleCode,
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };

  if (includeRoles) {
    headers['X-Include-Roles'] = includeRoles.join(',');
  }

  // When JIL removes the use_clam flag, we can remove the below line
  headers['x-jil-feature'] = 'use_clam';

  headers['x-jil-feature'] = [headers['x-jil-feature'], 'pa_4280'].join(',');

  return headers;
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getHeaders};
