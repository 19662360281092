import {EVENT_ACTION} from 'services/analytics/AnalyticsConstants';
import ProductListDescriptor from 'services/product/ProductListDescriptor';
import {translateJson} from 'utils/jsUtils';

/**
 * @description construct product list analytics descriptor
 * @param {Array} productList - list of products
 * @returns {Object} ProductListDescriptor
 */
function constructProductListDescriptor(productList) {
  return new ProductListDescriptor(formatProductPricingForAnalytics(productList));
}

/**
 * @description format productList pricing information from the promo offer data
 * @param {Object} options - list of customizable options
 * @param {Array} options.items list of products to pull pricing data from
 */
function formatProductPricingForAnalytics(options) {
  const {items} = options;

  return {
    items: items.map((item) => {
      const formattedItem = {...item};

      if (!item.pricing) {
        return formattedItem;
      }

      const {currency, prices} = item.pricing;

      formattedItem.pricing = {};
      translateJson(prices?.[0].price_details, formattedItem, {
        display_rules: {
          percent_discount: 'pricing.percentageDiscount',
          price: 'pricing.displayedPrice',
          regular_price: 'pricing.originalPrice',
          tax: 'pricing.tax',
        },
        unit: {
          amount_with_tax: 'pricing.offerPriceWithTax',
          amount_without_tax: 'pricing.offerPriceWithoutTax',
          includes_discount: 'pricing.includesDiscount',
        },
        unit_without_discount: {
          amount_with_tax: 'pricing.basePrice',
          amount_without_tax: 'pricing.basePrice',
        },
      });

      formattedItem.pricing.currency = currency?.code;

      return formattedItem;
    }),
  };
}

/**
 * @description creates an analytics interaction string from promoOffers and eventAction string.
 * @param {Array} promoOffers - list of promo offers to pull interaction info from
 * @param {String} eventAction - event action to add to the interaction info, defaults to 'impression'
 * @returns {Object} containing impression or click interaction string
 */
function getAnalyticsPromoOfferInteraction(promoOffers, eventAction) {
  const interaction = Array.isArray(promoOffers)
    ? promoOffers
        .map(
          (offer, index) =>
            `offer-${index}|${offer.offer_id}|AdminConsole|renewalFlow|${offer.product_arrangement_code}|${offer.product_code}`
        )
        .join(',')
    : undefined;

  return eventAction === EVENT_ACTION.CLICK ? {click: interaction} : {impression: interaction};
}

export {
  constructProductListDescriptor,
  formatProductPricingForAnalytics,
  getAnalyticsPromoOfferInteraction,
};
