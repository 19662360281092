import {feature} from '@admin-tribe/binky';
import {Provider, defaultTheme} from '@adobe/react-spectrum';
import {render as rtlRender} from '@testing-library/react';
import {mount} from 'enzyme';
import React from 'react';
import {act} from 'react-dom/test-utils';
import {IntlProvider} from 'react-intl';

const defaultLocale = 'en';

/**
 * @description Creates a mocked prototype of the provided service.
 *  Used to test a component that requires the propType to be an instance of the service.
 *
 * @param {Object} service - the service to create a mocked instance of.
 * @param {Object} options - specified attributes and functions that the mocked service should contain.
 * @returns {Object} - a mocked prototype of the service set with the provided options.
 */
const createMockedService = (service, options) => {
  const model = Object.create(service.prototype);
  return Object.assign(model, options);
};

// v3 provider
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cframpto@ to update
const mountWithProvider = (node) =>
  mount(node, {
    wrappingComponent: Provider,
    wrappingComponentProps: {
      colorScheme: 'light',
      theme: defaultTheme,
    },
  });

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
function renderWithIntl(ui, {loc = defaultLocale, ...renderOptions} = {}) {
  // eslint-disable-next-line react/prop-types -- Disable PropTypes for test utils file
  const Wrapper = ({children}) => <IntlProvider locale={loc}>{children}</IntlProvider>;
  return rtlRender(ui, {wrapper: Wrapper, ...renderOptions});
}

const featureStates = {};

const mockFeatureCheck = (methodName, expectedFeatureState, flag) => {
  if (Object.prototype.hasOwnProperty.call(featureStates, flag)) {
    return featureStates[flag] === expectedFeatureState;
  }

  throw new Error(
    `${methodName}() called with unexpected feature '${flag}'. Did you forget to pass it to
    setUpFeatures()?`
  );
};

// This can be removed once we switch over to the jest-test-utils project
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
const setUpFeatures = (features) => {
  Object.assign(feature, {
    isDisabled: (flag) => mockFeatureCheck('isDisabled', false, flag),
    isEnabled: (flag) => mockFeatureCheck('isEnabled', true, flag),
  });

  Object.assign(featureStates, features);
};

const wait = (amount = 0) =>
  // eslint-disable-next-line no-promise-executor-return -- https://github.com/enzymejs/enzyme/issues/2073#issuecomment-531488981
  new Promise((resolve) => setTimeout(resolve, amount));
// Use this in your test after mounting if you want the query to finish and update the wrapper
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- bluu@ to update
const updateWrapper = async (wrapper, amount = 0) => {
  await act(async () => {
    await wait(amount);
    wrapper.update();
  });
};

export {createMockedService, mountWithProvider, renderWithIntl, setUpFeatures, updateWrapper};
