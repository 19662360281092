(function () {
  'use strict';
  /**
   * @deprecated Please use src2/member/memberDisplayUtils.jsx
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.common.member.member-type')
    .filter('binkyMemberTypeLabel', binkyMemberTypeLabel);

  /* @ngInject */
  function binkyMemberTypeLabel($translate, _) {
    return getMemberTypeLabel;

    function getMemberTypeLabel(memberType, options = {}) {
      const casing = options.format === 'uppercase' ? 'uppercase' : 'lowercase';
      return $translate.instant(
        `binky.widgets.members.memberType.${casing}.${getTypeName(memberType)}`
      );
    }

    function getTypeName(memberType) {
      if (memberType.isTechnicalAccount()) {
        return 'technical_account';
      }
      return _.toLower(memberType.type);
    }
  }
})();
