/* eslint-disable max-lines -- disabling for axios functions */
import axios from 'axios';
import omitBy from 'lodash/omitBy';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL license groups APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL License Groups api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Uploads CSV to add users to a license group
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - upload params
 * @param {String} params.productId - The ID of the product
 * @param {String} params.licenseGroupId - The id of the license group to which the users will be added to
 */
const bulkAddLicenseGroupUsers = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'lg-add-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Uploads CSV to remove users from a license group
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - upload params
 * @param {String} params.productId - The ID of the product
 * @param {String} params.licenseGroupId - The id of the license group to which the users will be added to
 */
const bulkRemoveLicenseGroupUsers = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'lg-remove-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description delete the active license
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @returns {Promise<Object>} Resolves to the axios response object.
 */
const deleteLicenseActivations = ({orgId, productId, licenseGroupId}) =>
  axios.delete(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/license-activations`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Gets CSV of devices for a license group
 *
 * @param {String} options.orgId - The org id
 * @param {String} options.licenseGroupId - The license group id
 * @param {String} options.productId - Product ID
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportLicenseGroupDevices = async ({orgId, licenseGroupId, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/devices`,
    {
      headers: {
        Accept: 'text/csv+license-group-devices,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
    }
  );
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV of device users for a license group
 *
 * @param {String} options.licenseGroupId - The license group id
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportLicenseGroupDeviceUsers = async ({orgId, licenseGroupId, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/users`,
    {
      headers: {
        Accept: 'text/csv+license-group-device-users,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
    }
  );
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV of users for a license group
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @returns {Promise<Object>} - Object containing blob representation of csv
 */
const exportLicenseGroupUserList = async ({orgId, productId, licenseGroupId, params}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/users`,
    {
      headers: {
        Accept: 'text/csv+license-group-users,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
      params,
    }
  );
  return csvBlobTransformer(response);
};

/**
 * Fetches license group configurations
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 */
const getLicenseGroupConfigurations = ({orgId, productId, licenseGroupId}) =>
  axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/configurations`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Fetches license group permissions
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 */
const getLicenseGroupPermissions = ({orgId, productId, licenseGroupId}) =>
  axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/permissions`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Fetches license group member configurations
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @param {Array<String>} memberIds - The member id list to get the member configuration for
 */
const getLicenseGroupMemberConfiguration = async ({
  orgId,
  productId,
  licenseGroupId,
  memberIds,
}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/member-configuration`,
    {
      headers: getHeaders({clientId, includeRoles}),
      params: {
        license_group_members: memberIds.join(','),
      },
    }
  );
  return response;
};

/**
 * Fetches paginated list of license groups, or a specific license group when given the licenseGroupId
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} [licenseGroupId] - The license group id to fetch.
 *                 If undefined, will fetch a paginated list of license groups with params below
 * @param {Number} [page] - page number of paginated license groups to fetch
 * @param {Number} [page_size] - the page size of the paginated response
 * @param {String} [search_query] - search query string
 * @param {String} [sort] - the field to sort by
 * @param {String} [sort_order] - the sort order (ASC or DESC)
 */
const getLicenseGroups = async ({orgId, productId, licenseGroupId, ...params}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId || ''}`,
    {
      headers: getHeaders({clientId, includeRoles}),
      params,
    }
  );
  return response;
};

/**
 * Fetches license group user list
 *
 * @param {String} params.orgId - The org id
 * @param {String} params.productId - The product id
 * @param {String} params.licenseGroupId - The licenseGroup id
 */
const getLicenseGroupUserList = ({
  filterExcludeDomain,
  filterIncludeDomain,
  include,
  orgId,
  productId,
  licenseGroupId,
  ...params
}) => {
  const snakeCaseParams = omitBy(
    {
      filter_exclude_domain: filterExcludeDomain,
      filter_include_domain: filterIncludeDomain,
      include: include?.join(','),
      ...params,
    },
    (value) => value === undefined
  );
  return axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/users`,
    {
      headers: getHeaders({clientId, includeRoles}),
      params: snakeCaseParams,
    }
  );
};

/**
 * Patches license group configurations
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @param {Array<Object>} patchOperations - The patch operations to update the configurations with
 */
const patchLicenseGroupConfigurations = ({orgId, productId, licenseGroupId}, patchOperations) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/configurations`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Patches license group permissions
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @param {Array<Object>} patchOperations - The patch operations to update the permissions with
 */
const patchLicenseGroupPermissions = ({orgId, productId, licenseGroupId}, patchOperations) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/permissions`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Patches license group member configurations
 *
 * @param {String} params.orgId - The org id
 * @param {String} params.licenseGroupId - The licenseGroup id
 * @param {String} params.productId - The product id
 * @param {Array<Object>} patchOperations - The patch operations to update the member configurations with
 */
const patchLicenseGroupMemberConfiguration = async (
  {orgId, productId, licenseGroupId},
  patchOperations
) => {
  const response = await axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/member-configuration`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

/**
 * Patches license group user list
 *
 * @param {String} params.orgId - The org id
 * @param {String} params.licenseGroupId - The licenseGroup id
 * @param {String} params.productId - The product id
 * @param {Array<Object>} params.operations - The patch operations to update the license group user list with
 */
const patchLicenseGroupUserList = ({orgId, productId, licenseGroupId, operations}) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/users`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Patch multiple license groups
 *
 * @param {String} params.orgId - The org id
 * @param {String} params.productId - The product id
 * @param {Array<Object>} patchOperations - The patch operations to update the license groups with
 */
const patchLicenseGroups = async ({orgId, productId}, patchOperations) => {
  const response = await axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

/**
 * Creates license group
 *
 * @param {String} orgId - The org id
 * @param {Object} licenseGroup - The license group object
 * @param {String} productId - The product id
 */
const postLicenseGroups = async ({orgId, productId}, licenseGroup) => {
  const response = await axios.post(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups`,
    licenseGroup,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

/**
 * Updates license group
 *
 * @param {String} orgId - The org id
 * @param {Object} licenseGroup - The license group object
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 */
const putLicenseGroup = async ({orgId, productId, licenseGroupId}, licenseGroup) => {
  const response = await axios.put(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}`,
    licenseGroup,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

/**
 * Updates license group configurations
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @param {Object} licenseGroupConfigurations - The license group configurations object
 */
const putLicenseGroupConfigurations = (
  {orgId, productId, licenseGroupId},
  licenseGroupConfigurations
) =>
  axios.put(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/configurations`,
    licenseGroupConfigurations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Updates license group permissions
 *
 * @param {String} orgId - The org id
 * @param {String} productId - The product id
 * @param {String} licenseGroupId - The licenseGroup id
 * @param {Object} putLicenseGroupPermissions - The license group permissions object
 */
const putLicenseGroupPermissions = ({orgId, productId, licenseGroupId}, licenseGroupPermissions) =>
  axios.put(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${licenseGroupId}/permissions`,
    licenseGroupPermissions,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilOrganizationsProductsLicenseGroups = {
  bulkAddLicenseGroupUsers,
  bulkRemoveLicenseGroupUsers,
  configure,
  deleteLicenseActivations,
  exportLicenseGroupDevices,
  exportLicenseGroupDeviceUsers,
  exportLicenseGroupUserList,
  getLicenseGroupConfigurations,
  getLicenseGroupMemberConfiguration,
  getLicenseGroupPermissions,
  getLicenseGroups,
  getLicenseGroupUserList,
  patchLicenseGroupConfigurations,
  patchLicenseGroupMemberConfiguration,
  patchLicenseGroupPermissions,
  patchLicenseGroups,
  patchLicenseGroupUserList,
  postLicenseGroups,
  putLicenseGroup,
  putLicenseGroupConfigurations,
  putLicenseGroupPermissions,
};

export default jilOrganizationsProductsLicenseGroups;
/* eslint-enable max-lines -- disabling for axios functions */
