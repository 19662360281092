(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableUsageList.js
   *
   * @ngdoc factory
   * @name ConsumableUsageList
   * @description Model for an individual ConsumableUsageList
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Usage
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableUsageList', getConsumableUsageListModel);

  /* @ngInject */
  function getConsumableUsageListModel(_, ConsumableUsage, jilConsumables, List) {
    class ConsumableUsageList extends List {
      /**
       * @description Creates a new ConsumableUsageList for use.
       *
       * @param {Object} options options for this list
       * @param {Array} options.items ConsumableUsage entities which make up this list
       * @param {Number} options.usageTotal total number of usages available to be queried
       * @param {Consumable} parentConsumable the Consumable that this list belongs to
       */
      constructor(options, parentConsumable) {
        super({
          itemClassRef: ConsumableUsage,
          itemCount: _.get(options, 'usageTotal', 0),
          resource: jilConsumables.usage,
        });
        this.parentConsumable = parentConsumable;
        initModel(this, options);
      }

      /**
       * @description Method to fetch an array of ConsumableUsages
       *
       * @returns {Promise} Resolves with refreshed model if successful, else
       *                    rejects with error message
       */
      refresh() {
        const params = getQueryParams(this);
        return super.refresh(
          _.assign({orgId: params.organization_id}, _.omit(params, ['organization_id']))
        );
      }
    }

    //////////////

    function initModel(model, options) {
      // First we assign the model fields
      _.assignIn(model, getQueryParams(model));

      model.items = processItems(_.get(options, 'items'));
    }

    function getQueryParams(model) {
      // Get the parent params, and convert them all to snake case, since that's what the API params expect
      const parentParams = _.mapKeys(
        _.invoke(model, 'parentConsumable.getQueryParams') || {},
        (value, key) => _.snakeCase(key)
      );

      // return the parameters we have that correspond to API query params.
      const propertyArray = [
        'contract_id',
        'fulfillable_item_code',
        'fulfillment_id',
        'group_id',
        'include_depleted',
        'include_expired',
        'license_id',
        'organization_id',
      ];
      return _.pick(parentParams, propertyArray);
    }

    /**
     * @description Initializes ConsumableUsages
     *
     * @param {Array} items ConsumableUsage entities to be processed
     *
     * @returns {Array} the processed items
     */
    function processItems(items) {
      return _.map(items, (item) => ConsumableUsage.apiResponseTransformer(item));
    }

    return ConsumableUsageList;
  }
})();
