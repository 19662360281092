import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL Organizations Search APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Organizations Search api
 * @param {String} config.clientId - The identifier for application
 * @param {String[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches the available types for a user for a particular
 *     organization.
 *
 * @param {Object} payload - API Payload
 * @param {String} payload.email - The email to get the available types of
 * @param {String} orgId - The org id
 */
const getAvailableTypes = async ({payload, orgId}) => {
  const response = await axios.post(
    `${url}/v2/organizations/${orgId}/search/available-types`,
    payload,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response.data;
};

/**
 * @description Fetches members for the SearchUserList model.
 *
 * @param {String} orgId - The org id
 * @param {Object} [params] - The API parameters
 * @param {Boolean} [params.include_user_groups] - include user groups in search results
 * @param {String} [params.search_query] - query string to match
 * @param {String} [params.sort] - sort by, comma-separated string of JIL_CONSTANTS.SORT values
 * @param {String} [params.sort_order] - sort order, one of by JIL_CONSTANTS.ORDER
 * @param {Number} [params.page] - page number
 * @param {Number} [params.page_size] - items per page
 * @param {String} [params.target_license_group_id] - target license group ID
 * @param {String} [params.target_product_id] - target product ID
 * @param {String} [params.target_type] - type of target fields. 'ADMIN' or 'USER'
 * @param {String} [params.target_user_group_id] - target user group ID
 */
const getSearchMembers = async ({orgId, ...params}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/search/members`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

const jilSearch = {configure, getAvailableTypes, getSearchMembers};

export default jilSearch;
