(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.directories:binkyDirectoryStatus
   * @description A directory status widget.
   *
   * @param {String} status the status of the directory. One of the DIRECTORY_STATUS constants
   * @param {String} ownershipStatus the ownership status of the directory. One of the DIRECTORY_OWNERSHIP_STATUS constants.
   * @param {String} type the type of directory. One of the DIRECTORY_TYPE constants.
   * @example <binky-directory-status
   *            ownership-status="TRUSTED"
   *            status="ACTIVE"
   *          </binky-directory-status>
   *
   */
  angular.module('binky.widgets.directories.status').component('binkyDirectoryStatus', {
    bindings: {
      isPrimaryIdpOkta: '<',
      ownershipStatus: '@',
      showAutoRemovedSyncStatus: '<',
      status: '@',
      type: '@',
    },
    controller,
    templateUrl: 'widgets/directories/status/directory-status.component.html',
  });

  /* @ngInject */
  function controller($log, _, directoryStatusHelper, DIRECTORY_STATUS, feature) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      autoRemovedDeprecatedSyncAlert:
        'binky.widgets.directories.directoryListTable.autoRemovedDeprecatedSyncAlert',
      idpMigrationAlert: 'binky.widgets.directories.directoryListTable.idpMigrationAlert',
    });

    function $onInit() {
      vm.showIdpMigrationTooltip = feature.isEnabled('temp_idp_migration') && vm.isPrimaryIdpOkta;

      if (vm.status && _.includes(DIRECTORY_STATUS, vm.status)) {
        vm.labelKey = directoryStatusHelper.getLabelKey(vm.status, vm.ownershipStatus);
        vm.variant = directoryStatusHelper.getVariant(vm.status);
      } else {
        $log.error(`Invalid directory status: ${vm.status}`);
      }
    }
  }
})();
