(function () {
  /**
   * @deprecated not required with React/ES6
   *
   * @ngdoc factory
   * @name binky.core.translation-utils
   * @description Utility service for translations.
   */
  angular.module('binky.core.translation-utils').factory('translationUtils', utils);

  /* @ngInject */
  function utils($translate) {
    const service = {
      hasKey,
      sanitizeSafeInstant,
    };

    return service;

    /**
     * @description Helper function to determine whether a translation exists for a given key
     *
     * @param {String} translationId - translation id
     * @returns {Boolean} True if the translation exists, false otherwise
     */
    function hasKey(translationId) {
      // There isn't a hasKey functionality on $tanslate (https://angular-translate.github.io/docs/#/api/pascalprecht.translate.$translate)
      // So create our own by translating it and seeing if anything comes back
      // eslint-disable-next-line @admin-tribe/admin-tribe/angular-translate-no-dynamic-instant -- this is safe because we don't display the product of instant
      const translatedValue = $translate.instant(translationId);
      return translatedValue !== translationId;
    }

    /**
     * @description Helper function to perform a $translate.instant without the side effect
     *   of de-sanitizing the resulting string in the case of a missed lookup table result.
     *
     * @param {String} translationId - translation id
     * @param {Object} interpolateParams - translation params to pass to instant
     * @param {String} interpolationId - the id of the interpolation to use
     * @param {String} forceLanguage - a language to be used instead of the current language
     * @param {String} sanitizeStrategy - force sanitize strategy for this call instead of using the configured one
     * @returns {String} The translated string, if the key exists, or the original input string
     */
    function sanitizeSafeInstant(
      translationId,
      interpolateParams = {},
      interpolationId = undefined,
      forceLanguage = false,
      sanitizeStrategy = undefined
    ) {
      // eslint-disable-next-line @admin-tribe/admin-tribe/angular-translate-no-dynamic-instant -- this is safe because we don't display the product of instant
      const translatedValue = $translate.instant(
        translationId,
        interpolateParams,
        interpolationId,
        forceLanguage,
        sanitizeStrategy
      );
      return translatedValue === translationId ? translationId : translatedValue;
    }
  }
})();
