import {Content, Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

const {DEFAULT_MSG_HEIGHT, DEFAULT_MSG_WIDTH} = ASSIGNMENT_MENU_CONSTANTS;

// A component to use when there are no items to be displayed.
// Shared between menus within AssignmentMenu.
const MenuNoItemsMessage = ({content, heading}) => (
  <View height={DEFAULT_MSG_HEIGHT} width={DEFAULT_MSG_WIDTH}>
    {/* No illustration used here - only using for the Spectrum text styling  */}
    <IllustratedMessage>
      {/* Modals will have a h2 at the top, so set this heading to h3 */}
      <Heading level={3}>{heading}</Heading>
      <Content>{content}</Content>
    </IllustratedMessage>
  </View>
);

MenuNoItemsMessage.propTypes = {
  content: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default MenuNoItemsMessage;
