(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.insights.item:InsightsItemList
   * @description Model for a insight item list
   */
  angular.module('binky.core.insights.item').factory('InsightsItemList', insightsItemList);

  /* @ngInject */
  function insightsItemList(
    $q,
    $rootScope,
    _,
    INSIGHTS_ITEM_LIST_CACHE_ID,
    INSIGHTS_ITEM_LIST_EVENT,
    InsightsItem,
    InsightsItemAccess,
    List,
    modelCache,
    reportBroker
  ) {
    class InsightsItemList extends List {
      /**
       * @description Method to create a new instance of a InsightsItemList.
       *
       * @param {Object} options - options passed to the InsightsItemList constructor
       * @param {ContractList} options.contractList - REQUIRED the contract list we're checking access for
       * @param {String} options.insightsSection - used to determine what sections the user has access to (reports and or logs)
       * @param {String} options.orgId - REQUIRED the id of the organization we're checking access for
       * @param {ProductList} options.productList - REQUIRED the product list we're checking access for
       */
      constructor(options) {
        super(
          _.assign(
            {
              itemClassRef: InsightsItem,
              resource: reportBroker.insightsItem,
            },
            options
          )
        );
        this.insightsSection = options.insightsSection;
        // these are the options needed to pass along to the InsightsItemAccess to be
        // able to properly evaulate access criteria
        this.additionalOptions = _.pick(options, ['contractList', 'orgId', 'productList']);
      }

      /**
       * @description Method to refresh Insights Item list from back-end.
       *
       * @returns {Promise} resolves to InsightItemList on success, else rejects with error
       */
      refresh() {
        this.$promise = InsightsItemAccess.getAvailableInsightsItems(
          this.insightsSection,
          this.additionalOptions
        )
          .then((availableItems) =>
            super.refresh(
              _.omitBy(
                {
                  orgId: _.get(this, 'additionalOptions.orgId'),
                  // types = array of ids of insights items (ex: [assetActivity, assetExpiry])
                  // referenced by the reportBroker API
                  types: _.map(availableItems, (item) => _.get(item, 'id')),
                },
                _.isUndefined
              )
            )
          )
          .then(onSuccess.bind(this))
          .catch($q.reject);

        function onSuccess() {
          $rootScope.$emit(INSIGHTS_ITEM_LIST_EVENT.REFRESH);
          modelCache.put(
            INSIGHTS_ITEM_LIST_CACHE_ID,
            this,
            this.key({insightsSection: this.insightsSection})
          );
          return this;
        }
        return this.$promise;
      }

      /**
       * @description Method to fetch the InsightsItemList. It will be refreshed on
       *     registering the active org
       *
       * @param {Object} options - configuration options for list retrieval
       * @param {String} options.insightsSection - used to determine what sections the user has access to (reports and or logs)
       * @param {String} options.orgId - REQUIRED the org id we're checking access for
       *
       * @returns {InsightsItemList} singleton InsightsItemList.
       */
      static get(options) {
        let model = new InsightsItemList(options);
        const cachedModel = modelCache.get(
          INSIGHTS_ITEM_LIST_CACHE_ID,
          model.key({insightsSection: options.insightsSection})
        );
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    modelCache.register(INSIGHTS_ITEM_LIST_CACHE_ID, 1);

    return InsightsItemList;
  }
})();
