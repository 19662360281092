const MIGRATION_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.migration.MIGRATION_CONSTANTS;
const MIGRATION_LIST_CACHE_ID =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.migration.migrationListConstants.MIGRATION_LIST_CACHE_ID;
(function () {
  /**
   * @deprecated Please use src2/core/models/migrations/migrationConstants.js
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('binky.core.migration')
    .constant('MIGRATION_LIST_CACHE_ID', MIGRATION_LIST_CACHE_ID)
    .constant('MIGRATION_STATUS', MIGRATION_CONSTANTS.MIGRATION_STATUS)
    .constant('MIGRATION_TYPE', MIGRATION_CONSTANTS.MIGRATION_TYPE);
})();
