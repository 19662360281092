import differenceWith from 'lodash/differenceWith';

import feature from 'services/feature';
import {compareArrays} from 'utils/jsUtils';

import {
  CONTRACT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from './UserRolesConstants';

/**
 * @description Compare two exploded roles.
 *
 * @param {UserRole} itm1 reference to the first UserRole
 * @param {UserRole} itm2 reference to the UserRole to compare to
 * @returns {Boolean} true if the two items are equal
 */
function compareAdminRoles(itm1, itm2) {
  if (itm1.type !== itm2.type) {
    return false;
  }
  if (itm1.type === LICENSE_ADMIN || itm1.type === LICENSE_DEV_ADMIN) {
    return itm1.targetId === itm2.targetId && itm1.targetParentId === itm2.targetParentId;
  }
  if (
    itm1.type === CONTRACT_ADMIN ||
    itm1.type === PRODUCT_ADMIN ||
    itm1.type === PRODUCT_SUPPORT_ADMIN ||
    itm1.type === USER_GROUP_ADMIN
  ) {
    return itm1.targetId === itm2.targetId;
  }
  // if it's any other admin type, there's nothing else to compare
  return true;
}

/**
 * @description Explode the roles and their targets into individual roles for easier comparison
 *
 * @param {Array} roles the set of roles to explode
 * @returns {Array} the exploded array of roles
 */
function explodeAdminRoles(roles) {
  // we explode the role list to make comparisons simpler
  let explodedRoles = [];
  roles.forEach((role) => {
    explodedRoles = [...new Set([...explodedRoles, ...explodeRole(role)])];
  });
  return explodedRoles;

  function explodeRole(role) {
    if (role.type === LICENSE_ADMIN || role.type === LICENSE_DEV_ADMIN) {
      return role.targets.map((target) => ({
        targetId: target.id,
        targetParentId: target.parentId,
        type: role.type,
      }));
    }
    if (
      role.type === CONTRACT_ADMIN ||
      role.type === PRODUCT_ADMIN ||
      role.type === PRODUCT_SUPPORT_ADMIN ||
      (feature.isEnabled('temp_parkour_mm') && role.type === USER_GROUP_ADMIN)
    ) {
      return role.targets.map((target) => ({
        targetId: target.id,
        type: role.type,
      }));
    }
    // remove with temp_parkour_mm
    if (role.type === USER_GROUP_ADMIN) {
      return role.targets.map((target) => ({
        targetId: target.id,
        type: role.type,
      }));
    }
    return [role];
  }
}

/**
 * @description Helper method to construct the patches for Admin Role changes to be saved.
 *
 * @param {OrganizationUser} model reference to OrganizationUser model
 * @returns {Array} the set of patches to be performed
 */
function getAdminRolePatches(model) {
  const explodedRoles = explodeAdminRoles(model.roles);
  const explodedSavedRoles = explodeAdminRoles(model.savedState.roles);
  const addedRoles = differenceWith(explodedRoles, explodedSavedRoles, compareAdminRoles);

  const removedRoles = differenceWith(explodedSavedRoles, explodedRoles, compareAdminRoles);

  const addOperations = addedRoles.map((addedRole) => ({
    op: 'add',
    path: `/${model.id}/roles/${getRoleTarget(addedRole)}`,
  }));

  const removeOperations = removedRoles.map((removedRole) => ({
    op: 'remove',
    path: `/${model.id}/roles/${getRoleTarget(removedRole)}`,
  }));

  return [...new Set([...addOperations, ...removeOperations])];

  function getRoleTarget(role) {
    let suffix = '';
    if (role.type === LICENSE_ADMIN || role.type === LICENSE_DEV_ADMIN) {
      suffix = `/${role.targetId}/product/${role.targetParentId}`;
    } else if (
      role.type === CONTRACT_ADMIN ||
      role.type === PRODUCT_ADMIN ||
      role.type === PRODUCT_SUPPORT_ADMIN ||
      role.type === USER_GROUP_ADMIN
    ) {
      suffix = `/${role.targetId}`;
    }
    return `${role.type}${suffix}`;
  }
}

/**
 * @description Filters the users list for those with role changes.
 *
 * @param {OrganizationUser[]} users - an array of OrganizationUser objects to inspect for changes.
 * @returns {OrganizationUser[]} users with role changes.
 */
function getUsersWithModifiedRoles(users) {
  return users.filter(
    (user) =>
      !compareArrays(
        explodeAdminRoles(user.savedState.roles),
        explodeAdminRoles(user.roles),
        compareAdminRoles
      )
  );
}

export {compareAdminRoles, explodeAdminRoles, getAdminRolePatches, getUsersWithModifiedRoles};
