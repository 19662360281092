import axios from 'axios';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL user groups APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL User Groups api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Uploads CSV to add user groups
 *
 * @param {FormData} data - CSV file as FormData object
 * @param {String} orgId - The org id
 */
const bulkAddUserGroups = ({data, orgId}) =>
  axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'add-user-groups',
    },
  });

/**
 * @description Uploads CSV to add users to user group
 *
 * @param {FormData} data - CSV file as FormData object
 * @param {String} orgId - The org id
 * @param {String} params.userGroupId - The id of the user group to which the users will be added to
 */
const bulkAddUserGroupUsers = ({data, orgId, params = {}}) =>
  axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'ug-add-users',
      ...params,
    },
  });

/**
 * @description Uploads CSV to edit user groups
 *
 * @param {FormData} data - CSV file as FormData object
 * @param {String} orgId - The org id
 */
const bulkEditUserGroups = ({data, orgId}) =>
  axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'edit-user-groups',
    },
  });

/**
 * @description Uploads CSV to remove users from user group
 *
 * @param {FormData} data - CSV file as FormData object
 * @param {String} orgId - The org id
 * @param {String} params.userGroupId - The id of the user group to which the users will be removed from
 */
const bulkRemoveUserGroupUsers = ({data, orgId, params = {}}) =>
  axios.post(`${url}/v2/organizations/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'ug-remove-users',
      ...params,
    },
  });

/**
 * Fetches user group admins
 *
 * @param {String} userGroupId - The group id
 * @param {String} orgId - The org id
 * @param {String} [adminId] - Optional. The user id (if defined, will fetch a single admin from
 *                            a user group. Default to undefined to fetch all admins)
 * @param {String} userGroupId - The user group id
 * @param {Object} params - The JIL query params
 */
const getUserGroupAdmins = ({orgId, adminId, userGroupId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/admins/${adminId || ''}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * Modifies user group admins
 *
 * @param {String} userGroupId - The group id
 * @param {Array<String>} patchOperations - The patch operations to update the user group admin configurations with
 * @param {String} orgId - The org id
 * @param {String} userGroupId - The user group id
 */
const patchUserGroupAdmins = ({operations, orgId, userGroupId}) =>
  axios.patch(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/admins`, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Fetches all license groups linked to a user group
 *
 * @param {String} orgId - The org id
 * @param {String} userGroupId - The user group id
 * @param {Object} params - The JIL query params
 */
const getUserGroupLicenseGroups = ({orgId, userGroupId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/license-groups`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * Delete a license group/user group link
 *
 * @param {String} licenseGroupId - The license group id
 * @param {String} orgId - The org id
 * @param {String} userGroupId - The user group id
 */
const deleteUserGroupLicenseGroup = ({licenseGroupId, orgId, userGroupId}) =>
  axios.delete(
    `${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/license-groups/${licenseGroupId}`,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Modifies license groups linked to a user group
 *
 * @param {String} orgId - The org id
 * @param {Array<String>} patchOperations -  The patch operations to update license groups
 * @param {String} userGroupId - The user group id
 */
const patchUserGroupLicenseGroups = ({orgId, userGroupId}, patchOperations) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/license-groups`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * Links a license group to a user group
 *
 * @param {String} orgId - The org id
 * @param {Object} licenseGroup - The license group object
 * @param {String} userGroupId - The user group id
 */
const postUserGroupLicenseGroups = ({orgId, userGroupId}, licenseGroup) =>
  axios.post(
    `${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/license-groups`,
    licenseGroup,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Fetches paginated list of user groups, or a specific user group when given the userGroupId
 *
 * @param {String} orgId - The org id
 * @param {String} [userGroupId] - The user group id to fetch. If undefined, will fetch a paginated list
 *                                 of user groups with params below
 * @param {Object} params - The JIL query params
 */
const getUserGroups = ({orgId, userGroupId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId || ''}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * Create a new user group
 *
 * @param {String} orgId - The org id
 * @param {Object} userGroup - The user group object
 */
const postUserGroup = ({orgId}, userGroup) =>
  axios.post(`${url}/v2/organizations/${orgId}/user-groups`, userGroup, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Deletes a user group
 *
 * @param {String} orgId - The org id
 * @param {String} userGroupId - The user group id
 */
const deleteUserGroup = ({orgId, userGroupId}) =>
  axios.delete(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Gets CSV of an organization's user groups
 *
 * @param {String} orgId - The org id
 * @returns {Promise<Object>} - The Axios response
 */
const exportUserGroups = async ({orgId, params}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/user-groups/`, {
    headers: {
      Accept: 'text/csv+user-groups-all,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });
  return csvBlobTransformer(response);
};

/**
 * Fetches user group users list
 *
 * @param {String} userGroupId - The group id
 * @param {String} orgId - The org id
 * @param {String} [userId] - Optional. The user id (if defined, will fetch a single user from a user
 *                            group. Default to undefined to fetch user group users list)
 */
const getUserGroupUsersList = ({orgId, userGroupId, userId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/users/${userId || ''}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * Delete a user from a user group
 *
 * @param {String} userGroupId - The group id
 * @param {String} orgId - The org id
 * @param {String} userId - The user id
 */
const deleteUserGroupUser = ({userGroupId, orgId, userId}) =>
  axios.delete(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/users/${userId}`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Modify users in a user group
 *
 * @param {String} orgId - The org id
 * @param {Array<String>} patchOperations - The patch operations to update the user group configurations with
 * @param {String} userGroupId - The user group id
 */
const patchUserGroupUsers = ({operations, orgId, userGroupId}) =>
  axios.patch(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/users`, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Add user to a user group
 *
 * @param {String} orgId - The org id
 * @param {Object} user - The user object
 * @param {String} userGroupId - The user group id
 */
const postUserGroupUser = ({orgId, userGroupId}, user) =>
  axios.post(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/users`, user, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Gets CSV of users for a user group
 * @param {String} userGroupId - The user group id
 * @param {String} orgId - The org id
 * @returns {Promise<Object>} - Object containing blob representation of csv
 */
const exportUserGroupUsers = async ({orgId, userGroupId, params}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/user-groups/${userGroupId}/users/`,
    {
      headers: {
        Accept: 'text/csv+user-ug,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
      params,
    }
  );
  return csvBlobTransformer(response);
};

/**
 * Update a user group
 *
 * @param {String} orgId - The org id
 * @param {UserGroup} userGroup - The user group object
 * @param {String} userGroupId - The user group id
 * @returns {UserGroup} - The updated user group
 */
const putUserGroup = ({orgId, userGroupId}, userGroup) =>
  axios.put(`${url}/v2/organizations/${orgId}/user-groups/${userGroupId}`, userGroup, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilUserGroups = {
  bulkAddUserGroups,
  bulkAddUserGroupUsers,
  bulkEditUserGroups,
  bulkRemoveUserGroupUsers,
  configure,
  deleteUserGroup,
  deleteUserGroupLicenseGroup,
  deleteUserGroupUser,
  exportUserGroups,
  exportUserGroupUsers,
  getUserGroupAdmins,
  getUserGroupLicenseGroups,
  getUserGroups,
  getUserGroupUsersList,
  patchUserGroupAdmins,
  patchUserGroupLicenseGroups,
  patchUserGroupUsers,
  postUserGroup,
  postUserGroupLicenseGroups,
  postUserGroupUser,
  putUserGroup,
};

export default jilUserGroups;
