import classNames from 'classnames';
import React from 'react';

import NavigationAnchor from 'common/components/navigation-anchor/NavigationAnchor';
import NavItemPopover from 'shell/nav/NavItemPopover';
import {WORKSPACE_NAV} from 'shell/nav/NavItemPopoverConstants';

import styles from './HorizontalWorkspaceNavItems.pcss';
import NavIconAndLabel from './NavIconAndLabel';
import {useWorkspaceNavContext} from './WorkspaceNavContext';

/**
 * Nav items widget to be displayed when the
 */
const HorizontalWorkspaceNavItems = () => {
  const {navAriaLabel, navItems, selectedNavItemKey} = useWorkspaceNavContext();

  return (
    <nav aria-label={navAriaLabel} className={styles['horizontal-nav-items']}>
      <ul>
        {navItems.map((workspaceNavItem) => (
          <li key={workspaceNavItem.key || workspaceNavItem.name}>
            {workspaceNavItem.isGroup() && (
              <NavItemPopover
                colorScheme="light"
                context={WORKSPACE_NAV}
                groupName={workspaceNavItem.name}
                navItems={workspaceNavItem.items}
                selectedKey={selectedNavItemKey}
              />
            )}
            {!workspaceNavItem.isGroup() && (
              <NavigationAnchor
                aria-current={workspaceNavItem.key === selectedNavItemKey ? 'page' : false}
                className={classNames(
                  workspaceNavItem.key === selectedNavItemKey && styles['selected-anchor'],
                  workspaceNavItem.key !== selectedNavItemKey && styles['unselected-anchor']
                )}
                data-testid={workspaceNavItem.key}
                href={workspaceNavItem.href}
              >
                <NavIconAndLabel workspaceNavItem={workspaceNavItem} />
              </NavigationAnchor>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default HorizontalWorkspaceNavItems;
