const FULFILLMENT_EVENT_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.fulfillmentEvent.FULFILLMENT_EVENT_CONSTANTS;

/**
 * @deprecated These have been ported to src2/core/models/fulfillmentEvent/FulfillmentEventConstants.js
 *    Any subsequent changes made to these constants should be replicated there.
 */
(function () {
  /**
   * @deprecated Please use src2/core/models/fulfillmentEvent/FulfillmentEventConstants.js
   */
  angular
    .module('binky.core.fulfillment-events')
    .constant('FULFILLMENT_EVENT_STATUS', FULFILLMENT_EVENT_CONSTANTS.FULFILLMENT_EVENT_STATUS);
})();
