/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {Flex, ProgressCircle} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

import './MenuLoading.pcss';

const {DEFAULT_MSG_HEIGHT, DEFAULT_MSG_WIDTH} = ASSIGNMENT_MENU_CONSTANTS;

// A component to show the loading spinner. Shared between menus within AssignmentMenu.
const MenuLoading = () => {
  const intl = useIntl();

  return (
    <Flex
      alignItems="center"
      height={DEFAULT_MSG_HEIGHT}
      justifyContent="center"
      width={DEFAULT_MSG_WIDTH}
    >
      <div
        aria-label={intl.formatMessage({
          id: 'binky.common.assignmentMenu.shared.progressCircle.label',
        })}
        data-testid="menu-loading"
        styleName="loading"
        //  Add tabIndex to this div so that when this component is rendered within the Submenu, React Spectrum
        //  can properly give focus to an element. Otherwise, when there isn't any focusable element within the Submenu
        //  React Spectrum will fail to autofocus an element and result in buggy behavior when trying to scroll through
        //  a menu with lazily loading Submenus.
        //  eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- see above comment
        tabIndex="0"
      >
        <ProgressCircle
          aria-label={intl.formatMessage({
            id: 'binky.common.assignmentMenu.shared.progressCircle.label',
          })}
          isIndeterminate
        />
      </div>
    </Flex>
  );
};

MenuLoading.propTypes = {};

export default MenuLoading;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
