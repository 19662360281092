(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.detail-section
   * @description module that defines binky.widgets.common.detail-section widget to use to construct
   *  detail-sections to display in drawers and detail pages
   */
  angular.module('binky.widgets.common.detail-section', [
    'binky.core.lodash',
    'binky.widgets.common.wait',
    'pascalprecht.translate',
  ]);
})();
