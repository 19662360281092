/* eslint-disable max-statements -- disabling so as to not affect ngInject */
/* eslint-disable max-lines */
(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.common.member.member-list-table:binkyMemberListTable
   * @description Component to display list of members in a table with avatars
   *
   * @param {Boolean} canViewMemberDetails Whether the member details can be viewed or not
   * @param {Boolean} disableRowClick The interaction to click on row
   * @param {Boolean} disableSort The sort functionality
   * @param {Object} [display] An object defining which columns of the table are enabled. See below.
   * @param {Boolean} [display.accountStatus] The account status column.
   * @param {Boolean} [display.adminRole] The admin role column.
   * @param {Boolean} [display.checkbox] The checkbox column. if true, must specify memberIsEditable callback as well.
   * @param {Boolean} [display.email] The email column.
   * @param {Boolean} [display.idType] The id type column.
   * @param {Boolean} [display.productIcons] The product icons column.
   * @param {Boolean} [display.productProfile] The product profile column.
   * @param {Boolean} [display.username] The username column.
   * @param {Boolean} [display.expiration] The expiration column.
   * @param {Function} getProductsForMember Callback to get the list of products for a member
   * @param {Boolean} isAdminContext determines whether to offer the admin context experience
   * @param {Function} memberIsEditable callback to determine whether a particular member is editable (checkbox enabled).
   * @param {MemberList} memberList A list of members.
   * @param {Function} [onMoreProductsClick] callback triggered when the product icon lists' more products button is
   *     clicked. Required to be passed when display.productIcons === true
   * @param {Function} onRefreshData callback triggered when the data is
   *     refreshed, such as for a page change, sort, etc.
   * @param {Function} onRowClick callback for when the row has been clicked
   * @param {String} paginationId The pagination id.
   * @param {Selection} selection The selection list object tracking which members are selected.
   */
  angular
    .module('binky.widgets.common.member.member-list-table')
    .component('binkyMemberListTable', {
      bindings: {
        canViewMemberDetails: '<?',
        disableRowClick: '<?',
        disableSort: '<?',
        display: '<?',
        getProductsForMember: '<?',
        isAdminContext: '<?',
        memberIsEditable: '<?',
        memberList: '<',
        noMembersString: '<?',
        onMoreProductsClick: '<?',
        onRefreshData: '&?',
        onRoleChange: '&?',
        onRowClick: '<?',
        paginationId: '@',
        roles: '<?',
        selection: '<',
      },
      controller,
      templateUrl: 'widgets/common/member/member-list-table/member-list-table.component.html',
      transclude: {
        'no-members-content': '?noMembersContent',
      },
    });

  /* @ngInject */
  function controller(
    $scope,
    $timeout,
    $translate,
    _,
    binkyMemberNameLabelFilter,
    binkyUISrc2,
    feature,
    MemberType,
    MEMBER_TYPE,
    SELECTION_STATE,
    JIL_CONSTANTS
    // USER_LIST_REFRESH
  ) {
    const vm = this;

    _.assign(vm, {
      SORT_EMAIL: JIL_CONSTANTS.SORT.EMAIL,
      SORT_FNAME_LNAME: JIL_CONSTANTS.SORT.FNAME_LNAME,
      SORT_ORDER_ASC: JIL_CONSTANTS.ORDER.ASC,
      SORT_ORDER_DESC: JIL_CONSTANTS.ORDER.DESC,
      SORT_USERNAME: JIL_CONSTANTS.SORT.USERNAME,
    });

    _.assign(vm, {
      $onInit,
      feature,
      getDefaultProductRole,
      getMemberNameType,
      getMemberProducts,
      getMemberRowLabel,
      getMemberType,
      getNumberOfColumns,
      getOnMoreProductsClick,
      getSortAnnouncementText,
      getSortInstruction,
      isChecked,
      isEditable,
      memberTrRole: 'row',
      memberTrTabindex: '0',
      onClickCheck,
      onClickCheckAll,
      onClickRole,
      onClickRow,
      onGoToNextPage,
      onGoToPreviousPage,
      onPageChange,
      onPageSizeChange,
      ProductIconList: binkyUISrc2.common.components.ProductIconList,
      sortableColInfo: [],
      sortColumn,
      sortInstructionDefault: '',
      sortOrderChangedCol: '',
      sortOrderingIdPrefix: _.uniqueId('profile-list-sort-ordering-'),
    });

    function $onInit() {
      if (_.isUndefined(vm.noMembersString)) {
        vm.noMembersString = $translate.instant('binky.widgets.members.memberListTable.noUsers');
      }
      vm.showAvatar = _.get(vm, 'display.avatar', true);
      setRowIds();

      setColumnInfo();
      if (vm.disableRowClick || !vm.canViewMemberDetails) {
        // Make sure the row is not tabbable if clicking is disabled or will not open the user drawer.
        vm.memberTrTabindex = undefined;
      }
    }

    // $scope.$on(USER_LIST_REFRESH, onRefresh);
    $scope.$on('user-list:refresh', onRefresh);

    function isChecked(member) {
      return vm.selection.isItemSelected(member);
    }

    function getDefaultProductRole() {
      return _.find(vm.roles, {default: true}) || {};
    }

    function getMemberProducts(member) {
      return _.invoke(vm, 'getProductsForMember', member);
    }

    function getMemberRowLabel(member) {
      if (vm.disableRowClick || !vm.canViewMemberDetails) {
        return undefined;
      }

      return $translate.instant('binky.widgets.members.memberListTable.rowLabel', {
        userName: member.memberLabel,
      });
    }

    function getMemberType(member) {
      if (feature.isEnabled('temp_remove_business_id') && member.type === MEMBER_TYPE.TYPE2E) {
        return _.has(member, 'authenticatingAccount.type')
          ? // Linting updates revealed this line lacks coverage. As the code is deprecated, we won't address it.
            /* istanbul ignore next */
            new MemberType(member.authenticatingAccount.type, member.authenticatingAccount.id)
          : new MemberType(MEMBER_TYPE.NOT_AVAILABLE, undefined);
      }
      return member.getType();
    }

    function isEditable(member) {
      return _.invoke(vm, 'memberIsEditable', member);
    }

    function onClickCheck(member) {
      vm.selection.toggleItemSelection(member);

      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    function getEditableMembers() {
      return _.filter(vm.memberList.items, isEditable);
    }

    function getMemberNameType(member) {
      const memberType = member.getType();

      if (memberType.isUserGroup()) {
        return 'Group';
      }

      if (vm.canViewMemberDetails) {
        if (memberType.isUser() && !memberType.isTechnicalAccount()) {
          return vm.isAdminContext ? 'Admin' : 'User';
        }
      }

      return 'Other';
    }

    function getNumberOfColumns() {
      // Add 1 to allow for the Name column as it is always present
      return _.keys(vm.display).length + 1;
    }

    function getOnMoreProductsClick(member) {
      return function () {
        _.invoke(vm, 'onMoreProductsClick', member);
      };
    }

    function getSortAnnouncementText() {
      let announcement = '';
      if (vm.sortOrderChangedCol !== '') {
        if (vm.memberList.sort.order === vm.SORT_ORDER_DESC) {
          announcement = vm.sortableColInfo[vm.sortOrderChangedCol].sortAnnouncementDesc;
        }
        if (vm.memberList.sort.order === vm.SORT_ORDER_ASC) {
          announcement = vm.sortableColInfo[vm.sortOrderChangedCol].sortAnnouncementAsc;
        }
      }
      return announcement;
    }

    function getSortInstruction(column) {
      if (
        vm.memberList.sort.expression === column &&
        vm.memberList.sort.order === vm.SORT_ORDER_ASC
      ) {
        return vm.sortableColInfo[column].sortInstructionAsc;
      } else if (
        vm.memberList.sort.expression === column &&
        vm.memberList.sort.order === vm.SORT_ORDER_DESC
      ) {
        return vm.sortableColInfo[column].sortInstructionDesc;
      }

      return vm.sortInstructionDefault;
    }

    function onClickCheckAll() {
      const editableMembers = getEditableMembers();
      if (vm.isAllChecked) {
        vm.selection.deselectItems(editableMembers);
      } else {
        vm.selection.selectItems(editableMembers);
      }

      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    // Trigger the provided row click responder, if the click wasn't on a reserved element
    function onClickRow($event, member) {
      if (wasClickOnReservedElement($event) || vm.disableRowClick) {
        return;
      }
      _.invoke(vm, 'onRowClick', member);
    }

    function onClickRole($event) {
      // Don't want a row click detected.
      $event.stopPropagation();
    }

    function onPageChange(newPage) {
      vm.memberList.pagination.currentPage = newPage;
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.memberList.pagination.pageSize = pageSize;
      vm.memberList.pagination.currentPage = 1;
      refreshData();
    }

    function onRefresh() {
      updateHeaderCheckbox();
    }

    function onGoToNextPage() {
      vm.memberList.state.goToNextPage();
      refreshData();
    }

    function onGoToPreviousPage() {
      vm.memberList.state.goToPreviousPage();
      refreshData();
    }

    function sortColumn(expression) {
      if (vm.memberList.items.length === 0 || vm.disableSort) {
        return;
      }
      if (vm.memberList.sort.expression === expression) {
        if (vm.memberList.sort.order === JIL_CONSTANTS.ORDER.ASC) {
          vm.memberList.sort.order = JIL_CONSTANTS.ORDER.DESC;
        } else {
          vm.memberList.sort.order = JIL_CONSTANTS.ORDER.ASC;
        }
      } else {
        vm.memberList.sort.expression = expression;
        vm.memberList.sort.order = JIL_CONSTANTS.ORDER.ASC;
      }
      refreshData();

      // After sorting, we need to notify users of assistive technology that this has happened.
      // We only want the area that announces this to be visible long enough to let the user know.
      // This mimics behaviour in the coral table component. See also:
      // https://opensource.adobe.com/coral-spectrum/documentation/file/coral-spectrum/coral-component-table/src/scripts/Table.js.html#lineNumber1623
      vm.sortOrderChangedCol = expression;
      $timeout(() => {
        vm.sortOrderChangedCol = '';
      }, 2500);
    }

    ///////////////

    function getColumnInfo(colFullname, colShortName) {
      const label = $translate.instant(
        `binky.widgets.members.memberListTable.columns.${colShortName}`
      );
      return {
        label,
        sortAnnouncementAsc: $translate.instant(
          'binky.widgets.members.memberListTable.columns.sortedAscending',
          {sortColumn: label}
        ),
        sortAnnouncementDesc: $translate.instant(
          'binky.widgets.members.memberListTable.columns.sortedDescending',
          {sortColumn: label}
        ),
        sortInstructionAsc: $translate.instant(
          'binky.widgets.members.memberListTable.columns.sortCurrentAscending',
          {sortColumn: label}
        ),
        sortInstructionDesc: $translate.instant(
          'binky.widgets.members.memberListTable.columns.sortCurrentDescending',
          {sortColumn: label}
        ),
        sortOrderId: `${vm.sortOrderingIdPrefix}-${colFullname}`,
      };
    }

    function setColumnInfo() {
      // Do all the label translations once for column sort accessibility information.
      vm.sortInstructionDefault = $translate.instant(
        'binky.widgets.members.memberListTable.columns.sortAscending'
      );
      vm.sortableColInfo[vm.SORT_FNAME_LNAME] = getColumnInfo(vm.SORT_FNAME_LNAME, 'name');
      vm.sortableColInfo[vm.SORT_EMAIL] = getColumnInfo(vm.SORT_EMAIL, 'email');
      vm.sortableColInfo[vm.SORT_USERNAME] = getColumnInfo(vm.SORT_USERNAME, 'username');
    }

    function setRowIds() {
      _.forEach(vm.memberList.items, (item) => {
        if (item.getDisplayName()) {
          _.assign(item, {
            memberLabel: binkyMemberNameLabelFilter(item),
            rowId: _.uniqueId('member-list-table-row-'),
          });
        } else {
          _.assign(item, {
            memberLabel: '',
          });
        }
      });
    }

    function wasClickOnReservedElement($event) {
      if ($event.target) {
        const targetEl = angular.element($event.target);
        return (
          targetEl[0].tagName === 'A' ||
          targetEl[0].tagName === 'INPUT' ||
          targetEl[0].tagName === 'LABEL'
        );
      }
      return false;
    }

    function refreshData() {
      vm.memberList.refresh().finally(() => {
        updateHeaderCheckbox();
        setRowIds();
      });
      _.invoke(vm, 'onRefreshData');
    }

    function updateHeaderCheckbox() {
      if (vm.memberList && vm.memberList.items && vm.memberList.items.length > 0) {
        const editableMembers = getEditableMembers();
        const selectionState = vm.selection.getSelectionStateForItems(editableMembers);
        vm.isAllChecked = editableMembers.length > 0 && selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked =
          editableMembers.length > 0 && selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
/* eslint-enable max-lines */
/* eslint-enable max-statements -- disabling so as to not affect ngInject */
