import {Contract} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import MenuItemCheckbox from 'common/components/assignment-menu/menu-item-checkbox/MenuItemCheckbox';

import {useAssignmentMenuContext} from '../AssignmentMenuContext';
import MenuNoItemsMessage from '../shared/MenuNoItemsMessage';

const ContractListMenu = ({onSelection}) => {
  const {isSelected, selectableItems, toggleItem} = useAssignmentMenuContext();
  const intl = useIntl();
  const selectableContracts = selectableItems.filter((item) => item instanceof Contract);

  return selectableContracts.length > 0 ? (
    <>
      {selectableContracts.map((contract) => (
        <MenuItemCheckbox
          key={contract.id}
          isSelected={isSelected}
          item={contract}
          label={contract.displayName}
          onSelection={(item, selected) => {
            toggleItem(item);
            // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unreachable else code as onSelection prop is marked as required
            // istanbul ignore else
            if (onSelection) {
              onSelection(item, selected);
            }
          }}
        />
      ))}
    </>
  ) : (
    <MenuNoItemsMessage
      heading={intl.formatMessage({
        id: 'binky.common.assignmentMenu.contractMenu.messages.noContract.heading',
      })}
    />
  );
};

ContractListMenu.propTypes = {
  onSelection: PropTypes.func.isRequired,
};

export default ContractListMenu;
