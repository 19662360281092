const CONTRACT_LIST_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.contract.ContractListConstants;
(function () {
  /**
   * @deprecated Please use src2/core/services/contract/ContractListConstants.js
   */
  angular
    .module('binky.core.contract')
    .constant('CONTRACT_LIST_CACHE_ID', CONTRACT_LIST_CONSTANTS.CONTRACT_LIST_CACHE_ID)
    .constant('CONTRACT_LIST_EVENT', CONTRACT_LIST_CONSTANTS.CONTRACT_LIST_EVENT);
})();
