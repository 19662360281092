(function () {
  /**
   * @awaitingdeprecation
   */
  angular
    .module('binky.core.configuration.ready')
    .provider('configurationReady', configurationReady);

  /* @ngInject */
  function configurationReady(binkySrc2) {
    let configData = null;
    const configStore = binkySrc2.services.configStore;
    const initInjector = angular.injector(['ng']);
    const $q = initInjector.get('$q');
    const $http = initInjector.get('$http');
    const ENV_CONFIGURATION_FILE = '/configuration.495e31ace1.json';
    const deferred = $q.defer();

    this.whenConfigReady = whenConfigReady;
    this.configure = configure;

    this.$get = $get;

    ////////////

    function configure() {
      const timestamp = Date.now();
      const noCacheConfigFile = `${ENV_CONFIGURATION_FILE}?t=${timestamp}`;
      $http
        .get(noCacheConfigFile)
        .then((response) => {
          configData = response.data;
          configStore.initialize(configData);
          deferred.resolve(configData);
        })
        .catch(deferred.reject);
      return deferred.promise;
    }

    /* @ngInject */
    function $get() {
      const result = {
        getConfig,
      };
      return result;

      ////////////

      function getConfig() {
        return configData;
      }
    }

    ////////////

    function whenConfigReady() {
      return deferred.promise;
    }
  }
})();
