(function () {
  'use strict';
  /**
   * @deprecated by getUserRoleName in src2
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.user.user-role').filter('binkyUserRoleName', binkyUserRoleName);

  /* @ngInject */
  function binkyUserRoleName($translate, PRODUCT_ROLE, USER_ROLE) {
    return getUserRoleName;

    // eslint-disable-next-line complexity
    function getUserRoleName(type) {
      switch (type) {
        case USER_ROLE.ADOBE_AGENT.ADMIN:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.admin');
        case USER_ROLE.ADOBE_AGENT.CUSTOMER_CARE:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.customerCare');
        case USER_ROLE.ADOBE_AGENT.PROFESSIONAL_SERVICES:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.professionalServices');
        case USER_ROLE.ADOBE_AGENT.PROVISIONER:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.provisioner');
        case USER_ROLE.ADOBE_AGENT.READ:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.read');
        case USER_ROLE.ADOBE_AGENT.RESELLER_LICENSING:
          return $translate.instant('binky.widgets.user.userRole.adobeAgent.resellerLicensing');
        case USER_ROLE.COMPARTMENT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.compartmentAdmin.shortName');
        case USER_ROLE.COMPARTMENT_VIEWER:
          return $translate.instant(
            'binky.widgets.user.userRole.admin.compartmentViewer.shortName'
          );
        case USER_ROLE.CONTRACT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.contract.shortName');
        case USER_ROLE.DEPLOYMENT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.deployment.shortName');
        case USER_ROLE.LICENSE_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.license.shortName');
        case USER_ROLE.ORG_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.org.shortName');
        case USER_ROLE.PRODUCT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.product.shortName');
        case USER_ROLE.PRODUCT_SUPPORT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.productSupport.shortName');
        case USER_ROLE.STORAGE_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.storage.shortName');
        case USER_ROLE.SUPPORT_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.support.shortName');
        case USER_ROLE.USER_GROUP_ADMIN:
          return $translate.instant('binky.widgets.user.userRole.admin.userGroup.shortName');
        case PRODUCT_ROLE.APPROVER:
          return $translate.instant('binky.widgets.user.userRole.product.approver');
        case PRODUCT_ROLE.EDITOR:
          return $translate.instant('binky.widgets.user.userRole.product.editor');
        case PRODUCT_ROLE.OBSERVER:
          return $translate.instant('binky.widgets.user.userRole.product.observer');
        case PRODUCT_ROLE.PUBLISHER:
          return $translate.instant('binky.widgets.user.userRole.product.publisher');
        default:
          throw new Error(`Unable to resolve name for type (${type})`);
      }
    }
  }
})();
