(function () {
  /**
   * @deprecated Please use src2/core/services/member/MemberList.js
   *
   * @ngdoc factory
   * @name MemberList
   * @description Abstract model for people list objects
   */
  angular.module('binky.core.common.member').factory('MemberList', getMemberList);

  /* @ngInject */
  function getMemberList($log, $q, $rootScope, _, JIL_CONSTANTS, List, MEMBER_EVENT, modelCache) {
    class MemberList extends List {
      /**
       * @class
       * @description Constructor for MemberList model Objects.
       * @param {Object} [options] - MemberList instance settings
       * @param {String} [options.cacheType] - key to use to find the cache
       * @param {String} [options.filterExcludeDomain] - domain to exclude from results
       * @param {String} [options.filterIncludeDomain] - domain to include in results
       * @param {String} [options.filterQuery] - query to filter results against
       * @param {Number} [options.pageNumber] - page number of cached model to fetch
       * @param {Number} [options.pageSize] - number of users to display per page
       * @param {Resource} [options.resource] - $resource object to perform operations on
       * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
       * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
       * @param {Array} [options.members] - raw members to store in this list
       * @param {Class} [options.memberClassRef] - class to instantiate list members as on refresh
       */
      constructor(options = {}) {
        super({
          cacheType: options.cacheType,
          filterQuery: options.filterQuery,
          itemClassRef: options.memberClassRef,
          items: options.members,
          pageNumber: options.pageNumber,
          pageSize: options.pageSize,
          resource: options.resource,
          sortExpression: options.sortExpression || JIL_CONSTANTS.SORT.FNAME_LNAME,
          sortOrder: options.sortOrder || JIL_CONSTANTS.ORDER.ASC,
          state: options.state,
        });
        this.filter.excludeDomain = options.filterExcludeDomain;
        this.filter.includeDomain = options.filterIncludeDomain;
      }

      /**
       * @description Method to return the current unique key for the member list.
       *
       * @param {Object} params - params passed into a GET
       * @returns {String} key to uniquely identify this list
       */
      key(params = {}) {
        return super.key(
          _({
            filterExcludeDomain: this.filter.excludeDomain,
            filterIncludeDomain: this.filter.includeDomain,
          })
            .omitBy(_.isUndefined)
            .assign(params)
            .value()
        );
      }

      /**
       * @description
       * Method to refresh the contents of the member list
       *
       * @param {Object} params - params to pass into the GET
       * @param {Object} options - options to control the flow in this function (do not get passed into the GET call)
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(params = {}, options = {}) {
        return super.refresh(
          _({
            filter_exclude_domain: this.filter.excludeDomain,
            filter_include_domain: this.filter.includeDomain,
          })
            .omitBy(_.isUndefined)
            .assign(params)
            .value(),
          options
        );
      }

      /**
       * @description Method to reset this list to its collapsed state.
       *   This is being introduced for tables with a large number of items that wish to return to a search view after
       *   each operation rather than refreshing the entire list.
       *
       * @returns {Promise} a promise which is resolved with the model
       */
      reset() {
        this.filter.lastQuery = '';
        this.filter.query = '';

        this.items = [];

        this.pagination.currentPage = 1;
        this.pagination.itemCount = 0;

        this.sort.expression = '';
        this.sort.order = '';

        return $q.resolve(this);
      }

      /**
       * @description Method to save changes to user list to back-end.
       *
       * @param {Object} params - params to pass into the save operation
       * @param {Object} options - options to alter the behavior of save
       * @param {Object} options.refresh - if true, will refresh the list after saving. Defaults to true.
       * @param {Object} options.removePathOverride - memberId and path pairs to override the
       *                 default remove behavior
       * @param {Object} options.removeProductAndRolePathOverrides - memberId and paths pairs to
       *                 override the default remove behavior
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(params = {}, options = {}) {
        const deferred = $q.defer();
        this.$promise = deferred.promise;

        if (this.hasUnsavedChanges()) {
          const refreshList = _.get(options, 'refresh', true);

          const addOperations = _.map(this.addedItems, (member) => ({
            op: 'add',
            path: `/${member.id}`,
          }));

          let removeOperations;
          if (options.removeProductAndRolePathOverrides) {
            removeOperations = getRemoveUserOperations(
              this.removedItems,
              options.removeProductAndRolePathOverrides
            );
          } else {
            removeOperations = getRemoveOperations(this.removedItems, options.removePathOverride);
          }
          const operations = [...addOperations, ...removeOperations];

          this.$resolved = false;
          this.resource.batchOperation(
            params,
            operations,
            () => {
              _.forEach(this.addedItems, (member) => {
                $rootScope.$emit(MEMBER_EVENT.UPDATE, member.id);
              });
              this.resetAddedItems();
              _.forEach(this.removedItems, (member) => {
                $rootScope.$emit(MEMBER_EVENT.UPDATE, member.id);
              });
              this.resetRemovedItems();
              this.$resolved = true;

              this.filter.lastQuery = this.filter.query;
              this.filter.query = '';
              if (refreshList) {
                this.refresh()
                  .then(() => {
                    deferred.resolve(this);
                  })
                  .catch(onError.bind(this));
              } else {
                deferred.resolve(this);
              }
            },
            onError.bind(this)
          );
        } else {
          // no changes to save
          deferred.resolve();
        }

        function onError(error) {
          $log.error('Failed to add/remove selected members from list. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        return deferred.promise;
      }

      /**
       * @description Configure the model's cache.
       *
       * @param {String} cacheId The id of the cache to store model into (should
       *                         correspond with MODEL constant)
       * @param {Number} capacity The number of objects to keep in the cache at any time
       */
      static setupCache(cacheId, capacity) {
        modelCache.register(cacheId, capacity);
        modelCache.removeAllOnEvent(cacheId, [
          MEMBER_EVENT.CREATE,
          MEMBER_EVENT.DELETE,
          MEMBER_EVENT.UPDATE,
        ]);
      }
    }

    return MemberList;

    //////////////

    /**
     * @description Method to fetch the remove operations on the given users
     *
     * @param {Array} removedItems list of user to be removed
     * @param {Object} pathOverride A map of memberId and path pairs
     *
     * @return {Array} an array of operations to remove the given users
     */
    function getRemoveOperations(removedItems, pathOverride = {}) {
      return _.map(removedItems, (member) => ({
        op: 'remove',
        path: pathOverride[member.id] ? pathOverride[member.id] : `/${member.id}`,
      }));
    }

    /**
     * @description Method to fetch the remove operations on the given users
     *              to remove them from specific products
     *
     * @param {Array} removedItems list of user to be removed
     * @param {Object} pathOverrides A map of memberId and paths pairs
     *
     * @return {Array} an array of operations to remove the given users
     */
    function getRemoveUserOperations(removedItems, pathOverrides) {
      return _.flatMap(removedItems, (member) =>
        _.map(pathOverrides[member.id], (path) => ({
          op: 'remove',
          path,
        }))
      );
    }
  }
})();
