/* eslint-disable react/no-unknown-property -- styleName not recognized */
import binky, {MEMBER_TYPE, feature} from '@admin-tribe/binky';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import SyncIcon from '@react/react-spectrum/Icon/Sync';
import camelCase from 'lodash/camelCase';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import TruncatedText from 'common/components/truncated-text/TruncatedText';
import useCountryList from 'common/hooks/useCountryList';
import {getMemberDisplayName, isT2EUser} from 'features/member/memberDisplayUtils';

import './AddUserFormReadonlyFields.pcss';

const AddUserFormReadonlyFields = observer(({orgUser, showIdType = true}) => {
  const countryList = useCountryList();
  const userCountry = countryList.items.find((item) => item.countryCode === orgUser.countryCode);
  const countryName = userCountry && userCountry.countryName;
  const intl = useIntl();

  const canViewIdType = binky.services.feature.isDisabled('bug_fix_14090') || showIdType;

  let userType;
  if (feature.isEnabled('temp_remove_business_id') && isT2EUser(orgUser)) {
    userType =
      orgUser.authenticatingAccount?.type === undefined
        ? MEMBER_TYPE.NOT_AVAILABLE
        : orgUser.authenticatingAccount.type;
  } else {
    userType = orgUser.type;
  }

  return (
    <>
      {canViewIdType && (
        <div styleName="first-row">
          <FieldLabel
            label={intl.formatMessage({
              id: 'binky.common.addUserFormReadonlyFields.fieldLabel.idType',
            })}
          >
            <TruncatedText data-testid="id-type">
              {intl.formatMessage({id: `binky.common.idType.${camelCase(userType)}`})}
            </TruncatedText>
          </FieldLabel>
        </div>
      )}
      <div styleName="second-row">
        <FieldLabel
          label={intl.formatMessage({id: 'binky.common.addUserFormReadonlyFields.fieldLabel.name'})}
        >
          <span styleName="name-field">
            <TruncatedText data-testid="name">{getMemberDisplayName(intl, orgUser)}</TruncatedText>
            {orgUser.externallyManaged && <SyncIcon size="S" />}
          </span>
        </FieldLabel>
        <FieldLabel
          label={intl.formatMessage({
            id: 'binky.common.addUserFormReadonlyFields.fieldLabel.email',
          })}
        >
          <TruncatedText data-testid="email">{orgUser.email}</TruncatedText>
        </FieldLabel>
        {!!orgUser.userName && (
          <FieldLabel
            label={intl.formatMessage({
              id: 'binky.common.addUserFormReadonlyFields.fieldLabel.ssoUsername',
            })}
          >
            <TruncatedText data-testid="user-name">{orgUser.userName}</TruncatedText>
          </FieldLabel>
        )}
        {countryName && (
          <FieldLabel
            label={intl.formatMessage({
              id: 'binky.common.addUserFormReadonlyFields.fieldLabel.country',
            })}
          >
            <TruncatedText data-testid="country-name">{countryName}</TruncatedText>
          </FieldLabel>
        )}
      </div>
    </>
  );
});

AddUserFormReadonlyFields.propTypes = {
  orgUser: PropTypes.instanceOf(binky.services.organization.OrganizationUser).isRequired,
  showIdType: PropTypes.bool,
};

export default AddUserFormReadonlyFields;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
