/**
 * Method that takes a list of operations and for a given key,
 * removes those operations for which there is an ADD and REMOVE operation.
 * @param {Array<object>} operations
 * @returns Array<object> - de-duped operations to call back with
 */
const mergeOperations = (operations) => {
  // We need an object for faster operation lookups
  const flatOperations = {};
  operations.forEach((operation) => {
    // If we find an opposite operation e.g ADD/REMOVE we cancel both operations
    if (flatOperations[operation.key] && flatOperations[operation.key].type !== operation.type) {
      delete flatOperations[operation.key];
    } else {
      // Otherwise we assign the operation to be processed
      Object.assign(flatOperations, {[operation.key]: operation});
    }
  });
  return Object.values(flatOperations);
};

export default mergeOperations;
