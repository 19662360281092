import pick from 'lodash/pick';

import {PAGE_TARGET, PAGE_TARGET_TYPE} from './PageContextConstants';

export default class PageContext {
  /**
   * @constructs PageContext
   * @description Constructor for PageContext model Objects.
   * @param {Object} options - PageContext instance settings
   * @param {Object} [options.sidecarData] - Optional. Additional data provided
   *     which is custom to this context.
   * @param {String} [options.target] - PAGE_TARGET value. The specific object
   *     being operated on for this page (e.g., Organization, Product, Profile,
   *     etc)
   * @param {String} [options.targetId] - Object ID of the type (e.g.,
   *     productId, profileId, orgId, etc)
   * @param {String} [options.targetParent] - PAGE_TARGET value. The parent of
   *     the object being operated on for this page (e.g., Organization, Product,
   *     Profile, etc)
   * @param {String} [options.targetParentId] - Object ID of the parent type
   *     (e.g., productId, profileId, orgId, etc)
   * @param {String} [options.targetType] - PAGE_TARGET_TYPE value. The type of
   *     object the page is dealing with, underneath the target itself. (e.g.,
   *     User, Admin)
   */
  constructor(options) {
    Object.assign(
      this,
      {sidecarData: {}},
      pick(options, [
        'sidecarData',
        'target',
        'targetId',
        'targetParent',
        'targetParentId',
        'targetType',
      ])
    );
  }

  isAccessManagementTarget() {
    return this.target === PAGE_TARGET.ACCESS_MANAGEMENT;
  }

  isAdminTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.ADMIN;
  }

  isDeveloperTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.DEVELOPER;
  }

  isDevicesPaymentNeededTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.DEVICES_PAYMENT_NEEDED;
  }

  isDevicesTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.DEVICES;
  }

  isDirectoryTarget() {
    return this.target === PAGE_TARGET.DIRECTORY;
  }

  isIntegrationTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.INTEGRATION;
  }

  isOrganizationTarget() {
    return this.target === PAGE_TARGET.ORGANIZATION;
  }

  isProductConfigurationTarget() {
    return this.target === PAGE_TARGET.PRODUCT_CONFIGURATION;
  }

  isProductConfigurationTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.PRODUCT_CONFIGURATION;
  }

  isProductTarget() {
    return this.target === PAGE_TARGET.PRODUCT;
  }

  isStorageTarget() {
    return this.target === PAGE_TARGET.STORAGE;
  }

  isUserFolderTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.USER_FOLDER;
  }

  isUserGroupTarget() {
    return this.target === PAGE_TARGET.USER_GROUP;
  }

  isUserGroupTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.USER_GROUP;
  }

  isUserTargetType() {
    return this.targetType === PAGE_TARGET_TYPE.USER;
  }
}
