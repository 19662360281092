/* eslint-disable max-lines -- Disable max-lines */
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import toNumber from 'lodash/toNumber';
import {action, makeObservable, observable} from 'mobx';

import feature from 'services/feature';

import {
  FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
  FULFILLABLE_ITEM_CHARGING_MODEL_TYPE,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_DELEGATION_TYPE,
  FULFILLABLE_ITEM_TYPE,
} from './FulfillableItemConstants';

class FulfillableItemList {
  items;
  /**
   * @description Creates a new FulfillableItemList for use.
   *
   * @param {Array} items Fulfillable Items which make up this list
   */
  constructor(items = []) {
    makeObservable(this, {
      items: observable,
      setFeatureSetItemValue: action,
      setFulfillmentConfigurableValue: action,
      setRequestorExternalInfoItemValue: action,
      setSelectedValue: action,
    });
    const itemsToProcess = Array.isArray(items) ? items : items.items;
    this.items = processItems(itemsToProcess);
    this.registerSavedState();
  }

  /**
   * @description Method to return consumable fulfillable items of quota type.
   * @param {Object} options - options for getting the items
   * @param {Boolean} [options.requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   *
   * @returns {Array} consumable quotas FIs in the list
   */
  getConsumableQuotaTypeItems(options = {}) {
    const {includeDelegationTypePerson = true} = options;
    const {requireDelegationConfigurable = true} = options;
    const quotaIgnoreList = [
      FULFILLABLE_ITEM_CODE.ACP_BATCH_DATA_ACTIVATION,
      FULFILLABLE_ITEM_CODE.ACP_DATA_SCIENCE_WORKSPACE_INTEL_QUOTA,
      FULFILLABLE_ITEM_CODE.ACP_DATA_SCIENCE_WORKSPACE_NOPRD_PROFILE,
      FULFILLABLE_ITEM_CODE.ACP_DATA_SCIENCE_WORKSPACE_RICHNESS,
      FULFILLABLE_ITEM_CODE.ACP_DATA_SCIENCE_WORKSPACE_SANDBOXES,
      FULFILLABLE_ITEM_CODE.ACP_DATA_SERVICES_FOUNDATION,
      FULFILLABLE_ITEM_CODE.ACP_DSF_STREAMING_QUOTA,
      FULFILLABLE_ITEM_CODE.ACP_HFA,
      FULFILLABLE_ITEM_CODE.ACP_NON_PROD_PROFILES,
      FULFILLABLE_ITEM_CODE.ACP_NON_PROD_PROFILES_RICHNESS,
      FULFILLABLE_ITEM_CODE.ACP_NON_PROD_STREAMINGSEGMENTATION,
      FULFILLABLE_ITEM_CODE.ACP_PROFILE_RICHNESS_PACKS,
      FULFILLABLE_ITEM_CODE.ACP_RT_CDP_NON_PROD_PROFILES,
      FULFILLABLE_ITEM_CODE.ACP_RT_DATA_ACTIVATION,
      FULFILLABLE_ITEM_CODE.ACP_SANDBOXES,
      FULFILLABLE_ITEM_CODE.ACP_UPS_STRAMINGSEGMENTATION,
      FULFILLABLE_ITEM_CODE.CC_STORAGE,
      FULFILLABLE_ITEM_CODE.DC_STORAGE,
      FULFILLABLE_ITEM_CODE.DX_ADOBE_IO_RUNTIME,
      FULFILLABLE_ITEM_CODE.DX_AEM_API_CALLS,
      FULFILLABLE_ITEM_CODE.DX_AEM_ASSET_SHARE_USERS,
      FULFILLABLE_ITEM_CODE.DX_AEM_BRAND_PORTAL_STORAGE,
      FULFILLABLE_ITEM_CODE.DX_AEM_BRAND_PORTAL_USERS,
      FULFILLABLE_ITEM_CODE.DX_AEM_DYNAMIC_MEDIA,
      FULFILLABLE_ITEM_CODE.DX_AEM_ENVIRONMENT,
      FULFILLABLE_ITEM_CODE.DX_AEM_PAGE_VIEWS,
      FULFILLABLE_ITEM_CODE.DX_AEM_STORAGE,
      FULFILLABLE_ITEM_CODE.DX_AEM_USERS_CONCURRENT,
      FULFILLABLE_ITEM_CODE.DX_AEM_USERS_LIGHT,
      FULFILLABLE_ITEM_CODE.DX_AEM_USERS_POWER,
      FULFILLABLE_ITEM_CODE.DX_AEM_USERS_STANDARD,
      FULFILLABLE_ITEM_CODE.DX_CAMPAIGN,
      FULFILLABLE_ITEM_CODE.DX_CJA_API_CALLS,
      FULFILLABLE_ITEM_CODE.DX_CJA_ATTRIBUTIONS,
      FULFILLABLE_ITEM_CODE.DX_CJA_CALC_METRICS,
      FULFILLABLE_ITEM_CODE.DX_CJA_CONCURRENT_REQUESTS,
      FULFILLABLE_ITEM_CODE.DX_CJA_DATA_RESTATEMENT,
      FULFILLABLE_ITEM_CODE.DX_CJA_DATA_TRANSFER,
      FULFILLABLE_ITEM_CODE.DX_CJA_PROFILE_SIZE,
      FULFILLABLE_ITEM_CODE.DX_CJA_PROFILE_SIZE_QUANTITY,
      FULFILLABLE_ITEM_CODE.DX_CJA_PROFILES,
      FULFILLABLE_ITEM_CODE.DX_CJA_PROFILES_RESTATEMENT,
      FULFILLABLE_ITEM_CODE.DX_JOURNEYS_NON_PROD_PROFILES,
      FULFILLABLE_ITEM_CODE.ESM_SHARED_STORAGE,
      FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE,
      FULFILLABLE_ITEM_CODE.OZ_STORAGE,
      FULFILLABLE_ITEM_CODE.PRIVATE_CC_STORAGE,
    ];

    return this.items.filter(
      (item) =>
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
        !quotaIgnoreList.includes(item.code) &&
        (!requireDelegationConfigurable || item.delegationConfigurable) &&
        (includeDelegationTypePerson ||
          item.delegationType !== FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON)
    );
  }

  /**
   * @description Method to return the cap for the cc_storage fulfillable item
   *
   * @returns {Number} storage charging model cap
   */
  getCreativeCloudStorageChargingModelCap() {
    const item = this.items.find((i) => i.code === FULFILLABLE_ITEM_CODE.CC_STORAGE);
    return extractQuotaChargingModelCap(item);
  }

  /**
   * @description Method to return fulfillable items of desktop type.
   *
   * @returns {Array} desktop FIs in the list
   */
  getDesktopTypeItems() {
    return this.items.filter((item) => item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.DESKTOP);
  }

  /**
   * @description Method to return the cap for the esm_user_storage fulfillable item
   *
   * @returns {Number} storage charging model cap
   */
  getESMUserStorageChargingModelCap() {
    const item = this.items.find((i) => i.code === FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE);
    return extractQuotaChargingModelCap(item);
  }

  /**
   * @description Method to return fulfillable items of organization type quota recurring
   *              UNLIMITED cap.
   *
   * @returns {FulfillableItem} the first (and should be only) organization UNLIMITED quota FulfillableItem in the list
   */
  getOrgDelegatableUnlimitedItem() {
    return this.items.find(
      (item) =>
        item.delegationType === FULFILLABLE_ITEM_DELEGATION_TYPE.ORGANIZATION &&
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
        item.chargingModel &&
        item.chargingModel.cap === FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED
    );
  }

  /**
   * @description Method to return fulfillable items of organization type quota on-demand consumable.
   *
   * @returns {FulfillableItem} the first (and should be only) organization on-demand quota FulfillableItem in the list
   */
  getOrgOnDemandConsumableItem() {
    return this.items.find(
      (item) =>
        item.delegationType === FULFILLABLE_ITEM_DELEGATION_TYPE.ORGANIZATION &&
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
        item.chargingModel &&
        item.chargingModel.model === FULFILLABLE_ITEM_CHARGING_MODEL_TYPE.ON_DEMAND
    );
  }

  /**
   * @description Method to return fulfillable items of quota type.
   * @param {Boolean} [requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   * @param {String} [delegationType] if provided, we restrict the response to only items
   *   of the matching delegation type. Defaults to unrestricted.
   *
   * @returns {Array} quotas FIs in the list
   */
  getQuotaTypeItems(requireDelegationConfigurable = true, delegationType = null) {
    return this.items.filter(
      (item) =>
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
        (delegationType === null || item.delegationType === delegationType) &&
        (!requireDelegationConfigurable || item.delegationConfigurable)
    );
  }

  /**
   * @description Method to return fulfillable items of service type for this Product instance.
   * @param {Boolean} [requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   *
   * @returns {Array} services FIs in the list
   */
  getServiceTypeItems(requireDelegationConfigurable = true) {
    return this.items.filter(
      (item) =>
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.SERVICE &&
        (!requireDelegationConfigurable || item.delegationConfigurable)
    );
  }

  /**
   * @description Method to return a copy of the fulfillable items of service type for this Product instance
   *   with selected set to its initial value.
   *   This is used by the product profiles to initialize the configurable services in the profile.
   *
   * @returns {Array} all services FIs in the list with selected set to a Boolean
   */
  getServiceTypeItemsWithSelectedInitialized() {
    return this.getServiceTypeItems().map((service) => {
      const result = cloneDeep(service);
      result.selected = result.purchased && !result.offByDefault;
      return result;
    });
  }

  /**
   * @description Method to return the cap for the Stock Credit fulfillable item
   *
   * @returns {Number} credit charging model cap
   */
  getStockCreditChargingModelCap() {
    const item = this.items.find((i) => i.code === FULFILLABLE_ITEM_CODE.STOCK_CREDIT);
    return extractQuotaChargingModelCap(item);
  }

  /**
   * @description Method to return the cap for the Stock Image fulfillable item
   *
   * @returns {Number} image charging model cap
   */
  getStockImageChargingModelCap() {
    const item = this.items.find((i) => i.code === FULFILLABLE_ITEM_CODE.STOCK_IMAGE);
    return extractQuotaChargingModelCap(item);
  }

  /**
   * @description Method to return stock related charging model caps.
   *
   * @returns {Array} stock charging model caps
   */
  getStockQuotas() {
    return this.items
      .filter((item) =>
        [
          FULFILLABLE_ITEM_CODE.STOCK_CREDIT,
          FULFILLABLE_ITEM_CODE.STOCK_IMAGE,
          FULFILLABLE_ITEM_CODE.STOCK_PREMIUM_CREDITS,
          FULFILLABLE_ITEM_CODE.STOCK_STANDARD_CREDITS,
          FULFILLABLE_ITEM_CODE.STOCK_UNIVERSAL_CREDITS,
          FULFILLABLE_ITEM_CODE.STOCK_VIDEO,
        ].includes(item.code)
      )
      .map((foundItem) => ({
        chargingModelCap: extractQuotaChargingModelCap(foundItem),
        code: foundItem.code,
      }));
  }

  /**
   * @description Method to return the cap for the Stock Video fulfillable item
   *
   * @returns {Number} video charging model cap
   */
  getStockVideoChargingModelCap() {
    const item = this.items.find((i) => i.code === FULFILLABLE_ITEM_CODE.STOCK_VIDEO);
    return extractQuotaChargingModelCap(item);
  }

  /**
   * @description Method to return fulfillable items of support type.
   *
   * @returns {Array} support FIs in the list
   */
  getSupportTypeItems() {
    return this.items.filter((item) => item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.SUPPORT);
  }

  /**
   * @description Method to determine if any item in FulfillableItem list has apiAccess set to true.
   *
   * @returns {Boolean} True if any FulfillableItem has apiAccess set to true, false otherwise.
   */
  hasApiAccess() {
    return this.items.some((item) => item.apiAccess);
  }

  /**
   * @description Method to determine if this list contains the asset sharing
   *   policy config FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasAssetSharingPolicyConfig() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.ASSET_SHARING_POLICY_CONFIG
    );
  }

  /**
   * @description Method to return whether the list has fulfillable items of consumable quota
   *              type.
   * @param {Object} options - options for getting the items
   * @param {Boolean} [options.requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   *
   * @returns {Boolean} true if any fulfillable item is of type consumable quota
   */
  hasConsumableQuotaTypeItems(options = {}) {
    return this.getConsumableQuotaTypeItems(options).length > 0;
  }

  /**
   * @description Method to determine if this list contains FulfillableItem with delegationType set to any non-null value <> 'NA'
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasDelegationType() {
    return this.items.some(
      (item) => !!item.delegationType && item.delegationType !== FULFILLABLE_ITEM_DELEGATION_TYPE.NA
    );
  }

  /**
   * @description Method to determine if list contains desktop type items.
   *
   * @returns {Boolean} true if the list has desktop items, otherwise false
   */
  hasDesktopTypeItems() {
    return this.items.some((item) => item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.DESKTOP);
  }

  /**
   * @description Method to determine if this list contains the DMA ACP CS FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasDmaAcpCs() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.DMA_ACP_CS);
  }

  /**
   * @description Method to determine if this list contains the domain_claiming FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasDomainClaiming() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.DOMAIN_CLAIMING);
  }

  /**
   * @description Method to determine if this list contains the ESM shared storage FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasESMSharedStorage() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.ESM_SHARED_STORAGE);
  }

  /**
   * @description Method to determine if this list contains the ESM user storage FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasESMUserStorage() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE);
  }

  /**
   * @description Method to determine if this list has a support case creation FulfillableItem and
   * does not have a disallow expert session FulfillableItem.
   *
   * @returns {Boolean} True if disallow_expert_sessions does not present, false otherwise.
   */
  hasExpertSessionCreationAllowed() {
    return (
      this.hasSupportCaseCreationAllowed() &&
      !this.items.some(
        (item) => item.code === FULFILLABLE_ITEM_CODE.EXPORT_SESSION_CREATION_DISALLOWED
      )
    );
  }

  /**
   * @description Method to determine if this list contains the laboratory_license_mgmt FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasLaboratoryLicenseManagement() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.LABORATORY_LICENSE_MANAGEMENT
    );
  }

  /**
   * @description Method to determine if this list contains a quota organization delegatable FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasOrgDelegatable() {
    const fiCodeAllowlist = ['sbst_source'];
    const consumableQuotaItems = this.getConsumableQuotaTypeItems({
      requireDelegationConfigurable: false,
    });
    return consumableQuotaItems
      .filter((item) => fiCodeAllowlist.includes(item.code))
      .some(
        (item) =>
          item.delegationType === FULFILLABLE_ITEM_DELEGATION_TYPE.ORGANIZATION &&
          item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA
      );
  }

  /**
   * @description Method to determine if this list contains an organization type quota on-demand consumable FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasOrgOnDemandConsumable() {
    return !!this.getOrgOnDemandConsumableItem();
  }

  /**
   * @description Method to determine if this list contains an organization type quota recurring consumable FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasOrgRecurringConsumable() {
    return this.items.some(
      (item) =>
        item.delegationType === FULFILLABLE_ITEM_DELEGATION_TYPE.ORGANIZATION &&
        item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
        item.chargingModel &&
        item.chargingModel.model === FULFILLABLE_ITEM_CHARGING_MODEL_TYPE.RECURRING
    );
  }

  /**
   * @description Method to determine if this list contains the overdelegation_allowed FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasOverdelegationAllowed() {
    return this.items.some(
      (item) => item.selected && item.code === FULFILLABLE_ITEM_CODE.OVERDELEGATION_ALLOWED
    );
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioning() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.PACKAGE_PRECONDITIONING);
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem
   *   with a charging model unit that contains frl_isolated
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioningForFRLIsolated() {
    return this.hasPackagePreconditioningForFRLType(FULFILLABLE_ITEM_CHARGING_UNIT.FRL_ISOLATED);
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem
   *   with a charging model unit that contains frl_lan
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioningForFRLLan() {
    return this.hasPackagePreconditioningForFRLType(FULFILLABLE_ITEM_CHARGING_UNIT.FRL_LAN);
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem
   *   with a charging model unit that contains frl_offline
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioningForFRLOffline() {
    return this.hasPackagePreconditioningForFRLType(FULFILLABLE_ITEM_CHARGING_UNIT.FRL_OFFLINE);
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem
   *   with a charging model unit that contains frl_online
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioningForFRLOnline() {
    return this.hasPackagePreconditioningForFRLType(FULFILLABLE_ITEM_CHARGING_UNIT.FRL_ONLINE);
  }

  /**
   * @description Method to determine if this list contains the packaging_preconditioning FulfillableItem
   *   with a charging model unit that contains a given FRL flavor
   *
   * @param {String} type - FRL flavor
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasPackagePreconditioningForFRLType(type) {
    return this.items.some(
      (item) =>
        item.code === FULFILLABLE_ITEM_CODE.PACKAGE_PRECONDITIONING &&
        item.chargingModel?.unit?.includes(type)
    );
  }

  /**
   * @description Method to return whether the list has fulfillable items of quota type.
   * @param {Boolean} [requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   *
   * @returns {Boolean} true if any fulfillable item is of type quota
   */
  hasQuotaTypeItems(requireDelegationConfigurable = true) {
    return this.getQuotaTypeItems(requireDelegationConfigurable).length > 0;
  }

  /**
   * @description Method to return whether the list has fulfillable items of service type.
   * @param {Boolean} [requireDelegationConfigurable] true if only configurable items
   *   should be returned. Defaults to true.
   *
   * @returns {Boolean} true if any fulfillable item is of type service
   */
  hasServiceTypeItems(requireDelegationConfigurable = true) {
    return this.getServiceTypeItems(requireDelegationConfigurable).length > 0;
  }

  /**
   * @description Method to determine if this list contains the total_sign_transaction FulfillableItem, optionally, with
   *   the specified chargingModel unit.
   *
   * @param {String} [unit] - If specified, the charging model unit must match too.
   * @returns {Boolean} true if this list has the FulfillableItem with the optionally specified charging model unit, else false
   */
  hasSignTransactions(unit) {
    return this.items.some(
      (item) =>
        item.code === FULFILLABLE_ITEM_CODE.TOTAL_SIGN_TRANSACTIONS &&
        (!unit || (item.chargingModel && item.chargingModel.unit === unit))
    );
  }

  /**
   * @description Method to determine if this list contains the single desktop
   *   application config FulfillableItem.
   *
   * @returns {Boolean} True if the FulfillableItem is present, false otherwise.
   */
  hasSingleDesktopApplicationConfig() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.SINGLE_DESKTOP_APPLICATION_CONFIGURATION
    );
  }

  /**
   * @description Method to determine if this list has a credit quota.
   *
   * @returns {Boolean} true if this list has a credit quota, else false
   */
  hasStockCredits() {
    return this.getStockCreditChargingModelCap() >= 0;
  }

  /**
   * @description Method to determine if this list has an image quota.
   *
   * @returns {Boolean} true if this list has an image quota, else false
   */
  hasStockImages() {
    return this.getStockImageChargingModelCap() >= 0;
  }

  /**
   * @description Method to determine if this list has a video quota.
   *
   * @returns {Boolean} true if this list has a video quota, else false
   */
  hasStockVideos() {
    return this.getStockVideoChargingModelCap() >= 0;
  }

  /**
   * @description Method to determine if this list has a support allowed admins FulfillableItem.
   *
   * @returns {Boolean} True if present, false otherwise.
   */
  hasSupportAllowedAdmins() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.SUPPORT_ALLOWED_ADMINS);
  }

  /**
   * @description Method to determine if this list has a support case creation FulfillableItem.
   *
   * @returns {Boolean} True if present, false otherwise.
   */
  hasSupportCaseCreationAllowed() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.SUPPORT_CASE_CREATION_ALLOWED
    );
  }

  /**
   * @description Method to determine if this list has a support role assignment FulfillableItem.
   *
   * @returns {Boolean} true if present, else false.
   */
  hasSupportRoleAssignmentAllowed() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.SUPPORT_ROLE_ASSIGNMENT_ALLOWED
    );
  }

  /**
   * @description Method to determine if there are any unsaved changes to this list.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return !isEqual(this.items, this.savedState);
  }

  /**
   * @description Method to determine if this list contains User Permission Management.
   *
   * @returns {Boolean} true if the FulfillableItem is present, otherwise false.
   */
  hasUserGroupAssignment() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.USER_GROUP_ASSIGNMENT);
  }

  /**
   * @description Method to determine if this list contains User Permission Management.
   *
   * @returns {Boolean} true if the FulfillableItem is present, otherwise false.
   */
  hasUserPermissionManagement() {
    return this.items.some(
      (item) => item.code === FULFILLABLE_ITEM_CODE.USER_PERMISSION_MANAGEMENT
    );
  }

  /**
   * @description Method to determine if this list has a workspace FulfillableItem
   *
   * @returns {Boolean} True if present, false otherwise.
   */
  hasWorkspaces() {
    return this.items.some((item) => item.code === FULFILLABLE_ITEM_CODE.WORKSPACES);
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   */
  registerSavedState() {
    this.savedState = cloneDeep(this.items);
  }

  /**
   * @description Restores the list items from its saved state
   */
  restore() {
    this.items = cloneDeep(this.savedState);
  }

  /**
   * @description Setter for updating a feature set value.
   *
   * @param {String} fulfillableItemCode - FI code for the FI whose feature set
   *     will be updated.
   * @param {String} featureSetId - Id of the feature set that should be updated.
   * @param {Boolean} value - Value to assign.
   */
  setFeatureSetItemValue(fulfillableItemCode, featureSetId, value) {
    const fulfillableItem = getTargetFulfillableItem(fulfillableItemCode, this.items);
    const featureSetEntry = fulfillableItem.featureSets.find(({id}) => id === featureSetId);
    if (featureSetEntry) {
      featureSetEntry.selected = value;
    }
  }

  /**
   * @description Setter for updating the fulfillmentConfigurable value. To be
   *     used by the intermediary logic that toggles services on/off based on
   *     the CC storage quota value.
   * @param {String} fulfillableItemCode - FI code for the FI whose
   *     fulfillmentConfigurable property will be updated.
   * @param {Boolean} value - Value to assign.
   */
  setFulfillmentConfigurableValue(fulfillableItemCode, value) {
    const fulfillableItem = getTargetFulfillableItem(fulfillableItemCode, this.items);
    fulfillableItem.fulfillmentConfigurable = value;
  }

  /**
   * @description Setter for updating a requestor external info item value.
   *
   * @param {String} fulfillableItemCode - FI code for the FI whose requestor
   *     external info item will be updated.
   * @param {String} reiItemKey - Key of the requestor external info item that
   *     should be updated.
   * @param {Boolean} value - Value to assign.
   */
  setRequestorExternalInfoItemValue(fulfillableItemCode, reiItemKey, value) {
    const fulfillableItem = getTargetFulfillableItem(fulfillableItemCode, this.items);
    const requestorExternalInfoItem = fulfillableItem.requestorExternalInfo.find(
      ({key}) => key === reiItemKey
    );
    if (requestorExternalInfoItem) {
      requestorExternalInfoItem.value = value;
    }
  }

  /**
   * @description Setter for updating the selected value for a fulfillable item.
   *
   * @param {String} fulfillableItemCode - FI code for the FI whose selected
   *     value will be updated.
   * @param {Boolean} value - Value to assign.
   */
  setSelectedValue(fulfillableItemCode, value) {
    const fulfillableItem = getTargetFulfillableItem(fulfillableItemCode, this.items);
    fulfillableItem.selected = value;
  }

  /**
   * @description Method to transform the list into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @param {Object} [options] - Configuration for this function call.
   * @param {Boolean} [options.filterToFulfillmentConfigurable] - Whether to limit to FIs
   *    that are fulfillment configurable. Defaults to false.
   * @param {Boolean} [options.filterToModified] - Whether to limit to FIs
   *    that have been modified. Defaults to false.
   * @returns {Array} minimum necessary representation of the list
   */
  toMinimumModel(options = {}) {
    const {filterToFulfillmentConfigurable = false, filterToModified = false} = options;
    return this.items
      .filter(
        (item) =>
          (!filterToFulfillmentConfigurable || item.fulfillmentConfigurable) &&
          (!filterToModified || itemHasChanged(item, this.savedState))
      )
      .map((item) => {
        const result = pick(item, ['chargingModel.cap', 'code', 'selected']);
        // Non-configurable Unlimited caps shouldn't be passed on the FN message
        if (
          !item.fulfillmentConfigurable &&
          result.chargingModel &&
          result.chargingModel.cap === FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED
        ) {
          delete result.chargingModel;
        }
        if (item.requestorExternalInfo) {
          result.requestorExternalInfo = item.requestorExternalInfo
            .filter((reiItem) => reiItem.required || !!reiItem.value)
            .map((reiItem) => pick(reiItem, ['key', 'value']));
        }
        result.featureSets =
          (item &&
            item.featureSets &&
            item.featureSets.map((featureSet) => pick(featureSet, ['id', 'label', 'selected']))) ||
          [];

        return result;
      });

    function itemHasChanged(item, savedState) {
      const savedItem = savedState.find((i) => i.code === item.code);
      return !isEqual(item, savedItem);
    }
  }
}

/** Private Methods **/

function extractQuotaChargingModelCap(fulfillableItem) {
  const chargingModelCap = fulfillableItem?.chargingModel?.cap;
  if (
    chargingModelCap !== undefined &&
    chargingModelCap !== FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED
  ) {
    return toNumber(chargingModelCap);
  }
  return undefined;
}

/**
 * @description Helper to obtain a target fulfillable item.
 *
 * @param {String} fulfillableItemCode - Code of the fulfillable item to return.
 * @param {FulfillableItem[]} items - Array of fulfillable items to search through.
 *
 * @returns {FulfillableItem} Fulfillable item that matches the provided code.
 * @throws {Error} - Throws an error when no matching fulfillable item is found.
 */
function getTargetFulfillableItem(fulfillableItemCode, items) {
  const targetFulfillableItem = items.find((item) => item.code === fulfillableItemCode);
  if (targetFulfillableItem) {
    return targetFulfillableItem;
  }
  throw new Error(`Unable to find a fulfillable item with code ${fulfillableItemCode}`);
}

/**
 * @description Initializes Fulfillable Items
 *
 * @param {FulfillableItem[]} items Fulfillable Items to be processed
 *
 * @returns {FulfillableItem[]} the processed items
 */
function processItems(items) {
  let processedItems = items;
  // JIRA ONESIE-40810
  // We have certain FIs which we want to suppress until Nov 2021
  // Note, this will not become default behaviour, instead it will be removed
  if (feature.isEnabled('temp_hide_max_fis')) {
    processedItems = processedItems.filter(
      (item) => !['create_pdf_standalone', 'export_pdf', 'stock_limited'].includes(item.code)
    );
  }
  return processedItems;
}

export default FulfillableItemList;
/* eslint-enable max-lines -- Enable max-lines */
