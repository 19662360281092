(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.omni-tool.features-panel
   * @description module that defines the omni tool features-panel widget
   */
  angular.module('binky.widgets.omni-tool.features-panel', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.common.popover-button',
    'binky.widgets.common.search',
    'binky.widgets.common.toggle',
    'binky.widgets.omni-tool.panel-manager',
  ]);
})();
