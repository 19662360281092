/**
 * Class used by a CacheItem to track when data has become stale.
 */
class CacheTimer {
  static CACHE_LIFETIME = 15 * 60 * 1000; // 15 minutes

  /**
   *
   * @param {Number} lifespan Data lifespan. Setting this to null will make `isStale`
   *                          always report false.
   */
  constructor(lifespan) {
    this.lifespan = lifespan;
  }

  /**
   * Reports the staleness based on the lifespan, startTime and the difference
   * between them.
   *
   * No lifespan will always report false. If no startTime is provided we
   * consider it stale.
   *
   * @return {boolean} True if stale, false otherwise
   */
  isStale() {
    // When we don't have a lifespan it means
    // the data should live forever
    if (!this.lifespan) {
      return false;
    }

    return !this.startTime || Date.now() - this.startTime > this.lifespan;
  }

  /**
   * Reset the timer. It will report stale data until the next call to start()
   */
  reset() {
    this.startTime = null;
  }

  /**
   * Starts the clock, tick tock tick tock...
   * The provided duration becomes the lifespan.
   */
  start() {
    this.startTime = Date.now();
  }
}

export default CacheTimer;
