(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.shell.navigation.state-manager')
    .provider('stateManager', getStateManagerProvider);

  /* @ngInject */
  function getStateManagerProvider($stateProvider, $stateRegistryProvider, _) {
    const futureStates = {};

    this.$get = $get;
    this.configure = configure;

    /* @ngInject */
    function $get($stateRegistry, $trace, feature) {
      const service = {
        enableTracing,
        isStateDisabled,
        isStateEnabled,
      };

      return service;

      ////////////

      /**
       * @description Method to turn on ui-router's state transition tracing
       *   feature in the application. This will enable detailed logs of state
       *   changes to be sent to the browser's console at runtime.
       */
      function enableTracing() {
        $trace.enable();
      }

      /**
       * @description Method to determine whether a state is disabled or not. A
       *   state is disabled if access is denied by a feature flag set on the
       *   state, or if the state has not been registered at all.
       * @param {String} stateName - name of state to see if disabled
       * @param {Object} [stateJsonObj] - navigation state to see if disabled (optional;
       *   if present, will check feature flags of state declaration directly)
       * @returns {Boolean} true if state is disabled, else false
       */
      function isStateDisabled(stateName, stateJsonObj) {
        return !service.isStateEnabled(stateName, stateJsonObj);
      }

      /**
       * @description Method to determine whether a state is enabled or not. A
       *   state is enabled if access is allowed by a feature flag set on the
       *   state, or if the state has been registered without feature flagging.
       * @param {String} stateName - name of state to see if enabled
       * @param {Object} [stateJsonObj] - navigation state to see if enabled (optional;
       *   if present, will check feature flags of state declaration directly)
       * @returns {Boolean} true if state is enabled, else false
       */
      function isStateEnabled(stateName, stateJsonObj) {
        // check feature flags defined on state object
        if (stateJsonObj) {
          if (_.has(stateJsonObj, 'feature')) {
            return _.get(stateJsonObj, 'feature.enabled')
              ? feature.isEnabled(_.get(stateJsonObj, 'feature.name'))
              : feature.isDisabled(_.get(stateJsonObj, 'feature.name'));
          }
        }

        // check to see if state is a future state
        if (_.has(futureStates, stateName)) {
          return !!getFirstEnabledState(feature, stateName);
        }

        // if not future state, return value based on state registry
        return !!$stateRegistry.get(stateName);
      }
    }

    /**
     * @description Method to configure new states from navigation declarations.
     *   If the new state is feature flagged, we will insert a future state - effectively
     *   delaying the actual final state from being created until runtime (when
     *   we can safely access feature flags service). Otherwise, if state is not
     *   gated by feature flags, we add it directly to the state registry.
     * @param {Object} navItemJsonStateObj - navigation declaration state attribute/Object
     */
    function configure(navItemJsonStateObj) {
      // if state has feature flag, we'll need to create a placeholder future state
      if (_.has(navItemJsonStateObj, 'feature')) {
        // provide a unique id to more easily identify later on
        navItemJsonStateObj.id = _.uniqueId();
        // ensure we only create one future state
        if (_.has(futureStates, navItemJsonStateObj.name)) {
          // store any other competing state declarations to check which to use at runtime
          futureStates[navItemJsonStateObj.name].push(navItemJsonStateObj);
        } else {
          futureStates[navItemJsonStateObj.name] = [navItemJsonStateObj];
          $stateProvider.state(createFutureState(navItemJsonStateObj));
        }
      } else if (
        !$stateRegistryProvider.get(navItemJsonStateObj.name) &&
        !_.some($stateRegistryProvider.stateQueue.queue, ['self.name', navItemJsonStateObj.name])
      ) {
        // only add state if not already added (e.g. - breadcrumb declaration)
        // need to also check registry queue, in case parent state has not been
        // registered yet (child states queue until parent is added)
        $stateProvider.state(createStateDeclaration(navItemJsonStateObj));
      }
    }

    ////////

    /**
     * @description Method to create a future state from a navigation state
     *   declaration Object. A future state is a slimmed-down StateDeclaration
     *   Object that simply contains a name, url, and lazyLoad function as
     *   attributes. The name must end in a dot, followed by two wildcard chars
     *   (asterisks; **). While the lazyLoad function is typically used to import
     *   additional data, we currently load all assets, so we only need to use
     *   this method to instantiate the actual state that should exist. Since
     *   the lazyLoad method gets called in Angular's run phase, we can safely
     *   access feature flags without having to worry about them being uninitialized.
     * @param {Object} navItemJsonStateObj - navigation declaration's state attribute
     *   value
     * @returns {Object} future state declaration
     */
    function createFutureState(navItemJsonStateObj) {
      return {
        lazyLoad(transition) {
          const $q = transition.injector().get('$q');
          const featureReady = transition.injector().get('featureReady');

          const loadDeferred = $q.defer();

          $q.all([featureReady.whenRunReady()])
            .then(() => {
              const feature = transition.injector().get('feature');
              const finalState = getFirstEnabledState(feature, navItemJsonStateObj.name);
              if (finalState) {
                $stateProvider.state(createStateDeclaration(finalState));
              } else {
                $stateProvider.state(
                  navItemJsonStateObj.name,
                  _.assign(
                    {
                      redirectTo: 'not-found',
                    },
                    // only assign url value if original state also has a url
                    _.pick(navItemJsonStateObj.definition, 'url')
                  )
                );
              }

              destroyUnusedFutureStates(navItemJsonStateObj.name, finalState);
              loadDeferred.resolve();
            })
            .catch(loadDeferred.reject);

          return loadDeferred.promise;
        },
        name: `${navItemJsonStateObj.name}.**`,
        url: navItemJsonStateObj.definition.url,
      };
    }

    /**
     * @description Constructs Object that conforms to StateDeclaration
     *   interface (https://ui-router.github.io/ng1/docs/latest/interfaces/state.statedeclaration.html)
     *   from navItemJsonObj state data. The initial site navigation implementation
     *   had navigation-related data in the state attribute, so instead
     *   of duplicating data, we leave the 'name' attribute untouched and
     *   add it to the StateDeclaration definition in this method.
     * @param {Object} navItemJsonStateObj - JS Object representing a
     *   navigation item's state
     * @returns {Object} StateDeclaration Object
     */
    function createStateDeclaration(navItemJsonStateObj) {
      return _.assign({name: navItemJsonStateObj.name}, navItemJsonStateObj.definition);
    }

    /**
     * @description Method to delete any unused future states, deleting any transition
     *   hooks attached to it along the way. This allows greater flexibility and
     *   reduces the amount of repeated code required to create bifurcated states
     *   (states with same name, separated by feature flag) in the application (i.e. -
     *   developers only need to be concerned with access control mechanisms for
     *   the state subtree that they're working in, not with every other state
     *   subtree to ensure mutually exclusive checks aren't put in place).
     *
     *   Since the ui-router state system replaces references to future states
     *   with a single, actual state, this method is particularly useful once
     *   ui-router has run a future state's lazyLoad method and an actual state
     *   has been added to the state registry.
     * @param {String} stateName - name of state for which to clean up future states
     * @param {Object} usedState - JS Object representing actual state instantiated
     *   (destroys all other potential future states)
     */
    function destroyUnusedFutureStates(stateName, usedState) {
      if (usedState) {
        const unusedStates = _.reject(futureStates[stateName], {id: usedState.id});
        deregisterTransitionHooks(unusedStates);
        futureStates[stateName] = _.filter(futureStates[stateName], {id: usedState.id});
      } else {
        deregisterTransitionHooks(futureStates[stateName]);
        futureStates[stateName] = [];
      }

      ////////

      function deregisterTransitionHooks(unusedStates) {
        // remove stored transition hooks by calling their stored return methods...
        _.forEach(unusedStates, (unusedState) => {
          _.forEach(unusedState.transitionHooks, (deregisterTransitionHook) =>
            deregisterTransitionHook()
          );
        });
      }
    }

    /**
     * @description Method to retrieve the first enabled state for a given state
     *   name from list of potential future states.
     * @param {Object} featureRef - since this method can be used from a service
     *   context or within a state transition, it needs a reference to the feature
     *   service to be passed along (here) to universally access this data
     * @param {String} stateName - name of state to retrieve
     * @returns {Object|undefined} returns first enabled state (if found), otherwise
     *   if no states are enabled, returns undefined
     */
    function getFirstEnabledState(featureRef, stateName) {
      const enabledState = _.find(futureStates[stateName], (potentialState) =>
        isStateFeatureFlagged(
          featureRef,
          potentialState.feature.name,
          potentialState.feature.enabled
        )
      );
      return enabledState;
    }

    /**
     * @description Method to determine if a given state is enabled or not.
     * @param {Object} featureRef - since this method can be used from a service
     *   context or within a state transition, it needs a reference to the feature
     *   service to be passed along (here) to universally access this data
     * @param {String} stateName - name of state to check
     * @param {Boolean} featureEnabled - whether or not the feature is enabled
     *   (according to state declaration); true if enabled, else false
     * @returns {Boolean} true if state is enabled, else false
     */
    function isStateFeatureFlagged(featureRef, stateName, featureEnabled) {
      return featureRef.isEnabled(stateName) === featureEnabled;
    }
  }
})();
