/* eslint-disable eslint-comments/disable-enable-pair -- one function over limit */
/* eslint-disable max-lines -- one function over limit */
import axios from 'axios';
import omitBy from 'lodash/omitBy';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL license groups APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL License Groups api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description uploads avatar for a user
 *
 * @param {String} orgId - The org id
 * @param {String} userId - The user id
 * @param {Object} formdata - Formdata from image upload
 *
 */
const postAvatar = async ({orgId, userId, formdata}) => {
  const response = await axios.post(`${url}/${orgId}/users/${userId}/avatar`, formdata, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
  });
  return response;
};

/**
 * @description Creates and/or adds users to an organization
 *
 * @param {String} orgId - The org id
 * @param {Array<User>} usersArray - Users to be added
 * @param {Object} [params] - POST params
 * @param {String} [params.switchingUserType] - To be included if the operation is part of the switch id type workflow
 */
const batchAddUsers = async ({orgId, usersArray, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/users%3Abatch`, usersArray, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

/**
 * @description Returns the users for a product owned by an organization filtered by the provisioning status of the users license.
 *
 * @param {String} orgId - The org id
 * @param {Object} params - Operations to perf
 * @param {String} [params.provisioning_status] - The delegation statuses the licenses should have
 * @param {String} [params.delegation_status] - The delegation status the licenses should have.
 * @param {String} [params.no_access_code] - The no access code the delegated licenses should have. Can be IDENTITY_TYPE
 * @param {Number} [params.page] - The page of results to return. Begins at 0.
 * @param {String} [params.page_size] - The maximum number of items to return in a single page
 *
 * @returns {Promise<Object>} Axios response
 */
const queryDelegationsUsers = ({orgId, ...params}) =>
  axios.get(`${url}/${orgId}/users%3Asearch.delegations`, {
    headers: {
      Accept: 'text/csv+user-all,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });

/**
 * @description Applies operations to users
 *
 * @param {String} orgId - The org id
 * @param {Array} operations - operations to perform
 */
const patchUsers = async ({orgId, operations}) => {
  const response = await axios.patch(`${url}/${orgId}/users`, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Uploads CSV to add users
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - upload params
 * @param {String} [params.productId] - The ID of the product
 * @param {String} [params.licenseGroupId] - The id of the license group to which the users will be added to
 * @param {String} [params.userGroupId] - The id of the user group to which the users will be added to
 */
const bulkAddUsers = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
    params: {
      type: 'add-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Uploads CSV to edit users
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - Upload params
 * @param {String} [params.productId] - The ID of the product
 * @param {String} [params.licenseGroupId] - The id of the license group to which the users will be edited
 * @param {String} [params.userGroupId] - The id of the user group to which the users will be edited
 */
const bulkEditUsers = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
    params: {
      type: 'edit-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Uploads CSV to operate offer switch migration
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - Upload params
 * @param {String} [params.productId] - The ID of the product
 * @param {String} [params.licenseGroupId] - The id of the license group to which the users will be edited
 * @param {String} [params.userGroupId] - The id of the user group to which the users will be edited
 */
const bulkOfferSwitchMigration = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
    params: {
      type: 'offer-switch-migration',
      ...params,
    },
  });
  return response;
};

/**
 * @description Uploads CSV to remove users
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - Upload params
 * @param {String} [params.productId] - The ID of the product
 * @param {String} [params.licenseGroupId] - The id of the license group to which the users will be removed from
 * @param {String} [params.userGroupId] - The id of the user group to which the users will be removed from
 */
const bulkRemoveUsers = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
    params: {
      type: 'remove-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Uploads CSV to switch user type
 *
 * @param {String} orgId - The org id
 * @param {Object} data - CSV file as FormData object
 * @param {Object} params - Upload params
 * @param {String} params.orgId - The org ID
 * @param {String} [params.productId] - The ID of the product
 * @param {String} [params.licenseGroupId] - The id of the license group to which the users will be edited
 * @param {String} [params.userGroupId] - The id of the user group to which the users will be edited
 */
const bulkSwitchIdentityType = async ({orgId, data, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
    params: {
      type: 'switch-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Kicks of export users csv async job
 *
 * @param {String} orgId - The org id
 */
const exportUsersAsync = async ({orgId}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, null, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary="placeHolderBoundary"',
      ...getHeaders({clientId, includeRoles}),
    },
    params: {
      type: 'export-users-csv',
    },
  });
  return response;
};

/**
 * @description Gets CSV to switch user type
 *
 * @param {String} orgId - The org id
 * @param {Object} [params] - GET params
 */
const exportIdentityType = async ({orgId, params}) => {
  const response = await axios.get(`${url}/${orgId}/users`, {
    headers: {
      Accept: 'text/csv+user-identity-type,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV for license status report
 *
 * @param {String} orgId - The org id
 * @param {Object} [params] - GET params
 */
const exportLicenseStatusReport = async ({orgId, params}) => {
  const response = await axios.get(`${url}/${orgId}/users`, {
    headers: {
      Accept: 'text/csv+license-status-report,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV of an organization's users
 *
 * @param {String} orgId - The org id
 * @param {Object} [params] - GET params
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportUsers = async ({orgId, params}) => {
  const response = await axios.get(`${url}/${orgId}/users`, {
    headers: {
      Accept: 'text/csv+user-all,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });
  return csvBlobTransformer(response);
};

/**
 * @description Get Global Admin roles
 * @param {String} orgId - The org id
 * @returns {Promise} A promise that resolves to GA roles
 */
const getGlobalAdminRoles = ({orgId}) =>
  axios.get(`${url}/${orgId}/users:global-admin-roles`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Gets a user's group memberships
 *
 * @param {String} orgId - The org id
 * @param {String} userId - The user id
 *
 * @returns {Promise<Object>} Axios JIL GET users group-memberships response
 */
const getGroupMemberships = ({orgId, userId}) =>
  axios.get(`${url}/${orgId}/users/${userId}/group-memberships`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Creates a user
 *
 * @param {String} orgId - The org id
 * @param {User} userModel - The user to create
 */
const postUsers = async ({orgId, userModel}) => {
  const response = await axios.post(`${url}/${orgId}/users`, userModel, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Fetches user data for an org, or a specific user
 *
 * @param {String} orgId - The org id
 * @param {String} [filterExcludeDomain] - Domain to exclude from the results
 * @param {String} [filterIncludeDomain] - Domain to include in the results
 * @param {Boolean} [includeLicenseGroups] - Determine if licenseGroups are included
 * @param {String} [userId] - The user id
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @param {ORGANIZATION_USER_API_QUERY_PARAMS} [queryParams.include] - Optional, determine if products, user group products, or user creation source
 * are included (separated by commas) Possible values are PRODUCTS, USER_GROUP_PRODUCTS, USER_CREATION_SOURCE, USER_EDU_ROLE_TAGS
 */
const getUsers = async ({
  filterExcludeDomain,
  filterIncludeDomain,
  orgId,
  includeLicenseGroups,
  userId,
  ...queryParams
}) => {
  const params = omitBy(
    {
      fetchProductLicenseGroups: includeLicenseGroups,
      filter_exclude_domain: filterExcludeDomain,
      filter_include_domain: filterIncludeDomain,
      ...queryParams,
    },
    (value) => value === undefined
  );
  const response = await axios.get(`${url}/${orgId}/users/${userId || ''}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

/**
 * @description Fetches user head data for an org
 *
 * @param {String} [filterExcludeDomain] - Domain to exclude from the results
 * @param {String} [filterIncludeDomain] - Domain to include in the results
 * @param {String} orgId - The organization id
 */
const headUsers = async ({filterExcludeDomain, filterIncludeDomain, orgId}) => {
  const params = omitBy(
    {
      filter_exclude_domain: filterExcludeDomain,
      filter_include_domain: filterIncludeDomain,
    },
    (value) => value === undefined
  );
  const response = await axios.head(`${url}/${orgId}/users`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

/**
 * @description Fetches data for multiple users
 *
 * @param {String} orgId - The org id
 * @param {Object} params - Query params
 * @param {String} params.orgId - The org id
 * @param {Number} [params.page] - Page number of cached model to fetch
 * @param {Number} [params.page_size] - Number of items to display per page
 * @param {String} [params.search_query] - Search query
 * @param {String} [params.productConfigurations] - Whether to include product configurations
 * @param {String} [params.sort] - Sorting criteria (e.g. - name)
 * @param {String} [params.sort_order] - Sorting order (e.g. - ASC or DESC)
 *
 * @returns {Promise<Object>} Axios JIL GET users response
 */
const queryUsers = ({orgId, params}) =>
  axios.get(`${url}/${orgId}/users`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

const jilUsers = {
  batchAddUsers,
  bulkAddUsers,
  bulkEditUsers,
  bulkOfferSwitchMigration,
  bulkRemoveUsers,
  bulkSwitchIdentityType,
  configure,
  exportIdentityType,
  exportLicenseStatusReport,
  exportUsers,
  exportUsersAsync,
  getGlobalAdminRoles,
  getGroupMemberships,
  getUsers,
  headUsers,
  patchUsers,
  postAvatar,
  postUsers,
  queryDelegationsUsers,
  queryUsers,
};

export default jilUsers;
