import {defineContents} from '@pandora/react-content-provider';

import pandoraContentModel from '../../../core/services/pandoraContentModel';

let contentModel, translatedMessages;

/**
 * @description Puts additional pandora content models into the app root level content model.
 *  Added content models will override any existing content models with the same key.
 *
 * @param {Object} additionalContent - Additional pandora content models to add to the binky root content model.
 */
function addPandoraContentModel(additionalContent) {
  contentModel = {...contentModel, ...additionalContent};
}

/**
 * @description Creates the app's root level pandora content model.
 *
 *  As Pandora components with content models are added to this repo,
 *  the function below needs to be updated to include those content
 *  models to establish a mapping between the string files and the
 *  component's content model it relies on to fetch the translated strings.
 *
 * @param {String} contentModelId - The ID for the content model
 *
 * @returns {ContentModelInterface} the root content model that wraps all Pandora components included in package.json to set the default strings.
 */
function getRootPandoraContentModel(contentModelId = 'BinkyContentModel') {
  return defineContents(contentModelId, {
    ...pandoraContentModel,
    ...contentModel,
  });
}

/**
 * @description Fetches the root pandora content value which
 *  contains the default strings for Pandora components.
 *  Called at the ReactContainerBridge.
 *
 * @param {String[]} messages - an array of string keys in dot notation
 *  that maps to the translated strings.
 *    Example of messages: en.json
 *    {
 *      "pandora.drawerContent.dismissButton": "Close",
 *      "pandora.pageNavContent.next": "Next",
 *      "pandora.pageNavContent.nextPage": "Next page",
 *     }
 *
 * @returns {Object} a JSON formatted object that is used as input for the Pandora
 *  content provider's value prop.
 *    For example:
 *    {
 *      drawerContent: {
 *        dismissButton: "Close",
 *      },
 *      pageNavContent: {
 *        next: "Next",
 *        nextPage: "Next page",
 *      }
 *    }
 */
function getRootPandoraContentValue(messages) {
  // Set global var so messages are accessible to getPandoraContentValue.
  translatedMessages = messages;
  // Fetch pandora strings to reduce message keys we iterate through
  const pandoraKeys = Object.keys(messages).filter(
    (translationKey) => translationKey.split('.')[0] === 'pandora'
  );

  return getPandoraContentValue(pandoraKeys);
}

/**
 * @description Fetches the pandora content value for
 *  the given scope of translated string keys.
 *
 * @param {String | String[]} keyPrefixes - a single prefix
 *    of the string key to fetch the content for or an array
 *    of prefixes.
 *    For example: If the en.json file contains:
 *      "pandora.userTableSectionContent.tableSectionContent.tableContent.ariaLabel": "User table",
 *      "pandora.userTableSectionContent.tableSectionContent.tableFiltersContent.label": "Search for users",
 *      "pandora.userTableSectionContent.addUserButton": "Add users",
 *
 *    And content is needed for the Pandora user table section, we would call:
 *      getSpecifiedPandoraContentValue("pandora.userTableSectionContent")
 *
 *    Which would return:
 *      {
 *        userTableSectionContent: {
 *          addUserButton: "Add users",
 *          tableContent: {
 *            ariaLabel:  "User table"
 *          },
 *          tableFiltersContent: {
 *            label: "Search for users"
 *          }
 *        }
 *      }
 *
 *    An example where multiple keyPrefixes are needed:
 *    For example: If the en.json file contains:
 *      "pandora.drawerContent.dismissButton": "Close",
 *      "pandora.pageNavContent.next": "Next",
 *      "pandora.pageNavContent.nextPage": "Next page",
 *      "pandora.pageSizeSelectorContent.itemsPerPage": "Items per page",
 *
 *    We can call:
 *    getSpecifiedPandoraContentValue(["pandora.drawerContent", "pandora.pageNavContent"]).
 *
 *    Which would return:
 *      {
 *        drawerContent: {
 *          dismissButton: "Close",
 *        },
 *        pageNavContent: {
 *          next: "Next",
 *          nextPage: "Next page",
 *        }
 *      }
 * @returns {Object} a JSON formatted object containing the strings
 *  mapped from the keys that begin with the keyPrefixes provided.
 *  Used as input for the Pandora content provider's value prop.
 */
function getSpecifiedPandoraContentValue(keyPrefixes) {
  let keyPrefixesArray = keyPrefixes;
  if (!Array.isArray(keyPrefixes)) {
    keyPrefixesArray = [keyPrefixes];
  }
  const pandoraKeys = Object.keys(translatedMessages).filter((translationKey) =>
    // Check if any of the keyPrefixes relates to the current translationKey
    keyPrefixesArray.some((keyPrefix) => translationKey.startsWith(keyPrefix))
  );

  return getPandoraContentValue(pandoraKeys);
}

/* Private functions */
/**
 * @description Fetches the pandora content value for the given string keys.
 *
 * @param {String[]} keys - an array of translation keys to fetch the content for. In dot-notation.
 * @returns {Object} a JSON formatted object containing the strings mapped from the provided keys.
 */
function getPandoraContentValue(keys) {
  //  Will be pandoraContentObject = {pandora: {...}}
  const pandoraContentObject = keys
    // Dot-notation to JSON object
    .reduce((obj, key) => {
      const objCopy = {...obj};
      const currPath = key.split('.');
      const lastPartOfPath = currPath.pop();
      let objPointer = objCopy;

      let currentPartOfPath = currPath.shift();
      while (currentPartOfPath) {
        // Looking ahead one to check if the current pointer needs to point to an array of content.
        if (!currPath || Number.isNaN(Number.parseInt(currPath[0], 10))) {
          if (!objPointer[currentPartOfPath]) {
            // Construct new nested object, since one needed does not exist yet
            objPointer[currentPartOfPath] = {};
          }
          objPointer = objPointer[currentPartOfPath];
        } else {
          // Located a nested array in the content model
          const index = Number.parseInt(currPath[0], 10);
          if (!objPointer[currentPartOfPath]) {
            // Construct new nested array, since one needed does not exist yet
            objPointer[currentPartOfPath] = [];
          }
          if (index + 1 > objPointer[currentPartOfPath].length) {
            // Only create a new content model object if this is the first instance of the index
            objPointer[currentPartOfPath].push({});
          }
          // Point to new content model object we added to the array
          objPointer = objPointer[currentPartOfPath][index];
          // Skip over the index, do not include it in nested content object
          currentPartOfPath = currPath.shift();
        }
        currentPartOfPath = currPath.shift();
      }
      // Set translated string to new pandora content entry object
      objPointer[lastPartOfPath] = translatedMessages[key];
      return objCopy;
    }, {});
  // Remove pandora wrapper around content object
  return pandoraContentObject.pandora;
}

export {
  addPandoraContentModel,
  getSpecifiedPandoraContentValue,
  getRootPandoraContentModel,
  getRootPandoraContentValue,
};
