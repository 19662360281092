import {error, info, success, warning} from '@react/react-spectrum/Toast';

import {translateString} from 'core/services/locales';

const TOAST_TIME_TO_LIVE = 5000;

/**
 * Pop a toast in the lower-left hand corner of the window.
 *
 * @param showToast - one of error, info, success of warning
 * @param msg - the string to display
 * @param options - see Spectrum v2 Toast docs.
 *   A timeout of 0 means the toast persists until explicitly closed.
 *
 * Note on accessibility:
 *  A key question is whether the user experience is harmed if a toast messages is removed.
 *  If yes, then the toast needs to stay visible until dismissed.
 */
function show(showToast, msg, options) {
  showToast(msg, {
    // Make all toasts live inside a special container so they are isolated
    // from the angular app-shell to ensure they are above everything
    container: document.querySelector('.react-toast-container'),
    // For accessibility, error and warning toasts must be explicitly dismissed by the admin.
    timeout: showToast === error || showToast === warning ? 0 : TOAST_TIME_TO_LIVE,
    ...options,
  });
}

/**
 * To pop an error toast in the lower left corner of the window.
 * For accessibility, error toasts do not and should not time out.
 *
 * @param {String} [msg] - string to display in the toast.
 *   Defaults to 'Your action can’t be completed. Please try again shortly.'.
 * @param {Object} [options] - see Spectrum v2 Toast documentation.
 */
function showError(msg, options) {
  show(error, msg || translateString({id: 'binky.shell.toasts.defaults.error'}), options);
}

/**
 * To pop an info toast in the lower left corner of the window.
 * The default timeout is TOAST_TIME_TO_LIVE.
 *
 * @param {String} msg - string to display in the toast.
 * @param {Object} [options] - see Spectrum v2 Toast documentation.
 */
function showInfo(msg, options) {
  show(info, msg, options);
}
/**
 * To pop a success toast in the lower left corner of the window.
 * The default timeout is TOAST_TIME_TO_LIVE.
 *
 * @param {String} [msg] - string to display in the toast.
 *   Defaults to 'Success! Your action was completed.'
 * @param {Object} [options] - see Spectrum v2 Toast documentation.
 */
function showSuccess(msg, options) {
  show(success, msg || translateString({id: 'binky.shell.toasts.defaults.success'}), options);
}

/**
 * To pop a warning toast in the lower left corner of the window.
 * By default warning toasts do not time out.
 * This may be overridden only if there is some other way in the UI to discover the warning.
 *
 * @param {String} msg - string to display in the toast.
 * @param {Object} [options] - see Spectrum v2 Toast documentation.
 */
function showWarning(msg, options) {
  show(warning, msg, options);
}

export {showError, showInfo, showSuccess, showWarning};
