/**
 * @description Helper to generate a JSON patch (RFC6902) document given the difference between two objects.
 *
 * @param {Object} original - the original object
 * @param {Object} modified - the modified object
 * @param {Object} [options]
 * @param {String} [options.basePath] - the base path for the patches
 * @param {getResourceIdCallback} [options.getResourceId] - a function that gets the resource id for use in the patch path
 *
 * @returns {Array<Object>} the JSON patch (RFC6902) document (array of modification operations) that describe how modified differs from original
 */
function generatePatches(original, modified, options = {}) {
  const {basePath = '', getResourceId = getDefaultResourceId} = options;

  const addOperations = [];
  iterateObject(
    modified,
    (value, key) => {
      // don't iterate deeper if we will replace this key in the future
      if (keyHasChanged(original, key, value, getResourceId)) return false;

      if (!has(original, key, getResourceId)) {
        addOperations.push(constructAddOperation(key, value, basePath));
        return false;
      }
      return true;
    },
    getResourceId
  );

  const removeOperations = [];
  iterateObject(
    original,
    (value, key) => {
      // don't iterate deeper if we will replace this key in the future
      if (keyHasChanged(modified, key, value, getResourceId)) return false;

      if (!has(modified, key, getResourceId)) {
        removeOperations.push(constructRemoveOperation(key, basePath));
        return false;
      }
      return true;
    },
    getResourceId
  );

  const replaceOperations = [];
  iterateObject(
    modified,
    (value, key) => {
      if (keyHasChanged(original, key, value, getResourceId)) {
        replaceOperations.push(constructReplaceOperation(key, value, basePath));
        return false;
      }
      return true;
    },
    getResourceId
  );

  return [...addOperations, ...removeOperations, ...replaceOperations];
}

/**
 * @description The default {getResourceIdCallback} callback.
 *
 * @param {Any} value - the value of the item to get the id of
 * @param {String} key - the key of the item in the object
 *
 * @returns the index of the resource id in the array
 */
function getDefaultResourceId(resource, index) {
  return String(index);
}

/**
 * @description Iterates over an object's keys, running a callback on each key with the key and value.
 *
 * @param {Object} object - the object to iterate over
 * @param {iterateObjectCallback} callbackFn - the callback to call for each key
 * @param {getResourceIdCallback} getResourceId - a callback that gets the resource id for use in the patch path
 * @param {Array<String>} currentKey - the current key of iteration
 */
function iterateObject(object, callbackFn, getResourceId, currentKey = []) {
  Object.entries(object).forEach(([key, value]) => {
    const actualKey = Array.isArray(object) ? getResourceId(value, key) : key;
    const fullKey = [...currentKey, actualKey];
    const goDeeper = callbackFn(value, fullKey);
    if (goDeeper && typeof value === 'object') {
      iterateObject(value, callbackFn, getResourceId, fullKey);
    }
  });
}

/**
 * @description Determines whether an item at a key differs from a value.
 *
 * @param {Object} original - the object containing the potentially differing item at a key
 * @param {Array<String>} key - the key of the potentially differing item in original
 * @param {Any} value - the value to compare against
 * @param {getResourceIdCallback} getResourceId - a callback that gets the resource id for use in the patch path
 *
 * @returns {Boolean} whether the item at the specified key has changed
 */
function keyHasChanged(original, key, value, getResourceId) {
  return (
    has(original, key, getResourceId) && !surfaceEqual(value, get(original, key, getResourceId))
  );
}

function surfaceEqual(a, b) {
  if (typeof a !== 'object' || typeof b !== 'object') {
    return a === b;
  }
  return Array.isArray(a) === Array.isArray(b);
}

/**
 * @description Constructs an add patch operation.
 *
 * @param {Array<String>} key - the path of add operation
 * @param {Any} value - the value of the addition
 * @param {String} basePath - the base path to prepend
 *
 * @returns {Object} the add patch operation
 */
function constructAddOperation(key, value, basePath) {
  return {
    op: 'add',
    path: getPatchPath(key, basePath),
    value,
  };
}

/**
 * @description Constructs a remove patch operation.
 *
 * @param {Array<String>} key - the path of remove operation
 * @param {Any} value - the value of the removal
 * @param {String} basePath - the base path to prepend
 *
 * @returns {Object} the remove patch operation
 */
function constructRemoveOperation(key, basePath) {
  return {
    op: 'remove',
    path: getPatchPath(key, basePath),
  };
}

/**
 * @description Constructs a replace patch operation.
 *
 * @param {Array<String>} key - the path of replace operation
 * @param {Any} value - the value of the replacement
 * @param {String} basePath - the base path to prepend
 *
 * @returns {Object} the replace patch operation
 */
function constructReplaceOperation(key, value, basePath) {
  return {
    op: 'replace',
    path: getPatchPath(key, basePath),
    value,
  };
}

/**
 * @description Gets the patch path given the key and base path
 *
 * @param {Array<String>} keys - the key into the object
 * @param {String} basePath - the base path to prepend
 *
 * @returns {String} the patch path formatted to RFC6902
 */
function getPatchPath(keys, basePath) {
  let resourcePath = `${basePath}/${keys.reduce((path, key) => `${path}/${key}`)}`;
  // remove any empty segments
  resourcePath = resourcePath.replaceAll('//', '/');
  // never have trailing slashes
  if (resourcePath.endsWith('/')) resourcePath = resourcePath.slice(0, -1);
  return resourcePath;
}

/**
 * @description Retrieves the item at a specified key in an object.
 * This is the same as lodash's get, however uses the custom keys as implemented in getResourceId.
 *
 * @param {Object} object - the object to inspect
 * @param {Array<String>} key - the key to look for
 * @param {getResourceIdCallback} getResourceId - a function that gets the resource id for use in the patch path
 *
 * @returns the item at key in object
 */
function get(object, key, getResourceId) {
  const {value} = getIfExists(object, key, getResourceId);
  return value;
}

/**
 * @description Checks whether the item at a specified key in an object exists.
 * This is the same as lodash's has, however uses the custom keys as implemented in getResourceId.
 *
 * @param {Object} object - the object to inspect
 * @param {Array<String>} key - the key to look for
 * @param {getResourceIdCallback} getResourceId - a function that gets the resource id for use in the patch path
 *
 * @returns true if the item at key exists, false otherwise
 */
function has(object, key, getResourceId) {
  const {found} = getIfExists(object, key, getResourceId);
  return found;
}

function getIfExists(object, keys, getResourceId) {
  const notFoundReturn = {found: false, value: null};
  let tempObject = object;
  for (let i = 0, length = keys.length; i < length; i++) {
    const key = keys[i];
    if (Array.isArray(tempObject)) {
      const index = tempObject
        .map((item, idx) => getResourceId(item, idx))
        .findIndex((k) => k === key);
      if (index === -1) return notFoundReturn;
      tempObject = tempObject[index];
    } else {
      if (!Object.prototype.hasOwnProperty.call(tempObject, key)) return notFoundReturn;
      tempObject = tempObject[key];
    }
  }
  return {found: true, value: tempObject};
}

export {generatePatches, get};
