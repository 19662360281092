(function () {
  /**
   * @deprecated Please use src2/core/models/organizationAdminList/OrganizationAdminList.js
   *
   */
  angular
    .module('binky.core.organizations.admin-list')
    .factory('OrganizationAdminList', getOrganizationAdminListModel);

  /* @ngInject */
  function getOrganizationAdminListModel(
    $log,
    $q,
    $rootScope,
    _,
    jilAdmins,
    jilUsers,
    MEMBER_EVENT,
    MemberList,
    modelCache,
    ORGANIZATION_ADMIN_LIST_CACHE_ID,
    ORGANIZATION_ADMIN_LIST_EVENT,
    OrganizationUser2,
    userRoleUtils
  ) {
    class OrganizationAdminList extends MemberList {
      /**
       * @class
       * @description Constructor for OrganizationAdminList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: ORGANIZATION_ADMIN_LIST_CACHE_ID,
              memberClassRef: OrganizationUser2,
              resource: jilAdmins.admins,
            },
            options
          )
        );
        this.orgId = options.orgId;
        this.modifiedUsers = [];
        this.role = options.role;
        this.roles = options.roles;
      }

      /**
       * @description Method for providing modified users, to be persisted on save
       * @param {Array} users - the users that have been modified
       */
      addModifiedUsers(users) {
        this.modifiedUsers = _.union(this.modifiedUsers, users);
      }

      /**
       * @description Method to determine if there are any unsaved changes to this
       *              list. Unsaved changes are either items pending addition or
       *              removal to/from this list since list creation or last save.
       * @returns {Boolean} true if there are unsaved changes, else false
       */
      hasUnsavedChanges() {
        return getModifiedUsers(this.modifiedUsers).length > 0 || super.hasUnsavedChanges();
      }

      key() {
        return super.key(getParams(this));
      }

      refresh() {
        return super.refresh(getParams(this)).then((userList) => {
          $rootScope.$emit(ORGANIZATION_ADMIN_LIST_EVENT.UPDATE);
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(ORGANIZATION_ADMIN_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
          }
          return userList;
        });
      }

      /**
       * @description Method to save changes to user list to back-end.
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;

        if (this.hasUnsavedChanges()) {
          // First we check if users are being added, and fail if so
          // This list does not yet support add in that way
          if (_.size(this.addedItems) > 0) {
            throw new Error('OrganizationAdminList.save cannot be invoked with added people');
          }
          let operations = [];
          _.forEach(getModifiedUsers(this.modifiedUsers), (modifiedUser) => {
            operations = _.union(operations, userRoleUtils.getAdminRolePatches(modifiedUser));
          });
          _.forEach(this.removedItems, (removedUser) => {
            // we empty the roles on remove, and have the same logic as edit tell us what to take away
            removedUser.roles = [];
            operations = _.union(operations, userRoleUtils.getAdminRolePatches(removedUser));
          });

          this.$resolved = false;

          jilUsers.users.batchOperation(
            _.omitBy({orgId: this.orgId}, _.isEmpty),
            operations,
            () => {
              _(this.removedItems)
                .union(this.modifiedUsers)
                .forEach((user) => {
                  $rootScope.$emit(MEMBER_EVENT.UPDATE, user.id);
                });
              this.resetRemovedItems();
              this.modifiedUsers = [];
              this.$resolved = true;

              this.filter.query = '';
              this.refresh()
                .then(() => {
                  deferred.resolve(this);
                })
                .catch(onError.bind(this));
            },
            onError.bind(this)
          );
        } else {
          // no changes to save
          deferred.resolve(this);
        }

        function onError(error) {
          $log.error('Failed to remove or modify selected users. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        return deferred.promise;
      }

      /**
       * @description Method to instantiate OrganizationAdminList for the current active organization
       * @param {Object} [options] - options
       * @property {Number} [options.orgId] - The id of the organization
       * @property {Number} [options.pageSize] - number of admins to display per page
       * @property {String} [options.role] - filtered role for admin. One of
       * ORG_ADMIN, DEPLOYMENT_ADMIN, PRODUCT_ADMIN, LICENSE_GROUP_ADMIN, STORAGE_ADMIN,
       * USER_GROUP_ADMIN
       * @property {Array} [options.roles] - filtered roles for admin.
       * @returns {OrganizationAdminList} orgList - new OrganizationAdminList
       */
      static get(options = {}) {
        const {orgId, pageSize, role, roles} = options;
        let model = new OrganizationAdminList({orgId, pageSize, role, roles});
        const cachedModel = modelCache.get(ORGANIZATION_ADMIN_LIST_CACHE_ID, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    // Private methods
    /**
     * @description filter the modifiedUsers list for those with role changes.
     *
     * @param {Array} users an array of OrganizationUser objects to inspect for changes
     * @returns {Array} users with changes
     */
    function getModifiedUsers(users) {
      // Temporarily disable for https://github.com/Gillespie59/eslint-plugin-angular/issues/449
      return _.filter(
        users,
        (user) =>
          !compareArrays(
            userRoleUtils.explodeAdminRoles(user.savedState.roles),
            userRoleUtils.explodeAdminRoles(user.roles),
            userRoleUtils.compareAdminRoles
          )
      );
    }

    /**
     * @description Fetch params used for this call.
     *
     * @param {Object} model the model to pick params from
     * @returns {Array} params to be passed to JIL or used as a key
     */
    function getParams(model) {
      return _.omitBy(
        {orgId: model.orgId, role: model.role, roles: _.join(model.roles, ',')},
        _.isEmpty
      );
    }

    /**
     * @description Compare two arrays.
     *
     * @param {Array} arr1 reference to the first array
     * @param {Array} arr2 reference to the array to compare to
     * @param {Function} isEqual the function to use to compare the two arrays
     * @returns {Boolean} true if the two items are equal
     */
    function compareArrays(arr1, arr2, isEqual) {
      return (
        _(arr1).differenceWith(arr2, isEqual).isEmpty() &&
        _(arr2).differenceWith(arr1, isEqual).isEmpty()
      );
    }

    // We register the cache size for this class

    MemberList.setupCache(ORGANIZATION_ADMIN_LIST_CACHE_ID, 5);

    return OrganizationAdminList;
  }
})();
