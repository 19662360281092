(function () {
  /**
   * @deprecated Please use src2/core/models/fulfillmentEvent/FulfillmentEvent.js
   *
   * @ngdoc factory
   * @name FulfillmentEvent
   * @description Model factory for fulfillment event objects.
   */
  angular
    .module('binky.core.fulfillment-events')
    .factory('FulfillmentEvent', getFulfillmentEventModel);

  /* @ngInject */
  function getFulfillmentEventModel(binkySrc2) {
    return binkySrc2.models.fulfillmentEvent.FulfillmentEvent;
  }
})();
