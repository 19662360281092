import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import {EVENT_NAME} from 'services/analytics/AnalyticsConstants';
import AnalyticsPageDescriptor from 'services/analytics/AnalyticsPageDescriptor';
import ErrorDescriptor from 'services/analytics/ErrorDescriptor';
import CartDescriptor from 'services/cart/CartDescriptor';
import feature from 'services/feature';
import PrimaryProductDescriptor from 'services/product/PrimaryProductDescriptor';
import {constructProductListDescriptor} from 'services/product/productListAnalyticsUtils';
import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CARD_ID,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from 'services/sophia/SophiaConstants';
import SophiaDescriptor from 'services/sophia/SophiaDescriptor';
import UiEventDescriptor from 'services/uiEvent/UiEventDescriptor';

import AnalyticsEvent from './AnalyticsEvent';
import CancellationOrderDescriptor from './CancellationOrderDescriptor';
import ChatDescriptor from './ChatDescriptor';
import GainsightEventDescriptor from './GainsightEventDescriptor';
import OrganizationInfoDescriptor from './OrganizationInfoDescriptor';

/**
 * @description Trigger state change analytics.
 * @param {Object} [options] Params this method
 * @param {Object} [options.cancellationOrder] Cancellation Order properties
 * @param {Object} [options.cart] cart information
 * @param {String} option.eventAction event action
 * @param {String} option.eventName event name
 * @param {string} option.interaction interaction string
 * @param {String} options.name Analytics state name
 * @param {Object} [options.productList] Product list information
 * @param {Array} [options.productList.items] List of products
 */
function dispatchPageAnalytics(options) {
  const {cancellationOrder, cart, eventAction, eventName, interaction, name, productList} = options;

  const analyticsData = {
    descriptors: {
      analyticsPage: new AnalyticsPageDescriptor({name}),
    },
  };

  if (cancellationOrder) {
    analyticsData.descriptors.cancellationOrder = new CancellationOrderDescriptor(
      cancellationOrder
    );
  }

  if (cart) {
    analyticsData.descriptors.cart = constructCartDescriptor(cart);
  }

  if (productList) {
    analyticsData.descriptors.productList = constructProductListDescriptor(productList);
  }

  if (eventAction && eventName) {
    analyticsData.descriptors.uiEvent = new UiEventDescriptor({
      eventAction,
      eventName,
      interaction,
    });
  }

  AnalyticsEvent.dispatch(analyticsData);
}

/**
 * @description Trigger UiEvent analytics.
 * @param {Object} options analytics information
 * @param {Object} [options.cancellationOrder] Cancellation Order properties
 * @param {Object} [options.cart] Cart information to construct an analytics event
 * @param {String} options.eventAction Analytics event action
 * @param {String} options.eventName Analytics event name
 * @param {Object} [option.gainsightEvent] gainsight event data
 * @param {Object} [options.interaction] Analytics interaction object (click, impression)
 * @param {Object} [options.productList] Product list information
 * @param {Array} [options.productList.items] List of products
 * @param {Object} [options.sophia] Sophia campaign information
 */
/* eslint-disable complexity -- 2 over mainly due to feature flag checks */
function dispatchUiEventAnalytics(options) {
  const {
    cancellationOrder,
    cart,
    error,
    eventAction,
    eventName,
    gainsightEvent,
    interaction,
    organizationInfo,
    productList,
    sophia,
    chat,
  } = options;

  const analyticsData = {
    descriptors: {
      uiEvent: new UiEventDescriptor({eventAction, eventName, interaction}),
    },
  };

  if (feature.isEnabled('temp_redirect_self_cancel_to_jarvis') && chat) {
    analyticsData.descriptors.chat = new ChatDescriptor(chat);
  }

  if (cancellationOrder) {
    analyticsData.descriptors.cancellationOrder = new CancellationOrderDescriptor(
      cancellationOrder
    );
  }

  if (cart) {
    analyticsData.descriptors.cart = constructCartDescriptor(cart);
  }

  if (gainsightEvent) {
    analyticsData.descriptors.gainsightEvent = new GainsightEventDescriptor(gainsightEvent);
  }

  if (sophia && !isEmpty(sophia.responses)) {
    analyticsData.descriptors.sophia = constructSophiaDescriptor(sophia);
  }

  if (productList) {
    // primaryProduct is still used by Adobe Anaytics to track products that are interacted with/accepted.
    // rather than overload the productList Translator logic we have split it into its own descriptor/translator.
    if (eventName === EVENT_NAME.ACCEPT_OFFER) {
      const primaryProduct = productList.items[0]; // only one item is interacted with per interaction analytics event.
      analyticsData.descriptors.primaryProduct = new PrimaryProductDescriptor(primaryProduct);
    }

    analyticsData.descriptors.productList = constructProductListDescriptor(productList);
  }

  if (error) {
    analyticsData.descriptors.error = new ErrorDescriptor(error);
  }

  if (organizationInfo) {
    analyticsData.descriptors.organizationInfo = new OrganizationInfoDescriptor(organizationInfo);
  }

  AnalyticsEvent.dispatch(analyticsData);
}
/* eslint-enable complexity -- 2 over mainly due to feature flag checks */

/**
 * @description Serialize a Javascript date object to a string format consumable by Adobe Analytics
 * @param {Date} date The date to be serialized
 * @returns The date object in the string format "year=2022 | month=January | date=1 | day=Saturday | time=1:23 AM | tz=GMT+00:00"
 */
function formatAnalyticsDate(date) {
  if (!isDate(date)) {
    return undefined;
  }

  return format(date, "'year='yyyy' | month='MMMM' | date='d' | day='EEEE' | time='p' | tz='OOOO");
}

/**
 * @description gather floodgate data for floodgate feature groups, used for A/B testing purposes
 * @param {Array<String>} [featureGroups=undefined] - a list of custom feature group strings to poll floodgate for
 * @returns {String} a serialized string containing pipe delimited list of featureGroupId and variantId
 * for each requested feature group. The feature group will be represented with the featureGroupId and variantId,
 * separated by a colon (:). And each key value pair for the feature group will be separated by a pipe (|).
 * @example "feature_group:123|other_feature_group:456"
 */
function getFloodgateVariantData(featureGroups) {
  return (
    featureGroups
      ?.map((featureGroup) => {
        const variantId = feature.getReleaseVariantId(featureGroup) ?? -1; // Preserve 0 = control
        return `${featureGroup}:${variantId}`;
      })
      .join('|') || ''
  );
}

/**
 * @description given sophia notification data response it constructs a Sophia Descriptor object
 * @param {Object} [options.responses] object containing the list of sophia data responses
 * @param {String} [options.cardIdKey] represents the value in a response that will be mapped to cardId during SophiaDescriptor construction
 * @returns {SophiaDescriptor} containing Sophia related analytic data.
 */
function constructSophiaDescriptor(options) {
  const {cardIdKey, responses} = options;

  const cardIdConstant = cardIdKey || CARD_ID;

  return new SophiaDescriptor({
    responses: responses.map((response) => ({
      cardId: get(response, cardIdConstant),
      ...pick(response, [
        ACTIONBLOCK_ID,
        CAMPAIGN_ID,
        CONTAINER_ID,
        CONTROL_GROUP_ID,
        SURFACE_ID,
        TREATMENT_ID,
        VARIATION_ID,
      ]),
    })),
  });
}

/**
 * @description Waits for a collection of promises to resolve, and merges the resulting values into a single object, where possible.
 * Promises that resolve with an non-object result will be ignored. Promises that reject are also ignored.
 *
 * @param {Array} promiseCollection - an array of promises to wait for settling.
 * @returns {promise<Object>} a promise that will resolve with a single merged object representing the merged collection.
 */
async function getSettledPromisesAndMergedResult(promiseCollection) {
  const results = await Promise.allSettled(promiseCollection);
  return merge(
    {},
    ...results.map((item) =>
      item.status === 'fulfilled' && typeof item.value === 'object' ? item.value : {}
    )
  );
}

/**
 * @description construct the cart descriptors from different data sources.
 * @param {Object} options - options to make a cart analytics event
 * @param {Array} options.billingData - billing data for every item in the cartItems array
 * @param {String} options.cancellationReasonComment -  reason for cancllation
 * @param {Enumerator} options.cancellationReasons - checkbox selections
 * @param {String} options.cartId - cart ID
 * @param {Array} options.cartItems - internally formatted cart items from the response
 * @param {Object} options.contract - contract information relating to cart
 * @param {String} options.purchaseAuthId - purchase auth id
 * @param {Object} options.total - total pricing information for the cart
 * @returns {Object} cart descriptor
 */
function constructCartDescriptor(options) {
  const {
    billingData,
    cancellationReasonComment,
    cancellationReasons,
    cartId,
    cartItems,
    contract,
    purchaseAuthId,
    total,
  } = options;

  const items = cartItems.map((cartItem, idx) => {
    const item = pick(cartItem, [
      'assignableLicenseCount', // Purchased licenses
      'customer_segment',
      'numberSelected',
      'offer_id',
      'pricing.currency.code',
      'productBucket',
      'provisionedQuantity', // Assigned licenses
    ]);

    if (item.pricing && Array.isArray(billingData)) {
      Object.assign(item.pricing, get(billingData[idx], 'sub_total'));
    }

    return item;
  });

  return new CartDescriptor({
    cancellationReasonComment,
    cancellationReasons,
    contract: {
      customerSegment: contract.customerSegment,
      id: contract.id,
      inRenewalWindow: contract.isInRenewalWindow(),
      isNFR: contract.isNFR(),
      model: contract.model,
      offsetFromAnniversaryDate: contract.getOffsetFromAnniversaryDate(),
      salesChannel: contract.salesChannel,
    },
    id: cartId,
    items,
    purchaseAuthId,
    total,
  });
}

export {
  formatAnalyticsDate,
  getFloodgateVariantData,
  dispatchUiEventAnalytics,
  dispatchPageAnalytics,
  getSettledPromisesAndMergedResult,
};
