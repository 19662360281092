(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableSummarization.js
   *
   * @ngdoc factory
   * @name ConsumableSummarization
   * @description Model for a ConsumableSummarization
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Summarization
   */
  angular
    .module('binky.core.product.consumable')
    .factory('ConsumableSummarization', getConsumableSummarizationModel);

  /* @ngInject */
  function getConsumableSummarizationModel(_, ConsumableSummaryList) {
    class ConsumableSummarization {
      /**
       * @description Creates a new ConsumableSummarization for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.organizationId organization id of org this summarization pertains to
       * @param {String} options.contractId contract id this summarization pertains to
       * @param {String} options.summarizedBy dimension being summarized, such as "license_id" or "order_id"; if missing, fulfillable_item_code is assumed
       * @param {Array} options.summaries array of ConsumableSummary elements, one for each "value" in the dimension
       *                     i.e. if summarize_by is license_id, there would be one Summary for each license
       */
      constructor(options) {
        initModel(this, options);
      }

      /**
       * @description Find any Consumable objects that are for the specified fulfillable item code
       *
       * @param {String} fiCode the fulfillable item code to find summaries for
       *
       * @returns {Array} List of Consumables that are for the fulfillable item code
       */
      getConsumablesForFulfillableItemCode(fiCode) {
        return this.summaryList.getConsumablesForFulfillableItemCode(fiCode);
      }

      /**
       * @description Find any ConsumableSummary objects that are for the specified ID
       *
       * @param {String} id the ID to find summaries for
       *
       * @returns {Array} List of Summaries that are for the ID
       */
      getSummariesForId(id) {
        return this.summaryList.getSummariesForId(id);
      }

      /**
       * @description Get the top level query params from the parent summarization list, and add
       *    specific params for this summarization (contract id, org id)
       *
       * @returns {Object} Object of the parameters used to query for this summarization
       */
      getTopLevelQueryParams() {
        return _.assignIn(
          {},
          _.pick(this, ['contractId', 'organizationId', 'summarizedBy']),
          _.pick(this.parentSummzarizationList, [
            'fulfillment_id',
            'group_id',
            'include_depleted',
            'include_expired',
            'license_id',
          ])
        );
      }

      /**
       * @description Set a reference to the parent summarization list, for the getting of query parameters
       *
       * @param {ConsumableSummarizationList} summarizationList the parent list for this summarization
       */
      setParentSummarizationList(summarizationList) {
        this.parentSummzarizationList = summarizationList;
      }

      /**
       * @class
       * @description Transforms the /consumable summary response Object into a new ConsumableSummary
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new ConsumableSummarization(dataTransferObject);
      }
    }

    //////////////

    function initModel(model, options) {
      // First we assign the model fields
      _.assignIn(model, _.pick(options, ['contractId', 'organizationId', 'summarizedBy']));

      // We pass additional options (many from the parent summarization list query params) to the
      // ConsumableSummaryList constructor, so it can provide them down the chain as needed
      // (mostly needed by ConsumableUsageList to construct its own relevant query params)
      model.summaryList = new ConsumableSummaryList(options.summaries, model);
    }

    return ConsumableSummarization;
  }
})();
