const CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID = 'ConsumableSummarizationList';

const CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY = {
  CONTRACT_ID: 'contract_id',
  FULFILLMENT_ID: 'fulfillment_id',
  GROUP_ID: 'group_id',
  LICENSE_ID: 'license_id',
  ORDER_ID: 'order_id',
  ORGANIZATION_ID: 'organization_id',
};

const CONSUMABLE_THRESHOLD_STATUS = {
  DEPLETED: 'DEPLETED',
  NONE: 'NONE',
  PERCENTAGE_THRESHOLD: 'PERCENTAGE_THRESHOLD',
};

export {
  CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  CONSUMABLE_THRESHOLD_STATUS,
};
