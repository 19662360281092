(function () {
  /**
   * @ngdoc module
   * @name binky.core.common.model.cache
   * @description defines a model cache and related utilities
   */
  angular.module('binky.core.common.model.cache', [
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
