(function () {
  'use strict';
  /**
   * @deprecated Please use src2/widgets/common/components/comment/delete-comment/DeleteComment.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.comments.delete:binkyDeleteComment
   *
   * @description A delete comment widget.
   *
   * @param {String} commentId The id of the comment.
   * @param {Function} [onDelete] The callback function to delete the comment when the delete button is clicked
   *
   * @example <binky-delete-comment comment-id="MyCommentId"> </binky-delete-comment>
   *
   */
  angular.module('binky.widgets.common.comments.delete').component('binkyDeleteComment', {
    bindings: {
      commentId: '@',
      onDelete: '&',
    },
    controller,
    templateUrl: 'widgets/common/comments/delete/delete-comment.component.html',
  });

  /* @ngInject */
  function controller($transclude, _, DELETE_COMMENT_MODAL_ID, panelManager) {
    const vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
      _.assign(vm, {
        modalId: `${DELETE_COMMENT_MODAL_ID}_${vm.commentId}`,
        onConfirm,
        openModal,
      });
    }

    function onConfirm() {
      panelManager.close(vm.modalId);
      _.invoke(vm, 'onDelete', {commentId: vm.commentId});
    }

    function openModal() {
      panelManager.open(vm.modalId);
    }
  }
})();
