import clypeUsers from 'api/clypeUsers';
import LinkedUserAccount from 'models/user/linked-accounts/LinkedUserAccount';
import ModelList from 'services/modelList/ModelList';

import {LINKED_USER_ACCOUNT_LIST_FILTER} from './LinkedUserAccountListConstants';

class LinkedUserAccountList extends ModelList {
  /**
   * @class
   * @description instantiate the list of LinkedUserAccounts for a user.
   * @param {Object} options Initialization Object (params described below)
   * @param {String} [options.filter] - the filter to apply to the accounts, from
   *     LINKED_USER_ACCOUNT_LIST_FILTER. Defaults to ALL.
   * @param {String} [options.userId] - the userId to fetch linked accounts for.
   * @param {Boolean} [options.includePaths] - Set to true to get Global Admin
   * org hierarchy
   */
  constructor(options = {}) {
    super({
      id: options.id,
      isCacheable: true,
      itemClassRef: LinkedUserAccount,
      modelCacheId: 'user/LinkedUserAccount',
      resource: ({includePaths, userId}) =>
        clypeUsers.getLinkedAccountsResource(userId, {includePaths}),
    });
    this.linkedAccountListFilter = options.filter || LINKED_USER_ACCOUNT_LIST_FILTER.ALL;
    this.userId = options.userId;
    this.includePaths = options.includePaths;
  }

  /**
   * @description Method to refresh the contents of the list
   * @param {Object} options - Optional. Initialization Object (params described below)
   * @param {String} [options.userId] - the userId to fetch linked accounts for.
   * @param {Boolean} [options.includePaths] - Set to true to get Global Admin
   * org hierarchy
   * @return {Promise<LinkedUserAccountList>} resolves to refreshed UserGroupList, else rejects
   *                              with error message
   */
  refresh(options = {}) {
    return super.refresh({includePaths: this.includePaths, userId: options.userId || this.userId});
  }
}

export default LinkedUserAccountList;
