(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Invitation
   * @description Model for a Invitation.
   */
  angular.module('binky.core.invitation').factory('Invitation', getInvitation);

  /* @ngInject */
  function getInvitation($rootScope, _, INVITATION_EVENT, jilInvitations) {
    class Invitation {
      constructor(options) {
        const {invitationCode} = options;

        _.assign(this, {
          invitationCode,
        });
      }

      /**
       * @description Accepts the provided invitation, for the logged in user.
       *
       * @returns {Promise} resolves when acceptance is complete
       */
      accept() {
        this.$resolved = false;

        this.$promise = jilInvitations.invitations
          .accept({invitationCode: this.invitationCode})
          .$promise.then(() => {
            $rootScope.$emit(INVITATION_EVENT.ACCEPT);
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Method to fetch the invitation data
       * @returns {Promise} promise - promise object, resolved when data is loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = jilInvitations.invitations
          .get({invitationCode: this.invitationCode})
          .$promise.then((response) => {
            initModel(this, response);
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Revokes the provided invitation, so that it can no longer
       *   be accepted by the invitee.
       *
       * @returns {Promise} resolves when revocation is complete
       */
      revoke() {
        this.$resolved = false;

        this.$promise = jilInvitations.invitations
          .delete({invitationCode: this.invitationCode})
          .$promise.then(() => {
            $rootScope.$emit(INVITATION_EVENT.REVOKE);
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Method for getting an Invitation object and loading its data
       * @param {String} invitationCode - invitationCode of the invite to fetch
       * @returns {Invitation} invitation object
       */
      static get(invitationCode) {
        const model = new Invitation({invitationCode});
        model.refresh();
        return model;
      }
    }

    return Invitation;

    /** Private Methods **/

    function initModel(model, options) {
      _.assignIn(model, _.pick(options, ['email', 'invitationCode', 'status', 'typeCode']));
    }
  }
})();
