(function () {
  /**
   * @ngdoc module
   * @name binky.core.authentication.ready
   * @description defines authenticationReady service, which blocks on user authentication.
   */
  angular.module('binky.core.authentication.ready', [
    'binky.core.authentication',
    'binky.core.configuration.ready',
    'binky.core.locale.ready',
    'binky.core.src2-migration',
    'binky.core.user.authenticated-user',
  ]);
})();
