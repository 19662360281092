// eslint-disable-next-line angular/window-service -- importing binkySrc2
const DIRECTORY_CONSTANTS = window.binkySrc2.services.directory.DIRECTORY_CONSTANTS;
(function () {
  /**
   * @deprecated. Please use src2/core/services/directory/directoryConstants.js
   */
  angular
    .module('binky.core.directories')
    .constant('DIRECTORY_ENCRYPTION_PROGRESS', DIRECTORY_CONSTANTS.DIRECTORY_ENCRYPTION_PROGRESS)
    .constant('DIRECTORY_ENCRYPTION_STATUS', DIRECTORY_CONSTANTS.DIRECTORY_ENCRYPTION_STATUS)
    .constant('DIRECTORY_EVENT', DIRECTORY_CONSTANTS.DIRECTORY_EVENT)
    .constant('DIRECTORY_OWNERSHIP_STATUS', DIRECTORY_CONSTANTS.DIRECTORY_OWNERSHIP_STATUS)
    .constant('DIRECTORY_STATUS', DIRECTORY_CONSTANTS.DIRECTORY_STATUS)
    .constant('DIRECTORY_TYPE', DIRECTORY_CONSTANTS.DIRECTORY_TYPE)
    .constant('IDP_STATUS', DIRECTORY_CONSTANTS.IDP_STATUS)
    .constant('IDP_TYPES', DIRECTORY_CONSTANTS.IDP_TYPES);
})();
