(function () {
  /**
   * @ngdoc module
   * @name binky.core.common.country
   * @description Module which defines the Country and CountryList models
   */
  angular.module('binky.core.common.country', [
    'binky.core.common.model.cache',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.promise-utils',
    'binky.core.src2-migration',
  ]);
})();
