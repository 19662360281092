(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   */
  angular.module('binky.widgets.omni-tool.features-panel').constant('OMNI_TOOL_FEATURES_PANEL', {
    ID: 'features-panel',
    ROWS_PER_PAGE: 10,
    TEXT: 'features',
  });
})();
