import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL organizationsMigrations APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL organizations migrations api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Fetches a specific migration policy for a specific org
 *
 * @param {String} orgId - The org id
 * @param {String} name - The migration to fetch policy information for
 * @returns {Promise} Resolves to the axios response object whose data contains the policy
 */
const getMigrationPolicy = async ({orgId, name}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/migration/${name}/policy`, {
    headers: getHeaders({clientId, includeRoles}),
  });

  return response;
};

/**
 * Fetches all migrations for a specific org
 *
 * @param {String} orgId - The org id
 * @param {String} types - Migration types for getting migration information
 * @returns {Promise} Resolves to the axios response object whose data contains an array of OrganizationsMigrations
 */
const getMigrations = async ({orgId, types}) => {
  const params = {
    types,
  };
  const headers = {
    Accept: 'application/vnd.com.adobe.jil.organizations.migrations.v2+json',
    ...getHeaders({clientId, includeRoles}),
  };

  const response = await axios.get(`${url}/v2/organizations/${orgId}/migrations`, {
    headers,
    params,
  });

  return response;
};

/**
 * Updates a specific migration policy for a specific org
 *
 * @param {Object} orgId - The org id
 * @param {String} name - The migration to store policy information for
 * @param {Object} policy - The policy data to store
 * @returns {Promise} Resolves to the axios response object whose data contains the organization
 */
const putMigrationPolicy = ({orgId, name, policy}) =>
  axios.put(`${url}/v2/organizations/${orgId}/migration/${name}/policy`, policy, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilOrganizationsMigrations = {
  configure,
  getMigrationPolicy,
  getMigrations,
  putMigrationPolicy,
};

export default jilOrganizationsMigrations;
