/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {Grid, Heading, Link, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import Drawer from 'shell/panels/drawer/Drawer';
import DrawerContent from 'shell/panels/drawer/content/DrawerContent';
import DrawerHeader from 'shell/panels/drawer/header/DrawerHeader';
import useDrawer from 'shell/panels/drawer/useDrawer';

import messages from '../../../../locales/en.495e31ace1.json';
import GoUrl from '../../go-url/GoUrl';
import TitledSection from '../../titled-section/TitledSection';
import './InsightsDetailsLink.pcss';
import InsightsConstants from '../InsightsConstants';

/**
 * InsightsDetailsLink - details link which opens details drawer when clicked
 */
const InsightsDetailsLink = ({details}) => {
  const id = useId();
  const intl = useIntl();
  const [closeDrawer, isOpen, openDrawer, overlayVisible] = useDrawer();
  const {DESCRIPTION, LABEL, LINK_LABEL, TITLE} = InsightsConstants.STRING_TYPE;

  const getString = (data, type) => {
    const key = data[`${type}Key`];
    // set empty string as defualt since TitledSection's title and GoUrl's children are required
    return data[type] || (messages[key] ? intl.formatMessage({id: key}) : '');
  };

  const getLinkLabel = () =>
    getString(details, LINK_LABEL) ||
    intl.formatMessage({id: 'binky.widgets.insights.detailsLinkLabel'}); // set Details as default link label

  return (
    <>
      <Link isQuiet onPress={openDrawer}>
        {getLinkLabel()}
      </Link>
      <Drawer
        id={id}
        isOpen={isOpen}
        onClose={closeDrawer}
        overlayVisible={overlayVisible}
        title={getString(details, TITLE)}
      >
        <DrawerHeader>
          <span styleName="description">{getString(details, DESCRIPTION)}</span>
        </DrawerHeader>
        <DrawerContent>
          {details.additionalInfo?.map((info, index) => {
            const key = `info${index}`;
            return (
              <View key={key} paddingBottom="size-500">
                <Heading level={3} marginY="size-100">
                  {getString(info, TITLE)}
                </Heading>
                <span styleName="description">{getString(info, DESCRIPTION)}</span>
              </View>
            );
          })}
          {details.links && (
            <TitledSection title={getString(details.links, TITLE)}>
              <Grid>
                {details.links.items?.map((item, index) => {
                  const key = `goUrl${index}`;
                  return (
                    <GoUrl key={key} marginTop="size-100" name={item.goUrl}>
                      {getString(item, LABEL)}
                    </GoUrl>
                  );
                })}
              </Grid>
            </TitledSection>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

InsightsDetailsLink.propTypes = {
  /**
   * Details object which contains link label and information that needs to be displayed in drawer
   */
  details: PropTypes.shape({
    /**
     * Additional information for the insights item
     */
    additionalInfo: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Description of the additional information
         */
        description: PropTypes.string,
        /**
         * Description translation key of the additional information which is prefixed with 'binky.widgets.insights'
         */
        descriptionKey: PropTypes.string,
        /**
         * Title of the additional information
         */
        title: PropTypes.string,
        /**
         * Title translation key of the additional information which is prefixed with 'binky.widgets.insights'
         */
        titleKey: PropTypes.string,
      })
    ),
    /**
     * Drawer description
     */
    description: PropTypes.string,
    /**
     * Drawer description translation key which is prefixed with 'binky.widgets.insights'
     */
    descriptionKey: PropTypes.string,
    /**
     * Label for the details link
     */
    linkLabel: PropTypes.string,
    /**
     * Label translation key for the details link which is prefixed with 'binky.widgets.insights'
     */
    linkLabelKey: PropTypes.string,
    /**
     * Data for the links section
     */
    links: PropTypes.shape({
      /**
       * Label for the details link
       */
      items: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Go url name
           */
          goUrl: PropTypes.string,
          /**
           * Label for the go url link
           */
          label: PropTypes.string,
          /**
           * Label translation key for the go url link which is prefixed with 'binky.widgets.insights'
           */
          labelKey: PropTypes.string,
        })
      ),
      /**
       * Title for the links section Ex: Helpful links
       */
      title: PropTypes.string,
      /**
       * Title translation key for the links section which is prefixed with 'binky.widgets.insights'
       */
      titleKey: PropTypes.string,
    }),
    /**
     * Drawer title and normally, it's the same as insights report/log name. Ex: Audit logs
     */
    title: PropTypes.string,
    /**
     * Drawer title translation key which is prefixed with 'binky.widgets.insights'
     */
    titleKey: PropTypes.string,
  }).isRequired,
};

export default InsightsDetailsLink;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
