(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.badge-popover:binkyBadgePopover
   *
   * @description The badge popover widget. This widget will display any notices
   *   that are passed in to it as a badge widget that can optionally also
   *   display popover content. Multiple notices with popover content can be
   *   displayed at once, utilizing a carousel mechanism in the popover.
   *
   *   Only one type of notice can be displayed at a given time. The two types
   *   of notices are badge-only and badge w/popover. The highest priority
   *   (lowest value) notice type will be chosen for display.
   *
   *   If the highest priority notice is a badge only notice, then only a badge
   *   will be displayed.
   *
   *   If the highest priority notice contains popover content, then all notices
   *   passed in to this component that contain popover content will be shown.
   *   The badge will be keyboard accessible, open a popover when selected, and
   *   all badges (sorted by priority) will be navigable to the end user.
   *
   *   Multiple badge-only notices can not be shown at the same time since there
   *   does not exist a method to navigate between them. So, whenever the
   *   highest priority notice is a badge-only notice, this will be the only
   *   notice shown. This ensures that the most critical notice is never hidden
   *   from the user.
   *
   * @param {Notice[]} notices - array of Notice model Objects to render. More
   *   details on model class and class members (attributes) can be found in
   *   notice.service.js
   *
   * @example
   * <binky-badge-popover notices="$ctrl.notices"></binky-badge-popover>
   *
   */
  angular.module('binky.widgets.common.badge-popover').component('binkyBadgePopover', {
    bindings: {
      notices: '<',
    },
    controller,
    templateUrl: 'widgets/common/badge-popover/badge-popover.component.html',
  });

  /* @ngInject */
  function controller($element, $scope, $timeout, _, CAROUSEL_CHANGE_EVENT) {
    const vm = this;
    let deregisterSelectedBadgeListener;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      onPopoverContentHover,
      popoverId: _.uniqueId('badge-popover-'),
    });

    /**
     * @description Destructor for badge components. Cleans up any registered
     *   listeners to prevent memory leaks at runtime.
     */
    function $onDestroy() {
      deregisterSelectedBadgeListener();
    }

    /**
     * @description Initialization method for badge popover component. Sets up
     *   initial state, sorts/filters Notices, and sets up listeners for popover
     *   carousel change events.
     */
    function $onInit() {
      // sort notices by priority
      const sortedNotices = _.sortBy(vm.notices, ['priority']);

      // if highest priority item has popover content, then display all badges
      // inside of a badge-popover component, else just single badge (no
      // popover)
      vm.showPopover = _.chain(sortedNotices).head().invoke('hasBody').value();
      if (vm.showPopover) {
        vm.sortedAndFilteredNotices = _.filter(sortedNotices, (notice) => notice.hasBody());

        if (vm.sortedAndFilteredNotices.length > 1) {
          $element.addClass('multi-notice');
        }
      } else {
        vm.sortedAndFilteredNotices = sortedNotices;
      }

      // highest priority item is displayed
      setSelectedNotice(_.head(vm.sortedAndFilteredNotices));

      // keep selected Notice in sync with carousel selection
      deregisterSelectedBadgeListener = $scope.$on(
        CAROUSEL_CHANGE_EVENT,
        (event, newSelectionObj) => {
          setSelectedNotice(_.nth(vm.sortedAndFilteredNotices, newSelectionObj.index));
        }
      );
    }

    /**
     * @description Method to handle mouse hover events over the popover content
     *   element. Will update current popover hovering state which controls
     *   whether or not the carousel arrows are shown.
     * @param {Boolean} isHovering - true if mouse is hovering over popover
     *   content element, else false
     */
    function onPopoverContentHover(isHovering) {
      vm.isPopoverContentHovering = isHovering;
    }

    ////////

    /**
     * @description Method to set the currently-selected notice for this
     *   component. When this component is first initialized/rendered, this is
     *   the highest priority Notice found in the bound "notices" array.
     *   However, if/when a new Notice is navigated to through the popover
     *   carousel, the selected notice will be updated to reflect this new
     *   choice. This allows us to change the badge popover in tandem with the
     *   popover Notice being displayed, while also keeping track of what the
     *   user was looking at in case the popover is errantly closed (e.g. -
     *   switch popovers and new popover message is shorter, causing mouse hover
     *   state to be lost)
     * @param {Notice} notice - new Notice to set as selected notice
     */
    function setSelectedNotice(notice) {
      vm.selectedNotice = notice;
    }
  }
})();
