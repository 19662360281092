/* eslint-disable complexity -- Needed */
import {log} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import Bumper from 'shell/bumper/Bumper';

import OverlayWait from '../../../common/components/wait/OverlayWait';
import {ORIENTATION} from '../nav/WorkspaceNavConstants';
import useWorkspacePageProps from '../useWorkspacePageProps';

import {PAGE_ALIGNMENT, PAGE_CONTENT_HEIGHT, PAGE_SECTIONS} from './PageConstants';

/**
 * Page component to layout the basic page view.
 */
const Page = ({
  bumperCtaButtonLabel,
  bumperStrings,
  children,
  'data-testid': dataTestId,
  isBumpered = false,
  isInWorkspace = false,
  isLoading = false,
  loadingMessage,
  onClickBumper,
  orientation = null,
  showContent = false,
}) => {
  let pageActionsEndAligned, pageActionsStartAligned;

  // Override props if props are provided by Workspace context, otherwise use original props
  const workspacePageProps = useWorkspacePageProps();
  if (workspacePageProps) {
    // eslint-disable-next-line no-param-reassign -- override prop params
    ({isInWorkspace, orientation} = workspacePageProps);
  }

  const isPageSection = (component, section) => component?.type?.displayName === section;

  const findPageSection = (section) => {
    if (children?.length > 1) {
      return children.find((el) => isPageSection(el, section));
    }
    return isPageSection(children, section) && children;
  };

  const pageActionButtons = findPageSection(PAGE_SECTIONS.ACTIONS);
  const pageBanners =
    findPageSection(PAGE_SECTIONS.BANNERS) || findPageSection(PAGE_SECTIONS.BANNER);
  const pageBreadcrumbs = findPageSection(PAGE_SECTIONS.BREADCRUMBS);
  const pageDescription = findPageSection(PAGE_SECTIONS.DESCRIPTION);
  const pageHeader = findPageSection(PAGE_SECTIONS.HEADER);
  const pageInfoBar = findPageSection(PAGE_SECTIONS.INFO_BAR);
  const pageNav = findPageSection(PAGE_SECTIONS.NAV);
  const pageContent = findPageSection(PAGE_SECTIONS.CONTENT);

  if (pageActionButtons) {
    if (pageDescription) {
      pageActionsStartAligned = cloneElement(pageActionButtons, {
        align: PAGE_ALIGNMENT.START,
        alignSelf: 'auto',
      });
    } else {
      pageActionsEndAligned = pageActionButtons;
    }
  }

  const getBumper = () => {
    const bumper = (
      <Bumper
        ctaButtonLabel={bumperCtaButtonLabel}
        data-testid="page-bumper"
        details={bumperStrings?.details}
        onButtonClick={onClickBumper}
        title={bumperStrings?.title}
      />
    );
    return isInWorkspace ? (
      <View data-testid="bumper-wrapper" flexGrow={1}>
        {bumper}
      </View>
    ) : (
      bumper
    );
  };

  const marginX = isInWorkspace && orientation !== ORIENTATION.VERTICAL ? null : 'auto';

  return isBumpered ? (
    getBumper()
  ) : (
    // Log any uncaught errors thrown under Page subtree and show bumper
    <ErrorBoundary
      fallbackRender={getBumper}
      onError={(error) => {
        if (dataTestId) {
          log.error(`Unexpected Page error for ${dataTestId}: ${error.message}`, error);
        } else {
          log.error(`Unexpected Page error: ${error.message}`, error);
        }
      }}
    >
      <View
        data-testid={dataTestId}
        flexGrow={isInWorkspace ? 1 : 'unset'}
        marginX={marginX}
        overflow="hidden auto"
      >
        <OverlayWait
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          showContent={showContent}
          size="L"
        >
          <View
            marginBottom="size-200"
            // 1540 - 8px to account for padding
            maxWidth="1532px"
            minHeight={PAGE_CONTENT_HEIGHT}
            // Adding padding to avoid clipping button and tab halos (aka focus ring)
            padding="size-100"
          >
            {pageBanners}
            {pageBreadcrumbs && <View marginBottom="size-300">{pageBreadcrumbs}</View>}
            {/* If there are actions buttons, but no description, the
                buttons will be aligned at the end next to the title header. */}
            <Flex alignItems="start" columnGap="size-200" justifyContent="space-between" wrap>
              {pageHeader}
              {pageActionsEndAligned}
            </Flex>
            <Flex
              direction="column"
              // size-85/7px between title and description or if no
              // description, size-400/32px between the title and the
              // component below.
              marginTop={pageDescription ? 'size-85' : 'size-400'}
              rowGap="size-300" // 24px
            >
              {pageDescription}
              {pageActionsStartAligned}
              {pageInfoBar}
              {pageNav}
              {pageContent}
            </Flex>
          </View>
        </OverlayWait>
      </View>
    </ErrorBoundary>
  );
};

Page.propTypes = {
  /**
   * Translated text of the Bumper's CTA button label. Defaults to `Get Help`.
   */
  bumperCtaButtonLabel: PropTypes.string,
  /**
   * BumperStrings passed to the Page if the page is bumpered.
   */
  bumperStrings: PropTypes.shape({
    details: PropTypes.string,
    title: PropTypes.string,
  }),
  /**
   * The page sections to be displayed within the Page.
   */
  children: PropTypes.node,
  /**
   * The specified data-testid string. Also used to ID the page if
   * an error is caught by ErrorBoundary.
   */
  'data-testid': PropTypes.string,
  /**
   * If the page is shown with a Bumper. Defaults to false.
   */
  isBumpered: PropTypes.bool,
  /**
   * If the page is a child of a workspace. Defaults to false.
   */
  isInWorkspace: PropTypes.bool,
  /**
   * If the page is still loading. Defaults to false.
   */
  isLoading: PropTypes.bool,
  /**
   * An optional localized string to display below the loading spinner.
   */
  loadingMessage: PropTypes.string,
  /**
   * The handler to pass in to Bumper.
   */
  onClickBumper: PropTypes.func,
  /**
   * A string indicating which orientation the page & nav are rendering at
   */
  orientation: PropTypes.string,
  /**
   * When 'isLoading' is true, showContent determines whether or not the content shows below the OverlayWait scrim.
   * The default is false.
   */
  showContent: PropTypes.bool,
};

export default Page;
/* eslint-enable complexity -- Needed */
