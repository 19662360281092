import {LinkedUserAccount, Organization} from '@admin-tribe/binky';
import {Grid} from '@adobe/react-spectrum';
import CloudIcon from '@spectrum-icons/workflow/Cloud';
import PropTypes from 'prop-types';
import React from 'react';

import BumperMessage from './bumper-message/BumperMessage';

/**
 * A Bumper component which can be embedded in larger pages
 */
const Bumper = ({
  activeOrgId,
  authenticatedUserId,
  details,
  icon,
  ctaButtonLabel,
  linkedUserAccounts,
  moreDetails,
  onButtonClick,
  onOrgChange,
  onSecondaryButtonClick,
  organizationItems,
  secondaryLabel,
  title,
}) => (
  <Grid
    alignContent="center"
    alignItems="center"
    areas={['icon', 'content']}
    gap="size-150"
    justifyContent="center"
    justifyItems="center"
    marginTop="size-1200"
  >
    {icon || <CloudIcon data-testid="bumper-icon" size="XXL" />}
    <BumperMessage
      activeOrgId={activeOrgId}
      authenticatedUserId={authenticatedUserId}
      ctaButtonLabel={ctaButtonLabel}
      details={details}
      linkedUserAccounts={linkedUserAccounts}
      moreDetails={moreDetails}
      onButtonClick={onButtonClick}
      onOrgChange={onOrgChange}
      onSecondaryButtonClick={onSecondaryButtonClick}
      organizationItems={organizationItems}
      secondaryLabel={secondaryLabel}
      title={title}
    />
  </Grid>
);

Bumper.propTypes = {
  /**
   * The ID of the active org.
   */
  activeOrgId: PropTypes.string,
  /**
   * The ID of current authenticated user, used in OrganizationSwitcher.
   */
  authenticatedUserId: PropTypes.string,
  /**
   * Translated text of the button label for cta button. Defaults to `Get Help`.
   */
  ctaButtonLabel: PropTypes.string,
  /**
   * Translated text of the details in the Bumper.
   */
  details: PropTypes.string,
  /**
   * The icon component. Defaults to a Cloud.
   */
  icon: PropTypes.node,
  /**
   * The linked user accounts to display for org switching.
   */
  linkedUserAccounts: PropTypes.arrayOf(PropTypes.instanceOf(LinkedUserAccount)),
  /**
   * Translated text for more details in Bumper.
   */
  moreDetails: PropTypes.string,
  /**
   * Callback function called when cta button is clicked.
   */
  onButtonClick: PropTypes.func,
  /**
   * The function to invoke on selecting a different org
   */
  onOrgChange: PropTypes.func,
  /**
   * Callback function called when the secondary button is clicked.
   */
  onSecondaryButtonClick: PropTypes.func,
  /**
   * The organization items to display for the switcher.
   */
  organizationItems: PropTypes.arrayOf(PropTypes.instanceOf(Organization)),
  /**
   * Translated text of the button label for the secondary button.
   * If the label is not defined, the secondary button would not be displayed.
   */
  secondaryLabel: PropTypes.string,
  /**
   * Translated text for title in Bumper. Defaults to `We are sorry.`.
   */
  title: PropTypes.string,
};

export default Bumper;
