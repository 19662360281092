import {CONTACT_TYPE, ContactList} from '@admin-tribe/binky';
import {Checkbox, Form, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import SecurityContactInput from './security-contact-input/SecurityContactInput';

const {DATA_ACCESS_ADMINISTRATOR, DATA_PROTECTION_OFFICER, SECURITY_OFFICER} = CONTACT_TYPE;

/**
 * The form to update the security contacts' information.
 */
const SecurityContactsForm = ({contactList, isDisabled = false, onChange}) => {
  const contactTypes = [DATA_ACCESS_ADMINISTRATOR, SECURITY_OFFICER, DATA_PROTECTION_OFFICER];
  const intl = useIntl();
  const [contacts, setContacts] = useState(contactList);
  const [selectedCheckbox, setSelectedCheckbox] = useState();

  const isCheckboxSelected = (key) => key === selectedCheckbox;

  const isCheckboxDisabled = (key) =>
    !isCheckboxSelected(key) && (isDisabled || !!selectedCheckbox);

  const isEditingSingleContact = (contactType) => contactType && selectedCheckbox !== contactType;

  const getDividerPlacement = (contactType) =>
    contactType === DATA_ACCESS_ADMINISTRATOR ? DividerPlacement.NONE : DividerPlacement.TOP;

  const getMarginBottom = (contactType) =>
    contactType === DATA_PROTECTION_OFFICER ? undefined : 'size-200';

  const onChangeContact = (updatedContact, contactType) => {
    const editedContacts = {...contacts};
    if (isEditingSingleContact(contactType)) {
      // Only save the input for the contact being edited
      editedContacts[contactType] = updatedContact;
      if (selectedCheckbox) {
        // When a field is changed to differ from the contact
        // being copied, deselect the checkbox
        setSelectedCheckbox();
      }
    } else {
      // Change should be reflected on the all contacts
      contactTypes.forEach((type) => {
        editedContacts[type] = updatedContact;
      });
    }
    setContacts(new ContactList(editedContacts));
    onChange?.(new ContactList(editedContacts));
  };

  const onCheckboxChange = (key, isSelected) => {
    // Either one checkbox is selected or none
    setSelectedCheckbox(isSelected ? key : undefined);
    if (isSelected) {
      // Update all the contacts
      onChangeContact(contacts[key]);
    }
  };

  return (
    <View data-testid="security-contacts-form">
      {contactTypes.map((contactType) => (
        <TitledSection
          key={contactType}
          data-testid={`${contactType}-section`}
          dividerPlacement={getDividerPlacement(contactType)}
          marginBottom={getMarginBottom(contactType)}
          title={intl.formatMessage({
            id: `binky.common.securityContacts.${contactType}Section.title`,
          })}
          tooltipDescription={intl.formatMessage({
            id: `binky.common.securityContacts.${contactType}Section.description`,
          })}
        >
          <Form>
            <SecurityContactInput
              contact={contacts[contactType]}
              isDisabled={isDisabled}
              onChange={(contact) => onChangeContact(contact, contactType)}
            />
            <Checkbox
              data-testid="checkbox"
              isDisabled={isCheckboxDisabled(contactType)}
              isSelected={isCheckboxSelected(contactType)}
              onChange={(isSelected) => onCheckboxChange(contactType, isSelected)}
            >
              {intl.formatMessage({
                id: 'binky.common.editSecurityContactsModal.form.checkbox',
              })}
            </Checkbox>
          </Form>
        </TitledSection>
      ))}
    </View>
  );
};

SecurityContactsForm.propTypes = {
  /**
   * The list of contacts to be updated using the form.
   */
  contactList: PropTypes.instanceOf(ContactList).isRequired,
  /**
   * Whether the form is disabled or not. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The callback function triggered when the form is updated.
   * Includes the current contact list as a parameter.
   */
  onChange: PropTypes.func,
};

export default SecurityContactsForm;
