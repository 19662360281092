class CollaborationPoliciesConcurrencyError extends Error {
  /**
   * @class
   * @description Creates an error representing a concurrency issue when updating policies.
   *
   * @param {String} [message] - The message to report
   */
  constructor(message) {
    super(message);
    this.name = 'CollaborationPoliciesConcurrencyError';
  }
}

export default CollaborationPoliciesConcurrencyError;
