const CONTRACT_MIGRATION_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.contract.CONTRACT_MIGRATION_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/models/migration/MigrationConstants.js
   */
  angular
    .module('binky.core.contract.migrations')
    .constant('CONTRACT_MIGRATION_STATUS', CONTRACT_MIGRATION_CONSTANTS.CONTRACT_MIGRATION_STATUS)
    .constant('CONTRACT_MIGRATION_TYPE', CONTRACT_MIGRATION_CONSTANTS.CONTRACT_MIGRATION_TYPE);
})();
