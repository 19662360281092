(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ProductDescriptor
   * @description This class describes a product that is affected by an action.
   *   For example: a product is removed from cart
   */
  angular.module('binky.core.product').factory('ProductDescriptor', factory);

  function factory(binkySrc2) {
    return binkySrc2.services.product.ProductDescriptor;
  }
})();
