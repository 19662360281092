import cloneDeep from 'lodash/cloneDeep';

/**
 * @description Returns a promise that is resolved with a cloned value.
 *
 * @param {Object|Promise<Object>} params - the request parameters
 * @returns {Promise<Object>} a promise that is resolved with a cloned value.
 */
async function getClonedResolvedValuePromise(params) {
  try {
    const value = await Promise.resolve(params);
    return cloneDeep(value);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {getClonedResolvedValuePromise};
