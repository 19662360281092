(function () {
  /**
   * @ngdoc module
   * @name binky.core.api.jil
   * @description module that defines jil api resource services
   */
  angular.module('binky.core.api.jil', [
    'binky.core.api.utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
    'ngResource',
  ]);
})();
