(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.common.badge-popover:binkyBadgePopoverItem
   *
   * @description The badge popover item widget. This widget is used in
   *   conjunction with the badge popover widget to encapsulate styles
   *   associated with the popover carousel items. Since nested
   *   coral-popover-header and coral-popover-footer elements are not styled in
   *   the same manner as first-level elements of this type within a
   *   coral-popover, we need to recreate the visual look and feel in a manner
   *   that is easily applied to various in-app badge notifications.
   *
   * @param {Notice} notice - Notice model Object to render. More details on
   *   model class and class members (attributes) can be found in notice.service.js
   *
   * @example
   * <binky-badge-popover-item notice="$ctrl.notice"></binky-badge-popover-item>
   *
   */
  angular.module('binky.widgets.common.badge-popover').component('binkyBadgePopoverItem', {
    bindings: {
      notice: '<',
    },
    controller,
    templateUrl: 'widgets/common/badge-popover/badge-popover-item.component.html',
  });

  function controller($window, _, BadgeUtils) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onActionClick,
    });

    function $onInit() {
      _.assign(vm, {
        icon: BadgeUtils.getIconFromVariant(vm.notice.variant),
      });
    }

    function onActionClick($event) {
      if (vm.notice.action.onClick) {
        vm.notice.action.onClick();
      } else if (vm.notice.action.href) {
        $window.open(vm.notice.action.href);
      }
      $event.stopPropagation();
    }
  }
})();
