/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc service
 * @name binky.widgets.directories.status-helper
 * @description directory status helper factory
 */
(function () {
  'use strict';

  angular
    .module('binky.widgets.directories.status-helper')
    .factory('directoryStatusHelper', getDirectoryStatusHelper);

  /* @ngInject */
  function getDirectoryStatusHelper(
    _,
    DIRECTORY_OWNERSHIP_STATUS,
    DIRECTORY_STATUS,
    DIRECTORY_STATUS_LABEL
  ) {
    const lookupTable = [
      {
        labelKey: DIRECTORY_STATUS_LABEL.ALL_STATUSES,
        ownershipStatus: undefined,
        status: undefined,
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.ACTIVE,
        ownershipStatus: DIRECTORY_OWNERSHIP_STATUS.OWNED,
        status: DIRECTORY_STATUS.ACTIVE,
        variant: 'success',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.NEEDS_DOMAIN,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.NEEDS_DOMAIN,
        variant: 'warning',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.REQUIRES_ACTIVATION,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.REQUIRES_ACTIVATION,
        variant: 'warning',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.REQUIRES_APPROVAL,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.REQUIRES_APPROVAL,
        variant: 'warning',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.REQUIRES_CONFIGURATION,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.REQUIRES_CONFIGURATION,
        variant: 'warning',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.PENDING,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.PENDING,
        variant: 'warning',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.REJECTED,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.REJECTED,
        variant: 'error',
      },
      {
        labelKey: DIRECTORY_STATUS_LABEL.REVOKED,
        ownershipStatus: undefined,
        status: DIRECTORY_STATUS.REVOKED,
        variant: 'warning',
      },
      {
        // only used when lookup by labelKey
        labelKey: DIRECTORY_STATUS_LABEL.TRUSTED,
        ownershipStatus: DIRECTORY_OWNERSHIP_STATUS.TRUSTED,
        status: DIRECTORY_STATUS.ACTIVE,
      },
    ];

    const service = {
      getApiQueryParams,
      getLabelKey,
      getVariant,
    };

    return service;

    /////////////

    function getLabelKey(status, ownershipStatus) {
      const result = _.find(
        lookupTable,
        (item) =>
          item.status === status &&
          (_.isUndefined(item.ownershipStatus) || item.ownershipStatus === ownershipStatus)
      );

      return _.get(result, 'labelKey');
    }

    // Returns the directoryList refresh API params for the given DIRECTORY_STATUS_LABEL.
    function getApiQueryParams(statusLabelKey) {
      const result = _.find(lookupTable, (item) => _.result(item, 'labelKey') === statusLabelKey);
      return _.pick(result, ['ownershipStatus', 'status']);
    }

    function getVariant(status) {
      const result = _.find(lookupTable, ['status', status]);
      return _.get(result, 'variant', '');
    }
  }
})();
