(function () {
  /**
   * @deprecated not required in ES6 code
   */
  angular.module('binky.core.promise-utils').factory('promiseUtils', utils);

  /* @ngInject */
  function utils($q) {
    const service = {
      toAngularPromise,
      toEs6Promise,
    };

    return service;

    //////////////

    /**
     * @description Transform ES6 promise to Angular $q promise
     *
     * @param {Promise} es6Promise - es6Promise
     * @returns {Promise} promise  returns an Angular $q promise
     */
    function toAngularPromise(es6Promise) {
      if (es6Promise.then) {
        return $q((resolve, reject) => {
          es6Promise.then(resolve).catch(reject);
        });
      }
      return $q.resolve(es6Promise);
    }

    /**
     * @description Transform Angular $q promise to ES6 promise.
     *
     * @param {Promise} $qPromise - $q promise
     * @returns {Promise} promise  returns an ES6 promise
     */
    function toEs6Promise($qPromise) {
      if ($qPromise.then) {
        return new Promise((resolve, reject) => {
          $qPromise.then(resolve).catch(reject);
        });
      }
      return Promise.resolve($qPromise);
    }
  }
})();
