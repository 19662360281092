(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.directories.directory-domain-list-table
   * @description module that defines the directory domain list table
   */
  angular.module('binky.widgets.directories.directory-domain-list-table', [
    'binky.core.api.jil',
    'binky.core.common.selection',
    'binky.core.directories.domains',
    'binky.core.lodash',
    'binky.widgets.common.pagination-control-bar',
  ]);
})();
