/* eslint-disable react/no-unknown-property -- styleName not recognized */
import binky, {
  AuthenticatedUser,
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  authentication,
  feature,
} from '@admin-tribe/binky';
import {AuthProvider} from '@pandora/react-auth-provider';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  PICKER_TYPE,
  SEARCH_TYPE,
  UserPickerV3,
  UserPickerV3ContentModel,
} from '@pandora/react-user-picker';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {OPERATION} from 'common/components/add-user-form-table/MemberAndSelectedItemsList';
import {EVENT_TYPE} from 'common/components/add-user-form-table/useAddUserFormTable';
import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from 'common/components/add-user-form-table/utils/MemberAndSelectedItemsConstants';
import UserPicker from 'common/components/user-picker/UserPicker';

import styles from './AddUserForm.pcss';
import EditableFields from './editable-fields/AddUserFormEditableFields';
import ReadonlyFields from './readonly-fields/AddUserFormReadonlyFields';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-add-user-form
 */

const AvailableTypeList = binky.services.availableType.AvailableTypeList;
const OrganizationUser = binky.services.organization.OrganizationUser;

const {ERROR_STATUS, LOADING_STATUS} = MEMBER_AND_SELECTED_ITEMS_CONSTANTS;

/**
 * Represents a single user-entry form during a add-user workflow.
 */
const AddUserForm = ({
  defaultValue,
  id,
  inputMessage,
  onFormChange,
  orgId,
  pickerType,
  searchType,
  targetOptions,
}) => {
  const getAccessToken = () => authentication.getAccessToken?.()?.token;
  const getUserProfile = () => AuthenticatedUser.get().profile;
  const content = useContentEntry(UserPickerV3ContentModel);
  const intl = useIntl();
  const [member, setMember] = React.useState(
    binky.services.feature.isEnabled('bug_fix_13949') ? undefined : defaultValue
  );
  const [availableTypeList, setAvailableTypeList] = React.useState();
  // binky-ui UserPicker has an EXISTING_USER searchType, which is equivalent to the Pandora EXISTING_OR_NEW_USER
  // so convert that string in the case the flag is enabled
  const convertedSearchType =
    feature.isEnabled('temp_new_user_picker') && searchType === UserPicker.SEARCH_TYPE.EXISTING_USER
      ? SEARCH_TYPE.EXISTING_OR_NEW_USER
      : searchType;

  const canViewReadonlyFields = React.useMemo(
    () => member && member.getType().isUser() && !member.isNew(),
    [member]
  );

  const canViewEditableFields = React.useMemo(
    () =>
      !canViewReadonlyFields &&
      member?.isNew() &&
      member?.getType().isUser() &&
      (defaultValue ||
        (availableTypeList &&
          !availableTypeList.hasInvalidEmail &&
          availableTypeList.availableAndAllowedTypes.length > 0)),
    [availableTypeList, canViewReadonlyFields, defaultValue, member]
  );

  const onSelect = (selectedMember, _availableTypeList) => {
    const getOrDefaultMemberType = () => {
      if (
        selectedMember &&
        !selectedMember.type &&
        !selectedMember.getType().isUserGroup() &&
        selectedMember.isNew() &&
        _availableTypeList
      ) {
        const firstAvailableAndAllowedType =
          _availableTypeList.availableOnlyTypesInPriorityOrder.find(
            // Default missing 'allowed' property to 'true' because the property won't be available
            // until https://jira.corp.adobe.com/browse/ONESIE-16297
            (item) => item.allowed !== false && !item.existingUser
          );

        return firstAvailableAndAllowedType?.userType;
      }

      return selectedMember?.type;
    };

    const newMember = selectedMember
      ? new OrganizationUser({
          ...selectedMember,
          orgId,
          type: getOrDefaultMemberType(),
        })
      : undefined;
    if (feature.isEnabled('temp_new_user_picker')) {
      // add member after it's being reset in onInputChange
      setTimeout(() => setMember(newMember), 100);
    } else {
      setMember(newMember);
    }

    setAvailableTypeList(_availableTypeList);
  };

  const onInputChange = (queryText) => {
    // Clears all existing form data whenever the email value changes.
    // Continually setting the orgUser to undefined will not trigger the useEffect, as it remains the same value.
    setMember(undefined);
    onFormChange({
      type: EVENT_TYPE.CHANGE_QUERY_TEXT,
      value: queryText,
    });
  };

  const onEditableFieldsChange = (field, value) => {
    Object.assign(member, {
      [field]: value,
      orgId,
    });

    // Addresses: https://git.corp.adobe.com/admin-tribe/onesie/issues/13822
    // When a new type is selected, creating a new member here will force React state to update.
    // This refreshes the AddUserFormSection and removes selected products and user groups.
    if (field === 'type') {
      setMember((prevMember) => new OrganizationUser({...prevMember, [field]: value, orgId}));
    }
  };

  const setDefaultUser = async () => {
    const tempAvailableTypeList = await AvailableTypeList.get({
      email: defaultValue.email,
      orgId,
    });

    onSelect(defaultValue, tempAvailableTypeList);
  };

  React.useEffect(() => {
    onFormChange({member, type: EVENT_TYPE.CHANGE_MEMBER});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Triggers onChange only when addUserForm is updated.
  }, [member]);

  React.useEffect(() => {
    if (binky.services.feature.isEnabled('bug_fix_13949')) {
      if (defaultValue) {
        // re-fetch availableTypes when a defaultValue is passed
        setDefaultUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update for this
  }, [orgId]);

  const userPickerLabel = () =>
    pickerType === PICKER_TYPE.USERS_AND_GROUPS
      ? intl.formatMessage({id: 'binky.common.addUserForm.fieldLabel.emailOrGroup'})
      : intl.formatMessage({id: 'binky.common.addUserForm.fieldLabel.email'});

  const onStatusFormChange = (status) => {
    onFormChange({
      op: UserPicker.STATUS.LOADING === status ? OPERATION.ADD : OPERATION.REMOVE,
      type: EVENT_TYPE.CHANGE_LOADING_STATUS,
      value: LOADING_STATUS.USER_PICKER_LOADING,
    });
  };

  const onValidationFormChange = (status) => {
    onFormChange({
      op: UserPicker.VALIDATION_STATUS.CLEAR === status ? OPERATION.REMOVE : OPERATION.ADD,
      type: EVENT_TYPE.CHANGE_ERROR_STATUS,
      value: ERROR_STATUS.USER_PICKER_ERROR,
    });
  };

  return (
    <div data-testid="add-user-form" styleName="user-profile-selection">
      {feature.isEnabled('temp_new_user_picker') ? (
        <AuthProvider
          value={{
            getAccessToken,
            getUserProfile,
          }}
        >
          <UserPickerV3
            content={content}
            defaultValue={defaultValue?.email}
            id={`textfield-email-${id}`}
            label={userPickerLabel()}
            marginEnd="size-200"
            message={inputMessage}
            onInputChange={onInputChange}
            onSelect={onSelect}
            onStatusChange={onStatusFormChange}
            onValidationStatusChange={onValidationFormChange}
            orgId={orgId}
            pickerType={pickerType}
            searchType={convertedSearchType}
            targetOptions={targetOptions}
            width="size-4600"
          />
        </AuthProvider>
      ) : (
        <UserPicker
          className={styles['email-selector']}
          defaultValue={defaultValue && defaultValue.email}
          id={`textfield-email-${id}`}
          isAllowingUserGroups={false}
          label={userPickerLabel()}
          message={inputMessage}
          onInputChange={onInputChange}
          onSelect={onSelect}
          onStatusChange={onStatusFormChange}
          onValidationStatusChange={onValidationFormChange}
          orgId={orgId}
          pickerType={pickerType}
          searchType={searchType}
          targetOptions={targetOptions}
        />
      )}

      {canViewEditableFields && (
        <EditableFields
          availableTypeList={availableTypeList}
          id={id}
          onFormChange={onEditableFieldsChange}
          orgId={orgId}
          orgUser={member}
        />
      )}
      {canViewReadonlyFields && <ReadonlyFields orgUser={member} />}
    </div>
  );
};

AddUserForm.propTypes = {
  /**
   * The User to fill the form with on initialization, if provided
   */
  defaultValue: PropTypes.instanceOf(OrganizationUser),
  /**
   * The unique row id of this form within the AddUserFormTable
   */
  id: PropTypes.string.isRequired,
  /**
   * UserPicker message-object to display
   */
  inputMessage: PropTypes.shape({value: PropTypes.string, variant: PropTypes.string}),
  /**
   * Callback function to notify on form change.
   * This callback function is triggered with 2 params. The first param is the fieldId, and the second param is the new
   * value of that field.
   */
  onFormChange: PropTypes.func.isRequired,
  /**
   * The ID of the organization
   */
  orgId: PropTypes.string.isRequired,
  /**
   * Defines what entity types should be shown in the UserPicker search.
   */
  pickerType: PropTypes.oneOf(Object.values(PICKER_TYPE)),
  /**
   * Defines whether existing or new entities should be shown in the UserPicker search.
   */
  searchType: PropTypes.oneOf(Object.values(UserPicker.SEARCH_TYPE)).isRequired,
  /**
   * UserPicker filtering target settings.
   */
  targetOptions: PropTypes.shape({
    searchTargetType: PropTypes.oneOf(Object.values(SEARCH_TARGET_RESULT_TYPE)),
    targetClass: PropTypes.oneOf(Object.values(SEARCH_TARGET_CLASS)),
    targetId: PropTypes.string,
    targetParentId: PropTypes.string,
  }),
};

export default AddUserForm;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
