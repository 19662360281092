/* eslint-disable max-lines -- over line limit */
import axios from 'axios';
import omitBy from 'lodash/omitBy';

import {csvBlobTransformer} from 'api/utils/apiUtils';
import {keysToSnakeCase} from 'utils/objectUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL products APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Products api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Gets CSV for deactivated devices
 *
 * @param {String} orgId - The org id, {String} orgId - The organisation id
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportDeactivatedDevices = async ({orgId}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/devices`, {
    headers: {
      Accept: 'text/csv+device,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params: {
      deactivatedOnly: true,
    },
  });
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV for license deficit report
 *
 * @param {String} orgId - The org id
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportLicenseDeficitReport = async ({orgId}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/products`, {
    headers: {
      Accept: 'text/csv+license-deficit-report,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params: {
      include_created_date: true,
      include_groups_quantity: false,
    },
  });
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV for the license group users.
 *
 * @param {String} options.groupId - The license group id
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const exportLicenseGroupUsers = async ({orgId, groupId, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${groupId}/users`,
    {
      headers: {
        Accept: 'text/csv+license-group-users,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
    }
  );
  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV of users for a product owned by an organization filtered by the provisioning status of the license
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const exportProductPaymentStatusUsers = async ({orgId, productId, ...queryParams}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/users%3Asearch.payment-status-csv`,
    {
      headers: {
        ...getHeaders({clientId, includeRoles}),
        Accept: 'text/csv+product-user-payment-status-report,application/json',
      },
      params: queryParams,
    }
  );

  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV file of users who do not have access to a product
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise<Object>} - Resolves to object containing blob representation of csv
 */
const exportProductProvisioningStatusUsers = async ({orgId, productId, ...queryParams}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/users%3Asearch.no-access-status`,
    {
      headers: {
        ...getHeaders({clientId, includeRoles}),
        Accept: 'text/csv+product-user-no-access-report,application/json',
      },
      params: queryParams,
    }
  );

  return csvBlobTransformer(response);
};
/**
 * @description Gets CSV of products that an organization posesses.
 *
 * @param {Object} options - Top level wrapper object.
 * @param {String} options.orgId - Org id of products that should be exported.
 * @returns {Promise} Resolves to the axios response object that will then be
 *     transformed by the csvBlobTransformer.
 */
const exportProducts = async ({orgId}) => {
  const response = await axios.get(`${url}/v2/organizations/${orgId}/products`, {
    headers: {
      ...getHeaders({clientId, includeRoles}),
      Accept: 'text/csv+product,application/json',
    },
  });

  return csvBlobTransformer(response);
};

/**
 * @description Gets CSV for active devices
 *
 * @param {String} orgId - The org id, {String} orgId - The organisation id
 * @param {String} productId - The org id, {String} productId - The product id
 * @return {Promise} Promise which resolves with the CSV response converted to a blob.
 */
const getDevicesForExport = async ({orgId, productId}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/devices`,
    {
      headers: {
        Accept: 'text/csv+product-devices,application/json',
        ...getHeaders({clientId, includeRoles}),
      },
      params: {
        include_created_date: true,
        include_groups_quantity: false,
      },
    }
  );
  return csvBlobTransformer(response);
};

/**
 * @description Fetches the license group admins
 *
 * @param {Object} options - The params
 * @param {String} options.groupId - The license group id
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const getLicenseGroupAdmins = ({groupId, orgId, productId, ...queryParams}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${groupId}/admins`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Fetches the product admins
 *
 * @param {Object} options - The params
 * @param {String} [filterExcludeDomain] - domain to exclude from results
 * @param {String} [filterIncludeDomain] - domain to include in results
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const getProductAdmins = ({
  filterExcludeDomain,
  filterIncludeDomain,
  orgId,
  productId,
  ...params
}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/products/${productId}/admins`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: {...keysToSnakeCase({filterExcludeDomain, filterIncludeDomain}), ...params},
  });
};

/**
 * @description Returns the users for a product owned by an organization filtered by the license quality status
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.params - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const getProductLicenseQualityUsers = async ({orgId, productId, ...params}) => {
  const response = await axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/users%3Asearch.license-quality`,
    {
      headers: getHeaders({clientId, includeRoles}),
      params,
    }
  );
  // Adding custom transformer because 'type' is needed for user, and
  // and it is currently not getting returned
  const transformedResponse = response.data.map((item) => {
    const newItem = {...item};
    if (!newItem.type) {
      newItem.type = 'TYPE1';
    }
    return newItem;
  });

  return {...response, data: transformedResponse};
};

/**
 * @description Fetches users who do not have access to the product because they have incompatible ID types
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const getProductNoAccessUsers = ({orgId, productId, ...queryParams}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/products/${productId}/users%3Asearch.no-access-code`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Gets list of users for the given provisioning status
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {...String} ...options.queryParams - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object
 */
const getProductProvisioningStatusUsers = ({orgId, productId, ...params}) =>
  axios.get(
    `${url}/v2/organizations/${orgId}/products/${productId}/users%3Asearch.provisioning-status`,
    {
      headers: getHeaders({clientId, includeRoles}),
      params,
    }
  );

/**
 * Fetches all products or a single product.
 *
 * @param {String} [acceptLanguage] - The language to fetch the product list in. Defaults to English.
 * @param {String} [contractId] - The contract id. If not defined, gets products from all contracts in org.
 * @param {Boolean} [includeCancellationData] - whether to include cancellation information for the products or not. Defaults to false.
 * @param {Boolean} [includeExpired] - Whether or not to include expired licenses. Defaults to true.
 * @param {Boolean} [includeCreatedDate] - Whether or not to include creation date. Defaults to true.
 * @param {Boolean} [includeGroupsQuantity] - Whether or not to include groups quantity. Defaults to false.
 * @param {Boolean} [includeInactive] - Whether or not to include inactive licenses. Defaults to false.
 * @param {Boolean} [includeLicenseActivations] - Whether or not to include license activations. Defaults to true.
 * @param {Boolean} [includeLicenseAllocationInfo] - Whether or not to include license allocation info. Defaults to false.
 * @param {Boolean} [includePricingData] - whether to include pricing information for the products or not. Default to false.
 * @param {Number} [licenseGroupLimit] - The maximum license groups to include in the respnose. Defaults to 0.
 * @param {String} orgId - The organization id.
 * @param {String} [processingInstructionCodes] - Processing instructions codes to request.
 * @param {String} [productId] - The id of the individual product to get. If not defined, gets all products.
 * @returns {Promise} Resolves to the axios response object whose data contains an array of Products or a single Product.
 */
// eslint-disable-next-line complexity -- needs to be rewritten
const getProducts = async ({
  acceptLanguage,
  contractId,
  includeAcquiredOfferIds,
  includeConfiguredProductArrangementId,
  includeCancellationData,
  includeCreatedDate,
  includeExpired,
  includeGroupsQuantity,
  includeInactive,
  includeLicenseActivations,
  includeLicenseAllocationInfo,
  includePricingData,
  licenseGroupLimit,
  orgId,
  processingInstructionCodes,
  productId,
  includeLegacyLSFields = false,
}) => {
  const headers = getHeaders({clientId, includeRoles});
  if (acceptLanguage) {
    headers['Accept-Language'] = acceptLanguage;
  }
  let params = {
    contract_id: contractId,
    include_cancellation_data: includeCancellationData, // added for temp_self_cancel
    include_created_date: includeCreatedDate,
    include_expired: includeExpired,
    include_groups_quantity: includeGroupsQuantity,
    include_inactive: includeInactive,
    include_license_activations: includeLicenseActivations,
    include_license_allocation_info: includeLicenseAllocationInfo,
    include_pricing_data: includePricingData,
    includeAcquiredOfferIds,
    includeConfiguredProductArrangementId,
    includeLegacyLSFields,
    license_group_limit: licenseGroupLimit,
    processing_instruction_codes: processingInstructionCodes?.join(','),
  };
  if (params.include_created_date === undefined) params.include_created_date = true;
  if (params.include_expired === undefined) params.include_expired = true;
  if (params.include_groups_quantity === undefined) params.include_groups_quantity = false;
  if (params.include_inactive === undefined) params.include_inactive = false;
  if (params.include_license_activations === undefined) params.include_license_activations = true;
  if (params.include_license_allocation_info === undefined)
    params.include_license_allocation_info = false;
  if (params.includeAcquiredOfferIds === undefined) params.includeAcquiredOfferIds = false;
  if (params.includeConfiguredProductArrangementId === undefined)
    params.includeConfiguredProductArrangementId = false;

  params = omitBy(params, (value) => value === undefined);

  const response = await axios.get(`${url}/v2/organizations/${orgId}/products/${productId || ''}`, {
    headers,
    params,
  });
  return response;
};

/**
 * @description Get list of users for a product licensed by an org
 *
 * @param {String} orgId - Org ID
 * @param {String} productId - Product ID
 * @param {String} [filterExcludeDomain] - domain to exclude from results
 * @param {String} [filterIncludeDomain] - domain to include in results
 * @param {Number} [page] - page number of cached model to fetch
 * @param {Number} [page_size] - number of users to display per page
 * @param {String} [search_query] - search query
 * @param {String} [sort] - sorting criteria
 * @param {String} [sort_order] - sort order
 * @returns {Promise} Resolves to list of users
 */
const getProductUsers = ({
  filterExcludeDomain,
  filterIncludeDomain,
  include,
  orgId,
  productId,
  ...params
}) => {
  const snakeCaseParams = omitBy(
    {
      filter_exclude_domain: filterExcludeDomain,
      filter_include_domain: filterIncludeDomain,
      include: include?.join(','),
    },
    (value) => value === undefined
  );
  return axios.get(`${url}/v2/organizations/${orgId}/products/${productId}/users`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {...snakeCaseParams, ...params},
  });
};

/**
 * @description Applies operations to license groups admins.
 *
 * @param {Object} options - The params
 * @param {String} options.groupId - The license group id
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {Object} options.operations - The operations to perform.
 * @param {...String} ...options.params - Any additional JIL query params
 * @returns {Promise} Resolves to the axios response object.
 */
const patchLicenseGroupAdmins = ({groupId, orgId, productId, operations, ...params}) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/products/${productId}/license-groups/${groupId}/admins`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
      params,
    }
  );

/**
 * @description Performs patch operations for product admins
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.productId - Product ID
 * @param {Object} options.operations - Patch operations to perform
 * @returns {Promise} Resolves to the axios response object.
 */
const patchProductAdmins = ({orgId, productId, operations}) => {
  const requestUrl = `${url}/v2/organizations/${orgId}/products/${productId}/admins`;
  return axios.patch(requestUrl, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Performs patch operations for products.
 *
 * @param {FULFILLMENT_TEST_TYPE} [fulfillmentTestType] - The fulfillment test type, used for no-op testing where no resources need to be provisioned.
 * @param {String} orgId - The org id
 * @param {Array<Object>} operations - Patch operations to perform
 * @returns {Promise} Resolves to the axios response object whose data contains the results of the patch
 */
const patchProducts = async ({fulfillmentTestType, orgId, operations}) => {
  const params = omitBy(
    {
      fulfillment_test_type: fulfillmentTestType,
    },
    (value) => value === undefined
  );
  const response = await axios.patch(`${url}/v2/organizations/${orgId}/products`, operations, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
  return response;
};

const jilProducts = {
  configure,
  exportDeactivatedDevices,
  exportLicenseDeficitReport,
  exportLicenseGroupUsers,
  exportProductPaymentStatusUsers,
  exportProductProvisioningStatusUsers,
  exportProducts,
  getDevicesForExport,
  getLicenseGroupAdmins,
  getProductAdmins,
  getProductLicenseQualityUsers,
  getProductNoAccessUsers,
  getProductProvisioningStatusUsers,
  getProducts,
  getProductUsers,
  patchLicenseGroupAdmins,
  patchProductAdmins,
  patchProducts,
};

export default jilProducts;
/* eslint-enable max-lines -- over line limit */
