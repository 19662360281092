/* eslint-disable complexity -- Product validation is complicated */

import {
  FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED,
  FULFILLABLE_ITEM_CODE,
} from 'models/fulfillableItemList/FulfillableItemConstants';
import {
  MAX_FI_GRANT_QUANTITY,
  MAX_LICENSE_GRANT_QUANTITY,
} from 'models/product/grant-quantity/GrantQuantityConstants';
import {REI_ITEM_TYPE} from 'models/product/requestor-external-info/RequestorExternalInfoConstants';

/**
 * @description Method for checking if a product's submittable properties are valid.
 *
 * @param {Product} product - Product that will be checked for validity.
 * @param {Object[]} [productLicenseSelectionOptions=[]] - License selection
 *     options that will be used to confirm a valid selection was picked.
 *     Defaults to empty array.
 * @returns {Boolean} True if the submittable properties are valid, false
 *     otherwise.
 */
function isProductValid(product, productLicenseSelectionOptions = []) {
  if (!product) {
    return false;
  }

  // check a license quantity is specified, if required
  if (usesLicenseQuantity(product)) {
    const quantity = Number.parseInt(
      product.licenseAllocationInfo.licenseResourceList.getLicenseGrantQuantity(),
      10
    );
    if (Number.isNaN(quantity) || quantity < 1 || quantity > MAX_LICENSE_GRANT_QUANTITY) {
      return false;
    }
  }
  if (!validateQuotaItems(product)) return false;

  // check the requestorExternalInfo for the fulfillable items
  if (product.isNew() && product.fulfillableItemList.items.length > 0) {
    if (
      !product.fulfillableItemList.items.every(
        (fItem) =>
          !fItem.selected || fItem.requestorExternalInfo.every(isRequestorExternalInfoItemValid)
      )
    ) {
      return false;
    }
  }

  if (productLicenseSelectionOptions.length > 0) {
    if (
      !validateLicenseSelections(product.acceptedResourceLocators, productLicenseSelectionOptions)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * @description Method for checking if a product's submittable properties are valid for offer switch.
 *
 * @param {Object} selectedOfferToSwitch - Selected offer be checked for validity.
 * @returns {Boolean} True if the submittable properties are valid, false
 *     otherwise.
 */
function isProductValidForOfferSwitch(selectedOfferToSwitch) {
  if (selectedOfferToSwitch) {
    return true;
  }
  return false;
}

/**
 * @description Helper method for checking if an REI item is valid.
 *
 * @param {Object} reiItem - Individual requestor external info
 * @returns {Boolean} True if its valid, false otherwise.
 */
function isRequestorExternalInfoItemValid(reiItem) {
  switch (reiItem.type) {
    case REI_ITEM_TYPE.BOOLEAN:
      return typeof reiItem.value === 'boolean';
    case REI_ITEM_TYPE.INTEGER:
      return Number.isInteger(reiItem.value);
    case REI_ITEM_TYPE.STRING: {
      if (reiItem.supportedValues?.length > 0) {
        return isValidWithSupportedValues(reiItem);
      }
      return isNotRequiredOrNonEmptyString(reiItem);
    }
    default:
      return false;
  }
}

///////////

function isNotEmptyAndContainsAValidValue(reiItem) {
  return (
    isValueAStringAndIsNotEmpty(reiItem) &&
    (reiItem.unrestricted ||
      reiItem.supportedValues.some((supportedValue) => supportedValue.value === reiItem.value))
  );
}

function isNotRequiredOrNonEmptyString(reiItem) {
  return !reiItem.required || isValueAStringAndIsNotEmpty(reiItem);
}

function isValueAStringAndIsNotEmpty(reiItem) {
  return typeof reiItem.value === 'string' && reiItem.value.length > 0;
}

function isValidWithSupportedValues(reiItem) {
  return (
    (!reiItem.required &&
      (reiItem.value === undefined ||
        reiItem.value === null ||
        reiItem.value.length === 0 ||
        isNotEmptyAndContainsAValidValue(reiItem))) ||
    (reiItem.required && isNotEmptyAndContainsAValidValue(reiItem))
  );
}

function usesLicenseQuantity(product) {
  return !product.hasUnlimitedLicenses();
}

function validateLicenseSelections(acceptedResourceLocators, productLicenseSelectionOptions) {
  const acceptedResourceLocatorOptions = productLicenseSelectionOptions.map(
    (option) => option.acceptedResourceLocator
  );
  return (
    acceptedResourceLocators !== undefined &&
    acceptedResourceLocators !== null &&
    acceptedResourceLocators.length > 0 &&
    acceptedResourceLocators.every((acceptedResourceLocator) =>
      acceptedResourceLocatorOptions.includes(acceptedResourceLocator)
    )
  );
}

function validateQuotaItems(product) {
  // if the product uses quotas, check at least one is specified as above 0
  // we exclude CC Storage, as it's valid for that to be 0
  const fItems = product.fulfillableItemList
    .getQuotaTypeItems(false)
    .filter((item) => item.code !== FULFILLABLE_ITEM_CODE.CC_STORAGE);

  if (fItems.length > 0) {
    // return false if there is any fulfillmentConfigurable FI that exceeds the MAX_FI_GRANT_QUANTITY.
    if (
      fItems.some((fItem) => {
        const fItemCap =
          product.licenseAllocationInfo.licenseResourceList.getFulfillableItemGrantQuantity(
            fItem.code
          );
        return (
          fItem.fulfillmentConfigurable &&
          fItemCap !== FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED &&
          fItemCap > MAX_FI_GRANT_QUANTITY
        );
      })
    ) {
      return false;
    }
    return fItems.some((fItem) => {
      const fItemCap =
        product.licenseAllocationInfo.licenseResourceList.getFulfillableItemGrantQuantity(
          fItem.code
        );
      return (
        !fItem.fulfillmentConfigurable ||
        fItemCap === FULFILLABLE_ITEM_CHARGING_MODEL_CAP_UNLIMITED ||
        fItemCap > 0
      );
    });
  }
  // no quota items means this doesn't impact validity
  return true;
}

export {isProductValid, isProductValidForOfferSwitch, isRequestorExternalInfoItemValid};
/* eslint-enable complexity -- Product validation is complicated */
