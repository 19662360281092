(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.organizations.organizations-switcher
   * @description module that defines organizations widgets
   */
  angular.module('binky.widgets.organizations.organizations-switcher', [
    'binky.core.common.member.type',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.widgets.common.feature',
    'binky.widgets.common.member.member-type',
    'binky.widgets.common.search',
    'binky.widgets.common.select-list',
    'binky.widgets.common.wait',
  ]);
})();
