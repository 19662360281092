(function () {
  /**
   * @deprecated Please use src2/core/services/feature.js
   *
   * @ngdoc provider
   * @name binky.core.feature
   * @description
   * Features can be enabled or disabled via the URL query params allFlags or flags, via the configure
   * parameter or via the IMS Floodgate service. The omni tool panel, which can be accessed via the
   * '~' key on the One Console, will show all feature states and can also be used to turn params
   * on or off.
   *
   * If the allFlags query param is specified, it is used as the lone definition of which feature
   * flags to enable. This means that all flags will be overridden to be false, unless explicity
   * set to true in the allFlags parameter. Otherwise flags specified with the query param take
   * precedence, followed by Floodgate and the config file.
   *
   * The value of the flags and allFlags query params is a comma separated string of the form
   *   &flags=flagName[=boolean][,flagName[=boolean]]*
   * For example '&flags=flag1,flag2=true,flag3=false'
   */
  angular.module('binky.core.feature').provider('feature', featureProvider);

  /* @ngInject */
  function featureProvider(binkySrc2) {
    const feature = binkySrc2.services.feature;
    this.configure = configure;
    this.registerHandler = registerHandler;
    this.$get = $get;

    function configure(configFeatures, backupFloodgateReleases, featureNames) {
      feature.setup(configFeatures, backupFloodgateReleases, featureNames);
    }

    function registerHandler(handler) {
      feature.registerHandler(handler);
    }

    /* @ngInject */
    function $get($q) {
      const deferred = $q.defer();
      const service = {
        $promise: deferred.promise,
        getAllReleases: feature.getAllReleases,
        getFeatures: feature.getFeatures,
        getNonOverrideSource: feature.getNonOverrideSource,
        getNonOverrideValue: feature.getNonOverrideValue,
        getOverrideValue: feature.getOverrideValue,
        getReleaseVariantId: feature.getReleaseVariantId,
        getSource: feature.getSource,
        getVariantId: feature.getVariantId,
        hasChange: feature.hasChange,
        isDisabled: feature.isDisabled,
        isEnabled: feature.isEnabled,
        isOverridden: feature.isOverridden,
        prepareOverride: feature.prepareOverride,
        refresh: feature.refresh,
        registerHandler,
        resetOverrides: feature.resetOverrides,
        saveAndReload: feature.saveAndReload,
        unprepareOverride: feature.unprepareOverride,
      };
      return service;
    }
  }
})();
