import {Checkbox, CheckboxGroup, TextArea} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';

import {requiredLabelPropsCheck} from 'common/utils/prop-type/propTypeUtils';

/**
 * Displays a list of cancellation reasons with a comment box that appears when an option is selected.
 */
const SurveyReasons = ({
  'aria-labelledby': ariaLabelledBy,
  comment,
  commentLabel,
  commentMaxLength = 255,
  commentWidth,
  isEmphasized = false,
  label,
  onCommentChange,
  onSelectedReasonsChange,
  placeholder,
  reasons,
  selectedReasons = [],
}) => {
  const commentBoxId = useId('comment-box');
  return (
    <>
      <CheckboxGroup
        aria-controls={commentBoxId}
        aria-labelledby={ariaLabelledBy}
        data-testid="reasons"
        isEmphasized={isEmphasized}
        label={label}
        onChange={onSelectedReasonsChange}
        value={selectedReasons}
      >
        {reasons.map((reason) => (
          <Checkbox key={reason.key} value={reason.key}>
            {reason.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
      {selectedReasons.length > 0 && (
        <TextArea
          data-testid="comment-box"
          id={commentBoxId}
          label={commentLabel}
          maxLength={commentMaxLength}
          onChange={onCommentChange}
          placeholder={placeholder}
          value={comment}
          width={commentWidth}
        />
      )}
    </>
  );
};

SurveyReasons.propTypes = {
  /**
   * Id of the label for the checkbox group
   */
  'aria-labelledby': requiredLabelPropsCheck,
  /**
   * Detailed elaboration of their choice
   */
  comment: PropTypes.string,
  /**
   * Label for freeform comment
   */
  commentLabel: PropTypes.string.isRequired,
  /**
   * Max length of comment
   */
  commentMaxLength: PropTypes.number,
  /**
   * Width of the TextArea, uses React Spectrum sizing: e.g. size-0
   * See https://react-spectrum.adobe.com/react-spectrum/styling.html#dimension-values
   */
  commentWidth: PropTypes.string,
  /**
   * Highlights checkboxes in blue when selected
   * See https://spectrum.adobe.com/page/checkbox/#Emphasis
   */
  isEmphasized: PropTypes.bool,
  /**
   * Label for the checkbox group
   */
  label: requiredLabelPropsCheck,
  /**
   * Handler for whenever the comment is updated
   */
  onCommentChange: PropTypes.func,
  /**
   * Handler for when the list of reasons is updated
   */
  onSelectedReasonsChange: PropTypes.func.isRequired,
  /**
   * Placeholder text for the comment text entry
   */
  placeholder: PropTypes.string,
  /**
   * List of reasons with checkboxes.
   */
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Selected reasons from the survey
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
};

export default SurveyReasons;
