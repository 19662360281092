import {CONTACT_TYPE, ContactList} from '@admin-tribe/binky';
import {Text, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ContactCard from '../contact-card/ContactCard';

const {DATA_ACCESS_ADMINISTRATOR, DATA_PROTECTION_OFFICER, SECURITY_OFFICER} = CONTACT_TYPE;

/**
 * The security contacts section displaying the contact info for security and data officers.
 */
const SecurityContactsSection = observer(({contactList, level = 2}) => {
  const intl = useIntl();

  const getSectionDescription = (contactType) =>
    intl.formatMessage({
      id: `binky.common.securityContacts.${contactType}Section.description`,
    });

  const getSectionTitle = (contactType) =>
    intl.formatMessage({
      id: `binky.common.securityContacts.${contactType}Section.title`,
    });
  const contactTypes = [DATA_ACCESS_ADMINISTRATOR, DATA_PROTECTION_OFFICER, SECURITY_OFFICER];

  return (
    <View data-testid="security-contacts-section">
      {contactTypes.map((contactType) => (
        <TitledSection
          key={contactType}
          data-testid={`${contactType}-section`}
          dividerPlacement={DividerPlacement.NONE}
          headingLevel={level}
          id={`${contactType}-title`}
          marginBottom="size-200"
          title={getSectionTitle(contactType)}
        >
          <View marginBottom="size-200">
            <Text data-testid="description" id={`${contactType}-description`}>
              {getSectionDescription(contactType)}
            </Text>
          </View>
          <ContactCard
            contact={contactList[contactType]}
            descriptionId={`${contactType}-description`}
            labelId={`${contactType}-title`}
          />
        </TitledSection>
      ))}
    </View>
  );
});

SecurityContactsSection.propTypes = {
  /**
   * The list of contacts containing the contact info for security and data officers.
   */
  contactList: PropTypes.instanceOf(ContactList).isRequired,
  /**
   * The header level for the titled section. The default is 3.
   */
  level: PropTypes.number,
};

export default SecurityContactsSection;
