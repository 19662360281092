(function () {
  /**
   * @deprecated Please use src2/core/models/user/User.js
   *
   * @ngdoc factory
   * @name User
   * @description Model for an individual user
   */
  angular.module('binky.core.user').factory('User', user);

  /* @ngInject */
  function user(binkySrc2) {
    return binkySrc2.models.user.User;
  }
})();
