import {feature} from '@admin-tribe/binky';

import {getGoUrl} from 'common/utils/url/goUrlUtils';
/**
 * @deprecated Intended to be replaced by getOrganizationUserErrorProps.
 * @description Gets a localized error message to display to the user. This uses the last error
 *   from the list, in the event an array is returned.
 *
 * @param {Object|Array} error The error object, or array of errors for multi-status responses, received from axios
 * @returns {String} Localized string for the last error that can be displayed to a user (in an error toast for example)
 */
function getOrganizationUserErrorMessage(intl, error) {
  const errors = !error || Array.isArray(error) ? error : [error];
  let result;
  const requestId = errors?.[0].headers?.['x-request-id'] ?? null;
  result = intl.formatMessage(
    {
      id: 'binky.organization.organizationUser.organizationUserDisplayUtils.genericWithRequestId',
    },
    {requestId}
  );

  errors?.forEach((e) => {
    switch (e?.response?.errorCode) {
      case 'DOMAIN_ENFORCEMENT_VIOLATION':
        result = intl.formatMessage({
          id: 'binky.organization.organizationUser.organizationUserDisplayUtils.domainEnforcementViolation',
        });
        break;
      case 'EXTERNALLY_MANAGED_USER':
        result = intl.formatMessage({
          id: 'binky.organization.organizationUser.organizationUserDisplayUtils.externallyManagedUser',
        });
        break;
      case 'INVALID_FIRST_NAME':
        result = intl.formatMessage({
          id: 'binky.organization.organizationUser.organizationUserDisplayUtils.invalidFirstName',
        });
        break;
      case 'INVALID_LAST_NAME':
        result = intl.formatMessage({
          id: 'binky.organization.organizationUser.organizationUserDisplayUtils.invalidLastName',
        });
        break;
      default:
    }
  });

  return result;
}

/**
 * @description Gets error message and toast-props to display to the user.
 *   This uses the last error from the list, in the event an array is returned.
 *
 * @param {intl} intl - Intl object for localizing strings
 * @param {Object|Array} error The error object, or array of errors for multi-status responses, received from axios
 * @param {Object} [options] Callback options wrapper
 * @param {Function} [options.onTrialAlreadyConsumed] - Callback to invoke when
 *     the toasts action button is pressed when a trial already consumed error
 *     is encountered.
 * @returns {Object} Localized toast-props for the last error that can be displayed to a user
 */
function getOrganizationUserErrorProps(intl, error, {onTrialAlreadyConsumed} = {}) {
  const errors = !error || Array.isArray(error) ? error : [error];
  let result;
  const requestId = errors?.[0].headers?.['x-request-id'] ?? null;
  result = {
    message: intl.formatMessage(
      {
        id: 'binky.organization.organizationUser.organizationUserDisplayUtils.genericWithRequestId',
      },
      {requestId}
    ),
  };

  const trialAlreadyConsumedErrors = errors?.filter(
    (e) => getErrorCode(e) === 'TRIAL_ALREADY_CONSUMED'
  );
  if (feature.isEnabled('temp_check_ads_for_abuse') && trialAlreadyConsumedErrors?.length > 0) {
    const emailsThatWereBlocked = trialAlreadyConsumedErrors.map((e) => e?.request?.email);

    // remove any undefined emails so we can display only valuid emails
    const filteredEmails = emailsThatWereBlocked.filter((email) => email);

    const actionLabel = intl.formatMessage(
      {
        id: 'binky.organization.organizationUser.organizationUserDisplayUtils.trialAlreadyConsumedActionLabel',
      },
      {count: emailsThatWereBlocked.length}
    );
    const messageLabel = intl.formatMessage(
      {
        id: 'binky.organization.organizationUser.organizationUserDisplayUtils.trialAlreadyConsumedMessage',
      },
      {
        // leveraging the filtered count, assumes that if one request does not contain an email, all of them do not
        // then forces a more generic message (zero emails string)
        count: feature.isEnabled('bug_fix_org_user_toast')
          ? filteredEmails.length
          : emailsThatWereBlocked.length,
        emails: intl.formatList(
          feature.isEnabled('bug_fix_org_user_toast') ? filteredEmails : emailsThatWereBlocked,
          {type: 'unit'}
        ),
      }
    );

    result = {
      actionLabel,
      message: messageLabel,
      onAction: onTrialAlreadyConsumed,
    };
  } else {
    errors?.forEach((e) => {
      switch (getErrorCode(e)) {
        case 'DOMAIN_ENFORCEMENT_VIOLATION':
          result = {
            actionLabel: intl.formatMessage({
              id: 'binky.organization.organizationUser.organizationUserDisplayUtils.domainEnforcementLearnMore',
            }),
            message: intl.formatMessage({
              id: 'binky.organization.organizationUser.organizationUserDisplayUtils.domainEnforcementViolation',
            }),
            onAction: () => {
              const urlString = getGoUrl('aac_domain_enforcement', intl.locale);
              // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- False positive, it is using optional chaining
              window?.open(urlString, '_blank', 'noopener,noreferrer');
            },
          };
          break;
        case 'EXTERNALLY_MANAGED_USER':
          result = {
            message: intl.formatMessage({
              id: 'binky.organization.organizationUser.organizationUserDisplayUtils.externallyManagedUser',
            }),
          };
          break;
        case 'INVALID_FIRST_NAME':
          result = {
            message: intl.formatMessage({
              id: 'binky.organization.organizationUser.organizationUserDisplayUtils.invalidFirstName',
            }),
          };
          break;
        case 'INVALID_LAST_NAME':
          result = {
            message: intl.formatMessage({
              id: 'binky.organization.organizationUser.organizationUserDisplayUtils.invalidLastName',
            }),
          };
          break;
        default:
      }
    });
  }
  return result;
}

function getErrorCode(e) {
  return e?.response?.errorCode || e?.response?.data?.errorCode;
}

export {getOrganizationUserErrorMessage, getOrganizationUserErrorProps};
