/* eslint-disable max-lines -- Utils file */
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {FULFILLABLE_ITEM_CODE} from '../../models/fulfillableItemList/FulfillableItemConstants';
import Offer from '../../models/offers/Offer';
import {CLOUD, QUALIFIER_TARGET} from '../../models/offers/OfferConstants';
import {
  ORGANIZATION_MARKET_SEGMENT,
  ORGANIZATION_MARKET_SUBSEGMENT,
} from '../organization/OrganizationConstants';

/**
 * @description Determine whether the offers prices all include tax.
 *     Note: This is only used by Digital River offers
 * @returns {boolean} true if prices include tax, false otherwise
 */
function doPricesIncludeTax(offerList) {
  return offerList.items.every((offer) => offer.priceIncludesTax());
}

/**
 * @description Method to filter the qualifying offers to only those which can
 *     have a Purchase Authorization created for them.
 * @param {OfferList} offerList - OfferList containing offers to filter.
 * @returns {Offers[]} Array of offers that can have purchase authorizations
 *     created for them.
 */
function filterOnCanCreatePurchaseAuthorizationUtil(offerList) {
  return offerList.items.filter((offer) => offer.canCreatePurchaseAuthorization());
}

/**
 * @description Method to filter the qualifying free offers for the org
 *     marketSubsegments.
 *     Note: assumes this is a free offer list -
 *     'queryParams: {price_point: 'FREE'}}' It is also possible that there is
 *     not an offer for every marketSubsegment, or in the future, there may be
 *     more than one offer per marketSubsegment.
 * @param {Object} context - Context provided to filter
 * @param {Organization} [context.organization] - The organization to compare
 *     with for offer qualification.
 * @param {Boolean} [context.orgHasESM] - Whether the org has ESM
 * @param {ProductList} [context.ownedProducts] - The current product list to
 *     discard offers that have already been provisioned.
 * @param {OfferList} offerList - OfferList containing the offers to filter.
 * @returns {Offer[]} - Array of filtered qualifying free offers.
 */
function filterOnQualifyingFreeOffersUtil(context, offerList) {
  const {organization = {}, orgHasESM, ownedProducts = {}} = context;
  return offerList.items.filter(
    (offer) => offer.isPricePointFree() && isNotOwnedOffer(offer) && isQualifiedOffer(offer)
  );

  function isNotOwnedOffer(offer) {
    return !ownedProducts.items.some(
      (product) => product.productArrangementCode === offer.product_arrangement_code
    );
  }

  function isQualifiedOffer(offer) {
    const creativeCloudProducts = ownedProducts.items.filter(
      (product) => product.cloud === CLOUD.CREATIVE
    );

    const hasProductsInSameBuyingProgramCustomerSegment = ownedProducts.items.some(
      (product) =>
        product.buyingProgram === offer.buying_program &&
        product.customerSegment === offer.customer_segment
    );

    return offer.qualifiers.every((qualifier) => {
      switch (qualifier.target) {
        case QUALIFIER_TARGET.CC_ORG_WITH_STORAGE:
          return handleCCOrgWithStorageQualifier();

        case QUALIFIER_TARGET.FURTHER_EDU_ORG:
          return isSubSegmentOrSegmentWithNoSubSegments(
            ORGANIZATION_MARKET_SEGMENT.EDUCATION,
            ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED
          );
        case QUALIFIER_TARGET.K12_EDU_ORG:
          return isSubSegmentOrSegmentWithNoSubSegments(
            ORGANIZATION_MARKET_SEGMENT.EDUCATION,
            ORGANIZATION_MARKET_SUBSEGMENT.K_12
          );
        case QUALIFIER_TARGET.STANDARD:
          return hasProductsInSameBuyingProgramCustomerSegment;
        default:
          return false;
      }
    });

    function handleCCOrgWithStorageQualifier() {
      // Because VIP will have both cc_storage and esm_storage in the same
      // offer, we will allow either to qualify the org for the offer
      //
      // for ESM offers, we require a cap greater than 0
      if (
        offer.hasESMUserStorage() &&
        hasProductsInSameBuyingProgramCustomerSegment &&
        orgHasESM &&
        creativeCloudProducts.some(
          (product) => product.fulfillableItemList.getESMUserStorageChargingModelCap() > 0
        )
      ) {
        return true;
      }
      const offerFulfillableItems = offer.product_arrangement?.fulfillable_items || [];
      // for USM offers, we require no license with ESM, and one license
      // with a CC Storage cap greater than 0
      return (
        offerFulfillableItems.some(
          (offerFI) => offerFI.code === FULFILLABLE_ITEM_CODE.CC_STORAGE
        ) &&
        hasProductsInSameBuyingProgramCustomerSegment &&
        !orgHasESM &&
        creativeCloudProducts.some(
          (product) => product.fulfillableItemList.getCreativeCloudStorageChargingModelCap() > 0
        )
      );
    }

    function isSubSegmentOrSegmentWithNoSubSegments(segment, subSegment) {
      const orgSubSegments = organization.getMarketSubsegments();
      return (
        (organization.marketSegment === segment &&
          (!orgSubSegments || orgSubSegments.length === 0)) ||
        orgSubSegments.includes(subSegment)
      );
    }
  }
}

/**
 * @description Helper to obtain the copy name for an offer.
 * @param {Offer} offer - Offer whose copy name will be returned.
 * @returns {string|undefined} - Copy name for an offer.
 */
function getCopyName(offer) {
  return offer.merchandising?.copy?.name;
}

/**
 * @description Get the list of marketSubsegments represented in the list of
 *     offers.
 *
 * @param {Offer[]} offers - Array of offers
 * @returns {MarketSubsegment[]} - Array of 0 or more marketSubsegments
 */
function getMarketSubsegmentsForOffers(offers) {
  return [
    ...new Set(
      offers
        .flatMap((offer) => Offer.qualifierTargetsToMarketSubsegments(offer.qualifiers))
        .filter((subsegment) => subsegment)
    ),
  ];
}

/**
 * @description Gets a list of offer ids mapped from the resolved offers
 *
 * @param {OfferList} offerList - OfferList containing the offers this method
 *     will return ids for.
 * @returns {string[]} list of offer ids
 */
function getOfferIds(offerList) {
  return offerList.items.map((offer) => offer.offer_id);
}

/**
 * @description Get the offers for the provided billableItems. Populates the
 *     available license count and assignable license count if available from
 *     the product object; populates the renewing license count from the
 *     billableItems.
 *
 * @param {BillableItem[]} billableItems - Array of billableItems to get
 *     offers for.
 * @param {OfferList} offerList - OfferList containing the offers to filter.
 * @param {Product[]} products - Array of products
 * @returns {Offer[]} - Array of offers for the provided billableItems
 */
function getOffersForBillableItems({billableItems, offerList, products}) {
  const populatedOffers = addBillableItemAndProductInfoToOffers({
    billableItems,
    offerIds: billableItems.map((item) => item.offer_id),
    offers: offerList.items,
    products,
  });
  return sortOffersByFunctionOutput(populatedOffers, getCopyName);
}

/**
 * @description Get the offers for the provided billableItems and products.
 *     Populates the available license count and assignable license count if
 *     available from the product object; populates the renewing license count
 *     from the billableItems.
 *
 * @param {BillableItem[]} billableItems - Array of billableItems to get
 *     offers for.
 * @param {OfferList} offerList - OfferList containing the offers to filter.
 * @param {Product[]} products - Array of products to get offers for
 * @returns {Offers[]} - Array of offers for the provided billableItems and
 *     products.
 */
function getOffersForBillableItemsAndProducts({billableItems, offerList, products}) {
  const offerIdsFromBillableItems = billableItems.map((item) => item.offer_id);
  const offerIdsFromProductList = products.map((product) => product.offerId);
  const offerIds = [...new Set([...offerIdsFromBillableItems, ...offerIdsFromProductList])];
  const populatedOffers = addBillableItemAndProductInfoToOffers({
    billableItems,
    offerIds,
    offers: offerList.items,
    products,
  });

  return sortOffersByFunctionOutput(populatedOffers, getCopyName);
}

/**
 * @description Get the offers for the provided products. Populates the
 *     available license count, if available from the product object.
 *
 * @param {Object} options - Top level wrapper object.
 * @param {OfferList} offerList - OfferList containing the offers to filter.
 * @param {Product[]} products - Array of products to get offers for.
 * @param {string} [segment] - Customer segment to filter by.
 * @returns {Offer[]} - Array of offers for the provided products.
 */
function getOffersForProducts({offerList, products, segment}) {
  const productOffers = [];
  const offersListItems = getOffersForSegment(offerList, segment);
  products.forEach((product) => {
    const offer = offersListItems.find(
      (offerItem) => offerItem.product_arrangement_code === product.productArrangementCode
    );
    if (offer) {
      if (typeof product.getAvailableLicenseCount === 'function') {
        offer.availableLicenseCount = product.getAvailableLicenseCount();
      }
      productOffers.push(offer);
    }
  });
  return uniqBy(productOffers, 'product_arrangement_code');
}

/**
 * @description Get the offers for the provided customer segment.
 *
 * @param {Offer[]} offerList - Array of offers to filter from.
 * @param {string} segment - segment to filter offers by. e.g. TEAM, ENTERPRISE
 * @returns {Offer[]} - Array of offers for the provided segment
 */
function getOffersForSegment(offerList, segment) {
  return segment
    ? offerList.items.filter((offer) => offer.customer_segment === segment)
    : offerList.items;
}

/**
 * @description Filter the list offers to exclude the provided products (by
 *     product arrangement code).
 *     If no products are provided, return all offers.
 *
 * @param {Object} options - Top level wrapper object.
 * @param {OfferList} options.offerList - OfferList containing the offers to
 *     filter from.
 * @param {Product[]} options.products - Array of products to exclude
 * @param {boolean} options.requireCopy- If true, filters any offers that lack copy
 *     since we won't be able to display the offer's product name, etc.
 * @param {string} [options.segment] - Customer segment to filter by (optional)
 * @returns {Offer[]} - Array of offers without the offers corresponding to
 *     the provided products.
 */
function getOffersWithoutProducts({offerList, products, requireCopy, segment}) {
  if (products && products.length > 0) {
    const offersListItems = getOffersForSegment(offerList, segment);
    return offersListItems.filter(offersWithoutProductsFilter);
  }
  return requireCopy
    ? getOffersForSegment(offerList, segment).filter((offer) => offer.merchandising?.copy?.name)
    : getOffersForSegment(offerList, segment);

  function offersWithoutProductsFilter(offer) {
    return (
      !products.some(
        (product) => product.productArrangementCode === offer.product_arrangement_code
      ) &&
      (!requireCopy || offer.merchandising?.copy?.name)
    );
  }
}

/**
 * @description Filter the list offers to exclude the provided billableItems
 *     and products (by offer Id).
 *
 * @param {Object} options - Top level wrapper object.
 * @param {BillableItem[]} options.billableItems - Array of billableItems to exclude
 *     offers for.
 * @param {OfferList} options.offerList - OfferList containing the offers to
 *     filter.
 * @param {Product[]} options.products - Array of products to exclude offers for
 * @returns {Offer[]} - Array of offers exclude the provided billableItems
 *     and products.
 */
function getOffersWithoutRenewalOrderOrProducts({billableItems, offerList, products}) {
  const offerIdsFromBillableItems = billableItems.map((item) => item.offer_id);
  const offerIdsFromProductList = products.map((item) => item.offerId);
  const excludedOfferIds = [...new Set([...offerIdsFromBillableItems, ...offerIdsFromProductList])];
  const targetOffers = offerList.items.filter(
    (offer) => !excludedOfferIds.includes(offer.offer_id)
  );

  return sortOffersByFunctionOutput(targetOffers, getCopyName);
}

/**
 * @description Helper to obtain the pores order for an offer.
 *     NOTE: this was put here to help obtain full test coverage.
 * @param {*} offer
 * @returns
 */
function getPoresOrder(offer) {
  return offer.poresOrder;
}

/**
 * @description Helper method that will execute a sort on an offerList based on
 *     the output of a provided callback method.
 * @param {Offer[]} offers - Array of offers to sort.
 * @param {Function} fn - function to invoke to determine the sort order.
 * @returns {Offer[]} Array of offers sorted based on the provided method.
 */
function sortOffersByFunctionOutput(offers, fn) {
  return sortBy(offers, [(offer) => fn(offer)]);
}

///////

/**
 * @description Helper to assign product and billableItem information to
 *     offers.
 *
 * @param {Offer[]} offers - Offers to filter from.
 * @param {string[]} offerIds - Offer ids of offers to be populated.
 * @param {BillableItem[]} billableItems - Array of billableItems to cross
 *     reference.
 * @param {Product[]} products - Array of products to cross reference.
 *
 * @returns {Offer[]} - Updated array of offers.
 */
function addBillableItemAndProductInfoToOffers({offers, offerIds, billableItems, products}) {
  const populatedOffers = [];
  offerIds.forEach((offerId) => {
    const offer = getItemByOfferId(offers, 'offer_id', offerId);

    if (offer) {
      const billableItem = getItemByOfferId(billableItems, 'offer_id', offerId);
      // When we are unable to get the product using the offerId, try using the product arrangement code.
      // This is likely to happen for a promo team in the renewal window.
      // Their promo offers are mapped to regular offers by the offer mapping service,
      // we won't be able to get the product (associated with the promo offer) using the regular offerId.
      const product =
        getItemByOfferId(products, 'offerId', offerId) ||
        getItemByProductArrangementCode(
          products,
          'productArrangementCode',
          offer.product_arrangement_code
        );

      offer.availableLicenseCount = product?.getAvailableLicenseCount?.() || 0;
      offer.assignableLicenseCount = product?.getAssignableLicenseCount?.() || 0;
      offer.renewingLicenseCount = billableItem?.quantity || 0;
      populatedOffers.push(offer);
    }
  });
  return populatedOffers;
}

/**
 * @description Get the item from the array by a given offer id.
 *
 * @param {BillableItem[] | Offer[] | Product[]} arr - Array of items that we
 *     want to search from.
 * @param {string} key - key for the offerId field, it's offerId for
 *     productList, and offer_id for offerlist and billableItems.
 * @param {string} offerId offerId that we want to get the item for.
 * @returns {BillableItem | Offer | Product} - The target item that has the
 *     given offer id.
 */
function getItemByOfferId(arr, key, offerId) {
  return arr.find((item) => item[key] === offerId);
}

/**
 * @description Get the item from the array by a given product arrangement code.
 *
 * @param {Offer[] | Product[]} arr - Array of items that we want to search
 *     from.
 * @param {string} key key for the arrangement code field, it's
 *     productArrangementCode for productList, and product_arrangement_code for
 *     offerlist.
 * @param {string} productArrangementCode product arrangement code that we want
 *     to get the item for.
 * @returns {Offer | Product} the item that has the given product arrangement
 *     code.
 */
function getItemByProductArrangementCode(arr, key, productArrangementCode) {
  return arr.find((item) => item[key] === productArrangementCode);
}

export {
  doPricesIncludeTax,
  filterOnCanCreatePurchaseAuthorizationUtil,
  filterOnQualifyingFreeOffersUtil,
  getCopyName,
  getMarketSubsegmentsForOffers,
  getOfferIds,
  getOffersForBillableItems,
  getOffersForBillableItemsAndProducts,
  getOffersForProducts,
  getOffersForSegment,
  getOffersWithoutProducts,
  getOffersWithoutRenewalOrderOrProducts,
  getPoresOrder,
  sortOffersByFunctionOutput,
};
/* eslint-enable max-lines -- Utils file */
