(function () {
  /**
   * @deprecated Please use src2/core/service/migration/migrationList.js
   *
   * @ngdoc factory
   * @name MigrationList
   * @description Model for a list of JIL organization migrations. Some types of migration are
   *   parsed into a custom model (e.g. T2EMigration) which extends Migration; others which lack
   *   additional response data and do not require helper functions are parsed into Migration.
   */
  angular.module('binky.core.migration').factory('MigrationList', factory);

  /* @ngInject */
  function factory($q, binkySrc2, MIGRATION_LIST_CACHE_ID, modelCache) {
    modelCache.register(MIGRATION_LIST_CACHE_ID, 10);

    class MigrationList2 extends binkySrc2.services.migration.MigrationList {
      /**
       * @description Method to refresh Migration list from back-end.
       * @returns {Promise} resolves to MigrationList on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              resolve(this);
            })
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the MigrationsList.
       *
       * @param {Object} options as described below
       * @param {String} options.orgId associates MigrationList instance with an org.
       * @param {String[]} options.types the migration types to fetch in this list
       * @return {MigrationList2} migrationList instance
       */
      static get(options) {
        const model = new MigrationList2(options);
        model.refresh();
        return model;
      }
    }

    //////////////

    return MigrationList2;
  }
})();
