/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {VisuallyHidden} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import './WizardStepIndicator.pcss';
import {defineMessages, useIntl} from 'react-intl';

/**
 * Widget used for styling individual wizard steps.
 */
const WizardStepIndicator = ({children, stepNumber, currentStep}) => {
  const intl = useIntl();
  const messages = defineMessages({
    WizardStepCompletionMessage: {
      defaultMessage: 'Step completed',
      id: 'binky.common.wizardStepIndicator.stepCompletedLabel',
    },
  });

  const getStepIndicatorClass = () => {
    if (stepNumber === currentStep) {
      return 'current';
    }
    if (stepNumber < currentStep) {
      return 'completed';
    }
    return 'incomplete';
  };

  return (
    <>
      <div styleName={`step-indicator step-indicator-${getStepIndicatorClass()}`}>
        <span
          aria-hidden="true"
          data-testid="step-number"
          styleName={`step-label step-label-${
            stepNumber > currentStep ? 'incomplete' : 'completed'
          }`}
        >
          {stepNumber + 1}
        </span>
      </div>
      <span
        data-testid="step-label"
        styleName={`label label-${stepNumber === currentStep ? 'active' : 'inactive'}`}
      >
        {children}
      </span>
      {stepNumber < currentStep && (
        <VisuallyHidden data-testid={`step-${stepNumber}-hidden`} elementType="span">
          {intl.formatMessage(messages.WizardStepCompletionMessage)}
        </VisuallyHidden>
      )}
    </>
  );
};

WizardStepIndicator.propTypes = {
  /**
   * Children to pass through.
   */
  children: PropTypes.string,
  /**
   * Number indicating the cuurent step state
   */
  currentStep: PropTypes.number.isRequired,
  /**
   * Number indicating the step this component represents
   */
  stepNumber: PropTypes.number.isRequired,
};

export default WizardStepIndicator;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
