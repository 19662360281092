(function () {
  'use strict';
  /**
   * @deprecated use the React Spectrum Alert
   *
   * @ngdoc factory
   * @name DisplayMessage
   * @description Model factory for DisplayMessage Objects
   */
  angular
    .module('binky.widgets.common.display-message')
    .factory('DisplayMessage', getDisplayMessage);

  /* @ngInject */
  function getDisplayMessage($translate, DISPLAY_MESSAGE_VARIANT) {
    /**
     * @class
     * @description Representation of all properties needed for a display message
     */
    class DisplayMessage {
      /**
       * @constructs DisplayMessage
       * @description Constructor for DisplayMessage model Objects.
       * @param {Object} options - DisplayMessage instance settings
       * @param {String} [options.body] - Translated body text of the display message. If the body changes, the display message is re-created with the new body.
       * @param {String} [options.ctaText] - Translated button text.
       * @param {String} [options.header] - Translated header text of the display message.
       * @param {Function} [options.onCtaClick] - Callback function called when button is clicked.
       * @param {String} [options.removeWithKey] - If the key is defined in local storage, then the display message will not be rendered. Otherwise, the display message will be rendered.
       * @param {Boolean} [options.showDefaultButton] - If false, and variant is 'help' or 'info' and ctaText not specified, will not show the Close button.
       * @param {String} [options.variant] - Variant type of the display message. One of: 'error', 'warning', 'help', 'info'. Defaults to 'error'.
       */
      constructor(options = {}) {
        this.body =
          options.body || $translate.instant('binky.widgets.common.display-message.details');
        this.ctaText = options.ctaText;
        this.header = getDefaultHeaders(options);
        this.onCtaClick = options.onCtaClick;
        this.removeWithKey = options.removeWithKey;
        this.showDefaultButton = options.showDefaultButton;
        this.variant = options.variant || DISPLAY_MESSAGE_VARIANT.ERROR;
      }
    }

    return DisplayMessage;

    /* Private Functions */

    /**
     * Show the default header ("We're sorry") when the default body is being used
     * @param {Object} options - DisplayMessage instance settings
     * @param {String} [options.body] - Translated body text of the display message. If the body changes, the display message is re-created with the new body.
     * @param {String} [options.header] - Translated header text of the display message.
     * @returns {String} The translated default header.
     */
    function getDefaultHeaders(options) {
      if (!options.body) {
        return $translate.instant('binky.widgets.common.display-message.title');
      }

      return options.header;
    }
  }
})();
