(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.widgets.directories.status').constant('DIRECTORY_STATUS_LABEL', {
    // one for each of DIRECTORY_STATUS plus ALL_STATUSES
    ACTIVE: 'ACTIVE',
    ALL_STATUSES: 'ALL_STATUSES', // for the directory status selector (not shown in the table)
    NEEDS_DOMAIN: 'NEEDS_DOMAIN',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    REQUIRES_ACTIVATION: 'REQUIRES_ACTIVATION',
    REQUIRES_APPROVAL: 'REQUIRES_APPROVAL',
    REQUIRES_CONFIGURATION: 'REQUIRES_CONFIGURATION',
    REVOKED: 'REVOKED',
    TRUSTED: 'TRUSTED',
  });
})();
