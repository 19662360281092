(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.page-banner
   * @description module that defines page-banner widgets
   */
  angular.module('binky.widgets.common.page-banner', [
    'binky.core.lodash',
    'binky.core.storage-utils',
    'binky.widgets.common.button',
    'pascalprecht.translate',
  ]);
})();
