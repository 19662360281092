// eslint-disable-next-line @admin-tribe/admin-tribe/include-in-index -- no need to export
import {Flex, ProgressCircle, View} from '@adobe/react-spectrum';
import {FocusScope} from '@react-aria/focus';
import {usePreventScroll} from '@react-aria/overlays';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

const DEFAULT_DELAY = 600; // ms

/**
 * A wait component that traps the focus and displays a ProgressCircle while a section of the page is loaded.
 * While loading, an optional message can be displayed under the wait indicator
 * The content can optionally be shown while loading.
 *
 * This is based on @pandora/react-page-wait except this supports a wait on a section of the page.
 * Use @pandora/react-page-wait for a wait component which overlays the entire page.
 */

const WaitFocusScope = ({
  delay = DEFAULT_DELAY,
  loadingMessage,
  showContent = false,
  ...waitProps
}) => {
  const intl = useIntl();

  const messageId = useId();
  const [showWait, setShowWait] = useState(!delay);
  const timeoutRef = useRef();

  // Prevent scrolling while overlay is shown.
  usePreventScroll();

  // If requested, delay showing the ProgressCircle.
  useEffect(() => {
    if (delay) {
      timeoutRef.current = setTimeout(() => {
        setShowWait(true);
      }, delay);
    }
    return () => {
      // It is a no-op if clearTimeout is called with an id which isn't associated with an active timer.
      clearTimeout(timeoutRef.current);
    };
  }, [delay]);

  // This is based on @pandora/react-page-wait which has been thru accessibility review.
  return (
    <View
      bottom="size-0"
      data-testid="overlay-wait-container"
      left="size-0"
      position="absolute"
      right="size-0"
      top="size-0"
      UNSAFE_style={{
        backgroundColor: showWait && 'var(--spectrum-global-color-gray-50)',
        opacity: showContent ? '0.69' : '1',
        zIndex: 999,
      }}
    >
      {showWait && (
        <FocusScope autoFocus contain restoreFocus>
          <div
            aria-labelledby={messageId}
            data-testid="overlay-wait-group"
            role="group"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- triggers focus on the overlay manually since no interactive elements exist within this scope */
            tabIndex={0}
          >
            <Flex
              alignItems="center"
              bottom="size-0"
              direction="column"
              justifyContent="center"
              left="size-0"
              position="absolute"
              right="size-0"
              top="size-0"
            >
              <ProgressCircle
                aria-label={intl.formatMessage({id: 'binky.common.wait.loadingLabel'})}
                data-testid="overlay-wait-indicator"
                isIndeterminate
                {...waitProps}
              />
              {loadingMessage && (
                <View
                  data-testid="overlay-wait-loading-text"
                  id={messageId}
                  marginTop="size-100"
                  UNSAFE_style={{color: 'var(--color-grey-900)', fontSize: 'larger'}}
                >
                  {loadingMessage}
                </View>
              )}
            </Flex>
          </div>
        </FocusScope>
      )}
    </View>
  );
};

WaitFocusScope.propTypes = {
  /**
   * The number of milliseconds to delay before showing the wait spinner.
   * The default is 600ms when 'temp_a11y_overlay_wait' is enabled, else 0.
   */
  delay: PropTypes.number,
  /**
   * Whether the wait should be shown above the content.
   */
  isLoading: PropTypes.bool,
  /**
   * An optional localized string to display below the loading spinner.
   */
  loadingMessage: PropTypes.string,
  /**
   * Whether to show the content when the wait spinner is rendered.
   * Generally except for initial load, showContent should be set to true.
   * The default is false.
   */
  showContent: PropTypes.bool,
  /**
   * Styles to pass to Spectrum's ProgressCircle.
   * An aria-label must be provided for accessibility. The default is 'Loading...'.
   * The size is what the ProgressCircle's diameter should be. Spectrum's default is 'M'.
   */
  waitProps: PropTypes.shape({
    'aria-label': PropTypes.string,
    size: PropTypes.string,
  }),
};

export default WaitFocusScope;
