(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.storage-utils')
    // eslint-disable-next-line angular/window-service
    .constant('SELECTED_LOCALE_KEY', window.binkySrc2.services.LOCALE_CONSTANTS.SELECTED_LOCALE_KEY)
    .constant('VALUE_KEY_APPEND', 'Type');
})();
