(function () {
  'use strict';
  /**
   * @deprecated use the React Spectrum Alert
   */
  angular.module('binky.widgets.common.display-message').constant('DISPLAY_MESSAGE_VARIANT', {
    ERROR: 'error',
    HELP: 'help',
    INFO: 'info',
    WARNING: 'warning',
  });
})();
