(function () {
  /**
   * @deprecated Please use src2/core/services/locale/Locale.js
   */
  angular.module('binky.core.locale').provider('locale', localeProvider);

  /* @ngInject */
  function localeProvider(
    _,
    DEFAULT_LANGUAGE,
    LANGUAGE_BCP47_MAP,
    LANGUAGE_COUNTRY_MAP,
    SUPPORTED_LANGUAGES,
    SURNAME_FIRST_LOCALES
  ) {
    this.getLocaleCodeFromLanguage = getLocaleCodeFromLanguage;
    this.getStringFileUrl = getStringFileUrl;
    this.getSupportedLanguages = getSupportedLanguages;
    this.getSupportedLanguageKeys = getSupportedLanguageKeys;
    this.updateSupportedLanguageList = updateSupportedLanguageList;

    let supportedLanguagesList = SUPPORTED_LANGUAGES;

    /* @ngInject */
    this.$get = function ($location, $translate) {
      const LOCALE_QUERY_PARAM = 'locale';

      const service = {
        deleteQueryParam,
        getActiveCountry,
        getActiveLanguage,
        getActiveLanguageBCP47Code,
        getActiveLocaleCode,
        getCoralLocale,
        getLanguageFromLocaleCode,
        getLocaleCodeFromLanguage,
        getQueryParam,
        getSupportedLanguages,
        isSurnameFirstNameLocale,
        updateSupportedLanguageList,
      };

      return service;

      ////////////

      function deleteQueryParam() {
        $location.search(LOCALE_QUERY_PARAM, null);
      }

      function getActiveCountry() {
        let lang = LANGUAGE_COUNTRY_MAP[$translate.use()];
        // if the active language is null, this may indicate its still loading
        if (angular.isUndefined(lang)) {
          // proposed language tells us the locale being loaded
          lang = LANGUAGE_COUNTRY_MAP[$translate.proposedLanguage()];
          // if this is still not present, we fall back to English
          if (angular.isUndefined(lang)) {
            lang = DEFAULT_LANGUAGE;
          }
        }
        return lang;
      }

      function getActiveLanguage() {
        let lang = $translate.use();
        // if the active language is null, this may indicate its still loading
        if (angular.isUndefined(lang)) {
          // proposed language tells us the locale being loaded
          lang = $translate.proposedLanguage();
          // if this is still not present, we fall back to English
          if (angular.isUndefined(lang)) {
            lang = DEFAULT_LANGUAGE;
          }
        }
        return lang;
      }

      // The use of zh-cn and zh-tw has been deprecated, though we still use them
      // internally in our projects. Some services we connect with (and need to get
      // translated values from) use the newer language codes instead, so we need a
      // way to get those newer codes (as per BCP47 spec) to pass them along to those
      // services (i.e. AdobeStatus).
      function getActiveLanguageBCP47Code() {
        let lang = LANGUAGE_BCP47_MAP[$translate.use()];
        // if the active language is null, this may indicate its still loading
        if (angular.isUndefined(lang)) {
          // proposed language tells us the locale being loaded
          lang = LANGUAGE_BCP47_MAP[$translate.proposedLanguage()];
          // if this is still not present, we fall back to English
          if (angular.isUndefined(lang)) {
            lang = LANGUAGE_BCP47_MAP[DEFAULT_LANGUAGE];
          }
        }
        return lang;
      }

      function getActiveLocaleCode() {
        return getLocaleCodeFromLanguage(getActiveLanguage());
      }

      // Return active locale in the format used by Coral.
      // Coral currently supports 'en-US', 'de-DE', 'fr-FR', 'it-IT', 'es-ES', 'pt-BR', 'nl-NL'
      // 'ko-KR', 'ja-JP', 'zh-CN', 'zh-TW'.
      // Coral's default is the document's lang or 'en-US' if not specified.
      // If the specified value is not supported, Coral will use the English key.
      function getCoralLocale() {
        return _.replace(getActiveLocaleCode(), '_', '-');
      }

      function getQueryParam() {
        return $location.search()[LOCALE_QUERY_PARAM];
      }

      function isSurnameFirstNameLocale() {
        const lang = getActiveLanguage();
        return _.includes(SURNAME_FIRST_LOCALES, lang);
      }
    };

    ////////////

    function getLanguageFromLocaleCode(localeCode) {
      return localeCode ? _.split(localeCode, '_')[0] : undefined;
    }

    /**
     * @description Method to retrieve a locale code from the corresponding 2-character language code.
     *
     * @param {String} languageCode - two-character language code to get locale code from
     * @returns {String} locale code of language requested
     */
    function getLocaleCodeFromLanguage(languageCode) {
      const languageObject = _.find(getSupportedLanguages(), {value: languageCode}) || {};
      return languageObject.locale;
    }

    function getStringFileUrl(languageCode) {
      const languageObject = _.find(getSupportedLanguages(), {value: languageCode}) || {};
      return languageObject.url;
    }

    function getSupportedLanguageKeys() {
      return _.map(getSupportedLanguages(), 'value');
    }

    function updateSupportedLanguageList(env) {
      supportedLanguagesList =
        env === 'stage' ? SUPPORTED_LANGUAGES : _.reject(SUPPORTED_LANGUAGES, 'markedForTesting');
    }

    function getSupportedLanguages() {
      return supportedLanguagesList;
    }
  }
})();
