(function () {
  'use strict';
  /**
   * @deprecated Replaced by shell/workspace/subpage/Subpage/Subpage.jsx
   */
  angular.module('binky.shell.workspace.page.sub-page').component('binkySubPage', {
    bindings: {
      waitOnSubPage: '<?',
      waitString: '@?',
    },
    controller,
    templateUrl: 'shell/workspace/page/sub-page/sub-page.component.html',
    transclude: {
      'body-area': 'bodyArea',
      'header-area': '?headerArea',
    },
  });

  /* @ngInject */
  function controller($log, $q, waitOnError) {
    const vm = this;

    /** Bindable members */
    vm.$onInit = $onInit;

    /////////
    function $onInit() {
      if (vm.waitOnSubPage) {
        vm.waitOnSubPage
          .finally(() => {
            vm.showContent = true;
          })
          .catch((error) => {
            vm.isBumpered = true;
            vm.bumperStrings = waitOnError.getBumperStrings(error);
          });
      } else {
        vm.waitOnSubPage = $q.resolve();
      }
    }
  }
})();
