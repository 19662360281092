(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.carousel:binkyCarousel
   *
   * @description The Carousel widget
   *
   * @param {Function} [provideStepAriaLabel] - call back method provider which will
   *    be called with a single argument of the integer step index being processed,
   *    the function should return the value for the aria-label attribute of the Tab.
   *    Optional; default is undefined
   * @param {Boolean} [showArrows] - true if Carousel should show next and
   *   previous arrow buttons for step navigation. Optional; default is false
   *
   * @example
   * <binky-carousel>
   *   <coral-panel ng-repeat="item in $ctrl.exampleItems"><coral-panel-content>
   *     <div>
   *       <p>Example content for {{item.name}}</p>
   *     </div>
   *   </coral-panel-content></coral-panel>
   * </binky-carousel>
   *
   */
  angular.module('binky.widgets.common.carousel').component('binkyCarousel', {
    bindings: {
      provideStepAriaLabel: '<?',
      showArrows: '<?',
    },
    controller,
    templateUrl: 'widgets/common/carousel/carousel.component.html',
    transclude: true,
  });

  function controller($element, $scope, $timeout, _, CAROUSEL_CHANGE_EVENT) {
    const vm = this;

    _.assign(vm, {
      $doCheck,
      $onInit,
      hasNextStep,
      hasPreviousStep,
    });

    let numPanels, stepList;

    function $doCheck() {
      resetCarouselOnPanelChanges();
    }

    function $onInit() {
      stepList = _.head($element.find('coral-stepList'));

      const wizardView = _.head($element.find('coral-wizardview'));
      wizardView.addEventListener('coral-wizardview:change', () => {
        $timeout(() => {
          const index = getSelectedStepIndex();
          $scope.$emit(CAROUSEL_CHANGE_EVENT, {index});
          $scope.$apply();
        });
      });

      Coral.commons.ready(resetCarousel);
    }

    function hasNextStep() {
      return !isLastStepSelected();
    }

    function hasPreviousStep() {
      return !isFirstStepSelected();
    }

    ////////

    function getNumberOfPanels() {
      return _.get($element.find('coral-panel'), 'length', 0);
    }

    function getSelectedStepIndex() {
      const steps = _.invoke(stepList, 'items.getAll');
      return _.findIndex(steps, isStepSelected);
    }

    function isFirstStepSelected() {
      const firstStep = _.invoke(stepList, 'items.first');
      return isStepSelected(firstStep);
    }

    function isLastStepSelected() {
      const lastStep = _.invoke(stepList, 'items.last');
      return isStepSelected(lastStep);
    }

    function isStepSelected(step) {
      const selectedStep = _.get(stepList, 'selectedItem', false);
      return _.isEqual(step, selectedStep);
    }

    function resetCarousel() {
      numPanels = getNumberOfPanels();
      vm.hideNavigation = numPanels <= 1; // do not show the nav dots if there is only one item

      const ngWrappedSteplist = angular.element(stepList);
      // if transcluded content changes, we need to remove all children &
      // rebuild the steps
      ngWrappedSteplist.empty();
      // <coral-step> doesn't work well with ng-repeat, thus we append the steps manually
      _.times(numPanels, (panelIndex) => {
        const step = new Coral.Step();
        ngWrappedSteplist.append(step);

        if (!_.isUndefined(vm.provideStepAriaLabel) && _.isFunction(vm.provideStepAriaLabel)) {
          const stepArialLabel = vm.provideStepAriaLabel(panelIndex);
          if (!_.isUndefined(stepArialLabel)) {
            angular.element(step.setAttribute('aria-label', stepArialLabel));
          }
        }
      });
      $scope.$apply();
    }

    function resetCarouselOnPanelChanges() {
      // only check for changes if initialized
      if (_.isNumber(numPanels)) {
        // since panel changes often occur toward trailing end of cycle (i.e.
        // - after this point in execution), wrap in timeout to ensure
        //   transcluded content is updated prior to check...
        $timeout(
          () => {
            if (!_.isEqual(numPanels, getNumberOfPanels())) {
              // transcluded content changed, so reset carousel...
              resetCarousel();
            }
          },
          0,
          // IMPORTANT: do not make an $apply call automatically (resetCarousel
          // will $apply, if changes), otherwise infinite $digest loop will
          // occur at runtime since this is tied to digest cycle through
          // $doCheck method
          false
        );
      }
    }
  }
})();
