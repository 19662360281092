// Defined by JIL as the maximum "valid email length" in multiple APIs and utils
// (e.g. OrganizationUserUtilsService, SAMLValidationService, AvailableUserTypeFacade, etc.)
const EMAIL_MAX_LENGTH = 60;
const IMS_USER_ID_REGEX = /^[\da-z]{24}@([\d.a-z]+|adobeid)$/i;

const ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  LOCKED: 'LOCKED',
  REMOVED: 'REMOVED',
};

const AUTHENTICATING_ACCOUNT_TYPE = {
  ADOBE_ID: 'AdobeID',
  BUSINESS_ID: 'BusinessID',
  ENT_FED_ID: 'EntFedID',
  INVALID: 'Invalid',
};

export {ACCOUNT_STATUS, AUTHENTICATING_ACCOUNT_TYPE, EMAIL_MAX_LENGTH, IMS_USER_ID_REGEX};
