/** Model used to build the @see {HeaderNav} */
class HeaderNavItem {
  /**
   *
   * @param {{accessor: () => Promise<boolean> | boolean, href: string, labelKey: string, key: string}} param0
   */
  constructor({accessor, href, labelKey, key, order}) {
    Object.assign(this, {
      accessor,
      href,
      key,
      labelKey,
      order: order || 0,
    });

    if (!href || !labelKey || !key) {
      throw new Error('HeaderNavItem is missing data');
    }
  }

  /**
   * Translates the label key into a localized label
   * @param {*} intl
   * @returns {string}
   */
  getLocalizedLabel(intl) {
    return intl.formatMessage({
      id: this.labelKey,
    });
  }

  /**
   * Determines if this nav item should be visible
   * @returns {Promise<boolean> | boolean}
   */
  isVisible() {
    return this.accessor?.() ?? true;
  }
}

export default HeaderNavItem;
