/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {
  CONTRACT_ADMIN,
  ConsumableSummarizationList,
  DEPLOYMENT_ADMIN,
  FULFILLABLE_ITEM_CODE,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  Product,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
  feature,
} from '@admin-tribe/binky';
import {Divider, View} from '@adobe/react-spectrum';
import {ToggleSection} from '@pandora/react-toggle-section';
import AlertIcon from '@react/react-spectrum/Alert';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AdminRoleCheckbox from 'common/components/admin-role-checkbox/AdminRoleCheckbox';
import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import AssignmentSection from 'common/components/assignment-section/AssignmentSection';
import ToggleSectionDeprecated from 'common/components/toggle-section/ToggleSection';

import {getCheckboxList} from '../admin-role-checkbox/adminRoleCheckboxUtils';

import './AdminRolePicker.pcss';

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

/**
 * AdminRolePicker - a widget for picking Admin Roles.
 */
// eslint-disable-next-line complexity -- remove with temp_parkour_mm
const AdminRolePicker = ({
  adminRole,
  consumableSummarizationList,
  defaultCheckbox,
  defaultChecked = false,
  disabled = false,
  onAssignment = () => {},
  onSelectCheckbox = () => {},
  onToggle = () => {},
  orgId,
  productToAddAdmin,
}) => {
  const intl = useIntl();

  let banner, consumableData;
  if (feature.isEnabled('temp_parkour_mm') && adminRole === PRODUCT_SUPPORT_ADMIN) {
    consumableData = consumableSummarizationList?.getConsumableForSummaryIdAndFICode(
      FULFILLABLE_ITEM_CODE.SUPPORT_ALLOWED_ADMINS,
      productToAddAdmin?.id
    );

    banner = disabled && consumableData?.remainingQuantity === 0 && (
      <AlertIcon
        header={intl.formatMessage({
          id: 'binky.common.adminRole.roles.productSupportAdmin.alert.header',
        })}
        variant="warning"
      >
        {intl.formatMessage({
          id: 'binky.common.adminRole.roles.productSupportAdmin.alert.description',
        })}
      </AlertIcon>
    );
  }

  const ROLE_CARDS = {
    [CONTRACT_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.contractAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.contractAdmin.name'}),
      target: [TARGET_TYPE.CONTRACTS],
    },
    [DEPLOYMENT_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.deploymentAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.deploymentAdmin.name'}),
    },
    [LICENSE_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.licenseAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.licenseAdmin.name'}),
      // keep profile target first in array as it is used for localization key
      target: [TARGET_TYPE.PRODUCT_PROFILES, TARGET_TYPE.PRODUCTS],
    },
    [ORG_ADMIN]: {
      description: intl.formatMessage({id: 'binky.common.adminRole.roles.orgAdmin.description'}),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.orgAdmin.name'}),
    },
    [PRODUCT_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.productAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.productAdmin.name'}),
      target: productToAddAdmin ? undefined : [TARGET_TYPE.PRODUCTS],
    },
    [PRODUCT_SUPPORT_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.productSupportAdmin.description',
      }),
      name: intl.formatMessage(
        {
          id:
            feature.isEnabled('temp_hide_psa_quota') || !consumableData
              ? 'binky.common.adminRole.roles.productSupportAdmin.name.noQuota'
              : 'binky.common.adminRole.roles.productSupportAdmin.name',
        },
        consumableData
      ),
    },
    [STORAGE_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.storageAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.storageAdmin.name'}),
    },
    [SUPPORT_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.supportAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.supportAdmin.name'}),
    },
    [SUPPORT_ADMINS]: {
      checkboxList: getCheckboxList(defaultCheckbox, intl, SUPPORT_ADMINS),
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.supportAdmins.description2',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.supportAdmin.name'}),
    },
    [USER_GROUP_ADMIN]: {
      description: intl.formatMessage({
        id: 'binky.common.adminRole.roles.userGroupAdmin.description',
      }),
      name: intl.formatMessage({id: 'binky.common.adminRole.roles.userGroupAdmin.name'}),
      target: [TARGET_TYPE.USER_GROUPS],
    },
  };

  const checkboxList = ROLE_CARDS[adminRole].checkboxList;
  const target = ROLE_CARDS[adminRole].target;

  const [selectedItemCount, setSelectedItemCount] = useState(0);

  const onChange = (selectedItems, subRole) => {
    onAssignment(selectedItems, subRole);
    setSelectedItemCount(selectedItems.length);
  };

  return (
    <View data-testid="admin-role" width="100%">
      {feature.isEnabled('temp_parkour_mm') ? (
        <ToggleSection
          banner={banner}
          defaultChecked={defaultChecked}
          isDisabled={disabled}
          marginBottom="size-200"
          onToggle={(toggleStatus) => {
            setSelectedItemCount(0);
            onToggle(toggleStatus);
          }}
          toggleDescription={ROLE_CARDS[adminRole].description}
          toggleLabel={ROLE_CARDS[adminRole].name}
        >
          {ROLE_CARDS[adminRole].target && (
            <>
              <Divider marginTop="size-150" size="S" />
              <View marginStart="size-300">
                <p data-testid="managed-count-label" styleName="managed-count-label">
                  {intl.formatMessage(
                    {
                      id: `binky.common.adminRole.managedCount.${target[0]}`,
                    },
                    {amount: selectedItemCount}
                  )}
                </p>
                <AssignmentSection
                  isDisabled={disabled}
                  onChange={onChange}
                  orgId={orgId}
                  targets={target}
                />
              </View>
            </>
          )}
          {checkboxList && (
            <>
              <Divider marginTop="size-150" size="S" />
              <AdminRoleCheckbox
                checkboxList={checkboxList}
                onSelectRole={onSelectCheckbox}
                onSelectTarget={onChange}
                orgId={orgId}
              />
            </>
          )}
        </ToggleSection>
      ) : (
        <ToggleSectionDeprecated
          defaultChecked={defaultChecked}
          disabled={disabled}
          onToggle={(toggleStatus) => {
            setSelectedItemCount(0);
            onToggle(toggleStatus);
          }}
          toggleDescription={ROLE_CARDS[adminRole].description}
          toggleLabel={ROLE_CARDS[adminRole].name}
        >
          {ROLE_CARDS[adminRole].target && (
            <>
              <Divider marginTop="size-150" size="S" />
              <View marginStart="size-300">
                <p data-testid="managed-count-label" styleName="managed-count-label">
                  {intl.formatMessage(
                    {
                      id: `binky.common.adminRole.managedCount.${target[0]}`,
                    },
                    {amount: selectedItemCount}
                  )}
                </p>
                <AssignmentSection
                  isDisabled={disabled}
                  onChange={onChange}
                  orgId={orgId}
                  targets={target}
                />
              </View>
            </>
          )}
          {checkboxList && (
            <>
              <Divider marginTop="size-150" size="S" />
              <AdminRoleCheckbox
                checkboxList={checkboxList}
                onSelectRole={onSelectCheckbox}
                onSelectTarget={onChange}
                orgId={orgId}
              />
            </>
          )}
        </ToggleSectionDeprecated>
      )}
    </View>
  );
};

AdminRolePicker.propTypes = {
  /**
   * Type of admin role.
   */
  adminRole: PropTypes.oneOf([
    CONTRACT_ADMIN,
    DEPLOYMENT_ADMIN,
    LICENSE_ADMIN,
    ORG_ADMIN,
    PRODUCT_ADMIN,
    PRODUCT_SUPPORT_ADMIN,
    STORAGE_ADMIN,
    SUPPORT_ADMIN,
    SUPPORT_ADMINS,
    USER_GROUP_ADMIN,
  ]).isRequired,
  /**
   * ConsumableSummarizationList to get quota of product support admin.
   */
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList),
  /**
   * Default value for the checkbox to check or disable.
   */
  defaultCheckbox: PropTypes.shape({
    checked: PropTypes.arrayOf(
      PropTypes.oneOf([
        CONTRACT_ADMIN,
        DEPLOYMENT_ADMIN,
        LICENSE_ADMIN,
        ORG_ADMIN,
        PRODUCT_ADMIN,
        PRODUCT_SUPPORT_ADMIN,
        STORAGE_ADMIN,
        SUPPORT_ADMIN,
        SUPPORT_ADMINS,
        USER_GROUP_ADMIN,
      ])
    ),
    disabled: PropTypes.arrayOf(
      PropTypes.oneOf([
        CONTRACT_ADMIN,
        DEPLOYMENT_ADMIN,
        LICENSE_ADMIN,
        ORG_ADMIN,
        PRODUCT_ADMIN,
        PRODUCT_SUPPORT_ADMIN,
        STORAGE_ADMIN,
        SUPPORT_ADMIN,
        SUPPORT_ADMINS,
        USER_GROUP_ADMIN,
      ])
    ),
  }),
  /**
   * Default value for whether the picker is checked. Default value is false.
   */
  defaultChecked: PropTypes.bool,
  /**
   * Whether the picker is selected. Default value is false.
   */
  disabled: PropTypes.bool,
  /**
   * Handler that is called when an item is assigned
   */
  onAssignment: PropTypes.func,
  /**
   * Handler that is called when an item in the checkbox is selected
   */
  onSelectCheckbox: PropTypes.func,
  /**
   * Handler that is called when the picker is toggled.
   */
  onToggle: PropTypes.func,
  /**
   * The ID of the organization
   */
  orgId: PropTypes.string.isRequired,
  /**
   * The product that should be assigned admin roles(only for product or product support admin).
   */
  productToAddAdmin: PropTypes.instanceOf(Product),
};

export default AdminRolePicker;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
