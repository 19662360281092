(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.help-search')
    .constant('HELP_SEARCH_ALLOWLIST_DOMAINS', {
      ADOBE: 'adobe.com',
    })
    .constant('SEARCH_SCOPE', {
      ADOBE_COM: 'adobe_com',
      HELPX: 'helpx',
      LEARN: 'learn',
    });
})();
