import pick from 'lodash/pick';

class UiEventDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} options initialization options
   * @param {String} [options.componentName] source component name for this event
   * @param {String} [options.eventAction] event action that triggers this event (e.g. click)
   * @param {String} [options.eventName] event name (e.g. chat)
   * @param {Object} [options.interaction] interaction object containing click or impression
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['eventAction', 'eventName', 'interaction', 'name']));
  }
}

export default UiEventDescriptor;
