/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
import log from 'services/log';

const registeredHandlers = {};

const defaultNavigationUsageWarning = () => {
  log.warn(
    'A default handler was used for navigation. You should call navBus.registerNavigation() to replace these with your application navigation needs.'
  );
};

const defaultNavigate = (url) => {
  defaultNavigationUsageWarning();
  window.location.href = url;
};

const defaultReload = () => {
  defaultNavigationUsageWarning();
  window.location.reload();
};

const defaultPushState = (state, url) => {
  defaultNavigationUsageWarning();
  window.history.pushState(state, '', url);
};

const defaultReplaceState = (state, url) => {
  defaultNavigationUsageWarning();
  window.history.replaceState(state, '', url);
};

// The Nav Bus replicates the functionality of $state functions
// in Angular.  It is meant as a temporary bridge between legacy src
// code and newer src2 modules. It should be replaced with react-based
// routing once all routes are migrated out of src.
const navBus = {
  /**
   * Trigger the $state.go handler for a specific route and context
   * @deprecated - use navBus.navigate instead - In most cases, this should be possible by simply changing the function name
   *               however, if you are using the context parameter, you will need to branch based on the migration status of the route
   *
   * @param {String} routeId - the identifier for this route
   * @param {Object} context - a set of contextual params to pass along to the state
   * @returns {Promise} the promise returned by $state.go
   */
  go: (routeId, context) => registeredHandlers.go(routeId, context),
  /**
   * Trigger the $state.href handler for a specific route and context
   *
   * @param {String} routeId - the identifier for this route
   * @param {Object} context - a set of contextual params to pass along to the state
   * @returns {String} the compiled URL returned by $state.href
   */
  href: (routeId, context) => registeredHandlers.href(routeId, context),
  migrationUtils: {},
  /**
   * Navigates to a destination and chooses a navigation method based on its migration status
   * @param {String} href - The URL to navigate to
   */
  navigate: (url, options) => {
    if (!registeredHandlers.isLocationReactRoute) {
      log.warn(
        'Migration utils not registered, cannot determine how to navigate. Call registerMigrationUtils()'
      );
      defaultNavigate(url);
      return;
    }

    if (navBus.migrationUtils.isLocationReactRoute(url)) {
      if (registeredHandlers.navigate) {
        registeredHandlers.navigate({url, ...options});
        return;
      }

      log.warn('navigate() not registered, call registerNavigation()');
    } else {
      if (registeredHandlers.navigateAngular) {
        registeredHandlers.navigateAngular(url);
        return;
      }

      log.warn('navigateAngular() not registered, call registerNavigateAngular()');
    }

    defaultNavigate(url);
  },
  /** Calls the registered pushState method from @see {registerNavigation} */
  pushState: (options) => {
    if (registeredHandlers.pushState) {
      registeredHandlers.pushState(options);
    } else {
      defaultPushState(options.state, options.url);
    }
  },
  /** Registers checks to determine if pages are migrated to React */
  registerMigrationUtils: ({isLocationReactRoute}) => {
    registeredHandlers.isLocationReactRoute = isLocationReactRoute;

    navBus.migrationUtils.isLocationReactRoute = (location) =>
      registeredHandlers.isLocationReactRoute(location);
  },
  /** Registers a method which will navigate to an Angular state by href */
  registerNavigateAngular: (stateCallback) => {
    registeredHandlers.navigateAngular = stateCallback;
  },
  /**
   * Register various navigation methods used by the browser/window
   * @param {{onNavigate: Function, onPushState: Function, onReload: Function, onReplaceState: Function}}
   */
  registerNavigation: ({onNavigate, onPushState, onReload, onReplaceState}) => {
    registeredHandlers.navigate = onNavigate;
    registeredHandlers.reload = onReload;
    registeredHandlers.replaceState = onReplaceState;
    registeredHandlers.pushState = onPushState;
  },
  /**
   * Register a callback function to process $state.go
   *
   * @param {Function} callbackFn - the function to register for triggering $state.go
   */
  registerStateGo: (stateCallback) => {
    registeredHandlers.go = stateCallback;
  },
  /**
   * Register a callback function to process $state.go
   *
   * @param {Function} callbackFn - the function to register for triggering $state.href
   */
  registerStateHref: (stateCallback) => {
    registeredHandlers.href = stateCallback;
  },
  /** Calls the registered reload method from @see {registerNavigation} */
  reload: () => {
    if (registeredHandlers.reload) {
      registeredHandlers.reload();
    } else {
      defaultReload();
    }
  },
  /** Calls the registered replaceState method from @see {registerNavigation} */
  replaceState: (options) => {
    if (registeredHandlers.replaceState) {
      registeredHandlers.replaceState(options);
    } else {
      defaultReplaceState(options.state, options.url);
    }
  },
};

export default navBus;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
