import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {Children} from 'react';

/**
 * PageBanners section component used for Page.
 * Wraps one or more PageBanner/SophiaBanner which can be conditionally displayed.
 * Each banner should have a marginBottom of the equivalent of 8px so that multiple banners are
 * spaced equally apart.
 * In addition there is a bit of margin under the PageBanners to seperate them from the PageHeader.
 */
const PageBanners = ({children}) => {
  const banners = Children.toArray(children);

  return (
    banners.length > 0 && (
      <View data-testid="page-banners" marginBottom="size-200">
        {children}
      </View>
    )
  );
};

PageBanners.displayName = 'PageBanners';
PageBanners.propTypes = {
  /**
   * PageBanner elements to display.
   * children are not required so that 'PageBanner' components may be conditionally displayed.
   */
  children: PropTypes.node,
};

export default PageBanners;
