(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   */
  angular
    .module('binky.widgets.omni-tool.drawer')
    .directive('binkyOmniToolTabs', omniToolTabsDirective);

  /* @ngInject */
  function omniToolTabsDirective(
    $compile,
    _,
    OMNI_TOOL_FAKES_PANEL,
    OMNI_TOOL_FEATURES_PANEL,
    omniToolPanelManager
  ) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      template: '',
    };
    return directive;

    /////////////////

    function linkFn(scope, element) {
      const tabList = new Coral.TabList();
      scope.featureTab = new Coral.Tab();
      scope.featureTab.set({label: {textContent: OMNI_TOOL_FEATURES_PANEL.TEXT}});
      scope.fakeTab = new Coral.Tab();
      scope.fakeTab.set({label: {textContent: OMNI_TOOL_FAKES_PANEL.TEXT}});
      tabList.items.add(scope.featureTab);
      tabList.items.add(scope.fakeTab);
      // setup panel manager to keep track of which panel is selected
      const featureTabElement = angular.element(scope.featureTab);
      featureTabElement.on('click', onSwitchTabs);
      const fakeTabElement = angular.element(scope.fakeTab);
      fakeTabElement.on('click', onSwitchTabs);

      // do not attempt to set/change the 'id' member on the Coral.PanelStack
      // class instance, as this will disconnect the stack from its panels,
      // resulting in a broken TabView
      const panelStack = new Coral.PanelStack();
      setupPanel(panelStack, `<binky-omni-tool-features-panel></binky-omni-tool-features-panel>`);
      setupPanel(panelStack, `<binky-omni-tool-fakes-panel></binky-omni-tool-fakes-panel>`);

      tabList.set({target: panelStack});
      const tabView = new Coral.TabView();
      tabView.set({panelStack, tabList});

      element.empty().append(tabView);

      function onSwitchTabs(event) {
        const panelId = _.toLower(event.srcElement.innerText);
        omniToolPanelManager.switchTo(`${panelId}-panel`);
      }

      function setupPanel(panels, panelInnerHTML) {
        const panelContent = `<coral-panel-content>${panelInnerHTML}</coral-panel-content>`;
        const contentElement = $compile(panelContent)(scope)[0];
        panels.items.add({content: contentElement});
      }
    }
  }
})();
