class WorkspaceNavItem {
  /**
   * @description Constructor for WorkspaceNavItem model Objects.
   * @param {Object} options - Top level wrapper object.
   * @param {string} [options.href] - href that this nav item should navigate to.
   * @param {WorkspaceNavItem[]} [options.items] - The child nav items if this
   *     workspaceNavItem represents a group.
   * @param {string} [options.key] - Unique key assigned to the nav item (only
   *     used for non group WorkspaceNavItems)
   * @param {string} [options.name] - name to use for group label
   *     (if represents a group), or label for the workspace nav item
   *     (if represents a single item).
   * @param {number} [options.order] - list order for this nav item, if omitted
   *     will be derived from the lowest value "order" from the children in the
   *     items property.
   */
  constructor({analysisId, href, icon, items, key, name, order, testId}) {
    Object.assign(this, {
      analysisId,
      href,
      icon,
      items,
      key,
      name,
      order,
      testId,
    });

    if (this.order === undefined || this.order === null) {
      // no order provided, so compute order for this item based on contents
      this.order = Math.min(...this.items.map((item) => item.order));
    }
  }

  /**
   * @description Method to determine if this nav item represents a group of
   *     navigable items or not.
   * @return {boolean} true if this represents a group, else false
   */
  isGroup() {
    return !!this.items;
  }
}

export default WorkspaceNavItem;
