(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilInvitations
   * @description defines service to manage jil invitations resources
   */
  angular.module('binky.core.api.jil').provider('jilInvitations', jilInvitationsProvider);

  let url;

  function jilInvitationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/invitations`;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        invitations: getInvitationsResource(),
      };

      return service;

      ///////////

      function getInvitationsResource() {
        return $resource(
          `${url}/:invitationCode`,
          {
            invitationCode: '@invitationCode',
          },
          {
            accept: {method: 'PUT'},
          }
        );
      }
    }
  }
})();
