(function () {
  'use strict';
  /**
   * @deprecated use Pandora fileSize utils: https://git.corp.adobe.com/PandoraUI/ui-components/blob/master/packages/react-formatted-file-size/src/utils.ts
   * Important notes:
   * - bytesToString has been renamed to fetchReadableUnitsOfStorage in src2
   * - fetchReadableUnitsOfStorage returns an object not the formatted string.
   *    Components moving forward should define the string, not a service.
   * - src2 bytesToString returns a string representation of the number now
   *    instead of a Number to allow for larger numbers like BigInt and
   *    BigDecimal values that are not supported by Number.
   *
   * @ngdoc factory
   * @name FileSize
   * @description Service that encapsulates common File size utility functions
   */
  angular.module('binky.widgets.common.file-size').factory('FileSize', FileSizeService);

  function FileSizeService($filter, $translate, _, binkySrc2, feature, FILE_SIZE_UNIT) {
    // must maintain order of these labels, in order for calculations to remain correct
    const byteLabels = [
      FILE_SIZE_UNIT.B,
      FILE_SIZE_UNIT.KB,
      FILE_SIZE_UNIT.MB,
      FILE_SIZE_UNIT.GB,
      FILE_SIZE_UNIT.TB,
      // only support up to petabytes, since any number larger than that would exceed the
      // maximum safe Number value in JS
      FILE_SIZE_UNIT.PB,
    ];
    const kb = 1024;
    const stringKeyMap = {
      [FILE_SIZE_UNIT.B]: 'byte',
      [FILE_SIZE_UNIT.KB]: 'kilobyte',
      [FILE_SIZE_UNIT.MB]: 'megabyte',
      [FILE_SIZE_UNIT.GB]: 'gigabyte',
      [FILE_SIZE_UNIT.TB]: 'terabyte',
      [FILE_SIZE_UNIT.PB]: 'petabyte',
    };

    return {
      bytesToString,
      getNumBytes,
    };

    /**
     * @description Method to convert a Number of bytes into human-readable String,
     *   presenting that number using the most-appropriate units and out to the
     *   correct number of decimal places.
     * @param {BigInt | BigDecimal | Number | String} bytes - number of bytes to convert/create String for
     * @param {Number} decimals - number of decimal places to take calculation to
     *   (minus trailing zeros)
     * @returns {String} number of bytes, to decimal precision, followed by unit
     *   of measurement
     * @throws {Error} - if number of bytes exceeds maximum supported value in JS
     * @throws {TypeError} - if bytes are not a Number (e.g. - String, etc)
     */
    function bytesToString(bytes, decimals) {
      if (feature.isEnabled('bug_fix_25344')) {
        const calculatedBytes = binkySrc2.utils.fileSizeUtils.fetchReadableUnitsOfStorage(
          bytes,
          decimals
        );
        const decimalPlaces = _.isNil(decimals) ? 2 : decimals;
        // The binkySrc2 version of this function, fetchReadableUnitsOfStorage,
        // returns a binkySrc2 FileSize object not a string. Components should
        // be responsible for creating strings, not services.
        return formatReturnValue(calculatedBytes.count, decimalPlaces, calculatedBytes.units);
      }

      if (!_.isNumber(bytes)) {
        throw new TypeError(
          `The bytes param must be a Number, instead encountered: ${typeof bytes}`
        );
      }
      if (bytes > Number.MAX_SAFE_INTEGER) {
        throw new Error(`The bytes param value must be less than ${Number.MAX_SAFE_INTEGER}`);
      }

      const decimalPlaces = _.isNil(decimals) ? 2 : decimals;
      const index = Math.floor(Math.log(Math.abs(bytes)) / Math.log(kb));

      if (bytes === 0) {
        return formatReturnValue(0, decimalPlaces, FILE_SIZE_UNIT.B);
      }
      return formatReturnValue(
        parseFloat(bytes / Math.pow(kb, index)),
        decimalPlaces,
        byteLabels[index]
      );
    }

    /**
     * @description Method to convert a given number of bytes per unit of measurement
     *   (e.g. - KB, MB, etc) into just bytes (B).
     * @param {Number} number - number of bytes to convert
     * @param {String} unit - unit of measurement to convert from
     *
     * @returns {String} representation of the number of bytes
     * @returns {Number} number of bytes if bug_fix_25344 is disabled
     * @throws {Error} - if number of bytes exceeds max Number value in JS (can not
     *   guarantee accuracy of calculation, at this point). Remove with bug_fix_25344.
     * @throws {TypeError} - if number param is not a Number (e.g. - String, etc). Remove with bug_fix_25344.
     */
    function getNumBytes(number, unit) {
      if (feature.isEnabled('bug_fix_25344')) {
        // The src2 getNumBytes returns a string representation of a number
        // with potentially decimals instead of a rounded  number.
        return binkySrc2.utils.fileSizeUtils.getNumBytes(number, unit);
      }

      if (!_.isNumber(number)) {
        throw new TypeError(
          `The number param must be a number, instead encountered: ${typeof number}`
        );
      }
      if (!_.includes(byteLabels, unit)) {
        throw new Error(`Unsupported unit (${unit}) encountered`);
      }
      const index = _.indexOf(byteLabels, unit);
      if (Number.isSafeInteger(Number.parseFloat((number * Math.pow(kb, index)).toFixed(0)))) {
        return Number.parseFloat((number * Math.pow(kb, index)).toFixed(0));
      }
      throw new Error(
        `The requested operation exceeds the limits for precise floating point calculations. Please check the values and try again.`
      );
    }

    ////////

    function formatReturnValue(number, fractionSize, units) {
      return $translate.instant(
        `binky.widgets.common.fileSize.unit.${stringKeyMap[units]}.messageFormat.abbreviated`,
        {
          count: number,
          formattedCount: $filter('number')(number, fractionSize),
        },
        'messageformat'
      );
    }
  }
})();
