import {Flex, Switch, Text, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-toggle-section
 */

/**
 * ToggleSection is a collapsible widget with a switch. The section is expanded when the switch is toggled.
 */

const ToggleSection = ({
  children,
  defaultChecked = false,
  disabled = false,
  maxWidth,
  onToggle,
  toggleDescription,
  toggleLabel,
}) => {
  const [isEnabled, setIsEnabled] = useState(defaultChecked);
  const descriptionId = useId();

  const onChange = (toggleState) => {
    setIsEnabled(toggleState);
    onToggle(toggleState);
  };

  return (
    <View
      backgroundColor="gray-50"
      borderRadius="regular"
      data-testid="toggle-section"
      marginBottom="size-200"
      maxWidth={maxWidth}
      minHeight="size-700"
      paddingBottom="size-250"
      paddingEnd="size-300"
      paddingStart="size-300"
      paddingTop="size-150"
    >
      <View flexGrow={1}>
        <Flex direction="column" width="100%">
          <Switch
            aria-describedby={descriptionId}
            defaultSelected={defaultChecked}
            isDisabled={disabled}
            isEmphasized
            onChange={onChange}
            UNSAFE_style={{fontWeight: 'bold'}}
          >
            {toggleLabel}
          </Switch>
          <View marginStart="size-450">
            <Text data-testid="toggle-description" id={descriptionId}>
              {toggleDescription}
            </Text>
            {isEnabled && children && <View marginTop="size-100">{children}</View>}
          </View>
        </Flex>
      </View>
    </View>
  );
};

ToggleSection.propTypes = {
  /**
   * The content to display in the ToggleSection.
   */
  children: PropTypes.node,
  /**
   * Whether the ToggleSection is default checked. Defaults to false.
   */
  defaultChecked: PropTypes.bool,
  /**
   * Whether the ToggleSection is disabled. Defaults to false.
   */
  disabled: PropTypes.bool,
  /**
   * Max width setting of the the ToggleSection.
   */
  maxWidth: PropTypes.string,
  /**
   * Handler that is called when the ToggleSection is toggled.
   */
  onToggle: PropTypes.func.isRequired,
  /**
   * The description to be displayed for the ToggleSection.
   */
  toggleDescription: PropTypes.string.isRequired,
  /**
   * The label to be displayed for the ToggleSection.
   */
  toggleLabel: PropTypes.string.isRequired,
};

export default ToggleSection;
