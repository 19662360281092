(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ExtendedUserProfile
   * @description Abstract model for an extended user profile.
   */
  angular
    .module('binky.core.extended-user-profile')
    .factory('ExtendedUserProfile', getExtendedUserProfileModel);

  /* @ngInject */
  function getExtendedUserProfileModel(
    $log,
    $q,
    EXTENDED_USER_PROFILE_CACHE_ID,
    jilExtendedUserProfile,
    Member,
    modelCache
  ) {
    class ExtendedUserProfile {
      /**
       * @description Creates a new ExtendedUserProfile.
       *
       * @param {Object} options options for the ExtendedUserProfile as detailed below
       * @param {String} options.id An ExtendedUserProfile's ID
       */
      constructor(options = {}) {
        this.id = options.id;
      }

      /**
       * @description Method to update the current object with data object response.
       *              Not called a "Transformer" like other models as this method does not create.
       *
       * @param {Object} response - Object containing user data (e.g. - DTO)
       */
      // Disable these rules to allow the abstract method to exist
      // eslint-disable-next-line class-methods-use-this, no-unused-vars
      apiResponseUpdater(response) {
        throw new Error('This method should be overridden by sub-classes');
      }

      // The cache key generated for this object
      key() {
        return this.id;
      }

      /**
       * @description Method to refresh this user profile's state from the back-end.
       *
       * @returns {Promise} resolves to fetched ExtendedUserProfile instance, else
       *                    rejects with error message
       */
      refresh() {
        this.$resolved = false;
        const refreshParams = {id: this.id};
        this.$promise = jilExtendedUserProfile.extendedProfile
          .get(refreshParams)
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess(response) {
          this.apiResponseUpdater(response);
          modelCache.put(EXTENDED_USER_PROFILE_CACHE_ID, this, this.key());
          return this;
        }

        function onError(error) {
          $log.error(
            'ExtendedUserProfile.refresh(): Failed to retrieve user profile. Error: ',
            error
          );
          return $q.reject(error);
        }

        return this.$promise;
      }

      /**
       * @description Method to run during the run-ready startup phase of Binky.
       *              If there no action to take, inheriting classes can ignore it.
       *
       * @returns {Promise} resolves when all actions are complete.
       */
      // eslint-disable-next-line class-methods-use-this
      runReady() {
        throw new Error('This method should be overridden by sub-classes');
      }

      /**
       * @description Method to update an existing ExtendedUserProfile.
       *              Will save the current state of the object to the cache on success.
       *
       * @param {Array} patchPayload - Array of Patch commands
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      update(patchPayload) {
        this.$resolved = false;

        this.$promise = jilExtendedUserProfile.extendedProfile
          .update({id: this.id}, patchPayload)
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;

        function onSuccess() {
          modelCache.put(EXTENDED_USER_PROFILE_CACHE_ID, this, this.key());
          return this;
        }

        function onError(error) {
          $log.error('ExtendedUserProfile.update(): Failed to update user profile. Error: ', error);
          return $q.reject(error);
        }
      }

      /**
       * @description Method to retrieve an existing ExtendedUserProfile from
       *              back-end data store.
       *              All derived classes are expected to implement their own version of this,
       *              in order to be pulled correctly by the extended-user-provider ready provider.
       */
      static get() {
        throw new Error('This method should be overridden by sub-classes');
      }

      /**
       * @description Method to retrieve an existing ExtendedUserProfile from
       *              back-end data store.
       *
       * @param {Class} ExtendedUserProfileClass - Inherited Class-type to create if needed
       * @param {Class} id - the User ID to lookup the extended profile for
       * @returns {ExtendedUserProfile} Reference to the retrieved user profile, or a blank one if it doesn't exist on the backend
       */
      static getWithId(ExtendedUserProfileClass, id) {
        let model = new ExtendedUserProfileClass({id});
        const cachedModel = modelCache.get(EXTENDED_USER_PROFILE_CACHE_ID, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    // only need to store 1 value in the cache since this should only run against the current user
    Member.setupCache(EXTENDED_USER_PROFILE_CACHE_ID, 1);

    return ExtendedUserProfile;
  }
})();
