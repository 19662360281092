/**
 * The app's page footer.
 */
import {
  AnalyticsEvent,
  SELECTED_LOCALE_KEY,
  localeUtils,
  reloadWithPersistentData,
  setLocalStorageItem,
} from '@admin-tribe/binky';
import {Flex, Link, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import GlobeGrid from '@spectrum-icons/workflow/GlobeGrid';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import GoUrl from 'common/components/go-url/GoUrl';

import LanguagePicker from '../../common/components/language-picker/LanguagePicker';

import CookiePreferences from './cookie-preferences/CookiePreferences';

const Footer = ({
  showAdChoices = true,
  showCookiePreferences = true,
  showDoNotSharePersonalInfo = true,
  showLanguagePicker = true,
}) => {
  const intl = useIntl();
  const currentYear = new Date().getFullYear();
  const MARGIN_END = 'size-200';
  const LINK_VARIANT = 'secondary';

  const globeIconId = useId();

  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- cframpto@ to update
  function getGoUrl(name) {
    return (
      <GoUrl isQuiet marginEnd={MARGIN_END} name={name} variant={LINK_VARIANT}>
        {name === 'aac_cookie_pref'
          ? 'AdChoices'
          : intl.formatMessage({id: `binky.shell.footer.${name}`})}
      </GoUrl>
    );
  }

  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- @zachb to update
  function getUnlocalizedGoUrl(name) {
    const triggerAnalytics = () => {
      AnalyticsEvent.dispatch({
        attributes: {
          goUrlName: name,
        },
        componentMethod: 'goUrl',
        componentMethodType: 'submit',
        componentName: 'Footer',
      });
    };

    return (
      <Link
        data-testid={name}
        isQuiet
        marginEnd="size-200"
        onPress={triggerAnalytics}
        variant="secondary"
      >
        <a href={`https://www.adobe.com/go/${name}`} rel="noopener noreferrer" target="_blank">
          <span>{intl.formatMessage({id: `binky.shell.footer.${name}`})}</span>
        </a>
      </Link>
    );
  }

  function updateLocalStorageAndReload(key) {
    setLocalStorageItem(SELECTED_LOCALE_KEY, key);
    // If a query param is set, delete it. Since the href is changed it causes the browser to reload.
    if (localeUtils.getQueryParam()) {
      localeUtils.deleteQueryParam();
    } else {
      reloadWithPersistentData();
    }
  }
  // eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- ybarra@ to update
  function renderGlobeAndLanguagePicker() {
    return (
      <Flex alignItems="center" direction="row" justifyContent="flex-start">
        <GlobeGrid
          aria-label={intl.formatMessage({id: 'binky.common.languagePicker.icon.label'})}
          data-testid="global-grid-icon"
          id={globeIconId}
          marginEnd="size-50"
          position="relative"
          size="S"
        />
        <LanguagePicker
          aria-labelledby={globeIconId}
          data-testid="language-picker"
          defaultSelectedKey={intl.locale}
          isQuiet
          onSelectionChange={updateLocalStorageAndReload}
        />
      </Flex>
    );
  }
  // If the footer is an immediate descent of the <body> tag, role="contentinfo" is implied.
  // Since that is not currently the case we'll explicitly label this as the page footer.
  return (
    <footer role="contentinfo">
      <View backgroundColor="gray-100" borderTopColor="mid" borderTopWidth="thin">
        <Flex
          alignItems="center"
          direction="row"
          gap="size-200"
          height="size-600"
          justifyContent="space-between"
          marginEnd="size-200"
          marginStart="size-200"
        >
          <Flex alignItems="center" direction="row" justifyContent="flex-start">
            {/* left side */}
            {getGoUrl('terms')}
            {getGoUrl('privacy')}
            {showAdChoices ? getGoUrl('aac_cookie_pref') : null}
            {showCookiePreferences ? (
              <CookiePreferences isQuiet marginEnd={MARGIN_END} variant={LINK_VARIANT} />
            ) : null}
            {showDoNotSharePersonalInfo ? getUnlocalizedGoUrl('ca-rights') : null}
            {showLanguagePicker ? renderGlobeAndLanguagePicker() : null}
          </Flex>
          <View>
            {/* right side */}
            <span data-testid="legal">&copy; {currentYear} Adobe. All rights reserved.</span>
          </View>
        </Flex>
      </View>
    </footer>
  );
};

Footer.propTypes = {
  /**
   * Whether or not to show AdChoices in the footer. The default is true.
   */
  showAdChoices: PropTypes.bool,
  /**
   * Whether or not to show CookiePreferences in the footer. The default is true.
   */
  showCookiePreferences: PropTypes.bool,
  /**
   * Whether or not to show the Do not sell or share my personal information
   * link in the footer. This link takes users to CA (California) rights information
   * that explains additional rights to privacy that residents of this locale can
   * enjoy/pursue. The default is true.
   */
  showDoNotSharePersonalInfo: PropTypes.bool,
  /**
   * Whether or not to show the language picker in the footer. The default is true.
   */
  showLanguagePicker: PropTypes.bool,
};

export default Footer;
