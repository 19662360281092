import {Content, Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

const {DEFAULT_MSG_HEIGHT, DEFAULT_MSG_WIDTH} = ASSIGNMENT_MENU_CONSTANTS;

// A component to show that the user's search query has failed and a prompt to ask them to try again. Shared between menus within AssignmentMenu.
const MenuError = () => (
  <View height={DEFAULT_MSG_HEIGHT} width={DEFAULT_MSG_WIDTH}>
    <IllustratedMessage>
      {/* No illustration, only using IllustratedMessage for text styling */}
      {/* Modals will have a h2 at the top, so set this heading to h3 */}
      <Heading level={3}>
        <FormattedMessage id="binky.common.assignmentMenu.shared.messages.error.heading" />
      </Heading>
      <Content>
        <FormattedMessage id="binky.common.assignmentMenu.shared.messages.error.content" />
      </Content>
    </IllustratedMessage>
  </View>
);

MenuError.propTypes = {};

export default MenuError;
