import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import toPlainObject from 'lodash/toPlainObject';

import {keysToCamelCase} from '../../utils/objectUtils';
import FulfillableItemList from '../fulfillableItemList/FulfillableItemList';
import Offer from '../offers/Offer';

class FulfillmentEvent {
  /**
   * @description Method to construct a FulfillmentEvent from the JIL API
   *  response.
   *
   * @param {Object} fulfillmentEvent - a single fulfillment event.
   * @returns {FulfillmentEvent} reference to an FulfillmentEvent object.
   */
  static apiResponseTransformer(fulfillmentEvent) {
    return new FulfillmentEvent(fulfillmentEvent);
  }

  /**
   * @constructs FulfillmentEvent
   * @description Constructor for FulfillmentEvent model objects.
   * @param {Object} options Wrapper object for FulfillmentEvent properties.
   */
  constructor(options) {
    if (options) {
      applyResource.apply(this, [options]);
    }
  }

  /**
   * @description Method to get the sorted offer names for this fulfillment
   *  event.
   *
   * @returns {string[]} Array of offer names sorted alphabetically.
   */
  getSortedOfferNames() {
    let offerNames = this.offerMetadata.map(
      (offer, idx) => this.offerMetadata[idx]?.merchandising?.copy?.name
    );
    offerNames = offerNames.filter((name) => !!name);
    return offerNames.sort();
  }

  toFulfillmentReturnItems() {
    return this.offers.map((offer) => {
      const acceptedTerms = {
        acceptedAgreement: 'INITIAL',
        contractId: offer.contractId,
      };
      return {
        acceptedTerms,
        offerId: offer.offerId,
        originalExternalItemId: offer.externalItemId,
        quantity: offer.quantity,
      };
    });
  }

  /**
   * @description Method to get the minimum object representation of this
   *  model.
   * @returns {Object} Object representing this fulfillment event's properties.
   */
  toMinimumModel() {
    return toMinimumObject(this);
  }
}

function applyResource(resource) {
  Object.assign(this, toMinimumObject(keysToCamelCase(toPlainObject(resource))));

  // Transforming the fulfillableItemList of the offerMetadata so that we can
  // determine summary labels.
  this.offerMetadata = resource.offer_metadata.map((originalOffer) => {
    const newOffer = new Offer(cloneDeep(originalOffer));
    if (newOffer.product_arrangement?.fulfillable_items) {
      newOffer.product_arrangement.fulfillableItemList = new FulfillableItemList(
        keysToCamelCase(cloneDeep(newOffer.product_arrangement.fulfillable_items))
      );
      // Applying the type property as fulfillableItemType so that getQuotaTypeItems call will
      // work as expected.
      newOffer.product_arrangement.fulfillableItemList.items =
        newOffer.product_arrangement.fulfillableItemList.items.map((item) => ({
          ...item,
          fulfillableItemType: item.type,
        }));
      delete newOffer.product_arrangement.fulfillable_items;
    }
    return newOffer;
  });
  return this;
}

function toMinimumObject(object) {
  return pick(object, [
    'external_request_id',
    'fcTime',
    'fnTime',
    'fulfillmentId',
    'lastFeTime',
    // Omitting offerMetadata because they need to be transformed into Offers without having
    // their properties turned into camelcase.
    'offers',
    'originalFulfillmentId',
    'ownerId',
    'requestId',
    'requestor',
    'requestType',
    'status',
  ]);
}

export default FulfillmentEvent;
