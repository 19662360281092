(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc directive
   * @name binky.widgets.common.force-positive-integer
   *
   * @description Directive for number inputs. Only allows number input from 0-9,
   * disallows "-", "e", and ".".
   *
   * @example <input type="number" min="0" force-positive-integer>
   *
   */
  angular
    .module('binky.widgets.common.force-positive-integer')
    .directive('binkyForcePositiveInteger', binkyForcePositiveInteger);

  function binkyForcePositiveInteger() {
    return {
      link,
      restrict: 'A',
    };

    ///////////////

    function link(scope, elm) {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      elm[0].onkeypress = function (evt) {
        // Keycode reference https://css-tricks.com/snippets/javascript/javascript-keycodes/
        // http://www.asquare.net/javascript/tests/KeyCode.html
        // if ((number)||backSpace||tab||upArrow||downArrow)
        const result =
          (evt.charCode > 47 && evt.charCode < 58) ||
          evt.keyCode === 8 ||
          evt.keyCode === 9 ||
          (evt.keyCode === 38 && evt.key !== '&') ||
          (evt.keyCode === 40 && evt.key !== '(');

        return result;
      };
    }
  }
})();
