import {MEMBER_TYPE, User, UserGroup, feature} from '@admin-tribe/binky';
import {
  ActionButton,
  Cell,
  Column,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {EN_DASH} from '@pandora/react-table-section';
import {
  PICKER_TYPE,
  UserPickerContextProvider as PandoraUserPickerContextProvider,
  UserPickerV3,
  UserPickerV3ContentModel,
} from '@pandora/react-user-picker';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import UserPicker from 'common/components/user-picker/UserPicker';
import UserPickerContextProvider from 'common/components/user-picker/UserPickerContext';
import {getPersonName} from 'features/user/personNameUtils';

import styles from './MemberManagement.pcss';
import mergeOperations from './mergeOperations';

const OPERATION = {
  ADD: 'add',
  REMOVE: 'remove',
};

/**
 * Reusable MemberManagement component supporting Users and UserGroups entities.
 * This component helps with adding or removing members for a storage folder,
 * app integration, or any other use case that needs user management.
 */
const MemberManagement = ({
  disabledTypes = [],
  emptyStateContentText,
  emptyStateHeadingText,
  excludeTechAccounts = false,
  existingMembers,
  onChange,
  pickerType = UserPicker.PICKER_TYPE.USERS_AND_GROUPS,
  orgId,
  tableAriaLabel,
}) => {
  const content = useContentEntry(UserPickerV3ContentModel);
  const [membersList, setMembersList] = useState(existingMembers);
  // This allOperations state holds is an array data structure representing ALL operations ('ADD' or 'REMOVE')
  // performed on the table. And the mergeOperations utility is what finally consolidates any repetitive actions
  // into an array of operations, which is then passed via the onChange call so the client knows the difference
  // compared with the existingMembers that is initially passed in
  const [allOperations, setAllOperations] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedKeys, setSelectedKeys] = useState(new Set());
  const clearAutocomplete = useRef();

  const intl = useIntl();

  // TableView calls back with string 'all' when all rows selected so in that case we convert it to a Set
  // containing all keys in membersList. Otherwise we just pass the state to the set.
  const convertToSetOrSave = (keys) => {
    if (keys === 'all') return setSelectedKeys(new Set(membersList.map((member) => member.id)));
    return setSelectedKeys(keys);
  };

  const selectMember = (member, _availableTypesList, setInputText) => {
    // Enclose the value of the setInputText in the ref
    clearAutocomplete.current = () => setInputText('');
    setSelectedMember(member);
  };

  // Edge case: If we have a member selected but then we delete or add characters from the User Picker input, then we need to
  // de-select member by disabling the Add button and also re-setting the member to empty object till a new valid selection
  const validateMember = (value) => {
    if (selectedMember.email && value.length !== selectedMember.email.length) setSelectedMember({});
  };

  const addMember = () => {
    // We have 2 cases that although member was selected in the UserPicker,
    // we do not want to add it to the Table:
    // 1. When user is already in the table
    // 2. If the user type is not in the disabledTypes
    if (
      !membersList.find((m) => m.id === selectedMember.id) &&
      !disabledTypes.includes(selectedMember.type)
    ) {
      setMembersList([selectedMember, ...membersList]);

      const tempOps = [
        ...allOperations,
        {key: selectedMember.id, member: selectedMember, type: OPERATION.ADD},
      ];
      setAllOperations(tempOps);
      onChange(mergeOperations(tempOps));
    }

    setSelectedMember({});

    // Clear the UserPicker's input text
    clearAutocomplete.current();
  };

  const removeMembers = () => {
    const membersToRemove = [];
    const toRemoveMap = new Map();

    selectedKeys.forEach((key) => {
      const user = membersList.find((member) => member.id === key);
      membersToRemove.push(user);
      toRemoveMap.set(key);
    });

    // Reset selected keys into the table view
    setSelectedKeys(new Set());

    const tempOps = [
      ...allOperations,
      ...membersToRemove.map((member) => ({key: member.id, member, type: OPERATION.REMOVE})),
    ];

    setAllOperations(tempOps);
    onChange(mergeOperations(tempOps));

    setMembersList(membersList.filter((member) => !toRemoveMap.has(member.id)));
  };

  // This function with be triggered by the TableView in the case of no rows a.k.a empty state
  const renderEmptyState = () => (
    <IllustratedMessage>
      <NotFound />
      <Heading>
        {emptyStateHeadingText ||
          intl.formatMessage({id: 'binky.common.membersManagement.tableView.emptyTable.heading'})}
      </Heading>
      <Content>
        {emptyStateContentText ||
          intl.formatMessage({id: 'binky.common.membersManagement.tableView.emptyTable.content'})}
      </Content>
    </IllustratedMessage>
  );

  return (
    <>
      {feature.isEnabled('temp_new_user_picker') ? (
        <Flex alignItems="end" direction="row" gap="size-100" justifyContent="space-between">
          <Flex alignItems="end" direction="row" gap="size-100" width="50%">
            <PandoraUserPickerContextProvider canAddUser={false}>
              <UserPickerV3
                content={content}
                disabledIds={membersList}
                disabledTypes={disabledTypes}
                excludeTechAccounts={excludeTechAccounts}
                label={intl.formatMessage({
                  id: 'binky.common.membersManagement.userPicker.label',
                })}
                onInputChange={validateMember}
                onSelect={selectMember}
                orgId={orgId}
                pickerType={pickerType}
                showUserType
                width="size-3600"
              />
            </PandoraUserPickerContextProvider>

            <ActionButton
              data-testid="add-member-button"
              isDisabled={!selectedMember.id || disabledTypes.includes(selectedMember.type)}
              onPress={addMember}
            >
              {intl.formatMessage({
                id: 'binky.common.membersManagement.addUser',
              })}
            </ActionButton>
          </Flex>
          <div aria-live="polite" role="status">
            <ActionButton
              data-testid="remove-member-button"
              isDisabled={selectedKeys.size === 0}
              onPress={removeMembers}
            >
              {intl.formatMessage(
                {
                  id: 'binky.common.membersManagement.removeUsers',
                },
                {count: selectedKeys.size}
              )}
            </ActionButton>
          </div>
        </Flex>
      ) : (
        <Flex alignItems="center" direction="row" gap="size-100" justifyContent="space-between">
          <Flex alignItems="center" direction="row" gap="size-100">
            <UserPickerContextProvider canAddUser={false}>
              <UserPicker
                className={styles['user-picker']}
                disabledList={membersList}
                disabledTypes={disabledTypes}
                excludeTechAccounts={excludeTechAccounts}
                label={intl.formatMessage({
                  id: 'binky.common.membersManagement.userPicker.label',
                })}
                message={{
                  value: intl.formatMessage({
                    id: 'binky.common.membersManagement.userPicker.message.value',
                  }),
                }}
                onInputChange={validateMember}
                onSelect={selectMember}
                orgId={orgId}
                pickerType={pickerType}
                searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                showTypes
              />
            </UserPickerContextProvider>

            <ActionButton
              data-testid="add-member-button"
              isDisabled={!selectedMember.id || disabledTypes.includes(selectedMember.type)}
              onPress={addMember}
            >
              {intl.formatMessage({
                id: 'binky.common.membersManagement.addUser',
              })}
            </ActionButton>
          </Flex>
          <div aria-live="polite" role="status">
            <ActionButton
              data-testid="remove-member-button"
              isDisabled={selectedKeys.size === 0}
              onPress={removeMembers}
            >
              {intl.formatMessage(
                {
                  id: 'binky.common.membersManagement.removeUsers',
                },
                {count: selectedKeys.size}
              )}
            </ActionButton>
          </div>
        </Flex>
      )}

      <TableView
        aria-label={
          tableAriaLabel ||
          intl.formatMessage({
            id: 'binky.common.membersManagement.tableView.ariaLabel',
          })
        }
        marginTop="size-300"
        // For an empty table we show the empty state which is a minHeight of size-2000,
        // otherwise the rows content sets the height
        minHeight={membersList.length === 0 ? 'size-3600' : 'size-0'}
        onSelectionChange={convertToSetOrSave}
        overflowMode="wrap"
        renderEmptyState={renderEmptyState}
        selectedKeys={selectedKeys}
        selectionMode="multiple"
      >
        <TableHeader>
          <Column>
            {intl.formatMessage({
              id: 'binky.common.membersManagement.tableView.name',
            })}
          </Column>
          <Column>
            {intl.formatMessage({
              id: 'binky.common.membersManagement.tableView.email',
            })}
          </Column>
        </TableHeader>
        <TableBody>
          {membersList.map((member) => {
            // User Group Rows
            if (member.type === MEMBER_TYPE.USER_GROUP) {
              return (
                <Row key={member.id}>
                  <Cell>{member.name}</Cell>
                  <Cell>{EN_DASH}</Cell>
                </Row>
              );
            }
            // Type1-Type3 Users Rows
            return (
              <Row key={member.id}>
                <Cell>{getPersonName(intl, member)}</Cell>
                <Cell>{member.email}</Cell>
              </Row>
            );
          })}
        </TableBody>
      </TableView>
    </>
  );
};

MemberManagement.propTypes = {
  /**
   * The disabledTypes is for when all rows of a certain user type (TYPE1-TYPE3, USER_GROUP) needs to be disabled in the User Picker
   */
  disabledTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * The emptyStateContentText is to change the content of the inner empty table view text
   */
  emptyStateContentText: PropTypes.string,
  /**
   * The emptyStateContentText is to change the heading of the inner empty table view text
   */
  emptyStateHeadingText: PropTypes.string,
  /** Omits technical accounts from UserPicker results when true. Defaults to false.  */
  excludeTechAccounts: PropTypes.bool,
  /**
   * Members that will displayed on the table view on the first render. The UserPicker will have them disabled from selection
   */
  existingMembers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(User), PropTypes.instanceOf(UserGroup)])
  ).isRequired,
  /**
   * This callback will fire whenever is a state change in the table i.e. additions or deletions of rows
   * The onChange will be called with
   * PropTypes.arrayOf(
   *   PropTypes.shape({
   *     key: PropTypes.string,
   *     member: PropTypes.oneOfType([
   *       PropTypes.instanceOf(OrganizationUser),
   *       PropTypes.instanceOf(UserGroup),
   *     ]),
   *     type: PropTypes.string,
   *   })
   * )
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The ID of the organization
   */
  orgId: PropTypes.string.isRequired,
  /**
   * User Picker pickerType option to be passed into the component
   */
  pickerType: PropTypes.oneOf(Object.values(PICKER_TYPE)),
  /**
   * Used if a custom aria-label prop is needed on the TableView
   */
  tableAriaLabel: PropTypes.string,
};

export default MemberManagement;
