(function () {
  /**
   * @deprecated ES6 code uses mobx
   *
   * @ngdoc factory
   * @name ModelError
   * @description Standard model for use in throwing errors from models
   */
  angular.module('binky.core.common.model').factory('ModelError', modelError);

  /* @ngInject */
  function modelError(_) {
    class ModelError extends Error {
      /**
       * @class
       * @description Creates a new ModelError for use.
       *
       * @param {Object} [options] - ModelError instance settings
       * @param {Object} [options.model] - The model throwing an exception (e.g., Member)
       * @param {Error} [options.message] - The message to report
       */
      constructor(options = {}) {
        super(options.message);
        this.name = 'ModelError';
        this.model = _.get(options.model, 'constructor.name');
      }

      static throwUnimplementedMethodException(model) {
        throw new ModelError({message: ModelError.UNIMPLEMENTED_METHOD, model});
      }
    }

    ModelError.UNIMPLEMENTED_METHOD = 'Unimplemented method!';

    return ModelError;
  }
})();
