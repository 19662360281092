import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import {action, makeObservable, observable, toJS} from 'mobx';

import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';

class MemberConfigurationRoles {
  /**
   *
   * @param {Object} options for init
   * @returns {Promise<MemberConfigurationRoles>} - a promise that resolves to MemberConfigurationRoles
   */
  static get(options) {
    const model = new MemberConfigurationRoles(options);
    return model.refresh();
  }

  availableRoles;
  description;
  learnMoreHref;
  memberRoles;

  /**
   * @class
   * @description Store for fetching and updating member configuration roles (used for things like Sign product roles)
   *
   * @param {Object} options - the options object
   * @param {String} options.orgId - the org id
   * @param {String} options.productId - the product id
   * @param {String} options.licenseGroupId - the license group id
   * @param {Array<String>} options.memberIds - ids of the members to fetch roles for, if not defined, fetch
   */
  constructor(options) {
    makeObservable(this, {
      availableRoles: observable,
      description: observable,
      learnMoreHref: observable,
      memberRoles: observable,
      setAvailableRoles: action,
      setDescription: action,
      setLearnMoreHref: action,
      setMemberRoles: action,
    });

    Object.assign(this, options);
  }

  hasUnsavedChanges() {
    return !isEqual(this.memberRoles, this.savedState);
  }

  async refresh() {
    const response = await jilOrganizationsProductsLicenseGroups.getLicenseGroupMemberConfiguration(
      {
        licenseGroupId: this.licenseGroupId,
        memberIds: this.memberIds,
        orgId: this.orgId,
        productId: this.productId,
      }
    );

    const memberRoles = {};
    const availableRolesSet = new Set();
    response.data.forEach((responseItem) => {
      this.setDescription(responseItem.description);
      this.setLearnMoreHref(responseItem.learnMoreHref);

      responseItem.availableRoles
        .map((availableRole) =>
          // we store the parentId for each role, to inject into PATCH modifications later
          ({responseParentId: responseItem.id, ...availableRole})
        )
        .forEach((item) => availableRolesSet.add(item));

      responseItem.licenseGroupMembers.forEach((licenseGroupMember) => {
        memberRoles[licenseGroupMember.id] = licenseGroupMember.roles;
      });
    });

    this.setAvailableRoles([...availableRolesSet]);
    this.setMemberRoles(memberRoles);
    this.registerSavedState();
    return this;
  }

  registerSavedState() {
    this.savedState = cloneDeep(toJS(this.memberRoles));
  }

  restore() {
    if (this.hasUnsavedChanges()) {
      this.setMemberRoles(cloneDeep(this.savedState));
    }
  }

  async save() {
    if (!this.memberRoles || !this.availableRoles) {
      return this;
    }
    let patchOperations = [];
    Object.keys(this.memberRoles).forEach((memberId) => {
      const memberRoles = toJS(this.memberRoles)[memberId];
      const availableRoles = toJS(this.availableRoles);
      const savedMemberRoles = this.savedState[memberId];
      const addedRoles = difference(memberRoles, savedMemberRoles);
      const removedRoles = difference(savedMemberRoles, memberRoles);
      patchOperations = union(
        patchOperations,
        addedRoles.map((value) => ({
          op: 'add',
          path: `${processPrefix(memberId, availableRoles, value)}`,
          value,
        })),
        removedRoles.map((value) => ({
          op: 'remove',
          path: `${processPrefix(memberId, availableRoles, value)}/${value}`,
        }))
      );
    });

    if (patchOperations.length > 0) {
      await jilOrganizationsProductsLicenseGroups.patchLicenseGroupMemberConfiguration(
        {
          licenseGroupId: this.licenseGroupId,
          orgId: this.orgId,
          productId: this.productId,
        },
        patchOperations
      );
      this.registerSavedState();
    }

    return this;

    function processPrefix(memberId, availableRoles, value) {
      const availableRole = availableRoles.find((element) => element.id === value);
      let response = `/licenseGroupMembers/${memberId}/roles`;
      if (availableRole && availableRole.responseParentId) {
        response = `/${availableRole.responseParentId}${response}`;
      }
      return response;
    }
  }

  setAvailableRoles(availableRoles) {
    this.availableRoles = availableRoles;
  }

  setDescription(description) {
    this.description = description;
  }

  setLearnMoreHref(learnMoreHref) {
    this.learnMoreHref = learnMoreHref;
  }

  setMemberRoles(memberRoles) {
    this.memberRoles = memberRoles;
  }
}

export default MemberConfigurationRoles;
