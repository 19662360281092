/**
 * @deprecated use Pandora Insights components
 *
 * @ngdoc component
 * @name binky.widgets.insights.create-insights-item-detail-modal:binkyCreateInsightsItemDetailModal
 *
 * @description The modal for creating insights item detail.
 *
 * @param {Function} onSuccessCreateInsightsItemDetail - a function invoked when item is created.
 *   Invoked with {isNew, item, section}.
 *
 */
(function () {
  'use strict';
  angular
    .module('binky.widgets.insights.create-insights-item-detail-modal')
    .component('binkyCreateInsightsItemDetailModal', {
      bindings: {
        contractInfoList: '<?',
        onSuccessCreateInsightsItemDetail: '&?',
      },
      controller,
      templateUrl:
        'widgets/insights/create-insights-item-detail-modal/create-insights-item-detail-modal.component.html',
    });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    _,
    binkySrc2,
    binkyUISrc2,
    COMMON_EVENTS,
    CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID,
    feature,
    INSIGHTS_ITEM,
    InsightsItemDetail,
    panelManager,
    toastManager,
    translationUtils
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      insightsUtils: binkySrc2.services.insights.insightsUtils,
      isCreateDisabled,
      onChangeDateRange,
      onClose,
      onCreate,
      onOpen,
      onSelectDropdownOption,
    });

    function $onInit() {
      _.assign(vm, {
        CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID,
        insightsDateRange: binkyUISrc2.components.insights.InsightsDateRange,
        insightsDropdown: binkyUISrc2.components.insights.InsightsDropdown,
        isCreating: false,
        promiseToWait: $q.resolve(),
      });
    }

    function isCreateDisabled() {
      const contractsContent = _.find(vm.dropdownContents, {
        key: INSIGHTS_ITEM.DROPDOWN_TYPE.CONTRACTS,
      });

      if (contractsContent && _.get(vm.contractInfoList, 'length') > 1 && !vm.contractId) {
        return true;
      }
      return _.isUndefined(vm.startDate) || _.isUndefined(vm.endDate) || vm.isCreating;
    }

    function onChangeDateRange(dateRange) {
      if (dateRange.isValid) {
        vm.endDate = dateRange.end;
        vm.startDate = dateRange.start;
      } else {
        vm.endDate = undefined;
        vm.startDate = undefined;
      }
      // remove this when migrating to src2
      $scope.$apply();
    }

    function onClose() {
      $scope.$broadcast(COMMON_EVENTS.RESET);
      vm.contractId = undefined;
      vm.contractType = undefined;
      vm.endDate = undefined;
      vm.startDate = undefined;
    }

    function onCreate() {
      const options = {
        contractId: vm.contractId,
        contractType: vm.contractType,
        endDate: vm.insightsUtils.getMaxISODate(vm.dateRangeContent, vm.endDate),
        orgId: vm.orgId,
        startDate: vm.insightsUtils.getMinISODate(vm.dateRangeContent, vm.startDate),
        type: vm.type,
      };

      vm.isCreating = true;
      vm.promiseToWait = InsightsItemDetail.create(options)
        .then(() => {
          vm.onSuccessCreateInsightsItemDetail({item: vm.insightsItem});
          toastManager.showSuccessToast();
        })
        .catch(() => {
          toastManager.showErrorToast();
        })
        .finally(() => {
          panelManager.close(CREATE_INSIGHTS_ITEM_DETAIL_MODAL_ID);
          vm.isCreating = false;
        });
    }

    function onOpen({insightsItem, orgId}) {
      const createAction = _.get(insightsItem, 'actions.CREATE');

      _.assign(vm, {
        dateRangeContent: _.find(createAction.content, {
          type: INSIGHTS_ITEM.CONTENT_TYPE.DATE_RANGE,
        }),
        description:
          createAction.description ||
          translationUtils.sanitizeSafeInstant(createAction.descriptionKey),
        dropdownContents: feature.isEnabled('temp_insights_dropdown')
          ? _.filter(createAction.content, {
              type: INSIGHTS_ITEM.CONTENT_TYPE.DROPDOWN,
            })
          : [],
        insightsItem,
        orgId,
        title: createAction.title || translationUtils.sanitizeSafeInstant(createAction.titleKey),
        type: insightsItem.id,
      });
    }

    function onSelectDropdownOption(type, selectedItem) {
      if (type === INSIGHTS_ITEM.DROPDOWN_TYPE.CONTRACTS) {
        vm.contractId = selectedItem.contractId;
        vm.contractType = selectedItem.type;
        // remove this when migrating to src2
        $scope.$apply();
      }
    }
  }
})();
