(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   *
   * @ngdoc component
   * @name binky.widgets.omni-tool.drawer:binkyOmniToolDrawer
   *
   * @description The "omni tool" drawer filled with internal developer tools
   *
   * @example <binky-omni-tool-drawer></binky-omni-tool-drawer>
   *
   */
  angular.module('binky.widgets.omni-tool.drawer').component('binkyOmniToolDrawer', {
    bindings: {
      onToggleDrawer: '&',
    },
    controller,
    templateUrl: 'widgets/omni-tool/drawer/omni-tool-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $document,
    $rootScope,
    $scope,
    $window,
    _,
    AuthenticatedUser,
    omniToolPanelManager,
    OMNI_TOOL_DRAWER_ID,
    OMNI_TOOL_PANEL_REGISTER,
    OMNI_TOOL_PANEL_SWITCH,
    OMNI_TOOL_VALID_KEY,
    panelManager
  ) {
    const vm = this;
    _.assign(vm, {
      $destroy,
      $onInit,
      closeDrawer,
      OMNI_TOOL_DRAWER_ID,
    });

    function $destroy() {
      _.invoke(vm, 'registerListener');
      _.invoke(vm, 'switchListener');
      _.invoke(vm, 'drawerToggleKeyListener');
    }

    function $onInit() {
      setupKeyListener();
      vm.registerListener = $rootScope.$on(OMNI_TOOL_PANEL_REGISTER, (e, panelCallbacks) => {
        vm.panelCallbacks = panelCallbacks;
      });
      vm.switchListener = $rootScope.$on(OMNI_TOOL_PANEL_SWITCH, (e, panelId) => {
        vm.panelId = panelId;
        _.defer(() => {
          $scope.$apply();
        });
      });
      vm.panelCallbacks = [];
    }

    function closeDrawer() {
      omniToolPanelManager.clearPanelCallbacks();
      panelManager.close(OMNI_TOOL_DRAWER_ID);
    }

    //////////////

    /**
     * Helper to check if fakes are enabled or if the user that's logged in should be
     * allowed to view the omni-tool drawer.
     *
     * @returns {boolean} true if the user's email ends in @adobe.com or if fakes are active
     */
    function authenticate() {
      const authenticatedUser = AuthenticatedUser.get();
      // Lowercasing the email so we can cover ADOBE.COM and ADOBETEST.COM cases
      const userEmail = _.toLower(authenticatedUser.getEmail());
      return (
        ((_.endsWith(userEmail, '@adobe.com') || _.endsWith(userEmail, '@adobetest.com')) &&
          authenticatedUser.getEmailVerified()) ||
        !!$window.fakes
      );
    }

    /**
     * Helper to set the key event listener for the omni tool drawer
     */
    function setupKeyListener() {
      $document.bind('keydown', (e) => {
        // user must press the proper key and be a valid developer
        if (
          e.key === OMNI_TOOL_VALID_KEY &&
          // following line prevents drawer from opening if the user is focused on an element
          // on the page (such as a text box)
          $document.find('body')[0] === $document[0].activeElement &&
          authenticate()
        ) {
          $scope.$apply(() => {
            vm.onToggleDrawer({e});
          });
        }
      });
    }
  }
})();
