import get from 'lodash/get';
import {makeObservable, observable, runInAction} from 'mobx';

import ims from 'api/ims';
import Country from 'models/country/Country';

class CountryList {
  /**
   * @description Method for getting all the countries
   * @param {Object} [options] - Configuration options for list retrieval
   * @param {Boolean} [options.returnPromise=false] whether we want to return a promise or not
   * @returns {CountryList} new CountryList instance, fetched from back-end.
   * If 'returnPromise' is true, it returns promise.
   */
  static get({returnPromise = false} = {}) {
    const model = new CountryList();
    // If we want to await CountryList, we need to return 'model.refresh()'
    if (returnPromise) {
      return model.refresh();
    }
    model.refresh();
    return model;
  }

  errorMessage;
  isLoading;
  items = [];
  constructor() {
    makeObservable(this, {
      errorMessage: observable,
      isLoading: observable,
      items: observable,
    });
  }

  /**
   * @description Method to fetch the country list
   * @returns {Promise} promise - promise object, resolved when the country list is loaded.
   */
  async refresh() {
    this.isLoading = true;
    this.errorMessage = undefined;

    try {
      const response = await ims.getCountries();

      const countryData = get(response, 'data.countries');

      const countries = countryData && countryData.map((country) => new Country(country));

      runInAction(() => {
        if (countries) {
          this.items = countries;
        } else {
          this.items = [];
          this.errorMessage = 'Failed to fetch country from IMS';
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return this;
  }
}

export default CountryList;
