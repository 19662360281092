(function () {
  /**
   * @ngdoc module
   * @name binky.core.common.list
   * @description module defining abstract List model
   */
  angular.module('binky.core.common.list', [
    'binky.core.api.jil',
    'binky.core.common.model',
    'binky.core.common.model.cache',
    'binky.core.src2-migration',
  ]);
})();
