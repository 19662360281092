(function () {
  /**
   * @ngdoc module
   * @name binky.core.migration
   * @description Module which defines migrations related models
   */
  angular.module('binky.core.migration.t2e', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.migration',
  ]);
})();
