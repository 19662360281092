const PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE = {
  EXPANDABLE: 'expandable',
  EXPANDABLE_SELECT: 'expandableSelect',
};
const PROFILE_PERMISSIONS_SUB_CONTENT_TYPE = {
  INCLUDE: 'include',
  SELECT: 'select',
  TEXT_FORM: 'textForm',
};

export {PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE, PROFILE_PERMISSIONS_SUB_CONTENT_TYPE};
