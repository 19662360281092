const ANALYTICS_EVENT = 'AnalyticsEvent';
const EVENT_ACTION = {
  CLICK: 'click',
  CLOSE: 'close',
  DISMISS: 'dismiss',
  DISPLAY: 'display',
  FAILED: 'failed',
  LOADED: 'loaded',
  NEXT: 'next',
  PREV: 'previous',
  PROCEED: 'proceed',
  SUCCESS: 'success',
  VIEW: 'view',
};
const EVENT_NAME = {
  ACCEPT_OFFER: 'acceptOffer',
  GLOBAL_BANNER: 'globalBanner',
  SWITCH_TABS: 'switchTabs',
  WIDE_BANNER: 'wideBanner',
};
export {ANALYTICS_EVENT, EVENT_ACTION, EVENT_NAME};
