import format from 'number-format.js';

import log from 'services/log';
import {htmlDecode} from 'utils/domUtils';

/**
 * @description Returns a human-readable price string containing the specified amount in the
 *   specified currency. For example, given a US currency descriptor and amount 12345.67, this
 *   method returns US$12,345.67. If the amount cannot be formatted, for example because the
 *   formatString has an unexpected format, then the raw amount as a string is returned.
 *
 *   The currency object should contain at least formatString and usePrecision properties.
 *
 *   The formatString is the IT countries service definition of how to display amounts in a
 *   given currency.The formatString consists of a currency symbol, and a mask for formatting
 *   the number. For example, in the formatString "'US$'#,##0.00", the symbol is 'US$' and the
 *   mask is '#,##0.00'. The mask may also come after the symbol, e.g. '#,##0.00 &euro;'.
 *
 *   The usePrecision property indicates whether the currency is shown with decimal places,
 *   for example 'US$12.34' vs '12 円' (Japanese Yen).
 *
 * @param {Number} amount - the amount to format, e.g. 12345.67
 * @param {Object} currency - the currency definition, as returned by JIL APIs, containing at
 *   least formatString and usePrecision properties.
 * @returns {String} the specified amount in the specified currency, e.g. US$12,345.67
 */

function formatPrice(amount, currency) {
  const formatStringRegex = /^(.*?)'((?![#0]).*?(?![#0]))'(.*)$/;
  const parseResult = formatStringRegex.exec(currency.formatString);

  const [matchedString, maskBeforeSymbol, symbol, maskAfterSymbol] = parseResult || [];
  const numMasks = [maskBeforeSymbol, maskAfterSymbol].filter((content) => content).length;
  const parseSucceeded = matchedString && symbol && numMasks === 1;

  if (!parseSucceeded) {
    log.error(`Error parsing formatString in currency ${JSON.stringify(currency)}`);
    return `${amount}`;
  }

  // The formatString symbols are HTML-encoded, e.g. '&pound;' instead of '£'
  const decodedSymbol = htmlDecode(symbol);

  // In practice, only the maskBeforeSymbol or the maskAfterSymbol will be non-empty
  return `${applyMask(maskBeforeSymbol)}${decodedSymbol}${applyMask(maskAfterSymbol)}`;

  function applyMask(mask) {
    if (!mask) {
      return '';
    }
    let fullMask = mask;
    if (!currency.usePrecision) {
      // Per https://github.com/Mottie/javascript-number-formatter#note add a special character
      // to the mask which tells numberFormat not to use a decimal (or 'precision') seperator,
      // e.g. with formatString "#,##0 '&euro;'", the mask "#,##0 " is changed to "#,##0. "
      const charToForceNoDecimalSeparator = fullMask.includes('.') ? ',' : '.';
      fullMask = fullMask.replace(/(\s*)$/, `${charToForceNoDecimalSeparator}$1`);
    }

    return format(fullMask, amount);
  }
}

const price = {formatPrice};
export default price;
