import isEmpty from 'lodash/isEmpty';

import MemberType from './type/MemberType';

export default class Member {
  /**
   * @class
   * @description Creates a new Member for use.
   *
   * @param {Object} [options] - Member instance settings
   * @param {String} [options.id] - Member's ID
   * @param {Boolean} [options.editable] - Member is editable
   * @param {Boolean} [options.inTarget] - Member is inTarget (e.g., for autocomplete)
   * @param {Object} [options.licenseGroups] - Member's licenseGroups
   * @param {Object} [options.productRole] - Member's productRole
   * @param {Boolean} [options.type] - Member's type
   */
  constructor(options = {}) {
    this.id = options.id;
    this.editable = options.editable;
    this.inTarget = options.inTarget;
    this.licenseGroups = options.licenseGroups;
    this.productRole = options.productRole;
    this.type = options.type;
  }

  /** Public methods **/
  /**
   * @description Method to retrieve the display name of this Member.
   */
  getDisplayName() {
    throw new Error(`Not Implemented: ${this}`);
  }

  /**
   * @description Method to retrieve the MemberType of the member
   *
   * @returns {MemberType} type of the member
   */
  getType() {
    return new MemberType(this.type, this.id);
  }

  /**
   * @description Method to determine if this Member has a display name
   *
   * @returns {Boolean} true if the Member has a display name, else false
   */
  hasDisplayName() {
    return !isEmpty(this.getDisplayName());
  }

  /**
   * @description Method to determine if this Member is a new one or not.
   *
   * @returns {Boolean} true if a new Member, else false
   */
  isNew() {
    // eslint-disable-next-line no-eq-null -- https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isnil
    return this.id == null;
  }
}
