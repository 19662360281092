import pick from 'lodash/pick';

/**
 * @description This class repreesent a product
 */
class ProductDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {Number} [options.cancelQuantity] The number of licenses selected for cancellation
   * @param {String} [options.id] Product ID
   * @param {Number} [options.licenseCount] Total number of licenses
   * @param {String} [options.licenseType] License type
   * @param {Number} [options.numberSelected] User selected quantity
   * @param {Number} [options.numberUnassigned] Number of unassigned licenses
   * @param {String} [options.offer_id] Offer ID
   * @param {Object} [options.pricing] pricing information relating to the product
   * @param {String} [options.productBucket] The source bucket where the product was selected from
   * @param {String} [options.productName] License type
   * @param {String} [options.searchTerm] Search term
   */
  constructor(options = {}) {
    Object.assign(
      this,
      pick(options, [
        'cancelQuantity',
        'id',
        'licenseCount',
        'licenseType',
        'numberSelected',
        'numberUnassigned',
        'offer_id',
        'pricing',
        'productBucket',
        'productName',
        'searchTerm',
      ])
    );
  }
}

export default ProductDescriptor;
