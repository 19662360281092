(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name insightsItemAccess
   * @description insights item access factory
   */
  angular.module('binky.core.insights.access').factory('InsightsItemAccess', InsightsItemAccess);
  /* @ngInject */
  function InsightsItemAccess(
    $log,
    $q,
    _,
    AuthenticatedUser,
    feature,
    INSIGHTS_ITEM_ACCESS_LIST_CACHE_ID,
    modelCache,
    reportBroker
  ) {
    const service = {
      checkAccess,
      getAvailableInsightsItems,
      hasInsightsItemAccess,
    };
    modelCache.register(INSIGHTS_ITEM_ACCESS_LIST_CACHE_ID, 10);
    return service;

    //////////////

    /**
     * @description Method to check access by feature flag, fulfillableItem, contract type and role.
     *
     * @param {Object} access - access object for insights item
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - the id of the org to check access
     * @param {ContractList} options.contractList - the contract list to check access against
     * @param {ProductList} options.productList - the product list to check access against
     *
     * @returns {Boolean} return true if all checks passed.
     */
    function checkAccess(access, options) {
      const {contractList, orgId, productList} = options;
      return (
        _.isUndefined(access) ||
        (checkNotNull(contractList, 'contractList') &&
          checkNotNull(productList, 'productList') &&
          checkAccessByFeatureFlag(access) &&
          checkAccessByFulfillableItems(access, productList) &&
          checkAccessByRole(access, {orgId}) &&
          checkAccessByContractType(access, contractList) &&
          checkAccessByBuyingProgram(access, contractList))
      );
    }

    /**
     * @description Method to get available insights items.
     *
     * @param {String} insightsSection - used to determine what section the user has access to (reports and or logs)
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - the id of the org to get available insights items for
     * @param {ContractList} options.contractList - the contract list to get insights items for
     * @param {ProductList} options.productList - the product list to get insights items for
     *
     * @returns {Promise} resolves with a list of available insights items
     */
    function getAvailableInsightsItems(insightsSection, options) {
      const cachedModel = modelCache.get(INSIGHTS_ITEM_ACCESS_LIST_CACHE_ID, insightsSection);
      return cachedModel
        ? $q.resolve(cachedModel)
        : reportBroker.insightsItemAccessList
            .query(_.omitBy({orgId: _.get(options, 'orgId')}, _.isUndefined))
            .$promise.then(onSuccess.bind(this))
            .catch($q.reject);

      function onSuccess(insightsItemAccessList) {
        const availableInsightsItems = _.filter(
          insightsItemAccessList,
          (item) => item.insightsSection === insightsSection && checkAccess(item.access, options)
        );
        modelCache.put(INSIGHTS_ITEM_ACCESS_LIST_CACHE_ID, availableInsightsItems, insightsSection);
        return availableInsightsItems;
      }
    }

    /**
     * @description Method to check the accessibility of insights item.
     *
     * @param {String} insightsSection - used to determine what section the user has access to (reports and or logs)
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - the id of the org to check insights item access for
     * @param {ContractList} options.contractList - the contract list to check access against
     * @param {ProductList} options.productList - the product list to check access against
     *
     * @returns {Promise} resolves with Boolean value; true if the insights section has insights item access, else false.
     */
    function hasInsightsItemAccess(insightsSection, options) {
      return getAvailableInsightsItems(insightsSection, options).then(
        (availableItems) => !_.isEmpty(availableItems)
      );
    }

    /** Private Methods **/

    /**
     * @description Method to check access by buying program. Makes sure at least one of the
     * required buying program is present in the contract list.
     *
     * @param {Object} access - access object for insights item
     * @param {ContractList} contractList - contract list to check if it contains a contract with required type(s)
     *
     * @returns {Boolean} return true if contract list has any of the required buying program(s).
     */
    function checkAccessByBuyingProgram(access, contractList) {
      return (
        _.isUndefined(access.requiredBuyingPrograms) ||
        _.some(contractList.items, (item) =>
          _.includes(access.requiredBuyingPrograms, item.buyingProgram)
        )
      );
    }

    /**
     * @description Method to check access by feature flag. Default to true if requiredFlag is not defined in the accessItem.
     *
     * @param {Object} access - access object for insights item
     *
     * @returns {Boolean} return true if required feature flag is enabled.
     */
    function checkAccessByFeatureFlag(access) {
      return _.isUndefined(access.requiredFlag) || feature.isEnabled(access.requiredFlag);
    }

    /**
     * @description Method to check access by fulfillableItem.
     *
     * @param {Object} access - access object for insights item
     * @param {ProductList} productList - product list to check if it contains certain fulfillableItem
     *
     * @returns {Boolean} return true if insights item is available.
     */
    function checkAccessByFulfillableItems(access, productList) {
      return (
        productList.hasAllFulfillableItems(access.requiredCompleteFulfillableItems) &&
        productList.hasSomeFulfillableItems(access.requiredPartialFulfillableItems) &&
        (_.isUndefined(access.hideWithCompleteFulfillableItems) ||
          !productList.hasAllFulfillableItems(access.hideWithCompleteFulfillableItems)) &&
        (_.isUndefined(access.hideWithPartialFulfillableItems) ||
          !productList.hasSomeFulfillableItems(access.hideWithPartialFulfillableItems))
      );
    }

    /**
     * @description Method to check access by role. Default to true if allowedRoles is not defined in the accessItem.
     *
     * @param {Object} access - access object for insights item
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - the id of the org to check access on
     *
     * @returns {Boolean} return true if user has any allowed roles.
     */
    function checkAccessByRole(access, options) {
      return (
        _.isUndefined(access.allowedRoles) ||
        currentUserHasAnyAllowedRole(access.allowedRoles, options)
      );
    }

    /**
     *
     * @description Method to check access by customer segment and sales channel.
     *   Makes sure at least one of the required customer segment and
     *   sales channel combo is present in the contract list.
     * @param {Object} access - access object for insights item
     * @param {ContractList} contractList - contract list to check if it
     *   contains a contract with required customer segment and sales channel
     * @returns {Boolean} return true if contract list has any of the required
     *   customer segment(s) or doesn't have prohibited customer segment and sales channel
     */
    function checkAccessByContractType(access, contractList) {
      const hideWithContractTypes =
        _.isUndefined(access.hideWithContractTypes) ||
        !_.some(contractList.items, (contract) =>
          _.find(access.hideWithContractTypes, (contractType) => _.isMatch(contract, contractType))
        );

      const requiredContractTypes =
        _.isUndefined(access.requiredContractTypes) ||
        _.some(contractList.items, (contract) =>
          _.find(access.requiredContractTypes, (contractType) => _.isMatch(contract, contractType))
        );
      return hideWithContractTypes && requiredContractTypes;
    }

    /**
     * @description Method to check if a provided object is not null, and log a warning if it is.
     *
     * @param {Object} object - object to null check
     * @param {String} key - name of the object to include in any warning log
     *
     * @returns {Boolean} return true if the object is not null
     */
    function checkNotNull(object, key) {
      if (object) {
        return true;
      }
      $log.warn(`${key} not provided to InsightItemAccess`);
      return false;
    }

    /**
     * @description Method to check if the currently authenticated user has any of the provided roles
     *  on the organization in question
     *
     * @param {Array} allowedRoles - the array of roles that we want to check for
     * @param {Object} options - options for the access check
     * @param {String} options.orgId - the id of the organization we're checking the user's roles on
     *
     * @returns {Boolean} return true if the user has any of the allowed roles on the target org
     */
    function currentUserHasAnyAllowedRole(allowedRoles, options) {
      const authenticatedUser = AuthenticatedUser.get();
      const userRoles = authenticatedUser.getRoles();
      return (
        userRoles.anyOfForOrg(allowedRoles, options.orgId) ||
        userRoles.anyOfForAdobeAgentOrg(allowedRoles)
      );
    }
  }
})();
