/* eslint-disable react/no-unknown-property -- styleName not recognized */
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';

import {useWizardContext} from '../WizardContext';
import WizardStepIndicator from '../wizard-step-indicator/WizardStepIndicator';

import './WizardStepList.pcss';

/**
 * Widget for displaying wizard steps
 */
const WizardStepList = ({className, steps, currentStep}) => {
  let wizardContext = useWizardContext() ?? {};

  if (!wizardContext.steps && steps) {
    // we are in controlled mode so don't use context and instead use the passed props
    wizardContext = {currentStep, steps};
  }

  return (
    <ol className={className} styleName="step-list">
      {wizardContext.steps.map((step, i) => {
        const isCurrentStep = i === wizardContext.currentStep;

        return (
          <li
            key={step}
            aria-current={isCurrentStep ? 'step' : undefined}
            data-testid="step-list-item"
            styleName="step-list-item"
            tabIndex="-1"
          >
            {i !== 0 && <ChevronRight size="S" />}
            <WizardStepIndicator
              currentStep={wizardContext.currentStep}
              isActive={isCurrentStep}
              stepNumber={i}
            >
              {step}
            </WizardStepIndicator>
          </li>
        );
      })}
    </ol>
  );
};

WizardStepList.propTypes = {
  /**
   * Classname to apply to the ordered list element wrapping the steps.
   */
  className: PropTypes.string,
  /**
   * Number representing the index of the curretn step,
   * Note that this prop is used only if no context has been defined.
   */
  currentStep: PropTypes.number,
  /**
   * An array of steps.
   * Note that this prop is used only if no context has been defined.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default WizardStepList;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
