(function () {
  'use strict';
  angular.module('binky.shell.panels.drawer', [
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.drawer-header.drawer-header-title',
    'binky.widgets.common.wait',
    'ngAnimate',
  ]);
})();
