(function () {
  'use strict';
  /**
   * @deprecated Replaced by Pandora Administration's ALLOWED_VIDEO_DOMAINS inside ReactVideoModal package
   * https://git.corp.adobe.com/PandoraUI/administration/blob/master/packages/react-video-modal/src/js/types.ts#L31
   *
   */
  angular.module('binky.widgets.common.video-modal').constant('VIDEO_ALLOWLIST_DOMAINS', {
    ADOBE: 'adobe.com',
    YOUTUBE: 'youtube.com',
  });
})();
