import {getDaysLeft} from 'utils/dateUtils';

/**
 * @description Returns the first VIP contract that has a
 *   previousAnniversaryDate value and is not in the renewal window
 *   (lapsed subscription).
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} contract with a lapsed VIP subscription, else undefined
 *   if no lapsed VIP subscription contracts exist
 */
function getLapsedVIPSubscriptionContract(contractList) {
  return contractList.items.find(
    (item) =>
      item.isIndirectContract() && item.getPreviousAnniversaryDate() && !item.isInRenewalWindow()
  );
}

/**
 * @description Returns the previous anniversary date for the first contract
 *   that has a previousAnniversaryDate value and is not in the renewal
 *   window (lapsed subscription).
 * @param {ContractList} contractList - the contract list
 *
 * @returns {String} previous anniversary date in ISO-8061 timestamp format or
 *   undefined if no previous anniversary date
 */
function getLapsedVIPSubscriptionPrevAnniversaryDate(contractList) {
  const lapsedVIPSubscriptionContract = getLapsedVIPSubscriptionContract(contractList);
  return lapsedVIPSubscriptionContract
    ? lapsedVIPSubscriptionContract.getPreviousAnniversaryDate()
    : undefined;
}

/**
 * @description Returns the reseller name for the first contract
 *   that has a previousAnniversaryDate value and is not in the renewal
 *   window (lapsed subscription).
 * @param {ContractList} contractList - the contract list
 *
 * @returns {String} reseller name
 */
function getLapsedVIPSubscriptionResellerName(contractList) {
  const lapsedVIPSubscriptionContract = getLapsedVIPSubscriptionContract(contractList);
  return lapsedVIPSubscriptionContract
    ? lapsedVIPSubscriptionContract.getResellerName()
    : undefined;
}

/**
 * @description Returns a VIP contract if one exists that was created <=
 *   newContractWindowInDays days ago.
 * @param {ContractList} contractList - the contract list
 * @param {Integer} [newContractWindowInDays] - number of days between the contract start date
 *   and now that the contract should be consider "new". Default is 7 days.
 *
 * @returns {String} reseller name
 */
function getNewVipContract(contractList, newContractWindowInDays = 7) {
  return contractList.items.find((contract) => {
    if (contract.isBuyingProgramVIP() && contract.isStatusActive()) {
      // In the typical case, start date will be in the past so daysAgo will be negative.
      // If start date is in the future, daysAgo will be positive.
      const daysAgo = getDaysLeft(contract.getStartDate());
      return Math.abs(daysAgo) <= newContractWindowInDays;
    }
    return false;
  });
}

/**
 * @description Checks whether there is a VIP contract in this list with a
 *   lapsed subscription. A lapsed subscription is a VIP contract that
 *   has a previousAnniversaryDate value and is not in the renewal
 *   window.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if a lapsed subscription exists, else false
 */
function hasLapsedVIPSubscription(contractList) {
  return contractList.items.some(
    (item) =>
      item.isIndirectContract() && item.getPreviousAnniversaryDate() && !item.isInRenewalWindow()
  );
}

/**
 * @description Returns true if there is a VIPMP contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if this is a VIPMP contract.
 */
function hasVIPMPContract(contractList) {
  return contractList.items.some((item) => item.isVIPMPContract());
}

export {
  getLapsedVIPSubscriptionContract,
  getLapsedVIPSubscriptionPrevAnniversaryDate,
  getLapsedVIPSubscriptionResellerName,
  hasLapsedVIPSubscription,
  getNewVipContract,
  hasVIPMPContract,
};
