(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name BadgeUtils
   * @description Service to centralize and expose common badge functionality
   *   that can be leveraged across various badge-related files.
   */
  angular.module('binky.widgets.common.badge-utils').factory('BadgeUtils', getBadgeUtils);

  /* @ngInject */
  function getBadgeUtils() {
    /**
     * @class
     * @description Service to centralize and expose common badge functionality.
     */
    class BadgeUtils {
      /**
       * @description Method to determine which icon should be used, based on
       *   the variant of the badge being used. This allows us to centralize
       *   icon decision logic between components, while abstracting the purpose
       *   of a badge (informational, special, success, warning, etc) away from
       *   the physical icon chosen to represent that purpose (info, alert,
       *   checkmark, etc).
       * @param {String} [variant] - one of error, info, special, success, or warning.
       *   Variants are expressed in the following manner:
       *
       *     info - info icon
       *     special - no icon
       *     success - no icon
       *     warning - alert icon
       *     error - alert icon
       * @returns {String|undefined} name of Coral icon to use for variant
       *   provided, else undefined if no icon associated with variant
       */
      static getIconFromVariant(variant) {
        switch (variant) {
          case 'info':
            return 'info';
          case 'error':
          case 'warning':
            return 'alert';
          // no image for these variants
          case 'special':
          case 'success':
          default:
            return undefined;
        }
      }
    }

    return BadgeUtils;
  }
})();
