(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.directories.domains:DirectoryDomainList
   * @description fetch the list of domains for a specific directory.
   */
  angular
    .module('binky.core.directories.domains')
    .factory('DirectoryDomainList', directoryDomainList);

  /* @ngInject */
  function directoryDomainList(
    $rootScope,
    _,
    DIRECTORY_DOMAIN_LIST_CACHE_ID,
    DIRECTORY_DOMAIN_LIST_EVENT,
    DIRECTORY_LIST_EVENT,
    DirectoryDomain,
    JIL_CONSTANTS,
    jilDirectories,
    List,
    modelCache
  ) {
    class DirectoryDomainList extends List {
      /**
       * @description instantiate the list of Domains for a specific directory.
       * @param {Object} options - the options
       * @param {String} options.directoryId - the directoryId for the directory domain list
       * @param {String} options.orgId - the orgId for the directory domain list
       */
      constructor(options) {
        super({
          cacheType: DIRECTORY_DOMAIN_LIST_CACHE_ID,
          itemClassRef: DirectoryDomain,
          resource: jilDirectories.directoryDomains,
          sortExpression: JIL_CONSTANTS.SORT.NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
        this.$resolved = false;
        this.directoryId = options.directoryId;
        this.orgId = options.orgId;
        this.items = [];
      }

      /**
       * @description overidden key method to be used when we fetch models from modelCache
       *
       * @returns {String} key formed from params.status (if present) and the orgId
       */
      key() {
        return super.key({directoryId: this.directoryId, orgId: this.orgId});
      }

      /**
       * @description
       * Method to refresh the contents of the directory domain list
       *
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh() {
        const reqParams = {directoryId: this.directoryId, orgId: this.orgId};
        return super.refresh(_.omitBy(reqParams, _.isUndefined)).then(() => {
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(DIRECTORY_DOMAIN_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
          }
        });
      }

      /**
       * @description Method to modify sort parameters of the domain list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description instantiates a new instance of DirectoryDomainList.
       * @param {Object} options - the options
       * @param {String} options.directoryId - the directoryId for the directory domain list
       * @param {String} options.orgId - the orgId for the directory domain list
       * @returns {DirectoryDomainList} DirectoryDomainList model object.
       */
      static get(options) {
        let model = new DirectoryDomainList(options);
        const cachedModel = modelCache.get(DIRECTORY_DOMAIN_LIST_CACHE_ID, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(DIRECTORY_DOMAIN_LIST_CACHE_ID, 1);
    modelCache.removeAllOnEvent(DIRECTORY_DOMAIN_LIST_CACHE_ID, [
      DIRECTORY_DOMAIN_LIST_EVENT.UPDATE,
      DIRECTORY_LIST_EVENT.UPDATE,
    ]);

    return DirectoryDomainList;
  }
})();
