(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilExtendedUserProfile
   * @description defines service to manage JIL user-profile resources
   */
  angular
    .module('binky.core.api.jil')
    .provider('jilExtendedUserProfile', jilExtendedUserProfileProvider);

  let url;

  /* @ngInject */
  function jilExtendedUserProfileProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        extendedProfile: extendedProfileResource(),
      };

      return service;

      ///////////

      function extendedProfileResource() {
        return $resource(
          `${url}/:orgId/users/:id/extended-profile`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            update: {
              method: 'PATCH',
            },
          }
        );
      }
    }
  }
})();
