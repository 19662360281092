/* eslint-disable max-lines -- ignore */
import assignIn from 'lodash/assignIn';
import invoke from 'lodash/invoke';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import toNumber from 'lodash/toNumber';

import jilOrganizationsProductsLicenseGroups from 'api/jil/jilOrganizationsProductsLicenseGroups';
import {FULFILLABLE_ITEM_TYPE} from 'models/fulfillableItemList/FulfillableItemConstants';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import modelCache from 'services/cache/modelCache/modelCache';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import Product from 'services/product/Product';
import {getListCacheKey} from 'utils/cacheUtils';

import {PRODUCT_LIST_EVENT} from '../ProductListConstants';

import {
  LICENSE_GROUP_CACHE_ID,
  LICENSE_GROUP_QUOTA_UNLIMITED,
  PRODUCT_CONFIGURATIONS_EVENT,
} from './LicenseGroupConstants';

export default class LicenseGroup {
  /**
   * @description Method to retrieve an existing license group.
   *
   * @param {Object} options - Options for the constructor.
   * @param {String} options.id - The id of of the license group
   * @param {String} options.orgId - The org id
   * @param {Product} options.product - product model. If this is passed, it will supersede productId
   * @param {String} options.productId - The id of the product
   *
   * @returns {Promise} Promise that resolves to the instance when the refresh is successful
   */
  static get(options) {
    const model = new LicenseGroup(options);

    const key = model.key();
    const cachedPromise = modelCache.get(LICENSE_GROUP_CACHE_ID, key);
    if (cachedPromise) {
      return cachedPromise;
    }

    return model.refresh();
  }

  /**
   * @description Creates a new LicenseGroup for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Number} options.adminCount - number of admins assigned to license group
   * @param {Boolean} options.administerable - Whether or not the license group is administerable
   * @param {Number} options.delegatableCreditQuota - number of credits that can be used
   * @param {Number} options.delegatableImageQuota - number of images that can be used
   * @param {Number} options.delegatableVideoQuota - number of videos that can be used
   * @param {Number} options.delegatedQuantity - number of delegated users in the license group
   * @param {String} options.description - Description of the license group
   * @param {Array} options.dmaproductRoles - An array of roles for the license group
   * @param {Array} options.fulfilledItems - fulfilled items configured for this license group
   * @param {String} options.id - The id of of the license group
   * @param {String} options.name - The name of the license group
   * @param {Boolean} options.notifications - Whether or not notifications are enabled on the license group
   * @param {String} options.orgId - the org id
   * @param {Array} options.privateClouds - list of private clouds set-up for this license group
   * @param {Product} options.product - product model. If this is passed, it will supersede
   *                                    productCode, productFamily, productId and productName and it may use
   *                                    isEnterprise and isAdobeStock.
   * @param {String} options.productCode - Code of the product
   * @param {String} options.productFamily - Family of the product
   * @param {String} options.productId - Id of the product
   * @param {String} options.productName - Name of the product
   * @param {Number} options.provisionedQuantity - number of provisioned users in the license group
   * @param {Boolean} options.removable - true if license group is removable, else false
   * @param {Object} options.solutionGroup - solution group license group belongs to
   * @param {String} options.totalQuantity - number of licenses that can be assigned
   */
  constructor(options) {
    this.orgId = options.orgId;
    this.updateModel(options);

    modelCache.clearOnEvent(LICENSE_GROUP_CACHE_ID, [
      MEMBER_EVENT.CREATE,
      MEMBER_EVENT.DELETE,
      MEMBER_EVENT.UPDATE,
      PRODUCT_LIST_EVENT.REFRESH,
    ]);
  }

  /**
   * @description Method to return fulfilled items of desktop type for this LicenseGroup instance.
   *
   * @returns {Array} desktops in LicenseGroup
   */
  getDesktopFulfilledItems() {
    return (
      (this.fulfilledItems &&
        this.fulfilledItems.filter((item) => item.fulfillableItemType === 'DESKTOP')) ||
      []
    );
  }

  /**
   * @description Method to determine how many Services are enabled for this LicenseGroup instance.
   *
   * @returns {Number} number of enabled services in LicenseGroup. If LicenseGroup data
   *                is in the process of loading, this will return undefined. If no enabled services present
   *                in LicenseGroup, this will return 0.
   */
  getEnabledServiceCount() {
    const services = this.getServiceFulfilledItems({delegationConfigurable: true});
    return services.filter((item) => item.selected === true).length;
  }

  /**
   * @description Method to return fulfilled items of a particular type for this LicenseGroup instance
   *
   * @param {boolean} delegationConfigurable - if true, we require items be delegation configurable to show. Defaults to false.
   * @param {FULFILLABLE_ITEM_DELEGATION_TYPE} delegationType - the delegation type to filter to, such as PERSON. Defaults to no filtering.
   * @param {FULFILLABLE_ITEM_TYPE} type - the type of items to return, such as SERVICE or QUOTA.
   * @returns {Array} items in LicenseGroup
   */
  getFulfilledItemsOfType({delegationConfigurable = false, delegationType, type}) {
    return (
      this.fulfilledItems?.filter(
        (item) =>
          item.fulfillableItemType === type &&
          (!delegationConfigurable || item.delegationConfigurable === delegationConfigurable) &&
          (!delegationType || item.delegationType === delegationType)
      ) ?? []
    );
  }

  /**
   * @description Method to return the product roles
   *
   * @returns {Array} Array of product roles, with id and title properties
   */
  getProductRoles() {
    return this.dmaproductRoles ? this.dmaproductRoles : [];
  }

  /**
   * @description Method to return fulfilled items of quota type for this LicenseGroup instance.
   *
   * @param {boolean} delegationConfigurable - if true, we require items be delegation configurable to show. Defaults to false.
   * @param {FULFILLABLE_ITEM_DELEGATION_TYPE} delegationType - the delegation type to filter to, such as PERSON. Defaults to no filtering.
   * @returns {Array} quotas in LicenseGroup
   */
  getQuotaFulfilledItems({delegationConfigurable, delegationType} = {}) {
    return this.getFulfilledItemsOfType({
      delegationConfigurable,
      delegationType,
      type: FULFILLABLE_ITEM_TYPE.QUOTA,
    });
  }

  /**
   * @description Method to get the selected desktop fulfilled item for Single App product profile.
   *              If the api response provides the selectedApps, use that attribute to get selected desktop fulfilled item.
   *              Otherwise, loop through the desktop fulfilled items to find the selected one
   *
   * @returns {FulfilledItem} the selected desktop fulfilled item.
   */
  getSelectedDesktopFulfilledItem() {
    // For now, the selected apps should only have one element.
    const selectedDesktopCode = this.selectedApps ? this.selectedApps[0] : undefined;
    if (selectedDesktopCode) {
      return this.getDesktopFulfilledItems().find((item) => item.code === selectedDesktopCode);
    }

    // Sort the desktop FIs first so that we will always find the same one regardless of the order returned by the api.
    const sortedDesktopFulfilledItems = orderBy(this.getDesktopFulfilledItems(), 'longName');

    return sortedDesktopFulfilledItems.find((item) => item.selected === true);
  }

  /**
   * @description Method to get icons for the selected app for Single App offer
   *
   * @returns {Object} icon urls
   */
  getSelectedDesktopIcons() {
    const selectedDesktopFulfilledItem = this.getSelectedDesktopFulfilledItem();
    return selectedDesktopFulfilledItem && selectedDesktopFulfilledItem.assets
      ? selectedDesktopFulfilledItem.assets.icons
      : undefined;
  }

  /**
   * @description Method to return fulfilled items of service type for this LicenseGroup instance.
   *
   * @param {boolean} delegationConfigurable - if true, we require items be delegation configurable to show. Defaults to false.
   * @param {FULFILLABLE_ITEM_DELEGATION_TYPE} delegationType - the delegation type to filter to, such as PERSON. Defaults to no filtering.
   * @returns {Array} services in LicenseGroup
   */
  getServiceFulfilledItems({delegationConfigurable, delegationType} = {}) {
    return this.getFulfilledItemsOfType({
      delegationConfigurable,
      delegationType,
      type: FULFILLABLE_ITEM_TYPE.SERVICE,
    });
  }

  /**
   * @description Method to determine if there are any unsaved changes to this object.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    return !isEqual(this.savedState, this.toEditable());
  }

  /**
   * @description Method to determine if this License Group instance
   *              is administerable by the currently signed-in user.
   *
   * @returns {boolean} true if LicenseGroup is administerable.
   */
  isAdministerable() {
    return this.administerable;
  }

  /**
   * @description Method to determine if this License Group instance
   *              represents a new license group or an existing license group
   *
   * @returns {boolean} true if LicenseGroup represents a new license group
   */
  isNew() {
    return this.id === null || this.id === undefined;
  }

  /**
   * @description Method to determine if this License Group instance
   *              is removable by the currently signed-in user.
   *
   * @returns {boolean} true if LicenseGroup is removable.
   */
  isRemovable() {
    return this.removable;
  }

  key() {
    return getListCacheKey({
      id: this.id,
      orgId: this.orgId,
      productId: this.product.id,
    });
  }

  /**
   * @description Reloads the license group from the license configuration resource
   * @return {Promise} a promise
   */
  async refresh() {
    try {
      const response = await jilOrganizationsProductsLicenseGroups.getLicenseGroups({
        licenseGroupId: this.id,
        orgId: this.orgId,
        productId: this.product.id,
      });

      this.updateModel(response.data);
      modelCache.put(LICENSE_GROUP_CACHE_ID, this.key(), this);
    } catch (error) {
      log.error('License group failed to load. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   *
   * @returns {void}
   */
  registerSavedState() {
    if (this.isNew()) {
      this.savedState = {};
    } else {
      this.savedState = this.toEditable();
    }
  }

  /**
   * @description Restores the license group from its saved state
   */
  restore() {
    Object.assign(this, this.savedState);
  }

  /**
   * @description Method to save changes to the license group to the back-end.
   * @param {Object} options - selectedSolutionGroup object
   */
  async save(options = {}) {
    const reqObj = options.id
      ? this.toEditable(
          Object.assign(this, {
            solutionGroup: pick(options, ['id', 'name', 'description']),
          })
        )
      : this.toEditable();

    if (this.isNew()) {
      try {
        const response = await jilOrganizationsProductsLicenseGroups.postLicenseGroups(
          {
            orgId: this.orgId,
            productId: this.product.id,
          },
          reqObj
        );
        this.updateModel(response.data);
        eventBus.emit(PRODUCT_CONFIGURATIONS_EVENT.CREATE, this);
        modelCache.put(LICENSE_GROUP_CACHE_ID, this.key(), this);
      } catch (error) {
        log.error('Failed to create/update selected license group. Error: ', error);
        modelCache.clear(LICENSE_GROUP_CACHE_ID);
        return Promise.reject(error);
      }
    } else if (this.hasUnsavedChanges()) {
      try {
        const response = await jilOrganizationsProductsLicenseGroups.putLicenseGroup(
          {
            licenseGroupId: this.id,
            orgId: this.orgId,
            productId: this.product.id,
          },
          reqObj
        );
        this.updateModel(response.data);
        eventBus.emit(PRODUCT_CONFIGURATIONS_EVENT.UPDATE, this);
        modelCache.put(LICENSE_GROUP_CACHE_ID, this.key(), this);
      } catch (error) {
        log.error('Failed to create/update selected license group. Error: ', error);
        modelCache.clear(LICENSE_GROUP_CACHE_ID);
        return Promise.reject(error);
      }
    }

    this.registerSavedState();
    return this;
  }

  toEditable() {
    const response = pick(this, [
      'delegatableCreditQuota',
      'delegatableImageQuota',
      'delegatableVideoQuota',
      'description',
      'name',
      'notifications',
      'publicName',
      'solutionGroup',
      'totalQuantity',
    ]);

    response.fulfilledItems = this.fulfilledItems
      ? toMinimumFulfilledItems(this.fulfilledItems)
      : [];

    return response;

    function toMinimumFulfilledItems(fulfilledItems) {
      return fulfilledItems.map((fulfilledItem) =>
        pick(fulfilledItem, ['code', 'selected', 'chargingModel.cap'])
      );
    }
  }

  updateModel(dataObject) {
    const props = [
      'adminCount',
      'administerable',
      'assignedQuantity',
      'contractDisplayNames',
      'delegatableCreditQuota',
      'delegatableImageQuota',
      'delegatableVideoQuota',
      'delegatedQuantity',
      'description',
      'dmaproductRoles',
      'fulfilledItems',
      'id',
      'name',
      'notifications',
      'provisionedQuantity',
      'publicName',
      'removable',
      'selectedApps',
      'solutionGroup',
      'totalQuantity',
      'userGroup',
    ];

    const defaults = {
      description: '',
      name: 'Default Configuration',
      totalQuantity: '0',
    };

    assignIn(this, defaults, pick(dataObject, props));

    if (dataObject.product) {
      if (dataObject.product instanceof Product) {
        this.product = dataObject.product;
      } else {
        // Use the skeleton product.
        this.product = new Product({...dataObject.product, ignoreEmptyFIs: true});
      }
    } else if (!(this.product instanceof Product)) {
      this.product = new Product({
        id: dataObject.productId,
        ignoreEmptyFIs: true,
      });
    }
    if (
      (invoke(this.product, 'isEnterprise') && invoke(this.product, 'isAdobeStock')) ||
      this.totalQuantity === LICENSE_GROUP_QUOTA_UNLIMITED
    ) {
      delete this.totalQuantity;
    } else {
      this.totalQuantity = toNumber(this.totalQuantity);
    }

    this.publicName = this.publicName || this.name;

    this.registerSavedState();
  }
}
/* eslint-enable max-lines -- ignore */
