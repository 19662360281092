(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum StatusLight
   *
   * @ngdoc component
   * @name binky.widgets.common.status:binkyStatus
   * @description A status widget that has a circle icon and a label.
   *
   * @param {string} label Translated label text.
   * @param {string} [variant] Optional variant type of the icon. One of: 'default', 'error', 'info',
   *   'success', 'warning'. Defaults to 'success'.
   * @example <binky-status
   *            label="label"
   *            variant="success">
   *          </binky-status>
   *
   */
  angular.module('binky.widgets.common.status').component('binkyStatus', {
    bindings: {
      label: '@',
      variant: '@?',
    },
    controller,
    templateUrl: 'widgets/common/status/status.component.html',
  });

  function controller($element, _, STATUS_VARIANT) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      vm.variant = vm.variant || STATUS_VARIANT.SUCCESS;
    }
  }
})();
