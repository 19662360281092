import {Icon, Item, Picker, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ImageIcon from '../image-icon/ImageIcon';

import styles from './ProductPicker.pcss';

/**
 * ProductPicker - a widget for selecting a product
 */
const ProductPicker = ({
  disabledProductIdList,
  selectedProductId,
  onSelectionChange,
  productList,
  width = '100%',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();
  const selectProductText = intl.formatMessage({id: 'binky.common.productPicker.selectProduct'});

  return (
    <Picker
      aria-label={selectProductText}
      disabledKeys={disabledProductIdList}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onSelectionChange={onSelectionChange}
      placeholder={selectProductText}
      selectedKey={selectedProductId}
      width={width}
    >
      {/* Opting to generate the list of Item components, not using the 'Picker.items' prop
          because we need to show/hide count when drop-down is open/closed. */}
      {productList.map(({id, count, displayName, iconUrl}) => (
        <Item key={id} textValue={displayName}>
          {/* Opting out of Chiclet because its height must be 32px */}
          <Icon>
            <ImageIcon alt="" size="S" src={iconUrl} />
          </Icon>
          <Text textValue={displayName}>
            {displayName}
            {isOpen && count !== undefined && <span className={styles.count}>&nbsp;({count})</span>}
          </Text>
        </Item>
      ))}
    </Picker>
  );
};

ProductPicker.propTypes = {
  /**
   * List of product ID's that should be disabled
   */
  disabledProductIdList: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback when selection is changed.
   * The callback is called with the selected product 'id' of
   * a product in productList.
   * Example: onSelectionChange('someId')
   */
  onSelectionChange: PropTypes.func.isRequired,
  /**
   * List of products to select from
   */
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Product count to show if it's available
       */
      count: PropTypes.number,
      /**
       * Product name
       */
      displayName: PropTypes.string.isRequired,
      /**
       * Each product requires an icon
       */
      iconUrl: PropTypes.string.isRequired,
      /**
       * ID used for onSelectionChanged callback
       */
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
  /**
   * The selected product 'id' from a product in productList
   */
  selectedProductId: PropTypes.string,
  /**
   * The rendered width of this component
   */
  width: PropTypes.string,
};

export default ProductPicker;
