/**
 * @description Private constant to track span status
 */
const SPAN_STATUS = {
  COMPLETED: 0,
  REGISTERED: 2,
  STARTED: 1,
};

/**
 * @description Base class to provide basic observability metrics features
 */
class BaseMetrics {
  /**
   * @description Constructor
   * @param {String} options.configData.env Environment name from configuration.495e31ace1.json
   * @param {Number} options.initialStartTime Initial start time, used to store the start
   * timer right after the user finished authentication
   */
  constructor(options) {
    this.#reset();
    this.env = options?.configData?.env || 'missing';
    this.initialStartTime = options?.initialStartTime;
  }

  /**
   * @description (private) Reset data
   */
  #reset() {
    this.spans = {};
    this.currentRoute = null;
  }

  /**
   * @description Mark a span completed. Once all spans are completed, trigger onAllSpansCompleted().
   * @param {String} span Span to complete
   */
  completeSpan(span) {
    // Intentionally use this logic to handle:
    // - The span is already completed: SPAN_STATUS.COMPLETED = 0
    // - The span doesn't exist, e.g. duplicate completeSpan calls
    //   caused by duplicate events.
    if (!this.spans[span]) {
      return;
    }

    this.spans[span] = SPAN_STATUS.COMPLETED;

    let total = 0;
    Object.keys(this.spans).forEach((key) => {
      total += this.spans[key];
    });

    if (total === 0) {
      const startTime = this.initialStartTime || this.startTime;
      const transitionTime = performance.now() - startTime;

      this.onAllSpansCompleted({transitionTime});
      this.#reset();
      this.initialStartTime = null;
    }
  }

  /**
   * @description Handler for after route change is done
   * @param {String} routeChangeType See ROUTE_CHANGE_TYPE constant
   * @param {ObservabilityRoute} observabilityRoute Route information
   */
  onAfterRouteChange({observabilityRoute, routeChangeType}) {
    this.currentRoute = {observabilityRoute, routeChangeType};
  }

  /**
   * @description Override this method by inheriting classes.
   * Let the inheriting classes implement custom logic when all spans are completed.
   */
  // eslint-disable-next-line class-methods-use-this -- Base class implementation, requires override by inheriting class
  onAllSpansCompleted() {
    throw new Error('Spans require onRouteCompleted() override.');
  }

  /**
   * @description Handler for when route change is started
   */
  onBeforeRouteChange() {
    this.#reset();
    this.startTime = performance.now();
  }

  /**
   * @description Register spans to track
   * @param {Array<String>} spanList List of span to track
   */
  registerSpans(spanList = []) {
    spanList.forEach((span) => {
      this.spans[span] = SPAN_STATUS.REGISTERED;
    });
  }

  /**
   * @description Override this method by inheriting classes.
   * Set custom attributes in the inheriting classes.
   */
  // eslint-disable-next-line class-methods-use-this -- Base class implementation, requires override by inheriting class
  setCustomAttributes(
    // eslint-disable-next-line no-unused-vars -- Base class implementation
    attributes
  ) {
    throw new Error('setCustomAttributes() requires override.');
  }

  /**
   * @description Mark a span as started
   * @param {String} span Span to start
   */
  startSpan(span) {
    this.spans[span] = SPAN_STATUS.STARTED;
  }
}

export default BaseMetrics;
