/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to show whether a directory is synced
 * @param {boolean|undefined} externallyManaged Whether directory is externally managed
 * @returns {string} formatted directory synced status label
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.directories.synced-status')
    .filter('binkyDirectorySyncedStatusLabel', binkyDirectorySyncedStatusLabel);

  /* @ngInject */
  function binkyDirectorySyncedStatusLabel($translate) {
    return getDirectorySyncedStatus;

    function getDirectorySyncedStatus(externallyManaged) {
      return $translate.instant(
        `binky.widgets.directories.directorySyncedStatus.${externallyManaged ? 'yes' : 'no'}`
      );
    }
  }
})();
