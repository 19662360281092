/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */

import navBus from 'services/navBus';

const targetKey = 'binkyPersist';
const paramsToPersist = [];

/**
 * @description Gets the persistent data that was applied to the `window`
 * @returns {{[key: string]: any}}
 */
const getPersistentData = () => window[targetKey] || {};

/**
 * @description Gets data that should persist across the application based data that was set in the URL.
 * This data gets applied to the `window` so reloads or navigation will cause this data
 * to be lost. See `restoreDataToQueryParams` if you want to restore this data to the URL.
 * @param {string[]} paramKeys The keys of data that you want to persist
 * @returns {{[key: string]: any}} The persisted data
 */
const storePersistentData = (paramKeys = []) => {
  paramsToPersist.push(...paramKeys);

  if (paramsToPersist.length <= 0) {
    return getPersistentData();
  }

  const currentQueryParams = [...new URL(window.location.href).searchParams.entries()];

  const dataToPersist = currentQueryParams.reduce((data, [key, value]) => {
    if (paramsToPersist.includes(key)) {
      data[key] = value;
    }

    return data;
  }, getPersistentData());

  window[targetKey] = dataToPersist;

  return dataToPersist;
};

/**
 * Re-applies the stored data to the URL
 */
const restoreDataToQueryParams = () => {
  if (paramsToPersist.length <= 0) {
    return;
  }

  const currentURL = new URL(window.location.href);
  const currentSearchParamKeys = [...currentURL.searchParams.keys()];
  let modified = false;
  paramsToPersist.forEach((param) => {
    if (window[targetKey]?.[param] !== undefined && !currentSearchParamKeys.includes(param)) {
      currentURL.searchParams.append(param, window[targetKey][param]);
      modified = true;
    }
  });

  if (modified) {
    // Only want the pathname with search params, that is the href without the origin
    // See url param: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState#parameters
    navBus.replaceState({url: currentURL.href.replace(currentURL.origin, '')});
  }
};

/**
 * Restores the persistent data to the URL and calls `window.location.reload()`
 * @param {Function} navigateFn - A function which will be given the new URL
 */
const reloadWithPersistentData = () => {
  restoreDataToQueryParams();
  navBus.reload();
};

/** Empties the array of keys to persist */
const clearStoredKeys = () => {
  paramsToPersist.length = 0;
};

export {
  getPersistentData,
  storePersistentData,
  restoreDataToQueryParams,
  reloadWithPersistentData,
  clearStoredKeys,
};
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
