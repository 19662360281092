(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.widgets.common.member.status').constant('ACCOUNT_STATUSES', {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED',
  });
})();
