import {Item, Picker} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {
  getDefaultSelectedDurationId,
  getDurationInfoFromId,
  transformPeriodsData,
} from './durationPickerUtils';

/**
 * Duration Picker component for selecting the monthly/annual ETLA contract duration
 * for viewing usage report
 */
const DurationPicker = ({
  'aria-labelledby': ariaLabelledBy,
  currentTimeInEpochSecs,
  isDisabled = false,
  onSelectionChange,
  timePeriodsResponse,
}) => {
  const intl = useIntl();
  const selectPeriodText = intl.formatMessage({
    id: 'binky.common.etlaUsageReport.selectTimePeriod',
  });
  const periodList = transformPeriodsData(currentTimeInEpochSecs, intl, timePeriodsResponse);
  const pickerId = 'etla-usage-report-duration-picker';
  return (
    <Picker
      aria-labelledby={ariaLabelledBy}
      defaultSelectedKey={getDefaultSelectedDurationId(
        currentTimeInEpochSecs,
        intl,
        timePeriodsResponse
      )}
      id={pickerId}
      isDisabled={isDisabled}
      label={selectPeriodText}
      maxWidth="100%"
      onSelectionChange={(id) => onSelectionChange(getDurationInfoFromId(id))}
      width="size-5000"
    >
      {periodList.map(({id, text}) => (
        <Item key={id}>{text}</Item>
      ))}
    </Picker>
  );
};

DurationPicker.propTypes = {
  /**
   * aria-labelledby prop to assign to the Picker component.
   */
  'aria-labelledby': PropTypes.string.isRequired,
  /**
   * Current time in seconds since epoch.
   */
  currentTimeInEpochSecs: PropTypes.number.isRequired,
  /**
   * Determines whether the duration picker should be in disabled mode or not.
   * Default value is false.
   */
  isDisabled: PropTypes.bool,
  /**
   * Callback method invoked when the current duration selection is changed by the user
   * An object with the following fields would be passed as argument:
   * - startDate(Number): start date of duration in seconds since epoch
   * - endDate(Number): end date of duration in seconds since epoch
   * - selectedPeriodType(String): one of USAGE_REPORT_DATA_POINT_TYPES
   */
  onSelectionChange: PropTypes.func.isRequired,
  /**
   * Array of objects each representing annual contract duration info
   * Each such object has the following fields:
   * - startDate(Number): start date of contract year in seconds since epoch
   * - endDate(Number): end date of contract year in seconds since epoch
   * - dataPoints(Object[]): array of objects representing info of monthly contract durations
   *   Each such monthly duration object contains the following fields:
   *   - startDate(Number): start date of contract month in seconds since epoch
   *   - endDate(Number): end date of contract month in seconds since epoch
   */
  timePeriodsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      dataPoints: PropTypes.arrayOf(
        PropTypes.shape({
          endDate: PropTypes.number.isRequired,
          startDate: PropTypes.number.isRequired,
        })
      ).isRequired,
      endDate: PropTypes.number.isRequired,
      startDate: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default DurationPicker;
