import hash from 'object-hash';

/**
 * @description Routing information needed by observability
 */
class ObservabilityRoute {
  /**
   * @description Constructor
   * @param {String} options.routeName Route name
   * @param {Object} options.routeParams Key-value pair, e.g. {orgId: '123@AdobeOrg'}
   */
  constructor(options) {
    assignRoute(options, this);
  }

  /**
   * @description Return a unique ID based on the routing information.
   * Specifically, different ObservabilityRoute with the same routing information
   * returns the same ID.
   * @returns {String} Unique ID based on routing information
   */
  getKey() {
    const keyParams = {};
    assignRoute(this, keyParams);
    return hash(keyParams);
  }
}

function assignRoute(route, target) {
  const {routeName, routeParams} = route;
  Object.assign(target, {routeName, routeParams});
}

export default ObservabilityRoute;
