(function () {
  'use strict';
  /**
   * @deprecated we do not need this form of flagging in jsx
   *
   * @ngdoc directive
   * @name binkyRequireFeature
   * @description
   * Feature markup directly within views without requiring controller support.
   * Element included if requireFeature flag is enabled.
   * For example: <p binky-require-feature="enabledFlag">Show this paragraph.</p>
   */
  angular.module('binky.widgets.common.feature').directive('binkyRequireFeature', requireFeature);

  function requireFeature(feature) {
    return {
      link: (scope, element, attrs) => {
        if (feature.isDisabled(attrs.binkyRequireFeature)) {
          element.remove();
        }
      },
      restrict: 'A',
    };
  }
})();
