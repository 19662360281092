/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {Checkbox} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v3 equivalent does not support the UI we want
import {MenuItem} from '@react/react-spectrum/Menu';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

import ASSIGNMENT_MENU_CONSTANTS from '../AssignmentMenuConstants';
import {useAssignmentMenuContext} from '../AssignmentMenuContext';

import styles from './MenuItemCheckbox.pcss';

const {MENUITEM_PREFIX} = ASSIGNMENT_MENU_CONSTANTS.IDS;

const MenuItemCheckbox = ({
  icon,
  item,
  isDisabled = () => false,
  isPreselected = false,
  label,
  onSelection,
  secondaryLabel,
}) => {
  const id = React.useRef(uniqueId(MENUITEM_PREFIX));
  const {isSelected} = useAssignmentMenuContext();
  const isDisabledForItem = isDisabled(item);
  const isSelectedForItem = isSelected(item);

  return (
    <MenuItem
      className={styles['menu-item-checkbox']}
      data-testid="assignment-menu-menuitem"
      disabled={isDisabledForItem}
      icon={icon}
      id={id.current}
      onSelect={() => onSelection(item, !isSelectedForItem)}
      role="menuitemcheckbox"
      selected={isSelectedForItem}
    >
      <span styleName="menu-item">
        <span styleName="menu-item-labels">
          <span data-testid="label">{label}</span>
          {secondaryLabel && (
            <span data-testid="secondary-label" styleName="secondary-label">
              {secondaryLabel}
            </span>
          )}
        </span>

        {/* when 'isPreselected' set to true, preselect and disable the Checkbox */}
        {isPreselected && isDisabledForItem ? (
          <Checkbox
            aria-label={label}
            excludeFromTabOrder
            isDisabled={isDisabledForItem}
            isSelected
          />
        ) : (
          !isDisabledForItem && (
            <Checkbox
              aria-label={label}
              excludeFromTabOrder
              isEmphasized
              isSelected={isSelectedForItem}
              onChange={(isItemSelected) => onSelection(item, isItemSelected)}
            />
          )
        )}
      </span>
    </MenuItem>
  );
};

MenuItemCheckbox.propTypes = {
  icon: PropTypes.node,
  isDisabled: PropTypes.func,
  /**
   * Whether the checkbox should be preselected or not. Defaults to false.
   */
  isPreselected: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  onSelection: PropTypes.func,
  secondaryLabel: PropTypes.node,
};

export default MenuItemCheckbox;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
