(function () {
  /**
   * @deprecated ES6 code uses mobx
   */
  angular.module('binky.core.common.model.cache').factory('modelCacheUtils', modelCacheUtils);

  /* @ngInject */
  function modelCacheUtils(_) {
    const service = {
      getParameterizedKey,
    };

    return service;

    function getParameterizedKey(keyValueMap) {
      let listKey = '';
      _.forOwn(keyValueMap, (val, key) => {
        listKey += `&${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
      });
      return listKey;
    }
  }
})();
