(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.table-checkbox
   * @description defines table checkbox with selection state.
   */
  angular.module('binky.widgets.common.table-checkbox', [
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.widgets.common.indeterminate',
    'ngCoral',
  ]);
})();
