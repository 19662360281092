import flow from 'lodash/flow';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

export default class AvailableType {
  /**
   * @description Transforms a data response Object into a new AvailableType
   *     instance for use.
   *
   * @param {Object} dataTransferObject - Initialization Object.  Refer to
   *     'constructor' method for details.
   *
   * @returns {AvailableType} The transformed available type
   */
  static apiResponseTransformer(dataTransferObject) {
    return new AvailableType(dataTransferObject);
  }

  /**
   * @description Creates a new AvailableType for use.
   *
   * @param {Object} options Initialization Object (params described below).
   * @param {boolean} options.allowed Whether this type is allowed by Global
   *     Admin Console.
   * @param {string} options.authenticatingDomainType - Source of the authenticating
   *     domain type for T2E AvailableType, e.g. 'TYPE3' if the T2E AvailableType is
   *     authenticated from a T3 domain. (intended to replace trustedDomainType)
   * @param {boolean} options.available Whether or not this type is available.
   * @param {OrganizationUser} [options.existingUser] The existing user for the
   *     given type.
   * @param {boolean} options.isExternallyManaged - Whether or not this type is
   *     externally managed.
   * @param {string} options.trustedDomainType - Source of the trusted domain
   *     type for T2E AvailableType, e.g. 'TYPE3' if the T2E AvailableType is
   *     sourced from a trusted T3 domain.
   * @param {boolean} options.userIsInOrg - Whether the user is already a member
   *     of the org that was queried.
   * @param {string} options.userType - The user's type.
   */
  constructor(options) {
    Object.assign(
      this,
      flow(
        (_options) =>
          pick(_options, [
            'allowed',
            'authenticatingDomainType',
            'available',
            'existingUser',
            'isExternallyManaged',
            'trustedDomainType',
            'userIsInOrg',
            'userType',
          ]),
        (_options) => omitBy(_options, (option) => option === undefined)
      )(options)
    );
  }
}
