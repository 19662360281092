import feature from 'services/feature';

import {PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS} from '../../services/product/ProductConstants';
import {
  CONTRACT_EXPIRATION_PHASE,
  CONTRACT_EXPIRATION_PHASE_ORDER,
  CONTRACT_STATUS,
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS,
} from '../contract/ContractConstants';

const GRACE_CONTRACT_SYMPTOMS =
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.GRACE];
const INACTIVE_CONTRACT_SYMPTOMS =
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.INACTIVE];
const NORMAL_CONTRACT_SYMPTOMS =
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.NORMAL];
const NOTIFICATION_CONTRACT_SYMPTOMS =
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.NOTIFICATION];
const POST_GRACE_CONTRACT_SYMPTOMS =
  PHASE_TO_CONTRACT_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.POST_GRACE];

const GRACE_LICENSE_SYMPTOMS =
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.GRACE];
const INACTIVE_LICENSE_SYMPTOMS =
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.INACTIVE];
const NORMAL_LICENSE_SYMPTOMS =
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.NORMAL];
const NOTIFICATION_LICENSE_SYMPTOMS =
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.NOTIFICATION];
const POST_GRACE_LICENSE_SYMPTOMS =
  PHASE_TO_LICENSE_COMPLIANCE_SYMPTOMS[CONTRACT_EXPIRATION_PHASE.POST_GRACE];

const complianceSymptomMode = {
  CONTRACT: 'CONTRACT',
  LICENSE: 'LICENSE',
};
const modeToSymptomsMap = {
  GRACE_CONTRACT_SYMPTOMS,
  GRACE_LICENSE_SYMPTOMS,
  INACTIVE_CONTRACT_SYMPTOMS,
  INACTIVE_LICENSE_SYMPTOMS,
  NORMAL_CONTRACT_SYMPTOMS,
  NORMAL_LICENSE_SYMPTOMS,
  NOTIFICATION_CONTRACT_SYMPTOMS,
  NOTIFICATION_LICENSE_SYMPTOMS,
  POST_GRACE_CONTRACT_SYMPTOMS,
  POST_GRACE_LICENSE_SYMPTOMS,
};

/* eslint-disable id-length -- providing descriptive method names */
/**
 * @description Method for checking the compliance symptoms of a product and
 *     returning the worst expiration phase that is found.
 *
 * @param {string} contractStatus - Status of the contract.
 * @param {Product} product - Product whose compliance symptoms will be checked.
 * @returns {string} expiration phase - the worst expiration phase the provided
 *     product's symptoms correspond to.
 */
function getExpirationPhaseForAllLicenseTuples(contractStatus, product) {
  const foundPhases =
    product?.licenseTupleList?.items.map((item) =>
      getExpirationPhaseFromComplianceSymptoms({
        contractStatus,
        mode: complianceSymptomMode.LICENSE,
        symptoms: item.complianceSymptoms,
      })
    ) || [];

  if (foundPhases.length > 0) {
    const sortedPhases = foundPhases.sort(
      (a, b) => CONTRACT_EXPIRATION_PHASE_ORDER[b] - CONTRACT_EXPIRATION_PHASE_ORDER[a]
    );
    if (
      sortedPhases.every((expirationPhase) => expirationPhase === CONTRACT_EXPIRATION_PHASE.UNKNOWN)
    ) {
      return CONTRACT_EXPIRATION_PHASE.UNKNOWN;
    }
    return sortedPhases[0];
  }
  return CONTRACT_EXPIRATION_PHASE.UNKNOWN;
}

/**
 * @description Helper to translate the contract compliance symptoms to the
 *     corresponding phase.
 *
 * @param {Contract} contract - Contract that contains the compliance.
 * @returns {string} expiration phase - the phase the provided symptoms and
 *     contract status correspond to.
 */
function getExpirationPhaseFromContractComplianceSymptoms(contract) {
  return getExpirationPhaseFromComplianceSymptoms({
    contractStatus: contract.status,
    mode: complianceSymptomMode.CONTRACT,
    symptoms: contract.complianceSymptoms,
  });
}

/**
 * @deprecated This method is not sufficient for situations where there are more
 *     than one tuple, replaced by getExpirationPhaseForAllLicenseTuples.
 * @description Helper to translate the license compliance symptoms to the
 *     corresponding phase.
 *
 * @param {string} contractStatus - Status of the contract.
 * @param {Object[]} symptoms - Array of symptom name and value objects.
 * @returns {string} expiration phase - the phase the provided symptoms
 *     correspond to.
 */
function getExpirationPhaseFromLicenseComplianceSymptoms(contractStatus, symptoms) {
  return getExpirationPhaseFromComplianceSymptoms({
    contractStatus,
    mode: complianceSymptomMode.LICENSE,
    symptoms,
  });
}

/* eslint-enable id-length -- providing descriptive method names */

/**
 * @description Returns true if symptom is in list of complianceSymptoms
 *
 * @param {ComplianceSymptom[]} complianceSymptoms The list of compliance symptoms
 * @param {String} name The compliance symptom name
 * @returns {Boolean} true if list of symptoms has the compliance symptom
 */
function hasComplianceSymptom({complianceSymptoms, name}) {
  return complianceSymptoms.some((complianceSymptom) => complianceSymptom.name === name);
}

/**
 * @description Returns true if provided list of complianceSymptoms
 * has the compliance symptom set to true
 *
 * @param {ComplianceSymptom[]} complianceSymptoms The list of compliance symptoms
 * @param {String} name The compliance symptom name
 * @returns {Boolean} true if list has the compliance symptom set to true
 */
function hasComplianceSymptomEnabled({complianceSymptoms, name}) {
  return complianceSymptoms.some(
    (complianceSymptom) => complianceSymptom.name === name && complianceSymptom.isEnabled()
  );
}

/**
 * @description Returns true if provided list of complianceSymptoms
 * has all the provided symptom names set to true
 *
 * @param {ComplianceSymptom[]} complianceSymptoms The list of compliance symptoms
 * @param {String[]} names The list of compliance symptom names to check for
 * @returns {Boolean} true if list of symptoms has the compliance symptom set to true
 */
function hasComplianceSymptomsEnabled({complianceSymptoms, names}) {
  return names.every((name) => hasComplianceSymptomEnabled({complianceSymptoms, name}));
}

/**
 * @description Fetches a compliance symptom value
 *
 * @param {ComplianceSymptoms[]} complianceSymptoms the list of compliance symptoms
 * @param {String} name the name of the compliance symptom to find
 * @returns {String} the value of the specified symptom, or null
 */
function getComplianceSymptomValue({complianceSymptoms, name}) {
  const foundSymptom = complianceSymptoms?.find((symptom) => symptom.name === name);
  return foundSymptom?.value;
}

/** Private, unexported methods below */

/**
 * @description Helper to translate compliance symptoms to the
 *     corresponding phase.
 *
 * @param {string} contractStatus - Status of the contract.
 * @param {string} mode - String specifying what mode is being used (either
 *     CONTRACT or LICENSE).
 * @param {Object[]} symptoms - Array of symptom name and value objects.
 * @returns {string} expiration phase - the phase the provide symptoms
 *     correspond to.
 */
function getExpirationPhaseFromComplianceSymptoms({contractStatus, mode, symptoms}) {
  if (!contractStatus) {
    // safeguards against virtual DX contracts
    return CONTRACT_EXPIRATION_PHASE.UNKNOWN;
  }
  if (contractStatus === CONTRACT_STATUS.INACTIVE) {
    return CONTRACT_EXPIRATION_PHASE.INACTIVE;
  }
  if (!symptoms || symptoms.length === 0) {
    // safeguards against contracts where the status may be present but the
    // symptoms are not.
    return CONTRACT_EXPIRATION_PHASE.UNKNOWN;
  }

  if (
    hasRequiredAndDoesNotHaveExcludedSymptoms(
      modeToSymptomsMap[`NORMAL_${mode}_SYMPTOMS`],
      symptoms,
      mode
    )
  ) {
    return CONTRACT_EXPIRATION_PHASE.NORMAL;
  }
  if (
    hasRequiredAndDoesNotHaveExcludedSymptoms(
      modeToSymptomsMap[`NOTIFICATION_${mode}_SYMPTOMS`],
      symptoms,
      mode
    )
  ) {
    return CONTRACT_EXPIRATION_PHASE.NOTIFICATION;
  }
  // Putting POST_GRACE before GRACE because GRACE is a superset of POST_GRACE
  if (
    hasRequiredAndDoesNotHaveExcludedSymptoms(
      modeToSymptomsMap[`POST_GRACE_${mode}_SYMPTOMS`],
      symptoms,
      mode
    )
  ) {
    return CONTRACT_EXPIRATION_PHASE.POST_GRACE;
  }
  if (
    hasRequiredAndDoesNotHaveExcludedSymptoms(
      modeToSymptomsMap[`GRACE_${mode}_SYMPTOMS`],
      symptoms,
      mode
    )
  ) {
    return CONTRACT_EXPIRATION_PHASE.GRACE;
  }
  if (
    hasRequiredAndDoesNotHaveExcludedSymptoms(
      modeToSymptomsMap[`INACTIVE_${mode}_SYMPTOMS`],
      symptoms,
      mode
    )
  ) {
    return CONTRACT_EXPIRATION_PHASE.INACTIVE;
  }
  return CONTRACT_EXPIRATION_PHASE.UNKNOWN;
}

function hasRequiredAndDoesNotHaveExcludedSymptoms(phaseSymptoms, itemSymptoms, mode) {
  if (feature.isEnabled('bug_fix_e2e_23023')) {
    return mode === complianceSymptomMode.LICENSE
      ? phaseSymptoms.REQUIRED_SYMPTOMS.every(
          (name) =>
            itemSymptoms.find(
              (symptom) => symptom.name === name && (symptom.isEnabled() || symptom.isDate())
            ) !== undefined
        ) &&
          phaseSymptoms.EXCLUDED_SYMPTOMS.every(
            (name) => itemSymptoms.find((symptom) => symptom.name === name) === undefined
          )
      : phaseSymptoms.REQUIRED_SYMPTOMS.every(
          (name) =>
            itemSymptoms.find(
              (symptom) => symptom.name === name && (symptom.isEnabled() || symptom.isDate())
            ) !== undefined
        ) &&
          phaseSymptoms.DISABLED_SYMPTOMS.every(
            (name) =>
              itemSymptoms.find((symptom) => symptom.name === name && symptom.isDisabled()) !==
              undefined
          ) &&
          phaseSymptoms.EXCLUDED_SYMPTOMS.every(
            (name) => itemSymptoms.find((symptom) => symptom.name === name) === undefined
          );
  }
  return (
    phaseSymptoms.REQUIRED_SYMPTOMS.every(
      (name) =>
        itemSymptoms.find(
          (symptom) => symptom.name === name && (symptom.isEnabled() || symptom.isDate())
        ) !== undefined
    ) &&
    phaseSymptoms.DISABLED_SYMPTOMS.every(
      (name) =>
        itemSymptoms.find((symptom) => symptom.name === name && symptom.isDisabled()) !== undefined
    ) &&
    phaseSymptoms.EXCLUDED_SYMPTOMS.every(
      (name) => itemSymptoms.find((symptom) => symptom.name === name) === undefined
    )
  );
}

export {
  getComplianceSymptomValue,
  getExpirationPhaseForAllLicenseTuples,
  getExpirationPhaseFromContractComplianceSymptoms,
  getExpirationPhaseFromLicenseComplianceSymptoms,
  hasComplianceSymptom,
  hasComplianceSymptomEnabled,
  hasComplianceSymptomsEnabled,
};
