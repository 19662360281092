const PAGE_ALIGNMENT = {END: 'end', START: 'start'};
const PAGE_COMPONENTS = {
  AVATAR: 'Avatar',
  BUTTON: {VARIANT: {ACCENT: 'accent', CTA: 'cta', SECONDARY: 'secondary'}},
  DETAILS_BUTTON: 'DetailsButton',
  IMAGE_ICON: 'ImageIcon',
  TAG_GROUP: 'PageHeaderTagGroup',
  USER_GROUP_AVATAR: 'UserGroupAvatar',
  USER_GROUP_SHARE_INFO: 'UserGroupShareInfo',
};
// must take into account size of header 56px and footer 48px to center vertically
const PAGE_CONTENT_HEIGHT = 'calc(100vh - 56px - 48px)';
const PAGE_SECTIONS = {
  ACTIONS: 'PageActions',
  BANNER: 'PageBanner', // a single PageBanner component
  BANNERS: 'PageBanners', // PageBanners section which contains PageBanner components
  BREADCRUMBS: 'PageBreadcrumbs',
  CONTENT: 'PageContent',
  DESCRIPTION: 'PageDescription',
  HEADER: 'PageHeader',
  INFO_BAR: 'PageInfoBar',
  NAV: 'PageNav',
};

export {PAGE_ALIGNMENT, PAGE_COMPONENTS, PAGE_CONTENT_HEIGHT, PAGE_SECTIONS};
