(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.shell.navigation.responsive-nav')
    .constant('NAV_DROPDOWN_ID_PREFIX', 'nav_dropdown_')
    .constant('NAV_DROPDOWN_OPENED', 'NavDropdownOpened')
    .constant('NAV_MENU_ID_PREFIX', 'nav_menu_')
    .constant('NAV_ITEM_ID_PREFIX', 'nav_item_')
    .constant('NAV_EVENT', {
      RESET: 'ResetNav',
    });
})();
