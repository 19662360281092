(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.shell.navigation.breadcrumbs:binkyBreadCrumbs
   *
   * @description displays a horizontal list of breadcrumbs.
   *  This traverses the given navigation list, finding the route to the current state.
   *  Each active item in the route becomes a crumb.
   *  Each crumb, except the last one, is a link, that allows navigation back up the page hierarchy.
   *  If no route is found, or there is only one crumb, nothing will be displayed.
   *
   * @binding {String|undefined} lastCrumbName. If specified, this is used as the name of the last
   *  crumb, rather than the state name.
   * @binding {String} navItemListId. Id of the navigation list that is the root of the breadcrumbs,
   *  For example, DEVELOPER_WORKSPACE_NAV_ID.
   */
  angular.module('binky.shell.navigation.breadcrumbs').component('binkyBreadcrumbs', {
    bindings: {
      lastCrumbName: '@?',
      navItemListId: '<',
    },
    controller,
    templateUrl: 'shell/navigation/breadcrumbs/breadcrumbs.component.html',
  });

  /* @ngInject */
  function controller($log, $state, $transitions, _, navManager) {
    const vm = this;
    let deregisterHookFn;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /////////////

    function $onInit() {
      // The html does not show breadcrumbs if there is only one.
      buildCrumbs();

      // {} means match all transitions.
      deregisterHookFn = $transitions.onSuccess({}, (/* transition, state */) => {
        buildCrumbs();
      });
    }

    function $onDestroy() {
      deregisterHookFn();
    }

    ///////////////

    function buildCrumbs() {
      vm.breadcrumbs = [];

      if (!vm.navItemListId) {
        return;
      }

      const navList = navManager.find(vm.navItemListId);
      constructCrumbs(navList, $state.$current);
    }

    function constructCrumbs(navList, state) {
      let nextState = state;
      let prevStateName;
      do {
        if (nextState && _.endsWith(nextState.name, '.**')) {
          // if next state picked up is a future state, we need to call its lazyLoad
          // method to initialize before we can construct crumbs (future states do
          // not expose 'data' attributes, so breadcrumbs would otherwise be lost)
          const futureStateName = prevStateName || _.replace(nextState.name, '.**', '');
          const lazyLoadPromise = $state.lazyLoad(futureStateName);
          lazyLoadPromise
            .then(() => {
              constructCrumbs(navList, $state.get(futureStateName));
            })
            .catch((error) => {
              $log.warn(`Error resolving lazy load: ${error}`);
            });
          nextState = undefined;
        } else {
          prevStateName = undefined;
          const nextStateName = _.get(nextState, 'name');
          const navItem = nextStateName ? navList.findByState(nextStateName) : undefined;

          if (navItem && _.has(navItem, 'name')) {
            const breadcrumb = createBreadcrumb(navItem);
            vm.breadcrumbs.unshift(breadcrumb);
            // store prev state name here, if it represents a future state, the
            // future state will be returned by $state.get() and we'll lose the
            // actual breadcrumb in favor of the wildcard name (if we don't save)
            prevStateName = _.get(nextState, 'data.breadcrumb.previous');
            nextState = prevStateName ? $state.get(prevStateName) : undefined;
          } else if (nextState === $state.$current) {
            nextState = nextState.parent;
          } else {
            nextState = undefined;
          }
        }
      } while (nextState);
    }

    function createBreadcrumb(navItem) {
      const breadcrumb = {
        link: navItem.getHrefForState(),
      };
      navItem
        .getDisplayName()
        .then((name) => {
          breadcrumb.name = name;
        })
        .catch((error) => {
          $log.warn(`Error resolving NavItem getDisplayName: ${error}`);
        });
      return breadcrumb;
    }
  }
})();
