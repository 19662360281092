/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to convert from a DOMAIN_STATUS_LABEL_KEY key to a DOMAIN_STATUS status.
 * @param {String} statusLabelKey A key from DOMAIN_STATUS_LABEL_KEY.
 * @returns {String} The DOMAIN_STATUS status that corresponds to the DOMAIN_STATUS_LABEL_KEY key.
 */
(function () {
  'use strict';
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.domains.status-filter')
    .filter('binkyDomainStatusLabelKeyToStatus', filter);

  /* @ngInject */
  function filter(DOMAIN_STATUS, DOMAIN_STATUS_LABEL_KEY) {
    return getDomainStatus;

    function getDomainStatus(statusLabelKey) {
      if (statusLabelKey === DOMAIN_STATUS_LABEL_KEY.ACTIVE) {
        return DOMAIN_STATUS.ACTIVE;
      } else if (statusLabelKey === DOMAIN_STATUS_LABEL_KEY.NEEDS_APPROVAL) {
        return DOMAIN_STATUS.CLAIMED;
      } else if (statusLabelKey === DOMAIN_STATUS_LABEL_KEY.NEEDS_DIRECTORY) {
        return DOMAIN_STATUS.VALIDATED;
      } else if (statusLabelKey === DOMAIN_STATUS_LABEL_KEY.NEEDS_VALIDATION) {
        return DOMAIN_STATUS.UNCLAIMED;
      } else if (statusLabelKey === DOMAIN_STATUS_LABEL_KEY.WITHDRAWN) {
        return DOMAIN_STATUS.WITHDRAWN;
      }
      return '';
    }
  }
})();
