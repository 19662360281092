import {navBus} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import {AngularWrapperContext} from 'core/services/ReactContainerBridge';

/**
 * Creates an `<a>` element that handles navigation between pages. This is necessary to
 * handle navigation between pages that are in various states of React migration.
 * If you need this link styled to match others then you can wrap it yourself, see example.
 * @example
 * import {Link} from '@adobe/react-spectrum';
 *
 * const Component = () => (<Link>
 *  <NavigationAnchor href="/url">Text</NavigationAnchor>
 * </Link>);
 */
const NavigationAnchor = React.forwardRef((props, ref) => {
  const {children, href, ...other} = props;

  // click handler to bypass angular routing
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      navBus.navigate(href);
    },
    [href]
  );

  return (
    <AngularWrapperContext.Consumer>
      {(inAngularWrapper) => (
        <a ref={ref} {...other} href={href} onClick={(e) => inAngularWrapper || onClick(e)}>
          {children}
        </a>
      )}
    </AngularWrapperContext.Consumer>
  );
});

NavigationAnchor.propTypes = {
  /** The text for the anchor */
  children: PropTypes.node.isRequired,
  /** The destination URL */
  href: PropTypes.string.isRequired,
};

export default NavigationAnchor;
