(function () {
  'use strict';
  /**
   * @deprecated should use React Spectrum SearchField
   *
   * @ngdoc directive
   * @name binky.widgets.search:binkySearch
   *
   * @description a wrapper around the Coral search widget
   *
   * @param {Boolean} [allowsWildcard] True to not count wildcard (*) at the end of the search string toward minLength (defaults to false)
   * @param {Boolean} [focusOnShow] Whether the enabled search field should take focus on display. Defaults to false.
   * @param {Boolean} [isDisabled] Whether the search field is to be disabled or not. Defaults to false.
   * @param {String} [label] The aria-label string to be set on the input field. If not defined, and labelledBy is not
   *   defined, the placeholder will be used. If labelledBy is also set, aria-labelledby will take precedence
   *   over aria-label.
   * @param {Number} [maxLength] The maximum search string length allowed (set on input field to restrict input length)
   * @param {Number} [minLength] The minimum search string length allowed for validation before submit
   * @param {String} [labelledBy] The id of the label for the search input buried in the coral-search component.
   *   Default is undefined.
   * @param {String} [name] The name to pass to the coral-search. Default is ''.
   * @param {Function} [onChange] A function to call when the input for this search changes
   * @param {Function} [onClick] A function to call when the input for this search is clicked
   * @param {Function} [onSubmit] A function to call when the search is submitted (user hits enter). This should be
   *   used when the search/filter shouldn't be applied until the user is finished entering (server search call)
   * @param {String} [placeholder] The placeholder text to display. If not specified, and labelledBy is not specified,
   *   'Search' will be used.
   * @param {Boolean} [quiet] Whether to use Coral's quiet styling or not (defaults to false)
   * @param {RegEx} [searchPattern] The pattern allowed for validation before submit
   * @param {String} [searchPatternErrorMessage] The error message to show if the pattern is violated
   *
   * @example <binky-search name="MyName" placeholder="My Placeholder" on-change="myCallback"></binky-search>
   *
   *
   */
  angular.module('binky.widgets.common.search').directive('binkySearch', binkySearchDirective);

  /* @ngInject */
  function binkySearchDirective($translate, _, COMMON_EVENTS) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        allowsWildcard: '<?',
        focusOnShow: '<?',
        isDisabled: '<?',
        label: '@?',
        labelledBy: '@?',
        maxLength: '<?',
        minLength: '<?',
        name: '@?',
        onChange: '<?',
        onClick: '<?',
        onSubmit: '<?',
        placeholder: '@?',
        quiet: '@?',
        searchPattern: '<?',
        searchPatternErrorMessage: '@?',
      },
      template: '',
    };

    return directive;

    /////////////////

    function linkFn(scope, element) {
      scope.lengthErrorMessage = $translate.instant('binky.widgets.common.search.lengthError', {
        minLength: scope.minLength,
      });
      scope.wildcardLengthErrorMessage = $translate.instant(
        'binky.widgets.common.search.wildcardLengthError',
        {
          minLength: scope.minLength,
        }
      );
      scope.errorMessage = '';
      scope.maxLength = scope.maxLength ? _.toSafeInteger(scope.maxLength) : 256;

      const coralSearch = createCoralSearch(element, scope);
      const errorTooltip = createCoralErrorIconAndTooltip(element);

      // Note we need to re-find the elements, we can't use the coralSearch above
      const searchElement = element.find('coral-search');
      searchElement.on('coral-search:input', () => {
        onChange();
        scope.$apply();
      });
      searchElement.on('coral-search:clear coral-search:submit', () => {
        onSubmit();
        scope.$apply();
      });

      element.on(COMMON_EVENTS.SUBMIT, onSubmit);

      function onChange() {
        setInvalid(false);
        _.invoke(scope, 'onChange', element.find('input').val());
      }

      function onSubmit() {
        onChange();
        const trimmedSearchValue = _.trim(element.find('input').val());
        const valid = validate(trimmedSearchValue);
        // set invalid on the element if validation fails
        setInvalid(!valid);
        // only submit if validation succeeds
        if (valid) {
          // pass along the trimmed search value, so we don't submit searches that consist only of spaces
          _.invoke(scope, 'onSubmit', _.trim(trimmedSearchValue));
        }
      }

      function setInvalid(invalid) {
        coralSearch.invalid = invalid;
        if (invalid) {
          errorTooltip.set({
            content: {
              innerText: scope.errorMessage,
            },
          });
          element.addClass('invalid');
        } else {
          element.removeClass('invalid');
          // close the tooltip if it's still open
          if (errorTooltip.open) {
            errorTooltip.set({open: false});
          }
        }
      }

      // eslint-disable-next-line complexity
      function validate(value) {
        let valid = true;
        let usesWildcard = false;
        scope.errorMessage = '';
        if (scope.minLength) {
          let lengthCheckValue = value;
          if (
            scope.allowsWildcard &&
            lengthCheckValue.charAt(lengthCheckValue.length - 1) === '*'
          ) {
            lengthCheckValue = lengthCheckValue.slice(0, lengthCheckValue.length - 1);
            usesWildcard = true;
          }
          if (lengthCheckValue && lengthCheckValue.length > 0) {
            valid = lengthCheckValue.length >= scope.minLength;
            if (!valid) {
              scope.errorMessage = usesWildcard
                ? scope.wildcardLengthErrorMessage
                : scope.lengthErrorMessage;
            }
          }
        }
        const pattern = scope.searchPattern || new RegExp(/^.*$/);
        if (pattern && valid) {
          valid = pattern.test(value);
          if (!valid) {
            scope.errorMessage =
              scope.searchPatternErrorMessage ||
              $translate.instant('binky.widgets.common.search.patternError');
          }
        }
        return valid;
      }
    }

    function createCoralSearch(element, scope) {
      // dynamically insert the coral search element
      const coralSearch = new Coral.Search();
      coralSearch.set({
        name: scope.name || '',
        placeholder: getPlaceholder(scope),
      });
      if (scope.quiet && scope.quiet === 'true') {
        coralSearch.set({
          variant: 'quiet',
        });
      }
      setDisabled();
      scope.$watch('isDisabled', setDisabled);
      element.append(coralSearch);
      element.prop('$coralComponent', coralSearch);

      // Make sure the search component has fully rendered before looking for the <input> element.
      Coral.commons.ready(() => {
        // labelledBy needs the input component to be rendered to establish the connection with the label.
        coralSearch.set({
          labelledBy: scope.labelledBy || undefined,
        });

        const searchInput = element.find('input');
        if (!_.isEmpty(searchInput)) {
          searchInput.attr('maxlength', scope.maxLength);
          searchInput.attr('aria-label', getAriaLabel(scope));
          if (scope.focusOnShow && !coralSearch.disabled) {
            searchInput.focus();
          }

          if (scope.onClick) {
            searchInput.on('click', (event) => {
              event.stopImmediatePropagation();
              scope.onClick();
            });
          }
        }
      });

      return coralSearch;

      function setDisabled() {
        coralSearch.disabled = !!scope.isDisabled;
      }
    }

    function createCoralErrorIconAndTooltip(element) {
      const errorIcon = new Coral.Icon();
      errorIcon.set({
        icon: 'alert',
        size: 'S',
        tabIndex: 0,
      });
      errorIcon.setAttribute('class', `${errorIcon.getAttribute('class')} search-error-icon`);
      const errorTooltip = new Coral.Tooltip();
      errorTooltip.set({
        placement: 'right',
        target: errorIcon,
        variant: 'error',
      });
      errorTooltip.setAttribute(
        'class',
        `${errorTooltip.getAttribute('class')} search-error-tooltip`
      );

      errorIcon.on('mouseenter', () => {
        errorTooltip.open = true;
      });
      errorIcon.on('mouseleave', () => {
        errorTooltip.open = false;
      });
      element.append(errorIcon);
      element.append(errorTooltip);
      return errorTooltip;
    }

    // If neither label or labelledBy is explicitly set, we'll set aria-label to the placeholder.
    function getAriaLabel(scope) {
      // We don't need to add an implicit label if it is already explicitly labelled.
      // Note: If the labelledBy element is a label, coral-search will add a for attribute to the label
      // so that it is possible to focus/activate the input by clicking on the label, as well as the search
      // component itself.
      if (scope.labelledBy) {
        return undefined;
      }

      // labelledBy has priority over label
      if (scope.label) {
        return scope.label;
      }

      return getPlaceholder(scope);
    }

    function getPlaceholder(scope) {
      if (scope.placeholder) {
        return scope.placeholder;
      }

      // Use the default placeholder if a label isn't provided.
      return scope.label || scope.labelledBy
        ? undefined
        : $translate.instant('binky.widgets.common.search.defaultPlaceholder');
    }
  }
})();
