(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name NavItem
   * @description Model for NavItem Objects
   */
  angular.module('binky.shell.navigation.nav-item').factory('NavItem', getNavItem);

  /* @ngInject */
  function getNavItem($injector, $log, $q, $state, $stateParams, $translate, _, navManager) {
    /**
     * @class
     * @description Representation of a navigable item.
     */
    class NavItem {
      /**
       * @constructs NavItem
       * @description Constructor for NavItem model Objects.
       * @param {Object} options - NavItem instance settings
       * @param {Object} [options.access] - NavItem access settings
       * @param {Object} [options.access.feature] - access bumpered when feature enabled
       * @param {String} [options.access.feature.name] - feature flag name
       * @param {String} [options.access.feature.omit] - if true, not added to nav list
       * @param {Array} [options.access.roles] - access restricted to roles
       * @param {String} [options.child] - ID of child navigation list
       * @param {Boolean} [options.disabled] - true if item should be disabled
       * @param {String} [options.group] - name of group to add item to
       * @param {Boolean} [options.hidden] - true if item should be hidden
       * @param {String} [options.icon] - name of icon to be used in item
       * @param {Boolean} [options.invalid] - true if item is invalid
       * @param {String} [options.list] - if item is a sub list, this is its key
       * @param {String} options.name - name of item
       * @param {Boolean} [options.nameSanitized] - whether the name field is sanitized to
       *   be safe for passing to $translate. Defaults to false.
       * @param {Number} options.order - zero-based index where tab should live in list
       * @param {Object} options.state - item's destination state (when clicked) (see: https://ui-router.github.io/ng1/docs/0.3.1/index.html#/api/ui.router.state.$state)
       * @param {String} options.state.name - state name
       * @param {String} [options.state.options] - state options
       * @param {String} [options.state.params] - state params
       * @param {String} [options.state.parent] - state parent
       * @param {String} [options.state.url] - state url
       */
      constructor(options = {}) {
        _.assign(
          this,
          _.pick(options, [
            'access',
            'child',
            'disabled',
            'displayName',
            'group',
            'hidden',
            'icon',
            'invalid',
            'list',
            'name',
            'nameSanitized',
            'order',
          ])
        );
        // do not include state definition in nav item model (only used for state
        // initialization in nav-ready provider)
        if (_.has(options, 'state')) {
          this.state = _.pick(options.state, ['name', 'params', 'parent', 'options', 'url']);
          if (_.has(options, 'state.definition.abstract')) {
            this.state.abstract = _.get(options, 'state.definition.abstract');
          }
        }
      }

      /**
       * @description Method to determine if the given state route exists as a
       *   link (i.e. - this.state.name) for this navigable item or any child
       *   lists, if this item represents an abstract state or parent list.
       * @param {String} stateName - name of state route to look for
       * @param {Object} stateParams - params of state route to look for
       * @returns {Boolean} true if route exists, else false
       */
      containsRouteTo(stateName, stateParams) {
        let containsRouteTo = false;

        if (_.has(this, 'state')) {
          // tab has a state, so check if it is equal to or a parent (by state) of the current one
          containsRouteTo =
            _.startsWith(stateName, this.state.name) && _.isMatch(stateParams, this.state.params);
        }

        if (!containsRouteTo && _.has(this, 'child')) {
          // check if a child nav item represents the current state
          const childList = navManager.find(this.child);
          return childList ? childList.containsRouteTo(stateName, stateParams) : false;
        }

        return containsRouteTo;
      }

      /**
       * @description Method to determine if there is a route to the current state, either via
       *   direct links (see containsRouteTo) or, if this state, has a data.origin property, if
       *   there is a route to the origin.
       * @returns {Boolean} true if current location/route exists, else false
       */
      containsRouteToCurrentLocation() {
        let contains = this.containsRouteTo($state.$current.name, $stateParams);

        if (!contains && _.hasIn($state.$current, 'data.origin')) {
          contains = this.containsRouteTo($state.$current.data.origin, $stateParams);
        }

        return contains;
      }

      getDisplayName() {
        if (this.displayName) {
          return $injector.invoke(this.displayName);
        }
        // $translate cannot be called with an empty key
        return $q.resolve(
          _.isEmpty(this.name)
            ? undefined
            : $translate(this.name).catch(
                () =>
                  // This is hit when the name is not matched to a key in the
                  // locale files.
                  // Technically the error param returned here is the same as name,
                  // but for safety (and ease of code reading) we use the original.
                  this.name
              )
        );
      }

      /**
       * @description Method to return href for an active state. This is equivalent
       *   to the onClick() behavior for an active state.
       * @returns {String|undefined} href for state, or undefined, if the state
       *   is not active.
       */
      getHrefForState() {
        if (this.isLazyLoadPending()) {
          this.loadState();
        } else {
          // if this NavItem has a state and it's not abstract, construct/return
          // an HREF to it...
          const firstActiveState = getFirstActiveState(this);
          if (firstActiveState) {
            const params = firstActiveState.state.params || $stateParams;
            return $state.href(firstActiveState.state.name, params, firstActiveState.state.options);
          }
        }
        return undefined;
      }

      /**
       * @description Method to return the state name of the navItem.
       * @returns {String|undefined} state name, or undefined if the state name
       * does not exist
       */
      getStateName() {
        return _.get(this, 'state.name');
      }

      /**
       * @description Method to determine if this NavItem has a non-abstract state
       *   defined.
       * @returns {Boolean} true if non abstract state defined, else false
       */
      hasNonAbstractStateDefined() {
        return (
          _.has(this, 'state.name') &&
          !_.get($state.get(this.state.name), 'abstract') &&
          !_.get(this, 'state.abstract', false)
        );
      }

      /**
       * @description Method to determine if this item is active.
       * @returns {Boolean} true if item is active, else false
       */
      isActive() {
        return !!(this.state || this.child) && !this.disabled && !this.hidden && !this.invalid;
      }

      /**
       * @description Method to determine if this navigation item's state
       *   represents a future state that has not been loaded yet. Lazy loaded
       *   states (e.g. - feature flagged states) will have a name that ends in
       *   '.**' until they have been loaded. Once they are loaded, future calls
       *   to retrieve them will include the actual name/state, instead of the
       *   wildcard version. This is useful to know when attempting to perform
       *   other actions that require a state to be loaded first (e.g. - create
       *   an href to the state).
       * @returns {Boolean} true if a future (lazy loaded) state that has not
       *   been loaded, else false
       */
      isLazyLoadPending() {
        if (_.has(this, 'state.name')) {
          const stateRef = $state.get(this.state.name);
          // only check registered states, if state not registered, false will be
          // returned (below)
          if (stateRef) {
            return _.endsWith(stateRef.name, '.**');
          }
        }
        // navigation item does not represent a future state, so return false
        return false;
      }

      /**
       * @description Method to explicitly load this navigation item's state
       *   into the state tree. Calling code needs to ensure that this
       *   navigation item has an associated state definition prior to calling,
       *   else error will be thrown when attempting to load.
       * @throws {TypeError} if no state associated with this navigation item
       * @returns {Promise} promise which will resolve to loaded state
       *   definition Object when state has been loaded
       */
      loadState() {
        if (!this.loadingPromise) {
          this.loadingPromise = $state.lazyLoad(this.state.name);
        }
        return this.loadingPromise;
      }

      /**
       * @description Method to handle browser interaction with navigation item
       */
      onClick() {
        if (this.containsRouteToCurrentLocation()) {
          return;
        }

        // if this NavItem has a state and it's not abstract, go to it...
        const firstActiveState = getFirstActiveState(this);
        if (firstActiveState) {
          const params = firstActiveState.setStateParams();
          $state.go(firstActiveState.state.name, params, firstActiveState.state.options);
        }
      }

      /**
       * @description Method to handle keyboard interaction with navigation item
       * @param {Event} event - jqLite event that generated the keypress
       */
      onKeypress(event) {
        // only call onClick handler if enter or spacebar is clicked
        if (event.keyCode === 13 || event.keyCode === 32) {
          this.onClick();
        }
        event.preventDefault();
      }

      /**
       * @description Method to set the current state params with the orgId.
       * @returns {Object|undefined} if state name, state params for the state, else undefined
       */
      setStateParams() {
        if (_.has(this, 'state.name')) {
          this.state.params = this.state.params || {};
          return this.state.params;
        }
        return undefined;
      }

      /**
       * @description Method to update the hidden attribute on this navigable
       *   item.
       *
       *   If this item is a child of a parent navigable item, then its
       *   access.authorized callback is checked to ensure that authorization is
       *   allowed. If the callback succeeds, then its Boolean value is used to
       *   determine visibility. However, if the callback fails for any reason,
       *   then access to this navigable item is allowed (is visible).
       *
       *   If this item is not a child of a parent navigable item, then a
       *   resolved Promise is returned without updating the hidden attribute at
       *   all (i.e. - this item stays visible).
       * @param {Transition} trans - the current Transition taking place when
       *   this navigable item's visibility is updated. For more info, see:
       *
       *   https://ui-router.github.io/ng1/docs/latest/classes/transition.transition-1.html
       * @returns {Promise} resolves when authorization check is successful,
       *   rejects when authorization attempt fails. Navigable item 'hidden'
       *   attribute is updated either way.
       */
      updateVisibility(trans) {
        if (_.has(this, 'access.authorized')) {
          return this.access.authorized(this, $injector, trans).then(
            (authorized) => {
              this.hidden = authorized ? undefined : true;
            },
            (error) => {
              $log.debug(`nav item (${this.name}) user authorization check failed: ${error}`);
              this.hidden = undefined;
            }
          );
        }

        return $q.resolve();
      }
    }

    return NavItem;

    ////////

    /**
     * @description Helper method to determine the first active state of a NavItem.
     *   The first active state will be the state contained in the given NavItem
     *   if that NavItem has a state defined and it is not an abstract state.
     *   Otherwise, the first active state will be the first child NavItem found
     *   that also contains a state that is not abstract.
     * @param {NavItem} navItem - reference to NavItem returning state for
     * @returns {NavItem|undefined} will return first active NavItem, if one exists,
     *   else will return undefined
     */
    function getFirstActiveState(navItem) {
      if (navItem.hasNonAbstractStateDefined()) {
        // this nav item has a non-abstract state defined, so return reference...
        return navItem;
      } else if (_.has(navItem, 'child')) {
        // ...otherwise, if child list defined...
        // ...check first active child for state & return (if available)
        const childList = navManager.find(navItem.child);
        if (childList) {
          const firstActiveChild = childList.findFirstActive();
          if (firstActiveChild) {
            return getFirstActiveState(firstActiveChild);
          }
        }
      }
      return undefined;
    }
  }
})();
