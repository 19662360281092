(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/go-url/GoUrl.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.learn-more-go-url
   *
   * @description Constructs a go-url from the name and by default, displays 'Learn more'.
   *  Optionally, transcluded text can be used for the link text. The link text is styled so it doesn't wrap across lines.
   *
   * @param {String} name. The text for the anchor.
   *
   * @example <binky-learn-more-go-url name="aac_identity_learn_more"></binky-learn-more-go-url>
   *   displays a link which looks like 'Learn more >'
   * @example <binky-learn-more-go-url name="aac_identity_learn_more">Alternate link text</binky-learn-more-go-url>
   *   displays a link which looks like 'Alternate link text >'
   */
  angular.module('binky.widgets.common.learn-more-go-url').component('binkyLearnMoreGoUrl', {
    bindings: {
      name: '@',
    },
    templateUrl: 'widgets/common/learn-more-go-url/learn-more-go-url.component.html',
    transclude: true,
  });
})();
