(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.insights.item:InsightsItemDetail
   * @description Model for a insights item detail
   */
  angular
    .module('binky.core.insights.item')
    .factory('InsightsItemDetail', getInsightsItemDetailModel);

  /* @ngInject */
  function getInsightsItemDetailModel(
    $http,
    $log,
    $q,
    $rootScope,
    $timeout,
    _,
    apiUtils,
    authentication,
    downloadUtils,
    INSIGHTS_ITEM_DETAIL_CONTENT_TYPE,
    INSIGHTS_ITEM_DETAIL_DOWNLOAD_MECHANISM,
    INSIGHTS_ITEM_DETAIL_EVENT,
    reportBroker,
    User
  ) {
    class InsightsItemDetail {
      /**
       * @description Creates a new InsightsItemDetail for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Array} options.assets array of asset of the insights item detail.
       * @param {String} options.contentType the content type of the insights item detail. Ex: folder, text/csv
       * @param {User} options.createdBy the creator of the insights item detail
       * @param {Date} options.createdTime the created time of the insights item detail
       * @param {Number} options.downloadsCount the download count of the insights item detail
       * @param {String} options.id the id of the insights item detail
       * @param {Number} options.modifiedCount the modified count of the insights item detail
       * @param {String} options.name the name of the insights item detail
       * @param {Date} options.lastModifiedTime the last modified date of the insights item detail
       * @param {String} options.path the path of the insights item detail
       * @param {Number} options.sharesCount the share count of the insights item detail
       * @param {REPORT_STATUS} options.status the status of the insights item detail
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      /**
       * @description Method to download report of insights item detail.
       *
       * @param {Object} options - options object
       * @param {String} options.apiKey - the api key for header
       * @param {Object} options.downloadAction - available download actions, including mechanism, fetch and follow
       *
       * @returns {Promise} promise - resolved when the file is downloaded
       */
      download(options) {
        // Using $http because ACP does not support encoded url
        // Only download the report when the domain of the link path is adobe.io
        if (this.linkPath && _.endsWith(getHostName(this.linkPath), 'adobe.io')) {
          return options.downloadAction.mechanism ===
            INSIGHTS_ITEM_DETAIL_DOWNLOAD_MECHANISM.REDIRECT
            ? this.redirectDownload(options)
            : this.simpleDownload(options); // default to simple download
        }
        // Coral will display reverse order if we call show toast at the same time so add this timeout to avoid it.
        return $timeout(() => $q.reject(), 500);
      }

      /**
       * @description Method to determine if the content type of this Insights Item Detail is a folder
       *
       * @returns {boolean} true if content type is "folder"
       */
      isFolder() {
        return this.contentType === INSIGHTS_ITEM_DETAIL_CONTENT_TYPE.FOLDER;
      }

      /**
       * @description Method to return the current unique key for this insights item detail.
       *
       * @returns {String} key to uniquely identify this object
       */
      key() {
        return this.id;
      }

      /**
       * @description Method to download report of insights item detail when download mechanism is redirect.
       *
       * @param {Object} options - options object
       * @param {String} options.apiKey - the api key for header
       * @param {Object} options.downloadAction - available download actions, including mechanism, fetch and follow
       *
       * @returns {Promise} promise - resolved when the file is downloaded
       */
      redirectDownload(options) {
        return $http(
          getDownloadParams(options.apiKey, options.downloadAction.fetch, undefined, this.linkPath)
        )
          .then((response) =>
            this.simpleDownload({
              apiKey: options.apiKey,
              downloadAction: {fetch: options.downloadAction.follow},
              url: _.get(JSON.parse(response.data), 'location'),
            })
          )
          .catch(() => $q.reject());
      }

      /**
       * @description Method to download report of insights item detail when download mechanism is simple or undefined.
       *
       * @param {Object} options - options object
       * @param {String} options.apiKey - the api key for header
       * @param {Object} options.downloadAction - available download actions, including mechanism, fetch and follow
       * @param {String} url - the download url
       *
       * @returns {Promise} promise - resolved when the file is downloaded
       */
      simpleDownload(options) {
        return $http(
          getDownloadParams(
            options.apiKey,
            options.downloadAction.fetch,
            apiUtils.csvBlobTransformer,
            options.url || this.linkPath
          )
        )
          .then((response) => {
            downloadUtils.downloadExportData(
              _.get(response, 'data.file'),
              this.repoName || this.name
            );
          })
          .catch(() => $q.reject());
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        return {
          id: this.id,
        };
      }

      /**
       * @class
       * @description Transforms the /organizations InsightsItem response Object into a new
       *   InsightsItem instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new InsightsItemDetail(dataTransferObject);
      }

      /**
       * @description Method to create an insights item detail report.
       *
       * @param {Object} options - object containing information to create an insights item detail report
       * @param {String} options.endDate - the end date of the insights item detail report being added. Default is undefined.
       * @param {String} options.orgId - the org id.
       * @param {String} options.startDate - the start date of the insights item detail report being added. Default is undefined.
       * @param {String} options.type - the type of the insights item detail report being added.
       *
       * @returns {Promise} resolves if successfully create insights item detail report, else rejects with error message
       */
      static create(options) {
        this.$resolved = false;
        const operations = {
          contractId: options.contractId,
          contractType: options.contractType,
          dateRange: {
            endDate: options.endDate,
            startDate: options.startDate,
          },
        };

        this.$promise = reportBroker.insightsItemDetail
          .create(
            {
              orgId: options.orgId,
              type: options.type,
            },
            operations
          )
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(INSIGHTS_ITEM_DETAIL_EVENT.CREATE, this);
          return this;
        }

        function onError(error) {
          $log.error('Failed to create insights item detail report. Error: ', error);
          return $q.reject(error);
        }

        return this.$promise;
      }
    }

    /** Private Methods **/

    /**
     * @description Method to get download paramter
     *
     * @param {String} apiKey - the api key for header
     * @param {Object} downloadActionOptions - download action options, fetch or follow
     * @param {Object} transformResponse - the transformer response to download
     * @param {String} url - the download url
     *
     * @returns {Object} parameter used by downloading insights item detail
     */
    function getDownloadParams(apiKey, downloadActionOptions, transformResponse, url) {
      const params = {headers: {}, method: 'GET', transformResponse, url};
      if (_.get(downloadActionOptions, 'authorization.type') !== 'exclude') {
        // set the default Authorization value
        params.headers.Authorization = `Bearer ${authentication.getAccessToken()}`;
      }
      if (_.get(downloadActionOptions, 'x-api-key.type') !== 'exclude') {
        // set the default api key
        params.headers['X-Api-Key'] = apiKey;
      }
      return params;
    }

    /**
     * @description Method to get host name from url
     *
     * @param {String} url url to be processed
     *
     * @returns {String} host name from the url
     */
    function getHostName(url) {
      const match = url.match(/:\/\/(www\d?\.)?(.[^/:]+)/i);
      return _.get(match, 'length') > 2 ? match[2] : null;
    }

    /**
     * @description Initializes Insights Item data.
     *
     * @param {Object} model InsightsItem model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick([
            'assets',
            'contentType',
            'createdBy',
            'createdTime',
            'downloadsCount',
            'id',
            'modifiedCount',
            'name',
            'lastModifiedTime',
            'path',
            'sharesCount',
            'status',
          ])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      // assign data to model for view mode
      _.forEach(options.data, (value, key) => {
        model[key] = value;
      });

      // assign metadata to model for view mode
      _.forEach(options.metadata, (value, key) => {
        model[key] = value;
      });

      model.createdBy = new User(options.createdBy);

      // should only have one asset if content type is not folder
      if (
        options.contentType !== INSIGHTS_ITEM_DETAIL_CONTENT_TYPE.FOLDER &&
        _.get(options.assets, 'length') === 1
      ) {
        model.linkPath = options.assets[0].linkPath;
        model.repoName = options.assets[0]['repo:name'];
        model.size = _.toNumber(options.assets[0]['repo:size']);
      }

      // setup model as already resolved which ensures it can be used
      // interchangably when fetched from the insights-item-detail list or instantiated
      // independently
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    return InsightsItemDetail;
  }
})();
