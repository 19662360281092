import {log} from '@admin-tribe/binky';
import {Divider, Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';

import {useModalDialogContext} from '../ModalDialogContext';

/**
 * Component to render description / sub-header elements under the header of a modal. Does not scroll with content
 */

const ModalDescription = ({children, showDivider = true}) => {
  const [classPrefix, setClassPrefix] = useState('');
  const {id, setModalDescribedBy, styleProps} = useModalDialogContext();
  const myRef = useRef(null);
  const descriptionId = `${id}-description`;

  React.useEffect(() => {
    setModalDescribedBy?.(descriptionId);
  }, [descriptionId, setModalDescribedBy]);

  // Get dynamic class prefix from parent modal
  React.useEffect(() => {
    const parentNode = myRef.current.parentNode.parentNode;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- deprecated code, will leave untested
    // istanbul ignore next
    try {
      const className = parentNode.getAttribute('class');
      setClassPrefix(className.replace(/_spectrum-Dialog-grid/g, '_') ?? '');
    } catch (error) {
      log.error('ModalDescription unable to get class name from parent', error);
    }
  }, []);

  return (
    <View
      data-testid="description"
      elementType="section"
      gridArea={`${classPrefix}divider`}
      marginBottom="size-125"
      marginTop="size-125"
      marginX={styleProps.spaceX}
    >
      <span ref={myRef} />
      <Flex direction="column">
        <View id={descriptionId}>{children}</View>
        {showDivider && <Divider marginTop="size-250" size="M" />}
      </Flex>
    </View>
  );
};

ModalDescription.propTypes = {
  children: PropTypes.node,
  showDivider: PropTypes.bool,
};

export default ModalDescription;
