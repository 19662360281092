(function () {
  'use strict';
  /**
   * @deprecated use src2/shell/panels/drawer/header/Header.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.drawer-header.drawer-header-description:binkyDrawerHeaderDescription
   *
   * @description A header for a binky-drawer which includes a title, an optional description, and optional
   *   action buttons lined up below the title and description.
   *
   * @param {String} [headerDescription] The description for the drawer. The string may contain html tags. Optional.
   * @param {String} headerTitle The title for the drawer. Required.
   */
  angular
    .module('binky.widgets.common.drawer-header.drawer-header-description')
    .component('binkyDrawerHeaderDescription', {
      bindings: {
        headerDescription: '@?',
        headerTitle: '@',
      },
      templateUrl:
        'widgets/common/drawer-header/drawer-header-description/drawer-header-description.component.html',
      transclude: {
        'header-action-buttons': '?headerActionButtons',
      },
    });
})();
