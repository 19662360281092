import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import jilOrganizations from 'api/jil/jilOrganizations';
import eventBus from 'services/events/eventBus';

import {ORGANIZATION_EVENT, ORGANIZATION_TYPE} from './OrganizationConstants';

class Organization {
  /**
   * Method for getting an org object and loading org data
   *
   * @param {Object} options - options to pass to the org constructor
   * @param {String} options.id - id of the org
   * @param {Boolean} [options.includeAncestors] - true if the ancestors should be fetched
   * @param {boolean} [options.includeHasChildren] - (optional) true if the presence of child admin consoles (GAC) should be fetched
   * @param {boolean} [options.includeTermsAcceptances] - (optional) true if org-level terms acceptances should be fetched
   * @param {Array<String>} [options.fetchTemplateNames] - an array of template names for which to fetch term acceptances if includeTermsAcceptances is true
   * @returns {Organization} org - org object
   */
  static get(options) {
    const model = new Organization(options);
    return model.refresh();
  }

  /**
   * Constructor
   *
   * @param {Object} options - options to pass to the org constructor
   * @param {Array<String>} [options.ancestors] - the names of this orgs ancestors
   * @param {String} [options.countryCode] - country code of the org
   * @param {DELEGATION_GROUP_MIGRATION_STATUS} [options.delegationGroupMigrationStatus] - delegation group migration status
   * @param {String} [options.endUserId] - DEPRECATED: the end user id
   * @param {Boolean} [options.hasChildren] - true if parent organization with children (GAC)
   * @param {String} [options.id] - id of the org
   * @param {Boolean} [options.includeAncestors] - true if the ancestors should be fetched
   * @param {boolean} [options.includeHasChildren] - (optional) true if the presence of child admin consoles (GAC) should be fetched
   * @param {boolean} [options.includeTermsAcceptances] - (optional) true if org-level terms acceptances should be fetched
   * @param {Array<String>} [options.fetchTemplateNames] - an array of template names for which to fetch term acceptances if includeTermsAcceptances is true
   * @param {ORGANIZATION_MARKET_SEGMENT} [options.marketSegment] - the market segment of the org
   * @param {Array<ORGANIZATION_MARKET_SUBSEGMENT>} [options.marketSubsegments] - the mark subsegments of the org
   * @param {String} [options.migrationStatus] - DEPRECATED: the migration status of the org (whether it's been migrated to Admin Console)
   * @param {String} [options.name] - name of the org
   * @param {String} [options.originatingEntityId] - the originating entity of the org
   * @param {String} [options.parentId] - the org's parent IMS org id. This is the parent this org is nested within
   * @param {String} [options.parentOrgId] - DEPRECATED: the org's freeform text parent org id to update. This will be removed once Banyan is widely adopted
   * @param {String} [options.sfdcId] - the org's sfdc id
   * @param {String} [options.soldToId] - the org's sold to id
   * @param {String} [options.soldToName] - the org's sold to name
   * @param {Array<String>} [options.tags] - the list of tags associated with the org
   * @param {ORGANIZATION_TYPE} [options.type] - type of the org
   */
  constructor(options) {
    initModel(this, options);
    registerSavedState(this);
    this.includeAncestors = options.includeAncestors;
    this.includeHasChildren = options.includeHasChildren;
    this.includeTermsAcceptances = options.includeTermsAcceptances;
    this.fetchTemplateNames = options.fetchTemplateNames;
  }

  /**
   * Method to verify if all required fields are filled
   *
   * @returns {Boolean} true if all required fields are filled
   */
  areRequiredFieldsFilled() {
    return !!(this.name && this.marketSegment && this.countryCode && this.type);
  }

  /**
   * Method to create a new org
   *
   * @returns {Promise} promise - resolved with the instance
   */
  async create() {
    const response = await jilOrganizations.postOrganizations(toMinimumObject(this));
    initModel(this, response.data);
    registerSavedState(this);
    eventBus.emit(ORGANIZATION_EVENT.CREATE, this.id);
    return this;
  }

  /**
   * Method to delete an org
   *
   * @returns {Promise} promise - resolved with the instance
   */
  async delete() {
    await jilOrganizations.deleteOrganizations({orgId: this.id});
    eventBus.emit(ORGANIZATION_EVENT.DELETE, this.id);
    return this;
  }

  /**
   * Returns the marketSubsegments, if any.
   *
   * @returns {Array<ORGANIZATION_MARKET_SEGMENT>} the market segments
   */
  getMarketSubsegments() {
    return this.marketSubsegments;
  }

  /**
   * Method to verify whether changes are made
   *
   * @returns {Boolean} Boolean - true if changes are made
   */
  hasUnsavedChanges() {
    const currentOrg = filterEmptyFields(this);
    const currentOrgCopy = filterEmptyFields(this.orgCopy);
    return !isEqual(currentOrg, currentOrgCopy);

    function filterEmptyFields(model) {
      const newModel = omitBy(toMinimumObject(model), (value) => !value);
      return newModel;
    }
  }

  /**
   * Method to determine if this organization is a developer organization.
   *
   * A developer organization allows the admin the ability to manage
   * users in Admin Console but restricts access to change anything that only
   * should be available only to Enterprise Admins.
   *
   * @returns {Boolean} true if this organization is a developer organization.
   */
  isDeveloperOrg() {
    return this.type === ORGANIZATION_TYPE.DEVELOPER;
  }

  /**
   * Method to determine if this Organization is a new one or not
   *
   * @returns {Boolean} true if a new Organization, else false
   */
  isNew() {
    return !this.id;
  }

  /**
   * Method to fetch the org data
   *
   * @returns {Promise} promise - resolved with the instance
   */
  async refresh() {
    const response = await jilOrganizations.getOrganizations({
      includeAncestors: this.includeAncestors,
      includeHasChildren: this.includeHasChildren,
      includeTermsAcceptances: this.includeTermsAcceptances,
      orgId: this.id,
      templateNames: this.fetchTemplateNames,
    });
    initModel(this, response.data);
    registerSavedState(this);
    eventBus.emit(ORGANIZATION_EVENT.REFRESH, this.id);
    return this;
  }

  /**
   * Method to revert all changes
   */
  revertChanges() {
    if (this.hasUnsavedChanges()) {
      const properties = this.toMinimumModel();
      Object.keys(properties).forEach((key) => {
        delete this[key];
      });
      initModel(this, this.orgCopy);
    }
  }

  /**
   * @description generate model containing only relevant API fields
   * @returns {Object} the minimum model
   */
  toMinimumModel() {
    return toMinimumObject(this);
  }

  /**
   * Method to update the org data
   *
   * @param {Object} options fields to update on the org, see constructor for details
   * @returns {Promise} promise - resolved with the instance
   */
  async update(options) {
    const org = {id: this.id, name: this.name};
    Object.assign(org, options);
    const response = await jilOrganizations.putOrganizations(org);
    initModel(this, response.data);
    registerSavedState(this);
    eventBus.emit(ORGANIZATION_EVENT.UPDATE, this.id);
    return this;
  }
}

function toMinimumObject(org) {
  const orgProperties = [
    'ancestors',
    'countryCode',
    'delegationGroupMigrationStatus',
    'endUserId',
    'hasChildren',
    'id',
    'info',
    'marketSegment',
    'marketSubsegments',
    'migrationStatus',
    'name',
    'originatingEntityId',
    'parentId',
    'parentOrgId',
    'policies',
    'sfdcId',
    'soldToId',
    'soldToName',
    'tags',
    'termsAcceptances',
    'type',
  ];

  return pick(org, orgProperties);
}

function initModel(model, options) {
  Object.assign(model, toMinimumObject(options));
  return model;
}
function registerSavedState(model) {
  if (model.hasUnsavedChanges()) {
    Object.assign(model, {
      orgCopy: toMinimumObject(model),
    });
  }
}

export default Organization;
