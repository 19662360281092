/**
 * @description Method to format a number as a percentage string.
 *   ref: https://wiki.corp.adobe.com/display/DMaG11n/Number+formats
 *
 * @param {Object} intl - the React intl object for internationalization
 * @param {Number} percentage - the numeric percentage to display
 * @returns {String} the formatted percentage, for example, "20.5%"
 */
function formatPercentage(intl, percentage) {
  return intl.formatMessage({id: 'binky.common.storage.percentage'}, {percentage});
}

/**
 * @description Method to format a FileSize object for display.
 *   ref: https://wiki.corp.adobe.com/display/DMaG11n/Number+formats
 *
 * @param {Object} intl - the React intl object for internationalization
 * @param {FileSize} fileSize - a FileSize object
 * @param {String} fileSize.visualCount - the size as a string
 * @param {FILE_SIZE_UNIT} fileSize.units - the units as a string
 * @returns {String} file size - the formatted fileSize, for example '1.5GB' or maybe '1,5Go' (French) dependent on locale formttingå.
 */
function formatFileSize(intl, fileSize) {
  const {units, visualCount} = fileSize;

  return intl.formatMessage({id: `binky.common.storage.fileSize.${units}`}, {size: visualCount});
}

/**
 * @description Method to format the file usage summary (returned by StorageStats).
 *
 * @param {Object} intl - the React intl object for internationalization
 * @param {Object} usageSummary - an object with an amount FileSize and a total FileSize
 * @returns {String} the formatted usage, for example '1 GB of 5 GB'
 */
function formatUsageSummary(intl, usageSummary) {
  return intl.formatMessage(
    {id: 'binky.common.storage.usageSummary'},
    {
      amountFileSize: formatFileSize(intl, usageSummary.amount),
      totalFileSize: formatFileSize(intl, usageSummary.total),
    }
  );
}

export {formatFileSize, formatPercentage, formatUsageSummary};
