/**
 * @deprecated use the React Spectrum DatePicker, or src2/widgets/date-range/DateRange.jsx
 *
 * @ngdoc component
 * @name binky.widgets.common:binkyDatepicker
 *
 * @description Wrapper for Coral Datepicker
 *
 * @param {Boolean} [disabled] boolean for disabling the datepicker
 * @param {String} [displayFormat] the format used to display the selected date(time) to the user
 * @param {String} [labelledBy] reference to a space delimited set of ids for the HTML elements that provide a label for the formField.
 * @param {String|Date} [max] the maximum date that the Datepicker will accept as valid
 * @param {String|Date} [min] the minimum date that the Datepicker will accept as valid
 * @param {String} [name] name used to submit the data in a form
 * @param {Function} [onChange] callback when datepicker's value changes
 * @param {String} [placeholder] short hint that describes the expected value of the Datepicker
 * @param {Boolean} [readOnly] boolean for readOnly field of the datepicker
 * @param {Boolean} [required] boolean for required field of the datepicker
 * @param {String} [type] the type of datepicker to show to the user, 'date' | 'datetime' | 'time'
 * @param {String} [value] the current value.
 * @param {String} [valueFormat] the format to use on expressing the selected date as a string on the value attribute
 * @param {String} [variant] the datepicker's variant, 'default' | 'quiet'
 */
(function () {
  'use strict';
  angular.module('binky.widgets.common.datepicker').component('binkyDatepicker', {
    bindings: {
      disabled: '<?',
      disableManualInput: '<?',
      displayFormat: '<?',
      labelledBy: '@?',
      max: '<?',
      min: '<?',
      name: '@?',
      onChange: '&?',
      placeholder: '@?',
      readOnly: '<?',
      required: '<?',
      type: '@?',
      value: '<?',
      valueFormat: '<?',
      variant: '@?',
    },
    controller,
  });

  function controller(_, $element, $timeout, feature, moment) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onInit() {
      vm.datepicker = new Coral.Datepicker();
      updateBinding();
      vm.datepicker.on('change', () => {
        // Timeout in order to trigger an Angular digest cycle.
        $timeout(() => {
          if (vm.onChange) {
            vm.onChange({
              isInvalid: vm.datepicker.invalid,
              value: vm.datepicker.value,
              valueAsDate: vm.datepicker.valueAsDate,
            });
          }
        });
      });
      $element.append(vm.datepicker);
    }

    function $onChanges() {
      updateBinding();
    }

    //////////////

    function getDefaultDisplayFormat() {
      if (vm.type === 'datetime') {
        // Month name, day of month, year, time - for example 'July 27, 2018 8:30 PM'
        return 'LLL';
      }
      if (vm.type === 'time') {
        // for example '8:30 PM'
        return 'LT';
      }

      // default to display for type 'date'
      // Month name, day of month, year - for example 'July 27, 2018'
      return 'LL';
    }

    function updateBinding() {
      // if a custom value format is being used, it must be set first.
      // otherwise, min/max values set using this format will not be interpreted
      // correctly at runtime (min/max set before valueFormat)
      _.assign(vm.datepicker, {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
        valueFormat: vm.valueFormat || 'YYYY-MM-DDTHH:mm:ss.sssZ',
      });
      _.assign(vm.datepicker, {
        disabled: vm.disabled,
        displayFormat: vm.displayFormat || getDefaultDisplayFormat(),
        labelledBy: vm.labelledBy,
        max: vm.max,
        min: vm.min,
        name: vm.name,
        placeholder: vm.placeholder,
        readOnly: vm.readOnly,
        required: vm.required,
        startDay: moment.localeData().firstDayOfWeek(),
        type: vm.type || 'date',
        value: vm.value,
        variant: vm.variant || 'default',
      });
      if (vm.datepicker) {
        // eslint-disable-next-line no-underscore-dangle
        vm.datepicker._elements.input.readOnly = vm.disableManualInput;

        // Coral.Datepicker.validateCalendar() is only performed on user interaction.
        // Thus, even though the min or max value changes, Coral.Datepicker.validateCalendar()
        // is not triggered. For that reason, we programmatically validate here.
        validateCalendar();
      }
    }

    function validateCalendar() {
      // Need to convert current dates to Date object
      const date = vm.value ? new Date(vm.value) : undefined;
      const startDate = vm.min ? new Date(vm.min) : undefined;
      const endDate = vm.max ? new Date(vm.max) : undefined;
      _.assign(vm.datepicker, {
        invalid: !(_.isUndefined(vm.value) || isDateInRange(date, startDate, endDate)),
      });
    }

    function isDateInRange(date, startDate, endDate) {
      if (_.isUndefined(startDate) && _.isUndefined(endDate)) {
        return true;
      } else if (_.isUndefined(startDate)) {
        return date <= endDate;
      } else if (_.isUndefined(endDate)) {
        return date >= startDate;
      }

      return startDate <= date && date <= endDate;
    }
  }
})();
