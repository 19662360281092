/* eslint-disable react/no-unknown-property -- styleName not recognized */
import {Flex, Text} from '@adobe/react-spectrum';
import HelpOutlineIcon from '@spectrum-icons/workflow/HelpOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import TooltipButton from '../tooltip-button/TooltipButton';
import TruncatedText from '../truncated-text/TruncatedText';

import styles from './Chiclet.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-chiclet
 */

/**
 * Chiclet display the information with the count of product. Tooltip text may be displayed within the widget.
 */
const Chiclet = ({
  count,
  IconComponent,
  iconVariant = 'default',
  isTruncated = false,
  name,
  tooltipText,
  variant = 'small',
}) => {
  const intl = useIntl();
  return (
    <Flex alignItems={isTruncated ? 'center' : 'start'} direction="row">
      {IconComponent && (
        <span
          data-testid="chiclet-icon-component"
          styleName={`icon ${iconVariant === 'default' ? '' : `icon-${iconVariant}`}`}
        >
          {IconComponent}
        </span>
      )}
      {/* Min width is used to ensure that the tooltip button does not overflow the parent element */}
      {isTruncated ? (
        <Flex alignItems="center" direction="row" minWidth="0px">
          <TruncatedText
            data-testid="chiclet-name"
            textClassName={`${styles.name} ${styles[`name-${variant}`]}`}
          >
            {name}
          </TruncatedText>
          {Number.isInteger(count) && (
            <span data-testid="chiclet-count" styleName="count">
              &nbsp;({intl.formatNumber(count)})
            </span>
          )}
        </Flex>
      ) : (
        <Text data-testid="chiclet-name">
          <span styleName={`name name-${variant}`}>{name}</span>
          {Number.isInteger(count) && (
            <span data-testid="chiclet-count" styleName="count">
              &nbsp;({intl.formatNumber(count)})
            </span>
          )}
        </Text>
      )}
      {tooltipText && (
        <TooltipButton
          data-testid="chiclet-tooltip"
          hoverText={tooltipText}
          isQuiet
          variant="action"
        >
          <HelpOutlineIcon size="S" />
        </TooltipButton>
      )}
    </Flex>
  );
};

Chiclet.propTypes = {
  /**
   * Displays a number of the named item
   */
  count: PropTypes.number,
  /**
   * The icon to be displayed.
   */
  IconComponent: PropTypes.node,
  /**
   * Variant type of the icon. One of: 'circle', 'default', 'square'. Defaults to 'default'.
   */
  iconVariant: PropTypes.oneOf(['circle', 'default', 'square']),
  /**
   * Truncates the 'name' prop if set to true.
   * A small width needs to be set on the parent element to trigger the ellipsis to appear.
   */
  isTruncated: PropTypes.bool,
  /**
   * The name to be displayed in the widget.
   */
  name: PropTypes.string.isRequired,
  /**
   * The extra information to be displayed in the tooltip.
   */
  tooltipText: PropTypes.string,
  /**
   * Variant type of the chiclet. One of: 'small', 'large'. Defaults to 'small'.
   */
  variant: PropTypes.oneOf(['large', 'small']),
};

export default Chiclet;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
