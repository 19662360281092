import pick from 'lodash/pick';

import Member from 'models/member/Member';
import {ACCOUNT_STATUS} from 'models/user/UserConstants';
import Locale from 'services/locale/Locale';

import feature from '../../services/feature';

class User extends Member {
  /**
   * @description Method to construct a User from a data Object containing
   *              the IMS profile information.
   *
   * @param {Object} data - Object containing IMS profile data
   * @returns {User} reference to User Object created
   */
  static imsProfileTransformer(data) {
    /* eslint-disable camelcase -- data properties */
    const {countryCode, email, first_name, userId, last_name, account_type} = data;
    return new User({
      countryCode,
      email,
      firstName: first_name,
      id: userId,
      lastName: last_name,
      type: account_type.toUpperCase(),
    });
    /* eslint-enable camelcase -- data properties */
  }

  /**
   * @description Method to construct a User from a data Object containing
   *              user information.
   *
   * @param {Object} data - Object containing user data (e.g. - DTO)
   * @returns {User} reference to User Object created
   */
  static apiResponseTransformer(data) {
    return new User({
      id: data.userId,
      ...pick(data, [
        'accountStatus',
        'authenticatingAccount',
        'authenticatingAccountType',
        'countryCode',
        'domainEnforcementException',
        'editable',
        'email',
        'firstName',
        'id',
        'inTarget',
        'lastName',
        'licenseGroups',
        'markedForRemoval',
        'productRole',
        'products',
        'roles',
        'type',
        'userGroups',
        'userName',
      ]),
    });
  }

  /**
   * @class
   * @description Creates a new User for use. See Member class
   *    for additional params.
   *
   * @param {Object} [options] - User instance settings
   * @param {String} [options.accountStatus] User's account status. One of ACCOUNT_STATUS.
   * @param {String} [options.countryCode] - User's country code
   * @param {String} [options.email] - User's email
   * @param {Boolean} [options.externallyManaged] True if user was created from an external system
   * @param {String} [options.firstName] - User's first name
   * @param {String} [options.lastName] - User's last name
   * @param {Object} [options.authenticatingAccount] - Authenticating account info
   * @param {AUTHENTICATING_ACCOUNT_TYPE} [options.authenticatingAccountType] - The IMS account type
   * @param {Array} [options.licenseGroups] A User's license groups
   * @param {Boolean} [options.markedForRemoval] - True if the user was marked as part of a previous cancellation
   * @param {Array} [options.products] A User's products
   * @param {Array<Product>} [options.products] A User's products
   * @param {Array} [options.userGroups] A User's user groups
   * @param {String} [options.userName] - User's user name
   */
  constructor(options = {}) {
    super(options);

    this.accountStatus = options.accountStatus;
    this.countryCode = options.countryCode;
    this.email = options.email;
    this.externallyManaged = options.externallyManaged ?? false;
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.authenticatingAccountType = options.authenticatingAccountType;
    this.licenseGroups = options.licenseGroups || [];
    this.products = options.products || [];
    this.roles = options.roles || [];
    this.userGroups = options.userGroups || [];
    this.userName = options.userName;
    this.authenticatingAccount = options.authenticatingAccount;
    this.domainEnforcementException = options.domainEnforcementException;
    if (feature.isEnabled('temp_self_cancel')) {
      this.markedForRemoval = options.markedForRemoval;
    }
  }

  /** Public methods **/
  /**
   * @description Method to retrieve the full name of this user.
   *
   * @returns {String} Full name of user
   */
  getDisplayName() {
    let name;

    if (this.hasDisplayName()) {
      if (!this.firstName || this.firstName === 'UD') {
        name = this.lastName;
      } else if (!this.lastName || this.lastName === 'UD') {
        name = this.firstName;
      } else if (Locale.get().isSurnameFirstNameLocale) {
        name = `${this.lastName} ${this.firstName}`;
      } else {
        name = `${this.firstName} ${this.lastName}`;
      }
    }

    return name;
  }

  /**
   * @description Method to retrieve the display name of this user, if the user doesn't have a name, then returns their email
   *
   * @returns {String} Display name or email of the user
   */
  getDisplayNameOrEmail() {
    return this.getDisplayName() || this.email;
  }

  /**
   * @description Method to determine if this User has a first or last name
   *
   * @returns {Boolean} true if the User has a first or last name, else false
   */
  hasDisplayName() {
    return !!(
      (this.firstName && this.firstName !== 'UD') ||
      (this.lastName && this.lastName !== 'UD')
    );
  }

  /**
   * @description Method to determine if this user is disabled.
   *
   * @returns {Boolean} true if the account status is set to disabled, false otherwise
   */
  isDisabled() {
    return this.accountStatus === ACCOUNT_STATUS.DISABLED;
  }

  /**
   * @description Method to determine if this user has been created in an
   *              external system (e.g. Azure)
   *
   * @returns {Boolean} true if the user comes from an external system, false otherwise
   */
  isExternallyManaged() {
    return this.externallyManaged;
  }
}

export default User;
