(function () {
  'use strict';
  /**
   * @deprecated we do not need this form of flagging in jsx
   *
   * @ngdoc directive
   * @name binkyClassFeatureEnabled
   * @description Attribute directive that operates similarly to the ngClass
   *   directive; adding a class to an element, if the corresponding feature
   *   flag it is attached to is enabled at runtime.
   */
  angular
    .module('binky.widgets.common.feature')
    .directive('binkyClassFeatureEnabled', binkyClassFeatureEnabled);

  /* @ngInject */
  function binkyClassFeatureEnabled(_, feature) {
    const directive = {
      link,
      restrict: 'A',
    };

    return directive;

    /////////////////

    function link($scope, $element, attrs) {
      const classFlagPairsObj = $scope.$eval(attrs.binkyClassFeatureEnabled);

      _(classFlagPairsObj)
        .keys()
        .forEach((key) => {
          if (feature.isEnabled(classFlagPairsObj[key])) {
            $element.addClass(key);
          }
        });
    }
  }
})();
