import pick from 'lodash/pick';

import {DIRECTORY_OWNERSHIP_STATUS} from 'services/directory/directoryConstants';

import {DOMAIN_STATUS} from './domainConstants';

class Domain {
  static get(options) {
    return new Domain(options);
  }

  /**
   * @description instantiate a Domain object.
   * @param {Object} options - options to config the new Domain, a JSON object representing a
   *   domain that has already been fetched (typically via DomainList.get).
   * @param {String} directoryId - directory id
   * @param {String} directoryName - directory name
   * @param {String} directoryType - directory type
   * @param {String} domainName - domain name
   * @param {String} orgId - organization id
   * @param {Boolean} externallyManaged - flag for if domain is externally managed
   * @param {String|DIRECTORY_OWNERSHIP_STATUS} ownershipStatus - ownership status
   * @param {String|DOMAIN_CONSTANTS.STATUS} status - domain status
   */
  constructor(options = {}) {
    Object.assign(
      this,
      pick(options, [
        'directoryId',
        'directoryName',
        'directoryType',
        'domainName',
        'externallyManaged',
        'orgId',
        'ownershipStatus',
        'status',
      ])
    );

    this.ownershipStatus = this.ownershipStatus || DIRECTORY_OWNERSHIP_STATUS.OWNED;
  }

  isActive() {
    return this.status === DOMAIN_STATUS.ACTIVE;
  }

  isExternallyManaged() {
    return this.externallyManaged;
  }

  isTrusted() {
    return this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED;
  }

  isWithdrawn() {
    return this.status === DOMAIN_STATUS.WITHDRAWN;
  }

  needsApproval() {
    return this.status === DOMAIN_STATUS.CLAIMED;
  }

  needsDirectory() {
    return this.status === DOMAIN_STATUS.VALIDATED;
  }

  needsValidation() {
    return this.status === DOMAIN_STATUS.UNCLAIMED;
  }

  /**
   * @description generate model subset containing only API relevant fields.
   * @returns {Object} API relevant subset of Domain object.
   */
  toMinimumModel() {
    return pick(this, [
      'directoryName',
      'directoryId',
      'directoryType',
      'domainName',
      'externallyManaged',
      'ownershipStatus',
      'status',
    ]);
  }
}

export default Domain;
