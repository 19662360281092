(function () {
  /**
   * @deprecated Please use src2/core/services/organization/OrganizationList.js
   *
   * @ngdoc factory
   * @name binky.core.organizations:OrganizationList
   * @description model that represents the collection of a user's organizations.
   */
  angular.module('binky.core.organizations').factory('OrganizationList', getOrganizationList);
  /* @ngInject */
  function getOrganizationList($q, binkySrc2) {
    class OrganizationList2 extends binkySrc2.services.organization.OrganizationList {
      /**
       * @description Method to refresh OrganizationList from back-end.
       * @returns {Promise} resolves to OrganizationList on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => {
              resolve(this);
            })
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the OrganizationList.
       *
       * @return {OrganizationList2} OrganizationList instance
       */
      static get() {
        if (!OrganizationList2.model) {
          OrganizationList2.model = new OrganizationList2();
          OrganizationList2.model.refresh();
        }
        return OrganizationList2.model;
      }
    }

    return OrganizationList2;
  }
})();
