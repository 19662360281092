import {PROFILE_PERMISSIONS_SUB_CONTENT_TYPE} from './LicenseGroupConfigurationsConstants';

/**
 * @description Checks whether a configuration section contains a group with the specified id
 *
 * @param {Object} section - the section to check
 * @param {String} configurationsGroupId - the group id to look for
 *
 * @returns {Boolean} true if the section contains a group with the specified id
 */
function licenseGroupConfigurationSectionHasGroupId(section, configurationsGroupId) {
  return !!section?.content?.find((group) => group?.id === configurationsGroupId);
}

/**
 * @description Checks whether a configurations array is invalid.
 *
 * @param {Array} configurations - the array of configurations to check
 *
 * @returns {Boolean} true if invalid, false if valid
 */
function areLicenseGroupConfigurationsInvalid(configurations) {
  return (
    !configurations ||
    configurations.some((configuration) =>
      configuration.sections.some((section) => isLicenseGroupConfigurationSectionInvalid(section))
    )
  );
}

/**
 * @description Checks wether a configuration section is invalid
 *
 * @param {@Object} section - the section to check
 *
 * @returns {Boolean} true if invalid, false if valid
 */
function isLicenseGroupConfigurationSectionInvalid(section) {
  return (
    !section?.content ||
    section.content.some((content) => isLicenseGroupConfigurationGroupInvalid(content))
  );
}

/**
 * @description Checks whether a configuration group is invalid
 *
 * @param {Object} configurationGroup - the configuration group to check
 *
 * @returns {Boolean} true if invalid, false if valid
 */
function isLicenseGroupConfigurationGroupInvalid(configurationGroup) {
  return (
    !configurationGroup?.content ||
    configurationGroup.content.some((content) =>
      isLicenseGroupConfigurationSubContentInvalid(content)
    )
  );
}

/**
 * @description Checks whether a configuration group sub-content is invalid
 *
 * @param {Object} content - the configuration group sub-content to check
 *
 * @returns {Boolean} true if invalid, false is valid
 */
function isLicenseGroupConfigurationSubContentInvalid(content) {
  if (!content) return true;

  // if a non-selected radio/check, then it is valid
  if (content?.selected === false) return false;

  if (content.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.INCLUDE)
    return isIncludeTypeSubContentInvalid(content);
  if (content.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.TEXT_FORM)
    return isTextFormTypeSubContentInvalid(content);

  // otherwise, it is valid
  return false;
}

function isIncludeTypeSubContentInvalid(content) {
  const includedValueCount = content.values.filter((value) => value.included).length;
  const tooLittle = content.minimumValueCount && includedValueCount < content.minimumValueCount;
  const tooMuch = content.maximumValueCount && includedValueCount > content.maximumValueCount;
  return tooLittle || tooMuch;
}

function isTextFormTypeSubContentInvalid(content) {
  const textValueCount = content.values.length;
  if (content.minimumValueCount && textValueCount < content.minimumValueCount) return true;
  if (content.maximumValueCount && textValueCount > content.maximumValueCount) return true;
  if (content.regex) {
    const regex = new RegExp(content.regex);
    return content.values.some((value) => !regex.test(value.text));
  }
  return false;
}

export {
  licenseGroupConfigurationSectionHasGroupId,
  areLicenseGroupConfigurationsInvalid,
  isLicenseGroupConfigurationGroupInvalid,
  isLicenseGroupConfigurationSectionInvalid,
  isLicenseGroupConfigurationSubContentInvalid,
};
