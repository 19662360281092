import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ViewDetailsDrawerTrigger from 'common/components/view-details-drawer-trigger/ViewDetailsDrawerTrigger';

/**
 * The drawer trigger for any InfoItemScorecard.
 */
const InfoItemScorecardDrawerTrigger = ({children}) => {
  const intl = useIntl();
  return (
    <ViewDetailsDrawerTrigger
      tooltipText={intl.formatMessage({
        id: 'binky.common.infoItemScorecardDrawerTrigger.tooltip',
      })}
    >
      {() => children}
    </ViewDetailsDrawerTrigger>
  );
};

InfoItemScorecardDrawerTrigger.propTypes = {
  /**
   * The drawer component.
   */
  children: PropTypes.element,
};

export default InfoItemScorecardDrawerTrigger;
