(function () {
  /**
   * @deprecated Please use src2/core/models/product/consumable/ConsumableList.js
   *
   * @ngdoc factory
   * @name ConsumableList
   * @description Model for an individual ConsumableList
   * As described in https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-Consumable
   */
  angular.module('binky.core.product.consumable').factory('ConsumableList', getConsumableListModel);

  /* @ngInject */
  function getConsumableListModel(_, Consumable) {
    class ConsumableList {
      /**
       * @description Creates a new ConsumableList for use.
       *
       * @param {Array} items Consumable entities which make up this list
       * @param {ConsumableSummary} consumableSummary the parent summary for this list
       */
      constructor(items, consumableSummary) {
        this.items = processItems(items, consumableSummary);
      }

      /**
       * @description Find any Consumable objects that are for the specified fulfillable item code
       *
       * @param {String} fiCode the fulfillable item code to find summaries for
       *
       * @returns {Array} List of Consumables that are for the fulfillable item code
       */
      getConsumablesForFulfillableItemCode(fiCode) {
        return _.filter(this.items, {fulfillableItemCode: fiCode});
      }
    }

    //////////////

    /**
     * @description Initializes Consumables
     *
     * @param {Array} items Consumable entities to be processed
     * @param {ConsumableSummary} parentSummary the parent Summary for this list of Consumables
     *
     * @returns {Array} the processed items
     */
    function processItems(items, parentSummary) {
      return _.map(items, (item) => Consumable.apiResponseTransformer(item, parentSummary));
    }

    return ConsumableList;
  }
})();
