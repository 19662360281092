import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Configure JIL products APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Products api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches users that have accepted (i.e. consented) to allowing a specific app integration
 * to access their Adobe data
 *
 * @param {String} clientIdParam - The client id / app integration id
 * @param {String} orgId - The organization id
 * @returns {Promise} Resolves to the axios response object whose data contains an array of users
 */
const getAcceptedUsersByClientId = ({clientId: clientIdParam, orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/application-integrations/${clientIdParam}/consents`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Fetches app integrations. If provided client id (app integration id), then fetches
 * that individual app integration
 *
 * @param {String} orgId - Org ID
 * @param {String} clientIdParam - The id of the individual app integration to fetch
 * @returns {Promise<AxiosResponse>} Resolves to Axios response
 */
const getApplicationIntegrations = ({clientId: clientIdParam = '', orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/application-integrations/${clientIdParam}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Fetches application policies
 *
 * @param {String} orgId - Org id
 * @returns {Promise<AxiosResponse>} Resolves to Axios response
 */
const getApplicationPolicies = ({orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/application-policies`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Patches a list of users' consent status for a specific app integration
 *
 * @param {String} clientIdParam - The client id / app integration id
 * @param {String} orgId - The organization id
 * @returns {Promise} Resolves to the axios response object whose data contains an array of organization users
 */
const patchAcceptedUsersByClientId = ({clientId: clientIdParam, orgId}, patchOperations) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/application-integrations/${clientIdParam}/consents`,
    patchOperations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Patches the application integrations
 *
 * @param {String} orgId - The organization id
 * @param {Array<Object>} patchOperations - The patch operations
 * @returns
 */
const patchApplicationIntegrations = ({orgId}, patchOperations) =>
  axios.patch(`${url}/v2/organizations/${orgId}/application-integrations`, patchOperations, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Patches the application policies
 *
 * @param {String} orgId - The organization id
 * @param {Array<Object>} patchOperations - The patch operations
 * @returns
 */
const patchApplicationPolicies = ({orgId}, patchOperations) =>
  axios.patch(`${url}/v2/organizations/${orgId}/application-policies`, patchOperations, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilApplicationIntegrations = {
  configure,
  getAcceptedUsersByClientId,
  getApplicationIntegrations,
  getApplicationPolicies,
  patchAcceptedUsersByClientId,
  patchApplicationIntegrations,
  patchApplicationPolicies,
};

export default jilApplicationIntegrations;
