import {getInitialDataForContractId} from '@admin-tribe/binky';
import {View} from '@adobe/react-spectrum';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import InsightsDashboardEtlaUsageReport from 'common/components/etla-usage-report/InsightsDashboardEtlaUsageReport';
import ContractPicker from 'common/components/etla-usage-report/contract-picker/ContractPicker';
import OverlayWait from 'common/components/wait/OverlayWait';

import TopErrorScreen from './TopErrorScreen';

/**
 * A component that will display the insights dashboard with a contract picker
 */
const InsightsDashboardWithPicker = ({
  contractInfoList,
  contractSpecificDisplayErrorScreen,
  contractSpecificErrorStatus,
  initialReportData,
  orgId,
  topDisplayErrorScreen,
  topErrorStatus,
}) => {
  const intl = useIntl();
  const [contractSpecificDisplayErrorScreenState, setContractSpecificDisplayErrorScreenState] =
    useState(contractSpecificDisplayErrorScreen);
  const [contractSpecificErrorStatusState, setContractSpecificErrorStatusState] = useState(
    contractSpecificErrorStatus
  );
  const [reportData, setReportData] = useState(initialReportData);
  const [reportDataIsLoading, setReportDataIsLoading] = useState(false);
  const [selectedContractIdState, setSelectedContractIdState] = useState(
    contractInfoList?.[0].contractId
  );

  const updateReportData = async (selectedContractId) => {
    setReportDataIsLoading(true);
    try {
      const reportDataForContract = await getInitialDataForContractId(selectedContractId, orgId);
      setReportData(reportDataForContract);
      setContractSpecificDisplayErrorScreenState(false);
    } catch (error) {
      setContractSpecificErrorStatusState(error.response.status);
      setContractSpecificDisplayErrorScreenState(true);
    }

    setReportDataIsLoading(false);
    setSelectedContractIdState(selectedContractId);
  };

  const onSelectionChange = (selectedContractId) => {
    updateReportData(selectedContractId);
  };

  if (topDisplayErrorScreen) {
    return <TopErrorScreen errorStatus={topErrorStatus} />;
  }

  const reportDataLoadedText = intl.formatMessage(
    {id: 'binky.widgets.reports.etlaUsageDataLoaded.ariaActive.message'},
    {
      contractId: selectedContractIdState,
    }
  );

  return (
    <View>
      <ContractPicker contractInfoList={contractInfoList} onSelectionChange={onSelectionChange} />
      <OverlayWait isLoading={reportDataIsLoading}>
        <VisuallyHidden aria-live="polite">{reportDataLoadedText}</VisuallyHidden>
        <InsightsDashboardEtlaUsageReport
          key={selectedContractIdState}
          displayErrorScreen={contractSpecificDisplayErrorScreenState}
          errorStatus={contractSpecificErrorStatusState}
          orgId={orgId}
          reportData={reportData}
        />
      </OverlayWait>
    </View>
  );
};
InsightsDashboardWithPicker.propTypes = {
  /**
   * Array of objects each representing contract info
   * Each such object has the following fields:
   * - contractId(String): id of contract
   * - type(String): type of contract
   */
  contractInfoList: PropTypes.arrayOf(
    PropTypes.shape({
      contractId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  /**
   *  A flag to check whether to display contract specific
   *  error screen or not.
   */
  contractSpecificDisplayErrorScreen: PropTypes.bool,
  /**
   * An object defining error status, in case of displaying
   * contract specific error screen.
   */
  contractSpecificErrorStatus: PropTypes.number,
  /**
   * An object defining the reportData which wraps timePeriods,
   * currentYear_dataPoints, productList, contractId and orgId.
   */
  initialReportData: PropTypes.shape({
    contractId: PropTypes.string,
    dataPoints: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
      dataPoints: PropTypes.arrayOf(PropTypes.object),
      endDate: PropTypes.number,
      startDate: PropTypes.number,
    }),
    dataPointsStatus: PropTypes.string,
    endDate: PropTypes.number,
    graceEndDate: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    productsTableData: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
    timePeriods: PropTypes.arrayOf(PropTypes.object),
  }),
  /**
   * The organisation id for which etla usage report dashboard
   * data is to be displayed.
   */
  orgId: PropTypes.string.isRequired,
  /**
   *  A flag to check whether to display top level
   *  error screen or not.
   */
  topDisplayErrorScreen: PropTypes.bool,
  /**
   * An object defining error status, in case of displaying
   * top level error screen.
   */
  topErrorStatus: PropTypes.number,
};

export default InsightsDashboardWithPicker;
