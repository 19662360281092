const PRODUCT_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.product.PRODUCT_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/models/product/ProductConstants.js
   */
  angular
    .module('binky.core.product')
    .constant('PRODUCT_APPLICABLE_OFFER_TYPE', PRODUCT_CONSTANTS.PRODUCT_APPLICABLE_OFFER_TYPE)
    .constant('LICENSE_STATUS', PRODUCT_CONSTANTS.LICENSE_STATUS)
    .constant('LICENSE_TYPE', PRODUCT_CONSTANTS.LICENSE_TYPE)
    .constant('PRODUCT_BUYING_PROGRAM', PRODUCT_CONSTANTS.PRODUCT_BUYING_PROGRAM)
    .constant('PRODUCT_CACHE_ID', PRODUCT_CONSTANTS.PRODUCT_CACHE_ID)
    .constant('PRODUCT_CHANNELS', PRODUCT_CONSTANTS.PRODUCT_CHANNELS)
    .constant(
      'PRODUCT_CONFIGURATION_SETTING_TYPE',
      PRODUCT_CONSTANTS.PRODUCT_CONFIGURATION_SETTING_TYPE
    )
    .constant('PRODUCT_DELEGATION_TARGET', PRODUCT_CONSTANTS.PRODUCT_DELEGATION_TARGET)
    .constant('PRODUCT_CODE', PRODUCT_CONSTANTS.PRODUCT_CODE)
    .constant('PRODUCT_FAMILY', PRODUCT_CONSTANTS.PRODUCT_FAMILY)
    .constant(
      'PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE',
      PRODUCT_CONSTANTS.PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE
    )
    // These products have QUOTA for ORGANIZATION but should show as seat-based.
    .constant('PRODUCT_LICENSE_ALLOWLIST', PRODUCT_CONSTANTS.PRODUCT_LICENSE_ALLOWLIST)
    .constant('PRODUCT_METADATA_CACHE_ID', PRODUCT_CONSTANTS.PRODUCT_METADATA_CACHE_ID)
    .constant('PRODUCT_ROLE', PRODUCT_CONSTANTS.PRODUCT_ROLE);
})();
