(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilSearch.js
   *
   * @ngdoc service/provider
   * @name jilSearch
   * @description defines service to manage jil search resources
   */
  angular.module('binky.core.api.jil').provider('jilSearch', jilSearchProvider);

  let url;

  /* @ngInject */
  function jilSearchProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        availableTypes: getAvailableTypesResource(),
        search: getSearchResource(),
      };

      return service;

      ///////////

      function getSearchResource() {
        return $resource(`${url}/:orgId/search/members`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getAvailableTypesResource() {
        return $resource(
          `${url}/:orgId/search/available-types`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
