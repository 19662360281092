(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.go-url
   * @description module that defines go-url widgets
   */
  angular.module('binky.widgets.common.go-url', [
    'binky.core.common.analytics',
    'binky.core.locale',
  ]);
})();
