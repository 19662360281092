const ORGANIZATION_USER_API_QUERY_PARAMS = {
  DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR: 'DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR',
  DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR: 'DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR',
  FETCH_DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR: 'FETCH_DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR',
  PRODUCTS: 'PRODUCTS',
  USER_CREATION_SOURCE: 'USER_CREATION_SOURCE',
  USER_EDU_ROLE_TAGS: 'USER_EDU_ROLE_TAGS',
  USER_GROUP_PRODUCTS: 'USER_GROUP_PRODUCTS',
};

const CREATION_SOURCE = {
  ADMIN_CONSOLE_CSV: 'ADMIN_CONSOLE_CSV',
  ADMIN_CONSOLE_UI: 'ADMIN_CONSOLE_UI',
  EDU_ROSTER_SYNC: 'EDU_ROSTER_SYNC',
  JIT: 'JIT',
  PROGRESSIVE_ACCOUNT: 'PROGRESSIVE_ACCOUNT',
  SCIM: 'SCIM',
  SUSI: 'SUSI',
  UMAPI: 'UMAPI',
  UST: 'UST',
};

export {CREATION_SOURCE, ORGANIZATION_USER_API_QUERY_PARAMS};
