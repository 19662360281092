(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/language-picker/LanguagePicker.jsx
   */
  angular.module('binky.widgets.common.language-switcher').component('binkyLanguageSwitcher', {
    controller,
    templateUrl: 'widgets/common/language-switcher/language-switcher.component.html',
  });

  /* @ngInject */
  function controller(_, $scope, $window, locale, SELECTED_LOCALE_KEY, storageUtils) {
    this.$onInit = $onInit;

    function $onInit() {
      const vm = this;
      const activeLanguage = locale.getActiveLanguage();

      // To populate the language selector.
      vm.languages = _.filter(
        locale.getSupportedLanguages(),
        (lang) => lang.value === activeLanguage || !lang.hidden
      );
      vm.selectedLanguage = activeLanguage;

      // The change handler for the language selector.
      vm.setActiveLanguage = function (language) {
        if (activeLanguage && activeLanguage !== language) {
          storageUtils.setLocalStorageItem(SELECTED_LOCALE_KEY, language);
          // If a query param is set, delete it.
          if (locale.getQueryParam()) {
            locale.deleteQueryParam();
            $scope.$on('$locationChangeSuccess', () => {
              $window.location.reload(true); // force reload from server
            });
          } else {
            // We have to reload the whole page when we change locale.
            // Without forcedReload param, the browser may choose to reload from cache.
            $window.location.reload();
          }
        }
      };
    }
  }
})();
