// eslint-disable-next-line angular/window-service
const ORGANIZATION_CONSTANTS = window.binkySrc2.services.organization.ORGANIZATION_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/services/organization/OrganizationConstants.js
   */
  angular
    .module('binky.core.organizations.search-list')
    .constant('ORGANIZATION_SEARCH_TYPE', ORGANIZATION_CONSTANTS.ORGANIZATION_SEARCH_TYPE);
})();
