(function () {
  /**
   * @deprecated Please use src2/core/models/user/linked-accounts/LinkedUserAccountList.js
   *
   * @ngdoc factory
   * @name binky.core.user.linked-accounts:LinkedUserAccountList
   * @description fetch the list of linked user accounts
   */
  angular
    .module('binky.core.user.linked-accounts')
    .factory('LinkedUserAccountList', linkedUserAccountList);

  /* @ngInject */
  function linkedUserAccountList(
    $q,
    _,
    binkySrc2,
    LINKED_USER_ACCOUNT_LIST_CACHE_ID,
    modelCache,
    promiseUtils
  ) {
    modelCache.register(LINKED_USER_ACCOUNT_LIST_CACHE_ID, 1);
    class LinkedUserAccountList extends binkySrc2.services.users.LinkedUserAccountList {
      /**
       * @description Method to fetch the account list
       * @returns {Promise} promise - promise object, resolved when the country list is loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then(() => resolve(this))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to fetch the LinkedUserAccountList. It will be fetched from
       *              the api unless it is already cached.
       * @param {Object} options Initialization Object (params described below)
       * @param {String} [options.filter] - the filter to apply to the accounts, from
       *     LINKED_USER_ACCOUNT_LIST_FILTER. Defaults to ALL.
       * @param {String} [options.userId] - the userId to fetch linked accounts for.
       * @returns {LinkedUserAccountList} the fetched list.
       */
      static get(options) {
        const model = new LinkedUserAccountList(options);

        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((list) => {
            _.assign(model, list);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    return LinkedUserAccountList;
  }
})();
