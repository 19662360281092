const ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY = {
  DEFAULT: 'DEFAULT',
  INSTANCE_ID: 'INSTANCE_ID',
  RENEWAL: 'RENEWAL',
};
const ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS = {
  COMPLETE: 'COMPLETE',
  NONE: 'NONE',
  NOT_RENEWAL: 'NOT_RENEWAL',
  PARTIAL: 'PARTIAL',
};

export {ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY, ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS};
