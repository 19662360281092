(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.shell.workspace').component('binkyWorkspace', {
    bindings: {
      waitOnWorkspace: '<?',
      workspaceNavItemListId: '@?',
    },
    controller,
    templateUrl: 'shell/workspace/workspace.component.html',
  });

  /* @ngInject */
  function controller(
    $document,
    $q,
    $rootScope,
    _,
    feature,
    navManager,
    PANEL_MANAGER,
    panelManager,
    waitOnError
  ) {
    const vm = this;
    let deregisterRailCloseListener, deregisterRailOpenListener, htmlEl;

    /* Bindable members */
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /////////

    function $onInit() {
      deregisterRailCloseListener = $rootScope.$on(PANEL_MANAGER.RAIL.CLOSE, onRailClose);
      deregisterRailOpenListener = $rootScope.$on(PANEL_MANAGER.RAIL.OPEN, onRailOpen);

      vm.railOpened = panelManager.isPanelTypeOpen(PANEL_MANAGER.TYPE.RAIL);

      if (!vm.waitOnWorkspace) {
        vm.waitOnWorkspace = {
          promise: $q.resolve(),
        };
      }

      vm.waitOnWorkspace.promise
        .then(() => {
          if (vm.workspaceNavItemListId) {
            const workspaceNavItemList = navManager.find(vm.workspaceNavItemListId);
            vm.menuItems = workspaceNavItemList.items;
          } else {
            vm.menuItems = [];
          }
        })
        .catch((error) => {
          vm.isBumpered = true;
          vm.bumperStrings = waitOnError.getBumperStrings(error);
        });

      htmlEl = $document.find('html');
      htmlEl.on('pointerup', enableTextSelection);
    }

    function onRailClose() {
      vm.railOpened = false;
    }

    function onRailOpen() {
      vm.railOpened = true;
    }

    // There is an AngularJS ngAnimate + React Spectrum bug that prevents users from selecting text on the page.
    // AngularJS ngAnimate classes like ng-enter and ng-leave stop propagation of the transitionend event to React Spectrum,
    // thus failing to let React Spectrum know when to restore the disabled text selection state.
    // As a temporary fix we need to manually reset the html styling until the app is fully migrated to React or if React Spectrum
    // writes a fix to handle this edge case.
    function enableTextSelection() {
      if (htmlEl.css('user-select') === 'none') {
        htmlEl.css('user-select', '');
      }
    }

    function $onDestroy() {
      deregisterRailCloseListener();
      deregisterRailOpenListener();

      htmlEl.off('pointerup', enableTextSelection);
    }
  }
})();
