import flatMap from 'lodash/flatMap';
import omitBy from 'lodash/omitBy';

import jilFulfillmentEvents from 'api/jil/jilFulfillmentEvents';
import FulfillmentEvent from 'models/fulfillmentEvent/FulfillmentEvent';
import {FULFILLMENT_EVENT_STATUS} from 'models/fulfillmentEvent/FulfillmentEventConstants';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import {
  FULFILLMENT_EVENT_LIST_CONTEXT,
  FULFILLMENT_EVENT_LIST_EVENT,
  IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT,
  LICENSE_FULFILLMENT_EVENT_LIST_EVENT,
} from './FulfillmentEventListConstants';

class FulfillmentEventList extends JilModelList {
  /**
   * @description Method for getting all fulfillment events
   * @param {Object} [options] - options to configure the fulfillment event list. See constructor for details.
   * @returns {Promise<FulfillmentEventList>} new FulfillmentEventList instance, fetched from back-end
   */
  static get(options) {
    const model = new FulfillmentEventList(options);
    return model.refresh();
  }

  /**
   * @description Method to create a new instance of a FulfillmentEventList.
   *
   * @param {Object} options - configures the FulfillmentEventList model
   *  instance.
   * @param {Date} [options.endDate] - Upper bound to filter fulfillment
   *  events based on date.
   * @param {boolean} [options.includeTranslatedEvent] - Param to decide whether to include events from translation
   *  service in the results. This is true by default
   * @param {FULFILLMENT_EVENT_LIST_CONTEXT} [options.listContext] - String that
   *  indicates what context this list is being used. One of IN_PROGRESS,
   *  LICENSE, ORGANIZATION (default).
   * @param {string} options.orgId - Organization id that this list's
   *  fulfillment events belong to.
   * @param {string} [options.productId] - Product id that will be used to fetch
   *  fulfillment events for a license.
   * @param {Date} [options.startDate] - Lower bound to filter fulfillment
   *  events based on date.
   * @param {string} [options.status] - Status value to filter by fulfillment
   *  event status.
   */
  constructor({
    endDate,
    includeTranslatedEvent,
    listContext = FULFILLMENT_EVENT_LIST_CONTEXT.ORGANIZATION,
    orgId,
    productId,
    startDate,
    status,
  } = {}) {
    super({
      cacheClearingEvents: [],
      itemClassRef: FulfillmentEvent,
      resource:
        listContext === FULFILLMENT_EVENT_LIST_CONTEXT.LICENSE
          ? jilFulfillmentEvents.getFulfillmentEventsForLicense
          : jilFulfillmentEvents.getFulfillmentEvents,
    });

    Object.assign(this, {
      endDate,
      includeTranslatedEvent: includeTranslatedEvent ?? true,
      listContext,
      orgId,
      productId,
      startDate,
      status,
    });
  }

  /**
   * @description Gets a list of contract ids that have fulfillment events.
   *  NOTE: This should only be used by the in progress fulfillment event
   *  list used by the fulfillmentEventRefreshManager.
   * @returns {string[]} The contract ids who have fulfillment events.
   */
  getContractsWithFulfillmentEvents() {
    const items = flatMap(this.items, (item) => flatMap(item.offers, 'contractId'));
    return [...new Set(items)];
  }

  /**
   * @description Gets a list of offer ids that have fulfillment events.
   *  NOTE: This should only be used by the in progress fulfillment event
   *  list used by the fulfillmentEventRefreshManager.
   * @returns {string[]} The offer ids who have corresponding
   *  fulfillment events.
   */
  getOffersWithFulfillmentEvents() {
    const items = flatMap(this.items, (item) => flatMap(item.offers, 'offerId'));
    return [...new Set(items)];
  }

  /**
   * @description Helper method to check if there is at least one
   *  fulfillment event with in progress status to assist the refreshing
   *  mechanism.
   *
   * @returns {boolean} True if there is an IN_PROGRESS fulfillment event,
   *  false otherwise.
   */
  hasInProgressFulfillmentEvents() {
    return this.items.some((item) => item.status === FULFILLMENT_EVENT_STATUS.IN_PROGRESS);
  }

  /**
   * @description Method to fetch the fulfillment event list
   * @returns {Promise} promise - promise object, resolved when the fulfillment event
   *   list is loaded.
   */
  async refresh() {
    try {
      await super.refresh(getParams(this));
    } catch (error) {
      log.error('FulfillmentEventList.refresh() failed. Error: ', error);
      return Promise.reject(error);
    }
    eventBus.emit(getEventConstant(this.listContext).UPDATE, this);
    eventBus.emit(getEventConstant(this.listContext).UPDATE_COUNT, this.pagination.itemCount);
    return this;
  }

  /**
   * @description Helper method to call the parent class
   *  shouldUpdateTotalItemCount, combined with conditions to make the
   *  result false if filters were set.
   *
   * @param {Object} params - top level wrapper object.
   * @param {Date} [params.end_date] - Upper bound to filter fulfillment
   *  events based on date. Note this is underscored because we're using the getParams() output.
   * @param {boolean} [params.include_translated_event] - Param to decide whether to  include events from
   *  translation service in the results. This is true by default. Note this is underscored because we're using the
   *  getParams() output.
   * @param {Date} [params.start_date] - Lower bound to filter fulfillment
   *  events based on date. Note this is underscored because we're using the getParams() output.
   * @param {string} [params.status] - Status value to filter by fulfillment
   *  event status.
   * @returns {boolean} True if an event about the updated count should be
   *  emitted, false otherwise.
   */
  shouldUpdateTotalItemCount(params) {
    return (
      super.shouldUpdateTotalItemCount() &&
      (this.listContext === FULFILLMENT_EVENT_LIST_CONTEXT.ORGANIZATION ||
        this.listContext === FULFILLMENT_EVENT_LIST_CONTEXT.LICENSE) &&
      !params.end_date &&
      !params.start_date &&
      !params.status &&
      // eslint-disable-next-line no-eq-null -- https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isnil
      (params.include_translated_event == null || params.include_translated_event === true)
    );
  }
}

//////////////

function getEventConstant(listContext) {
  switch (listContext) {
    case FULFILLMENT_EVENT_LIST_CONTEXT.IN_PROGRESS:
      return IN_PROGRESS_FULFILLMENT_EVENT_LIST_EVENT;
    case FULFILLMENT_EVENT_LIST_CONTEXT.LICENSE:
      return LICENSE_FULFILLMENT_EVENT_LIST_EVENT;
    default:
      return FULFILLMENT_EVENT_LIST_EVENT;
  }
}

function getParams(list) {
  return omitBy(
    {
      end_date: list.endDate,
      include_translated_event: list.includeTranslatedEvent,
      orgId: list.orgId,
      productId: list.productId,
      start_date: list.startDate,
      status: list.status,
    },
    (value) => value === undefined
  );
}

export default FulfillmentEventList;
