const ORGANIZATION_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.organization.ORGANIZATION_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/models/organization/OrganizationConstants.js
   */
  angular
    .module('binky.core.organizations.market-subsegments')
    .constant('ORG_MARKET_SUBSEGMENT', ORGANIZATION_CONSTANTS.ORGANIZATION_MARKET_SUBSEGMENT);
})();
