(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyOfferPerTermPrice
   * @description determines what the price per term string for this offer should be.
   *   ex. "$123.45 / mo per license" or "$456.78 / yr" etc
   * @param {Offer} offer model instance.
   * @param {Object} [options] Optional formatting options.
   * @param {Boolean} options.perLicense If set, this will override the default offer.isOrganizationDelegationType check
   *   to determine whether price is labelled with the perLicense string, such as '$19.99 / mo per license' or the term
   *   string, such as '$19.99 / mo'. Set to true to use the 'per license' version of the string, or false to use the
   *   term string.
   * @return {String} The string representing this offer's unit price per term
   * @example <div> {{ $ctrl.offer | binkyOfferPerTermPrice }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.commerce').filter('binkyOfferPerTermPrice', perTermPriceString);

  /* @ngInject */
  function perTermPriceString($translate, _, price) {
    return getPricePerTermStringFromOffer;

    ////////////////

    function getPricePerTermStringFromOffer(offer, options = {}) {
      const {perLicense} = options;

      // If pricing.prices[0].price_details.display_rules.price is 0, we give it another chance
      // to get the price from pricing.prices[0].price_details.unit
      if (_.get(offer, 'pricing.prices[0].price_details.display_rules.price')) {
        const itemPrice = price.format(
          _.get(offer, 'pricing.prices[0].price_details.display_rules.price'),
          _.get(offer, 'pricing.currency')
        );
        return getPerTermPriceString(offer.term, itemPrice);
      } else if (_.has(offer, 'pricing.prices[0].price_details.unit')) {
        const itemPrice = price.format(
          offer.priceIncludesTax()
            ? _.get(offer, 'pricing.prices[0].price_details.unit.amount_with_tax')
            : _.get(offer, 'pricing.prices[0].price_details.unit.amount_without_tax'),
          _.get(offer, 'pricing.currency')
        );
        return getPerTermPriceString(offer.term, itemPrice);
      }
      return $translate.instant('binky.widgets.commerce.perTerm.pricingUnavailable');

      ////////////////

      function getPerTermPriceString(billingFrequency, formattedPrice) {
        let subKey;

        // If perLicense is specified this overrides the default check to determine if it is perLicense.
        if (!_.isUndefined(perLicense)) {
          subKey = perLicense ? 'perLicense' : 'term';
        } else if (_.result(offer, 'isOrganizationDelegationType', false)) {
          subKey = 'term';
        } else {
          subKey = 'perLicense';
        }

        return $translate.instant(`binky.widgets.commerce.perTerm.${subKey}.${billingFrequency}`, {
          price: formattedPrice,
        });
      }
    }
  }
})();
