import JIL_CONSTANTS from '../../../api/jil/JilConstants';
import ListState from '../ListState';

class AlphaListState extends ListState {
  /**
   * @description Constructor for AlphaListState model Objects.
   *  Supports list services that use next-token paging.
   * @param {Object} [options] - options object
   * @param {Boolean} [options.cacheNextTokens] - true to cache previous page searches.
   *   This is especially useful if the API response does not include previous tokens (default is false)
   */
  constructor(options) {
    super(options);

    Object.assign(this, {
      contentType: '',
      currentPage: '',
      goNext: true,
      nextPage: '',
      pageSearch: '',
      pageSearchList: [],
      ...options, // override the defaults
    });
  }

  goToNextPage() {
    this.goNext = true;
    if (this.cacheNextTokens) {
      this.pageSearchList.push(this.pageSearch);
    }
    this.pageSearch = this.nextPage;
  }

  goToPreviousPage() {
    this.goNext = false;
    this.pageSearch = this.cacheNextTokens ? this.pageSearchList.pop() : this.currentPage;
  }

  hasNextPage() {
    return this.nextPage.length > 0;
  }

  hasPreviousPage() {
    return this.cacheNextTokens ? this.pageSearchList.length > 0 : this.currentPage.length > 0;
  }

  resetParams() {
    this.contentType = '';
    this.currentPage = '';
    this.goNext = true;
    this.nextPage = '';
    this.pageNumber = 1;
    this.pageSearch = '';
    this.pageSearchList = [];
  }

  setPageSize(newPageSize) {
    if (newPageSize > 0 && newPageSize !== this.pageSize) {
      // valid size for page
      this.pageSize = newPageSize;
      this.resetParams();
    }
  }

  setResponseAndHeaders(response, headers) {
    this.contentType = headers[JIL_CONSTANTS.HEADERS.CONTENT_TYPE] || '';
    this.currentPage = headers[JIL_CONSTANTS.HEADERS.CURRENT_PAGE] || '';
    this.nextPage = headers[JIL_CONSTANTS.HEADERS.NEXT_PAGE] || '';
    this.hasContent = response.length > 0 || this.filterQuery.length >= this.filterQueryMinLength;
  }

  setSortKeyAndReverseIfSameKey(sortKey) {
    super.setSortKeyAndReverseIfSameKey(sortKey);
    this.resetParams();
  }

  shouldRefreshOnSearch(value) {
    if (value !== this.filterQuery) {
      this.resetParams();
      this.filterQuery = value;
      return true;
    }
    return false;
  }

  toQueryParams() {
    return {
      page_search: this.pageSearch,
      page_size:
        // page size should always be a positive integer, if using cached next tokens.
        this.cacheNextTokens || this.goNext ? this.pageSize : this.pageSize * -1,
      search_query: this.filterQuery,
      sort: this.sortKey,
      sort_order: this.sortReverse ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
    };
  }
}

export default AlphaListState;
