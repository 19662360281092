const DATA_POINT_TYPES = {ANNUAL: 'annual', MONTHLY: 'monthly'};
const USAGE_REPORT_DATA = {
  // AVAILABLE_START_DATE Epoch time is Jun 18 2020
  AVAILABLE_START_DATE: '1592438400',
  NOT_FOUND_STATUS: {
    AFTER_START_DATE: 'notFoundAfterReportAvailableStartDate',
    BEFORE_START_DATE: 'notFoundBeforeReportAvailableStartDate',
  },
};
const USAGE_REPORT_ERROR = {EMPTY_DATA_POINTS: 'emptyDataPoints'};

export {DATA_POINT_TYPES, USAGE_REPORT_DATA, USAGE_REPORT_ERROR};
