(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.shell.panels.modal
   * @description module that defines the modal panel
   */
  angular.module('binky.shell.panels.modal', [
    'binky.shell.panels.manager',
    'binky.widgets.common.display-message',
    'binky.widgets.common.events',
    'binky.widgets.common.page-banner',
  ]);
})();
