(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/workspace/page/header/PageHeader.jsx
   *
   * @ngdoc component
   * @name binky.shell.workspace.page.header:binkyPageHeader
   *
   * @description Page header component for structuring common page header features. Use title or titleKey but not both.
   *
   * @param {string} breadcrumbsId Optional id for breacrumbs nav id if header should show breadcrumbs.
   * @param {number} [headingLevel=2] defines heading level value used in the page header tag
   * @param {string} title The page header title to be displayed as is.
   * @param {string} titleKey The page header title in the form of a string key that will be resolved prior to display.
   */
  angular.module('binky.shell.workspace.page.header').component('binkyPageHeader', {
    bindings: {
      breadcrumbsId: '<?',
      headingLevel: '<?',
      title: '@',
      titleKey: '@',
    },
    controller,
    templateUrl: 'shell/workspace/page/header/page-header.component.html',
    transclude: {
      'breadcrumbs-area': '?breadcrumbsArea',
      'button-bar-area': '?buttonBarArea',
      'icon-area': '?iconArea',
      'infocard-area': '?infocardArea',
      'links-area': '?linksArea',
      'scorecard-area': '?scorecardArea',
      'tag-area': '?tagArea',
    },
  });

  /* @ngInject */
  function controller($transclude, translationUtils) {
    const vm = this;

    /** Bindable members */
    vm.$onInit = $onInit;
    vm.hasIcon = hasIcon;

    /////////
    function $onInit() {
      vm.title = vm.titleKey ? translationUtils.sanitizeSafeInstant(vm.titleKey) : vm.title;
      vm.headingLevel = vm.headingLevel || 1;
      if (vm.headingLevel < 1 || vm.headingLevel > 2) {
        // Page header levels can't go under heading level 1 and above heading level 2, so reset to default
        vm.headingLevel = 1;
      }
    }

    function hasIcon() {
      return $transclude.isSlotFilled('icon-area');
    }
  }
})();
