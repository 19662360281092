import {View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Datepicker should be replaced with v3 DatePicker
import Datepicker from '@react/react-spectrum/Datepicker';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import {useId} from '@react-aria/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import './DateRange.pcss';

/**
 * DateRange - a widget for selecting date range.
 */
const DateRange = ({
  defaultValue,
  disabled = false,
  displayFormat = 'L',
  label,
  max,
  min,
  onChangeDateRange,
  placeholder,
  placement = 'bottom',
  value,
  valueFormat = 'YYYY-MM-DD',
}) => {
  const intl = useIntl();
  const labelFor = useId();
  const [validationState, setValidationState] = useState();

  const isValidDateRange = (end, start) =>
    // onChange will be triggered when the focus moves out from date picker so date can be an empty string
    !!(
      start &&
      end &&
      (!min || moment(start, displayFormat).isSameOrAfter(min)) &&
      (!max || moment(end, displayFormat).isSameOrBefore(max)) &&
      moment(start, displayFormat).isSameOrBefore(moment(end, displayFormat))
    );
  const onChange = ({end, start}) => {
    const isValid = isValidDateRange(end, start);
    // only set invalid state when both end and start are defined
    setValidationState(start && end && !isValid ? 'invalid' : undefined);
    onChangeDateRange({
      end: end ? moment(end, displayFormat).format(valueFormat) : undefined,
      isValid,
      start: start ? moment(start, displayFormat).format(valueFormat) : undefined,
    });
  };

  const pickerProps = {
    'data-testid': 'date-range',
    defaultValue,
    disabled,
    displayFormat,
    id: labelFor,
    max,
    min,
    onChange,
    placeholder: placeholder || moment.localeData().longDateFormat(displayFormat),
    placement,
    selectionType: 'range',
    styleName: 'date-range',
    validationState,
    valueFormat,
  };
  if (value) {
    pickerProps.value = value;
  }

  return (
    <View>
      <FieldLabel
        label={
          label ||
          intl.formatMessage({
            id: 'binky.common.dateRange.label',
          })
        }
        labelFor={labelFor}
      />
      <Datepicker {...pickerProps} />
    </View>
  );
};

DateRange.propTypes = {
  /**
   * Default start and end date for the DateRange using the default valueFormat 'YYYY-DD-MM'. Ex: 2021-01-10
   */
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  /**
   * A boolean value to determine if the DateRange should be disabled. Default is false.
   */
  disabled: PropTypes.bool,
  /**
   * The display format of the DateRange. Default is 'L' which is the locale-specific month numeral, day of month, year.
   */
  displayFormat: PropTypes.string,
  /**
   * The label for the DateRange. Default is "Date range".
   */
  label: PropTypes.string,
  /**
   * Maximum date for the DateRange using the default valueFormat 'YYYY-DD-MM'. Ex: 2021-01-10
   */
  max: PropTypes.string,
  /**
   * Minimum date for the DateRange using the default valueFormat 'YYYY-DD-MM'. Ex: 2021-01-10
   */
  min: PropTypes.string,
  /**
   * Handler that is called when the DateRange is selected. The param is an object with isValid, start and end keys
   * whose values are boolean and date strings in valueFormat.
   */
  onChangeDateRange: PropTypes.func.isRequired,
  /**
   * Translated placeholder of the DateRange.
   */
  placeholder: PropTypes.string,
  /**
   * Placement for the calendar popover relative to the input. top, right, bottom or left. Default is 'bottom'.
   */
  placement: PropTypes.string,
  /**
   * The value to assign to the Datepicker, useful for resetting the picker to a previously set range. Ex: [2021-01-10, 2021-01-12]
   * See https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/Datepicker/js/Datepicker.js#L99 for other accepted types.
   */
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * The date format for the min, max, start and end values. Default is 'YYYY-MM-DD'.
   */
  valueFormat: PropTypes.string,
};

export default DateRange;
