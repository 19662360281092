import {Content} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const PageContent = ({children}) => <Content data-testid="content">{children}</Content>;

PageContent.displayName = 'PageContent';
PageContent.propTypes = {
  /**
   * The content to be displayed in the PageContent.
   * While children aren't required, this component should only be used if there are or can be children,
   * for example, after an API returns.
   */
  children: PropTypes.node,
};

export default PageContent;
