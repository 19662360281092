import {Locale, localeUtils} from '@admin-tribe/binky';
import {
  ActionButton,
  Flex,
  Heading,
  Provider as SpectrumV3Provider,
  View,
} from '@adobe/react-spectrum';
import {TruncatedText} from '@pandora/react-truncated-text';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {useDialog} from '@react-aria/dialog';
import {FocusScope} from '@react-aria/focus';
import {I18nProvider} from '@react-aria/i18n';
import {OverlayContainer, useModal, useOverlay, usePreventScroll} from '@react-aria/overlays';
import {mergeProps, useId} from '@react-aria/utils';
import CloseIcon from '@spectrum-icons/workflow/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import PanelWait from '../panel-wait/PanelWait';

import styles from './Drawer.pcss';

/**
 * @deprecated Ported to Pandora-UI/ui-components
 * usage info here: https://git.corp.adobe.com/PandoraUI/ui-components/tree/master/packages/react-drawer
 */
/**
 * Drawer component for displaying additional information that does not need to
 * occupy area within the main page.
 */
const Drawer = (props) => {
  const {
    children,
    onClose,
    isLoading = false,
    isOpen,
    isWide,
    overlayVisible,
    title,
    titleIconElement,
  } = props;
  const intl = useIntl();
  const languageBCP47Code = localeUtils.getBcp47CodeFromLanguage(intl.locale);
  const spectrumLocale = Locale.get().activeLocaleForSpectrum;

  // Handle interacting outside the drawer and pressing
  // the Escape key to close the drawer.
  const ref = React.useRef();
  const {overlayProps} = useOverlay(
    mergeProps(props, {
      isDismissable: true,
    }),
    ref
  );

  // Prevent scrolling of content behind the overlay while the drawer is open,
  // and hide content outside the drawer from screen readers.
  usePreventScroll();

  // useModal hides content outside the current `<OverlayContainer>` from screen readers on mount and restores it on
  // unmount. It does this by keeping a count of open dialogs (!isDisabled) and adding and removing aria-hidden="true"
  // to the OverlayContainer which is in the ReactContainerBridge. To find this in the DOM, it is the parent of the
  // OverlayContainerDOM which is rendered as <div data-overlay-container="true">.
  //
  // The drawer/modal itself is rendered into an OverlayContainer which is a React portal. It renders in the DOM as
  // <div data-overlay-container="true"> usually towards the bottom.
  //
  // Both the drawer and modal are flavors of dialogs. If more than one dialog is open, you will see aria-hidden="true"
  // is added to the all data-overlay-containers except for the topmost dialog.
  const {modalProps} = useModal({isDisabled: !isOpen});

  // Provides the behavior and accessibility implementation for a dialog component.
  const {dialogProps} = useDialog(props, ref);

  // Manually assigning the aria-labelledby because useSlotId of the useDialog
  // hook checks for an element with the assigned id that is not found
  // in the DOM when the useLayoutEffect runs which results in the id being
  // set to null.
  const drawerHeadingId = useId();
  dialogProps['aria-labelledby'] = drawerHeadingId;

  return (
    <OverlayContainer>
      <TransitionGroup component={null}>
        {isOpen && (
          <CSSTransition
            classNames={{
              enter: styles['drawer-overlay-enter'],
              enterActive: styles['drawer-overlay-enter-active'],
              exit: styles['drawer-overlay-exit'],
            }}
            in={isOpen}
            timeout={500}
          >
            <View UNSAFE_style={{isolation: 'isolate'}}>
              <div className={styles['drawer-overlay']} data-testid="drawer-overlay">
                <FocusScope autoFocus contain restoreFocus>
                  <div
                    className={classNames(
                      styles.drawer,
                      isWide && styles['drawer-wide'],
                      overlayVisible && styles['drawer-opened'],
                      !overlayVisible && styles['drawer-closed']
                    )}
                    {...overlayProps}
                    {...dialogProps}
                    {...modalProps}
                    ref={ref}
                  >
                    <SpectrumV2Provider
                      data-testid="spectrum-v2-provider"
                      locale={spectrumLocale}
                      scale="medium"
                      theme="light"
                    >
                      <SpectrumV3Provider
                        data-testid="spectrum-v3-provider"
                        locale={spectrumLocale}
                      >
                        <View backgroundColor="transparent">
                          <I18nProvider locale={languageBCP47Code}>
                            {isLoading && <PanelWait />}
                            <Flex
                              alignItems="center"
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Heading
                                id={drawerHeadingId}
                                level={2}
                                marginX="size-200"
                                maxWidth="325px"
                              >
                                <Flex alignItems="center" columnGap="size-200" direction="row">
                                  {titleIconElement}
                                  <TruncatedText dataTestId="header-text" textContent={title} />
                                </Flex>
                              </Heading>
                              <Flex alignItems="start">
                                <ActionButton
                                  aria-label={intl.formatMessage({
                                    id: 'binky.shell.panels.drawer.dismiss',
                                  })}
                                  data-testid="close-button"
                                  isQuiet
                                  marginEnd="8px"
                                  marginTop="-24px"
                                  onPress={onClose}
                                  variant="primary"
                                >
                                  <CloseIcon size="S" />
                                </ActionButton>
                              </Flex>
                            </Flex>
                            {children}
                          </I18nProvider>
                        </View>
                      </SpectrumV3Provider>
                    </SpectrumV2Provider>
                  </div>
                </FocusScope>
              </div>
            </View>
          </CSSTransition>
        )}
      </TransitionGroup>
    </OverlayContainer>
  );
};

Drawer.propTypes = {
  /**
   * Children elements to render in the drawer container.
   */
  children: PropTypes.node.isRequired,
  /**
   * Flag indicating whether the wait should be displayed. (Defaults to false)
   */
  isLoading: PropTypes.bool,
  /**
   * Property that indicates whether the drawer is open. This property is
   * strictly read only, it is used to trigger the fading overlay CSSTransition
   * as well as making the drawer appear or disappear.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Render drawer 50% wider than usual.  This is needed by Omnitool and is not
   * intended for publicly visible components. Defaults false.
   */
  isWide: PropTypes.bool,
  /**
   * Close handler to be invoked when the drawer is closed.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Property that indicates whether the drawer overlay is visible. This
   * property is strictly read only, it is set via setOverlayVisible in
   * the closeDrawer and openDrawer callbacks.
   */
  overlayVisible: PropTypes.bool.isRequired,
  /**
   * Title of the drawer to be shown in a Heading element
   */
  title: PropTypes.string.isRequired,
  /**
   * An icon/avatar React element to render to the left of the drawer title.
   */
  titleIconElement: PropTypes.element,
};

export default Drawer;
