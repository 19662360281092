import omitBy from 'lodash/omitBy';

import JIL_CONSTANTS from 'api/jil/JilConstants';
import jilInvoices from 'api/jil/jilInvoices';
import Invoice from 'models/invoice/Invoice';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

import {
  INVOICE_LIST_CACHE_ID,
  INVOICE_LIST_EVENT,
  INVOICE_SORT,
} from '../../models/invoice/InvoiceListConstants';

class InvoiceList extends JilModelList {
  /**
   * @description Method to create a new instance of a InvoiceList.
   * @param {Object} [options] - configures the InvoiceList model instance.
   * @param {String} [options.orgId] - associates InvoiceList instance with an org.
   * @param {String} [options.start_date] - ISO date string, earliest invoice date to include
   * @param {String} [options.end_date] - ISO date string, latest invoice date to include
   * @param {Array<INVOICE_STATUS>} [options.invoiceStatuses] - an array of invoice statuses as
   *   defined in INVOICE_STATUS, used for filtering through JIL. Defaults to empty array.
   * @param {String} [options.filterQuery] - query to filter results against
   * @param {Number} [options.pageNumber] - 1-based page number to fetch
   * @param {Number} [options.pageSize] - number of items to display per page
   * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
   * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
   */
  constructor(options = {}) {
    super({
      filterQuery: options.filterQuery,
      isCacheable: true,
      itemClassRef: Invoice,
      modelCacheId: INVOICE_LIST_CACHE_ID,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize,
      resource: jilInvoices.getInvoices,
      sortExpression: options.sortExpression || INVOICE_SORT.INVOICE_DATE,
      sortOrder: options.sortOrder || JIL_CONSTANTS.ORDER.DESC,
      state: options.state,
    });

    Object.assign(this, {
      ...options,
      endDate: options?.end_date,
      invoiceStatuses: options?.invoiceStatuses || [],
      orgId: options?.orgId,
      startDate: options?.start_date,
    });
  }

  /**
   * @description Creating items in this fashion is not supported for this list.
   */
  // eslint-disable-next-line class-methods-use-this -- porting from Angular
  create() {
    throw new Error('Unimplemented method!');
  }

  /**
   * @description Method to get the latest invoice from an InvoiceList
   *
   * @returns {Invoice} invoice - the latest invoice
   */
  getLatestInvoice() {
    return this.items.find((item) => item.latest);
  }

  /**
   * @description Method to refresh the contents of the list.
   *
   * @returns {Promise} promise - resolved when the list is refreshed
   */
  async refresh() {
    try {
      await super.refresh(getQueryParams(this));
    } catch (error) {
      log.error('InvoiceList.refresh() failed. Error: ', error);
      throw error;
    }
    eventBus.emit(INVOICE_LIST_EVENT.UPDATE, this);
    return this;
  }

  /**
   * @description Method to save/update PO number.
   * @param {String} contractId - the associated contract Id
   * @param {String} invoiceId - the associated invoice Id
   * @param {String} newPoNumber - the new PO number
   * @param {String} oldPoNumber - the old PO number
   * @param {String} reason - the reason for updating. One of
   * 'MISSING_PO_NUMBER', 'INCORRECT_PO_NUMBER', 'INVALID_PO_NUMBER'
   * @returns {Promise} promise - resolved when the call is made
   */
  async savePONumber({contractId, invoiceId, newPoNumber, oldPoNumber, reason}) {
    try {
      await jilInvoices.putUpdatePONumber({
        contractId,
        invoiceId,
        newPoNumber,
        oldPoNumber,
        orgId: this.orgId,
        reason,
      });
    } catch (error) {
      log.error('Failed to update PO number. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

/**
 * @description Removes any query params that are left undefined and returns the altered object.
 * @param {Object} model - contains the parameters of which to filter by (timeframe, status)
 * @returns {Object} The query params object but with any fields left undefined omitted.
 */
function getQueryParams(model) {
  return omitBy(
    {
      end_date: model.endDate,
      invoiceStatuses:
        model.invoiceStatuses.length > 0 ? model.invoiceStatuses.join(',') : undefined,
      orgId: model.orgId,
      start_date: model.startDate,
    },
    (value) => value === undefined
  );
}

export default InvoiceList;
