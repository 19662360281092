import minBy from 'lodash/minBy';

import {CONTRACT_LEGACY_DX_ID} from 'models/contract/ContractConstants';

import {hasDirectContract} from './contractDirectUtils';
import {hasIndirectContract} from './contractIndirectUtils';

/**
 * @description Returns true if at least one contract can add team licenses. At least
 *    one contract must be Direct or Indirect, not migrating, not have new terms to accept,
 *    have an owner country code, and be allowed to create a PA (not be in past due state, etc).
 * @param {ContractList} contractList - the contract list
 * @returns {Boolean} true if allowed to add licenses.
 */
function canAddProducts(contractList) {
  return contractList.items.some((item) => item.canAddProducts());
}

/**
 * @description Gets the list of contracts that AC knows how to display. This
 *     removes any DX legacy contracts from the list.
 * @param {ContractList} contractList - ContractList whose items will be filtered.
 * @returns {Contract[]} - Filtered array of contracts
 */
function getAdminConsoleDisplayableContracts(contractList) {
  return contractList.items.filter((item) => item.id !== CONTRACT_LEGACY_DX_ID);
}

/**
 * @description Getter to obtain the allocation contracts in a ContractList.
 * @param {ContractList} contractList  - ContractList whose items will be filtered.
 * @returns {Contract[]} - Filtered array of contracts.
 */
function getAllocationContracts(contractList) {
  return contractList.items.filter((item) => item.isModelAllocation());
}
/**
 * @description Return an array of all contracts associated with a product.
 *
 * @param {ContractList} contractList - the contract list
 * @param {Product} product - the product
 *
 * @returns {Array<Contract>} The contracts for this product
 */
function getContractsForProduct(contractList, product) {
  return contractList.items?.filter((item) => product.contractIds?.includes(item.id));
}

/**
 * @description Returns the first found indirect/direct contract. It should
 *  not be possible to have more than one of these, so this really just excludes enterprise.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} direct or indirect contract, or undefined
 */
function getDirectOrIndirectContract(contractList) {
  return contractList.items.find((item) => item.isDirectContract() || item.isIndirectContract());
}

/**
 * @description Returns the ID for the first found indirect/direct contract. It should
 *  not be possible to have more than one of these, so this really just excludes enterprise.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {String} direct or indirect contract ID, or null
 */
function getDirectOrIndirectContractId(contractList) {
  const foundContract = getDirectOrIndirectContract(contractList);
  return foundContract ? foundContract.id : null;
}

/**
 * @description Returns the earliest anniversary date for any indirect/direct contracts.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {String} anniversary date in ISO-8061 timestamp format or undefined if no
 *   anniversary date
 */
function getEarliestAnniversaryDate(contractList) {
  const nonEnterpriseContracts = contractList.items.filter((item) => !item.isEnterpriseContract());
  const earliestContract = minBy(nonEnterpriseContracts, (item) => item.getAnniversaryDate());

  return earliestContract ? earliestContract.getAnniversaryDate() : undefined;
}

/**
 * @description Returns the earliest start date
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Date} start date
 */
function getEarliestStartDate(contractList) {
  const earliestContract = minBy(contractList.items, (item) => item.getStartDate());

  return earliestContract ? earliestContract.getStartDate() : undefined;
}

/**
 * @description Return the first contract associated with this product.
 *
 * @param {ContractList} contractList - the contract list
 * @param {Product} product - the product
 *
 * @returns {Contract} The first contract associated with this product, or undefined if none is found
 */
function getFirstContractForProduct(contractList, product) {
  const contracts = getContractsForProduct(contractList, product);
  return contracts?.length > 0 ? contracts[0] : undefined;
}

/**
 * @description Returns the first contract that has an offer eligible for migration.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Contract} contract with offer switch migration
 */
function getOfferSwitchMigrationEligibleContract(contractList) {
  return contractList.items.find((item) =>
    item.hasOfferSwitchMigration({onlyEligibleMigrations: true})
  );
}

/**
 * @description Returns true if there is an allocation contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if this is an allocation contract.
 */
function hasAllocationContract(contractList) {
  return contractList.items.some((item) => item.isModelAllocation());
}

/**
 * @description Returns true if there is a contract that is in renewal window.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there is a contract in renewal window.
 */
function hasContractInRenewalWindow(contractList) {
  return contractList.items.some((item) => item.isInRenewalWindow());
}

/**
 * @description Check whether there's at least 1 contract that has
 * offer switch migration, regardless of the status.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if at least 1 contract has offer switch migration;
 * false when all contracts have no offer switch migration
 */
function hasAnyOfferSwitchMigrationContracts(contractList) {
  return (
    contractList.items.find((item) =>
      item.hasOfferSwitchMigration({onlyEligibleMigrations: false})
    ) !== undefined
  );
}

/**
 * @param {ContractList} contractList - the contract list
 * @returns {Boolean} whether any contracts have a contract with China as the country code
 */
function hasChinaContract(contractList) {
  return contractList.items.some((contract) => contract.getOwnerCountryCode() === 'CN');
}

/**
 * @description Returns true if there is only trial or allocation contracts
 * @param {ContractList} contractList
 * @returns {Boolean} true if there is only trial or allocation contracts
 */
function hasOnlyTrialOrAllocationContracts(contractList) {
  return (
    contractList.items.length > 0 &&
    contractList?.items?.every(
      (contract) => contract.isModelTrial() || contract.isModelAllocation()
    )
  );
}

/**
 * @description Returns true if there is a direct or indirect contract.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there is a direct or indirect contract.
 */
function hasTeamContract(contractList) {
  return hasIndirectContract(contractList) || hasDirectContract(contractList);
}

/**
 * @description Returns true if any contract requires acceptance of terms.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if acceptance of terms is required.
 */
function hasTermsToAccept(contractList) {
  return contractList.items.some((item) => item.hasTermsToAccept());
}

/**
 * @description Returns true if any contract requires reacceptance of terms.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if reacceptance of terms is required.
 */
function hasTermsToReAccept(contractList) {
  return contractList.items.some((item) => item.hasTermsToReAccept());
}

/**
 * @description Returns true if there is at least one trial or allocation contract
 * @param {ContractList} contractList
 * @returns {Boolean} true if there is at least one trial or allocation contract
 */
function hasAnyTrialOrAllocationContracts(contractList) {
  return (
    contractList.items.length > 0 &&
    contractList?.items?.some((contract) => contract.isModelTrial() || contract.isModelAllocation())
  );
}

/**
 * @description Check whether the userId is the owner of one of the contracts
 * @param {ContractList} contractList - the contract list
 *
 * @param {Object} options - options to configure contract owner check
 * @param {Object} options.userId - user ID to determine ownership
 * @returns {Boolean} true if the userId is the owner of one of the contracts
 */
function isContractOwner(contractList, {userId} = {}) {
  return contractList.items.some((item) => item.isContractOwner({userId}));
}

/**
 * @description Returns true if there are any active migrations for these contracts
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there are migrations in progress
 */
function isMigrating(contractList) {
  return contractList.items.some((item) => item.isMigrating());
}

/**
 * @description Returns true if there are any contracts have initial orders processing
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if there are initial orders processing
 */
function isOrderProcessing(contractList) {
  return contractList.items.some((item) => item.isOrderProcessing());
}

/**
 * @description Returns true if any contract requires acceptance of terms.
 * @param {ContractList} contractList - the contract list
 *
 * @returns {Boolean} true if acceptance of terms is required.
 */
function mustAcceptTerms(contractList) {
  return contractList.items.some((item) => item.mustAcceptTerms());
}

export {
  canAddProducts,
  getAdminConsoleDisplayableContracts,
  getAllocationContracts,
  getContractsForProduct,
  getDirectOrIndirectContract,
  getDirectOrIndirectContractId,
  getEarliestAnniversaryDate,
  getEarliestStartDate,
  getFirstContractForProduct,
  getOfferSwitchMigrationEligibleContract,
  hasAllocationContract,
  hasAnyOfferSwitchMigrationContracts,
  hasAnyTrialOrAllocationContracts,
  hasChinaContract,
  hasContractInRenewalWindow,
  hasOnlyTrialOrAllocationContracts,
  hasTeamContract,
  hasTermsToAccept,
  hasTermsToReAccept,
  isContractOwner,
  isMigrating,
  isOrderProcessing,
  mustAcceptTerms,
};
