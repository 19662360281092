(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.search.within
   * @description module that defines search-within widgets
   */
  angular.module('binky.widgets.common.search.within', [
    'binky.core.lodash',
    'binky.widgets.common.search',
    'binky.widgets.common.select',
  ]);
})();
