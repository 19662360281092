import merge from 'lodash/merge';

import CacheTimer from './CacheTimer';

/**
 * A cache item holds a piece of data for a certain amount of time.
 * The lifetime of the data is customisable and it starts
 * everytime the data is updated.
 *
 * The underlying timer is available for finer control over the
 * lifetime of the data.
 */
class CacheItem {
  constructor(initialData = {}, lifetime = CacheTimer.CACHE_LIFETIME) {
    this.data = initialData;
    this.timer = new CacheTimer(lifetime);
    this.timer.start();
  }

  /**
   * Gets the stored value.
   *
   * @return {any} The stored value
   */
  getValue() {
    return this.data;
  }

  /**
   * Reports whether the data is stale or not. This is dependant on the
   * provided lifetime.
   *
   * @return {boolean} True if stale, false otherwise.
   */
  get isStale() {
    return this.timer.isStale();
  }

  /**
   * Updates the stored value. The value is merged so if an object is stored
   * only part of the value can be provided. This can be overridden by setting
   * merge to false in options.
   *
   * Note that when a value is updated the timer is reset.
   *
   * @param {any}    value           The updated value
   * @param {Object} options         Options for how the update should be handled.
   * @param {Class}  [options.merge] Merge passed value with the existing one.
   */
  putValue(value, options = {merge: true}) {
    this.data = options.merge ? merge(this.data, value) : value;

    // start the timer since we have new data
    this.timer.start();
  }
}

export default CacheItem;
