(function () {
  'use strict';
  /**
   * @deprecated should use the React Spectrum Button
   *
   * @ngdoc component
   * @name binky.widgets.common.button:binkyButton
   *
   * @description A button widget.
   *
   * @param [coralClose] If present, passed through to the coral button to indicate it should close a panel.
   * @param [disabled] Whether this button is disabled or not.
   * @param {Boolean} [focusable] Whether to allow focus on the inner coral button. Default: undefined (meaning is focusable)
   * @param [icon] The icon to display on the button. Optional.
   * @param [iconPosition] The position of the icon; 'left', 'right', default is 'left'. Optional.
   * @param [iconSize] The size of icon icon; 'XXS', 'XS', 'S', 'M', default is 'S'. Optional.
   * @param [variant] One of cta, secondary, warning, quiet, minimal, or disabled. Default: secondary
   *
   * @example <binky-button icon="download" icon-size="S" variant="cta">My Button Text</binky-button>
   *
   * Note, translate doesn't work well when set on a transclude element, and so it is
   * cleanest to set the translate on a span element within the button.
   * @example <binky-button variant="cta"><span translate>path.to.key</span></binky-button>
   */
  angular.module('binky.widgets.common.button').component('binkyButton', {
    bindings: {
      coralClose: '@?',
      describedBy: '@?',
      disabled: '@?',
      focusable: '<?',
      icon: '@?',
      iconPosition: '@?',
      iconSize: '@?',
      size: '@?',
      variant: '@?',
    },
    controller,
    transclude: true,
  });

  /* @ngInject */
  function controller($compile, $element, $transclude, _) {
    const vm = this;

    // we set these to stop ng-aria from declaring the binky-button as tab-able
    // (due to the presence of the ng-click). This does not impact the inner
    // Coral button, which remains both clickable and tab-able.
    $element.attr('role', 'presentation');
    $element.attr('tabindex', '-1');

    _.assign(vm, {
      $onChanges,
      $onInit,
      $postLink,
    });

    $element.bind('click', (evt) => {
      if (isDisabled()) {
        evt.stopImmediatePropagation();
        return false;
      }
      return true;
    });

    let button, buttonEl;

    function $onChanges() {
      if (button && buttonEl) {
        setDisabled();
        setInnerButtonFocusable();
        setAttributes();
      }
    }

    function $onInit() {
      switch (vm.size) {
        case 'S':
          $element.addClass('binky-small-button');
          break;
        default:
        // none
      }
    }

    function $postLink() {
      button = new Coral.Button();
      setAttributes();
      $transclude(($linkNode, scope) => {
        button.label.classList.add('_coral-Button-label');
        angular.element(button.label).empty().append($compile($linkNode)(scope));
      });
      buttonEl = angular.element(button);
      setDisabled();
      setDescribedBy();
      setInnerButtonFocusable();
      if (vm.coralClose !== undefined) {
        buttonEl.attr('coral-close', '');
      }
      $element.append(buttonEl);
    }

    function isDisabled() {
      // using ng-disabled results in disabled=true, but we also want to support just disabled
      return vm.disabled !== undefined && vm.disabled !== false;
    }

    function setDescribedBy() {
      if (vm.describedBy) {
        buttonEl.attr('aria-describedby', vm.describedBy);
      } else {
        buttonEl.removeAttr('aria-describedby');
      }
    }

    function setDisabled() {
      if (isDisabled()) {
        buttonEl.attr('disabled', '');
      } else {
        buttonEl.removeAttr('disabled');
      }
    }

    function setAttributes() {
      button.set({
        icon: vm.icon,
        iconPosition: vm.iconPosition,
        iconSize: vm.iconSize,
        variant: vm.variant,
      });
    }

    function setInnerButtonFocusable() {
      // No-op if vm.focusable undefined, as we do not want to take off attributes if was set via other means
      if (!_.isUndefined(vm.focusable)) {
        if (vm.focusable) {
          $element.attr('aria-hidden'); // tabindex set in constructor
          buttonEl.removeAttr('aria-hidden');
          buttonEl.removeAttr('tabindex');
        } else {
          $element.attr('aria-hidden', 'true'); // tabindex set in constructor
          buttonEl.attr('aria-hidden', 'true');
          buttonEl.attr('tabindex', '-1');
        }
      }
    }
  }
})();
