(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/product-picker/ProductPicker.jsx
   *
   * @ngdoc component
   * @name binky.widgets.products:BinkyProductAssignment
   * @description A product assignment widget used to select products for assignment. Will display icons of the selected products.
   *              Truncates the number of displayed icons by showing a popover when count of selected icons exceeds displayedIconLimit
   * @param {Array} disabledProducts An array of product IDs that indicate which products should be disabled from selection
   * @param {Number} [displayedIconLimit] The number of icons to be displayed when they are selected. Defaults to 4
   * @param {Array} products An array of products containing product information needed for product assignment
   * @param {Object} products[i].assets The assets object which contains the icons necessary to display the product
   * @param {Number} products[i].assignedLicenses The number of licenses that have been assigned to the product
   * @param {Number} products[i].availableLicenses The number of available licenses of the product
   * @param {String} products[i].longName The long name of the product
   * @param {String} products.id The id of the product to distinguish the product in the Selection
   * @param {String} pulldownId The pulldownId of the product selection component. Each instance of this component will
   *                         create a pulldown with the id of 'product-assignment-pulldown-${pulldownId}' used to identify the pulldown
   * @param {Selection} selection The Selection used to track the state of selected products
   */
  angular.module('binky.widgets.products.product-assignment').component('binkyProductAssignment', {
    bindings: {
      disabledProducts: '<?',
      displayedIconLimit: '<?',
      products: '<',
      pulldownId: '<',
      selection: '<',
    },
    controller,
    templateUrl: 'widgets/products/product-assignment/product-assignment.component.html',
  });

  function controller($element, $translate, _, panelManager) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      displayedIconLimit: _.defaultTo(vm.displayedIconLimit, 4),
      getPopoverProductCount,
      isDisabled,
      openPulldown,
      showCountOfPopoverProducts,
    });

    function $onInit() {
      vm.pulldownId = `product-assignment-pulldown-${vm.pulldownId}`;
      vm.popoverId = `product-assignment-popover-${vm.pulldownId}`;
    }

    function getPopoverProductCount() {
      return $translate.instant('binky.widgets.products.productAssignment.popoverProductCount', {
        popoverProductCount: vm.selection.items.length - vm.displayedIconLimit,
      });
    }

    function isDisabled() {
      const areProductsAssignable = _.some(
        vm.products,
        (product) => product.assignedLicenses < product.availableLicenses
      );
      const selectionIsEmpty = vm.selection.count === 0;

      const isButtonDisabled = selectionIsEmpty && !areProductsAssignable;

      updateTooltipMessage(isButtonDisabled);

      return isButtonDisabled;
    }

    function openPulldown() {
      if (panelManager.isOpen(vm.pulldownId)) {
        panelManager.close(vm.pulldownId);
      } else {
        panelManager.open(vm.pulldownId);
      }
    }

    function showCountOfPopoverProducts() {
      return vm.selection.items.length > vm.displayedIconLimit;
    }

    /* Private functions */

    // Need to update the tooltip text via JS so translate directive doesn't prevent the tooltip arrow from showing
    function updateTooltipMessage(isButtonDisabled) {
      const coralTooltip = _.head($element.find('coral-tooltip'));
      const coralTooltipContent = new Coral.Tooltip.Content();
      coralTooltipContent.innerText = $translate.instant(
        isButtonDisabled
          ? 'binky.widgets.products.productAssignment.button.disabledTooltip'
          : 'binky.widgets.products.productAssignment.button.defaultTooltip'
      );
      coralTooltip.content = coralTooltipContent;
    }
  }
})();
