import axios from 'axios';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Configure JIL Organization Offers API
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for JIL Organization Offers API
 * @param {string} config.clientId - The identifier for application
 * @param {string[]} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @description Fetches offers appropriate for a contract of a particular
 *     organization.
 *
 * @param {string} contractId - Id of the contract.
 * @param {string} orgId - Id of the organization.
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the requested offers.
 */
const getOffers = ({contractId, orgId, ...params}) =>
  axios.get(`${url}/v2/organizations/${orgId}/contracts/${contractId}/offers`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

const jilOrganizationsContractOffers = {
  configure,
  getOffers,
};

export default jilOrganizationsContractOffers;
