(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.directories:binkyDirectoryEncryptionStatus
   * @description renders a directory encryption status icon/label for a directory
   */
  angular
    .module('binky.widgets.directories.encryption-status')
    .component('binkyDirectoryEncryptionStatus', {
      bindings: {
        showIcon: '<',
        status: '<',
      },
      controller,
      templateUrl:
        'widgets/directories/encryption-status/directory-encryption-status.component.html',
    });

  /* @ngInject */
  function controller($element, _, DIRECTORY_ENCRYPTION_PROGRESS, DIRECTORY_ENCRYPTION_STATUS) {
    const vm = this;
    vm.$postLink = $postLink;

    function $postLink() {
      setStatusAndIcon(vm.status);
      vm.$onChanges = $onChanges;
    }

    function $onChanges() {
      setStatusAndIcon(vm.status);
    }

    // eslint-disable-next-line complexity
    function setStatusAndIcon(statusObj) {
      let iconColor = '';
      let iconName = '';
      let status = '';

      // First check progress status
      switch (_.get(statusObj, 'encryptionProgressStatus')) {
        case DIRECTORY_ENCRYPTION_PROGRESS.FAILED:
          status = '.failed';
          iconName = 'closeCircle';
          iconColor = 'red';
          break;
        case DIRECTORY_ENCRYPTION_PROGRESS.IN_PROGRESS:
          status = '.pending';
          iconName = 'clock';
          iconColor = 'yellow';
          break;
        default:
        // For DIRECTORY_ENCRYPTION_PROGRESS.COMPLETED, DIRECTORY_ENCRYPTION_PROGRESS.UNKNOWN, and other statuses
        // Do nothing, let the status be set by the encryption status
      }

      // If no progress status has been set, check encryptionStatus
      if (!status) {
        switch (_.get(statusObj, 'encryptionStatus')) {
          case DIRECTORY_ENCRYPTION_STATUS.DISABLED:
            status = '.disabled';
            iconName = 'closeCircle';
            iconColor = 'grey';
            break;
          case DIRECTORY_ENCRYPTION_STATUS.ENABLED:
            status = '.enabled';
            iconName = 'checkmarkCircle';
            iconColor = 'green';
            break;
          case DIRECTORY_ENCRYPTION_STATUS.FAILED:
            status = '.failed';
            iconName = 'closeCircle';
            iconColor = 'red';
            break;
          case DIRECTORY_ENCRYPTION_STATUS.NOT_APPLICABLE:
            status = '.notApplicable';
            break;
          case DIRECTORY_ENCRYPTION_STATUS.REVOKED:
            status = '.revoked';
            iconName = 'circle';
            iconColor = 'yellow';
            break;
          case DIRECTORY_ENCRYPTION_STATUS.UNKNOWN:
            status = '.unknown';
            iconName = 'closeCircle';
            iconColor = 'red';
            break;
          default:
            // Handle any unexpected API response.
            status = '.notAvailable';
            iconName = 'circle';
            iconColor = 'grey';
        }
      }

      vm.statusLabel = status;

      const iconContainer = angular.element(
        $element[0].querySelectorAll('.directory-encryption-status-icon')
      );
      iconContainer.empty();

      // Create icon if necessary
      if (vm.showIcon && iconName) {
        const icon = angular.element(new Coral.Icon());
        icon.attr('size', 'XS');
        icon.attr('icon', iconName);
        icon.addClass(iconColor);
        iconContainer.append(icon);
      }
    }
  }
})();
