(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/avatar/Avatar.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.avatar:binkyAvatar
   *
   * @description an avatar widget
   *
   * @param {Member} member The member to fetch an avatar for.
   * @param {boolean} presentational If the image is presentational,
   *   set this to true to apply an empty alt attribute (defaults to false).
   * @param {string} size The size of avatar to render.
   *   One of: XXS, XS, S, M, L, XL. Default: S
   * @param {string} src An image to use instead of going to IMS.
   *
   * @example <binky-avatar size="M" member="myMember"></binky-avatar>
   *
   *
   */
  angular.module('binky.widgets.common.avatar').component('binkyAvatar', {
    bindings: {
      member: '<',
      presentational: '@?',
      size: '@',
      src: '@',
    },
    controller,
    templateUrl: 'widgets/common/avatar/avatar.component.html',
  });

  /* @ngInject */
  function controller(
    $compile,
    $element,
    $scope,
    _,
    AuthenticatedUser,
    authentication,
    MemberType,
    UserAvatar
  ) {
    let deregisterEventHandler, initialized, placeholderEl;

    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
    });

    /////////////////

    function $onChanges(changesObj) {
      // when the src is dynamically set, we clear the placeholder
      if (initialized) {
        if (
          _.get(changesObj, 'src.currentValue') &&
          changesObj.src.currentValue !== vm.avatarImgUrl &&
          !vm.member
        ) {
          _.invoke(placeholderEl, 'remove');
          vm.avatarImgUrl = changesObj.src.currentValue;
        } else if (
          _.get(changesObj, 'member.currentValue') &&
          getMemberId(changesObj.member.currentValue) !==
            getMemberId(changesObj.member.previousValue)
        ) {
          delete vm.avatarImgUrl;
          _.invoke(placeholderEl, 'remove');
          configureAvatar();
        }
      }
    }

    function $onDestroy() {
      deregisterEventHandler();
    }

    function $onInit() {
      configureAvatar();

      // MEMBER_EVENT.UPDATE in onesie TO DO: when MEMBER_EVENT ported to binky, replace here with constant value
      deregisterEventHandler = $scope.$on('UpdateMember', updateAvatar);

      initialized = true;
    }

    function setDefaults() {
      // eslint-disable-next-line unicorn/explicit-length-check -- lint rule is misundersting the syntax
      vm.size = vm.size || 'S';
      vm.presentational = vm.presentational || false;
    }

    function configureAvatar() {
      setDefaults();

      const icon = getIcon(vm.member);

      // We declare the placeholderSize distinct from the main size to support the active user case
      let placeholderSize = vm.size;

      // If we have a src already we don't need the placeholder
      if (vm.src) {
        vm.avatarImgUrl = vm.src;
      } else {
        // if a member was passed, determine if its the active IMS user
        const loggedInUserId = _.get(authentication.getUserProfile(), 'userId');
        const isActiveUser = vm.member && getMemberId(vm.member) === loggedInUserId;
        if (isActiveUser) {
          placeholderSize = getActiveUserPlaceholderSize(vm.size);
        }
        // dynamically insert the placeholder element
        placeholderEl = $compile(
          `<div class="avatar-placeholder ${vm.size} ${isActiveUser ? 'active-user' : ''}">
             <coral-icon class="${placeholderSize}" icon="${icon}" size="${placeholderSize}" ${
               vm.presentational ? 'alt=""' : ''
             }></coral-icon>
           </div>`
        )($scope);
        $element.append(placeholderEl);
        // We only go to IMS if we have the member object to fill out the request
        if (vm.member) {
          if (vm.member.getType().isUser() && !vm.member.getType().isTechnicalAccount()) {
            fetchAvatar();
          }
        }
      }
    }

    function fetchAvatar() {
      UserAvatar.get(getMemberId(vm.member), vm.size)
        .$promise.then((avatar) => {
          vm.avatarImgUrl = avatar.src;
          placeholderEl.remove();
        })
        // We want an error fetching the avatar to do nothing, keeping the existing image/placeholder
        .catch(_.noop);
    }

    function getActiveUserPlaceholderSize(size) {
      let returnSize;
      switch (size) {
        case 'XXS':
          returnSize = 'S';
          break;
        case 'XS':
          returnSize = 'M';
          break;
        case 'S':
          returnSize = 'L';
          break;
        case 'M':
          returnSize = 'XL';
          break;
        case 'L':
          returnSize = 'XXL';
          break;
        case 'XL':
          returnSize = 'XXL';
          break;
        default:
          throw new Error(`Unsupported avatar size requested: ${size}`);
      }
      return returnSize;
    }

    function getIcon(member) {
      let foundIcon = 'user';
      if (member) {
        const memberType = new MemberType(member.type, member.id);
        if (memberType.isUserGroup()) {
          foundIcon = 'UserGroup';
        } else if (memberType.isTechnicalAccount()) {
          foundIcon = 'gears';
        }
      }
      return foundIcon;
    }

    function getMemberId(member) {
      if (member) {
        if (member instanceof AuthenticatedUser) {
          return member.getId();
        }
        return member.id;
      }
      return undefined;
    }

    function updateAvatar(event, userId) {
      if (vm.member && vm.member.id === userId) {
        fetchAvatar();
      }
    }
  }
})();
