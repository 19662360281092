(function () {
  /**
   * @ngdoc module
   * @name binky.core.locale.ready
   * @description defines localeReady service, which fetching loc files.
   */
  angular.module('binky.core.locale.ready', [
    'angularMoment',
    'binky.core.configuration.ready',
    'binky.core.locale',
    'binky.core.lodash',
    'binky.core.storage-utils',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
  ]);
})();
