import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import React from 'react';
import {useIntl} from 'react-intl';

import Drawer from 'shell/panels/drawer/Drawer';
import DrawerContent from 'shell/panels/drawer/content/DrawerContent';
import useDrawer from 'shell/panels/drawer/useDrawer';

import OmniToolCachePanel from './panels/OmniToolCachePanel';
import OmniToolFakesPanel from './panels/OmniToolFakesPanel';
import OmniToolFlagsPanel from './panels/OmniToolFlagsPanel';
import OmniToolUtilitiesPanel from './panels/OmniToolUtilitiesPanel';

/**
 * OmniTool - provides tools and functionality for developers inside the application.
 */
const OmniTool = () => {
  const id = useId();
  const intl = useIntl();
  const [closeDrawer, isOpen, openDrawer, overlayVisible] = useDrawer();

  document.addEventListener('keydown', onKeydown);

  function isSupportedElement(tagName) {
    switch (tagName.toLowerCase()) {
      case 'input':
      case 'textarea':
      case 'select':
        return false;
      default:
        return true;
    }
  }

  function onKeydown(event) {
    switch (event.key) {
      case '~':
        // we ensure that the targeted element isn't something consuming text input
        if (isSupportedElement(event.target.tagName)) {
          openDrawer();
        }
        break;
      default:
        break;
    }
  }

  return (
    <Drawer
      id={id}
      isOpen={isOpen}
      isWide
      onClose={closeDrawer}
      overlayVisible={overlayVisible}
      title={intl.formatMessage({id: 'binky.common.omniTool.title'})}
    >
      <DrawerContent>
        <Tabs aria-label={intl.formatMessage({id: 'binky.common.omniTool.title'})}>
          <TabList>
            <Item key="flags">{intl.formatMessage({id: 'binky.common.omniTool.tabs.flags'})}</Item>
            <Item key="fakes">{intl.formatMessage({id: 'binky.common.omniTool.tabs.fakes'})}</Item>
            <Item key="utilities">
              {intl.formatMessage({id: 'binky.common.omniTool.tabs.utilities'})}
            </Item>
            <Item key="cache">{intl.formatMessage({id: 'binky.common.omniTool.tabs.cache'})}</Item>
          </TabList>
          <TabPanels>
            <Item key="flags">
              <OmniToolFlagsPanel />
            </Item>
            <Item key="fakes">
              <OmniToolFakesPanel />
            </Item>
            <Item key="utilities">
              <OmniToolUtilitiesPanel />
            </Item>
            <Item key="cache">
              <OmniToolCachePanel />
            </Item>
          </TabPanels>
        </Tabs>
      </DrawerContent>
    </Drawer>
  );
};

OmniTool.propTypes = {};

export default OmniTool;
