(function () {
  /**
   * @deprecated Please use src2/core/models/listState/hybrid/HybridList.js
   *
   * @ngdoc factory
   * @name ListState
   * @description class for representing the hybrid state of Lists
   */
  angular
    .module('binky.core.common.list.state.hybrid')
    .factory('HybridListState', getHybridListState);

  /* @ngInject */
  function getHybridListState(AlphaListState, JIL_CONSTANTS) {
    class HybridListState extends AlphaListState {
      /**
       * @class
       * @description Constructor for HybridListState model Objects.
       * @param {Object} [options] - options object
       */
      constructor(options = {}) {
        super(options);

        this.itemCount = 0;
        this.pageCount = 0;
      }

      goToNextPage() {
        if (this.filterQuery.length < this.filterQueryMinLength) {
          super.goToNextPage();
        } else {
          this.setPage(this.pageNumber + 1);
        }
      }

      goToPreviousPage() {
        if (this.filterQuery.length < this.filterQueryMinLength) {
          super.goToPreviousPage();
        } else {
          this.setPage(this.pageNumber - 1);
        }
      }

      hasNextPage() {
        return this.filterQuery.length < this.filterQueryMinLength
          ? super.hasNextPage()
          : this.pageNumber < this.pageCount;
      }

      hasPreviousPage() {
        return this.filterQuery.length < this.filterQueryMinLength
          ? super.hasPreviousPage()
          : this.pageNumber > 1;
      }

      setPage(newPage) {
        if (newPage > 0 && newPage !== this.pageNumber) {
          // valid page (starts at zero and not same page that is currently selected)
          this.pageNumber = newPage;
        }
      }

      setResponseAndHeaders(response, headers) {
        if (this.filterQuery.length >= this.filterQueryMinLength) {
          this.itemCount = parseInt(headers(JIL_CONSTANTS.HEADERS.TOTAL_COUNT), 10) || 0;
          this.pageCount = parseInt(headers(JIL_CONSTANTS.HEADERS.PAGE_COUNT), 10) || 0;
        }
        super.setResponseAndHeaders(response, headers);
      }

      toQueryParams() {
        // ACP paging does not support searching so we should call index paging api when searching
        return this.filterQuery.length < this.filterQueryMinLength
          ? super.toQueryParams()
          : {
              page: this.pageNumber - 1,
              page_size: this.pageSize,
              search_query: this.filterQuery,
              sort: this.sortKey,
              sort_order: this.sortReverse ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
            };
      }
    }

    return HybridListState;
  }
})();
