(function () {
  'use strict';

  /**
   * @deprecated ported to src2 or no longer required
   *
   * @description formats and returns a person's name. Uses firstName and lastName if provided,
   *   otherwise falls back to lastName only, firstName only, fullName, email or fallbackName
   *   as required. If none of these are provided, returns an empty string.
   *
   * @param {Object} options as described below
   * @param {String} [options.email] the person's email address
   * @param {String} [options.fallbackName] a fallback name to use if no other options are
   *   specified, e.g. "Somebody". If not specified, the filter falls back to an empty string.
   * @param {String} [options.firstName] the person's first name
   * @param {String} [options.fullName] the person's full name
   * @param {String} [options.lastName] the person's last name
   * @param {Boolean} [options.showAdobeSuffix] true if "(Adobe)" should be appended to the end of
   *   the returned string; defaults to false.
   * @param {String} [options.userId] the IMS user ID of the user whose name is being formatted. If
   *   it natches the logged in user's ID, then "(you)" is appended to the returned string.
   * @returns {String} the formatted name string
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.common.person-name').filter('binkyPersonName', filter);

  /* @ngInject */
  function filter($translate, AuthenticatedUser, locale) {
    return filterFn;

    function filterFn(options) {
      const opts = options || {};
      const name = getName(opts);

      if (name) {
        if (isLoggedInUser(opts.userId)) {
          return $translate.instant('binky.widgets.common.personName.youSuffix', {name});
        }
        if (opts.showAdobeSuffix) {
          return $translate.instant('binky.widgets.common.personName.adobeSuffix', {name});
        }
      }

      return name;
    }

    /////////////

    function getName({fallbackName, firstName, fullName, lastName, email}) {
      if (firstName && lastName) {
        return locale.isSurnameFirstNameLocale()
          ? `${lastName} ${firstName}`
          : `${firstName} ${lastName}`;
      }
      return lastName || firstName || fullName || email || fallbackName || '';
    }

    function isLoggedInUser(userId) {
      const loggedInUserId = AuthenticatedUser.get().getId();
      return loggedInUserId ? userId === loggedInUserId : false;
    }
  }
})();
