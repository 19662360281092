(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilProducts.js
   *
   * @ngdoc service/provider
   * @name jilProducts
   * @description defines service to manage jil product resources
   */
  angular.module('binky.core.api.jil').provider('jilProducts', jilProductsProvider);

  let url;

  /* @ngInject */
  function jilProductsProvider(_) {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        licenseGroupAdmins: getLicenseGroupAdminsResource(),
        licenseGroupConfigurations: getLicenseGroupConfigurationsResource(),
        licenseGroupMembersConfiguration: getLicenseGroupMembersConfigurationResource(),
        licenseGroupPermissions: getLicenseGroupPermissionsResource(),
        licenseGroups: getLicenseGroupsResource(),
        licenseGroupUsers: getLicenseGroupUsersResource(),
        marketingCloudProductSolutionGroups: getMCProductSolutionGroupsResource(),
        productAdmins: getProductAdminsResource(),
        productLicenseQualityUsers: getProductUsersByLicenseQuality(),
        productNoAccessUsers: getProductNoAccessUsersResource(),
        productProvisioningStatusUsers: getProductProvisioningStatusUsersResource(),
        products: getProductsResource(),
        productUsers: getProductUsersResource(),
      };

      return service;

      ///////////

      function getLicenseGroupAdminsResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:groupId/admins`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getLicenseGroupConfigurationsResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:groupId/configurations`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            patch: {
              method: 'PATCH',
            },
            put: {
              method: 'PUT',
            },
          }
        );
      }

      function getLicenseGroupMembersConfigurationResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:groupId/member-configuration`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            patch: {
              method: 'PATCH',
            },
          }
        );
      }

      function getLicenseGroupPermissionsResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:groupId/permissions`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            patch: {
              method: 'PATCH',
            },
            put: {
              method: 'PUT',
            },
          }
        );
      }

      function getLicenseGroupsResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:id`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            create: {
              method: 'POST',
            },
            exportDevices: {
              headers: {
                Accept: 'text/csv+license-group-devices,application/json',
              },
              method: 'GET',
              params: {
                id: '@id',
                orgId: apiUtils.getActiveOrg,
                productId: '@productId',
              },
              transformResponse: apiUtils.csvBlobTransformer,
              url: `${url}/:orgId/products/:productId/license-groups/:id/devices`,
            },
            update: {
              method: 'PUT',
            },
          }
        );
      }

      function getLicenseGroupUsersResource() {
        return $resource(
          `${url}/:orgId/products/:productId/license-groups/:groupId/users`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            bulkAdd: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'lg-add-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkRemove: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'lg-remove-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            export: {
              headers: {
                Accept: 'text/csv+license-group-users,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            exportDeviceUsers: {
              headers: {
                Accept: 'text/csv+license-group-device-users,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }

      function getProductAdminsResource() {
        return $resource(
          `${url}/:orgId/products/:productId/admins`,
          {
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getProductUsersByLicenseQuality() {
        return $resource(
          `${url}/:orgId/products/:productId/users%3Asearch.license-quality`,
          {
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            export: {
              headers: {
                Accept: 'text/csv+product-user-payment-status-report,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
              url: `${url}/:orgId/products/:productId/users%3Asearch.payment-status-csv`,
            },
            query: {
              isArray: true,
              // Adding custom transformer because 'type' is need for user, and
              // and it is currently not getting returned
              transformResponse(data) {
                const result = angular.fromJson(data);
                return _.map(result, (item) => {
                  if (!item.type) {
                    item.type = 'TYPE1';
                  }
                  return item;
                });
              },
            },
          }
        );
      }

      function getProductProvisioningStatusUsersResource() {
        return $resource(
          `${url}/:orgId/products/:productId/users%3Asearch.provisioning-status`,
          {
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            export: {
              headers: {
                Accept: 'text/csv+product-user-no-access-report,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
              url: `${url}/:orgId/products/:productId/users%3Asearch.no-access-status`,
            },
          }
        );
      }

      function getProductNoAccessUsersResource() {
        return $resource(`${url}/:orgId/products/:productId/users%3Asearch.no-access-code`, {
          orgId: apiUtils.getActiveOrg,
          productId: '@productId',
        });
      }

      function getProductsResource() {
        return $resource(
          `${url}/:orgId/products/:id`,
          {
            id: '@id',
            include_created_date: true,
            include_groups_quantity: false,
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
            export: {
              headers: {
                Accept: 'text/csv+product,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            exportDevices: {
              headers: {
                Accept: 'text/csv+product-devices,application/json',
              },
              method: 'GET',
              params: {
                id: '@id',
              },
              transformResponse: apiUtils.csvBlobTransformer,
              url: `${url}/:orgId/products/:id/devices`,
            },
            exportLicenseDeficitReport: {
              headers: {
                Accept: 'text/csv+license-deficit-report,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            update: {
              method: 'PUT',
            },
          }
        );
      }

      function getProductUsersResource() {
        return $resource(
          `${url}/:orgId/products/:productId/users`,
          {
            orgId: apiUtils.getActiveOrg,
            productId: '@productId',
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getMCProductSolutionGroupsResource() {
        return $resource(`${url}/:orgId/products/:productId/solution-groups`, {
          orgId: apiUtils.getActiveOrg,
          productId: '@productId',
        });
      }
    }
  }
})();
