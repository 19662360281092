(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/panels/modal-dialog/ModalDialog.jsx
   *
   * @ngdoc component
   * @name binky.shell.panels.modal:binkyModal
   *
   * @description The modal
   * @param {String} [backgroundClass] Used to make the header a banner. Pass in the class name that has the background image/height set since cannot pass images through name/path.
   *    Cannot use header normally if backgroundClass is set.
   * @param {String} [backdrop] Can be set to ['none', 'modal', 'static] for dialog variant. Default 'modal' for dialog variant. Always 'none' for fullscreen variant,
   *    none: No backdrop.
   *    modal: A backdrop that hides the dialog when clicked.
   *    static: A backdrop that does not hide the dialog when clicked.
   * @param {Boolean} [centered] If true, everything within the <content> transclusion area will be centered. Default false.
   * @param {Boolean} [closable] If true, a 'X' icon button w/ coral-close and absolute position is displayed in upper-right corner. Default is false.
   * @param {Object|DisplayMessage} [displayMessage] The dictionary {messageId: DisplayMessage} (key is a messageId, value is a DisplayMessage object) or just a DisplayMessage object which encapsulate(s) all parameters of pageBanner.
   * @param {string} [focusOnShow] Css selector for focusable object to focus when dialog is shown. If not specified,
   *    or selector doesn't match any elements, the first focusable element in the dialog will receive focus.
   * @param {String} [interaction] Can be set to 'off' to stop keyboard interaction. Default 'on'.
   * @param {Boolean} [isPersistent] If true, transcluded content will be initialized once on instantiation and remain in the DOM until
   *    the modal is destroyed. Useful in cases where event listeners are required. Default is false.
   * @param {String} [modalClass] The class to assign to the modal
   * @param {String} [modalContext] The context of the modal.
   * @param {String} modalId The ID to assign to the modal.
   * @param {String} [modalTitleVariant] The icon to be displayed with the modal. Defaults to no icon. Icon can be set to warning, success, info, help, and error.
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onEscape] The callback function to register for a keydown event on the 'Escape' key.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {String} [variant] This controls the styling characteristics of the modal. The options are:
   *    fullpage-fullscreen: 1130px content width which is the maximum available plus a little extra to accommodate use
   *        cases where extra real estate is required.
   *        Clicking outside the content area will not close the modal.
   *    xwide-fullscreen: 900px content width, and places the action buttons at the bottom of the page.
   *        Clicking outside the content area will not close the modal.
   *    wide-fullscreen (default): 722px content width, and places the action buttons at the bottom
   *        of the page. Clicking outside the content area will not close the modal.
   *    narrow-fullscreen: 474px content width, and places the action buttons at the bottom of the page.
   *        Clicking outside the content area will not close the modal.
   *    wide-dialog: uses maximum available content width, and places the action buttons at the bottom of the dialog.
   *        Clicking outside the content area will close the modal.
   *    narrow-dialog: uses a thinner content width, and places the action buttons at the bottom of the dialog.
   *        Clicking outside the content area will close the modal.
   * @param {Transclude} content The modal content. Only added to the DOM when the modal is open.
   * @param {Transclude} footer The modal footer. Only added to the DOM when the modal is open.
   * @param {Transclude} header The modal header. Only added to the DOM when the modal is open.
   * @example
   *  <binky-modal
   *      display-message="myDisplayMessage"
   *      modal-id="myId" on-open="myOpen"
   *      on-close="myClose">
   *    <header>
   *      My Header
   *    </header>
   *    <content>
   *      My Content
   *    </content>
   *    <footer>
   *      My Footer
   *    </footer>
   *  </binky-modal>
   *
   */
  angular.module('binky.shell.panels.modal').component('binkyModal', {
    bindings: {
      backdrop: '@?',
      backgroundClass: '@?',
      centered: '@?',
      closable: '@?', // note there is no 'e' in closable
      displayMessage: '<?',
      focusOnShow: '@?',
      interaction: '@?',
      isPersistent: '<?',
      modalClass: '@?',
      modalContext: '@?',
      modalId: '@',
      modalTitleVariant: '@?',
      onClose: '<?',
      onEscape: '&?',
      onOpen: '<?',
      variant: '@?',
    },
    controller,
    templateUrl: 'shell/panels/modal/modal.component.html',
    transclude: {
      content: 'content',
      footer: '?footer',
      header: '?header',
    },
  });

  /* @ngInject */
  function controller(
    $element,
    $rootScope,
    $scope,
    $transclude,
    _,
    COMMON_EVENTS,
    DisplayMessage,
    PANEL_MANAGER,
    panelManager
  ) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      getDialog,
      getVariantClasses,
      scrollToTop,
      showContent,
    });

    const deregisterEventHandler = $rootScope.$on(COMMON_EVENTS.SCROLL_TO_TOP, (event, modalId) => {
      if (vm.isOpen && vm.modalId === modalId) {
        vm.scrollToTop();
      }
    });

    function $onChanges(changesObj) {
      if (changesObj.displayMessage) {
        const newDisplayMessage = _.get(changesObj, 'displayMessage.currentValue');

        vm.displayMessage =
          newDisplayMessage instanceof DisplayMessage ? {1: newDisplayMessage} : newDisplayMessage;

        // Depending on order and timing, the dialog might not have been created yet.
        _.invoke(getDialog(), 'center');
      }
    }

    function $onInit() {
      panelManager.register(PANEL_MANAGER.TYPE.MODAL, vm.modalId, onOpen, onClose);

      // Wait until the Coral dialog element is constructed so we can find it to attach the optional escape key handler.
      Coral.commons.ready(() => {
        addEventListeners();
        appendTranscludedElements();
        setFocusOnShow();
      });

      vm.variant = vm.variant || 'wide-fullscreen';
      vm.variantClasses = _.split(vm.variant, '-');
      vm.modalTitleVariant = vm.modalTitleVariant || 'default';
      vm.variantClasses.push(vm.modalTitleVariant);
      if (vm.modalClass) {
        vm.variantClasses.push(vm.modalClass);
      }

      const isFullscreen = _.includes(vm.variant, 'fullscreen');
      const dialog = getDialog();
      dialog.fullscreen = isFullscreen;
      // Addresses: https://git.corp.adobe.com/admin-tribe/onesie/issues/8877
      // Backdrop will hide the dialog when clicking outside the content area.
      dialog.backdrop = isFullscreen ? 'none' : vm.backdrop || 'modal';

      dialog.variant = vm.modalTitleVariant;

      // Adds 'X' coral-close button w/ absolute position in upper-right corner.
      if (vm.closable) {
        dialog.closable = 'on';
      }

      if (vm.interaction) {
        dialog.interaction = vm.interaction;
      }
    }

    function $onDestroy() {
      deregisterEventHandler();
      getDialog().remove();
      panelManager.unregister(vm.modalId);
    }

    // Place on scope so accessible to unit tests.
    function getDialog() {
      // Store the dialog in an instance variable, otherwise once it's opened it won't be a child of this element
      vm.dialog = vm.dialog || $element.find('coral-dialog')[0];
      return vm.dialog;
    }

    function onClose(params) {
      vm.isOpen = false;
      getDialog().hide();
      _.invoke(vm, 'onClose', params);
    }

    function onOpen(params) {
      vm.isOpen = true;
      _.invoke(vm, 'onOpen', params);
      getDialog().show();
    }

    // This is on scope to test the event.
    function scrollToTop() {
      getDialog().content.scrollTop = 0;
    }

    function showContent() {
      return vm.isPersistent || vm.isOpen;
    }

    /////////////////

    function addEventListeners() {
      // Using local const since Event.AT_TARGET is not supported by IE11
      const EVENT_PHASE_AT_TARGET = 2;

      // Only respond to events that are triggered on this component directly. Otherwise can get bubbled events from children.
      const dialog = getDialog();
      dialog.addEventListener('coral-overlay:close', (event) => {
        if (event.eventPhase === EVENT_PHASE_AT_TARGET) {
          // Close the panelManager by pressing ESC key will trigger onClose().
          // However, in order to update bindings or watchers inside onClose() functions, $apply() is needed.
          // On the other hand, if onClose() is triggered by `ng-click` event, $http callbacks are all wrapped in $scope.$apply(),
          // we don’t need to call it by ourselves.
          // Thus, by calling $apply() inside binky-modal, it will update the bindings or watchers.
          $scope.$apply(() => {
            panelManager.close(vm.modalId);
          });
        }
      });

      // When there is an escape handler, trap the esc key here and call the callback.
      if (vm.onEscape) {
        dialog.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            // The Overlay and the Dialog each have escape handlers so setting interaction 'off' on the Dialog is not enough.
            event.stopImmediatePropagation();
            vm.onEscape();
          }
        });
      }
    }

    // Manually transclude coral elements to get around issues transcluding into a Coral modal
    // https://git.corp.adobe.com/admin-tribe/onesie/issues/6241
    function appendTranscludedElements() {
      const content = new Coral.Dialog.Content();
      content.appendChild($element[0].querySelector('.modal-content'));
      getDialog().set({content});

      if ($transclude.isSlotFilled('footer')) {
        const footer = new Coral.Dialog.Footer();
        footer.appendChild($element[0].querySelector('.modal-footer'));
        getDialog().set({footer});
      }

      const header = new Coral.Dialog.Header();
      header.appendChild($element[0].querySelector('.modal-header'));
      getDialog().set({header});

      // since header has to be added for the background banner,
      // get rid of extra padding so it doesn't affect spacing for components like video modal
      if (!$transclude.isSlotFilled('header')) {
        const coralHeader = $element[0].querySelector('._coral-Dialog-header');
        coralHeader.classList.add('no-header-padding');
      }
    }

    function setFocusOnShow() {
      if (vm.focusOnShow) {
        getDialog().set({focusOnShow: vm.focusOnShow});
      }
    }

    function getVariantClasses() {
      // return object with variant classes so conditionals can be applied
      const variantClasses = {
        'background-wrapper': vm.backgroundClass,
        'no-header-line': vm.backgroundClass || !$transclude.isSlotFilled('header'),
      };
      _.forEach(vm.variantClasses, (variant) => {
        variantClasses[variant] = true;
      });
      return variantClasses;
    }
  }
})();
