const FULFILLABLE_ITEM_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.fulfillableItems.FULFILLABLE_ITEM_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/model/fulfillableItems/FulfillableItemConstants.js
   */
  angular
    .module('binky.core.offers.fulfillable-items')
    .constant(
      'FULFILLABLE_ITEM_CHARGING_MODEL_TYPE',
      FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_CHARGING_MODEL_TYPE
    )
    .constant(
      'FULFILLABLE_ITEM_CHARGING_UNIT',
      FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_CHARGING_UNIT
    )
    .constant('FULFILLABLE_ITEM_CODE', FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_CODE)
    .constant(
      'FULFILLABLE_ITEM_DELEGATION_TYPE',
      FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_DELEGATION_TYPE
    )
    .constant('FULFILLABLE_ITEM_TYPE', FULFILLABLE_ITEM_CONSTANTS.FULFILLABLE_ITEM_TYPE);
})();
