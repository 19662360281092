import pick from 'lodash/pick';

import collaborationPolicyService from 'api/collaborationPolicyService';
import feature from 'services/feature';
import log from 'services/log';

import CollaborationPoliciesConcurrencyError from './CollaborationPoliciesConcurrencyError';

const CONTENT_AUTHENTICITY = 'contentAuthenticity';
const CONTENT_FILTER_TYPE = 'contentFilter';
const HTTP_STATUS_PRECONDITION_FAILED = 412;
const INVITATION_DOMAIN_ALLOWLIST = 'invitationDomainAllowlist';
const INVITATION_DOMAIN_WHITELIST = 'invitationDomainWhitelist'; // remove with temp_use_allowlist
const PUBLIC_SHARE = 'publicShare';
const REQUEST_ACCESS = 'requestAccess';

const CONTENT_FILTER = {
  ALL_ACCESS: 'allAccess',
  BASIC_ACCESS: 'basicAccess',
  NO_ACCESS: 'noAccess',
};

const INVITATION_DOMAIN_SHARING = {
  ALL: 'all',
  CLAIMED_AND_TRUSTED: 'claimedAndTrusted',
  CLAIMED_AND_TRUSTED_WITH_DOMAINS: 'claimedAndTrustedWithDomains',
  NONE: 'none',
};

const RESTRICTION_STATUS = {
  DOMAIN_USERS: 'DomainUsers',
  // remove with temp_auto_assign_products
  INVITATION_AND_PUBLIC_LINK: 'InvitationAndPublicLink',
  NONE: 'None',
  PUBLIC_LINK: 'PublicLink',
};

class CollaborationPolicies {
  /**
   * @description Method to fetch the CollaborationPolicies.
   * @param {Object} options as described below
   * @param {String} options.orgId - ID of organization for which CollaborationPolicies should be fetched.
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the refresh fails.
   */
  static async get(options) {
    const model = new CollaborationPolicies(options);
    await model.refresh();
    return model;
  }

  /**
   * @description Method to create a new instance of a CollaborationPolicies object.
   * @param {Object} options - options to config the new CollaborationPolicies object.
   * @param {String} options.orgId - associates CollaborationPolicies instance with an organization.
   * @param {Array<Object>} options.policies - the raw policy data to store on this object.
   */
  constructor(options) {
    this.orgId = options.orgId;
    this.#updateModelItems(options.policies);
  }

  /**
   * @description Method to find a specific policy
   * @param {String} [name] - the name of the policy to find
   * @returns {Object} the found policy object, or undefined
   */
  #findPolicy(name) {
    return this.policies.find((policy) => policy.name === name);
  }

  /**
   * @description Method to update the policies
   * @param {Array<Object>} items as described below
   * @param {String} [item.category] - the category of the policy
   * @param {String} [item.defaultValue] - whether the current value is the default. This is not the
   *   default value itself.
   * @param {String} [item.etag] - the etag of the policy. This will be written back on save as an if-match
   * @param {String} [item.name] - the name of the policy
   * @param {String} [item.scope] - the scope of the policy
   * @param {String} [item.scopeId] - the identifier related to the scope. For example, if scope is
   *   'organization', this will be an orgId
   * @param {String} [item.value] - the value of the policy.
   */
  #updateModelItems(items = []) {
    this.policies = items.map((item) =>
      pick(item, ['category', 'defaultValue', 'etag', 'name', 'scope', 'scopeId', 'value'])
    );
  }

  /**
   * @description Method to add to the allowlisted domains
   * @param {Array<String>} [domainsToAdd] - the set of domains to add to the list
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  addAllowlistedDomains(domainsToAdd) {
    const policy = this.#findPolicy(INVITATION_DOMAIN_ALLOWLIST);
    const policyValue = JSON.parse(policy.value);
    if (policyValue) {
      policyValue.domains = [...(policyValue.domains ?? []), ...domainsToAdd];
      policy.value = JSON.stringify(policyValue);
    }
    return this.save();
  }

  /**
   * @description Method to add to the whitelisted domains
   * @param {Array<String>} [domainsToAdd] - the set of domains to add to the list
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  addWhitelistedDomains(domainsToAdd) {
    // remove this method with temp_use_allowlist
    const policy = this.#findPolicy(INVITATION_DOMAIN_WHITELIST);
    const policyValue = JSON.parse(policy.value);
    policyValue.domains = [...policyValue.domains, ...domainsToAdd];
    policy.value = JSON.stringify(policyValue);
    return this.save();
  }

  /**
   * @description Method to fetch the allowlisted domains
   * @returns {Array<String>} the allowlisted domains
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  getAllowlistedDomains() {
    const policy = this.#findPolicy(INVITATION_DOMAIN_ALLOWLIST);
    const policyValue = JSON.parse(policy.value);
    return policyValue?.domains;
  }

  /**
   * @description Method to fetch the request access status policy value
   * @returns {Boolean | String} the request access status policy value OR the expanded content authenticity policy value (will always be string once temp_newcapolicy is removed)
   * @throws an error if parsing policy value to JSON fails
   */
  getContentAuthenticityEnabled() {
    const policy = this.#findPolicy(CONTENT_AUTHENTICITY);
    if (feature.isEnabled('temp_newcapolicy')) {
      return JSON.parse(policy.value);
    }
    return policy.value === 'true';
  }

  /**
   * @description Method to fetch the content filter policy value. See
   * https://wiki.corp.adobe.com/display/acp/COLLAB-4505%3A+%5Bsolution%5D+SHARING%3A+Policy+service+support+for+new+content+filtering+policy
   * @returns {Boolean} the request access status policy value
   */
  getContentFilter() {
    const policy = this.#findPolicy(CONTENT_FILTER_TYPE);
    return JSON.parse(policy.value);
  }

  /**
   * @description Method to fetch the request access status policy value
   * @returns {Boolean} the request access status policy value
   */
  getRequestAccessEnabled() {
    const policy = this.#findPolicy(REQUEST_ACCESS);
    return policy.value === 'true';
  }

  /**
   * @description Method to fetch the request access status policy value
   * @returns {Boolean} the request access status policy value
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  getRestrictionStatus() {
    const publicSharePolicy = this.#findPolicy(PUBLIC_SHARE);
    const invitationDomainWhitelistPolicy = feature.isEnabled('temp_use_allowlist')
      ? this.#findPolicy(INVITATION_DOMAIN_ALLOWLIST)
      : this.#findPolicy(INVITATION_DOMAIN_WHITELIST);
    const invitationDomainWhitelistPolicyValue = JSON.parse(invitationDomainWhitelistPolicy.value);
    if (
      publicSharePolicy.value === 'true' &&
      invitationDomainWhitelistPolicyValue?.allow === INVITATION_DOMAIN_SHARING.ALL
    ) {
      return RESTRICTION_STATUS.NONE;
    }
    if (
      publicSharePolicy.value === 'false' &&
      invitationDomainWhitelistPolicyValue?.allow === INVITATION_DOMAIN_SHARING.ALL
    ) {
      return RESTRICTION_STATUS.PUBLIC_LINK;
    }
    if (
      publicSharePolicy.value === 'false' &&
      invitationDomainWhitelistPolicyValue?.allow ===
        INVITATION_DOMAIN_SHARING.CLAIMED_AND_TRUSTED_WITH_DOMAINS
    ) {
      return RESTRICTION_STATUS.DOMAIN_USERS;
    }
    return null;
  }

  /**
   * @description Method to fetch the whitelisted domains
   * @returns {Array<String>} the whitelisted domains
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  getWhitelistedDomains() {
    // remove this method with temp_use_allowlist
    const policy = this.#findPolicy(INVITATION_DOMAIN_WHITELIST);
    const policyValue = JSON.parse(policy.value);
    return policyValue.domains;
  }

  /**
   * @description Method to get the current policies of an organization.
   * @returns {Promise<CollaborationPolicies>} A CollaborationPolicies object or a Reject promise when it cannot be fetched.
   */
  async refresh() {
    try {
      const response = await collaborationPolicyService.getPolicies({
        newContentAuthenticityPolicy: feature.isEnabled('temp_newcapolicy'),
        orgId: this.orgId,
      });
      this.#updateModelItems(response.data);
    } catch (error) {
      log.error(`Could not fetch collaboration policies. Error: ${error}`);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to remove from the allowlisted domains
   * @param {Array<String>} [domainsToRemove] - the set of domains to remove from the list
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  removeAllowlistedDomains(domainsToRemove) {
    const policy = this.#findPolicy(INVITATION_DOMAIN_ALLOWLIST);
    const policyValue = JSON.parse(policy.value);
    // Filter the list to only those not in the list to remove
    if (policyValue) {
      policyValue.domains = (policyValue.domains ?? []).filter(
        (item) => !domainsToRemove.includes(item)
      );
      policy.value = JSON.stringify(policyValue);
    }
    return this.save();
  }

  /**
   * @description Method to remove from the whitelisted domains
   * @param {Array<String>} [domainsToRemove] - the set of domains to remove from the list
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  removeWhitelistedDomains(domainsToRemove) {
    // remove this method with temp_use_allowlist
    const policy = this.#findPolicy(INVITATION_DOMAIN_WHITELIST);
    const policyValue = JSON.parse(policy.value);
    // Filter the list to only those not in the list to remove
    policyValue.domains = policyValue.domains.filter((item) => !domainsToRemove.includes(item));
    policy.value = JSON.stringify(policyValue);
    return this.save();
  }

  /**
   * @description Method to save changes to the policies
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws a CollaborationPoliciesConcurrencyError if any of the policies return with HTTP_STATUS_PRECONDITION_FAILED code
   */
  async save() {
    try {
      const response = await collaborationPolicyService.setPolicies({
        orgId: this.orgId,
        // we write the policies back to the server with the etag expressed as 'ifMatch'
        policies: this.policies.map((policy) => ({
          category: policy.category,
          ifMatch: policy.etag,
          name: policy.name,
          value: policy.value,
        })),
      });
      if (response.data.some((policy) => policy.code === HTTP_STATUS_PRECONDITION_FAILED)) {
        throw new CollaborationPoliciesConcurrencyError(
          'Policy has been updated and local copy needs to be refreshed.'
        );
      }
      this.#updateModelItems(response.data);
    } catch (error) {
      log.error(`Could not update collaboration policies. Error: ${error}`);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to modify and save the Content Authenticity policy
   * @param {Boolean} [contentAuthenticityEnabled] - true if Content Authenticity should be enabled
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   */
  setContentAuthenticityEnabled(contentAuthenticityEnabled) {
    const policy = this.#findPolicy(CONTENT_AUTHENTICITY);
    if (feature.isEnabled('temp_newcapolicy')) {
      policy.value = JSON.stringify(contentAuthenticityEnabled);
    } else {
      policy.value = contentAuthenticityEnabled ? 'true' : 'false';
    }
    return this.save();
  }

  /**
   * @description Method to modify and save the Content Filter policy
   * @param {CONTENT_FILTER} [contentFilter] - the Content Filter value to store
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   */
  setContentFilter(contentFilter) {
    const policy = this.#findPolicy(CONTENT_FILTER_TYPE);
    policy.value = JSON.stringify(contentFilter);
    return this.save();
  }

  /**
   * @description Method to modify and save the Request Access policy
   * @param {Boolean} [requestAccessEnabled] - true if Request Access (for assets) should be enabled
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   */
  setRequestAccessEnabled(requestAccessEnabled) {
    const policy = this.#findPolicy(REQUEST_ACCESS);
    policy.value = requestAccessEnabled ? 'true' : 'false';
    return this.save();
  }

  /**
   * @description Method to modify and save the Public Share and related domains policies
   * @param {RESTRICTION_STATUS} [restrictionStatus] - the level to apply to these policies
   * @returns {Promise<CollaborationPolicies>} This CollaborationPolicies object or a Rejected promise when the update fails.
   * @throws an error if parsing or stringifying the domains to/from JSON fails
   */
  setRestrictionStatus(restrictionStatus) {
    // Perform necessary updates to policy objects to set desired restriction state.
    const publicSharePolicy = this.#findPolicy(PUBLIC_SHARE);
    const invitationDomainWhitelistPolicy = feature.isEnabled('temp_use_allowlist')
      ? this.#findPolicy(INVITATION_DOMAIN_ALLOWLIST)
      : this.#findPolicy(INVITATION_DOMAIN_WHITELIST);
    const invitationDomainWhitelistPolicyValue = JSON.parse(invitationDomainWhitelistPolicy.value);

    if (invitationDomainWhitelistPolicyValue) {
      switch (restrictionStatus) {
        // No restrictions
        case RESTRICTION_STATUS.NONE:
          publicSharePolicy.value = 'true';
          invitationDomainWhitelistPolicyValue.allow = INVITATION_DOMAIN_SHARING.ALL;
          break;
        // No public link sharing
        case RESTRICTION_STATUS.PUBLIC_LINK:
          publicSharePolicy.value = 'false';
          invitationDomainWhitelistPolicyValue.allow = INVITATION_DOMAIN_SHARING.ALL;
          break;
        // Sharing only to domain users
        case RESTRICTION_STATUS.INVITATION_AND_PUBLIC_LINK: // remove with temp_auto_assign_products
        case RESTRICTION_STATUS.DOMAIN_USERS:
          publicSharePolicy.value = 'false';
          invitationDomainWhitelistPolicyValue.allow =
            INVITATION_DOMAIN_SHARING.CLAIMED_AND_TRUSTED_WITH_DOMAINS;
          break;
        // Invalid option
        default:
          throw new Error(`Invalid restriction status: ${restrictionStatus}`);
      }
      invitationDomainWhitelistPolicy.value = JSON.stringify(invitationDomainWhitelistPolicyValue);
    }

    return this.save();
  }
}

export {CONTENT_FILTER, RESTRICTION_STATUS};
export default CollaborationPolicies;
