import ModelList from 'services/modelList/ModelList';

import etlaUsageReport from '../../api/etla-usage-report/etlaUsageReport';

import EtlaUsageReportContractAndProductBundle from './EtlaUsageReportContractAndProductBundle';

class EtlaUsageReportContractAndProductBundleList extends ModelList {
  /**
   * @description Method to fetch the EtlaUsageReportContractAndProductBundleList.
   * @param {Object} options - top level wrapper object.
   * @param {string} options.orgId - Organization id that this list's
   *  events belong to.
   * @returns {EtlaUsageReportContractAndProductBundleList} the fetched EtlaUsageReportContractAndProductBundleList.
   */
  static get(options) {
    const model = new EtlaUsageReportContractAndProductBundleList(options);
    return model.refresh();
  }

  /**
   * @description Constructs EtlaUsageReportContractAndProductBundleList.
   *
   * @param {Object} options - configures the EtlaUsageReportContractAndProductBundleList model
   *  instance.
   * @param {string} options.orgId - Organization id that this list's
   *  EtlaUsageReportContractAndProductBundle belongs to.
   */
  constructor(options = {}) {
    super({
      itemClassRef: EtlaUsageReportContractAndProductBundle,
      resource: etlaUsageReport.getProducts,
    });

    Object.assign(this, {orgId: options.orgId});
  }

  /**
   * @description Method to refresh the EtlaUsageReportContractAndProductBundleList from the backend.
   *
   * @returns {Promise} resolves to EtlaUsageReportContractAndProductBundleList on success, else
   *  rejects with error.
   */
  refresh() {
    return super.refresh({orgId: this.orgId});
  }
}

export default EtlaUsageReportContractAndProductBundleList;
