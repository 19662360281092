const ACTIONBLOCK_ID = 'actionBlockId';
const BANNER_RESPONSE_KEYS = {
  CARD_ID: 'cardID',
};
const CAMPAIGN_ID = 'campaignId';
const CARD_ID = 'cardId';
const CLICKABLE_CARD_LABELS = {
  PRIMARY: 'ctaLabel',
  SECONDARY: 'secondaryCTALabel',
};
const CONTAINER_ID = 'containerId';
const CONTROL_GROUP_ID = 'controlGroupId';
const HVA_KEYS = {
  ID: 'id',
};
const INTERACTION = {
  DISMISS: 'DISMISS',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};
const SOPHIA_EVENT_NAME = {
  SOPHIA_BANNER_CLICK: 'sophiaBannerClick',
  SOPHIA_BANNER_LOAD: 'sophiaBannerLoad',
};
const SURFACE_ID = 'surfaceId';
const TREATMENT_ID = 'treatmentId';
const VARIATION_ID = 'variationId';

export {
  ACTIONBLOCK_ID,
  BANNER_RESPONSE_KEYS,
  CAMPAIGN_ID,
  CARD_ID,
  CLICKABLE_CARD_LABELS,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  HVA_KEYS,
  INTERACTION,
  SOPHIA_EVENT_NAME,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
};
