/* See https://developer.adobe.com/adobe-status/ for more details */

import axios from 'axios';

import authentication from 'services/authentication';

let clientId, url;

/**
 * Configure StatusEvents api
 *
 * @param {Object} config - The configuration object
 * @param {String} config.clientId - The identifier for the application
 * @param {String} config.url - The root url for StatusEvents api
 */
const configure = (config) => {
  ({clientId, url} = config);
};

/**
 * @description Builds the common request configurations of the app
 *
 * @returns {Object} request configuration object to pass into axios
 */
const getHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for Status API call');
  }
  return {
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };
};

/**
 * Fetches list of all ongoing issues and maintenances
 * that are impacting Adobe Products and Services
 *
 * @param {Object} queryParams - The query parameters to use for this call
 * @param {string} queryParams.from - The date from which to get events
 */
const getEvents = async (queryParams) => {
  const response = await axios.get(url, {headers: getHeaders(), params: queryParams});
  return response;
};

/**
 * Fetches list of all ongoing incidents that are
 * impacting Adobe Products and Services
 */
const getIncidents = async () => {
  const response = await axios.get(`${url}/incidents`, {headers: getHeaders()});
  return response;
};

/**
 * Fetches list of all ongoing maintenances that are
 * impacting Adobe Products and Services.
 */
const getMaintenances = async () => {
  const response = await axios.get(`${url}/maintenance`, {headers: getHeaders()});
  return response;
};

/**
 * View all upcoming scheduled maintenances that are
 * impacting Adobe Products and Services.
 */
const getScheduledMaintenances = async () => {
  const response = await axios.get(`${url}/scheduled`, {headers: getHeaders()});
  return response;
};

const statusEvents = {
  configure,
  getEvents,
  getIncidents,
  getMaintenances,
  getScheduledMaintenances,
};

export default statusEvents;
