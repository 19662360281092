import {AvailableTypeList} from '@admin-tribe/binky';
import {Content} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getDisallowedTypeMessage} from 'common/utils/global-admin/globalAdminUtils';

const DisallowedTypeMessage = ({availableTypeList}) => {
  const intl = useIntl();
  return <Content>{getDisallowedTypeMessage({availableTypeList, intl})}</Content>;
};

DisallowedTypeMessage.propTypes = {
  availableTypeList: PropTypes.instanceOf(AvailableTypeList).isRequired,
};

export default DisallowedTypeMessage;
