import get from 'lodash/get';

import ims from 'api/ims';
import jilContracts from 'api/jil/jilContracts';
import log from 'services/log';

class ContractTerms {
  /**
   * @description Method to fetch the ContractTerms for the active org.
   *
   * @param {Object} options - configures the ContractTerms model instance.
   * @param {String} [options.orgId] - associates ContractTerms instance with an org.
   * @param {String} [options.serviceName] the contract terms service name to fetch
   * @returns {ContractTerms} the found ContractTerms state
   */
  static get(options) {
    const model = new ContractTerms(options);
    // Caching would be handled here
    model.refresh(); // don't wait on async function here, else Promise returned, instead of instance
    return model;
  }

  /**
   * @class
   * @description Creates a new ContractTerms object for use.
   *
   * @param {Object} options - configures the ContractTerms model instance.
   * @param {String} [options.orgId] - associates ContractTerms instance with an org.
   * @param {String} options.serviceName the contract terms service name to fetch
   */
  constructor(options) {
    this.orgId = options.orgId;
    this.serviceName = options.serviceName;
    // Default to true for cases when we error out querying the T&C status, to unblock admins
    this.current = true;
  }

  /**
   * @description Accept the current terms and conditions.
   *
   * @param {Contract} [contract] the contract to accept terms for.
   *
   * @returns {Promise} resolved when acceptance completes.
   */
  acceptTerms(contract) {
    const shouldAcceptJILTerms =
      contract && (contract.isVIPMPContract() || (contract.isIndirectContract() && !this.accepted));
    if (shouldAcceptJILTerms) {
      return acceptJILTerms({contractId: contract.id, orgId: this.orgId});
    }
    return acceptIMSTerms({orgId: getIMSOrgId(this.orgId), serviceName: this.serviceName});

    ////////

    async function acceptIMSTerms(params) {
      const response = await ims.acceptTermsAndConditions(params);
      if (response?.data?.accepted === true) {
        return response;
      }
      return onError.call(this, response);
    }

    function acceptJILTerms({contractId, orgId}) {
      // Sets the contract owner (enrollee) on the first time accepting the
      // contract if the owner is not already defined
      return jilContracts.putContractAcceptTerms({contractId, orgId}, undefined);
    }

    function onError(error) {
      log.error(
        `ContractTerms.acceptTerms(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }
  }

  /**
   * @description Returns the T&C content to display. This will only be
   *   populated if acceptance is required.
   *
   * @returns {String} the Terms and Conditions content
   */
  getContent() {
    return this.tc ? get(this.tc[0], 'text') : undefined;
  }

  /**
   * @description Returns whether the T&Cs are current.
   *
   * @returns {Boolean} true if current, false if acceptance required
   */
  getCurrent() {
    return this.current;
  }

  /**
   * @description Returns whether the current user can re-accept the terms
   *
   * @returns {Boolean} true if the active user can accept, false if not
   */
  getMayAccept() {
    return this.mayAccept;
  }

  /**
   * @description Returns whether the current user must re-accept the terms
   *
   * @returns {Boolean} true if the active user can must accept, false if not
   */
  mustAcceptTerms() {
    return !this.getCurrent() && this.getMayAccept();
  }

  /**
   * @description Fetch the current acceptance state of the terms and conditions.
   */
  async refresh() {
    const termsAndConditionsAcceptance = await ims.getTermsAndConditionsAcceptance({
      orgId: getIMSOrgId(this.orgId),
      serviceName: this.serviceName,
    });
    Object.assign(this, termsAndConditionsAcceptance.data);

    // if the terms require acceptance, and the user can accept, we fetch them
    if (this.mustAcceptTerms()) {
      const contentResponse = await ims.getTermsAndConditionsContent({
        serviceName: this.serviceName,
      });
      Object.assign(this, contentResponse.data);
    }

    return this;
  }
}

/**
 * @param {String} orgId
 * @returns The orgId stripped of the '@AdobeOrg' for the IMS API
 */
function getIMSOrgId(orgId) {
  return orgId.split('@')[0];
}

export default ContractTerms;
