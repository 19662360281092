import {
  LicenseGroup,
  Product,
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  UserGroup,
  toBinkyLicenseGroup,
  toBinkyProduct,
  toBinkyUserGroup,
} from '@admin-tribe/binky';
import {Flex, ProgressCircle, View} from '@adobe/react-spectrum';
import {ITEM_TYPE} from '@pandora/react-assignment-modal';
import {
  AssignmentModalSection,
  AssignmentModalSectionContentModel,
  AssignmentSectionContext,
} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  ProductRoleMemberContext,
  ProductRolePickerContentModel,
  ProductRolePickerSection,
} from '@pandora/react-mini-cards';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from 'common/components/add-user-form-table/utils/MemberAndSelectedItemsConstants';
import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';
import {
  toPandoraProductTargets,
  toPandoraUserGroupTargets,
} from 'common/components/assignment-section/assignmentSectionUtils';
import LinkButton from 'common/components/link-button/LinkButton';
import TitledSection from 'common/components/titled-section/TitledSection';
import UserPicker from 'common/components/user-picker/UserPicker';

import AddUserForm from '../add-user-form/AddUserForm';

import styles from './AddUserFormTable.pcss';
import useAddUserFormTable from './useAddUserFormTable';

const {LOADING_STATUS} = MEMBER_AND_SELECTED_ITEMS_CONSTANTS;

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-add-user-form-table
 */

/**
 * Represents a collection of AddUserForms, for adding multiple users to an organization
 */
const AddUserFormTable = observer(
  ({
    // Using this odd name to emphasize it expects Pandora models despite being a Binky-UI component.
    createPandoraAssignmentSectionContext,
    maxMemberListSize,
    isSystemAdmin,
    onChange,
    orgId,
    pickerType = UserPicker.PICKER_TYPE.USERS_AND_GROUPS,
    searchType,
    targetOptions,
    targets,
    titleTextId = 'binky.common.addUserFormTable.title.user',
    validateSelectedMemberForItem,
  }) => {
    const intl = useIntl();

    const {
      changeFormValueForId,
      changePandoraProductRolesForId,
      memberAndSelectedItemsList,
      removeMemberAndSelectedItemsForId,
      changeItemForId,
    } = useAddUserFormTable({
      defaultMemberListSize: 2,
      maxMemberListSize,
      validateSelectedMemberForItem,
    });
    const assignmentModalSectionContent = useContentEntry(AssignmentModalSectionContentModel);
    const productRolePickerContent = useContentEntry(ProductRolePickerContentModel);
    const productTargets = toPandoraProductTargets(targets);
    const userGroupTargets = toPandoraUserGroupTargets(targets);

    React.useEffect(() => {
      if (!memberAndSelectedItemsList.isLoading) {
        onChange(memberAndSelectedItemsList);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Triggers onChange only when memberAndSelectedItemsList are updated.
    }, [memberAndSelectedItemsList, memberAndSelectedItemsList.isLoading]);

    const productAssignmentModalOnChange = useCallback(
      (memberAndSelectedItems) => (pandoraSelectedItemsMap) => {
        const changeItemMethod = changeItemForId(memberAndSelectedItems.id);
        const binkySelectedItems = [
          // Only change the product and profile selected-items.
          ...pandoraSelectedItemsMap[ITEM_TYPE.PRODUCTS].map((product) => toBinkyProduct(product)),
          ...pandoraSelectedItemsMap[ITEM_TYPE.PRODUCT_PROFILES].map((profile) =>
            toBinkyLicenseGroup(profile)
          ),
          ...memberAndSelectedItems.getSelectedItems(UserGroup),
        ];
        changeItemMethod(binkySelectedItems);
      },
      [changeItemForId]
    );

    const userGroupAssignmentModalOnChange = useCallback(
      (memberAndSelectedItems) => (pandoraSelectedItemsMap) => {
        const changeItemMethod = changeItemForId(memberAndSelectedItems.id);
        const binkySelectedItems = [
          // Only change the user-group selected-items.
          ...memberAndSelectedItems.getSelectedItems(Product),
          ...memberAndSelectedItems.getSelectedItems(LicenseGroup),
          ...pandoraSelectedItemsMap[ITEM_TYPE.USER_GROUPS].map((userGroup) =>
            toBinkyUserGroup(userGroup)
          ),
        ];
        changeItemMethod(binkySelectedItems);
      },
      [changeItemForId]
    );

    const renderAssignmentSections = (memberAndItems) => (
      <AssignmentSectionContext.Provider
        // We cannot convert Binky Products to Pandora Products here, this gets run
        // every render, and the conversion is too heavy-weight.
        value={createPandoraAssignmentSectionContext(memberAndItems.member)}
      >
        <Flex direction="column" gap="size-200" marginTop="size-200">
          {productTargets.length > 0 && (
            <AssignmentModalSection
              content={assignmentModalSectionContent}
              isDisabled={!memberAndItems.member}
              member={memberAndItems.member}
              onChange={productAssignmentModalOnChange(memberAndItems)}
              onProductRolesChange={changePandoraProductRolesForId(memberAndItems.id)}
              orgId={orgId}
              showButtonLabel
              targets={productTargets}
            />
          )}
          {userGroupTargets.length > 0 && (
            <AssignmentModalSection
              content={assignmentModalSectionContent}
              isDisabled={!memberAndItems.member}
              member={memberAndItems.member}
              onChange={userGroupAssignmentModalOnChange(memberAndItems)}
              orgId={orgId}
              showButtonLabel
              targets={userGroupTargets}
            />
          )}
        </Flex>
      </AssignmentSectionContext.Provider>
    );

    return (
      <SpectrumV2Provider className={styles['background-transparent']}>
        <div data-testid="add-user-form-table">
          {memberAndSelectedItemsList.items.map((memberAndItems, i) => {
            const titleLabel = intl.formatMessage({id: titleTextId}, {index: i + 1});
            return (
              <TitledSection
                key={memberAndItems.id}
                dividerPlacement={i > 0 ? TitledSection.DIVIDER_PLACEMENT.TOP : 'NONE'}
                rightHeaderContent={
                  memberAndItems.isEmpty() ? undefined : (
                    <LinkButton
                      ariaLabel={intl.formatMessage(
                        {id: 'binky.common.addUserFormTable.remove.ariaLabel'},
                        {item: titleLabel}
                      )}
                      onClick={removeMemberAndSelectedItemsForId(memberAndItems.id)}
                    >
                      {intl.formatMessage({id: 'binky.common.addUserFormTable.remove'})}
                    </LinkButton>
                  )
                }
                title={titleLabel}
              >
                <AddUserForm
                  id={memberAndItems.id}
                  inputMessage={memberAndItems.getFirstValidationMessage(pickerType, intl)}
                  isSystemAdmin={isSystemAdmin}
                  onFormChange={(formValue) =>
                    // only fetch member details when assignment section is displayed so it can list existing products, user groups, or profiles.
                    changeFormValueForId(memberAndItems.id)({
                      ...formValue,
                      fetchMemberDetails: !!targets,
                    })
                  }
                  orgId={orgId}
                  pickerType={pickerType}
                  searchType={searchType}
                  targetOptions={targetOptions}
                />
                <View marginBottom="size-200">
                  <ProductRoleMemberContext.Provider value={{member: memberAndItems.member}}>
                    <ProductRolePickerSection
                      content={productRolePickerContent}
                      onProductRoleChange={(role) =>
                        changePandoraProductRolesForId(memberAndItems.id)([role])
                      }
                      orgId={orgId}
                    />
                    {targets &&
                      (memberAndItems.isStatusLoading(
                        LOADING_STATUS.FETCHING_USER_OR_USER_GROUP_DETAILS
                      ) ? (
                        <ProgressCircle
                          aria-label={intl.formatMessage({
                            id: 'binky.common.addUserFormTable.loading',
                          })}
                          isIndeterminate
                          size="S"
                        />
                      ) : (
                        renderAssignmentSections(memberAndItems)
                      ))}
                  </ProductRoleMemberContext.Provider>
                </View>
              </TitledSection>
            );
          })}
        </div>
      </SpectrumV2Provider>
    );
  }
);

AddUserFormTable.propTypes = {
  /**
   * Callback function to create assignment section context based on the selected org user
   * (no longer used but leaving definition until usages are removed from onesie as part of ONESIE-38454)
   */
  createAssignmentSectionContext: PropTypes.func,
  /**
   * Callback function to create assignment section context with Pandora data models based on the selected org user
   */
  createPandoraAssignmentSectionContext: PropTypes.func,
  /**
   * Whether the current user is a system admin
   */
  isSystemAdmin: PropTypes.bool,
  /**
   * The maximum number of users to allow in the form
   */
  maxMemberListSize: PropTypes.number,
  /**
   * Handler that is called when any field in the array of forms has changed
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The ID of the organization
   */
  orgId: PropTypes.string.isRequired,
  /**
   * Defines what entity types should be shown in the UserPicker search.
   */
  pickerType: PropTypes.oneOf(Object.values(UserPicker.PICKER_TYPE)),
  /**
   * Defines whether existing or new entities should be shown in the UserPicker search.
   */
  searchType: PropTypes.oneOf(Object.values(UserPicker.SEARCH_TYPE)).isRequired,
  /**
   * UserPicker filtering target settings.
   */
  targetOptions: PropTypes.shape({
    searchTargetType: PropTypes.oneOf(Object.values(SEARCH_TARGET_RESULT_TYPE)),
    targetClass: PropTypes.oneOf(Object.values(SEARCH_TARGET_CLASS)),
    targetId: PropTypes.string,
    targetParentId: PropTypes.string,
  }),
  /**
   * Prop passed on to AssignmentSection.
   */
  targets: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TARGET_TYPE))),
  /**
   * The title to display above each Form. Defaults to render "User #"
   */
  titleTextId: PropTypes.string,
  /**
   * Callback function called when values are filled out in an AddUserForm.
   * Allows outside classes to add their own unique validations to AddUserForm.
   */
  validateSelectedMemberForItem: PropTypes.func,
};

export default AddUserFormTable;
