/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name binky.widgets.products:binkyProductLink
 *
 * @description A product link, which will inject in any required state
 *
 * @param {Object} context. A map of key value pairs to swap into the link, if needed
 * @param {Object} link. The product link object to render
 * @param {String} type. The type of link to render, either 'alias' or 'button'
 * @param {String} variant. The variant, only applicable to a button type
 */
(function () {
  'use strict';
  angular.module('binky.widgets.products.product-link').component('binkyProductLink', {
    bindings: {
      context: '<?',
      link: '<?',
      type: '@?',
      variant: '@?',
    },
    controller,
    templateUrl: 'widgets/products/product-link/product-link.component.html',
  });

  /* @ngInject */
  function controller($element, _, AnalyticsEvent) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getHref,
      linkClickEvent,
    });

    function $onInit() {
      // we need to wait for Coral to finish before setting the text
      Coral.commons.ready(() => {
        $element.find('coral-anchorbutton-label').text(_.get(vm, 'link.text'));
      });
    }

    function getHref() {
      return formatUrl(_.get(vm, 'link.href'));
    }

    /**
     * @description Helper function to format the product URL.
     * @param {String} url - unformatted url returned by API (with '{tenantID}').
     * @returns {String} formattedUrl - url formatted with activeOrg tenantID (for DMA products).
     */
    function formatUrl(url) {
      let returnUrl = url;
      _.forEach(vm.context, (value, key) => {
        returnUrl = _.includes(url, `{{${key}}}`) ? _.replace(url, `{{${key}}}`, value) : url;
      });
      return returnUrl;
    }

    function linkClickEvent($event) {
      triggerAnalytics();
      $event.stopPropagation();
    }

    function triggerAnalytics() {
      const attributes = {};
      attributes.clickedLinkHref = vm.link.href;
      attributes.clickedLinkText = vm.link.text;
      AnalyticsEvent.dispatch({
        attributes,
        componentMethod: 'goToLink',
        componentMethodType: 'click',
        componentName: 'binkyProductLink',
      });
    }
  }
})();
