import jilProducts from 'api/jil/jilProducts';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import MemberList from 'services/member/MemberList';

import {PRODUCT_ADMIN_LIST_CACHE_ID, PRODUCT_ADMIN_LIST_EVENT} from './ProductAdminListConstants';

/**
 * @description Method to instantiate a ProductAdminList.
 * @param {Object} options - Options for the constructor.
 * @param {String} options.productId The product id.
 * @param {Array} [options.roles] roles. If product has 'isProductSupportRoleAssignmentAllowedArray'
 *  FI this is an array of roles for admin. Options are PRODUCT_ADMIN and PRODUCT_SUPPORT_ADMIN.
 *  If product doesn't have the FI it should be undefined.
 * @returns {ProductAdminList} orgList - new ProductAdminList.
 */
class ProductAdminList extends MemberList {
  /**
   * @description Constructor for LicenseGroupAdminList model Objects.
   */
  constructor({productId, roles, ...options}) {
    super({
      eventUpdateCount: PRODUCT_ADMIN_LIST_EVENT.UPDATE.COUNT,
      eventUpdateList: PRODUCT_ADMIN_LIST_EVENT.UPDATE.LIST,
      itemClassRef: OrganizationUser,
      modelCacheId: PRODUCT_ADMIN_LIST_CACHE_ID,
      refreshResource: jilProducts.getProductAdmins,
      saveResource: jilProducts.patchProductAdmins,
      ...options,
    });

    this.roles = roles;
    this.productId = productId;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      productId: this.productId,
      roles: this.roles?.join(','),
    };
  }

  /**
   * @description Method to save changes to admin list to back-end.
   *   In Admin Console, admins are added and edited using OrganizationUser.save().
   * @param {Object} [options] - options for saving to pass thru to MemberList.save()
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to true.
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message.
   */
  save(options) {
    return super.save(getSaveParams(this), options);
  }
}

///////////

/**
 * @description Fetch params used for this call.
 *
 * @param {Object} model the model to pick params from.
 * @returns {Array} params to be passed to JIL or used as a key.
 */
function getSaveParams(model) {
  return {
    productId: model.productId,
    roles: model.roles?.join(','),
  };
}

export default ProductAdminList;
