(function () {
  /**
   * @ngdoc module
   * @name binky.core.common.member
   * @description Module which contains member related services
   */
  angular.module('binky.core.common.member', [
    'binky.core.common.list',
    'binky.core.common.member.type',
    'binky.core.common.model',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
