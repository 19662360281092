(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/workspace/page/Page.jsx
   */
  angular.module('binky.shell.workspace.page').component('binkyPage', {
    bindings: {
      waitOnPage: '<?',
    },
    controller,
    templateUrl: 'shell/workspace/page/page.component.html',
    transclude: {
      'body-area': 'bodyArea',
      'header-area': '?headerArea',
      'nav-area': '?navArea',
    },
  });

  /* @ngInject */
  function controller($element, $q, waitOnError) {
    const vm = this;

    /** Bindable members */
    vm.$onInit = $onInit;

    /////////
    function $onInit() {
      if (vm.waitOnPage) {
        vm.waitOnPage.catch((error) => {
          vm.isBumpered = true;
          vm.bumperStrings = waitOnError.getBumperStrings(error);
        });
      } else {
        vm.waitOnPage = $q.resolve();
      }
    }
  }
})();
