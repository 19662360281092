(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilConstants.js
   *
   * @ngdoc constant
   * @name JIL_CONSTANTS
   * @description defines constants used in making api calls to JIL
   */
  angular.module('binky.core.api.jil').constant(
    'JIL_CONSTANTS',
    // eslint-disable-next-line angular/window-service
    window.binkySrc2.api.jil.JIL_CONSTANTS
  );
})();
