(function () {
  /**
   * @awaitingdeprecation
   */
  angular
    .module('binky.core.extended-user-profile.ready')
    .provider('extendedUserProfileReady', extendedUserProfileReady);

  /* @ngInject */
  function extendedUserProfileReady(_, configurationReadyProvider) {
    const ngInjector = angular.injector(['ng']);
    const $q = ngInjector.get('$q');
    const configDeferred = $q.defer();

    let extendedUserProfileClassname;

    this.configure = configure;
    this.whenConfigReady = whenConfigReady;
    this.$get = $get;

    ////////////

    function configure() {
      configurationReadyProvider
        .whenConfigReady()
        .then((configData) => {
          const extendedProfileConfig = _.get(configData, 'ready.extendedUserProfile');
          if (extendedProfileConfig) {
            extendedUserProfileClassname = extendedProfileConfig.classname;
          } else {
            configDeferred.reject('No classname found');
          }
          configDeferred.resolve();
        })
        .catch(configDeferred.reject);

      return configDeferred.promise;
    }

    function whenConfigReady() {
      return configDeferred.promise;
    }

    ////////////

    /* @ngInject */
    function $get($injector, apiReady, authenticationReady, orgReady, OrganizationList, feature) {
      const runDeferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        const apiReadyPromise = apiReady.whenRunReady();
        const authReadyPromise = authenticationReady.whenRunReady();
        const orgReadyPromise = orgReady.whenRunReady();

        apiReadyPromise
          .then(() => authReadyPromise)
          .then(() => orgReadyPromise)
          .then(() => {
            if (feature.isEnabled('temp_adobe_agent_access')) {
              const AuthenticatedUser = $injector.get('AuthenticatedUser');
              const user = AuthenticatedUser.get();
              const roles = user.getRoles();
              if (roles.anyForOrg(OrganizationList.getActiveOrgIdFromSessionStorage())) {
                const extendedUserProfile = $injector.get(extendedUserProfileClassname).get();
                // eslint-disable-next-line promise/no-nesting -- have to nest so if the condition that checks against the roles to ensure authentication dependency fails, then still resolve/continue on
                return extendedUserProfile.$promise.then(() => extendedUserProfile.runReady());
              }
              return undefined;
            }

            const ExtendedUserProfileClass = $injector.get(extendedUserProfileClassname);
            const extendedUserProfile = ExtendedUserProfileClass.get();
            return extendedUserProfile.$promise.then(() => extendedUserProfile.runReady());
          })
          .then(() => {
            runDeferred.resolve();
          })
          .catch(runDeferred.reject);

        return runDeferred.promise;
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
