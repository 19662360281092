(function () {
  'use strict';
  /**
   * @deprecated should use Pandora Commerce components
   *
   * @ngdoc filter
   * @name binky.widgets.commerce:binkyContractTaxRate
   * @description Returns the specified tax rate as a string, using the appropriate tax term for the specified
   * country; e.g. 'VAT 8.12%'.
   * @param {Contract} contract model instance.
   * @param {Function} contract.getOwnerCountryCode returns two digit country code, e.g. 'US'.
   * @param {Number} taxRate, e.g. 5.84.
   * @return {String} tax rate, "VAT 8.12%".
   * @example <div> {{ $ctrl.contract | binkyContractTaxRate:$ctrl.taxRate }} </div>
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.widgets.commerce').filter('binkyContractTaxRate', taxRateStringFilter);

  /* @ngInject */
  function taxRateStringFilter($translate, _, commerceUtils) {
    return getTaxRateString;

    ////////////////

    function getTaxRateString(contract, taxRate) {
      const taxTerm = commerceUtils.getTaxTerm(_.invoke(contract, 'getOwnerCountryCode'));
      return _.isNil(taxRate)
        ? $translate.instant(`binky.widgets.commerce.tax.${taxTerm}`)
        : $translate.instant(`binky.widgets.commerce.taxRate.${taxTerm}`, {taxRate});
    }
  }
})();
