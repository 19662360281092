import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/flow';
import pick from 'lodash/pick';
import {v4 as uuid4} from 'uuid';

import jilApplicationIntegrations from 'api/jil/jilApplicationIntegrations';
import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import User from 'models/user/User';
import modelCache from 'services/cache/modelCache/modelCache';
import log from 'services/log';
import UserGroup from 'services/users/user-group/UserGroup';

import {
  ACCEPTED_USER_LIST_CACHE_ID,
  APP_INTEGRATION_CACHE_ID,
  APP_INTEGRATION_GLOBAL_PATH,
  APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID,
  APP_INTEGRATION_LIST_CACHE_ID,
  AUTHORIZATION_POLICIES,
  POLICY_TARGET_ACTION,
  POLICY_TARGET_TYPE,
} from './appIntegrationConstants';

/**
 * Model for an individual application integration
 */
class AppIntegration {
  /**
   * @description Method to fetch the ProductsApplicationIntegration's global policy.
   *
   * @returns {Promise} a promise fulfilled when the global policy exists which is either "ALLOW" or "DENY".
   */
  static async getGlobalPolicy({orgId}) {
    const cachedGlobalPolicy = modelCache.get(APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID, orgId);
    if (cachedGlobalPolicy) {
      return cachedGlobalPolicy;
    }

    let globalAppPolicy;
    try {
      const response = await jilApplicationIntegrations.getApplicationPolicies({
        orgId,
      });
      globalAppPolicy = response.data.find(
        (appPolicy) => appPolicy?.principal?.type === 'GLOBAL'
      )?.policy;
      modelCache.put(APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID, orgId, globalAppPolicy);
    } catch (error) {
      log.error('Failed to fetch global policy. Error:', error);
      throw error;
    }
    return globalAppPolicy;
  }

  /**
   * @description Method to set the ProductsApplicationIntegration's global policy.
   * @param {String} newPolicy - should be "ALLOW" or "DENY"
   * @param {String} orgId - Organization id for the global policy
   * @returns {Promise} a promise fulfilled when the global policy has been updated.
   */
  static async setGlobalPolicy({newPolicy, orgId}) {
    const operations = [
      {
        op: 'replace',
        path: APP_INTEGRATION_GLOBAL_PATH,
        value: newPolicy,
      },
    ];
    try {
      await jilApplicationIntegrations.patchApplicationPolicies({orgId}, operations);
      modelCache.put(APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID, orgId, newPolicy);
    } catch (error) {
      log.error('Failed to set global policy. Error:', error);
      throw error;
    }
  }

  /**
   * @description Method to get application integration by client id.
   *
   * @returns {Promise<AppIntegration>} resolves to AppIntegration on success, else rejects with error
   */
  static async getAppIntegrationByClientId({clientId, orgId}) {
    const model = modelCache.get(APP_INTEGRATION_CACHE_ID, clientId);
    if (model) {
      return model;
    }

    let appIntegration;
    try {
      const response = await jilApplicationIntegrations.getApplicationIntegrations({
        clientId,
        orgId,
      });
      appIntegration = new AppIntegration({
        ...response.data,
        orgId,
      });
      modelCache.put(APP_INTEGRATION_CACHE_ID, clientId, appIntegration);
    } catch (error) {
      log.error('Failed to fetch app integration by client id. Error:', error);
      throw error;
    }
    return appIntegration;
  }

  /**
   * @description Creates a new AppIntegration for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.appId the id of this application integration
   * @param {Array} options.clientIds the id of this platforms related to the app
   * @param {String} options.logo the url for the logo of this application integration
   * @param {String} options.name the name of this application integration
   * @param {String} options.orgId the orgId of this app integration
   * @param {Array} options.permissions the permissions of this application integration
   * @param {Object} options.policy the policy object of this products application integration
   * @param {Object} options.publisher the publisher of this application integration
   * @param {String} options.website the url for this application integration
   */
  constructor(options) {
    updateModel(this, options);
  }

  /**
   * @description Method to transform model into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @returns {Object} minimum necessary representation of model
   */
  toMinimumModel() {
    return {
      appId: this.appId,
    };
  }

  /**
   * @description Method to update changes to accepted user in ApplicationIntegrations item.
   *
   * @param {String|POLICY_ACTIONS} op The action to take with the new policy
   * @param {Array<String>} userIds The user ids whose consent will be modified
   * @returns {Promise} resolves to ApplicationIntegration on success, else rejects with error
   */
  async updateAcceptedUsers(op, userIds) {
    const operations = userIds.map((id) => ({
      op,
      path: `/${id}`,
    }));

    try {
      await jilApplicationIntegrations.patchAcceptedUsersByClientId(
        {clientId: this.clientIds[0], orgId: this.orgId},
        operations
      );

      modelCache.clear(ACCEPTED_USER_LIST_CACHE_ID);
      modelCache.clear(APP_INTEGRATION_CACHE_ID);
    } catch (error) {
      log.error('Failed to update application integration accepted users. Error:', error);
      throw error;
    }
    return this;
  }

  /**
   * @description Method to update changes to ApplicationIntegrations item.
   *
   * @param {POLICY_ACTIONS} op The action to take with the new policy. Can be 'add', 'remove', 'replace'
   * @param {AUTHORIZATION_POLICIES} updatedPolicy The new policy to save. Can be ALLOW, DENY, CUSTOM
   * @param {Array<Users>} [updatedAuthorizedUsers] The specified users that can use app if policy is CUSTOM
   * @returns {Promise<AppIntegration>} resolves to AppIntegration on success, else rejects with error
   */
  async updateAppPolicy(op, updatedPolicy, updatedAuthorizedUsers) {
    const policyObject = {
      policy: updatedPolicy,
      principal: {
        type: 'APP',
      },
      targets:
        updatedPolicy === AUTHORIZATION_POLICIES.SINGLE_APP.CUSTOM
          ? updatedAuthorizedUsers.map((user) => ({
              action: POLICY_TARGET_ACTION.ALLOW,
              id: user.id,
              type: user.getType().isUserGroup()
                ? POLICY_TARGET_TYPE.GROUP
                : POLICY_TARGET_TYPE.USER,
            }))
          : undefined,
    };
    const operations = this.clientIds.map((clientId) => ({
      op,
      path: `/${clientId}/policy`,
      value: policyObject,
    }));

    try {
      await jilApplicationIntegrations.patchApplicationIntegrations(
        {orgId: this.orgId},
        operations
      );
      modelCache.clear(APP_INTEGRATION_CACHE_ID);
      modelCache.clear(APP_INTEGRATION_LIST_CACHE_ID);
    } catch (error) {
      log.error('Failed to update application integration policy. Error:', error);
      throw error;
    }
    return this;
  }
}

/**
 * @description Initializes Application Integrations data.
 *
 * @param {Object} model AppIntegration model instance to initialize
 * @param {Object} options initialization object (as described in constructor options parameter)
 */
function updateModel(model, options) {
  // First we assign the model fields
  Object.assign(
    model,
    flow(
      (fields) =>
        pick(fields, [
          'appId',
          'clientIds',
          'logo',
          'name',
          'orgId',
          'permissions',
          'platforms',
          'policy',
          'publisher',
          'website',
        ]),
      // we clone to avoid issues when updating the nested object items
      cloneDeep
    )(options)
  );

  // For GETs to /application-integrations (like ?has_policies=true), some entities do not have an identifying
  // appId field. To guarantee that this entity always has a unique identifier, we generate a uuid for it.
  Object.assign(model, {
    uuid: uuid4(),
  });

  Object.assign(model, {
    authorizedUsers: options?.policy?.targets?.reduce(mapPolicyTargets, []) ?? [],
    policy: options?.policy?.policy,
  });
  function mapPolicyTargets(result, target) {
    if (target.type === POLICY_TARGET_TYPE.USER) {
      result.push(new User(target));
    } else if (target.type === POLICY_TARGET_TYPE.GROUP) {
      // Type should be user group type instead of target type(GROUP)
      result.push(Object.assign(new UserGroup(target), {type: MEMBER_TYPE.USER_GROUP}));
    }
    return result;
  }
}

export default AppIntegration;
