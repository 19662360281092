import {CONTRACT_MODEL} from '@admin-tribe/binky';
import {View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- use this with v2 Select
import FieldLabel from '@react/react-spectrum/FieldLabel';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- somehow v3 Picker can't be opened in coral-dialog
import Select from '@react/react-spectrum/Select';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import InsightsConstants from '../InsightsConstants';
import './InsightsDropdown.pcss';

/**
 * InsightsDropdown - a widget for selecting custom option when creating insights item detail.
 */
const InsightsDropdown = ({contents, contractInfoList, onSelect}) => {
  const intl = useIntl();
  const labelFor = useId();

  const contractsContent = contents.find(
    (content) => content.key === InsightsConstants.DROPDOWN_TYPE.CONTRACTS
  );

  return contractsContent && contractInfoList.length > 1 ? (
    <View backgroundColor="transparent" marginTop="size-400">
      <FieldLabel
        label={contractsContent.title || intl.formatMessage({id: contractsContent.titleKey})}
        labelFor={labelFor}
      />
      <Select
        defaultValue={undefined} // set it to undefined so that the first item won't be selected
        id={labelFor}
        onChange={(id) =>
          onSelect(
            InsightsConstants.DROPDOWN_TYPE.CONTRACTS,
            contractInfoList.find((item) => item.contractId === id)
          )
        }
        options={contractInfoList.map((item) => ({
          label: intl.formatMessage(
            {
              id:
                item.type.toUpperCase() === CONTRACT_MODEL.ALLOCATION
                  ? 'binky.common.etlaUsageReport.contractPicker.allocationModel.text'
                  : 'binky.common.etlaUsageReport.contractPicker.otherModels.text',
            },
            {
              contractId: item.contractId,
            }
          ),
          value: item.contractId,
        }))}
        placeholder=""
        styleName="selector"
      />
    </View>
  ) : null;
};

InsightsDropdown.propTypes = {
  /**
   * Dropdown contents of creating insights item detail
   */
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      titleKey: PropTypes.string, // keys are prefixed with 'binky.widgets.insights'.
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Array of objects each representing contract info
   * Each such object has the following fields:
   * - contractId(String): id of contract
   * - type(String): type of contract
   */
  contractInfoList: PropTypes.arrayOf(
    PropTypes.shape({
      contractId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  /**
   * Handler that is called when the dropdown option is selected
   */
  onSelect: PropTypes.func.isRequired,
};

export default InsightsDropdown;
