import jilApplicationIntegrations from 'api/jil/jilApplicationIntegrations';
import User from 'models/user/User';
import JilModelList from 'services/modelList/JilModelList';

import {ACCEPTED_USER_LIST_CACHE_ID} from './appIntegrationConstants';

class AcceptedUserList extends JilModelList {
  constructor(options) {
    super({
      isCacheable: true,
      itemClassRef: User,
      modelCacheId: ACCEPTED_USER_LIST_CACHE_ID,
      resource: jilApplicationIntegrations.getAcceptedUsersByClientId,
      ...options,
    });

    Object.assign(this, {
      clientId: options.clientId,
      orgId: options.orgId,
    });
  }

  /**
   * @description Method to refresh the contents of the accepted user list
   * @returns {Promise<AcceptedUserList>} promise - resolved when the list is refreshed
   */
  refresh() {
    return super.refresh({
      clientId: this.clientId,
      orgId: this.orgId,
    });
  }
}

export default AcceptedUserList;
