import pick from 'lodash/pick';

import SummaryItem from './SummaryItem';

class AcquisitionSummary {
  /**
   * @description AcquisitionSummaries can be thought of as the purchase details.
   * Summaries can be aggregated differently. In general there will be one top-level summary
   * for a distinct combination of contract id, pa-code and summary type.
   *
   * For DEFAULT summaries, summary-items are aggregated for each distinct combination of offer-id and end-date
   *
   * See https://wiki.corp.adobe.com/display/clam/GET+Acquisition+Summaries
   * and https://wiki.corp.adobe.com/display/aces/Spec+%3A%3A+Purchase+Summarization
   *
   * @param {String}   options.contractId - the contract id
   * @param {Object[]} options.offers - the offers
   * @param {String}   options.productArrangementCode - the product arrangement code
   * @param {Boolean}  options.quantityUnlimited - whether quantity is unlimited
   * @param {ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS} options.renewalQuantityStatus
   * Renewal quantity status that will be populated when the summarizedBy param
   * is set to RENEWAL, one of ['COMPLETE', 'PARTIAL', 'NONE']
   * @param {String}   options.summaryId - the summary id
   * @param {SummaryItem[]} options.summaryItems - the summary items
   * @param {String}   options.summarizedBy - summarized by
   * @param {String}   options.totalBackedQuantity - the total backed quantity
   * @param {String}   options.totalUnbackedQuantity - the total unbacked quantity
   */
  constructor(options = {}) {
    initModel(this, options);
  }
}

function initModel(model, options) {
  Object.assign(
    model,
    {
      summaryItems:
        options.summaryItems?.map(
          (summaryItem) =>
            new SummaryItem({
              ...summaryItem,
              hasEnglishAndMultiLanguage: hasEnglishAndMultiLanguage(options.offers),
              isAllocated: options.isAllocated,
              // Pass the matching offer into SummaryItem for convenience i.e. access to merchandising data
              offer: options.offers?.find((offer) => offer.offer_id === summaryItem.offerId),
            })
        ) || [],
    },
    pick(options, [
      'contractId',
      'offers',
      'orgId',
      'productArrangementCode',
      'quantityUnlimited',
      'renewalQuantityStatus',
      'summaryId',
      'summarizedBy',
      'totalBackedQuantity',
      'totalUnbackedQuantity',
    ])
  );
}

/**
 * @param {Object[]} offers - the list of offers associated with this acquisition summary
 * @returns Returns true if the list of offers within this acquisition
 * summary (which is grouped by PA code) has both EN and MULT offers
 */
function hasEnglishAndMultiLanguage(offers) {
  return (
    offers?.some((offer) => offer.language === 'EN') &&
    offers?.some((offer) => offer.language === 'MULT')
  );
}

export default AcquisitionSummary;
