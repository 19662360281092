import pick from 'lodash/pick';

/**
 * @description This class represents properties related to a cancellation order
 */
class CancellationOrderDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {String} [options.orderNumber] The Cancellation Order Number
   * @param {Array<string>} [options.reasonCodes] An array of codes representing selected reasons
   * @param {Object} [options.retention] An object representing a selected retention offer
   * @param {Number} [options.retention.discountTotalTax] The total discount tax
   * @param {Number} [options.retention.discountTotalWithoutTax] The total discount without tax
   * @param {Number} [options.retention.discountTotalWithTax] The total discount with tax
   * @param {Number} [options.retention.billingTotalTax] The total billing tax
   * @param {Number} [options.retention.billingTotalWithoutTax] The total billing price without tax
   * @param {Number} [options.retention.billingTotalWithTax] The total billing price with tax
   * @param {String} [options.retention.offerId] The retention offer ID
   * @param {String} [options.retention.orderNumber] The retention order number
   * @param {Array<Object>} [options.retention.outcomes] The outcomes for this retention offer
   * @param {Number} [options.retention.outcomes[].discountAmount] The currency amount or percentage discount
   * @param {String} [options.retention.outcomes[].discountCurrency] The currency (e.g., USD)
   * @param {Number} [options.retention.outcomes[].durationAmount] The duration amount
   * @param {String} [options.retention.outcomes[].durationType] The duration type (e.g. FINITE)
   * @param {String} [options.retention.outcomes[].durationUnit] The duration unit (e.g. MONTH)
   * @param {String} [options.retention.outcomes[].type] The type of retention offer (e.g. FIXED_DISCOUNT)
   * @param {String} [options.retention.promoId] The promotion ID
   * @param {String} [options.userReason] The user provided reason for cancellation, if provided
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['orderNumber', 'reasonCodes', 'retention', 'userReason']));
  }
}

export default CancellationOrderDescriptor;
