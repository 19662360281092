(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.number-input
   * @description module that wraps the coral numberinput to alleviate binding issues
   * Elaboration: ng-coral-prop takes a string with a json object in it, but the
   * path needs to be available on the component's controller, because of this
   * expressions that are wrapped in double curly braces will not work, and because
   * I have a need for this in Stock, I have elected to create a wrapper to
   * mitigate that problem.
   */
  angular.module('binky.widgets.common.number-input', [
    'binky.core.lodash',
    'binky.widgets.common.force-positive-integer',
  ]);
})();
