import addHours from 'date-fns/addHours';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';

const INCLUSIVITY = {
  BOTH: 1,
  END: 2,
  NONE: 3,
  START: 4,
};
const HOURS_IN_DAY = 24;

/**
 * @description Compare two dates without comparing time.
 *
 * @param {Date/String} date1Input the candidate date
 * @param {Date/String} date2Input the expected date
 * @returns {Number} returns 1 if date1Input is greater than date2Input, -1 if
 *   date1Input is less than date2Input, and 0 if 2 dates are equal.
 */
function compareDates(date1Input, date2Input) {
  // We should look into strong typing with Typescript or somehow disallow generics (Date | String)
  // as now we have to check the type. Moment was generous that it could take either a string
  // or a Date object, but Date fns is stricter and only operates on date objects not strings
  // The food chain being Date takes strings, Date functions take dates according to Date-fns

  /**
   * Lambda to get the start of a day
   * @param {Date | String} input
   */
  const getDateObj = (input) => startOfDay(typeof input === 'string' ? new Date(input) : input);

  const date1 = getDateObj(date1Input);
  const date2 = getDateObj(date2Input);
  const order = date1.getTime() - date2.getTime();

  if (order > 0) {
    return 1;
  }
  if (order < 0) {
    return -1;
  }
  return 0;
}

/**
 * @description Drop a time in a date.
 *
 * @param {String} date the input date
 * @returns {String} returns a date without time.
 */
function getDate(date) {
  return startOfDay(new Date(date));
}

/**
 * @description Return the number of days left between now and date.
 *
 * @param {Date|String} date the end date as either a Date or an ISO format date String
 * @param {String} [fractionalDays] - when provided, it configures to whether round, round up (ceil) or
 * round down (floor) fractional day. By default, fractional days are truncated towards zero.
 * @returns {Integer|undefined} returns the number of days between now and date
 *   or undefined if date isn't specified,
 *   or undefined if string and an invalid date representation,
 */
function getDaysLeft(date, {fractionalDays} = {}) {
  const isValidDate =
    isValid(new Date(date)) || Object.prototype.toString.call(date) === '[object Date]';
  if (!isValidDate) return undefined;

  // We can only operate on a date object so we do parse it if date is a string
  const dateObj = typeof date === 'string' ? parseISO(date) : date;

  // We need to do new Date(Date.now()) explicitly as tests are mocking Date.now.
  // Moment seems to have used Date.now under the hood, whereas, Date-fns, takes a
  // date such as new Date()
  // If we want the tests to pass, we would need to start a date with a Date now start point
  switch (fractionalDays) {
    case 'round':
      return Math.round(differenceInHours(dateObj, new Date(Date.now())) / HOURS_IN_DAY);
    case 'ceil':
      return Math.ceil(differenceInHours(dateObj, new Date(Date.now())) / HOURS_IN_DAY);
    case 'floor':
      return Math.floor(differenceInHours(dateObj, new Date(Date.now())) / HOURS_IN_DAY);
    default:
      return differenceInDays(dateObj, new Date(Date.now()));
  }
}

/**
 * @description Adds hours to the current time. Defaults to the current time.
 *
 * @param {Number} numOfDays - The number of days to add to the current time
 * @returns {Date} The date numOfDays from now
 */
function getHoursFromNow(numOfHours = 0) {
  return addHours(Date.now(), numOfHours);
}

/**
 * @description Method to determine if a given date is within a certain time
 *   period, ignoring time units smaller than a day (i.e. - the hour, minute, or
 *   second of the date is not considered; only the day, month, year, etc)
 * @param {Date|String} testDate - the date to test as either a Date or an ISO
 *   format date String
 * @param {Date|String} beginRangeDate - the start of the range to verify
 *   against as either a Date or an ISO format date String
 * @param {Date|String} endRangeDate - the end of the range to verify against as
 *   either a Date or an ISO format date String
 * @param {Number} [inclusivity] - numerical representation of inclusivity for
 *   the check, see INCLUSIVITY (above) for value mappings. Defaults to
 *   INCLUSIVITY.NONE for a true "between" evaluation
 * @returns {Boolean} true if date is between other dates, respecting
 *   inclusivity values requested
 */
function isBetween(testDate, beginRangeDate, endRangeDate, inclusivity = INCLUSIVITY.NONE) {
  switch (inclusivity) {
    case INCLUSIVITY.BOTH:
      return (
        compareDates(testDate, beginRangeDate) >= 0 && compareDates(testDate, endRangeDate) <= 0
      );
    case INCLUSIVITY.START:
      return (
        compareDates(testDate, beginRangeDate) >= 0 && compareDates(testDate, endRangeDate) < 0
      );
    case INCLUSIVITY.END:
      return (
        compareDates(testDate, beginRangeDate) > 0 && compareDates(testDate, endRangeDate) <= 0
      );
    case INCLUSIVITY.NONE:
    default:
      return compareDates(testDate, beginRangeDate) > 0 && compareDates(testDate, endRangeDate) < 0;
  }
}

/**
 * @description Return the local ISO string. The toISOString function in Date returns string with UTC format.
 *
 * @param {Date} date - the date to be convert to local ISO string
 * @returns {String} ISO string with time zone offset. Ex: 2021-03-26T23:59:59.999-07:00
 */
function toLocalISOString(date) {
  if (isValid(date)) {
    const offset = date.getTimezoneOffset(); // the number is positive when timezone is later than UTC and unit is minute
    const absOffset = Math.abs(offset);
    const sign = offset > 0 ? '-' : '+'; // sign should be oppsite to the timezone offset
    const hh = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const mm = String(absOffset % 60).padStart(2, '0');

    return date.toISOString().replace('Z', `${sign}${hh}:${mm}`);
  }
  return undefined;
}

/**
 * @description Parse the given string in ISO 8601 duration format and return an obejct with duration.
 * If the argument isn't a string, the function cannot parse the string or the values are invalid,
 * it returns an empty object `{}`. Or it throws a TypeError if less than 1 argument was passed.
 *
 * This method will be available in the future version of `date-fns` and will be removed from here,
 * once https://github.com/date-fns/date-fns/pull/2302 is merged.
 *
 * @param {String} isoDuration - an ISO format duration String
 * @returns {Object} the parsed duration `Ex: {months: 2, years: 1}` or an empty object
 */
function parseISODuration(isoDuration) {
  if (isoDuration?.length < 1) {
    throw new TypeError(`1 argument required, but only ${isoDuration.length} present`);
  }

  if (typeof isoDuration === 'string') {
    const nr = '\\d+(?:[\\.,]\\d+)?';
    const dateRegex = `(${nr}Y)?(${nr}M)?(${nr}D)?`;
    const timeRegex = `T(${nr}H)?(${nr}M)?(${nr}S)?`;
    const durationRegex = new RegExp(`P${dateRegex}(?:${timeRegex})?`);

    const match = isoDuration.match(durationRegex);

    if (match) {
      const duration = {};
      if (match[1]) duration.years = Number.parseFloat(match[1]);
      if (match[2]) duration.months = Number.parseFloat(match[2]);
      if (match[3]) duration.days = Number.parseFloat(match[3]);
      if (match[4]) duration.hours = Number.parseFloat(match[4]);
      if (match[5]) duration.minutes = Number.parseFloat(match[5]);
      if (match[6]) duration.seconds = Number.parseFloat(match[6]);
      return duration;
    }
  }
  return {};
}

export {
  INCLUSIVITY,
  compareDates,
  getDate,
  getDaysLeft,
  getHoursFromNow,
  isBetween,
  toLocalISOString,
  parseISODuration,
};
