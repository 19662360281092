import {Flex, Header, View} from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';

import TruncatedText from 'common/components/truncated-text/TruncatedText';

import {useModalDialogContext} from '../ModalDialogContext';

import styles from './ModalTagHeader.pcss';

/**
 * Component to render an icon + label in the top right of a modal
 */
const ModalTagHeader = ({children = '', IconComponent}) => {
  const {styleProps} = useModalDialogContext();
  return (
    <Header>
      {/* spectrum: remove when TruncatedText is ported to v3 */}
      <Provider className={styles['background-transparent']}>
        <View
          data-testid="tag-header"
          elementType="section"
          maxWidth="size-4600"
          paddingEnd={styleProps.spaceX}
        >
          <Flex alignItems="center">
            {IconComponent && (
              <View elementType="span" marginEnd="size-75">
                {IconComponent}
              </View>
            )}
            <TruncatedText textClassName={styles.header}>{children}</TruncatedText>
          </Flex>
        </View>
      </Provider>
    </Header>
  );
};

ModalTagHeader.propTypes = {
  children: PropTypes.string,
  IconComponent: PropTypes.node,
};

export default ModalTagHeader;
