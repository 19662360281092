const OBSERVABILITY_BUMPER_FEATURE_FLAG = 'bumper_observability';

const ROUTE_CHANGE_TYPE = {
  // 1st visit to the route, unless it's the initial visit, see START_SESSION
  INITIAL_ROUTE: 'initialRoute',
  // The user returns to a visited route
  RETURN_ROUTE: 'returnRoute',
  // 1st time a user visits the application. Higher priority than INITIAL_ROUTE.
  START_SESSION: 'startSession',
};

export {OBSERVABILITY_BUMPER_FEATURE_FLAG, ROUTE_CHANGE_TYPE};
