import camelCase from 'lodash/camelCase';

import MEMBER_AND_SELECTED_ITEMS_CONSTANTS from './MemberAndSelectedItemsConstants';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- thean@ to update
function getErrorMessage(errorStatus, {intl, pickerType}) {
  const namespace = `binky.common.addUserFormTable.error.${errorStatus}`;
  switch (errorStatus) {
    case MEMBER_AND_SELECTED_ITEMS_CONSTANTS.ERROR_STATUS.DUPLICATE_INPUT: {
      return intl.formatMessage({
        id: `${namespace}.${camelCase(pickerType)}`,
      });
    }
    case MEMBER_AND_SELECTED_ITEMS_CONSTANTS.ERROR_STATUS.USER_PICKER_ERROR: {
      // UserPicker constructs its own error messages
      return undefined;
    }
    default:
      return intl.formatMessage({id: namespace});
  }
}
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- thean@ to update
function getInfoMessage(status, {intl}) {
  const namespace = `binky.common.addUserFormTable.info.${status}`;
  return intl.formatMessage({id: namespace});
}

export {getErrorMessage, getInfoMessage};
