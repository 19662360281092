(function () {
  'use strict';
  /**
   * @deprecated we do not need this form of flagging in jsx
   *
   * @ngdoc directive
   * @name binkyHideWithFeature
   * @description
   * Feature markup directly within views without requiring controller support.
   * Element removed if hideWithFeature flag is enabled.
   * For example: <p binky-hide-with-feature="enabledFlag">Hide this paragraph.</p>
   */
  angular.module('binky.widgets.common.feature').directive('binkyHideWithFeature', hideWithFeature);

  function hideWithFeature(feature) {
    return {
      link: (scope, element, attrs) => {
        if (feature.isEnabled(attrs.binkyHideWithFeature)) {
          element.remove();
        }
      },
      restrict: 'A',
    };
  }
})();
