(function () {
  'use strict';
  /**
   * @deprecated Please use src2/shell/panels/drawer/Drawer.jsx
   *
   * @ngdoc component
   * @name binky.shell.panels.pulldown:binkyDrawer
   *
   * @description An animated drawer panel
   *
   * @param {String} drawerId The ID to assign to the drawer-content div.
   * @param {String} [drawerTitle] A title to show on the default header, if drawer-header is not used.
   *   For accessibility purposes, this is used to label the dialog so should always be supplied.
   * @param {Function} [onClose] The callback function to register for the close event.
   * @param {Function} [onOpen] The callback function to register for the open event.
   * @param {Promise} [waitOnDrawer] An optional promise to wait for before displaying drawer content.
   * @param {Transclude} drawerBody The core transclusion for the drawer content. Required.
   * @param {Transclude} [drawerHeader] A transclusion for the drawer header. Optional.
   *   For consistency, if a header level is used it should be <h3 class="coral-Heading coral-Heading--3">.
   * @param {Transclude} [drawerFooter] A transclusion for the drawer footer. Optional.
   *
   * @example <binky-drawer
   *            drawer-id="example-id"
   *            drawer-title="Example Title"
   *            on-close="myClose"
   *            on-open="myOpen"
   *            wait-on-drawer="promise"
   *          >
   *            <drawer-body></drawer-body>
   *          </binky-drawer>
   */
  angular.module('binky.shell.panels.drawer').component('binkyDrawer', {
    bindings: {
      drawerId: '@',
      drawerTitle: '@?',
      onClose: '<?',
      onOpen: '<?',
      waitOnDrawer: '<?',
    },
    controller,
    templateUrl: 'shell/panels/drawer/drawer.component.html',
    transclude: {
      'drawer-body': 'drawerBody',
      'drawer-footer': '?drawerFooter',
      'drawer-header': '?drawerHeader',
    },
  });

  /* @ngInject */
  function controller(
    $document,
    $element,
    $q,
    $scope,
    $timeout,
    $transclude,
    _,
    feature,
    PANEL_MANAGER,
    PANEL_OPEN_FOCUS_DELAY,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      feature,
    });

    function $onDestroy() {
      panelManager.unregister(vm.drawerId);
      removeKeyboardListeners();
    }

    function $onInit() {
      _.assign(vm, {
        closeDrawer: () => {
          if (!panelManager.close(vm.drawerId)) {
            // If the panel was unregistered or already closed, we still want to trigger the callback logic
            onClose();
          }
        },
        isFooterPresent: $transclude.isSlotFilled('drawer-footer'),
        isShown: false,
        waitOnDrawer: vm.waitOnDrawer || $q.resolve(),
      });

      $timeout(() => {
        panelManager.register(PANEL_MANAGER.TYPE.DRAWER, vm.drawerId, onOpen, onClose);
      });
    }

    ///////////////

    function addKeyboardListeners() {
      // We need to add the keydown handler to the document itself otherwise
      // we cannot capture key presses when there is no element with focus.
      // In that scenario, the body itself is the target element. We need to make
      // sure the Escape key works to close the window when no element has focus,
      // and that focus trap still works to stop focus returning to elements
      // behind the drawer.
      $document.on('keydown', onKeydown);
    }

    function focusFirstInput() {
      const focusableEls = getFocusableElements();
      _.chain(focusableEls).head().invoke('focus').value();
    }

    function canTakeFocus(element) {
      // offsetParent is null when element display is set to none, so we can use
      // this to ensure that we don't try and trap focus on an element that is
      // not visible.
      return !element.disabled && _.isElement(element.offsetParent);
    }

    function getFocusableElements() {
      // When detecting focusable elements, account is taken of the behaviour of some
      // coral components where presentation role is used for images within a button,
      // which will prevent them taking focus, and components like the numeric stepper
      // input where there are buttons as part of the component which are excluded
      // from tab order using tabindex=-1.
      const drawerEl = $element[0].querySelector('.drawer-content');
      const focusableElsAll = drawerEl.querySelectorAll(
        'button:not([tabindex="-1"]), [href], input, select, textarea, binky-icon-tooltip > coral-icon, [tabindex]:not([tabindex="-1"]):not([role="presentation"]):not([role="img"])'
      );

      return _.filter([...focusableElsAll], (el) => canTakeFocus(el));
    }

    function isNotDrawerEvent(event) {
      // If the event has occurred in, for example, a modal dialog that is active,
      // we don't want to process it. We check for document.body here because when the user
      // clicks on a non-interactive item that cannot take focus, for example a span
      // that contains only text, the target element for the keyboard event is
      // then document.body.  We treat these as drawer events whilst the drawer is open.
      return (
        event &&
        event.target &&
        event.target.tagName !== 'BODY' &&
        event.target !== $element[0] &&
        !$element[0].contains(event.target)
      );
    }

    function onClose() {
      _.invoke(vm, 'onClose');
      vm.isShown = false;

      removeKeyboardListeners();
    }

    /**
     * @description Helper method to handle keyboard interactions with the
     *   drawer. When adding/removing this method, it's important to use vanilla
     *   JS methods (addEventListener, removeEventListener) since they allow us
     *   to attach the listener to the capture phase of the event lifecycle. We
     *   need to capture events (as opposed to handling bubbling events) to
     *   prevent any nested elements from potentitally receiving this event at
     *   all. Otherwise, if we rely solely on the bubbling phase (default for
     *   Angular handlers), the target element will have already been called by
     *   the time the drawer handler (parent in DOM) gets the event - causing
     *   calls to stopPropagation() or preventDefault() behaviors to be made too
     *   late to be effective.
     * @param {Event} event - keydown key event that triggered this handler
     */
    function onKeydown(event) {
      // Exit early if the user has simply held a button down too long
      if (event.repeat) {
        event.preventDefault();
        event.stopImmediatePropagation();
        return;
      }

      // Exit early if some element outside of this one is active
      if (isNotDrawerEvent(event)) {
        return;
      }

      if (event.key === 'Escape' || event.key === 'Esc') {
        $scope.$apply(() => {
          vm.closeDrawer();
        });
        event.preventDefault();
      } else if (event.key === 'Tab') {
        trapFocus(event);
      }
    }

    function onOpen() {
      _.invoke(vm, 'onOpen');
      vm.isShown = true;

      addKeyboardListeners();

      // give any lingering key events (keypress, keyup) time to process
      // through before changing focus
      $timeout(focusFirstInput, PANEL_OPEN_FOCUS_DELAY);
    }

    function removeKeyboardListeners() {
      $document.off('keydown', onKeydown);
    }

    /**
     * @description Helper method called when the Tab key is involved in a keydown
     * event. This method ensures that tabbing does not cause focus to leave the
     * drawer whilst it is open, per the expected behaviour for a modal dialog.
     * @param {Event} event - keydown key event that triggered this handler
     */
    function trapFocus(event) {
      const focusableEls = getFocusableElements();

      /**
       * There will always be at least 1 focusable element because of the presence
       * of the mandatory close button in the header which is always visible.
       * If there are no other elements, then the close button will be both
       * first and last element.
       */
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      if (event.shiftKey) {
        if (event.target === firstFocusableEl || event.target.tagName === 'BODY') {
          lastFocusableEl.focus();
          event.preventDefault();
        }
      } else if (event.target === lastFocusableEl || event.target.tagName === 'BODY') {
        firstFocusableEl.focus();
        event.preventDefault();
      }
    }
  }
})();
