(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.domains.status
   * @description module that defines domain status widgets
   */
  angular.module('binky.widgets.domains.status-filter', [
    'binky.core.domains',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.domains.status',
  ]);
})();
