(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.user.user-role
   * @description module that defines the user role widget
   */
  angular.module('binky.widgets.user.user-role', [
    'binky.core.product',
    'binky.core.translation-utils',
    'binky.core.user.roles',
    'pascalprecht.translate',
  ]);
})();
