(function () {
  /**
   * @ngdoc module
   * @name binky.core.organizations.search-list
   * @description defines the organization search list model
   */
  angular.module('binky.core.organizations.search-list', [
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.common.model.cache',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.storage-utils',
    'ngResource',
  ]);
})();
