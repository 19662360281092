(function () {
  /**
   * @ngdoc module
   * @name binky.core.migration
   * @description Module which defines migrations related models
   */
  angular.module('binky.core.migration', [
    'binky.core.api.jil',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.migration.t2e',
    'binky.core.src2-migration',
  ]);
})();
