const LICENSE_GROUP_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.product.licenseGroup.LICENSE_GROUP_CONSTANTS;
(function () {
  /**
   * @deprecated Please use src2/core/services/product/license-group/LicenseGroupConstants.js
   */
  angular.module('binky.core.product.configurations').constant('PRODUCT_CONFIGURATIONS_EVENT', {
    CREATE: LICENSE_GROUP_CONSTANTS.PRODUCT_CONFIGURATIONS_EVENT.CREATE,
    UPDATE: LICENSE_GROUP_CONSTANTS.PRODUCT_CONFIGURATIONS_EVENT.UPDATE,
  });
})();
