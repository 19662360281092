/* eslint-disable max-lines -- util file */
import flatMap from 'lodash/flatMap';
import flow from 'lodash/flow';
import invokeMap from 'lodash/invokeMap';
import minBy from 'lodash/minBy';

import {PRODUCT_DELEGATION_TARGET} from './ProductConstants';

/**
 * @description Check if this product list has only products where the provided
 *    condition is met, and for those if any have the second condition met for
 *    their fulfillable items.
 *
 * @param {ProductList} productList - the product list.
 * @param {Function} productCondition - a function which will be passed the product
 *    and is expected to return true or false.
 * @param {Function} fiCondition - a function which will be passed the fulfillableItemList
 *    and is expected to return true or false.
 * @returns {Boolean} whether all conditions were met.
 */
function everyProductAndSomeFulfillableItemCondition(productList, productCondition, fiCondition) {
  return (
    productList.items.every(productCondition) &&
    productList.items.some((product) => fiCondition(product.fulfillableItemList))
  );
}

/**
 * @description Get a fulfillable item from a product list by code
 *
 * @param {ProductList} productList - the product list.
 * @param {String} code - the fi code to find.
 * @returns {FulfillableItem} the found fulfillable item or undefined if none found
 */
function findFulfillableItem(productList, code) {
  return flow(
    (products) => flatMap(products, 'fulfillableItemList.items'),
    (fulfillableItems) => fulfillableItems.find((fi) => fi && fi.code === code)
  )(productList.items);
}

/**
 * @description Get a fulfillable item from a product list by code if it is selected
 *
 * @param {ProductList} productList - the product list.
 * @param {String} fiToFind - the fulfillable item to find.
 * @returns {FulfillableItem} the found fulfillable item or undefined if none found
 */
function findSelectedFulfillableItem(productList, fiToFind) {
  return flow(
    (products) => flatMap(products, 'fulfillableItemList.items'),
    (fulfillableItems) =>
      fulfillableItems.find((fi) => fi && fi.code === fiToFind.code && fi.selected === true)
  )(productList.items);
}

/**
 * @description Get the cumulative count of PAID and
 *   PENDING_PAYMENT licenses for all products.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Number} the assignable license count.
 */
function getAssignableLicenseCount(productList) {
  return invokeMap(productList.items, 'getAssignableLicenseCount').reduce(
    (sum, value) => sum + value,
    0
  );
}

/**
 * @deprecated. Use getClosestLicenseEndDateForStatus instead.
 *
 * @description Method to get the closest license quantity end date
 *              based on a provided condition and only for products that
 *              are associated with a specified contractId.
 *
 * @param {ProductList} productList - the product list.
 * @param {Function} condition condition that will be passed through to
 *                             the individual product's licenseQuantityList
 *                             getClosestEndDateWhen method
 * @param {String} contractId id of the contract to filter on.
 *
 * @returns {Date} minimum endDate of the filtered license quantities
 */
function getClosestLicenseQuantityEndDateWhen(productList, condition, contractId) {
  return flow(
    (products) => products.filter((product) => product.contractIds?.includes(contractId)),
    (product) => invokeMap(product, 'licenseQuantityList.getClosestEndDateWhen', condition),
    (endDates) => minBy(endDates, (endDate) => new Date(endDate).getTime())
  )(productList.items);
}

/**
 * @description Method to get the closest license quantity end date
 *              based on a provided condition and only for products that
 *              are associated with a specified contractId.
 *
 * @param {ProductList} productList - the product list.
 * @param {Function} condition condition that will be passed through to
 *                             the individual product's licenseQuantityList
 *                             getClosestEndDateWhen method
 * @param {String} contractId id of the contract to filter on.
 *
 * @returns {Date} minimum endDate of the filtered license quantities
 */
function getClosestLicenseEndDateForStatus(productList, status, contractId) {
  return flow(
    (products) => products.filter((product) => product.contractIds?.includes(contractId)),
    (product) => invokeMap(product, 'licenseTupleList.getClosestEndDateForStatus', status),
    (endDates) => minBy(endDates, (endDate) => new Date(endDate).getTime())
  )(productList.items);
}

/**
 * @description Method to get products which can be assigned a developer
 *
 * @param {ProductList} productList - the product list.
 * @returns {Array<Product>} developer assignable products.
 *
 */
function getDeveloperProducts(productList) {
  return productList.items.filter((product) =>
    product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  );
}

/**
 * @description Return the list of products that match offerIds
 *
 * @param {ProductList} productList Product list to search from
 * @param {Array<String>} offerIds Array of offer ID's
 *
 * @returns {Array<Product>} Array of products
 */
function getProductsByOfferIds(productList, offerIds = []) {
  return offerIds
    .map((offerId) => productList.items.find((product) => product.offerId === offerId))
    .filter((product) => product);
}

/**
 * @description Method to get products which can be assigned to users.
 *  Products which are not administerable should be included.
 *  They will show as disabled in the list of assignable products.
 *
 * @param {ProductList} productList - the product list
 * @returns {Array<Product>} The products which can be assigned to users
 *
 */
function getProductsAssignableToUsers(productList) {
  return productList.items.filter(
    (product) =>
      product.fulfillableItemList.hasDelegationType() &&
      !product.fulfillableItemList.hasOrgDelegatable() &&
      !product.fulfillableItemList.hasOrgOnDemandConsumable() &&
      [
        PRODUCT_DELEGATION_TARGET.TYPE1,
        PRODUCT_DELEGATION_TARGET.TYPE2,
        PRODUCT_DELEGATION_TARGET.TYPE2E,
        PRODUCT_DELEGATION_TARGET.TYPE3,
      ].some((delegationTarget) => product.isDelegatableToType(delegationTarget))
  );
}

/**
 * @description Method to get a list of Products which have unassigned licenses from a ProductList object
 * @param {ProductList} productList - object representing a list of products
 * @returns {Object} object - containing an items collection
 *          {Array<Product>} object.items - containing products that have unassigned products
 * @returns {Object} object - containing empty items collection when productList prop is null/undefined and productList.items collection is null/undefined.
 *          {Array} object.items - empty collection.
 */
const getUnassignedProducts = (productList) => {
  if (!productList || !productList.items) {
    return {items: []};
  }

  return {
    items: productList.items.filter((product) => {
      const assignableLicenses = product.getAssignableLicenseCount();
      const undelegatedLicenseCount = product.getUndelegatedLicenseCount();

      const unassignedLicenses = assignableLicenses - undelegatedLicenseCount;

      const mismatchedLicenceCounts =
        assignableLicenses === 0 || undelegatedLicenseCount === 0 || unassignedLicenses < 0;
      return !mismatchedLicenceCounts;
    }),
  };
};

/**
 * @description Method to determine the cumulative count of seat based PAID and
 *   PENDING_PAYMENT licenses for all products.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Number} quantity of seat based delegated licenses that are paid or pending across
 *   all products in this list.
 */
function getSeatBasedAssignableLicenseCount(productList) {
  return invokeMap(productList.items, 'getSeatBasedAssignableLicenseCount').reduce(
    (sum, value) => sum + value,
    0
  );
}

/**
 * @description Method to determine the cumulative count of seat based provisioned licenses for all products.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Number} quantity of seat based provisioned licenses across
 *   all products in this list
 */
function getSeatBasedTotalProvisionedQuantity(productList) {
  return invokeMap(productList.items, 'getSeatBasedTotalProvisionedQuantity').reduce(
    (sum, value) => sum + value,
    0
  );
}

/**
 * @description Method to determine if any of the given Products can be administered.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any single Product is administrable
 */
function hasAdministrable(productList) {
  return productList.items.some((product) => product.isAdministerable());
}

/**
 * @description Method to get the storage only products.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Array<Product>} storage only products.
 */
function getStorageOnlyProducts(productList) {
  return productList.items.filter((product) => product.isStorageOnly());
}

/**
 * @description Method to determine the cumulative count of provisioned licenses for all products.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Number} quantity of provisioned licenses across all products in this list
 */
function getTotalProvisionedQuantity(productList) {
  return productList.items.reduce((sum, item) => sum + item.provisionedQuantity, 0);
}

/**
 * @description Method to determine if product list contains all passed fulfillableItems
 *
 * @param {ProductList} productList - the product list.
 * @param {Array} fulfillableItems - array of fulfillableItem code to be checked
 * @returns {Boolean} true if parameter is undefined or product list has all passed fulfillableItems
 */
function hasAllFulfillableItems(productList, fulfillableItems) {
  return (
    !fulfillableItems ||
    fulfillableItems.every((fulfillableItem) => findFulfillableItem(productList, fulfillableItem))
  );
}

/**
 * @description Method to determine if any product has paid or pending
 *   licenses attached to it.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any product has paid or pending licenses, else
 *   false if there are no pending or paid licenses at all
 */
function hasAssignableLicenses(productList) {
  return getAssignableLicenseCount(productList) > 0;
}

/**
 * @description Method to determine if any product has been purchased through
 *   an ETLA buying program, while another product has been purchased through a
 *   VIP product buying program.
 *
 * @param {ProductList} productList - the product list
 * @returns {Boolean} true if any product has a buying program of ETLA and
 *   another product has a buying program of VIP, else false if both conditions
 *   are not met
 */
function hasBuyingProgramEtlaAndVipProducts(productList) {
  return (
    productList.items.some((product) => product.isBuyingProgramETLA()) &&
    productList.items.some((product) => product.isBuyingProgramVIP())
  );
}

/**
 * @description Method to determine if any products in the list are
 *              consumable.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any products are consumable, else false
 */
function hasConsumableProducts(productList) {
  return productList.items.some((product) => product.isConsumable());
}

/**
 * @description Method to determine if any products in the list
 *            can be assigned a developer.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any products are assignable to developer, else false
 *
 */
function hasDeveloperProducts(productList) {
  return productList.items.some((product) =>
    product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  );
}

/**
 * @description Method to determine if any products in the list are
 *              enterprise.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any products are enterprise, else false
 */
function hasEnterpriseProducts(productList) {
  return productList.items.some((product) => product.isEnterprise());
}

/**
 * @description Method to determine if any products in the list are
 *              a group consumable (not organization consumable).
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any products are consumable, else false
 */
function hasGroupConsumableProducts(productList) {
  return productList.items.some((product) => product.isGroupConsumable());
}

/**
 * @description Method to determine if the products contain at least one indirect product
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if there is at least one indirect product, else false
 */
function hasIndirectProducts(productList) {
  return productList.items.some(
    (product) => product.isEnterpriseIndirect() || product.isTeamIndirect()
  );
}

/**
 * @description Method to determine if any products in the list are
 *              device licenses.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if device licenses exist, else false
 */
function hasLegacyDeviceLicenses(productList) {
  return productList.items.some((product) => product.isLegacyDeviceLicense());
}

/**
 * @description Method to determine if there is a product that corresponds with every offer.
 *
 * @param {ProductList} productList - the product list.
 * @param {Array} offers array of offers
 * @returns {Boolean} true if there is a product for every offer, else false.
 */
function hasOffers(productList, offers) {
  return offers.every((offer) =>
    productList.items.some((product) => product.offerId === offer.offer_id)
  );
}

/**
 * @description Method to determine if all of the products are team products
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if there is at least one product and they are all
 *                    team products, else false
 */
function hasOnlyTeamProducts(productList) {
  return productList.items.length > 0 && productList.items.every((product) => product.isTeam());
}

/**
 * @description Method to determine if any products in the list are
 *              a organization "consumable".
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if any products are consumable, else false
 */
function hasOrganizationConsumableProducts(productList) {
  return productList.items.some((product) => product.isOrganizationConsumable());
}

/**
 * @description Method to determine if any products in the list are
 *              packageable for deployment.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if packageable products exist, else false
 */
function hasPackageSupport(productList) {
  return productList.items.some((product) => product.fulfillableItemList.hasDesktopTypeItems());
}

/**
 * @description Returns true if this list contains any products with the specified cloud
 *   property
 *
 * @param {ProductList} productList - the product list.
 * @param {CLOUD} cloud - the CLOUD to check for (e.g. CLOUD.CREATIVE)
 * @returns {Boolean} true if this list contains
 */
function hasProductsInCloud(productList, cloud) {
  return productList.items.some((product) => product.cloud === cloud);
}

/**
 * @description Method to determine if any products in the list are
 *              eligible for product-specific support.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if products entitled to product-specific support exist, else false
 */
function hasProductSupportRoleAssignmentAllowed(productList) {
  return productList.items.some((product) => product.isProductSupportRoleAssignmentAllowed());
}

/**
 * @description Returns true if this list contains any SDL products
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if this list contains SDL
 */
function hasSDLProducts(productList) {
  return productList.items.some((product) => product.isSharedDeviceLicense());
}

/**
 * @description Method to determine if product list contains at least one passed fulfillableItems
 *
 * @param {ProductList} productList - the product list.
 * @param {Array} fulfillableItems - array of fulfillableItem code to be checked
 * @returns {Boolean} true if parameter is undefined or product list contains at least
 *   one passed fulfillableItems
 */
function hasSomeFulfillableItems(productList, fulfillableItems) {
  return (
    !fulfillableItems ||
    fulfillableItems.some((fulfillableItem) => findFulfillableItem(productList, fulfillableItem))
  );
}

/**
 * @description Method to determine if product list contains at least one passed fulfillableItems
 *
 * @param {ProductList} productList - the product list.
 * @param {Array} fulfillableItems - array of fulfillableItem code to be checked
 * @returns {Boolean} true if parameter is undefined or product list contains at least
 *   one passed fulfillableItems
 */
function hasSomeSelectedFulfillableItems(productList, fulfillableItems) {
  return (
    !fulfillableItems ||
    fulfillableItems.some((fulfillableItem) =>
      findSelectedFulfillableItem(productList, fulfillableItem)
    )
  );
}

/**
 * @description Returns true if the list contains any Stock products
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if the list contains any Stock products
 */
function hasStockProducts(productList) {
  return productList.items.some((product) => product.isAdobeStock());
}

/**
 * @description Method to determine if any products in the list are
 *              eligible for Enterprise-level support including support case creation.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if products entitled to Enterprise-level support exist, else false
 */
function hasSupportCaseCreationAllowed(productList) {
  return productList.items.some((product) =>
    product.fulfillableItemList.hasSupportCaseCreationAllowed()
  );
}

/**
 * @description Method to determine if any products in the list are
 *              eligible for Enterprise-level support.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if products entitled to Enterprise-level support exist, else false
 */
function hasSupportRoleAssignmentAllowed(productList) {
  return productList.items.some((product) =>
    product.fulfillableItemList.hasSupportRoleAssignmentAllowed()
  );
}

/**
 * @description Method to determine if any products in the list have an
 *              associated workspace.
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if products have a workspace associated with them, else false
 */
function hasWorkspaces(productList) {
  return productList.items.some((product) => product.fulfillableItemList.hasWorkspaces());
}

/**
 * @description Method to determine if products contain at least one team product
 *
 * @param {ProductList} productList - the product list.
 * @returns {Boolean} true if there is at least one team product, else false
 */
function hasTeamProducts(productList) {
  return productList.items.some((product) => product.isTeam());
}

/**
 * @description Check if this product list has any products where the provided
 *    condition is met for their fulfillable items.
 *
 * @param {ProductList} productList - the product list.
 * @param {Function} condition - a function which will be passed the fulfillableItemList
 *    and is expected to return true or false.
 * @returns {Boolean} whether the condition was met.
 */
function someByFulfillableItemCondition(productList, condition) {
  return everyProductAndSomeFulfillableItemCondition(productList, () => true, condition);
}

export {
  everyProductAndSomeFulfillableItemCondition,
  findFulfillableItem,
  findSelectedFulfillableItem,
  getAssignableLicenseCount,
  getClosestLicenseQuantityEndDateWhen,
  getClosestLicenseEndDateForStatus,
  getDeveloperProducts,
  getProductsAssignableToUsers,
  getProductsByOfferIds,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  getStorageOnlyProducts,
  getTotalProvisionedQuantity,
  getUnassignedProducts,
  hasAdministrable,
  hasAllFulfillableItems,
  hasAssignableLicenses,
  hasBuyingProgramEtlaAndVipProducts,
  hasConsumableProducts,
  hasDeveloperProducts,
  hasEnterpriseProducts,
  hasGroupConsumableProducts,
  hasIndirectProducts,
  hasLegacyDeviceLicenses,
  hasOffers,
  hasOnlyTeamProducts,
  hasOrganizationConsumableProducts,
  hasPackageSupport,
  hasProductsInCloud,
  hasProductSupportRoleAssignmentAllowed,
  hasSomeFulfillableItems,
  hasSomeSelectedFulfillableItems,
  hasStockProducts,
  hasSDLProducts,
  hasSupportCaseCreationAllowed,
  hasSupportRoleAssignmentAllowed,
  hasTeamProducts,
  hasWorkspaces,
  someByFulfillableItemCondition,
};
/* eslint-enable max-lines -- util file */
