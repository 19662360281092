const FORMAT = {
  // Storing all formats needed for Date-Fns
  DATEFNS: {
    DATE: 'yyyy-MM-dd',
    DATETIMEZONE: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    // The below formats are only to be used when you need a date-fns localized string
    // When you need and can use a React component for display purposes please use
    // React-Intl and our Binky-UI wrapper FormattedDateAndTime
    // When you need to use a string the accpeted date formatting for our apps is FORMATS.long
    // FORMATS.long is equivalent to English: February 11, 2018 or French: 11 février 2018
    FORMATS: {
      full: 'EEEE, MMMM do, y',
      long: 'MMMM do, y', // This is the default formatting for our dates
      medium: 'MMM d, y',
      short: 'MM/dd/yyyy',
    },
  },
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
  DATETIMEZONE: 'YYYY-MM-DDTHH:mm:ss.sssZ',
};
const MILLISECONDS_TO_END_DAY = 86399999;
const MINIMUM = {
  DATETIMEZONE: '1982-12-01T00:00:00.000-08:00', // beginning of day GMT+8 (Seattle)
};

export {FORMAT, MILLISECONDS_TO_END_DAY, MINIMUM};
