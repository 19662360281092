(function () {
  /**
   * @deprecated Please use src2/core/services/analytics/AnalyticsEvent.js
   *
   * @ngdoc factory
   * @name AnalyticsEvent
   * @description Model for an individual AnalyticsEvent
   */
  angular.module('binky.core.common.analytics').factory('AnalyticsEvent', getAnalyticsEventModel);

  /* @ngInject */
  function getAnalyticsEventModel($rootScope, _, ANALYTICS_EVENT) {
    class AnalyticsEvent {
      /** Constructor **/
      /**
       * @class
       * @description Creates a new AnalyticsEvent instance for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Array} options.attributes key value pair attributes to be passed along with this event
       * @param {String} options.componentMethod the method of the component generating this event
       * @param {String} options.componentMethodType the type of method generating this event
       * @param {String} options.componentName the name of the component generating this event
       * @param {Object} options.descriptors Object that contains data descriptors keyed by the name,
       * for example:
       * {
       *   cart: {
       *     payment: {payment descriptor},
       *     offerList: [offer descriptors]
       *   }
       * }
       * @param {String} options.pageContext the page context for the place this event was generated
       */
      constructor(options = {}) {
        _.assignIn(
          this,
          _.pick(options, [
            'attributes',
            'cart',
            'cgen',
            'componentMethod',
            'componentMethodType',
            'componentName',
            'descriptors',
            'pageContext',
          ])
        );
        if (!this.attributes) {
          this.attributes = {};
        }
      }

      /**
       * @description Dispatch an AnalyticsEvent on the root scope
       *
       * @param {Object} options Initialization Object (params described in the constructor)
       * @return {undefined}
       */
      static dispatch(options) {
        $rootScope.$emit(ANALYTICS_EVENT, new AnalyticsEvent(options));
      }
    }

    return AnalyticsEvent;
  }
})();
