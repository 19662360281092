import pick from 'lodash/pick';

/**
 * Global Admin policy model
 */
class GlobalAdminPolicy {
  /**
   * @description Create a new instance of GlobalAdminPolicy
   *
   * @param {Object} options Initialization options
   * @param {Boolean} options.defaultValue Policy default value
   * @param {String} options.name Policy name
   * @param {Boolean} options.value Current policy value
   */
  constructor(options) {
    Object.assign(this, pick(options, ['defaultValue', 'name', 'value']));
  }
}

export default GlobalAdminPolicy;
