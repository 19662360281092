(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.directories.domains')
    .constant('DIRECTORY_DOMAIN_LIST_CACHE_ID', 'DirectoryDomainList')
    .constant('DIRECTORY_DOMAIN_LIST_EVENT', {
      UPDATE: 'UpdateDirectoryDomainList',
      UPDATE_COUNT: 'UpdateDirectoryDomainsCount',
    });
})();
