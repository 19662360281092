(function () {
  /**
   * @deprecated. Use src2 domain.
   *
   * @ngdoc factory
   * @name binky.core.domains:Domain
   * @description model to fetch/update a domain.
   */
  angular.module('binky.core.domains').factory('Domain', getDomainModel);

  /* @ngInject */
  function getDomainModel(binkySrc2) {
    class DomainSrc2 extends binkySrc2.models.domain.Domain {
      static apiResponseTransformer(item) {
        return new DomainSrc2(item);
      }
    }

    return DomainSrc2;
  }
})();
