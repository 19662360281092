(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.badge-popover
   * @description module that defines badge-popover widgets
   */
  angular.module('binky.widgets.common.badge-popover', [
    'binky.core.lodash',
    'binky.widgets.common.badge',
    'binky.widgets.common.badge-utils',
    'binky.widgets.common.button',
    'binky.widgets.common.carousel',
    'binky.widgets.common.popover',
  ]);
})();
