(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilProductArrangements.js
   *
   * @ngdoc service/provider
   * @name jilProductArrangements
   * @description defines service to manage jil product arrangements
   */
  angular
    .module('binky.core.api.jil')
    .provider('jilProductArrangements', jilProductArrangementsProvider);

  let url;

  /* @ngInject */
  function jilProductArrangementsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations/:orgId/product-arrangements`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        metadata: getMetadataResource(),
      };

      return service;

      ///////////

      function getMetadataResource() {
        return $resource(`${url}/:productArrangementCode/metadata/:productId`, {
          orgId: apiUtils.getActiveOrg,
          productArrangementCode: '@productArrangementCode',
          productId: '@productId',
        });
      }
    }
  }
})();
