(function () {
  'use strict';
  /**
   * @deprecated use Pandora Insights components
   */
  angular
    .module('binky.widgets.insights.insights-item-detail-list-table')
    .constant('INSIGHTS_ITEM_DETAIL_COLUMN', {
      KEY: {NAME: 'name'},
      TYPE: {
        BYTE: 'Byte',
        DATE: 'Date',
        NUMBER: 'Number',
        OBJECT: 'Object',
        STATUS: 'Status',
        STRING: 'String',
        USER: 'User',
      },
    })
    .constant('INSIGHTS_ITEM_DETAIL_LIST_PAGINATION_ID', 'insightsItemDetailListPagination')
    .constant('INSIGHTS_ITEM_DETAIL_LIST_REFRESH', 'insightsItemDetail-list:refresh');
})();
