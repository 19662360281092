import {CONTRACT_BILLING_FREQUENCY, FULFILLABLE_ITEM_CHARGING_UNIT} from '@admin-tribe/binky';
import {ContentEntrySelector} from '@pandora/react-content-provider';
import {StyledPrice} from '@pandora/react-price';
import PropTypes from 'prop-types';
import React from 'react';

import BOLD_PRICE_STYLES from './BoldPrice.pcss';
import BOLD_PRICE_WITH_TAX_STYLES from './BoldPriceWithTax.pcss';
import {TAX_TERM} from './PriceConstants';

/**
 * Price - The Pandora Price wrapper that allows localization strings and styling to be passed in.
 * See https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price more details.
 * Does not currently integrate the Computed Price feature.
 * Ways to customize:
 *    - Pass in a translation prefix and ensure the locale strings are suffixed with
 *        with the keys found in the model from pandora https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price#content-model.
 *        ex: "path.to.translation.perUnitLabel": "translation string"
 *    - Pass in styles of the form found in https://git.corp.adobe.com/PandoraUI/commerce/tree/f42e68ee37325f967398329e77e04fed6554f16e/packages/react-price#custom-styling
 *        With classes named identically to https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price#supported-css-classes
 *  Translation suffixes set by default in this component:
 *    - perUnitAriaLabel
 *    - perUnitLabel
 *    - recurrenceAriaLabel
 *    - recurrenceLabel
 *    - strikethroughAriaLabel
 *    - taxExclusiveLabel
 *    - taxInclusiveLabel
 *  Translation suffixes available, but not set in this component:
 *    - alternativePriceAriaLabel
 *    - freeAriaLabel
 *    - freeLabel
 */
const Price = ({
  currency,
  customContentModel,
  isTaxInclusive,
  overrideStyles,
  perUnit,
  price,
  PriceComponent,
  taxTerm,
  recurrenceTerm,
}) => {
  const {formatString, usePrecision} = currency;
  const data = {
    formatString,
    isTaxInclusive,
    perUnit,
    price,
    recurrenceTerm,
    taxTerm,
  };

  const Component = PriceComponent || StyledPrice;
  const options = {
    styles: overrideStyles,
    usePrecision,
  };

  return (
    // If customContentModel is not defined, the default Pandora price content model will be used.
    <ContentEntrySelector model={customContentModel}>
      <Component data={data} options={options} />
    </ContentEntrySelector>
  );
};

Price.propTypes = {
  /**
   * The currency object where formatString and usePrecision are provided.
   * Other values are ignored as Pandora Price does not support them.
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }).isRequired,
  /**
   * The currency object where formatString and usePrecision are provided.
   * Other values are ignored as Pandora Price does not support them.
   */
  // eslint-disable-next-line react/forbid-prop-types -- ContentModelInterface defined in pandora
  customContentModel: PropTypes.object,
  /**
   * A flag indicating whether the price for display should be
   * treated as inclusive or exclusive of tax. The text indicating
   * whether a price is inclusive or exclusive of tax is only
   * displayed if `taxTerm` is also provided.
   */
  isTaxInclusive: PropTypes.bool,
  /**
   * CSS clases as defined by Pandora, used to make changes to the default styling
   * for reference see https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price#supported-css-classes
   */
  // eslint-disable-next-line react/forbid-prop-types -- CSS classes defined in pandora
  overrideStyles: PropTypes.object,
  /**
   * The unit for which the price is being purchased for. Either "per license" or "per transaction".
   */
  perUnit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)),
  /**
   * The price to apply formatting and styles to.
   */
  price: PropTypes.number.isRequired,
  /**
   * Any pandora price component. Used for predefined styled components in pandora.
   * https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price
   */
  PriceComponent: PropTypes.elementType,
  /**
   * The recurrenceTerm is optional and is used to determine the
   * recurrence text (e.g. `/mo` or `/yr`) to display with the price.
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The text to be used in the suffix which indicates whether the
   * price is inclusive or exclusive of tax.
   */
  taxTerm: PropTypes.oneOf(Object.values(TAX_TERM)),
};

export default Price;
// make common predefined styles available
export {BOLD_PRICE_STYLES, BOLD_PRICE_WITH_TAX_STYLES};
