import {useCollator} from '@react-aria/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedList, useIntl} from 'react-intl';

import {getUserRoleName} from 'features/user/user-roles/userRoleUtils';

/**
 * Renders a list of user roles or nothing if there are no roles.
 * If the role is not known this will throw.
 */
const UserRoles = ({isShortName = true, roles = []}) => {
  const intl = useIntl();
  const collator = useCollator();

  const getRoleDisplayNames = () =>
    roles
      .map((role) => getUserRoleName(intl, {isShortName, type: role.type}))
      .sort((a, b) => collator.compare(a, b));

  return roles.length > 0 && <FormattedList type="unit" value={getRoleDisplayNames()} />;
};

UserRoles.propTypes = {
  /**
   * If true, the short version of the role name will be used.
   * If false, the long version of the role name will be used, if it exists.
   * In some cases, there is only one version of the role name.
   * The default is true.
   */
  isShortName: PropTypes.bool,
  /**
   * An array of roles. Each item should have a type property which is one of the
   * roles handled by getUserRoleName. The default is an empty array - nothing will be rendered.
   */
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ),
};

export default UserRoles;
