import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import LinkButton from '../link-button/LinkButton';

/**
 * Truncates the text to a certain number of characters and appends an ellipsis and a "Show more" link,
 * which toggles the truncation off. A "Show less" link lets you toggle back.
 */
const ShowMoreText = ({maxLength = 140, text}) => {
  const intl = useIntl();

  const [isTextTruncated, setIsTextTruncated] = useState(false);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- glo56560@ to update
  useEffect(() => {
    setIsTextTruncated(text.length > maxLength);
  }, [text, maxLength]);

  const needsTruncation = text.length > maxLength;
  return (
    <>
      <View data-testid="show-more-text" elementType="span" marginEnd="size-100">
        {isTextTruncated ? (
          <>
            {text.slice(0, maxLength)}
            {intl.formatMessage({
              id: `binky.common.showMoreText.moreIndicator`,
            })}
          </>
        ) : (
          text
        )}
      </View>

      {needsTruncation && (
        <LinkButton
          onClick={() => {
            setIsTextTruncated(!isTextTruncated);
          }}
        >
          {intl.formatMessage({
            id: `binky.common.showMoreText.show${isTextTruncated ? 'More' : 'Less'}`,
          })}
        </LinkButton>
      )}
    </>
  );
};

ShowMoreText.propTypes = {
  /**
   * The number of characters to show before truncating. The default is 140 characters.
   */
  maxLength: PropTypes.number,
  /**
   * The text that may be truncated if its length exceeds maxlength.
   */
  text: PropTypes.string.isRequired,
};

export default ShowMoreText;
