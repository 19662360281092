import axios from 'axios';

import {getUrlSearchParams} from 'utils/urlUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * Configure JIL organizations APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL organizations api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Deletes an organization
 *
 * @param {String} orgId - The organization to delete
 * @returns {Promise} Resolves to the axios response object
 */
const deleteOrganizations = ({orgId}) =>
  axios.delete(`${url}/v2/organizations/${orgId}`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Fetches all organizations for the auth'd user or a specific org if orgId is provided
 *
 * @param {String} [orgId] - The org id
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {Boolean} [includeHasChildren] - true if the presence of child orgs should be fetched
 * @param {Boolean} [includeTermsAcceptances] - true if org-level terms acceptances should be fetched
 * @param {Array<String>} [templateNames] - an array of template names for which to fetch term acceptances if includeTermsAcceptances is true
 * @returns {Promise} Resolves to the axios response object whose data contains an array of Organizations or a single Organization
 */
const getOrganizations = ({
  orgId,
  includeAncestors,
  includeHasChildren,
  includeTermsAcceptances,
  templateNames,
} = {}) => {
  const defaultParams = 'delegation_groups_migration_status,renga_tags,rso_values';
  let paramValue;

  // optional params
  if (includeAncestors) {
    paramValue = 'ancestors';
  }
  if (includeHasChildren) {
    paramValue = paramValue ? `${paramValue},has_children` : 'has_children';
  }

  if (includeTermsAcceptances) {
    paramValue = paramValue ? `${paramValue},terms_acceptances` : 'terms_acceptances';
  }

  // default params
  paramValue = paramValue ? `${paramValue},${defaultParams}` : defaultParams;

  // params of form: include=param1,param2,etc
  const params = {include: paramValue, template_names: templateNames?.join(',')};
  return axios.get(`${url}/v2/organizations/${orgId || ''}`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });
};

/**
 * Fetches all organizations by admin email
 *
 * @param {String} [adminEmail] - The admin email to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByAdminEmail = ({adminEmail, includeAncestors, orgType}) =>
  axios.post(
    `${url}/v2/organizations:search.admin_email`,
    getUrlSearchParams({
      admin_email: adminEmail,
      include_ancestors: includeAncestors,
      org_type: orgType,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...getHeaders({clientId, includeRoles}),
      },
    }
  );

/**
 * Fetches all organizations by admin id
 *
 * @param {String} [adminId] - The admin id to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByAdminId = ({adminId, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.admin_id`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      admin_id: adminId,
      include_ancestors: includeAncestors,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by claimed domain
 *
 * @param {String} [claimedDomain] - The claimed domain to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByClaimedDomain = ({claimedDomain, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.claimed_domain`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      claimed_domain: claimedDomain,
      include_ancestors: includeAncestors,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by contract id
 *
 * @param {String} [contractId] - The contract id to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByContractId = ({contractId, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.contract`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      id: contractId,
      include_ancestors: includeAncestors,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by ecc end user id
 *
 * @param {String} [eccEndUserId] - The ecc end user id to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByEccEndUserId = ({eccEndUserId, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.ecc_end_user_id`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      ecc_end_user_id: eccEndUserId,
      include_ancestors: includeAncestors,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by exact name
 *
 * @param {String} [name] - The exact name to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByExactName = ({name, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.name`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      include_ancestors: includeAncestors,
      name,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by an external contract id
 *
 * @param {String} [externalContractId] - The external contract id to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByExternalContractId = ({externalContractId, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.external_contract`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      id: externalContractId,
      include_ancestors: includeAncestors,
      org_type: orgType,
    },
  });

/**
 * Fetches all organizations by tokenized name
 *
 * @param {String} [tokenizedName] - The tokenized name to filter orgs by
 * @param {Boolean} [includeAncestors] - true if the ancestor orgs should be fetched
 * @param {ORGANIZATION_TYPE} [orgType] - if provided, the type of org that this search should
 *   be restricted to
 * @returns {Promise} Resolves to the axios response object whose data contains the organizations
 */
const getOrganizationsByTokenizedName = ({tokenizedName, includeAncestors, orgType}) =>
  axios.get(`${url}/v2/organizations:search.tokenized_name`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      include_ancestors: includeAncestors,
      org_type: orgType,
      tokenized_name: tokenizedName,
    },
  });

/**
 * Creates an organization
 *
 * @param {Organization} org - The organization to create
 * @returns {Promise} Resolves to the axios response object whose data contains the organization
 */
const postOrganizations = (org) =>
  axios.post(`${url}/v2/organizations`, org, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Updates an organization
 *
 * @param {Object} org - The organization to update
 * @returns {Promise} Resolves to the axios response object whose data contains the organization
 */
const putOrganizations = (org) =>
  axios.put(`${url}/v2/organizations/${org.id}`, org, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilOrganizations = {
  configure,
  deleteOrganizations,
  getOrganizations,
  getOrganizationsByAdminEmail,
  getOrganizationsByAdminId,
  getOrganizationsByClaimedDomain,
  getOrganizationsByContractId,
  getOrganizationsByEccEndUserId,
  getOrganizationsByExactName,
  getOrganizationsByExternalContractId,
  getOrganizationsByTokenizedName,
  postOrganizations,
  putOrganizations,
};

export default jilOrganizations;
