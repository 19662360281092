import pick from 'lodash/pick';

/**
 * @description This class represents properties related to a cancellation order
 */
class GainsightEventDescriptor {
  /**
   * @description Constructor
   *
   * @param {Object} [options] Initialization options
   * @param {Object} [options.engagement] An object representing the specific Gainsight engagement
   * @param {String} [options.engagement.activation] One of 'Auto', 'Bot', 'Badge', 'Journey', 'Orchestration'
   * @param {String} [options.engagement.comment] The user comment (surveyResponded events)
   * @param {String} [options.engagement.engagementId] Gainsight PX engagement UUID
   * @param {String} [options.engagement.engagementName] Gainsight PX engagement name
   * @param {String} [options.engagement.engagementType] One of 'Dialog', 'Slider', 'Guide', 'NPS', 'CES', Rating', 'Boolean'
   * @param {String} [options.engagement.executionId] Runtime UUID - in case of interval / paywall scheduling
   * @param {Number} [options.engagement.executionTimestamp] Runtime timestamp (unix timestamp)
   * @param {String} [options.engagement.identifyId] User identifier
   * @param {String} [options.engagement.interaction] One of 'Viewed', 'Completed', 'Responded', 'Viewed Step'
   * @param {Number} [options.engagement.score] Score the user inserted depends on the type of survey  (surveyResponded events)
   * @param {String} [options.engagement.scoreType] For NPS: 'Promoter' (9-10), 'Passive' (7-8), 'Detractor' (0-6)  (surveyResponded events)
   * @param {Number} [options.engagement.stepNumber] Step sequence number (engagementViewedStep events)
   * @param {Object} [options.step] An object representing a specific step (linkClick and customButtonLink events)
   * @param {String} [options.step.stepId] Unique identification number for the step
   * @param {String} [options.step.stepName] The name given to the step
   * @param {String} [options.url] The url for the interaction (linkClick and customButtonLink events)
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['engagement', 'step', 'url']));
  }
}

export default GainsightEventDescriptor;
