import ConsumableSummary from './ConsumableSummary';

class ConsumableSummaryList {
  /**
   * @description Creates a new ConsumableSummaryList instance.
   *
   * @param {ConsumableSummary[]} items - ConsumableSummary entities which make
   *     up this list
   * @param {ConsumableSummarization} parentSummarization - the parent
   *     summarization for this summary list
   */
  constructor(items, parentSummarization) {
    this.items = items?.map((item) =>
      ConsumableSummary.apiResponseTransformer(item, parentSummarization)
    );
  }

  /**
   * @description Find any Consumable objects that are for the specified
   *     fulfillable item code
   *
   * @param {string} fiCode - the fulfillable item code for which to find summaries
   *
   * @returns {Consumable[]} the Consumables that are for the fulfillable item code
   */
  getConsumablesForFulfillableItemCode(fiCode) {
    return this.items.reduce(
      (consumables, consumableSummary) => [
        ...consumables,
        ...consumableSummary.getConsumablesForFulfillableItemCode(fiCode),
      ],
      []
    );
  }

  /**
   * @description Find any ConsumableSummary objects that are for the specified ID
   *
   * @param {string} id - the ID for which to find summaries
   *
   * @returns {ConsumableSummary[]} - items that are for the ID
   */
  getSummariesForId(id) {
    return this.items.filter((item) => item.isSummaryForId(id));
  }
}

export default ConsumableSummaryList;
