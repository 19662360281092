(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.directories.status-helper
   * @description module that defines directory widgets
   */
  angular.module('binky.widgets.directories.status-helper', [
    'binky.core.lodash',
    'binky.widgets.directories.status',
  ]);
})();
