(function () {
  /**
   * @deprecated please use src2/core/services/cart/CartConstants.js
   */
  angular.module('binky.core.cart').constant('CART_EVENT', {
    RENEWAL_ORDER_REFRESHED: 'RenewalOrderRefreshed',
    SUBMIT: 'CartSubmit',
    SUBMIT_RENEWAL_ORDER: 'CartSubmitRenewalOrder',
  });
})();
