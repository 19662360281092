/* eslint-disable max-lines -- needed more lines */
import cloneDeep from 'lodash/cloneDeep';
import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import remove from 'lodash/remove';

import jilUsers from 'api/jil/jilUsers';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import MemberType from 'models/member/type/MemberType';
import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import {
  getProductLicensePatches,
  getUserGroupPatches,
} from 'models/organizationUser/organizationUserUtils';
import User from 'models/user/User';
import {
  compareAdminRoles,
  explodeAdminRoles,
  getAdminRolePatches,
} from 'models/user/roles/userRoleUtils';
import modelCache from 'services/cache/modelCache/modelCache';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import {getListCacheKey} from 'utils/cacheUtils';
import {compareArrays} from 'utils/jsUtils';

import {ORGANIZATION_USER_API_QUERY_PARAMS} from './OrganizationUserConstants';

// the nested objects (products, roles, userGroups) are tracked separately
const EDITABLE_FIELDS = ['countryCode', 'email', 'firstName', 'lastName', 'userName'];
const ORGANIZATION_USER_2_CACHE_ID = 'OrganizationUser2';

class OrganizationUser extends User {
  static canTransform(data) {
    const memberType = new MemberType(data.type, data.id);
    return memberType.isUser();
  }

  /**
   * @description Method to retrieve an existing OrganizationUser from
   *              back-end data store.
   *
   * @param {Object} options options for the OrganizationUser as detailed below
   * @param {String} options.orgId orgId that will be provided when the OrganizationUser is being created
   * @param {ORGANIZATION_USER_API_QUERY_PARAMS} [include] - Optional, determine if products, user group products, or user creation source
   * are included (separated by commas) Possible values are PRODUCTS, USER_GROUP_PRODUCTS, USER_CREATION_SOURCE, USER_EDU_ROLE_TAGS
   * @param {String} options.userId ID of the user to retrieve for this org
   *
   * @returns {Promise<OrganizationUser>} Reference to pre-existing user
   */
  static get(options) {
    const {orgId, include, userId} = options;
    const model = new this({
      id: userId,
      include,
      orgId,
    });

    const key = model.key();
    if (modelCache.has(ORGANIZATION_USER_2_CACHE_ID, key)) {
      const cachedPromise = modelCache.get(ORGANIZATION_USER_2_CACHE_ID, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    return model.refresh();
  }

  /**
   * @description Method to retrieve the patch operations for a user
   *
   * @param {OrganizationUser} user the user to update
   *
   * @returns {Array<Object>} An array of patch operation objects with an "op" and "path" field.
   */
  static getPatchOperationsForUpdate(user) {
    let operations = EDITABLE_FIELDS.filter((key) => user[key] !== user.savedState[key]).map(
      (key) => ({op: 'replace', path: `/${user.id}/${key}/${user[key]}`})
    );

    operations = combineOperations({operations, user});
    return operations;
  }

  /**
   * @description Creates a new OrganizationUser.
   *
   * @param {Object} options options for the OrganizationUser as detailed below
   * @param {String} [options.id] An OrganizationUser's ID
   * @param {String} [options.countryCode] An OrganizationUser's country code
   * @param {Boolean} [options.editable] True if User is editable
   * @param {String} [options.email] An OrganizationUser's email address
   * @param {String} [options.firstName] An OrganizationUser's first name
   * @param {String[]} [options.include] Optional array potentially consisting of query params to pass to the Users API
   * Possible values include PRODUCTS, USER_GROUP_PRODUCTS, USER_CREATION_SOURCE, USER_EDU_ROLE_TAGS
   * @param {String} [options.lastName] An OrganizationUser's last name
   * @param {Array} [options.licenseGroups] An OrganizationUser's configs.
   *       This field is only returned for Product Users
   * @param {String} options.orgId An orgId that will be provided when the OrganizationUser is being created
   * @param {Array} [options.products] An OrganizationUser's products, with nested configs.
   *       This field is returned for the org user list, and the single user fetch.
   * @param {String} [options.provisioningStatus] User's provisioning status for the choosen product.
   * Possible values include PROVISIONED, UNPROVISIONED
   * @param {Boolean} [options.removable] True if user can be removed.
   * @param {Array} [options.roles] An OrganizationUser's roles. This is returned for the
   *       single user fetch.
   * @param {String} [options.type] An OrganizationUser's type
   * @param {Array} [options.userGroups] An OrganizationUser's user groups. This is returned
   *       for the single user fetch.
   * @param {String} [options.userName] An OrganizationUser's user name
   */
  constructor(options) {
    super(options);
    this.orgId = options.orgId;
    this.include = options.include || [ORGANIZATION_USER_API_QUERY_PARAMS.PRODUCTS];
    this.provisioningStatus = options.provisioningStatus;
    this.removable = options.removable;
    this.registerSavedState();
  }

  async delete() {
    const operations = [{op: 'remove', path: `/${this.id}`}];

    try {
      await this.performBatchOperation(operations);
    } catch (error) {
      log.error(
        `OrganizationUser.delete(): Failed to delete data on back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }

    eventBus.emit(MEMBER_EVENT.DELETE);

    return this;
  }

  /**
   * @description Method to get any unsaved role changes to this object.
   *
   * @returns {Object} the altered state
   * @property {Array} added - the unsaved role adds, else empty
   * @property {Array} removed - the unsaved role removes, else empty
   */
  getUnsavedRoleChanges() {
    return {
      added: differenceWith(this.roles, this.savedState.roles, compareAdminRoles),
      removed: differenceWith(this.savedState.roles, this.roles, compareAdminRoles),
    };
  }

  /**
   * @description Method to determine if there are any unsaved changes to this object.
   *
   * @returns {Boolean} true if there are unsaved changes, else false
   */
  hasUnsavedChanges() {
    const savedBaseState = pick(this.savedState, EDITABLE_FIELDS);
    return (
      !isEqual(savedBaseState, pick(this, EDITABLE_FIELDS)) ||
      !compareArrays(
        explodeAdminRoles(this.savedState.roles),
        explodeAdminRoles(this.roles),
        compareAdminRoles
      ) ||
      !isEmpty(getProductLicensePatches(this)) ||
      !isEmpty(getUserGroupPatches(this)) ||
      !isEmpty(getAdminRolePatches(this))
    );
  }

  /**
   * @description Method to determine if the user is removable
   *   The current check in JIL is !externally managed && user can be removed from authSrc.
   *
   * @return {Boolean} true if user is removable, false otherwise.
   */
  isRemovable() {
    return this.removable;
  }

  key() {
    return getListCacheKey(
      omitBy(
        {
          id: this.id,
          include: this.include,
          orgId: this.orgId,
          removable: this.removable,
        },
        (e) => e === undefined
      )
    );
  }

  /**
   * @description Method to perform batch operations against this user.
   *
   * @param {Array} operations - list of operations to perform in batch
   *
   * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg
   */
  async performBatchOperation(operations) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- existing code
    // istanbul ignore else
    if (operations && operations.length > 0) {
      try {
        await jilUsers.patchUsers({operations, orgId: this.orgId});
      } catch (error) {
        log.error(
          'OrganizationUser.performBatchOperation() failed to perform batch operation(s). Error: ',
          error
        );
        return Promise.reject(error);
      }
    }
    return this;
  }

  /**
   * @description Method to refresh this user's state from the back-end.
   *
   * @returns {Promise<OrganizationUser>} resolves to fetched OrganizationUser instance, else
   *                                      rejects with error message
   */
  async refresh() {
    let response;
    const refreshParams = omitBy(
      {
        include: this.include.join(','),
        orgId: this.orgId,
        userId: this.id,
      },
      (id) => id === undefined
    );

    try {
      response = await jilUsers.getUsers(refreshParams);
    } catch (error) {
      log.error(
        `OrganizationUser.refresh(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      return Promise.reject(error);
    }
    // Due to a possible bug in JIL, getUsers() API will not set the "roles" attribute
    // at all when the user has no roles, instead of the more intuitive empty-array.
    // This can cause assign() to miss overwriting roles in the existing object, if ignored.

    Object.assign(this, response.data);

    if (response.data && response.data.roles === undefined) {
      this.roles = [];
    }
    this.registerSavedState();

    const key = this.key();
    modelCache.put(ORGANIZATION_USER_2_CACHE_ID, key, Promise.resolve(this));

    return this;
  }

  /**
   * @description Updates model with a nested form of itself recording state
   *     which may be later modified.
   */
  registerSavedState() {
    if (this.isNew()) {
      this.savedState = {products: [], roles: [], userGroups: []};
    } else {
      // eslint-disable-next-line lodash/chaining -- not possible with tree shaking
      this.savedState = cloneDeep(pick(this, EDITABLE_FIELDS));
      this.savedState.products = cloneDeep(this.products);
      this.savedState.roles = cloneDeep(this.roles);
      this.savedState.userGroups = cloneDeep(this.userGroups);
    }
  }

  /**
   * @description Restores the user from its saved state
   */
  restore() {
    Object.assign(this, this.savedState);
  }

  /**
   * @description Method to save changes to the organization user to the back-end.
   *
   * @param {Object} [options] options provided to the save method
   * @param {File} [options.avatar] user avatar to be uploaded
   * @param {String} [options.orgId] orgId to supply to the provider making the API call (for create user only)
   *
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message
   */
  async save(options = {}) {
    const avatar = options.avatar;
    const orgId = options.orgId || this.orgId;
    try {
      if (this.isNew()) {
        // this does not yet exist and is a create
        const userModel = this.toMinimumModel();
        userModel.type = this.type;
        if (userModel.type === MEMBER_TYPE.TYPE1) {
          delete userModel.countryCode;
        }
        const response = await jilUsers.postUsers({
          orgId,
          userModel,
        });

        eventBus.emit(MEMBER_EVENT.CREATE, this.id);

        Object.assign(this, response.data);

        this.registerSavedState();
      } else if (this.hasUnsavedChanges() || avatar) {
        // this already exists and is an update
        if (avatar) {
          const formdata = new FormData();
          formdata.append('image', avatar);
          await jilUsers.postAvatar({
            formdata,
            orgId,
            userId: this.id,
          });
        }
        let operations = EDITABLE_FIELDS.map((fieldKey) => {
          if (this[fieldKey] !== this.savedState[fieldKey]) {
            const authId =
              this.type === MEMBER_TYPE.TYPE2E ? this.authenticatingAccount.id : this.id;
            return {
              op: 'replace',
              path: `/${authId}/${fieldKey}/${this[fieldKey]}`,
            };
          }
          return undefined;
        });

        operations = combineOperations({operations, user: this});
        await this.performBatchOperation(operations.filter(Boolean));

        eventBus.emit(MEMBER_EVENT.UPDATE, this.id);

        modelCache.remove(ORGANIZATION_USER_2_CACHE_ID, this.key());

        this.registerSavedState();
      }
    } catch (error) {
      log.error('Failed to create/update selected organization user. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description Method to transform model into the smallest representation.
   *   This helps reduce the amount of traffic our server has to deal with, in
   *   addition to altering models to conform to server/API expectations (in
   *   many cases).
   *
   * @returns {Object} minimum necessary representation of model
   */
  toMinimumModel() {
    const minimumModel = pick(this, EDITABLE_FIELDS);
    minimumModel.id = this.id;
    minimumModel.type = this.type;
    minimumModel.products = this.products.map((product) => toMinimumModelIfPossible(product));
    minimumModel.roles = this.roles.map((role) => toMinimumModelIfPossible(role));
    minimumModel.userGroups = this.userGroups.map((userGroup) =>
      toMinimumModelIfPossible(userGroup)
    );

    // The product's minimum model doesn't include license groups, add it back
    minimumModel.products.forEach((product) => {
      product.licenseGroups = this.products.find((p) => p.id === product.id).licenseGroups;

      product.licenseGroups = product.licenseGroups?.map((licenseGroup) =>
        toMinimumModelIfPossible(licenseGroup)
      );
    });

    // Remove products with no license groups
    remove(minimumModel.products, ['licenseGroups.length', 0]);

    // Since the user can have raw nested objects or model objects, only call toMinimumModel() if we can.
    function toMinimumModelIfPossible(obj) {
      if (obj.toMinimumModel && typeof obj.toMinimumModel === 'function') {
        return obj.toMinimumModel();
      }
      return obj;
    }

    return minimumModel;
  }
}

/**
 * @description Method to combine all operations and remove duplicates
 *
 * @param {Object} options
 * @param {Array} options.operations - list of operations to perform in batch
 * @param {OrganizationUser} options.user the user to update
 *
 * @returns {Array} array with all operations
 */
function combineOperations({operations, user}) {
  // This is equivalent to a union - Set removes duplicates.
  return [
    ...new Set([
      ...operations,
      ...getAdminRolePatches(user),
      ...getProductLicensePatches(user),
      ...getUserGroupPatches(user),
    ]),
  ];
}

export default OrganizationUser;
export {ORGANIZATION_USER_2_CACHE_ID};
/* eslint-enable max-lines -- needed more lines */
