import {Content, IllustratedMessage, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

const {DEFAULT_MSG_WIDTH} = ASSIGNMENT_MENU_CONSTANTS;

// A component wrapper to show the end of the paginated search results. Shared between menus within AssignmentMenu.
const MenuEndOfResults = ({itemCount, showSearchPrompt, totalItemCount}) => (
  <View data-testid="menu-end-of-results" height="size-700" width={DEFAULT_MSG_WIDTH}>
    <IllustratedMessage>
      {/* No illustration, only using IllustratedMessage for Spectrum styling */}
      <Content>
        <FormattedMessage
          id="binky.common.assignmentMenu.shared.messages.truncatedResults.content1"
          values={{
            itemCount,
            totalItemCount,
          }}
        />
        {showSearchPrompt && (
          <span data-testid="search-prompt">
            <br />
            <FormattedMessage id="binky.common.assignmentMenu.shared.messages.truncatedResults.content2" />
          </span>
        )}
      </Content>
    </IllustratedMessage>
  </View>
);

MenuEndOfResults.propTypes = {
  itemCount: PropTypes.number.isRequired,
  showSearchPrompt: PropTypes.bool.isRequired,
  totalItemCount: PropTypes.number.isRequired,
};

export default MenuEndOfResults;
