import hash from 'object-hash';

/**
 * @description Returns a key that uniquely identifies an model list
 *    in a given cache.
 *
 * @param {Object} params - the request parameters used to populate
 *                 the cached list.
 * @returns {String} a cache key that is unique within the cache
 */
function getListCacheKey(params) {
  return hash(params);
}

export {getListCacheKey};
