(function () {
  /**
   * @ngdoc module
   * @name binky.core.contract
   * @description Module which defines contract-related models
   */
  angular.module('binky.core.contract', [
    'angularMoment',
    'binky.core.api.jil',
    'binky.core.common.model.cache',
    'binky.core.contract.migrations',
    'binky.core.date-utils',
    'binky.core.email-utils',
    'binky.core.feature',
    'binky.core.js-utils',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.price',
    'binky.core.promise-utils',
    'binky.core.src2-migration',
    'binky.core.user.authenticated-user',
  ]);
})();
