(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.comments.delete
   * @description module that defines comment delete widgets
   */
  angular.module('binky.widgets.common.comments.delete', [
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.confirmation-modal',
    'pascalprecht.translate',
  ]);
})();
