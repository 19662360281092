(function () {
  /**
   * @ngdoc module
   * @name binky.core.url-utils
   * @description Module which defines url utility helpers
   */
  angular.module('binky.core.url-utils', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
