(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('binky.widgets.domains.status').constant('DOMAIN_STATUS_LABEL_KEY', {
    // one for each of DOMAIN_STATUS plus ALL_STATUSES
    ACTIVE: 'active',
    ALL_STATUSES: 'allStatuses', // for the domain status selector (not shown in the table)
    NEEDS_APPROVAL: 'needsApproval',
    NEEDS_DIRECTORY: 'needsDirectory',
    NEEDS_VALIDATION: 'needsValidation',
    WITHDRAWN: 'withdrawn',
  });
})();
