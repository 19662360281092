(function () {
  'use strict';
  /**
   * @deprecated Please use src2/common/components/page-banner/PageBanner.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.page-banner:binkyPageBanner
   *
   * @description A page banner widget with a button. The 'info' and 'help' modals will default to having a 'Close'
   *   button which "closes" the banner by removing it from the page.
   *
   * @param {String} body Translated body text of the page banner. If the body changes, the banner is re-created with
   *   the new body.
   * @param {String} [buttonText] Translated button text. For variants 'info' and 'help', defaults to 'Close'.
   * @param {String} [header] Translated header text of the page banner. If header is '', the header is hidden.
   * @param {Function} [onBodyLinkClick] Callback function called when link in body is clicked.
   * @param {Function} [onButtonClick] Callback function called when button is clicked. Defaults to closing the banner.
   * @param {String} [removeWithKey] If the key is defined in local storage, then the banner will not be rendered.
   *   Otherwise, banner will be rendered.
   * @param {Boolean} [showDefaultButton] Default true. If true and no buttonText specified, will add the default close
   *   button for variants 'info' and 'help'. If false, and no buttonText specified, there will be no button added.
   * @param {String} [variant] Variant type of the page banner. One of: 'error', 'warning', 'success', 'help', 'info'.
   *   Defaults to 'info'.
   *
   * @example <binky-page-banner
   *            body="Body text"
   *            button-text="Button text"
   *            header="Header text"
   *            on-body-link-click="onBodyLinkClick()"
   *            on-button-click="handleButtonClick()"
   *            remove-with-key="KEY"
   *            variant="info">
   *         </binky-page-banner>
   *
   */
  angular.module('binky.widgets.common.page-banner').component('binkyPageBanner', {
    bindings: {
      body: '@',
      buttonText: '@?',
      header: '@?',
      onBodyLinkClick: '&?',
      onButtonClick: '&?',
      removeWithKey: '@?',
      showDefaultButton: '<?',
      variant: '@?',
    },
    controller,
  });

  /* @ngInject */
  function controller(
    $compile,
    $element,
    $scope,
    $translate,
    _,
    PAGE_BANNER_VARIANT,
    storageUtils
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      handleButtonClick,
    });

    function $onChanges(changesObj) {
      // The banner body changed. Create or re-create the alert with the new body.
      if (changesObj.body) {
        setDefaults();
        appendOrRemoveAlert();
      }
    }

    function createAlert() {
      const coralAlert = new Coral.Alert();
      coralAlert.size = 'L';
      coralAlert.variant = vm.variant;

      const alertElement = angular.element(coralAlert);
      alertElement.addClass('binky-page-banner');

      const alertHeaderElement = angular.element(coralAlert.header);
      alertHeaderElement.addClass('binky-page-banner-header');
      alertHeaderElement.text(vm.header);

      const alertContentElement = angular.element(coralAlert.content);
      alertContentElement.addClass('binky-page-banner-content');

      alertContentElement.append(createBody());
      if (vm.buttonText) {
        alertContentElement.append(createButton());
      }

      return alertElement;
    }

    function createBody() {
      // Update the body text to prevent potential JS injections
      // by replacing any curly brackets with the HTML code.
      // The curly brackets suggests to the complier
      // that there is injected JS in the string.
      let safeBody = _.replace(vm.body, /{./g, '&lcub');
      safeBody = _.replace(safeBody, /}./g, '&rcub');
      return $compile(`<div class="binky-page-banner-body-container">${safeBody}</div>`)($scope);
    }

    function createButton() {
      const containerEl = angular.element('<div class="binky-page-banner-button-container"></div>');
      const button = `<binky-button class="binky-page-banner-button"
                        ng-click="$ctrl.handleButtonClick()"
                        variant="quiet">{{ $ctrl.buttonText }}</binky-button>`;

      containerEl.append($compile(button)($scope));
      return containerEl;
    }

    function appendOrRemoveAlert() {
      if (vm.removeWithKey && storageUtils.getLocalStorageItem(vm.removeWithKey)) {
        remove();
      } else {
        const alert = createAlert();
        $element.empty().append(alert);
      }
    }

    function handleButtonClick() {
      _.invoke(vm, 'onButtonClick');
      if (vm.removeWithKey) {
        storageUtils.setLocalStorageItem(vm.removeWithKey, true);
      }
    }

    function remove() {
      $element.remove();
    }

    function setDefaults() {
      vm.variant = vm.variant || PAGE_BANNER_VARIANT.INFO;

      // Default to constructing a Close button for help and info variants.
      const showDefaultButton = _.defaultTo(vm.showDefaultButton, true);
      if (
        showDefaultButton &&
        (vm.variant === PAGE_BANNER_VARIANT.HELP || vm.variant === PAGE_BANNER_VARIANT.INFO)
      ) {
        if (!vm.buttonText) {
          vm.buttonText = $translate.instant('binky.widgets.common.pageBanner.close');
        }

        if (!vm.onButtonClick) {
          vm.onButtonClick = remove;
        }
      }
    }
  }
})();
