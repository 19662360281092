(function () {
  /**
   * @deprecated Please use src2/core/models/member/MemberConstants.js
   */
  angular.module('binky.core.common.member').constant(
    'MEMBER_EVENT',
    // eslint-disable-next-line angular/window-service
    window.binkySrc2.models.member.MEMBER_CONSTANTS.MEMBER_EVENT
  );
})();
