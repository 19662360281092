(function () {
  /**
   * @deprecated Please use src2/core/utils/emailUtils.js
   */
  angular.module('binky.core.email-utils').factory('emailUtils', emailUtils);

  /* @ngInject */
  function emailUtils(binkySrc2) {
    return binkySrc2.utils.emailUtils;
  }
})();
