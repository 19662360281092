import binky from '@admin-tribe/binky';
import {TARGET_TYPE as ASSIGNMENT_MODAL_TARGET_TYPE} from '@pandora/react-assignment-modal';

import ASSIGNMENT_MENU_CONSTANTS from 'common/components/assignment-menu/AssignmentMenuConstants';

const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const UserGroup = binky.services.users.UserGroup;
const Product = binky.services.product.Product;
const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cawright@ to update
const itemToTargetType = (item, targets) => {
  if (item instanceof UserGroup) {
    return TARGET_TYPE.USER_GROUPS;
  }

  if (item instanceof Product) {
    return binky.services.feature.isEnabled('temp_parkour_mm') &&
      targets?.includes(TARGET_TYPE.PRODUCT_SUPPORTS)
      ? TARGET_TYPE.PRODUCT_SUPPORTS
      : TARGET_TYPE.PRODUCTS;
  }

  if (item instanceof LicenseGroup) {
    return TARGET_TYPE.PRODUCT_PROFILES;
  }
  return null;
};

/**
 * Converts Binky-UI TARGET_TYPE values to equivalent Pandora TARGET_TYPEs, filtering for only Product/Profile related values
 * @param {TARGET_TYPE[]} targets The targets for Binky-UI's Assignment components
 * @returns Array of the Pandora TARGET_TYPE equivalents that are applicable for Product assignment
 */
const toPandoraProductTargets = (targets) => {
  const productTargets = [];
  if (targets?.includes(TARGET_TYPE.PRODUCTS) || targets?.includes(TARGET_TYPE.PRODUCT_SUPPORTS)) {
    productTargets.push(ASSIGNMENT_MODAL_TARGET_TYPE.PRODUCTS);
  }
  if (targets?.includes(TARGET_TYPE.PRODUCT_PROFILES)) {
    productTargets.push(ASSIGNMENT_MODAL_TARGET_TYPE.PRODUCT_PROFILES);
  }
  return productTargets;
};

/**
 * Converts Binky-UI TARGET_TYPE values to equivalent Pandora TARGET_TYPEs, filtering for only User Group related values
 * @param {TARGET_TYPE[]} targets The targets for Binky-UI's Assignment components
 * @returns Array of the Pandora TARGET_TYPE equivalents that are applicable for User Group assignment
 */
const toPandoraUserGroupTargets = (targets) =>
  targets?.includes(TARGET_TYPE.USER_GROUPS) ? [ASSIGNMENT_MODAL_TARGET_TYPE.USER_GROUPS] : [];

export {itemToTargetType, toPandoraProductTargets, toPandoraUserGroupTargets};
