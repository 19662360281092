import omitBy from 'lodash/omitBy';

import jilContracts from 'api/jil/jilContracts';
import Contract from 'models/contract/Contract';
import {CONTRACT_EVENT} from 'models/contract/ContractConstants';
import {CONTRACT_LIST_CACHE_ID, CONTRACT_LIST_EVENT} from 'services/contract/ContractListConstants';
import eventBus from 'services/events/eventBus';
import feature from 'services/feature';
import log from 'services/log';
import JilModelList from 'services/modelList/JilModelList';

class ContractList extends JilModelList {
  /**
   * @description Method to transform API responses from a ContractList fetch
   *   operation into actual Contract instances inside of the List. This is
   *   necessary since new Contract instances do not have an id value. So, we
   *   need a custom method here to inject the legacy DX value when fetching an
   *   existing Contract that also does not have an id (i.e. - legacy DX
   *   contract).
   * @param {Object} responseData - JSON of fetched list of contracts from
   *   server
   * @returns {Contract[]} new Array of Contract instances, representing
   *   external Contract list state/data
   */
  static apiResponseTransformer(responseData) {
    return responseData.map((responseItem) => Contract.apiResponseTransformer(responseItem));
  }

  /**
   * @description Method to create a new instance of a ContractList.
   * @param {Object} options - configures the ContractList model instance.
   * @param {Boolean} [options.includeAutoRenewal] - whether or not to return autoRenewal Object
   *   Defaults to false.
   * @param {Boolean} [options.includeInactiveContracts] - whether or not to return Contracts with
   *   status INACTIVE. Defaults to true.
   * @param {Boolean} [options.includeTermsAcceptances] - whether or not to return TermsAcceptance
   *   information. Defaults to false.
   * @param {String} [options.orgId] - associates ContractList instance with an org.
   */
  constructor({
    isCacheable = true,
    orgId,
    includeAutoRenewal = false,
    includeInactiveContracts = true,
    includeTermsAcceptances = false,
  } = {}) {
    super({
      cacheClearingEvents: [CONTRACT_EVENT.UPDATE],
      isCacheable,
      itemClassRef: Contract,
      modelCacheId: CONTRACT_LIST_CACHE_ID,
      resource: jilContracts.getContracts,
      transformResponseData: ContractList.apiResponseTransformer,
    });
    this.orgId = orgId;

    this.includeInactiveContracts = includeInactiveContracts;
    if (feature.isEnabled('temp_terms_redirect')) {
      this.includeTermsAcceptances = includeTermsAcceptances;
    }
    if (feature.isEnabled('auto_renewal_opt_in_self_service')) {
      this.includeAutoRenewal = includeAutoRenewal;
    }
  }

  /**
   * @description Method to return the current unique key for this list.
   *
   * @returns {String} key to uniquely identify this list
   */
  getKey() {
    const options = feature.isEnabled('temp_terms_redirect')
      ? {
          includeInactiveContracts: this.includeInactiveContracts,
          includeTermsAcceptances: this.includeTermsAcceptances,
          orgId: this.orgId,
        }
      : {
          includeInactiveContracts: this.includeInactiveContracts,
          orgId: this.orgId,
        };

    if (feature.isEnabled('auto_renewal_opt_in_self_service')) {
      options.includeAutoRenewal = this.includeAutoRenewal;
    }

    return super.getKey(options);
  }

  /**
   * @description Method to fetch the contract list
   * @returns {Promise} promise - promise object, resolved when the contract list is loaded.
   */
  async refresh() {
    const options = feature.isEnabled('temp_terms_redirect')
      ? {
          includeInactiveContracts: this.includeInactiveContracts,
          includeTermsAcceptances: this.includeTermsAcceptances,
          orgId: this.orgId,
        }
      : {
          includeInactiveContracts: this.includeInactiveContracts,
          orgId: this.orgId,
        };

    if (feature.isEnabled('auto_renewal_opt_in_self_service')) {
      options.includeAutoRenewal = this.includeAutoRenewal;
    }

    try {
      await super.refresh(
        omitBy(options, (value) => value === undefined),
        {
          onSuccessPostTransform: () => {
            this.items.forEach((contract) => contract.setOrgId(this.orgId));
          },
        }
      );
    } catch (error) {
      log.error('ContractList.refresh() failed. Error: ', error);
      return Promise.reject(error);
    }

    eventBus.emit(CONTRACT_LIST_EVENT.REFRESH, this);
    return this;
  }

  toSrc1List(src1ItemClassRef, src1ListRef) {
    const src1List = super.toSrc1List(src1ItemClassRef, src1ListRef);

    // call apiResponseTransformer to convert dates to Date objects
    src1List.items.forEach((item) => {
      Object.assign(item, src1ItemClassRef.apiResponseTransformer(item));
    });

    return src1List;
  }
}

export default ContractList;
