import PropTypes from 'prop-types';
import React from 'react';

import Chiclet from '../chiclet/Chiclet';
import HorizontalList from '../horizontal-list/HorizontalList';
import HorizontalListItem from '../horizontal-list/HorizontalListItem';
import ImageIcon from '../image-icon/ImageIcon';

/**
 * Displays a list of products in chiclets as a horizontal list.
 * If it exceeds the bounds of the width of the window it will wrap to the next line.
 */
const HorizontalProductsList = ({itemMaxWidth, products}) => (
  <HorizontalList>
    {products.map((product) => {
      const {count, displayName, iconUrl, id} = product;
      return (
        <HorizontalListItem
          key={id}
          marginBottom="size-0"
          marginTop="size-0"
          maxWidth={itemMaxWidth}
        >
          <Chiclet
            count={count}
            IconComponent={<ImageIcon alt="" size="S" src={iconUrl} />}
            isTruncated
            name={displayName}
          />
        </HorizontalListItem>
      );
    })}
  </HorizontalList>
);

HorizontalProductsList.propTypes = {
  /**
   * Max width of each cell in the list (determines truncation)
   */
  itemMaxWidth: PropTypes.number,
  /**
   * List of product licenses to assign
   */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The number of licenses for a product
       */
      count: PropTypes.number,
      /**
       * The name of the product
       */
      displayName: PropTypes.string.isRequired,
      /**
       * Link to a small icon that represents the product
       */
      iconUrl: PropTypes.string,
      /**
       * The product id
       */
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HorizontalProductsList;
