/**
 * Temporary fake api resource while src2 things are being ported.
 */

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
/* istanbul ignore file -- temporary file to mock API calls, will remove in near future */

import {mockMoreUserGroups} from 'app/developer/components/assignment-menu-example/mockItems';

// Returns promise with resolved list of user groups
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- thean@ to update
const getFakeUserGroupList = (query) => {
  const checkForUserGroupMatch = (userGroup, queryStr) =>
    [userGroup.name].some((value) => value && value.toLowerCase().includes(queryStr.toLowerCase()));

  const moreUserGroups = mockMoreUserGroups();

  const results = {
    totalCount: moreUserGroups.length,
    userGroupList: moreUserGroups.filter((userGroup) => checkForUserGroupMatch(userGroup, query)),
  };

  return fakeAsyncCall(results, 500);
};

// Fake call that will return a promise that will resolve with given results and in specified time.
// Defaults to 500ms
function fakeAsyncCall(results, duration = 500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(results);
    }, duration);
  });
}

// eslint-disable-next-line import/prefer-default-export -- temporary helper file while needed src2 is being ported
export {getFakeUserGroupList};
