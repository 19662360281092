import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {getPolicyDisabledActionMessage} from 'common/utils/global-admin/globalAdminUtils';

/**
 * Component to show "i" tooltip text on hover/focus when Global Admin policies
 * are disabled.
 */
const PolicyInfoTooltip = ({orgId, policyActionList}) => {
  const intl = useIntl();

  const [tooltipText, setTooltipText] = useState();

  /**
   * Check policies and set disabled message if any policies are disabled
   */
  useEffect(() => {
    let isMounted = true;

    const checkPolicyActionList = async () => {
      const message = await getPolicyDisabledActionMessage({intl, orgId, policyActionList});
      if (isMounted) {
        setTooltipText(message?.description);
      }
    };

    checkPolicyActionList();

    return () => {
      isMounted = false;
    };
  }, [intl, orgId, policyActionList]);

  return (
    (tooltipText && (
      <TooltipButton hoverText={tooltipText} isQuiet variant="action">
        <InfoOutlineIcon size="S" />
      </TooltipButton>
    )) ||
    null
  );
};

PolicyInfoTooltip.propTypes = {
  /**
   * Policies are checked against the org ID
   */
  orgId: PropTypes.string.isRequired,
  /**
   * List of actions and policies to check
   */
  policyActionList: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Array of user actions for the policy name.
       * When the corresponding policy value is false (not allowed),
       * the rendered tooltip includes the actions.
       */
      actions: PropTypes.arrayOf(PropTypes.string),
      /**
       * One of the policy names from GLOBAL_ADMIN_POLICY in
       * @admin-tribe/binky.
       */
      policy: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PolicyInfoTooltip;
