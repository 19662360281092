import cloneDeep from 'lodash/cloneDeep';

import adobeStatusEvents from 'api/adobeStatusEvents';
import {
  ERROR,
  INVALIDATE,
  POLL_FREQUENCY,
  RECENT_HISTORY_THRESHOLD,
  REFRESH,
} from 'services/adobeStatus/AdobeStatusConstants';
import eventBus from 'services/events/eventBus';

class AdobeStatusData {
  /**
   * @description Method to fetch an AdobeStatusData.
   * @returns {AdobeStatusData} the AdobeStatusData.
   */
  static get() {
    const model = new AdobeStatusData();
    return model.refresh();
  }

  /**
   * @description Method to create a new instance of a AdobeStatusData.
   */
  constructor() {
    // We want to keep track of the status of any currently active (or very recent) events,
    // so that the user can see when they're resolved. The RECENT_HISTORY_THRESHOLD determines
    // how far back from now we should look for resolved events. Divide by 1000 to convert
    // from milliseconds to seconds (time stamps returned by status api are in seconds)
    this.statusWatchStart = (Date.now() - RECENT_HISTORY_THRESHOLD) / 1000;
  }

  /**
   * @description Returns all active events and any that ended after we started watching.
   * Incident events (ordered by time opened regardless of whether they are ongoing or recently closed)
   * come before all maintenance events (which are also ordered by start time).
   * @returns {Array} The events
   */
  getActiveAndRecentStatusEvents() {
    if (!this.data) {
      return [];
    }

    // Incident events are listed first
    const statusEvents = this.getIncidentEvents();

    // Order incident events by start time (most recent --> older) regardless of whether
    // they are open currently or were recently closed
    statusEvents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);

    // All maintenance events are listed after all incident events
    const maintenanceEvents = this.getMaintenanceEvents();

    // Order maintenance events by start time (most recent --> older)
    maintenanceEvents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    statusEvents.push(...maintenanceEvents);

    return statusEvents;
  }

  /**
   * @description Returns all active incident events and any that ended after we started watching.
   * @param {Object} [options] Options
   * @param {Boolean} [options.includeSeverityClosed] whether to include products with severity 'Closed'
   * @returns {Array} The events
   */
  getIncidentEvents(options = {includeSeverityClosed: true}) {
    const statusEvents = [];
    if (!this.data) {
      return statusEvents;
    }

    Object.values(this.data.incidentEvent.incidents)?.forEach((incident) => {
      const incidentId = incident.id;
      const products = Object.values(incident.products);
      products.forEach((product) => {
        // Only display incident events that are recently closed, or open currently
        if (product.endedOn >= this.statusWatchStart || product.endedOn === 0) {
          const updatedProduct = cloneDeep(product);
          updatedProduct.incidentId = incidentId;
          const productHistory = Object.values(product.history);
          const severity = productHistory[productHistory.length - 1]?.severity;
          const latestMessageTime = productHistory[productHistory.length - 1]?.messageTime;
          updatedProduct.latestMessageTime = new Date(latestMessageTime * 1000);

          // Events that report an end time or have trivial severity
          // are marked as 'Closed'
          if (product.endedOn !== 0 || severity === 'Trivial') {
            updatedProduct.incidentSeverity = 'Closed';
          } else {
            updatedProduct.incidentSeverity = severity;
          }

          if (
            options.includeSeverityClosed ||
            (!options.includeSeverityClosed && updatedProduct.incidentSeverity !== 'Closed')
          ) {
            statusEvents.push(updatedProduct);
          }
        }
      });
    });

    return statusEvents;
  }

  /**
   * @description Returns all active maintenance events and any that ended after we started watching.
   * @returns {Array} The events
   */
  getMaintenanceEvents() {
    const maintenanceEvents = [];
    if (!this.data) {
      return maintenanceEvents;
    }

    Object.values(this.data.maintenanceEvent.maintenance).forEach((maintenanceEvent) => {
      const products = Object.values(maintenanceEvent.products);

      if (maintenanceEvent.completedOn >= this.statusWatchStart) {
        products.forEach((product) => {
          const productHistory = Object.values(product.history);
          const latestMessageTime = productHistory[productHistory.length - 1]?.messageTime;

          // create a clone of the event and set the product name/time stamp
          const updatedEvent = cloneDeep(maintenanceEvent);
          updatedEvent.name = product.name;
          updatedEvent.latestMessageTime = latestMessageTime * 1000;
          maintenanceEvents.push(updatedEvent);
        });
      }
    });

    return maintenanceEvents;
  }

  /**
   * @description Determines if there are any currently ongoing non-maintenance events
   * @returns {Boolean} True if some non-maintenance events are ongoing, false otherwise
   */
  hasOngoingEvents() {
    return this.getIncidentEvents(false).length > 0;
  }

  /**
   * @description Determines if there are any currently ongoing maintenance events
   * @returns {Boolean} True if some maintenance events are ongoing, false otherwise
   */
  hasOngoingMaintenance() {
    return this.getMaintenanceEvents().length > 0;
  }

  /**
   * @description Method to load and process the adobe status list.
   * @returns {Promise} promise resolved when adobe status list is loaded.
   */
  async refresh() {
    let response;
    eventBus.emit(INVALIDATE);
    try {
      response = await adobeStatusEvents.getCurrentStatus();
    } catch (error) {
      eventBus.emit(ERROR);
      throw error;
    }
    this.data = response.data;
    eventBus.emit(REFRESH);

    return this;
  }

  /**
   * @description Method to set up polling the status API for updates
   */
  startPolling() {
    this.POLLING_PROMISE = setInterval(() => {
      this.refresh();
    }, POLL_FREQUENCY);
  }

  /**
   * @description Method to halt polling the status API
   */
  stopPolling() {
    if (this.POLLING_PROMISE) {
      clearInterval(this.POLLING_PROMISE);
      this.POLLING_PROMISE = null;
    }
  }
}

export default AdobeStatusData;
