import {AUTHENTICATING_ACCOUNT_TYPE, feature} from '@admin-tribe/binky';
import {Flex, Text} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import LinkIcon from '@spectrum-icons/workflow/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Shows a message with account type and an alert icon with a tooltip
 * when necessary,explaining the authentication federation scheme.
 * @param {String} memberTypeLabel - The member account type
 * @returns the account type and a tooltip button with hover text for a type 2/3 managed user
 */
const MemberIdTypeCell = ({
  authenticatingAccountType,
  isDomainEnforcementException,
  isSystemAdmin,
  isType2E,
  memberTypeLabel,
}) => {
  const intl = useIntl();
  const generateLabel = (tooltipText, icon) => (
    <Flex alignItems="center">
      <Text UNSAFE_style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{memberTypeLabel}</Text>
      <TooltipButton
        buttonAriaLabel={tooltipText}
        hoverText={tooltipText}
        isQuiet
        marginStart="size-50"
        variant="action"
      >
        {icon}
      </TooltipButton>
    </Flex>
  );

  if (
    feature.isDisabled('temp_remove_business_id') &&
    authenticatingAccountType === AUTHENTICATING_ACCOUNT_TYPE.ENT_FED_ID &&
    isType2E
  ) {
    return generateLabel(
      intl.formatMessage({
        id: 'binky.common.memberListTable.cell.idExplanation',
      }),
      <LinkIcon size="S" />
    );
  }
  if (
    feature.isEnabled('temp_de_exclusion_40038') &&
    isDomainEnforcementException &&
    isSystemAdmin
  ) {
    return generateLabel(
      intl.formatMessage({
        id: 'binky.common.memberListTable.cell.idTooltipContent',
      }),
      <InfoOutline size="S" />
    );
  }
  if (authenticatingAccountType === AUTHENTICATING_ACCOUNT_TYPE.INVALID && isType2E) {
    return generateLabel(
      intl.formatMessage({id: 'binky.common.memberListTable.cell.idWarning'}),
      <AlertIcon size="S" />
    );
  }
  return memberTypeLabel;
};

MemberIdTypeCell.propTypes = {
  /**
   * Account types from IMS, they can be AdobeID (Type 1), EntFedID (Type 2/3), BusinessId, or Invalid
   * Defaults to Invalid
   */
  authenticatingAccountType: PropTypes.oneOf(Object.values(AUTHENTICATING_ACCOUNT_TYPE)),
  /**
   * True if a member is in domain exclusion list
   */
  isDeExcluded: PropTypes.bool,
  /**
   * True if an member type is TYPE2E, false otherwise.
   */
  isType2E: PropTypes.bool,
  /**
   * Will display the id type of the member
   */
  memberTypeLabel: PropTypes.string.isRequired,
};

export default MemberIdTypeCell;
