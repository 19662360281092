import axios from 'axios';

import authentication from 'services/authentication';
import Locale from 'services/locale/Locale';

let clientId, url;

/**
 * Configure CCS api
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for CCS api
 */
const configure = (config) => {
  ({url, clientId} = config);
};

/**
 * @description Method to call the GET cart API.
 * @param {Object} options - Top level wrapper object.
 * @property {string} [options.cartId] - Cart id to fetch in particular.
 * @property {string} options.orgId - Org id of the organization whose cart
 *     information will be fetched.
 * @property {string} [options.contract-id] - Contract id whose cart information
 *     will be fetched.
 * @property {string} [options.order-type] - Order type to filter by.
 * @returns {Object} - Object that contains the cart API response.
 */
const getCart = ({cartId, orgId, ...queryParams}) => {
  const requestUrl = cartId
    ? `${url}/organizations/${getSplitActiveOrgId(orgId)}/carts/${cartId}`
    : `${url}/organizations/${getSplitActiveOrgId(orgId)}/carts`;

  return axios.get(requestUrl, {
    headers: getSharedHeaders(),
    params: queryParams,
  });
};
/**
 * Saves cart
 *
 * @param {String} orgId - The org id
 * @param {Object} cart - The cart object
 */
const postCart = async ({orgId}, cart) => {
  const response = await axios.post(
    `${url}/organizations/${getSplitActiveOrgId(orgId)}/carts/`,
    cart,
    {
      headers: getSharedHeaders(),
    }
  );
  return response;
};

const ccs = {
  configure,
  getCart,
  postCart,
};

/**
 * @description Method to get active org id only
 * @returns {String} org id only e.g. '123'
 */
function getSplitActiveOrgId(orgId) {
  return orgId.split('@')[0];
}

function getSharedHeaders() {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': clientId,
    'x-user-auth': authentication.getAccessToken().token,
  };

  // Pass the `Accept-Language` header through to CCS when it is called in modals.
  // resolves: french canadian local currency mismatch between jil and ccs responses within modals
  // An agreement has been made with CCS: Using this Accept-Language header and country value (attained at their end relating to the contract)
  // CCS will return the French Canadian formatString to AC.
  let lang = Locale.get().activeLocaleCode;

  if (lang === 'zh_CN' || lang === 'zh_TW') {
    lang = Locale.get().activeLanguageBCP47Code;
  }

  Object.assign(headers, {'Accept-Language': lang});

  return headers;
}

export default ccs;
