(function () {
  'use strict';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name binky.widgets.products.product-role-switcher:binkyProductRoleSwitcher
   *
   * @description a dropdown for switching product role.
   *
   * @param {Boolean} [bulkEdit] An optional field indicates whether this switcher is for bulk editing.
   * @param {Function} onRoleChange A callback from the parent component which is called when the role is change.
   * @param {Object} roles An object defining all the possible roles.
   * @param {String} selectedRole The ID key for the role to select on init.
   * @param {String} [variant] An optional variant for the binky-select component. Defaults to quiet.
   *
   */
  angular
    .module('binky.widgets.products.product-role-switcher')
    .component('binkyProductRoleSwitcher', {
      bindings: {
        bulkEdit: '@?',
        onRoleChange: '&',
        roles: '<',
        selectedRole: '<',
        userCount: '@?',
        variant: '@?',
      },
      controller,
      templateUrl: 'widgets/products/product-role-switcher/product-role-switcher.component.html',
    });

  /* @ngInject */
  function controller($scope, $translate, _) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      onSelectChange,
    });

    function $onInit() {
      _.defaults(vm, {variant: 'quiet'});
    }

    function $onChanges() {
      if (_.has(vm, 'bulkEdit')) {
        // update placeholder based on the number of users being selected
        vm.placeHolder = $translate.instant(
          'binky.widgets.products.productRoleSwitcher.messageFormat.placeHolder',
          {count: vm.userCount},
          'messageformat'
        );
      }
    }

    /**
     * @description - callback for the change event from <binky-select>
     * @param {string} selectedRole - the value of the selected role
     */
    function onSelectChange(selectedRole) {
      if (!_.isEqual(vm.selectedRole, selectedRole)) {
        vm.selectedRole = selectedRole;
        vm.onRoleChange({selectedRole});
      }
    }
  }
})();
