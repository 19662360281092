import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import HelpOutlineIcon from '@spectrum-icons/workflow/HelpOutline';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A component that will display tooltip with a message if product is deleted
 */
const DeletedProductToolTip = () => {
  const intl = useIntl();
  return (
    <TooltipTrigger>
      <ActionButton
        aria-label={intl.formatMessage({
          id: 'binky.widgets.reports.error.deleted.product.tooltip.label',
        })}
        isQuiet
      >
        <HelpOutlineIcon />
      </ActionButton>
      <Tooltip>
        {intl.formatMessage({id: 'binky.widgets.reports.tooltip.deletedProduct.label'})}
      </Tooltip>
    </TooltipTrigger>
  );
};

export default DeletedProductToolTip;
