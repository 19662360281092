(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.domains
   * @description module that encapsulates the domains widgets
   */
  angular.module('binky.widgets.domains', [
    'binky.core.api.jil',
    'binky.core.common.selection',
    'binky.core.directories',
    'binky.core.domains',
    'binky.core.js-utils',
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.status',
    'binky.widgets.directories.directory-type',
    'binky.widgets.domains.domain-list-table',
    'binky.widgets.domains.status',
    'ngCoral',
    'pascalprecht.translate',
  ]);
})();
