import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {copyWithAlertToast} from 'common/utils/clipboard';

// A component that wraps a spectrum button and copies the
// given value to the clipboard when clicked.
const CopyToClipboardButton = ({
  children,
  successMessage,
  value,
  // https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/Button/js/Button.js#L55
  ...buttonProps
}) => {
  const intl = useIntl();

  return (
    <Button onClick={() => copyWithAlertToast({intl, successMessage, value})} {...buttonProps}>
      {children}
    </Button>
  );
};

CopyToClipboardButton.propTypes = {
  children: PropTypes.node,
  hideDelay: PropTypes.number,
  successMessage: PropTypes.string,
  value: PropTypes.string,
};

export default CopyToClipboardButton;
