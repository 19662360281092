import {computed, makeObservable, observable, runInAction} from 'mobx';

import jilSearch from 'api/jil/jilSearch';
import AvailableType from 'models/availableType/AvailableType';
import {MEMBER_TYPE} from 'models/member/type/MemberTypeConstants';
import log from 'services/log';
import {looksLikeAnEmail} from 'utils/emailUtils';

const ID_TYPE_PRIORITY_ORDER = {
  [MEMBER_TYPE.TYPE3]: 1,
  [MEMBER_TYPE.TYPE2]: 2,
  [MEMBER_TYPE.TYPE2E]: 3,
  [MEMBER_TYPE.TYPE1]: 4,
};

class AvailableTypeList {
  /**
   * @description Method to retrieve an existing AvailableTypeList from the back-end.
   *
   * @param {Object} options - options provided to the get call
   * @param {String} options.email - the email to get the available types of
   * @param {String} options.orgId - the orgId that will be included in the assembled url
   *
   * @returns {AvailableTypeList} new AvailableTypeList Object reference
   */
  static async get(options) {
    const model = new AvailableTypeList(options);
    await model.refresh();
    return model;
  }

  error = undefined;
  hasInvalidEmail = false;
  isLoading = false;
  items = [];

  /**
   * @class
   * @description Constructor for AvailableType model Objects.
   * @param {Object} options - options provided to the constructor
   * @param {String} options.email - the email to get the available types of
   * @param {String} options.orgId - the orgId that will be included in the assembled url
   */
  constructor(options) {
    makeObservable(this, {
      availableAndAllowedTypes: computed,
      availableAndDisallowedTypes: computed,
      availableOnlyTypes: computed,
      availableOnlyTypesInPriorityOrder: computed,
      error: observable,
      hasInvalidEmail: observable,
      hasOneOrMoreDisabledTypes: computed,
      isLoading: observable,
      items: observable,
    });

    this.email = options.email;
    this.orgId = options.orgId;
  }

  get availableAndAllowedTypes() {
    return this.availableOnlyTypes.filter((availableType) => availableType.allowed !== false);
  }

  get availableAndDisallowedTypes() {
    return this.availableOnlyTypes.filter((availableType) => availableType.allowed === false);
  }

  get availableOnlyTypes() {
    return this.items.filter(
      (availableType) => availableType.available && !!availableType.userType
    );
  }

  get availableOnlyTypesInPriorityOrder() {
    return this.availableOnlyTypes.sort(
      (availableType1, availableType2) =>
        ID_TYPE_PRIORITY_ORDER[availableType1.userType] -
        ID_TYPE_PRIORITY_ORDER[availableType2.userType]
    );
  }

  get hasOneOrMoreDisabledTypes() {
    return this.availableOnlyTypes.length - this.availableAndAllowedTypes.length > 0;
  }

  hasType(type) {
    return this.availableOnlyTypes.some((availableType) => availableType.userType === type);
  }

  async refresh() {
    const handleError = (error) => {
      runInAction(() => {
        this.error = error;
        this.hasInvalidEmail = error?.response?.data?.errorCode === 'INVALID_EMAIL';
      });

      const logger = this.hasInvalidEmail ? log.info : log.error;

      logger(
        `AvailableTypeList.refresh(): Failed to fetch data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
    };

    this.error = undefined;
    this.hasInvalidEmail = false;
    this.items = [];

    if (!looksLikeAnEmail(this.email)) {
      handleError({
        config: {url: 'None'},
        response: {
          data: {errorCode: 'INVALID_EMAIL'},
          headers: {'X-Request-Id': 'None'},
          status: 'None',
        },
      });
      return this;
    }

    this.isLoading = true;

    try {
      const response = await jilSearch.getAvailableTypes({
        orgId: this.orgId,
        payload: {
          email: this.email,
        },
      });

      if (response) {
        runInAction(() => {
          this.items = response.map(AvailableType.apiResponseTransformer);
        });
      }
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
    return this;
  }
}

export default AvailableTypeList;
