import axios from 'axios';

let url;

/**
 * Configure AdobeStatusEvents api
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for AdobeStatusEvents api
 */
const configure = (config) => {
  ({url} = config);
};

/**
 * Fetches current status
 */
const getCurrentStatus = () => axios.get(`${url}`, {});

const adobeStatusEvents = {
  configure,
  getCurrentStatus,
};

export default adobeStatusEvents;
