/* eslint-disable no-console -- Needed to override methods */

/**
 * Modifies console methods to filter out React logs that we cannot address at this time
 */
const enableReactLogFilter = () => {
  const originalConsoleError = console.error;

  console.error = (...args) => {
    const messagesToFilter = [
      // We cannot do anything about the following issue. See ONESIE-34639
      "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.",
    ];

    if (messagesToFilter.some((message) => args[0].startsWith(message))) {
      return;
    }

    originalConsoleError(...args);
  };
};

export {enableReactLogFilter};
/* eslint-enable no-console -- Needed to override methods */
