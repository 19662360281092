import invokeMap from 'lodash/invokeMap';

import log from 'services/log';

const handleError = (error) => {
  log.error(error);
};

/**
 * Executes all providers' configure()
 */
async function configure(readyProviders) {
  let response = Promise.resolve();
  try {
    response = await Promise.all(invokeMap(readyProviders, 'configure'));
  } catch (error) {
    handleError(error);
  }

  return response;
}

/**
 * Executes all providers' run()
 */
async function run(readyProviders) {
  let response = Promise.resolve();
  try {
    response = await Promise.all(invokeMap(readyProviders, 'run'));
  } catch (error) {
    handleError(error);
  }

  return response;
}

export {configure, run};
