(function () {
  'use strict';
  /**
   * @deprecated should use src2/common/components/wait/OverlayWait.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.wait:binkyWait
   *
   * @description an animated wait component used as to indicate a process that is in-progress for
   *              an indefinite amount of time. If there is a promise, the wait component is shown
   *              until the promise is resolved and then the transcluded content is shown.
   *
   * @binding {Boolean} alwayShowContent. If true, will show the content behind a scrim while
   *    the wait is shown.
   * @binding {Boolean} centered. A flag uses to specify whether the wait is centered or not.
   *    The wait's container needs to have its style 'position: relative' for the centering to
   *    work correctly. The default value is false.
   * @binding {Object} promise. When promise is resolved, the wait component will be hidden and
   *    the transcluded div will be shown.
   * @binding {String} size. The size of the wait - small, medium or large.
   *    Valid values are 'S', 'M' and 'L'. The default value is 'S'.
   *
   * @example <binky-wait size="L" centered></binky-wait>
   */
  angular.module('binky.widgets.common.wait').component('binkyWait', {
    bindings: {
      alwaysShowContent: '<?',
      centered: '@?',
      promise: '<?',
      size: '@?',
      waitString: '@?', // only applies if centered
    },
    controller,
    templateUrl: 'widgets/common/wait/wait.component.html',
    transclude: true,
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {$onChanges, $onInit});

    /////////////

    function $onInit() {
      vm.isCentered = function () {
        return vm.centered === '' || vm.centered === 'centered';
      };
    }

    // This is called on init and then whenever one-way bindings are updated, e.g. the promise.
    function $onChanges() {
      vm.showWait = true;
      vm.showContent = !!vm.alwaysShowContent;

      if (vm.promise) {
        vm.showScrim = vm.showContent;
        vm.promise
          .then(() => {
            vm.showWait = false;
            vm.showContent = true;
            vm.showScrim = false;
          })
          .catch(() => {
            vm.showWait = false;
            vm.showContent = true;
            vm.showScrim = false;
          });
      }
    }
  }
})();
