(function () {
  /**
   * @deprecated Please use src2/core/api/jil/jilConsumables.js
   *
   * @ngdoc service/provider
   * @name jilConsumables
   * @description defines service to manage jil consumables summary resources
   */
  angular.module('binky.core.api.jil').provider('jilConsumables', jilConsumablesProvider);

  let url;

  /* @ngInject */
  function jilConsumablesProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        summary: getSummaryResource(),
        usage: getUsageResource(),
      };

      return service;

      ///////////

      // GET /v2/organizations/{orgId}/consumables:summary
      // parameters defined at https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-ConsumableSummaryAPI
      function getSummaryResource() {
        return $resource(`${url}/:orgId/consumables\\:summary`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      // GET /v2/organizations/{orgId}/consumables:usage
      // parameters defined at https://wiki.corp.adobe.com/display/platformsvcs/Consumables#Consumables-ConsumableUsageAPI
      function getUsageResource() {
        return $resource(`${url}/:orgId/consumables\\:usages`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
