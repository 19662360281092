/* eslint-disable react/no-unknown-property -- styleName not recognized */
import binky from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import SharedUserGroupIcon from '@spectrum-icons/workflow/Delegate';
import GearsIcon from '@spectrum-icons/workflow/Gears';
import UserIcon from '@spectrum-icons/workflow/User';
import UserGroupIcon from '@spectrum-icons/workflow/UserGroup';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {getCustomAvatarSize} from 'common/utils/icons/iconUtils';

import './Avatar.pcss';

const Member = binky.models.member.Member;
const MemberType = binky.models.member.MemberType;
const UserAvatar = binky.models.user.UserAvatar;
const AuthenticatedUser = binky.models.user.AuthenticatedUser;
const UserGroup = binky.services.users.UserGroup;

// Renders inline, either an avatar, or a default icon for a member.
// If useDefault is true, the default icon for the member type will always be used.
const Avatar = (props) => {
  const {alt = '', member, size = 'S', useDefault = false, ...styleProps} = props;

  const isMountedRef = useRef(true);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const intl = useIntl();

  const getAvatarSize = useCallback(() => getCustomAvatarSize(size), [size]);

  const getMemberId = useCallback(() => {
    if (member instanceof AuthenticatedUser) {
      return member.getId();
    }
    return member.id;
  }, [member]);

  const getIcon = useCallback(() => {
    let foundIcon = (
      <UserIcon
        aria-label={intl.formatMessage({id: 'binky.common.avatar.icon.user'})}
        size={size}
      />
    );
    if (member) {
      const memberType = new MemberType(member.type, member.id);
      if (memberType.isUserGroup()) {
        if (member instanceof UserGroup && member.isShared()) {
          foundIcon = (
            <SharedUserGroupIcon
              aria-label={intl.formatMessage({id: 'binky.common.avatar.icon.sharedUserGroup'})}
              size={size}
            />
          );
        } else {
          foundIcon = (
            <UserGroupIcon
              aria-label={intl.formatMessage({id: 'binky.common.avatar.icon.userGroup'})}
              size={size}
            />
          );
        }
      } else if (memberType.isTechnicalAccount()) {
        foundIcon = (
          <GearsIcon
            aria-label={intl.formatMessage({id: 'binky.common.avatar.icon.technicalAccount'})}
            size={size}
          />
        );
      }
    }
    return foundIcon;
  }, [intl, member, size]);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    isMountedRef.current = true;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore next -- untestable
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const avatar = await UserAvatar.get(getMemberId(), size);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else -- untestable
        if (isMountedRef.current) {
          setAvatarSrc(avatar.src);
        }
      } catch (error) {
        // We want an error fetching the avatar to do nothing, keeping the existing image/placeholder
      }
    };

    if (
      !useDefault &&
      member &&
      member.getType().isUser() &&
      !member.getType().isTechnicalAccount()
    ) {
      fetchAvatar();
    }
  }, [getMemberId, member, size, useDefault]);

  if (avatarSrc) {
    return (
      <Flex
        {...styleProps}
        alignContent="center"
        alignItems="center"
        height={getAvatarSize()}
        justifyContent="center"
        width={getAvatarSize()}
      >
        <img alt={alt} src={avatarSrc} styleName={`img-${size}`} />
      </Flex>
    );
  }

  return (
    <View
      {...styleProps}
      backgroundColor="gray-300"
      height={getAvatarSize()}
      UNSAFE_style={{borderRadius: '50%', display: 'inline-block'}}
      width={getAvatarSize()}
    >
      <Flex
        alignContent="center"
        alignItems="center"
        height={getAvatarSize()}
        justifyContent="center"
        width={getAvatarSize()}
      >
        {getIcon()}
      </Flex>
    </View>
  );
};

Avatar.displayName = 'Avatar';
Avatar.propTypes = {
  /**
   * The alt to display for the avatar. If the avatar is next to the visible member/user name there is
   * no reason to specify alt since it does not add any additional information. Default is '' (which is
   * distinct from the img tag not being present).
   * Note: this does not apply to UserIcon, UserGroupIcon or GearsIcon workflow icons.
   * By default they are hidden but you can use an aria-label on the parent component or the worflow icon.
   */
  alt: PropTypes.string,
  /**
   * The member to display. This will be object if coming from angular.
   */
  member: PropTypes.oneOfType([
    PropTypes.instanceOf(Member),
    PropTypes.instanceOf(AuthenticatedUser),
    PropTypes.object,
  ]),
  /**
   * The size of the avatar. Default is 'S'.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']),
  /**
   * If true, the default icon for the member type will always be used.
   * An API call will not be made to fetch the individual member's icon.
   * The default is false.
   */
  useDefault: PropTypes.bool,
};

export default Avatar;
/* eslint-enable react/no-unknown-property -- styleName not recognized */
