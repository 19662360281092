(function () {
  'use strict';
  /**
   * @deprecated Please use src2/widgets/common/components/comment/Comment.jsx
   *
   * @ngdoc component
   * @name binky.widgets.common.comments:binkyComment
   *
   * @description A comment widget.
   *
   * @param {String} [bannerContent] The message to convey in an alert
   * @param {String} [bannerVariant] Variant type of an alert to be placed in the header of the comment (i.e warning)
   * @param {Boolean} [canDelete] Boolean value to determine whether the comment can be deleted. CommentId is a required field if you pass true.
   * @param {Transclude} commentContent The publisher's message
   * @param {String} [commentId] The id of the comment.
   * @param {String} creationDate The creation date of the comment, in ISO8601 format
   * @param {String} [onDelete] The callback function to call when the delete button is clicked
   * @param {String} [overrideTitle] The text to use as the title. If not provided, the user `displayName` will be used.
   * @param {User} [user] A user object to put in the comment title. If provided, and no override is set, this will be used for the title, and
   *     it will be used to create the avatar. This requires the following fields: `firstName`, `id`, `lastName`
   *
   * @example <binky-comment banner-content="My banner" banner-variant="warning" comment-title-key="My title" creation-date="2018-03-06T16:34:48-08:00">
   *              <comment-content>My message</comment-content>
   *         </binky-comment>
   *
   */
  angular.module('binky.widgets.common.comments').component('binkyComment', {
    bindings: {
      bannerContent: '@?',
      bannerVariant: '@?',
      canDelete: '<?',
      commentId: '@?',
      creationDate: '@',
      onDelete: '&?',
      overrideTitle: '@?',
      user: '<?',
    },
    controller,
    templateUrl: 'widgets/common/comments/comment.component.html',
    transclude: {
      commentContent: 'commentContent',
    },
  });

  /* @ngInject */
  function controller(_, binkyPersonNameFilter, COMMENT_BANNER_VARIANT) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      getCommentTitle,
    });

    function $onInit() {
      _.assign(vm, {
        hasBanner: !!vm.bannerContent,
      });

      switch (vm.bannerVariant) {
        case COMMENT_BANNER_VARIANT.WARNING.TYPE:
          vm.bannerIcon = COMMENT_BANNER_VARIANT.WARNING.ICON;
          break;
        default:
          break;
      }
    }

    function getCommentTitle() {
      if (vm.overrideTitle) {
        // If the override title is provided, we prefer it
        return vm.overrideTitle;
      } else if (vm.user) {
        const options = _(vm.user)
          .pick(['firstName', 'lastName'])
          .assign({userId: _.get(vm, 'user.id')})
          .omitBy(_.isUndefined)
          .value();
        // If we don't have the title key, but we have the user, we use the person filter to display them
        return binkyPersonNameFilter(options);
      }
      return '';
    }
  }
})();
