import {MemberType} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import {Tag} from '@react/react-spectrum/TagList';
import WorkflowIcon from '@spectrum-icons/workflow/Workflow';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getMemberTypeLabel} from 'features/member/memberDisplayUtils';

const OrganizationSwitcherRow = ({processedLinkedAccount}) => {
  const intl = useIntl();

  return (
    <Flex alignItems="center" direction="row" gap="size-50" justifyContent="space-between">
      <View elementType="span">
        {processedLinkedAccount.name}
        {processedLinkedAccount.multipleAccountsForOrg && (
          <Tag>
            {getMemberTypeLabel(
              intl,
              new MemberType(processedLinkedAccount.userType, processedLinkedAccount.userId)
            )}
          </Tag>
        )}
      </View>
      {processedLinkedAccount.pathName && (
        // View is needed to align WorkflowIcon
        <View data-test-id="path-name-container" elementType="span" width="size-300">
          <WorkflowIcon
            size="S"
            UNSAFE_style={{
              paddingLeft: 'var(--spectrum-global-dimension-size-50)',
              transform: 'rotate(90deg)',
            }}
            width="size-300"
          />
        </View>
      )}
    </Flex>
  );
};

OrganizationSwitcherRow.propTypes = {
  /**
   * Linked account information which is derived from LinkedUserAccount instance.
   * See getOrganizationUtils.js -- filterLinkedOrganizations()
   */
  processedLinkedAccount: PropTypes.shape({
    /**
     * User may have multiple accounts on the same org, e.g. T2E and T3
     */
    multipleAccountsForOrg: PropTypes.bool.isRequired,
    /**
     * Org display name
     */
    name: PropTypes.string.isRequired,
    /**
     * Global Admin parent org hierarchy
     */
    pathName: PropTypes.string,
    /**
     * The current user's ID for this org
     */
    userId: PropTypes.string.isRequired,
    /**
     * The current user's ID type for this org
     */
    userType: PropTypes.string.isRequired,
  }),
};

export default OrganizationSwitcherRow;
