(function () {
  /**
   * @deprecated Please use commerce Pandora components
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('binky.core.price').filter('price', priceFilter);

  /* @ngInject */
  function priceFilter(price) {
    return priceFilterFn;

    /**
     * @description Returns a human-readable price string containing the specified amount in the
     *   specified currency. For further details see the price service documentation.
     *
     * @param {Number} amount - the amount to format, e.g. 12345.67
     * @param {Object} currency - the currency definition, as returned by JIL APIs, containing at
     *   least formatString and usePrecision properties.
     * @returns {String} the specified amount in the specified currency, e.g. US$12,345.67
     */
    function priceFilterFn(amount, currency) {
      return price.format(amount, currency);
    }
  }
})();
