(function () {
  /**
   * @deprecated Please use src2/core/models/organizationUser/OrganizationUser.js
   *
   * @ngdoc factory
   * @name OrganizationUser2
   * @description Model for an organization user.
   */
  // eslint-disable-next-line angular/factory-name
  angular
    .module('binky.core.organizations.user')
    .factory('OrganizationUser2', getOrganizationUser2Model);

  /* @ngInject */
  function getOrganizationUser2Model(
    $q,
    _,
    binkySrc2,
    Member,
    ORGANIZATION_USER_2_CACHE_ID,
    OrganizationList,
    promiseUtils
  ) {
    class OrganizationUserSrc2 extends binkySrc2.services.organization.OrganizationUser {
      constructor(options = {}) {
        options.orgId = options.orgId || _.get(OrganizationList.get(), 'activeOrg.id');
        super(options);
      }

      delete() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .delete()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => resolve(response))
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      save(options = {}) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .save(options)
            .then((response) => {
              resolve(response);
              this.$resolved = true;
            })
            .catch((error) => {
              reject(error.response);
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to construct a OrganizationUserSrc2 from a data Object containing user information.
       *
       * @param {Object} responseData Object containing user data
       * @returns {OrganizationUserSrc2} Reference to OrganizationUser2
       */
      static apiResponseTransformer(responseData) {
        // create new OrganizationUserSrc2 from data Object
        return new OrganizationUserSrc2(responseData);
      }

      static get(options) {
        const {orgId, userId} = options;
        const model = new OrganizationUserSrc2({id: userId, orgId});
        const promise = super.get(options);
        model.$resolved = false;
        model.$promise = promiseUtils
          .toAngularPromise(promise)
          .then((organizationUser) => {
            _.assign(model, organizationUser);
            return model;
          })
          .finally(() => {
            model.$resolved = true;
          });
        return model;
      }
    }

    Member.setupCache(ORGANIZATION_USER_2_CACHE_ID, 10);

    return OrganizationUserSrc2;
  }
})();
