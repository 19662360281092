(function () {
  'use strict';
  /**
   * @deprecated use src2 OmniTool
   *
   * @ngdoc service
   * @name binky.widgets.omni-tool.panel-manager:omniToolPanelManager
   * @description manage behavior of panel components
   */
  angular
    .module('binky.widgets.omni-tool.panel-manager')
    .factory('omniToolPanelManager', omniToolPanelManager);

  /* @ngInject */
  function omniToolPanelManager($rootScope, OMNI_TOOL_PANEL_REGISTER, OMNI_TOOL_PANEL_SWITCH) {
    const service = {
      clearPanelCallbacks,
      registerPanel,
      switchTo,
    };
    let panelCallbacks = [];
    return service;

    //////////////

    /**
     * Helper to clear the callbacks that have been previously registered to this service
     * (Prevents multiple sets of secondary buttons from appearing after successive drawer toggles)
     */
    function clearPanelCallbacks() {
      panelCallbacks = [];
    }

    function registerPanel(panelId, ...callbacks) {
      panelCallbacks.push({callbacks, panelId});
      $rootScope.$emit(OMNI_TOOL_PANEL_REGISTER, panelCallbacks);
    }

    function switchTo(panelId) {
      $rootScope.$emit(OMNI_TOOL_PANEL_SWITCH, panelId);
    }
  }
})();
