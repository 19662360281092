(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.badge
   * @description module that defines badge widgets
   */
  angular.module('binky.widgets.common.badge', [
    'binky.core.lodash',
    'binky.widgets.common.badge-utils',
  ]);
})();
