// eslint-disable-next-line filenames/match-exported -- context
import PropTypes from 'prop-types';
import React, {useContext, useMemo} from 'react';

const WorkspaceNavContext = React.createContext({});
const useWorkspaceNavContext = () => useContext(WorkspaceNavContext);

/**
 * Context that wraps the workspace nav item child components, exposes the
 * the aria label, the navItems, and the key of the item that is currently
 * selected.
 */
const WorkspaceNavContextProvider = ({children, navAriaLabel, navItems, selectedNavItemKey}) => {
  const value = useMemo(
    () => ({navAriaLabel, navItems, selectedNavItemKey}),
    [navAriaLabel, navItems, selectedNavItemKey]
  );
  return (
    <WorkspaceNavContext.Provider value={value}>
      <WorkspaceNavContext.Consumer>{children}</WorkspaceNavContext.Consumer>
    </WorkspaceNavContext.Provider>
  );
};

WorkspaceNavContextProvider.propTypes = {
  /**
   * Children that will be using the properties exposed by this context.
   */
  children: PropTypes.func.isRequired,
  /**
   * Aria label to be applied to the nav element wrapping the nav items.
   */
  navAriaLabel: PropTypes.string.isRequired,
  /**
   * Nav items to potentially navigate to.
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  navItems: PropTypes.arrayOf(PropTypes.object),
  /**
   * Key of the nav item that is currently selected.
   */
  selectedNavItemKey: PropTypes.string,
};

export {useWorkspaceNavContext};
export default WorkspaceNavContextProvider;
