const FULFILLMENT_EVENT_STATUS = {
  ABORTED: 'ABORTED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
};
const FULFILLMENT_NEEDED_TYPE = {
  REGULAR: 'REGULAR',
  RETURN: 'RETURN',
  UPDATE: 'UPDATE',
};

export {FULFILLMENT_EVENT_STATUS, FULFILLMENT_NEEDED_TYPE};
