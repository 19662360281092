(function () {
  /**
   * @ngdoc module
   * @name binky.core.product.fulfillable-item-list
   * @description Module which contains fulfillable-item-list related services
   */
  angular.module('binky.core.product.fulfillable-item-list', [
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.offers.fulfillable-items',
    'binky.core.src2-migration',
  ]);
})();
