import angular from 'angular';
import isEmpty from 'lodash/isEmpty';
/**
 * @name prepareLibraryTemplateCache
 * @description Imports each Angular components/directive HTML template from src/shell and
 *   src/widgets and adds it to Angular's $templateCache at the URL expected by
 *   the legacy Angular source.
 */
function prepareLibraryTemplateCache() {
  const caches = {};
  // Shell and Widgets HTML are always loaded
  loadContextIntoModuleCache(
    caches,
    // eslint-disable-next-line no-undef -- this is a valid use of webpack's require function
    require.context('../../../src/shell', true, /.*\.html$/),
    'shell'
  );
  loadContextIntoModuleCache(
    caches,
    // eslint-disable-next-line no-undef -- this is a valid use of webpack's require function
    require.context('../../../src/widgets', true, /.*\.html$/),
    'widgets'
  );
  setupCachedHtmlAsAngularModuleRuns(caches);
}

/**
 * @name loadContextIntoModuleCache
 * @description Imports Angular components/directive HTML templates from the provided
 *   require.context and prepares it for adding to Angular's $templateCache at runtime.
 * @param {Object} caches a KVP object containing angular module identifiers mapped to arrays
 *   of objects to be loaded. These will be added to by this function.
 * @param {Require.Context} context the requires context to iterate across to find templates
 * @param {String} prefix the String prefix we should put on any module key we add to the cache
 * @param {String} overrideModule if provided, all templates from the context will be loaded
 *   to this module instead of whichever one relates to their own module.
 */
function loadContextIntoModuleCache(caches, context, prefix, overrideModule) {
  // Each context contains the HTML files for a specified file set
  context.keys().forEach((key) => {
    // from that we extract the template URL
    const templateUrl = key.replace(/^\.\//, `${prefix}/`);
    // if we're not using a passed in module, we pick apart the template
    // URL to compute one based on the file hierarchy
    const cacheKey = isEmpty(overrideModule)
      ? `binky.${templateUrl
          .replace(/\/[^/]+\.html$/, '')
          .split('/')
          .join('.')}`
      : overrideModule;
    // we then push the resulting state into a cache object
    // creating an entry for the module key if it doesn't exist
    if (!caches[cacheKey]) {
      caches[cacheKey] = [];
    }
    caches[cacheKey].push({html: context(key), templateUrl});
  });
}

/**
 * @name setupCachedHtmlAsAngularModuleRuns
 * @description Adds the provided caches to the relevant `angular.module.run` function
 *   to be loaded at runtime into the Angular $templateCache.
 * @param {Object} caches a KVP object containing angular module identifiers mapped to arrays
 *   of objects to be loaded.
 */
function setupCachedHtmlAsAngularModuleRuns(caches) {
  // with the assembled cache of module keys, template URLs, and HTML content
  // we register the angular.module().run() commands to load them at runtime
  Object.keys(caches).forEach((module) => {
    // we use the cache to have a single run per module
    angular.module(module).run([
      '$templateCache',
      ($templateCache) => {
        // for each module we may load multiple templates
        caches[module].forEach((cachedItem) => {
          const {html, templateUrl} = cachedItem;
          $templateCache.put(templateUrl, html);
        });
      },
    ]);
  });
}

export {
  loadContextIntoModuleCache,
  prepareLibraryTemplateCache,
  setupCachedHtmlAsAngularModuleRuns,
};
