(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.directories.domains:DirectoryDomain
   * @description model for a domain under a specific directory.
   */
  angular
    .module('binky.core.directories.domains')
    .factory('DirectoryDomain', getDirectoryDomainModel);

  /* @ngInject */
  function getDirectoryDomainModel(_, DOMAIN_STATUS) {
    class DirectoryDomain {
      /**
       * @description instantiate a DirectoryDomain object.
       * @param {Object} options - options to config the new DirectoryDomain, a JSON object representing a
       *   domain that has already been fetched (typically via DirectoryDomainList.get).
       */
      constructor(options) {
        applyResource(this, options);
      }

      isActive() {
        return this.status === DOMAIN_STATUS.ACTIVE;
      }

      needsApproval() {
        return this.status === DOMAIN_STATUS.CLAIMED;
      }

      needsDirectory() {
        return this.status === DOMAIN_STATUS.VALIDATED;
      }

      needsValidation() {
        return this.status === DOMAIN_STATUS.UNCLAIMED;
      }

      /**
       * @description generate model subset containing only API relevant fields.
       * @returns {Object} API relevant subset of DirectoryDomain object.
       */
      toMinimumModel() {
        return toMinimumObject(this);
      }

      static apiResponseTransformer(item) {
        return new DirectoryDomain(item);
      }
    }
    return DirectoryDomain;

    //////////////

    function applyResource(domain, resource) {
      angular.extend(domain, toMinimumObject(resource));
      return domain;
    }

    function toMinimumObject(object) {
      return _.pick(object, ['domainName', 'status']);
    }
  }
})();
