import jilProducts from 'api/jil/jilProducts';
import jilUsers from 'api/jil/jilUsers';
import {MEMBER_EVENT} from 'models/member/MemberConstants';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import {getAdminRolePatches, getUsersWithModifiedRoles} from 'models/user/roles/userRoleUtils';
import eventBus from 'services/events/eventBus';
import log from 'services/log';
import MemberList from 'services/member/MemberList';

import {
  LICENSE_GROUP_ADMIN_LIST_CACHE_ID,
  LICENSE_GROUP_ADMIN_LIST_EVENT,
} from './LicenseGroupAdminListConstants';

/**
 * @description Method to instantiate LicenseGroupAdminList for the current active organization.
 * @param {Object} options - Options for the constructor.
 * @param {String} options.productId The product id.
 * @param {String} options.configurationId The product profile id.
 * @param {Array} [options.roles] Filtered roles for admin. For example, [ROLE.ADMIN.LICENSE_DEV].
 * @returns {LicenseGroupAdminList} orgList - new LicenseGroupAdminList.
 */
class LicenseGroupAdminList extends MemberList {
  /**
   * @description Constructor for LicenseGroupAdminList model Objects.
   */
  constructor({configurationId, productId, roles, ...options}) {
    super({
      eventUpdateCount: LICENSE_GROUP_ADMIN_LIST_EVENT.UPDATE.COUNT,
      eventUpdateList: LICENSE_GROUP_ADMIN_LIST_EVENT.UPDATE.LIST,
      itemClassRef: OrganizationUser,
      modelCacheId: LICENSE_GROUP_ADMIN_LIST_CACHE_ID,
      refreshResource: jilProducts.getLicenseGroupAdmins,
      saveResource: jilProducts.patchLicenseGroupAdmins,
      ...options,
    });

    this.productId = productId;
    this.configurationId = configurationId;
    this.roles = roles;

    // Developers are modified with this resource rather than the saveResource.
    this.modifedUserResource = jilUsers.patchUsers;
    this.modifiedUsers = [];
  }

  // eslint-disable-next-line class-methods-use-this -- need to override base method
  add() {
    throw new Error('LicenseGroupAdminList.add not implemented');
  }

  /**
   * @description Method for providing modified users, to be persisted on save.
   * @param {OrganizationUser[]} users - the users that have been modified.
   */
  addModifiedUsers(users) {
    this.modifiedUsers = [...new Set([...this.modifiedUsers, ...users])];
  }

  /**
   * @description Method to fetch the operations on modified users.
   *
   * @param {Object} options Options
   * @param {Boolean} [options.removeUserAccess] If true, modified admins will in addition to having
   *   their roles removed, will lose their user access. Default is false.
   * @return {Array} an array of operations to save
   */
  getModifiedUserOperations({removeUserAccess = false} = {}) {
    let operations = [];

    // This is used to remove developer access and optionaly user access as well.
    const modifiedItems = getUsersWithModifiedRoles(this.modifiedUsers);
    modifiedItems.forEach((member) => {
      if (removeUserAccess) {
        this.removedItems.push(member);
      }
      // This is equivalent to a union - Set removes duplicates.
      operations = [...new Set([...operations, ...getAdminRolePatches(member)])];
    });

    return operations;
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      groupId: this.configurationId,
      productId: this.productId,
      roles: this.roles?.join(','),
    };
  }

  /**
   * @description Method to determine if there are any unsaved changes to this
   *              list. Unsaved changes are either items pending addition or
   *              removal to/from this list since list creation or last save.
   * @returns {Boolean} true if there are unsaved changes, else false.
   */
  hasUnsavedChanges() {
    return getUsersWithModifiedRoles(this.modifiedUsers).length > 0 || super.hasUnsavedChanges();
  }

  /**
   * @description Method to save changes to admin list to back-end.
   *   In Admin Console, admins are added and edited using OrganizationUser.save().
   * @param {Object} [options] - options for saving
   * @param {Boolean} [options.removeUserAccess] If true, modified admins will in addition to having
   *   their roles removed, will lose their user access. Default is false.
   * @param {Boolean} [options.refresh] - whether to refresh after saving, defaults to true.
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message.
   */
  async save(options) {
    if (!this.hasUnsavedChanges()) {
      return this;
    }

    // Developers are admins with the role LICENSE_DEV_ADMIN.
    // When a dev is removed the LICENSE_DEV_ADMIN role is removed from the member.
    const modifiedUserOperations = this.getModifiedUserOperations(options);
    if (modifiedUserOperations.length > 0) {
      try {
        await this.modifedUserResource({
          operations: modifiedUserOperations,
          ...getSaveParams(this),
        });
        this.modifiedUsers.forEach((member) => {
          eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
        });
        this.modifiedUsers = [];
      } catch (error) {
        log.error(`LicenseGroupAdminList.save() for modified users failed. Error: ${error}`);
        throw error;
      }
    }

    // Now remove any admins in the removedItems list.
    if (this.removedItems.length > 0) {
      return super.save(getSaveParams(this), options);
    }

    return this;
  }
}

///////////

/**
 * @description Get params used for the call to save the modified users.
 *
 * @param {Object} model the model to pick params from.
 * @returns {Array} params to be passed to JIL or used as a key.
 */
function getSaveParams(model) {
  return {
    groupId: model.configurationId,
    orgId: model.orgId,
    productId: model.productId,
    roles: model.roles?.join(','),
  };
}

export default LicenseGroupAdminList;
