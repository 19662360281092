(function () {
  'use strict';
  /**
   * @deprecated use the Pandora wizard
   */
  angular.module('binky.widgets.common.wizard').component('binkyWizard', {
    bindings: {
      stepTitles: '<',
      wizardId: '@',
    },
    controller,
    templateUrl: 'widgets/common/wizard/wizard.component.html',
    transclude: true,
  });

  /* @ngInject */
  function controller(
    _,
    $element,
    $scope,
    $timeout,
    COMMON_EVENTS,
    modalHelper,
    WIZARD_CHANGE,
    WIZARD_INIT,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    /////////////

    function $onInit() {
      const coralWizardView = $element.find('coral-wizardview')[0];

      $scope.$on(COMMON_EVENTS.RESET, onReset);
      $scope.$on(WIZARD_NEXT, onNext);
      $scope.$on(WIZARD_PREVIOUS, onPrevious);

      Coral.commons.ready(coralWizardView, () => {
        $scope.$emit(WIZARD_INIT, vm.wizardId);
        coralWizardView.on('coral-wizardview:change', onCoralWizardChange);
      });

      ///////////////

      function onNext(event, id) {
        if (id === vm.wizardId) {
          Coral.commons.ready(() => {
            coralWizardView.next();
          });
        }
      }

      function onPrevious(event, id) {
        if (id === vm.wizardId) {
          coralWizardView.previous();
        }
      }

      function onReset() {
        let stepIndex = getWizardCurrentStep(coralWizardView);
        while (stepIndex >= 1) {
          coralWizardView.previous();
          stepIndex -= 1;
        }
      }

      function onCoralWizardChange() {
        // Timeout in order to trigger an Angular digest cycle.
        $timeout(() => {
          const stepIndex = getWizardCurrentStep(coralWizardView);
          const totalSteps = getWizardNumSteps(coralWizardView);
          updateModalContextIfExists(stepIndex);

          $scope.$emit(WIZARD_CHANGE, {
            stepIndex,
            totalSteps,
            wizardId: vm.wizardId,
          });
        });
      }
    }

    function $onChanges() {
      const coralWizardView = $element.find('coral-wizardview')[0];

      Coral.commons.ready(coralWizardView, () => {
        coralWizardView.stepLists.first().items.clear();
        _.forEach(vm.stepTitles, addStepToCoralWizard);
        // Timeout in order to trigger an Angular digest cycle.
        $timeout(() => {
          $scope.$emit(WIZARD_CHANGE, {
            stepIndex: getWizardCurrentStep(coralWizardView),
            totalSteps: vm.stepTitles.length,
            wizardId: vm.wizardId,
          });
        });

        ///////////////

        // Add steps dynamically to circumvent Coral/Angular sync idiosyncrasies
        function addStepToCoralWizard(titleText) {
          coralWizardView.stepLists.first().items.add({
            label: {
              innerText: titleText,
            },
          });
        }
      });
    }

    ///////////////

    function getWizardCurrentStep(coralWizard) {
      const stepList = coralWizard.stepLists.first();
      const steps = stepList.items.getAll();

      return steps.indexOf(stepList.selectedItem);
    }

    function getWizardNumSteps(coralWizard) {
      const stepList = coralWizard.stepLists.first();
      const steps = stepList.items.getAll();

      return steps.length;
    }

    /**
     * This function updates a modal context based on the current wizard view.
     * @param {Number} stepIndex The current wizard's step index
     */
    function updateModalContextIfExists(stepIndex) {
      const wizardSelector = `.${vm.wizardId}`;
      const context = _.get(vm.stepTitles, `[${stepIndex}]`);

      // If there is an open modal that contains the current wizard,
      // we update the modal context based on the current wizard view (step title).
      modalHelper.updateModalContextIfExists(context, wizardSelector);
    }
  }
})();
