import {isWithinInterval, sub} from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

import statusEvents from 'api/statusEvents';
import log from 'services/log';

class StatusEvents {
  /**
   * @description Method to fetch a StatusEvents object.
   * @returns {StatusEvents} the StatusEvents.
   */
  static get() {
    const model = new StatusEvents();
    return model.refresh();
  }

  /**
   * @description Method to create a new instance of a StatusEvents object.
   */
  constructor(options = {}) {
    Object.assign(this, options);
  }

  /**
   * @description Returns all incidents grouped by severity and ordered by latest
   * message time.
   * @returns {Object} An object containing an array for each type of incident:
   * Major, minor, potential, and closed.
   */
  orderIncidentsBySeverity() {
    // The 4 types of incidents
    this.majorIncidents = [];
    this.minorIncidents = [];
    this.potentialIncidents = [];
    this.trivialIncidents = [];
    this.closedIncidents = [];

    const allIncidents = this.incidents;

    allIncidents.forEach((incident) => {
      const incidentId = incident.id;
      const products = Object.values(incident.products);

      products.forEach((product) => {
        // create a clone of the event and set the product incidentId/severity
        const updatedProduct = cloneDeep(product);

        updatedProduct.incidentId = incidentId;
        updatedProduct.incidentSeverity = product.severity;

        const productHistory = Object.values(product.history);
        const latestMessageTime = findLatestMessageTime(productHistory);

        updatedProduct.latestMessageTime = latestMessageTime;

        // check that the incident is not closed, group by severity
        if (updatedProduct.status !== 'Closed') {
          if (updatedProduct.incidentSeverity === 'Major') {
            this.majorIncidents.push(updatedProduct);
          } else if (updatedProduct.incidentSeverity === 'Minor') {
            this.minorIncidents.push(updatedProduct);
          } else if (updatedProduct.incidentSeverity === 'Potential') {
            this.potentialIncidents.push(updatedProduct);
          } else if (updatedProduct.incidentSeverity === 'Trivial') {
            this.trivialIncidents.push(updatedProduct);
          }
        } else if (isDateInLast24Hours(updatedProduct.endedOn)) {
          this.closedIncidents.push(updatedProduct);
        }
      });
    });

    // Sort by latest message time
    this.majorIncidents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.minorIncidents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.potentialIncidents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.trivialIncidents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.closedIncidents.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
  }

  /**
   * @description Returns all maintenance events grouped by type and ordered by latest
   * message time.
   * @returns {Object} An object containing an array for each type of maintenance:
   * Urgent, normal, and closed/cancelled.
   */
  orderMaintenancesByType() {
    // The 3 types of maintenance events
    this.urgentMaintenances = [];
    this.normalMaintenances = [];
    this.completedAndCancelledMaintenances = [];

    const allMaintenances = this.maintenances;

    allMaintenances.forEach((maintenanceEvent) => {
      const products = Object.values(maintenanceEvent.products);

      products.forEach((product) => {
        const productHistory = Object.values(product.history);
        const latestMessageTime = findLatestMessageTime(productHistory);

        // create a clone of the event and set the product name/time stamp
        const updatedEvent = cloneDeep(maintenanceEvent);
        updatedEvent.latestMessageTime = latestMessageTime;

        updatedEvent.name = product.name;
        const maintenanceType = updatedEvent.maintenanceType;
        const status = updatedEvent.status;

        // group by maintenance type
        // urgent and normal should be blue
        if (status === 'Canceled' || status === 'Completed') {
          if (isDateInLast24Hours(updatedEvent.completedOn)) {
            updatedEvent.latestMessageTime = updatedEvent.completedOn;
            this.completedAndCancelledMaintenances.push(updatedEvent);
          }
        } else if (status === 'Started') {
          if (maintenanceType === 'Urgent') {
            this.urgentMaintenances.push(updatedEvent);
          } else {
            this.normalMaintenances.push(updatedEvent);
          }
        }
      });
    });

    // Sort by latest message time
    this.urgentMaintenances.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.normalMaintenances.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
    this.completedAndCancelledMaintenances.sort(
      (a, b) => b.latestMessageTime - a.latestMessageTime
    );
  }

  /**
   * @description Returns all scheduled (upcoming) maintenance events ordered by latest message time.
   * @returns {Array} List of scheduled maintenance events
   */
  orderScheduledMaintenancesByTime() {
    const allScheduledMaintenances = this.scheduledMaintenances;
    this.scheduledMaintenancesByTime = [];

    allScheduledMaintenances.forEach((scheduledMaintenanceEvent) => {
      const products = Object.values(scheduledMaintenanceEvent.products);
      products.forEach((product) => {
        const productHistory = Object.values(product.history);
        const latestMessageTime = findLatestMessageTime(productHistory);

        // create a clone of the event and set the product name/time stamp
        const updatedEvent = cloneDeep(scheduledMaintenanceEvent);
        updatedEvent.latestMessageTime = latestMessageTime;
        updatedEvent.name = product.name;
        this.scheduledMaintenancesByTime.push(updatedEvent);
      });
    });

    // sort by latest message time
    this.scheduledMaintenancesByTime.sort((a, b) => b.latestMessageTime - a.latestMessageTime);
  }

  /**
   * @description Returns all active events and any that ended after we started watching (as long as they
   * ended within the last 24 hours).
   * ORDER IN WHICH EVENTS ARE DISPLAYED (each category in order of latest message time, descending):
   * 1. Open incidents (severity major, minor, then potential)
   * 2. Ongoing maintenances (status urgent, then normal)
   * 3. Closed incidents (as long as they closed within the last 24 hours)
   * 4. Completed/cancelled maintenances
   * 5. Scheduled (upcoming) maintenances
   * @returns {Array} The events
   */
  organizeActiveAndRecentStatusEvents() {
    this.finalStatusListInDisplayOrder = [
      ...this.majorIncidents,
      ...this.minorIncidents,
      ...this.potentialIncidents,
      ...this.urgentMaintenances,
      ...this.normalMaintenances,
      ...this.closedIncidents,
      ...this.completedAndCancelledMaintenances,
      ...this.scheduledMaintenancesByTime,
    ];
  }

  /**
   * @description Method to load and process the status events data.
   * This includes incidents, maintenance events and scheduled maintenance events.
   * @returns {Promise} promise resolved when adobe status list is loaded.
   */
  async refresh() {
    let response, scheduledMaintenanceResponse;
    try {
      const oneDayEarlier = sub(Date.now(), {hours: 24});
      // required query param for statusEvents.getEvents: 'from=<24 hours earlier>'
      response = await statusEvents.getEvents({
        from: formatDate(oneDayEarlier),
      });
    } catch (error) {
      log.error('Unable to get status data. Error: ', error);
      throw error;
    }
    this.incidents = response.data?.incidents || [];
    this.maintenances = response.data?.maintenances || [];

    try {
      scheduledMaintenanceResponse = await statusEvents.getScheduledMaintenances();
    } catch (error) {
      log.error('Unable to get scheduled maintenance data. Error: ', error);
      throw error;
    }
    this.scheduledMaintenances = scheduledMaintenanceResponse.data || [];

    // categorize incidents and maintenances and save these categories on this object
    // concatenate different incident and maintenance categories into the order in which
    // they are displayed on Admin Console (and save master list on this object)
    this.orderIncidentsBySeverity();
    this.orderMaintenancesByType();
    this.orderScheduledMaintenancesByTime();
    this.organizeActiveAndRecentStatusEvents();
    return this;
  }
}

// HELPER METHODS

/**
 * @description Method check whether a date falls within the past 24 window.
 * @returns {Boolean} True if within 24 hours, false if earlier than past 24 hour window.
 */
function isDateInLast24Hours(date) {
  const now = Date.now();
  const twentyFourHoursAgo = sub(now, {hours: 24});
  return isWithinInterval(new Date(date), {
    end: now,
    start: twentyFourHoursAgo,
  });
}

/**
 * @description Method find the most recent message time in a product's event history.
 * @returns {Date} latest message time.
 */
function findLatestMessageTime(productHistory) {
  let latestMessageTime = new Date(productHistory[0].messageTime);
  for (let i = 1; i < productHistory.length; i++) {
    const item = productHistory[i];
    const messageTime = new Date(item.messageTime);
    if (messageTime > latestMessageTime) {
      latestMessageTime = messageTime;
    }
  }
  return latestMessageTime;
}

// Status api takes in only YYYY-MM-DD format
function formatDate(date) {
  const year = date.toLocaleString('default', {year: 'numeric'});
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', {day: '2-digit'});

  return [year, month, day].join('-');
}

export default StatusEvents;
