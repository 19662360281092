import jilProducts from 'api/jil/jilProducts';
import OrganizationUser from 'models/organizationUser/OrganizationUser';
import JilModelList from 'services/modelList/JilModelList';
import {getListCacheKey} from 'utils/cacheUtils';

const PRODUCT_NO_ACCESS_USER_LIST_CACHE_ID = 'productNoAccessUserList';
/**
 * @description List of users who do not have access to the product because they have incompatible ID types
 * @param {Object} options - Options for the constructor
 * @param {String} options.orgId - organization id
 * @param {String} options.productId The product id
 * @param {Array<String>} [options.roles] An OrganizationUser's roles
 * @returns {ProductNoAccessUserList} productNoAccessUserList - new ProductNoAccessUserList
 */
class ProductNoAccessUserList extends JilModelList {
  /**
   * @description Constructor for ProductNoAccessUserList model Objects.
   */
  constructor({orgId, productId, ...options}) {
    super({
      isCacheable: true,
      itemClassRef: OrganizationUser,
      modelCacheId: PRODUCT_NO_ACCESS_USER_LIST_CACHE_ID,
      resource: jilProducts.getProductNoAccessUsers,
      ...options,
    });
    Object.assign(this, {orgId, productId});
  }

  /**
   * @description Method to return the current unique key for this list.
   *              Used for caching the fetched response.
   *
   * @returns {String} key to uniquely identify this list
   */
  getKey() {
    return getListCacheKey(this.getQueryParams());
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      no_access_code: 'IDENTITY_TYPE',
      orgId: this.orgId,
      page_size: this.pagination.pageSize,
      productId: this.productId,
    };
  }

  /**
   * @description Method to refresh the contents of the product np access user list
   * @returns {Promise<ProductNoAccessUserList>} promise - resolved when the list is refreshed
   */
  refresh() {
    return super.refresh(this.getQueryParams());
  }
}

export default ProductNoAccessUserList;
