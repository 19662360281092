/* eslint-disable max-lines -- mock data */
import binky from '@admin-tribe/binky';

const Product = binky.services.product.Product;
const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const UserGroup = binky.services.users.UserGroup;
const mediaRoot = 'https://wwwimages.stage.adobe.com/content/dam/acom';
const svgRoot = `${mediaRoot}/one-console/icons`;
const svgs = {
  acrobatDc: `${svgRoot}/acrobat-dc.svg`,
  aeAppIcon: `${mediaRoot}/one-console/icons_rebrand/ae_appicon.svg`,
  audition: `${svgRoot}/audition.svg`,
  framemaker: `${svgRoot}/framemaker.svg`,
  illustrator: `${svgRoot}/illustrator.svg`,
  photoshop: `${svgRoot}/photoshop.svg`,
  stock: `${svgRoot}/stock.svg`,
};

const mockProducts = [
  {
    assets: {
      icons: {
        svg: svgs.audition,
      },
    },
    availableLicenseCount: 0,
    cloud: 'CREATIVE',
    customerSegment: 'TEAM',
    id: 'product 1',
    longName: 'Audition',
    provisionedQuantity: 3,
  },
  {
    assets: {
      icons: {
        svg: svgs.illustrator,
      },
    },
    availableLicenseCount: 15,
    cloud: 'CREATIVE',
    customerSegment: 'TEAM',
    id: 'product 2',
    longName: 'Illustrator',
    provisionedQuantity: 3,
  },
  {
    assets: {
      icons: {
        svg: svgs.aeAppIcon,
      },
    },
    availableLicenseCount: 1,
    cloud: 'CREATIVE',
    customerSegment: 'TEAM',
    id: 'product 3',
    longName: 'After Effects',
    provisionedQuantity: 1,
  },
  {
    assets: {
      icons: {
        svg: svgs.acrobatDc,
      },
    },
    availableLicenseCount: 5,
    cloud: 'DOCUMENT',
    customerSegment: 'TEAM',
    id: 'product 4',
    longName: 'Acrobat',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.stock,
      },
    },
    availableLicenseCount: 5,
    cloud: 'CREATIVE',
    customerSegment: 'TEAM',
    id: 'product 5',
    longName: 'Adobe Stock - 10 images a month',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.photoshop,
      },
    },
    availableLicenseCount: 10,
    cloud: 'CREATIVE',
    customerSegment: 'TEAM',
    id: 'product 6',
    longName: 'Photoshop',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.framemaker,
      },
    },
    availableLicenseCount: 0,
    cloud: 'OTHERS',
    customerSegment: 'ENTERPRISE',
    id: 'product 7',
    longName: 'Adobe FrameMaker',
    provisionedQuantity: 2,
  },
];

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- bluu@ to update
const mockTeamProducts = () =>
  mockProducts.map((productObj) => {
    const product = new Product(productObj);
    product.getAvailableLicenseCount = () => productObj.availableLicenseCount;
    return product;
  });

// Has no availableLicenseCount, and has licenseGroupSummaries
const mockEnterpriseProducts = [
  {
    assets: {
      icons: {
        svg: svgs.audition,
      },
    },
    cloud: 'CREATIVE',
    id: 'enterprise product 1',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Audition license group 1',
        name: 'Audition license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.audition,
            },
          },
          id: '1',
          longName: 'Audition',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Audition license group 2',
        name: 'Audition license group 2',
        product: {
          assets: {
            icons: {
              svg: svgs.audition,
            },
          },
          id: '1',
          longName: 'Audition',
        },
      },
    ],
    longName: 'Audition',
    provisionedQuantity: 3,
  },
  {
    assets: {
      icons: {
        svg: svgs.illustrator,
      },
    },
    cloud: 'CREATIVE',
    id: 'enterprise product 2',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Illustrator license group 1',
        name: 'Illustrator license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.illustrator,
            },
          },
          id: '2',
          longName: 'Illustrator',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Illustrator license group 2',
        name: 'Illustrator license group 2',
        product: {
          assets: {
            icons: {
              svg: svgs.illustrator,
            },
          },
          id: '2',
          longName: 'Illustrator',
        },
      },
    ],
    longName: 'Illustrator',
    provisionedQuantity: 3,
  },
  {
    assets: {
      icons: {
        svg: svgs.aeAppIcon,
      },
    },
    cloud: 'CREATIVE',
    id: 'enterprise product 3',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'After Effects license group 1',
        name: 'After Effects license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.aeAppIcon,
            },
          },
          id: '3',
          longName: 'After Effects',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'After Effects license group 2',
        name: 'After Effects license group 2',
        product: {
          assets: {
            icons: {
              svg: svgs.aeAppIcon,
            },
          },
          id: '3',
          longName: 'After Effects',
        },
      },
    ],
    longName: 'After Effects',
    provisionedQuantity: 1,
  },
  {
    assets: {
      icons: {
        svg: svgs.acrobatDc,
      },
    },
    cloud: 'DOCUMENT',
    id: 'enterprise product 4',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Acrobat license group 1',
        name: 'Acrobat license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.acrobatDc,
            },
          },
          id: '4',
          longName: 'Acrobat',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Acrobat license group 2',
        name: 'Acrobat license group 2',
        product: {
          assets: {
            icons: {
              svg: svgs.acrobatDc,
            },
          },
          id: '4',
          longName: 'Acrobat',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Acrobat license group 3',
        name: 'Acrobat license group 3',
        product: {
          assets: {
            icons: {
              svg: svgs.acrobatDc,
            },
          },
          id: '4',
          longName: 'Acrobat',
        },
      },
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Acrobat license group 4',
        name: 'Acrobat license group 4',
        product: {
          assets: {
            icons: {
              svg: svgs.acrobatDc,
            },
          },
          id: '4',
          longName: 'Acrobat',
        },
      },
    ],
    longName: 'Acrobat',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.stock,
      },
    },
    cloud: 'CREATIVE',
    id: 'enterprise product 5',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Adobe Stock license group 1',
        name: 'Adobe Stock license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.stock,
            },
          },
          id: '5',
          longName: 'Adobe Stock',
        },
      },
    ],
    longName: 'Adobe Stock - 10 images a month',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.photoshop,
      },
    },
    cloud: 'CREATIVE',
    id: 'enterprise product 6',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Photoshop license group 1',
        name: 'Photoshop license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.photoshop,
            },
          },
          id: '5',
          longName: 'Audition',
        },
      },
    ],
    longName: 'Photoshop',
    provisionedQuantity: 2,
  },
  {
    assets: {
      icons: {
        svg: svgs.framemaker,
      },
    },
    cloud: 'OTHERS',
    id: 'enterprise product 7',
    licenseGroupSummaries: [
      {
        getProductRoles: () => [
          {id: '1', title: 'User'},
          {id: '2', title: 'Admin'},
        ],
        id: 'Adobe FrameMaker license group 1',
        name: 'Adobe FrameMaker license group 1',
        product: {
          assets: {
            icons: {
              svg: svgs.framemaker,
            },
          },
          id: '7',
          longName: 'Adobe FrameMaker',
        },
      },
    ],
    longName: 'Adobe FrameMaker',
    provisionedQuantity: 2,
  },
];

// mockEnterpriseProducts but using src2 Product class and LicenseGroupClass
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- bluu@ to update
const moreMockEnterpriseProducts = () =>
  mockEnterpriseProducts.map((productObj) => {
    const product = new Product(productObj);

    const profiles = product.licenseGroupSummaries
      ? product.licenseGroupSummaries.map(
          (licenseGroupObj) =>
            new LicenseGroup(
              Object.assign(licenseGroupObj, {
                dmaProductRoles: [
                  {id: '1', title: 'User'},
                  {id: '2', title: 'Admin'},
                ],
                product,
              })
            )
        )
      : undefined;

    if (product.licenseGroupSummaries) {
      product.licenseGroupSummaries = profiles;
    }

    return product;
  });

// Mock user groups with src2 UserGroup class
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- thean@ to update
const mockMoreUserGroups = () => {
  const results = [];
  for (let i = 0; i < 1000; i++) {
    results.push(
      new UserGroup({
        id: `User group #${i}`,
        name: `User group #${i}`,
        userCount: i,
      })
    );
  }
  return results;
};

export {
  mockProducts,
  mockEnterpriseProducts,
  mockTeamProducts,
  moreMockEnterpriseProducts,
  mockMoreUserGroups,
};
/* eslint-enable max-lines -- mock data */
