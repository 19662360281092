import {ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {PAGE_ALIGNMENT, PAGE_COMPONENTS} from '../PageConstants';

const HIGHEST_PRIORITY = 3;
const MEDIUM_PRIORITY = 2;
const LOWEST_PRIORITY = 1;

/**
 * The general actions for the Page which can include cta buttons, secondary buttons, and a detail button.
 */
const PageActions = ({align = PAGE_ALIGNMENT.END, alignSelf = 'center', children}) => {
  const alignStart = align === PAGE_ALIGNMENT.START;

  const getTypePriority = (child) => {
    // When buttons aligned at the start: [cta1, cta2, secondary1, secondary2, DetailsButton]
    // When buttons aligned at the end: [DetailsButton, secondary2, secondary1, cta2, cta1]
    if (child?.type?.displayName === PAGE_COMPONENTS.DETAILS_BUTTON) {
      return alignStart ? LOWEST_PRIORITY : HIGHEST_PRIORITY;
    }
    if (child.props.variant === PAGE_COMPONENTS.BUTTON.VARIANT.ACCENT) {
      return alignStart ? HIGHEST_PRIORITY : LOWEST_PRIORITY;
    }
    // The button has the variant 'secondary'
    return MEDIUM_PRIORITY;
  };

  const getSortPriority = (child1, child2) => {
    // When buttons aligned at the start: [cta1, cta2, secondary1, secondary2, DetailsButton]
    // When buttons aligned at the end: [DetailsButton, secondary2, secondary1, cta2, cta1]
    const child1Priority = getTypePriority(child1);
    const child2Priority = getTypePriority(child2);
    return !alignStart && child1Priority === child2Priority
      ? -1 // When aligned on the end, preserves passed in priority ordering of cta and secondary buttons
      : child2Priority - child1Priority;
  };

  const sortedChildren = children.length > 1 ? [...children] : children;
  if (children.length > 1) {
    sortedChildren.sort((child1, child2) => getSortPriority(child1, child2));
  }

  return (
    <ButtonGroup align={align} alignSelf={alignSelf} flexGrow={1} flexShrink={1}>
      {sortedChildren}
    </ButtonGroup>
  );
};

PageActions.displayName = 'PageActions';

PageActions.propTypes = {
  /**
   * The alignment of the actions button group. Defaults to PAGE_ALIGNMENT.END
   */
  align: PropTypes.string,
  /**
   * The alignment of the actions button group within a Flex container. Defaults
   * to 'center'
   */
  alignSelf: PropTypes.string,
  /**
   * The action buttons for a page which can be of the variant primary or secondary, or include a DetailsButton
   */
  children: PropTypes.node,
};

export default PageActions;
