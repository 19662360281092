const ACCEPTED_USER_LIST_CACHE_ID = 'AcceptedUserList';

const APP_INTEGRATION_CACHE_ID = 'AppIntegration';

const APP_INTEGRATION_GLOBAL_PATH = '/GLOBAL/policy';

const APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID = 'AppIntegrationGlobalPolicy';

const APP_INTEGRATION_LIST_CACHE_ID = 'AppIntegrationList';

const AUTHORIZATION_POLICIES = {
  ALL_APPS: {
    ALLOW_ALL: 'ALLOW',
    BLOCK_ALL: 'DENY',
  },
  SINGLE_APP: {
    ALLOW_ALL: 'ALLOW',
    BLOCK_ALL: 'DENY',
    CUSTOM: 'CUSTOM',
  },
};

const INVALID_APPLICATION_POLICY_TARGET_ERROR = 'INVALID_APPLICATION_POLICY_TARGET';

const POLICY_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  REPLACE: 'replace',
};

const POLICY_TARGET_ACTION = {
  ALLOW: 'ALLOW',
};

const POLICY_TARGET_TYPE = {
  GROUP: 'GROUP',
  UNKNOWN: 'UNKNOWN',
  USER: 'USER',
};

export {
  ACCEPTED_USER_LIST_CACHE_ID,
  APP_INTEGRATION_CACHE_ID,
  APP_INTEGRATION_GLOBAL_PATH,
  APP_INTEGRATION_GLOBAL_POLICY_CACHE_ID,
  APP_INTEGRATION_LIST_CACHE_ID,
  AUTHORIZATION_POLICIES,
  INVALID_APPLICATION_POLICY_TARGET_ERROR,
  POLICY_ACTIONS,
  POLICY_TARGET_ACTION,
  POLICY_TARGET_TYPE,
};
