(function () {
  /**
   * @ngdoc module
   * @name binky.core.extended-user-profile.ready
   * @description Module which defines start-up steps for extended user profiles
   */
  angular.module('binky.core.extended-user-profile.ready', [
    'binky.core.configuration.ready',
    'binky.core.extended-user-profile',
    'binky.core.feature',
    'binky.core.lodash',
  ]);
})();
