(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('binky.core.offers')
    .constant('OFFER_LIST_FILTER', {
      CONTRACT: 'CONTRACT',
      NONE: 'NONE',
      PORES: 'PORES', // PORES: personalized offers recommendation service
      SKU: 'SKU',
    })
    .constant('OFFER_LIST_INTENT', {
      // The intent is for getting PORES offers.
      // PORES passes it to Sophia so that it could match up the Sophia Campaigns.
      AAC_OFFER_DISCOVERY_PURCHASE: 'aac_offer_discovery_purchase',
      AAC_OFFER_DISCOVERY_RENEWAL: 'aac_offer_discovery_renewal',
      AAC_SELF_SERVICE_PROMOS: 'aac_self_service_promos',
      ADD_SEATS_OFFER_DISCOVERY: 'add_seats_offer_discovery',
    })
    .constant('OFFER_LIST_MODE', {
      PURCHASE: 'PURCHASE',
      RENEWAL: 'RENEWAL',
    })
    .constant('OFFER_LIST_SERVICE_PROVIDER', {
      FIG_ID: 'FIG_ID',
      MATERIAL: 'MATERIAL',
      MERCHANDISING: 'MERCHANDISING',
      PRICING: 'PRICING',
      PRODUCT_ARRANGEMENT: 'PRODUCT_ARRANGEMENT',
    });
})();
