import assignIn from 'lodash/assignIn';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import union from 'lodash/union';

import {
  CONTRACT_ADMIN,
  LICENSE_ADMIN,
  LICENSE_DEV_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
} from 'models/user/roles/UserRolesConstants';
import log from 'services/log';
import Product from 'services/product/Product';
import LicenseGroup from 'services/product/license-group/LicenseGroup';
import UserRole from 'services/users/UserRole';
import UserGroup from 'services/users/user-group/UserGroup';

/**
 * @description Compare two exploded product license groups.
 *
 * @param {LicenseGroup} itm1 reference to the first Product LicenseGroup
 * @param {LicenseGroup} itm2 reference to the Product LicenseGroup to compare to
 * @returns {Boolean} true if the two items are defined and have the same id and the same product ids
 */
function compareProductLicenseGroups(itm1, itm2) {
  return (
    isEqualAndNotUndefined(itm1?.id, itm2?.id) &&
    isEqualAndNotUndefined(itm1.product?.id, itm2.product?.id)
  );
}

/**
 * @description Compare two user groups.
 *
 * @param {UserGroup} itm1 reference to the first UserGroup
 * @param {UserGroup} itm2 reference to the UserGroup to compare to
 * @returns {Boolean} true if the two items are defined and have the same id
 */
function compareUserGroups(itm1, itm2) {
  return isEqualAndNotUndefined(itm1?.id, itm2?.id);
}

/**
 * @description Explode the products and their license groups into individual items for easier comparison
 *
 * @param {Array} products the set of products to explode.
 *   Default is [] to prevent TypeError if products is undefined.
 * @param {Object} options
 * @param {Boolean} [options.onlyEditable] if true, we restrict the response to only editable products.
 *   Default: false
 * @returns {Array} the exploded array of licenseGroups
 */
function explodeProductLicenseGroups(products = [], {onlyEditable = false} = {}) {
  // we explode the product license groups to make comparisons simpler
  let explodedGroups = [];
  products.forEach((product) => {
    explodedGroups = union(
      explodedGroups,
      product.licenseGroups
        ?.filter(
          // if editable is passed in as true, then we only allow editing administerable groups,
          // which aren't derived via user groups
          // Note, we look for administerable not explicitly false, to allow for cases where
          // a minimal license group object is synthesized by our code, rather than fetched
          (licenseGroup) =>
            !onlyEditable || (licenseGroup.administerable !== false && !licenseGroup.userGroup)
        )
        .map((licenseGroup) => ({
          id: licenseGroup.id,
          product: {
            id: product.id,
          },
        }))
    );
  });
  return explodedGroups;
}

/**
 * @description Helper method to construct the patches for Product License changes to be saved.
 *
 * @param {OrganizationUser} model reference to OrganizationUser model
 * @returns {Array} the set of patches to be performed
 */
function getProductLicensePatches(model) {
  const explodedProductLicenses = explodeProductLicenseGroups(model.products, {onlyEditable: true});
  const explodedSavedProductLicenses = explodeProductLicenseGroups(model.savedState.products, {
    onlyEditable: true,
  });
  const addedProductLicenses = differenceWith(
    explodedProductLicenses,
    explodedSavedProductLicenses,
    compareProductLicenseGroups
  );
  const removedProductLicenses = differenceWith(
    explodedSavedProductLicenses,
    explodedProductLicenses,
    compareProductLicenseGroups
  );
  const addOperations = addedProductLicenses.map((addedProductLicense) => ({
    op: 'add',
    path: `/${model.id}/products/${addedProductLicense.product.id}/licenseGroups/${addedProductLicense.id}`,
  }));
  const removeOperations = removedProductLicenses.map((removedProductLicense) => ({
    op: 'remove',
    path: `/${model.id}/products/${removedProductLicense.product.id}/licenseGroups/${removedProductLicense.id}`,
  }));
  return union(addOperations, removeOperations);
}

/**
 * @description Helper method to construct the patches for User Group changes to be saved.
 *
 * @param {OrganizationUser} model reference to OrganizationUser model
 * @returns {Array} the set of patches to be performed
 */
function getUserGroupPatches(model) {
  const addedUserGroups = differenceWith(
    model.userGroups,
    model.savedState.userGroups,
    compareUserGroups
  );
  const removedUserGroups = differenceWith(
    model.savedState.userGroups,
    model.userGroups,
    compareUserGroups
  );
  const addOperations = addedUserGroups.map((addedUserGroup) => ({
    op: 'add',
    path: `/${model.id}/userGroups/${addedUserGroup.id}`,
  }));
  const removeOperations = removedUserGroups.map((removedUserGroup) => ({
    op: 'remove',
    path: `/${model.id}/userGroups/${removedUserGroup.id}`,
  }));
  return union(addOperations, removeOperations);
}

// Internal helper function.
function isEqualAndNotUndefined(itm1, itm2) {
  return itm1 !== undefined && itm1 === itm2;
}

/**
 * @description Method to initialize a User's developer products.
 *
 * @param {Object[]} roles - Array containing user roles data
 * @param {Product[]} activeOrgProducts - Array containing active org product data
 *
 * @return {Product[]} the list of created Products
 */
function initDeveloperProducts(roles, activeOrgProducts) {
  const developerProducts = [];

  const flatMapedProducts = roles
    ?.filter((role) => role.type === LICENSE_DEV_ADMIN)
    .flatMap((el) => el.targets);
  const groupedById = groupBy(flatMapedProducts, 'parentId');

  Object.entries(groupedById).forEach(([key, value]) => {
    developerProducts.push({id: key, licenseGroups: value});
  });

  return (developerProducts.length > 0 && initProducts(developerProducts, activeOrgProducts)) || [];
}

/**
 * @description Method to initialize a User's products.
 *
 * @param {Object[]} products - Array containing user product data
 * @param {Product[]} activeOrgProducts - Array containing active org product data
 *
 * @return {Product[]} the list of created Products
 */
function initProducts(products, activeOrgProducts) {
  return products?.map((product) => {
    const foundProduct = activeOrgProducts?.find(
      (activeOrgProduct) => activeOrgProduct?.id === product?.id
    );
    if (foundProduct) {
      return new Product(
        assignIn({ignoreEmptyFIs: true}, foundProduct, {
          licenseGroups: product?.licenseGroups?.map(
            (licenseGroup) => new LicenseGroup(licenseGroup)
          ),
          provisioningStatus: product?.provisioningStatus,
        })
      );
    }

    log.error(`organizationUserUtils.initProducts(): product ${product.id} not found`);
    return product;
  });
}

/**
 * @description Method to initialize a User's license groups so
 *              that each Product listed contains the full Product name to
 *              be used when displaying that Product via the UI.
 *
 * @param {Object[]} licenseGroups - Array containing user product config data
 * @param {Product[]} activeOrgProducts - Array containing active org product data
 *
 * @return {LicenseGroup[]} the list of created LicenseGroup
 */
function initLicenseGroups(licenseGroups, activeOrgProducts) {
  return licenseGroups?.map(
    (productConfiguration) =>
      new LicenseGroup(
        assignIn({}, productConfiguration, {
          product: activeOrgProducts.find(
            (activeOrgProduct) => activeOrgProduct.id === get(productConfiguration, 'product.id')
          ),
        })
      )
  );
}

/**
 * @description Method to initialize a User's roles so that each Role listed
 *              contains an actual Role Object/prototype to use.
 *
 * @param {Object[]} roles - Array containing user role data
 * @param {Product[]} activeOrgProducts - Array containing active org product data
 * @param {Contract[]} actionOrgContracts - Array containing active org contract data
 * @return {UserRole[]} the list of created UserRoles
 */

/* eslint-disable complexity -- checks required */
function initRoles(roles, activeOrgProducts, activeOrgContracts) {
  return roles?.map((role) => {
    let foundRole;
    // check for 'role' attribute since that is what API returns (we cast
    // to Role.type attribute when creating a Role Object in the UI)
    switch (role.type) {
      case CONTRACT_ADMIN:
        if (role.targets) {
          foundRole = UserRole.getFromContracts(role.targets, activeOrgContracts);
        } else {
          logInitRoleError(role);
        }
        break;
      case LICENSE_DEV_ADMIN:
        if (role.targets) {
          foundRole = UserRole.getDeveloperFromLicenseGroups(role.targets, activeOrgProducts);
        } else {
          logInitRoleError(role);
        }
        break;
      case LICENSE_ADMIN:
        if (role.targets) {
          foundRole = UserRole.getFromLicenseGroups(role.targets, activeOrgProducts);
        } else {
          logInitRoleError(role);
        }
        break;
      case PRODUCT_ADMIN:
      case PRODUCT_SUPPORT_ADMIN:
        if (role.targets) {
          foundRole = UserRole.getFromProducts(role.targets, activeOrgProducts, role.type);
        } else {
          logInitRoleError(role);
        }
        break;
      case USER_GROUP_ADMIN:
        if (role.targets) {
          foundRole = UserRole.getFromUserGroups(role.targets);
        } else {
          logInitRoleError(role);
        }
        break;
      default: {
        // org, support, or deployment admin role
        foundRole = UserRole.get(role.type);
      }
    }
    return foundRole;
  });
}
/* eslint-enable complexity -- checks required */

/**
 * @description Method to log error whenever role initialization has failed
 *
 * @param {Object[]} roles - Array containing user role data
 *
 */
function logInitRoleError(role) {
  log.error(`Failed to process ${role.type} role: ${role}`);
}

/**
 * @description Method to initialize a User's user groups so that each
 *              group listed contains an actual UserGroup Object/prototype
 *              to use.
 *
 * @param {Object[]} userGroups - Array containing user's group data
 *
 * @return {UserGroup[]} the list of created UserGroups
 */
function initUserGroups(userGroups) {
  return userGroups?.map((userGroup) => new UserGroup(userGroup)) ?? [];
}

export {
  compareProductLicenseGroups,
  compareUserGroups,
  explodeProductLicenseGroups,
  getProductLicensePatches,
  getUserGroupPatches,
  initDeveloperProducts,
  initLicenseGroups,
  initProducts,
  initRoles,
  initUserGroups,
};
