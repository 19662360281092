const PRODUCT_LIST_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.models.product.PRODUCT_LIST_CONSTANTS;

(function () {
  /**
   * @deprecated Please use src2/core/services/product/ProductListConstants.js
   */
  angular
    .module('binky.core.product')
    .constant('FULFILLMENT_TEST_TYPE', PRODUCT_LIST_CONSTANTS.FULFILLMENT_TEST_TYPE)
    .constant('PRODUCT_LIST_CACHE_ID', PRODUCT_LIST_CONSTANTS.PRODUCT_LIST_CACHE_ID)
    .constant('PRODUCT_LIST_EVENT', PRODUCT_LIST_CONSTANTS.PRODUCT_LIST_EVENT)
    .constant('PRODUCT_LIST_PI_CODE', PRODUCT_LIST_CONSTANTS.PRODUCT_LIST_PI_CODE); // Processing Instruction code param
})();
