import {SearchField, View} from '@adobe/react-spectrum';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {getProductDisplayName} from '../../../../features';

import EtlaUsageReportProductTable from './EtlaUsageReportProductTable';

/**
 * A component that will display etla usage report search bar and products table.
 */
const SearchAndProductsRail = ({onSelectProduct, productsTableData}) => {
  const intl = useIntl();
  const [productsTableDataCopy, setProductsTableDataCopy] = useState(productsTableData);

  const getProductNameMatchingList = (query) =>
    productsTableData.filter((x) =>
      getProductDisplayName(intl, x.product).toLowerCase().includes(query.toLowerCase())
    );

  const onSearchProducts = (query) => {
    setProductsTableDataCopy(getProductNameMatchingList(query));
  };

  const searchResultText = intl.formatMessage(
    {id: 'binky.widgets.reports.productSearch.ariaActive.message'},
    {
      totalCount: productsTableDataCopy.length,
    }
  );

  return (
    <View data-testid="etla-usage-report-search-and-products-rail" minWidth="size-4600">
      <SearchField
        data-testid="search-products"
        isQuiet
        label={intl.formatMessage({id: 'binky.widgets.reports.productSearch.label'})}
        marginBottom="size-100"
        onChange={onSearchProducts}
        onSubmit={onSearchProducts}
        width="100%"
      />
      <VisuallyHidden aria-live="polite">{searchResultText}</VisuallyHidden>
      <View backgroundColor="gray-100" id="etla-usage-report-product-table">
        <EtlaUsageReportProductTable
          onSelectProduct={onSelectProduct}
          productsTableData={productsTableDataCopy}
        />
      </View>
    </View>
  );
};

SearchAndProductsRail.propTypes = {
  /**
   * A callback called when a product is selected from product table list with parameter as select product item.
   */
  onSelectProduct: PropTypes.func.isRequired,
  /**
   * An array of objects wrapping bundleProduct and Product.
   */
  // eslint-disable-next-line react/forbid-prop-types -- requires rewrite
  productsTableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SearchAndProductsRail;
