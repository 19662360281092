import {CONTRACT_EXPIRATION_PHASE, CONTRACT_STATUS} from '@admin-tribe/binky';
import {StatusLight} from '@pandora/react-status-light';
import {PropTypes} from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {getContractExpirationStatus} from './contractExpirationStatusUtils';

/**
 * @description Prop types check for the contractStatus and expirationPhase props.
 *     Will check that at least one of those is provided and then confirm the
 *     provided value is an expected value.
 *
 * @param {Object} props - List of all props supplied to a particular component.
 * @param {string} propName - Name of prop that will be checked for type.
 * @param {string} componentName - Name of the component.
 * @returns {Error | null} - Error if none of contractStatus or expirationPhase
 *     are provided, OR if the provided value is not an expected entry, null
 *     otherwise.
 */
function requiredPropsCheck(props, propName, componentName) {
  if (!props.contractStatus && !props.expirationPhase) {
    return new Error(
      `Neither of contractStatus or expirationPhase were provided in '${componentName}'. At least one is required.`
    );
  }
  const propValue = props[propName];
  const error = new Error(`${propValue} is not one of the recognized values for ${propName}`);
  if (propValue && propName === 'contractStatus') {
    return Object.values(CONTRACT_STATUS).includes(propValue) ? null : error;
  }
  if (propValue && propName === 'expirationPhase') {
    return Object.values(CONTRACT_EXPIRATION_PHASE).includes(propValue) ? null : error;
  }
  return null;
}

/**
 * Component for showing the status light and label for a given expiration phase
 * or contract status.
 */
const ContractExpirationStatus = ({
  contractStatus,
  expirationPhase,
  isContractEtlaOrAllocation = false,
}) => {
  const {labelId, variant} = getContractExpirationStatus({
    contractStatus,
    expirationPhase,
    isContractEtlaOrAllocation,
  });
  return (
    <StatusLight variant={variant}>
      <FormattedMessage id={labelId} />
    </StatusLight>
  );
};

ContractExpirationStatus.propTypes = {
  /**
   * Contract status that will determine the label and status light variant.
   */
  contractStatus: requiredPropsCheck,
  /**
   * Expiration phase that will determine the label and status light variant.
   */
  expirationPhase: requiredPropsCheck,
  /**
   * Flag indicating whether the contract this widget is being invoked for was
   * an ETLA or Allocation contract. Defaults to false.
   */
  isContractEtlaOrAllocation: PropTypes.bool,
};

export default ContractExpirationStatus;
