(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name binky.widgets.common.pagination-control-bar.pagination-select
   * @description module that defines pagination-select widgets
   */
  angular.module('binky.widgets.common.pagination-control-bar.pagination-select', [
    'binky.core.lodash',
  ]);
})();
