import {DATA_POINT_TYPES} from '@admin-tribe/binky';

/**
 * @description Helper for getting Assigned column content
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {number} delegatedQty - provisioned qty value for day or month
 * @param {number} provisionedQty - provisioned qty value for day or month
 * @returns {string} assigned column content string
 */
function getAssignedColumnContent(intl, delegatedQty, provisionedQty) {
  if (provisionedQty <= 0) {
    return intl.formatMessage({
      id: 'binky.common.etlaUsageReport.chart.table.content.noData',
    });
  }
  return intl.formatNumber(delegatedQty);
}
/**
 * @description Helper for getting licenses column content
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {number} provisionedQty - provisioned qty value for day or month
 * @returns {string} licenses column content string
 */
function getLicensesColumnContent(intl, provisionedQty) {
  if (provisionedQty <= 0) {
    return intl.formatMessage({
      id: 'binky.common.etlaUsageReport.chart.table.content.noData',
    });
  }
  return intl.formatNumber(provisionedQty);
}
/**
 * @description Helper for getting yearly or monthly table column content
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object} value - labels for chart component
 * @param {string} currentSelectedPeriodType - the current selected period type for chart, i.e. annual or monthly
 * @returns {string} yearly or monthly table column content
 */
function getYearlyOrMonthlyColumnContent(intl, value, currentSelectedPeriodType) {
  if (currentSelectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
    return intl.formatMessage(
      {id: 'binky.widgets.reports.chart.labels.xAxis.yearlyView'},
      {count: value.count}
    );
  }
  return intl.formatMessage(
    {id: 'binky.widgets.reports.chart.labels.xAxis.monthlyView'},
    {
      day: intl.formatDate(value * 1000, {day: 'numeric', timeZone: 'UTC'}),
      month: intl.formatDate(value * 1000, {month: 'short', timeZone: 'UTC'}),
    }
  );
}
/**
 * @description Helper for getting yearly or monthly table header string
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {string} currentSelectedPeriodType - the current selected period type for chart, i.e. annual or monthly
 * @returns {string} yearly or monthly table header content
 */
function getYearlyOrMonthTableHeader(intl, currentSelectedPeriodType) {
  if (currentSelectedPeriodType === DATA_POINT_TYPES.ANNUAL) {
    return intl.formatMessage({id: 'binky.common.etlaUsageReport.chart.table.header.month'});
  }
  return intl.formatMessage({id: 'binky.common.etlaUsageReport.chart.table.header.date'});
}

export {
  getAssignedColumnContent,
  getLicensesColumnContent,
  getYearlyOrMonthTableHeader,
  getYearlyOrMonthlyColumnContent,
};
