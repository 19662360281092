import ConsumableList from './ConsumableList';

class ConsumableSummary {
  /**
   * @description Transforms the consumable summary response object into a new
   *     ConsumableSummary instance. See constructor for params.
   */
  static apiResponseTransformer(dataTransferObject, parentSummarization) {
    return new ConsumableSummary(dataTransferObject, parentSummarization);
  }

  /**
   * @description Creates a new ConsumableSummary instance.
   *
   * @param {Object} options - initialization object
   * @param {string} options.id - value of the dimension to which this
   *     summary pertains. E.g., for summarization by "fulfillment_id," this
   *     would be a specific fulfillment id; for summarization by "license_id,"
   *     this would be a specific license id.
   * @param {Consumable[]} options.consumables - array of Consumable entities;
   *     each is a Consumable summary for a Fulfillable Item associated with
   *     the dimension value
   * @param {ConsumableSummarization} parentSummarization - the
   *     ConsumableSummarization that contains this Summary
   */
  constructor(options, parentSummarization) {
    this.parentSummarization = parentSummarization;
    this.id = options.id;
    this.consumableList = new ConsumableList(options.consumables, this);
  }

  /**
   * @description Find any Consumable objects that are for the specified
   *     fulfillable item code
   *
   * @param {string} fiCode - the fulfillable item code for which to find summaries
   *
   * @returns {Consumable[]} Consumables that match the fulfillable item code
   */
  getConsumablesForFulfillableItemCode(fiCode) {
    return this.consumableList.getConsumablesForFulfillableItemCode(fiCode);
  }

  /**
   * @description Get the top-level query params used when fetching this summary
   *
   * @returns {Object} Params used in the query to get this summary (provided
   *     by the parent ConsumableSummarization)
   */
  getTopLevelQueryParams() {
    return this.parentSummarization.getTopLevelQueryParams();
  }

  /**
   * @description Checks to see if this summary is for a given ID
   *
   * @param {string} id - the ID to test
   *
   * @returns {boolean} whether this summary is for the given ID
   */
  isSummaryForId(id) {
    return this.id === id;
  }
}

export default ConsumableSummary;
