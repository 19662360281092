import binky from '@admin-tribe/binky';
import {useRef} from 'react';

const CountryList = binky.services.country.CountryList;

/**
 * Basic hook that stores the current CountryList to save API calls.
 */
const useCountryList = () => {
  const countryRef = useRef(null);
  if (!countryRef.current) {
    countryRef.current = CountryList.get();
  }

  return countryRef.current;
};

export default useCountryList;
