import axios from 'axios';

import {getPoresHeaders} from './poresApiUtils';

let clientId, url;

/**
 * @description Configure Pores Organization Offers API
 *
 * @param {Object} config - The configuration object
 * @param {string} config.url - The root url for Pores Organization Offers API
 * @param {string} config.clientId - The identifier for application
 */

const configure = (config) => {
  ({url, clientId} = config);
};

/**
 * @description Fetches offers for a particular organization.
 * @param {string} orgId - Id of the organization.
 * for other possible params,
 * refer to https://developers.corp.adobe.com/pores-docs/docs/openapi.yml#operation/getOrgOfferRecommendations
 * @returns {Promise} Resolves to the axios response object whose data contains
 *     the requested offers.
 */
const getOffers = ({orgId, ...params}) =>
  axios.get(`${url}/organizations/${orgId}/offers`, {
    headers: getPoresHeaders({clientId}),
    params,
  });

const poresOrganizationsOffers = {
  configure,
  getOffers,
};

export default poresOrganizationsOffers;
