(function () {
  'use strict';
  /**
   * @deprecated replaced by React Spectrum TableView - see TableStore story
   *
   * @ngdoc component
   * @name binky.widgets.common.table-checkbox:binkyTableCheckbox
   * @description widget to manage a selectable table checkbox.
   * @param {boolean} [isSelectable] determines whether checkbox state can be modified. Defaults to true.
   * @param {String} label the label to use for a screenreader. Optional.
   * @param {Selection} selection selection model instance which tracks selection status.
   * @param {Object} targetItem target object of which to track selection status.
   */
  angular.module('binky.widgets.common.table-checkbox').component('binkyTableCheckbox', {
    bindings: {
      isSelectable: '<?',
      label: '<?',
      selection: '<',
      targetItem: '<',
    },
    controller,
    templateUrl: 'widgets/common/table-checkbox/table-checkbox.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      isChecked,
      isDisabled,
      onClickCheck,
    });

    function isChecked() {
      return vm.selection.isItemSelected(vm.targetItem);
    }

    function isDisabled() {
      return vm.isSelectable === false;
    }

    function onClickCheck() {
      vm.selection.toggleItemSelection(vm.targetItem);
    }
  }
})();
