import {GlobalAdminPolicyList, Locale} from '@admin-tribe/binky';
import camelCase from 'lodash/camelCase';

/**
 * @description Get the disallowed ID type message
 * @param {AvailableTypeList} options.availableTypeList Available ID types
 * @param {intl} options.intl Result of useIntl hook.
 * @returns {String} Disallowed ID type message
 */
function getDisallowedTypeMessage({availableTypeList, intl}) {
  const sortedTranslatedDisallowedType = availableTypeList.availableAndDisallowedTypes
    .map((availableType) =>
      intl.formatMessage({
        id: `binky.common.idType.${camelCase(availableType.userType)}`,
      })
    )
    .sort();

  return intl.formatMessage(
    {
      id: 'binky.common.idType.disallowedTypeMessage2',
    },
    {
      count: sortedTranslatedDisallowedType.length,
      disallowedType0: sortedTranslatedDisallowedType[0],
      disallowedType1: sortedTranslatedDisallowedType[1],
    }
  );
}

/**
 * @description Check global admin policies and return a header and a description
 * when the policies are disabled.
 * @param {Object} options Options
 * @param {intl} options.intl Result of useIntl hook.
 * @param {String} options.orgId Policies are check against this org
 * @param {Object} options.policyActionList List of policies and each corresponding action names.
 * [
 *   {policy: 'policy1', actions: ['action1', 'action2', ...]},
 *   {policy: 'policy2', actions: [...]},
 * ]
 * @returns {Object|undefined} Return either undefined when all policies are allowed,
 * or return a header and a description when policies are disabled.
 * The latter format is {header: ..., description: ...}
 */
async function getPolicyDisabledActionMessage({intl, orgId, policyActionList}) {
  const disabledActions = await getPolicyDisabledActions({orgId, policyActionList});

  return disabledActions
    ? {
        description: intl.formatMessage(
          {
            id: 'binky.globalAdmin.disabledActions.description',
          },
          {
            actionNames: intl.formatList(disabledActions),
            count: disabledActions.length,
          }
        ),
        header: intl.formatMessage({
          id: 'binky.globalAdmin.disabledActions.header',
        }),
      }
    : undefined;
}

/**
 * @description Check global admin policies and return a list of
 * disallowed actions.
 * @param {Object} options Options
 * @param {String} options.orgId Policies are check against this org
 * @param {Object} [options.policyActionList] List of policies and each corresponding action names.
 * [
 *   {policy: 'policy1', actions: ['action1', 'action2', ...]},
 *   {policy: 'policy2', actions: [...]},
 * ]
 * @returns {Array<String>|undefined} List of sorted disallowed actions
 * if any policies are disallowed. Otherwise, return undefined.
 */
async function getPolicyDisabledActions({orgId, policyActionList}) {
  const globalAdminPolicyList = await GlobalAdminPolicyList.get({orgId});
  if (globalAdminPolicyList.items.length === 0) {
    // Default to allow actions
    return undefined;
  }

  const language = Locale.get().activeLanguageBCP47Code;
  const disabledActions = [];

  policyActionList?.forEach(({policy, actions}) => {
    if (!globalAdminPolicyList.getPolicyValue(policy)) {
      disabledActions.push(...actions);
    }
  });

  if (disabledActions.length === 0) {
    return undefined;
  }

  disabledActions.sort((a, b) => a.localeCompare(b, language));
  return disabledActions;
}

export {getDisallowedTypeMessage, getPolicyDisabledActionMessage, getPolicyDisabledActions};
