import {Divider, Flex, Heading, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TitledSection.pcss';

/**
 * @deprecated Ported to Pandora-UI/administration
 * usage info here: https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-titled-section
 */

/**
 * TitledSection is a section widget with a header which contains a title and optional content which right-aligned with
 * the title.
 */

const DIVIDER_PLACEMENT = {
  BOTTOM: 'BOTTOM',
  NONE: 'NONE',
  TOP: 'TOP',
};

const TitledSection = ({
  children,
  dividerPlacement = DIVIDER_PLACEMENT.BOTTOM,
  id,
  rightHeaderContent,
  ruleVariant = 'M',
  title,
}) => {
  const headingId = useId(id);

  return (
    <Flex aria-labelledby={headingId} data-testid="titled-section" direction="column">
      {dividerPlacement === DIVIDER_PLACEMENT.TOP && (
        <View>
          <Divider marginBottom="size-130" size={ruleVariant} variant={ruleVariant} />
        </View>
      )}
      <Flex justifyContent="space-between">
        <Heading
          data-testid="titled-section-title"
          id={headingId}
          level={3}
          marginBottom="size-150"
          marginTop="0"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set the title
          UNSAFE_className={styles.title}
        >
          {title}
        </Heading>

        {rightHeaderContent && <View>{rightHeaderContent}</View>}
      </Flex>
      {dividerPlacement === DIVIDER_PLACEMENT.BOTTOM && (
        <View>
          <Divider size={ruleVariant} />
        </View>
      )}
      <View>{children}</View>
    </Flex>
  );
};

TitledSection.DIVIDER_PLACEMENT = DIVIDER_PLACEMENT;
TitledSection.propTypes = {
  /**
   * The content to display in the TitledSection.
   */
  children: PropTypes.node.isRequired,
  /**
   * The location to put the divider in the TitledSection.
   */
  dividerPlacement: PropTypes.oneOf(Object.keys(DIVIDER_PLACEMENT)),
  /**
   * The id to use for the Heading. Default is a generated unique id.
   * The section content will be labelled by the section title.
   */
  id: PropTypes.string,
  /**
   * Content to be displayed in the TitledSection.
   */
  rightHeaderContent: PropTypes.element,
  /**
   * Variant type of the widget. One of: 'S', 'M', 'L'. Defaults to 'M'.
   */
  ruleVariant: PropTypes.oneOf(['S', 'M', 'L']),
  /**
   * Title of the TitledSection.
   */
  title: PropTypes.string.isRequired,
};
export default TitledSection;
