(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc provider
   * @name binky.core.api.jil:jilContactsProvider
   * @description defines service to manage jil contacts resources
   */
  angular.module('binky.core.api.jil').provider('jilContacts', jilContactsProvider);

  let url;

  /* @ngInject */
  function jilContactsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        contacts: getContactsResource(),
      };

      return service;

      ///////////

      function getContactsResource() {
        return $resource(
          `${url}/:orgId/contacts`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            update: {
              method: 'PUT',
            },
          }
        );
      }
    }
  }
})();
