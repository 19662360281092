const LICENSE_GROUP_CACHE_ID = 'LicenseGroup';
const LICENSE_GROUP_LIST_CACHE_ID = 'LicenseGroupList';
const LICENSE_GROUP_QUOTA_UNLIMITED = 'UNLIMITED';
// eslint-disable-next-line id-length -- porting to src2. Should revisit and move string to the two usage files
const PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE =
  'Unable to create multiple default product configurations';
const PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT = 5;
const PRODUCT_CONFIGURATIONS_EVENT = {
  CREATE: 'ProductConfigurationsEventCreate',
  UPDATE: 'ProductConfigurationsEventUpdate',
};

export {
  LICENSE_GROUP_CACHE_ID,
  LICENSE_GROUP_LIST_CACHE_ID,
  LICENSE_GROUP_QUOTA_UNLIMITED,
  PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE,
  PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT,
  PRODUCT_CONFIGURATIONS_EVENT,
};
