import {
  createFloodgateFeatureFlagVendor,
  exec,
  mapVendorFlags,
} from '@pandora/feature-flag-vendors';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {
  createAllFlagsVendor,
  createConfigVendor,
  createLocalStorageVendor,
  createUrlVendor,
} from '../../utils';
import log from '../log';

let orgId, singleton;

class FeaturesCache {
  /**
   * @description Method for getting the FeaturesCache singleton
   * @returns {FeaturesCache|undefined} the FeaturesCache fetched from cache
   */
  static get() {
    return singleton;
  }

  /**
   * Static setup for setting up feature in storybook, or to be used as a unit tests feature utility
   * @param {Object} map key-value pairs for setting up features for testing purposes
   */
  static setup(map) {
    // eslint-disable-next-line consistent-this -- we need this to be a static class method
    singleton = {
      features: Object.keys(map),
      map,
    };
  }

  envConfig;
  features = [];
  // This is an object literal that when hydrated will contain all features searchable in O(1)
  map = {};

  vendors = [];
  /**
   * Constructs the FeaturesCache with a config and an initial orgId
   * @param {Object} envConfig  - the configuration of a specific environment
   * @param {String} envConfig.services.floodgate.url  - the floodgate url string
   * @param {String} envConfig.services.ims.clientId  - the IMS client id to call Floodgate
   * @param {String} initOrgId - this is the initial orgId
   */
  constructor(envConfig, initOrgId) {
    makeObservable(this, {
      executeVendors: action,
      features: observable,
      map: observable,
    });

    this.envConfig = envConfig;
    singleton = this;
    orgId = initOrgId;

    // If we have an orgId we initialize a Config, otherwise not
    const configVendorConfig = {
      data: envConfig,
      orgId,
    };

    const urlVendorConfig = {
      queryParameter: 'flags',
    };

    const floodgateVendorConfig = {
      apiKey: envConfig.services.ims.clientId,
      clientId: envConfig.services.ims.clientId,
      // This will log any Floodgate constructor issues, inclusive of failing to getAccessToken
      logger: log,
      queryParams: new URLSearchParams({comOrgId: orgId}),
      url: `${envConfig.services.floodgate.url}/feature`,
    };

    if (typeof window !== 'undefined') {
      floodgateVendorConfig.getAccessToken = () =>
        // We use apply to bind the instance of this to window.adobeIMS
        // otherwise it runs getAccessToken in the context of window
        window?.adobeIMS?.getAccessToken.apply(window.adobeIMS).token;

      // We use window.fetch because otherwise fake data would not work
      floodgateVendorConfig.fetch = window.fetch;
    }

    // Generate all Vendor functions
    const Config = createConfigVendor(configVendorConfig);
    const Floodgate = createFloodgateFeatureFlagVendor(floodgateVendorConfig);
    const URLVendor = createUrlVendor(urlVendorConfig);
    const LocalStorage = createLocalStorageVendor();
    const AllFlags = createAllFlagsVendor();

    // 1. We read the config file, and generate features based on current orgId
    // 2. We call Floodgate and get all feature flags from there
    // 3. We look at the flags parameter in the URL and get all features from there
    // 4. We look at local storage which is leveraged by Omnitool
    // 5. We look for the allFlags parameters, and if present we override all other vendors
    this.vendors = [Config, Floodgate, URLVendor, LocalStorage, AllFlags];
  }

  /**
   * This method executes all vendors and assigns the resulted features
   */
  async executeVendors() {
    try {
      const features = await exec(this.vendors);
      runInAction(() => {
        this.features = features;
        this.map = mapVendorFlags(features);
      });
    } catch (error) {
      log.error('Failed to executeVendors within the FeaturesCache class. Error:', error);
    }
  }

  /**
   * This method is called to set a new orgId when an orgID has changed
   * @param {String} newOrgId
   */
  async setOrgId(newOrgId) {
    orgId = newOrgId;
    const Config = createConfigVendor({data: this.envConfig, orgId});
    this.vendors[0] = Config;
    await this.executeVendors();
  }
}
export default FeaturesCache;
