import assignIn from 'lodash/assignIn';
import pick from 'lodash/pick';

export default class StockQuantity {
  /**
   * @class
   * @description Transforms a data response Object into a new StockQuantity
   *   instance for use.
   *
   * @param {Object} dataTransferObject Initialization Object (params described below)
   * @param {Number} dataTransferObject.consumedQuantity A StockQuantity's consumed quantity
   * @param {Number} dataTransferObject.distributedQuantity A StockQuantity's distributed quantity
   * @param {Number} dataTransferObject.remainingQuantity A StockQuantity's remaining quantity
   * @param {Number} dataTransferObject.totalQuantity A StockQuantity's total quantity
   * @param {String} dataTransferObject.type A StockQuantity's type
   */
  static apiResponseTransformer(dataTransferObject) {
    return new StockQuantity(dataTransferObject);
  }

  /**
   * @class
   * @description Creates a new StockQuantity instance for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Number} options.consumedQuantity Consumed quantity for the org
   * @param {Number} options.distributedQuantity Distributed quantity for the org
   * @param {Number} options.remainingQuantity Remaining quantity for the org
   * @param {Number} options.totalQuantity Total quantity (negative in case of PAR offer)
   * @param {String} options.type What type of stock product this quantity represents
   */
  constructor(options) {
    this.initModel(options);
  }

  /**
   * @description Gets the code that JIL uses to represent this stock quantity
   *
   * @returns {String} Code that JIL uses for this stock entitlement
   */
  getJilType() {
    switch (this.type) {
      case 'credit':
        return 'stock_credit';
      case 'image':
        return 'stock_image';
      case 'credit_premium':
        return 'stock_premium_credits';
      case 'credit_standard':
        return 'stock_standard_credits';
      case 'credit_universal':
        return 'stock_universal_credits';
      default:
        return undefined;
    }
  }

  /**
   * @description Initializes StockQuantity data from options.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Number} options.consumedQuantity Consumed quantity for the org
   * @param {Number} options.distributedQuantity Distributed quantity for the org
   * @param {Number} options.remainingQuantity Remaining quantity for the org
   * @param {Number} options.totalQuantity Total quantity (negative in case of PAR offer)
   * @param {String} options.type What type of stock product this quantity represents
   */
  initModel(options = {}) {
    assignIn(
      this,
      pick(options, [
        'consumedQuantity',
        'distributedQuantity',
        'remainingQuantity',
        'totalQuantity',
        'type',
      ])
    );
    this.jilType = this.getJilType(this.type);
  }
}
