import {Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {EN_DASH, SORT_ORDER, Table, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A thin wrap on the Pandora TableSection Table which wraps a React Spectrum TableView.
 * This component must be wrapped in a Pandora TableSectionProvider.
 * 'tableSectionState.items' is used to populate the table.
 *
 * One of 'aria-label' or 'aria-labelledby' is required by TableView.
 * React Spectrum logs a warning to the console if one of these props is missing.
 *
 * This component does the boilerplate layout and allows you to
 * describe the columns and provide a renderer for each column.
 * The item key defaults to 'id' ?? 'key'.
 *
 * The table, by default is using the TableView overflowMode="wrap".
 * By default overflow of a single word is truncuated though individual
 * renderers should override this styling if there isn't a drawer which shows
 * the non-truncated value.
 */
const TableSectionTable = ({
  columnDescriptor,
  columnNamespace,
  renderers,
  sortDescriptor,
  // any remaining props passed to the Pandora Table -> RS TableView
  // one of 'aria-label' or 'aria-labelledby' is required
  ...tableProps
}) => {
  const {tableSectionState} = useTableSectionContext();
  const intl = useIntl();
  const firstSortColumn = columnDescriptor.find((column) => column.props?.allowsSorting ?? false);
  const theSortDescriptor =
    sortDescriptor || (firstSortColumn && {column: firstSortColumn.key, direction: SORT_ORDER.ASC});

  const getColumnDescriptor = (key) => columnDescriptor.find((column) => column.key === key);

  return (
    <Table sortDescriptor={theSortDescriptor} {...tableProps} overflowMode="wrap">
      <TableHeader columns={columnDescriptor}>
        {(column) => (
          <Column key={column.key} {...column.props}>
            {intl.formatMessage({id: `${columnNamespace}.${column.key}`})}
          </Column>
        )}
      </TableHeader>
      <TableBody items={tableSectionState.items}>
        {(item) => (
          <Row key={item.id ?? item.key}>
            {(columnKey) => (
              <Cell>
                {(renderers[columnKey] && renderers[columnKey]({item, key: columnKey})) ??
                  (!getColumnDescriptor(columnKey).allowMissingValue && EN_DASH)}
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

TableSectionTable.propTypes = {
  /**
   * The 'aria-label' for the table. One of 'aria-label' or 'aria-labelledby' is required.
   * React Spectrum TableView will enforce this with warning log to console.
   */
  'aria-label': PropTypes.string,
  /**
   * The 'aria-label' for the table. One of 'aria-label' or 'aria-labelledby' is required.
   * React Spectrum TableView will enforce this with warning log to console.
   */
  'aria-labelledby': PropTypes.string,
  /**
   * An ordered array which describes each column.
   * The allowMissingValue property indicates whether rendering no value is
   * allowed instead of defaulting with an EN_DASH. This should be used
   * judiciously, and is initially being added for rendering nothing in the
   * action column of tables when no action is available. Defaults to false.
   * By default the cells will render an EN_DASH if a renderer method returns
   * null/undefined but not on 0.
   * The key is used to locate the column's header in the columnNamespace
   * and it is passed to the renderer for a given column.
   * Any props for a column are passed along to the Spectrum 'Column'.
   */
  columnDescriptor: PropTypes.arrayOf(
    PropTypes.shape({
      allowMissingValue: PropTypes.bool,
      key: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types -- container for Spectrum Column props
      props: PropTypes.object,
    })
  ).isRequired,
  /**
   * The string namespace for the column headers.
   */
  columnNamespace: PropTypes.string.isRequired,
  /**
   * The renderers for each column.
   * There should be a property with a callback for each column key.
   * If a render returns null/undefined, an EN_DASH will be rendered in the cell.
   */
  // eslint-disable-next-line react/forbid-prop-types -- fields dependent on columnDescriptor, react/forbid-prop-types
  renderers: PropTypes.object.isRequired,
  /**
   * The sortDescriptor to pass to thru to Spectrum's 'TableView'.
   * If not specified, the first column found that allowsSorting will be
   * used and will be sorted ascending.
   */
  sortDescriptor: PropTypes.shape({
    column: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
};

TableSectionTable.displayName = 'TableSectionTable';

export default TableSectionTable;
