(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name binky.core.trusts:TrustList
   * @description fetch the list of trusts for an org.
   */
  angular.module('binky.core.trusts').factory('TrustList', trustList);

  /* @ngInject */
  function trustList(
    $log,
    $q,
    $rootScope,
    _,
    ACCESS_REQUESTS_EVENT,
    ACTIVE_TRUST_LIST_CACHE_ID,
    DIRECTORY_TRUSTEE_EVENT,
    JIL_CONSTANTS,
    jilTrusts,
    List,
    modelCache,
    PENDING_TRUST_LIST_CACHE_ID,
    Trust,
    TRUST_LIST_CACHE_ID,
    TRUST_STATUS,
    TRUSTEE_EVENT,
    TRUSTEE_LIST_EVENT
  ) {
    class TrustList extends List {
      /**
       * @description instantiate the list of Trusts for an Organization.
       */
      constructor() {
        super({
          cacheType: TRUST_LIST_CACHE_ID,
          itemClassRef: Trust,
          resource: jilTrusts.trusts,
          sortExpression: JIL_CONSTANTS.SORT.TRUSTEE_ORG_NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
        this.$resolved = false;
      }

      /**
       * @description Method to accept the access requests for a list of pending Trusts
       * @param {Array} items the list of trusts the should have their access requests accepted
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the TrustList has been refreshed with the trusts with TRUST_STATUS.PENDING status.
       */
      acceptAccessRequests(items) {
        const operations = _.map(items, (item) => ({
          op: 'replace',
          path: `/${item.trusteeOrgId}/${item.directoryId}/ACCEPT/notifications/${item.notifyAdmins}`,
        }));

        return this.batchOperation(operations, {
          status: TRUST_STATUS.PENDING,
        }).then((responses) => {
          // Update the active trust (trustee) list so that the scorecard will update with the new trustee.
          modelCache.removeAll(ACTIVE_TRUST_LIST_CACHE_ID);
          TrustList.get({status: TRUST_STATUS.ACTIVE});
          return emitUpdateTrusteeEvents(responses);
        });
      }

      /**
       * @description Method to accept, reject, revoke or a delete a list of Trust requests
       * @param {Array} operations - list of operations to perform in batch
       * @param {Object} queryParams optional params to use for the list refresh
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the TrustList has been refreshed with the specified queryParams.
       */
      batchOperation(operations, queryParams) {
        this.$promise = $q((resolve, reject) => {
          if (_.isArray(operations) && operations.length > 0) {
            this.$resolved = false;
            this.resource.batchOperation({}, operations, onSuccess.bind(this), onError.bind(this));
          } else {
            resolve([]);
          }

          ////////

          // Multi-status response is an Array of
          // {
          //   "responseCode": httpStatusCode, // 2xx if successful, else a failure code
          //   "response": responseObject      // an OrganizationTrust object if successful, else a description of the failure
          // }
          function onSuccess(response) {
            const [completedItems, failedItems] = _.partition(response, (item) =>
              _.inRange(item.responseCode, 200, 300)
            );

            // The refresh call eventually sets $resolved to true as required.
            this.refresh(queryParams)
              .then(() => resolve({completedItems, failedItems}))
              .catch(onError.bind(this));
          }

          function onError(error) {
            $log.error('Failed to PATCH trust list. Error: ', error);
            this.$resolved = true;
            reject(error);
          }
        });

        return this.$promise;
      }

      /**
       * @description reload the Trust list
       * @param {Object} queryParams the query parameters to pass when refreshing a trust list
       * @returns {Promise} resolved when Trust list fetch completes.
       */
      refresh(queryParams = {}) {
        return super.refresh(queryParams).then(() => {
          if (queryParams.status === TRUST_STATUS.ACTIVE) {
            $rootScope.$emit(TRUSTEE_LIST_EVENT.UPDATE);
          } else if (queryParams.status === TRUST_STATUS.PENDING) {
            $rootScope.$emit(ACCESS_REQUESTS_EVENT.UPDATE);
          }

          if (this.shouldUpdateTotalItemCount()) {
            if (queryParams.status === TRUST_STATUS.ACTIVE) {
              $rootScope.$emit(TRUSTEE_LIST_EVENT.UPDATE_COUNT, this.pagination.itemCount);
            } else if (queryParams.status === TRUST_STATUS.PENDING) {
              $rootScope.$emit(ACCESS_REQUESTS_EVENT.UPDATE_COUNT, this.pagination.itemCount);
            }
          }
        });
      }

      /**
       * @description Method to reject the access requests for a list of pending Trusts.
       * @param {Array} items the list of trusts the should have their access requests rejected
       * @param {String} [rejectReason] optional; reason the requests are rejected
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the TrustList has been refreshed with the trusts with TRUST_STATUS.PENDING status.
       */
      rejectAccessRequests(items, rejectReason) {
        const operations = _.map(items, (item) => ({
          op: 'replace',
          path: rejectReason
            ? `/${item.trusteeOrgId}/${item.directoryId}/REJECT/reason/${rejectReason}`
            : `/${item.trusteeOrgId}/${item.directoryId}/REJECT`,
        }));

        return this.batchOperation(operations, {status: TRUST_STATUS.PENDING});
      }

      /**
       * @description Method to revoke the access for a list of active Trusts.
       * @param {Array} items the list of trusts the should have their access revoked
       * @param {String} [revokeReason] optional; reason the requests are revoked
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the TrustList has been refreshed with the trusts with TRUST_STATUS.ACTIVE status.
       */
      revokeAccess(items, revokeReason) {
        const operations = _.map(items, (item) => ({
          op: 'replace',
          path: revokeReason
            ? `/${item.trusteeOrgId}/${item.directoryId}/REVOKE/reason/${revokeReason}`
            : `/${item.trusteeOrgId}/${item.directoryId}/REVOKE`,
        }));

        return this.batchOperation(operations, {status: TRUST_STATUS.ACTIVE}).then((responses) =>
          emitUpdateTrusteeEvents(responses)
        );
      }

      /**
       * @description Method to save the notification value for an active Trust.
       * @param {Object} item The trust item to update.
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the TrustList has been refreshed with the trusts with TRUST_STATUS.ACTIVE status.
       */
      saveNotificationsValue(item) {
        const operations = [
          {
            op: 'replace',
            path: `/${item.trusteeOrgId}/${item.directoryId}/NOTIFICATIONS/${item.notifyAdmins}`,
          },
        ];

        return this.batchOperation(operations, {status: TRUST_STATUS.ACTIVE}).then((responses) =>
          emitUpdateTrusteeEvents(responses)
        );
      }

      /**
       * @description Method to modify sort parameters of the trust list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       * @returns {undefined} no return value
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description instantiates a new instance of TrustList.
       * @param {Object} params the query parameters to pass when refreshing a trust list
       * @returns {TrustList} TrustList model object.
       */
      static get(params = {}) {
        let model = new TrustList();

        // Override the default cacheType if filtering on active or pending trusts.
        if (params.status === TRUST_STATUS.ACTIVE) {
          model.cacheType = ACTIVE_TRUST_LIST_CACHE_ID;
        } else if (params.status === TRUST_STATUS.PENDING) {
          model.cacheType = PENDING_TRUST_LIST_CACHE_ID;
        }

        const cachedModel = modelCache.get(model.cacheType, model.key(params));
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh(params);
        }
        return model;
      }
    }

    // Cache right now is size 1 so removeAll.
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(DIRECTORY_TRUSTEE_EVENT.UPDATE, () => {
      modelCache.removeAll(ACTIVE_TRUST_LIST_CACHE_ID);
    });

    // We register the cache size for this class
    modelCache.register(ACTIVE_TRUST_LIST_CACHE_ID, 1);
    modelCache.register(PENDING_TRUST_LIST_CACHE_ID, 1);

    return TrustList;

    //////////////

    // There has been an update which impacts an active trust / trustee.
    // Either a pending trust has become an active trust or an active trust has been revoked.
    function emitUpdateTrusteeEvents(responses) {
      const {completedItems} = responses;
      _.forEach(completedItems, (item) => {
        const id = _.get(item, 'response.id');
        $rootScope.$emit(TRUSTEE_EVENT.UPDATE, id);
      });
      return responses;
    }
  }
})();
