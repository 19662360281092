/* eslint-disable max-lines -- this file requires more lines, a lot of the lines are documentation */
import axios from 'axios';

import {csvBlobTransformer} from 'api/utils/apiUtils';

import {getHeaders} from './jilApiUtils';

let clientId, includeRoles, url;

/**
 * @description Uploads CSV to remove users from directory users
 *
 * @param {String} orgId - The org id
 * @param {FormData} data - CSV file as FormData object
 * @param {Object} params - Upload params
 * @param {String} params.directoryId - The id of the directory to which the users will be removed from
 */
const bulkRemoveDirectoryUsers = async ({data, orgId, params = {}}) => {
  const response = await axios.post(`${url}/${orgId}/jobs`, data, {
    headers: getHeaders({clientId, includeRoles}),
    params: {
      type: 'du-remove-users',
      ...params,
    },
  });
  return response;
};

/**
 * @description Configure JIL Directory API
 *
 * @param {Object} config - Configuration object
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 * @param {String} config.url - The root url for JIL
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Deletes a list of tenant admins for the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @param {number} options.id - Admin Id
 * @returns {Promise} Axios request promise
 */
const deleteTenantAdmins = ({directoryId, orgId, tenantId, id}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}/admins/${id}`;
  return axios.delete(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};
/**
 * @description Gets CSV of an organization's directory users
 *
 * @param {String} orgId - The org id
 * @param {String} directoryId - The directory id
 * @param {Object} params - GET params
 * @returns {Promise<Object>} - The Axios response
 */
const exportDirectoryUsers = async ({directoryId, orgId, params}) => {
  const response = await axios.get(`${url}/${orgId}/directories/${directoryId}/users`, {
    headers: {
      Accept: 'text/csv+directory-user-all,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });
  return csvBlobTransformer(response);
};

/**
 * @description Gets a list of domains for the given directory id, in CSV form.
 *
 * @param {Object} options - Request options
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 *
 * @returns {Promise<Object>} - Promise containing the CSV
 */
const exportDirectoryDomainsToCSV = async ({directoryId, orgId, ...queryParams}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/domains`;
  const response = await axios.get(requestUrl, {
    headers: {
      Accept: 'text/csv+domain',
      ...getHeaders({clientId, includeRoles}),
    },
    params: queryParams,
  });

  return csvBlobTransformer(response);
};

/**
 * @description Fetches user head data for a directory user list
 *
 * @param {String} directoryId - Directory ID
 * @param {String} orgId - Organization ID
 */
const headUsers = async ({directoryId, orgId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/users`;
  const response = await axios.head(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Gets a list of domains for the given directory id.
 *
 * @param {Object} options - Request options
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 *
 * @returns {Promise} Axios request promise
 */
const getDomains = ({directoryId, orgId, ...queryParams}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/domains`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Gets a list of trustees for the given directory id.
 *
 * @param {Object} options - Request options
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 *
 * @returns {Promise} Axios request promise
 */
const getDirectoryTrusts = ({directoryId, orgId, ...queryParams}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/trusts`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Gets a list of directories for the given orgId.
 *
 * @param {String} options.orgId - Organization id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 * @param {String} options.ownershipStatus - OWNED or TRUSTED
 * @param {String} options.status - A status related to the OwnershipStatus
 *
 * @returns {Promise} Axios request promise
 */
const getDirectories = ({orgId, ...queryParams}) => {
  const requestUrl = `${url}/${orgId}/directories`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Gets the user for a directory with the given directoryId and userId.
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {String} options.userId - User id
 *
 * @returns {Promise} Axios request promise
 */
const getDirectoryUser = ({directoryId, orgId, userId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/users/${userId}`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Gets a list of tenant admins for the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @param {number} options.id - Admin Id
 * @returns {Promise} Axios request promise
 */
const getTenantAdmins = ({directoryId, orgId, tenantId, id}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}/admins/${id}`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Gets the metadata for a tenant with the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @returns {Promise} Axios request promise
 */
const getTenantMetadata = ({directoryId, orgId, tenantId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}/metadata`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Gets the SAML config with the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @param {number} options.action - Action
 * @returns {Promise} Axios request promise
 */
const getSamlConfig = ({action, directoryId, orgId, tenantId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}${action}`;
  return axios.get(requestUrl);
};

/**
 * @description Gets the details of a directory for the given directoryId.
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 *
 * @returns {Promise} Axios request promise
 */
const getDirectoryDetails = ({orgId, directoryId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Gets the users for a directory with the given directoryId.
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {Object} options.queryParams - The JILModelList params such as page,
 *   page_size, search_query, sort and sort_order.
 *
 * @returns {Promise} Axios request promise
 */
const getDirectoryUsers = ({orgId, directoryId, ...queryParams}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/users`;
  return axios.get(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
    params: queryParams,
  });
};

/**
 * @description Updates the directory for the given directoryId with directoryData.
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 * @param {String} options.directoryData - Data to be updated
 * example for directoryData: {
    name: directoryName,
  };
 * @returns {Promise} Axios request promise
 */
const updateDirectory = ({orgId, directoryId, directoryData}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}`;

  return axios.put(requestUrl, directoryData, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Creates a new directory.
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryData - Data for the new directory
 *
 * @returns {Promise} Axios request promise
 */
const createDirectory = ({orgId, directoryData}) => {
  const requestUrl = `${url}/${orgId}/directories`;
  return axios.post(requestUrl, directoryData, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Removes a directory with the specified directoryId
 *
 * @param {String} options.orgId - Organization id
 * @param {String} options.directoryId - Directory id
 *
 * @returns {Promise} Axios request promise
 */
const deleteDirectory = ({orgId, directoryId}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}`;
  return axios.delete(requestUrl, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Gets CSV of directories for an orgId
 *
 * @param {String} orgId - The org id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 * @param {String} options.ownershipStatus - OWNED or TRUSTED
 * @param {String} options.status - A status related to the OwnershipStatus
 * @returns {Promise<Object>} - Object containing blob representation of csv
 */

const exportDirectoriesToCSV = async ({orgId, ...queryParams}) => {
  const response = await axios.get(`${url}/${orgId}/directories`, {
    headers: {
      Accept: 'text/csv+directory',
      ...getHeaders({clientId, includeRoles}),
    },
    params: queryParams,
  });
  return csvBlobTransformer(response);
};

/**
 * @description Patches a list of directories
 *
 * @param {Object} options
 * @param {String} options.orgId - The org id that the directories belong to.
 * @param {Object[]} options.operations - The patch operations
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const patchDirectories = ({orgId, operations}) =>
  axios.patch(`${url}/${orgId}/directories`, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Patches a list of domains
 *
 * @param {Object} options
 * @param {String} options.directoryId - The id of the directory the users belong to.
 * @param {String} options.orgId - The org id that the users and the directory belong to.
 * @param {Object[]} options.operations - The patch operations
 * @returns {Promise<AxiosResponse>} - The Axios response
 */
const patchDirectoryUsers = async ({directoryId, orgId, operations}) => {
  const response = await axios.patch(
    `${url}/${orgId}/directories/${directoryId}/users`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
  return response;
};

/**
 * @description Saves a list of tenant admins with the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {number} options.id - Admin Id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @returns {Promise} Axios request promise
 */
const postTenantAdmins = ({directoryId, orgId, tenantId, tenantAdmins, id}) => {
  const requestUrl = `${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}/admins/${id}`;
  return axios.post(requestUrl, tenantAdmins, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @description Saves the SAML config with the given params.
 *
 * @param {number} options.directoryId - The directory id
 * @param {number} options.id - Admin Id
 * @param {String} options.orgId - Organization id
 * @param {number} options.tenantId - Tenant Id
 * @returns {Promise} Axios request promise
 */
const postSamlConfig = ({data, directoryId, orgId, tenantId, action}) =>
  axios.post(`${url}/${orgId}/directories/${directoryId}/tenants/${tenantId}${action}`, data, {
    headers: {'Content-Type': undefined, ...getHeaders({clientId, includeRoles})},
  });

/**
 * @description Updates the settings for DomainEnforcement
 *
 * @param {String} directoryId - The directory id
 * @param {String} orgId - Organization id
 * @param {String} data - The state of the domain enforcement phase
 * Accepted values for state:
 * ACTIVATED - New Personal Accounts on the domain are blocked. Personal Account (Non-member) accounts notified
 * ENFORCED - Non-member accounts forced to change
 * DEACTIVATED - Domain Enforcement is not enforced on this directory
 */
const putDirectoryDomainEnforcement = ({state, directoryId, orgId}) =>
  axios.put(
    `${url}/${orgId}/directories/${directoryId}/domain-enforcement`,
    {state},
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Gets the list of exceptions from DomainEnforcement for a directory
 * @param {String} directoryId - The directory id
 * @param {String} orgId - Organization id
 */
const getDomainEnforcementExceptionList = ({directoryId, orgId}) =>
  axios.get(`${url}/${orgId}/directories/${directoryId}/domain-enforcement:exclusions`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Adds an user(or multiple users) to the exception list for DomainEnforcement in a directory
 * @param {String} directoryId - The directory id
 * @param {String} orgId - Organization id
 * @param {Array} exclusions - The list of users to exclude from domain enforcement.
 *                                     Accepts a set of emails and/or t1 userId's
 */
const postDomainEnforcementExceptionList = ({exclusions, directoryId, orgId}) =>
  axios.post(
    `${url}/${orgId}/directories/${directoryId}/domain-enforcement:exclusions`,
    exclusions,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Removes an user(or multiple users) from the exception list for DomainEnforcement in a directory
 *
 * @param {String} directoryId - The directory id
 * @param {String} orgId - Organization id
 * @param {Array<Object>} patches - The list of user emails and ops to patch from domain enforcement.
 */
const patchDomainEnforcementExceptionList = ({patches, directoryId, orgId}) =>
  axios.patch(`${url}/${orgId}/directories/${directoryId}/domain-enforcement:exclusions`, patches, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilDirectories = {
  bulkRemoveDirectoryUsers,
  configure,
  createDirectory,
  deleteDirectory,
  deleteTenantAdmins,
  exportDirectoriesToCSV,
  exportDirectoryDomainsToCSV,
  exportDirectoryUsers,
  getDirectories,
  getDirectoryDetails,
  getDirectoryTrusts,
  getDirectoryUser,
  getDirectoryUsers,
  getDomainEnforcementExceptionList,
  getDomains,
  getSamlConfig,
  getTenantAdmins,
  getTenantMetadata,
  headUsers,
  patchDirectories,
  patchDirectoryUsers,
  patchDomainEnforcementExceptionList,
  postDomainEnforcementExceptionList,
  postSamlConfig,
  postTenantAdmins,
  putDirectoryDomainEnforcement,
  updateDirectory,
};

export default jilDirectories;
/* eslint-enable max-lines -- this file requires more lines, a lot of the lines are documentation */
