import PaginatedModelList from 'services/modelList/PaginatedModelList';

import poresOrganizationsOffers from '../../api/pores/poresOrganizationsOffers';
import feature from '../../services/feature';
import {
  filterOnQualifyingFreeOffersUtil,
  getPoresOrder,
  sortOffersByFunctionOutput,
} from '../../services/offerList/offerListUtils';

import Offer from './Offer';
import {OFFER_LIST_SERVICE_PROVIDER} from './OfferListConstants';

class PoresOfferList extends PaginatedModelList {
  /**
   * @description instantiates a new instance of a PoresOfferList. We don't cache
   *     this in the List because we always want to fetch the latest data.
   *
   * @param {Object} options - Initialization Object (params described below)
   * @param {Object} [options.queryParams] - queryParams to pass to the offers
   *     API call.
   * @returns {PoresOfferList} - PoresOfferList model object.
   */
  static get(options = {}) {
    const model = new PoresOfferList(options);
    return model.refresh();
  }

  /**
   * @description instantiate the list of Offers for an Organization.
   *
   * @param {Object} options - Initialization Object (params described below)
   * @param {string} [options.contractId] - Associates OfferList instance with a
   *     contract.
   * @param {string} [options.orgId] - Associates OfferList instance with an org.
   * @param {Object} [options.queryParams] - queryParams to pass along the
   *     offers API call - for example "price_point".
   */
  constructor(options = {}) {
    super({
      // we don't cache this in the List because we always want to fetch the latest data
      itemClassRef: Offer,
      resource: getResource(),
      // need to pass this converter to convert pores offer object
      // because offer and metadata object are different objects
      // earlier, jil response was returning combined object
      transformResponseData: transformPoresResponseData,
    });

    this.items = [];
    this.queryParams = options.queryParams;
    this.offerIds = options.offerIds;
    this.orgId = options.orgId;
    this.contractId = options.contractId;
  }

  /**
   * @description Method to filter the qualifying free offers for the org
   *   marketSubsegments.
   *   Note: assumes this is a free offer list - 'queryParams: {price_point: 'FREE'}}'
   *   It is also possible that there is not an offer for every
   *   marketSubsegment, or in the future, there may be more than one offer per
   *   marketSubsegment.
   *   This is a destructive change to the object.
   * @param {Object} context - Context provided to filter
   * @param {Organization} [context.organization] - The organization to compare
   *     with for offer qualification.
   * @param {ProductList} [context.ownedProducts] - The current product list to
   *     discard offers that have already been provisioned.
   */
  filterOnQualifyingFreeOffers(context = {}) {
    this.items = filterOnQualifyingFreeOffersUtil(context, this);
  }

  /**
   * @description Method to refresh the Offer List.
   *
   * @return {Promise} resolved when Offer list fetch completes.
   */
  async refresh() {
    const queryParams = getQueryParams(this);
    await super.refresh(queryParams);
    onRefreshSuccess.bind(this)();
    return this;

    function getFilterSpecificQueryParams(model) {
      const defaultServiceProvidersString = getDefaultServiceProvidersString();
      let queryProvidersFromFilter;
      if (model.queryParams && model.queryParams.service_providers) {
        queryProvidersFromFilter = Array.isArray(model.queryParams.service_providers)
          ? model.queryParams.service_providers.join(',')
          : model.queryParams.service_providers;
        model.queryParams.service_providers = queryProvidersFromFilter;
      }
      const poresServiceProviders = queryProvidersFromFilter || defaultServiceProvidersString;
      return {
        contractId: model.contractId,
        orgId: model.orgId,
        service_providers: poresServiceProviders,
      };
    }

    function getQueryParams(model) {
      const defaultQueryParams = {};

      const filterSpecificQueryParams = getFilterSpecificQueryParams(model);

      const allQueryParams = Object.assign(
        defaultQueryParams,
        filterSpecificQueryParams,
        model.queryParams
      );
      Object.keys(allQueryParams).forEach((key) => {
        if (allQueryParams[key] === undefined) delete allQueryParams[key];
      });
      return allQueryParams;
    }

    function onRefreshSuccess() {
      this.items = this.items.filter(
        (offer) => !feature.isEnabled(`filter_offer_${offer.offer_id}`)
      );
      this.items = sortOffersByFunctionOutput(this.items, getPoresOrder);

      return this;
    }
  }
}

/**
 * @description Get the resource according to the filter
 * {PORES}/organization/{orgId}/offers - returns the ordered list of
 * personalised offers for the given organization.
 * This is the endpoint of PORES for organizations. PORES stands for
 * personalized offers recommendation service.
 * More information about PORES in general: https://developers.corp.adobe.com/pores-docs/docs/openapi.yml
 * API spec for Admin Console integration with PORES: https://wiki.corp.adobe.com/x/Q_Jjag
 *
 * @returns {Resource} - The resource for the API.
 */
function getResource() {
  return poresOrganizationsOffers.getOffers;
}

function transformPoresResponseData(responseData, ClassRef) {
  return responseData.map((responseItem) => ClassRef.apiResponseTransformer(responseItem));
}

export function getDefaultServiceProvidersString() {
  return Object.values(OFFER_LIST_SERVICE_PROVIDER).join(',');
}

export default PoresOfferList;
