(function () {
  'use strict';
  /**
   * @deprecated Please use src2/member/memberDisplayUtils.jsx
   *
   * @ngdoc filter
   * @name binky.widgets.common.member:binkyMemberNameLabel
   * @description Display a customer-facing name for a Member instance or one of its child classes.
   * @param {Member} member - A Member instance class or one of its child classes, e.g. User
   * @param {Object} [options] Optional formatting options.
   * @param {Boolean} [options.fallbackToEmail] If true, attempt to display the Member.email if
   *    the member name is empty.
   * @return {String} The Member name, email (optional based on fallbackToEmail), or '[none]'
   * @example $ctrl.ownerInfo.person | binkyMemberNameLabel:{fallbackToEmail: true}
   */

  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('binky.widgets.common.member.member-name')
    .filter('binkyMemberNameLabel', binkyMemberNameLabel);

  /* @ngInject */
  function binkyMemberNameLabel($translate, _) {
    return getMemberNameLabel;

    function getMemberNameLabel(member, options = {}) {
      const name = _.trim(member.getDisplayName());
      // if the name isn't an empty string, we return it
      if (!_.isEmpty(name)) {
        return name;
      } else if (options.fallbackToEmail && member.email) {
        return member.email;
      }
      return $translate.instant('binky.widgets.members.memberName.none');
    }
  }
})();
